var Vocab = [
  {
    "term": "それ",
    "termReading": "それ",
    "termTranslation": "that, that one",
    "ex": "それはとってもいい話だ。",
    "exReading": "それ は とっても いい はなし だ",
    "exTranslation": "That's a really nice story.",
    "category": "Pronoun"
  },
  {
    "term": "一つ",
    "termReading": "ひとつ",
    "termTranslation": "one (thing)",
    "ex": "それを一つください。",
    "exReading": "それ を ひとつ ください",
    "exTranslation": "Please give me one of those.",
    "category": "Noun"
  },
  {
    "term": "一",
    "termReading": "いち",
    "termTranslation": "one",
    "ex": "一から始めましょう。",
    "exReading": "いち から はじめましょう",
    "exTranslation": "Let's start from the beginning.",
    "category": "Noun"
  },
  {
    "term": "二",
    "termReading": "に",
    "termTranslation": "two",
    "ex": "その人には二回会った。",
    "exReading": "その ひと に は に かい あった",
    "exTranslation": "I met that person twice.",
    "category": "Noun"
  },
  {
    "term": "二つ",
    "termReading": "ふたつ",
    "termTranslation": "two (things)",
    "ex": "ソフトクリームを二つください。",
    "exReading": "そふとくりーむ を ふたつ ください",
    "exTranslation": "Please give me two ice cream cones.",
    "category": "Noun"
  },
  {
    "term": "三",
    "termReading": "さん",
    "termTranslation": "three",
    "ex": "彼女は三人の子供の母親だ。",
    "exReading": "かのじょ は さんにん の こども の ははおや だ",
    "exTranslation": "She's the mother of three children.",
    "category": "Noun"
  },
  {
    "term": "三つ",
    "termReading": "みっつ",
    "termTranslation": "three (things)",
    "ex": "コップを三つ買いました。",
    "exReading": "こっぷ を みっつ かいました",
    "exTranslation": "I bought three glasses.",
    "category": "Noun"
  },
  {
    "term": "四つ",
    "termReading": "よっつ",
    "termTranslation": "four (things)",
    "ex": "私は腕時計を四つ持っています。",
    "exReading": "わたし は うでどけい を よっつ もって います",
    "exTranslation": "I have four wristwatches.",
    "category": "Noun"
  },
  {
    "term": "四",
    "termReading": "し",
    "termTranslation": "four",
    "ex": "四月に大学に入学しました。",
    "exReading": "しがつ に だいがく に にゅうがく しました",
    "exTranslation": "I entered my university in April.",
    "category": "Noun"
  },
  {
    "term": "これ",
    "termReading": "これ",
    "termTranslation": "this, this one",
    "ex": "これをください。",
    "exReading": "これをください。",
    "exTranslation": "I'll have this please.",
    "category": "Noun"
  },
  {
    "term": "四",
    "termReading": "よん",
    "termTranslation": "four",
    "ex": "ハワイは四回目です。",
    "exReading": "はわい は よんかいめ です",
    "exTranslation": "This is my fourth trip to Hawaii.",
    "category": "Noun"
  },
  {
    "term": "五つ",
    "termReading": "いつつ",
    "termTranslation": "five (things)",
    "ex": "桃を五つください。",
    "exReading": "もも を いつつ ください",
    "exTranslation": "I'd like five peaches, please.",
    "category": "Noun"
  },
  {
    "term": "五",
    "termReading": "ご",
    "termTranslation": "five",
    "ex": "五人で旅行に行きました。",
    "exReading": "ごにん で りょこう に いきました",
    "exTranslation": "Five of us went on a trip.",
    "category": "Noun"
  },
  {
    "term": "六つ",
    "termReading": "むっつ",
    "termTranslation": "six (things)",
    "ex": "息子は六つになりました。",
    "exReading": "むすこ は むっつ に なりました",
    "exTranslation": "My son turned six.",
    "category": "Noun"
  },
  {
    "term": "六",
    "termReading": "ろく",
    "termTranslation": "six",
    "ex": "彼には子供が六人います。",
    "exReading": "かれ に は こども が ろくにん います",
    "exTranslation": "He has six children.",
    "category": "Noun"
  },
  {
    "term": "七",
    "termReading": "なな",
    "termTranslation": "seven",
    "ex": "バナナが七本あります。",
    "exReading": "ばなな が ななほん あります",
    "exTranslation": "There are seven bananas.",
    "category": "Noun"
  },
  {
    "term": "七つ",
    "termReading": "ななつ",
    "termTranslation": "seven (things)",
    "ex": "この子は今年七つになります。",
    "exReading": "この こ は ことし ななつ に なります",
    "exTranslation": "Our kid's turning seven this year.",
    "category": "Noun"
  },
  {
    "term": "八",
    "termReading": "はち",
    "termTranslation": "eight",
    "ex": "りんごを八個ください。",
    "exReading": "りんご を はちこ ください",
    "exTranslation": "Please give me eight apples.",
    "category": "Noun"
  },
  {
    "term": "八つ",
    "termReading": "やっつ",
    "termTranslation": "eight (things)",
    "ex": "あの家には時計が八つあります。",
    "exReading": "あの いえ に は とけい が やっつ あります",
    "exTranslation": "There are eight clocks in that house.",
    "category": "Noun"
  },
  {
    "term": "なる",
    "termReading": "なる",
    "termTranslation": "become",
    "ex": "彼は医者になりました。",
    "exReading": "かれ は いしゃ に なりました",
    "exTranslation": "He became a doctor.",
    "category": "Verb"
  },
  {
    "term": "九",
    "termReading": "きゅう",
    "termTranslation": "nine",
    "ex": "野球は九人で1チームです。",
    "exReading": "やきゅう は きゅうにん で ひとちーむ です",
    "exTranslation": "In baseball there are nine people on one team.",
    "category": "Noun"
  },
  {
    "term": "九つ",
    "termReading": "ここのつ",
    "termTranslation": "nine (things)",
    "ex": "娘は明日九つになります。",
    "exReading": "むすめ は あす ここのつ に なります",
    "exTranslation": "My daughter's turning nine tomorrow.",
    "category": "Noun"
  },
  {
    "term": "十",
    "termReading": "じゅう",
    "termTranslation": "ten",
    "ex": "その子は指で十数えました。",
    "exReading": "その こ は ゆび で じゅう かぞえました",
    "exTranslation": "The child counted to ten on her fingers.",
    "category": "Noun"
  },
  {
    "term": "百",
    "termReading": "ひゃく",
    "termTranslation": "hundred",
    "ex": "私の祖母は百才です。",
    "exReading": "わたし の そぼ は ひゃくさい です",
    "exTranslation": "My grandmother is 100 years old.",
    "category": "Noun"
  },
  {
    "term": "千",
    "termReading": "せん",
    "termTranslation": "thousand",
    "ex": "千円貸してください。",
    "exReading": "せんえん かして ください",
    "exTranslation": "Please lend me 1000 yen.",
    "category": "Noun"
  },
  {
    "term": "万",
    "termReading": "まん",
    "termTranslation": "ten thousand",
    "ex": "この靴は1万円です。",
    "exReading": "この くつ は いちまんえん です",
    "exTranslation": "These shoes are ten thousand yen.",
    "category": "Noun"
  },
  {
    "term": "円",
    "termReading": "えん",
    "termTranslation": "circle",
    "ex": "そこに大きな円を描いて。",
    "exReading": "そこ に おおき な えん を かいて",
    "exTranslation": "Draw a big circle there.",
    "category": "Noun"
  },
  {
    "term": "円",
    "termReading": "えん",
    "termTranslation": "yen",
    "ex": "カレーライスは700円です。",
    "exReading": "かれーらいす は ななひゃくえん です",
    "exTranslation": "The curry and rice is 700 yen.",
    "category": "Noun"
  },
  {
    "term": "時",
    "termReading": "とき",
    "termTranslation": "time",
    "ex": "時の経つのは早い。",
    "exReading": "とき の たつ の は はやい",
    "exTranslation": "Time passes by quickly.",
    "category": "Noun"
  },
  {
    "term": "する",
    "termReading": "する",
    "termTranslation": "do, make",
    "ex": "友達と一緒に宿題をした。",
    "exReading": "ともだち と いっしょ に しゅくだい を した",
    "exTranslation": "I did my homework with a friend.",
    "category": "Verb"
  },
  {
    "term": "時々",
    "termReading": "ときどき",
    "termTranslation": "sometimes",
    "ex": "彼は時々遅刻します。",
    "exReading": "かれ は ときどき ちこく します",
    "exTranslation": "He's sometimes late.",
    "category": "Adverb"
  },
  {
    "term": "日",
    "termReading": "にち",
    "termTranslation": "day",
    "ex": "私たちは先月11日に結婚しました。",
    "exReading": "わたしたち は せんげつ じゅういち にち に けっこん しました",
    "exTranslation": "We got married last month on the 11th.",
    "category": "Noun"
  },
  {
    "term": "六日",
    "termReading": "むいか",
    "termTranslation": "six days, 6th of the month",
    "ex": "六日前に日本に帰ってきました。",
    "exReading": "むいかまえ に にほん に かえって きました",
    "exTranslation": "I came back to Japan six days ago.",
    "category": "Noun"
  },
  {
    "term": "三日",
    "termReading": "みっか",
    "termTranslation": "three days, 3rd of the month",
    "ex": "手紙が届くのに三日かかりました。",
    "exReading": "てがみ が とどく の に みっか かかりました",
    "exTranslation": "It took three days for the letter to arrive.",
    "category": "Noun"
  },
  {
    "term": "五日",
    "termReading": "いつか",
    "termTranslation": "five days, 5th of the month",
    "ex": "五月五日は祝日です",
    "exReading": "ごがつ いつか は しゅくじつ です",
    "exTranslation": "May 5th is a holiday.",
    "category": "Noun"
  },
  {
    "term": "八日",
    "termReading": "ようか",
    "termTranslation": "eight days, 8th of the month",
    "ex": "八日からイギリスに行きます。",
    "exReading": "ようか から いぎりす に いきます",
    "exTranslation": "I go to England on the eighth.",
    "category": "Noun"
  },
  {
    "term": "二十日",
    "termReading": "はつか",
    "termTranslation": "20 days, 20th of the month",
    "ex": "来月の二十日は弟の誕生日です。",
    "exReading": "らいげつ の はつか は おとうと の たんじょうび です",
    "exTranslation": "The 20th of next month is my little brother's birthday.",
    "category": "Noun"
  },
  {
    "term": "二日",
    "termReading": "ふつか",
    "termTranslation": "two days, 2nd of the month",
    "ex": "私は二日待った。",
    "exReading": "わたし は ふつか まった",
    "exTranslation": "I waited two days.",
    "category": "Noun"
  },
  {
    "term": "九日",
    "termReading": "ここのか",
    "termTranslation": "nine days, 9th of the month",
    "ex": "九日に荷物が届きます。",
    "exReading": "ここのか に にもつ が とどきます",
    "exTranslation": "The package will arrive on the ninth.",
    "category": "Noun"
  },
  {
    "term": "ところ",
    "termReading": "ところ",
    "termTranslation": "place",
    "ex": "私は友達のところに泊まった。",
    "exReading": "わたし は ともだち の ところ に とまった",
    "exTranslation": "I stayed at my friend's place for the night.",
    "category": "Noun"
  },
  {
    "term": "一日",
    "termReading": "ついたち",
    "termTranslation": "1st of the month",
    "ex": "来月の一日は空いていますか。",
    "exReading": "らいげつ の ついたち は あいています か",
    "exTranslation": "Are you free on the 1st of next month?",
    "category": "Noun"
  },
  {
    "term": "十日",
    "termReading": "とおか",
    "termTranslation": "ten days, 10th of the month",
    "ex": "十日後に帰ります。",
    "exReading": "とおかご に かえります",
    "exTranslation": "I'll come home in ten days.",
    "category": "Noun"
  },
  {
    "term": "七日",
    "termReading": "なのか",
    "termTranslation": "seven days, 7th of the month",
    "ex": "先月の七日に孫が生まれました。",
    "exReading": "せんげつ の なのか に まご が うまれました",
    "exTranslation": "My grandchild was born on the seventh of last month.",
    "category": "Noun"
  },
  {
    "term": "四日",
    "termReading": "よっか",
    "termTranslation": "four days, 4th of the month",
    "ex": "新学期は来月の四日からです。",
    "exReading": "しんがっき は らいげつ の よっか から です",
    "exTranslation": "The new school term starts on the fourth of next month.",
    "category": "Noun"
  },
  {
    "term": "月",
    "termReading": "つき",
    "termTranslation": "moon",
    "ex": "今夜は月がとてもきれいです。",
    "exReading": "こんや は つき が とても きれい です",
    "exTranslation": "The moon looks so beautiful tonight.",
    "category": "Noun"
  },
  {
    "term": "水",
    "termReading": "みず",
    "termTranslation": "water",
    "ex": "水を一杯ください。",
    "exReading": "みず を いっぱい ください",
    "exTranslation": "Please give me a glass of water.",
    "category": "Noun"
  },
  {
    "term": "金",
    "termReading": "かね",
    "termTranslation": "money",
    "ex": "これはかなり金がかかった。",
    "exReading": "これ は かなり かね が かかった",
    "exTranslation": "This cost quite a bit of money.",
    "category": "Noun"
  },
  {
    "term": "日曜日",
    "termReading": "にちようび",
    "termTranslation": "Sunday",
    "ex": "日曜日は海に行きました。",
    "exReading": "にちようび は うみ に いきました",
    "exTranslation": "We went to the seaside on Sunday.",
    "category": "Noun"
  },
  {
    "term": "土曜日",
    "termReading": "どようび",
    "termTranslation": "Saturday",
    "ex": "土曜日の夜はクラブに行きます。",
    "exReading": "どようび の よる は くらぶ に いきます",
    "exTranslation": "I'm going to a club Saturday night.",
    "category": "Noun"
  },
  {
    "term": "やる",
    "termReading": "やる",
    "termTranslation": "do",
    "ex": "一緒に宿題をやろう。",
    "exReading": "いっしょ に しゅくだい を やろう",
    "exTranslation": "Let's do our homework together.",
    "category": "Verb"
  },
  {
    "term": "金曜日",
    "termReading": "きんようび",
    "termTranslation": "Friday",
    "ex": "金曜日の夜は友達と出かけます。",
    "exReading": "きんようび の よる は ともだち と でかけます",
    "exTranslation": "I go out with my friends on Friday nights.",
    "category": "Noun"
  },
  {
    "term": "月曜日",
    "termReading": "げつようび",
    "termTranslation": "Monday",
    "ex": "月曜日に会いましょう。",
    "exReading": "げつようび に あいましょう",
    "exTranslation": "Let's meet on Monday.",
    "category": "Noun"
  },
  {
    "term": "木曜日",
    "termReading": "もくようび",
    "termTranslation": "Thursday",
    "ex": "木曜日は仕事が休みです。",
    "exReading": "もくようび は しごと が やすみ です",
    "exTranslation": "Thursday is my day off work.",
    "category": "Noun"
  },
  {
    "term": "曜日",
    "termReading": "ようび",
    "termTranslation": "day of the week",
    "ex": "曜日を間違えました。",
    "exReading": "ようび を まちがえました",
    "exTranslation": "I got the day of the week wrong.",
    "category": "Noun"
  },
  {
    "term": "火曜日",
    "termReading": "かようび",
    "termTranslation": "Tuesday",
    "ex": "火曜日に会議があります。",
    "exReading": "かようび に かいぎ が あります",
    "exTranslation": "There's a meeting on Tuesday.",
    "category": "Noun"
  },
  {
    "term": "水曜日",
    "termReading": "すいようび",
    "termTranslation": "Wednesday",
    "ex": "水曜日はバイトがあります。",
    "exReading": "すいようび は ばいと が あります",
    "exTranslation": "I have a part-time job on Wednesdays.",
    "category": "Noun"
  },
  {
    "term": "週",
    "termReading": "しゅう",
    "termTranslation": "week",
    "ex": "その次の週は空いてますか。",
    "exReading": "その つぎ の しゅう は あいてます か",
    "exTranslation": "Are you free the following week?",
    "category": "Noun"
  },
  {
    "term": "年",
    "termReading": "とし",
    "termTranslation": "year, age",
    "ex": "新しい年が始まりました。",
    "exReading": "あたらしい とし が はじまりました",
    "exTranslation": "A new year has started.",
    "category": "Noun"
  },
  {
    "term": "分かる",
    "termReading": "わかる",
    "termTranslation": "understand",
    "ex": "質問の意味は分かりましたか。",
    "exReading": "しつもん の いみ は わかりました か",
    "exTranslation": "Did you understand the meaning of the question?",
    "category": "Verb"
  },
  {
    "term": "そう",
    "termReading": "そう",
    "termTranslation": "in such manner, that way",
    "ex": "私もそう思います。",
    "exReading": "わたし も そう おもいます",
    "exTranslation": "That's what I think too.",
    "category": "Adverb"
  },
  {
    "term": "何",
    "termReading": "なに",
    "termTranslation": "what, which",
    "ex": "夕食には何を食べたいですか。",
    "exReading": "ゆうしょく に は なに を たべたい です か",
    "exTranslation": "What would you like for supper?",
    "category": "Noun"
  },
  {
    "term": "先",
    "termReading": "さき",
    "termTranslation": "ahead, first",
    "ex": "お先にどうぞ。",
    "exReading": "おさきに どうぞ",
    "exTranslation": "Please go ahead.",
    "category": "Noun"
  },
  {
    "term": "今年",
    "termReading": "ことし",
    "termTranslation": "this year",
    "ex": "今年はイタリアに旅行したい。",
    "exReading": "ことし は いたりあ に りょこう したい",
    "exTranslation": "I want to travel to Italy this year.",
    "category": "Noun"
  },
  {
    "term": "今",
    "termReading": "いま",
    "termTranslation": "now",
    "ex": "彼は今、勉強しています。",
    "exReading": "かれ は いま べんきょう して います",
    "exTranslation": "He's studying now.",
    "category": "Adverb"
  },
  {
    "term": "今日",
    "termReading": "きょう",
    "termTranslation": "today",
    "ex": "今日は仕事がありません。",
    "exReading": "きょう は しごと が ありません",
    "exTranslation": "I have no work today.",
    "category": "Noun"
  },
  {
    "term": "今月",
    "termReading": "こんげつ",
    "termTranslation": "this month",
    "ex": "今月はとても忙しい。",
    "exReading": "こんげつ は とても いそがしい",
    "exTranslation": "I'm very busy this month.",
    "category": "Noun"
  },
  {
    "term": "今週",
    "termReading": "こんしゅう",
    "termTranslation": "this week",
    "ex": "今週は日本語のテストがあります。",
    "exReading": "こんしゅう は にほんご の てすと が あります",
    "exTranslation": "There's a Japanese test this week.",
    "category": "Noun"
  },
  {
    "term": "来る",
    "termReading": "くる",
    "termTranslation": "come",
    "ex": "彼は昼過ぎに来ます。",
    "exReading": "かれ は ひるすぎ に きます",
    "exTranslation": "He'll come after lunch.",
    "category": "Verb"
  },
  {
    "term": "来年",
    "termReading": "らいねん",
    "termTranslation": "next year",
    "ex": "来年一緒に旅行しましょう。",
    "exReading": "らいねん いっしょ に りょこう しましょう",
    "exTranslation": "Let's travel together next year.",
    "category": "Noun"
  },
  {
    "term": "もう",
    "termReading": "もう",
    "termTranslation": "already, yet",
    "ex": "彼はもう帰りました。",
    "exReading": "かれ は もう かえりました 。",
    "exTranslation": "He's already gone home.",
    "category": "Adverb"
  },
  {
    "term": "行く",
    "termReading": "いく",
    "termTranslation": "go",
    "ex": "日曜日は図書館に行きます。",
    "exReading": "にちようび は としょかん に いきます",
    "exTranslation": "I go to the library on Sundays.",
    "category": "Verb"
  },
  {
    "term": "帰る",
    "termReading": "かえる",
    "termTranslation": "return, go back",
    "ex": "家に帰ろう。",
    "exReading": "うち に かえろう",
    "exTranslation": "Let's go home.",
    "category": "Verb"
  },
  {
    "term": "大きい",
    "termReading": "おおきい",
    "termTranslation": "big",
    "ex": "あの大きい建物は何ですか。",
    "exReading": "あの おおきい たてもの は なん です か",
    "exTranslation": "What's that big building?",
    "category": "Adjective"
  },
  {
    "term": "小さい",
    "termReading": "ちいさい",
    "termTranslation": "small",
    "ex": "小さい花が咲いています。",
    "exReading": "ちいさい はな が さいて います",
    "exTranslation": "Small flowers are blooming.",
    "category": "Adjective"
  },
  {
    "term": "少ない",
    "termReading": "すくない",
    "termTranslation": "few, little",
    "ex": "今年は雨が少ないです。",
    "exReading": "ことし は あめ が すくない です 。",
    "exTranslation": "There's not much rain this year.",
    "category": "Adjective"
  },
  {
    "term": "少し",
    "termReading": "すこし",
    "termTranslation": "a bit, a little while",
    "ex": "少し疲れました。",
    "exReading": "すこし つかれました",
    "exTranslation": "I'm a little tired.",
    "category": "Adverb"
  },
  {
    "term": "多い",
    "termReading": "おおい",
    "termTranslation": "lots of",
    "ex": "京都にはお寺が多い。",
    "exReading": "きょうと に は おてら が おおい",
    "exTranslation": "There are a lot of temples in Kyoto.",
    "category": "Adjective"
  },
  {
    "term": "多分",
    "termReading": "たぶん",
    "termTranslation": "probably, perhaps",
    "ex": "彼女は多分家で寝ています。",
    "exReading": "かのじょ は たぶん いえ で ねて います",
    "exTranslation": "She's probably sleeping at home.",
    "category": "Adverb"
  },
  {
    "term": "上げる",
    "termReading": "あげる",
    "termTranslation": "raise, improve",
    "ex": "彼は荷物をあみだなに上げた。",
    "exReading": "かれ は にもつ を あみだな に あげた",
    "exTranslation": "He put the luggage on the luggage rack.",
    "category": "Verb"
  },
  {
    "term": "よく",
    "termReading": "よく",
    "termTranslation": "often",
    "ex": "彼女はよく旅行に行きます。",
    "exReading": "かのじょ は よく りょこう に いきます",
    "exTranslation": "She often goes on holiday.",
    "category": "Adverb"
  },
  {
    "term": "上る",
    "termReading": "のぼる",
    "termTranslation": "go up",
    "ex": "猫が屋根に上っている。",
    "exReading": "ねこ が やね に のぼって いる",
    "exTranslation": "The cat's up on the roof.",
    "category": "Verb"
  },
  {
    "term": "上",
    "termReading": "うえ",
    "termTranslation": "up, above",
    "ex": "上を向いて。",
    "exReading": "うえ を むいて",
    "exTranslation": "Look up.",
    "category": "Noun"
  },
  {
    "term": "下",
    "termReading": "した",
    "termTranslation": "under, below",
    "ex": "財布は机の下にあった。",
    "exReading": "さいふ は つくえ の した に あった",
    "exTranslation": "My purse was under the desk.",
    "category": "Noun"
  },
  {
    "term": "右",
    "termReading": "みぎ",
    "termTranslation": "right",
    "ex": "右のポケットにハンカチが入っています。",
    "exReading": "みぎ の ぽけっと に はんかち が はいって います",
    "exTranslation": "There's a handkerchief in my right pocket.",
    "category": "Noun"
  },
  {
    "term": "左",
    "termReading": "ひだり",
    "termTranslation": "left",
    "ex": "そこを左に曲がってください。",
    "exReading": "そこ を ひだり に まがって ください",
    "exTranslation": "Please turn left there.",
    "category": "Noun"
  },
  {
    "term": "方",
    "termReading": "ほう",
    "termTranslation": "direction, side",
    "ex": "彼は私の方を見ました。",
    "exReading": "かれ は わたし の ほう を みました",
    "exTranslation": "He looked at me.",
    "category": "Noun"
  },
  {
    "term": "大人",
    "termReading": "おとな",
    "termTranslation": "adult",
    "ex": "お酒は大人になってから。",
    "exReading": "おさけ は おとな に なって から",
    "exTranslation": "You can drink alcohol when you're an adult.",
    "category": "Noun"
  },
  {
    "term": "人",
    "termReading": "ひと",
    "termTranslation": "person",
    "ex": "彼は優しい人です。",
    "exReading": "かれ は やさしい ひと です",
    "exTranslation": "He's a nice guy.",
    "category": "Noun"
  },
  {
    "term": "一人",
    "termReading": "ひとり",
    "termTranslation": "one, one person",
    "ex": "そこには私一人しかいなかった。",
    "exReading": "そこ に は わたし ひとり しか いなかった",
    "exTranslation": "I was the only one there.",
    "category": "Noun"
  },
  {
    "term": "どう",
    "termReading": "どう",
    "termTranslation": "how, what",
    "ex": "あなたはどう思いますか。",
    "exReading": "あなた は どう おもいます か",
    "exTranslation": "What do you think?",
    "category": "Adverb"
  },
  {
    "term": "入れる",
    "termReading": "いれる",
    "termTranslation": "put in",
    "ex": "彼はかばんに手帳を入れた。",
    "exReading": "かれ は かばん に てちょう を いれた",
    "exTranslation": "He put the appointment book in his bag.",
    "category": "Verb"
  },
  {
    "term": "入る",
    "termReading": "はいる",
    "termTranslation": "enter",
    "ex": "寒いので中に入ってください。",
    "exReading": "さむい の で なか に はいって ください",
    "exTranslation": "It's cold so please come inside.",
    "category": "Verb"
  },
  {
    "term": "出る",
    "termReading": "でる",
    "termTranslation": "go out, leave",
    "ex": "今朝は早く家を出ました。",
    "exReading": "けさ は はやく いえ を でました",
    "exTranslation": "I left the house early this morning.",
    "category": "Verb"
  },
  {
    "term": "出来る",
    "termReading": "できる",
    "termTranslation": "can do, be good at",
    "ex": "彼女はイタリア語が出来ます。",
    "exReading": "かのじょ は いたりあご が できます",
    "exTranslation": "She knows Italian.",
    "category": "Verb"
  },
  {
    "term": "出す",
    "termReading": "だす",
    "termTranslation": "take out",
    "ex": "彼はかばんから教科書を出した。",
    "exReading": "かれ は かばん から きょうかしょ を だした",
    "exTranslation": "He took the textbook out of his bag.",
    "category": "Verb"
  },
  {
    "term": "本",
    "termReading": "ほん",
    "termTranslation": "book, volume",
    "ex": "本を１冊買いました。",
    "exReading": "ほん を いっさつ かいました",
    "exTranslation": "I bought one book.",
    "category": "Noun"
  },
  {
    "term": "休む",
    "termReading": "やすむ",
    "termTranslation": "take a day off, take a break",
    "ex": "明日、会社を休みます。",
    "exReading": "あした かいしゃ を やすみます",
    "exTranslation": "I'm off work tomorrow.",
    "category": "Verb"
  },
  {
    "term": "体",
    "termReading": "からだ",
    "termTranslation": "body, health",
    "ex": "私は体が丈夫だ。",
    "exReading": "わたし は からだ が じょうぶ だ",
    "exTranslation": "I'm physically strong.",
    "category": "Noun"
  },
  {
    "term": "目",
    "termReading": "め",
    "termTranslation": "eye",
    "ex": "彼女は青い目をしています。",
    "exReading": "かのじょ は あおい め を して います",
    "exTranslation": "She has blue eyes.",
    "category": "Noun"
  },
  {
    "term": "どこ",
    "termReading": "どこ",
    "termTranslation": "where",
    "ex": "あの本をどこに置きましたか。",
    "exReading": "あの ほん を どこ に おきました か",
    "exTranslation": "Where did you put that book?",
    "category": "Noun"
  },
  {
    "term": "口",
    "termReading": "くち",
    "termTranslation": "mouth",
    "ex": "口を大きく開けてください。",
    "exReading": "くち を おおきく あけて ください",
    "exTranslation": "Open wide, please.",
    "category": "Noun"
  },
  {
    "term": "耳",
    "termReading": "みみ",
    "termTranslation": "ear, hearing",
    "ex": "彼女は耳がよく聞こえません。",
    "exReading": "かのじょ は みみ が よく きこえません",
    "exTranslation": "Her hearing isn't very good.",
    "category": "Noun"
  },
  {
    "term": "上手",
    "termReading": "じょうず",
    "termTranslation": "good, skilled",
    "ex": "妹は歌が上手です。",
    "exReading": "いもうと は うた が じょうず です",
    "exTranslation": "My little sister can sing.",
    "category": "Adjectival Noun"
  },
  {
    "term": "手",
    "termReading": "て",
    "termTranslation": "hand",
    "ex": "分かった人は手を上げてください。",
    "exReading": "わかった ひと は て を あげて ください",
    "exTranslation": "Please raise your hand if you understand.",
    "category": "Noun"
  },
  {
    "term": "足",
    "termReading": "あし",
    "termTranslation": "leg, foot",
    "ex": "彼は足が長い。",
    "exReading": "かれ は あし が ながい",
    "exTranslation": "He has long legs.",
    "category": "Noun"
  },
  {
    "term": "空く",
    "termReading": "すく",
    "termTranslation": "be empty",
    "ex": "レストランは空いていました。",
    "exReading": "れすとらん は すいて いました",
    "exTranslation": "The restaurant was empty.",
    "category": "Verb"
  },
  {
    "term": "男",
    "termReading": "おとこ",
    "termTranslation": "man, male",
    "ex": "男の人が私たちに話しかけた。",
    "exReading": "おとこ の ひと が わたしたち に はなしかけた",
    "exTranslation": "This guy began talking to us.",
    "category": "Noun"
  },
  {
    "term": "女",
    "termReading": "おんな",
    "termTranslation": "woman, girl",
    "ex": "店員は若い女の人でした。",
    "exReading": "てんいん は わかい おんな の ひと でした",
    "exTranslation": "The salesclerk was a young woman.",
    "category": "Noun"
  },
  {
    "term": "子供",
    "termReading": "こども",
    "termTranslation": "child, kid",
    "ex": "電車で子供が騒いでいた。",
    "exReading": "でんしゃ で こども が さわいで いた",
    "exTranslation": "Kids were making a racket in the train.",
    "category": "Noun"
  },
  {
    "term": "あげる",
    "termReading": "あげる",
    "termTranslation": "give",
    "ex": "この本、あなたにあげます。",
    "exReading": "この ほん あなた に あげます",
    "exTranslation": "I'll give you this book.",
    "category": "Verb"
  },
  {
    "term": "好き",
    "termReading": "すき",
    "termTranslation": "favorite, liked",
    "ex": "私はワインが好きです。",
    "exReading": "わたし は わいん が すき です",
    "exTranslation": "I like wine.",
    "category": "Adjectival Noun"
  },
  {
    "term": "大好き",
    "termReading": "だいすき",
    "termTranslation": "love",
    "ex": "私は犬が大好きだ。",
    "exReading": "わたし は いぬ が だいすき だ",
    "exTranslation": "I love dogs.",
    "category": "Adjective"
  },
  {
    "term": "私",
    "termReading": "わたし",
    "termTranslation": "I",
    "ex": "私が行きましょう。",
    "exReading": "わたし が いきましょう",
    "exTranslation": "Why don't I go.",
    "category": "Noun"
  },
  {
    "term": "友達",
    "termReading": "ともだち",
    "termTranslation": "friend, companion",
    "ex": "私には友達がたくさんいます。",
    "exReading": "わたし に は ともだち が たくさん います",
    "exTranslation": "I have a lot of friends.",
    "category": "Noun"
  },
  {
    "term": "家",
    "termReading": "うち",
    "termTranslation": "house, home",
    "ex": "家に遊びに来てください。",
    "exReading": "うち に あそび に きて ください",
    "exTranslation": "Please come over to my house.",
    "category": "Noun"
  },
  {
    "term": "気",
    "termReading": "き",
    "termTranslation": "spirit, temperament",
    "ex": "彼は意外に気が小さい。",
    "exReading": "かれ は いがい に き が ちいさい",
    "exTranslation": "He's unexpectedly timid.",
    "category": "Noun"
  },
  {
    "term": "元気",
    "termReading": "げんき",
    "termTranslation": "healthy, energetic",
    "ex": "おかげさまで元気です。",
    "exReading": "おかげさま で げんき です",
    "exTranslation": "I'm fine, thank you.",
    "category": "Adjective"
  },
  {
    "term": "天気",
    "termReading": "てんき",
    "termTranslation": "weather",
    "ex": "今日はいい天気ですね。",
    "exReading": "きょう は いい てんき です ね",
    "exTranslation": "It's nice out today.",
    "category": "Noun"
  },
  {
    "term": "晴れる",
    "termReading": "はれる",
    "termTranslation": "be sunny, clear up",
    "ex": "明日は晴れるといいですね。",
    "exReading": "あした は はれる と いい です ね",
    "exTranslation": "I hope it'll be sunny tomorrow.",
    "category": "Verb"
  },
  {
    "term": "こう",
    "termReading": "こう",
    "termTranslation": "like this, this kind of",
    "ex": "こう小さい字は読めない。",
    "exReading": "こう ちいさい じ は よめない",
    "exTranslation": "I can't read such small letters.",
    "category": "Adverb"
  },
  {
    "term": "昨日",
    "termReading": "きのう",
    "termTranslation": "yesterday",
    "ex": "昨日、友達に会った。",
    "exReading": "きのう ともだち に あった",
    "exTranslation": "I met a friend yesterday.",
    "category": "Noun"
  },
  {
    "term": "開ける",
    "termReading": "あける",
    "termTranslation": "open, unlock",
    "ex": "窓を開けてください。",
    "exReading": "まど を あけて ください",
    "exTranslation": "Please open the window.",
    "category": "Verb"
  },
  {
    "term": "開く",
    "termReading": "ひらく",
    "termTranslation": "open",
    "ex": "32ページを開いてください。",
    "exReading": "さんじゅうにぺーじ を ひらいて ください",
    "exTranslation": "Please open to page 32.",
    "category": "Verb"
  },
  {
    "term": "閉じる",
    "termReading": "とじる",
    "termTranslation": "close",
    "ex": "教科書を閉じてください。",
    "exReading": "きょうかしょ を とじて ください",
    "exTranslation": "Please close your textbooks.",
    "category": "Verb"
  },
  {
    "term": "閉める",
    "termReading": "しめる",
    "termTranslation": "shut, close",
    "ex": "ちゃんとドアを閉めてよ。",
    "exReading": "ちゃんと どあ を しめてよ",
    "exTranslation": "Close the door properly.",
    "category": "Verb"
  },
  {
    "term": "閉まる",
    "termReading": "しまる",
    "termTranslation": "be shut, be closed",
    "ex": "お店はもう閉まっていました。",
    "exReading": "おみせ は もう しまって いました",
    "exTranslation": "The shop had already closed.",
    "category": "Verb"
  },
  {
    "term": "聞く",
    "termReading": "きく",
    "termTranslation": "hear, ask",
    "ex": "彼女はラジオを聞いています。",
    "exReading": "かのじょ は らじお を きいて います",
    "exTranslation": "She's listening to the radio.",
    "category": "Verb"
  },
  {
    "term": "時間",
    "termReading": "じかん",
    "termTranslation": "time, hour",
    "ex": "今は時間がありません。",
    "exReading": "いま は じかん が ありません",
    "exTranslation": "I don't have time now.",
    "category": "Noun"
  },
  {
    "term": "高い",
    "termReading": "たかい",
    "termTranslation": "high, tall",
    "ex": "これがこの町で一番高いビルです。",
    "exReading": "これ が この まち で いちばん たかい びる です",
    "exTranslation": "This is the tallest building in town.",
    "category": "Adjective"
  },
  {
    "term": "くれる",
    "termReading": "くれる",
    "termTranslation": "be given",
    "ex": "友達が誕生日プレゼントをくれた。",
    "exReading": "ともだち が たんじょうび ぷれぜんと を くれた",
    "exTranslation": "My friend gave me a birthday present.",
    "category": "Verb"
  },
  {
    "term": "安い",
    "termReading": "やすい",
    "termTranslation": "inexpensive, cheap",
    "ex": "この服はとても安かった。",
    "exReading": "この ふく は とても やすかった",
    "exTranslation": "These clothes were very cheap.",
    "category": "Adjective"
  },
  {
    "term": "低い",
    "termReading": "ひくい",
    "termTranslation": "low, short",
    "ex": "彼は背が低い。",
    "exReading": "かれ は せ が ひくい",
    "exTranslation": "He's short.",
    "category": "Adjective"
  },
  {
    "term": "前",
    "termReading": "まえ",
    "termTranslation": "front, before",
    "ex": "その店の前で会いましょう。",
    "exReading": "その みせ の まえ で あいましょう",
    "exTranslation": "Let's meet in front of the shop.",
    "category": "Noun"
  },
  {
    "term": "後",
    "termReading": "あと",
    "termTranslation": "after",
    "ex": "仕事の後、映画を見た。",
    "exReading": "しごと の あと えいが を みた",
    "exTranslation": "I saw a movie after work.",
    "category": "Adverb"
  },
  {
    "term": "後ろ",
    "termReading": "うしろ",
    "termTranslation": "back, behind",
    "ex": "後ろを向いて。",
    "exReading": "うしろ を むいて",
    "exTranslation": "Turn around.",
    "category": "Noun"
  },
  {
    "term": "午後",
    "termReading": "ごご",
    "termTranslation": "afternoon, p.m.",
    "ex": "明日の午後、お客様が来る。",
    "exReading": "あす の ごご おきゃくさま が くる",
    "exTranslation": "A guest is coming to visit tomorrow afternoon.",
    "category": "Noun"
  },
  {
    "term": "午前",
    "termReading": "ごぜん",
    "termTranslation": "morning, a.m.",
    "ex": "午前9時のニュースです。",
    "exReading": "ごぜん くじ の にゅーす です",
    "exTranslation": "And now for the 9.00 a.m. news.",
    "category": "Noun"
  },
  {
    "term": "朝",
    "termReading": "あさ",
    "termTranslation": "morning",
    "ex": "気持ちのいい朝です。",
    "exReading": "きもち の いい あさ です",
    "exTranslation": "It's a pleasant morning.",
    "category": "Noun"
  },
  {
    "term": "昼",
    "termReading": "ひる",
    "termTranslation": "daytime, midday",
    "ex": "私は昼のドラマを毎日見ます。",
    "exReading": "わたし は ひる の どらま を まいにち みます",
    "exTranslation": "I watch daytime dramas every day.",
    "category": "Noun"
  },
  {
    "term": "かなり",
    "termReading": "かなり",
    "termTranslation": "fairly, rather",
    "ex": "彼はかなり英語が上手です。",
    "exReading": "かれ は かなり えいご が じょうず です",
    "exTranslation": "He's pretty good at English.",
    "category": "Adverb"
  },
  {
    "term": "晩",
    "termReading": "ばん",
    "termTranslation": "evening (usually from sunset to bedtime)",
    "ex": "晩ご飯は食べましたか。",
    "exReading": "ばんごはん は たべました か",
    "exTranslation": "Have you had dinner?",
    "category": "Noun"
  },
  {
    "term": "今晩",
    "termReading": "こんばん",
    "termTranslation": "this evening, tonight",
    "ex": "今晩のパーティーは何時からですか。",
    "exReading": "こんばん の ぱーてぃー は なんじ から です か",
    "exTranslation": "From what time is the party tonight?",
    "category": "Noun"
  },
  {
    "term": "夜",
    "termReading": "よる",
    "termTranslation": "night (from sunset to sunrise)",
    "ex": "きのうの夜は家にいました。",
    "exReading": "きのう の よる は いえ に いました",
    "exTranslation": "I was at home last night.",
    "category": "Noun"
  },
  {
    "term": "食べる",
    "termReading": "たべる",
    "termTranslation": "eat",
    "ex": "昨日タイカレーを食べました。",
    "exReading": "きのう たいかれー を たべました",
    "exTranslation": "I had Thai curry yesterday.",
    "category": "Verb"
  },
  {
    "term": "飲む",
    "termReading": "のむ",
    "termTranslation": "drink",
    "ex": "友達とお酒を飲んでいます。",
    "exReading": "ともだち と おさけ を のんで います",
    "exTranslation": "I'm drinking sake with a friend.",
    "category": "Verb"
  },
  {
    "term": "ご飯",
    "termReading": "ごはん",
    "termTranslation": "cooked rice, meal",
    "ex": "私はパンよりご飯が好きだ。",
    "exReading": "わたし は ぱん より ごはん が すき だ",
    "exTranslation": "I prefer rice to bread.",
    "category": "Noun"
  },
  {
    "term": "買う",
    "termReading": "かう",
    "termTranslation": "buy",
    "ex": "郵便局で切手を買いました。",
    "exReading": "ゆうびんきょく で きって を かいました",
    "exTranslation": "I bought a stamp at the post office.",
    "category": "Verb"
  },
  {
    "term": "見る",
    "termReading": "みる",
    "termTranslation": "see, look at",
    "ex": "私は絵を見るのが好きです。",
    "exReading": "わたし は え を みる の が すき です",
    "exTranslation": "I like looking at pictures.",
    "category": "Verb"
  },
  {
    "term": "見せる",
    "termReading": "みせる",
    "termTranslation": "show",
    "ex": "その写真を見せてください。",
    "exReading": "その しゃしん を みせて ください",
    "exTranslation": "Please show me those pictures.",
    "category": "Verb"
  },
  {
    "term": "もっと",
    "termReading": "もっと",
    "termTranslation": "more",
    "ex": "もっと近くに来てください。",
    "exReading": "もっと ちかく に きて ください",
    "exTranslation": "Please come closer.",
    "category": "Adverb"
  },
  {
    "term": "見つける",
    "termReading": "みつける",
    "termTranslation": "find",
    "ex": "新しい仕事を見つけました。",
    "exReading": "あたらしい しごと を みつけました。",
    "exTranslation": "I found a new job.",
    "category": "Verb"
  },
  {
    "term": "見える",
    "termReading": "みえる",
    "termTranslation": "be visible",
    "ex": "ここから富士山がよく見えます。",
    "exReading": "ここ から ふじさん が よく みえます",
    "exTranslation": "You can see Mount Fuji well from here.",
    "category": "Verb"
  },
  {
    "term": "見つかる",
    "termReading": "みつかる",
    "termTranslation": "be found, be caught",
    "ex": "メガネが見つかりません。",
    "exReading": "めがね が みつかりません",
    "exTranslation": "I can't find my glasses.",
    "category": "Verb"
  },
  {
    "term": "言う",
    "termReading": "いう",
    "termTranslation": "say, tell",
    "ex": "上司が「一杯、飲もう。」と言った。",
    "exReading": "じょうし が いっぱい のもう と いった",
    "exTranslation": "My boss said: \"Let's have a drink.\"",
    "category": "Verb"
  },
  {
    "term": "話す",
    "termReading": "はなす",
    "termTranslation": "speak, tell",
    "ex": "母と電話で話しました。",
    "exReading": "はは と でんわ で はなしました",
    "exTranslation": "I spoke to my mother on the phone.",
    "category": "Verb"
  },
  {
    "term": "読む",
    "termReading": "よむ",
    "termTranslation": "read",
    "ex": "彼女は雑誌を読んでいます。",
    "exReading": "かのじょ は ざっし を よんで います",
    "exTranslation": "She's reading the magazine.",
    "category": "Verb"
  },
  {
    "term": "漢字",
    "termReading": "かんじ",
    "termTranslation": "character, kanji",
    "ex": "漢字は中国から来ました。",
    "exReading": "かんじ は ちゅうごく から きました",
    "exTranslation": "Kanji came from China.",
    "category": "Noun"
  },
  {
    "term": "書く",
    "termReading": "かく",
    "termTranslation": "write",
    "ex": "彼に手紙を書きました。",
    "exReading": "かれ に てがみ を かきました",
    "exTranslation": "I wrote him a letter.",
    "category": "Verb"
  },
  {
    "term": "覚える",
    "termReading": "おぼえる",
    "termTranslation": "memorize, learn",
    "ex": "妹は平仮名を全部覚えました。",
    "exReading": "いもうと は ひらがな を ぜんぶ おぼえました",
    "exTranslation": "My little sister learned all the hiragana.",
    "category": "Verb"
  },
  {
    "term": "そこ",
    "termReading": "そこ",
    "termTranslation": "there",
    "ex": "そこに座ってください。",
    "exReading": "そこ に すわって ください",
    "exTranslation": "Please sit there.",
    "category": "Pronoun"
  },
  {
    "term": "会う",
    "termReading": "あう",
    "termTranslation": "meet",
    "ex": "また会いましょう。",
    "exReading": "また あいましょう",
    "exTranslation": "Let's meet again.",
    "category": "Verb"
  },
  {
    "term": "仕事",
    "termReading": "しごと",
    "termTranslation": "work, job",
    "ex": "3月は仕事が忙しい。",
    "exReading": "さんがつ は しごと が いそがしい",
    "exTranslation": "I have a lot of work in March.",
    "category": "Noun"
  },
  {
    "term": "場合",
    "termReading": "ばあい",
    "termTranslation": "situation, case",
    "ex": "分からない場合は私に聞いてください。",
    "exReading": "わからない ばあい は わたし に きいて ください",
    "exTranslation": "Please ask me if you don't understand.",
    "category": "Noun"
  },
  {
    "term": "車",
    "termReading": "くるま",
    "termTranslation": "car, automobile",
    "ex": "弟が車を買った。",
    "exReading": "おとうと が くるま を かった",
    "exTranslation": "My younger brother bought a car.",
    "category": "Noun"
  },
  {
    "term": "電車",
    "termReading": "でんしゃ",
    "termTranslation": "train",
    "ex": "私は電車で通学しています。",
    "exReading": "わたし は でんしゃ で つうがく して います",
    "exTranslation": "I go to school by train.",
    "category": "Noun"
  },
  {
    "term": "駅",
    "termReading": "えき",
    "termTranslation": "railway station",
    "ex": "駅はどこですか。",
    "exReading": "えき は どこ です か",
    "exTranslation": "Where's the station?",
    "category": "Noun"
  },
  {
    "term": "道",
    "termReading": "みち",
    "termTranslation": "road, street",
    "ex": "この道を真っ直ぐ行くと駅です。",
    "exReading": "この みち を まっすぐ いく と えき です",
    "exTranslation": "Go straight along this road to get to the station.",
    "category": "Noun"
  },
  {
    "term": "他",
    "termReading": "ほか",
    "termTranslation": "other",
    "ex": "他に方法がありません。",
    "exReading": "ほか に ほうほう が ありません",
    "exTranslation": "There's no other way.",
    "category": "Noun"
  },
  {
    "term": "止める",
    "termReading": "やめる",
    "termTranslation": "stop, give up, abolish",
    "ex": "話すのを止めてください。",
    "exReading": "はなす の を やめて ください",
    "exTranslation": "Stop talking, please.",
    "category": "Verb"
  },
  {
    "term": "ここ",
    "termReading": "ここ",
    "termTranslation": "here",
    "ex": "ここに本があります。",
    "exReading": "ここ に ほん が あります",
    "exTranslation": "There's a book here.",
    "category": "Pronoun"
  },
  {
    "term": "歩く",
    "termReading": "あるく",
    "termTranslation": "walk, go on foot",
    "ex": "駅まで歩きましょう。",
    "exReading": "えき まで あるきましょう",
    "exTranslation": "Let's walk to the station.",
    "category": "Verb"
  },
  {
    "term": "走る",
    "termReading": "はしる",
    "termTranslation": "run",
    "ex": "彼は毎晩3キロ走っています。",
    "exReading": "かれ は まいばん さんきろ はしって います",
    "exTranslation": "Every night he runs three kilometers.",
    "category": "Verb"
  },
  {
    "term": "近く",
    "termReading": "ちかく",
    "termTranslation": "near, close to",
    "ex": "駅の近くで食事をした。",
    "exReading": "えき の ちかく で しょくじ を した",
    "exTranslation": "I ate near the station.",
    "category": "Adverb"
  },
  {
    "term": "近い",
    "termReading": "ちかい",
    "termTranslation": "near, soon",
    "ex": "駅はここから近いです。",
    "exReading": "えき は ここ から ちかい です",
    "exTranslation": "The station is near here.",
    "category": "Adjective"
  },
  {
    "term": "近く",
    "termReading": "ちかく",
    "termTranslation": "vicinity, nearby",
    "ex": "私の家は駅の近くです。",
    "exReading": "わたし の いえ は えき の ちかく です",
    "exTranslation": "My house is near the station.",
    "category": "Noun"
  },
  {
    "term": "遠い",
    "termReading": "とおい",
    "termTranslation": "far, distant",
    "ex": "家から学校までは遠いです。",
    "exReading": "いえ から がっこう まで は とおい です",
    "exTranslation": "It's a long way from my house to the school.",
    "category": "Adjective"
  },
  {
    "term": "長い",
    "termReading": "ながい",
    "termTranslation": "long",
    "ex": "彼女の髪はとても長い。",
    "exReading": "かのじょ の かみ は とても ながい",
    "exTranslation": "Her hair is very long.",
    "category": "Adjective"
  },
  {
    "term": "短い",
    "termReading": "みじかい",
    "termTranslation": "short, brief",
    "ex": "彼は足が短い。",
    "exReading": "かれ は あし が みじかい",
    "exTranslation": "His legs are short.",
    "category": "Adjective"
  },
  {
    "term": "広い",
    "termReading": "ひろい",
    "termTranslation": "wide, big",
    "ex": "彼の家はとても広い。",
    "exReading": "かれ の いえ は とても ひろい",
    "exTranslation": "His house is very big.",
    "category": "Adjective"
  },
  {
    "term": "もらう",
    "termReading": "もらう",
    "termTranslation": "receive",
    "ex": "彼女からプレゼントをもらいました。",
    "exReading": "かのじょ から ぷれぜんと を もらいました",
    "exTranslation": "I got a present from her.",
    "category": "Verb"
  },
  {
    "term": "全部",
    "termReading": "ぜんぶ",
    "termTranslation": "all, wholly",
    "ex": "それ、全部ください。",
    "exReading": "それ ぜんぶ ください",
    "exTranslation": "I'd like all of those, please.",
    "category": "Adverb"
  },
  {
    "term": "国",
    "termReading": "くに",
    "termTranslation": "country",
    "ex": "私の国について少しお話しましょう。",
    "exReading": "わたし の くに に ついて すこし おはなし しましょう",
    "exTranslation": "Let me tell you a bit about my country.",
    "category": "Noun"
  },
  {
    "term": "白い",
    "termReading": "しろい",
    "termTranslation": "white, blank",
    "ex": "彼は歯が白い。",
    "exReading": "かれ は は が しろい",
    "exTranslation": "His teeth are white.",
    "category": "Adjective"
  },
  {
    "term": "赤い",
    "termReading": "あかい",
    "termTranslation": "red",
    "ex": "赤いバラを買いました。",
    "exReading": "あかい ばら を かいました",
    "exTranslation": "I bought a red rose.",
    "category": "Adjective"
  },
  {
    "term": "部屋",
    "termReading": "へや",
    "termTranslation": "room",
    "ex": "私の部屋は2階にあります。",
    "exReading": "わたし の へや は にかい に あります",
    "exTranslation": "My room is on the second floor.",
    "category": "Noun"
  },
  {
    "term": "米",
    "termReading": "こめ",
    "termTranslation": "rice (grain)",
    "ex": "日本人はお米が大好きです。",
    "exReading": "にほんじん は おこめ が だいすき です",
    "exTranslation": "Japanese people love rice.",
    "category": "Noun"
  },
  {
    "term": "未だ",
    "termReading": "まだ",
    "termTranslation": "yet, more",
    "ex": "宿題は未だ終わっていません。",
    "exReading": "しゅくだい は まだ おわって いません",
    "exTranslation": "I haven't finished my homework yet.",
    "category": "Adverb"
  },
  {
    "term": "有る",
    "termReading": "ある",
    "termTranslation": "exist, possess",
    "ex": "私の机の上に書類がたくさん有ります。",
    "exReading": "わたし の つくえ の うえ に しょるい が たくさん あります",
    "exTranslation": "There are a lot of documents on my desk.",
    "category": "Verb"
  },
  {
    "term": "無い",
    "termReading": "ない",
    "termTranslation": "to not be",
    "ex": "ここには何も無い。",
    "exReading": "ここ に は なに も ない",
    "exTranslation": "There's nothing here.",
    "category": "Adjective"
  },
  {
    "term": "とても",
    "termReading": "とても",
    "termTranslation": "very",
    "ex": "この本はとてもおもしろい。",
    "exReading": "この ほん は とても おもしろい",
    "exTranslation": "This book is very interesting.",
    "category": "Adverb"
  },
  {
    "term": "作る",
    "termReading": "つくる",
    "termTranslation": "make, create",
    "ex": "今、朝ご飯を作っています。",
    "exReading": "いま あさごはん を つくって います",
    "exTranslation": "I'm making breakfast now.",
    "category": "Verb"
  },
  {
    "term": "使う",
    "termReading": "つかう",
    "termTranslation": "use",
    "ex": "このパソコンを使ってください。",
    "exReading": "この ぱそこん を つかって ください",
    "exTranslation": "Please use this computer.",
    "category": "Verb"
  },
  {
    "term": "消す",
    "termReading": "けす",
    "termTranslation": "switch off, turn off",
    "ex": "昼間は電気を消してください。",
    "exReading": "ひるま は でんき を けして ください",
    "exTranslation": "Please turn the lights off during the day.",
    "category": "Verb"
  },
  {
    "term": "売る",
    "termReading": "うる",
    "termTranslation": "sell",
    "ex": "彼は家を売った。",
    "exReading": "かれ は いえ を うった",
    "exTranslation": "He sold the house.",
    "category": "Verb"
  },
  {
    "term": "店",
    "termReading": "みせ",
    "termTranslation": "shop, store",
    "ex": "私はこの店によく来ます。",
    "exReading": "わたし は この みせ に よく きます",
    "exTranslation": "I come to this store quite often.",
    "category": "Noun"
  },
  {
    "term": "春",
    "termReading": "はる",
    "termTranslation": "spring",
    "ex": "今年の春は暖かいね。",
    "exReading": "ことし の はる は あたたかい ね",
    "exTranslation": "It's warm this spring, isn't it.",
    "category": "Noun"
  },
  {
    "term": "夏",
    "termReading": "なつ",
    "termTranslation": "summer",
    "ex": "私は夏が大好き。",
    "exReading": "わたし は なつ が だいすき",
    "exTranslation": "I just love summer.",
    "category": "Noun"
  },
  {
    "term": "秋",
    "termReading": "あき",
    "termTranslation": "autumn, fall",
    "ex": "彼女は秋に結婚します。",
    "exReading": "かのじょ は あき に けっこん します",
    "exTranslation": "She'll get married this fall.",
    "category": "Noun"
  },
  {
    "term": "冬",
    "termReading": "ふゆ",
    "termTranslation": "winter",
    "ex": "カナダの冬はとても寒いです。",
    "exReading": "かなだ の ふゆ は とても さむい です",
    "exTranslation": "Canada's winters are very cold.",
    "category": "Noun"
  },
  {
    "term": "みんな",
    "termReading": "みんな",
    "termTranslation": "all, everybody",
    "ex": "みんなにお菓子をあげましょう。",
    "exReading": "みんな に おかし を あげましょう",
    "exTranslation": "Let's give everyone some sweets.",
    "category": "Noun"
  },
  {
    "term": "暑い",
    "termReading": "あつい",
    "termTranslation": "hot, warm (temperature of the air)",
    "ex": "今日はとても暑い。",
    "exReading": "きょう は とても あつい",
    "exTranslation": "It's very hot today.",
    "category": "Adjective"
  },
  {
    "term": "熱い",
    "termReading": "あつい",
    "termTranslation": "hot (things, touch)",
    "ex": "このスープはとても熱い。",
    "exReading": "この すーぷ は とても あつい",
    "exTranslation": "This soup is very hot.",
    "category": "Adjective"
  },
  {
    "term": "寒い",
    "termReading": "さむい",
    "termTranslation": "cold (temperature of the air)",
    "ex": "この部屋は寒いです。",
    "exReading": "この へや は さむい です",
    "exTranslation": "It's cold in this room.",
    "category": "Adjective"
  },
  {
    "term": "暖かい",
    "termReading": "あたたかい",
    "termTranslation": "warm",
    "ex": "このコートはとても暖かい。",
    "exReading": "この こーと は とても あたたかい。",
    "exTranslation": "This coat is really warm.",
    "category": "Adjective"
  },
  {
    "term": "新しい",
    "termReading": "あたらしい",
    "termTranslation": "new",
    "ex": "彼の車は新しい。",
    "exReading": "かれ の くるま は あたらしい",
    "exTranslation": "His car is new.",
    "category": "Adjective"
  },
  {
    "term": "古い",
    "termReading": "ふるい",
    "termTranslation": "old",
    "ex": "私は古い車が好きです。",
    "exReading": "わたし は ふるい くるま が すき です",
    "exTranslation": "I like old cars.",
    "category": "Adjective"
  },
  {
    "term": "良い",
    "termReading": "いい",
    "termTranslation": "good",
    "ex": "彼女は良い友達です。",
    "exReading": "かのじょ は いい ともだち です",
    "exTranslation": "She's a good friend.",
    "category": "Adjective"
  },
  {
    "term": "悪い",
    "termReading": "わるい",
    "termTranslation": "bad",
    "ex": "たばこは体に悪い。",
    "exReading": "たばこ は からだ に わるい",
    "exTranslation": "Cigarettes are bad for your health.",
    "category": "Adjective"
  },
  {
    "term": "思う",
    "termReading": "おもう",
    "termTranslation": "think",
    "ex": "私もそう思います。",
    "exReading": "わたし も そう おもいます",
    "exTranslation": "I think so too.",
    "category": "Verb"
  },
  {
    "term": "いつも",
    "termReading": "いつも",
    "termTranslation": "always",
    "ex": "彼女はいつも元気だ。",
    "exReading": "かのじょ は いつも げんき だ",
    "exTranslation": "She's always full of energy.",
    "category": "Adverb"
  },
  {
    "term": "忘れる",
    "termReading": "わすれる",
    "termTranslation": "forget, leave behind",
    "ex": "約束を忘れないでください。",
    "exReading": "やくそく を わすれない で ください",
    "exTranslation": "Please don't forget your promise.",
    "category": "Verb"
  },
  {
    "term": "考える",
    "termReading": "かんがえる",
    "termTranslation": "think, consider",
    "ex": "よく考えてください。",
    "exReading": "よく かんがえて ください",
    "exTranslation": "Please think carefully.",
    "category": "Verb"
  },
  {
    "term": "決める",
    "termReading": "きめる",
    "termTranslation": "decide, agree upon",
    "ex": "帰国することに決めました。",
    "exReading": "きこく する こと に きめました",
    "exTranslation": "I've decided to go back to my country.",
    "category": "Verb"
  },
  {
    "term": "決まる",
    "termReading": "きまる",
    "termTranslation": "be decided",
    "ex": "旅行の日程が決まりました。",
    "exReading": "りょこう の にってい が きまりました",
    "exTranslation": "I've decided on my travel schedule.",
    "category": "Verb"
  },
  {
    "term": "知る",
    "termReading": "しる",
    "termTranslation": "know",
    "ex": "誰もその話を知らない。",
    "exReading": "だれ も その はなし を しらない",
    "exTranslation": "No one knows that story.",
    "category": "Verb"
  },
  {
    "term": "一番",
    "termReading": "いちばん",
    "termTranslation": "most",
    "ex": "彼はクラスで一番背が高い。",
    "exReading": "かれ は くらす で いちばん せ が たかい",
    "exTranslation": "He's the tallest in class.",
    "category": "Adverb"
  },
  {
    "term": "住む",
    "termReading": "すむ",
    "termTranslation": "live, reside",
    "ex": "彼は会社の近くに住んでいる。",
    "exReading": "かれ は かいしゃ の ちかく に すんで いる",
    "exTranslation": "He lives near his office.",
    "category": "Verb"
  },
  {
    "term": "名前",
    "termReading": "なまえ",
    "termTranslation": "name",
    "ex": "あなたの名前を教えてください。",
    "exReading": "あなた の なまえ を おしえて ください",
    "exTranslation": "Please tell me your name.",
    "category": "Noun"
  },
  {
    "term": "食べ物",
    "termReading": "たべもの",
    "termTranslation": "food",
    "ex": "日本の食べ物はとても美味しいです。",
    "exReading": "にっぽん の たべもの は とても おいしい です",
    "exTranslation": "Japanese food is very delicious.",
    "category": "Noun"
  },
  {
    "term": "いつ",
    "termReading": "いつ",
    "termTranslation": "when",
    "ex": "彼女はいつ来ますか。",
    "exReading": "かのじょ は いつ きます か",
    "exTranslation": "When is she coming?",
    "category": "Noun"
  },
  {
    "term": "飲み物",
    "termReading": "のみもの",
    "termTranslation": "beverage, drink",
    "ex": "何か飲み物が欲しいな。",
    "exReading": "なに か のみもの が ほしい な 。",
    "exTranslation": "I could really use something to drink.",
    "category": "Noun"
  },
  {
    "term": "重い",
    "termReading": "おもい",
    "termTranslation": "heavy",
    "ex": "このかばんは重いです。",
    "exReading": "この かばん は おもい です",
    "exTranslation": "This bag is heavy.",
    "category": "Adjective"
  },
  {
    "term": "軽い",
    "termReading": "かるい",
    "termTranslation": "light, light weight",
    "ex": "この靴はとても軽い。",
    "exReading": "この くつ は とても かるい",
    "exTranslation": "These shoes are very light.",
    "category": "Adjective"
  },
  {
    "term": "送る",
    "termReading": "おくる",
    "termTranslation": "send",
    "ex": "彼の家に荷物を送りました。",
    "exReading": "かれ の いえ に にもつ を おくりました",
    "exTranslation": "I sent a package to his house.",
    "category": "Verb"
  },
  {
    "term": "取る",
    "termReading": "とる",
    "termTranslation": "take, get",
    "ex": "テストでいい点を取った。",
    "exReading": "てすと で いい てん を とった",
    "exTranslation": "I got a good grade on the test.",
    "category": "Verb"
  },
  {
    "term": "待つ",
    "termReading": "まつ",
    "termTranslation": "wait, wait for",
    "ex": "あなたが来るのを待っています。",
    "exReading": "あなた が くる の を まって います",
    "exTranslation": "I am waiting for you to come.",
    "category": "Verb"
  },
  {
    "term": "持つ",
    "termReading": "もつ",
    "termTranslation": "have, possess",
    "ex": "私は車を持っています。",
    "exReading": "わたし は くるま を もって います",
    "exTranslation": "I have a car.",
    "category": "Verb"
  },
  {
    "term": "気持ち",
    "termReading": "きもち",
    "termTranslation": "feeling, sensation",
    "ex": "彼の気持ちが分からない。",
    "exReading": "かれ の きもち が わからない",
    "exTranslation": "I don't understand his feelings.",
    "category": "Noun"
  },
  {
    "term": "生きる",
    "termReading": "いきる",
    "termTranslation": "live (one's life)",
    "ex": "皆一生懸命生きている。",
    "exReading": "みんな いっしょうけんめい いきて いる",
    "exTranslation": "They're all living their lives the best way that they can.",
    "category": "Verb"
  },
  {
    "term": "どちら",
    "termReading": "どちら",
    "termTranslation": "which",
    "ex": "肉と魚とどちらが好きですか。",
    "exReading": "にく と さかな と どちら が すき です か",
    "exTranslation": "Which do you prefer, meat or fish?",
    "category": "Noun"
  },
  {
    "term": "先生",
    "termReading": "せんせい",
    "termTranslation": "teacher",
    "ex": "私は日本語の先生になりたいです。",
    "exReading": "わたし は にほんご の せんせい に なりたい です",
    "exTranslation": "I want to become a Japanese teacher.",
    "category": "Noun"
  },
  {
    "term": "大学",
    "termReading": "だいがく",
    "termTranslation": "university, college",
    "ex": "大学に行ってもっと勉強したいです。",
    "exReading": "だいがく に いって もっと べんきょう したい です",
    "exTranslation": "I want to go to college and study more.",
    "category": "Noun"
  },
  {
    "term": "学生",
    "termReading": "がくせい",
    "termTranslation": "student",
    "ex": "彼は真面目な学生です。",
    "exReading": "かれ は まじめ な がくせい です",
    "exTranslation": "He's a serious student.",
    "category": "Noun"
  },
  {
    "term": "大学生",
    "termReading": "だいがくせい",
    "termTranslation": "university student",
    "ex": "姉は大学生です。",
    "exReading": "あね は だいがくせい です",
    "exTranslation": "My big sister's a college student.",
    "category": "Noun"
  },
  {
    "term": "学校",
    "termReading": "がっこう",
    "termTranslation": "school",
    "ex": "学校は8時半に始まります。",
    "exReading": "がっこう は はちじはん に はじまります",
    "exTranslation": "School starts at 8:30.",
    "category": "Noun"
  },
  {
    "term": "高校生",
    "termReading": "こうこうせい",
    "termTranslation": "high school student",
    "ex": "私の弟は高校生です。",
    "exReading": "わたし の おとうと は こうこうせい です",
    "exTranslation": "My little brother's a high school student.",
    "category": "Noun"
  },
  {
    "term": "教える",
    "termReading": "おしえる",
    "termTranslation": "teach, tell",
    "ex": "彼は数学を教えています。",
    "exReading": "かれ は すうがく を おしえて います",
    "exTranslation": "He teaches mathematics.",
    "category": "Verb"
  },
  {
    "term": "勉強",
    "termReading": "べんきょう",
    "termTranslation": "study",
    "ex": "私は日本語を勉強しています。",
    "exReading": "わたし は にほんご を べんきょう して います",
    "exTranslation": "I'm studying Japanese.",
    "category": "Verbal Noun"
  },
  {
    "term": "強い",
    "termReading": "つよい",
    "termTranslation": "strong",
    "ex": "今日は風が強い。",
    "exReading": "きょう は かぜ が つよい",
    "exTranslation": "There's a strong wind today.",
    "category": "Adjective"
  },
  {
    "term": "どれ",
    "termReading": "どれ",
    "termTranslation": "what, which",
    "ex": "この中でどれが好きですか。",
    "exReading": "この なか で どれ が すき です か",
    "exTranslation": "Which of these do you like?",
    "category": "Noun"
  },
  {
    "term": "弱い",
    "termReading": "よわい",
    "termTranslation": "weak",
    "ex": "その子は体が少し弱い。",
    "exReading": "その こ は からだ が すこし よわい",
    "exTranslation": "That child has a somewhat weak constitution.",
    "category": "Adjective"
  },
  {
    "term": "引く",
    "termReading": "ひく",
    "termTranslation": "draw, pull",
    "ex": "このドアは引いてください。",
    "exReading": "この どあ は ひいて ください",
    "exTranslation": "Please pull this door open.",
    "category": "Verb"
  },
  {
    "term": "質問",
    "termReading": "しつもん",
    "termTranslation": "question",
    "ex": "質問のある方はどうぞ。",
    "exReading": "しつもん の ある かた は どうぞ",
    "exTranslation": "Please feel free to ask any questions.",
    "category": "Verbal Noun"
  },
  {
    "term": "難しい",
    "termReading": "むずかしい",
    "termTranslation": "difficult",
    "ex": "この本は難しいですね。",
    "exReading": "この ほん は むずかしい です ね",
    "exTranslation": "This book is difficult.",
    "category": "Adjective"
  },
  {
    "term": "数",
    "termReading": "かず",
    "termTranslation": "number",
    "ex": "グラスの数が足りません。",
    "exReading": "ぐらす の かず が たりません",
    "exTranslation": "There are not enough glasses.",
    "category": "Noun"
  },
  {
    "term": "勝つ",
    "termReading": "かつ",
    "termTranslation": "win",
    "ex": "今日はヤンキースが勝った。",
    "exReading": "きょう は やんきーす が かった",
    "exTranslation": "The Yankees won today.",
    "category": "Verb"
  },
  {
    "term": "負ける",
    "termReading": "まける",
    "termTranslation": "lose",
    "ex": "私たちのチームはその試合で負けた。",
    "exReading": "わたしたち の ちーむ は その しあい で まけた",
    "exTranslation": "Our team lost the game.",
    "category": "Verb"
  },
  {
    "term": "本当に",
    "termReading": "ほんとうに",
    "termTranslation": "really, truly",
    "ex": "あなたが本当に好きです。",
    "exReading": "あなた が ほんとうに すき です",
    "exTranslation": "I really like you.",
    "category": "Adverb"
  },
  {
    "term": "要る",
    "termReading": "いる",
    "termTranslation": "need, require",
    "ex": "予約は要りません。",
    "exReading": "よやく は いりません",
    "exTranslation": "Reservations are not required.",
    "category": "Verb"
  },
  {
    "term": "ドア",
    "termReading": "ドア",
    "termTranslation": "door",
    "ex": "ドアを開けてください。",
    "exReading": "どあ を あけて ください",
    "exTranslation": "Please open the door.",
    "category": "Noun"
  },
  {
    "term": "時計",
    "termReading": "とけい",
    "termTranslation": "clock, watch",
    "ex": "時計を見たらちょうど3時だった。",
    "exReading": "とけい を みたら ちょうど さんじ だった",
    "exTranslation": "I looked at the clock and it was exactly three.",
    "category": "Noun"
  },
  {
    "term": "払う",
    "termReading": "はらう",
    "termTranslation": "pay",
    "ex": "私が払いましょう。",
    "exReading": "わたし が はらいましょう",
    "exTranslation": "Let me pay for that.",
    "category": "Verb"
  },
  {
    "term": "切る",
    "termReading": "きる",
    "termTranslation": "cut",
    "ex": "この紙を半分に切ってください。",
    "exReading": "この かみ を はんぶん に きって ください",
    "exTranslation": "Please cut this paper in half.",
    "category": "Verb"
  },
  {
    "term": "変える",
    "termReading": "かえる",
    "termTranslation": "change",
    "ex": "旅行の日程を変えました。",
    "exReading": "りょこう の にってい を かえました",
    "exTranslation": "I changed our travel schedule.",
    "category": "Verb"
  },
  {
    "term": "乗る",
    "termReading": "のる",
    "termTranslation": "ride, take",
    "ex": "駅からはタクシーに乗ってください。",
    "exReading": "えき からは たくしー に のって ください",
    "exTranslation": "Please take a taxi from the station.",
    "category": "Verb"
  },
  {
    "term": "着る",
    "termReading": "きる",
    "termTranslation": "wear, put on",
    "ex": "今日はスーツを着ています。",
    "exReading": "きょう は すーつ を きて います",
    "exTranslation": "I'm wearing a suit today.",
    "category": "Verb"
  },
  {
    "term": "立つ",
    "termReading": "たつ",
    "termTranslation": "stand, rise",
    "ex": "彼はステージに立った。",
    "exReading": "かれ は すてーじ に たった",
    "exTranslation": "He stood on stage.",
    "category": "Verb"
  },
  {
    "term": "座る",
    "termReading": "すわる",
    "termTranslation": "sit, sit down",
    "ex": "私は窓側の席に座った。",
    "exReading": "わたし は まどがわ の せき に すわった",
    "exTranslation": "I sat in a window seat.",
    "category": "Verb"
  },
  {
    "term": "次",
    "termReading": "つぎ",
    "termTranslation": "next",
    "ex": "次はいつ会いましょうか。",
    "exReading": "つぎ は いつ あいましょう か",
    "exTranslation": "When shall we meet again?",
    "category": "Noun"
  },
  {
    "term": "しゃべる",
    "termReading": "しゃべる",
    "termTranslation": "chat, talk",
    "ex": "彼女はよくしゃべるね。",
    "exReading": "かのじょ は よく しゃべる ね",
    "exTranslation": "She really likes to chat, doesn't she?",
    "category": "Verb"
  },
  {
    "term": "動く",
    "termReading": "うごく",
    "termTranslation": "move",
    "ex": "動かないで。",
    "exReading": "うごかない で",
    "exTranslation": "Freeze!",
    "category": "Verb"
  },
  {
    "term": "働く",
    "termReading": "はたらく",
    "termTranslation": "work",
    "ex": "姉は銀行で働いています。",
    "exReading": "あね は ぎんこう で はたらいています。",
    "exTranslation": "My big sister works at a bank.",
    "category": "Verb"
  },
  {
    "term": "早い",
    "termReading": "はやい",
    "termTranslation": "early",
    "ex": "まだ学校へ行くには早い時間です。",
    "exReading": "まだ がっこう へ いく に は はやい じかん です",
    "exTranslation": "It's still too early to go to school.",
    "category": "Adjective"
  },
  {
    "term": "速い",
    "termReading": "はやい",
    "termTranslation": "fast",
    "ex": "彼は走るのが速い。",
    "exReading": "かれ は はしる の が はやい",
    "exTranslation": "He runs fast.",
    "category": "Adjective"
  },
  {
    "term": "遅い",
    "termReading": "おそい",
    "termTranslation": "slow, late",
    "ex": "前の車はとても遅い。",
    "exReading": "まえ の くるま は とても おそい",
    "exTranslation": "The car in front is very slow.",
    "category": "Adjective"
  },
  {
    "term": "始める",
    "termReading": "はじめる",
    "termTranslation": "begin, start",
    "ex": "テストを始めてください。",
    "exReading": "てすと を はじめて ください",
    "exTranslation": "Please start the test.",
    "category": "Verb"
  },
  {
    "term": "始まる",
    "termReading": "はじまる",
    "termTranslation": "begin",
    "ex": "新しい仕事が始まりました。",
    "exReading": "あたらしい しごと が はじまりました",
    "exTranslation": "My new job started.",
    "category": "Verb"
  },
  {
    "term": "終わる",
    "termReading": "おわる",
    "termTranslation": "finish, end",
    "ex": "会議は4時に終わります。",
    "exReading": "かいぎ は よじ に おわります",
    "exTranslation": "The conference ends at four o'clock.",
    "category": "Verb"
  },
  {
    "term": "終わり",
    "termReading": "おわり",
    "termTranslation": "end, conclusion",
    "ex": "夏休みももう終わりだ。",
    "exReading": "なつやすみ も もう おわり だ",
    "exTranslation": "Summer vacation's already over.",
    "category": "Noun"
  },
  {
    "term": "テーブル",
    "termReading": "テーブル",
    "termTranslation": "table",
    "ex": "新しいテーブルを買いました。",
    "exReading": "あたらしい てーぶる を かいました",
    "exTranslation": "I bought a new table.",
    "category": "Noun"
  },
  {
    "term": "去年",
    "termReading": "きょねん",
    "termTranslation": "last year",
    "ex": "私は去年フランスへ行った。",
    "exReading": "わたし は きょねん ふらんす へ いった",
    "exTranslation": "I went to France last year.",
    "category": "Noun"
  },
  {
    "term": "紙",
    "termReading": "かみ",
    "termTranslation": "paper",
    "ex": "紙と鉛筆はありますか。",
    "exReading": "かみ と えんぴつ は あります か",
    "exTranslation": "Do you have paper and pencil?",
    "category": "Noun"
  },
  {
    "term": "楽しむ",
    "termReading": "たのしむ",
    "termTranslation": "enjoy",
    "ex": "今日は一人の時間を楽しみたい。",
    "exReading": "きょう は ひとり の じかん を たのしみたい",
    "exTranslation": "Today I would like to enjoy some time to myself.",
    "category": "Verb"
  },
  {
    "term": "楽しい",
    "termReading": "たのしい",
    "termTranslation": "fun, enjoyable",
    "ex": "彼はとても楽しい人です。",
    "exReading": "かれ は とても たのしい ひと です",
    "exTranslation": "He's very fun to be with.",
    "category": "Adjective"
  },
  {
    "term": "歌う",
    "termReading": "うたう",
    "termTranslation": "sing",
    "ex": "私たちは大きな声で歌いました。",
    "exReading": "わたしたち は おおき な こえ で うたいました",
    "exTranslation": "We sang loudly.",
    "category": "Verb"
  },
  {
    "term": "欲しい",
    "termReading": "ほしい",
    "termTranslation": "want, desire",
    "ex": "僕は新しい靴が欲しいです。",
    "exReading": "ぼく は あたらしい くつ が ほしい です",
    "exTranslation": "I want new shoes.",
    "category": "Adjective"
  },
  {
    "term": "色",
    "termReading": "いろ",
    "termTranslation": "color",
    "ex": "すてきな色のセーターですね。",
    "exReading": "すてき な いろ の せーたー です ね",
    "exTranslation": "That sweater is a wonderful color.",
    "category": "Noun"
  },
  {
    "term": "茶色",
    "termReading": "ちゃいろ",
    "termTranslation": "light brown",
    "ex": "彼女は茶色の靴を履いています。",
    "exReading": "かのじょ は ちゃいろ の くつ を はいて います",
    "exTranslation": "She's wearing brown shoes.",
    "category": "Noun"
  },
  {
    "term": "直ぐ",
    "termReading": "すぐ",
    "termTranslation": "at once, soon",
    "ex": "直ぐ行きます。",
    "exReading": "すぐ いきます",
    "exTranslation": "I'll go right away.",
    "category": "Adverb"
  },
  {
    "term": "ゲーム",
    "termReading": "ゲーム",
    "termTranslation": "game",
    "ex": "私たちはビデオゲームをした。",
    "exReading": "わたしたち は びでおげーむ を した",
    "exTranslation": "We played a video game.",
    "category": "Noun"
  },
  {
    "term": "書き直す",
    "termReading": "かきなおす",
    "termTranslation": "rewrite",
    "ex": "この書類を書き直してください。",
    "exReading": "この しょるい を かきなおして ください",
    "exTranslation": "Please rewrite this document.",
    "category": "Verb"
  },
  {
    "term": "直る",
    "termReading": "なおる",
    "termTranslation": "be repaired, return to normal",
    "ex": "クーラーはまだ直りません。",
    "exReading": "くーらー は まだ なおりません",
    "exTranslation": "The air conditioner hasn't been repaired yet.",
    "category": "Verb"
  },
  {
    "term": "線",
    "termReading": "せん",
    "termTranslation": "line",
    "ex": "赤い線を2本引いて下さい。",
    "exReading": "あかい せん を に ほん ひいて ください",
    "exTranslation": "Please draw two red lines.",
    "category": "Noun"
  },
  {
    "term": "曲がる",
    "termReading": "まがる",
    "termTranslation": "turn",
    "ex": "そこを左に曲がってください。",
    "exReading": "そこ を ひだり に まがって ください",
    "exTranslation": "Please turn left there.",
    "category": "Verb"
  },
  {
    "term": "同じ",
    "termReading": "おなじ",
    "termTranslation": "same, identical",
    "ex": "彼の日本語のレベルは私と同じ位だ。",
    "exReading": "かれ の にほんご の れべる は わたし と おなじ くらい だ",
    "exTranslation": "His Japanese level is about the same as mine.",
    "category": "Adjectival Noun"
  },
  {
    "term": "違う",
    "termReading": "ちがう",
    "termTranslation": "differ, be wrong",
    "ex": "答えが違います。",
    "exReading": "こたえ が ちがいます",
    "exTranslation": "That's the wrong answer.",
    "category": "Verb"
  },
  {
    "term": "図書館",
    "termReading": "としょかん",
    "termTranslation": "library",
    "ex": "図書館で料理の本を借りた。",
    "exReading": "としょかん で りょうり の ほん を かりた",
    "exTranslation": "I borrowed a cookbook from the library.",
    "category": "Noun"
  },
  {
    "term": "泊まる",
    "termReading": "とまる",
    "termTranslation": "stay overnight",
    "ex": "今日はこのホテルに泊まります。",
    "exReading": "きょう は この ほてる に とまります",
    "exTranslation": "I'm staying at this hotel today.",
    "category": "Verb"
  },
  {
    "term": "遊ぶ",
    "termReading": "あそぶ",
    "termTranslation": "play",
    "ex": "子供たちが公園で遊んでいる。",
    "exReading": "こどもたち が こうえん で あそんで いる",
    "exTranslation": "The children are playing in the park.",
    "category": "Verb"
  },
  {
    "term": "どうして",
    "termReading": "どうして",
    "termTranslation": "why",
    "ex": "どうして泣いているの。",
    "exReading": "どうして ないて いる の",
    "exTranslation": "Why are you crying?",
    "category": "Adverb"
  },
  {
    "term": "服",
    "termReading": "ふく",
    "termTranslation": "clothes",
    "ex": "昨日、新しい服を買った。",
    "exReading": "きのう あたらしい ふく を かった",
    "exTranslation": "I bought some new clothes yesterday.",
    "category": "Noun"
  },
  {
    "term": "お父さん",
    "termReading": "おとうさん",
    "termTranslation": "father",
    "ex": "お父さんは会社員です。",
    "exReading": "おとうさん は かいしゃいん です",
    "exTranslation": "My father's a company employee.",
    "category": "Noun"
  },
  {
    "term": "父",
    "termReading": "ちち",
    "termTranslation": "(speaker's)father",
    "ex": "私は父が大好きです。",
    "exReading": "わたし は ちち が だいすき です",
    "exTranslation": "I love my father.",
    "category": "Noun"
  },
  {
    "term": "お母さん",
    "termReading": "おかあさん",
    "termTranslation": "mother",
    "ex": "お母さんによろしくお伝えください。",
    "exReading": "おかあさん に よろしく おつたえ ください",
    "exTranslation": "Please give my best to your mother.",
    "category": "Noun"
  },
  {
    "term": "母",
    "termReading": "はは",
    "termTranslation": "(speaker's)mother",
    "ex": "昨日、母と話をしました。",
    "exReading": "きのう はは と はなし を しました",
    "exTranslation": "I talked to my mother yesterday.",
    "category": "Noun"
  },
  {
    "term": "親",
    "termReading": "おや",
    "termTranslation": "parent",
    "ex": "親の愛は有り難い。",
    "exReading": "おや の あい は ありがたい",
    "exTranslation": "We're grateful for our parents' love.",
    "category": "Noun"
  },
  {
    "term": "姉",
    "termReading": "あね",
    "termTranslation": "older sister",
    "ex": "姉は大学生です。",
    "exReading": "あね は だいがくせい です",
    "exTranslation": "My big sister's a college student.",
    "category": "Noun"
  },
  {
    "term": "お姉さん",
    "termReading": "おねえさん",
    "termTranslation": "older sister",
    "ex": "昨日、あなたのお姉さんに会ったよ。",
    "exReading": "きのう あなた の おねえさん に あった よ",
    "exTranslation": "I met your older sister yesterday.",
    "category": "Noun"
  },
  {
    "term": "妹",
    "termReading": "いもうと",
    "termTranslation": "younger sister",
    "ex": "私の妹は小学生です。",
    "exReading": "わたし の いもうと は しょうがくせい です",
    "exTranslation": "My little sister goes to elementary school.",
    "category": "Noun"
  },
  {
    "term": "おなか",
    "termReading": "おなか",
    "termTranslation": "stomach",
    "ex": "おなかが空きました。",
    "exReading": "おなか が すきました",
    "exTranslation": "I'm hungry.",
    "category": "Noun"
  },
  {
    "term": "兄",
    "termReading": "あに",
    "termTranslation": "older brother",
    "ex": "兄は水泳が得意です。",
    "exReading": "あに は すいえい が とくい です",
    "exTranslation": "My big brother is good at swimming.",
    "category": "Noun"
  },
  {
    "term": "お兄さん",
    "termReading": "おにいさん",
    "termTranslation": "older brother",
    "ex": "あなたのお兄さんは何歳?",
    "exReading": "あなた の おにいさん は なんさい",
    "exTranslation": "How old's your big brother?",
    "category": "Noun"
  },
  {
    "term": "弟",
    "termReading": "おとうと",
    "termTranslation": "younger brother",
    "ex": "弟は野球が好きです。",
    "exReading": "おとうと は やきゅう が すき です",
    "exTranslation": "My little brother likes baseball.",
    "category": "Noun"
  },
  {
    "term": "娘",
    "termReading": "むすめ",
    "termTranslation": "daughter, girl",
    "ex": "私の娘はアメリカにいます。",
    "exReading": "わたし の むすめ は あめりか に います",
    "exTranslation": "My daughter is in the United States.",
    "category": "Noun"
  },
  {
    "term": "息子",
    "termReading": "むすこ",
    "termTranslation": "son",
    "ex": "うちの息子は大学1年生です。",
    "exReading": "うち の むすこ は だいがく いちねんせい です",
    "exTranslation": "My son's a college freshman.",
    "category": "Noun"
  },
  {
    "term": "若い",
    "termReading": "わかい",
    "termTranslation": "young",
    "ex": "彼はまだ若いです。",
    "exReading": "かれ は まだ わかい です",
    "exTranslation": "He's still young.",
    "category": "Adjective"
  },
  {
    "term": "彼女",
    "termReading": "かのじょ",
    "termTranslation": "she, one's girlfriend",
    "ex": "彼女はOLです。",
    "exReading": "かのじょ は おーえる です",
    "exTranslation": "She's an office worker.",
    "category": "Pronoun"
  },
  {
    "term": "彼",
    "termReading": "かれ",
    "termTranslation": "he, one's boyfriend",
    "ex": "彼は私の上司です。",
    "exReading": "かれ は わたし の じょうし です",
    "exTranslation": "He's my senior.",
    "category": "Noun"
  },
  {
    "term": "結婚",
    "termReading": "けっこん",
    "termTranslation": "marriage",
    "ex": "彼女は来月結婚します。",
    "exReading": "かのじょ は らいげつ けっこん します",
    "exTranslation": "She's getting married next month.",
    "category": "Verbal Noun"
  },
  {
    "term": "ミーティング",
    "termReading": "ミーティング",
    "termTranslation": "meeting",
    "ex": "朝9時からミーティングが始まった。",
    "exReading": "あさ 9 じ から みーてぃんぐ が はじまった",
    "exTranslation": "The meeting started at 9 a.m.",
    "category": "Noun"
  },
  {
    "term": "自転車",
    "termReading": "じてんしゃ",
    "termTranslation": "bicycle",
    "ex": "毎日、駅まで自転車で行きます。",
    "exReading": "まいにち えき まで じてんしゃ で いきます",
    "exTranslation": "I go to the station by bike every day.",
    "category": "Noun"
  },
  {
    "term": "自分",
    "termReading": "じぶん",
    "termTranslation": "self, oneself",
    "ex": "宿題は自分でやりなさい。",
    "exReading": "しゅくだい は じぶん で やりなさい",
    "exTranslation": "Do your homework by yourself.",
    "category": "Noun"
  },
  {
    "term": "ご主人",
    "termReading": "ごしゅじん",
    "termTranslation": "(your) husband",
    "ex": "ご主人はお元気ですか。",
    "exReading": "ごしゅじん は おげんき です か",
    "exTranslation": "How is your husband?",
    "category": "Noun"
  },
  {
    "term": "答える",
    "termReading": "こたえる",
    "termTranslation": "answer, reply",
    "ex": "私の質問に答えてください。",
    "exReading": "わたし の しつもん に こたえて ください",
    "exTranslation": "Please answer my question.",
    "category": "Verb"
  },
  {
    "term": "別",
    "termReading": "べつ",
    "termTranslation": "another, different",
    "ex": "別の本も見せてください。",
    "exReading": "べつ の ほん も みせて ください",
    "exTranslation": "Please show me another book.",
    "category": "Adjectival Noun"
  },
  {
    "term": "病気",
    "termReading": "びょうき",
    "termTranslation": "illness",
    "ex": "祖父が病気になった。",
    "exReading": "そふ が びょうき に なった",
    "exTranslation": "My grandfather got sick.",
    "category": "Verbal Noun"
  },
  {
    "term": "死ぬ",
    "termReading": "しぬ",
    "termTranslation": "die",
    "ex": "犬が病気で死にました。",
    "exReading": "いぬ が びょうき で しにました。",
    "exTranslation": "Our dog got sick and died.",
    "category": "Verb"
  },
  {
    "term": "痛い",
    "termReading": "いたい",
    "termTranslation": "painful",
    "ex": "今日は頭が痛いです。",
    "exReading": "きょう は あたま が いたい です",
    "exTranslation": "I have a headache today.",
    "category": "Adjective"
  },
  {
    "term": "酒",
    "termReading": "さけ",
    "termTranslation": "alcoholic drink, Japanese rice wine",
    "ex": "彼女は酒に強い。",
    "exReading": "かのじょ は さけ に つよい",
    "exTranslation": "She holds her liquor well.",
    "category": "Noun"
  },
  {
    "term": "つける",
    "termReading": "つける",
    "termTranslation": "switch on",
    "ex": "電気をつけてください。",
    "exReading": "でんき を つけて ください",
    "exTranslation": "Please turn on the light.",
    "category": "Verb"
  },
  {
    "term": "一杯",
    "termReading": "いっぱい",
    "termTranslation": "full",
    "ex": "プールは人で一杯です。",
    "exReading": "ぷーる は ひと で いっぱい です",
    "exTranslation": "The pool is packed.",
    "category": "Adverb"
  },
  {
    "term": "飛ぶ",
    "termReading": "とぶ",
    "termTranslation": "fly",
    "ex": "鳥が飛んでいます。",
    "exReading": "とり が とんで います",
    "exTranslation": "A bird is flying.",
    "category": "Verb"
  },
  {
    "term": "飛行機",
    "termReading": "ひこうき",
    "termTranslation": "airplane",
    "ex": "息子は飛行機のおもちゃが好きです。",
    "exReading": "むすこ は ひこうき の おもちゃ が すき です",
    "exTranslation": "My son likes toy airplanes.",
    "category": "Noun"
  },
  {
    "term": "お願い",
    "termReading": "おねがい",
    "termTranslation": "favor",
    "ex": "お願いがあります。",
    "exReading": "おねがい が あります",
    "exTranslation": "I have a favor to ask.",
    "category": "Verbal Noun"
  },
  {
    "term": "続く",
    "termReading": "つづく",
    "termTranslation": "continue, follow",
    "ex": "工事は3月まで続きます。",
    "exReading": "こうじ は さんがつ まで つづきます",
    "exTranslation": "Construction will continue until March.",
    "category": "Verb"
  },
  {
    "term": "日記",
    "termReading": "にっき",
    "termTranslation": "diary",
    "ex": "私は毎日、日記を付けています。",
    "exReading": "わたし は まいにち にっき を つけて います",
    "exTranslation": "I keep a diary every day.",
    "category": "Noun"
  },
  {
    "term": "首",
    "termReading": "くび",
    "termTranslation": "neck",
    "ex": "きりんの首は長い。",
    "exReading": "きりん の くび は ながい",
    "exTranslation": "Giraffes' necks are long.",
    "category": "Noun"
  },
  {
    "term": "頭",
    "termReading": "あたま",
    "termTranslation": "head",
    "ex": "今朝から頭が痛い。",
    "exReading": "けさ から あたま が いたい",
    "exTranslation": "I've had a headache since this morning.",
    "category": "Noun"
  },
  {
    "term": "顔",
    "termReading": "かお",
    "termTranslation": "face",
    "ex": "彼はタオルで顔を拭きました。",
    "exReading": "かれ は たおる で かお を ふきました",
    "exTranslation": "He wiped his face with a towel.",
    "category": "Noun"
  },
  {
    "term": "あれ",
    "termReading": "あれ",
    "termTranslation": "that over there",
    "ex": "あれは何ですか。",
    "exReading": "あれ は なん です か",
    "exTranslation": "What's that?",
    "category": "Noun"
  },
  {
    "term": "感じる",
    "termReading": "かんじる",
    "termTranslation": "feel, sense",
    "ex": "膝に痛みを感じます。",
    "exReading": "ひざ に いたみ を かんじます",
    "exTranslation": "I feel a pain in my knee.",
    "category": "Verb"
  },
  {
    "term": "探す",
    "termReading": "さがす",
    "termTranslation": "search for, look for",
    "ex": "彼は郵便局を探していました。",
    "exReading": "かれ は ゆうびんきょく を さがして いました",
    "exTranslation": "He was looking for the post office.",
    "category": "Verb"
  },
  {
    "term": "落ちる",
    "termReading": "おちる",
    "termTranslation": "fall, sink",
    "ex": "猿も木から落ちる。",
    "exReading": "さる も き から おちる",
    "exTranslation": "Monkeys also fall from trees.",
    "category": "Verb"
  },
  {
    "term": "お手洗い",
    "termReading": "おてあらい",
    "termTranslation": "toilet, bathroom",
    "ex": "お手洗いはどこですか。",
    "exReading": "おてあらい は どこ です か",
    "exTranslation": "Where's the bathroom?",
    "category": "Noun"
  },
  {
    "term": "冷たい",
    "termReading": "つめたい",
    "termTranslation": "cold (things, touch)",
    "ex": "冷たい飲み物をください。",
    "exReading": "つめたい のみもの を ください",
    "exTranslation": "Please give me a cold drink.",
    "category": "Adjective"
  },
  {
    "term": "汚い",
    "termReading": "きたない",
    "termTranslation": "dirty",
    "ex": "彼の部屋はとても汚い。",
    "exReading": "かれ の へや は とても きたない",
    "exTranslation": "His room is very dirty.",
    "category": "Adjective"
  },
  {
    "term": "太い",
    "termReading": "ふとい",
    "termTranslation": "thick, fat",
    "ex": "彼女は足が太い。",
    "exReading": "かのじょ は あし が ふとい",
    "exTranslation": "She has fat legs.",
    "category": "Adjective"
  },
  {
    "term": "曇る",
    "termReading": "くもる",
    "termTranslation": "become cloudy",
    "ex": "明日は昼頃から曇るでしょう。",
    "exReading": "あす は ひるごろ から くもる でしょう",
    "exTranslation": "It'll probably get cloudy around noon tomorrow.",
    "category": "Verb"
  },
  {
    "term": "神",
    "termReading": "かみ",
    "termTranslation": "god, spirit",
    "ex": "彼は神を信じている。",
    "exReading": "かれ は かみ を しんじて いる",
    "exTranslation": "He believes in God.",
    "category": "Noun"
  },
  {
    "term": "まずい",
    "termReading": "まずい",
    "termTranslation": "bad-tasting, bad",
    "ex": "ここの料理はまずい。",
    "exReading": "ここ の りょうり は まずい",
    "exTranslation": "The food here is bad.",
    "category": "Adjective"
  },
  {
    "term": "建てる",
    "termReading": "たてる",
    "termTranslation": "build, erect",
    "ex": "私たちは来年、家を建てます。",
    "exReading": "わたしたち は らいねん いえ を たてます",
    "exTranslation": "We're building a house next year.",
    "category": "Verb"
  },
  {
    "term": "置く",
    "termReading": "おく",
    "termTranslation": "put, leave",
    "ex": "彼はかばんをいすの上に置きました。",
    "exReading": "かれ は かばん を いす の うえ に おきました",
    "exTranslation": "He put the bag on the chair.",
    "category": "Verb"
  },
  {
    "term": "辺",
    "termReading": "へん",
    "termTranslation": "vicinity, part",
    "ex": "彼はこの辺に住んでいます。",
    "exReading": "かれ は このへん に すんで います",
    "exTranslation": "He lives around here.",
    "category": "Noun"
  },
  {
    "term": "黄色い",
    "termReading": "きいろい",
    "termTranslation": "yellow",
    "ex": "彼女に黄色いバラを買いました。",
    "exReading": "かのじょ に きいろい ばら を かいました",
    "exTranslation": "I bought her a yellow rose.",
    "category": "Adjective"
  },
  {
    "term": "一緒に",
    "termReading": "いっしょに",
    "termTranslation": "together, at the same time",
    "ex": "一緒に帰りましょう。",
    "exReading": "いっしょに かえりましょう",
    "exTranslation": "Let's go home together.",
    "category": "Adverb"
  },
  {
    "term": "緑",
    "termReading": "みどり",
    "termTranslation": "green, foliage",
    "ex": "この町には緑がたくさんあります。",
    "exReading": "この まち に は みどり が たくさん あります",
    "exTranslation": "There's a lot of greenery in this town.",
    "category": "Noun"
  },
  {
    "term": "易しい",
    "termReading": "やさしい",
    "termTranslation": "easy, simple",
    "ex": "この問題はかなり易しいです。",
    "exReading": "この もんだい は かなり やさしい です",
    "exTranslation": "This problem is pretty easy.",
    "category": "Adjective"
  },
  {
    "term": "留学生",
    "termReading": "りゅうがくせい",
    "termTranslation": "student studying abroad",
    "ex": "彼は留学生です。",
    "exReading": "かれ は りゅうがくせい です",
    "exTranslation": "He's a foreign student.",
    "category": "Noun"
  },
  {
    "term": "戻る",
    "termReading": "もどる",
    "termTranslation": "return, turn back",
    "ex": "今、会社に戻ります。",
    "exReading": "いま かいしゃ に もどります",
    "exTranslation": "I'm going back to the office now.",
    "category": "Verb"
  },
  {
    "term": "そば",
    "termReading": "そば",
    "termTranslation": "side, vicinity",
    "ex": "そばにいて下さい。",
    "exReading": "そば に いて ください",
    "exTranslation": "Stay with me.",
    "category": "Noun"
  },
  {
    "term": "起きる",
    "termReading": "おきる",
    "termTranslation": "get up, get out of bed",
    "ex": "私は毎朝6時に起きます。",
    "exReading": "わたし は まいあさ ろくじ に おきます",
    "exTranslation": "I get up at six o'clock every morning.",
    "category": "Verb"
  },
  {
    "term": "起こる",
    "termReading": "おこる",
    "termTranslation": "happen",
    "ex": "町で大事件が起こりました。",
    "exReading": "まち で だいじけん が おこりました",
    "exTranslation": "There was a major incident in town.",
    "category": "Verb"
  },
  {
    "term": "起こす",
    "termReading": "おこす",
    "termTranslation": "wake up",
    "ex": "明日、6時に起こしてください。",
    "exReading": "あす ろくじ に おこして ください",
    "exTranslation": "Please wake me up tomorrow at six o'clock.",
    "category": "Verb"
  },
  {
    "term": "起きる",
    "termReading": "おきる",
    "termTranslation": "occur",
    "ex": "近所で盗難事件が起きました。",
    "exReading": "きんじょ で とうなん じけん が おきました",
    "exTranslation": "Some burglaries occurred in the neighborhood.",
    "category": "Verb"
  },
  {
    "term": "寝る",
    "termReading": "ねる",
    "termTranslation": "sleep, go to sleep",
    "ex": "もう寝よう。",
    "exReading": "もう ねよう",
    "exTranslation": "Let's go to bed.",
    "category": "Verb"
  },
  {
    "term": "細い",
    "termReading": "ほそい",
    "termTranslation": "thin, slender, narrow",
    "ex": "彼女は指が細いですね。",
    "exReading": "かのじょ は ゆび が ほそい です ね",
    "exTranslation": "Her fingers are thin.",
    "category": "Adjective"
  },
  {
    "term": "載せる",
    "termReading": "のせる",
    "termTranslation": "place, put on",
    "ex": "皿にケーキを載せました。",
    "exReading": "さら に けーき を のせました。",
    "exTranslation": "I put the cake on the plate.",
    "category": "Verb"
  },
  {
    "term": "締める",
    "termReading": "しめる",
    "termTranslation": "tighten, fasten",
    "ex": "彼はシートベルトを締めた。",
    "exReading": "かれ は しーとべると を しめた",
    "exTranslation": "He fastened his seat belt.",
    "category": "Verb"
  },
  {
    "term": "甘い",
    "termReading": "あまい",
    "termTranslation": "sweet",
    "ex": "このイチゴは甘い。",
    "exReading": "この いちご は あまい",
    "exTranslation": "This strawberry is sweet.",
    "category": "Adjective"
  },
  {
    "term": "こっち",
    "termReading": "こっち",
    "termTranslation": "here",
    "ex": "こっちに来て下さい。",
    "exReading": "こっち に きて ください",
    "exTranslation": "Please come here.",
    "category": "Pronoun"
  },
  {
    "term": "辛い",
    "termReading": "からい",
    "termTranslation": "spicy, salty",
    "ex": "彼は辛いものが好きです。",
    "exReading": "かれ は からい もの が すき です",
    "exTranslation": "He likes spicy food.",
    "category": "Adjective"
  },
  {
    "term": "優しい",
    "termReading": "やさしい",
    "termTranslation": "gentle, kind",
    "ex": "彼はとても優しい人です。",
    "exReading": "かれ は とても やさしい ひと です",
    "exTranslation": "He's a very kind person.",
    "category": "Adjective"
  },
  {
    "term": "夫",
    "termReading": "おっと",
    "termTranslation": "husband",
    "ex": "私の夫はサラリーマンです。",
    "exReading": "わたし の おっと は さらりーまん です",
    "exTranslation": "My husband is a company employee.",
    "category": "Noun"
  },
  {
    "term": "妻",
    "termReading": "つま",
    "termTranslation": "wife",
    "ex": "今日は妻の誕生日だ。",
    "exReading": "きょう は つま の たんじょうび だ",
    "exTranslation": "It's my wife's birthday today.",
    "category": "Noun"
  },
  {
    "term": "誰",
    "termReading": "だれ",
    "termTranslation": "who, whom",
    "ex": "誰と会ってみたいですか。",
    "exReading": "だれ と あって みたい です か",
    "exTranslation": "Who would you like to meet one day?",
    "category": "Noun"
  },
  {
    "term": "愛する",
    "termReading": "あいする",
    "termTranslation": "love, care for",
    "ex": "私は家族を愛しています。",
    "exReading": "わたし は かぞく を あいして います",
    "exTranslation": "I love my family.",
    "category": "Verb"
  },
  {
    "term": "笑う",
    "termReading": "わらう",
    "termTranslation": "laugh, smile",
    "ex": "赤ん坊が笑っています。",
    "exReading": "あかんぼう が わらって います",
    "exTranslation": "The baby is laughing.",
    "category": "Verb"
  },
  {
    "term": "酸っぱい",
    "termReading": "すっぱい",
    "termTranslation": "sour",
    "ex": "このぶどうは酸っぱいです。",
    "exReading": "この ぶどう は すっぱい です",
    "exTranslation": "These grapes are sour.",
    "category": "Adjective"
  },
  {
    "term": "言葉",
    "termReading": "ことば",
    "termTranslation": "word, language",
    "ex": "この言葉の意味が分かりません。",
    "exReading": "この ことば の いみ が わかりません",
    "exTranslation": "I don't understand the meaning of this word.",
    "category": "Noun"
  },
  {
    "term": "よう",
    "termReading": "よう",
    "termTranslation": "way, like, kind",
    "ex": "彼女は眠いようです。",
    "exReading": "かのじょ は ねむい よう です",
    "exTranslation": "It looks like she's sleepy.",
    "category": "Noun"
  },
  {
    "term": "呼ぶ",
    "termReading": "よぶ",
    "termTranslation": "call, name",
    "ex": "ウェイターを呼びましょう。",
    "exReading": "うぇいたー を よびましょう",
    "exTranslation": "Let's call the waiter.",
    "category": "Verb"
  },
  {
    "term": "胸",
    "termReading": "むね",
    "termTranslation": "chest",
    "ex": "胸に少し痛みがあります。",
    "exReading": "むね に すこし いたみ が あります",
    "exTranslation": "I have a slight pain in my chest.",
    "category": "Noun"
  },
  {
    "term": "腰",
    "termReading": "こし",
    "termTranslation": "hip, lower back",
    "ex": "昨日から腰が痛い。",
    "exReading": "きのう から こし が いたい",
    "exTranslation": "I've had a pain in my lower back since yesterday.",
    "category": "Noun"
  },
  {
    "term": "背",
    "termReading": "せ",
    "termTranslation": "height, stature",
    "ex": "彼女は背が高い。",
    "exReading": "かのじょ は せ が たかい",
    "exTranslation": "She's tall.",
    "category": "Noun"
  },
  {
    "term": "片仮名",
    "termReading": "かたかな",
    "termTranslation": "katakana",
    "ex": "お名前を片仮名で書いてください。",
    "exReading": "おなまえ を かたかな で かいて ください",
    "exTranslation": "Please write your name in katakana.",
    "category": "Noun"
  },
  {
    "term": "平仮名",
    "termReading": "ひらがな",
    "termTranslation": "hiragana",
    "ex": "私は平仮名を全部読めます。",
    "exReading": "わたし は ひらがな を ぜんぶ よめます",
    "exTranslation": "I can read all the hiragana.",
    "category": "Noun"
  },
  {
    "term": "悲しい",
    "termReading": "かなしい",
    "termTranslation": "sad",
    "ex": "その映画はとても悲しかった。",
    "exReading": "その えいが は とても かなしかった",
    "exTranslation": "That movie was very sad.",
    "category": "Adjective"
  },
  {
    "term": "美しい",
    "termReading": "うつくしい",
    "termTranslation": "beautiful",
    "ex": "この絵は美しいです。",
    "exReading": "この え は うつくしい です",
    "exTranslation": "This picture is beautiful.",
    "category": "Adjective"
  },
  {
    "term": "授業",
    "termReading": "じゅぎょう",
    "termTranslation": "class, lesson",
    "ex": "今日は日本語の授業があります。",
    "exReading": "きょう は にほんご の じゅぎょう が あります",
    "exTranslation": "There's going to be a Japanese class today.",
    "category": "Verbal Noun"
  },
  {
    "term": "あそこ",
    "termReading": "あそこ",
    "termTranslation": "over there",
    "ex": "あそこにバス停があります。",
    "exReading": "あそこ に ばすてい が あります",
    "exTranslation": "There's a bus stop over there.",
    "category": "Pronoun"
  },
  {
    "term": "手伝う",
    "termReading": "てつだう",
    "termTranslation": "help, assist",
    "ex": "私が手伝いましょう。",
    "exReading": "わたし が てつだいましょう",
    "exTranslation": "Can I help you?",
    "category": "Verb"
  },
  {
    "term": "狭い",
    "termReading": "せまい",
    "termTranslation": "narrow, restricted",
    "ex": "私の部屋は狭いです。",
    "exReading": "わたし の へや は せまい です",
    "exTranslation": "My room is small.",
    "category": "Adjective"
  },
  {
    "term": "触る",
    "termReading": "さわる",
    "termTranslation": "touch, feel",
    "ex": "絵に触らないでください。",
    "exReading": "え に さわらない で ください",
    "exTranslation": "Please don't touch the painting.",
    "category": "Verb"
  },
  {
    "term": "嫌い",
    "termReading": "きらい",
    "termTranslation": "dislike",
    "ex": "私はタバコが嫌いです。",
    "exReading": "わたし は たばこ が きらい です",
    "exTranslation": "I hate cigarettes.",
    "category": "Adjective"
  },
  {
    "term": "浴びる",
    "termReading": "あびる",
    "termTranslation": "take (a shower)",
    "ex": "私は朝、シャワーを浴びます。",
    "exReading": "わたし は あさ しゃわー を あびます",
    "exTranslation": "I take a shower in the morning.",
    "category": "Verb"
  },
  {
    "term": "渇く",
    "termReading": "かわく",
    "termTranslation": "be thirsty",
    "ex": "喉が渇きました。",
    "exReading": "のど が かわきました",
    "exTranslation": "I'm thirsty.",
    "category": "Verb"
  },
  {
    "term": "髪の毛",
    "termReading": "かみのけ",
    "termTranslation": "hair",
    "ex": "髪の毛が伸びたね。",
    "exReading": "かみのけ が のびた ね",
    "exTranslation": "Your hair's longer, isn't it?",
    "category": "Noun"
  },
  {
    "term": "肩",
    "termReading": "かた",
    "termTranslation": "shoulder",
    "ex": "肩が凝りました。",
    "exReading": "かた が こりました",
    "exTranslation": "My shoulders are stiff.",
    "category": "Noun"
  },
  {
    "term": "鼻",
    "termReading": "はな",
    "termTranslation": "nose",
    "ex": "鼻がかゆいです。",
    "exReading": "はな が かゆい です",
    "exTranslation": "My nose itches.",
    "category": "Noun"
  },
  {
    "term": "おいしい",
    "termReading": "おいしい",
    "termTranslation": "tasty",
    "ex": "このケーキはおいしいね。",
    "exReading": "この けーき は おいしい ね",
    "exTranslation": "This cake is yummy.",
    "category": "Adjective"
  },
  {
    "term": "腕",
    "termReading": "うで",
    "termTranslation": "arm",
    "ex": "彼の腕は太い。",
    "exReading": "かれ の うで は ふとい",
    "exTranslation": "He has big arms.",
    "category": "Noun"
  },
  {
    "term": "掛ける",
    "termReading": "かける",
    "termTranslation": "hang, put on",
    "ex": "夫の服をハンガーに掛けた。",
    "exReading": "おっと の ふく を はんがー に かけた",
    "exTranslation": "I put my husband's clothes on a hanger.",
    "category": "Verb"
  },
  {
    "term": "僕",
    "termReading": "ぼく",
    "termTranslation": "I (young male)",
    "ex": "僕は学生です。",
    "exReading": "ぼく は がくせい です",
    "exTranslation": "I'm a student.",
    "category": "Noun"
  },
  {
    "term": "駄目",
    "termReading": "だめ",
    "termTranslation": "no good",
    "ex": "彼は駄目な男だ。",
    "exReading": "かれ は だめ な おとこ だ",
    "exTranslation": "He's a loser.",
    "category": "Adjectival Noun"
  },
  {
    "term": "大丈夫",
    "termReading": "だいじょうぶ",
    "termTranslation": "alright, OK",
    "ex": "大丈夫ですか。",
    "exReading": "だいじょうぶ です か",
    "exTranslation": "Are you OK?",
    "category": "Adjectival Noun"
  },
  {
    "term": "風邪",
    "termReading": "かぜ",
    "termTranslation": "cold (illness)",
    "ex": "私は風邪を引きました。",
    "exReading": "わたし は かぜ を ひきました 。",
    "exTranslation": "I caught a cold.",
    "category": "Noun"
  },
  {
    "term": "奇麗",
    "termReading": "きれい",
    "termTranslation": "beautiful",
    "ex": "彼女はとても奇麗だ。",
    "exReading": "かのじょ は とても きれい だ",
    "exTranslation": "She's very beautiful.",
    "category": "Adjectival Noun"
  },
  {
    "term": "嬉しい",
    "termReading": "うれしい",
    "termTranslation": "happy, glad",
    "ex": "彼に会えて嬉しかった。",
    "exReading": "かれ に あえて うれしかった",
    "exTranslation": "I was glad to meet him.",
    "category": "Adjective"
  },
  {
    "term": "なる",
    "termReading": "なる",
    "termTranslation": "become",
    "ex": "柿の木に実がたくさんなっています。",
    "exReading": "かきのき に み が たくさん なって います",
    "exTranslation": "A lot of fruit is ripening on the persimmon tree.",
    "category": "Verb"
  },
  {
    "term": "ため",
    "termReading": "ため",
    "termTranslation": "sake, purpose",
    "ex": "これは医者のためのサイトです。",
    "exReading": "これ は いしゃ の ため の さいと です",
    "exTranslation": "This is a site for doctors.",
    "category": "Noun"
  },
  {
    "term": "より",
    "termReading": "より",
    "termTranslation": "more, further",
    "ex": "これから、より一層努力します。",
    "exReading": "これから より いっそう どりょく します",
    "exTranslation": "I'll work that much harder from now.",
    "category": "Adverb"
  },
  {
    "term": "七",
    "termReading": "しち",
    "termTranslation": "seven",
    "ex": "そのグループのメンバーは全部で七人だ。",
    "exReading": "その ぐるーぷ の めんばー は ぜんぶ で しちにん だ",
    "exTranslation": "There are seven members of the group in all.",
    "category": "Noun"
  },
  {
    "term": "九",
    "termReading": "く",
    "termTranslation": "nine",
    "ex": "私は九月に行く予定です。",
    "exReading": "わたし は くがつ に いく よてい です",
    "exTranslation": "I plan to go in September.",
    "category": "Noun"
  },
  {
    "term": "億",
    "termReading": "おく",
    "termTranslation": "hundred million",
    "ex": "世界人口は65億人だ。",
    "exReading": "せかい じんこう は ろくじゅうごおくにん だ",
    "exTranslation": "The world's population is 6.5 billion people.",
    "category": "Noun"
  },
  {
    "term": "寺",
    "termReading": "てら",
    "termTranslation": "buddhist temple",
    "ex": "あそこに古いお寺があります。",
    "exReading": "あそこ に ふるい おてら が あります",
    "exTranslation": "There's an old temple there.",
    "category": "Noun"
  },
  {
    "term": "ドル",
    "termReading": "ドル",
    "termTranslation": "dollar",
    "ex": "この服は300ドルしました。",
    "exReading": "この ふく は さんびゃくどる しました",
    "exTranslation": "These clothes cost 300 dollars.",
    "category": "Noun"
  },
  {
    "term": "日",
    "termReading": "ひ",
    "termTranslation": "sun, sunlight, day",
    "ex": "夏は日が長い。",
    "exReading": "なつ は ひ が ながい",
    "exTranslation": "The days are long in summer.",
    "category": "Noun"
  },
  {
    "term": "火",
    "termReading": "ひ",
    "termTranslation": "fire",
    "ex": "火を消して。",
    "exReading": "ひ を けして",
    "exTranslation": "Put the fire out.",
    "category": "Noun"
  },
  {
    "term": "木",
    "termReading": "き",
    "termTranslation": "tree, wood",
    "ex": "台風で木が倒れた。",
    "exReading": "たいふう で き が たおれた",
    "exTranslation": "The tree fell because of the typhoon.",
    "category": "Noun"
  },
  {
    "term": "金",
    "termReading": "きん",
    "termTranslation": "gold, sum of money, Friday",
    "ex": "彼女は金のネックレスをしています。",
    "exReading": "かのじょ は きん の ねっくれす を して います",
    "exTranslation": "She's wearing a gold necklace.",
    "category": "Noun"
  },
  {
    "term": "システム",
    "termReading": "システム",
    "termTranslation": "system",
    "ex": "全てのシステムが停止した。",
    "exReading": "すべて の しすてむ が ていし した",
    "exTranslation": "All systems have stopped.",
    "category": "Noun"
  },
  {
    "term": "十分",
    "termReading": "じゅうぶん",
    "termTranslation": "full, plentiful",
    "ex": "お金はまだ十分あります。",
    "exReading": "おかね は まだ じゅうぶん あります",
    "exTranslation": "We still have plenty of money.",
    "category": "Adjectival Noun"
  },
  {
    "term": "分",
    "termReading": "ぶん",
    "termTranslation": "amount, part, share",
    "ex": "このケーキはあなたの分です。",
    "exReading": "この けーき は あなた の ぶん です",
    "exTranslation": "This is your share of the cake.",
    "category": "Noun"
  },
  {
    "term": "分ける",
    "termReading": "わける",
    "termTranslation": "divide into parts, sort, share",
    "ex": "お菓子をみんなで分けました。",
    "exReading": "おかし を みんな で わけました",
    "exTranslation": "We shared the sweets with everyone.",
    "category": "Verb"
  },
  {
    "term": "分かれる",
    "termReading": "わかれる",
    "termTranslation": "be divided, disperse, branch off",
    "ex": "グループの中で意見が分かれました。",
    "exReading": "ぐるーぷ の なか で いけん が わかれました",
    "exTranslation": "Opinion was divided within the group.",
    "category": "Verb"
  },
  {
    "term": "コンピューター",
    "termReading": "コンピューター",
    "termTranslation": "computer",
    "ex": "新しいコンピューターを買った。",
    "exReading": "あたらしい こんぴゅーたー を かった",
    "exTranslation": "I bought a new computer.",
    "category": "Noun"
  },
  {
    "term": "何か",
    "termReading": "なにか",
    "termTranslation": "something, some",
    "ex": "道に何か落ちています。",
    "exReading": "みち に なにか おちて います",
    "exTranslation": "Something has fallen on the road.",
    "category": "Noun"
  },
  {
    "term": "何",
    "termReading": "なん",
    "termTranslation": "what, which",
    "ex": "質問は何ですか。",
    "exReading": "しつもん は なん です か",
    "exTranslation": "What's the question?",
    "category": "Noun"
  },
  {
    "term": "先ず",
    "termReading": "まず",
    "termTranslation": "first",
    "ex": "帰ったら先ず手を洗いましょう。",
    "exReading": "かえったら まず て を あらいましょう 。",
    "exTranslation": "When you get home, wash your hands first.",
    "category": "Adverb"
  },
  {
    "term": "先月",
    "termReading": "せんげつ",
    "termTranslation": "last month",
    "ex": "先月、友達の結婚式があった。",
    "exReading": "せんげつ ともだち の けっこんしき が あった",
    "exTranslation": "My friend's wedding was held last month.",
    "category": "Noun"
  },
  {
    "term": "やる",
    "termReading": "やる",
    "termTranslation": "give (to an inferior)",
    "ex": "犬にえさをやった。",
    "exReading": "いぬ に えさ を やった",
    "exTranslation": "I fed the dog.",
    "category": "Verb"
  },
  {
    "term": "先週",
    "termReading": "せんしゅう",
    "termTranslation": "last week",
    "ex": "先週は海に行った。",
    "exReading": "せんしゅう は うみ に いった",
    "exTranslation": "I went to the beach last week.",
    "category": "Noun"
  },
  {
    "term": "今まで",
    "termReading": "いままで",
    "termTranslation": "until now, so far",
    "ex": "今までどこにいたのですか。",
    "exReading": "いままで どこ に いた の です か",
    "exTranslation": "Where were you until now?",
    "category": "Adverb"
  },
  {
    "term": "来月",
    "termReading": "らいげつ",
    "termTranslation": "next month",
    "ex": "来月から大学生になります。",
    "exReading": "らいげつ から だいがくせい に なります",
    "exTranslation": "I'll be a college student beginning next month.",
    "category": "Noun"
  },
  {
    "term": "来週",
    "termReading": "らいしゅう",
    "termTranslation": "next week",
    "ex": "続きは来週やりましょう。",
    "exReading": "つづき は らいしゅう やりましょう",
    "exTranslation": "Let's continue next week.",
    "category": "Noun"
  },
  {
    "term": "ロボット",
    "termReading": "ロボット",
    "termTranslation": "robot",
    "ex": "彼はロボットの研究をしています。",
    "exReading": "かれ は ろぼっと の けんきゅう を しています 。",
    "exTranslation": "He is doing research on robots.",
    "category": "Noun"
  },
  {
    "term": "行う",
    "termReading": "おこなう",
    "termTranslation": "do, carry out",
    "ex": "その会社は来月、キャンペーンを行う。",
    "exReading": "その かいしゃ は らいげつ きゃんぺーん を おこなう",
    "exTranslation": "That company is going to run a campaign next month.",
    "category": "Verb"
  },
  {
    "term": "行き",
    "termReading": "いき",
    "termTranslation": "going",
    "ex": "行きは新幹線で行った。",
    "exReading": "いき は しんかんせん で いった",
    "exTranslation": "I went by bullet train on the way over.",
    "category": "Noun"
  },
  {
    "term": "行き",
    "termReading": "ゆき",
    "termTranslation": "going",
    "ex": "東京行きの列車に乗った。",
    "exReading": "とうきょうゆき の れっしゃ に のった",
    "exTranslation": "I got on the Tokyo-bound train.",
    "category": "Noun"
  },
  {
    "term": "帰り",
    "termReading": "かえり",
    "termTranslation": "return, going home",
    "ex": "仕事の帰りにビールを飲んだ。",
    "exReading": "しごと の かえり に びーる を のんだ",
    "exTranslation": "I had a beer on the way home from work.",
    "category": "Noun"
  },
  {
    "term": "ほとんど",
    "termReading": "ほとんど",
    "termTranslation": "almost, hardly",
    "ex": "お金がほとんどありません。",
    "exReading": "おかね が ほとんど ありません",
    "exTranslation": "There's hardly any money.",
    "category": "Adverb"
  },
  {
    "term": "大きさ",
    "termReading": "おおきさ",
    "termTranslation": "size, dimensions",
    "ex": "この大きさの封筒が欲しいのですが。",
    "exReading": "この おおきさ の ふうとう が ほしい の です が",
    "exTranslation": "I'd like an envelope of this size.",
    "category": "Noun"
  },
  {
    "term": "大分",
    "termReading": "だいぶ",
    "termTranslation": "very, greatly",
    "ex": "大分ピアノが上手くなりました。",
    "exReading": "だいぶ ぴあの が うまく なりました",
    "exTranslation": "You've gotten pretty good at the piano.",
    "category": "Adverb"
  },
  {
    "term": "中",
    "termReading": "なか",
    "termTranslation": "inside, middle",
    "ex": "財布は引き出しの中にあります。",
    "exReading": "さいふ は ひきだし の なか に あります",
    "exTranslation": "My purse is in the drawer.",
    "category": "Noun"
  },
  {
    "term": "少年",
    "termReading": "しょうねん",
    "termTranslation": "boy",
    "ex": "少年たちがサッカーをしている。",
    "exReading": "しょうねんたち が さっかー を して いる",
    "exTranslation": "The boys are playing soccer.",
    "category": "Noun"
  },
  {
    "term": "パソコン",
    "termReading": "パソコン",
    "termTranslation": "personal computer",
    "ex": "彼はパソコンを2台持っています。",
    "exReading": "かれ は ぱそこん を にだい もって います",
    "exTranslation": "He has two personal computers.",
    "category": "Noun"
  },
  {
    "term": "少しも",
    "termReading": "すこしも",
    "termTranslation": "at all",
    "ex": "あなたは少しも悪くない。",
    "exReading": "あなた は すこしも わるく ない",
    "exTranslation": "It's not your fault at all.",
    "category": "Adverb"
  },
  {
    "term": "少々",
    "termReading": "しょうしょう",
    "termTranslation": "a little, a few",
    "ex": "塩を少々入れてください。",
    "exReading": "しお を しょうしょう いれて ください",
    "exTranslation": "Please put in a little salt.",
    "category": "Adverb"
  },
  {
    "term": "多く",
    "termReading": "おおく",
    "termTranslation": "much, largely",
    "ex": "毎年多くの人が海外へ旅行する。",
    "exReading": "まいとし おおく の ひと が かいがい へ りょこう する",
    "exTranslation": "A lot of people travel abroad every year.",
    "category": "Adverb"
  },
  {
    "term": "上がる",
    "termReading": "あがる",
    "termTranslation": "go up, rise",
    "ex": "私たちは２階に上がった。",
    "exReading": "わたしたち は にかい に あがった",
    "exTranslation": "We went up to the second floor.",
    "category": "Verb"
  },
  {
    "term": "もう",
    "termReading": "もう",
    "termTranslation": "another, again",
    "ex": "コーヒーをもう一杯ください。",
    "exReading": "こーひー を もう いっぱい ください",
    "exTranslation": "Please give me another cup of coffee.",
    "category": "Adverb"
  },
  {
    "term": "上がる",
    "termReading": "あがる",
    "termTranslation": "get nervous, get stage fright",
    "ex": "彼は人前だと上がってしまう。",
    "exReading": "かれ は ひとまえ だ と あがって しまう",
    "exTranslation": "He gets nervous in front of other people.",
    "category": "Verb"
  },
  {
    "term": "年上",
    "termReading": "としうえ",
    "termTranslation": "older, senior",
    "ex": "彼は私より年上です。",
    "exReading": "かれ は わたし より としうえ です",
    "exTranslation": "He's older than me.",
    "category": "Noun"
  },
  {
    "term": "上り",
    "termReading": "のぼり",
    "termTranslation": "going up, ascent",
    "ex": "これは上り電車です。",
    "exReading": "これ は のぼり でんしゃ です",
    "exTranslation": "This is the train going downtown.",
    "category": "Noun"
  },
  {
    "term": "下げる",
    "termReading": "さげる",
    "termTranslation": "lower, turn down",
    "ex": "少し音量を下げてください。",
    "exReading": "すこし おんりょう を さげて ください",
    "exTranslation": "Please turn the volume down a little.",
    "category": "Verb"
  },
  {
    "term": "プログラム",
    "termReading": "プログラム",
    "termTranslation": "program",
    "ex": "受付でプログラムを受け取った。",
    "exReading": "うけつけ で ぷろぐらむ を うけとった",
    "exTranslation": "I got my program at the reception.",
    "category": "Noun"
  },
  {
    "term": "下がる",
    "termReading": "さがる",
    "termTranslation": "come down, hang down, fall",
    "ex": "やっと熱が下がった。",
    "exReading": "やっと ねつ が さがった",
    "exTranslation": "His fever finally went down.",
    "category": "Verb"
  },
  {
    "term": "下りる",
    "termReading": "おりる",
    "termTranslation": "go down, come down",
    "ex": "そこの階段を下りてください。",
    "exReading": "そこ の かいだん を おりて ください",
    "exTranslation": "Please go down those stairs.",
    "category": "Verb"
  },
  {
    "term": "下ろす",
    "termReading": "おろす",
    "termTranslation": "bring down, take down, pull down",
    "ex": "棚からその箱を下ろしてください。",
    "exReading": "たな から その はこ を おろして ください",
    "exTranslation": "Please take the box down from the shelf.",
    "category": "Verb"
  },
  {
    "term": "下り",
    "termReading": "くだり",
    "termTranslation": "downhill, going down",
    "ex": "もうすぐ下りの電車が発車します。",
    "exReading": "もうすぐ くだり の でんしゃ が はっしゃ します",
    "exTranslation": "The train out of the city leaves soon.",
    "category": "Noun"
  },
  {
    "term": "よく",
    "termReading": "よく",
    "termTranslation": "well",
    "ex": "よく答えが分かりましたね。",
    "exReading": "よく こたえ が わかりました ね",
    "exTranslation": "You did well in coming up with the answer.",
    "category": "Adverb"
  },
  {
    "term": "年下",
    "termReading": "としした",
    "termTranslation": "younger, junior",
    "ex": "彼は奥さんより年下です。",
    "exReading": "かれ は おくさん より としした です",
    "exTranslation": "He's younger than his wife.",
    "category": "Noun"
  },
  {
    "term": "下る",
    "termReading": "くだる",
    "termTranslation": "go down, go down or away from a central place",
    "ex": "小さな船が川を下っています。",
    "exReading": "ちいさ な ふね が かわ を くだって います",
    "exTranslation": "A small ship is going down the river.",
    "category": "Verb"
  },
  {
    "term": "一方",
    "termReading": "いっぽう",
    "termTranslation": "one way",
    "ex": "ここは一方通行です。",
    "exReading": "ここ は いっぽう つうこう です",
    "exTranslation": "This is a one-way street.",
    "category": "Noun"
  },
  {
    "term": "方",
    "termReading": "かた",
    "termTranslation": "person　（polite form)",
    "ex": "次の方、どうぞ。",
    "exReading": "つぎ の かた どうぞ",
    "exTranslation": "Next please.",
    "category": "Noun"
  },
  {
    "term": "まま",
    "termReading": "まま",
    "termTranslation": "as is, still (in the current state)",
    "ex": "電気がついたままですよ。",
    "exReading": "でんき が ついた まま です よ",
    "exTranslation": "The light is still on.",
    "category": "Noun"
  },
  {
    "term": "二人",
    "termReading": "ふたり",
    "termTranslation": "two persons",
    "ex": "今日は妻と二人で食事をします。",
    "exReading": "きょう は つま と ふたり で しょくじ を します",
    "exTranslation": "Today I'm having dinner with my wife, just the two of us.",
    "category": "Noun"
  },
  {
    "term": "大人しい",
    "termReading": "おとなしい",
    "termTranslation": "gentle, quiet, subdued",
    "ex": "私の彼女はとても大人しいです。",
    "exReading": "わたし の かのじょ は とても おとなしい です",
    "exTranslation": "My girlfriend's very quiet.",
    "category": "Adjective"
  },
  {
    "term": "人々",
    "termReading": "ひとびと",
    "termTranslation": "people",
    "ex": "あの村の人々はとても親切です。",
    "exReading": "あの むら の ひとびと は とても しんせつ です",
    "exTranslation": "People in that village are very kind.",
    "category": "Noun"
  },
  {
    "term": "一人で",
    "termReading": "ひとりで",
    "termTranslation": "by oneself",
    "ex": "今日は一人で映画を見ます。",
    "exReading": "きょう は ひとりで えいが を みます",
    "exTranslation": "I saw a movie by myself today.",
    "category": "Adverb"
  },
  {
    "term": "テレビ",
    "termReading": "テレビ",
    "termTranslation": "television",
    "ex": "私はテレビをあまり見ません。",
    "exReading": "わたし は てれび を あまり みません",
    "exTranslation": "I don't watch much TV.",
    "category": "Noun"
  },
  {
    "term": "外人",
    "termReading": "がいじん",
    "termTranslation": "foreigner, alien",
    "ex": "この町には外人が少ない。",
    "exReading": "この まち に は がいじん が すくない",
    "exTranslation": "There are few foreigners in this town.",
    "category": "Noun"
  },
  {
    "term": "外",
    "termReading": "そと",
    "termTranslation": "outside, open air",
    "ex": "外は暑いよ。",
    "exReading": "そと は あついよ",
    "exTranslation": "It's hot outside.",
    "category": "Noun"
  },
  {
    "term": "休日",
    "termReading": "きゅうじつ",
    "termTranslation": "holiday, day off",
    "ex": "休日は家でよくテレビを見ます。",
    "exReading": "きゅうじつ は いえ で よく てれび を みます",
    "exTranslation": "I often watch television at home on my days off.",
    "category": "Noun"
  },
  {
    "term": "休み",
    "termReading": "やすみ",
    "termTranslation": "rest, holiday, absence",
    "ex": "木曜日は仕事が休みです。",
    "exReading": "もくようび は しごと が やすみ です",
    "exTranslation": "Thursday is my day off work.",
    "category": "Noun"
  },
  {
    "term": "ソフト",
    "termReading": "ソフト",
    "termTranslation": "software, programme",
    "ex": "このソフトで日本語を勉強することができます。",
    "exReading": "この そふと で にほんご を べんきょう する こと が できます",
    "exTranslation": "You can study Japanese with this software.",
    "category": "Noun"
  },
  {
    "term": "力",
    "termReading": "ちから",
    "termTranslation": "strength, power",
    "ex": "お相撲さんは、みんな力持ちだ。",
    "exReading": "おすもうさん は みんな ちからもち だ",
    "exTranslation": "All sumo wrestlers are strong.",
    "category": "Noun"
  },
  {
    "term": "協力",
    "termReading": "きょうりょく",
    "termTranslation": "cooperation, collaboration",
    "ex": "このプロジェクトにはみんなの協力が必要です。",
    "exReading": "この ぷろじぇくと に は みんな の きょうりょく が ひつよう です",
    "exTranslation": "This project requires everyone's cooperation.",
    "category": "Verbal Noun"
  },
  {
    "term": "人口",
    "termReading": "じんこう",
    "termTranslation": "population",
    "ex": "その国の人口はどのくらいですか。",
    "exReading": "その くに の じんこう は どの くらい です か",
    "exTranslation": "What's the population of that country?",
    "category": "Noun"
  },
  {
    "term": "出口",
    "termReading": "でぐち",
    "termTranslation": "exit, exit ramp",
    "ex": "出口はあそこです。",
    "exReading": "でぐち は あそこ です",
    "exTranslation": "The exit's over there.",
    "category": "Noun"
  },
  {
    "term": "ただ",
    "termReading": "ただ",
    "termTranslation": "free",
    "ex": "この温泉はただです。",
    "exReading": "この おんせん は ただ です",
    "exTranslation": "This hot spring is free.",
    "category": "Noun"
  },
  {
    "term": "入り口",
    "termReading": "いりぐち",
    "termTranslation": "entrance",
    "ex": "入り口は向こうです。",
    "exReading": "いりぐち は むこう です",
    "exTranslation": "The entrance is over there.",
    "category": "Noun"
  },
  {
    "term": "右手",
    "termReading": "みぎて",
    "termTranslation": "right hand",
    "ex": "私は右手で字を書きます。",
    "exReading": "わたし は みぎて で じ を かきます",
    "exTranslation": "I write right-handed.",
    "category": "Noun"
  },
  {
    "term": "左手",
    "termReading": "ひだりて",
    "termTranslation": "left hand",
    "ex": "彼女は左手で字を書く。",
    "exReading": "かのじょ は ひだりて で じ を かく",
    "exTranslation": "She writes left-handed.",
    "category": "Noun"
  },
  {
    "term": "下手",
    "termReading": "へた",
    "termTranslation": "not good at",
    "ex": "私は歌が下手だ。",
    "exReading": "わたし は うた が へた だ",
    "exTranslation": "I'm a terrible singer.",
    "category": "Adjectival Noun"
  },
  {
    "term": "これら",
    "termReading": "これら",
    "termTranslation": "these",
    "ex": "今日はこれらの問題について話し合います。",
    "exReading": "きょう は これら の もんだい に ついて はなしあいます",
    "exTranslation": "We will discuss these issues today.",
    "category": "Noun"
  },
  {
    "term": "足りる",
    "termReading": "たりる",
    "termTranslation": "suffice, serve, be satisfied",
    "ex": "お金が足りなくて買えなかった。",
    "exReading": "おかね が たりなくて かえなかった",
    "exTranslation": "I couldn't buy it because I didn't have enough money.",
    "category": "Verb"
  },
  {
    "term": "足す",
    "termReading": "たす",
    "termTranslation": "add, supplement",
    "ex": "母は味噌汁に水を足した。",
    "exReading": "はは は みそしる に みず を たした",
    "exTranslation": "My mother added water to the miso soup.",
    "category": "Verb"
  },
  {
    "term": "山",
    "termReading": "やま",
    "termTranslation": "mountain",
    "ex": "山の空気はきれいだ。",
    "exReading": "やま の くうき は きれい だ",
    "exTranslation": "The mountain air is clean.",
    "category": "Noun"
  },
  {
    "term": "川",
    "termReading": "かわ",
    "termTranslation": "river, stream",
    "ex": "小さな川を渡りました。",
    "exReading": "ちいさ な かわ を わたりました",
    "exTranslation": "I crossed a stream.",
    "category": "Noun"
  },
  {
    "term": "いずれ",
    "termReading": "いずれ",
    "termTranslation": "eventually, sometime",
    "ex": "いずれまたお会いしましょう。",
    "exReading": "いずれ また おあい しましょう",
    "exTranslation": "Let's meet again sometime.",
    "category": "Adverb"
  },
  {
    "term": "空く",
    "termReading": "あく",
    "termTranslation": "become available or vacant",
    "ex": "後ろの席が空いています。",
    "exReading": "うしろ の せき が あいて います",
    "exTranslation": "The rear seat is empty.",
    "category": "Verb"
  },
  {
    "term": "空手",
    "termReading": "からて",
    "termTranslation": "karate, empty hand",
    "ex": "彼は空手を習っています。",
    "exReading": "かれ は からて を ならって います",
    "exTranslation": "He's learning karate.",
    "category": "Noun"
  },
  {
    "term": "空",
    "termReading": "そら",
    "termTranslation": "sky, weather",
    "ex": "空が真っ青です。",
    "exReading": "そら が まっさお です",
    "exTranslation": "The sky is bright blue.",
    "category": "Noun"
  },
  {
    "term": "海外",
    "termReading": "かいがい",
    "termTranslation": "overseas, abroad",
    "ex": "彼は海外での生活が長いです。",
    "exReading": "かれ は かいがい で の せいかつ が ながい です",
    "exTranslation": "He's lived abroad for a long time.",
    "category": "Noun"
  },
  {
    "term": "あまり",
    "termReading": "あまり",
    "termTranslation": "(not) much",
    "ex": "このビールはあまり美味しくありません。",
    "exReading": "この びーる は あまり おいしく ありません",
    "exTranslation": "This beer isn't very good.",
    "category": "Adverb"
  },
  {
    "term": "海",
    "termReading": "うみ",
    "termTranslation": "sea, ocean",
    "ex": "海は広くて大きい。",
    "exReading": "うみ は ひろく て おおきい",
    "exTranslation": "The sea is vast and wide.",
    "category": "Noun"
  },
  {
    "term": "毎日",
    "termReading": "まいにち",
    "termTranslation": "every day",
    "ex": "私たちは毎日散歩をします。",
    "exReading": "わたしたち は まいにち さんぽ を します",
    "exTranslation": "We go for a walk every day.",
    "category": "Noun"
  },
  {
    "term": "毎年",
    "termReading": "まいとし",
    "termTranslation": "every year",
    "ex": "私は毎年、海外旅行に行きます。",
    "exReading": "わたし は まいとし かいがい りょこう に いきます",
    "exTranslation": "I travel abroad every year.",
    "category": "Noun"
  },
  {
    "term": "毎年",
    "termReading": "まいねん",
    "termTranslation": "every year",
    "ex": "毎年給料が上がる。",
    "exReading": "まいねん きゅうりょう が あがる",
    "exTranslation": "My salary goes up every year.",
    "category": "Noun"
  },
  {
    "term": "なお",
    "termReading": "なお",
    "termTranslation": "still, in addition",
    "ex": "なお、雨の場合は中止です。",
    "exReading": "なお あめ の ばあい は ちゅうし です",
    "exTranslation": "Moreover, it will be canceled in the event of rain.",
    "category": "Adverb"
  },
  {
    "term": "毎週",
    "termReading": "まいしゅう",
    "termTranslation": "every week",
    "ex": "私は毎週母に電話をします。",
    "exReading": "わたし は まいしゅう はは に でんわ を します",
    "exTranslation": "I call my mother every week.",
    "category": "Noun"
  },
  {
    "term": "毎月",
    "termReading": "まいつき",
    "termTranslation": "every month",
    "ex": "私は毎月貯金をしています。",
    "exReading": "わたし は まいつき ちょきん を して います",
    "exTranslation": "I save money every month.",
    "category": "Noun"
  },
  {
    "term": "石",
    "termReading": "いし",
    "termTranslation": "stone, small rock",
    "ex": "私は石につまづいた。",
    "exReading": "わたし は いし に つまづいた",
    "exTranslation": "I tripped on a stone.",
    "category": "Noun"
  },
  {
    "term": "田んぼ",
    "termReading": "たんぼ",
    "termTranslation": "rice field",
    "ex": "この辺は田んぼがたくさんあります。",
    "exReading": "このへん は たんぼ が たくさん あります",
    "exTranslation": "There are a lot of rice fields around here.",
    "category": "Noun"
  },
  {
    "term": "ほぼ",
    "termReading": "ほぼ",
    "termTranslation": "almost, nearly",
    "ex": "仕事がほぼ終わりました。",
    "exReading": "しごと が ほぼ おわりました",
    "exTranslation": "My work is almost finished.",
    "category": "Adverb"
  },
  {
    "term": "花",
    "termReading": "はな",
    "termTranslation": "flower",
    "ex": "きれいな花が咲きました。",
    "exReading": "きれい な はな が さきました",
    "exTranslation": "Beautiful flowers have bloomed.",
    "category": "Noun"
  },
  {
    "term": "林",
    "termReading": "はやし",
    "termTranslation": "woods, grove",
    "ex": "私たちは林の中に入っていった。",
    "exReading": "わたしたち は はやし の なか に はいって いった",
    "exTranslation": "We entered the woods.",
    "category": "Noun"
  },
  {
    "term": "森",
    "termReading": "もり",
    "termTranslation": "thick woods, forest",
    "ex": "私は森を歩くのが好きです。",
    "exReading": "わたし は もり を あるく の が すき です",
    "exTranslation": "I like to walk in the forest.",
    "category": "Noun"
  },
  {
    "term": "子",
    "termReading": "こ",
    "termTranslation": "child, kid",
    "ex": "その子は日本語が分からない。",
    "exReading": "その こ は にほんご が わからない",
    "exTranslation": "That kid doesn't understand Japanese.",
    "category": "Noun"
  },
  {
    "term": "サービス",
    "termReading": "サービス",
    "termTranslation": "service, on the house",
    "ex": "この店はサービスがいい。",
    "exReading": "この みせ は さーびす が いい 。",
    "exTranslation": "The service in this store is good.",
    "category": "Verbal Noun"
  },
  {
    "term": "女の子",
    "termReading": "おんなのこ",
    "termTranslation": "girl, daughter",
    "ex": "あの女の子を知っていますか。",
    "exReading": "あの おんなのこ を しって います か",
    "exTranslation": "Do you know that girl?",
    "category": "Noun"
  },
  {
    "term": "男の子",
    "termReading": "おとこのこ",
    "termTranslation": "boy, baby boy",
    "ex": "男の子たちがサッカーをしている。",
    "exReading": "おとこのこたち が さっかー を して いる",
    "exTranslation": "The boys are playing soccer.",
    "category": "Noun"
  },
  {
    "term": "私たち",
    "termReading": "わたしたち",
    "termTranslation": "we",
    "ex": "私たちは来月結婚します。",
    "exReading": "わたしたち は らいげつ けっこん します 。",
    "exTranslation": "We will get married next month.",
    "category": "Noun"
  },
  {
    "term": "達する",
    "termReading": "たっする",
    "termTranslation": "attain, reach",
    "ex": "気温は35度に達した。",
    "exReading": "きおん は さんじゅうごど に たっした",
    "exTranslation": "The temperature reached 35 degrees.",
    "category": "Verb"
  },
  {
    "term": "グループ",
    "termReading": "グループ",
    "termTranslation": "group",
    "ex": "店に学生のグループが来た。",
    "exReading": "みせ に がくせい の ぐるーぷ が きた",
    "exTranslation": "A group of students came to the shop.",
    "category": "Noun"
  },
  {
    "term": "家",
    "termReading": "いえ",
    "termTranslation": "house, home",
    "ex": "ここが私の家です。",
    "exReading": "ここ が わたし の いえ です",
    "exTranslation": "This is my house.",
    "category": "Noun"
  },
  {
    "term": "家内",
    "termReading": "かない",
    "termTranslation": "my wife",
    "ex": "家内は九州出身です。",
    "exReading": "かない は きゅうしゅう しゅっしん です",
    "exTranslation": "My wife is from Kyushu.",
    "category": "Noun"
  },
  {
    "term": "客",
    "termReading": "きゃく",
    "termTranslation": "visitor, customer",
    "ex": "その店は若い客が多いです。",
    "exReading": "その みせ は わかい きゃく が おおい です",
    "exTranslation": "That shop has a lot of young customers.",
    "category": "Noun"
  },
  {
    "term": "空気",
    "termReading": "くうき",
    "termTranslation": "air, atmosphere",
    "ex": "ここは空気がきれいです。",
    "exReading": "ここ は くうき が きれい です",
    "exTranslation": "The air here is clean.",
    "category": "Noun"
  },
  {
    "term": "ホテル",
    "termReading": "ホテル",
    "termTranslation": "hotel",
    "ex": "今ホテルに着きました。",
    "exReading": "いま ほてる に つきました",
    "exTranslation": "I arrived at the hotel just now.",
    "category": "Noun"
  },
  {
    "term": "気に入る",
    "termReading": "きにいる",
    "termTranslation": "like, be pleased with",
    "ex": "新しい靴がとても気に入りました。",
    "exReading": "あたらしい くつ が とても きにいりました",
    "exTranslation": "I really like my new shoes.",
    "category": "Verb"
  },
  {
    "term": "人気",
    "termReading": "にんき",
    "termTranslation": "popularity, temperament of the people, business conditions",
    "ex": "このバンドはとても人気があるよ。",
    "exReading": "この ばんど は とても にんき が ある よ",
    "exTranslation": "That band is very popular.",
    "category": "Noun"
  },
  {
    "term": "雨",
    "termReading": "あめ",
    "termTranslation": "rain",
    "ex": "雨が降っています。",
    "exReading": "あめ が ふって います",
    "exTranslation": "It's raining.",
    "category": "Noun"
  },
  {
    "term": "雪",
    "termReading": "ゆき",
    "termTranslation": "snow, snowfall",
    "ex": "クリスマスに雪が降りました。",
    "exReading": "くりすます に ゆき が ふりました",
    "exTranslation": "It snowed at Christmas.",
    "category": "Noun"
  },
  {
    "term": "まとめる",
    "termReading": "まとめる",
    "termTranslation": "gather together, put in order",
    "ex": "彼女は荷物をまとめて出て行った。",
    "exReading": "かのじょ は にもつ を まとめて でて いった",
    "exTranslation": "She packed her luggage and left.",
    "category": "Verb"
  },
  {
    "term": "青い",
    "termReading": "あおい",
    "termTranslation": "blue",
    "ex": "ここの海はとても青い。",
    "exReading": "ここ の うみ は とても あおい",
    "exTranslation": "The sea here is very blue.",
    "category": "Adjective"
  },
  {
    "term": "青",
    "termReading": "あお",
    "termTranslation": "blue, green, green light",
    "ex": "私の好きな色は青です。",
    "exReading": "わたし の すき な いろ は あお です",
    "exTranslation": "My favorite color is blue.",
    "category": "Noun"
  },
  {
    "term": "晴れ",
    "termReading": "はれ",
    "termTranslation": "fine weather, clear sky",
    "ex": "明日の天気は晴れです。",
    "exReading": "あす の てんき は はれ です",
    "exTranslation": "Tomorrow's weather will be sunny.",
    "category": "Noun"
  },
  {
    "term": "明らか",
    "termReading": "あきらか",
    "termTranslation": "clear, obvious",
    "ex": "明らかに彼が悪い。",
    "exReading": "あきらか に かれ が わるい",
    "exTranslation": "It's clearly his fault.",
    "category": "Adjective"
  },
  {
    "term": "やはり",
    "termReading": "やはり",
    "termTranslation": "as expected, also, still",
    "ex": "彼はやはり遅刻しました。",
    "exReading": "かれ は やはり ちこく しました",
    "exTranslation": "He was late, as expected.",
    "category": "Adverb"
  },
  {
    "term": "明るい",
    "termReading": "あかるい",
    "termTranslation": "bright, cheerful",
    "ex": "彼女は明るい性格です。",
    "exReading": "かのじょ は あかるい せいかく です",
    "exTranslation": "She has a cheerful personality.",
    "category": "Adjective"
  },
  {
    "term": "明日",
    "termReading": "あした",
    "termTranslation": "tomorrow",
    "ex": "明日、会社を休みます。",
    "exReading": "あした かいしゃ を やすみます",
    "exTranslation": "I'm off work tomorrow.",
    "category": "Noun"
  },
  {
    "term": "暗い",
    "termReading": "くらい",
    "termTranslation": "dark, dreary, shadowy",
    "ex": "東の空が暗いです。",
    "exReading": "ひがし の そら が くらい です",
    "exTranslation": "The eastern sky is dark.",
    "category": "Adjective"
  },
  {
    "term": "昨年",
    "termReading": "さくねん",
    "termTranslation": "last year",
    "ex": "昨年は地震が多い年でした。",
    "exReading": "さくねん は じしん が おおい とし でした",
    "exTranslation": "Last year there were a lot of earthquakes.",
    "category": "Noun"
  },
  {
    "term": "はっきり",
    "termReading": "はっきり",
    "termTranslation": "clearly",
    "ex": "今日は山がはっきり見える。",
    "exReading": "きょう は やま が はっきり みえる",
    "exTranslation": "You can see the mountains clearly today.",
    "category": "Adverb"
  },
  {
    "term": "一昨年",
    "termReading": "おととし",
    "termTranslation": "the year before last",
    "ex": "一昨年初めて京都へ旅行しました。",
    "exReading": "おととし はじめて きょうと へ りょこう しました",
    "exTranslation": "I first traveled to Kyoto the year before last.",
    "category": "Noun"
  },
  {
    "term": "一昨日",
    "termReading": "おととい",
    "termTranslation": "day before yesterday",
    "ex": "一昨日彼から電話がありました。",
    "exReading": "おととい かれ から でんわ が ありました",
    "exTranslation": "There was a phone call from him the day before yesterday.",
    "category": "Noun"
  },
  {
    "term": "東",
    "termReading": "ひがし",
    "termTranslation": "east, eastern",
    "ex": "東の空が暗いです。",
    "exReading": "ひがし の そら が くらい です",
    "exTranslation": "The eastern sky is dark.",
    "category": "Noun"
  },
  {
    "term": "西",
    "termReading": "にし",
    "termTranslation": "west, western",
    "ex": "太陽は西に沈みます。",
    "exReading": "たいよう は にし に しずみます",
    "exTranslation": "The sun sets in the west.",
    "category": "Noun"
  },
  {
    "term": "つまり",
    "termReading": "つまり",
    "termTranslation": "in short, basically",
    "ex": "つまり、あなたは何も知らないのですね。",
    "exReading": "つまり あなた は なに も しらない の です ね",
    "exTranslation": "So, basically, you don't know anything.",
    "category": "Adverb"
  },
  {
    "term": "南",
    "termReading": "みなみ",
    "termTranslation": "south",
    "ex": "私の家は町の南にあります。",
    "exReading": "わたし の いえ は まち の みなみ に あります",
    "exTranslation": "My house is in the south of the town.",
    "category": "Noun"
  },
  {
    "term": "北",
    "termReading": "きた",
    "termTranslation": "north",
    "ex": "ロシアは日本の北にあります。",
    "exReading": "ろしあ は にっぽん の きた に あります",
    "exTranslation": "Russia is north of Japan.",
    "category": "Noun"
  },
  {
    "term": "方向",
    "termReading": "ほうこう",
    "termTranslation": "direction, course",
    "ex": "あの人たちは皆、同じ方向を見ている。",
    "exReading": "あの ひとたち は みな おなじ ほうこう を みて いる",
    "exTranslation": "Those people are all looking in the same direction.",
    "category": "Noun"
  },
  {
    "term": "向かう",
    "termReading": "むかう",
    "termTranslation": "face, confront, head toward",
    "ex": "今、会社に向かっています。",
    "exReading": "いま かいしゃ に むかって います",
    "exTranslation": "I'm heading for the office right now.",
    "category": "Verb"
  },
  {
    "term": "ビル",
    "termReading": "ビル",
    "termTranslation": "building",
    "ex": "私の会社はあのビルの8階です。",
    "exReading": "わたし の かいしゃ は あの びる の はちかい です",
    "exTranslation": "My company's on the eighth floor of that building.",
    "category": "Noun"
  },
  {
    "term": "向こう",
    "termReading": "むこう",
    "termTranslation": "the other side, the other party, opponent",
    "ex": "友達は向こうにいます。",
    "exReading": "ともだち は むこう に います",
    "exTranslation": "My friend is over there.",
    "category": "Noun"
  },
  {
    "term": "向く",
    "termReading": "むく",
    "termTranslation": "turn, face, look",
    "ex": "こっちを向いてください。",
    "exReading": "こっち を むいて ください",
    "exTranslation": "Please turn this way.",
    "category": "Verb"
  },
  {
    "term": "開く",
    "termReading": "あく",
    "termTranslation": "open",
    "ex": "電車のドアが開きました。",
    "exReading": "でんしゃ の どあ が あきました",
    "exTranslation": "The train door opened.",
    "category": "Verb"
  },
  {
    "term": "聞こえる",
    "termReading": "きこえる",
    "termTranslation": "hear, be heard",
    "ex": "隣の部屋からテレビの音が聞こえる。",
    "exReading": "となり の へや から てれび の おと が きこえる",
    "exTranslation": "I can hear the sound of the television from the next room.",
    "category": "Verb"
  },
  {
    "term": "もちろん",
    "termReading": "もちろん",
    "termTranslation": "of course, no doubt",
    "ex": "もちろん一緒に行きます。",
    "exReading": "もちろん いっしょ に いきます",
    "exTranslation": "Of course I'll go with you.",
    "category": "Adverb"
  },
  {
    "term": "年間",
    "termReading": "ねんかん",
    "termTranslation": "period of one year",
    "ex": "年間5万人がここを訪れます。",
    "exReading": "ねんかん ごまんにん が ここ を おとずれます",
    "exTranslation": "This place gets 50,000 visitors a year.",
    "category": "Noun"
  },
  {
    "term": "この間",
    "termReading": "このあいだ",
    "termTranslation": "the other day, recently",
    "ex": "この間彼女に会った。",
    "exReading": "このあいだ かのじょ に あった",
    "exTranslation": "I met her the other day.",
    "category": "Noun"
  },
  {
    "term": "間",
    "termReading": "あいだ",
    "termTranslation": "interval",
    "ex": "雲の間から月が出た。",
    "exReading": "くも の あいだ から つき が でた",
    "exTranslation": "The moon came out from between the clouds.",
    "category": "Noun"
  },
  {
    "term": "人間",
    "termReading": "にんげん",
    "termTranslation": "human being, people",
    "ex": "人間の心は複雑です。",
    "exReading": "にんげん の こころ は ふくざつ です",
    "exTranslation": "Man's mind is complex.",
    "category": "Noun"
  },
  {
    "term": "かつて",
    "termReading": "かつて",
    "termTranslation": "once, formerly",
    "ex": "かつて私が学生だった頃のことです。",
    "exReading": "かつて わたし が がくせい だった ころ の こと です",
    "exTranslation": "That was when I was still a student.",
    "category": "Adverb"
  },
  {
    "term": "高さ",
    "termReading": "たかさ",
    "termTranslation": "height",
    "ex": "富士山の高さは3,776メートルです。",
    "exReading": "ふじさん の たかさ は さんぜんななひゃく-ななじゅうろくめーとる です",
    "exTranslation": "The height of Mt. Fuji is 3,776m.",
    "category": "Noun"
  },
  {
    "term": "最大",
    "termReading": "さいだい",
    "termTranslation": "biggest, largest",
    "ex": "これは世界最大の船です。",
    "exReading": "これ は せかい さいだい の ふね です",
    "exTranslation": "This is the world's largest ship.",
    "category": "Noun"
  },
  {
    "term": "初めて",
    "termReading": "はじめて",
    "termTranslation": "for the first time",
    "ex": "東京に来るのは初めてです。",
    "exReading": "とうきょう に くる の は はじめて です 。",
    "exTranslation": "It's the first time I've been to Tokyo.",
    "category": "Adverb"
  },
  {
    "term": "最初",
    "termReading": "さいしょ",
    "termTranslation": "first, outset",
    "ex": "5ページの最初を見てください。",
    "exReading": "ごぺーじ の さいしょ を みて ください",
    "exTranslation": "Please look at the beginning of page 5.",
    "category": "Noun"
  },
  {
    "term": "スポーツ",
    "termReading": "スポーツ",
    "termTranslation": "sport",
    "ex": "あなたは何かスポーツをしていますか。",
    "exReading": "あなた は なにか すぽーつ を して います か",
    "exTranslation": "Which sport do you play?",
    "category": "Noun"
  },
  {
    "term": "初め",
    "termReading": "はじめ",
    "termTranslation": "beginning",
    "ex": "初めは上手くできませんでした。",
    "exReading": "はじめ は うまく できません でした 。",
    "exTranslation": "I couldn't do it well at first.",
    "category": "Noun"
  },
  {
    "term": "今後",
    "termReading": "こんご",
    "termTranslation": "after this, in the future",
    "ex": "今後ともよろしくお願いします。",
    "exReading": "こんご とも よろしく おねがい します",
    "exTranslation": "I look forward to working with you in the future.",
    "category": "Noun"
  },
  {
    "term": "後",
    "termReading": "のち",
    "termTranslation": "after, afterwards",
    "ex": "後に彼は総理大臣になりました。",
    "exReading": "のち に かれ は そうり だいじん に なりました",
    "exTranslation": "He would later become Prime Minister.",
    "category": "Noun"
  },
  {
    "term": "最後",
    "termReading": "さいご",
    "termTranslation": "last, final",
    "ex": "今日が夏休み最後の日だ。",
    "exReading": "きょう が なつやすみ さいご の ひ だ",
    "exTranslation": "Today's the last day of summer vacation.",
    "category": "Noun"
  },
  {
    "term": "なぜ",
    "termReading": "なぜ",
    "termTranslation": "why, for what reason",
    "ex": "なぜ来なかったの。",
    "exReading": "なぜ こなかった の",
    "exTranslation": "Why didn't you come?",
    "category": "Adverb"
  },
  {
    "term": "明後日",
    "termReading": "あさって",
    "termTranslation": "day after tomorrow",
    "ex": "明後日は休日です。",
    "exReading": "あさって は きゅうじつ です 。",
    "exTranslation": "The day after tomorrow's a holiday.",
    "category": "Noun"
  },
  {
    "term": "牛",
    "termReading": "うし",
    "termTranslation": "cattle, cow",
    "ex": "牛が草を食べています。",
    "exReading": "うし が くさ を たべて います",
    "exTranslation": "The cow is eating grass.",
    "category": "Noun"
  },
  {
    "term": "半分",
    "termReading": "はんぶん",
    "termTranslation": "half",
    "ex": "お菓子を友達に半分あげた。",
    "exReading": "おかし を ともだち に はんぶん あげた",
    "exTranslation": "I gave half the sweets to a friend.",
    "category": "Noun"
  },
  {
    "term": "半年",
    "termReading": "はんとし",
    "termTranslation": "half a year",
    "ex": "日本に来て半年になります。",
    "exReading": "にっぽん に きて はんとし に なります",
    "exTranslation": "It has been half a year since I came to Japan.",
    "category": "Noun"
  },
  {
    "term": "そのまま",
    "termReading": "そのまま",
    "termTranslation": "like that, unchanged",
    "ex": "そのままお待ちください。",
    "exReading": "そのまま おまち ください",
    "exTranslation": "Please just wait a bit.",
    "category": "Adverb"
  },
  {
    "term": "半月",
    "termReading": "はんつき",
    "termTranslation": "half a month",
    "ex": "半月前に日本に来ました。",
    "exReading": "はんつきまえ に にほん に きました",
    "exTranslation": "I came to Japan half a month ago.",
    "category": "Noun"
  },
  {
    "term": "半日",
    "termReading": "はんにち",
    "termTranslation": "half a day",
    "ex": "今日は半日だけ仕事だ。",
    "exReading": "きょう は はんにち だけ しごと だ",
    "exTranslation": "I'm working only a half-day today.",
    "category": "Noun"
  },
  {
    "term": "毎朝",
    "termReading": "まいあさ",
    "termTranslation": "every morning",
    "ex": "私は毎朝ジョギングをします。",
    "exReading": "わたし は まいあさ じょぎんぐ を します",
    "exTranslation": "I jog every morning.",
    "category": "Noun"
  },
  {
    "term": "今朝",
    "termReading": "けさ",
    "termTranslation": "this morning",
    "ex": "今朝から頭が痛い。",
    "exReading": "けさ から あたま が いたい",
    "exTranslation": "I've had a headache since this morning.",
    "category": "Noun"
  },
  {
    "term": "もし",
    "termReading": "もし",
    "termTranslation": "if, in case of",
    "ex": "もし雨が降ったら、行きません。",
    "exReading": "もし あめ が ふったら いきません",
    "exTranslation": "I won't go if it rains.",
    "category": "Adverb"
  },
  {
    "term": "昼休み",
    "termReading": "ひるやすみ",
    "termTranslation": "noon recess, lunch break",
    "ex": "昼休みに公園に行った。",
    "exReading": "ひるやすみ に こうえん に いった",
    "exTranslation": "I went to the park at lunchtime.",
    "category": "Noun"
  },
  {
    "term": "昼前",
    "termReading": "ひるまえ",
    "termTranslation": "in the morning, before noon",
    "ex": "昼前に会議があった。",
    "exReading": "ひるまえ に かいぎ が あった",
    "exTranslation": "There was a meeting in the morning.",
    "category": "Noun"
  },
  {
    "term": "昼間",
    "termReading": "ひるま",
    "termTranslation": "daytime, day",
    "ex": "昼間は仕事で忙しいです。",
    "exReading": "ひるま は しごと で いそがしい です",
    "exTranslation": "I'm busy with work in the daytime.",
    "category": "Noun"
  },
  {
    "term": "毎晩",
    "termReading": "まいばん",
    "termTranslation": "every evening",
    "ex": "姉は毎晩日記を書いています。",
    "exReading": "あね は まいばん にっき を かいて います",
    "exTranslation": "My older sister writes in her diary every evening.",
    "category": "Noun"
  },
  {
    "term": "つもり",
    "termReading": "つもり",
    "termTranslation": "intention, purpose",
    "ex": "明日からタバコを止めるつもりです。",
    "exReading": "あした から たばこ を やめる つもり です",
    "exTranslation": "I'll stop smoking as of tomorrow.",
    "category": "Noun"
  },
  {
    "term": "今夜",
    "termReading": "こんや",
    "termTranslation": "tonight, this evening",
    "ex": "今夜は月がとてもきれいです。",
    "exReading": "こんや は つき が とても きれい です",
    "exTranslation": "The moon is very beautiful tonight.",
    "category": "Noun"
  },
  {
    "term": "昨夜",
    "termReading": "ゆうべ",
    "termTranslation": "last night, last evening",
    "ex": "昨夜、流れ星を見ました。",
    "exReading": "ゆうべ ながれぼし を みました",
    "exTranslation": "I saw some shooting stars last night.",
    "category": "Noun"
  },
  {
    "term": "夜中",
    "termReading": "よなか",
    "termTranslation": "midnight, dead of night",
    "ex": "夜中に電話がありました。",
    "exReading": "よなか に でんわ が ありました",
    "exTranslation": "I got a telephone call in the middle of the night.",
    "category": "Noun"
  },
  {
    "term": "夕方",
    "termReading": "ゆうがた",
    "termTranslation": "evening",
    "ex": "夕方そちらに着きます。",
    "exReading": "ゆうがた そちら に つきます",
    "exTranslation": "I'll arrive there in the evening.",
    "category": "Noun"
  },
  {
    "term": "やっと",
    "termReading": "やっと",
    "termTranslation": "at last, finally",
    "ex": "やっと仕事が終わりました。",
    "exReading": "やっと しごと が おわりました",
    "exTranslation": "Finally, I finished my work.",
    "category": "Adverb"
  },
  {
    "term": "昼食",
    "termReading": "ちゅうしょく",
    "termTranslation": "lunch",
    "ex": "昼食に寿司を食べました。",
    "exReading": "ちゅうしょく に すし を たべました",
    "exTranslation": "I ate sushi for lunch.",
    "category": "Noun"
  },
  {
    "term": "朝食",
    "termReading": "ちょうしょく",
    "termTranslation": "breakfast",
    "ex": "朝食に納豆を食べました。",
    "exReading": "ちょうしょく に なっとう を たべました",
    "exTranslation": "I ate natto for breakfast.",
    "category": "Noun"
  },
  {
    "term": "夕食",
    "termReading": "ゆうしょく",
    "termTranslation": "supper",
    "ex": "夕食は7時です。",
    "exReading": "ゆうしょく は しちじ です",
    "exTranslation": "Dinner's at seven o'clock.",
    "category": "Noun"
  },
  {
    "term": "夕飯",
    "termReading": "ゆうはん",
    "termTranslation": "evening meal, supper",
    "ex": "夕飯は寿司でした。",
    "exReading": "ゆうはん は すし でした",
    "exTranslation": "Dinner was sushi.",
    "category": "Noun"
  },
  {
    "term": "ニュース",
    "termReading": "ニュース",
    "termTranslation": "news",
    "ex": "夜のニュースを見ましたか。",
    "exReading": "よる の にゅーす を みました か",
    "exTranslation": "Did you see the evening news?",
    "category": "Noun"
  },
  {
    "term": "見方",
    "termReading": "みかた",
    "termTranslation": "view, way of looking",
    "ex": "彼に対する見方が変わりました。",
    "exReading": "かれ に たいする みかた が かわりました",
    "exTranslation": "My view of him has changed.",
    "category": "Noun"
  },
  {
    "term": "花見",
    "termReading": "はなみ",
    "termTranslation": "flower viewing",
    "ex": "友達と花見をしました。",
    "exReading": "ともだち と はなみ を しました",
    "exTranslation": "I went cherry blossom-viewing with a friend.",
    "category": "Noun"
  },
  {
    "term": "言い方",
    "termReading": "いいかた",
    "termTranslation": "way of speaking, expression",
    "ex": "そんな言い方をしてはいけません。",
    "exReading": "そんな いいかた を して は いけません",
    "exTranslation": "You mustn't talk like that.",
    "category": "Noun"
  },
  {
    "term": "話",
    "termReading": "はなし",
    "termTranslation": "talk, story",
    "ex": "あなたの話は面白いね。",
    "exReading": "あなた の はなし は おもしろい ね",
    "exTranslation": "Your story's interesting.",
    "category": "Noun"
  },
  {
    "term": "ずっと",
    "termReading": "ずっと",
    "termTranslation": "all day, from start to finish",
    "ex": "父は休みの日はずっとテレビを見ている。",
    "exReading": "ちち は やすみ の ひ は ずっと てれび を みて いる",
    "exTranslation": "My father spends his days off watching television all the time.",
    "category": "Adverb"
  },
  {
    "term": "読み",
    "termReading": "よみ",
    "termTranslation": "reading, judgment, foresight",
    "ex": "母に読み書きを習いました。",
    "exReading": "はは に よみかき を ならいました",
    "exTranslation": "I learned reading and writing from my mother.",
    "category": "Noun"
  },
  {
    "term": "読み方",
    "termReading": "よみかた",
    "termTranslation": "way of reading, reading",
    "ex": "この漢字の読み方を教えてください。",
    "exReading": "この かんじ の よみかた を おしえて ください",
    "exTranslation": "Please tell me how to read this Chinese character.",
    "category": "Noun"
  },
  {
    "term": "語る",
    "termReading": "かたる",
    "termTranslation": "tell, talk",
    "ex": "今夜は大いに語りましょう。",
    "exReading": "こんや は おおいに かたりましょう",
    "exTranslation": "Let's talk about everything tonight.",
    "category": "Verb"
  },
  {
    "term": "言語",
    "termReading": "げんご",
    "termTranslation": "language, speech",
    "ex": "彼はアジアの言語を研究している。",
    "exReading": "かれ は あじあ の げんご を けんきゅう して いる",
    "exTranslation": "He's doing research in Asian languages.",
    "category": "Noun"
  },
  {
    "term": "ビデオ",
    "termReading": "ビデオ",
    "termTranslation": "video",
    "ex": "私はその番組をビデオに撮った。",
    "exReading": "わたし は その ばんぐみ を びでお に とった",
    "exTranslation": "I recorded that program on video.",
    "category": "Noun"
  },
  {
    "term": "英語",
    "termReading": "えいご",
    "termTranslation": "English",
    "ex": "あなたは英語が話せますか。",
    "exReading": "あなた は えいご が はなせます か",
    "exTranslation": "Can you speak English?",
    "category": "Noun"
  },
  {
    "term": "文字",
    "termReading": "もじ",
    "termTranslation": "character, letter",
    "ex": "壁に文字が書いてあった。",
    "exReading": "かべ に もじ が かいて あった",
    "exTranslation": "There was some writing on the wall.",
    "category": "Noun"
  },
  {
    "term": "ローマ字",
    "termReading": "ろーまじ",
    "termTranslation": "Roman alphabet",
    "ex": "ローマ字で名前を書いてください。",
    "exReading": "ろーまじ で なまえ を かいて ください",
    "exTranslation": "Please write your name using Roman letters.",
    "category": "Noun"
  },
  {
    "term": "字",
    "termReading": "じ",
    "termTranslation": "character, letter",
    "ex": "もっと大きく字を書いてください。",
    "exReading": "もっと おおきく じ を かいて ください",
    "exTranslation": "Please write the letters larger.",
    "category": "Noun"
  },
  {
    "term": "マンション",
    "termReading": "マンション",
    "termTranslation": "apartment, apartment block, condominium",
    "ex": "彼はマンションに住んでいます。",
    "exReading": "かれ は まんしょん に すんで います",
    "exTranslation": "He lives in an apartment.",
    "category": "Noun"
  },
  {
    "term": "書き方",
    "termReading": "かきかた",
    "termTranslation": "how to write, way of writing",
    "ex": "彼はその漢字の書き方が分からない。",
    "exReading": "かれ は その かんじ の かきかた が わからない",
    "exTranslation": "He doesn't know how to write that Chinese character.",
    "category": "Noun"
  },
  {
    "term": "覚める",
    "termReading": "さめる",
    "termTranslation": "awake, wake up",
    "ex": "今朝は6時に目が覚めた。",
    "exReading": "けさ は ろくじ に め が さめた",
    "exTranslation": "I woke up at six o'clock this morning.",
    "category": "Verb"
  },
  {
    "term": "覚ます",
    "termReading": "さます",
    "termTranslation": "awaken, wake up",
    "ex": "子供が目を覚ました。",
    "exReading": "こども が め を さました",
    "exTranslation": "The child woke up.",
    "category": "Verb"
  },
  {
    "term": "大会",
    "termReading": "たいかい",
    "termTranslation": "mass meeting, tournament",
    "ex": "夏には川辺で花火大会があります。",
    "exReading": "なつ に は かわべ で はなびたいかい が あります",
    "exTranslation": "There are fireworks by the river in summer.",
    "category": "Noun"
  },
  {
    "term": "しばらく",
    "termReading": "しばらく",
    "termTranslation": "awhile, long time, for the time being",
    "ex": "そこでしばらく休んでいます。",
    "exReading": "そこで しばらく やすんで います",
    "exTranslation": "He's resting over there for a while.",
    "category": "Adverb"
  },
  {
    "term": "会話",
    "termReading": "かいわ",
    "termTranslation": "conversation",
    "ex": "親子の会話は大切です。",
    "exReading": "おやこ の かいわ は たいせつ です",
    "exTranslation": "Conversation between parents and children is important.",
    "category": "Verbal Noun"
  },
  {
    "term": "話し合う",
    "termReading": "はなしあう",
    "termTranslation": "discuss, talk over",
    "ex": "私たちはよく話し合いました。",
    "exReading": "わたしたち は よく はなしあいました",
    "exTranslation": "We often talked to each other.",
    "category": "Verb"
  },
  {
    "term": "合う",
    "termReading": "あう",
    "termTranslation": "fit",
    "ex": "この靴は私の足に合っている。",
    "exReading": "この くつ は わたし の あし に あって いる",
    "exTranslation": "These shoes fit me.",
    "category": "Verb"
  },
  {
    "term": "間に合う",
    "termReading": "まにあう",
    "termTranslation": "be in time, answer the purpose, can do without",
    "ex": "授業に間に合いました。",
    "exReading": "じゅぎょう に まにあいました",
    "exTranslation": "I made it in time for class.",
    "category": "Verb"
  },
  {
    "term": "ガス",
    "termReading": "ガス",
    "termTranslation": "gas",
    "ex": "地震でガスが止まった。",
    "exReading": "じしん で がす が とまった",
    "exTranslation": "The gas got cut off due to the earthquake.",
    "category": "Noun"
  },
  {
    "term": "会社",
    "termReading": "かいしゃ",
    "termTranslation": "company, corporation",
    "ex": "彼は小さな会社に勤めています。",
    "exReading": "かれ は ちいさ な かいしゃ に つとめて います",
    "exTranslation": "He's working for a small business firm.",
    "category": "Noun"
  },
  {
    "term": "社会",
    "termReading": "しゃかい",
    "termTranslation": "society",
    "ex": "これは大きな社会問題になっている。",
    "exReading": "これ は おおき な しゃかい もんだい に なって いる",
    "exTranslation": "This is a major social issue.",
    "category": "Noun"
  },
  {
    "term": "社員",
    "termReading": "しゃいん",
    "termTranslation": "staff member, employee",
    "ex": "彼は優秀な社員です。",
    "exReading": "かれ は ゆうしゅう な しゃいん です",
    "exTranslation": "He's an excellent employee.",
    "category": "Noun"
  },
  {
    "term": "仕方",
    "termReading": "しかた",
    "termTranslation": "way, method",
    "ex": "ファイルのダウンロードの仕方が分かりません。",
    "exReading": "ふぁいる の だうんろーど の しかた が わかりません",
    "exTranslation": "I don't know how to download the file.",
    "category": "Noun"
  },
  {
    "term": "うまい",
    "termReading": "うまい",
    "termTranslation": "good at",
    "ex": "彼は野球がうまい。",
    "exReading": "かれ は やきゅう が うまい",
    "exTranslation": "He's good at baseball.",
    "category": "Adjective"
  },
  {
    "term": "食事",
    "termReading": "しょくじ",
    "termTranslation": "meal, dinner",
    "ex": "今日は上司と食事をする。",
    "exReading": "きょう は じょうし と しょくじ を する",
    "exTranslation": "I'm eating with the boss today.",
    "category": "Verbal Noun"
  },
  {
    "term": "火事",
    "termReading": "かじ",
    "termTranslation": "fire",
    "ex": "火事です。119番に電話してください。",
    "exReading": "かじ です ひゃくじゅうきゅうばん に でんわ して ください",
    "exTranslation": "There's a fire. Please call 119.",
    "category": "Noun"
  },
  {
    "term": "大事",
    "termReading": "だいじ",
    "termTranslation": "importance",
    "ex": "お体をお大事に。",
    "exReading": "おからだ を おだいじ に",
    "exTranslation": "Take care of yourself.",
    "category": "Adjectival Noun"
  },
  {
    "term": "事故",
    "termReading": "じこ",
    "termTranslation": "accident, incident",
    "ex": "彼は事故で怪我をしました。",
    "exReading": "かれ は じこ で けが を しました",
    "exTranslation": "He was injured in the accident.",
    "category": "Noun"
  },
  {
    "term": "サラリーマン",
    "termReading": "サラリーマン",
    "termTranslation": "office worker, salaried worker, company employee",
    "ex": "父はサラリーマンです。",
    "exReading": "ちち は さらりーまん です",
    "exTranslation": "My father's a company employee.",
    "category": "Noun"
  },
  {
    "term": "工事",
    "termReading": "こうじ",
    "termTranslation": "construction",
    "ex": "工事の音がうるさい。",
    "exReading": "こうじ の おと が うるさい",
    "exTranslation": "The construction site is noisy.",
    "category": "Verbal Noun"
  },
  {
    "term": "工場",
    "termReading": "こうじょう",
    "termTranslation": "factory, plant",
    "ex": "彼は食品工場で働いています。",
    "exReading": "かれ は しょくひん こうじょう で はたらいて います",
    "exTranslation": "He's working at the food factory.",
    "category": "Noun"
  },
  {
    "term": "電話",
    "termReading": "でんわ",
    "termTranslation": "telephone, phone call",
    "ex": "あとで電話します。",
    "exReading": "あとで でんわ します",
    "exTranslation": "I will call you later.",
    "category": "Verbal Noun"
  },
  {
    "term": "電気",
    "termReading": "でんき",
    "termTranslation": "electricity, electric light",
    "ex": "電気をつけてください。",
    "exReading": "でんき をつけてください",
    "exTranslation": "Please turn on the light.",
    "category": "Noun"
  },
  {
    "term": "バス",
    "termReading": "バス",
    "termTranslation": "bus",
    "ex": "バスで行こう。",
    "exReading": "ばす で いこう",
    "exTranslation": "Let's go by bus.",
    "category": "Noun"
  },
  {
    "term": "車",
    "termReading": "くるま",
    "termTranslation": "car",
    "ex": "この道は車が多い。",
    "exReading": "この みち は くるま が おおい",
    "exTranslation": "There are many cars on this road.",
    "category": "Noun"
  },
  {
    "term": "駅員",
    "termReading": "えきいん",
    "termTranslation": "station employee",
    "ex": "駅員に聞きましょう。",
    "exReading": "えきいん に ききましょう",
    "exTranslation": "Let's ask the station employee.",
    "category": "Noun"
  },
  {
    "term": "通り",
    "termReading": "とおり",
    "termTranslation": "passage, street",
    "ex": "この通りはにぎやかですね。",
    "exReading": "この とおり は にぎやか です ね",
    "exTranslation": "This street is lively, isn't it?",
    "category": "Noun"
  },
  {
    "term": "通る",
    "termReading": "とおる",
    "termTranslation": "pass, take, go through",
    "ex": "毎日、この道を通ります。",
    "exReading": "まいにち この みち を とおります",
    "exTranslation": "I take this road every day.",
    "category": "Verb"
  },
  {
    "term": "クラス",
    "termReading": "クラス",
    "termTranslation": "class",
    "ex": "この学校は1クラス30人です。",
    "exReading": "この がっこう は 1 くらす 30 にん です 。",
    "exTranslation": "In this school there are 30 people per class.",
    "category": "Noun"
  },
  {
    "term": "通う",
    "termReading": "かよう",
    "termTranslation": "go to and from, frequent a place",
    "ex": "私はジムに通っています。",
    "exReading": "わたし は じむ に かよって います",
    "exTranslation": "I go to a gym.",
    "category": "Verb"
  },
  {
    "term": "交通事故",
    "termReading": "こうつうじこ",
    "termTranslation": "traffic accident",
    "ex": "彼は交通事故を起こした。",
    "exReading": "かれ は こうつうじこ を おこした",
    "exTranslation": "He caused a traffic accident.",
    "category": "Noun"
  },
  {
    "term": "水道",
    "termReading": "すいどう",
    "termTranslation": "water service, channel",
    "ex": "東京は水道の水が不味い。",
    "exReading": "とうきょう は すいどう の みず が まずい",
    "exTranslation": "Tap water in Tokyo doesn't taste good.",
    "category": "Noun"
  },
  {
    "term": "車道",
    "termReading": "しゃどう",
    "termTranslation": "road, roadway",
    "ex": "車道の工事が始まりました。",
    "exReading": "しゃどう の こうじ が はじまりました",
    "exTranslation": "The construction of the roadway started.",
    "category": "Noun"
  },
  {
    "term": "トラック",
    "termReading": "トラック",
    "termTranslation": "truck, lorry",
    "ex": "トラックを運転できますか。",
    "exReading": "とらっく を うんてん できます か",
    "exTranslation": "Can you drive a truck?",
    "category": "Noun"
  },
  {
    "term": "道路",
    "termReading": "どうろ",
    "termTranslation": "road, street",
    "ex": "この道路は3年前にできました。",
    "exReading": "この どうろ は さんねんまえ に できました",
    "exTranslation": "This road was completed three years ago.",
    "category": "Noun"
  },
  {
    "term": "土地",
    "termReading": "とち",
    "termTranslation": "land",
    "ex": "ここは父の土地です。",
    "exReading": "ここ は ちち の とち です",
    "exTranslation": "This is my father's land.",
    "category": "Noun"
  },
  {
    "term": "地図",
    "termReading": "ちず",
    "termTranslation": "map, atlas",
    "ex": "地図を見て来てください。",
    "exReading": "ちず を みて きて ください",
    "exTranslation": "Please look at the map to get here.",
    "category": "Noun"
  },
  {
    "term": "他",
    "termReading": "た",
    "termTranslation": "others, other things",
    "ex": "留学生はアジア人が多く、その他は3割です。",
    "exReading": "りゅうがくせい は あじあじん が おおく そのた は さんわり です",
    "exTranslation": "The majority of foreign students are Asian, with 30 percent from other regions.",
    "category": "Noun"
  },
  {
    "term": "パーティー",
    "termReading": "パーティー",
    "termTranslation": "party",
    "ex": "明日、うちでパーティーを開きます。",
    "exReading": "あした うち で ぱーてぃー を ひらきます",
    "exTranslation": "Tomorrow I'm having a party at my house.",
    "category": "Verbal Noun"
  },
  {
    "term": "止める",
    "termReading": "とめる",
    "termTranslation": "stop, turn off",
    "ex": "車を止めて。",
    "exReading": "くるま を とめて",
    "exTranslation": "Stop the car.",
    "category": "Verb"
  },
  {
    "term": "止まる",
    "termReading": "とまる",
    "termTranslation": "come to a stop, cease",
    "ex": "今朝、事故で電車が止まりました。",
    "exReading": "けさ じこ で でんしゃ が とまりました",
    "exTranslation": "The train stopped this morning due to an accident.",
    "category": "Verb"
  },
  {
    "term": "止む",
    "termReading": "やむ",
    "termTranslation": "stop, abate",
    "ex": "雨が止みました。",
    "exReading": "あめ が やみました",
    "exTranslation": "The rain stopped.",
    "category": "Verb"
  },
  {
    "term": "歩道",
    "termReading": "ほどう",
    "termTranslation": "sidewalk, footpath",
    "ex": "歩道を歩きましょう。",
    "exReading": "ほどう を あるきましょう",
    "exTranslation": "Let's walk on the pavement.",
    "category": "Noun"
  },
  {
    "term": "うまい",
    "termReading": "うまい",
    "termTranslation": "delicious, tasty",
    "ex": "うまい寿司屋を見つけたよ。",
    "exReading": "うまい すしや を みつけた よ",
    "exTranslation": "I found a good sushi shop.",
    "category": "Adjective"
  },
  {
    "term": "渡す",
    "termReading": "わたす",
    "termTranslation": "carry across, hand over",
    "ex": "彼に手紙を渡しました。",
    "exReading": "かれ に てがみ を わたしました",
    "exTranslation": "I handed him the letter.",
    "category": "Verb"
  },
  {
    "term": "渡る",
    "termReading": "わたる",
    "termTranslation": "cross, ford, go across",
    "ex": "私たちは歩いて橋を渡った。",
    "exReading": "わたしたち は あるいて はし を わたった",
    "exTranslation": "We walked over the bridge.",
    "category": "Verb"
  },
  {
    "term": "年度",
    "termReading": "ねんど",
    "termTranslation": "year, school year",
    "ex": "売り上げは年度によって違います。",
    "exReading": "うりあげ は ねんど に よって ちがいます",
    "exTranslation": "Sales differ by fiscal year.",
    "category": "Noun"
  },
  {
    "term": "今度",
    "termReading": "こんど",
    "termTranslation": "this time, next time",
    "ex": "今度はどこに行きたいですか。",
    "exReading": "こんど は どこ に いきたい です か",
    "exTranslation": "Where do you want to go this time?",
    "category": "Noun"
  },
  {
    "term": "ガラス",
    "termReading": "ガラス",
    "termTranslation": "glass",
    "ex": "クリスタルガラスの花瓶を買いました。",
    "exReading": "くりすたるがらす の かびん を かいました",
    "exTranslation": "I bought a crystal glass vase.",
    "category": "Noun"
  },
  {
    "term": "何度",
    "termReading": "なんど",
    "termTranslation": "how many degrees, how many times",
    "ex": "あの店には何度も行きました。",
    "exReading": "あの みせ に は なんど も いきました",
    "exTranslation": "I've been to that shop many times.",
    "category": "Noun"
  },
  {
    "term": "最近",
    "termReading": "さいきん",
    "termTranslation": "late, recent",
    "ex": "それは最近話題の本ですね。",
    "exReading": "それ は さいきん わだい の ほん です ね",
    "exTranslation": "That's the book that people have been talking about lately.",
    "category": "Noun"
  },
  {
    "term": "遠く",
    "termReading": "とおく",
    "termTranslation": "great distance",
    "ex": "遠くに船が見えます。",
    "exReading": "とおく に ふね が みえます",
    "exTranslation": "I can see a ship in the distance.",
    "category": "Adverb"
  },
  {
    "term": "社長",
    "termReading": "しゃちょう",
    "termTranslation": "president (of a company)",
    "ex": "おばは小さな会社の社長です。",
    "exReading": "おば は ちいさ な かいしゃ の しゃちょう です",
    "exTranslation": "My aunt is the president of a small firm.",
    "category": "Noun"
  },
  {
    "term": "コース",
    "termReading": "コース",
    "termTranslation": "course, route",
    "ex": "私は日本語コースを取っています。",
    "exReading": "わたし は にほんご こーす を とって います",
    "exTranslation": "I'm taking a Japanese course.",
    "category": "Noun"
  },
  {
    "term": "会長",
    "termReading": "かいちょう",
    "termTranslation": "president, chairperson",
    "ex": "初めに会長が挨拶した。",
    "exReading": "はじめ に かいちょう が あいさつ した",
    "exTranslation": "First was the chairman's greetings.",
    "category": "Noun"
  },
  {
    "term": "長さ",
    "termReading": "ながさ",
    "termTranslation": "length",
    "ex": "このケーブルの長さは1メートルです。",
    "exReading": "この けーぶる の ながさ は いちめーとる です",
    "exTranslation": "The length of this cable is one meter.",
    "category": "Noun"
  },
  {
    "term": "長男",
    "termReading": "ちょうなん",
    "termTranslation": "eldest son",
    "ex": "彼の長男は8才です。",
    "exReading": "かれ の ちょうなん は はっさい です",
    "exTranslation": "His oldest son is eight years old.",
    "category": "Noun"
  },
  {
    "term": "長女",
    "termReading": "ちょうじょ",
    "termTranslation": "eldest daughter",
    "ex": "うちの長女は10歳です。",
    "exReading": "うち の ちょうじょ は じゅっさい です",
    "exTranslation": "Our oldest daughter is ten.",
    "category": "Noun"
  },
  {
    "term": "アパート",
    "termReading": "アパート",
    "termTranslation": "apartment, flat, apartment house",
    "ex": "彼はアパートに住んでいます。",
    "exReading": "かれ は あぱーと に すんで います",
    "exTranslation": "He lives in an apartment.",
    "category": "Noun"
  },
  {
    "term": "広がる",
    "termReading": "ひろがる",
    "termTranslation": "spread out, reach",
    "ex": "留学してから私の世界が広がった。",
    "exReading": "りゅうがく して から わたし の せかい が ひろがった",
    "exTranslation": "My world expanded after studying abroad.",
    "category": "Verb"
  },
  {
    "term": "広さ",
    "termReading": "ひろさ",
    "termTranslation": "area, extent",
    "ex": "その家の広さはどれ位ですか。",
    "exReading": "その いえ の ひろさ は どれ くらい です か",
    "exTranslation": "How big is that house?",
    "category": "Noun"
  },
  {
    "term": "全体",
    "termReading": "ぜんたい",
    "termTranslation": "whole, total",
    "ex": "全体の80パーセントが完成しました。",
    "exReading": "ぜんたい の はちじゅっぱーせんと が かんせい しました",
    "exTranslation": "80 percent of the total was completed.",
    "category": "Noun"
  },
  {
    "term": "全く",
    "termReading": "まったく",
    "termTranslation": "entirely, truly, indeed",
    "ex": "私には全く分かりません。",
    "exReading": "わたし に は まったく わかりません",
    "exTranslation": "I don't understand at all.",
    "category": "Adverb"
  },
  {
    "term": "レコード",
    "termReading": "レコード",
    "termTranslation": "record",
    "ex": "ジャズのレコードをかけました。",
    "exReading": "じゃず の れこーど を かけました",
    "exTranslation": "I put on a jazz record.",
    "category": "Noun"
  },
  {
    "term": "安全",
    "termReading": "あんぜん",
    "termTranslation": "safety, security",
    "ex": "安全が第一です。",
    "exReading": "あんぜん が だいいち です",
    "exTranslation": "Safety comes first.",
    "category": "Adjectival Noun"
  },
  {
    "term": "一部",
    "termReading": "いちぶ",
    "termTranslation": "part",
    "ex": "計画を一部変更しましょう。",
    "exReading": "けいかく を いちぶ へんこう しましょう",
    "exTranslation": "Let's change part of the plan.",
    "category": "Noun"
  },
  {
    "term": "部分",
    "termReading": "ぶぶん",
    "termTranslation": "part, section",
    "ex": "この部分は問題ないです。",
    "exReading": "この ぶぶん は もんだい ない です",
    "exTranslation": "There's no problem with this part.",
    "category": "Noun"
  },
  {
    "term": "国内",
    "termReading": "こくない",
    "termTranslation": "domestic, internal",
    "ex": "この携帯電話が使えるのは国内だけです。",
    "exReading": "この けいたい でんわ が つかえる の は こくない だけ です",
    "exTranslation": "You can only use this mobile phone domestically.",
    "category": "Noun"
  },
  {
    "term": "どんどん",
    "termReading": "どんどん",
    "termTranslation": "knock",
    "ex": "ドアをどんどんとたたいた。",
    "exReading": "どあ を どんどん と たたいた",
    "exTranslation": "I banged on the door.",
    "category": "Adverb"
  },
  {
    "term": "全国",
    "termReading": "ぜんこく",
    "termTranslation": "the whole country",
    "ex": "次は全国のお天気です。",
    "exReading": "つぎ は ぜんこく の おてんき です",
    "exTranslation": "And now for the nation's weather.",
    "category": "Noun"
  },
  {
    "term": "外国",
    "termReading": "がいこく",
    "termTranslation": "foreign country",
    "ex": "母はまだ外国に行ったことがありません。",
    "exReading": "はは は まだ がいこく に いった こと が ありません",
    "exTranslation": "My mother hasn't been abroad yet.",
    "category": "Noun"
  },
  {
    "term": "国会",
    "termReading": "こっかい",
    "termTranslation": "National Diet, parliament",
    "ex": "国会が再開した。",
    "exReading": "こっかい が さいかい した",
    "exTranslation": "The Diet reconvened.",
    "category": "Noun"
  },
  {
    "term": "帰国",
    "termReading": "きこく",
    "termTranslation": "return to one's country",
    "ex": "彼は帰国しました。",
    "exReading": "かれ は きこく しました",
    "exTranslation": "He returned to his country.",
    "category": "Verbal Noun"
  },
  {
    "term": "カメラ",
    "termReading": "カメラ",
    "termTranslation": "camera",
    "ex": "カメラが壊れた。",
    "exReading": "かめら が こわれた",
    "exTranslation": "The camera broke.",
    "category": "Noun"
  },
  {
    "term": "外国人",
    "termReading": "がいこくじん",
    "termTranslation": "foreigner",
    "ex": "日本に住む外国人が増えています。",
    "exReading": "にほん に すむ がいこくじん が ふえて います",
    "exTranslation": "The number of foreigners living in Japan is increasing.",
    "category": "Noun"
  },
  {
    "term": "外国語",
    "termReading": "がいこくご",
    "termTranslation": "foreign language",
    "ex": "外国語を習うのは難しい。",
    "exReading": "がいこくご を ならう の は むずかしい",
    "exTranslation": "Learning foreign languages is hard.",
    "category": "Noun"
  },
  {
    "term": "世界",
    "termReading": "せかい",
    "termTranslation": "world",
    "ex": "私は世界旅行をしたい。",
    "exReading": "わたし は せかい りょこう を したい",
    "exTranslation": "I want to travel around the world.",
    "category": "Noun"
  },
  {
    "term": "白",
    "termReading": "しろ",
    "termTranslation": "white",
    "ex": "白は雪の色です。",
    "exReading": "しろ は ゆき の いろ です",
    "exTranslation": "White is the color of snow.",
    "category": "Noun"
  },
  {
    "term": "テープ",
    "termReading": "テープ",
    "termTranslation": "tape, cassette",
    "ex": "彼女はその会話をテープに録音した。",
    "exReading": "かのじょ は その かいわ を てーぷ に ろくおん した",
    "exTranslation": "She recorded the conversation on tape.",
    "category": "Noun"
  },
  {
    "term": "黒い",
    "termReading": "くろい",
    "termTranslation": "black, dark",
    "ex": "彼女は黒いドレスを着ています。",
    "exReading": "かのじょ は くろい どれす を きて います",
    "exTranslation": "She's wearing a black dress.",
    "category": "Adjective"
  },
  {
    "term": "黒",
    "termReading": "くろ",
    "termTranslation": "black",
    "ex": "黒のボールペンはありますか。",
    "exReading": "くろ の ぼーるぺん は あります か",
    "exTranslation": "Do you have a black pen?",
    "category": "Noun"
  },
  {
    "term": "赤ちゃん",
    "termReading": "あかちゃん",
    "termTranslation": "baby",
    "ex": "ベッドで赤ちゃんが眠っています。",
    "exReading": "べっど で あかちゃん が ねむって います",
    "exTranslation": "The baby's sleeping in the bed.",
    "category": "Noun"
  },
  {
    "term": "赤",
    "termReading": "あか",
    "termTranslation": "red, crimson",
    "ex": "信号が赤に変わりました。",
    "exReading": "しんごう が あか に かわりました",
    "exTranslation": "The traffic light changed to red.",
    "category": "Noun"
  },
  {
    "term": "ビール",
    "termReading": "ビール",
    "termTranslation": "beer, lager",
    "ex": "夏はビールがとても美味しい。",
    "exReading": "なつ は びーる が とても おいしい",
    "exTranslation": "Beer tastes really good in summer.",
    "category": "Noun"
  },
  {
    "term": "銀行",
    "termReading": "ぎんこう",
    "termTranslation": "bank",
    "ex": "銀行は3時まで開いています。",
    "exReading": "ぎんこう は さんじ まで あいて います",
    "exTranslation": "The bank is open until three o'clock.",
    "category": "Noun"
  },
  {
    "term": "銀",
    "termReading": "ぎん",
    "termTranslation": "silver",
    "ex": "彼は銀メダルを取った。",
    "exReading": "かれ は ぎんめだる を とった",
    "exTranslation": "He got the silver medal.",
    "category": "Noun"
  },
  {
    "term": "地下鉄",
    "termReading": "ちかてつ",
    "termTranslation": "subway",
    "ex": "私は地下鉄で通勤しています。",
    "exReading": "わたし は ちかてつ で つうきん して います",
    "exTranslation": "I commute to work by subway.",
    "category": "Noun"
  },
  {
    "term": "牛肉",
    "termReading": "ぎゅうにく",
    "termTranslation": "beef",
    "ex": "夕食に牛肉を買った。",
    "exReading": "ゆうしょく に ぎゅうにく を かった",
    "exTranslation": "I bought beef for dinner.",
    "category": "Noun"
  },
  {
    "term": "ページ",
    "termReading": "ページ",
    "termTranslation": "page",
    "ex": "32ページを開いてください。",
    "exReading": "さんじゅうにぺーじ を ひらいて ください",
    "exTranslation": "Please open to page 32.",
    "category": "Noun"
  },
  {
    "term": "肉",
    "termReading": "にく",
    "termTranslation": "flesh, meat",
    "ex": "肉が焼けました。",
    "exReading": "にく が やけました",
    "exTranslation": "The meat is cooked.",
    "category": "Noun"
  },
  {
    "term": "魚",
    "termReading": "さかな",
    "termTranslation": "fish",
    "ex": "肉と魚とどちらが好きですか。",
    "exReading": "にく と さかな と どちら が すき です か",
    "exTranslation": "Which do you prefer, meat or fish?",
    "category": "Noun"
  },
  {
    "term": "分野",
    "termReading": "ぶんや",
    "termTranslation": "field, sphere",
    "ex": "音楽は彼の得意な分野です。",
    "exReading": "おんがく は かれ の とくい な ぶんや です",
    "exTranslation": "Music is his field of specialty.",
    "category": "Noun"
  },
  {
    "term": "野菜",
    "termReading": "やさい",
    "termTranslation": "vegetable",
    "ex": "私は毎日たくさん野菜を食べます。",
    "exReading": "わたし は まいにち たくさん やさい を たべます",
    "exTranslation": "I eat a lot of vegetables every day.",
    "category": "Noun"
  },
  {
    "term": "グラフ",
    "termReading": "グラフ",
    "termTranslation": "chart, graph",
    "ex": "彼は売上をグラフにした。",
    "exReading": "かれ は うりあげ を ぐらふ に した",
    "exTranslation": "He made a chart of the sales figures.",
    "category": "Noun"
  },
  {
    "term": "本屋",
    "termReading": "ほんや",
    "termTranslation": "bookstore, bookseller",
    "ex": "駅前に本屋があります。",
    "exReading": "えきまえ に ほんや が あります",
    "exTranslation": "There's a bookstore in front of the station.",
    "category": "Noun"
  },
  {
    "term": "八百屋",
    "termReading": "やおや",
    "termTranslation": "vegetable store, greengrocer",
    "ex": "八百屋でみかんを買いました。",
    "exReading": "やおや で みかん を かいました",
    "exTranslation": "I bought a tangerine at the grocery store.",
    "category": "Noun"
  },
  {
    "term": "そば屋",
    "termReading": "そばや",
    "termTranslation": "soba restaurant",
    "ex": "昼はそば屋に行きました。",
    "exReading": "ひる は そばや に いきました 。",
    "exTranslation": "I went to a noodle shop for lunch.",
    "category": "Noun"
  },
  {
    "term": "たばこ屋",
    "termReading": "たばこや",
    "termTranslation": "cigarette shop, tobacco store",
    "ex": "私はたばこ屋でライターを買った。",
    "exReading": "わたし は たばこや で らいたー を かった",
    "exTranslation": "I bought a lighter from the cigarette shop.",
    "category": "Noun"
  },
  {
    "term": "ポスト",
    "termReading": "ポスト",
    "termTranslation": "post box, letter box",
    "ex": "手紙をポストに入れました。",
    "exReading": "てがみ を ぽすと に いれました",
    "exTranslation": "I put the letter in the mailbox.",
    "category": "Noun"
  },
  {
    "term": "茶",
    "termReading": "ちゃ",
    "termTranslation": "tea (plain form)",
    "ex": "私たちは毎日お茶を飲みます。",
    "exReading": "わたしたち は まいにち おちゃ を のみます",
    "exTranslation": "We drink tea every day.",
    "category": "Noun"
  },
  {
    "term": "お茶",
    "termReading": "おちゃ",
    "termTranslation": "tea (polite)",
    "ex": "お茶を入れましょうか。",
    "exReading": "おちゃ を いれましょう か",
    "exTranslation": "Shall I make tea?",
    "category": "Noun"
  },
  {
    "term": "茶わん",
    "termReading": "ちゃわん",
    "termTranslation": "tea cup, rice bowl",
    "ex": "茶わんにご飯をよそいました。",
    "exReading": "ちゃわん に ごはん を よそいました",
    "exTranslation": "I put some rice into the rice bowl.",
    "category": "Noun"
  },
  {
    "term": "味",
    "termReading": "あじ",
    "termTranslation": "taste, flavor",
    "ex": "この料理は味が薄い。",
    "exReading": "この りょうり は あじ が うすい",
    "exTranslation": "This food is tasteless.",
    "category": "Noun"
  },
  {
    "term": "テスト",
    "termReading": "テスト",
    "termTranslation": "test, exam",
    "ex": "テストを始めてください。",
    "exReading": "てすと を はじめて ください",
    "exTranslation": "Please start the test.",
    "category": "Verbal Noun"
  },
  {
    "term": "未来",
    "termReading": "みらい",
    "termTranslation": "future",
    "ex": "未来は誰にも分からない。",
    "exReading": "みらい は だれ に も わからない",
    "exTranslation": "Nobody knows the future.",
    "category": "Noun"
  },
  {
    "term": "週末",
    "termReading": "しゅうまつ",
    "termTranslation": "weekend",
    "ex": "週末は家でゆっくりします。",
    "exReading": "しゅうまつ は うち で ゆっくり します",
    "exTranslation": "On the weekend I relax at home.",
    "category": "Noun"
  },
  {
    "term": "料理",
    "termReading": "りょうり",
    "termTranslation": "cooking, handling",
    "ex": "母は料理が得意です。",
    "exReading": "はは は りょうり が とくい です",
    "exTranslation": "My mother is good at cooking.",
    "category": "Verbal Noun"
  },
  {
    "term": "無理",
    "termReading": "むり",
    "termTranslation": "unreasonable, impossible, forced",
    "ex": "5時までに家に帰るのは無理です。",
    "exReading": "ごじ まで に いえ に かえる の は むり です",
    "exTranslation": "I can't possibly get home by five o'clock.",
    "category": "Adjectival Noun"
  },
  {
    "term": "あちこち",
    "termReading": "あちこち",
    "termTranslation": "all over",
    "ex": "私たちは朝からあちこち散歩しました。",
    "exReading": "わたしたち は あさ から あちこち さんぽ しました",
    "exTranslation": "We strolled about here and there from the morning.",
    "category": "Noun"
  },
  {
    "term": "無くす",
    "termReading": "なくす",
    "termTranslation": "lose, get rid of",
    "ex": "今日、鍵を無くしました。",
    "exReading": "きょう かぎ を なくしました",
    "exTranslation": "I lost my key today.",
    "category": "Verb"
  },
  {
    "term": "無くなる",
    "termReading": "なくなる",
    "termTranslation": "disappear, run short, get lost",
    "ex": "もうお金が無くなりました。",
    "exReading": "もう おかね が なくなりました",
    "exTranslation": "I've run out of money already.",
    "category": "Verb"
  },
  {
    "term": "作文",
    "termReading": "さくぶん",
    "termTranslation": "composition, essay",
    "ex": "日本語で作文を書きました。",
    "exReading": "にほんご で さくぶん を かきました",
    "exTranslation": "I wrote an essay in Japanese.",
    "category": "Noun"
  },
  {
    "term": "用いる",
    "termReading": "もちいる",
    "termTranslation": "use, make use of",
    "ex": "彼はその詩を用いて自分の気持ちを伝えた。",
    "exReading": "かれ は その し を もちいて じぶん の きもち を つたえた",
    "exTranslation": "He conveyed his feelings through poetry.",
    "category": "Verb"
  },
  {
    "term": "ゴルフ",
    "termReading": "ゴルフ",
    "termTranslation": "golf",
    "ex": "兄はゴルフを始めました。",
    "exReading": "あに は ごるふ を はじめました",
    "exTranslation": "My older brother started playing golf.",
    "category": "Noun"
  },
  {
    "term": "用事",
    "termReading": "ようじ",
    "termTranslation": "things to do, errand, business",
    "ex": "父は用事で出掛けています。",
    "exReading": "ちち は ようじ で でかけて います",
    "exTranslation": "My father is out running errands.",
    "category": "Noun"
  },
  {
    "term": "交通費",
    "termReading": "こうつうひ",
    "termTranslation": "traveling expense, transportation cost",
    "ex": "会社までの交通費は一ヶ月8,000円です。",
    "exReading": "かいしゃ まで の こうつうひ は いっかげつ はっせんえん です",
    "exTranslation": "Transportation to the company costs 8,000 yen a month.",
    "category": "Noun"
  },
  {
    "term": "消える",
    "termReading": "きえる",
    "termTranslation": "be extinguished, disappear",
    "ex": "突然、電気が消えた。",
    "exReading": "とつぜん でんき が きえた",
    "exTranslation": "Suddenly the electricity went out.",
    "category": "Verb"
  },
  {
    "term": "消しゴム",
    "termReading": "けしごむ",
    "termTranslation": "eraser",
    "ex": "消しゴムを貸して下さい。",
    "exReading": "けしごむ を かして ください",
    "exTranslation": "Please lend me your eraser.",
    "category": "Noun"
  },
  {
    "term": "ラジオ",
    "termReading": "ラジオ",
    "termTranslation": "radio",
    "ex": "彼女はラジオを聞いています。",
    "exReading": "かのじょ は らじお を きいて います",
    "exTranslation": "She's listening to the radio.",
    "category": "Noun"
  },
  {
    "term": "売れる",
    "termReading": "うれる",
    "termTranslation": "sell, be in demand",
    "ex": "今年の夏はクーラーがよく売れた。",
    "exReading": "ことし の なつ は くーらー が よく うれた",
    "exTranslation": "Air conditioners sold well this summer.",
    "category": "Verb"
  },
  {
    "term": "売り場",
    "termReading": "うりば",
    "termTranslation": "sales floor, department",
    "ex": "くつ売り場はどこですか。",
    "exReading": "くつうりば は どこ です か",
    "exTranslation": "Where is the shoe department?",
    "category": "Noun"
  },
  {
    "term": "店員",
    "termReading": "てんいん",
    "termTranslation": "clerk, salesperson",
    "ex": "あの店員はとても親切です。",
    "exReading": "あの てんいん は とても しんせつ です",
    "exTranslation": "That shop clerk is very kind.",
    "category": "Noun"
  },
  {
    "term": "売店",
    "termReading": "ばいてん",
    "termTranslation": "booth, store",
    "ex": "駅の売店で雑誌を買った。",
    "exReading": "えき の ばいてん で ざっし を かった",
    "exTranslation": "I bought a magazine at a shop in the station.",
    "category": "Noun"
  },
  {
    "term": "タクシー",
    "termReading": "タクシー",
    "termTranslation": "taxi, cab",
    "ex": "タクシーを呼んでください。",
    "exReading": "たくしー を よんで ください",
    "exTranslation": "Please call a taxi.",
    "category": "Noun"
  },
  {
    "term": "商品",
    "termReading": "しょうひん",
    "termTranslation": "goods, commodities",
    "ex": "この商品はよく売れている。",
    "exReading": "この しょうひん は よく うれて いる",
    "exTranslation": "This product is selling well.",
    "category": "Noun"
  },
  {
    "term": "作品",
    "termReading": "さくひん",
    "termTranslation": "work of art, piece",
    "ex": "この絵はゴッホの作品だ。",
    "exReading": "この え は ごっほ の さくひん だ",
    "exTranslation": "This painting is one of van Gogh's works.",
    "category": "Noun"
  },
  {
    "term": "販売",
    "termReading": "はんばい",
    "termTranslation": "sale, selling",
    "ex": "前売券は窓口で販売しています。",
    "exReading": "まえうりけん は まどぐち で はんばい して います",
    "exTranslation": "Advance tickets are being sold at the ticket window.",
    "category": "Verbal Noun"
  },
  {
    "term": "二階",
    "termReading": "にかい",
    "termTranslation": "second floor, upstairs",
    "ex": "兄は二階にいます。",
    "exReading": "あに は にかい に います",
    "exTranslation": "My elder brother is on the second floor.",
    "category": "Noun"
  },
  {
    "term": "ゆっくり",
    "termReading": "ゆっくり",
    "termTranslation": "slowly",
    "ex": "もっとゆっくり話してください。",
    "exReading": "もっと ゆっくり はなして ください",
    "exTranslation": "Please speak more slowly.",
    "category": "Adverb"
  },
  {
    "term": "段階",
    "termReading": "だんかい",
    "termTranslation": "stage",
    "ex": "この段階では、決断するのはまだ早い。",
    "exReading": "この だんかい で は けつだん する の は まだ はやい",
    "exTranslation": "At this stage, it is still early to decide.",
    "category": "Noun"
  },
  {
    "term": "階段",
    "termReading": "かいだん",
    "termTranslation": "steps, flight of stairs",
    "ex": "私たちは駅の階段をかけ上がった。",
    "exReading": "わたしたち は えき の かいだん を かけあがった",
    "exTranslation": "We ran up the station steps.",
    "category": "Noun"
  },
  {
    "term": "段々",
    "termReading": "だんだん",
    "termTranslation": "gradually, one after another",
    "ex": "段々仕事が楽しくなってきました。",
    "exReading": "だんだん しごと が たのしく なって きました",
    "exTranslation": "My work has gradually become more enjoyable.",
    "category": "Adverb"
  },
  {
    "term": "値段",
    "termReading": "ねだん",
    "termTranslation": "price",
    "ex": "このベルトの値段は2500円でした。",
    "exReading": "この べると の ねだん は にせんごひゃくえん でした",
    "exTranslation": "The price of this belt was 2,500 yen.",
    "category": "Noun"
  },
  {
    "term": "レストラン",
    "termReading": "レストラン",
    "termTranslation": "restaurant",
    "ex": "レストランでインド料理を食べました。",
    "exReading": "れすとらん で いんど りょうり を たべました",
    "exTranslation": "I ate Indian food at the restaurant.",
    "category": "Noun"
  },
  {
    "term": "価格",
    "termReading": "かかく",
    "termTranslation": "price, cost",
    "ex": "ガソリンの価格がどんどん上がっている。",
    "exReading": "がそりん の かかく が どんどん あがって いる",
    "exTranslation": "The price of gasoline keeps going up fast.",
    "category": "Noun"
  },
  {
    "term": "合格",
    "termReading": "ごうかく",
    "termTranslation": "passing an examination, eligibility",
    "ex": "娘が入学試験に合格しました。",
    "exReading": "むすめ が にゅうがく しけん に ごうかく しました",
    "exTranslation": "My daughter passed the university entrance exam.",
    "category": "Verbal Noun"
  },
  {
    "term": "夏休み",
    "termReading": "なつやすみ",
    "termTranslation": "summer vacation",
    "ex": "今日が夏休み最後の日だ。",
    "exReading": "きょう が なつやすみ さいご の ひ だ",
    "exTranslation": "Today's the last day of summer vacation.",
    "category": "Noun"
  },
  {
    "term": "冬休み",
    "termReading": "ふゆやすみ",
    "termTranslation": "winter vacation",
    "ex": "冬休みにお祖父ちゃんの家に行きます。",
    "exReading": "ふゆやすみ に おじいちゃん の うち に いきます",
    "exTranslation": "I'm going to grandpa's house during winter break.",
    "category": "Noun"
  },
  {
    "term": "カード",
    "termReading": "カード",
    "termTranslation": "card, credit card",
    "ex": "支払いはカードでお願いします。",
    "exReading": "しはらい は かーど で おねがい します",
    "exTranslation": "Please pay by card.",
    "category": "Noun"
  },
  {
    "term": "四季",
    "termReading": "しき",
    "termTranslation": "the four seasons",
    "ex": "日本には四季がある。",
    "exReading": "にほん に は しき が ある",
    "exTranslation": "We have four seasons in Japan.",
    "category": "Noun"
  },
  {
    "term": "暑さ",
    "termReading": "あつさ",
    "termTranslation": "heat, summer heat",
    "ex": "今年の夏は暑さが厳しい。",
    "exReading": "ことし の なつ は あつさ が きびしい",
    "exTranslation": "The heat this summer is overbearing.",
    "category": "Noun"
  },
  {
    "term": "熱",
    "termReading": "ねつ",
    "termTranslation": "heat, fever",
    "ex": "昨日の夜、熱が出ました。",
    "exReading": "きのう の よる ねつ が でました",
    "exTranslation": "I had a fever last night.",
    "category": "Noun"
  },
  {
    "term": "寒さ",
    "termReading": "さむさ",
    "termTranslation": "coldness, cold",
    "ex": "今日は厳しい寒さになるでしょう。",
    "exReading": "きょう は きびしい さむさ に なる でしょう",
    "exTranslation": "It will probably be extremely cold today.",
    "category": "Noun"
  },
  {
    "term": "アルバイト",
    "termReading": "アルバイト",
    "termTranslation": "casual job, student job, part-time job",
    "ex": "兄はアルバイトをしています。",
    "exReading": "あに は あるばいと を して います",
    "exTranslation": "My big brother works part-time.",
    "category": "Verbal Noun"
  },
  {
    "term": "暖める",
    "termReading": "あたためる",
    "termTranslation": "warm, warm up, heat up",
    "ex": "今、車を暖めています。",
    "exReading": "いま くるま を あたためています",
    "exTranslation": "I'm warming up the car now.",
    "category": "Verb"
  },
  {
    "term": "暖まる",
    "termReading": "あたたまる",
    "termTranslation": "get warm",
    "ex": "まだ部屋が暖まらない。",
    "exReading": "まだ へや が あたたまらない。",
    "exTranslation": "The room hasn't warmed up yet.",
    "category": "Verb"
  },
  {
    "term": "温度",
    "termReading": "おんど",
    "termTranslation": "temperature",
    "ex": "今、部屋の温度は25度だ。",
    "exReading": "いま へや の おんど は にじゅうごど だ",
    "exTranslation": "This room's temperature is currently 25 degrees.",
    "category": "Noun"
  },
  {
    "term": "気温",
    "termReading": "きおん",
    "termTranslation": "temperature",
    "ex": "今日の気温は26度です。",
    "exReading": "きょう の きおん は にじゅうろくど です",
    "exTranslation": "Today's temperature is 26 degrees.",
    "category": "Noun"
  },
  {
    "term": "コピー",
    "termReading": "コピー",
    "termTranslation": "copy, photocopy",
    "ex": "会議で書類のコピーを配った。",
    "exReading": "かいぎ で しょるい の こぴー を くばった",
    "exTranslation": "I handed out copies of the document at the meeting.",
    "category": "Verbal Noun"
  },
  {
    "term": "台",
    "termReading": "だい",
    "termTranslation": "stand, platform",
    "ex": "そこにちょうど良い台がある。",
    "exReading": "そこ に ちょうど いい だい が ある",
    "exTranslation": "There's a table that's just right.",
    "category": "Noun"
  },
  {
    "term": "風",
    "termReading": "かぜ",
    "termTranslation": "wind",
    "ex": "今日は風が強いです。",
    "exReading": "きょう は かぜ が つよい です",
    "exTranslation": "The wind is strong today.",
    "category": "Noun"
  },
  {
    "term": "台風",
    "termReading": "たいふう",
    "termTranslation": "typhoon",
    "ex": "台風が近づいている。",
    "exReading": "たいふう が ちかづいて いる",
    "exTranslation": "The typhoon's getting closer.",
    "category": "Noun"
  },
  {
    "term": "事情",
    "termReading": "じじょう",
    "termTranslation": "circumstances, conditions",
    "ex": "あなたの事情はよく分かりました。",
    "exReading": "あなた の じじょう は よく わかりました",
    "exTranslation": "I understand your situation well.",
    "category": "Noun"
  },
  {
    "term": "ぶつかる",
    "termReading": "ぶつかる",
    "termTranslation": "hit, bump into",
    "ex": "車が電柱にぶつかった。",
    "exReading": "くるま が でんちゅう に ぶつかった",
    "exTranslation": "The car crashed into the telephone pole.",
    "category": "Verb"
  },
  {
    "term": "情報",
    "termReading": "じょうほう",
    "termTranslation": "information",
    "ex": "学生たちはインターネットでいろいろな情報を集めた。",
    "exReading": "がくせいたち は いんたーねっと で いろいろな じょうほう を あつめた",
    "exTranslation": "The students gathered all kinds of information on the Internet.",
    "category": "Noun"
  },
  {
    "term": "報告",
    "termReading": "ほうこく",
    "termTranslation": "report",
    "ex": "昨日の会議について報告があります。",
    "exReading": "きのう の かいぎ に ついて ほうこく が あります",
    "exTranslation": "There's a report about yesterday's conference.",
    "category": "Verbal Noun"
  },
  {
    "term": "新聞",
    "termReading": "しんぶん",
    "termTranslation": "newspaper",
    "ex": "今日の新聞、どこに置いた?",
    "exReading": "きょう の しんぶん どこ に おいた",
    "exTranslation": "Where did you put today's newspaper?",
    "category": "Noun"
  },
  {
    "term": "新年",
    "termReading": "しんねん",
    "termTranslation": "New Year",
    "ex": "新年明けましておめでとうございます。",
    "exReading": "しんねん あけまして おめでとう ございます",
    "exTranslation": "Happy new year.",
    "category": "Noun"
  },
  {
    "term": "フィルム",
    "termReading": "フィルム",
    "termTranslation": "film",
    "ex": "旅行のためにたくさんフィルムを買った。",
    "exReading": "りょこう の ため に たくさん ふぃるむ を かった",
    "exTranslation": "I bought a lot of film for the trip.",
    "category": "Noun"
  },
  {
    "term": "良い",
    "termReading": "よい",
    "termTranslation": "good, suitable, preferable",
    "ex": "彼は良いところだけを強調した。",
    "exReading": "かれ は よい ところ だけ を きょうちょう した",
    "exTranslation": "He only emphasized the strong points.",
    "category": "Adjective"
  },
  {
    "term": "中心",
    "termReading": "ちゅうしん",
    "termTranslation": "center, middle",
    "ex": "ここが建物の中心です。",
    "exReading": "ここ が たてもの の ちゅうしん です",
    "exTranslation": "This is the center of the building.",
    "category": "Noun"
  },
  {
    "term": "安心",
    "termReading": "あんしん",
    "termTranslation": "peace of mind, relief",
    "ex": "それを聞いて安心しました。",
    "exReading": "それ を きいて あんしん しました",
    "exTranslation": "I was relieved to hear that.",
    "category": "Verbal Noun"
  },
  {
    "term": "思い出す",
    "termReading": "おもいだす",
    "termTranslation": "recollect, recall",
    "ex": "大切な用事を思い出しました。",
    "exReading": "たいせつ な ようじ を おもいだしました",
    "exTranslation": "I just remembered something important I need to take care of.",
    "category": "Verb"
  },
  {
    "term": "デパート",
    "termReading": "デパート",
    "termTranslation": "department store, department",
    "ex": "私はデパートで靴を買った。",
    "exReading": "わたし は でぱーと で くつ を かった",
    "exTranslation": "I bought shoes at the department store.",
    "category": "Noun"
  },
  {
    "term": "思い出",
    "termReading": "おもいで",
    "termTranslation": "recollections, memory",
    "ex": "旅行で楽しい思い出ができました。",
    "exReading": "りょこう で たのしい おもいで が できました",
    "exTranslation": "I made wonderful memories during my trip.",
    "category": "Noun"
  },
  {
    "term": "考え",
    "termReading": "かんがえ",
    "termTranslation": "thought, view, idea",
    "ex": "それは良い考えです。",
    "exReading": "それ は いい かんがえ です",
    "exTranslation": "That's a good idea.",
    "category": "Noun"
  },
  {
    "term": "解決",
    "termReading": "かいけつ",
    "termTranslation": "solution, settlement",
    "ex": "トラブルがやっと解決した。",
    "exReading": "とらぶる が やっと かいけつ した",
    "exTranslation": "The problem has finally been solved.",
    "category": "Verbal Noun"
  },
  {
    "term": "知らせる",
    "termReading": "しらせる",
    "termTranslation": "let know, inform",
    "ex": "皆に会議の日にちを知らせた。",
    "exReading": "みんな に かいぎ の ひにち を しらせた",
    "exTranslation": "I informed everyone of the day of the meeting.",
    "category": "Verb"
  },
  {
    "term": "ベッド",
    "termReading": "ベッド",
    "termTranslation": "bed",
    "ex": "彼はベッドで寝ています。",
    "exReading": "かれ は べっど で ねて います",
    "exTranslation": "He's sleeping in the bed.",
    "category": "Noun"
  },
  {
    "term": "能力",
    "termReading": "のうりょく",
    "termTranslation": "ability, capacity",
    "ex": "彼は能力のある社員です。",
    "exReading": "かれ は のうりょく の ある しゃいん です",
    "exTranslation": "He's a resourceful employee.",
    "category": "Noun"
  },
  {
    "term": "可能",
    "termReading": "かのう",
    "termTranslation": "possible, potential",
    "ex": "20キロのダイエットは可能だと思いますか。",
    "exReading": "にじゅっきろ の だいえっと は かのう だ と おもいます か",
    "exTranslation": "Do you think it's possible to go on a twenty kilogram diet?",
    "category": "Adjectival Noun"
  },
  {
    "term": "可",
    "termReading": "か",
    "termTranslation": "permitted, OK",
    "ex": "このアルバイトは「学生可」ですね。",
    "exReading": "この あるばいと は がくせい か です ね",
    "exTranslation": "So, students can apply for this part-time job, right?",
    "category": "Noun"
  },
  {
    "term": "郵便",
    "termReading": "ゆうびん",
    "termTranslation": "mail service, postal matter",
    "ex": "さっき郵便が届きました。",
    "exReading": "さっき ゆうびん が とどきました",
    "exTranslation": "The mail has just arrived.",
    "category": "Noun"
  },
  {
    "term": "コート",
    "termReading": "コート",
    "termTranslation": "coat, jacket",
    "ex": "寒かったのでコートを着た。",
    "exReading": "さむかった の で こーと を きた",
    "exTranslation": "I put on a coat because it was cold.",
    "category": "Noun"
  },
  {
    "term": "不便",
    "termReading": "ふべん",
    "termTranslation": "inconvenient",
    "ex": "私の家は駅から遠くて不便です。",
    "exReading": "わたし の いえ は えき から とおく て ふべん です",
    "exTranslation": "My house is inconveniently located far from the station.",
    "category": "Adjectival Noun"
  },
  {
    "term": "郵便屋さん",
    "termReading": "ゆうびんやさん",
    "termTranslation": "mailman",
    "ex": "郵便屋さんはもう来ましたか。",
    "exReading": "ゆうびんやさん は もう きました か",
    "exTranslation": "Has the postman already come?",
    "category": "Noun"
  },
  {
    "term": "郵便局",
    "termReading": "ゆうびんきょく",
    "termTranslation": "post office",
    "ex": "郵便局で切手を買いました。",
    "exReading": "ゆうびんきょくで きって を かいました",
    "exTranslation": "I bought a stamp at the post office.",
    "category": "Noun"
  },
  {
    "term": "交番",
    "termReading": "こうばん",
    "termTranslation": "police box",
    "ex": "あそこの交番で道を聞きましょう。",
    "exReading": "あそこ の こうばん で みち を ききましょう",
    "exTranslation": "Let's ask for directions at that police box.",
    "category": "Noun"
  },
  {
    "term": "ノート",
    "termReading": "ノート",
    "termTranslation": "note book, laptop",
    "ex": "ノートを開いてください。",
    "exReading": "のーと を ひらいて ください",
    "exTranslation": "Please open your notebook.",
    "category": "Noun"
  },
  {
    "term": "番地",
    "termReading": "ばんち",
    "termTranslation": "lot number, address",
    "ex": "その建物の番地は分かりますか。",
    "exReading": "その たてもの の ばんち は わかります か",
    "exTranslation": "Do you know the building's plot number?",
    "category": "Noun"
  },
  {
    "term": "番号",
    "termReading": "ばんごう",
    "termTranslation": "number, serial number",
    "ex": "この番号に電話してください。",
    "exReading": "この ばんごう に でんわ して ください",
    "exTranslation": "Please call this number.",
    "category": "Noun"
  },
  {
    "term": "場所",
    "termReading": "ばしょ",
    "termTranslation": "place, space",
    "ex": "会社の場所を教えてください。",
    "exReading": "かいしゃ の ばしょ を おしえて ください",
    "exTranslation": "Please tell me where the office is.",
    "category": "Noun"
  },
  {
    "term": "近所",
    "termReading": "きんじょ",
    "termTranslation": "neighborhood",
    "ex": "近所にカナダ人が住んでいる。",
    "exReading": "きんじょ に かなだじん が すんで いる",
    "exTranslation": "A Canadian lives in my neighborhood.",
    "category": "Noun"
  },
  {
    "term": "ワイン",
    "termReading": "ワイン",
    "termTranslation": "wine",
    "ex": "ワインを少し飲みました。",
    "exReading": "わいん を すこし のみました",
    "exTranslation": "I drank a little wine.",
    "category": "Noun"
  },
  {
    "term": "台所",
    "termReading": "だいどころ",
    "termTranslation": "kitchen",
    "ex": "お母さんは台所にいます。",
    "exReading": "おかあさん は だいどころ に います",
    "exTranslation": "Mother's in the kitchen.",
    "category": "Noun"
  },
  {
    "term": "住所",
    "termReading": "じゅうしょ",
    "termTranslation": "one's dwelling, address",
    "ex": "この住所に行ってください。",
    "exReading": "この じゅうしょ に いって ください",
    "exTranslation": "Please go to this address.",
    "category": "Noun"
  },
  {
    "term": "便所",
    "termReading": "べんじょ",
    "termTranslation": "lavatory, bathroom",
    "ex": "便所はそこです。",
    "exReading": "べんじょ は そこ です",
    "exTranslation": "The bathroom is there.",
    "category": "Noun"
  },
  {
    "term": "有名",
    "termReading": "ゆうめい",
    "termTranslation": "famous",
    "ex": "ボルドーはワインの生産で有名だ。",
    "exReading": "ぼるどー は わいん の せいさん で ゆうめい だ",
    "exTranslation": "Bordeau is famous for wine production.",
    "category": "Adjectival Noun"
  },
  {
    "term": "おかしい",
    "termReading": "おかしい",
    "termTranslation": "strange, peculiar, suspicious",
    "ex": "彼の様子がおかしい。",
    "exReading": "かれ の ようす が おかしい",
    "exTranslation": "He's acting strange.",
    "category": "Adjective"
  },
  {
    "term": "名字",
    "termReading": "みょうじ",
    "termTranslation": "surname, family name",
    "ex": "あなたの名字は何ですか。",
    "exReading": "あなた の みょうじ は なん です か",
    "exTranslation": "What's your surname?",
    "category": "Noun"
  },
  {
    "term": "氏名",
    "termReading": "しめい",
    "termTranslation": "name",
    "ex": "ここに住所と氏名を書いてください。",
    "exReading": "ここ に じゅうしょ と しめい を かいて ください",
    "exTranslation": "Please write your name and address here.",
    "category": "Noun"
  },
  {
    "term": "各国",
    "termReading": "かっこく",
    "termTranslation": "every country",
    "ex": "各国の代表がニューヨークに集まった。",
    "exReading": "かっこく の だいひょう が にゅーよーく に あつまった",
    "exTranslation": "Each country's representatives assembled in New York.",
    "category": "Noun"
  },
  {
    "term": "町",
    "termReading": "まち",
    "termTranslation": "town, machi, city quarter",
    "ex": "あの町は緑を増やしています。",
    "exReading": "あの まち は みどり を ふやして います",
    "exTranslation": "That town is increasing its greenery.",
    "category": "Noun"
  },
  {
    "term": "トイレ",
    "termReading": "トイレ",
    "termTranslation": "toilet",
    "ex": "トイレを掃除しましたか。",
    "exReading": "といれ を そうじ しました か",
    "exTranslation": "Did you clean the toilet?",
    "category": "Noun"
  },
  {
    "term": "都市",
    "termReading": "とし",
    "termTranslation": "cities, urban community",
    "ex": "東京は日本一大きな都市です。",
    "exReading": "とうきょう は にほんいち おおき な とし です",
    "exTranslation": "Tokyo is the largest city in Japan.",
    "category": "Noun"
  },
  {
    "term": "都合",
    "termReading": "つごう",
    "termTranslation": "circumstances",
    "ex": "今日は都合が悪くて行けません。",
    "exReading": "きょう は つごう が わるくて いけません",
    "exTranslation": "I can't go today because it's not convenient for me.",
    "category": "Noun"
  },
  {
    "term": "朝御飯",
    "termReading": "あさごはん",
    "termTranslation": "breakfast",
    "ex": "七時に朝御飯を食べました。",
    "exReading": "しちじ に あさごはん を たべました",
    "exTranslation": "I ate breakfast at seven o'clock.",
    "category": "Noun"
  },
  {
    "term": "買い物",
    "termReading": "かいもの",
    "termTranslation": "shopping, purchase",
    "ex": "母は買い物に出かけています。",
    "exReading": "はは は かいもの に でかけて います",
    "exTranslation": "My mother's gone shopping.",
    "category": "Verbal Noun"
  },
  {
    "term": "キャンプ",
    "termReading": "キャンプ",
    "termTranslation": "camping, camp",
    "ex": "友達とキャンプに行った。",
    "exReading": "ともだち と きゃんぷ に いった",
    "exTranslation": "I went camping with a friend.",
    "category": "Verbal Noun"
  },
  {
    "term": "荷物",
    "termReading": "にもつ",
    "termTranslation": "baggage, load",
    "ex": "彼の家に荷物を送りました。",
    "exReading": "かれ の いえ に にもつ を おくりました",
    "exTranslation": "I sent a package to his house.",
    "category": "Noun"
  },
  {
    "term": "品物",
    "termReading": "しなもの",
    "termTranslation": "article, thing",
    "ex": "その店は色々な品物を売っている。",
    "exReading": "その みせ は いろいろ な しなもの を うって いる",
    "exTranslation": "The shop sells a lot of products.",
    "category": "Noun"
  },
  {
    "term": "見物",
    "termReading": "けんぶつ",
    "termTranslation": "sightseeing, visit",
    "ex": "皆で東京見物をした。",
    "exReading": "みんな で とうきょう けんぶつ を した",
    "exTranslation": "We all went sightseeing in Tokyo.",
    "category": "Verbal Noun"
  },
  {
    "term": "物",
    "termReading": "もの",
    "termTranslation": "thing, object",
    "ex": "彼女の家には物がたくさんあります。",
    "exReading": "かのじょ の いえ に は もの が たくさん あります",
    "exTranslation": "There are a lot of things in her house.",
    "category": "Noun"
  },
  {
    "term": "プラスチック",
    "termReading": "プラスチック",
    "termTranslation": "plastic",
    "ex": "このカップはプラスチックです。",
    "exReading": "この かっぷ は ぷらすちっく です",
    "exTranslation": "This cup is plastic.",
    "category": "Noun"
  },
  {
    "term": "忘れ物",
    "termReading": "わすれもの",
    "termTranslation": "something left behind, lost item",
    "ex": "学校に忘れ物をしました。",
    "exReading": "がっこう に わすれもの を しました",
    "exTranslation": "I left something behind at school.",
    "category": "Noun"
  },
  {
    "term": "重さ",
    "termReading": "おもさ",
    "termTranslation": "weight",
    "ex": "この荷物の重さを計ってください。",
    "exReading": "この にもつ の おもさ を はかって ください",
    "exTranslation": "Please weigh this luggage.",
    "category": "Noun"
  },
  {
    "term": "配る",
    "termReading": "くばる",
    "termTranslation": "hand out, distribute, allot",
    "ex": "会議で書類を配った。",
    "exReading": "かいぎ で しょるい を くばった",
    "exTranslation": "I handed out the document at the meeting.",
    "category": "Verb"
  },
  {
    "term": "配達",
    "termReading": "はいたつ",
    "termTranslation": "delivery",
    "ex": "彼は新聞配達をしている。",
    "exReading": "かれ は しんぶん はいたつ を して いる",
    "exTranslation": "He's delivering newspapers.",
    "category": "Verbal Noun"
  },
  {
    "term": "カラー",
    "termReading": "カラー",
    "termTranslation": "color",
    "ex": "カラーコピーは一枚幾らですか。",
    "exReading": "からーこぴー は いちまい いくら です か",
    "exTranslation": "How much is one color copy?",
    "category": "Noun"
  },
  {
    "term": "心配",
    "termReading": "しんぱい",
    "termTranslation": "anxiety, worry, good offices",
    "ex": "明日のプレゼンテーションが心配だ。",
    "exReading": "あす の ぷれぜんてーしょん が しんぱい だ",
    "exTranslation": "I'm worried about tomorrow's presentation.",
    "category": "Verbal Noun"
  },
  {
    "term": "見送る",
    "termReading": "みおくる",
    "termTranslation": "see (someone) off",
    "ex": "彼が外国に行くのを見送りました。",
    "exReading": "かれ が がいこく に いく の を みおくりました",
    "exTranslation": "I saw him off on his trip overseas.",
    "category": "Verb"
  },
  {
    "term": "見送り",
    "termReading": "みおくり",
    "termTranslation": "seeing someone off",
    "ex": "駅に友人の見送りに行きました。",
    "exReading": "えき に ゆうじん の みおくり に いきました",
    "exTranslation": "I went to the station to see off a friend.",
    "category": "Verbal Noun"
  },
  {
    "term": "受ける",
    "termReading": "うける",
    "termTranslation": "receive, accept",
    "ex": "彼は就職試験を受けた。",
    "exReading": "かれ は しゅうしょく しけん を うけた",
    "exTranslation": "He took an exam to apply for a job.",
    "category": "Verb"
  },
  {
    "term": "ピアノ",
    "termReading": "ピアノ",
    "termTranslation": "piano",
    "ex": "昔、ピアノを習っていました。",
    "exReading": "むかし ぴあの を ならって いました",
    "exTranslation": "I used to learn the piano.",
    "category": "Noun"
  },
  {
    "term": "受け取る",
    "termReading": "うけとる",
    "termTranslation": "receive, understand",
    "ex": "彼からメールを受け取りました。",
    "exReading": "かれ から めーる を うけとりました",
    "exTranslation": "I got an email from him.",
    "category": "Verb"
  },
  {
    "term": "取れる",
    "termReading": "とれる",
    "termTranslation": "come off, be removed",
    "ex": "シャツのボタンが取れた。",
    "exReading": "しゃつ の ぼたん が とれた",
    "exTranslation": "My shirt button came off.",
    "category": "Verb"
  },
  {
    "term": "書き取る",
    "termReading": "かきとる",
    "termTranslation": "write down, note down",
    "ex": "話しのポイントを書き取った。",
    "exReading": "はなし の ぽいんと を かきとった",
    "exTranslation": "I wrote down the main points of what was said.",
    "category": "Verb"
  },
  {
    "term": "届く",
    "termReading": "とどく",
    "termTranslation": "reach, be received",
    "ex": "昨日、父から手紙が届いた。",
    "exReading": "きのう ちち から てがみ が とどいた",
    "exTranslation": "I got a letter from my father yesterday.",
    "category": "Verb"
  },
  {
    "term": "スキー",
    "termReading": "スキー",
    "termTranslation": "ski, skiing",
    "ex": "冬はよくスキーに行きます。",
    "exReading": "ふゆ は よく すきー に いきます",
    "exTranslation": "We go skiing a lot in winter.",
    "category": "Noun"
  },
  {
    "term": "届ける",
    "termReading": "とどける",
    "termTranslation": "deliver, give notice",
    "ex": "これを彼に届けてください。",
    "exReading": "これ を かれ に とどけて ください",
    "exTranslation": "Please send this to him.",
    "category": "Verb"
  },
  {
    "term": "持つ",
    "termReading": "もつ",
    "termTranslation": "maintain, last long",
    "ex": "この車はよく持っているね。",
    "exReading": "この くるま は よく もって いる ね",
    "exTranslation": "This car has lasted a long time.",
    "category": "Verb"
  },
  {
    "term": "金持ち",
    "termReading": "かねもち",
    "termTranslation": "wealthy person",
    "ex": "彼は金持ちです。",
    "exReading": "かれ は かねもち です",
    "exTranslation": "He's rich.",
    "category": "Noun"
  },
  {
    "term": "持って行く",
    "termReading": "もっていく",
    "termTranslation": "take",
    "ex": "水を持って行きましょう。",
    "exReading": "みず を もっていきましょう",
    "exTranslation": "Let's take some water with us.",
    "category": "Verb"
  },
  {
    "term": "なかなか",
    "termReading": "なかなか",
    "termTranslation": "not at all, hardly",
    "ex": "荷物がなかなか届きません。",
    "exReading": "にもつ が なかなか とどきません",
    "exTranslation": "The parcel still hasn't arrived.",
    "category": "Adverb"
  },
  {
    "term": "持って来る",
    "termReading": "もってくる",
    "termTranslation": "bring",
    "ex": "そのいすを持って来てください。",
    "exReading": "その いす を もって きて ください",
    "exTranslation": "Please bring that chair.",
    "category": "Verb"
  },
  {
    "term": "打つ",
    "termReading": "うつ",
    "termTranslation": "hit, strike",
    "ex": "転んでひざを打ちました。",
    "exReading": "ころんで ひざ を うちました",
    "exTranslation": "I fell down and hit my knee.",
    "category": "Verb"
  },
  {
    "term": "投げる",
    "termReading": "なげる",
    "termTranslation": "throw, abandon",
    "ex": "ボールをこっちに投げてください。",
    "exReading": "ぼーる を こっち に なげて ください",
    "exTranslation": "Please throw the ball over here.",
    "category": "Verb"
  },
  {
    "term": "生まれる",
    "termReading": "うまれる",
    "termTranslation": "be born, come into existence",
    "ex": "姉夫婦に男の子が生まれました。",
    "exReading": "あね ふうふ に おとこのこ が うまれました",
    "exTranslation": "My sister and her husband had a baby boy.",
    "category": "Verb"
  },
  {
    "term": "プール",
    "termReading": "プール",
    "termTranslation": "pool",
    "ex": "私は夏休みにプールに行った。",
    "exReading": "わたし は なつやすみ に ぷーる に いった",
    "exTranslation": "I went to the pool during summer vacation.",
    "category": "Noun"
  },
  {
    "term": "生む",
    "termReading": "うむ",
    "termTranslation": "have children",
    "ex": "うちのネコが子猫を生みました。",
    "exReading": "うち の ねこ が こねこ を うみました",
    "exTranslation": "Our cat had kittens.",
    "category": "Verb"
  },
  {
    "term": "女性",
    "termReading": "じょせい",
    "termTranslation": "woman, female",
    "ex": "そのパーティーに女性は何人来ますか。",
    "exReading": "その ぱーてぃー に じょせい は なんにん きます か",
    "exTranslation": "How many women are coming to that party?",
    "category": "Noun"
  },
  {
    "term": "生産",
    "termReading": "せいさん",
    "termTranslation": "production",
    "ex": "ボルドーはワインの生産で有名だ。",
    "exReading": "ぼるどー は わいん の せいさん で ゆうめい だ",
    "exTranslation": "Bordeau is famous for wine production.",
    "category": "Verbal Noun"
  },
  {
    "term": "お土産",
    "termReading": "おみやげ",
    "termTranslation": "souvenir",
    "ex": "父はお土産にお菓子を買ってきた。",
    "exReading": "ちち は おみやげ に おかし を かって きた",
    "exTranslation": "My father bought some sweets as a souvenir.",
    "category": "Noun"
  },
  {
    "term": "ホーム",
    "termReading": "ホーム",
    "termTranslation": "platform",
    "ex": "もうすぐこのホームに電車が来ます。",
    "exReading": "もうすぐ この ほーむ に でんしゃ が きます",
    "exTranslation": "The train will arrive on this platform soon.",
    "category": "Noun"
  },
  {
    "term": "生活",
    "termReading": "せいかつ",
    "termTranslation": "life, living",
    "ex": "日本での生活は楽しいです。",
    "exReading": "にほん で の せいかつ は たのしい です",
    "exTranslation": "Life in Japan is fun.",
    "category": "Verbal Noun"
  },
  {
    "term": "生徒",
    "termReading": "せいと",
    "termTranslation": "pupil, student",
    "ex": "このクラスの生徒は30人です。",
    "exReading": "この くらす の せいと は さんじゅうにん です",
    "exTranslation": "There are 30 students in this class.",
    "category": "Noun"
  },
  {
    "term": "中学",
    "termReading": "ちゅうがく",
    "termTranslation": "junior high school",
    "ex": "息子は中学に通っています。",
    "exReading": "むすこ は ちゅうがく に かよって います",
    "exTranslation": "My son goes to junior high.",
    "category": "Noun"
  },
  {
    "term": "入学",
    "termReading": "にゅうがく",
    "termTranslation": "enter a school, matriculate",
    "ex": "妹は九月にアメリカの大学に入学します。",
    "exReading": "いもうと は くがつ に あめりか の だいがく に にゅうがく します",
    "exTranslation": "My little sister's entering college in the United States in September.",
    "category": "Verbal Noun"
  },
  {
    "term": "エレベーター",
    "termReading": "エレベーター",
    "termTranslation": "elevator",
    "ex": "エレベーターで下に降りましょう。",
    "exReading": "えれべーたー で した に おりましょう",
    "exTranslation": "Let's take the elevator down.",
    "category": "Noun"
  },
  {
    "term": "中学生",
    "termReading": "ちゅうがくせい",
    "termTranslation": "junior high school student",
    "ex": "息子は中学生です。",
    "exReading": "むすこ は ちゅうがくせい です",
    "exTranslation": "My son is a junior high school student.",
    "category": "Noun"
  },
  {
    "term": "小学生",
    "termReading": "しょうがくせい",
    "termTranslation": "schoolchildren, schoolboy",
    "ex": "うちの息子は来年、小学生になります。",
    "exReading": "うち の むすこ は らいねん しょうがくせい に なります",
    "exTranslation": "Our son will start primary school next year.",
    "category": "Noun"
  },
  {
    "term": "見学",
    "termReading": "けんがく",
    "termTranslation": "observation tour, study tour, field trip",
    "ex": "今日、工場の見学に行きました。",
    "exReading": "きょう こうじょう の けんがく に いきました",
    "exTranslation": "We went on a field trip to a factory today.",
    "category": "Verbal Noun"
  },
  {
    "term": "通学",
    "termReading": "つうがく",
    "termTranslation": "attending school, going to school",
    "ex": "毎朝、通学に1時間かかる。",
    "exReading": "まいあさ つうがく に いちじかん かかる",
    "exTranslation": "It takes me an hour to go to school every morning.",
    "category": "Verbal Noun"
  },
  {
    "term": "メモ",
    "termReading": "メモ",
    "termTranslation": "memorandum, memo pad",
    "ex": "メモを取ってください。",
    "exReading": "めも を とって ください",
    "exTranslation": "Please take notes.",
    "category": "Noun"
  },
  {
    "term": "高校",
    "termReading": "こうこう",
    "termTranslation": "high school",
    "ex": "妹は高校に通っています。",
    "exReading": "いもうと は こうこう に かよって います",
    "exTranslation": "My little sister goes to high school.",
    "category": "Noun"
  },
  {
    "term": "小学校",
    "termReading": "しょうがっこう",
    "termTranslation": "elementary school",
    "ex": "家の近くに小学校があります。",
    "exReading": "いえ の ちかく に しょうがっこう が あります",
    "exTranslation": "There's an elementary school near my house.",
    "category": "Noun"
  },
  {
    "term": "中学校",
    "termReading": "ちゅうがっこう",
    "termTranslation": "junior high school",
    "ex": "息子の中学校は家から5分です。",
    "exReading": "むすこ の ちゅうがっこう は いえ から ごふん です",
    "exTranslation": "My son's junior high is five minutes from our house.",
    "category": "Noun"
  },
  {
    "term": "校長",
    "termReading": "こうちょう",
    "termTranslation": "principal, schoolmaster",
    "ex": "あの人は高校の校長だ。",
    "exReading": "あの ひと は こうこう の こうちょう だ",
    "exTranslation": "That person is a high school principal.",
    "category": "Noun"
  },
  {
    "term": "パン",
    "termReading": "パン",
    "termTranslation": "bread",
    "ex": "朝ご飯にはいつもパンを食べる。",
    "exReading": "あさごはん に は いつも ぱん を たべる",
    "exTranslation": "I always eat bread for breakfast.",
    "category": "Noun"
  },
  {
    "term": "休校",
    "termReading": "きゅうこう",
    "termTranslation": "temporary closure of school",
    "ex": "学校は今週は休校です。",
    "exReading": "がっこう は こんしゅう は きゅうこう です",
    "exTranslation": "School is closed this week.",
    "category": "Verbal Noun"
  },
  {
    "term": "教会",
    "termReading": "きょうかい",
    "termTranslation": "church",
    "ex": "私たちは教会で結婚式をしました。",
    "exReading": "わたしたち は きょうかい で けっこんしき を しました",
    "exTranslation": "We had our wedding in a church.",
    "category": "Noun"
  },
  {
    "term": "教育",
    "termReading": "きょういく",
    "termTranslation": "education",
    "ex": "彼は海外で教育を受けました。",
    "exReading": "かれ は かいがい で きょういく を うけました",
    "exTranslation": "He received his education abroad.",
    "category": "Verbal Noun"
  },
  {
    "term": "育てる",
    "termReading": "そだてる",
    "termTranslation": "bring up, breed",
    "ex": "彼女は三人の子を育てました。",
    "exReading": "かのじょ は さんにん の こ を そだてました",
    "exTranslation": "She raised three children.",
    "category": "Verb"
  },
  {
    "term": "びっくりする",
    "termReading": "びっくりする",
    "termTranslation": "surprised",
    "ex": "大きな音にびっくりしました。",
    "exReading": "おおきな おと に びっくり しました",
    "exTranslation": "I was startled by a sudden sound.",
    "category": "Verb"
  },
  {
    "term": "育つ",
    "termReading": "そだつ",
    "termTranslation": "be brought up, grow",
    "ex": "野菜がよく育っている。",
    "exReading": "やさい が よく そだって いる",
    "exTranslation": "The vegetables are growing well.",
    "category": "Verb"
  },
  {
    "term": "制度",
    "termReading": "せいど",
    "termTranslation": "system, organization",
    "ex": "来年から新しい制度が始まります。",
    "exReading": "らいねん から あたらしい せいど が はじまります",
    "exTranslation": "A new system will start from next year.",
    "category": "Noun"
  },
  {
    "term": "強さ",
    "termReading": "つよさ",
    "termTranslation": "strength, power",
    "ex": "風の強さに驚きました。",
    "exReading": "かぜ の つよさ に おどろきました",
    "exTranslation": "I was surprised by the strength of the wind.",
    "category": "Noun"
  },
  {
    "term": "取引",
    "termReading": "とりひき",
    "termTranslation": "transaction, dealings",
    "ex": "私たちは中国の会社と取引しています。",
    "exReading": "わたしたち は ちゅうごく の かいしゃ と とりひき して います",
    "exTranslation": "We're dealing with a Chinese company.",
    "category": "Verbal Noun"
  },
  {
    "term": "ズボン",
    "termReading": "ズボン",
    "termTranslation": "trousers, pants",
    "ex": "ズボンが汚れた。",
    "exReading": "ずぼん が よごれた",
    "exTranslation": "My trousers got dirty.",
    "category": "Noun"
  },
  {
    "term": "引き出し",
    "termReading": "ひきだし",
    "termTranslation": "drawer, withdrawal",
    "ex": "財布は引き出しの中にあります。",
    "exReading": "さいふ は ひきだし の なか に あります",
    "exTranslation": "My purse is in the drawer.",
    "category": "Noun"
  },
  {
    "term": "押さえる",
    "termReading": "おさえる",
    "termTranslation": "hold down",
    "ex": "ドアを押さえてください。",
    "exReading": "どあ を おさえて ください",
    "exTranslation": "Please hold the door.",
    "category": "Verb"
  },
  {
    "term": "押し入れ",
    "termReading": "おしいれ",
    "termTranslation": "closet",
    "ex": "布団を押し入れにしまいました。",
    "exReading": "ふとん を おしいれ に しまいました",
    "exTranslation": "I put my futon in the closet.",
    "category": "Noun"
  },
  {
    "term": "練習",
    "termReading": "れんしゅう",
    "termTranslation": "practice, training",
    "ex": "娘は今、バイオリンを練習しています。",
    "exReading": "むすめ は いま ばいおりん を れんしゅう して います",
    "exTranslation": "My daughter is practicing the violin now.",
    "category": "Verbal Noun"
  },
  {
    "term": "おもちゃ",
    "termReading": "おもちゃ",
    "termTranslation": "toy, plaything",
    "ex": "赤ちゃんが自動車のおもちゃで遊んでいる。",
    "exReading": "あかちゃん が じどうしゃ の おもちゃ で あそんで いる",
    "exTranslation": "The baby's playing with the toy car.",
    "category": "Noun"
  },
  {
    "term": "習う",
    "termReading": "ならう",
    "termTranslation": "learn, practice",
    "ex": "彼は空手を習っています。",
    "exReading": "かれ は からて を ならって います",
    "exTranslation": "He's learning karate.",
    "category": "Verb"
  },
  {
    "term": "慣れる",
    "termReading": "なれる",
    "termTranslation": "grow accustomed to",
    "ex": "新しい家にはもう慣れましたか。",
    "exReading": "あたらしい いえ に は もう なれました か",
    "exTranslation": "Have you gotten used to the new house yet?",
    "category": "Verb"
  },
  {
    "term": "習慣",
    "termReading": "しゅうかん",
    "termTranslation": "custom, habit",
    "ex": "毎朝コーヒーを飲むのが習慣です。",
    "exReading": "まいあさ こーひー を のむ の が しゅうかん です",
    "exTranslation": "I'm in the habit of drinking coffee every morning.",
    "category": "None"
  },
  {
    "term": "研究",
    "termReading": "けんきゅう",
    "termTranslation": "research, study",
    "ex": "彼は何年も地震の研究をしている。",
    "exReading": "かれ は なんねん も じしん の けんきゅう を して いる",
    "exTranslation": "He's been researching earthquakes for many years.",
    "category": "Verbal Noun"
  },
  {
    "term": "グラム",
    "termReading": "グラム",
    "termTranslation": "gram",
    "ex": "ひき肉を200グラムください。",
    "exReading": "ひきにく を にひゃくぐらむ ください",
    "exTranslation": "Please give me 200 grams of ground (beef, chicken, etc.)",
    "category": "Noun"
  },
  {
    "term": "試験",
    "termReading": "しけん",
    "termTranslation": "test, examination",
    "ex": "明日の試験、頑張ってね。",
    "exReading": "あした の しけん がんばって ね",
    "exTranslation": "Good luck on tomorrow's exam.",
    "category": "Verbal Noun"
  },
  {
    "term": "問題",
    "termReading": "もんだい",
    "termTranslation": "problem, question",
    "ex": "問題が一つあります。",
    "exReading": "もんだい が ひとつ あります",
    "exTranslation": "There's one problem.",
    "category": "Noun"
  },
  {
    "term": "簡単",
    "termReading": "かんたん",
    "termTranslation": "simple, easy",
    "ex": "この料理はとても簡単です。",
    "exReading": "この りょうり は とても かんたん です",
    "exTranslation": "This dish is very easy to make.",
    "category": "Adjectival Noun"
  },
  {
    "term": "複雑",
    "termReading": "ふくざつ",
    "termTranslation": "complicated, intricate",
    "ex": "このプログラムはとても複雑です。",
    "exReading": "この ぷろぐらむ は とても ふくざつ です",
    "exTranslation": "This program is very complicated.",
    "category": "Adjectival Noun"
  },
  {
    "term": "コーヒー",
    "termReading": "コーヒー",
    "termTranslation": "coffee",
    "ex": "私は毎朝コーヒーを飲みます。",
    "exReading": "わたし は まいあさ こーひー を のみます",
    "exTranslation": "I drink coffee every morning.",
    "category": "Noun"
  },
  {
    "term": "数字",
    "termReading": "すうじ",
    "termTranslation": "figure, numeral",
    "ex": "数字は苦手です。",
    "exReading": "すうじ は にがて です",
    "exTranslation": "I'm not good with numbers.",
    "category": "Noun"
  },
  {
    "term": "数学",
    "termReading": "すうがく",
    "termTranslation": "mathematics",
    "ex": "兄は数学の先生です。",
    "exReading": "あに は すうがく の せんせい です",
    "exTranslation": "My big brother's a math teacher.",
    "category": "Noun"
  },
  {
    "term": "数える",
    "termReading": "かぞえる",
    "termTranslation": "count, calculate",
    "ex": "いすの数を数えてください。",
    "exReading": "いす の かず を かぞえて ください",
    "exTranslation": "Please count the number of chairs.",
    "category": "Verb"
  },
  {
    "term": "今回",
    "termReading": "こんかい",
    "termTranslation": "this time",
    "ex": "まあ今回は許してあげよう。",
    "exReading": "まあ こんかい は ゆるして あげよう",
    "exTranslation": "Well, I'll let you off this time.",
    "category": "Noun"
  },
  {
    "term": "テント",
    "termReading": "テント",
    "termTranslation": "tent",
    "ex": "みんなでテントを張りました。",
    "exReading": "みんな で てんと を はりました",
    "exTranslation": "We all put up the tent together.",
    "category": "Noun"
  },
  {
    "term": "回る",
    "termReading": "まわる",
    "termTranslation": "turn round, go round, detour",
    "ex": "月は地球のまわりを回っています。",
    "exReading": "つき は ちきゅう の まわり を まわって います",
    "exTranslation": "The moon spins around the earth.",
    "category": "Verb"
  },
  {
    "term": "回す",
    "termReading": "まわす",
    "termTranslation": "turn, rotate",
    "ex": "ねじは左に回すと外れます。",
    "exReading": "ねじ は ひだり に まわす と はずれます",
    "exTranslation": "If you turn the screw to the left it will come undone.",
    "category": "Verb"
  },
  {
    "term": "個人",
    "termReading": "こじん",
    "termTranslation": "individual",
    "ex": "これは私個人の意見です。",
    "exReading": "これ は わたし こじん の いけん です",
    "exTranslation": "This is my personal opinion.",
    "category": "Noun"
  },
  {
    "term": "担当",
    "termReading": "たんとう",
    "termTranslation": "person in charge",
    "ex": "私はセールスを担当しています。",
    "exReading": "わたし は せーるす を たんとう して います",
    "exTranslation": "I'm in charge of sales.",
    "category": "Verbal Noun"
  },
  {
    "term": "ボート",
    "termReading": "ボート",
    "termTranslation": "boat",
    "ex": "池でボートに乗りました。",
    "exReading": "いけ で ぼーと に のりました",
    "exTranslation": "I got on the boat at the pond.",
    "category": "Noun"
  },
  {
    "term": "当たる",
    "termReading": "あたる",
    "termTranslation": "hit, strike",
    "ex": "ボールが彼の頭に当たった。",
    "exReading": "ぼーる が かれ の あたま に あたった",
    "exTranslation": "The ball hit him on the head.",
    "category": "Verb"
  },
  {
    "term": "当時",
    "termReading": "とうじ",
    "termTranslation": "at that time, at the present time",
    "ex": "彼女は当時、まだ3才だった。",
    "exReading": "かのじょ は とうじ まだ さんさい だった",
    "exTranslation": "She was only three years old at the time.",
    "category": "Noun"
  },
  {
    "term": "本当",
    "termReading": "ほんとう",
    "termTranslation": "truth, reality",
    "ex": "その話は本当ですか。",
    "exReading": "その はなし は ほんとう です か",
    "exTranslation": "Is the story true?",
    "category": "Noun"
  },
  {
    "term": "当然",
    "termReading": "とうぜん",
    "termTranslation": "natural, as a matter of course",
    "ex": "彼女が怒るのも当然だ。",
    "exReading": "かのじょ が おこる の も とうぜん だ",
    "exTranslation": "It's natural that she should be angry.",
    "category": "Adjectival Noun"
  },
  {
    "term": "ボール",
    "termReading": "ボール",
    "termTranslation": "ball",
    "ex": "彼はボールを投げた。",
    "exReading": "かれ は ぼーる を なげた",
    "exTranslation": "He threw the ball.",
    "category": "Noun"
  },
  {
    "term": "全然",
    "termReading": "ぜんぜん",
    "termTranslation": "wholly, at all",
    "ex": "この本は全然面白くなかった。",
    "exReading": "この ほん は ぜんぜん おもしろく なかった",
    "exTranslation": "This book wasn't interesting at all.",
    "category": "Adverb"
  },
  {
    "term": "方法",
    "termReading": "ほうほう",
    "termTranslation": "method, procedure",
    "ex": "いい方法を思いつきました。",
    "exReading": "いい ほうほう を おもいつきました",
    "exTranslation": "We hit on a good method.",
    "category": "Noun"
  },
  {
    "term": "法律",
    "termReading": "ほうりつ",
    "termTranslation": "law",
    "ex": "新しい法律ができた。",
    "exReading": "あたらしい ほうりつ が できた",
    "exTranslation": "A new law has been passed.",
    "category": "Noun"
  },
  {
    "term": "規則",
    "termReading": "きそく",
    "termTranslation": "rule, regulation",
    "ex": "あの会社の規則は厳しいです。",
    "exReading": "あの かいしゃ の きそく は きびしい です",
    "exTranslation": "That company's rules are strict.",
    "category": "Noun"
  },
  {
    "term": "オートバイ",
    "termReading": "オートバイ",
    "termTranslation": "motorbike, motorcycle",
    "ex": "彼はオートバイに乗っている。",
    "exReading": "かれ は おーとばい に のって いる",
    "exTranslation": "He rides a motorcycle.",
    "category": "Noun"
  },
  {
    "term": "経験",
    "termReading": "けいけん",
    "termTranslation": "experience",
    "ex": "今日の試合はいい経験になりました。",
    "exReading": "きょう の しあい は いい けいけん に なりました",
    "exTranslation": "Today's game was a good experience.",
    "category": "Verbal Noun"
  },
  {
    "term": "経つ",
    "termReading": "たつ",
    "termTranslation": "pass, elapse",
    "ex": "あれから14年が経ちました。",
    "exReading": "あれ から じゅうよねん が たちました",
    "exTranslation": "Fourteen years have passed since then.",
    "category": "Verb"
  },
  {
    "term": "経済",
    "termReading": "けいざい",
    "termTranslation": "economy, economics",
    "ex": "大学で経済を勉強しました。",
    "exReading": "だいがく で けいざい を べんきょう しました",
    "exTranslation": "I studied economics in college.",
    "category": "Noun"
  },
  {
    "term": "経営",
    "termReading": "けいえい",
    "termTranslation": "management, operation",
    "ex": "我が社の経営はうまくいっています。",
    "exReading": "わがしゃ の けいえい は うまく いって います",
    "exTranslation": "Our company is well managed.",
    "category": "Verbal Noun"
  },
  {
    "term": "ひどい",
    "termReading": "ひどい",
    "termTranslation": "cruel, severe",
    "ex": "妹とひどい喧嘩をした。",
    "exReading": "いもうと と ひどい けんか を した",
    "exTranslation": "I had an awful fight with my little sister.",
    "category": "Adjective"
  },
  {
    "term": "株",
    "termReading": "かぶ",
    "termTranslation": "stocks, shares",
    "ex": "最近株を始めました。",
    "exReading": "さいきん かぶ を はじめました",
    "exTranslation": "I recently started investing in stocks.",
    "category": "Noun"
  },
  {
    "term": "企業",
    "termReading": "きぎょう",
    "termTranslation": "corporation, business",
    "ex": "彼女はアメリカの企業で働いています。",
    "exReading": "かのじょ は あめりか の きぎょう で はたらいて います",
    "exTranslation": "She works in an American company.",
    "category": "Noun"
  },
  {
    "term": "作業",
    "termReading": "さぎょう",
    "termTranslation": "work, operation",
    "ex": "作業するにはもっと広いスペースが必要だ。",
    "exReading": "さぎょう する に は もっと ひろい すぺーす が ひつよう だ",
    "exTranslation": "I need a bigger space to work.",
    "category": "Verbal Noun"
  },
  {
    "term": "産業",
    "termReading": "さんぎょう",
    "termTranslation": "industry",
    "ex": "日本の自動車産業は世界的に有名だ。",
    "exReading": "にほん の じどうしゃ さんぎょう は せかいてき に ゆうめい だ",
    "exTranslation": "The Japanese auto industry is famous worldwide.",
    "category": "Noun"
  },
  {
    "term": "あなた",
    "termReading": "あなた",
    "termTranslation": "you",
    "ex": "この本、あなたにあげます。",
    "exReading": "この ほん あなた に あげます",
    "exTranslation": "I'll give you this book.",
    "category": "Noun"
  },
  {
    "term": "工業",
    "termReading": "こうぎょう",
    "termTranslation": "industry, manufacturing industry",
    "ex": "そこは工業都市だ。",
    "exReading": "そこ は こうぎょう とし だ",
    "exTranslation": "That's an industrial city.",
    "category": "Noun"
  },
  {
    "term": "商業",
    "termReading": "しょうぎょう",
    "termTranslation": "commerce, trade",
    "ex": "この町では商業が盛んだ。",
    "exReading": "この まち で は しょうぎょう が さかん だ",
    "exTranslation": "Business is thriving in this city.",
    "category": "Noun"
  },
  {
    "term": "利用",
    "termReading": "りよう",
    "termTranslation": "utilization, usage",
    "ex": "私はよく図書館を利用します。",
    "exReading": "わたし は よく としょかん を りよう します",
    "exTranslation": "I often use the library.",
    "category": "Verbal Noun"
  },
  {
    "term": "便利",
    "termReading": "べんり",
    "termTranslation": "convenience, handiness",
    "ex": "インターネットはとても便利です。",
    "exReading": "いんたーねっと は とても べんり です",
    "exTranslation": "The Internet is very convenient.",
    "category": "Adjectival Noun"
  },
  {
    "term": "スイッチ",
    "termReading": "スイッチ",
    "termTranslation": "switch",
    "ex": "彼はカーラジオのスイッチを入れた。",
    "exReading": "かれ は かーらじお の すいっち を いれた",
    "exTranslation": "He hit the switch on his car radio.",
    "category": "Noun"
  },
  {
    "term": "技術",
    "termReading": "ぎじゅつ",
    "termTranslation": "skill, technology",
    "ex": "彼は非常に高い技術を持っている。",
    "exReading": "かれ は ひじょう に たかい ぎじゅつ を もって いる",
    "exTranslation": "He has very advanced skills.",
    "category": "Noun"
  },
  {
    "term": "手術",
    "termReading": "しゅじゅつ",
    "termTranslation": "surgical operation",
    "ex": "父は胸の手術をした。",
    "exReading": "ちち は むね の しゅじゅつ を した",
    "exTranslation": "My father had a chest operation.",
    "category": "Verbal Noun"
  },
  {
    "term": "製造",
    "termReading": "せいぞう",
    "termTranslation": "production, manufacture",
    "ex": "この工場ではエアコンを製造しています。",
    "exReading": "この こうじょう で は えあこん を せいぞう して います",
    "exTranslation": "This factory manufactures air conditioning units.",
    "category": "Verbal Noun"
  },
  {
    "term": "必ず",
    "termReading": "かならず",
    "termTranslation": "without fail, certainly",
    "ex": "必ずシートベルトを着けて下さい。",
    "exReading": "かならず しーとべると を つけて ください",
    "exTranslation": "Always wear your seat belt.",
    "category": "Adverb"
  },
  {
    "term": "プレゼント",
    "termReading": "プレゼント",
    "termTranslation": "present, gift",
    "ex": "誕生日にプレゼントをもらいました。",
    "exReading": "たんじょうび に ぷれぜんと を もらいました",
    "exTranslation": "I got some presents on my birthday.",
    "category": "Verbal Noun"
  },
  {
    "term": "必要",
    "termReading": "ひつよう",
    "termTranslation": "need, necessity",
    "ex": "私にはたくさんのお金が必要だ。",
    "exReading": "わたし に は たくさん の おかね が ひつよう だ",
    "exTranslation": "I need a lot of money.",
    "category": "Adjectival Noun"
  },
  {
    "term": "重要",
    "termReading": "じゅうよう",
    "termTranslation": "important, essential",
    "ex": "これは重要な書類です。",
    "exReading": "これ は じゅうよう な しょるい です",
    "exTranslation": "This is an important document.",
    "category": "Adjectival Noun"
  },
  {
    "term": "要求",
    "termReading": "ようきゅう",
    "termTranslation": "requirement, demand",
    "ex": "彼は私の要求にこたえた。",
    "exReading": "かれ は わたし の ようきゅう に こたえた",
    "exTranslation": "He answered my demands.",
    "category": "Verbal Noun"
  },
  {
    "term": "目覚まし時計",
    "termReading": "めざましどけい",
    "termTranslation": "alarm clock",
    "ex": "7時に目覚まし時計が鳴りました。",
    "exReading": "しちじ に めざましどけい が なりました",
    "exTranslation": "The alarm clock went off at seven o'clock.",
    "category": "Noun"
  },
  {
    "term": "いつでも",
    "termReading": "いつでも",
    "termTranslation": "always, at all times",
    "ex": "いつでもうちに来てください。",
    "exReading": "いつでも うち に きて ください",
    "exTranslation": "Please come to my house any time.",
    "category": "Adverb"
  },
  {
    "term": "計算",
    "termReading": "けいさん",
    "termTranslation": "calculation",
    "ex": "その計算は間違っている。",
    "exReading": "その けいさん は まちがって いる",
    "exTranslation": "That calculation is wrong.",
    "category": "Verbal Noun"
  },
  {
    "term": "引き算",
    "termReading": "ひきざん",
    "termTranslation": "subtraction",
    "ex": "娘は学校で引き算を習っている。",
    "exReading": "むすめ は がっこう で ひきざん を ならって いる",
    "exTranslation": "My daughter's learning subtraction at school.",
    "category": "Verbal Noun"
  },
  {
    "term": "足し算",
    "termReading": "たしざん",
    "termTranslation": "addition",
    "ex": "娘は学校で足し算を習った。",
    "exReading": "むすめ は がっこう で たしざん を ならった",
    "exTranslation": "My daughter learned addition in school.",
    "category": "Verbal Noun"
  },
  {
    "term": "交差点",
    "termReading": "こうさてん",
    "termTranslation": "crossing, intersection",
    "ex": "次の交差点を左に曲がってください。",
    "exReading": "つぎ の こうさてん を ひだり に まがって ください",
    "exTranslation": "Please turn left at the next intersection.",
    "category": "Noun"
  },
  {
    "term": "テニス",
    "termReading": "テニス",
    "termTranslation": "tennis",
    "ex": "彼らはよくテニスをしています。",
    "exReading": "かれら は よく てにす を して います",
    "exTranslation": "They often play tennis.",
    "category": "Verbal Noun"
  },
  {
    "term": "割る",
    "termReading": "わる",
    "termTranslation": "divide, crack, split",
    "ex": "皿を落として割った。",
    "exReading": "さら を おとして わった",
    "exTranslation": "I dropped the plate and broke it.",
    "category": "Verb"
  },
  {
    "term": "割れる",
    "termReading": "われる",
    "termTranslation": "crack, be divided, split",
    "ex": "コップが落ちて割れた。",
    "exReading": "こっぷ が おちて われた",
    "exTranslation": "The glass fell and broke.",
    "category": "Verb"
  },
  {
    "term": "割り算",
    "termReading": "わりざん",
    "termTranslation": "division",
    "ex": "割り算は小学校で習います。",
    "exReading": "わりざん は しょうがっこう で ならいます",
    "exTranslation": "Division is learned in elementary school.",
    "category": "Verbal Noun"
  },
  {
    "term": "残る",
    "termReading": "のこる",
    "termTranslation": "remain, be left over",
    "ex": "料理がたくさん残りました。",
    "exReading": "りょうり が たくさん のこりました",
    "exTranslation": "A lot of food was left over.",
    "category": "Verb"
  },
  {
    "term": "こちら",
    "termReading": "こちら",
    "termTranslation": "here, this way",
    "ex": "受付はこちらです。",
    "exReading": "うけつけ は こちら です",
    "exTranslation": "The reception desk is this way.",
    "category": "Noun"
  },
  {
    "term": "残す",
    "termReading": "のこす",
    "termTranslation": "leave, reserve, leave undone",
    "ex": "彼女はメッセージを残しました。",
    "exReading": "かのじょ は めっせーじ を のこしました",
    "exTranslation": "She left a message.",
    "category": "Verb"
  },
  {
    "term": "返す",
    "termReading": "かえす",
    "termTranslation": "return, repay",
    "ex": "図書館に本を返した。",
    "exReading": "としょかん に ほん を かえした",
    "exTranslation": "I returned the book to the library.",
    "category": "Verb"
  },
  {
    "term": "返事",
    "termReading": "へんじ",
    "termTranslation": "answer, reply",
    "ex": "手紙の返事を出しました。",
    "exReading": "てがみ の へんじ を だしました",
    "exTranslation": "I sent a reply to the letter.",
    "category": "Verbal Noun"
  },
  {
    "term": "借りる",
    "termReading": "かりる",
    "termTranslation": "borrow",
    "ex": "彼にビデオを借りました。",
    "exReading": "かれ に びでお を かりました",
    "exTranslation": "I borrowed a video from him.",
    "category": "Verb"
  },
  {
    "term": "ボタン",
    "termReading": "ボタン",
    "termTranslation": "button",
    "ex": "ボタンを押してください。",
    "exReading": "ぼたん を おして ください",
    "exTranslation": "Please press the button.",
    "category": "Noun"
  },
  {
    "term": "貸す",
    "termReading": "かす",
    "termTranslation": "lend, lease",
    "ex": "私は彼に本を貸しています。",
    "exReading": "わたし は かれ に ほん を かして います",
    "exTranslation": "I'm lending him the book.",
    "category": "Verb"
  },
  {
    "term": "貸し出す",
    "termReading": "かしだす",
    "termTranslation": "lend, lend out",
    "ex": "その本は貸し出し中です。",
    "exReading": "その ほん は かしだしちゅう です",
    "exTranslation": "That book is currently on loan.",
    "category": "Verb"
  },
  {
    "term": "申し込む",
    "termReading": "もうしこむ",
    "termTranslation": "apply, make a proposal",
    "ex": "彼女はそのセミナーに申し込んだ。",
    "exReading": "かのじょ は その せみなー に もうしこんだ",
    "exTranslation": "She registered for that workshop.",
    "category": "Verb"
  },
  {
    "term": "期待",
    "termReading": "きたい",
    "termTranslation": "expectation, anticipation",
    "ex": "みんな私たちに期待しています。",
    "exReading": "みんな わたしたち に きたい して います",
    "exTranslation": "Everyone has high expectations of us.",
    "category": "Verbal Noun"
  },
  {
    "term": "おかしい",
    "termReading": "おかしい",
    "termTranslation": "funny, amusing, comical",
    "ex": "彼の話はおかしかった。",
    "exReading": "かれ の はなし は おかしかった",
    "exTranslation": "His story was funny.",
    "category": "Adjective"
  },
  {
    "term": "期間",
    "termReading": "きかん",
    "termTranslation": "term, period",
    "ex": "テスト期間は10日から15日までだ。",
    "exReading": "てすと きかん は とおか から じゅうごにち まで だ",
    "exTranslation": "The test period is from the 10th to the 15th.",
    "category": "Noun"
  },
  {
    "term": "時期",
    "termReading": "じき",
    "termTranslation": "time, season",
    "ex": "今はあなたにとって大事な時期です。",
    "exReading": "いま は あなた に とって だいじ な じき です",
    "exTranslation": "It's an important time for you now.",
    "category": "Noun"
  },
  {
    "term": "限る",
    "termReading": "かぎる",
    "termTranslation": "be limited to",
    "ex": "このサービスは週末に限ります",
    "exReading": "この さーびす は しゅうまつ に かぎります",
    "exTranslation": "This service is limited to weekends.",
    "category": "Verb"
  },
  {
    "term": "急ぐ",
    "termReading": "いそぐ",
    "termTranslation": "hurry, hasten",
    "ex": "私たちは駅へ急ぎました。",
    "exReading": "わたしたち は えき へ いそぎました",
    "exTranslation": "We hurried to the station.",
    "category": "Verb"
  },
  {
    "term": "コート",
    "termReading": "コート",
    "termTranslation": "court",
    "ex": "新しいコートでテニスをしました。",
    "exReading": "あたらしい こーと で てにす を しました",
    "exTranslation": "I played tennis on the new court.",
    "category": "Noun"
  },
  {
    "term": "急に",
    "termReading": "きゅうに",
    "termTranslation": "suddenly, unexpectedly",
    "ex": "急に用事を思い出した。",
    "exReading": "きゅうに ようじ を おもいだした",
    "exTranslation": "I suddenly remembered I have something to do.",
    "category": "Adverb"
  },
  {
    "term": "急",
    "termReading": "きゅう",
    "termTranslation": "urgent, dire, pressing",
    "ex": "急な坂道を上った。",
    "exReading": "きゅう な さかみち を のぼった",
    "exTranslation": "I went up the steep hill.",
    "category": "Adjectival Noun"
  },
  {
    "term": "急行",
    "termReading": "きゅうこう",
    "termTranslation": "express train, going in a hurry",
    "ex": "ちょうど急行電車が来た。",
    "exReading": "ちょうど きゅうこう でんしゃ が きた",
    "exTranslation": "The express train just came.",
    "category": "Noun"
  },
  {
    "term": "切れる",
    "termReading": "きれる",
    "termTranslation": "run out, expire, can cut",
    "ex": "このはさみはよく切れますね。",
    "exReading": "この はさみ は よく きれます ね",
    "exTranslation": "These scissors cut well.",
    "category": "Verb"
  },
  {
    "term": "ドラマ",
    "termReading": "ドラマ",
    "termTranslation": "drama, theater, soap opera",
    "ex": "私はドラマを見るのが好きです。",
    "exReading": "わたし は どらま を みる の が すき です",
    "exTranslation": "I like to watch TV dramas.",
    "category": "Noun"
  },
  {
    "term": "切手",
    "termReading": "きって",
    "termTranslation": "postage stamp",
    "ex": "郵便局で切手を買いました。",
    "exReading": "ゆうびんきょく で きって を かいました",
    "exTranslation": "I bought a stamp at the post office.",
    "category": "Noun"
  },
  {
    "term": "売り切れる",
    "termReading": "うりきれる",
    "termTranslation": "be sold out, go out of stock",
    "ex": "その本は直ぐ売り切れた。",
    "exReading": "その ほん は すぐ うりきれた",
    "exTranslation": "The book sold out quickly.",
    "category": "Verb"
  },
  {
    "term": "売り切れ",
    "termReading": "うりきれ",
    "termTranslation": "sellout, sold out",
    "ex": "チケットはもう売り切れだって。",
    "exReading": "ちけっと は もう うりきれ だって",
    "exTranslation": "The tickets have sold out already.",
    "category": "Noun"
  },
  {
    "term": "大切",
    "termReading": "たいせつ",
    "termTranslation": "important, valuable",
    "ex": "これは母が大切にしていた指輪です。",
    "exReading": "これ は はは が たいせつ に して いた ゆびわ です",
    "exTranslation": "This is a ring that meant a lot to my mother.",
    "category": "Adjectival Noun"
  },
  {
    "term": "ビザ",
    "termReading": "ビザ",
    "termTranslation": "visa",
    "ex": "学生ビザを持っています。",
    "exReading": "がくせい びざ を もって います",
    "exTranslation": "He has a student visa.",
    "category": "Noun"
  },
  {
    "term": "切符",
    "termReading": "きっぷ",
    "termTranslation": "ticket",
    "ex": "東京までの切符を買った。",
    "exReading": "とうきょう まで の きっぷ を かった",
    "exTranslation": "I bought a ticket to Tokyo.",
    "category": "Noun"
  },
  {
    "term": "入場券",
    "termReading": "にゅうじょうけん",
    "termTranslation": "entrance ticket",
    "ex": "入場券は3000円です。",
    "exReading": "にゅうじょうけん は さんぜんえん です",
    "exTranslation": "The admission ticket is 3,000 yen.",
    "category": "Noun"
  },
  {
    "term": "家賃",
    "termReading": "やちん",
    "termTranslation": "rent",
    "ex": "ここの家賃は12万円です。",
    "exReading": "ここ の やちん は じゅうにまんえん です",
    "exTranslation": "The rent here is 120,000 yen.",
    "category": "Noun"
  },
  {
    "term": "時代",
    "termReading": "じだい",
    "termTranslation": "age, antiquity",
    "ex": "今は便利さとスピードの時代だ。",
    "exReading": "いま は べんりさ と すぴーど の じだい だ",
    "exTranslation": "This is the age of convenience and speed.",
    "category": "Noun"
  },
  {
    "term": "ポケット",
    "termReading": "ポケット",
    "termTranslation": "pocket",
    "ex": "財布をポケットにしまった。",
    "exReading": "さいふ を ぽけっと に しまった",
    "exTranslation": "I put my wallet in my pocket.",
    "category": "Noun"
  },
  {
    "term": "代わる",
    "termReading": "かわる",
    "termTranslation": "substitute, be substituted for",
    "ex": "上司に代わって会議に出た。",
    "exReading": "じょうし に かわって かいぎ に でた",
    "exTranslation": "I attended the conference in place of my boss.",
    "category": "Verb"
  },
  {
    "term": "代える",
    "termReading": "かえる",
    "termTranslation": "substitute, use in place of",
    "ex": "社長に代えて部長を出席させます。",
    "exReading": "しゃちょう に かえて ぶちょう を しゅっせき させます",
    "exTranslation": "I'll have the department manager attend in place of the president.",
    "category": "Verb"
  },
  {
    "term": "指",
    "termReading": "ゆび",
    "termTranslation": "finger, toe",
    "ex": "彼は指が太い。",
    "exReading": "かれ は ゆび が ふとい",
    "exTranslation": "His fingers are fat.",
    "category": "Noun"
  },
  {
    "term": "決定",
    "termReading": "けってい",
    "termTranslation": "decision, settlement",
    "ex": "会議で重要な決定がありました。",
    "exReading": "かいぎ で じゅうよう な けってい が ありました",
    "exTranslation": "An important decision was made at the conference.",
    "category": "Verbal Noun"
  },
  {
    "term": "そろそろ",
    "termReading": "そろそろ",
    "termTranslation": "soon, quietly",
    "ex": "そろそろ始めましょうか。",
    "exReading": "そろそろ はじめましょう か",
    "exTranslation": "It's about time to begin.",
    "category": "Adverb"
  },
  {
    "term": "一定",
    "termReading": "いってい",
    "termTranslation": "constant, standard",
    "ex": "申し込むには一定の資格が要る。",
    "exReading": "もうしこむ に は いってい の しかく が いる",
    "exTranslation": "You need certain qualifications to apply.",
    "category": "Verbal Noun"
  },
  {
    "term": "定期券",
    "termReading": "ていきけん",
    "termTranslation": "commuter's pass",
    "ex": "定期券は1万2千円でした。",
    "exReading": "ていきけん は いちまんにせんえん でした",
    "exTranslation": "The commuter pass was 1,2000 yen.",
    "category": "Noun"
  },
  {
    "term": "予定",
    "termReading": "よてい",
    "termTranslation": "schedule, plan",
    "ex": "今日の予定を教えてください。",
    "exReading": "きょう の よてい を おしえて ください",
    "exTranslation": "Please tell me what your schedule is for today.",
    "category": "Verbal Noun"
  },
  {
    "term": "天気予報",
    "termReading": "てんきよほう",
    "termTranslation": "weather forecast",
    "ex": "明日の天気予報は雨です。",
    "exReading": "あした の てんきよほう は あめ です",
    "exTranslation": "Rain is forecast for tomorrow.",
    "category": "Noun"
  },
  {
    "term": "ぶどう",
    "termReading": "ぶどう",
    "termTranslation": "grapes",
    "ex": "私はぶどうが好きです。",
    "exReading": "わたし は ぶどう が すき です",
    "exTranslation": "I like grapes.",
    "category": "Noun"
  },
  {
    "term": "予習",
    "termReading": "よしゅう",
    "termTranslation": "preparation, previewing (a lesson)",
    "ex": "明日の予習をしましょう。",
    "exReading": "あした の よしゅう を しましょう",
    "exTranslation": "Let's prepare for tomorrow.",
    "category": "Verbal Noun"
  },
  {
    "term": "予約",
    "termReading": "よやく",
    "termTranslation": "reservation, subscription",
    "ex": "レストランを予約しました。",
    "exReading": "れすとらん を よやく しました",
    "exTranslation": "I made a reservation at the restaurant.",
    "category": "Verbal Noun"
  },
  {
    "term": "約束",
    "termReading": "やくそく",
    "termTranslation": "promise, vow",
    "ex": "約束は守ります。",
    "exReading": "やくそく は まもります",
    "exTranslation": "I keep my promises.",
    "category": "Verbal Noun"
  },
  {
    "term": "変わる",
    "termReading": "かわる",
    "termTranslation": "change",
    "ex": "信号が青に変わりました。",
    "exReading": "しんごう が あお に かわりました。",
    "exTranslation": "The traffic light turned green.",
    "category": "Verb"
  },
  {
    "term": "めったに",
    "termReading": "めったに",
    "termTranslation": "rarely",
    "ex": "彼女はめったに怒りません。",
    "exReading": "かのじょ は めったに おこりません 。",
    "exTranslation": "She rarely gets angry.",
    "category": "Adverb"
  },
  {
    "term": "大変",
    "termReading": "たいへん",
    "termTranslation": "awful, serious",
    "ex": "大変なことが起こりました。",
    "exReading": "たいへん な こと が おこりました",
    "exTranslation": "A terrible thing happened.",
    "category": "Adjectival Noun"
  },
  {
    "term": "変",
    "termReading": "へん",
    "termTranslation": "strange, weird, funny",
    "ex": "変な音が聞こえます。",
    "exReading": "へん な おと が きこえます",
    "exTranslation": "I hear a strange sound.",
    "category": "Adjectival Noun"
  },
  {
    "term": "変化",
    "termReading": "へんか",
    "termTranslation": "change",
    "ex": "今年は変化の多い年でした。",
    "exReading": "ことし は へんか の おおい とし でした",
    "exTranslation": "There was a lot of change this year.",
    "category": "Verbal Noun"
  },
  {
    "term": "強化",
    "termReading": "きょうか",
    "termTranslation": "strengthening",
    "ex": "国は国語教育を強化しています。",
    "exReading": "くに は こくご きょういく を きょうか して います",
    "exTranslation": "Our government is strengthening its program in native language education.",
    "category": "Verbal Noun"
  },
  {
    "term": "クリスマス",
    "termReading": "クリスマス",
    "termTranslation": "Christmas",
    "ex": "クリスマスにはケーキを食べます。",
    "exReading": "くりすます に は けーき を たべます",
    "exTranslation": "We eat cake at Christmas.",
    "category": "Noun"
  },
  {
    "term": "文化",
    "termReading": "ぶんか",
    "termTranslation": "culture",
    "ex": "私はこの国の文化を勉強しています。",
    "exReading": "わたし は この くに の ぶんか を べんきょう して います",
    "exTranslation": "I'm studying this country's culture.",
    "category": "Noun"
  },
  {
    "term": "増える",
    "termReading": "ふえる",
    "termTranslation": "increase, accrue",
    "ex": "この町は人口が増えた。",
    "exReading": "この まち は じんこう が ふえた",
    "exTranslation": "The population has increased in this town.",
    "category": "Verb"
  },
  {
    "term": "増やす",
    "termReading": "ふやす",
    "termTranslation": "increase, add",
    "ex": "あの町は緑を増やしています。",
    "exReading": "あの まち は みどり を ふやして います",
    "exTranslation": "That town is increasing its greenery.",
    "category": "Verb"
  },
  {
    "term": "減る",
    "termReading": "へる",
    "termTranslation": "decrease, diminish",
    "ex": "体重がかなり減りました。",
    "exReading": "たいじゅう が かなり へりました",
    "exTranslation": "I've lost a lot of weight.",
    "category": "Verb"
  },
  {
    "term": "ネクタイ",
    "termReading": "ネクタイ",
    "termTranslation": "tie, necktie",
    "ex": "父の日にネクタイをプレゼントした。",
    "exReading": "ちちのひ に ねくたい を ぷれぜんと した",
    "exTranslation": "I gave him a tie on Father's Day.",
    "category": "Noun"
  },
  {
    "term": "減らす",
    "termReading": "へらす",
    "termTranslation": "decrease, reduce",
    "ex": "最近、食事を減らしています。",
    "exReading": "さいきん しょくじ を へらして います",
    "exTranslation": "I've been eating less recently.",
    "category": "Verb"
  },
  {
    "term": "乗り物",
    "termReading": "のりもの",
    "termTranslation": "vehicle, conveyance",
    "ex": "自転車は便利な乗り物です。",
    "exReading": "じてんしゃ は べんり な のりもの です",
    "exTranslation": "Bicycles are a convenient form of transport.",
    "category": "Noun"
  },
  {
    "term": "降りる",
    "termReading": "おりる",
    "termTranslation": "get off, land",
    "ex": "次の駅で降ります。",
    "exReading": "つぎ の えき で おります。",
    "exTranslation": "I get off at the next station.",
    "category": "Verb"
  },
  {
    "term": "降ろす",
    "termReading": "おろす",
    "termTranslation": "unload, set down",
    "ex": "彼は車から荷物を降ろした。",
    "exReading": "かれ は くるま から にもつ を おろした",
    "exTranslation": "He unloaded the luggage from the car.",
    "category": "Verb"
  },
  {
    "term": "バイオリン",
    "termReading": "バイオリン",
    "termTranslation": "violin",
    "ex": "彼女はバイオリンを習っています。",
    "exReading": "かのじょ は ばいおりん を ならって います",
    "exTranslation": "She's learning the violin.",
    "category": "Noun"
  },
  {
    "term": "降る",
    "termReading": "ふる",
    "termTranslation": "fall, come down",
    "ex": "激しい雨が降っています。",
    "exReading": "はげしい あめ が ふって います",
    "exTranslation": "A very heavy rain is falling.",
    "category": "Verb"
  },
  {
    "term": "着く",
    "termReading": "つく",
    "termTranslation": "arrive, make contact, settle",
    "ex": "午後8時に大阪に着きます。",
    "exReading": "ごご はちじ に おおさか に つきます",
    "exTranslation": "I arrive in Osaka at 8:00 pm.",
    "category": "Verb"
  },
  {
    "term": "着物",
    "termReading": "きもの",
    "termTranslation": "clothes, kimono",
    "ex": "彼女は着物がよく似合います。",
    "exReading": "かのじょ は きもの が よく にあいます",
    "exTranslation": "She looks good in a kimono.",
    "category": "Noun"
  },
  {
    "term": "下着",
    "termReading": "したぎ",
    "termTranslation": "underwear",
    "ex": "私は下着を手で洗う。",
    "exReading": "わたし は したぎ を て で あらう",
    "exTranslation": "I wash my underwear by hand.",
    "category": "Noun"
  },
  {
    "term": "パスポート",
    "termReading": "パスポート",
    "termTranslation": "passport",
    "ex": "パスポートが見つからない。",
    "exReading": "ぱすぽーと が みつからない",
    "exTranslation": "I can't find my passport.",
    "category": "Noun"
  },
  {
    "term": "上着",
    "termReading": "うわぎ",
    "termTranslation": "coat, jacket",
    "ex": "暑いので上着を脱ぎました。",
    "exReading": "あつい の で うわぎ を ぬぎました",
    "exTranslation": "I took my jacket off because it was hot.",
    "category": "Noun"
  },
  {
    "term": "着せる",
    "termReading": "きせる",
    "termTranslation": "dress, put on clothes",
    "ex": "娘に可愛いドレスを着せた。",
    "exReading": "むすめ に かわいい どれす を きせた",
    "exTranslation": "I dressed my daughter in a cute dress.",
    "category": "Verb"
  },
  {
    "term": "脱ぐ",
    "termReading": "ぬぐ",
    "termTranslation": "remove clothes, take off",
    "ex": "靴を脱いでください。",
    "exReading": "くつ を ぬいで ください",
    "exTranslation": "Please take off your shoes.",
    "category": "Verb"
  },
  {
    "term": "立場",
    "termReading": "たちば",
    "termTranslation": "standpoint, position",
    "ex": "彼女は自分の立場を分かっていない。",
    "exReading": "かのじょ は じぶん の たちば を わかって いない",
    "exTranslation": "She doesn't understand her position.",
    "category": "Noun"
  },
  {
    "term": "バッグ",
    "termReading": "バッグ",
    "termTranslation": "bag",
    "ex": "私は黒いバッグを持っています。",
    "exReading": "わたし は くろい ばっぐ を もって います",
    "exTranslation": "I have a black bag.",
    "category": "Noun"
  },
  {
    "term": "目立つ",
    "termReading": "めだつ",
    "termTranslation": "stand out, be conspicuous",
    "ex": "彼女の大きな帽子はとても目立つ。",
    "exReading": "かのじょ の おおき な ぼうし は とても めだつ",
    "exTranslation": "Her big hat really stands out.",
    "category": "Verb"
  },
  {
    "term": "立てる",
    "termReading": "たてる",
    "termTranslation": "stand, set up, establish",
    "ex": "彼はケーキにろうそくを立てた。",
    "exReading": "かれ は けーき に ろうそく を たてた",
    "exTranslation": "He stuck the candles into the cake.",
    "category": "Verb"
  },
  {
    "term": "役に立つ",
    "termReading": "やくにたつ",
    "termTranslation": "be useful, be helpful",
    "ex": "私は人々の役に立ちたいと思っています。",
    "exReading": "わたし は ひとびと の やくにたちたい と おもって います",
    "exTranslation": "I want to be of service to others.",
    "category": "Verb"
  },
  {
    "term": "出席",
    "termReading": "しゅっせき",
    "termTranslation": "attendance, presence",
    "ex": "午後は会議に出席します。",
    "exReading": "ごご は かいぎ に しゅっせき します",
    "exTranslation": "I'll attend a meeting in the afternoon.",
    "category": "Verbal Noun"
  },
  {
    "term": "ゴム",
    "termReading": "ゴム",
    "termTranslation": "elastic",
    "ex": "ゴムが伸びてしまった。",
    "exReading": "ごむ が のびて しまった",
    "exTranslation": "The elastic has gone loose.",
    "category": "Noun"
  },
  {
    "term": "席",
    "termReading": "せき",
    "termTranslation": "seat, one's place",
    "ex": "この席、空いてますか。",
    "exReading": "この せき あいてます か",
    "exTranslation": "Is this seat free?",
    "category": "Noun"
  },
  {
    "term": "欠席",
    "termReading": "けっせき",
    "termTranslation": "absence, nonattendance",
    "ex": "風邪のため今日は欠席します。",
    "exReading": "かぜ の ため きょう は けっせき します",
    "exTranslation": "I'll be absent today because of a cold.",
    "category": "Verbal Noun"
  },
  {
    "term": "次男",
    "termReading": "じなん",
    "termTranslation": "second son",
    "ex": "次男は今、海外にいます。",
    "exReading": "じなん は いま かいがい に います",
    "exTranslation": "My second son is abroad now.",
    "category": "Noun"
  },
  {
    "term": "次女",
    "termReading": "じじょ",
    "termTranslation": "second daughter",
    "ex": "うちの次女は春から中学生です。",
    "exReading": "うち の じじょ は はる から ちゅうがくせい です",
    "exTranslation": "Our second daughter is going into junior high this spring.",
    "category": "Noun"
  },
  {
    "term": "ギター",
    "termReading": "ギター",
    "termTranslation": "guitar",
    "ex": "彼女はギターが得意です。",
    "exReading": "かのじょ は ぎたー が とくい です",
    "exTranslation": "She's good at the guitar.",
    "category": "Noun"
  },
  {
    "term": "運ぶ",
    "termReading": "はこぶ",
    "termTranslation": "carry, transport",
    "ex": "いすを二階に運んでください。",
    "exReading": "いす を にかい に はこんで ください",
    "exTranslation": "Please carry the chair to the second floor.",
    "category": "Verb"
  },
  {
    "term": "運転",
    "termReading": "うんてん",
    "termTranslation": "drive",
    "ex": "父は安全運転だ。",
    "exReading": "ちち は あんぜん うんてん だ",
    "exTranslation": "My father drives safely.",
    "category": "Verbal Noun"
  },
  {
    "term": "運転手",
    "termReading": "うんてんしゅ",
    "termTranslation": "driver",
    "ex": "彼はタクシーの運転手です。",
    "exReading": "かれ は たくしー の うんてんしゅ です",
    "exTranslation": "He's a taxi driver.",
    "category": "Noun"
  },
  {
    "term": "転ぶ",
    "termReading": "ころぶ",
    "termTranslation": "tumble down, fall over",
    "ex": "お祖母ちゃんがお風呂場で転んだ。",
    "exReading": "おばあちゃん が おふろば で ころんだ",
    "exTranslation": "My grandma fell down in the bathroom.",
    "category": "Verb"
  },
  {
    "term": "セーター",
    "termReading": "セーター",
    "termTranslation": "sweater, pullover",
    "ex": "このセーターはウールだ。",
    "exReading": "この せーたー は うーる だ",
    "exTranslation": "This sweater is made of wool.",
    "category": "Noun"
  },
  {
    "term": "移る",
    "termReading": "うつる",
    "termTranslation": "move, shift",
    "ex": "彼女は新しい会社に移った。",
    "exReading": "かのじょ は あたらしい かいしゃ に うつった",
    "exTranslation": "She moved to a new company.",
    "category": "Verb"
  },
  {
    "term": "移す",
    "termReading": "うつす",
    "termTranslation": "move, shift, transfer",
    "ex": "机を窓の傍に移しました。",
    "exReading": "つくえ を まど の そば に うつしました",
    "exTranslation": "I moved the desk near the window.",
    "category": "Verb"
  },
  {
    "term": "動き",
    "termReading": "うごき",
    "termTranslation": "movement, motion",
    "ex": "まず相手の動きを見ましょう。",
    "exReading": "まず あいて の うごき を みましょう",
    "exTranslation": "First, watch your opponent's moves.",
    "category": "Noun"
  },
  {
    "term": "運動",
    "termReading": "うんどう",
    "termTranslation": "movement, exercise",
    "ex": "彼はもっと運動した方がいい。",
    "exReading": "かれ は もっと うんどう した ほう が いい",
    "exTranslation": "He should exercise more.",
    "category": "Verbal Noun"
  },
  {
    "term": "やっぱり",
    "termReading": "やっぱり",
    "termTranslation": "just as I thought, after all",
    "ex": "やっぱり旅が大好きだ。",
    "exReading": "やっぱり たび が だいすき だ",
    "exTranslation": "I just love to travel.",
    "category": "Adverb"
  },
  {
    "term": "活動",
    "termReading": "かつどう",
    "termTranslation": "activity",
    "ex": "彼は地域の活動に参加した。",
    "exReading": "かれ は ちいき の かつどう に さんか した",
    "exTranslation": "He took part in local activities.",
    "category": "Verbal Noun"
  },
  {
    "term": "動物",
    "termReading": "どうぶつ",
    "termTranslation": "animal",
    "ex": "私は動物が大好きです。",
    "exReading": "わたし は どうぶつ が だいすき です",
    "exTranslation": "I love animals.",
    "category": "Noun"
  },
  {
    "term": "動かす",
    "termReading": "うごかす",
    "termTranslation": "move, operate",
    "ex": "部屋の家具を動かしました。",
    "exReading": "へや の かぐ を うごかしました",
    "exTranslation": "I moved the furniture in the room.",
    "category": "Verb"
  },
  {
    "term": "不動産屋",
    "termReading": "ふどうさんや",
    "termTranslation": "real estate agent, realtor",
    "ex": "私の父は不動産屋です。",
    "exReading": "わたし の ちち は ふどうさんや です",
    "exTranslation": "My father's a real estate agent.",
    "category": "Noun"
  },
  {
    "term": "カーテン",
    "termReading": "カーテン",
    "termTranslation": "curtain",
    "ex": "朝、カーテンを開けた。",
    "exReading": "あさ かーてん を あけた",
    "exTranslation": "I opened the curtains in the morning.",
    "category": "Noun"
  },
  {
    "term": "早く",
    "termReading": "はやく",
    "termTranslation": "early, soon",
    "ex": "なるべく早く来て下さい。",
    "exReading": "なるべく はやく きて ください",
    "exTranslation": "Please come as early as possible.",
    "category": "Adverb"
  },
  {
    "term": "早口",
    "termReading": "はやくち",
    "termTranslation": "fast talking",
    "ex": "彼女は早口だ。",
    "exReading": "かのじょ は はやくち だ",
    "exTranslation": "She talks quickly.",
    "category": "Noun"
  },
  {
    "term": "速さ",
    "termReading": "はやさ",
    "termTranslation": "speed",
    "ex": "新幹線の速さはどれ位ですか。",
    "exReading": "しんかんせん の はやさ は どれ くらい です か",
    "exTranslation": "How fast is the bullet train?",
    "category": "Noun"
  },
  {
    "term": "速達",
    "termReading": "そくたつ",
    "termTranslation": "special delivery, express mail",
    "ex": "これを速達で送りたいのですが。",
    "exReading": "これ を そくたつ で おくりたい の です が",
    "exTranslation": "I want to send this by express delivery.",
    "category": "Noun"
  },
  {
    "term": "シャツ",
    "termReading": "シャツ",
    "termTranslation": "shirt",
    "ex": "このシャツはアイロンが必要だ。",
    "exReading": "この しゃつ は あいろん が ひつよう だ",
    "exTranslation": "This shirt needs ironing.",
    "category": "Noun"
  },
  {
    "term": "遅れる",
    "termReading": "おくれる",
    "termTranslation": "be late",
    "ex": "今朝彼女は学校に遅れました。",
    "exReading": "けさ かのじょ は がっこう に おくれました",
    "exTranslation": "She was late for school this morning.",
    "category": "Verb"
  },
  {
    "term": "開始",
    "termReading": "かいし",
    "termTranslation": "beginning",
    "ex": "運動会は9時開始です。",
    "exReading": "うんどうかい は くじ かいし です",
    "exTranslation": "The athletic meet begins at nine o'clock.",
    "category": "Verbal Noun"
  },
  {
    "term": "始めに",
    "termReading": "はじめに",
    "termTranslation": "in the beginning, early in",
    "ex": "始めにスープが出ます。",
    "exReading": "はじめに すーぷ が でます",
    "exTranslation": "Soup is served first.",
    "category": "Adverb"
  },
  {
    "term": "現在",
    "termReading": "げんざい",
    "termTranslation": "the present, present time",
    "ex": "現在の気温は30度です。",
    "exReading": "げんざい の きおん は さんじゅうど です",
    "exTranslation": "The current temperature is 30 degrees.",
    "category": "Noun"
  },
  {
    "term": "ナイフ",
    "termReading": "ナイフ",
    "termTranslation": "knife",
    "ex": "ナイフで手を切った。",
    "exReading": "ないふ で て を きった",
    "exTranslation": "I cut my hand with a knife.",
    "category": "Noun"
  },
  {
    "term": "実現",
    "termReading": "じつげん",
    "termTranslation": "implementation, realization",
    "ex": "夢を実現するには努力が必要です。",
    "exReading": "ゆめ を じつげん する に は どりょく が ひつよう です",
    "exTranslation": "You need to make an effort to make your dreams come true.",
    "category": "Verbal Noun"
  },
  {
    "term": "実施",
    "termReading": "じっし",
    "termTranslation": "enforcement, implementation",
    "ex": "現在、スペシャルキャンペーンを実施中です。",
    "exReading": "げんざい すぺしゃるきゃんぺーん を じっしちゅう です",
    "exTranslation": "A special campaign is now under way.",
    "category": "Verbal Noun"
  },
  {
    "term": "事実",
    "termReading": "じじつ",
    "termTranslation": "fact, truth",
    "ex": "それは全て事実ですか。",
    "exReading": "それ は すべて じじつ です か",
    "exTranslation": "Is it all true?",
    "category": "Noun"
  },
  {
    "term": "実行",
    "termReading": "じっこう",
    "termTranslation": "practice, execution",
    "ex": "彼はその計画を実行した。",
    "exReading": "かれ は その けいかく を じっこう した",
    "exTranslation": "He executed the plan.",
    "category": "Verbal Noun"
  },
  {
    "term": "バイク",
    "termReading": "バイク",
    "termTranslation": "motorbike, motor cycle",
    "ex": "兄はバイクが大好きです。",
    "exReading": "あに は ばいく が だいすき です",
    "exTranslation": "My older brother loves motorcycles.",
    "category": "Noun"
  },
  {
    "term": "実験",
    "termReading": "じっけん",
    "termTranslation": "experiment",
    "ex": "科学の授業で実験をした。",
    "exReading": "かがく の じゅぎょう で じっけん を した",
    "exTranslation": "We did an experiment in science class.",
    "category": "Verbal Noun"
  },
  {
    "term": "通過",
    "termReading": "つうか",
    "termTranslation": "passing, passage",
    "ex": "次の駅は通過します。",
    "exReading": "つぎ の えき は つうか します",
    "exTranslation": "This train will not stop at the next station.",
    "category": "Verbal Noun"
  },
  {
    "term": "過ぎる",
    "termReading": "すぎる",
    "termTranslation": "pass by, exceed",
    "ex": "時が過ぎるのは速い。",
    "exReading": "とき が すぎる の は はやい 。",
    "exTranslation": "Time passes quickly.",
    "category": "Verb"
  },
  {
    "term": "昼過ぎ",
    "termReading": "ひるすぎ",
    "termTranslation": "early afternoon",
    "ex": "彼は昼過ぎに来ます。",
    "exReading": "かれ は ひるすぎ に きます",
    "exTranslation": "He'll come after lunch.",
    "category": "Noun"
  },
  {
    "term": "いつか",
    "termReading": "いつか",
    "termTranslation": "some time, some day",
    "ex": "僕はいつかアフリカに行きたい。",
    "exReading": "ぼく は いつか あふりか に いきたい",
    "exTranslation": "I want to go to Africa some time.",
    "category": "Adverb"
  },
  {
    "term": "過去",
    "termReading": "かこ",
    "termTranslation": "the past",
    "ex": "それは過去の話だ。",
    "exReading": "それ は かこ の はなし だ",
    "exTranslation": "That's in the past now.",
    "category": "Noun"
  },
  {
    "term": "開発",
    "termReading": "かいはつ",
    "termTranslation": "development",
    "ex": "ダムの開発に住民は反対しています。",
    "exReading": "だむ の かいはつ に じゅうみん は はんたい して います",
    "exTranslation": "The residents are against the development of the dam.",
    "category": "Verbal Noun"
  },
  {
    "term": "発生",
    "termReading": "はっせい",
    "termTranslation": "occurrence, happening",
    "ex": "交差点で事故が発生した。",
    "exReading": "こうさてん で じこ が はっせい した",
    "exTranslation": "There was an accident at the intersection.",
    "category": "Verbal Noun"
  },
  {
    "term": "発言",
    "termReading": "はつげん",
    "termTranslation": "utterance, speech",
    "ex": "会議で全員が発言した。",
    "exReading": "かいぎ で ぜんいん が はつげん した",
    "exTranslation": "Everyone spoke at the conference.",
    "category": "Verbal Noun"
  },
  {
    "term": "オレンジ",
    "termReading": "オレンジ",
    "termTranslation": "orange",
    "ex": "私はオレンジが好きです。",
    "exReading": "わたし は おれんじ が すき です",
    "exTranslation": "I like oranges.",
    "category": "Noun"
  },
  {
    "term": "発見",
    "termReading": "はっけん",
    "termTranslation": "discovery, revelation",
    "ex": "新しい星が発見された。",
    "exReading": "あたらしい ほし が はっけん された",
    "exTranslation": "A new star was discovered.",
    "category": "Verbal Noun"
  },
  {
    "term": "出発",
    "termReading": "しゅっぱつ",
    "termTranslation": "departure, starting",
    "ex": "あと15分で出発です。",
    "exReading": "あと じゅうごふん で しゅっぱつ です",
    "exTranslation": "Departure will be in fifteen minutes.",
    "category": "Verbal Noun"
  },
  {
    "term": "発車",
    "termReading": "はっしゃ",
    "termTranslation": "start, leave",
    "ex": "バスが発車します。",
    "exReading": "ばす が はっしゃ します",
    "exTranslation": "The bus is leaving.",
    "category": "Verbal Noun"
  },
  {
    "term": "発表",
    "termReading": "はっぴょう",
    "termTranslation": "announcement",
    "ex": "合格者が発表された。",
    "exReading": "ごうかくしゃ が はっぴょう された",
    "exTranslation": "Those who passed were announced.",
    "category": "Verbal Noun"
  },
  {
    "term": "きつい",
    "termReading": "きつい",
    "termTranslation": "tight, difficult",
    "ex": "このシャツは少しきついです。",
    "exReading": "この しゃつ は すこし きつい です",
    "exTranslation": "This shirt is a little tight.",
    "category": "Adjective"
  },
  {
    "term": "表現",
    "termReading": "ひょうげん",
    "termTranslation": "expression",
    "ex": "彼は歌で自分の気持ちを表現した。",
    "exReading": "かれ は うた で じぶん の きもち を ひょうげん した",
    "exTranslation": "He expressed his feelings in song.",
    "category": "Verbal Noun"
  },
  {
    "term": "代表",
    "termReading": "だいひょう",
    "termTranslation": "representative",
    "ex": "彼がクラスの代表だ。",
    "exReading": "かれ が くらす の だいひょう だ",
    "exTranslation": "He's the class representative.",
    "category": "Verbal Noun"
  },
  {
    "term": "表",
    "termReading": "おもて",
    "termTranslation": "front side, face, outside",
    "ex": "表に人が来ています。",
    "exReading": "おもてに ひと が きて います",
    "exTranslation": "Someone has come to the door.",
    "category": "Noun"
  },
  {
    "term": "表",
    "termReading": "ひょう",
    "termTranslation": "table, list",
    "ex": "この表を見てください。",
    "exReading": "この ひょう を みて ください",
    "exTranslation": "Please look at this chart.",
    "category": "Noun"
  },
  {
    "term": "さっき",
    "termReading": "さっき",
    "termTranslation": "some time ago, only a moment ago",
    "ex": "さっきの話を続けましょう。",
    "exReading": "さっき の はなし を つづけましょう",
    "exTranslation": "Let's continue the conversation we had earlier.",
    "category": "Adverb"
  },
  {
    "term": "手紙",
    "termReading": "てがみ",
    "termTranslation": "letter",
    "ex": "友人から手紙をもらいました。",
    "exReading": "ゆうじん から てがみ を もらいました",
    "exTranslation": "I got a letter from a friend.",
    "category": "Noun"
  },
  {
    "term": "絵",
    "termReading": "え",
    "termTranslation": "picture, painting, drawing",
    "ex": "これは有名な画家の絵です。",
    "exReading": "これ は ゆうめい な がか の え です",
    "exTranslation": "This is a painting by a famous painter.",
    "category": "Noun"
  },
  {
    "term": "雑誌",
    "termReading": "ざっし",
    "termTranslation": "magazine, journal",
    "ex": "この雑誌はよく売れています。",
    "exReading": "この ざっし は よく うれて います",
    "exTranslation": "This magazine sells well.",
    "category": "Noun"
  },
  {
    "term": "音",
    "termReading": "おと",
    "termTranslation": "sound, noise",
    "ex": "雨の音が聞こえる。",
    "exReading": "あめ の おと が きこえる",
    "exTranslation": "I can hear the sound of rain.",
    "category": "Noun"
  },
  {
    "term": "テキスト",
    "termReading": "テキスト",
    "termTranslation": "text, text file",
    "ex": "テキストを読んでください。",
    "exReading": "てきすと を よんで ください",
    "exTranslation": "Please read the textbook.",
    "category": "Noun"
  },
  {
    "term": "音",
    "termReading": "おと",
    "termTranslation": "sound, speech sound, Chinese pronunciation of a character",
    "ex": "このピアノは音があまり良くない。",
    "exReading": "この ぴあの は おと が あまり よく ない",
    "exTranslation": "That piano's sound isn't very good.",
    "category": "Noun"
  },
  {
    "term": "音楽",
    "termReading": "おんがく",
    "termTranslation": "music",
    "ex": "私は音楽を聞くのが好きだ。",
    "exReading": "わたし は おんがく を きく の が すき だ",
    "exTranslation": "I like to listen to music.",
    "category": "Noun"
  },
  {
    "term": "薬",
    "termReading": "くすり",
    "termTranslation": "drug, remedy, chemical",
    "ex": "この薬を必ず飲んでください。",
    "exReading": "この くすり を かならず のんで ください",
    "exTranslation": "Please be sure to take this medicine.",
    "category": "Noun"
  },
  {
    "term": "歌",
    "termReading": "うた",
    "termTranslation": "song",
    "ex": "私はその歌を知らなかった。",
    "exReading": "わたし は その うた を しらなかった 。",
    "exTranslation": "I didn't know that song.",
    "category": "Noun"
  },
  {
    "term": "ビニール",
    "termReading": "ビニール",
    "termTranslation": "plastic bag",
    "ex": "ゴミはそのビニール袋に入れてください。",
    "exReading": "ごみ は その びにーるぶくろ に いれて ください",
    "exTranslation": "Please put trash in that plastic bag.",
    "category": "Noun"
  },
  {
    "term": "歌手",
    "termReading": "かしゅ",
    "termTranslation": "singer",
    "ex": "その歌手は歌が下手だ。",
    "exReading": "その かしゅ は うた が へた だ",
    "exTranslation": "That singer's terrible.",
    "category": "Noun"
  },
  {
    "term": "欲しがる",
    "termReading": "ほしがる",
    "termTranslation": "desire, wish for, covet",
    "ex": "子供がジュースを欲しがっています。",
    "exReading": "こども が じゅーす を ほしがって います",
    "exTranslation": "My kid wants some juice.",
    "category": "Verb"
  },
  {
    "term": "計画",
    "termReading": "けいかく",
    "termTranslation": "plan, project",
    "ex": "彼は一人旅の計画を立てた。",
    "exReading": "かれ は ひとりたび の けいかく を たてた",
    "exTranslation": "He planned a journey alone.",
    "category": "Verbal Noun"
  },
  {
    "term": "映画",
    "termReading": "えいが",
    "termTranslation": "movie",
    "ex": "彼はよく映画を見ます。",
    "exReading": "かれ は よく えいが を みます",
    "exTranslation": "He often watches movies.",
    "category": "Noun"
  },
  {
    "term": "りんご",
    "termReading": "りんご",
    "termTranslation": "apple",
    "ex": "りんごを一つください。",
    "exReading": "りんご を ひとつ ください",
    "exTranslation": "Please give me one of the apples.",
    "category": "Noun"
  },
  {
    "term": "面白い",
    "termReading": "おもしろい",
    "termTranslation": "interesting, amusing",
    "ex": "この本は全然面白くなかった。",
    "exReading": "この ほん は ぜんぜん おもしろく なかった",
    "exTranslation": "This book wasn't interesting at all.",
    "category": "Adjective"
  },
  {
    "term": "写る",
    "termReading": "うつる",
    "termTranslation": "be taken, be seen through",
    "ex": "このカメラはよく写りますよ。",
    "exReading": "この かめら は よく うつります よ",
    "exTranslation": "This camera takes good pictures.",
    "category": "Verb"
  },
  {
    "term": "写す",
    "termReading": "うつす",
    "termTranslation": "copy, photograph",
    "ex": "彼は友達の答えを写した。",
    "exReading": "かれ は ともだち の こたえ を うつした",
    "exTranslation": "He copied down his friend's answers.",
    "category": "Verb"
  },
  {
    "term": "写真",
    "termReading": "しゃしん",
    "termTranslation": "photograph",
    "ex": "写真は良い思い出になります。",
    "exReading": "しゃしん は いい おもいで に なります",
    "exTranslation": "Photographs make a good souvenir.",
    "category": "Noun"
  },
  {
    "term": "アルバム",
    "termReading": "アルバム",
    "termTranslation": "album",
    "ex": "私は彼のアルバムを見た。",
    "exReading": "わたし は かれ の あるばむ を みた",
    "exTranslation": "I saw his album.",
    "category": "Noun"
  },
  {
    "term": "真っ赤",
    "termReading": "まっか",
    "termTranslation": "deep red",
    "ex": "彼の顔は真っ赤でした。",
    "exReading": "かれ の かお は まっか でした",
    "exTranslation": "His face was red.",
    "category": "Adjectival Noun"
  },
  {
    "term": "真面目",
    "termReading": "まじめ",
    "termTranslation": "serious, sober, earnest",
    "ex": "彼は真面目な人です。",
    "exReading": "かれ は まじめ な ひと です",
    "exTranslation": "He's a serious-minded person.",
    "category": "Adjectival Noun"
  },
  {
    "term": "真ん中",
    "termReading": "まんなか",
    "termTranslation": "center, middle",
    "ex": "道の真ん中に人が立っている。",
    "exReading": "みち の まんなか に ひと が たって いる",
    "exTranslation": "Someone is standing in the middle of the road.",
    "category": "Noun"
  },
  {
    "term": "真っ白",
    "termReading": "まっしろ",
    "termTranslation": "pure-white",
    "ex": "外は雪で真っ白だった。",
    "exReading": "そと は ゆき で まっしろ だった",
    "exTranslation": "Everything outside was white with snow.",
    "category": "Adjectival Noun"
  },
  {
    "term": "スカート",
    "termReading": "スカート",
    "termTranslation": "skirt",
    "ex": "彼女はあまりスカートははかない。",
    "exReading": "かのじょ は あまり すかーと は はかない",
    "exTranslation": "She doesn't wear skirts very often.",
    "category": "Noun"
  },
  {
    "term": "真っ暗",
    "termReading": "まっくら",
    "termTranslation": "pitch-dark",
    "ex": "外は真っ暗です。",
    "exReading": "そと は まっくら です",
    "exTranslation": "It's pitch-dark outside.",
    "category": "Adjectival Noun"
  },
  {
    "term": "真っ黒",
    "termReading": "まっくろ",
    "termTranslation": "deep-black, jet-black",
    "ex": "インクで手が真っ黒になった",
    "exReading": "いんく で て が まっくろ に なった",
    "exTranslation": "My hands got all black with ink.",
    "category": "Adjectival Noun"
  },
  {
    "term": "真っ青",
    "termReading": "まっさお",
    "termTranslation": "deep-blue, pale (skin)",
    "ex": "空が真っ青です。",
    "exReading": "そら が まっさお です",
    "exTranslation": "The sky is bright blue.",
    "category": "Adjectival Noun"
  },
  {
    "term": "色々",
    "termReading": "いろいろ",
    "termTranslation": "in various ways",
    "ex": "彼は色々なことを知っている。",
    "exReading": "かれ は いろいろ な こと を しって いる",
    "exTranslation": "He knows about a lot of things.",
    "category": "Adjectival Noun"
  },
  {
    "term": "ペン",
    "termReading": "ペン",
    "termTranslation": "pen",
    "ex": "ペンを貸してください。",
    "exReading": "ぺん を かして ください",
    "exTranslation": "Please lend me the pen.",
    "category": "Noun"
  },
  {
    "term": "人形",
    "termReading": "にんぎょう",
    "termTranslation": "doll",
    "ex": "彼女は人形をたくさん持っています。",
    "exReading": "かのじょ は にんぎょう を たくさん もって います",
    "exTranslation": "She has a lot of dolls.",
    "category": "Noun"
  },
  {
    "term": "形",
    "termReading": "かたち",
    "termTranslation": "shape, form",
    "ex": "その椅子は変わった形をしている。",
    "exReading": "その いす は かわった かたち を して いる",
    "exTranslation": "That chair has an unusual shape.",
    "category": "Noun"
  },
  {
    "term": "大型",
    "termReading": "おおがた",
    "termTranslation": "large, large scale",
    "ex": "大型のテレビを買った。",
    "exReading": "おおがた の てれび を かった",
    "exTranslation": "We bought a large television.",
    "category": "Noun"
  },
  {
    "term": "種類",
    "termReading": "しゅるい",
    "termTranslation": "kind, species",
    "ex": "バラには色々な種類があります。",
    "exReading": "ばら に は いろいろ な しゅるい が あります",
    "exTranslation": "There are many varieties of rose.",
    "category": "Noun"
  },
  {
    "term": "おしゃべり",
    "termReading": "おしゃべり",
    "termTranslation": "chatter, talkative",
    "ex": "妹はとてもおしゃべりです。",
    "exReading": "いもうと は とても おしゃべり です",
    "exTranslation": "My little sister's very talkative.",
    "category": "Verbal Noun"
  },
  {
    "term": "直す",
    "termReading": "なおす",
    "termTranslation": "repair, cure, alter",
    "ex": "私がそれを直しました。",
    "exReading": "わたし が それ を なおしました",
    "exTranslation": "I mended it.",
    "category": "Verb"
  },
  {
    "term": "真っ直ぐ",
    "termReading": "まっすぐ",
    "termTranslation": "dead straight, honestly",
    "ex": "この道を真っ直ぐ行ってください。",
    "exReading": "この みち を まっすぐ いって ください",
    "exTranslation": "Please go straight along this road.",
    "category": "Adverb"
  },
  {
    "term": "直接",
    "termReading": "ちょくせつ",
    "termTranslation": "directly",
    "ex": "彼に直接お願いしなさい。",
    "exReading": "かれ に ちょくせつ おねがい しなさい",
    "exTranslation": "Ask him directly.",
    "category": "Adverb"
  },
  {
    "term": "角",
    "termReading": "かど",
    "termTranslation": "corner, edge, angle",
    "ex": "次の角で左に曲がってください。",
    "exReading": "つぎ の かど で ひだり に まがって ください",
    "exTranslation": "Please turn left at the next corner.",
    "category": "Noun"
  },
  {
    "term": "きっと",
    "termReading": "きっと",
    "termTranslation": "surely, certainly",
    "ex": "明日はきっと雨が降ります。",
    "exReading": "あした は きっと あめ が ふります",
    "exTranslation": "I'm sure it'll rain tomorrow.",
    "category": "Adverb"
  },
  {
    "term": "三角",
    "termReading": "さんかく",
    "termTranslation": "triangle",
    "ex": "紙を三角に切りました。",
    "exReading": "かみ を さんかく に きりました",
    "exTranslation": "I cut the paper into a triangle.",
    "category": "Noun"
  },
  {
    "term": "四角",
    "termReading": "しかく",
    "termTranslation": "square, quadrilateral",
    "ex": "紙を四角に切ってください。",
    "exReading": "かみ を しかく に きって ください",
    "exTranslation": "Please cut the paper into a square.",
    "category": "Adjectival Noun"
  },
  {
    "term": "四角い",
    "termReading": "しかくい",
    "termTranslation": "square, four-sided",
    "ex": "こっちの四角いテーブルを買おうよ。",
    "exReading": "こっち の しかくい てーぶる を かおうよ",
    "exTranslation": "Let's buy this square table over here.",
    "category": "Adjective"
  },
  {
    "term": "四つ角",
    "termReading": "よつかど",
    "termTranslation": "street corner, intersection",
    "ex": "あそこの四つ角を左に曲がってください。",
    "exReading": "あそこ の よつかど を ひだり に まがって ください",
    "exTranslation": "Please turn left at that crossroad up there.",
    "category": "Noun"
  },
  {
    "term": "メニュー",
    "termReading": "メニュー",
    "termTranslation": "menu, bill of fare",
    "ex": "メニューをください。",
    "exReading": "めにゅー を ください",
    "exTranslation": "Please give me the menu.",
    "category": "Noun"
  },
  {
    "term": "曲",
    "termReading": "きょく",
    "termTranslation": "song, melody, tune",
    "ex": "私はこの曲が大好きです。",
    "exReading": "わたし は この きょく が だいすき です",
    "exTranslation": "I love this song.",
    "category": "Noun"
  },
  {
    "term": "曲げる",
    "termReading": "まげる",
    "termTranslation": "bend, curve",
    "ex": "ひざを曲げてください。",
    "exReading": "ひざ を まげて ください",
    "exTranslation": "Please bend your knees.",
    "category": "Verb"
  },
  {
    "term": "曲がり角",
    "termReading": "まがりかど",
    "termTranslation": "corner",
    "ex": "ポストはそこの曲がり角にあります。",
    "exReading": "ぽすと は そこ の まがりかど に あります",
    "exTranslation": "There's a post box on that corner.",
    "category": "Noun"
  },
  {
    "term": "同様",
    "termReading": "どうよう",
    "termTranslation": "similar",
    "ex": "私たちは彼を家族同様に思っている。",
    "exReading": "わたしたち は かれ を かぞく どうよう に おもって いる",
    "exTranslation": "We think of him just like family.",
    "category": "Adjectival Noun"
  },
  {
    "term": "アイスクリーム",
    "termReading": "アイスクリーム",
    "termTranslation": "ice cream",
    "ex": "弟はアイスクリームが大好きです。",
    "exReading": "おとうと は あいすくりーむ が だいすき です",
    "exTranslation": "My little brother loves ice cream.",
    "category": "Noun"
  },
  {
    "term": "間違い",
    "termReading": "まちがい",
    "termTranslation": "mistake, error",
    "ex": "この文には間違いがあります。",
    "exReading": "この ぶん に は まちがい が あります",
    "exTranslation": "There's a mistake in this sentence.",
    "category": "Noun"
  },
  {
    "term": "間違える",
    "termReading": "まちがえる",
    "termTranslation": "mistake, confuse",
    "ex": "電話番号を間違えました。",
    "exReading": "でんわ ばんごう を まちがえました",
    "exTranslation": "I called the wrong number.",
    "category": "Verb"
  },
  {
    "term": "間違う",
    "termReading": "まちがう",
    "termTranslation": "be mistaken, be incorrect",
    "ex": "あなたは間違っている。",
    "exReading": "あなた は まちがって いる",
    "exTranslation": "You're mistaken.",
    "category": "Verb"
  },
  {
    "term": "似ている",
    "termReading": "にている",
    "termTranslation": "look like, resemble",
    "ex": "私は母に似ています。",
    "exReading": "わたし は はは に にて います",
    "exTranslation": "I look like my mother.",
    "category": "Verb"
  },
  {
    "term": "おしゃれ",
    "termReading": "おしゃれ",
    "termTranslation": "dressing up smartly, trendy",
    "ex": "あの子はおしゃれだね。",
    "exReading": "あの こ は おしゃれ だ ね",
    "exTranslation": "That girl is trendy.",
    "category": "Verbal Noun"
  },
  {
    "term": "以上",
    "termReading": "いじょう",
    "termTranslation": "not less than, more than",
    "ex": "飛行機が1時間以上遅れた。",
    "exReading": "ひこうき が いちじかん いじょう おくれた",
    "exTranslation": "The plane arrived over an hour late.",
    "category": "Noun"
  },
  {
    "term": "旅行",
    "termReading": "りょこう",
    "termTranslation": "travel, trip",
    "ex": "彼女は旅行が好きです。",
    "exReading": "かのじょ は りょこう が すき です",
    "exTranslation": "She likes traveling.",
    "category": "Verbal Noun"
  },
  {
    "term": "大使館",
    "termReading": "たいしかん",
    "termTranslation": "embassy",
    "ex": "彼は大使館に勤めています。",
    "exReading": "かれ は たいしかん に つとめて います",
    "exTranslation": "He's working for the embassy.",
    "category": "Noun"
  },
  {
    "term": "旅館",
    "termReading": "りょかん",
    "termTranslation": "Japanese inn",
    "ex": "京都では旅館に泊まりました。",
    "exReading": "きょうと で は りょかん に とまりました",
    "exTranslation": "I stayed at an inn in Kyoto.",
    "category": "Noun"
  },
  {
    "term": "ジュース",
    "termReading": "ジュース",
    "termTranslation": "juice, soft drink",
    "ex": "このジュースは甘すぎる。",
    "exReading": "この じゅーす は あま すぎる",
    "exTranslation": "This juice is too sweet.",
    "category": "Noun"
  },
  {
    "term": "映画館",
    "termReading": "えいがかん",
    "termTranslation": "movie theater",
    "ex": "彼と近くの映画館に行きました。",
    "exReading": "かれ と ちかく の えいがかん に いきました",
    "exTranslation": "I went to a nearby movie theater with him.",
    "category": "Noun"
  },
  {
    "term": "宿題",
    "termReading": "しゅくだい",
    "termTranslation": "homework",
    "ex": "友達と一緒に宿題をした。",
    "exReading": "ともだち と いっしょ に しゅくだい を した",
    "exTranslation": "I did my homework with a friend.",
    "category": "Noun"
  },
  {
    "term": "泊める",
    "termReading": "とめる",
    "termTranslation": "lodge, give shelter",
    "ex": "友達をうちに泊めてあげました。",
    "exReading": "ともだち を うち に とめて あげました",
    "exTranslation": "I put my friend up at my house.",
    "category": "Verb"
  },
  {
    "term": "遊び",
    "termReading": "あそび",
    "termTranslation": "play, amusement",
    "ex": "お正月には色々な遊びをします。",
    "exReading": "おしょうがつ に は いろいろ な あそび を します",
    "exTranslation": "We play a lot of games at New Year's.",
    "category": "Noun"
  },
  {
    "term": "ゼロ",
    "termReading": "ゼロ",
    "termTranslation": "zero",
    "ex": "今日の交通事故はゼロです。",
    "exReading": "きょう の こうつう じこ は ぜろ です",
    "exTranslation": "Today there were zero traffic accidents.",
    "category": "Noun"
  },
  {
    "term": "洋服",
    "termReading": "ようふく",
    "termTranslation": "clothes",
    "ex": "今日は洋服を買いに行きます。",
    "exReading": "きょう は ようふく を かい に いきます",
    "exTranslation": "I'm going to buy some clothes today.",
    "category": "Noun"
  },
  {
    "term": "教室",
    "termReading": "きょうしつ",
    "termTranslation": "classroom, class",
    "ex": "私の教室は3階にあります。",
    "exReading": "わたし の きょうしつ は さんがい に あります",
    "exTranslation": "My classroom is on the third floor.",
    "category": "Noun"
  },
  {
    "term": "図書室",
    "termReading": "としょしつ",
    "termTranslation": "library",
    "ex": "図書室で勉強した。",
    "exReading": "としょしつ で べんきょう した",
    "exTranslation": "I studied at the library.",
    "category": "Noun"
  },
  {
    "term": "窓",
    "termReading": "まど",
    "termTranslation": "window, windowpane",
    "ex": "窓を開けてください。",
    "exReading": "まど を あけて ください",
    "exTranslation": "Please open the window.",
    "category": "Noun"
  },
  {
    "term": "ピンク",
    "termReading": "ピンク",
    "termTranslation": "pink",
    "ex": "娘がピンクのドレスを着ている。",
    "exReading": "むすめ が ぴんく の どれす を きて いる",
    "exTranslation": "My daughter's wearing a pink dress.",
    "category": "Noun"
  },
  {
    "term": "親しい",
    "termReading": "したしい",
    "termTranslation": "intimate, familiar",
    "ex": "週末、親しい友達を家に呼んだ。",
    "exReading": "しゅうまつ したしい ともだち を いえ に よんだ",
    "exTranslation": "I invited a good friend to my house over the weekend.",
    "category": "Adjective"
  },
  {
    "term": "親切",
    "termReading": "しんせつ",
    "termTranslation": "kind, friendly",
    "ex": "親切にしてくださってどうもありがとうございます。",
    "exReading": "しんせつ に して くださって どうも ありがとう ございます",
    "exTranslation": "Thank you for being so kind to me.",
    "category": "Adjectival Noun"
  },
  {
    "term": "不親切",
    "termReading": "ふしんせつ",
    "termTranslation": "unkind, inattentive",
    "ex": "その店員は不親切だった。",
    "exReading": "その てんいん は ふしんせつ だった",
    "exTranslation": "That store clerk was unkind.",
    "category": "Adjectival Noun"
  },
  {
    "term": "家族",
    "termReading": "かぞく",
    "termTranslation": "family, household",
    "ex": "うちは五人家族です。",
    "exReading": "うち は ごにん かぞく です",
    "exTranslation": "We're a family of five.",
    "category": "Noun"
  },
  {
    "term": "グラス",
    "termReading": "グラス",
    "termTranslation": "glass",
    "ex": "これはきれいなグラスですね。",
    "exReading": "これ は きれい な ぐらす です ね",
    "exTranslation": "This is a beautiful glass.",
    "category": "Noun"
  },
  {
    "term": "万歳",
    "termReading": "ばんざい",
    "termTranslation": "Banzai! Hurrah! Long live...!",
    "ex": "勝った、万歳!",
    "exReading": "かった ばんざい",
    "exTranslation": "We won--hurray!",
    "category": "Verbal Noun"
  },
  {
    "term": "二十歳",
    "termReading": "はたち",
    "termTranslation": "20 years old",
    "ex": "妹は来年、二十歳になります。",
    "exReading": "いもうと は らいねん はたち に なります",
    "exTranslation": "My little sister will be 20 next year.",
    "category": "Noun"
  },
  {
    "term": "姉さん",
    "termReading": "ねえさん",
    "termTranslation": "older sister, girl, miss",
    "ex": "姉さん、ごめんね。",
    "exReading": "ねえさん ごめん ね",
    "exTranslation": "I'm sorry sis.",
    "category": "Noun"
  },
  {
    "term": "兄さん",
    "termReading": "にいさん",
    "termTranslation": "older brother",
    "ex": "兄さん、おめでとう。",
    "exReading": "にいさん おめでとう",
    "exTranslation": "Congratulations, big brother.",
    "category": "Noun"
  },
  {
    "term": "ダンス",
    "termReading": "ダンス",
    "termTranslation": "dance, dancing",
    "ex": "彼女はダンスが大好きです。",
    "exReading": "かのじょ は だんす が だいすき です",
    "exTranslation": "She loves to dance.",
    "category": "Verbal Noun"
  },
  {
    "term": "兄弟",
    "termReading": "きょうだい",
    "termTranslation": "sibling",
    "ex": "彼は3人兄弟です。",
    "exReading": "かれ は さんにん きょうだい です",
    "exTranslation": "He has two siblings.",
    "category": "Noun"
  },
  {
    "term": "業者",
    "termReading": "ぎょうしゃ",
    "termTranslation": "businessman",
    "ex": "引っ越しを業者に頼んだ。",
    "exReading": "ひっこし を ぎょうしゃ に たのんだ",
    "exTranslation": "I asked a company to handle my move.",
    "category": "Noun"
  },
  {
    "term": "若者",
    "termReading": "わかもの",
    "termTranslation": "young person, youth",
    "ex": "最近の若者は本を読まない。",
    "exReading": "さいきん の わかもの は ほん を よまない",
    "exTranslation": "Young people these days don't read books.",
    "category": "Noun"
  },
  {
    "term": "彼ら",
    "termReading": "かれら",
    "termTranslation": "they",
    "ex": "彼らはバスケットの選手です。",
    "exReading": "かれら は ばすけっと の せんしゅ です",
    "exTranslation": "They're basketball players.",
    "category": "Noun"
  },
  {
    "term": "おじ",
    "termReading": "おじ",
    "termTranslation": "uncle",
    "ex": "おじは銀行に勤めています。",
    "exReading": "おじ は ぎんこう に つとめて います",
    "exTranslation": "My uncle works for a bank.",
    "category": "Noun"
  },
  {
    "term": "結合",
    "termReading": "けつごう",
    "termTranslation": "combination, joining",
    "ex": "ファイルを結合して1つにしました。",
    "exReading": "ふぁいる を けつごう して ひとつ に しました",
    "exTranslation": "I consolidated the files into just one.",
    "category": "Verbal Noun"
  },
  {
    "term": "結果",
    "termReading": "けっか",
    "termTranslation": "result, outcome",
    "ex": "試合の結果を早く知りたい。",
    "exReading": "しあい の けっか を はやく しりたい",
    "exTranslation": "I want to know soon the results of the game.",
    "category": "Noun"
  },
  {
    "term": "果物",
    "termReading": "くだもの",
    "termTranslation": "fruit",
    "ex": "デザートに果物を食べましょう。",
    "exReading": "でざーと に くだもの を たべましょう",
    "exTranslation": "Let's have fruit for dessert.",
    "category": "Noun"
  },
  {
    "term": "課題",
    "termReading": "かだい",
    "termTranslation": "task, problem",
    "ex": "夏休みの課題は何ですか。",
    "exReading": "なつやすみ の かだい は なん です か",
    "exTranslation": "What's our summer assignment?",
    "category": "Noun"
  },
  {
    "term": "サッカー",
    "termReading": "サッカー",
    "termTranslation": "soccer, football",
    "ex": "彼はサッカーの選手です。",
    "exReading": "かれ は さっかー の せんしゅ です",
    "exTranslation": "He's a soccer player.",
    "category": "Noun"
  },
  {
    "term": "課",
    "termReading": "か",
    "termTranslation": "section, division, lesson",
    "ex": "今から課のミーティングがある。",
    "exReading": "いま から か の みーてぃんぐ が ある",
    "exTranslation": "Our division is going to have a meeting now.",
    "category": "Noun"
  },
  {
    "term": "効果",
    "termReading": "こうか",
    "termTranslation": "effect, result",
    "ex": "この薬には胃を守る効果がある。",
    "exReading": "この くすり に は い を まもる こうか が ある",
    "exTranslation": "This medicine is effective in protecting your stomach.",
    "category": "Noun"
  },
  {
    "term": "自動車",
    "termReading": "じどうしゃ",
    "termTranslation": "automobile",
    "ex": "彼は自動車会社に就職した。",
    "exReading": "かれ は じどうしゃ がいしゃ に しゅうしょく した",
    "exTranslation": "He got a job at a car company.",
    "category": "Noun"
  },
  {
    "term": "自然",
    "termReading": "しぜん",
    "termTranslation": "nature",
    "ex": "みんなで自然を守りましょう。",
    "exReading": "みんな で しぜん を まもりましょう",
    "exTranslation": "Let's all work to protect Nature.",
    "category": "Noun"
  },
  {
    "term": "スープ",
    "termReading": "スープ",
    "termTranslation": "soup, broth",
    "ex": "母がコーンスープを作っている。",
    "exReading": "はは が こーんすーぷ を つくって いる",
    "exTranslation": "My mother made some corn chowder.",
    "category": "Noun"
  },
  {
    "term": "自然",
    "termReading": "しぜん",
    "termTranslation": "natural, naturally",
    "ex": "親が子供を守るのは自然なことだ。",
    "exReading": "おや が こども を まもる の は しぜん な こと だ",
    "exTranslation": "It's natural that parents protect their children.",
    "category": "Adjectival Noun"
  },
  {
    "term": "自習",
    "termReading": "じしゅう",
    "termTranslation": "self-study, self-teaching",
    "ex": "明日は自習の時間がある。",
    "exReading": "あす は じしゅう の じかん が ある",
    "exTranslation": "I have study time tomorrow.",
    "category": "Verbal Noun"
  },
  {
    "term": "理由",
    "termReading": "りゆう",
    "termTranslation": "reason, cause",
    "ex": "遅れた理由を教えてください。",
    "exReading": "おくれた りゆう を おしえて ください",
    "exTranslation": "Please tell me why you were late.",
    "category": "Noun"
  },
  {
    "term": "自由",
    "termReading": "じゆう",
    "termTranslation": "freedom",
    "ex": "今日は自由な時間が多い。",
    "exReading": "きょう は じゆう な じかん が おおい",
    "exTranslation": "I have a lot of free time today.",
    "category": "Adjectival Noun"
  },
  {
    "term": "バナナ",
    "termReading": "バナナ",
    "termTranslation": "banana",
    "ex": "私は毎朝バナナを食べます。",
    "exReading": "わたし は まいあさ ばなな を たべます",
    "exTranslation": "I eat a banana every morning.",
    "category": "Noun"
  },
  {
    "term": "信じる",
    "termReading": "しんじる",
    "termTranslation": "believe, trust, have faith in",
    "ex": "彼はキリストを信じている。",
    "exReading": "かれ は きりすと を しんじて いる",
    "exTranslation": "He believes in Christ.",
    "category": "Verb"
  },
  {
    "term": "信号",
    "termReading": "しんごう",
    "termTranslation": "signal, traffic light",
    "ex": "信号が青になった。",
    "exReading": "しんごう が あお に なった",
    "exTranslation": "The traffic light turned green.",
    "category": "Noun"
  },
  {
    "term": "頼む",
    "termReading": "たのむ",
    "termTranslation": "ask, request",
    "ex": "私はハンバーガーを頼みました。",
    "exReading": "わたし は はんばーがー を たのみました",
    "exTranslation": "I ordered a hamburger.",
    "category": "Verb"
  },
  {
    "term": "市民",
    "termReading": "しみん",
    "termTranslation": "citizens, townsmen",
    "ex": "市民の安全は大切だ。",
    "exReading": "しみん の あんぜん は たいせつ だ",
    "exTranslation": "The citizens' safety is important.",
    "category": "Noun"
  },
  {
    "term": "うるさい",
    "termReading": "うるさい",
    "termTranslation": "noisy, annoying, pesky",
    "ex": "この通りは車の音がうるさい。",
    "exReading": "この とおり は くるま の おと が うるさい",
    "exTranslation": "Traffic is noisy on this street.",
    "category": "Adjective"
  },
  {
    "term": "住民",
    "termReading": "じゅうみん",
    "termTranslation": "residents, dwellers",
    "ex": "地域の住民が集まって話合いをした。",
    "exReading": "ちいき の じゅうみん が あつまって はなしあい を した",
    "exTranslation": "The local residents got together and talked.",
    "category": "Noun"
  },
  {
    "term": "主人",
    "termReading": "しゅじん",
    "termTranslation": "master, husband",
    "ex": "主人は今、留守です。",
    "exReading": "しゅじん は いま るす です",
    "exTranslation": "My husband is out now.",
    "category": "Noun"
  },
  {
    "term": "議員",
    "termReading": "ぎいん",
    "termTranslation": "legislator",
    "ex": "彼は国会議員です。",
    "exReading": "かれ は こっかい ぎいん です",
    "exTranslation": "He's a congressman.",
    "category": "Noun"
  },
  {
    "term": "会議",
    "termReading": "かいぎ",
    "termTranslation": "conference, meeting",
    "ex": "今日の午後、大事な会議があります。",
    "exReading": "きょう の ごご だいじ な かいぎ が あります",
    "exTranslation": "There's an important meeting this afternoon.",
    "category": "Verbal Noun"
  },
  {
    "term": "パトカー",
    "termReading": "パトカー",
    "termTranslation": "police car, patrol car",
    "ex": "あそこにパトカーがいる。",
    "exReading": "あそこ に ぱとかー が いる",
    "exTranslation": "There's a police car over there.",
    "category": "Noun"
  },
  {
    "term": "対する",
    "termReading": "たいする",
    "termTranslation": "face, be in response to",
    "ex": "その質問に対する答えが見つからなかった。",
    "exReading": "その しつもん に たいする こたえ が みつからなかった",
    "exTranslation": "I couldn't find an answer to that question.",
    "category": "Verb"
  },
  {
    "term": "対立",
    "termReading": "たいりつ",
    "termTranslation": "opposition, antagonism",
    "ex": "その2社は対立しています。",
    "exReading": "その にしゃ は たいりつ して います",
    "exTranslation": "The two companies are against each other.",
    "category": "Verbal Noun"
  },
  {
    "term": "反対",
    "termReading": "はんたい",
    "termTranslation": "oppose, object",
    "ex": "私は反対です。",
    "exReading": "わたし は はんたい です",
    "exTranslation": "I'm against it.",
    "category": "Verbal Noun"
  },
  {
    "term": "答え",
    "termReading": "こたえ",
    "termTranslation": "answer, solution",
    "ex": "彼はその問題の答えが分からない。",
    "exReading": "かれ は その もんだい の こたえ が わからない",
    "exTranslation": "He doesn't know the answer to the problem.",
    "category": "Noun"
  },
  {
    "term": "ハンバーガー",
    "termReading": "ハンバーガー",
    "termTranslation": "hamburger",
    "ex": "今日の昼ご飯はハンバーガーでした。",
    "exReading": "きょう の ひるごはん は はんばーがー でした",
    "exTranslation": "I had a hamburger for lunch today.",
    "category": "Noun"
  },
  {
    "term": "特に",
    "termReading": "とくに",
    "termTranslation": "specially, particularly",
    "ex": "特に質問はありません。",
    "exReading": "とくに しつもん は ありません",
    "exTranslation": "I don't particularly have any questions.",
    "category": "Adverb"
  },
  {
    "term": "特急",
    "termReading": "とっきゅう",
    "termTranslation": "super-express",
    "ex": "東京まで特急で3時間かかります。",
    "exReading": "とうきょう まで とっきゅう で さんじかん かかります",
    "exTranslation": "It takes three hours to Tokyo by special express.",
    "category": "Noun"
  },
  {
    "term": "特別",
    "termReading": "とくべつ",
    "termTranslation": "special, extraordinary",
    "ex": "あなたは私にとって特別な人です。",
    "exReading": "あなた は わたし に とって とくべつ な ひと です",
    "exTranslation": "You're special to me.",
    "category": "Adjectival Noun"
  },
  {
    "term": "別々",
    "termReading": "べつべつ",
    "termTranslation": "separately",
    "ex": "別々に払いましょう。",
    "exReading": "べつべつ に はらいましょう",
    "exTranslation": "Let's get separate checks.",
    "category": "Adjectival Noun"
  },
  {
    "term": "エスカレーター",
    "termReading": "エスカレーター",
    "termTranslation": "escalator",
    "ex": "3階までエスカレーターで行きましょう。",
    "exReading": "さんかい まで えすかれーたー で いきましょう",
    "exTranslation": "Let's take the escalator up to the third floor.",
    "category": "Noun"
  },
  {
    "term": "別れる",
    "termReading": "わかれる",
    "termTranslation": "separate, part from",
    "ex": "駅で友だちと別れました。",
    "exReading": "えき で ともだち と わかれました",
    "exTranslation": "I parted with my friend at the station.",
    "category": "Verb"
  },
  {
    "term": "一般",
    "termReading": "いっぱん",
    "termTranslation": "general, common",
    "ex": "一般の方はこちらの席へどうぞ。",
    "exReading": "いっぱん の かた は こちら の せき へ どうぞ",
    "exTranslation": "General seating is over here.",
    "category": "Noun"
  },
  {
    "term": "目的",
    "termReading": "もくてき",
    "termTranslation": "object, purpose",
    "ex": "彼が来た目的が分かりません。",
    "exReading": "かれ が きた もくてき が わかりません",
    "exTranslation": "I don't know the purpose of his visit.",
    "category": "Noun"
  },
  {
    "term": "普通",
    "termReading": "ふつう",
    "termTranslation": "normal, regular",
    "ex": "彼女は普通の女の子だ。",
    "exReading": "かのじょ は ふつう の おんな の こ だ",
    "exTranslation": "She's just a normal girl.",
    "category": "Adjectival Noun"
  },
  {
    "term": "タオル",
    "termReading": "タオル",
    "termTranslation": "towel",
    "ex": "私はタオルで顔をふいた。",
    "exReading": "わたし は たおる で かお を ふいた",
    "exTranslation": "I wiped my face with a towel.",
    "category": "Noun"
  },
  {
    "term": "並ぶ",
    "termReading": "ならぶ",
    "termTranslation": "line up, be parallel",
    "ex": "ここに並んでください。",
    "exReading": "ここ に ならんで ください",
    "exTranslation": "Please line up here.",
    "category": "Verb"
  },
  {
    "term": "並べる",
    "termReading": "ならべる",
    "termTranslation": "line up, display, set up",
    "ex": "私は料理をテーブルに並べた。",
    "exReading": "わたし は りょうり を てーぶる に ならべた",
    "exTranslation": "I arranged the food on the table.",
    "category": "Verb"
  },
  {
    "term": "平和",
    "termReading": "へいわ",
    "termTranslation": "peace, harmony",
    "ex": "この国は平和です。",
    "exReading": "この くに は へいわ です",
    "exTranslation": "This country is peaceful.",
    "category": "Adjectival Noun"
  },
  {
    "term": "高等学校",
    "termReading": "こうとうがっこう",
    "termTranslation": "senior high school",
    "ex": "弟が高等学校を卒業しました。",
    "exReading": "おとうと が こうとうがっこう を そつぎょう しました",
    "exTranslation": "My little brother graduated from high school.",
    "category": "Noun"
  },
  {
    "term": "パチンコ",
    "termReading": "パチンコ",
    "termTranslation": "pachinko (vertical pinball)",
    "ex": "彼は毎日パチンコをしています。",
    "exReading": "かれ は まいにち ぱちんこ を して います",
    "exTranslation": "He plays pachinko every day.",
    "category": "Noun"
  },
  {
    "term": "病院",
    "termReading": "びょういん",
    "termTranslation": "hospital",
    "ex": "病院はどこですか。",
    "exReading": "びょういん は どこ です か",
    "exTranslation": "Where is the hospital?",
    "category": "Noun"
  },
  {
    "term": "入院",
    "termReading": "にゅういん",
    "termTranslation": "be hospitalized",
    "ex": "昨日、母が入院しました。",
    "exReading": "きのう はは が にゅういん しました",
    "exTranslation": "My mother was hospitalized yesterday.",
    "category": "Verbal Noun"
  },
  {
    "term": "大学院",
    "termReading": "だいがくいん",
    "termTranslation": "graduate school",
    "ex": "彼は大学院に進みました。",
    "exReading": "かれ は だいがくいん に すすみました",
    "exTranslation": "He's gone on to graduate school.",
    "category": "Noun"
  },
  {
    "term": "医者",
    "termReading": "いしゃ",
    "termTranslation": "doctor",
    "ex": "私は医者に相談した。",
    "exReading": "わたし は いしゃ に そうだん した",
    "exTranslation": "I consulted the doctor.",
    "category": "Noun"
  },
  {
    "term": "みかん",
    "termReading": "みかん",
    "termTranslation": "tangerine, mandarin orange",
    "ex": "みかんを1つください。",
    "exReading": "みかん を ひとつ ください",
    "exTranslation": "Please give me a tangerine.",
    "category": "Noun"
  },
  {
    "term": "お医者さん",
    "termReading": "おいしゃさん",
    "termTranslation": "doctor",
    "ex": "熱があるのでお医者さんに行った。",
    "exReading": "ねつ が ある の で おいしゃさん に いった",
    "exTranslation": "I went to the doctor because I had a fever.",
    "category": "Noun"
  },
  {
    "term": "歯医者",
    "termReading": "はいしゃ",
    "termTranslation": "dentist",
    "ex": "私は歯医者が嫌いです。",
    "exReading": "わたし は はいしゃ が きらい です",
    "exTranslation": "I hate the dentist.",
    "category": "Noun"
  },
  {
    "term": "歯",
    "termReading": "は",
    "termTranslation": "tooth, cog",
    "ex": "私の歯は丈夫です。",
    "exReading": "わたし の は は じょうぶ です",
    "exTranslation": "My teeth are strong.",
    "category": "Noun"
  },
  {
    "term": "歯ブラシ",
    "termReading": "はぶらし",
    "termTranslation": "toothbrush",
    "ex": "新しい歯ブラシが必要だ。",
    "exReading": "あたらしい はぶらし が ひつよう だ",
    "exTranslation": "I need a new toothbrush.",
    "category": "Noun"
  },
  {
    "term": "ケーキ",
    "termReading": "ケーキ",
    "termTranslation": "cake",
    "ex": "誕生日にケーキを食べました。",
    "exReading": "たんじょうび に けーき を たべました",
    "exTranslation": "We ate cake on his birthday.",
    "category": "Noun"
  },
  {
    "term": "科学",
    "termReading": "かがく",
    "termTranslation": "science",
    "ex": "科学は常に進歩している。",
    "exReading": "かがく は つねに しんぽ して いる",
    "exTranslation": "Science is always advancing.",
    "category": "Noun"
  },
  {
    "term": "教科書",
    "termReading": "きょうかしょ",
    "termTranslation": "textbook, schoolbook",
    "ex": "日本語の教科書を忘れた。",
    "exReading": "にほんご の きょうかしょ を わすれた",
    "exTranslation": "I forgot my Japanese textbook.",
    "category": "Noun"
  },
  {
    "term": "理科",
    "termReading": "りか",
    "termTranslation": "science",
    "ex": "私は理科が得意です。",
    "exReading": "わたし は りか が とくい です",
    "exTranslation": "I'm good at science.",
    "category": "Noun"
  },
  {
    "term": "亡くなる",
    "termReading": "なくなる",
    "termTranslation": "die, pass away",
    "ex": "おととい、昔の友人が亡くなった。",
    "exReading": "おととい むかし の ゆうじん が なくなった",
    "exTranslation": "An old friend died the day before yesterday.",
    "category": "Verb"
  },
  {
    "term": "コップ",
    "termReading": "コップ",
    "termTranslation": "cup, glass",
    "ex": "私は毎朝コップ一杯の水を飲む。",
    "exReading": "わたし は まいあさ こっぷ いっぱい の みず を のむ",
    "exTranslation": "I drink a glass of water every morning.",
    "category": "Noun"
  },
  {
    "term": "忙しい",
    "termReading": "いそがしい",
    "termTranslation": "busy, occupied",
    "ex": "忙しいので手伝ってください。",
    "exReading": "いそがしい の で てつだって ください",
    "exTranslation": "I have a lot to do, so please help me out.",
    "category": "Adjective"
  },
  {
    "term": "疲れる",
    "termReading": "つかれる",
    "termTranslation": "get tired, become fatigued",
    "ex": "私は疲れてふらふらです。",
    "exReading": "わたし は つかれて ふらふら です",
    "exTranslation": "I'm tired and feel dizzy.",
    "category": "Verb"
  },
  {
    "term": "禁煙",
    "termReading": "きんえん",
    "termTranslation": "no smoking, giving up smoking",
    "ex": "彼は今、禁煙しています。",
    "exReading": "かれ は いま きんえん して います",
    "exTranslation": "He's stopped smoking now.",
    "category": "Verbal Noun"
  },
  {
    "term": "酔っ払い",
    "termReading": "よっぱらい",
    "termTranslation": "drunkard, boozer",
    "ex": "彼はただの酔っ払いです。",
    "exReading": "かれ は ただ の よっぱらい です",
    "exTranslation": "He's nothing but a drunk.",
    "category": "Noun"
  },
  {
    "term": "ナイロン",
    "termReading": "ナイロン",
    "termTranslation": "nylon",
    "ex": "このジャケットはナイロンでできています。",
    "exReading": "この じゃけっと は ないろん で できて います",
    "exTranslation": "This jacket is made of nylon.",
    "category": "Noun"
  },
  {
    "term": "酔っ払う",
    "termReading": "よっぱらう",
    "termTranslation": "get drunk",
    "ex": "昨夜は酔っ払いました。",
    "exReading": "ゆうべ は よっぱらいました",
    "exTranslation": "I got drunk last night.",
    "category": "Verb"
  },
  {
    "term": "危ない",
    "termReading": "あぶない",
    "termTranslation": "dangerous, uncertain",
    "ex": "その道は車が多くて危ない。",
    "exReading": "その みち は くるま が おおくて あぶない",
    "exTranslation": "There are many cars on that road, so it's dangerous.",
    "category": "Adjective"
  },
  {
    "term": "危険",
    "termReading": "きけん",
    "termTranslation": "danger, dangerous",
    "ex": "その地域は今、危険だ。",
    "exReading": "その ちいき は いま きけん だ",
    "exTranslation": "That region is dangerous right now.",
    "category": "Adjectival Noun"
  },
  {
    "term": "存在",
    "termReading": "そんざい",
    "termTranslation": "existence, being",
    "ex": "宇宙人は存在すると思いますか。",
    "exReading": "うちゅうじん は そんざい する と おもいます か",
    "exTranslation": "Do you think that aliens exist?",
    "category": "Verbal Noun"
  },
  {
    "term": "スーツ",
    "termReading": "スーツ",
    "termTranslation": "suit",
    "ex": "あのスーツはそんなに高くない。",
    "exReading": "あの すーつ は そんなに たかく ない",
    "exTranslation": "That suit is reasonably priced.",
    "category": "Noun"
  },
  {
    "term": "注目",
    "termReading": "ちゅうもく",
    "termTranslation": "attention, notice",
    "ex": "私たちはその会社に注目している。",
    "exReading": "わたしたち は その かいしゃ に ちゅうもく して いる",
    "exTranslation": "We have our eyes on that company.",
    "category": "Verbal Noun"
  },
  {
    "term": "注文",
    "termReading": "ちゅうもん",
    "termTranslation": "order, request",
    "ex": "レストランでピザを注文しました。",
    "exReading": "れすとらん で ぴざ を ちゅうもん しました",
    "exTranslation": "I ordered pizza at the restaurant.",
    "category": "Verbal Noun"
  },
  {
    "term": "意味",
    "termReading": "いみ",
    "termTranslation": "meaning, significance",
    "ex": "それはどういう意味ですか。",
    "exReading": "それ は どういう いみ です か",
    "exTranslation": "What does that mean?",
    "category": "Noun"
  },
  {
    "term": "意見",
    "termReading": "いけん",
    "termTranslation": "opinion",
    "ex": "あなたの意見が聞きたいです。",
    "exReading": "あなた の いけん が ききたい です",
    "exTranslation": "I want to hear your opinion.",
    "category": "Noun"
  },
  {
    "term": "チケット",
    "termReading": "チケット",
    "termTranslation": "ticket",
    "ex": "この遊園地のチケットは3000円です。",
    "exReading": "この ゆうえんち の ちけっと は さんぜんえん です",
    "exTranslation": "The tickets for this amusement park are 3000 yen.",
    "category": "Noun"
  },
  {
    "term": "注意",
    "termReading": "ちゅうい",
    "termTranslation": "attention, care, watch out",
    "ex": "車に注意してください。",
    "exReading": "くるま に ちゅうい して ください",
    "exTranslation": "Please watch out for traffic.",
    "category": "Verbal Noun"
  },
  {
    "term": "用意",
    "termReading": "ようい",
    "termTranslation": "prepare, ready oneself",
    "ex": "食事の用意ができました。",
    "exReading": "しょくじ の ようい が できました",
    "exTranslation": "The meal is ready.",
    "category": "Verbal Noun"
  },
  {
    "term": "確か",
    "termReading": "たしか",
    "termTranslation": "for sure, for certain",
    "ex": "彼の昇進は確かだ。",
    "exReading": "かれ の しょうしん は たしか だ",
    "exTranslation": "He's sure to get a promotion.",
    "category": "Adjectival Noun"
  },
  {
    "term": "確認",
    "termReading": "かくにん",
    "termTranslation": "confirmation",
    "ex": "もう一度、予約を確認した。",
    "exReading": "もう いちど よやく を かくにん した",
    "exTranslation": "I confirmed my reservation one more time.",
    "category": "Verbal Noun"
  },
  {
    "term": "チョコレート",
    "termReading": "チョコレート",
    "termTranslation": "chocolate",
    "ex": "妹はチョコレートが大好きです。",
    "exReading": "いもうと は ちょこれーと が だいすき です",
    "exTranslation": "My little sister loves chocolate.",
    "category": "Noun"
  },
  {
    "term": "機能",
    "termReading": "きのう",
    "termTranslation": "function, feature",
    "ex": "このソフトにはいろいろな機能があります。",
    "exReading": "この そふと に は いろいろな きのう が あります",
    "exTranslation": "This software has a lot of various features.",
    "category": "Verbal Noun"
  },
  {
    "term": "ジェット機",
    "termReading": "ジェットき",
    "termTranslation": "jet airplane",
    "ex": "ジェット機が飛んでいる。",
    "exReading": "じぇっとき が とんで いる",
    "exTranslation": "The jet plane is flying.",
    "category": "Noun"
  },
  {
    "term": "機械",
    "termReading": "きかい",
    "termTranslation": "machine",
    "ex": "新しい機械が壊れた。",
    "exReading": "あたらしい きかい が こわれた",
    "exTranslation": "The new machine broke.",
    "category": "Noun"
  },
  {
    "term": "材料",
    "termReading": "ざいりょう",
    "termTranslation": "material, factor",
    "ex": "サラダの材料をそろえました。",
    "exReading": "さらだ の ざいりょう を そろえました",
    "exTranslation": "I have all the ingredients for the salad.",
    "category": "Noun"
  },
  {
    "term": "チャンネル",
    "termReading": "チャンネル",
    "termTranslation": "channel",
    "ex": "テレビのチャンネルを変えてください。",
    "exReading": "てれび の ちゃんねる を かえて ください",
    "exTranslation": "Please change the TV channel.",
    "category": "Noun"
  },
  {
    "term": "具体的",
    "termReading": "ぐたいてき",
    "termTranslation": "concrete, specific",
    "ex": "具体的な例をいくつか見せてください。",
    "exReading": "ぐたいてき な れい を いくつか みせて ください",
    "exTranslation": "Please show me some concrete examples.",
    "category": "Adjectival Noun"
  },
  {
    "term": "基づく",
    "termReading": "もとづく",
    "termTranslation": "be based on",
    "ex": "この話は真実に基づいています。",
    "exReading": "この はなし は しんじつ に もとづいて います",
    "exTranslation": "This story is based on truth.",
    "category": "Verb"
  },
  {
    "term": "基本",
    "termReading": "きほん",
    "termTranslation": "basis, foundation",
    "ex": "今、ジャズダンスの基本を習っています。",
    "exReading": "いま じゃず だんす の きほん を ならって います",
    "exTranslation": "We're learning the basics of jazz dance at the moment.",
    "category": "Noun"
  },
  {
    "term": "基準",
    "termReading": "きじゅん",
    "termTranslation": "standard, criterion",
    "ex": "判断の基準が示された。",
    "exReading": "はんだん の きじゅん が しめされた",
    "exTranslation": "The standards of judgment were outlined.",
    "category": "Noun"
  },
  {
    "term": "つまらない",
    "termReading": "つまらない",
    "termTranslation": "boring, dull",
    "ex": "彼の話はつまらないですね。",
    "exReading": "かれ の はなし は つまらない です ね",
    "exTranslation": "What he's talking about is boring.",
    "category": "Adjective"
  },
  {
    "term": "備える",
    "termReading": "そなえる",
    "termTranslation": "provide for, furnish",
    "ex": "災害に備えて大量の水を買い込んだ。",
    "exReading": "さいがい に そなえて たいりょう の みず を かいこんだ",
    "exTranslation": "I bought large quantities of water to prepare for disasters.",
    "category": "Verb"
  },
  {
    "term": "準備",
    "termReading": "じゅんび",
    "termTranslation": "provide for, prepare for",
    "ex": "明日の会議の準備をした。",
    "exReading": "あす の かいぎ の じゅんび を した",
    "exTranslation": "I prepared for tomorrow's meeting.",
    "category": "Verbal Noun"
  },
  {
    "term": "設計",
    "termReading": "せっけい",
    "termTranslation": "design, plan",
    "ex": "兄は船の設計をしています。",
    "exReading": "あに は ふね の せっけい を して います",
    "exTranslation": "My big brother is designing a ship.",
    "category": "Verbal Noun"
  },
  {
    "term": "施設",
    "termReading": "しせつ",
    "termTranslation": "facilities, institution",
    "ex": "そのホテルにはレジャー施設がたくさんある。",
    "exReading": "その ほてる に は れじゃー しせつ が たくさん ある",
    "exTranslation": "There are a lot of leisure facilities in the hotel.",
    "category": "Noun"
  },
  {
    "term": "マッチ",
    "termReading": "マッチ",
    "termTranslation": "match, matchbox",
    "ex": "マッチを持っていますか。",
    "exReading": "まっち を もって います か",
    "exTranslation": "Do you have a match?",
    "category": "Noun"
  },
  {
    "term": "設ける",
    "termReading": "もうける",
    "termTranslation": "set up, establish",
    "ex": "授業の終わりに復習の時間を設けた。",
    "exReading": "じゅぎょう の おわり に ふくしゅう の じかん を もうけた",
    "exTranslation": "I set up a review time at the end of class.",
    "category": "Verb"
  },
  {
    "term": "説明",
    "termReading": "せつめい",
    "termTranslation": "explanation, description",
    "ex": "この単語の意味を説明してください。",
    "exReading": "この たんご の いみ を せつめい して ください",
    "exTranslation": "Please tell me the meaning of this word.",
    "category": "Verbal Noun"
  },
  {
    "term": "小説",
    "termReading": "しょうせつ",
    "termTranslation": "novel, story",
    "ex": "私は月に3冊くらい小説を読みます。",
    "exReading": "わたし は つき に さんさつ くらい しょうせつ を よみます",
    "exTranslation": "I read about three novels a month.",
    "category": "Noun"
  },
  {
    "term": "公開",
    "termReading": "こうかい",
    "termTranslation": "opening (to the public)",
    "ex": "その映画は今日、公開されます。",
    "exReading": "その えいが は きょう こうかい されます",
    "exTranslation": "The movie opens to the public today.",
    "category": "Verbal Noun"
  },
  {
    "term": "いとこ",
    "termReading": "いとこ",
    "termTranslation": "cousin",
    "ex": "従兄弟と私は同じ年です。",
    "exReading": "いとこ と わたし は おなじ とし です",
    "exTranslation": "My cousin and I are the same age.",
    "category": "Noun"
  },
  {
    "term": "公園",
    "termReading": "こうえん",
    "termTranslation": "park, public garden",
    "ex": "子供たちが公園で遊んでいる。",
    "exReading": "こどもたち が こうえん で あそんで いる",
    "exTranslation": "The children are playing in the park.",
    "category": "Noun"
  },
  {
    "term": "動物園",
    "termReading": "どうぶつえん",
    "termTranslation": "zoo",
    "ex": "昨日子供たちと動物園に行きました。",
    "exReading": "きのう こどもたち と どうぶつえん に いきました",
    "exTranslation": "I went to the zoo with my kids yesterday.",
    "category": "Noun"
  },
  {
    "term": "祭り",
    "termReading": "まつり",
    "termTranslation": "festival",
    "ex": "彼女は祭りが大好きです。",
    "exReading": "かのじょ は まつり が だいすき です 。",
    "exTranslation": "She loves festivals.",
    "category": "Noun"
  },
  {
    "term": "国際",
    "termReading": "こくさい",
    "termTranslation": "international",
    "ex": "ここで国際会議が開かれます。",
    "exReading": "ここ で こくさい かいぎ が ひらかれます",
    "exTranslation": "An international conference is going to be held here.",
    "category": "Noun"
  },
  {
    "term": "ストーブ",
    "termReading": "ストーブ",
    "termTranslation": "paraffin heater, stove",
    "ex": "うちではまだストーブを使っています。",
    "exReading": "うち で は まだ すとーぶ を つかって います",
    "exTranslation": "We still use a heating stove at my house.",
    "category": "Noun"
  },
  {
    "term": "実際",
    "termReading": "じっさい",
    "termTranslation": "truth, actual state",
    "ex": "彼は実際にはあまり背が高くない。",
    "exReading": "かれ は じっさい に は あまり せ が たかく ない",
    "exTranslation": "Actually, he's not very tall.",
    "category": "Adverb"
  },
  {
    "term": "飛行場",
    "termReading": "ひこうじょう",
    "termTranslation": "airport, airfield",
    "ex": "バスが飛行場に着きました。",
    "exReading": "ばす が ひこうじょう に つきました",
    "exTranslation": "The bus arrived at the airport.",
    "category": "Noun"
  },
  {
    "term": "航空便",
    "termReading": "こうくうびん",
    "termTranslation": "airmail",
    "ex": "航空便で書類が届きました。",
    "exReading": "こうくうびん で しょるい が とどきました",
    "exTranslation": "The document reached me by airmail.",
    "category": "Noun"
  },
  {
    "term": "船便",
    "termReading": "ふなびん",
    "termTranslation": "surface mail, shipping service",
    "ex": "アメリカの友人から船便が届いた。",
    "exReading": "あめりか の ゆうじん から ふなびん が とどいた",
    "exTranslation": "Something arrived by surface mail from my friend in the United States.",
    "category": "Noun"
  },
  {
    "term": "ガソリンスタンド",
    "termReading": "ガソリンスタンド",
    "termTranslation": "gas station",
    "ex": "この近くにガソリンスタンドはありますか。",
    "exReading": "この ちかく に がそりんすたんど は あります か",
    "exTranslation": "Is there a gas station nearby?",
    "category": "Noun"
  },
  {
    "term": "船",
    "termReading": "ふね",
    "termTranslation": "ship, boat, vessel",
    "ex": "私たちは船に乗った。",
    "exReading": "わたしたち は ふね に のった",
    "exTranslation": "We got on the ship.",
    "category": "Noun"
  },
  {
    "term": "空港",
    "termReading": "くうこう",
    "termTranslation": "airport",
    "ex": "空港までリムジンバスで行った。",
    "exReading": "くうこう まで りむじん ばす で いった",
    "exTranslation": "I went to the airport by shuttle bus.",
    "category": "Noun"
  },
  {
    "term": "港",
    "termReading": "みなと",
    "termTranslation": "port, harbor",
    "ex": "港に船が着きました。",
    "exReading": "みなと に ふね が つきました",
    "exTranslation": "The ship arrived at the port.",
    "category": "Noun"
  },
  {
    "term": "島",
    "termReading": "しま",
    "termTranslation": "island, isle",
    "ex": "日本は島国です。",
    "exReading": "にっぽん は しまぐに です",
    "exTranslation": "Japan is an island nation.",
    "category": "Noun"
  },
  {
    "term": "デート",
    "termReading": "デート",
    "termTranslation": "date",
    "ex": "デートで遊園地に行きました。",
    "exReading": "でーと で ゆうえんち に いきました",
    "exTranslation": "I went on a date to an amusement park.",
    "category": "Verbal Noun"
  },
  {
    "term": "完成",
    "termReading": "かんせい",
    "termTranslation": "completion, finish",
    "ex": "新しいホームページが完成した。",
    "exReading": "あたらしい ほーむぺーじ が かんせい した",
    "exTranslation": "We finished our new home page.",
    "category": "Verbal Noun"
  },
  {
    "term": "平成",
    "termReading": "へいせい",
    "termTranslation": "Heisei era",
    "ex": "彼女は平成3年生まれです。",
    "exReading": "かのじょ は へいせい さんねん うまれ です",
    "exTranslation": "She was born in Heisei Year 3 (1991).",
    "category": "Noun"
  },
  {
    "term": "成功",
    "termReading": "せいこう",
    "termTranslation": "success",
    "ex": "ついに実験が成功した。",
    "exReading": "ついに じっけん が せいこう した",
    "exTranslation": "The experiment finally succeeded.",
    "category": "Verbal Noun"
  },
  {
    "term": "原因",
    "termReading": "げんいん",
    "termTranslation": "cause, origin",
    "ex": "この事故の原因は何ですか。",
    "exReading": "このじこ の げんいん は なん です か",
    "exTranslation": "What's the cause of the accident?",
    "category": "Noun"
  },
  {
    "term": "ふすま",
    "termReading": "ふすま",
    "termTranslation": "sliding door, fusuma",
    "ex": "ふすまを閉めてください。",
    "exReading": "ふすま を しめて ください",
    "exTranslation": "Please close the sliding door.",
    "category": "Noun"
  },
  {
    "term": "資金",
    "termReading": "しきん",
    "termTranslation": "funds, capital",
    "ex": "私たちは今、結婚資金を貯めています。",
    "exReading": "わたしたち は いま けっこん しきん を ためて います",
    "exTranslation": "We're saving for the wedding now.",
    "category": "Noun"
  },
  {
    "term": "投資",
    "termReading": "とうし",
    "termTranslation": "investment",
    "ex": "私は4つの会社に投資しています。",
    "exReading": "わたし は よっつ の かいしゃ に とうし して います",
    "exTranslation": "I'm investing in four companies.",
    "category": "Verbal Noun"
  },
  {
    "term": "願う",
    "termReading": "ねがう",
    "termTranslation": "wish, ask a favor, apply for",
    "ex": "彼が元気になるよう願っています。",
    "exReading": "かれ が げんき に なる よう ねがって います",
    "exTranslation": "We're praying that he gets well.",
    "category": "Verb"
  },
  {
    "term": "正確",
    "termReading": "せいかく",
    "termTranslation": "accurate, precise",
    "ex": "彼の計算は正確です。",
    "exReading": "かれ の けいさん は せいかく です",
    "exTranslation": "His calculation is accurate.",
    "category": "Adjectival Noun"
  },
  {
    "term": "レモン",
    "termReading": "レモン",
    "termTranslation": "lemon",
    "ex": "紅茶にレモンを入れて飲んだ。",
    "exReading": "こうちゃ に れもん を いれて のんだ",
    "exTranslation": "I put lemon in my tea and drank it.",
    "category": "Noun"
  },
  {
    "term": "正しい",
    "termReading": "ただしい",
    "termTranslation": "correct, righteous, lawful",
    "ex": "それは正しい答えです。",
    "exReading": "それ は ただしい こたえ です",
    "exTranslation": "That's the correct answer.",
    "category": "Adjective"
  },
  {
    "term": "正月",
    "termReading": "しょうがつ",
    "termTranslation": "New Year",
    "ex": "お正月にはたいてい、家族が集まる。",
    "exReading": "おしょうがつ に は たいてい かぞく が あつまる",
    "exTranslation": "Our family usually gets together for the new year.",
    "category": "Noun"
  },
  {
    "term": "正直",
    "termReading": "しょうじき",
    "termTranslation": "honest, upright",
    "ex": "彼女はとても正直だ。",
    "exReading": "かのじょ は とても しょうじき だ",
    "exTranslation": "She's very honest.",
    "category": "Adjectival Noun"
  },
  {
    "term": "異なる",
    "termReading": "ことなる",
    "termTranslation": "differ",
    "ex": "彼と私はいつも意見が異なる。",
    "exReading": "かれ と わたし は いつも いけん が ことなる",
    "exTranslation": "He and I always have different opinions.",
    "category": "Verb"
  },
  {
    "term": "チーズ",
    "termReading": "チーズ",
    "termTranslation": "cheese",
    "ex": "チーズを一切れ食べました。",
    "exReading": "ちーず を ひときれ たべました",
    "exTranslation": "I ate a slice of cheese.",
    "category": "Noun"
  },
  {
    "term": "通常",
    "termReading": "つうじょう",
    "termTranslation": "common, ordinary",
    "ex": "通常は夜8時まで営業しています。",
    "exReading": "つうじょう は よる はちじ まで えいぎょう して います",
    "exTranslation": "We're usually open until eight o'clock at night.",
    "category": "Noun"
  },
  {
    "term": "非常に",
    "termReading": "ひじょうに",
    "termTranslation": "very, extremely",
    "ex": "これは非常に重要です。",
    "exReading": "これ は ひじょう に じゅうよう です",
    "exTranslation": "This is very important.",
    "category": "Adverb"
  },
  {
    "term": "調べる",
    "termReading": "しらべる",
    "termTranslation": "investigate, check",
    "ex": "この単語の意味を辞書で調べましょう。",
    "exReading": "この たんご の いみ を じしょ で しらべましょう",
    "exTranslation": "Let's look up the meaning of this word in the dictionary.",
    "category": "Verb"
  },
  {
    "term": "強調",
    "termReading": "きょうちょう",
    "termTranslation": "emphasis, stress",
    "ex": "彼は良いところだけを強調した。",
    "exReading": "かれ は よい ところ だけ を きょうちょう した",
    "exTranslation": "He only emphasized the strong points.",
    "category": "Verbal Noun"
  },
  {
    "term": "ドライブ",
    "termReading": "ドライブ",
    "termTranslation": "drive",
    "ex": "今日は群馬までドライブしました。",
    "exReading": "きょう は ぐんま まで どらいぶ しました",
    "exTranslation": "I drove to Gunma today.",
    "category": "Verbal Noun"
  },
  {
    "term": "季節",
    "termReading": "きせつ",
    "termTranslation": "season",
    "ex": "私の一番好きな季節は春です。",
    "exReading": "わたし の いちばん すき な きせつ は はる です",
    "exTranslation": "My favorite season is spring.",
    "category": "Noun"
  },
  {
    "term": "調査",
    "termReading": "ちょうさ",
    "termTranslation": "investigation, inquiry",
    "ex": "私たちがその問題を調査しています。",
    "exReading": "わたしたち が その もんだい を ちょうさ して います",
    "exTranslation": "We're investigating that problem.",
    "category": "Verbal Noun"
  },
  {
    "term": "提供",
    "termReading": "ていきょう",
    "termTranslation": "offer, provide",
    "ex": "彼がパーティー会場を提供してくれました。",
    "exReading": "かれ が ぱーてぃー かいじょう を ていきょう して くれました",
    "exTranslation": "He provided us with the venue for the party.",
    "category": "Verbal Noun"
  },
  {
    "term": "提案",
    "termReading": "ていあん",
    "termTranslation": "proposition, proposal",
    "ex": "そのアイデアは彼の提案です。",
    "exReading": "その あいであ は かれ の ていあん です",
    "exTranslation": "He's the one who proposed that idea.",
    "category": "Verbal Noun"
  },
  {
    "term": "ラケット",
    "termReading": "ラケット",
    "termTranslation": "racket",
    "ex": "テニスのラケットを買いました。",
    "exReading": "てにす の らけっと を かいました",
    "exTranslation": "I bought a tennis racket.",
    "category": "Noun"
  },
  {
    "term": "案内",
    "termReading": "あんない",
    "termTranslation": "guide, inform",
    "ex": "私が中をご案内します。",
    "exReading": "わたし が なか を ごあんない します",
    "exTranslation": "I'll show you around inside.",
    "category": "Verbal Noun"
  },
  {
    "term": "示す",
    "termReading": "しめす",
    "termTranslation": "show, indicate",
    "ex": "彼は新製品に興味を示している。",
    "exReading": "かれ は しんせいひん に きょうみ を しめして いる",
    "exTranslation": "He's showing interest in the new product.",
    "category": "Verb"
  },
  {
    "term": "連れて行く",
    "termReading": "つれていく",
    "termTranslation": "take (a person) along",
    "ex": "私も連れて行ってください。",
    "exReading": "わたし も つれて いって ください",
    "exTranslation": "Please take me along.",
    "category": "Verb"
  },
  {
    "term": "連れて来る",
    "termReading": "つれてくる",
    "termTranslation": "bring (a person)",
    "ex": "息子が友達を連れて来ました。",
    "exReading": "むすこ が ともだち を つれて きました",
    "exTranslation": "My son brought a friend.",
    "category": "Verb"
  },
  {
    "term": "スチュワーデス",
    "termReading": "スチュワーデス",
    "termTranslation": "flight attendant",
    "ex": "彼女はスチュワーデスになった。",
    "exReading": "かのじょ は すちゅわーです に なった",
    "exTranslation": "She's become a flight attendant.",
    "category": "Noun"
  },
  {
    "term": "続ける",
    "termReading": "つづける",
    "termTranslation": "continue, keep up",
    "ex": "仕事を続けてください。",
    "exReading": "しごと を つづけて ください",
    "exTranslation": "Please continue working.",
    "category": "Verb"
  },
  {
    "term": "相手",
    "termReading": "あいて",
    "termTranslation": "opponent",
    "ex": "試合の相手は誰ですか。",
    "exReading": "しあい の あいて は だれ です か",
    "exTranslation": "Who are they playing against?",
    "category": "Noun"
  },
  {
    "term": "会談",
    "termReading": "かいだん",
    "termTranslation": "conversation, meeting",
    "ex": "会談の内容が発表されました。",
    "exReading": "かいだん の ないよう が はっぴょう されました",
    "exTranslation": "The content of the talks was announced.",
    "category": "Verbal Noun"
  },
  {
    "term": "相談",
    "termReading": "そうだん",
    "termTranslation": "consultation, advice",
    "ex": "相談したいことがあります。",
    "exReading": "そうだん したい こと が あります",
    "exTranslation": "I'd like to get your advice on something.",
    "category": "Verbal Noun"
  },
  {
    "term": "テープレコーダー",
    "termReading": "テープレコーダー",
    "termTranslation": "tape recorder, cassette recorder",
    "ex": "テープレコーダーで自分の声を録音しました。",
    "exReading": "てーぷれこーだー で じぶん の こえ を ろくおん しました",
    "exTranslation": "I recorded my voice on the tape recorder.",
    "category": "Noun"
  },
  {
    "term": "記者",
    "termReading": "きしゃ",
    "termTranslation": "journalist, reporter",
    "ex": "彼は新聞記者です。",
    "exReading": "かれ は しんぶん きしゃ です",
    "exTranslation": "He's a newspaper journalist.",
    "category": "Noun"
  },
  {
    "term": "記録",
    "termReading": "きろく",
    "termTranslation": "record, documentation",
    "ex": "マラソンで世界記録が出た。",
    "exReading": "まらそん で せかい きろく が でた",
    "exTranslation": "There was a new world record in the marathon.",
    "category": "Verbal Noun"
  },
  {
    "term": "録音",
    "termReading": "ろくおん",
    "termTranslation": "sound recording",
    "ex": "そのラジオ番組はもう録音しました。",
    "exReading": "その らじお ばんぐみ は もう ろくおん しました",
    "exTranslation": "I've already recorded that radio show.",
    "category": "Verbal Noun"
  },
  {
    "term": "登る",
    "termReading": "のぼる",
    "termTranslation": "climb, mount, go up",
    "ex": "私たちは昨年、富士山に登りました。",
    "exReading": "わたしたち は さくねん ふじさん に のぼりました",
    "exTranslation": "We climbed Mount Fuji last year.",
    "category": "Verb"
  },
  {
    "term": "ひげ",
    "termReading": "ひげ",
    "termTranslation": "moustache, beard",
    "ex": "父はひげをはやしています。",
    "exReading": "ちち は ひげ を はやして います",
    "exTranslation": "My father's growing a beard.",
    "category": "Noun"
  },
  {
    "term": "関する",
    "termReading": "かんする",
    "termTranslation": "concern, relate to",
    "ex": "その問題に関する記事を読みました。",
    "exReading": "その もんだい に かんする きじ を よみました",
    "exTranslation": "I read an article about the problem.",
    "category": "Verb"
  },
  {
    "term": "関連",
    "termReading": "かんれん",
    "termTranslation": "connection, relation",
    "ex": "関連のセクションへ連絡した。",
    "exReading": "かんれん の せくしょん へ れんらく した",
    "exTranslation": "I contacted the related sections.",
    "category": "Verbal Noun"
  },
  {
    "term": "関係",
    "termReading": "かんけい",
    "termTranslation": "relation, relationship",
    "ex": "彼はその事件に関係がない。",
    "exReading": "かれ は その じけん に かんけい が ない",
    "exTranslation": "He has nothing to do with that incident.",
    "category": "Verbal Noun"
  },
  {
    "term": "状況",
    "termReading": "じょうきょう",
    "termTranslation": "state of affairs, conditions",
    "ex": "この状況では出発は難しいです。",
    "exReading": "この じょうきょう で は しゅっぱつ は むずかしい です",
    "exTranslation": "It will be difficult to depart in these conditions.",
    "category": "Noun"
  },
  {
    "term": "ぐっすり",
    "termReading": "ぐっすり",
    "termTranslation": "soundly",
    "ex": "赤ちゃんがぐっすり寝ている。",
    "exReading": "あかちゃん が ぐっすり ねて いる",
    "exTranslation": "The baby's sleeping soundly.",
    "category": "Adverb"
  },
  {
    "term": "状態",
    "termReading": "じょうたい",
    "termTranslation": "state, appearance",
    "ex": "ここは道の状態がとても悪いです。",
    "exReading": "ここ は みち の じょうたい が とても わるい です",
    "exTranslation": "The road is in a very bad state here.",
    "category": "Noun"
  },
  {
    "term": "治る",
    "termReading": "なおる",
    "termTranslation": "be cured, get well",
    "ex": "けがはもう治りましたか。",
    "exReading": "けが は もう なおりました か",
    "exTranslation": "Is your injury better yet?",
    "category": "Verb"
  },
  {
    "term": "政治",
    "termReading": "せいじ",
    "termTranslation": "politics",
    "ex": "私は政治に関心がある。",
    "exReading": "わたし は せいじ に かんしん が ある",
    "exTranslation": "I'm interested in politics.",
    "category": "Noun"
  },
  {
    "term": "治す",
    "termReading": "なおす",
    "termTranslation": "cure, heal",
    "ex": "早く風邪を治してください。",
    "exReading": "はやく かぜ を なおして ください",
    "exTranslation": "I hope your cold gets better soon.",
    "category": "Verb"
  },
  {
    "term": "ソース",
    "termReading": "ソース",
    "termTranslation": "sauce, Worcestershire sauce",
    "ex": "ソースはどれですか。",
    "exReading": "そーす は どれ です か",
    "exTranslation": "Which of these is Worcestershire sauce?",
    "category": "Noun"
  },
  {
    "term": "政府",
    "termReading": "せいふ",
    "termTranslation": "government, administration",
    "ex": "そのデモについて政府は何もしなかった。",
    "exReading": "その でも に ついて せいふ は なにも しなかった",
    "exTranslation": "The government didn't do anything about the demonstration.",
    "category": "Noun"
  },
  {
    "term": "党",
    "termReading": "とう",
    "termTranslation": "political party",
    "ex": "党の代表が質問に答えました。",
    "exReading": "とう の だいひょう が しつもん に こたえました",
    "exTranslation": "The party spokesman answered the question.",
    "category": "Noun"
  },
  {
    "term": "対策",
    "termReading": "たいさく",
    "termTranslation": "measure, action",
    "ex": "一緒に対策を考えましょう。",
    "exReading": "いっしょ に たいさく を かんがえましょう",
    "exTranslation": "Let's think together about what measures we'll take.",
    "category": "Noun"
  },
  {
    "term": "政策",
    "termReading": "せいさく",
    "termTranslation": "policy, political measures",
    "ex": "新しい政策はあまり良いとは思えません。",
    "exReading": "あたらしい せいさく は あまり いい と は おもえません",
    "exTranslation": "I don't think the new policy is very good.",
    "category": "Noun"
  },
  {
    "term": "タイプライター",
    "termReading": "タイプライター",
    "termTranslation": "typewriter",
    "ex": "母は古いタイプライターを持っています。",
    "exReading": "はは は ふるい たいぷらいたー を もって います",
    "exTranslation": "My mother has an old typewriter.",
    "category": "Noun"
  },
  {
    "term": "選ぶ",
    "termReading": "えらぶ",
    "termTranslation": "choose, elect",
    "ex": "良い家を選ぶのは難しい。",
    "exReading": "よい いえ を えらぶ の は むずかしい",
    "exTranslation": "It's difficult to choose a good house.",
    "category": "Verb"
  },
  {
    "term": "選手",
    "termReading": "せんしゅ",
    "termTranslation": "representative athlete",
    "ex": "彼はプロのサッカー選手だ。",
    "exReading": "かれ は ぷろ の さっかー せんしゅ だ",
    "exTranslation": "He's a professional soccer player.",
    "category": "Noun"
  },
  {
    "term": "選挙",
    "termReading": "せんきょ",
    "termTranslation": "election",
    "ex": "彼は選挙に出るつもりだ。",
    "exReading": "かれ は せんきょ に でる つもり だ",
    "exTranslation": "He intends to run in the election.",
    "category": "Verbal Noun"
  },
  {
    "term": "候補",
    "termReading": "こうほ",
    "termTranslation": "candidacy, candidate",
    "ex": "会長の候補は3人います。",
    "exReading": "かいちょう の こうほ は さんにん います",
    "exTranslation": "There are three candidates for chairman.",
    "category": "Noun"
  },
  {
    "term": "トマト",
    "termReading": "トマト",
    "termTranslation": "tomato",
    "ex": "私はトマトが大好きです。",
    "exReading": "わたし は とまと が だいすき です",
    "exTranslation": "I love tomatoes.",
    "category": "Noun"
  },
  {
    "term": "首相",
    "termReading": "しゅしょう",
    "termTranslation": "prime minister",
    "ex": "今の首相はあまり力がない。",
    "exReading": "いま の しゅしょう は あまり ちから が ない",
    "exTranslation": "The current Prime Minister doesn't have much power.",
    "category": "Noun"
  },
  {
    "term": "首都",
    "termReading": "しゅと",
    "termTranslation": "capital",
    "ex": "東京は日本の首都です。",
    "exReading": "とうきょう は にっぽん の しゅと です",
    "exTranslation": "Tokyo is the capital of Japan.",
    "category": "Noun"
  },
  {
    "term": "改革",
    "termReading": "かいかく",
    "termTranslation": "reform",
    "ex": "彼は行政を改革したいと思っている。",
    "exReading": "かれ は ぎょうせい を かいかく したい と おもって いる",
    "exTranslation": "He wants to reform the administration.",
    "category": "Verbal Noun"
  },
  {
    "term": "革",
    "termReading": "かわ",
    "termTranslation": "leather",
    "ex": "革のベルトを買いました。",
    "exReading": "かわ の べると を かいました",
    "exTranslation": "I bought a leather belt.",
    "category": "Noun"
  },
  {
    "term": "バター",
    "termReading": "バター",
    "termTranslation": "butter",
    "ex": "パンにバターをぬって食べました。",
    "exReading": "ぱん に ばたー を ぬって たべました",
    "exTranslation": "I spread some butter on the bread and ate it.",
    "category": "Noun"
  },
  {
    "term": "命令",
    "termReading": "めいれい",
    "termTranslation": "command, order",
    "ex": "彼女は命令に従わなかった。",
    "exReading": "かのじょ は めいれい に したがわなかった",
    "exTranslation": "She did not obey orders.",
    "category": "Verbal Noun"
  },
  {
    "term": "番組",
    "termReading": "ばんぐみ",
    "termTranslation": "program",
    "ex": "私はこの番組が好きです。",
    "exReading": "わたし は この ばんぐみ が すき です",
    "exTranslation": "I like this show.",
    "category": "Noun"
  },
  {
    "term": "組み立てる",
    "termReading": "くみたてる",
    "termTranslation": "build, set up, construct",
    "ex": "日曜日に本棚を組み立てます。",
    "exReading": "にちようび に ほんだな を くみたてます",
    "exTranslation": "I'll set up the bookshelf on Sunday.",
    "category": "Verb"
  },
  {
    "term": "組織",
    "termReading": "そしき",
    "termTranslation": "organization, constitution, tissue",
    "ex": "彼はある組織のリーダーだ。",
    "exReading": "かれ は ある そしき の りーだー だ",
    "exTranslation": "He's the leader of a certain organization.",
    "category": "Verbal Noun"
  },
  {
    "term": "バレーボール",
    "termReading": "バレーボール",
    "termTranslation": "volleyball",
    "ex": "妹はバレーボールが得意です。",
    "exReading": "いもうと は ばれーぼーる が とくい です",
    "exTranslation": "My little sister is good at volleyball.",
    "category": "Noun"
  },
  {
    "term": "進める",
    "termReading": "すすめる",
    "termTranslation": "advance, promote",
    "ex": "早く授業を進めましょう。",
    "exReading": "はやく じゅぎょう を すすめましょう",
    "exTranslation": "Let's hurry up and proceed with our class.",
    "category": "Verb"
  },
  {
    "term": "進む",
    "termReading": "すすむ",
    "termTranslation": "advance, move forward",
    "ex": "前に進んでください。",
    "exReading": "まえ に すすんで ください",
    "exTranslation": "Please move forward.",
    "category": "Verb"
  },
  {
    "term": "進学",
    "termReading": "しんがく",
    "termTranslation": "entering a school of higher grade",
    "ex": "彼女は来年、大学に進学する。",
    "exReading": "かのじょ は らいねん だいがく に しんがく する",
    "exTranslation": "She'll enter college next year.",
    "category": "Verbal Noun"
  },
  {
    "term": "拡大",
    "termReading": "かくだい",
    "termTranslation": "magnification, enlargement",
    "ex": "この図を拡大コピーしてください。",
    "exReading": "この ず を かくだい こぴー して ください",
    "exTranslation": "Please make an enlargement of this chart.",
    "category": "Verbal Noun"
  },
  {
    "term": "おかず",
    "termReading": "おかず",
    "termTranslation": "side dish",
    "ex": "晩ご飯のおかずは何?",
    "exReading": "ばんごはん の おかず は なに",
    "exTranslation": "What are we having for dinner?",
    "category": "Noun"
  },
  {
    "term": "責任",
    "termReading": "せきにん",
    "termTranslation": "responsibility, liability",
    "ex": "彼は失敗の責任を取って、会社を辞めた。",
    "exReading": "かれ は しっぱい の せきにん を とって かいしゃ を やめた",
    "exTranslation": "He took responsibility for the mistake and resigned from the company.",
    "category": "Noun"
  },
  {
    "term": "辞める",
    "termReading": "やめる",
    "termTranslation": "resign, retire",
    "ex": "彼は会社を辞めます。",
    "exReading": "かれ は かいしゃ を やめます",
    "exTranslation": "He's leaving the company.",
    "category": "Verb"
  },
  {
    "term": "辞書",
    "termReading": "じしょ",
    "termTranslation": "dictionary",
    "ex": "彼女は辞書をよく使います。",
    "exReading": "かのじょ は じしょ を よく つかいます",
    "exTranslation": "She often uses the dictionary.",
    "category": "Noun"
  },
  {
    "term": "通勤",
    "termReading": "つうきん",
    "termTranslation": "commuting, commutation",
    "ex": "毎朝、通勤に30分かかります。",
    "exReading": "まいあさ つうきん に さんじゅっぷん かかります",
    "exTranslation": "It takes thirty minutes to commute every morning.",
    "category": "Verbal Noun"
  },
  {
    "term": "カレンダー",
    "termReading": "カレンダー",
    "termTranslation": "calendar",
    "ex": "カレンダーに予定を書いた。",
    "exReading": "かれんだー に よてい を かいた",
    "exTranslation": "I wrote my schedule on the calendar.",
    "category": "Noun"
  },
  {
    "term": "勤める",
    "termReading": "つとめる",
    "termTranslation": "serve, hold a job",
    "ex": "私は銀行に勤めています。",
    "exReading": "わたし は ぎんこう に つとめて います",
    "exTranslation": "I'm working for a bank.",
    "category": "Verb"
  },
  {
    "term": "事務所",
    "termReading": "じむしょ",
    "termTranslation": "office, one's place of business",
    "ex": "後で事務所に来てください。",
    "exReading": "あとで じむしょ に きて ください",
    "exTranslation": "Please come to the office later.",
    "category": "Noun"
  },
  {
    "term": "事務室",
    "termReading": "じむしつ",
    "termTranslation": "office",
    "ex": "事務室でコピーを取って来ます。",
    "exReading": "じむしつ で こぴー を とって きます",
    "exTranslation": "I'm going to make copies in the office.",
    "category": "Noun"
  },
  {
    "term": "従来",
    "termReading": "じゅうらい",
    "termTranslation": "former, old",
    "ex": "このプリンターは従来のものより速い。",
    "exReading": "この ぷりんたー は じゅうらい の もの より はやい",
    "exTranslation": "This printer prints faster than previous models.",
    "category": "Noun"
  },
  {
    "term": "ハンドバッグ",
    "termReading": "ハンドバッグ",
    "termTranslation": "handbag",
    "ex": "ハンドバッグを忘れました。",
    "exReading": "はんどばっぐ を わすれました",
    "exTranslation": "I forgot my handbag.",
    "category": "Noun"
  },
  {
    "term": "成績",
    "termReading": "せいせき",
    "termTranslation": "results, record",
    "ex": "成績が上がりました。",
    "exReading": "せいせき が あがりました",
    "exTranslation": "His grades went up.",
    "category": "Noun"
  },
  {
    "term": "集める",
    "termReading": "あつめる",
    "termTranslation": "collect, gather",
    "ex": "弟は切手を集めています。",
    "exReading": "おとうと は きって を あつめて います",
    "exTranslation": "My little brother collects stamps.",
    "category": "Verb"
  },
  {
    "term": "集まる",
    "termReading": "あつまる",
    "termTranslation": "gather, be collected",
    "ex": "駅前に人が集まっています。",
    "exReading": "えきまえ に ひと が あつまって います",
    "exTranslation": "Lots of people are gathered in front of the station.",
    "category": "Verb"
  },
  {
    "term": "採用",
    "termReading": "さいよう",
    "termTranslation": "employment",
    "ex": "その会社は女性を多く採用している。",
    "exReading": "その かいしゃ は じょせい を おおく さいよう して いる",
    "exTranslation": "This company employs many women.",
    "category": "Verbal Noun"
  },
  {
    "term": "ベル",
    "termReading": "ベル",
    "termTranslation": "bell",
    "ex": "玄関のベルが鳴った。",
    "exReading": "げんかん の べる が なった",
    "exTranslation": "The door bell rang.",
    "category": "Noun"
  },
  {
    "term": "給料",
    "termReading": "きゅうりょう",
    "termTranslation": "salary, pay",
    "ex": "来年から給料が上がります。",
    "exReading": "らいねん から きゅうりょう が あがります",
    "exTranslation": "I'm getting a pay raise as of next year.",
    "category": "Noun"
  },
  {
    "term": "卒業",
    "termReading": "そつぎょう",
    "termTranslation": "graduation",
    "ex": "私は去年、大学を卒業した。",
    "exReading": "わたし は きょねん だいがく を そつぎょう した",
    "exTranslation": "I graduated from university last year.",
    "category": "Verbal Noun"
  },
  {
    "term": "就職",
    "termReading": "しゅうしょく",
    "termTranslation": "finding employment",
    "ex": "最近、若い人たちの就職が難しくなっています。",
    "exReading": "さいきん わかい ひとたち の しゅうしょく が むずかしく なって います",
    "exTranslation": "It's been hard for young people to find jobs recently.",
    "category": "Verbal Noun"
  },
  {
    "term": "退院",
    "termReading": "たいいん",
    "termTranslation": "discharge from a hospital",
    "ex": "母が今日退院します。",
    "exReading": "はは が きょう たいいん します",
    "exTranslation": "My mother is being discharged from the hospital today.",
    "category": "Verbal Noun"
  },
  {
    "term": "インク",
    "termReading": "インク",
    "termTranslation": "ink",
    "ex": "プリンターのインクを買った。",
    "exReading": "ぷりんたー の いんく を かった",
    "exTranslation": "I bought some printer ink.",
    "category": "Noun"
  },
  {
    "term": "契約",
    "termReading": "けいやく",
    "termTranslation": "contract, agreement",
    "ex": "その選手は新しいチームと契約した。",
    "exReading": "その せんしゅ は あたらしい ちーむ と けいやく した",
    "exTranslation": "That player signed a contract with a new team.",
    "category": "Verbal Noun"
  },
  {
    "term": "交渉",
    "termReading": "こうしょう",
    "termTranslation": "negotiation, bargaining",
    "ex": "今、値段を交渉しています。",
    "exReading": "いま ねだん を こうしょう して います",
    "exTranslation": "We're currently negotiating the price.",
    "category": "Verbal Noun"
  },
  {
    "term": "事件",
    "termReading": "じけん",
    "termTranslation": "affair, case",
    "ex": "その事件の犯人はまだ捕まっていない。",
    "exReading": "その じけん の はんにん は まだ つかまって いない",
    "exTranslation": "The perpetrator of the incident hasn't been caught yet.",
    "category": "Noun"
  },
  {
    "term": "条件",
    "termReading": "じょうけん",
    "termTranslation": "condition, item",
    "ex": "この条件では厳し過ぎます。",
    "exReading": "この じょうけん で は きびし すぎます",
    "exTranslation": "It's too harsh under these conditions.",
    "category": "Noun"
  },
  {
    "term": "クーラー",
    "termReading": "クーラー",
    "termTranslation": "air conditioning, cooler",
    "ex": "暑いのでクーラーをつけました。",
    "exReading": "あつい の で くーらー を つけました",
    "exTranslation": "I turned on the air conditioning because it was hot.",
    "category": "Noun"
  },
  {
    "term": "参加",
    "termReading": "さんか",
    "termTranslation": "participation",
    "ex": "明日は市民マラソンに参加します。",
    "exReading": "あす は しみん まらそん に さんか します",
    "exTranslation": "I'll participate in the citizens' marathon tomorrow.",
    "category": "Verbal Noun"
  },
  {
    "term": "増加",
    "termReading": "ぞうか",
    "termTranslation": "increase, gain",
    "ex": "島の人口は年々増加しています。",
    "exReading": "しま の じんこう は ねんねん ぞうか して います",
    "exTranslation": "The population of the island increases every year.",
    "category": "Verbal Noun"
  },
  {
    "term": "加える",
    "termReading": "くわえる",
    "termTranslation": "add, include",
    "ex": "塩、コショウを加えてください。",
    "exReading": "しお こしょう を くわえて ください",
    "exTranslation": "Please add the salt and pepper.",
    "category": "Verb"
  },
  {
    "term": "加工",
    "termReading": "かこう",
    "termTranslation": "processing, manufacturing",
    "ex": "この工場では魚を加工している。",
    "exReading": "この こうじょう で は さかな を かこう して いる",
    "exTranslation": "This factory processes fish.",
    "category": "Verbal Noun"
  },
  {
    "term": "ミルク",
    "termReading": "ミルク",
    "termTranslation": "milk, baby milk",
    "ex": "赤ちゃんにミルクをあげた。",
    "exReading": "あかちゃん に みるく を あげた",
    "exTranslation": "I fed the baby some milk.",
    "category": "Noun"
  },
  {
    "term": "比べる",
    "termReading": "くらべる",
    "termTranslation": "compare, contrast",
    "ex": "今月と先月の売上を比べた。",
    "exReading": "こんげつ と せんげつ の うりあげ を くらべた",
    "exTranslation": "I compared this month's sales with last month's.",
    "category": "Verb"
  },
  {
    "term": "批判",
    "termReading": "ひはん",
    "termTranslation": "criticism, comment",
    "ex": "彼は同僚を批判した。",
    "exReading": "かれ は どうりょう を ひはん した",
    "exTranslation": "He criticized his colleague.",
    "category": "Verbal Noun"
  },
  {
    "term": "評価",
    "termReading": "ひょうか",
    "termTranslation": "evaluation, appraisal",
    "ex": "最近、彼の評価が上がった。",
    "exReading": "さいきん かれ の ひょうか が あがった",
    "exTranslation": "He's been receiving more recognition lately.",
    "category": "Verbal Noun"
  },
  {
    "term": "対象",
    "termReading": "たいしょう",
    "termTranslation": "target, object",
    "ex": "このアンケートは大学生が対象です。",
    "exReading": "この あんけーと は だいがくせい が たいしょう です",
    "exTranslation": "This questionnaire targets university students.",
    "category": "Noun"
  },
  {
    "term": "サラダ",
    "termReading": "サラダ",
    "termTranslation": "salad",
    "ex": "サラダをたくさん食べました。",
    "exReading": "さらだ を たくさん たべました",
    "exTranslation": "I ate a lot of salad.",
    "category": "Noun"
  },
  {
    "term": "故障",
    "termReading": "こしょう",
    "termTranslation": "malfunction, fault, breakdown",
    "ex": "冷蔵庫が故障しました。",
    "exReading": "れいぞうこ が こしょう しました",
    "exTranslation": "The refrigerator broke down.",
    "category": "Verbal Noun"
  },
  {
    "term": "修理",
    "termReading": "しゅうり",
    "termTranslation": "repair, mending",
    "ex": "車を修理に出した。",
    "exReading": "くるま を しゅうり に だした",
    "exTranslation": "I took the car in for repair.",
    "category": "Verbal Noun"
  },
  {
    "term": "乗り換える",
    "termReading": "のりかえる",
    "termTranslation": "change, transfer",
    "ex": "次の駅で地下鉄に乗り換えます。",
    "exReading": "つぎ の えき で ちかてつ に のりかえます",
    "exTranslation": "I'm transfering to the subway at the next station.",
    "category": "Verb"
  },
  {
    "term": "乗り換え",
    "termReading": "のりかえ",
    "termTranslation": "change, transfer",
    "ex": "次の駅で乗り換えです。",
    "exReading": "つぎ の えき で のりかえ です",
    "exTranslation": "I'm transfering at the next station.",
    "category": "Verbal Noun"
  },
  {
    "term": "おじさん",
    "termReading": "おじさん",
    "termTranslation": "uncle",
    "ex": "昨日おじさんに会いました。",
    "exReading": "きのう おじさん に あいました",
    "exTranslation": "I met my uncle yesterday.",
    "category": "Noun"
  },
  {
    "term": "換える",
    "termReading": "かえる",
    "termTranslation": "exchange, convert",
    "ex": "車のタイヤを換えた。",
    "exReading": "くるま の たいや を かえた",
    "exTranslation": "I changed the tires on the car.",
    "category": "Verb"
  },
  {
    "term": "着替える",
    "termReading": "きがえる",
    "termTranslation": "change clothes",
    "ex": "彼はきれいな服に着替えた。",
    "exReading": "かれ は きれい な ふく に きがえた",
    "exTranslation": "He changed into clean clothes.",
    "category": "Verb"
  },
  {
    "term": "被る",
    "termReading": "かぶる",
    "termTranslation": "wear, put on",
    "ex": "帽子を被って外出した。",
    "exReading": "ぼうし を かぶって がいしゅつ した",
    "exTranslation": "I went out wearing a hat.",
    "category": "Verb"
  },
  {
    "term": "破る",
    "termReading": "やぶる",
    "termTranslation": "tear, break through, breach",
    "ex": "彼は約束を破った。",
    "exReading": "かれ は やくそく を やぶった",
    "exTranslation": "He broke his promise.",
    "category": "Verb"
  },
  {
    "term": "ベルト",
    "termReading": "ベルト",
    "termTranslation": "belt",
    "ex": "ベルトがきつくなりました。",
    "exReading": "べると が きつく なりました",
    "exTranslation": "My belt has gotten tight.",
    "category": "Noun"
  },
  {
    "term": "破れる",
    "termReading": "やぶれる",
    "termTranslation": "tear, rip open",
    "ex": "シャツが破れている。",
    "exReading": "しゃつ が やぶれて いる",
    "exTranslation": "My shirt got torn.",
    "category": "Verb"
  },
  {
    "term": "壊す",
    "termReading": "こわす",
    "termTranslation": "break, destroy, smash",
    "ex": "彼女が私のケータイを壊した。",
    "exReading": "かのじょ が わたし の けーたい を こわした",
    "exTranslation": "She destroyed my cell phone.",
    "category": "Verb"
  },
  {
    "term": "壊れる",
    "termReading": "こわれる",
    "termTranslation": "break, break down, get out of order",
    "ex": "会社のパソコンが壊れた。",
    "exReading": "かいしゃ の ぱそこん が こわれた",
    "exTranslation": "The company computer broke down.",
    "category": "Verb"
  },
  {
    "term": "救急車",
    "termReading": "きゅうきゅうしゃ",
    "termTranslation": "ambulance",
    "ex": "誰か救急車を呼んでください。",
    "exReading": "だれか きゅうきゅうしゃ を よんで ください",
    "exTranslation": "Someone please call an ambulance.",
    "category": "Noun"
  },
  {
    "term": "ラーメン",
    "termReading": "ラーメン",
    "termTranslation": "ramen (Chinese noodles)",
    "ex": "夕食にラーメンを食べました。",
    "exReading": "ゆうしょく に らーめん を たべました",
    "exTranslation": "I ate ramen for supper.",
    "category": "Noun"
  },
  {
    "term": "助ける",
    "termReading": "たすける",
    "termTranslation": "help, save",
    "ex": "彼女は病気の犬を助けた。",
    "exReading": "かのじょ は びょうき の いぬ を たすけた",
    "exTranslation": "She helped a sick dog.",
    "category": "Verb"
  },
  {
    "term": "立派",
    "termReading": "りっぱ",
    "termTranslation": "fine, excellent",
    "ex": "彼は立派な人です。",
    "exReading": "かれ は りっぱ な ひと です",
    "exTranslation": "He's a fine man.",
    "category": "Adjectival Noun"
  },
  {
    "term": "警察",
    "termReading": "けいさつ",
    "termTranslation": "police",
    "ex": "警察を呼んでください。",
    "exReading": "けいさつ を よんで ください",
    "exTranslation": "Call the police.",
    "category": "Noun"
  },
  {
    "term": "管理",
    "termReading": "かんり",
    "termTranslation": "management, administration",
    "ex": "彼女が私のスケジュールを管理している。",
    "exReading": "かのじょ が わたし の すけじゅーる を かんり して いる",
    "exTranslation": "She manages my schedule.",
    "category": "Verbal Noun"
  },
  {
    "term": "ライター",
    "termReading": "ライター",
    "termTranslation": "lighter",
    "ex": "店にライターを忘れました。",
    "exReading": "みせ に らいたー を わすれました",
    "exTranslation": "I forgot my lighter at the shop.",
    "category": "Noun"
  },
  {
    "term": "盗む",
    "termReading": "ぬすむ",
    "termTranslation": "steal, rob",
    "ex": "誰かが私のカバンを盗みました。",
    "exReading": "だれか が わたし の かばん を ぬすみました",
    "exTranslation": "Someone stole my bag.",
    "category": "Verb"
  },
  {
    "term": "殺す",
    "termReading": "ころす",
    "termTranslation": "kill, smother",
    "ex": "私は生き物を殺すのが嫌いだ。",
    "exReading": "わたし は いきもの を ころす の が きらい だ",
    "exTranslation": "I hate killing living things.",
    "category": "Verb"
  },
  {
    "term": "逮捕",
    "termReading": "たいほ",
    "termTranslation": "arrest, capture",
    "ex": "逃げていた犯人が逮捕されました。",
    "exReading": "にげて いた はんにん が たいほ されました",
    "exTranslation": "They arrested that criminal who escaped.",
    "category": "Verbal Noun"
  },
  {
    "term": "逃げる",
    "termReading": "にげる",
    "termTranslation": "escape, run away",
    "ex": "鳥が窓から逃げたよ。",
    "exReading": "とり が まど から にげた よ",
    "exTranslation": "The bird flew out of the window.",
    "category": "Verb"
  },
  {
    "term": "ワイシャツ",
    "termReading": "ワイシャツ",
    "termTranslation": "(business) shirt, (white) shirt",
    "ex": "彼は白いワイシャツを着ている。",
    "exReading": "かれ は しろい わいしゃつ を きて いる",
    "exTranslation": "He's wearing a white shirt.",
    "category": "Noun"
  },
  {
    "term": "戦争",
    "termReading": "せんそう",
    "termTranslation": "war, battle",
    "ex": "2003年にイラクで戦争があった。",
    "exReading": "にせんさんねん に いらく で せんそう が あった",
    "exTranslation": "There was a war in Iraq in 2003.",
    "category": "Verbal Noun"
  },
  {
    "term": "競争",
    "termReading": "きょうそう",
    "termTranslation": "competition",
    "ex": "2社は互いに競争している。",
    "exReading": "にしゃ は たがい に きょうそう して いる",
    "exTranslation": "Two companies are competing with each other.",
    "category": "Verbal Noun"
  },
  {
    "term": "混む・込む",
    "termReading": "こむ・こむ",
    "termTranslation": "be crowded, be congested",
    "ex": "電車が込んでいる。",
    "exReading": "でんしゃ が こんで いる",
    "exTranslation": "The train is crowded.",
    "category": "Verb"
  },
  {
    "term": "大統領",
    "termReading": "だいとうりょう",
    "termTranslation": "president (of a country)",
    "ex": "フランスの大統領は誰ですか。",
    "exReading": "ふらんす の だいとうりょう は だれ です か",
    "exTranslation": "Who's the president of France?",
    "category": "Noun"
  },
  {
    "term": "シャワー",
    "termReading": "シャワー",
    "termTranslation": "shower",
    "ex": "朝、急いでシャワーを浴びました。",
    "exReading": "あさ いそいで しゃわー を あびました",
    "exTranslation": "I took a very quick shower in the morning.",
    "category": "Noun"
  },
  {
    "term": "捨てる",
    "termReading": "すてる",
    "termTranslation": "discard, abandon",
    "ex": "ゴミを捨ててください。",
    "exReading": "ごみ を すてて ください",
    "exTranslation": "Please throw away the garbage.",
    "category": "Verb"
  },
  {
    "term": "拾う",
    "termReading": "ひろう",
    "termTranslation": "pick up, find",
    "ex": "道で財布を拾った。",
    "exReading": "みち で さいふ を ひろった",
    "exTranslation": "I picked up a wallet in the street.",
    "category": "Verb"
  },
  {
    "term": "池",
    "termReading": "いけ",
    "termTranslation": "pond",
    "ex": "池に鯉がいます。",
    "exReading": "いけ に こい が います",
    "exTranslation": "There are carp in the pond.",
    "category": "Noun"
  },
  {
    "term": "電池",
    "termReading": "でんち",
    "termTranslation": "electric cell, battery",
    "ex": "新しい電池を入れましょう。",
    "exReading": "あたらしい でんち を いれましょう",
    "exTranslation": "Let's put in a new battery.",
    "category": "Noun"
  },
  {
    "term": "うどん",
    "termReading": "うどん",
    "termTranslation": "noodles, Japanese wheat noodles",
    "ex": "私はお昼にうどんを食べました。",
    "exReading": "わたし は おひる に うどん を たべました",
    "exTranslation": "I ate udon noodles for lunch.",
    "category": "Noun"
  },
  {
    "term": "深い",
    "termReading": "ふかい",
    "termTranslation": "deep, profound, intimate",
    "ex": "あの池はとても深い。",
    "exReading": "あの いけ は とても ふかい",
    "exTranslation": "That pond is very deep.",
    "category": "Adjective"
  },
  {
    "term": "深さ",
    "termReading": "ふかさ",
    "termTranslation": "depth, profundity",
    "ex": "このプールの深さは2メートルです。",
    "exReading": "この ぷーる の ふかさ は にめーとる です",
    "exTranslation": "This pool is two meters deep.",
    "category": "Noun"
  },
  {
    "term": "浅い",
    "termReading": "あさい",
    "termTranslation": "shallow, superficial",
    "ex": "この川は浅いです。",
    "exReading": "この かわ は あさい です",
    "exTranslation": "This river is shallow.",
    "category": "Adjective"
  },
  {
    "term": "落とす",
    "termReading": "おとす",
    "termTranslation": "drop, omit",
    "ex": "途中で財布を落としました。",
    "exReading": "とちゅう で さいふ を おとしました",
    "exTranslation": "I lost my purse on the way.",
    "category": "Verb"
  },
  {
    "term": "ごみ",
    "termReading": "ごみ",
    "termTranslation": "rubbish, trash",
    "ex": "今日はごみの日だ。",
    "exReading": "きょう は ごみ の ひ だ",
    "exTranslation": "Today is garbage day.",
    "category": "Noun"
  },
  {
    "term": "泳ぐ",
    "termReading": "およぐ",
    "termTranslation": "swim",
    "ex": "彼女はダイエットのために泳いでいる。",
    "exReading": "かのじょ は だいえっと の ため に およいで いる",
    "exTranslation": "She swims to lose weight.",
    "category": "Verb"
  },
  {
    "term": "水泳",
    "termReading": "すいえい",
    "termTranslation": "swimming",
    "ex": "母は健康のために水泳をしている。",
    "exReading": "はは は けんこう の ため に すいえい を して いる",
    "exTranslation": "My mother swims for her health.",
    "category": "Verbal Noun"
  },
  {
    "term": "流れる",
    "termReading": "ながれる",
    "termTranslation": "flow, pass, drift away",
    "ex": "ラジオから美しい音楽が流れています。",
    "exReading": "らじお から うつくしい おんがく が ながれて います",
    "exTranslation": "Beautiful music is coming from the radio.",
    "category": "Verb"
  },
  {
    "term": "流行る",
    "termReading": "はやる",
    "termTranslation": "be in fashion, be popular",
    "ex": "去年はスニーカーが流行りました。",
    "exReading": "きょねん は すにーかー が はやりました",
    "exTranslation": "Sneakers were popular last year.",
    "category": "Verb"
  },
  {
    "term": "スーパーマーケット",
    "termReading": "スーパーマーケット",
    "termTranslation": "supermarket",
    "ex": "スーパーマーケットで人参を買った。",
    "exReading": "すーぱーまーけっと で にんじん を かった",
    "exTranslation": "I bought carrots at the supermarket.",
    "category": "Noun"
  },
  {
    "term": "洗う",
    "termReading": "あらう",
    "termTranslation": "wash",
    "ex": "早く顔を洗いなさい。",
    "exReading": "はやく かお を あらいなさい",
    "exTranslation": "Hurry up and wash your face.",
    "category": "Verb"
  },
  {
    "term": "洗面所",
    "termReading": "せんめんじょ",
    "termTranslation": "washroom, bathroom",
    "ex": "洗面所で顔を洗った。",
    "exReading": "せんめんじょ で かお を あらった",
    "exTranslation": "She washed her face in the lavatory.",
    "category": "Noun"
  },
  {
    "term": "油",
    "termReading": "あぶら",
    "termTranslation": "oil, animal oil",
    "ex": "水と油は混ざらない。",
    "exReading": "みず と あぶら は まざらない",
    "exTranslation": "Oil and water don't mix.",
    "category": "Noun"
  },
  {
    "term": "沈む",
    "termReading": "しずむ",
    "termTranslation": "sink, set",
    "ex": "ボートが川に沈んだ。",
    "exReading": "ぼーと が かわ に しずんだ",
    "exTranslation": "The boat sank in the river.",
    "category": "Verb"
  },
  {
    "term": "ハンカチ",
    "termReading": "ハンカチ",
    "termTranslation": "handkerchief",
    "ex": "ハンカチで手をふきました。",
    "exReading": "はんかち で て を ふきました",
    "exTranslation": "I wiped my hands with my handkerchief.",
    "category": "Noun"
  },
  {
    "term": "久しぶり",
    "termReading": "ひさしぶり",
    "termTranslation": "after a long time",
    "ex": "明日、久しぶりに友達に会います。",
    "exReading": "あした ひさしぶり に ともだち に あいます",
    "exTranslation": "Tomorrow I'm meeting a friend I haven't seen for a long time.",
    "category": "Adjectival Noun"
  },
  {
    "term": "氷",
    "termReading": "こおり",
    "termTranslation": "ice",
    "ex": "グラスに氷を入れてください。",
    "exReading": "ぐらす に こおり を いれて ください",
    "exTranslation": "Please put some ice in my glass.",
    "category": "Noun"
  },
  {
    "term": "冷える",
    "termReading": "ひえる",
    "termTranslation": "cool down, feel chilly",
    "ex": "クーラーで体が冷えた。",
    "exReading": "くーらー で からだ が ひえた",
    "exTranslation": "The air conditioner's given me the chills.",
    "category": "Verb"
  },
  {
    "term": "冷やす",
    "termReading": "ひやす",
    "termTranslation": "cool, chill",
    "ex": "頭を冷やしなさい。",
    "exReading": "あたま を ひやしなさい",
    "exTranslation": "Cool your head.",
    "category": "Verb"
  },
  {
    "term": "ビデオテープ",
    "termReading": "ビデオテープ",
    "termTranslation": "videotape",
    "ex": "私はビデオテープを30本持っています。",
    "exReading": "わたし は びでおてーぷ を さんじゅっぽん もって います",
    "exTranslation": "I have 30 videotapes.",
    "category": "Noun"
  },
  {
    "term": "冷める",
    "termReading": "さめる",
    "termTranslation": "cool off, subside",
    "ex": "彼への気持ちが冷めた。",
    "exReading": "かれ へ の きもち が さめた",
    "exTranslation": "My feelings for him have died down.",
    "category": "Verb"
  },
  {
    "term": "凍る",
    "termReading": "こおる",
    "termTranslation": "freeze, congeal",
    "ex": "寒い朝は道路が凍ります。",
    "exReading": "さむい あさ は どうろ が こおります",
    "exTranslation": "The roads freeze on cold mornings.",
    "category": "Verb"
  },
  {
    "term": "冷蔵庫",
    "termReading": "れいぞうこ",
    "termTranslation": "refrigerator",
    "ex": "飲み物は冷蔵庫にあります。",
    "exReading": "のみもの は れいぞうこ に あります",
    "exTranslation": "Drinks are in the refrigerator.",
    "category": "Noun"
  },
  {
    "term": "涼しい",
    "termReading": "すずしい",
    "termTranslation": "cool, refreshing",
    "ex": "夕方は涼しくなりますよ。",
    "exReading": "ゆうがた は すずしく なります よ",
    "exTranslation": "It gets cooler in the evening.",
    "category": "Adjective"
  },
  {
    "term": "ぶらぶら",
    "termReading": "ぶらぶら",
    "termTranslation": "wander aimlessly, hang about",
    "ex": "朝、近所をぶらぶらした。",
    "exReading": "あさ きんじょ を ぶらぶら した",
    "exTranslation": "I wandered around the neighborhood this morning.",
    "category": "Adverb"
  },
  {
    "term": "汚す",
    "termReading": "よごす",
    "termTranslation": "make dirty, soil",
    "ex": "彼は服を汚した。",
    "exReading": "かれ は ふく を よごした",
    "exTranslation": "He got his clothes dirty.",
    "category": "Verb"
  },
  {
    "term": "汚れ",
    "termReading": "よごれ",
    "termTranslation": "dirt, filth",
    "ex": "靴の汚れを落としました。",
    "exReading": "くつ の よごれ を おとしました",
    "exTranslation": "I cleaned the dirt off the shoes.",
    "category": "Noun"
  },
  {
    "term": "汚れる",
    "termReading": "よごれる",
    "termTranslation": "become dirty, become defiled",
    "ex": "エプロンをしないと服が汚れます。",
    "exReading": "えぷろん を しない と ふく が よごれます",
    "exTranslation": "Your clothes will get dirty if you don't wear an apron.",
    "category": "Verb"
  },
  {
    "term": "景色",
    "termReading": "けしき",
    "termTranslation": "scenery, landscape",
    "ex": "ここは景色がきれいですね。",
    "exReading": "ここ は けしき が きれい です ね",
    "exTranslation": "The scenery here is beautiful.",
    "category": "Noun"
  },
  {
    "term": "アナウンサー",
    "termReading": "アナウンサー",
    "termTranslation": "news reader, announcer",
    "ex": "私はアナウンサーになりたい。",
    "exReading": "わたし は あなうんさー に なりたい",
    "exTranslation": "I want to become an announcer.",
    "category": "Noun"
  },
  {
    "term": "影響",
    "termReading": "えいきょう",
    "termTranslation": "influence, effect",
    "ex": "私は彼から大きな影響を受けました。",
    "exReading": "わたし は かれ から おおき な えいきょう を うけました",
    "exTranslation": "I was deeply influenced by him.",
    "category": "Verbal Noun"
  },
  {
    "term": "光る",
    "termReading": "ひかる",
    "termTranslation": "shine, emit light",
    "ex": "波がきらきら光っていました。",
    "exReading": "なみ が きらきら ひかって いました",
    "exTranslation": "The waves sparkled brilliantly.",
    "category": "Verb"
  },
  {
    "term": "太る",
    "termReading": "ふとる",
    "termTranslation": "grow fat, fatten",
    "ex": "私の姉はすぐ太ります。",
    "exReading": "わたし の あね は すぐ ふとります",
    "exTranslation": "My big sister puts on weight easily.",
    "category": "Verb"
  },
  {
    "term": "太陽",
    "termReading": "たいよう",
    "termTranslation": "sun",
    "ex": "太陽が雲に隠れた。",
    "exReading": "たいよう が くも に かくれた",
    "exTranslation": "The sun hid behind the clouds.",
    "category": "Noun"
  },
  {
    "term": "スプーン",
    "termReading": "スプーン",
    "termTranslation": "spoon",
    "ex": "カレーライスはスプーンで食べます。",
    "exReading": "かれーらいす は すぷーん で たべます",
    "exTranslation": "You eat curry and rice with a spoon.",
    "category": "Noun"
  },
  {
    "term": "星",
    "termReading": "ほし",
    "termTranslation": "star, planet, spot",
    "ex": "今夜は星がよく見えます。",
    "exReading": "こんや は ほし が よく みえます",
    "exTranslation": "You can see the stars clearly tonight.",
    "category": "Noun"
  },
  {
    "term": "地球",
    "termReading": "ちきゅう",
    "termTranslation": "the Earth",
    "ex": "地球は丸い。",
    "exReading": "ちきゅう は まるい",
    "exTranslation": "The earth is round.",
    "category": "Noun"
  },
  {
    "term": "野球",
    "termReading": "やきゅう",
    "termTranslation": "baseball",
    "ex": "友達と野球をしました。",
    "exReading": "ともだち と やきゅう を しました",
    "exTranslation": "I played baseball with a friend.",
    "category": "Noun"
  },
  {
    "term": "雲",
    "termReading": "くも",
    "termTranslation": "cloud",
    "ex": "今日は雲が多い。",
    "exReading": "きょう は くも が おおい",
    "exTranslation": "It's cloudy today.",
    "category": "Noun"
  },
  {
    "term": "ブラウス",
    "termReading": "ブラウス",
    "termTranslation": "blouse",
    "ex": "彼女は白いブラウスを着ている。",
    "exReading": "かのじょ は しろい ぶらうす を きて いる",
    "exTranslation": "She's wearing a white blouse.",
    "category": "Noun"
  },
  {
    "term": "曇り",
    "termReading": "くもり",
    "termTranslation": "cloudiness, cloudy weather",
    "ex": "今日は一日曇りでした。",
    "exReading": "きょう は いちにち くもり でした",
    "exTranslation": "It was cloudy all day today.",
    "category": "Noun"
  },
  {
    "term": "地震",
    "termReading": "じしん",
    "termTranslation": "earthquake",
    "ex": "日本は地震が多いです。",
    "exReading": "にほん は じしん が おおい です",
    "exTranslation": "Earthquakes are frequent in Japan.",
    "category": "Noun"
  },
  {
    "term": "震える",
    "termReading": "ふるえる",
    "termTranslation": "tremble, quiver, shake",
    "ex": "彼は寒くて震えていました。",
    "exReading": "かれ は さむくて ふるえて いました",
    "exTranslation": "He was cold and shivering.",
    "category": "Verb"
  },
  {
    "term": "振る",
    "termReading": "ふる",
    "termTranslation": "wave, shake, brandish",
    "ex": "犬がしっぽを振っている。",
    "exReading": "いぬ が しっぽ を ふって いる",
    "exTranslation": "The dog is wagging its tail.",
    "category": "Verb"
  },
  {
    "term": "リボン",
    "termReading": "リボン",
    "termTranslation": "ribbon",
    "ex": "プレゼントにリボンを付けた。",
    "exReading": "ぷれぜんと に りぼん を つけた",
    "exTranslation": "I put a ribbon on the present.",
    "category": "Noun"
  },
  {
    "term": "揺れる",
    "termReading": "ゆれる",
    "termTranslation": "shake, sway, rock",
    "ex": "風で木が揺れています。",
    "exReading": "かぜ で き が ゆれて います",
    "exTranslation": "The trees are swaying in the wind.",
    "category": "Verb"
  },
  {
    "term": "神社",
    "termReading": "じんじゃ",
    "termTranslation": "Shinto shrine",
    "ex": "京都には神社がたくさんある。",
    "exReading": "きょうと に は じんじゃ が たくさん ある",
    "exTranslation": "There are many Shinto shrines in Kyoto.",
    "category": "Noun"
  },
  {
    "term": "秘密",
    "termReading": "ひみつ",
    "termTranslation": "secret, privacy",
    "ex": "これは秘密です。",
    "exReading": "これ は ひみつ です",
    "exTranslation": "This is a secret.",
    "category": "Adjectival Noun"
  },
  {
    "term": "厳しい",
    "termReading": "きびしい",
    "termTranslation": "strict, severe, intense",
    "ex": "私の上司はとても厳しい。",
    "exReading": "わたし の じょうし は とても きびしい",
    "exTranslation": "My boss is very strict.",
    "category": "Adjective"
  },
  {
    "term": "いかが",
    "termReading": "いかが",
    "termTranslation": "how, what",
    "ex": "お加減はいかがですか。",
    "exReading": "おかげん は いかが です か",
    "exTranslation": "How are you feeling?",
    "category": "Adverb"
  },
  {
    "term": "年寄り",
    "termReading": "としより",
    "termTranslation": "old person, older councilor",
    "ex": "あの村にはお年寄りがたくさん住んでいます。",
    "exReading": "あの むら に は おとしより が たくさん すんで います",
    "exTranslation": "A lot of elderly people live in that village.",
    "category": "Noun"
  },
  {
    "term": "歴史",
    "termReading": "れきし",
    "termTranslation": "history",
    "ex": "私は歴史に興味があります。",
    "exReading": "わたし は れきし に きょうみ が あります",
    "exTranslation": "I'm interested in history.",
    "category": "Noun"
  },
  {
    "term": "世紀",
    "termReading": "せいき",
    "termTranslation": "century",
    "ex": "新しい世紀の始まりです。",
    "exReading": "あたらしい せいき の はじまり です",
    "exTranslation": "It's the start of a new century.",
    "category": "Noun"
  },
  {
    "term": "建設",
    "termReading": "けんせつ",
    "termTranslation": "construction, building",
    "ex": "新しいビルの建設が始まった。",
    "exReading": "あたらしい びる の けんせつ が はじまった",
    "exTranslation": "Construction began on the new building.",
    "category": "Verbal Noun"
  },
  {
    "term": "ステレオ",
    "termReading": "ステレオ",
    "termTranslation": "stereo",
    "ex": "彼はステレオで音楽を聞いた。",
    "exReading": "かれ は すてれお で おんがく を きいた",
    "exTranslation": "He's listening to music on the stereo.",
    "category": "Noun"
  },
  {
    "term": "建物",
    "termReading": "たてもの",
    "termTranslation": "building, structure",
    "ex": "これは日本一古い建物です。",
    "exReading": "これ は にほんいち ふるい たてもの です",
    "exTranslation": "This is the oldest building in Japan.",
    "category": "Noun"
  },
  {
    "term": "建つ",
    "termReading": "たつ",
    "termTranslation": "be built, be erected",
    "ex": "ここに来年、家が建ちます。",
    "exReading": "ここ に らいねん いえ が たちます",
    "exTranslation": "Our house will be built here next year.",
    "category": "Verb"
  },
  {
    "term": "構成",
    "termReading": "こうせい",
    "termTranslation": "organization",
    "ex": "システムの構成を変えてみました。",
    "exReading": "しすてむ の こうせい を かえて みました",
    "exTranslation": "I've tried to reorganize the system.",
    "category": "Verbal Noun"
  },
  {
    "term": "構造",
    "termReading": "こうぞう",
    "termTranslation": "construction, structure",
    "ex": "この建物の構造は複雑です。",
    "exReading": "この たてもの の こうぞう は ふくざつ です",
    "exTranslation": "The structure of this building is complex.",
    "category": "Noun"
  },
  {
    "term": "フォーク",
    "termReading": "フォーク",
    "termTranslation": "fork",
    "ex": "フォークをもらえますか。",
    "exReading": "ふぉーく を もらえます か",
    "exTranslation": "May I have a fork?",
    "category": "Noun"
  },
  {
    "term": "橋",
    "termReading": "はし",
    "termTranslation": "bridge",
    "ex": "あの橋は日本で一番長い。",
    "exReading": "あの はし は にほん で いちばん ながい",
    "exTranslation": "That bridge is the longest in Japan.",
    "category": "Noun"
  },
  {
    "term": "柱",
    "termReading": "はしら",
    "termTranslation": "pillar, prop",
    "ex": "この家の柱は太い。",
    "exReading": "この いえ の はしら は ふとい",
    "exTranslation": "The pillars in this house are thick.",
    "category": "Noun"
  },
  {
    "term": "位置",
    "termReading": "いち",
    "termTranslation": "position, place",
    "ex": "私の町は東京の北に位置します。",
    "exReading": "わたし の まち は とうきょう の きた に いち します",
    "exTranslation": "My town is located north of Tokyo.",
    "category": "Verbal Noun"
  },
  {
    "term": "離婚",
    "termReading": "りこん",
    "termTranslation": "divorce",
    "ex": "友人が離婚しました。",
    "exReading": "ゆうじん が りこん しました",
    "exTranslation": "My friend got divorced.",
    "category": "Verbal Noun"
  },
  {
    "term": "おば",
    "termReading": "おば",
    "termTranslation": "aunt",
    "ex": "おばは大阪に住んでいます。",
    "exReading": "おば は おおさか に すんで います",
    "exTranslation": "My aunt lives in Osaka.",
    "category": "Noun"
  },
  {
    "term": "停車",
    "termReading": "ていしゃ",
    "termTranslation": "stoppage",
    "ex": "この電車は東京まで停車致しません。",
    "exReading": "この でんしゃ は とうきょう まで ていしゃ いたしません",
    "exTranslation": "This train won't stop until Tokyo.",
    "category": "Verbal Noun"
  },
  {
    "term": "バス停",
    "termReading": "ばすてい",
    "termTranslation": "bus stop",
    "ex": "次のバス停で降ります。",
    "exReading": "つぎ の ばすてい で おります",
    "exTranslation": "I get off at the next bus stop.",
    "category": "Noun"
  },
  {
    "term": "周辺",
    "termReading": "しゅうへん",
    "termTranslation": "environs, circumference",
    "ex": "この周辺には大学が多い。",
    "exReading": "この しゅうへん に は だいがく が おおい",
    "exTranslation": "There are a lot of universities in this area.",
    "category": "Noun"
  },
  {
    "term": "隣",
    "termReading": "となり",
    "termTranslation": "neighboring house, next seat",
    "ex": "隣の家には犬がいます。",
    "exReading": "となり の いえ に は いぬ が います",
    "exTranslation": "They have a dog next door.",
    "category": "Noun"
  },
  {
    "term": "きらきら",
    "termReading": "きらきら",
    "termTranslation": "shining, sparkling",
    "ex": "星がきらきら光っている。",
    "exReading": "ほし が きらきら ひかって いる",
    "exTranslation": "The stars are shining brightly.",
    "category": "Adverb"
  },
  {
    "term": "黄色",
    "termReading": "きいろ",
    "termTranslation": "yellow",
    "ex": "信号は黄色でした。",
    "exReading": "しんごう は きいろ でした",
    "exTranslation": "The traffic light was yellow.",
    "category": "Noun"
  },
  {
    "term": "横",
    "termReading": "よこ",
    "termTranslation": "side, width across",
    "ex": "横の長さは1メートルです。",
    "exReading": "よこ の ながさ は いちめーとる です",
    "exTranslation": "Its width is one meter.",
    "category": "Noun"
  },
  {
    "term": "横書き",
    "termReading": "よこがき",
    "termTranslation": "horizontal writing",
    "ex": "この本は横書きです。",
    "exReading": "この ほん は よこがき です",
    "exTranslation": "This book is printed horizontally.",
    "category": "Noun"
  },
  {
    "term": "判断",
    "termReading": "はんだん",
    "termTranslation": "judgment, decision",
    "ex": "彼の判断は正しい。",
    "exReading": "かれ の はんだん は ただしい",
    "exTranslation": "His judgment is correct.",
    "category": "Verbal Noun"
  },
  {
    "term": "どうぞ",
    "termReading": "どうぞ",
    "termTranslation": "please",
    "ex": "こちらへどうぞ。",
    "exReading": "こちらへどうぞ。",
    "exTranslation": "This way please.",
    "category": "Adverb"
  },
  {
    "term": "断る",
    "termReading": "ことわる",
    "termTranslation": "refuse, turn down",
    "ex": "私は彼のプロポーズを断った。",
    "exReading": "わたし は かれ の ぷろぽーず を ことわった",
    "exTranslation": "I turned down his marriage proposal.",
    "category": "Verb"
  },
  {
    "term": "横断歩道",
    "termReading": "おうだんほどう",
    "termTranslation": "pedestrian crossing, crosswalk",
    "ex": "あそこに横断歩道があります。",
    "exReading": "あそこ に おうだんほどう が あります",
    "exTranslation": "There's a crosswalk over there.",
    "category": "Noun"
  },
  {
    "term": "大幅",
    "termReading": "おおはば",
    "termTranslation": "drastically, substantially",
    "ex": "計画を大幅に変更した。",
    "exReading": "けいかく を おおはば に へんこう した",
    "exTranslation": "We substantially changed the plan.",
    "category": "Adverb"
  },
  {
    "term": "訪れる",
    "termReading": "おとずれる",
    "termTranslation": "visit, call on",
    "ex": "私は夏に京都を訪れました。",
    "exReading": "わたし は なつ に きょうと を おとずれました",
    "exTranslation": "I visited Kyoto this summer.",
    "category": "Verb"
  },
  {
    "term": "ネックレス",
    "termReading": "ネックレス",
    "termTranslation": "necklace",
    "ex": "妻にネックレスをプレゼントしました。",
    "exReading": "つま に ねっくれす を ぷれぜんと しました",
    "exTranslation": "I gave my wife a necklace.",
    "category": "Noun"
  },
  {
    "term": "訪ねる",
    "termReading": "たずねる",
    "termTranslation": "visit, call on",
    "ex": "彼はニューヨークの友達を訪ねた。",
    "exReading": "かれ は にゅーよーく の ともだち を たずねた",
    "exTranslation": "He visited his friend in New York.",
    "category": "Verb"
  },
  {
    "term": "冷房",
    "termReading": "れいぼう",
    "termTranslation": "air conditioning",
    "ex": "冷房を入れてください。",
    "exReading": "れいぼう を いれて ください",
    "exTranslation": "Please turn on the air-conditioner.",
    "category": "Verbal Noun"
  },
  {
    "term": "暖房",
    "termReading": "だんぼう",
    "termTranslation": "heating",
    "ex": "冬は暖房が必要です。",
    "exReading": "ふゆ は だんぼう が ひつよう です",
    "exTranslation": "Heating is necessary in winter.",
    "category": "Verbal Noun"
  },
  {
    "term": "文房具",
    "termReading": "ぶんぼうぐ",
    "termTranslation": "stationery, writing materials",
    "ex": "新しい文房具を買いました。",
    "exReading": "あたらしい ぶんぼうぐ を かいました",
    "exTranslation": "I bought some new stationery.",
    "category": "Noun"
  },
  {
    "term": "パジャマ",
    "termReading": "パジャマ",
    "termTranslation": "pajamas",
    "ex": "このパジャマを着て。",
    "exReading": "この ぱじゃま を きて",
    "exTranslation": "Put on these pajamas.",
    "category": "Noun"
  },
  {
    "term": "諸国",
    "termReading": "しょこく",
    "termTranslation": "various countries",
    "ex": "彼はヨーロッパ諸国を旅行した。",
    "exReading": "かれ は よーろっぱ しょこく を りょこう した",
    "exTranslation": "He traveled through various European countries.",
    "category": "Noun"
  },
  {
    "term": "緑色",
    "termReading": "みどりいろ",
    "termTranslation": "green",
    "ex": "彼は緑色のジャケットを着ています。",
    "exReading": "かれ は みどりいろ の じゃけっと を きて います",
    "exTranslation": "He's wearing a green jacket.",
    "category": "Noun"
  },
  {
    "term": "貿易",
    "termReading": "ぼうえき",
    "termTranslation": "trade, commerce",
    "ex": "父は貿易の仕事をしています。",
    "exReading": "ちち は ぼうえき の しごと を して います",
    "exTranslation": "My father works in the trading business.",
    "category": "Verbal Noun"
  },
  {
    "term": "輸入",
    "termReading": "ゆにゅう",
    "termTranslation": "import",
    "ex": "これはイタリアから輸入した服です。",
    "exReading": "これ は いたりあ から ゆにゅう した ふく です",
    "exTranslation": "These clothes are imported from Italy.",
    "category": "Verbal Noun"
  },
  {
    "term": "ボールペン",
    "termReading": "ボールペン",
    "termTranslation": "ballpoint pen",
    "ex": "ボールペンで名前を書いてください。",
    "exReading": "ぼーるぺん で なまえ を かいて ください",
    "exTranslation": "Please write your name with a ballpoint pen.",
    "category": "Noun"
  },
  {
    "term": "輸出",
    "termReading": "ゆしゅつ",
    "termTranslation": "export",
    "ex": "彼の会社は車を輸出しています。",
    "exReading": "かれ の かいしゃ は くるま を ゆしゅつ して います",
    "exTranslation": "His company exports cars.",
    "category": "Verbal Noun"
  },
  {
    "term": "指輪",
    "termReading": "ゆびわ",
    "termTranslation": "ring",
    "ex": "彼女に指輪をプレゼントしました。",
    "exReading": "かのじょ に ゆびわ を ぷれぜんと しました",
    "exTranslation": "I gave her a ring as a gift.",
    "category": "Noun"
  },
  {
    "term": "往復",
    "termReading": "おうふく",
    "termTranslation": "going and returning, round trip",
    "ex": "往復切符をください。",
    "exReading": "おうふく きっぷ を ください",
    "exTranslation": "I'd like a roundtrip ticket please.",
    "category": "Noun"
  },
  {
    "term": "復習",
    "termReading": "ふくしゅう",
    "termTranslation": "review",
    "ex": "昨日の復習をしましたか。",
    "exReading": "きのう の ふくしゅう を しました か",
    "exTranslation": "Did you review yesterday's lesson?",
    "category": "Verbal Noun"
  },
  {
    "term": "すいか",
    "termReading": "すいか",
    "termTranslation": "watermelon",
    "ex": "夏はすいかが美味しい。",
    "exReading": "なつ は すいか が おいしい",
    "exTranslation": "Watermelon is delicious in summer.",
    "category": "Noun"
  },
  {
    "term": "繰り返す",
    "termReading": "くりかえす",
    "termTranslation": "repeat",
    "ex": "彼女は同じ間違いを繰り返した。",
    "exReading": "かのじょ は おなじ まちがい を くりかえした",
    "exTranslation": "She repeated the same mistake.",
    "category": "Verb"
  },
  {
    "term": "留学",
    "termReading": "りゅうがく",
    "termTranslation": "studying abroad",
    "ex": "1年間、アメリカに留学しました。",
    "exReading": "いちねんかん あめりか に りゅうがく しました",
    "exTranslation": "I went to study in the United States for a year.",
    "category": "Verbal Noun"
  },
  {
    "term": "停留所",
    "termReading": "ていりゅうじょ",
    "termTranslation": "stop, station",
    "ex": "バスの停留所で10分待ちました。",
    "exReading": "ばす の ていりゅうじょ で じゅっぷん まちました",
    "exTranslation": "I waited for ten minutes at the bus stop.",
    "category": "Noun"
  },
  {
    "term": "書留",
    "termReading": "かきとめ",
    "termTranslation": "registered mail",
    "ex": "これを書留で送りたいのですが。",
    "exReading": "これ を かきとめ で おくりたい の です が",
    "exTranslation": "I want to send this by registered mail.",
    "category": "Noun"
  },
  {
    "term": "そちら",
    "termReading": "そちら",
    "termTranslation": "there, that way",
    "ex": "夕方そちらに着きます。",
    "exReading": "ゆうがた そちら に つきます",
    "exTranslation": "I'll arrive there in the evening.",
    "category": "Noun"
  },
  {
    "term": "守る",
    "termReading": "まもる",
    "termTranslation": "protect, observe",
    "ex": "彼は約束を守る人です。",
    "exReading": "かれ は やくそく を まもる ひと です",
    "exTranslation": "He's one to keep his promises.",
    "category": "Verb"
  },
  {
    "term": "留守",
    "termReading": "るす",
    "termTranslation": "not at home",
    "ex": "父は今、留守です。",
    "exReading": "ちち は いま るす です",
    "exTranslation": "My father isn't at home now.",
    "category": "Noun"
  },
  {
    "term": "住宅",
    "termReading": "じゅうたく",
    "termTranslation": "housing, residence",
    "ex": "ここは静かな住宅地だ。",
    "exReading": "ここ は しずかな じゅうたくち だ",
    "exTranslation": "This is a quiet residential area.",
    "category": "Noun"
  },
  {
    "term": "自宅",
    "termReading": "じたく",
    "termTranslation": "one's house, one's home",
    "ex": "自宅に電話を下さい。",
    "exReading": "じたく に でんわ を ください",
    "exTranslation": "Please call home.",
    "category": "Noun"
  },
  {
    "term": "そば",
    "termReading": "そば",
    "termTranslation": "buckwheat noodles, soba",
    "ex": "お昼にそばを食べた。",
    "exReading": "おひる に そば を たべた",
    "exTranslation": "I ate buckwheat noodles for lunch.",
    "category": "Noun"
  },
  {
    "term": "お宅",
    "termReading": "おたく",
    "termTranslation": "your home, you, fan, geek",
    "ex": "先生のお宅はどちらですか。",
    "exReading": "せんせい の おたく は どちら です か",
    "exTranslation": "Where is the teacher's house?",
    "category": "Noun"
  },
  {
    "term": "早起き",
    "termReading": "はやおき",
    "termTranslation": "early rising",
    "ex": "祖父は早起きです。",
    "exReading": "そふ は はやおき です",
    "exTranslation": "My grandfather gets up early.",
    "category": "Verbal Noun"
  },
  {
    "term": "昼寝",
    "termReading": "ひるね",
    "termTranslation": "siesta, nap",
    "ex": "私の子供は毎日昼寝をします。",
    "exReading": "わたし の こども は まいにち ひるね を します",
    "exTranslation": "My child takes a nap every day.",
    "category": "Verbal Noun"
  },
  {
    "term": "静か",
    "termReading": "しずか",
    "termTranslation": "quiet, tranquil",
    "ex": "今年の夏休みには静かな所へ行きたい。",
    "exReading": "ことし の なつやすみ に は しずか な ところ へ いきたい",
    "exTranslation": "I want to go to a quiet place this summer vacation.",
    "category": "Adjectival Noun"
  },
  {
    "term": "どっち",
    "termReading": "どっち",
    "termTranslation": "which",
    "ex": "どっちの色が好きですか。",
    "exReading": "どっち の いろ が すき です か 。",
    "exTranslation": "Which color do you like?",
    "category": "Noun"
  },
  {
    "term": "暇",
    "termReading": "ひま",
    "termTranslation": "free time, spare time",
    "ex": "明日は暇ですか。",
    "exReading": "あした は ひま です か",
    "exTranslation": "Are you free tomorrow?",
    "category": "Noun"
  },
  {
    "term": "趣味",
    "termReading": "しゅみ",
    "termTranslation": "hobby, interest",
    "ex": "私の趣味は映画とテニスです。",
    "exReading": "わたし の しゅみ は えいが と てにす です",
    "exTranslation": "My hobbies are tennis and movies.",
    "category": "Noun"
  },
  {
    "term": "両方",
    "termReading": "りょうほう",
    "termTranslation": "both",
    "ex": "チョコレートとケーキを両方ください。",
    "exReading": "ちょこれーと と けーき を りょうほう ください",
    "exTranslation": "I'll have both the chocolate and the cake, please.",
    "category": "Noun"
  },
  {
    "term": "両替",
    "termReading": "りょうがえ",
    "termTranslation": "money changing, exchange of money",
    "ex": "1万円を両替してください。",
    "exReading": "いちまんえん を りょうがえ して ください",
    "exTranslation": "Please exchange 10,000 yen for me.",
    "category": "Verbal Noun"
  },
  {
    "term": "バスケットボール",
    "termReading": "バスケットボール",
    "termTranslation": "basketball",
    "ex": "姉はバスケットボールの選手です。",
    "exReading": "あね は ばすけっとぼーる の せんしゅ です",
    "exTranslation": "My big sister's a basketball player.",
    "category": "Noun"
  },
  {
    "term": "両親",
    "termReading": "りょうしん",
    "termTranslation": "parents",
    "ex": "私の両親は大阪に住んでいます。",
    "exReading": "わたし の りょうしん は おおさか に すんで います",
    "exTranslation": "My parents live in Osaka.",
    "category": "Noun"
  },
  {
    "term": "片道",
    "termReading": "かたみち",
    "termTranslation": "one way",
    "ex": "東京まで片道切符を買った。",
    "exReading": "とうきょう まで かたみち きっぷ を かった",
    "exTranslation": "I bought a one-way ticket to Tokyo.",
    "category": "Noun"
  },
  {
    "term": "内側",
    "termReading": "うちがわ",
    "termTranslation": "inside, interior",
    "ex": "白線の内側に下がってください。",
    "exReading": "はくせん の うちがわ に さがって ください",
    "exTranslation": "Please step back behind the white line.",
    "category": "Noun"
  },
  {
    "term": "向こう側",
    "termReading": "むこうがわ",
    "termTranslation": "other side",
    "ex": "私の家は川の向こう側にあります。",
    "exReading": "わたし の いえ は かわ の むこうがわ に あります",
    "exTranslation": "My house is on the other side of the river.",
    "category": "Noun"
  },
  {
    "term": "サンドイッチ",
    "termReading": "サンドイッチ",
    "termTranslation": "sandwich",
    "ex": "今日のお昼はサンドイッチです。",
    "exReading": "きょう の おひる は さんどいっち です",
    "exTranslation": "We're having sandwiches for lunch.",
    "category": "Noun"
  },
  {
    "term": "外側",
    "termReading": "そとがわ",
    "termTranslation": "exterior, outside",
    "ex": "白線の外側を歩かないでください。",
    "exReading": "はくせん の そとがわ を あるかない で ください",
    "exTranslation": "Please don't walk outside the white line.",
    "category": "Noun"
  },
  {
    "term": "左側",
    "termReading": "ひだりがわ",
    "termTranslation": "left side",
    "ex": "画面の左側を見てください。",
    "exReading": "がめん の ひだりがわ を みて ください",
    "exTranslation": "Please look at the left side of the screen.",
    "category": "Noun"
  },
  {
    "term": "右側",
    "termReading": "みぎがわ",
    "termTranslation": "right side",
    "ex": "彼女はいつも私の右側を歩きます。",
    "exReading": "かのじょ は いつも わたし の みぎがわ を あるきます",
    "exTranslation": "She always walks on my right.",
    "category": "Noun"
  },
  {
    "term": "裏",
    "termReading": "うら",
    "termTranslation": "rear, reverse, inside",
    "ex": "会社の裏に公園があります。",
    "exReading": "かいしゃ の うら に こうえん が あります",
    "exTranslation": "There's a park behind the company.",
    "category": "Noun"
  },
  {
    "term": "にこにこ",
    "termReading": "にこにこ",
    "termTranslation": "smile",
    "ex": "彼女はいつもにこにこしています。",
    "exReading": "かのじょ は いつも にこにこ して います",
    "exTranslation": "She's always smiling.",
    "category": "Adverb"
  },
  {
    "term": "裏返す",
    "termReading": "うらがえす",
    "termTranslation": "turn over, turn inside out",
    "ex": "彼は紙を裏返しました。",
    "exReading": "かれ は かみ を うらがえしました",
    "exTranslation": "He turned the paper over.",
    "category": "Verb"
  },
  {
    "term": "週刊誌",
    "termReading": "しゅうかんし",
    "termTranslation": "weekly magazine",
    "ex": "その週刊誌はゴシップばかりだ。",
    "exReading": "その しゅうかんし は ごしっぷ ばかり だ",
    "exTranslation": "That weekly is full of gossip.",
    "category": "Noun"
  },
  {
    "term": "朝刊",
    "termReading": "ちょうかん",
    "termTranslation": "morning edition",
    "ex": "今日の朝刊に面白い記事があった。",
    "exReading": "きょう の ちょうかん に おもしろい きじ が あった",
    "exTranslation": "There was an amusing article in today's morning paper.",
    "category": "Noun"
  },
  {
    "term": "夕刊",
    "termReading": "ゆうかん",
    "termTranslation": "evening edition",
    "ex": "そのニュースは夕刊で見ました。",
    "exReading": "その にゅーす は ゆうかん で みました",
    "exTranslation": "I saw that news in the evening newspaper.",
    "category": "Noun"
  },
  {
    "term": "ウール",
    "termReading": "ウール",
    "termTranslation": "wool",
    "ex": "このウールのセーターは暖かい。",
    "exReading": "この うーる の せーたー は あたたかい",
    "exTranslation": "This wool sweater is warm.",
    "category": "Noun"
  },
  {
    "term": "詳しい",
    "termReading": "くわしい",
    "termTranslation": "detailed, complete",
    "ex": "もっと詳しく説明してください。",
    "exReading": "もっと くわしく せつめい して ください",
    "exTranslation": "Please explain in greater detail.",
    "category": "Adjective"
  },
  {
    "term": "細かい",
    "termReading": "こまかい",
    "termTranslation": "minute, elaborate",
    "ex": "彼女は細かいことにうるさい。",
    "exReading": "かのじょ は こまかい こと に うるさい",
    "exTranslation": "She's fussy about small details.",
    "category": "Adjective"
  },
  {
    "term": "細か",
    "termReading": "こまか",
    "termTranslation": "minute, elaborate",
    "ex": "それは細かな問題です。",
    "exReading": "それ は こまか な もんだい です",
    "exTranslation": "That's a complex issue.",
    "category": "Adjectival Noun"
  },
  {
    "term": "積もる",
    "termReading": "つもる",
    "termTranslation": "accumulate, get accumulated",
    "ex": "雪が積もっています。",
    "exReading": "ゆき が つもって います",
    "exTranslation": "The snow has settled.",
    "category": "Verb"
  },
  {
    "term": "コーラ",
    "termReading": "コーラ",
    "termTranslation": "cola, coke",
    "ex": "私の兄はコーラが好きだ。",
    "exReading": "わたし の あに は こーら が すき だ",
    "exTranslation": "My older brother likes cola.",
    "category": "Noun"
  },
  {
    "term": "訳",
    "termReading": "やく",
    "termTranslation": "translation, rendering",
    "ex": "その文の訳を読みました。",
    "exReading": "その ぶん の やく を よみました",
    "exTranslation": "I read a translation of that sentence.",
    "category": "Noun"
  },
  {
    "term": "訳す",
    "termReading": "やくす",
    "termTranslation": "translate",
    "ex": "この文を訳してください。",
    "exReading": "この ぶん を やくして ください",
    "exTranslation": "Please translate this sentence.",
    "category": "Verb"
  },
  {
    "term": "検討",
    "termReading": "けんとう",
    "termTranslation": "consideration, inspection",
    "ex": "今日中にこの問題を検討してください。",
    "exReading": "きょう じゅう に この もんだい を けんとう して ください",
    "exTranslation": "Please consider this problem by the end of the day.",
    "category": "Verbal Noun"
  },
  {
    "term": "塗る",
    "termReading": "ぬる",
    "termTranslation": "spread on, paint",
    "ex": "壁にペンキを塗っています。",
    "exReading": "かべ に ぺんき を ぬって います",
    "exTranslation": "I'm painting the wall.",
    "category": "Verb"
  },
  {
    "term": "トイレットペーパー",
    "termReading": "トイレットペーパー",
    "termTranslation": "toilet paper",
    "ex": "トイレットペーパーを交換しました。",
    "exReading": "といれっとぺーぱー を こうかん しました",
    "exTranslation": "I changed the toilet paper.",
    "category": "Noun"
  },
  {
    "term": "付く",
    "termReading": "つく",
    "termTranslation": "attach itself to, be connected with, come in contact with",
    "ex": "靴に泥が付いています。",
    "exReading": "くつ に どろ が ついて います",
    "exTranslation": "I have mud on my shoes.",
    "category": "Verb"
  },
  {
    "term": "付ける",
    "termReading": "つける",
    "termTranslation": "attach, set, apply",
    "ex": "おまけを付けました。",
    "exReading": "おまけ を つけました",
    "exTranslation": "I added a freebie.",
    "category": "Verb"
  },
  {
    "term": "受け付ける",
    "termReading": "うけつける",
    "termTranslation": "receive, accept",
    "ex": "郵便物は5時まで受け付けています。",
    "exReading": "ゆうびんぶつ は ごじ まで うけつけて います",
    "exTranslation": "We accept mail until five o'clock.",
    "category": "Verb"
  },
  {
    "term": "片付ける",
    "termReading": "かたづける",
    "termTranslation": "put in order, dispose of, do away with",
    "ex": "早く部屋を片付けなさい。",
    "exReading": "はやく へや を かたづけなさい",
    "exTranslation": "Hurry up and clean up your room.",
    "category": "Verb"
  },
  {
    "term": "どなた",
    "termReading": "どなた",
    "termTranslation": "who",
    "ex": "失礼ですが、どなたですか。",
    "exReading": "しつれい です が 、 どなた です か 。",
    "exTranslation": "Excuse me, but who are you?",
    "category": "Noun"
  },
  {
    "term": "受付",
    "termReading": "うけつけ",
    "termTranslation": "receptionist, information office",
    "ex": "受付は9時からです。",
    "exReading": "うけつけ は くじ から です",
    "exTranslation": "Registration is from nine o'clock.",
    "category": "Noun"
  },
  {
    "term": "近付く",
    "termReading": "ちかづく",
    "termTranslation": "approach, get acquainted",
    "ex": "女性が近付いてきました。",
    "exReading": "じょせい が ちかづいて きました",
    "exTranslation": "A woman approached me.",
    "category": "Verb"
  },
  {
    "term": "片付く",
    "termReading": "かたづく",
    "termTranslation": "be put in order, be settled",
    "ex": "仕事が大分片付いた。",
    "exReading": "しごと が だいぶ かたづいた",
    "exTranslation": "I've completed a lot of my work.",
    "category": "Verb"
  },
  {
    "term": "気を付ける",
    "termReading": "きをつける",
    "termTranslation": "take care of, pay attention to",
    "ex": "体に気を付けてください。",
    "exReading": "からだ に き を つけて ください",
    "exTranslation": "Please take care of yourself.",
    "category": "Verb"
  },
  {
    "term": "ハイキング",
    "termReading": "ハイキング",
    "termTranslation": "hiking",
    "ex": "昨日は友人とハイキングに行きました。",
    "exReading": "きのう は ゆうじん と はいきんぐ に いきました",
    "exTranslation": "I went hiking with a friend yesterday.",
    "category": "Verbal Noun"
  },
  {
    "term": "貼る",
    "termReading": "はる",
    "termTranslation": "stick, paste",
    "ex": "机にシールを貼った。",
    "exReading": "つくえ に しーる を はった",
    "exTranslation": "I stuck a sticker on the desk.",
    "category": "Verb"
  },
  {
    "term": "記念日",
    "termReading": "きねんび",
    "termTranslation": "memorial day, anniversary",
    "ex": "今日は両親の結婚記念日です。",
    "exReading": "きょう は りょうしん の けっこん きねんび です",
    "exTranslation": "Today's my parents' wedding anniversary.",
    "category": "Noun"
  },
  {
    "term": "残念",
    "termReading": "ざんねん",
    "termTranslation": "regretful, disappointing",
    "ex": "その試合は残念な結果になった。",
    "exReading": "その しあい は ざんねん な けっか に なった",
    "exTranslation": "The result of the game was disappointing.",
    "category": "Adjectival Noun"
  },
  {
    "term": "例えば",
    "termReading": "たとえば",
    "termTranslation": "for example",
    "ex": "例えば、このソフトで日本語を勉強することができます。",
    "exReading": "たとえば この そふと で にほんご を べんきょう する こと が できます",
    "exTranslation": "You can use this software to study Japanese, for example.",
    "category": "Adverb"
  },
  {
    "term": "ハム",
    "termReading": "ハム",
    "termTranslation": "ham",
    "ex": "ハムサンドをください。",
    "exReading": "はむ さんど を ください",
    "exTranslation": "Could I have a ham sandwich, please.",
    "category": "Noun"
  },
  {
    "term": "例文",
    "termReading": "れいぶん",
    "termTranslation": "illustrative sentence, example",
    "ex": "例文を3つ作ってください。",
    "exReading": "れいぶん を みっつ つくって ください",
    "exTranslation": "Please make three example sentences.",
    "category": "Noun"
  },
  {
    "term": "例",
    "termReading": "れい",
    "termTranslation": "example, precedent",
    "ex": "一つ例をあげてください。",
    "exReading": "ひとつ れい を あげて ください",
    "exTranslation": "Please give an example.",
    "category": "Noun"
  },
  {
    "term": "余る",
    "termReading": "あまる",
    "termTranslation": "remain over, be in excess",
    "ex": "夕食の料理がたくさん余った。",
    "exReading": "ゆうしょく の りょうり が たくさん あまった",
    "exTranslation": "A lot of food from dinner was left over.",
    "category": "Verb"
  },
  {
    "term": "除く",
    "termReading": "のぞく",
    "termTranslation": "rid of, exclude",
    "ex": "部長を除く全員が土曜日も働いた。",
    "exReading": "ぶちょう を のぞく ぜんいん が どようび も はたらいた",
    "exTranslation": "Everyone except the director worked on Saturday.",
    "category": "Verb"
  },
  {
    "term": "ボーイフレンド",
    "termReading": "ボーイフレンド",
    "termTranslation": "boyfriend",
    "ex": "私のボーイフレンドはハンサムな方でした。",
    "exReading": "わたし の ぼーいふれんど は はんさむな かた でした",
    "exTranslation": "My boyfriend was a handsome man.",
    "category": "Noun"
  },
  {
    "term": "削る",
    "termReading": "けずる",
    "termTranslation": "grind down, trim, scale back",
    "ex": "彼は家族のために仕事の時間を削った。",
    "exReading": "かれ は かぞく の ため に しごと の じかん を けずった",
    "exTranslation": "He reduced his working hours for his family's sake.",
    "category": "Verb"
  },
  {
    "term": "遅刻",
    "termReading": "ちこく",
    "termTranslation": "tardiness, lateness",
    "ex": "遅刻しないでください。",
    "exReading": "ちこく しない で ください",
    "exTranslation": "Please don't be late.",
    "category": "Verbal Noun"
  },
  {
    "term": "緩い",
    "termReading": "ゆるい",
    "termTranslation": "slack, loose",
    "ex": "このズボンは緩いです。",
    "exReading": "この ずぼん は ゆるい です",
    "exTranslation": "These trousers are loose.",
    "category": "Adjective"
  },
  {
    "term": "苦しい",
    "termReading": "くるしい",
    "termTranslation": "painful, difficult",
    "ex": "食べ過ぎておなかが苦しい。",
    "exReading": "たべすぎて おなか が くるしい",
    "exTranslation": "I have eaten too much and my stomach hurts.",
    "category": "Adjective"
  },
  {
    "term": "アイロン",
    "termReading": "アイロン",
    "termTranslation": "iron",
    "ex": "彼女はシャツにアイロンをかけた。",
    "exReading": "かのじょ は しゃつ に あいろん を かけた",
    "exTranslation": "She ironed the shirt.",
    "category": "Noun"
  },
  {
    "term": "苦い",
    "termReading": "にがい",
    "termTranslation": "bitter, sour",
    "ex": "私は苦いコーヒーが好きです。",
    "exReading": "わたし は にがい こーひー が すき です",
    "exTranslation": "I like bitter coffee.",
    "category": "Adjective"
  },
  {
    "term": "苦手",
    "termReading": "にがて",
    "termTranslation": "person that is hard to deal with, weak point",
    "ex": "私は料理が苦手です。",
    "exReading": "わたし は りょうり が にがて です",
    "exTranslation": "I'm not good at cooking.",
    "category": "Adjectival Noun"
  },
  {
    "term": "困る",
    "termReading": "こまる",
    "termTranslation": "be at a loss, in a jam, in trouble",
    "ex": "ケータイをなくして困っています。",
    "exReading": "けーたい を なくして こまって います",
    "exTranslation": "I'm in a jam because I lost my cell phone.",
    "category": "Verb"
  },
  {
    "term": "貧乏",
    "termReading": "びんぼう",
    "termTranslation": "poverty, destitution",
    "ex": "彼は昔は貧乏だった。",
    "exReading": "かれ は むかし は びんぼう だった",
    "exTranslation": "He used to be poor.",
    "category": "Adjectival Noun"
  },
  {
    "term": "カレーライス",
    "termReading": "カレーライス",
    "termTranslation": "curried rice",
    "ex": "子供はカレーライスが好きです。",
    "exReading": "こども は かれーらいす が すき です",
    "exTranslation": "Children like curry and rice.",
    "category": "Noun"
  },
  {
    "term": "不幸",
    "termReading": "ふこう",
    "termTranslation": "unhappiness, misfortune, bereavement",
    "ex": "彼女の家族に不幸があった。",
    "exReading": "かのじょ の かぞく に ふこう が あった",
    "exTranslation": "A misfortune befell her family.",
    "category": "Adjectival Noun"
  },
  {
    "term": "幸せ",
    "termReading": "しあわせ",
    "termTranslation": "happiness, good fortune",
    "ex": "良い友達がいて私は幸せだ。",
    "exReading": "いい ともだち が いて わたし は しあわせ だ",
    "exTranslation": "I'm happy to have good friends.",
    "category": "Adjectival Noun"
  },
  {
    "term": "塩",
    "termReading": "しお",
    "termTranslation": "salt",
    "ex": "もうちょっと塩を入れて。",
    "exReading": "もう ちょっと しお を いれて",
    "exTranslation": "Put in a little more salt.",
    "category": "Noun"
  },
  {
    "term": "塩辛い",
    "termReading": "しおからい",
    "termTranslation": "salty",
    "ex": "海の水は塩辛い。",
    "exReading": "うみ の みず は しおからい",
    "exTranslation": "Sea water is salty.",
    "category": "Adjective"
  },
  {
    "term": "キャッシュカード",
    "termReading": "キャッシュカード",
    "termTranslation": "cash card",
    "ex": "キャッシュカードでお金を下ろした。",
    "exReading": "きゃっしゅかーど で おかね を おろした",
    "exTranslation": "I took out some money with my cash card.",
    "category": "Noun"
  },
  {
    "term": "砂糖",
    "termReading": "さとう",
    "termTranslation": "sugar",
    "ex": "コーヒーに砂糖は入れますか。",
    "exReading": "こーひー に さとう は いれます か",
    "exTranslation": "Do you take sugar in your coffee?",
    "category": "Noun"
  },
  {
    "term": "規模",
    "termReading": "きぼ",
    "termTranslation": "scale, scope",
    "ex": "この動物園は日本一の規模です。",
    "exReading": "この どうぶつえん は にっぽんいち の きぼ です",
    "exTranslation": "This is the largest zoo in Japan.",
    "category": "Noun"
  },
  {
    "term": "農業",
    "termReading": "のうぎょう",
    "termTranslation": "agriculture",
    "ex": "私は農業を勉強しています。",
    "exReading": "わたし は のうぎょう を べんきょう して います",
    "exTranslation": "I'm studying agriculture.",
    "category": "Noun"
  },
  {
    "term": "濃い",
    "termReading": "こい",
    "termTranslation": "thick, concentrated, dark-colored",
    "ex": "私は濃い味が好きだ。",
    "exReading": "わたし は こい あじ が すき だ",
    "exTranslation": "I like strong tastes.",
    "category": "Adjective"
  },
  {
    "term": "イヤリング",
    "termReading": "イヤリング",
    "termTranslation": "earrings",
    "ex": "彼女はすてきなイヤリングをしている。",
    "exReading": "かのじょ は すてき な いやりんぐ を して いる",
    "exTranslation": "She's wearing wonderful earrings.",
    "category": "Noun"
  },
  {
    "term": "薄い",
    "termReading": "うすい",
    "termTranslation": "thin, weak, pale",
    "ex": "この電子辞書はとても薄い。",
    "exReading": "この でんし じしょ は とても うすい",
    "exTranslation": "This electronic dictionary is very thin.",
    "category": "Adjective"
  },
  {
    "term": "厚い",
    "termReading": "あつい",
    "termTranslation": "thick, bulky",
    "ex": "その辞書はとても厚い。",
    "exReading": "その じしょ は とても あつい",
    "exTranslation": "That dictionary is very thick.",
    "category": "Adjective"
  },
  {
    "term": "厚さ",
    "termReading": "あつさ",
    "termTranslation": "thickness",
    "ex": "私は板の厚さを測った。",
    "exReading": "わたし は いた の あつさ を はかった",
    "exTranslation": "I measured the thickness of the board.",
    "category": "Noun"
  },
  {
    "term": "迫る",
    "termReading": "せまる",
    "termTranslation": "press, close in on, be imminent",
    "ex": "締め切りが迫っています。",
    "exReading": "しめきり が せまって います",
    "exTranslation": "The deadline is approaching.",
    "category": "Verb"
  },
  {
    "term": "ガールフレンド",
    "termReading": "ガールフレンド",
    "termTranslation": "girlfriend",
    "ex": "昔は彼女のことをガールフレンドと言っていました。",
    "exReading": "むかし は かのじょ の こと を がーるふれんど と いって いました",
    "exTranslation": "We used to call girlfriends \"garufurendo.\"",
    "category": "Noun"
  },
  {
    "term": "伸びる",
    "termReading": "のびる",
    "termTranslation": "stretch, grow",
    "ex": "髪がだいぶ伸びたね。",
    "exReading": "かみ が だいぶ のびた ね",
    "exTranslation": "Your hair has grown a lot, hasn't it?",
    "category": "Verb"
  },
  {
    "term": "引っ越す",
    "termReading": "ひっこす",
    "termTranslation": "move, change quarters",
    "ex": "来月、大阪に引っ越します。",
    "exReading": "らいげつ おおさか に ひっこします",
    "exTranslation": "I'll move to Osaka next month.",
    "category": "Verb"
  },
  {
    "term": "越える",
    "termReading": "こえる",
    "termTranslation": "go over, go across, go beyond",
    "ex": "私たちは高い山を越えました。",
    "exReading": "わたしたち は たかい やま を こえました",
    "exTranslation": "We went over a tall mountain.",
    "category": "Verb"
  },
  {
    "term": "引っ越し",
    "termReading": "ひっこし",
    "termTranslation": "moving",
    "ex": "去年、引っ越ししました。",
    "exReading": "きょねん ひっこし しました",
    "exTranslation": "I moved last year.",
    "category": "Verbal Noun"
  },
  {
    "term": "カセットテープ",
    "termReading": "カセットテープ",
    "termTranslation": "cassette tape",
    "ex": "昔はカセットテープを使っていました。",
    "exReading": "むかし は かせっとてーぷ を つかっていました 。",
    "exTranslation": "I used to use cassette tapes along time ago.",
    "category": "Noun"
  },
  {
    "term": "追い越す",
    "termReading": "おいこす",
    "termTranslation": "outrun, overtake",
    "ex": "大きなトラックが私たちを追い越した。",
    "exReading": "おおき な とらっく が わたしたち を おいこした",
    "exTranslation": "A big truck overtook us.",
    "category": "Verb"
  },
  {
    "term": "上昇",
    "termReading": "じょうしょう",
    "termTranslation": "ascension, rising",
    "ex": "地球の気温は上昇している。",
    "exReading": "ちきゅう の きおん は じょうしょう して いる",
    "exTranslation": "The temperature of the earth is rising.",
    "category": "Verbal Noun"
  },
  {
    "term": "改札口",
    "termReading": "かいさつぐち",
    "termTranslation": "ticket barrier, wicket",
    "ex": "改札口で会いましょう。",
    "exReading": "かいさつぐち で あいましょう",
    "exTranslation": "Let's meet at the ticket gate.",
    "category": "Noun"
  },
  {
    "term": "失礼",
    "termReading": "しつれい",
    "termTranslation": "impoliteness, bad manners, I beg your parden/Goodbye",
    "ex": "ではそろそろ失礼します。",
    "exReading": "では そろそろ しつれい します",
    "exTranslation": "Well, it's about time for me to go.",
    "category": "Verbal Noun"
  },
  {
    "term": "かゆい",
    "termReading": "かゆい",
    "termTranslation": "itchy",
    "ex": "背中がかゆいです。",
    "exReading": "せなか が かゆい です",
    "exTranslation": "My back itches.",
    "category": "Adjective"
  },
  {
    "term": "お礼",
    "termReading": "おれい",
    "termTranslation": "thanks, reward, return present",
    "ex": "彼女にお礼の手紙を書きました。",
    "exReading": "かのじょ に おれい の てがみ を かきました",
    "exTranslation": "I wrote her a thank you letter.",
    "category": "Verbal Noun"
  },
  {
    "term": "謝る",
    "termReading": "あやまる",
    "termTranslation": "apologize",
    "ex": "彼は直ぐに謝りました。",
    "exReading": "かれ は すぐ に あやまりました",
    "exTranslation": "He apologized at once.",
    "category": "Verb"
  },
  {
    "term": "注射",
    "termReading": "ちゅうしゃ",
    "termTranslation": "injection, shot",
    "ex": "彼は注射があまり好きではありません。",
    "exReading": "かれ は ちゅうしゃ が あまり すき で は ありません",
    "exTranslation": "He's not too fond of getting shots.",
    "category": "Verbal Noun"
  },
  {
    "term": "程度",
    "termReading": "ていど",
    "termTranslation": "degree, extent",
    "ex": "この程度の怪我なら大丈夫です。",
    "exReading": "この ていど の けが なら だいじょうぶ です",
    "exTranslation": "With an injury of this small extent, she's going to be fine.",
    "category": "Noun"
  },
  {
    "term": "ぐらぐら",
    "termReading": "ぐらぐら",
    "termTranslation": "wobble",
    "ex": "地震で家がぐらぐらと揺れた。",
    "exReading": "じしん で いえ が ぐらぐら と ゆれた",
    "exTranslation": "The house shook because of the earthquake.",
    "category": "Adverb"
  },
  {
    "term": "誘う",
    "termReading": "さそう",
    "termTranslation": "invite, ask out",
    "ex": "彼女をデートに誘った。",
    "exReading": "かのじょ を でーと に さそった",
    "exTranslation": "I asked her out on a date.",
    "category": "Verb"
  },
  {
    "term": "導入",
    "termReading": "どうにゅう",
    "termTranslation": "introduction",
    "ex": "会社で新しいシステムを導入した。",
    "exReading": "かいしゃ で あたらしい しすてむ を どうにゅう した",
    "exTranslation": "A new system was introduced in the company.",
    "category": "Verbal Noun"
  },
  {
    "term": "努力",
    "termReading": "どりょく",
    "termTranslation": "endeavor, effort",
    "ex": "もっと努力しよう。",
    "exReading": "もっと どりょく しよう",
    "exTranslation": "Let's try harder.",
    "category": "Verbal Noun"
  },
  {
    "term": "怒る",
    "termReading": "おこる",
    "termTranslation": "get angry, scold",
    "ex": "彼女が嘘をついたので、彼は怒った。",
    "exReading": "かのじょ が うそ を ついた の で かれ は おこった",
    "exTranslation": "He was angry because she lied to him.",
    "category": "Verb"
  },
  {
    "term": "ジャム",
    "termReading": "ジャム",
    "termTranslation": "jam",
    "ex": "このイチゴでジャムを作りましょう。",
    "exReading": "この いちご で じゃむ を つくりましょう",
    "exTranslation": "Let's make jam from these strawberries.",
    "category": "Noun"
  },
  {
    "term": "独身",
    "termReading": "どくしん",
    "termTranslation": "single life, celibacy",
    "ex": "私の兄はまだ独身です。",
    "exReading": "わたし の あに は まだ どくしん です",
    "exTranslation": "My big brother's still single.",
    "category": "Noun"
  },
  {
    "term": "占める",
    "termReading": "しめる",
    "termTranslation": "occupy, hold",
    "ex": "私に届くメールのうち、迷惑メールが7割を占めている。",
    "exReading": "わたし に とどく めーる の うち めいわく めーる が ななわり を しめて いる",
    "exTranslation": "About 70 percent of emails sent to me are junk.",
    "category": "Verb"
  },
  {
    "term": "処理",
    "termReading": "しょり",
    "termTranslation": "management, process",
    "ex": "事務的な処理に1週間かかります。",
    "exReading": "じむてき な しょり に いっしゅうかん かかります",
    "exTranslation": "The administrative processing takes one week.",
    "category": "Verbal Noun"
  },
  {
    "term": "紹介",
    "termReading": "しょうかい",
    "termTranslation": "introduction, presentation",
    "ex": "両親に彼女を紹介した。",
    "exReading": "りょうしん に かのじょ を しょうかい した",
    "exTranslation": "I introduced my girlfriend to my parents.",
    "category": "Verbal Noun"
  },
  {
    "term": "スリッパ",
    "termReading": "スリッパ",
    "termTranslation": "slippers",
    "ex": "スリッパをはいてください。",
    "exReading": "すりっぱ を はいて ください",
    "exTranslation": "Wear slippers please.",
    "category": "Noun"
  },
  {
    "term": "招く",
    "termReading": "まねく",
    "termTranslation": "invite, beckon, engage",
    "ex": "両親を食事に招いた。",
    "exReading": "りょうしん を しょくじ に まねいた",
    "exTranslation": "I invited my parents to a meal.",
    "category": "Verb"
  },
  {
    "term": "招待",
    "termReading": "しょうたい",
    "termTranslation": "invitation",
    "ex": "高校の時の先生を結婚式に招待した。",
    "exReading": "こうこう の とき の せんせい を けっこんしき に しょうたい した",
    "exTranslation": "I invited my high school teacher to the wedding.",
    "category": "Verbal Noun"
  },
  {
    "term": "夫婦",
    "termReading": "ふうふ",
    "termTranslation": "husband and wife, married couple",
    "ex": "その夫婦はとても仲がいい。",
    "exReading": "その ふうふ は とても なか が いい",
    "exTranslation": "That couple gets on very well.",
    "category": "Noun"
  },
  {
    "term": "奥",
    "termReading": "おく",
    "termTranslation": "inner part, the back, inner chamber",
    "ex": "はさみは机の奥にあった。",
    "exReading": "はさみ は つくえ の おく に あった",
    "exTranslation": "The scissors were at the back of the desk.",
    "category": "Noun"
  },
  {
    "term": "トランプ",
    "termReading": "トランプ",
    "termTranslation": "(a pack of) cards, (playing) cards",
    "ex": "友達とトランプをして遊びました。",
    "exReading": "ともだち と とらんぷ を して あそびました",
    "exTranslation": "I played cards with a friend.",
    "category": "Verbal Noun"
  },
  {
    "term": "奥さん",
    "termReading": "おくさん",
    "termTranslation": "married lady, your wife",
    "ex": "彼の奥さんはきれいな方です。",
    "exReading": "かれ の おくさん は きれい な かた です",
    "exTranslation": "His wife is beautiful.",
    "category": "Noun"
  },
  {
    "term": "皆さん",
    "termReading": "みなさん",
    "termTranslation": "everybody, ladies and gentlemen",
    "ex": "皆さんにお話があります。",
    "exReading": "みなさん に おはなし が あります",
    "exTranslation": "I have something to say to everybody.",
    "category": "Noun"
  },
  {
    "term": "皆様",
    "termReading": "みなさま",
    "termTranslation": "everyone, ladies and gentlemen",
    "ex": "皆様、こんにちは。",
    "exReading": "みなさま こんにちは",
    "exTranslation": "Hello everyone.",
    "category": "Noun"
  },
  {
    "term": "誰か",
    "termReading": "だれか",
    "termTranslation": "someone",
    "ex": "誰かに聞いてみてください。",
    "exReading": "だれか に きいて みて ください",
    "exTranslation": "Please ask someone.",
    "category": "Noun"
  },
  {
    "term": "よろしい",
    "termReading": "よろしい",
    "termTranslation": "all right",
    "ex": "レポートはこれでよろしいですか。",
    "exReading": "れぽーと は これ で よろしい です か",
    "exTranslation": "Is this all right as a report?",
    "category": "Adjective"
  },
  {
    "term": "国籍",
    "termReading": "こくせき",
    "termTranslation": "nationality, citizenship",
    "ex": "私は日本国籍です。",
    "exReading": "わたし は にほん こくせき です",
    "exTranslation": "I'm a Japanese citizen.",
    "category": "Noun"
  },
  {
    "term": "愛",
    "termReading": "あい",
    "termTranslation": "love",
    "ex": "彼女は愛をこめて手紙を書いた。",
    "exReading": "かのじょ は あい を こめて てがみ を かいた",
    "exTranslation": "She wrote a loving letter.",
    "category": "Verbal Noun"
  },
  {
    "term": "可愛い",
    "termReading": "かわいい",
    "termTranslation": "cute, charming, sweet",
    "ex": "彼女の赤ちゃんは可愛いです。",
    "exReading": "かのじょ の あかちゃん は かわいい です",
    "exTranslation": "Her baby is cute.",
    "category": "Adjective"
  },
  {
    "term": "恋人",
    "termReading": "こいびと",
    "termTranslation": "lover, sweetheart",
    "ex": "彼は恋人を失った。",
    "exReading": "かれ は こいびと を うしなった",
    "exTranslation": "He lost his girlfriend.",
    "category": "Noun"
  },
  {
    "term": "ラッシュアワー",
    "termReading": "ラッシュアワー",
    "termTranslation": "rush hour",
    "ex": "私はラッシュアワーの電車が嫌いです。",
    "exReading": "わたし は らっしゅあわー の でんしゃ が きらい です",
    "exTranslation": "I hate rush hour trains.",
    "category": "Noun"
  },
  {
    "term": "誕生日",
    "termReading": "たんじょうび",
    "termTranslation": "birthday",
    "ex": "彼女の誕生日は7月16日です。",
    "exReading": "かのじょ の たんじょうび は しちがつ じゅうろくにち です",
    "exTranslation": "Her birthday is July 16th.",
    "category": "Noun"
  },
  {
    "term": "祝日",
    "termReading": "しゅくじつ",
    "termTranslation": "holiday, festival day",
    "ex": "5月3日は祝日だ。",
    "exReading": "ごがつ みっか は しゅくじつ だ",
    "exTranslation": "May 3rd is a holiday.",
    "category": "Noun"
  },
  {
    "term": "お祝い",
    "termReading": "おいわい",
    "termTranslation": "celebrate",
    "ex": "彼の就職のお祝いをしよう。",
    "exReading": "かれ の しゅうしょく の おいわい を しよう",
    "exTranslation": "Let's celebrate his new employment.",
    "category": "Verbal Noun"
  },
  {
    "term": "夢",
    "termReading": "ゆめ",
    "termTranslation": "dream",
    "ex": "昨夜恐ろしい夢を見た。",
    "exReading": "ゆうべ おそろしい ゆめ を みた",
    "exTranslation": "I had a terrible dream last night.",
    "category": "Noun"
  },
  {
    "term": "カップ",
    "termReading": "カップ",
    "termTranslation": "cup",
    "ex": "水を1カップ入れてください。",
    "exReading": "みず を いちかっぷ いれて ください",
    "exTranslation": "Please put in one cup of water.",
    "category": "Noun"
  },
  {
    "term": "泣く",
    "termReading": "なく",
    "termTranslation": "cry, weep",
    "ex": "妹はすぐに泣く。",
    "exReading": "いもうと は すぐ に なく",
    "exTranslation": "My little sister cries easily.",
    "category": "Verb"
  },
  {
    "term": "涙",
    "termReading": "なみだ",
    "termTranslation": "tear",
    "ex": "彼女の目から涙がこぼれた。",
    "exReading": "かのじょ の め から なみだ が こぼれた",
    "exTranslation": "Tears rolled down from her eyes.",
    "category": "Noun"
  },
  {
    "term": "喜ぶ",
    "termReading": "よろこぶ",
    "termTranslation": "be happy, be delighted",
    "ex": "彼女はとても喜びました。",
    "exReading": "かのじょ は とても よろこびました",
    "exTranslation": "She was very pleased.",
    "category": "Verb"
  },
  {
    "term": "恥ずかしい",
    "termReading": "はずかしい",
    "termTranslation": "shy, ashamed, disgraceful",
    "ex": "とても恥ずかしかった。",
    "exReading": "とても はずかしかった",
    "exTranslation": "It was very embarrassing.",
    "category": "Adjective"
  },
  {
    "term": "スケート",
    "termReading": "スケート",
    "termTranslation": "skate, skates",
    "ex": "湖でスケートをした。",
    "exReading": "みずうみ で すけーと を した",
    "exTranslation": "We went skating on the lake.",
    "category": "Noun"
  },
  {
    "term": "弁当",
    "termReading": "べんとう",
    "termTranslation": "box lunch, picnic lunch",
    "ex": "今日は弁当を持ってきました。",
    "exReading": "きょう は べんとう を もって きました",
    "exTranslation": "I brought a lunch box today.",
    "category": "Noun"
  },
  {
    "term": "看護婦",
    "termReading": "かんごふ",
    "termTranslation": "female nurse",
    "ex": "母は看護婦です。",
    "exReading": "はは は かんごふ です",
    "exTranslation": "My mother's a nurse.",
    "category": "Noun"
  },
  {
    "term": "患者",
    "termReading": "かんじゃ",
    "termTranslation": "patient",
    "ex": "患者は眠っています。",
    "exReading": "かんじゃ は ねむって います",
    "exTranslation": "The patient is sleeping.",
    "category": "Noun"
  },
  {
    "term": "述べる",
    "termReading": "のべる",
    "termTranslation": "state orally, expound, mention",
    "ex": "上司が意見を述べた。",
    "exReading": "じょうし が いけん を のべた",
    "exTranslation": "Our boss gave us his opinion.",
    "category": "Verb"
  },
  {
    "term": "たくさん",
    "termReading": "たくさん",
    "termTranslation": "in large quantity, plenty",
    "ex": "昨日はたくさん泳ぎました。",
    "exReading": "きのう は たくさん およぎました",
    "exTranslation": "I swam a lot yesterday.",
    "category": "Adverb"
  },
  {
    "term": "訴える",
    "termReading": "うったえる",
    "termTranslation": "sue",
    "ex": "彼女は会社を訴えた。",
    "exReading": "かのじょ は かいしゃ を うったえた",
    "exTranslation": "She sued the company.",
    "category": "Verb"
  },
  {
    "term": "迷う",
    "termReading": "まよう",
    "termTranslation": "be perplexed, hesitate, get lost",
    "ex": "道に迷いました。",
    "exReading": "みち に まよいました",
    "exTranslation": "I lost my way.",
    "category": "Verb"
  },
  {
    "term": "迷惑",
    "termReading": "めいわく",
    "termTranslation": "trouble, annoyance",
    "ex": "人に迷惑をかけてはいけません。",
    "exReading": "ひと に めいわく を かけて は いけません",
    "exTranslation": "You shouldn't cause trouble for other people.",
    "category": "Verbal Noun"
  },
  {
    "term": "地域",
    "termReading": "ちいき",
    "termTranslation": "region, area",
    "ex": "この地域は雨が多い。",
    "exReading": "この ちいき は あめ が おおい",
    "exTranslation": "There's a lot of rain in this region.",
    "category": "Noun"
  },
  {
    "term": "ピクニック",
    "termReading": "ピクニック",
    "termTranslation": "picnic",
    "ex": "今日はピクニックに行きましょう。",
    "exReading": "きょう は ぴくにっく に いきましょう",
    "exTranslation": "Let's go on a picnic today.",
    "category": "Noun"
  },
  {
    "term": "政権",
    "termReading": "せいけん",
    "termTranslation": "political power",
    "ex": "政権が交代した。",
    "exReading": "せいけん が こうたい した",
    "exTranslation": "There was a regime change.",
    "category": "Noun"
  },
  {
    "term": "贈る",
    "termReading": "おくる",
    "termTranslation": "offer, give",
    "ex": "母に花を贈った。",
    "exReading": "はは に はな を おくった",
    "exTranslation": "I sent my mother some flowers.",
    "category": "Verb"
  },
  {
    "term": "贈り物",
    "termReading": "おくりもの",
    "termTranslation": "present, gift",
    "ex": "すてきな贈り物をどうもありがとう。",
    "exReading": "すてき な おくりもの を どうも ありがとう",
    "exTranslation": "Thank you for the wonderful present.",
    "category": "Noun"
  },
  {
    "term": "与える",
    "termReading": "あたえる",
    "termTranslation": "give",
    "ex": "そのニュースは彼に大きなショックを与えた。",
    "exReading": "その にゅーす は かれ に おおき な しょっく を あたえた",
    "exTranslation": "The news gave him a great shock.",
    "category": "Verb"
  },
  {
    "term": "あちら",
    "termReading": "あちら",
    "termTranslation": "way over there",
    "ex": "あちらに行ってみよう。",
    "exReading": "あちら に いって みよう",
    "exTranslation": "Let's go that way.",
    "category": "Noun"
  },
  {
    "term": "貯金",
    "termReading": "ちょきん",
    "termTranslation": "savings, deposit",
    "ex": "貯金は十分にあります。",
    "exReading": "ちょきん は じゅうぶん に あります",
    "exTranslation": "I have enough savings.",
    "category": "Verbal Noun"
  },
  {
    "term": "預ける",
    "termReading": "あずける",
    "termTranslation": "deposit, entrust",
    "ex": "鍵を彼に預けた。",
    "exReading": "かぎ を かれ に あずけた",
    "exTranslation": "I gave him the key to look after.",
    "category": "Verb"
  },
  {
    "term": "預かる",
    "termReading": "あずかる",
    "termTranslation": "receive on deposit, take charge of",
    "ex": "荷物を預かってください。",
    "exReading": "にもつ を あずかって ください",
    "exTranslation": "Please take care of the luggage.",
    "category": "Verb"
  },
  {
    "term": "得意",
    "termReading": "とくい",
    "termTranslation": "one's forte, pride, customer",
    "ex": "彼は歌が得意です。",
    "exReading": "かれ は うた が とくい です",
    "exTranslation": "He's good at singing.",
    "category": "Adjectival Noun"
  },
  {
    "term": "どきどき",
    "termReading": "どきどき",
    "termTranslation": "feel excited, feel nervous",
    "ex": "彼女に会うとどきどきします。",
    "exReading": "かのじょ に あう と どきどき します",
    "exTranslation": "I feel nervous when I meet her.",
    "category": "Adverb"
  },
  {
    "term": "燃える",
    "termReading": "もえる",
    "termTranslation": "burn, undergo combustion",
    "ex": "山が燃えています。",
    "exReading": "やま が もえて います",
    "exTranslation": "The mountain's on fire.",
    "category": "Verb"
  },
  {
    "term": "焼ける",
    "termReading": "やける",
    "termTranslation": "burn, be scorched, be baked",
    "ex": "肉が焼けました。",
    "exReading": "にく が やけました",
    "exTranslation": "The meat is cooked.",
    "category": "Verb"
  },
  {
    "term": "すき焼き",
    "termReading": "すきやき",
    "termTranslation": "sukiyaki",
    "ex": "昨日の夜はすき焼きを食べた。",
    "exReading": "きのう の よる は すきやき を たべた",
    "exTranslation": "I had sukiyaki last night.",
    "category": "Noun"
  },
  {
    "term": "焼く",
    "termReading": "やく",
    "termTranslation": "burn, bake, be envious",
    "ex": "今、魚を焼いています。",
    "exReading": "いま さかな を やいて います",
    "exTranslation": "I'm grilling fish now.",
    "category": "Verb"
  },
  {
    "term": "まあまあ",
    "termReading": "まあまあ",
    "termTranslation": "OK, not bad, average",
    "ex": "彼の成績はまあまあです。",
    "exReading": "かれ の せいせき は まあまあ です",
    "exTranslation": "His results are so-so.",
    "category": "Adjective"
  },
  {
    "term": "乾く",
    "termReading": "かわく",
    "termTranslation": "dry, run dry",
    "ex": "夏は洗濯物がすぐ乾く。",
    "exReading": "なつ は せんたくもの が すぐ かわく",
    "exTranslation": "Laundry dries quickly in summer.",
    "category": "Verb"
  },
  {
    "term": "乾杯",
    "termReading": "かんぱい",
    "termTranslation": "a toast",
    "ex": "お二人の未来に乾杯しましょう。",
    "exReading": "おふたり の みらい に かんぱい しましょう",
    "exTranslation": "Let's drink a toast to the couple's future.",
    "category": "Verbal Noun"
  },
  {
    "term": "乾かす",
    "termReading": "かわかす",
    "termTranslation": "dry",
    "ex": "ぬれた服を乾かした。",
    "exReading": "ぬれた ふく を かわかした",
    "exTranslation": "I dried the wet clothes.",
    "category": "Verb"
  },
  {
    "term": "新幹線",
    "termReading": "しんかんせん",
    "termTranslation": "Shinkansen, Bullet Train",
    "ex": "新幹線で京都に行きました。",
    "exReading": "しんかんせん で きょうと に いきました",
    "exTranslation": "I went to Kyoto by bullet train.",
    "category": "Noun"
  },
  {
    "term": "マフラー",
    "termReading": "マフラー",
    "termTranslation": "scarf, muffler",
    "ex": "彼女は首にマフラーをまいていた。",
    "exReading": "かのじょ は くび に まふらー を まいて いた",
    "exTranslation": "She was wearing a scarf around her neck.",
    "category": "Noun"
  },
  {
    "term": "素晴らしい",
    "termReading": "すばらしい",
    "termTranslation": "splendid, excellent",
    "ex": "素晴らしい景色ですね。",
    "exReading": "すばらしい けしき です ね",
    "exTranslation": "This is wonderful scenery.",
    "category": "Adjective"
  },
  {
    "term": "海岸",
    "termReading": "かいがん",
    "termTranslation": "seashore, coast",
    "ex": "海岸を散歩しましょう。",
    "exReading": "かいがん を さんぽ しましょう",
    "exTranslation": "Let's take a walk along the coast.",
    "category": "Noun"
  },
  {
    "term": "家庭",
    "termReading": "かてい",
    "termTranslation": "home, family",
    "ex": "彼は家庭を大切にしている。",
    "exReading": "かれ は かてい を たいせつ に して いる",
    "exTranslation": "He values his family.",
    "category": "Noun"
  },
  {
    "term": "庭",
    "termReading": "にわ",
    "termTranslation": "garden, court",
    "ex": "庭にバラを植えました。",
    "exReading": "にわ に ばら を うえました",
    "exTranslation": "I planted a rose in the garden.",
    "category": "Noun"
  },
  {
    "term": "アクセサリー",
    "termReading": "アクセサリー",
    "termTranslation": "accessories, jewelry",
    "ex": "このアクセサリーは素敵ね。",
    "exReading": "この あくせさりー は すてき ね",
    "exTranslation": "This jewelry is lovely.",
    "category": "Noun"
  },
  {
    "term": "桜",
    "termReading": "さくら",
    "termTranslation": "cherry tree, cherry blossom",
    "ex": "桜は三月か四月に咲きます。",
    "exReading": "さくら は さんがつ か しがつ に さきます",
    "exTranslation": "Cherry blossoms bloom in March or April.",
    "category": "Noun"
  },
  {
    "term": "咲く",
    "termReading": "さく",
    "termTranslation": "bloom, blossom",
    "ex": "桜の花が咲きました。",
    "exReading": "さくら の はな が さきました",
    "exTranslation": "The cherry blossoms have bloomed.",
    "category": "Verb"
  },
  {
    "term": "吹く",
    "termReading": "ふく",
    "termTranslation": "blow, play on a wind instrument",
    "ex": "今日は北風が吹いている。",
    "exReading": "きょう は きたかぜ が ふいて いる",
    "exTranslation": "The north wind is blowing today.",
    "category": "Verb"
  },
  {
    "term": "散歩",
    "termReading": "さんぽ",
    "termTranslation": "walk, stroll",
    "ex": "私のお祖父さんは毎日散歩します。",
    "exReading": "わたし の おじいさん は まいにち さんぽ します 。",
    "exTranslation": "My grandfather takes a walk every day.",
    "category": "Verbal Noun"
  },
  {
    "term": "あくび",
    "termReading": "あくび",
    "termTranslation": "yawn",
    "ex": "父があくびをした。",
    "exReading": "ちち が あくび を した",
    "exTranslation": "My father yawned.",
    "category": "Verbal Noun"
  },
  {
    "term": "植える",
    "termReading": "うえる",
    "termTranslation": "plant, raise",
    "ex": "庭にバラを植えました。",
    "exReading": "にわ に ばら を うえました",
    "exTranslation": "I planted a rose in the garden.",
    "category": "Verb"
  },
  {
    "term": "屋根",
    "termReading": "やね",
    "termTranslation": "roof",
    "ex": "屋根にカラスが止まっています。",
    "exReading": "やね に からす が とまって います",
    "exTranslation": "There's a crow on the roof.",
    "category": "Noun"
  },
  {
    "term": "黒板",
    "termReading": "こくばん",
    "termTranslation": "blackboard",
    "ex": "答えを黒板に書いてください。",
    "exReading": "こたえ を こくばん に かいて ください",
    "exTranslation": "Please write the answer on the blackboard.",
    "category": "Noun"
  },
  {
    "term": "掲示板",
    "termReading": "けいじばん",
    "termTranslation": "notice board, bulletin board",
    "ex": "掲示板のお知らせを見ましたか。",
    "exReading": "けいじばん の おしらせ を みました か",
    "exTranslation": "Did you see the notice on the bulletin board?",
    "category": "Noun"
  },
  {
    "term": "からから",
    "termReading": "からから",
    "termTranslation": "thirsty, dry",
    "ex": "おしゃべりして喉がからからになりました。",
    "exReading": "おしゃべり して のど が からから に なりました",
    "exTranslation": "My throat got dry from chatting.",
    "category": "Adjective"
  },
  {
    "term": "草",
    "termReading": "くさ",
    "termTranslation": "grass, weed",
    "ex": "庭に草が生えている。",
    "exReading": "にわ に くさ が はえて いる",
    "exTranslation": "Weeds are growing in the garden.",
    "category": "Noun"
  },
  {
    "term": "葉",
    "termReading": "は",
    "termTranslation": "leaf, needle",
    "ex": "これは桜の葉です。",
    "exReading": "これ は さくら の は です",
    "exTranslation": "This is a cherry tree leaf.",
    "category": "Noun"
  },
  {
    "term": "絵葉書",
    "termReading": "えはがき",
    "termTranslation": "picture postcard",
    "ex": "友達から絵葉書が届きました。",
    "exReading": "ともだち から えはがき が とどきました",
    "exTranslation": "I received a picture postcard from a friend.",
    "category": "Noun"
  },
  {
    "term": "葉書",
    "termReading": "はがき",
    "termTranslation": "postcard",
    "ex": "家族に葉書を書いています。",
    "exReading": "かぞく に はがき を かいています 。",
    "exTranslation": "I wrote a postcard to my family.",
    "category": "Noun"
  },
  {
    "term": "ふらふら",
    "termReading": "ふらふら",
    "termTranslation": "weak",
    "ex": "私は疲れてふらふらです。",
    "exReading": "わたし は つかれて ふらふら です",
    "exTranslation": "I'm tired and feel dizzy.",
    "category": "Adverb"
  },
  {
    "term": "吸う",
    "termReading": "すう",
    "termTranslation": "inhale, suck",
    "ex": "彼は大きく息を吸った。",
    "exReading": "かれ は おおきく いき を すった",
    "exTranslation": "He took a deep breath.",
    "category": "Verb"
  },
  {
    "term": "普及",
    "termReading": "ふきゅう",
    "termTranslation": "diffusion, spread",
    "ex": "ゴミのリサイクルが普及している。",
    "exReading": "ごみ の りさいくる が ふきゅう して いる",
    "exTranslation": "Garbage recycling has become widespread.",
    "category": "Verbal Noun"
  },
  {
    "term": "胃",
    "termReading": "い",
    "termTranslation": "stomach",
    "ex": "昨日から胃が痛い。",
    "exReading": "きのう から い が いたい",
    "exTranslation": "I've had a stomach ache since yesterday.",
    "category": "Noun"
  },
  {
    "term": "皿",
    "termReading": "さら",
    "termTranslation": "plate, counter for plates or helpings",
    "ex": "皿にケーキを載せました。",
    "exReading": "さら に けーき を のせました。",
    "exTranslation": "I put the cake on the plate.",
    "category": "Noun"
  },
  {
    "term": "こぼす",
    "termReading": "こぼす",
    "termTranslation": "spill",
    "ex": "ソファにワインをこぼした。",
    "exReading": "そふぁ に わいん を こぼした",
    "exTranslation": "I spilt wine on the sofa.",
    "category": "Verb"
  },
  {
    "term": "血",
    "termReading": "ち",
    "termTranslation": "blood",
    "ex": "血が出ていますよ。",
    "exReading": "ち が でて います よ",
    "exTranslation": "You're bleeding.",
    "category": "Noun"
  },
  {
    "term": "内容",
    "termReading": "ないよう",
    "termTranslation": "contents",
    "ex": "この本の内容を説明してください。",
    "exReading": "この ほん の ないよう を せつめい して ください",
    "exTranslation": "Please explain the content of this book.",
    "category": "Noun"
  },
  {
    "term": "背景",
    "termReading": "はいけい",
    "termTranslation": "background, scenery, setting",
    "ex": "事件の背景に何があったのだろう。",
    "exReading": "じけん の はいけい に なに が あった の だろう",
    "exTranslation": "I wonder what was behind the incident.",
    "category": "Noun"
  },
  {
    "term": "骨",
    "termReading": "ほね",
    "termTranslation": "bone, frame, spirit",
    "ex": "彼は足の骨を折りました。",
    "exReading": "かれ は あし の ほね を おりました",
    "exTranslation": "He broke a bone in his foot.",
    "category": "Noun"
  },
  {
    "term": "ランチ",
    "termReading": "ランチ",
    "termTranslation": "lunch",
    "ex": "一緒にランチに行きませんか。",
    "exReading": "いっしょ に らんち に いきません か",
    "exTranslation": "Do you want to go to lunch together?",
    "category": "Noun"
  },
  {
    "term": "滑る",
    "termReading": "すべる",
    "termTranslation": "slide, slip, flunk an examination",
    "ex": "彼は雪の上を滑った。",
    "exReading": "かれ は ゆき の うえ を すべった",
    "exTranslation": "He slipped on the snow.",
    "category": "Verb"
  },
  {
    "term": "折れる",
    "termReading": "おれる",
    "termTranslation": "snap, be folded, give in",
    "ex": "強風で木の枝が折れた。",
    "exReading": "きょうふう で き の えだ が おれた",
    "exTranslation": "The tree branch broke in the strong wind.",
    "category": "Verb"
  },
  {
    "term": "折る",
    "termReading": "おる",
    "termTranslation": "break off, fold, bend",
    "ex": "祖父は足の骨を折りました。",
    "exReading": "そふ は あし の ほね を おりました",
    "exTranslation": "My grandfather broke a bone in his foot.",
    "category": "Verb"
  },
  {
    "term": "健康",
    "termReading": "けんこう",
    "termTranslation": "healthy",
    "ex": "私は健康な生活を送っています。",
    "exReading": "わたし は けんこう な せいかつ を おくって います",
    "exTranslation": "I'm living a healthy lifestyle.",
    "category": "Adjectival Noun"
  },
  {
    "term": "レシート",
    "termReading": "レシート",
    "termTranslation": "receipt",
    "ex": "レシートを財布に入れました。",
    "exReading": "れしーと を さいふ に いれました",
    "exTranslation": "I put the receipt in my wallet.",
    "category": "Noun"
  },
  {
    "term": "珍しい",
    "termReading": "めずらしい",
    "termTranslation": "rare, precious",
    "ex": "昨日珍しい果物を食べました。",
    "exReading": "きのう めずらしい くだもの を たべました",
    "exTranslation": "I ate some unusual fruit yesterday.",
    "category": "Adjective"
  },
  {
    "term": "撮る",
    "termReading": "とる",
    "termTranslation": "photograph, shoot, film",
    "ex": "写真をたくさん撮りました。",
    "exReading": "しゃしん を たくさん とりました",
    "exTranslation": "I took a lot of photographs.",
    "category": "Verb"
  },
  {
    "term": "再び",
    "termReading": "ふたたび",
    "termTranslation": "again, a second time",
    "ex": "彼は再びここに戻ってきた。",
    "exReading": "かれ は ふたたび ここ に もどって きた",
    "exTranslation": "He came back here once again.",
    "category": "Adverb"
  },
  {
    "term": "再来年",
    "termReading": "さらいねん",
    "termTranslation": "the year after next",
    "ex": "次のオリンピックは再来年です。",
    "exReading": "つぎ の おりんぴっく は さらいねん です",
    "exTranslation": "The next Olympics are the year after next.",
    "category": "Noun"
  },
  {
    "term": "チョーク",
    "termReading": "チョーク",
    "termTranslation": "chalk",
    "ex": "チョークで黒板に字を書きました。",
    "exReading": "ちょーく で こくばん に じ を かきました",
    "exTranslation": "I wrote letters on the blackboard with chalk.",
    "category": "Noun"
  },
  {
    "term": "再来月",
    "termReading": "さらいげつ",
    "termTranslation": "the month after next",
    "ex": "再来月まで予約で一杯です。",
    "exReading": "さらいげつ まで よやく で いっぱい です",
    "exTranslation": "They're fully booked until the month after the next.",
    "category": "Noun"
  },
  {
    "term": "再来週",
    "termReading": "さらいしゅう",
    "termTranslation": "the week after next",
    "ex": "再来週は忙しいです。",
    "exReading": "さらいしゅう は いそがしい です",
    "exTranslation": "I'll be busy the week after next.",
    "category": "Noun"
  },
  {
    "term": "放送",
    "termReading": "ほうそう",
    "termTranslation": "broadcasting",
    "ex": "その番組は来週放送されます。",
    "exReading": "その ばんぐみ は らいしゅう ほうそう されます",
    "exTranslation": "The program will be broadcast next week.",
    "category": "Verbal Noun"
  },
  {
    "term": "装置",
    "termReading": "そうち",
    "termTranslation": "equipment, device",
    "ex": "実験にはこの装置を使います。",
    "exReading": "じっけん に は この そうち を つかいます",
    "exTranslation": "We use this device in our experiments.",
    "category": "Noun"
  },
  {
    "term": "ティッシュ",
    "termReading": "ティッシュ",
    "termTranslation": "tissue",
    "ex": "ティッシュを取ってください。",
    "exReading": "てぃっしゅ を とって ください",
    "exTranslation": "Please pass me the tissues.",
    "category": "Noun"
  },
  {
    "term": "仮名",
    "termReading": "かな",
    "termTranslation": "kana, Japanese syllabary",
    "ex": "お名前に仮名を振ってください。",
    "exReading": "おなまえ に かな を ふって ください",
    "exTranslation": "Please write the pronunciation of your name in kana.",
    "category": "Noun"
  },
  {
    "term": "送り仮名",
    "termReading": "おくりがな",
    "termTranslation": "kana suffix",
    "ex": "その送り仮名は間違っています。",
    "exReading": "その おくりがな は まちがって います",
    "exTranslation": "That kana suffix is wrong.",
    "category": "Noun"
  },
  {
    "term": "鏡",
    "termReading": "かがみ",
    "termTranslation": "mirror",
    "ex": "彼女は鏡を見て髪を直した。",
    "exReading": "かのじょ は かがみ を みて かみ を なおした",
    "exTranslation": "She looked in the mirror and tidied her hair.",
    "category": "Noun"
  },
  {
    "term": "悲しむ",
    "termReading": "かなしむ",
    "termTranslation": "feel sad, lament",
    "ex": "父は友だちの死を悲しんでいます。",
    "exReading": "ちち は ともだち の し を かなしんで います",
    "exTranslation": "My father is grieving because of his friend's death.",
    "category": "Verb"
  },
  {
    "term": "ノック",
    "termReading": "ノック",
    "termTranslation": "knock",
    "ex": "入る時はドアをノックしてください。",
    "exReading": "はいる とき は どあ を のっく して ください",
    "exTranslation": "Please knock before entering.",
    "category": "Verbal Noun"
  },
  {
    "term": "固い",
    "termReading": "かたい",
    "termTranslation": "stiff, tight",
    "ex": "私の上司は頭が固い。",
    "exReading": "わたし の じょうし は あたま が かたい",
    "exTranslation": "My boss is stubborn.",
    "category": "Adjective"
  },
  {
    "term": "美術館",
    "termReading": "びじゅつかん",
    "termTranslation": "art museum",
    "ex": "昨日、美術館に行きました。",
    "exReading": "きのう びじゅつかん に いきました",
    "exTranslation": "I went to the museum yesterday.",
    "category": "Noun"
  },
  {
    "term": "美人",
    "termReading": "びじん",
    "termTranslation": "beautiful woman",
    "ex": "彼のお母さんは美人です。",
    "exReading": "かれ の おかあさん は びじん です",
    "exTranslation": "His mother's a beautiful woman.",
    "category": "Noun"
  },
  {
    "term": "美容院",
    "termReading": "びよういん",
    "termTranslation": "beauty shop",
    "ex": "父は美容院で髪を切ります。",
    "exReading": "ちち は びよういん で かみ を きります",
    "exTranslation": "My father has his hair cut at a hair salon.",
    "category": "Noun"
  },
  {
    "term": "よろしく",
    "termReading": "よろしく",
    "termTranslation": "properly, one's regards",
    "ex": "よろしくお願いします。",
    "exReading": "よろしく おねがい します",
    "exTranslation": "My best regards.",
    "category": "Adverb"
  },
  {
    "term": "博物館",
    "termReading": "はくぶつかん",
    "termTranslation": "museum",
    "ex": "昨日、車の博物館に行った。",
    "exReading": "きのう くるま の はくぶつかん に いった",
    "exTranslation": "I went to the car museum yesterday.",
    "category": "Noun"
  },
  {
    "term": "開催",
    "termReading": "かいさい",
    "termTranslation": "holding (of an event), opening",
    "ex": "京都で国際会議が開催された。",
    "exReading": "きょうと で こくさいかいぎ が かいさい された",
    "exTranslation": "An international conference was held in Kyoto.",
    "category": "Verbal Noun"
  },
  {
    "term": "特徴",
    "termReading": "とくちょう",
    "termTranslation": "distinctive feature, characteristic",
    "ex": "この曲線がこの車の特徴です。",
    "exReading": "この きょくせん が この くるま の とくちょう です",
    "exTranslation": "These curves are a unique feature of this car.",
    "category": "Noun"
  },
  {
    "term": "許す",
    "termReading": "ゆるす",
    "termTranslation": "permit, license, forgive",
    "ex": "私は彼を許しました。",
    "exReading": "わたし は かれ を ゆるしました",
    "exTranslation": "I forgave him.",
    "category": "Verb"
  },
  {
    "term": "がらがら",
    "termReading": "がらがら",
    "termTranslation": "empty",
    "ex": "映画館はがらがらでした。",
    "exReading": "えいがかん は がらがら でした",
    "exTranslation": "The movie theater was empty.",
    "category": "Adjective"
  },
  {
    "term": "免許証",
    "termReading": "めんきょしょう",
    "termTranslation": "license",
    "ex": "免許証を見せてください。",
    "exReading": "めんきょしょう を みせて ください",
    "exTranslation": "Please show me your license.",
    "category": "Noun"
  },
  {
    "term": "教師",
    "termReading": "きょうし",
    "termTranslation": "teacher, instructor",
    "ex": "彼は高校教師だ。",
    "exReading": "かれ は こうこう きょうし だ",
    "exTranslation": "He's a high school teacher.",
    "category": "Noun"
  },
  {
    "term": "教授",
    "termReading": "きょうじゅ",
    "termTranslation": "professor",
    "ex": "彼は化学の教授です。",
    "exReading": "かれ は かがく の きょうじゅ です",
    "exTranslation": "He's a chemistry professor.",
    "category": "Noun"
  },
  {
    "term": "伝える",
    "termReading": "つたえる",
    "termTranslation": "convey, transmit, hand down",
    "ex": "みんなにこのことを伝えてください。",
    "exReading": "みんな に この こと を つたえて ください",
    "exTranslation": "Please tell everyone about this matter.",
    "category": "Verb"
  },
  {
    "term": "ぎらぎら",
    "termReading": "ぎらぎら",
    "termTranslation": "dazzling, glaring, blinding",
    "ex": "太陽がぎらぎらしている。",
    "exReading": "たいよう が ぎらぎら して いる",
    "exTranslation": "The sun is glaring.",
    "category": "Adverb"
  },
  {
    "term": "鳥",
    "termReading": "とり",
    "termTranslation": "bird, poultry",
    "ex": "鳥が飛んでいます。",
    "exReading": "とり が とんで います",
    "exTranslation": "A bird is flying.",
    "category": "Noun"
  },
  {
    "term": "鳴る",
    "termReading": "なる",
    "termTranslation": "sound, ring, roar",
    "ex": "今朝、5時に電話が鳴った。",
    "exReading": "けさ ごじ に でんわ が なった",
    "exTranslation": "The telephone rang this morning at five o'clock.",
    "category": "Verb"
  },
  {
    "term": "鳴く",
    "termReading": "なく",
    "termTranslation": "cry, chirp, meow",
    "ex": "どこかでネコが鳴いている。",
    "exReading": "どこか で ねこ が ないて いる",
    "exTranslation": "A cat is meowing somewhere.",
    "category": "Verb"
  },
  {
    "term": "声",
    "termReading": "こえ",
    "termTranslation": "voice, sound",
    "ex": "彼は大きな声で話した。",
    "exReading": "かれ は おおき な こえ で はなした",
    "exTranslation": "He spoke loudly.",
    "category": "Noun"
  },
  {
    "term": "そっち",
    "termReading": "そっち",
    "termTranslation": "there, thou",
    "ex": "そっちが私の部屋です。",
    "exReading": "そっち が わたし の へや です",
    "exTranslation": "That's my room over there.",
    "category": "Noun"
  },
  {
    "term": "卵",
    "termReading": "たまご",
    "termTranslation": "egg",
    "ex": "ニワトリは卵を産みます。",
    "exReading": "にわとり は たまご を うみます",
    "exTranslation": "Chickens lay eggs.",
    "category": "Noun"
  },
  {
    "term": "犬",
    "termReading": "いぬ",
    "termTranslation": "dog",
    "ex": "この犬はとても賢い。",
    "exReading": "この いぬ は とても かしこい",
    "exTranslation": "This dog is very clever.",
    "category": "Noun"
  },
  {
    "term": "馬",
    "termReading": "うま",
    "termTranslation": "horse",
    "ex": "彼は牧場で馬に乗った。",
    "exReading": "かれ は ぼくじょう で うま に のった",
    "exTranslation": "He rode a horse at the ranch.",
    "category": "Noun"
  },
  {
    "term": "駐車場",
    "termReading": "ちゅうしゃじょう",
    "termTranslation": "parking area",
    "ex": "車は駐車場に止めてください。",
    "exReading": "くるま は ちゅうしゃじょう に とめて ください",
    "exTranslation": "Please park the car in the parking lot.",
    "category": "Noun"
  },
  {
    "term": "ピンポン",
    "termReading": "ピンポン",
    "termTranslation": "table tennis, ping pong",
    "ex": "昨日の夕方、友達とピンポンをした。",
    "exReading": "きのう の ゆうがた ともだち と ぴんぽん を した",
    "exTranslation": "I played ping-pong with a friend last night.",
    "category": "Verbal Noun"
  },
  {
    "term": "騒ぐ",
    "termReading": "さわぐ",
    "termTranslation": "make a racket, mess around, make a fuss",
    "ex": "電車の中で騒がないでください。",
    "exReading": "でんしゃ の なか で さわがない で ください",
    "exTranslation": "Please don't make a racket in the train.",
    "category": "Verb"
  },
  {
    "term": "刺す",
    "termReading": "さす",
    "termTranslation": "stab, prick, sting",
    "ex": "虫に腕を刺されました。",
    "exReading": "むし に うで を さされました",
    "exTranslation": "I was stung on the arm by an insect.",
    "category": "Verb"
  },
  {
    "term": "刺身",
    "termReading": "さしみ",
    "termTranslation": "sashimi, sliced raw meat",
    "ex": "私は刺身は食べません。",
    "exReading": "わたし は さしみ は たべません",
    "exTranslation": "I don't eat sashimi.",
    "category": "Noun"
  },
  {
    "term": "激しい",
    "termReading": "はげしい",
    "termTranslation": "violent, intense",
    "ex": "激しい雨が降っています。",
    "exReading": "はげしい あめ が ふって います",
    "exTranslation": "A very heavy rain is falling.",
    "category": "Adjective"
  },
  {
    "term": "ぺこぺこ",
    "termReading": "ぺこぺこ",
    "termTranslation": "hungry, starving",
    "ex": "私はおなかがぺこぺこです。",
    "exReading": "わたし は おなか が ぺこぺこ です",
    "exTranslation": "I'm very hungry.",
    "category": "Adjective"
  },
  {
    "term": "驚く",
    "termReading": "おどろく",
    "termTranslation": "be surprised, be startled",
    "ex": "彼は血を見て驚いた。",
    "exReading": "かれ は ち を みて おどろいた",
    "exTranslation": "He was surprised to see blood.",
    "category": "Verb"
  },
  {
    "term": "倒れる",
    "termReading": "たおれる",
    "termTranslation": "fall over, succumb",
    "ex": "強風で木が倒れた。",
    "exReading": "きょうふう で き が たおれた",
    "exTranslation": "The tree fell in the gale.",
    "category": "Verb"
  },
  {
    "term": "倒す",
    "termReading": "たおす",
    "termTranslation": "bring down, overthrow, defeat",
    "ex": "そのスキーヤーはポールを倒した。",
    "exReading": "その すきーやー は ぽーる を たおした",
    "exTranslation": "The skier knocked the pole down.",
    "category": "Verb"
  },
  {
    "term": "傾向",
    "termReading": "けいこう",
    "termTranslation": "tendency, disposition",
    "ex": "最近の若者は難しい本を読まない傾向がある。",
    "exReading": "さいきん の わかもの は むずかしい ほん を よまない けいこう が ある",
    "exTranslation": "Young people these days tend not to read difficult books.",
    "category": "Noun"
  },
  {
    "term": "ぺらぺら",
    "termReading": "ぺらぺら",
    "termTranslation": "fluently",
    "ex": "彼女はドイツ語がぺらぺらです。",
    "exReading": "かのじょ は どいつご が ぺらぺら です",
    "exTranslation": "She speaks German fluently.",
    "category": "Adjectival Noun"
  },
  {
    "term": "柔道",
    "termReading": "じゅうどう",
    "termTranslation": "judo",
    "ex": "私は柔道を習っています。",
    "exReading": "わたし は じゅうどう を ならって います",
    "exTranslation": "I'm learning judo.",
    "category": "Noun"
  },
  {
    "term": "柔らかい",
    "termReading": "やわらかい",
    "termTranslation": "soft",
    "ex": "布団がとても柔らかい。",
    "exReading": "ふとん が とても やわらかい",
    "exTranslation": "The futon is very soft.",
    "category": "Adjective"
  },
  {
    "term": "柔らか",
    "termReading": "やわらか",
    "termTranslation": "soft, tender, gentle",
    "ex": "彼の声は柔らかだ。",
    "exReading": "かれ の こえ は やわらか だ",
    "exTranslation": "His voice is gentle.",
    "category": "Adjectival Noun"
  },
  {
    "term": "主張",
    "termReading": "しゅちょう",
    "termTranslation": "insistence, assertion",
    "ex": "上司は私の主張を受け入れた。",
    "exReading": "じょうし は わたし の しゅちょう を うけいれた",
    "exTranslation": "My boss accepted my argument.",
    "category": "Verbal Noun"
  },
  {
    "term": "レインコート",
    "termReading": "レインコート",
    "termTranslation": "raincoat",
    "ex": "雨なのでレインコートを着ました。",
    "exReading": "あめ な の で れいんこーと を きました",
    "exTranslation": "I put on my raincoat because it was raining.",
    "category": "Noun"
  },
  {
    "term": "引っ張る",
    "termReading": "ひっぱる",
    "termTranslation": "pull, drag",
    "ex": "娘が私の手を引っ張った。",
    "exReading": "むすめ が わたし の て を ひっぱった",
    "exTranslation": "My daughter tugged on my hand.",
    "category": "Verb"
  },
  {
    "term": "突き当たる",
    "termReading": "つきあたる",
    "termTranslation": "hit against, come to the end of",
    "ex": "突き当たったら右に曲がってください。",
    "exReading": "つきあたったら みぎ に まがって ください",
    "exTranslation": "Please turn right when the street ends.",
    "category": "Verb"
  },
  {
    "term": "突き当たり",
    "termReading": "つきあたり",
    "termTranslation": "end of a street",
    "ex": "この先は突き当たりです。",
    "exReading": "この さき は つきあたり です",
    "exTranslation": "There's a T-intersection ahead.",
    "category": "Noun"
  },
  {
    "term": "壁",
    "termReading": "かべ",
    "termTranslation": "wall, partition",
    "ex": "壁に絵が掛かっている。",
    "exReading": "かべ に え が かかって いる",
    "exTranslation": "There's a painting on the wall.",
    "category": "Noun"
  },
  {
    "term": "アイス",
    "termReading": "アイス",
    "termTranslation": "popsicle, ice lolly",
    "ex": "暑いのでアイスを食べました。",
    "exReading": "あつい の で あいす を たべました",
    "exTranslation": "I ate ice cream because it was hot.",
    "category": "Noun"
  },
  {
    "term": "弾く",
    "termReading": "ひく",
    "termTranslation": "play on",
    "ex": "彼はギターを弾きます。",
    "exReading": "かれ は ぎたー を ひきます",
    "exTranslation": "He plays the guitar.",
    "category": "Verb"
  },
  {
    "term": "丸い",
    "termReading": "まるい",
    "termTranslation": "round",
    "ex": "地球は丸い。",
    "exReading": "ちきゅう は まるい。",
    "exTranslation": "The earth is round.",
    "category": "Adjective"
  },
  {
    "term": "丸",
    "termReading": "まる",
    "termTranslation": "circle or sphere, wholeness",
    "ex": "紙に大きな丸を書きました。",
    "exReading": "かみ に おおき な まる を かきました",
    "exTranslation": "I drew a big circle on the paper.",
    "category": "Noun"
  },
  {
    "term": "攻撃",
    "termReading": "こうげき",
    "termTranslation": "attack, criticism",
    "ex": "2003年にアメリカはイラクを攻撃した。",
    "exReading": "にせんさんねん に あめりか は いらく を こうげき した",
    "exTranslation": "The United States attacked Iraq in 2003.",
    "category": "Verbal Noun"
  },
  {
    "term": "いつごろ",
    "termReading": "いつごろ",
    "termTranslation": "about when, about what time",
    "ex": "いつごろ夏休みを取りますか。",
    "exReading": "いつごろ なつやすみ を とります か",
    "exTranslation": "About when are you going on summer vacation?",
    "category": "Noun"
  },
  {
    "term": "絶対に",
    "termReading": "ぜったいに",
    "termTranslation": "absolutely, unconditionally",
    "ex": "絶対に駄目です。",
    "exReading": "ぜったいに だめ です",
    "exTranslation": "Absolutely not.",
    "category": "Adverb"
  },
  {
    "term": "消防車",
    "termReading": "しょうぼうしゃ",
    "termTranslation": "fire engine",
    "ex": "消防車が4台も来た。",
    "exReading": "しょうぼうしゃ が よんだい も きた",
    "exTranslation": "Four fire engines came.",
    "category": "Noun"
  },
  {
    "term": "嫌",
    "termReading": "いや",
    "termTranslation": "dislike, have a dislike for",
    "ex": "私は待つのが嫌だ。",
    "exReading": "わたし は まつ の が いや だ",
    "exTranslation": "I hate waiting.",
    "category": "Adjectival Noun"
  },
  {
    "term": "大嫌い",
    "termReading": "だいきらい",
    "termTranslation": "hate",
    "ex": "私はテストが大嫌い。",
    "exReading": "わたし は てすと が だいきらい",
    "exTranslation": "I hate tests.",
    "category": "Adjectival Noun"
  },
  {
    "term": "ウィスキー",
    "termReading": "ウィスキー",
    "termTranslation": "whisky",
    "ex": "このウィスキーは強いよ。",
    "exReading": "この うぃすきー は つよい よ",
    "exTranslation": "This whisky is strong.",
    "category": "Noun"
  },
  {
    "term": "大抵",
    "termReading": "たいてい",
    "termTranslation": "generally, mostly",
    "ex": "朝食は大抵7時頃に食べます。",
    "exReading": "ちょうしょく は たいてい しちじごろ に たべます",
    "exTranslation": "I usually eat breakfast around seven o'clock.",
    "category": "Adverb"
  },
  {
    "term": "大勢",
    "termReading": "おおぜい",
    "termTranslation": "large numbers, a lot of",
    "ex": "大勢で食事に出かけました。",
    "exReading": "おおぜい で しょくじ に でかけました",
    "exTranslation": "We went out to eat in a large group.",
    "category": "Noun"
  },
  {
    "term": "姿",
    "termReading": "すがた",
    "termTranslation": "figure, appearance",
    "ex": "遠くに彼女の姿が見えた。",
    "exReading": "とおく に かのじょ の すがた が みえた",
    "exTranslation": "I saw her figure from a long way away.",
    "category": "Noun"
  },
  {
    "term": "姿勢",
    "termReading": "しせい",
    "termTranslation": "posture, attitude",
    "ex": "あの子はいつも姿勢が悪い。",
    "exReading": "あの こ は いつも しせい が わるい",
    "exTranslation": "That kid always has bad posture.",
    "category": "Noun"
  },
  {
    "term": "エアメール",
    "termReading": "エアメール",
    "termTranslation": "airmail",
    "ex": "彼にエアメールを送りました。",
    "exReading": "かれ に えあめーる を おくりました",
    "exTranslation": "I sent him a letter by airmail.",
    "category": "Noun"
  },
  {
    "term": "恐ろしい",
    "termReading": "おそろしい",
    "termTranslation": "terrible, awful",
    "ex": "昨夜恐ろしい夢を見た。",
    "exReading": "ゆうべ おそろしい ゆめ を みた",
    "exTranslation": "I had a terrible dream last night.",
    "category": "Adjective"
  },
  {
    "term": "怖い",
    "termReading": "こわい",
    "termTranslation": "fearful, be afraid",
    "ex": "私は犬が怖いです。",
    "exReading": "わたし は いぬ が こわい です",
    "exTranslation": "I'm scared of dogs.",
    "category": "Adjective"
  },
  {
    "term": "糸",
    "termReading": "いと",
    "termTranslation": "thread, yarn",
    "ex": "母は糸を針に通した。",
    "exReading": "はは は いと を はり に とおした",
    "exTranslation": "My mother threaded the needle.",
    "category": "Noun"
  },
  {
    "term": "孫",
    "termReading": "まご",
    "termTranslation": "grandchild",
    "ex": "昨日、孫が生まれました。",
    "exReading": "きのう まご が うまれました",
    "exTranslation": "My grandchild was born yesterday.",
    "category": "Noun"
  },
  {
    "term": "シャープペンシル",
    "termReading": "シャープペンシル",
    "termTranslation": "mechanical pencil",
    "ex": "私のシャープペンシルがありません。",
    "exReading": "わたし の しゃーぷぺんしる が ありません",
    "exTranslation": "My mechanical pencil is missing.",
    "category": "Noun"
  },
  {
    "term": "木綿",
    "termReading": "もめん",
    "termTranslation": "cotton",
    "ex": "彼女は木綿のシャツを着ています。",
    "exReading": "かのじょ は もめん の しゃつ を きて います",
    "exTranslation": "She's wearing a cotton shirt.",
    "category": "Noun"
  },
  {
    "term": "偉い",
    "termReading": "えらい",
    "termTranslation": "great, famous, eminent",
    "ex": "彼は偉い学者です。",
    "exReading": "かれ は えらい がくしゃ です",
    "exTranslation": "He's a great scholar.",
    "category": "Adjective"
  },
  {
    "term": "爪",
    "termReading": "つめ",
    "termTranslation": "nail, claw",
    "ex": "爪が伸びています。",
    "exReading": "つめ が のびて います",
    "exTranslation": "My nails have grown.",
    "category": "Noun"
  },
  {
    "term": "机",
    "termReading": "つくえ",
    "termTranslation": "desk, table",
    "ex": "新しい机を買ってもらいました。",
    "exReading": "あたらしい つくえ を かって もらいました",
    "exTranslation": "They bought me a new desk.",
    "category": "Noun"
  },
  {
    "term": "セロテープ",
    "termReading": "セロテープ",
    "termTranslation": "cellophane tape, Scotch tape, Sellotape",
    "ex": "セロテープはありますか。",
    "exReading": "せろてーぷ は あります か",
    "exTranslation": "Do you have any scotch tape?",
    "category": "Noun"
  },
  {
    "term": "棚",
    "termReading": "たな",
    "termTranslation": "cupboard, shelf, rack",
    "ex": "大きな棚はとても便利です。",
    "exReading": "おおき な たな は とても べんり です",
    "exTranslation": "A large set of shelves is very useful.",
    "category": "Noun"
  },
  {
    "term": "本棚",
    "termReading": "ほんだな",
    "termTranslation": "bookshelf",
    "ex": "これはとても大きな本棚ですね。",
    "exReading": "これ は とても おおき な ほんだな です ね",
    "exTranslation": "This is a very big bookcase.",
    "category": "Noun"
  },
  {
    "term": "方針",
    "termReading": "ほうしん",
    "termTranslation": "course, policy",
    "ex": "今後の方針が決まった。",
    "exReading": "こんご の ほうしん が きまった",
    "exTranslation": "We've decided our future policy.",
    "category": "Noun"
  },
  {
    "term": "釣る",
    "termReading": "つる",
    "termTranslation": "angle, fish",
    "ex": "昨日大きな魚を釣りました。",
    "exReading": "きのう おおき な さかな を つりました",
    "exTranslation": "I caught a big fish yesterday.",
    "category": "Verb"
  },
  {
    "term": "やけど",
    "termReading": "やけど",
    "termTranslation": "burn",
    "ex": "彼は手にやけどをしました。",
    "exReading": "かれ は て に やけど を しました",
    "exTranslation": "He burned his hand.",
    "category": "Verbal Noun"
  },
  {
    "term": "お釣り",
    "termReading": "おつり",
    "termTranslation": "change",
    "ex": "母はお釣りを確かめた。",
    "exReading": "はは は おつり を たしかめた",
    "exTranslation": "My mother checked the change.",
    "category": "Noun"
  },
  {
    "term": "鍵",
    "termReading": "かぎ",
    "termTranslation": "key, lock",
    "ex": "出かける時は鍵を掛けてください。",
    "exReading": "でかける とき は かぎ を かけて ください",
    "exTranslation": "Please lock the door when you go out.",
    "category": "Noun"
  },
  {
    "term": "鍋",
    "termReading": "なべ",
    "termTranslation": "pan, saucepan",
    "ex": "鍋にスープが入っているよ。",
    "exReading": "なべ に すーぷ が はいって いる よ",
    "exTranslation": "There's some soup in the pan.",
    "category": "Noun"
  },
  {
    "term": "寿司",
    "termReading": "すし",
    "termTranslation": "sushi",
    "ex": "彼女は寿司を初めて食べました。",
    "exReading": "かのじょ は すし を はじめて たべました 。",
    "exTranslation": "She ate sushi for the first time.",
    "category": "Noun"
  },
  {
    "term": "アイスコーヒー",
    "termReading": "アイスコーヒー",
    "termTranslation": "iced coffee",
    "ex": "アイスコーヒーをください。",
    "exReading": "あいすこーひー を ください",
    "exTranslation": "I'll have an iced coffee please.",
    "category": "Noun"
  },
  {
    "term": "泥棒",
    "termReading": "どろぼう",
    "termTranslation": "thief, crook",
    "ex": "近所に泥棒が入った。",
    "exReading": "きんじょ に どろぼう が はいった",
    "exTranslation": "There was a burglary in the neighborhood.",
    "category": "Verbal Noun"
  },
  {
    "term": "湯",
    "termReading": "ゆ",
    "termTranslation": "hot water, hot bath, bathhouse",
    "ex": "お風呂のお湯が一杯です。",
    "exReading": "おふろ の おゆ が いっぱい です",
    "exTranslation": "The bath is filled with hot water.",
    "category": "Noun"
  },
  {
    "term": "沸く",
    "termReading": "わく",
    "termTranslation": "boil, be excited",
    "ex": "お風呂が沸きました。",
    "exReading": "お ふろ が わきました。",
    "exTranslation": "The bath is ready.",
    "category": "Verb"
  },
  {
    "term": "沸かす",
    "termReading": "わかす",
    "termTranslation": "boil, excite",
    "ex": "お湯を沸かしてください。",
    "exReading": "おゆ を わかして ください",
    "exTranslation": "Please boil the water.",
    "category": "Verb"
  },
  {
    "term": "あっち",
    "termReading": "あっち",
    "termTranslation": "other side, there",
    "ex": "郵便局はあっちです。",
    "exReading": "ゆうびんきょく は あっち です",
    "exTranslation": "The post office is that way.",
    "category": "Noun"
  },
  {
    "term": "洗濯機",
    "termReading": "せんたくき",
    "termTranslation": "washing machine",
    "ex": "新しい洗濯機を買いました。",
    "exReading": "あたらしい せんたっき を かいました",
    "exTranslation": "I bought a new washing machine.",
    "category": "Noun"
  },
  {
    "term": "洗濯",
    "termReading": "せんたく",
    "termTranslation": "laundering, washing",
    "ex": "一週間、洗濯をしていない。",
    "exReading": "いっしゅうかん せんたく を して いない",
    "exTranslation": "I haven't done the laundry for a week.",
    "category": "Verbal Noun"
  },
  {
    "term": "濡れる",
    "termReading": "ぬれる",
    "termTranslation": "get wet",
    "ex": "雨で濡れてしまった。",
    "exReading": "あめ で ぬれて しまった",
    "exTranslation": "I got wet in the rain.",
    "category": "Verb"
  },
  {
    "term": "喫茶店",
    "termReading": "きっさてん",
    "termTranslation": "coffee shop, tea house, café",
    "ex": "喫茶店でコーヒーを飲んだ。",
    "exReading": "きっさてん で こーひー を のんだ",
    "exTranslation": "I drank coffee in the café",
    "category": "Noun"
  },
  {
    "term": "あんなに",
    "termReading": "あんなに",
    "termTranslation": "that, so",
    "ex": "あんなにいい人はいません。",
    "exReading": "あんなに いい ひと は いません",
    "exTranslation": "There could never be such a good person.",
    "category": "Adverb"
  },
  {
    "term": "怠ける",
    "termReading": "なまける",
    "termTranslation": "be lazy, neglect",
    "ex": "怠けていないで、手伝って。",
    "exReading": "なまけて いない で てつだって",
    "exTranslation": "Stop slacking and help me!",
    "category": "Verb"
  },
  {
    "term": "一生懸命",
    "termReading": "いっしょうけんめい",
    "termTranslation": "for life, with all one's might",
    "ex": "彼は毎日一生懸命働いている。",
    "exReading": "かれ は まいにち いっしょうけんめい はたらいて いる",
    "exTranslation": "He works hard every day.",
    "category": "Adverb"
  },
  {
    "term": "休憩",
    "termReading": "きゅうけい",
    "termTranslation": "rest, repose",
    "ex": "少し休憩しましょう。",
    "exReading": "すこし きゅうけい しましょう",
    "exTranslation": "Let's take a break.",
    "category": "Verbal Noun"
  },
  {
    "term": "天井",
    "termReading": "てんじょう",
    "termTranslation": "ceiling",
    "ex": "この部屋は天井が高いですね。",
    "exReading": "この へや は てんじょう が たかい です ね",
    "exTranslation": "This room has a high ceiling.",
    "category": "Noun"
  },
  {
    "term": "いたずら",
    "termReading": "いたずら",
    "termTranslation": "mischief, prank",
    "ex": "その子はいたずらが大好きだ。",
    "exReading": "その こ は いたずら が だいすき だ",
    "exTranslation": "That kid loves playing tricks.",
    "category": "Verbal Noun"
  },
  {
    "term": "納豆",
    "termReading": "なっとう",
    "termTranslation": "natto (fermented soybeans)",
    "ex": "私は納豆をよく食べます。",
    "exReading": "わたし は なっとう を よく たべます",
    "exTranslation": "I often eat natto.",
    "category": "Noun"
  },
  {
    "term": "化粧",
    "termReading": "けしょう",
    "termTranslation": "make up, put on makeup",
    "ex": "彼女は化粧が上手い。",
    "exReading": "かのじょ は けしょう が うまい",
    "exTranslation": "She's very good with makeup.",
    "category": "Verbal Noun"
  },
  {
    "term": "畑",
    "termReading": "はたけ",
    "termTranslation": "field, vegetable garden",
    "ex": "母は畑仕事が好きだ。",
    "exReading": "はは は はたけ しごと が すき だ",
    "exTranslation": "My mother likes working in the field.",
    "category": "Noun"
  },
  {
    "term": "炊く",
    "termReading": "たく",
    "termTranslation": "cook, boil",
    "ex": "母は毎朝ご飯を炊く。",
    "exReading": "はは は まいあさ ごはん を たく",
    "exTranslation": "My mother cooks rice every morning.",
    "category": "Verb"
  },
  {
    "term": "ウェートレス",
    "termReading": "ウェートレス",
    "termTranslation": "waitress",
    "ex": "ウェートレスを呼んだ。",
    "exReading": "うぇーとれす を よんだ",
    "exTranslation": "I called the waitress.",
    "category": "Noun"
  },
  {
    "term": "自炊",
    "termReading": "じすい",
    "termTranslation": "cooking food for oneself",
    "ex": "私は毎日、自炊している。",
    "exReading": "わたし は まいにち じすい して いる",
    "exTranslation": "I cook for myself every day.",
    "category": "Verbal Noun"
  },
  {
    "term": "灰皿",
    "termReading": "はいざら",
    "termTranslation": "ashtray",
    "ex": "灰皿をください。",
    "exReading": "はいざら を ください",
    "exTranslation": "Please give me an ashtray.",
    "category": "Noun"
  },
  {
    "term": "灰",
    "termReading": "はい",
    "termTranslation": "ashes",
    "ex": "火事ですべて灰になった。",
    "exReading": "かじ で すべて はい に なった",
    "exTranslation": "Everything turned to ashes in the fire.",
    "category": "Noun"
  },
  {
    "term": "灰色",
    "termReading": "はいいろ",
    "termTranslation": "ash color, gray",
    "ex": "今日の空は灰色だ。",
    "exReading": "きょう の そら は はいいろ だ",
    "exTranslation": "The sky's gray today.",
    "category": "Noun"
  },
  {
    "term": "おばさん",
    "termReading": "おばさん",
    "termTranslation": "aunt",
    "ex": "おばさん一家が遊びに来ました。",
    "exReading": "おばさん いっか が あそび に きました",
    "exTranslation": "My aunt and her family came to visit.",
    "category": "Noun"
  },
  {
    "term": "西暦",
    "termReading": "せいれき",
    "termTranslation": "Christian Era, A.D.",
    "ex": "西暦1964年に東京でオリンピックがあった。",
    "exReading": "せいれき せんきゅうひゃくろくじゅうよねん に とうきょう で おりんぴっく が あった",
    "exTranslation": "The Tokyo Olympics were held in 1964.",
    "category": "Noun"
  },
  {
    "term": "毛",
    "termReading": "け",
    "termTranslation": "hair, wool",
    "ex": "猫の毛がセーターに付いた。",
    "exReading": "ねこ の け が せーたー に ついた",
    "exTranslation": "I got cat hair on my sweater.",
    "category": "Noun"
  },
  {
    "term": "髪",
    "termReading": "かみ",
    "termTranslation": "hair, hairstyle",
    "ex": "昨日、髪を切りました。",
    "exReading": "きのう かみ を きりました",
    "exTranslation": "I had my hair cut yesterday.",
    "category": "Noun"
  },
  {
    "term": "舌",
    "termReading": "した",
    "termTranslation": "tongue",
    "ex": "舌を出してください。",
    "exReading": "した を だして ください",
    "exTranslation": "Stick out your tongue.",
    "category": "Noun"
  },
  {
    "term": "くし",
    "termReading": "くし",
    "termTranslation": "comb",
    "ex": "彼はくしで髪をとかした。",
    "exReading": "かれ は くし で かみ を とかした",
    "exTranslation": "He combed his hair.",
    "category": "Noun"
  },
  {
    "term": "臭い",
    "termReading": "くさい",
    "termTranslation": "ill-smelling, stinking",
    "ex": "納豆は臭い。",
    "exReading": "なっとう は くさい",
    "exTranslation": "Natto stinks.",
    "category": "Adjective"
  },
  {
    "term": "匂い",
    "termReading": "におい",
    "termTranslation": "smell, odor",
    "ex": "いい匂いがする。",
    "exReading": "いい におい が する",
    "exTranslation": "It smells good.",
    "category": "Noun"
  },
  {
    "term": "居る",
    "termReading": "いる",
    "termTranslation": "be present",
    "ex": "今日は一日中家に居ました。",
    "exReading": "きょう は いちにちじゅう うち に いました",
    "exTranslation": "I was at home all day today.",
    "category": "Verb"
  },
  {
    "term": "履く",
    "termReading": "はく",
    "termTranslation": "put on footwear, wear",
    "ex": "彼女はブーツを履いています。",
    "exReading": "かのじょ は ぶーつ を はいて います",
    "exTranslation": "She's wearing boots.",
    "category": "Verb"
  },
  {
    "term": "クリーニング",
    "termReading": "クリーニング",
    "termTranslation": "cleaning, laundry",
    "ex": "スーツをクリーニングに出しました。",
    "exReading": "すーつ を くりーにんぐ に だしました",
    "exTranslation": "I sent the suit to the cleaners.",
    "category": "Verbal Noun"
  },
  {
    "term": "戸",
    "termReading": "と",
    "termTranslation": "door, shutter",
    "ex": "部屋の戸が開いています。",
    "exReading": "へや の と が あいて います",
    "exTranslation": "The room's door is open.",
    "category": "Noun"
  },
  {
    "term": "扇風機",
    "termReading": "せんぷうき",
    "termTranslation": "electric fan",
    "ex": "暑いから扇風機をつけよう。",
    "exReading": "あつい から せんぷうき を つけよう",
    "exTranslation": "It's hot, so let's turn on the fan.",
    "category": "Noun"
  },
  {
    "term": "豚",
    "termReading": "ぶた",
    "termTranslation": "pig, pork",
    "ex": "豚はすごく鼻が良い。",
    "exReading": "ぶた は すごく はな が いい",
    "exTranslation": "Pigs have a very good sense of smell.",
    "category": "Noun"
  },
  {
    "term": "豚肉",
    "termReading": "ぶたにく",
    "termTranslation": "pork, hog meat",
    "ex": "豚肉は美味しいです。",
    "exReading": "ぶたにく は おいしい です",
    "exTranslation": "Pork is delicious.",
    "category": "Noun"
  },
  {
    "term": "ごちそう",
    "termReading": "ごちそう",
    "termTranslation": "feast, treat",
    "ex": "テーブルの上にごちそうが並んでいる。",
    "exReading": "てーぶる の うえ に ごちそう が ならんで いる",
    "exTranslation": "There are some delicious treats lined up on the table.",
    "category": "Noun"
  },
  {
    "term": "鶏",
    "termReading": "にわとり",
    "termTranslation": "chicken, hen",
    "ex": "その鶏は毎朝鳴きます。",
    "exReading": "その にわとり は まいあさ なきます",
    "exTranslation": "The rooster crows every morning.",
    "category": "Noun"
  },
  {
    "term": "腕時計",
    "termReading": "うでどけい",
    "termTranslation": "wrist watch",
    "ex": "私の腕時計は遅れている。",
    "exReading": "わたし の うでどけい は おくれて いる",
    "exTranslation": "My watch is slow.",
    "category": "Noun"
  },
  {
    "term": "お菓子",
    "termReading": "おかし",
    "termTranslation": "sweets, snacks",
    "ex": "みんなにお菓子をあげましょう。",
    "exReading": "みんな に おかし を あげましょう",
    "exTranslation": "Let's give everyone some sweets.",
    "category": "Noun"
  },
  {
    "term": "お辞儀",
    "termReading": "おじぎ",
    "termTranslation": "bow",
    "ex": "皆、社長にお辞儀をした。",
    "exReading": "みんな しゃちょう に おじぎ を した",
    "exTranslation": "Everyone bowed to the company president.",
    "category": "Verbal Noun"
  },
  {
    "term": "ごちそうする",
    "termReading": "ごちそうする",
    "termTranslation": "treat",
    "ex": "今日の夕食は私がごちそうします。",
    "exReading": "きょう の ゆうしょく は わたし が ごちそう します",
    "exTranslation": "I'll treat you to dinner tonight.",
    "category": "Verb"
  },
  {
    "term": "寝坊",
    "termReading": "ねぼう",
    "termTranslation": "late riser, oversleeping",
    "ex": "今朝は寝坊しました。",
    "exReading": "けさ は ねぼう しました",
    "exTranslation": "I slept in this morning.",
    "category": "Verbal Noun"
  },
  {
    "term": "旗",
    "termReading": "はた",
    "termTranslation": "flag, banner",
    "ex": "旗が風に揺れている。",
    "exReading": "はた が かぜ に ゆれて いる",
    "exTranslation": "The flag is waving in the wind.",
    "category": "Noun"
  },
  {
    "term": "鉛筆",
    "termReading": "えんぴつ",
    "termTranslation": "pencil",
    "ex": "鉛筆を貸して下さい。",
    "exReading": "えんぴつ を かして ください",
    "exTranslation": "Please lend me a pencil.",
    "category": "Noun"
  },
  {
    "term": "万年筆",
    "termReading": "まんねんひつ",
    "termTranslation": "fountain pen",
    "ex": "父に万年筆をプレゼントしました。",
    "exReading": "ちち に まんねんひつ を ぷれぜんと しました",
    "exTranslation": "I gave my father a fountain pen.",
    "category": "Noun"
  },
  {
    "term": "こぼれる",
    "termReading": "こぼれる",
    "termTranslation": "spill, overflow",
    "ex": "彼女の目から涙がこぼれた。",
    "exReading": "かのじょ の め から なみだ が こぼれた",
    "exTranslation": "Tears rolled down from her eyes.",
    "category": "Verb"
  },
  {
    "term": "箱",
    "termReading": "はこ",
    "termTranslation": "box, case",
    "ex": "この箱は重い。",
    "exReading": "この はこ は おもい",
    "exTranslation": "This box is heavy.",
    "category": "Noun"
  },
  {
    "term": "本箱",
    "termReading": "ほんばこ",
    "termTranslation": "bookcase",
    "ex": "雑誌を本箱に入れました。",
    "exReading": "ざっし を ほんばこ に いれました",
    "exTranslation": "I put the magazine in the bookcase.",
    "category": "Noun"
  },
  {
    "term": "手袋",
    "termReading": "てぶくろ",
    "termTranslation": "gloves",
    "ex": "寒いので手袋をしました。",
    "exReading": "さむい の で てぶくろ を しました",
    "exTranslation": "I wore gloves because it was cold.",
    "category": "Noun"
  },
  {
    "term": "袋",
    "termReading": "ふくろ",
    "termTranslation": "bag, sack",
    "ex": "袋はいりません。",
    "exReading": "ふくろ は いりません",
    "exTranslation": "I don't need a bag.",
    "category": "Noun"
  },
  {
    "term": "こんなに",
    "termReading": "こんなに",
    "termTranslation": "this, so, such",
    "ex": "こんなに面白い本は初めて読んだ。",
    "exReading": "こんなに おもしろい ほん は はじめて よんだ",
    "exTranslation": "I've never read such an interesting book before.",
    "category": "Adverb"
  },
  {
    "term": "財布",
    "termReading": "さいふ",
    "termTranslation": "purse, wallet",
    "ex": "会社に財布を忘れた。",
    "exReading": "かいしゃ に さいふ を わすれた",
    "exTranslation": "I left my wallet at the office.",
    "category": "Noun"
  },
  {
    "term": "毛布",
    "termReading": "もうふ",
    "termTranslation": "blanket",
    "ex": "この毛布は暖かい。",
    "exReading": "この もうふ は あたたかい",
    "exTranslation": "This blanket is warm.",
    "category": "Noun"
  },
  {
    "term": "布団",
    "termReading": "ふとん",
    "termTranslation": "futon, bedquilt",
    "ex": "母が布団を干している。",
    "exReading": "はは が ふとん を ほして いる",
    "exTranslation": "My mother is airing the futons.",
    "category": "Noun"
  },
  {
    "term": "小包",
    "termReading": "こづつみ",
    "termTranslation": "parcel, package",
    "ex": "フランスの友達から小包が届いた。",
    "exReading": "ふらんす の ともだち から こづつみ が とどいた",
    "exTranslation": "A package arrived from a friend in France.",
    "category": "Noun"
  },
  {
    "term": "ざあざあ",
    "termReading": "ざあざあ",
    "termTranslation": "heavily, pouring",
    "ex": "雨がざあざあ降っている。",
    "exReading": "あめ が ざあざあ ふって いる",
    "exTranslation": "It's pouring.",
    "category": "Adverb"
  },
  {
    "term": "包む",
    "termReading": "つつむ",
    "termTranslation": "wrap, pack",
    "ex": "プレゼントをきれいな紙で包みました。",
    "exReading": "ぷれぜんと を きれい な かみ で つつみました",
    "exTranslation": "I wrapped the present in beautiful paper.",
    "category": "Verb"
  },
  {
    "term": "飾る",
    "termReading": "かざる",
    "termTranslation": "decorate, exhibit",
    "ex": "テーブルの上に花を飾りました。",
    "exReading": "てーぶる の うえ に はな を かざりました",
    "exTranslation": "I decorated the table with flowers.",
    "category": "Verb"
  },
  {
    "term": "手帳",
    "termReading": "てちょう",
    "termTranslation": "pocketbook, memo",
    "ex": "新しい手帳を買いました。",
    "exReading": "あたらしい てちょう を かいました",
    "exTranslation": "I bought a new notebook.",
    "category": "Noun"
  },
  {
    "term": "電話帳",
    "termReading": "でんわちょう",
    "termTranslation": "telephone book",
    "ex": "電話帳を見せてください。",
    "exReading": "でんわちょう を みせて ください",
    "exTranslation": "Please show me the telephone book.",
    "category": "Noun"
  },
  {
    "term": "ジャガイモ",
    "termReading": "ジャガイモ",
    "termTranslation": "potato",
    "ex": "ポテトサラダを作るから、ジャガイモを買ってきて。",
    "exReading": "ぽてと さらだ を つくる から じゃがいも を かって きて",
    "exTranslation": "I'm going to make potato salad, so go and buy some potatoes.",
    "category": "Noun"
  },
  {
    "term": "牛乳",
    "termReading": "ぎゅうにゅう",
    "termTranslation": "milk",
    "ex": "私は毎朝、牛乳を飲む。",
    "exReading": "わたし は まいあさ ぎゅうにゅう を のむ",
    "exTranslation": "I drink milk every morning.",
    "category": "Noun"
  },
  {
    "term": "玄関",
    "termReading": "げんかん",
    "termTranslation": "entrance, door",
    "ex": "玄関に花を飾りました。",
    "exReading": "げんかん に はな を かざりました",
    "exTranslation": "I decorated the entrance with flowers.",
    "category": "Noun"
  },
  {
    "term": "眼鏡",
    "termReading": "めがね",
    "termTranslation": "glasses, judgment",
    "ex": "彼は眼鏡をかけています。",
    "exReading": "かれ は めがね を かけて います",
    "exTranslation": "He's wearing glasses.",
    "category": "Noun"
  },
  {
    "term": "眠い",
    "termReading": "ねむい",
    "termTranslation": "sleepy",
    "ex": "今日はとても眠いです。",
    "exReading": "きょう は とても ねむい です",
    "exTranslation": "I'm very sleepy today.",
    "category": "Adjective"
  },
  {
    "term": "ソファー",
    "termReading": "ソファー",
    "termTranslation": "sofa, sofa bed",
    "ex": "このソファーは気持ちがいい。",
    "exReading": "この そふぁー は きもち が いい",
    "exTranslation": "This sofa is very comfortable.",
    "category": "Noun"
  },
  {
    "term": "眠る",
    "termReading": "ねむる",
    "termTranslation": "sleep",
    "ex": "昨日は8時間眠りました。",
    "exReading": "きのう は はちじかん ねむりました",
    "exTranslation": "I slept for eight hours yesterday.",
    "category": "Verb"
  },
  {
    "term": "封筒",
    "termReading": "ふうとう",
    "termTranslation": "envelope",
    "ex": "その手紙を封筒に入れた。",
    "exReading": "その てがみ を ふうとう に いれた",
    "exTranslation": "I put that letter in an envelope.",
    "category": "Noun"
  },
  {
    "term": "出掛ける",
    "termReading": "でかける",
    "termTranslation": "go out, be about to go out",
    "ex": "主人はもう出掛けました。",
    "exReading": "しゅじん は もう でかけました",
    "exTranslation": "My husband has already gone out.",
    "category": "Verb"
  },
  {
    "term": "掛かる",
    "termReading": "かかる",
    "termTranslation": "hang, splash, require",
    "ex": "壁に大きな時計が掛かっています。",
    "exReading": "かべ に おおき な とけい が かかって います",
    "exTranslation": "There's a big clock hanging on the wall.",
    "category": "Verb"
  },
  {
    "term": "たばこ",
    "termReading": "たばこ",
    "termTranslation": "tobacco, cigarette",
    "ex": "彼はたばこを吸いません。",
    "exReading": "かれ は たばこ を すいません",
    "exTranslation": "He doesn't smoke.",
    "category": "Noun"
  },
  {
    "term": "掛け算",
    "termReading": "かけざん",
    "termTranslation": "multiplication",
    "ex": "弟は掛け算を習っている。",
    "exReading": "おとうと は かけざん を ならって いる",
    "exTranslation": "My little brother is learning his multiplication tables.",
    "category": "Verbal Noun"
  },
  {
    "term": "拍手",
    "termReading": "はくしゅ",
    "termTranslation": "applause, clapping",
    "ex": "大きな拍手が上がった。",
    "exReading": "おおき な はくしゅ が あがった",
    "exTranslation": "There was a big round of applause.",
    "category": "Verbal Noun"
  },
  {
    "term": "掃除",
    "termReading": "そうじ",
    "termTranslation": "cleaning",
    "ex": "週末は部屋の掃除をしました。",
    "exReading": "しゅうまつ は へや の そうじ を しました",
    "exTranslation": "I cleaned my room on the weekend.",
    "category": "Verbal Noun"
  },
  {
    "term": "掃く",
    "termReading": "はく",
    "termTranslation": "sweep, brush",
    "ex": "床をほうきで掃きました。",
    "exReading": "ゆか を ほうき で はきました",
    "exTranslation": "I swept the floor with a broom.",
    "category": "Verb"
  },
  {
    "term": "ちょうど",
    "termReading": "ちょうど",
    "termTranslation": "just, as if",
    "ex": "値段はちょうど3万円です。",
    "exReading": "ねだん は ちょうど さんまんえん です",
    "exTranslation": "The price is exactly 30,000 yen.",
    "category": "Adverb"
  },
  {
    "term": "掃除機",
    "termReading": "そうじき",
    "termTranslation": "vacuum cleaner",
    "ex": "掃除機が壊れた。",
    "exReading": "そうじき が こわれた",
    "exTranslation": "The vacuum cleaner broke.",
    "category": "Noun"
  },
  {
    "term": "握る",
    "termReading": "にぎる",
    "termTranslation": "grasp, make hand-rolled sushi balls",
    "ex": "少女は母親の手を握った。",
    "exReading": "しょうじょ は ははおや の て を にぎった",
    "exTranslation": "The girl clasped her mother's hand.",
    "category": "Verb"
  },
  {
    "term": "握手",
    "termReading": "あくしゅ",
    "termTranslation": "handshake, handshaking",
    "ex": "彼らは握手をした。",
    "exReading": "かれら は あくしゅ を した",
    "exTranslation": "They shook hands.",
    "category": "Verbal Noun"
  },
  {
    "term": "迎える",
    "termReading": "むかえる",
    "termTranslation": "welcome, go out to meet, invite",
    "ex": "姉が空港まで迎えに来てくれます。",
    "exReading": "あね が くうこう まで むかえに きて くれます",
    "exTranslation": "My big sister is coming to pick me up at the airport.",
    "category": "Verb"
  },
  {
    "term": "ちょっと",
    "termReading": "ちょっと",
    "termTranslation": "just a moment, just a little, easily",
    "ex": "もうちょっと塩を入れて。",
    "exReading": "もう ちょっと しお を いれて",
    "exTranslation": "Put in a little more salt.",
    "category": "Adverb"
  },
  {
    "term": "お巡りさん",
    "termReading": "おまわりさん",
    "termTranslation": "policeman",
    "ex": "あのお巡りさんに道を聞きましょう。",
    "exReading": "あの おまわりさん に みち を ききましょう",
    "exTranslation": "Let's ask that policeman how to get there.",
    "category": "Noun"
  },
  {
    "term": "幾つ",
    "termReading": "いくつ",
    "termTranslation": "how many, how old",
    "ex": "娘さんは幾つになりましたか。",
    "exReading": "むすめさん は いくつ に なりました か",
    "exTranslation": "How old is your daughter now?",
    "category": "Noun"
  },
  {
    "term": "幾ら",
    "termReading": "いくら",
    "termTranslation": "how much",
    "ex": "この靴は幾らですか。",
    "exReading": "この くつ は いくら です か",
    "exTranslation": "How much are these shoes?",
    "category": "Noun"
  },
  {
    "term": "冗談",
    "termReading": "じょうだん",
    "termTranslation": "joke",
    "ex": "冗談は止めてください。",
    "exReading": "じょうだん は やめて ください",
    "exTranslation": "Please stop joking.",
    "category": "Noun"
  },
  {
    "term": "つく",
    "termReading": "つく",
    "termTranslation": "be on, switched on",
    "ex": "部屋の電気がついています。",
    "exReading": "へや の でんき が ついて います",
    "exTranslation": "The room light is on.",
    "category": "Verb"
  },
  {
    "term": "穴",
    "termReading": "あな",
    "termTranslation": "hole, cavity",
    "ex": "靴下に穴が開いている。",
    "exReading": "くつした に あな が あいて いる",
    "exTranslation": "There are holes in my socks.",
    "category": "Noun"
  },
  {
    "term": "寂しい",
    "termReading": "さびしい",
    "termTranslation": "lonely, desolate, sad",
    "ex": "これは寂しい曲ですね。",
    "exReading": "これ は さびしい きょく です ね",
    "exTranslation": "This is a sad song.",
    "category": "Adjective"
  },
  {
    "term": "丁寧",
    "termReading": "ていねい",
    "termTranslation": "polite, courteous, careful",
    "ex": "彼女はいつも丁寧に仕事をします。",
    "exReading": "かのじょ は いつも ていねい に しごと を します",
    "exTranslation": "She always works carefully.",
    "category": "Adjectival Noun"
  },
  {
    "term": "可哀相",
    "termReading": "かわいそう",
    "termTranslation": "poor, pitiable",
    "ex": "その可哀相な子供たちは食べるものがない。",
    "exReading": "その かわいそう な こどもたち は たべる もの が ない",
    "exTranslation": "Those poor children have nothing to eat.",
    "category": "Adjectival Noun"
  },
  {
    "term": "てんぷら",
    "termReading": "てんぷら",
    "termTranslation": "tempura, Japanese deep-fried breaded food",
    "ex": "私はえびのてんぷらが好きです。",
    "exReading": "わたし は えび の てんぷら が すき です",
    "exTranslation": "I like shrimp tempura.",
    "category": "Noun"
  },
  {
    "term": "怪我",
    "termReading": "けが",
    "termTranslation": "injury",
    "ex": "彼女は腕を怪我した。",
    "exReading": "かのじょ は うで を けがした",
    "exTranslation": "She injured her arm.",
    "category": "Verbal Noun"
  },
  {
    "term": "我慢",
    "termReading": "がまん",
    "termTranslation": "patience, self-restraint",
    "ex": "彼のわがままには我慢できません。",
    "exReading": "かれ の わがまま に は がまん できません",
    "exTranslation": "I can't put up with his selfishness.",
    "category": "Verbal Noun"
  },
  {
    "term": "幼稚園",
    "termReading": "ようちえん",
    "termTranslation": "kindergarten",
    "ex": "娘は幼稚園に通っています。",
    "exReading": "むすめ は ようちえん に かよって います",
    "exTranslation": "My daughter goes to kindergarten.",
    "category": "Noun"
  },
  {
    "term": "隠れる",
    "termReading": "かくれる",
    "termTranslation": "hide, disappear",
    "ex": "太陽が雲に隠れた。",
    "exReading": "たいよう が くも に かくれた",
    "exTranslation": "The sun hid behind the clouds.",
    "category": "Verb"
  },
  {
    "term": "とんとん",
    "termReading": "とんとん",
    "termTranslation": "tapping",
    "ex": "母の肩をとんとんたたいた。",
    "exReading": "はは の かた を とんとん たたいた",
    "exTranslation": "I patted my mother's shoulders as a massage.",
    "category": "Adverb"
  },
  {
    "term": "日陰",
    "termReading": "ひかげ",
    "termTranslation": "the shade",
    "ex": "暑いので日陰で休みましょう。",
    "exReading": "あつい の で ひかげ で やすみましょう",
    "exTranslation": "It's hot so let's take a rest in the shade.",
    "category": "Noun"
  },
  {
    "term": "随分",
    "termReading": "ずいぶん",
    "termTranslation": "extremely, considerably",
    "ex": "随分早く終わったね。",
    "exReading": "ずいぶん はやく おわった ね",
    "exTranslation": "You finished really early, didn't you?",
    "category": "Adverb"
  },
  {
    "term": "頑張る",
    "termReading": "がんばる",
    "termTranslation": "persist, be tenacious",
    "ex": "明日の試験、頑張ってね。",
    "exReading": "あした の しけん がんばって ね",
    "exTranslation": "Good luck on tomorrow's exam.",
    "category": "Verb"
  },
  {
    "term": "頃",
    "termReading": "ころ",
    "termTranslation": "time",
    "ex": "子供の頃、よくその公園で遊んだ。",
    "exReading": "こども の ころ よく その こうえん で あそんだ",
    "exTranslation": "I often played in that park when I was a kid.",
    "category": "Noun"
  },
  {
    "term": "にんじん",
    "termReading": "にんじん",
    "termTranslation": "carrot",
    "ex": "私はにんじんが嫌いだ。",
    "exReading": "わたし は にんじん が きらい だ",
    "exTranslation": "I hate carrots.",
    "category": "Noun"
  },
  {
    "term": "この頃",
    "termReading": "このごろ",
    "termTranslation": "now, these days, recently",
    "ex": "この頃、よく肩が凝る。",
    "exReading": "このごろ よく かた が こる",
    "exTranslation": "These days I often get stiff shoulders.",
    "category": "Noun"
  },
  {
    "term": "消防署",
    "termReading": "しょうぼうしょ",
    "termTranslation": "fire station",
    "ex": "この町には消防署が1つしかありません。",
    "exReading": "この まち に は しょうぼうしょ が ひとつ しか ありません",
    "exTranslation": "There's only one fire station in this town.",
    "category": "Noun"
  },
  {
    "term": "尋ねる",
    "termReading": "たずねる",
    "termTranslation": "inquire, search for",
    "ex": "彼女は道を尋ねた。",
    "exReading": "かのじょ は みち を たずねた",
    "exTranslation": "She asked for directions.",
    "category": "Verb"
  },
  {
    "term": "缶詰",
    "termReading": "かんづめ",
    "termTranslation": "canned goods, cooping-up",
    "ex": "缶詰のフルーツはとても甘い。",
    "exReading": "かんづめ の ふるーつ は とても あまい",
    "exTranslation": "Canned fruit is very sweet.",
    "category": "Noun"
  },
  {
    "term": "ぬるい",
    "termReading": "ぬるい",
    "termTranslation": "tepid, lukewarm",
    "ex": "風呂がぬるかった。",
    "exReading": "ふろ が ぬるかった",
    "exTranslation": "The bath was lukewarm.",
    "category": "Adjective"
  },
  {
    "term": "缶",
    "termReading": "かん",
    "termTranslation": "can, tin",
    "ex": "コーヒーはその缶に入っています。",
    "exReading": "こーひー は その かん に はいって います",
    "exTranslation": "The coffee is in that can.",
    "category": "Noun"
  },
  {
    "term": "腐る",
    "termReading": "くさる",
    "termTranslation": "rot, corrode",
    "ex": "リンゴが腐っている。",
    "exReading": "りんご が くさって いる",
    "exTranslation": "This apple's rotten.",
    "category": "Verb"
  },
  {
    "term": "豆腐",
    "termReading": "とうふ",
    "termTranslation": "tofu",
    "ex": "私は毎日豆腐を食べます。",
    "exReading": "わたし は まいにち とうふ を たべます",
    "exTranslation": "I eat tofu every day.",
    "category": "Noun"
  },
  {
    "term": "床",
    "termReading": "ゆか",
    "termTranslation": "floor",
    "ex": "床がぬれている。",
    "exReading": "ゆか が ぬれて いる",
    "exTranslation": "The floor is wet.",
    "category": "Noun"
  },
  {
    "term": "パーセント",
    "termReading": "パーセント",
    "termTranslation": "percent",
    "ex": "この村の80パーセントは老人です。",
    "exReading": "この むら の はちじゅっぱーせんと は ろうじん です",
    "exTranslation": "Eighty percent of the people in this village are elderly.",
    "category": "Noun"
  },
  {
    "term": "床屋",
    "termReading": "とこや",
    "termTranslation": "barber, barbershop",
    "ex": "昨日床屋で髪を切った。",
    "exReading": "きのう とこや で かみ を きった",
    "exTranslation": "Yesterday I had my hair cut at the barber's.",
    "category": "Noun"
  },
  {
    "term": "畳",
    "termReading": "たたみ",
    "termTranslation": "tatami, mat",
    "ex": "そのホテルには畳の部屋がある。",
    "exReading": "その ほてる に は たたみ の へや が ある",
    "exTranslation": "That hotel has tatami rooms.",
    "category": "Noun"
  },
  {
    "term": "畳む",
    "termReading": "たたむ",
    "termTranslation": "fold, shut",
    "ex": "布団を畳んでください。",
    "exReading": "ふとん を たたんで ください",
    "exTranslation": "Please fold up the futon.",
    "category": "Verb"
  },
  {
    "term": "干す",
    "termReading": "ほす",
    "termTranslation": "dry, desiccate",
    "ex": "母は洗濯物を干しています。",
    "exReading": "はは は せんたくもの を ほして います",
    "exTranslation": "My mother's hanging out the laundry to dry.",
    "category": "Verb"
  },
  {
    "term": "ひざ",
    "termReading": "ひざ",
    "termTranslation": "knee",
    "ex": "スキーでひざを怪我しました。",
    "exReading": "すきー で ひざ を けがしました",
    "exTranslation": "I injured my knee skiing.",
    "category": "Noun"
  },
  {
    "term": "帽子",
    "termReading": "ぼうし",
    "termTranslation": "cap, hat",
    "ex": "暑いので帽子を被りましょう。",
    "exReading": "あつい の で ぼうし を かぶりましょう",
    "exTranslation": "You should wear a hat because it's hot.",
    "category": "Noun"
  },
  {
    "term": "是非",
    "termReading": "ぜひ",
    "termTranslation": "by all means, at any cost",
    "ex": "是非、うちに来てください。",
    "exReading": "ぜひ うち に きて ください",
    "exTranslation": "By all means come to my house.",
    "category": "Adverb"
  },
  {
    "term": "敬語",
    "termReading": "けいご",
    "termTranslation": "honorific language, polite speech",
    "ex": "お客様には敬語を使いなさい。",
    "exReading": "おきゃくさま に は けいご を つかいなさい",
    "exTranslation": "Use polite language to customers.",
    "category": "Noun"
  },
  {
    "term": "尊敬",
    "termReading": "そんけい",
    "termTranslation": "respect, esteem, honor",
    "ex": "祖父は家族みんなに尊敬されています。",
    "exReading": "そふ は かぞく みんな に そんけい されて います",
    "exTranslation": "My grandfather is respected by the whole family.",
    "category": "Verbal Noun"
  },
  {
    "term": "ひじ",
    "termReading": "ひじ",
    "termTranslation": "elbow",
    "ex": "彼はひじに怪我をした。",
    "exReading": "かれ は ひじ に けが を した",
    "exTranslation": "He injured his elbow.",
    "category": "Noun"
  },
  {
    "term": "敷く",
    "termReading": "しく",
    "termTranslation": "lay, spread",
    "ex": "生まれて初めて布団を敷いた。",
    "exReading": "うまれて はじめて ふとん を しいた",
    "exTranslation": "I laid out the futon for the first time in my life.",
    "category": "Verb"
  },
  {
    "term": "雷",
    "termReading": "かみなり",
    "termTranslation": "thunder, lightning",
    "ex": "雷が鳴っています。",
    "exReading": "かみなり が なって います",
    "exTranslation": "Thunder is rumbling.",
    "category": "Noun"
  },
  {
    "term": "零",
    "termReading": "れい",
    "termTranslation": "zero, naught",
    "ex": "今ちょうど零時です。",
    "exReading": "いま ちょうど れいじ です",
    "exTranslation": "It's exactly midnight now.",
    "category": "Noun"
  },
  {
    "term": "仕舞う",
    "termReading": "しまう",
    "termTranslation": "put away",
    "ex": "彼女は大切な書類を机に仕舞った。",
    "exReading": "かのじょ は たいせつ な しょるい を つくえ に しまった。",
    "exTranslation": "She put the important documents away in her desk.",
    "category": "Verb"
  },
  {
    "term": "ひも",
    "termReading": "ひも",
    "termTranslation": "laces, string, rope",
    "ex": "靴のひもが切れた。",
    "exReading": "くつ の ひも が きれた",
    "exTranslation": "The shoe lace is broken.",
    "category": "Noun"
  },
  {
    "term": "踊る",
    "termReading": "おどる",
    "termTranslation": "dance",
    "ex": "彼女はクラブで踊るのが好きです。",
    "exReading": "かのじょ は くらぶ で おどる の が すき です",
    "exTranslation": "She likes to dance at the club.",
    "category": "Verb"
  },
  {
    "term": "踊り",
    "termReading": "おどり",
    "termTranslation": "dance, dancing",
    "ex": "この踊りは易しいですよ。",
    "exReading": "この おどり は やさしい です よ",
    "exTranslation": "This dance is easy.",
    "category": "Noun"
  },
  {
    "term": "踏む",
    "termReading": "ふむ",
    "termTranslation": "step on, tread on",
    "ex": "運転手がブレーキを踏んだ。",
    "exReading": "うんてんしゅ が ぶれーき を ふんだ",
    "exTranslation": "The driver stepped on the brake.",
    "category": "Verb"
  },
  {
    "term": "踏切",
    "termReading": "ふみきり",
    "termTranslation": "railroad crossing",
    "ex": "その踏切は長い。",
    "exReading": "その ふみきり は ながい",
    "exTranslation": "This railroad crossing has a long signal.",
    "category": "Noun"
  },
  {
    "term": "まく",
    "termReading": "まく",
    "termTranslation": "scatter, plant",
    "ex": "日本では、二月に豆をまく行事がある。",
    "exReading": "にほん で は にがつ に まめ を まく ぎょうじ が ある",
    "exTranslation": "In Japan, during February, there's a bean throwing festival.",
    "category": "Verb"
  },
  {
    "term": "蹴る",
    "termReading": "ける",
    "termTranslation": "kick",
    "ex": "ゴールキーパーがボールを蹴った。",
    "exReading": "ごーるきーぱー が ぼーる を けった。",
    "exTranslation": "The goalkeeper kicked the ball.",
    "category": "Verb"
  },
  {
    "term": "食堂",
    "termReading": "しょくどう",
    "termTranslation": "dining hall, restaurant",
    "ex": "大学の食堂は安い。",
    "exReading": "だいがく の しょくどう は やすい",
    "exTranslation": "The university cafeteria is cheap.",
    "category": "Noun"
  },
  {
    "term": "猫",
    "termReading": "ねこ",
    "termTranslation": "cat, feline",
    "ex": "私は猫が大好きです。",
    "exReading": "わたし は ねこ が だいすき です",
    "exTranslation": "I love cats.",
    "category": "Noun"
  },
  {
    "term": "文章",
    "termReading": "ぶんしょう",
    "termTranslation": "writing, prose",
    "ex": "彼は文章がとてもうまい。",
    "exReading": "かれ は ぶんしょう が とても うまい",
    "exTranslation": "His writing is very good.",
    "category": "Noun"
  },
  {
    "term": "やかん",
    "termReading": "やかん",
    "termTranslation": "kettle",
    "ex": "やかんでお湯を沸かしました。",
    "exReading": "やかん で おゆ を わかしました",
    "exTranslation": "I boiled water in the kettle.",
    "category": "Noun"
  },
  {
    "term": "丈夫",
    "termReading": "じょうぶ",
    "termTranslation": "healthy, stout",
    "ex": "祖母は身体が丈夫だ。",
    "exReading": "そぼ は からだ が じょうぶ だ",
    "exTranslation": "My grandmother is physically strong.",
    "category": "Adjectival Noun"
  },
  {
    "term": "又",
    "termReading": "また",
    "termTranslation": "again, also, or",
    "ex": "明日、また来ます。",
    "exReading": "あした また きます",
    "exTranslation": "I'll come again tomorrow.",
    "category": "Adverb"
  },
  {
    "term": "お祖父さん",
    "termReading": "おじいさん",
    "termTranslation": "grandfather",
    "ex": "私のお祖父さんは毎日散歩します。",
    "exReading": "わたし の おじいさん は まいにち さんぽ します 。",
    "exTranslation": "My grandfather takes a walk every day.",
    "category": "Noun"
  },
  {
    "term": "お祖母さん",
    "termReading": "おばあさん",
    "termTranslation": "grandmother",
    "ex": "彼女はお祖母さんと住んでいる。",
    "exReading": "かのじょ は おばあさん と すん でいる 。",
    "exTranslation": "She lives with her grandmother.",
    "category": "Noun"
  },
  {
    "term": "ようこそ",
    "termReading": "ようこそ",
    "termTranslation": "welcome",
    "ex": "日本へようこそ。",
    "exReading": "にっぽん へ ようこそ",
    "exTranslation": "Welcome to Japan.",
    "category": "Adverb"
  },
  {
    "term": "祖父",
    "termReading": "そふ",
    "termTranslation": "grandfather",
    "ex": "祖父は元気です。",
    "exReading": "そふ は げんき です",
    "exTranslation": "My grandfather is well.",
    "category": "Noun"
  },
  {
    "term": "祖母",
    "termReading": "そぼ",
    "termTranslation": "grandmother",
    "ex": "祖母は京都で生まれました。",
    "exReading": "そぼ は きょうと で うまれました",
    "exTranslation": "My grandmother was born in Kyoto.",
    "category": "Noun"
  },
  {
    "term": "邪魔",
    "termReading": "じゃま",
    "termTranslation": "disturbing",
    "ex": "邪魔です、どいてください。",
    "exReading": "じゃま です どいて ください",
    "exTranslation": "You're in the way, so please move.",
    "category": "Adjectival Noun"
  },
  {
    "term": "風邪薬",
    "termReading": "かぜぐすり",
    "termTranslation": "remedy for a cold",
    "ex": "この風邪薬を飲みなさい。",
    "exReading": "この かぜぐすり を のみなさい",
    "exTranslation": "Take this cold medicine.",
    "category": "Noun"
  },
  {
    "term": "ウェーター",
    "termReading": "ウェーター",
    "termTranslation": "waiter",
    "ex": "ウェーターが水を運んできました。",
    "exReading": "うぇーたー が みず を はこんで きました",
    "exTranslation": "The waiter brought some water.",
    "category": "Noun"
  },
  {
    "term": "風呂屋",
    "termReading": "ふろや",
    "termTranslation": "bathhouse, public bath",
    "ex": "昨日、友達とお風呂屋さんに行った。",
    "exReading": "きのう ともだち と おふろやさん に いった",
    "exTranslation": "I went with a friend to a public bath yesterday.",
    "category": "Noun"
  },
  {
    "term": "風呂",
    "termReading": "ふろ",
    "termTranslation": "bath",
    "ex": "父は今お風呂に入っています。",
    "exReading": "ちち は いま お ふろ に はいって います",
    "exTranslation": "My father's in the bath now.",
    "category": "Noun"
  },
  {
    "term": "昭和",
    "termReading": "しょうわ",
    "termTranslation": "Showa era",
    "ex": "私の両親は昭和生まれです。",
    "exReading": "わたし の りょうしん は しょうわうまれ です",
    "exTranslation": "My parents were born in the Showa era.",
    "category": "Noun"
  },
  {
    "term": "紫",
    "termReading": "むらさき",
    "termTranslation": "purple",
    "ex": "彼女は紫のドレスを着ていた。",
    "exReading": "かのじょ は むらさき の どれす を きて いた",
    "exTranslation": "She wore a purple dress.",
    "category": "Noun"
  },
  {
    "term": "そんなに",
    "termReading": "そんなに",
    "termTranslation": "that, so",
    "ex": "一度にそんなにたくさんはできない。",
    "exReading": "いちど に そんなに たくさん は できない",
    "exTranslation": "You can't do that much at one time.",
    "category": "Adverb"
  },
  {
    "term": "紅茶",
    "termReading": "こうちゃ",
    "termTranslation": "black tea",
    "ex": "温かい紅茶が飲みたい。",
    "exReading": "あたたかい こうちゃ が のみたい",
    "exTranslation": "I want to drink hot tea.",
    "category": "Noun"
  },
  {
    "term": "梅雨",
    "termReading": "つゆ",
    "termTranslation": "rainy season",
    "ex": "梅雨は6月頃です。",
    "exReading": "つゆ は ろくがつごろ です",
    "exTranslation": "The rainy season comes around June.",
    "category": "Noun"
  },
  {
    "term": "桃",
    "termReading": "もも",
    "termTranslation": "peach, peach tree",
    "ex": "私の一番好きな果物は桃です。",
    "exReading": "わたし の いちばん すき な くだもの は もも です",
    "exTranslation": "My favorite fruit is peaches.",
    "category": "Noun"
  },
  {
    "term": "遭う",
    "termReading": "あう",
    "termTranslation": "meet with, encounter",
    "ex": "彼は交通事故に遭った。",
    "exReading": "かれ は こうつう じこ に あった",
    "exTranslation": "He had a traffic accident.",
    "category": "Verb"
  },
  {
    "term": "枕",
    "termReading": "まくら",
    "termTranslation": "pillow",
    "ex": "私は低い枕が好きです。",
    "exReading": "わたし は ひくい まくら が すき です",
    "exTranslation": "I like thin pillows.",
    "category": "Noun"
  },
  {
    "term": "嘘",
    "termReading": "うそ",
    "termTranslation": "lie",
    "ex": "嘘をついてはいけません。",
    "exReading": "うそ を ついて は いけません",
    "exTranslation": "You mustn't lie.",
    "category": "Noun"
  },
  {
    "term": "遠慮",
    "termReading": "えんりょ",
    "termTranslation": "reserve, hesitation, forethought",
    "ex": "私は遠慮します。",
    "exReading": "わたし は えんりょ します",
    "exTranslation": "No thanks, I'll pass (on that).",
    "category": "Verbal Noun"
  },
  {
    "term": "叱る",
    "termReading": "しかる",
    "termTranslation": "scold, tell off, reprimand",
    "ex": "父親が子供を叱っている。",
    "exReading": "ちちおや が こども を しかって いる",
    "exTranslation": "That father is scolding his children.",
    "category": "Verb"
  },
  {
    "term": "傘",
    "termReading": "かさ",
    "termTranslation": "umbrella, parasol",
    "ex": "電車に傘を忘れた。",
    "exReading": "でんしゃ に かさ を わすれた",
    "exTranslation": "I left my umbrella on the train.",
    "category": "Noun"
  },
  {
    "term": "お嬢さん",
    "termReading": "おじょうさん",
    "termTranslation": "young lady, your daughter",
    "ex": "お嬢さんはおいくつですか。",
    "exReading": "おじょうさん は おいくつ です か",
    "exTranslation": "How old is your daughter?",
    "category": "Noun"
  },
  {
    "term": "年賀状",
    "termReading": "ねんがじょう",
    "termTranslation": "New Year's card",
    "ex": "昨日、年賀状を出しました。",
    "exReading": "きのう ねんがじょう を だしました",
    "exTranslation": "I sent my New Year cards yesterday.",
    "category": "Noun"
  },
  {
    "term": "賑やか",
    "termReading": "にぎやか",
    "termTranslation": "lively, exciting",
    "ex": "浅草は賑やかな街です。",
    "exReading": "あさくさ は にぎやか な まち です",
    "exTranslation": "Asakusa is a lively town.",
    "category": "Adjectival Noun"
  },
  {
    "term": "蚊",
    "termReading": "か",
    "termTranslation": "mosquito",
    "ex": "蚊に足を刺された。",
    "exReading": "か に あし を さされた",
    "exTranslation": "I was bitten on my foot by a mosquito.",
    "category": "Noun"
  },
  {
    "term": "拭く",
    "termReading": "ふく",
    "termTranslation": "wipe, dry",
    "ex": "タオルで体を拭きました。",
    "exReading": "たおる で からだ を ふきました",
    "exTranslation": "I dried myself with a towel.",
    "category": "Verb"
  },
  {
    "term": "挨拶",
    "termReading": "あいさつ",
    "termTranslation": "greet, greeting",
    "ex": "彼女は笑顔で挨拶した。",
    "exReading": "かのじょ は えがお で あいさつ した",
    "exTranslation": "She greeted me with a smile.",
    "category": "Verbal Noun"
  },
  {
    "term": "伴う",
    "termReading": "ともなう",
    "termTranslation": "accompany, go with",
    "ex": "その仕事は危険を伴う。",
    "exReading": "その しごと は きけん を ともなう",
    "exTranslation": "Danger comes with the job.",
    "category": "Verb"
  },
  {
    "term": "巻く",
    "termReading": "まく",
    "termTranslation": "roll up, wind, wrap",
    "ex": "彼は頭にタオルを巻いていた。",
    "exReading": "かれ は あたま に たおる を まいて いた",
    "exTranslation": "He had a towel tied around his head.",
    "category": "Verb"
  },
  {
    "term": "靴",
    "termReading": "くつ",
    "termTranslation": "shoes, boots",
    "ex": "靴が汚れた。",
    "exReading": "くつ が よごれた",
    "exTranslation": "My shoes got dirty.",
    "category": "Noun"
  },
  {
    "term": "靴下",
    "termReading": "くつした",
    "termTranslation": "socks, stockings",
    "ex": "この靴下は3足で1000円です。",
    "exReading": "この くつした は さんそく で せんえん です",
    "exTranslation": "These socks are 1,000 yen for 3 pairs.",
    "category": "Noun"
  },
  {
    "term": "磨く",
    "termReading": "みがく",
    "termTranslation": "polish, grind",
    "ex": "靴を磨いてください。",
    "exReading": "くつ を みがいて ください",
    "exTranslation": "Please polish your shoes.",
    "category": "Verb"
  },
  {
    "term": "歯磨き",
    "termReading": "はみがき",
    "termTranslation": "toothpaste, brushing one's teeth",
    "ex": "歯磨きはしましたか。",
    "exReading": "はみがき は しました か",
    "exTranslation": "Have you brushed your teeth?",
    "category": "Noun"
  },
  {
    "term": "廊下",
    "termReading": "ろうか",
    "termTranslation": "hallway, corridor",
    "ex": "廊下は走らないでください。",
    "exReading": "ろうか は はしらない で ください",
    "exTranslation": "Don't run in the hallway.",
    "category": "Noun"
  },
  {
    "term": "瓶",
    "termReading": "びん",
    "termTranslation": "bottle, flask",
    "ex": "瓶ビールを注文した。",
    "exReading": "びんびーる を ちゅうもん した",
    "exTranslation": "I ordered a bottle of beer.",
    "category": "Noun"
  },
  {
    "term": "褒める",
    "termReading": "ほめる",
    "termTranslation": "praise, commend",
    "ex": "頑張ったので褒められました。",
    "exReading": "がんばった の で ほめられました",
    "exTranslation": "He was praised because worked hard.",
    "category": "Verb"
  },
  {
    "term": "元旦",
    "termReading": "がんたん",
    "termTranslation": "New Year's Day",
    "ex": "元旦に彼から年賀状が来た。",
    "exReading": "がんたん に かれ から ねんがじょう が きた 。",
    "exTranslation": "I got a New Year's card from him on January 1.",
    "category": "Noun"
  },
  {
    "term": "袖",
    "termReading": "そで",
    "termTranslation": "sleeve",
    "ex": "このシャツは袖が短い。",
    "exReading": "この しゃつ は そで が みじかい",
    "exTranslation": "This shirt has short sleeves.",
    "category": "Noun"
  },
  {
    "term": "長袖",
    "termReading": "ながそで",
    "termTranslation": "clothes with long sleeves",
    "ex": "今日は寒いので長袖を着ました。",
    "exReading": "きょう は さむい ので ながそで を きました 。",
    "exTranslation": "I put on a long-sleeved shirt because it's cold today.",
    "category": "Noun"
  },
  {
    "term": "半袖",
    "termReading": "はんそで",
    "termTranslation": "short-sleeved shirt",
    "ex": "今日は、半袖のシャツを着よう。",
    "exReading": "きょう は 、 はんそで の しゃつ を きよう 。",
    "exTranslation": "I'll put on a short-sleeved shirt today.",
    "category": "Noun"
  },
  {
    "term": "馬鹿",
    "termReading": "ばか",
    "termTranslation": "fool, nonsense",
    "ex": "私は馬鹿だった。",
    "exReading": "わたし は ばか だった",
    "exTranslation": "I was foolish.",
    "category": "Adjectival Noun"
  },
  {
    "term": "凄い",
    "termReading": "すごい",
    "termTranslation": "wonderful, great, incredible",
    "ex": "凄い雨になった。",
    "exReading": "すごい あめ に なった",
    "exTranslation": "It began raining cats and dogs.",
    "category": "Adjective"
  },
  {
    "term": "剃る",
    "termReading": "そる",
    "termTranslation": "shave",
    "ex": "父は毎日髭を剃ります。",
    "exReading": "ちち は まいにち ひげ を そります",
    "exTranslation": "My father shaves every day.",
    "category": "Verb"
  },
  {
    "term": "喧嘩",
    "termReading": "けんか",
    "termTranslation": "fight, quarrel, argument",
    "ex": "喧嘩はやめて。",
    "exReading": "けんか は やめて",
    "exTranslation": "Stop fighting.",
    "category": "Verbal Noun"
  },
  {
    "term": "叩く",
    "termReading": "たたく",
    "termTranslation": "tap, hit",
    "ex": "彼は子供のおしりを叩いた。",
    "exReading": "かれ は こども の おしり を たたいた",
    "exTranslation": "He slapped his child on the bottom.",
    "category": "Verb"
  },
  {
    "term": "噛む",
    "termReading": "かむ",
    "termTranslation": "bite, chew",
    "ex": "もっとよく噛みなさい。",
    "exReading": "もっと よく かみなさい",
    "exTranslation": "Chew your food better.",
    "category": "Verb"
  },
  {
    "term": "味噌汁",
    "termReading": "みそしる",
    "termTranslation": "miso soup",
    "ex": "私は毎日味噌汁を飲みます。",
    "exReading": "わたし は まいにち みそしる を のみます 。",
    "exTranslation": "I have miso soup every day.",
    "category": "Noun"
  },
  {
    "term": "姪",
    "termReading": "めい",
    "termTranslation": "niece",
    "ex": "私の姪は3才です。",
    "exReading": "わたし の めい は さんさい です",
    "exTranslation": "My nieces are three years old.",
    "category": "Noun"
  },
  {
    "term": "苺",
    "termReading": "いちご",
    "termTranslation": "strawberry",
    "ex": "この苺はとても甘い。",
    "exReading": "この いちご は とても あまい",
    "exTranslation": "This strawberry is very sweet.",
    "category": "Noun"
  },
  {
    "term": "茄子",
    "termReading": "なす",
    "termTranslation": "eggplant",
    "ex": "夕飯に茄子の天ぷらを食べました。",
    "exReading": "ゆうはん に なす の てんぷら を たべました 。",
    "exTranslation": "I ate eggplant tempura for dinner.",
    "category": "Noun"
  },
  {
    "term": "逢う",
    "termReading": "あう",
    "termTranslation": "meet",
    "ex": "ついに素晴らしい女性に逢えた。",
    "exReading": "ついに すばらしい じょせい に あえた 。",
    "exTranslation": "I've finally met a wonderful woman.",
    "category": "Verb"
  },
  {
    "term": "椅子",
    "termReading": "いす",
    "termTranslation": "chair",
    "ex": "そのお年寄りは椅子に座った。",
    "exReading": "その お としより は いす に すわった 。",
    "exTranslation": "That old person sat on a chair.",
    "category": "Noun"
  },
  {
    "term": "痩せる",
    "termReading": "やせる",
    "termTranslation": "lose weight, become thin",
    "ex": "私は少し痩せました。",
    "exReading": "わたし は すこし やせました",
    "exTranslation": "I've lost a little weight.",
    "category": "Verb"
  },
  {
    "term": "箸",
    "termReading": "はし",
    "termTranslation": "chopsticks",
    "ex": "箸を上手に使えるよ。",
    "exReading": "はし を じょうず に つかえる よ",
    "exTranslation": "I'm good at using chopsticks.",
    "category": "Noun"
  },
  {
    "term": "糊",
    "termReading": "のり",
    "termTranslation": "glue, starch",
    "ex": "糊で2枚の紙を貼り合わせた。",
    "exReading": "のり で にまい の かみ を はり あわせた",
    "exTranslation": "I stuck two sheets of paper together with glue.",
    "category": "Noun"
  },
  {
    "term": "醤油",
    "termReading": "しょうゆ",
    "termTranslation": "soy sauce",
    "ex": "もう少し醤油を足してください。",
    "exReading": "もうすこし しょうゆ を たして ください 。",
    "exTranslation": "Please add a little more soy sauce.",
    "category": "Noun"
  },
  {
    "term": "鋏",
    "termReading": "はさみ",
    "termTranslation": "scissors, pincers, tongs",
    "ex": "この鋏はよく切れる。",
    "exReading": "この はさみ は よく きれる",
    "exTranslation": "These scissors cut well.",
    "category": "Noun"
  },
  {
    "term": "鞄",
    "termReading": "かばん",
    "termTranslation": "bag, handbag, briefcase",
    "ex": "その黒い鞄は僕のです。",
    "exReading": "その くろい かばん は ぼく の です",
    "exTranslation": "The black briefcase is mine.",
    "category": "Noun"
  },
  {
    "term": "顎",
    "termReading": "あご",
    "termTranslation": "jaw, chin",
    "ex": "顎が痛い。",
    "exReading": "あご が いたい",
    "exTranslation": "My jaw hurts.",
    "category": "Noun"
  },
  {
    "term": "飴",
    "termReading": "あめ",
    "termTranslation": "candy, sweets",
    "ex": "缶に飴が入っています。",
    "exReading": "かん に あめ が はいって います",
    "exTranslation": "There's some candy in the can.",
    "category": "Noun"
  },
  {
    "term": "石鹸",
    "termReading": "せっけん",
    "termTranslation": "soap",
    "ex": "石鹸で手を洗ってください。",
    "exReading": "せっけん で て を あらって ください",
    "exTranslation": "Please wash your hands with soap.",
    "category": "Noun"
  },
  {
    "term": "一時",
    "termReading": "いちじ",
    "termTranslation": "temporarily",
    "ex": "お店は一時休業になったんだ。",
    "exReading": "おみせ は いちじ きゅうぎょう に なった ん だ",
    "exTranslation": "The store was temporarily closed.",
    "category": "Adverb"
  },
  {
    "term": "月日",
    "termReading": "がっぴ",
    "termTranslation": "date",
    "ex": "ここに生年月日を記入してください。",
    "exReading": "ここ に せいねんがっぴ を きにゅう して ください",
    "exTranslation": "Please fill in your date of birth here.",
    "category": "Noun"
  },
  {
    "term": "金もうけ",
    "termReading": "かねもうけ",
    "termTranslation": "making money",
    "ex": "金もうけが彼の趣味だ。",
    "exReading": "かねもうけ が かれ の しゅみ だ",
    "exTranslation": "Moneymaking is his hobby.",
    "category": "Verbal Noun"
  },
  {
    "term": "先に",
    "termReading": "さきに",
    "termTranslation": "before, ahead",
    "ex": "では、私たちは先に出発します。",
    "exReading": "では わたしたち は さきに しゅっぱつ します",
    "exTranslation": "Right, we will set off before you.",
    "category": "Adverb"
  },
  {
    "term": "先日",
    "termReading": "せんじつ",
    "termTranslation": "the other day, a few days ago",
    "ex": "先日の件はどうなりましたか。",
    "exReading": "せんじつ の けん は どう なりました か",
    "exTranslation": "What happened about that matter the other day?",
    "category": "Noun"
  },
  {
    "term": "今日",
    "termReading": "こんにち",
    "termTranslation": "today, these days",
    "ex": "今日の日本の若者は欧米化している。",
    "exReading": "こんにち の にほん の わかもの は おうべいか して いる",
    "exTranslation": "Young people in Japan today are westernized.",
    "category": "Noun"
  },
  {
    "term": "今ごろ",
    "termReading": "いまごろ",
    "termTranslation": "about this time",
    "ex": "今ごろそんなこと言わないで。",
    "exReading": "いまごろ そんな こと いわない で",
    "exTranslation": "Don't say such a thing now.",
    "category": "Noun"
  },
  {
    "term": "ケース",
    "termReading": "ケース",
    "termTranslation": "case",
    "ex": "このケースにはCDが50枚入ります。",
    "exReading": "この けーす に は CD が 50 まい はいります",
    "exTranslation": "50 CDs will fit in this case.",
    "category": "Noun"
  },
  {
    "term": "今にも",
    "termReading": "いまにも",
    "termTranslation": "at any moment",
    "ex": "今にも雨が降りそうですね。",
    "exReading": "いまにも あめ が ふりそう です ね",
    "exTranslation": "It looks like it's going to rain any moment now.",
    "category": "Adverb"
  },
  {
    "term": "今に",
    "termReading": "いまに",
    "termTranslation": "before long, someday",
    "ex": "あなたも今に分かるでしょう。",
    "exReading": "あなた も いまに わかる でしょう",
    "exTranslation": "You will understand one day.",
    "category": "Adverb"
  },
  {
    "term": "行き来",
    "termReading": "いきき",
    "termTranslation": "comings and goings, communication",
    "ex": "最近彼のところに行き来してないの。",
    "exReading": "さいきん かれ の ところ に いきき して ない の",
    "exTranslation": "I haven't been to his place lately.",
    "category": "Verbal Noun"
  },
  {
    "term": "行",
    "termReading": "ぎょう",
    "termTranslation": "line, row",
    "ex": "5行以内で答えを書いてください。",
    "exReading": "ごぎょう いない で こたえ を かいて ください",
    "exTranslation": "Please write your answer within five lines.",
    "category": "Noun"
  },
  {
    "term": "帰す",
    "termReading": "かえす",
    "termTranslation": "send home, see out",
    "ex": "学校は生徒たちを午前中に帰したね。",
    "exReading": "がっこう は せいとたち を ごぜんちゅう に かえした ね",
    "exTranslation": "The school dismissed students in the morning.",
    "category": "Verb"
  },
  {
    "term": "大いに",
    "termReading": "おおいに",
    "termTranslation": "very, greatly",
    "ex": "大いに学び、大いに遊びなさい。",
    "exReading": "おおいに まなび おおいに あそびなさい",
    "exTranslation": "Study hard, play hard.",
    "category": "Adverb"
  },
  {
    "term": "大げさ",
    "termReading": "おおげさ",
    "termTranslation": "exaggerate",
    "ex": "彼の話は大げさだ。",
    "exReading": "かれ の はなし は おおげさ だ",
    "exTranslation": "His story is exaggerated.",
    "category": "Adjective"
  },
  {
    "term": "イメージ",
    "termReading": "イメージ",
    "termTranslation": "image",
    "ex": "彼には清潔なイメージがあるね。",
    "exReading": "かれ に は せいけつ な いめーじ が ある ね",
    "exTranslation": "He has a clean image.",
    "category": "Verbal Noun"
  },
  {
    "term": "大水",
    "termReading": "おおみず",
    "termTranslation": "flood, inundation",
    "ex": "その年、この地域では大水がありました。",
    "exReading": "その とし この ちいき で は おおみず が ありました",
    "exTranslation": "In that year there was a flood in this area.",
    "category": "Noun"
  },
  {
    "term": "水中",
    "termReading": "すいちゅう",
    "termTranslation": "in the water, underwater",
    "ex": "このカメラなら水中の写真が撮れますね。",
    "exReading": "この かめら なら すいちゅう の しゃしん が とれます ね",
    "exTranslation": "With this camera you can take underwater photographs .",
    "category": "Noun"
  },
  {
    "term": "小",
    "termReading": "しょう",
    "termTranslation": "smallness, small size",
    "ex": "この箱の小をください。",
    "exReading": "この はこ の しょう を ください",
    "exTranslation": "Please give me the small version of this box.",
    "category": "Noun"
  },
  {
    "term": "少なくとも",
    "termReading": "すくなくとも",
    "termTranslation": "at least",
    "ex": "この仕事には少なくとも2週間必要でしょう。",
    "exReading": "この しごと に は すくなくとも にしゅうかん ひつよう でしょう",
    "exTranslation": "At least two weeks will be needed for this job.",
    "category": "Adverb"
  },
  {
    "term": "少々",
    "termReading": "しょうしょう",
    "termTranslation": "a little, a few",
    "ex": "少々のことは我慢します。",
    "exReading": "しょうしょう の こと は がまん します",
    "exTranslation": "I can put up with minor things.",
    "category": "Noun"
  },
  {
    "term": "上がる",
    "termReading": "あがる",
    "termTranslation": "be completed",
    "ex": "今日は仕事が早く上がったんだ。",
    "exReading": "きょう は しごと が はやく あがった ん だ",
    "exTranslation": "I finished work early today.",
    "category": "None"
  },
  {
    "term": "上がる",
    "termReading": "あがる",
    "termTranslation": "eat, drink",
    "ex": "冷めないうちにどうぞお上がり下さい。",
    "exReading": "さめない うち に どうぞ おあがり ください",
    "exTranslation": "Please eat it while it is still hot.",
    "category": "Verb"
  },
  {
    "term": "せい",
    "termReading": "せい",
    "termTranslation": "cause, fault",
    "ex": "自分の失敗を他人のせいにするな。",
    "exReading": "じぶん の しっぱい を たにん の せい に する な",
    "exTranslation": "Don't blame others for your failure.",
    "category": "Noun"
  },
  {
    "term": "上",
    "termReading": "じょう",
    "termTranslation": "upper, best",
    "ex": "握り寿司の上を注文したよ。",
    "exReading": "にぎりずし の じょう を ちゅうもん した よ",
    "exTranslation": "I ordered the best hand-rolled sushi.",
    "category": "Noun"
  },
  {
    "term": "上",
    "termReading": "かみ",
    "termTranslation": "superiors, people in authority",
    "ex": "お上は何を考えてるんだろうね。",
    "exReading": "おかみ は なに を かんがえて る ん だろう ね",
    "exTranslation": "What is the government planning on?",
    "category": "Noun"
  },
  {
    "term": "下さる",
    "termReading": "くださる",
    "termTranslation": "give, favor with",
    "ex": "先生が手紙を下さいました。",
    "exReading": "せんせい が てがみ を くださいました",
    "exTranslation": "The teacher gave me a letter.",
    "category": "Verb"
  },
  {
    "term": "下水",
    "termReading": "げすい",
    "termTranslation": "sewerage, drainage",
    "ex": "その道は今、下水の工事をしているよ。",
    "exReading": "その みち は いま げすい の こうじ を して いる よ",
    "exTranslation": "The street is now under construction for drainage.",
    "category": "Noun"
  },
  {
    "term": "上下",
    "termReading": "じょうげ",
    "termTranslation": "top and bottom",
    "ex": "この服は上下セットで買いました。",
    "exReading": "この ふく は じょうげ せっと で かいました",
    "exTranslation": "I bought these clothes as a top and bottoms set.",
    "category": "Verbal Noun"
  },
  {
    "term": "上下",
    "termReading": "うえした",
    "termTranslation": "above and below",
    "ex": "彼は上下おそろいの服を着ていますね。",
    "exReading": "かれ は うえした おそろい の ふく を きています ね 。",
    "exTranslation": "He is wearing a matching top and bottom.",
    "category": "Noun"
  },
  {
    "term": "下",
    "termReading": "げ",
    "termTranslation": "low class, inferiority, below",
    "ex": "彼女の成績は上の下です。",
    "exReading": "かのじょ の せいせき は じょう の げ です",
    "exTranslation": "Her grades are ranked at the bottom of the top group.",
    "category": "Noun"
  },
  {
    "term": "左右",
    "termReading": "さゆう",
    "termTranslation": "right and left",
    "ex": "左右を見てから横断歩道を渡りなさい。",
    "exReading": "さゆう を みてから おうだん ほどう を わたりなさい",
    "exTranslation": "Look left and right before crossing the pedestrian crossing.",
    "category": "Noun"
  },
  {
    "term": "いつも",
    "termReading": "いつも",
    "termTranslation": "always, usually",
    "ex": "彼女の様子がいつもと違う。",
    "exReading": "かのじょ の ようす が いつも と ちがう",
    "exTranslation": "She looks different than usual.",
    "category": "Noun"
  },
  {
    "term": "四方",
    "termReading": "しほう",
    "termTranslation": "all directions, far and wide",
    "ex": "火が四方に広がったんだよ。",
    "exReading": "ひ が しほう に ひろがった ん だ よ",
    "exTranslation": "The fire has spread in all directions.",
    "category": "Noun"
  },
  {
    "term": "方々",
    "termReading": "かたがた",
    "termTranslation": "people",
    "ex": "大勢の方々にご出席いただきました。",
    "exReading": "おおぜい の かたがた に ごしゅっせき いただきました",
    "exTranslation": "It was attended by a lot of people.",
    "category": "Noun"
  },
  {
    "term": "大人",
    "termReading": "おとな",
    "termTranslation": "adult",
    "ex": "あなたもだいぶ大人になったね。",
    "exReading": "あなた も だいぶ おとな に なった ね",
    "exTranslation": "You've grown up quite a bit.",
    "category": "Adjective"
  },
  {
    "term": "外出",
    "termReading": "がいしゅつ",
    "termTranslation": "outing",
    "ex": "午後は外出の予定です。",
    "exReading": "ごご は がいしゅつ の よてい です",
    "exTranslation": "I plan to go out this afternoon.",
    "category": "Verbal Noun"
  },
  {
    "term": "内",
    "termReading": "うち",
    "termTranslation": "inside",
    "ex": "心の内をお話し下さい。",
    "exReading": "こころ の うち を お はなし ください",
    "exTranslation": "Please speak your mind.",
    "category": "Noun"
  },
  {
    "term": "週休",
    "termReading": "しゅうきゅう",
    "termTranslation": "weekend",
    "ex": "うちの会社は週休2日です。",
    "exReading": "うち の かいしゃ は しゅうきゅう ふつか です",
    "exTranslation": "Our company has two days off a week.",
    "category": "Noun"
  },
  {
    "term": "一体",
    "termReading": "いったい",
    "termTranslation": "what on earth, what the…",
    "ex": "一体何事ですか。",
    "exReading": "いったい なにごと です か",
    "exTranslation": "What on earth is going on?",
    "category": "Adverb"
  },
  {
    "term": "きっかけ",
    "termReading": "きっかけ",
    "termTranslation": "chance, opportunity",
    "ex": "大学に通うのが上京のきっかけでした。",
    "exReading": "だいがく に かよう の が じょうきょう の きっかけ でした",
    "exTranslation": "The reason I came to Tokyo was to go to university.",
    "category": "Noun"
  },
  {
    "term": "出力",
    "termReading": "しゅつりょく",
    "termTranslation": "output",
    "ex": "このプリンターで写真を出力できます。",
    "exReading": "この ぷりんたー で しゃしん を しゅつりょく できます",
    "exTranslation": "You can output pictures with this printer.",
    "category": "Verbal Noun"
  },
  {
    "term": "火力",
    "termReading": "かりょく",
    "termTranslation": "heating power, heat",
    "ex": "火力を弱めないと肉がこげるよ。",
    "exReading": "かりょく を よわめない と にく が こげる よ",
    "exTranslation": "The meat's going to burn if you don't lower the heat.",
    "category": "Noun"
  },
  {
    "term": "水力",
    "termReading": "すいりょく",
    "termTranslation": "waterpower, hydraulic power",
    "ex": "この島は発電を水力に頼っているんだ。",
    "exReading": "この しま は はつでん を すいりょく に たよって いる ん だ",
    "exTranslation": "This island relies on hydroelectric energy to generate power.",
    "category": "Noun"
  },
  {
    "term": "口げんか",
    "termReading": "くちげんか",
    "termTranslation": "quarrel",
    "ex": "きのう、弟と口げんかしました。",
    "exReading": "きのう おとうと と くちげんか しました",
    "exTranslation": "I quarreled with my younger brother yesterday.",
    "category": "Verbal Noun"
  },
  {
    "term": "大手",
    "termReading": "おおて",
    "termTranslation": "major companies",
    "ex": "彼は大手のメーカーに勤めています。",
    "exReading": "かれ は おおて の めーかー に つとめて います",
    "exTranslation": "He works for a big manufacturing company.",
    "category": "Noun"
  },
  {
    "term": "足下",
    "termReading": "あしもと",
    "termTranslation": "step, under foot",
    "ex": "暗いので足下に気を付けてください。",
    "exReading": "くらい の で あしもと に き を つけて ください",
    "exTranslation": "Please watch your step in the dark.",
    "category": "Noun"
  },
  {
    "term": "火山",
    "termReading": "かざん",
    "termTranslation": "volcano",
    "ex": "島で火山が噴火したよ。",
    "exReading": "しま で かざん が ふんか した よ",
    "exTranslation": "The volcano erupted on the island.",
    "category": "Noun"
  },
  {
    "term": "すっかり",
    "termReading": "すっかり",
    "termTranslation": "all, completely",
    "ex": "買い物をすっかり忘れていた。",
    "exReading": "かいもの を すっかり わすれて いた",
    "exTranslation": "I completely forgot the shopping.",
    "category": "Adverb"
  },
  {
    "term": "小川",
    "termReading": "おがわ",
    "termTranslation": "brook, stream",
    "ex": "小川がさらさら流れています。",
    "exReading": "おがわ が さらさら ながれて います",
    "exTranslation": "The stream flows smoothly.",
    "category": "Noun"
  },
  {
    "term": "空中",
    "termReading": "くうちゅう",
    "termTranslation": "in the air, in the sky",
    "ex": "蝶々が空中を舞っています。",
    "exReading": "ちょうちょう が くうちゅう を まって います",
    "exTranslation": "The butterflies are flying in the air.",
    "category": "Noun"
  },
  {
    "term": "空ける",
    "termReading": "あける",
    "termTranslation": "empty, clear out",
    "ex": "彼女はお年寄りのために席を空けたんだ。",
    "exReading": "かのじょ は おとしより の ため に せき を あけた ん だ",
    "exTranslation": "She gave up her seat to the elderly person.",
    "category": "Verb"
  },
  {
    "term": "空っぽ",
    "termReading": "からっぽ",
    "termTranslation": "empty",
    "ex": "僕の財布は空っぽだよ。",
    "exReading": "ぼく の さいふ は からっぽ だ よ",
    "exTranslation": "My purse is empty.",
    "category": "Adjectival Noun"
  },
  {
    "term": "空き",
    "termReading": "あき",
    "termTranslation": "vacancy, free time",
    "ex": "部屋の空きはありますか。",
    "exReading": "へや の あき は あります か",
    "exTranslation": "Are there any vacant rooms?",
    "category": "Noun"
  },
  {
    "term": "空",
    "termReading": "から",
    "termTranslation": "emptiness, hollow",
    "ex": "この瓶はもう空ね。",
    "exReading": "この びん は もう から ね",
    "exTranslation": "This bottle is already empty.",
    "category": "Noun"
  },
  {
    "term": "海水",
    "termReading": "かいすい",
    "termTranslation": "seawater",
    "ex": "海水から塩を作ります。",
    "exReading": "かいすい から しお を つくります",
    "exTranslation": "Salt is made from seawater.",
    "category": "Noun"
  },
  {
    "term": "海上",
    "termReading": "かいじょう",
    "termTranslation": "maritime",
    "ex": "海上で衝突事故が発生した。",
    "exReading": "かいじょう で しょうとつ じこ が はっせい した",
    "exTranslation": "There was a collision at sea.",
    "category": "Noun"
  },
  {
    "term": "シリーズ",
    "termReading": "シリーズ",
    "termTranslation": "series",
    "ex": "このシリーズは50話もあるそうよ。",
    "exReading": "この しりーず は ごじゅうわ も ある そう よ",
    "exTranslation": "I hear that there are as many as 50 stories in the series.",
    "category": "Noun"
  },
  {
    "term": "水田",
    "termReading": "すいでん",
    "termTranslation": "paddy field, rice field",
    "ex": "窓の外に水田が広がっていたよ。",
    "exReading": "まど の そと に すいでん が ひろがって いた よ",
    "exTranslation": "A sweep of rice field appeared outside the window.",
    "category": "Noun"
  },
  {
    "term": "森林",
    "termReading": "しんりん",
    "termTranslation": "forest, woodland",
    "ex": "世界中で森林が失われています。",
    "exReading": "せかいじゅう で しんりん が うしなわれて います",
    "exTranslation": "Forests are being lost all over the world.",
    "category": "Noun"
  },
  {
    "term": "男らしい",
    "termReading": "おとこらしい",
    "termTranslation": "manly, masculine",
    "ex": "彼は男らしさを心掛けているね。",
    "exReading": "かれ は おとこらしさ を こころがけて いる ね",
    "exTranslation": "He's making an effort to appear manly.",
    "category": "Adjective"
  },
  {
    "term": "女らしい",
    "termReading": "おんならしい",
    "termTranslation": "womanly, feminine",
    "ex": "彼女は女らしい。",
    "exReading": "かのじょ は おんならしい",
    "exTranslation": "She's feminine.",
    "category": "Adjective"
  },
  {
    "term": "少女",
    "termReading": "しょうじょ",
    "termTranslation": "girl",
    "ex": "少女は母親の手を握った。",
    "exReading": "しょうじょ は ははおや の て を にぎった",
    "exTranslation": "The girl clasped her mother's hand.",
    "category": "Noun"
  },
  {
    "term": "女子",
    "termReading": "じょし",
    "termTranslation": "woman, girl",
    "ex": "このクラスの女子は18人です。",
    "exReading": "この くらす の じょし は じゅうはちにん です",
    "exTranslation": "There are 18 girls in this class.",
    "category": "Noun"
  },
  {
    "term": "好む",
    "termReading": "このむ",
    "termTranslation": "like, be fond of",
    "ex": "彼女は背の高い男性を好みますね。",
    "exReading": "かのじょ は せ の たかい だんせい を このみます ね",
    "exTranslation": "She prefers tall men.",
    "category": "Verb"
  },
  {
    "term": "エイズ",
    "termReading": "エイズ",
    "termTranslation": "AIDS",
    "ex": "その国ではエイズが大きな社会問題ね。",
    "exReading": "その くに で は えいず が おおき な しゃかい もんだい ね",
    "exTranslation": "AIDS is a major social issue in that country.",
    "category": "Noun"
  },
  {
    "term": "好み",
    "termReading": "このみ",
    "termTranslation": "taste, preference",
    "ex": "姉と私は服の好みが似ています。",
    "exReading": "あね と わたし は ふく の このみ が にて います",
    "exTranslation": "My elder sister and I have the same taste in clothes.",
    "category": "Noun"
  },
  {
    "term": "上達",
    "termReading": "じょうたつ",
    "termTranslation": "progress, proficiency",
    "ex": "短い時間にずいぶん上達しましたね。",
    "exReading": "みじかい じかん に ずいぶん じょうたつ しました ね",
    "exTranslation": "You have progressed well in a short time.",
    "category": "Verbal Noun"
  },
  {
    "term": "一家",
    "termReading": "いっか",
    "termTranslation": "household, family",
    "ex": "あの一家は仲がいい。",
    "exReading": "あの いっか は なか が いい",
    "exTranslation": "That family is very close.",
    "category": "Noun"
  },
  {
    "term": "家出",
    "termReading": "いえで",
    "termTranslation": "running away from home",
    "ex": "彼の息子が家出したそうよ。",
    "exReading": "かれ の むすこ が いえで した そう よ",
    "exTranslation": "I hear that his son left home.",
    "category": "Verbal Noun"
  },
  {
    "term": "大家",
    "termReading": "おおや",
    "termTranslation": "landlord",
    "ex": "ここの大家は近くに住んでいますよ。",
    "exReading": "ここ の おおや は ちかく に すんで います よ",
    "exTranslation": "The landlord lives near here.",
    "category": "Noun"
  },
  {
    "term": "元来",
    "termReading": "がんらい",
    "termTranslation": "originally, essentially",
    "ex": "彼は元来、真面目な人です。",
    "exReading": "かれ は がんらい まじめ な ひと です",
    "exTranslation": "He is essentially a serious person.",
    "category": "Adverb"
  },
  {
    "term": "元日",
    "termReading": "がんじつ",
    "termTranslation": "New Year's Day",
    "ex": "元日には初詣でに行きます。",
    "exReading": "がんじつ に は はつもうで に いきます",
    "exTranslation": "I visit a shrine on New Year's Day for the first prayer.",
    "category": "Noun"
  },
  {
    "term": "エンジン",
    "termReading": "エンジン",
    "termTranslation": "engine",
    "ex": "車のエンジンが調子悪い。",
    "exReading": "くるま の えんじん が ちょうし わるい",
    "exTranslation": "The car engine is in a bad condition.",
    "category": "Noun"
  },
  {
    "term": "お中元",
    "termReading": "おちゅうげん",
    "termTranslation": "mid-year gift, summer gift",
    "ex": "お中元にビールが届きました。",
    "exReading": "おちゅうげん に びーる が とどきました",
    "exTranslation": "We received some beer as a summer gift.",
    "category": "Noun"
  },
  {
    "term": "天の川",
    "termReading": "あまのがわ",
    "termTranslation": "Milky Way",
    "ex": "今夜は天の川が見えますね。",
    "exReading": "こんや は あまのがわ が みえます ね",
    "exTranslation": "The Milky Way can be seen tonight.",
    "category": "Noun"
  },
  {
    "term": "気分",
    "termReading": "きぶん",
    "termTranslation": "feeling, mood",
    "ex": "今日は最高にいい気分だよ。",
    "exReading": "きょう は さいこう に いい きぶん だ よ",
    "exTranslation": "I feel on top of the world today.",
    "category": "Noun"
  },
  {
    "term": "元気",
    "termReading": "げんき",
    "termTranslation": "vigor, spirits",
    "ex": "彼女は最近元気がないですね。",
    "exReading": "かのじょ は さいきん げんき が ない です ね",
    "exTranslation": "She has been in low spirits recently.",
    "category": "Noun"
  },
  {
    "term": "気体",
    "termReading": "きたい",
    "termTranslation": "gas, vapor",
    "ex": "水が沸騰して気体になったんだ。",
    "exReading": "みず が ふっとう して きたい に なった ん だ",
    "exTranslation": "The water boiled and then turned into gas.",
    "category": "Noun"
  },
  {
    "term": "気力",
    "termReading": "きりょく",
    "termTranslation": "energy, vitality",
    "ex": "彼は気力にあふれていますね。",
    "exReading": "かれ は きりょく に あふれて います ね",
    "exTranslation": "He really has energy to spare.",
    "category": "Noun"
  },
  {
    "term": "大雨",
    "termReading": "おおあめ",
    "termTranslation": "heavy rain",
    "ex": "各地で大雨が降っています。",
    "exReading": "かくち で おおあめ が ふって います",
    "exTranslation": "Heavy rain is falling in all regions.",
    "category": "Noun"
  },
  {
    "term": "クラス",
    "termReading": "クラス",
    "termTranslation": "class, lesson",
    "ex": "彼はビジネスクラスに乗ったの。",
    "exReading": "かれ は びじねすくらす に のった の",
    "exTranslation": "He traveled business class.",
    "category": "Noun"
  },
  {
    "term": "小雨",
    "termReading": "こさめ",
    "termTranslation": "drizzle",
    "ex": "小雨なので傘はいりません。",
    "exReading": "こさめ な の で かさ は いりません",
    "exTranslation": "I don't need an umbrella as it is only drizzling.",
    "category": "Noun"
  },
  {
    "term": "雨天",
    "termReading": "うてん",
    "termTranslation": "rainy weather",
    "ex": "試合は雨天中止です。",
    "exReading": "しあい は うてん ちゅうし です",
    "exTranslation": "The match is canceled due to rain.",
    "category": "Noun"
  },
  {
    "term": "大雪",
    "termReading": "おおゆき",
    "termTranslation": "heavy snow",
    "ex": "10年振りの大雪です。",
    "exReading": "じゅうねん ぶり の おおゆき です",
    "exTranslation": "This is the heaviest snow in ten years.",
    "category": "Noun"
  },
  {
    "term": "青年",
    "termReading": "せいねん",
    "termTranslation": "youth, young man",
    "ex": "町の青年たちはボランティア活動をしています。",
    "exReading": "まち の せいねんたち は ぼらんてぃあ かつどう を して います",
    "exTranslation": "Young people in the town are doing volunteer work.",
    "category": "Noun"
  },
  {
    "term": "青空",
    "termReading": "あおぞら",
    "termTranslation": "blue sky",
    "ex": "雲一つない青空ですね。",
    "exReading": "くも ひとつ ない あおぞら です ね",
    "exTranslation": "It is a cloudless blue sky.",
    "category": "Noun"
  },
  {
    "term": "明日",
    "termReading": "あす",
    "termTranslation": "tomorrow",
    "ex": "明日のプレゼンテーションが心配だ。",
    "exReading": "あす の ぷれぜんてーしょん が しんぱい だ",
    "exTranslation": "I'm worried about tomorrow's presentation.",
    "category": "Noun"
  },
  {
    "term": "明ける",
    "termReading": "あける",
    "termTranslation": "to dawn",
    "ex": "もうすぐ夜が明けるね。",
    "exReading": "もうすぐ よ が あける ね",
    "exTranslation": "It will be dawn soon.",
    "category": "Verb"
  },
  {
    "term": "明かり",
    "termReading": "あかり",
    "termTranslation": "light",
    "ex": "部屋の明かりを点けましょう。",
    "exReading": "へや の あかり を つけましょう",
    "exTranslation": "Let's turn on the room light.",
    "category": "Noun"
  },
  {
    "term": "スピード",
    "termReading": "スピード",
    "termTranslation": "speed",
    "ex": "カーブを曲がるときはスピードを落としましょう。",
    "exReading": "かーぶ を まがる とき は すぴーど を おとしましょう",
    "exTranslation": "You should slow down when driving around a bend.",
    "category": "Noun"
  },
  {
    "term": "明け方",
    "termReading": "あけがた",
    "termTranslation": "dawn, near daybreak",
    "ex": "明け方に雨が降り始めましたね。",
    "exReading": "あけがた に あめ が ふりはじめました ね",
    "exTranslation": "It began to rain at dawn.",
    "category": "Noun"
  },
  {
    "term": "一昨年",
    "termReading": "いっさくねん",
    "termTranslation": "the year before last",
    "ex": "大学卒業は一昨年です。",
    "exReading": "だいがく そつぎょう は いっさくねん です",
    "exTranslation": "I graduated from university two years ago.",
    "category": "Noun"
  },
  {
    "term": "一昨日",
    "termReading": "いっさくじつ",
    "termTranslation": "the day before yesterday",
    "ex": "一昨日、お電話を差し上げました。",
    "exReading": "いっさくじつ おでんわ を さしあげました",
    "exTranslation": "I gave you a call the day before yesterday.",
    "category": "Noun"
  },
  {
    "term": "昨日",
    "termReading": "さくじつ",
    "termTranslation": "yesterday",
    "ex": "昨日は雨でしたね。",
    "exReading": "さくじつ は あめ でした ね",
    "exTranslation": "It rained yesterday.",
    "category": "Noun"
  },
  {
    "term": "向上",
    "termReading": "こうじょう",
    "termTranslation": "improvement",
    "ex": "全員で技術の向上に努めています。",
    "exReading": "ぜんいん で ぎじゅつ の こうじょう に つとめて います",
    "exTranslation": "We all work together to improve our skills.",
    "category": "Verbal Noun"
  },
  {
    "term": "一向に",
    "termReading": "いっこうに",
    "termTranslation": "absolutely, at all",
    "ex": "彼は一向に興味を示さないの。",
    "exReading": "かれ は いっこうに きょうみ を しめさない の",
    "exTranslation": "He doesn't show any interest at all.",
    "category": "Adverb"
  },
  {
    "term": "開き",
    "termReading": "あき",
    "termTranslation": "gap, vacancy",
    "ex": "このブラウスは後ろ開きです。",
    "exReading": "この ぶらうす は うしろあき です",
    "exTranslation": "This blouse opens from the back.",
    "category": "Noun"
  },
  {
    "term": "いかに",
    "termReading": "いかに",
    "termTranslation": "how, in what way",
    "ex": "彼に会えば、彼がいかに良い人か分かります。",
    "exReading": "かれ に あえば かれ が いかに いい ひと か わかります",
    "exTranslation": "If you met him you'd realize what a nice person he is.",
    "category": "Adverb"
  },
  {
    "term": "聞かす",
    "termReading": "きかす",
    "termTranslation": "tell, read aloud",
    "ex": "子供に昔話を聞かせてあげたの。",
    "exReading": "こども に むかしばなし を きかせてあげた の 。",
    "exTranslation": "I read a story to the children.",
    "category": "Verb"
  },
  {
    "term": "聞き手",
    "termReading": "ききて",
    "termTranslation": "listener, audience",
    "ex": "彼女はいつも聞き手にまわるね。",
    "exReading": "かのじょ は いつも ききて に まわる ね",
    "exTranslation": "She always turns to the listener.",
    "category": "Noun"
  },
  {
    "term": "客間",
    "termReading": "きゃくま",
    "termTranslation": "guest room, parlor",
    "ex": "お客さんを客間にお通ししたわよ。",
    "exReading": "おきゃくさん を きゃくま に おとおし した わ よ",
    "exTranslation": "I showed the guest into the reception room.",
    "category": "Noun"
  },
  {
    "term": "間",
    "termReading": "かん",
    "termTranslation": "interval, duration of time",
    "ex": "その間に彼は居なくなっていました。",
    "exReading": "その かん に かれ は いなく なって いました",
    "exTranslation": "He disappeared in the meantime.",
    "category": "Noun"
  },
  {
    "term": "空間",
    "termReading": "くうかん",
    "termTranslation": "space, room",
    "ex": "狭い空間に物がたくさん置いてあるね。",
    "exReading": "せまい くうかん に もの が たくさん おいて ある ね",
    "exTranslation": "A lot of things have been put into a tight space.",
    "category": "Noun"
  },
  {
    "term": "円高",
    "termReading": "えんだか",
    "termTranslation": "appreciation of the yen",
    "ex": "円高の影響で海外製品が安く買えますよ。",
    "exReading": "えんだか の えいきょう で かいがい せいひん が やすく かえます よ",
    "exTranslation": "Overseas products can be bought more cheaply because of the strong yen.",
    "category": "Noun"
  },
  {
    "term": "最高",
    "termReading": "さいこう",
    "termTranslation": "maximum, supreme",
    "ex": "これまでで最高の結果が出たよ。",
    "exReading": "これまで で さいこう の けっか が でた よ",
    "exTranslation": "I got my best results so far.",
    "category": "Noun"
  },
  {
    "term": "スタート",
    "termReading": "スタート",
    "termTranslation": "start, begin",
    "ex": "マラソンは雨の中でスタートしたの。",
    "exReading": "まらそん は あめ の なか で すたーと した の",
    "exTranslation": "The marathon started in the rain.",
    "category": "Verbal Noun"
  },
  {
    "term": "最低",
    "termReading": "さいてい",
    "termTranslation": "lowest",
    "ex": "これは今までで最低の記録だ。",
    "exReading": "これ は いま まで で さいてい の きろく だ",
    "exTranslation": "This is the worst record so far.",
    "category": "Noun"
  },
  {
    "term": "最小",
    "termReading": "さいしょう",
    "termTranslation": "the smallest, minimum",
    "ex": "これは世界で最小のコンピューターです。",
    "exReading": "これ は せかい で さいしょう の こんぴゅーたー です",
    "exTranslation": "This is the smallest computer in the world.",
    "category": "Noun"
  },
  {
    "term": "最上",
    "termReading": "さいじょう",
    "termTranslation": "best, highest",
    "ex": "このホテルでは最上のサービスが受けられます。",
    "exReading": "この ほてる で は さいじょう の さーびす が うけられます",
    "exTranslation": "You can get the best service at this hotel.",
    "category": "Noun"
  },
  {
    "term": "最中",
    "termReading": "さいちゅう",
    "termTranslation": "in the middle of, during",
    "ex": "夕食の最中に電話がかかってきたの。",
    "exReading": "ゆうしょく の さいちゅう に でんわ が かかって きた の",
    "exTranslation": "I got a phone call in the middle of supper.",
    "category": "Noun"
  },
  {
    "term": "初日",
    "termReading": "しょにち",
    "termTranslation": "first day, opening day",
    "ex": "会議の初日に市長がスピーチをしたよ。",
    "exReading": "かいぎ の しょにち に しちょう が すぴーち を した よ",
    "exTranslation": "The mayor made a speech on the first day of the conference.",
    "category": "Noun"
  },
  {
    "term": "お前",
    "termReading": "おまえ",
    "termTranslation": "you, darling",
    "ex": "お前の言うことは信じられない。",
    "exReading": "おまえ の いう こと は しんじられ ない",
    "exTranslation": "I can't believe what you say.",
    "category": "Noun"
  },
  {
    "term": "前後",
    "termReading": "ぜんご",
    "termTranslation": "before and after, order",
    "ex": "そちらに着くのは6時前後です。",
    "exReading": "そちら に つく の は ろくじ ぜんご です",
    "exTranslation": "I will arrive there around 6 o'clock.",
    "category": "Noun"
  },
  {
    "term": "後方",
    "termReading": "こうほう",
    "termTranslation": "back, rear",
    "ex": "彼は後方の座席に着いたの。",
    "exReading": "かれ は こうほう の ざせき に ついた の",
    "exTranslation": "He sat down on a rear seat.",
    "category": "Noun"
  },
  {
    "term": "セット",
    "termReading": "セット",
    "termTranslation": "set",
    "ex": "このメニューはサラダと飲み物がセットになっています。",
    "exReading": "この めにゅー は さらだ と のみもの が せっと に なって います",
    "exTranslation": "In this menu, there's a set with a salad and a drink.",
    "category": "Verbal Noun"
  },
  {
    "term": "後ろ向き",
    "termReading": "うしろむき",
    "termTranslation": "facing backward",
    "ex": "彼は車を後ろ向きに駐車したの。",
    "exReading": "かれ は くるま を うしろむき に ちゅうしゃ した の",
    "exTranslation": "He parked the car backwards.",
    "category": "Noun"
  },
  {
    "term": "明々後日",
    "termReading": "しあさって",
    "termTranslation": "three days from today",
    "ex": "会議は明々後日に延期された。",
    "exReading": "かいぎ は しあさって に えんき された",
    "exTranslation": "The meeting was postponed to three days from today.",
    "category": "Noun"
  },
  {
    "term": "後半",
    "termReading": "こうはん",
    "termTranslation": "latter half, second half",
    "ex": "ドラマの後半は来週放送されるんだ。",
    "exReading": "どらま の こうはん は らいしゅう ほうそう される ん だ",
    "exTranslation": "The second half of the drama will be broadcast next week.",
    "category": "Noun"
  },
  {
    "term": "朝日",
    "termReading": "あさひ",
    "termTranslation": "morning sun",
    "ex": "朝日が昇りましたよ。",
    "exReading": "あさひ が のぼりました よ",
    "exTranslation": "The morning sun rose.",
    "category": "Noun"
  },
  {
    "term": "昨晩",
    "termReading": "さくばん",
    "termTranslation": "last night, last evening",
    "ex": "昨晩の雪がまだ庭に残っている。",
    "exReading": "さくばん の ゆき が まだ にわ に のこって いる",
    "exTranslation": "Last night's snow still remains in the garden.",
    "category": "Noun"
  },
  {
    "term": "昨夜",
    "termReading": "さくや",
    "termTranslation": "last night",
    "ex": "昨夜はテレビで喜劇を見たよ。",
    "exReading": "さくや は てれび で きげき を みた よ",
    "exTranslation": "I watched a comedy show on TV last night.",
    "category": "Noun"
  },
  {
    "term": "食う",
    "termReading": "くう",
    "termTranslation": "eat, live on",
    "ex": "腹一杯食った。",
    "exReading": "はらいっぱい くった",
    "exTranslation": "I ate heartily.",
    "category": "Verb"
  },
  {
    "term": "アップ",
    "termReading": "アップ",
    "termTranslation": "close-up",
    "ex": "写真をアップで撮ろう。",
    "exReading": "しゃしん を あっぷ で とろう",
    "exTranslation": "Let's take a close-up photo.",
    "category": "Verbal Noun"
  },
  {
    "term": "外食",
    "termReading": "がいしょく",
    "termTranslation": "eating out, dining out",
    "ex": "たまには外食しましょう。",
    "exReading": "たま に は がいしょく しましょう",
    "exTranslation": "Let's dine out occasionally.",
    "category": "Verbal Noun"
  },
  {
    "term": "食パン",
    "termReading": "しょくぱん",
    "termTranslation": "bread, loaf of bread",
    "ex": "朝食に食パンを2枚食べました。",
    "exReading": "ちょうしょく に しょくぱん を にまい たべました",
    "exTranslation": "I ate two slices of bread for breakfast.",
    "category": "Noun"
  },
  {
    "term": "一見",
    "termReading": "いっけん",
    "termTranslation": "at a glance",
    "ex": "彼は一見サラリーマン風ですね。",
    "exReading": "かれ は いっけん さらりーまんふう です ね",
    "exTranslation": "He looks like a businessman at a glance.",
    "category": "Adverb"
  },
  {
    "term": "言い出す",
    "termReading": "いいだす",
    "termTranslation": "begin to speak",
    "ex": "突然何を言い出すのかと思った。",
    "exReading": "とつぜん なに を いいだす の か と おもった",
    "exTranslation": "I wondered what you would say out of the blue.",
    "category": "Verb"
  },
  {
    "term": "外来語",
    "termReading": "がいらいご",
    "termTranslation": "loanword, foreign word",
    "ex": "外来語は一般にカタカナで書かれます。",
    "exReading": "がいらいご は いっぱんに かたかな で かかれます",
    "exTranslation": "In general, loan words are written in katakana.",
    "category": "Noun"
  },
  {
    "term": "英文",
    "termReading": "えいぶん",
    "termTranslation": "English, English writing",
    "ex": "彼女は英文の手紙を書きました。",
    "exReading": "かのじょ は えいぶん の てがみ を かきました",
    "exTranslation": "She wrote an English letter.",
    "category": "Noun"
  },
  {
    "term": "漢語",
    "termReading": "かんご",
    "termTranslation": "Chinese-character compounds",
    "ex": "漢語はもともと外来語です。",
    "exReading": "かんご は もともと がいらいご です",
    "exTranslation": "Kanji compounds are originally loanwords.",
    "category": "Noun"
  },
  {
    "term": "エネルギー",
    "termReading": "エネルギー",
    "termTranslation": "energy",
    "ex": "若者たちはエネルギーにあふれていますね。",
    "exReading": "わかものたち は えねるぎー に あふれて います ね",
    "exTranslation": "Young people are always full of energy.",
    "category": "Noun"
  },
  {
    "term": "下書き",
    "termReading": "したがき",
    "termTranslation": "draft",
    "ex": "今、論文の下書きをしているところです。",
    "exReading": "いま ろんぶん の したがき を して いる ところ です",
    "exTranslation": "I am in the middle of the thesis draft now.",
    "category": "Verbal Noun"
  },
  {
    "term": "後書き",
    "termReading": "あとがき",
    "termTranslation": "postscript, afterword",
    "ex": "後書きをよく読んで下さい。",
    "exReading": "あとがき を よく よんで ください",
    "exTranslation": "Please read the postscript carefully.",
    "category": "Noun"
  },
  {
    "term": "覚え",
    "termReading": "おぼえ",
    "termTranslation": "recollection, memory",
    "ex": "この子は覚えが早いね。",
    "exReading": "この こ は おぼえ が はやい ね",
    "exTranslation": "This kid remembers things quickly.",
    "category": "Noun"
  },
  {
    "term": "開会",
    "termReading": "かいかい",
    "termTranslation": "opening, start a meeting",
    "ex": "運動会は9時に開会します。",
    "exReading": "うんどうかい は くじ に かいかい します",
    "exTranslation": "The sports festival starts at nine o'clock.",
    "category": "Verbal Noun"
  },
  {
    "term": "会",
    "termReading": "かい",
    "termTranslation": "meeting, association",
    "ex": "会は午後9時に終わりました。",
    "exReading": "かい は ごご くじ に おわりました",
    "exTranslation": "The meeting ended at 9:00 pm.",
    "category": "Noun"
  },
  {
    "term": "合わせる",
    "termReading": "あわせる",
    "termTranslation": "adjust, match",
    "ex": "日時はご都合に合わせます。",
    "exReading": "にちじ は ごつごう に あわせます",
    "exTranslation": "The date can be decided according to your schedule.",
    "category": "Verb"
  },
  {
    "term": "合わす",
    "termReading": "あわす",
    "termTranslation": "match, add",
    "ex": "赤に黄色を合わすと何色になりますか。",
    "exReading": "あか に きいろ を あわすと なにいろ に なります か",
    "exTranslation": "What color do you get if you add yellow to red?",
    "category": "Verb"
  },
  {
    "term": "社会人",
    "termReading": "しゃかいじん",
    "termTranslation": "member of society, working person",
    "ex": "この春に彼は社会人になったよ。",
    "exReading": "この はる に かれ は しゃかいじん に なった よ",
    "exTranslation": "He became a working adult this spring.",
    "category": "Noun"
  },
  {
    "term": "かえって",
    "termReading": "かえって",
    "termTranslation": "on the contrary, rather",
    "ex": "そんなことをしたら、かえってよくないよ。",
    "exReading": "そんなことをしたら、かえってよくないよ。",
    "exTranslation": "If you do something like that, it will instead have adverse results.",
    "category": "Adverb"
  },
  {
    "term": "会員",
    "termReading": "かいいん",
    "termTranslation": "member",
    "ex": "彼女はクラブの会員です。",
    "exReading": "かのじょ は くらぶ の かいいん です",
    "exTranslation": "She's a member of the club.",
    "category": "Noun"
  },
  {
    "term": "一員",
    "termReading": "いちいん",
    "termTranslation": "member",
    "ex": "彼は野球部の一員です。",
    "exReading": "かれ は やきゅうぶ の いちいん です",
    "exTranslation": "He is a member of the baseball club.",
    "category": "Noun"
  },
  {
    "term": "仕上げ",
    "termReading": "しあげ",
    "termTranslation": "completion, finishing",
    "ex": "彼は論文の仕上げに入ったの。",
    "exReading": "かれ は ろんぶん の しあげ に はいった の",
    "exTranslation": "He entered the final stages of the thesis.",
    "category": "Verbal Noun"
  },
  {
    "term": "仕上げる",
    "termReading": "しあげる",
    "termTranslation": "finish, complete",
    "ex": "彼はやっと報告書を仕上げたよ。",
    "exReading": "かれ は やっと ほうこくしょ を しあげた よ",
    "exTranslation": "He finally finished up the report.",
    "category": "Verb"
  },
  {
    "term": "人事",
    "termReading": "じんじ",
    "termTranslation": "human affairs, personnel affairs",
    "ex": "彼は人事を担当しているんだ。",
    "exReading": "かれ は じんじ を たんとう して いる ん だ",
    "exTranslation": "He is in charge of personnel affairs.",
    "category": "Noun"
  },
  {
    "term": "行事",
    "termReading": "ぎょうじ",
    "termTranslation": "event, function",
    "ex": "今日は学校で行事がありました。",
    "exReading": "きょう は がっこう で ぎょうじ が ありました",
    "exTranslation": "There was an event at school today.",
    "category": "Noun"
  },
  {
    "term": "事",
    "termReading": "こと",
    "termTranslation": "thing, incident",
    "ex": "そんな事をしてはいけません。",
    "exReading": "そんな こと を して は いけません",
    "exTranslation": "Do not do such a thing.",
    "category": "Noun"
  },
  {
    "term": "コンクリート",
    "termReading": "コンクリート",
    "termTranslation": "concrete",
    "ex": "コンクリートにひびが入っていますね。",
    "exReading": "こんくりーと に ひび が はいって います ね",
    "exTranslation": "The concrete is cracked.",
    "category": "Noun"
  },
  {
    "term": "家事",
    "termReading": "かじ",
    "termTranslation": "housework, domestic duties",
    "ex": "母は毎日てきぱきと家事をしているよ。",
    "exReading": "はは は まいにち てきぱき と かじ を して いる よ",
    "exTranslation": "My mother zips through housework every day.",
    "category": "Noun"
  },
  {
    "term": "人工",
    "termReading": "じんこう",
    "termTranslation": "artificial",
    "ex": "これは人工の湖です。",
    "exReading": "これ は じんこう の みずうみ です",
    "exTranslation": "This is an artificial lake.",
    "category": "Noun"
  },
  {
    "term": "会場",
    "termReading": "かいじょう",
    "termTranslation": "venue, meeting place",
    "ex": "会場は人で一杯になりました。",
    "exReading": "かいじょう は ひと で いっぱい に なりました",
    "exTranslation": "The hall was filled with people.",
    "category": "Noun"
  },
  {
    "term": "出場",
    "termReading": "しゅつじょう",
    "termTranslation": "participation",
    "ex": "今日は8チームが出場しました。",
    "exReading": "きょう は はち ちーむ が しゅつじょう しました",
    "exTranslation": "Eight teams participated today.",
    "category": "Verbal Noun"
  },
  {
    "term": "車内",
    "termReading": "しゃない",
    "termTranslation": "inside a vehicle",
    "ex": "車内に忘れ物があったよ。",
    "exReading": "しゃない に わすれもの が あった よ",
    "exTranslation": "There was something left behind in the car.",
    "category": "Noun"
  },
  {
    "term": "下車",
    "termReading": "げしゃ",
    "termTranslation": "get off, stopover",
    "ex": "景色が良かったので途中下車したよ。",
    "exReading": "けしき が よかった の で とちゅう げしゃ した よ",
    "exTranslation": "We stopped off on the way because the scenery was lovely.",
    "category": "Verbal Noun"
  },
  {
    "term": "駅前",
    "termReading": "えきまえ",
    "termTranslation": "in front of the station",
    "ex": "駅前に交番が有ります。",
    "exReading": "えきまえ に こうばん が あります",
    "exTranslation": "There is a police box in front of the station.",
    "category": "Noun"
  },
  {
    "term": "いよいよ",
    "termReading": "いよいよ",
    "termTranslation": "finally, any moment",
    "ex": "明日はいよいよ出発の日です。",
    "exReading": "あす は いよいよ しゅっぱつ の ひ です",
    "exTranslation": "At last we will set off tomorrow.",
    "category": "Adverb"
  },
  {
    "term": "外交",
    "termReading": "がいこう",
    "termTranslation": "diplomacy, foreign relations",
    "ex": "政府は外交に力を入れているの。",
    "exReading": "せいふ は がいこう に ちから を いれて いる の",
    "exTranslation": "The government is making efforts in diplomacy.",
    "category": "Noun"
  },
  {
    "term": "交わす",
    "termReading": "かわす",
    "termTranslation": "exchange",
    "ex": "彼は私と再会の約束を交わした。",
    "exReading": "かれ は わたし と さいかい の やくそく を かわした",
    "exTranslation": "He promised me that we'd meet again.",
    "category": "Verb"
  },
  {
    "term": "交通",
    "termReading": "こうつう",
    "termTranslation": "traffic, transportation",
    "ex": "ここは交通の便がよいですね。",
    "exReading": "ここ は こうつう の べん が よい です ね",
    "exTranslation": "This place is conveniently located.",
    "category": "Verbal Noun"
  },
  {
    "term": "大通り",
    "termReading": "おおどおり",
    "termTranslation": "main street",
    "ex": "大通りでパレードが始まるよ。",
    "exReading": "おおどおり で ぱれーど が はじまる よ",
    "exTranslation": "The parade starts in the main street.",
    "category": "Noun"
  },
  {
    "term": "書道",
    "termReading": "しょどう",
    "termTranslation": "Japanese calligraphy",
    "ex": "書道をすると姿勢も良くなります。",
    "exReading": "しょどう を する と しせい も よく なります",
    "exTranslation": "Posture also improves when you study calligraphy.",
    "category": "Noun"
  },
  {
    "term": "十字路",
    "termReading": "じゅうじろ",
    "termTranslation": "crossroads",
    "ex": "十字路で車とバイクが衝突したぞ。",
    "exReading": "じゅうじろ で くるま と ばいく が しょうとつ した ぞ",
    "exTranslation": "The car collided with a motorcycle in the crossroads.",
    "category": "Noun"
  },
  {
    "term": "空き地",
    "termReading": "あきち",
    "termTranslation": "unoccupied ground, vacant lot",
    "ex": "空き地で工事が始まった。",
    "exReading": "あきち で こうじ が はじまった",
    "exTranslation": "Construction started in the vacant lot.",
    "category": "Noun"
  },
  {
    "term": "オリンピック",
    "termReading": "オリンピック",
    "termTranslation": "the Olympics",
    "ex": "オリンピックは4年に1度開催されます。",
    "exReading": "おりんぴっく は よねん に いちど かいさい されます",
    "exTranslation": "The Olympics are held once every four years.",
    "category": "Noun"
  },
  {
    "term": "図",
    "termReading": "ず",
    "termTranslation": "figure, map",
    "ex": "図を描いて説明しましょう。",
    "exReading": "ず を かいて せつめい しましょう",
    "exTranslation": "Let me draw a diagram to explain.",
    "category": "Noun"
  },
  {
    "term": "合図",
    "termReading": "あいず",
    "termTranslation": "signal, sign",
    "ex": "車掌が発車の合図をしたよ。",
    "exReading": "しゃしょう が はっしゃ の あいず を した よ",
    "exTranslation": "The conductor gave the signal to depart.",
    "category": "Verbal Noun"
  },
  {
    "term": "行き止まり",
    "termReading": "いきどまり",
    "termTranslation": "dead end",
    "ex": "ここから先は行き止まりです。",
    "exReading": "ここ から さき は いきどまり です",
    "exTranslation": "It is a dead-end ahead.",
    "category": "Noun"
  },
  {
    "term": "初歩",
    "termReading": "しょほ",
    "termTranslation": "first steps, beginning",
    "ex": "英語を初歩から勉強しています。",
    "exReading": "えいご を しょほ から べんきょう して います",
    "exTranslation": "I'm studying English from the beginning.",
    "category": "Noun"
  },
  {
    "term": "高度",
    "termReading": "こうど",
    "termTranslation": "high degree",
    "ex": "高度な技術を持つ人材を集めました。",
    "exReading": "こうど な ぎじゅつ を もつ じんざい を あつめました",
    "exTranslation": "We've attracted highly-skilled personnel.",
    "category": "Adjectival Noun"
  },
  {
    "term": "一度に",
    "termReading": "いちどに",
    "termTranslation": "at a time, at once",
    "ex": "皆の顔と名前を一度には覚えられません。",
    "exReading": "みんな の かお と なまえ を いちどに は おぼえられません",
    "exTranslation": "I can't remember everyone's name and face at once.",
    "category": "Adverb"
  },
  {
    "term": "遠足",
    "termReading": "えんそく",
    "termTranslation": "excursion, hike",
    "ex": "明日は遠足で動物園に行きます。",
    "exReading": "あす は えんそく で どうぶつえん に いきます",
    "exTranslation": "Tomorrow we're going to the zoo on a school trip.",
    "category": "Noun"
  },
  {
    "term": "駅長",
    "termReading": "えきちょう",
    "termTranslation": "stationmaster",
    "ex": "彼は駅長です。",
    "exReading": "かれ は えきちょう です",
    "exTranslation": "He is a stationmaster.",
    "category": "Noun"
  },
  {
    "term": "ストップ",
    "termReading": "ストップ",
    "termTranslation": "stop",
    "ex": "ここでいったんストップしてください。",
    "exReading": "ここ で いったん すとっぷ して ください",
    "exTranslation": "Please stop here at once.",
    "category": "Verbal Noun"
  },
  {
    "term": "全員",
    "termReading": "ぜんいん",
    "termTranslation": "all members, entire staff",
    "ex": "チーム全員に名札が配られました。",
    "exReading": "ちーむ ぜんいん に なふだ が くばられました",
    "exTranslation": "The name tags were distributed to the whole team.",
    "category": "Noun"
  },
  {
    "term": "全て",
    "termReading": "すべて",
    "termTranslation": "all, entirely",
    "ex": "彼に全てのことを伝えた。",
    "exReading": "かれ に すべて の こと を つたえた",
    "exTranslation": "I told him everything.",
    "category": "Adverb"
  },
  {
    "term": "外部",
    "termReading": "がいぶ",
    "termTranslation": "outside, outer",
    "ex": "これは外部には秘密です。",
    "exReading": "これ は がいぶ に は ひみつ です",
    "exTranslation": "This is confidential.",
    "category": "Noun"
  },
  {
    "term": "一部分",
    "termReading": "いちぶぶん",
    "termTranslation": "part, portion",
    "ex": "僕が知っているのは一部分に過ぎない。",
    "exReading": "ぼく が しって いる の は いちぶぶん に すぎない",
    "exTranslation": "What I know is only a portion of it.",
    "category": "Noun"
  },
  {
    "term": "国家",
    "termReading": "こっか",
    "termTranslation": "state, country",
    "ex": "首相は国家のリーダーだ。",
    "exReading": "しゅしょう は こっか の りーだー だ",
    "exTranslation": "The Prime Minister is the leader of the nation.",
    "category": "Noun"
  },
  {
    "term": "国々",
    "termReading": "くにぐに",
    "termTranslation": "nations, countries",
    "ex": "そのマラソン大会にはたくさんの国々から選手が集まったよ。",
    "exReading": "その まらそん たいかい に は たくさん の くにぐに から せんしゅ が あつまった よ",
    "exTranslation": "A lot of athletes from many countries gathered for the marathon.",
    "category": "Noun"
  },
  {
    "term": "国外",
    "termReading": "こくがい",
    "termTranslation": "abroad, outside the country",
    "ex": "犯人は国外に逃げたようです。",
    "exReading": "はんにん は こくがい に にげた よう です",
    "exTranslation": "The criminal seems to have escaped abroad.",
    "category": "Noun"
  },
  {
    "term": "きちんと",
    "termReading": "きちんと",
    "termTranslation": "properly, neatly",
    "ex": "問題についてきちんと話し合ったよ。",
    "exReading": "もんだい に ついて きちんと はなしあった よ",
    "exTranslation": "We discussed the problem thoroughly.",
    "category": "Adverb"
  },
  {
    "term": "出国",
    "termReading": "しゅっこく",
    "termTranslation": "departure, emigration",
    "ex": "1週間後に出国します。",
    "exReading": "いっしゅうかん ご に しゅっこく します",
    "exTranslation": "I will leave the country in one week.",
    "category": "Verbal Noun"
  },
  {
    "term": "国土",
    "termReading": "こくど",
    "termTranslation": "country, territory",
    "ex": "わが国の国土は70%が森林です。",
    "exReading": "わがくに の こくど は ななじゅっぱーせんと が しんりん です",
    "exTranslation": "70 % of the countryside in our country is forest.",
    "category": "Noun"
  },
  {
    "term": "国語",
    "termReading": "こくご",
    "termTranslation": "national language, Japanese",
    "ex": "今日の1時間目は国語です。",
    "exReading": "きょう の いちじかんめ は こくご です",
    "exTranslation": "The first class today is Japanese.",
    "category": "Noun"
  },
  {
    "term": "国交",
    "termReading": "こっこう",
    "termTranslation": "diplomatic relations, national friendship",
    "ex": "あの国とは国交がない。",
    "exReading": "あの くに と は こっこう が ない",
    "exTranslation": "There are no diplomatic relations with that country.",
    "category": "Noun"
  },
  {
    "term": "国道",
    "termReading": "こくどう",
    "termTranslation": "national highway",
    "ex": "この道をまっすぐ進むと国道に出ます。",
    "exReading": "この みち を まっすぐ すすむ と こくどう に でます",
    "exTranslation": "If you carry straight on this road you will come out at the national highway.",
    "category": "Noun"
  },
  {
    "term": "世間",
    "termReading": "せけん",
    "termTranslation": "world, the public",
    "ex": "世間の反応は冷たかったよ。",
    "exReading": "せけん の はんのう は つめたかった よ",
    "exTranslation": "The reaction of the public was cold.",
    "category": "Noun"
  },
  {
    "term": "世話",
    "termReading": "せわ",
    "termTranslation": "take care of, provide service",
    "ex": "旅行の間、犬の世話をしてください。",
    "exReading": "りょこう の あいだ いぬ の せわ を して ください",
    "exTranslation": "Please take care of the dog while we're on our trip.",
    "category": "Verbal Noun"
  },
  {
    "term": "クラブ",
    "termReading": "クラブ",
    "termTranslation": "club",
    "ex": "夏休みにはクラブの合宿に参加します。",
    "exReading": "なつやすみ に は くらぶ の がっしゅく に さんか します",
    "exTranslation": "I attend my club's training camp during summer vacations.",
    "category": "Noun"
  },
  {
    "term": "出世",
    "termReading": "しゅっせ",
    "termTranslation": "success, promotion",
    "ex": "彼は出世するタイプだな。",
    "exReading": "かれ は しゅっせ する たいぷ だ な",
    "exTranslation": "He is the successful type.",
    "category": "Verbal Noun"
  },
  {
    "term": "青白い",
    "termReading": "あおじろい",
    "termTranslation": "pale, pallid",
    "ex": "彼女は青白い顔をしているね。",
    "exReading": "かのじょ は あおじろい かお を して いる ね",
    "exTranslation": "Her face is pale.",
    "category": "Adjective"
  },
  {
    "term": "黒字",
    "termReading": "くろじ",
    "termTranslation": "the black, surplus",
    "ex": "わが社は今年度、黒字となりました。",
    "exReading": "わがしゃ は こんねんど くろじ と なりました",
    "exTranslation": "Our company got into the black this year.",
    "category": "Noun"
  },
  {
    "term": "赤道",
    "termReading": "せきどう",
    "termTranslation": "equator",
    "ex": "赤道に沿って旅をしました。",
    "exReading": "せきどう に そって たび を しました",
    "exTranslation": "I traveled around, following the equator.",
    "category": "Noun"
  },
  {
    "term": "赤字",
    "termReading": "あかじ",
    "termTranslation": "deficit, in the red",
    "ex": "私たちの会社は今月は赤字よ。",
    "exReading": "わたしたち の かいしゃ は こんげつ は あかじ よ",
    "exTranslation": "Our company is in deficit this month.",
    "category": "Noun"
  },
  {
    "term": "国鉄",
    "termReading": "こくてつ",
    "termTranslation": "Japan National Railways",
    "ex": "父は以前、国鉄に勤めていました。",
    "exReading": "ちち は いぜん こくてつ に つとめて いました",
    "exTranslation": "My father used to work for Japan National Railways.",
    "category": "Noun"
  },
  {
    "term": "私鉄",
    "termReading": "してつ",
    "termTranslation": "private railway",
    "ex": "私は私鉄の職員です。",
    "exReading": "わたし は してつ の しょくいん です",
    "exTranslation": "I'm an employee of a private railway company.",
    "category": "Noun"
  },
  {
    "term": "家屋",
    "termReading": "かおく",
    "termTranslation": "house, building",
    "ex": "私は木造の家屋が好きです。",
    "exReading": "わたし は もくぞう の かおく が すき です",
    "exTranslation": "I like houses built of wood.",
    "category": "Noun"
  },
  {
    "term": "じっと",
    "termReading": "じっと",
    "termTranslation": "without moving, still",
    "ex": "あの生徒はじっと先生の話を聞いていたね。",
    "exReading": "あの せいと は じっと せんせい の はなし を きいて いた ね",
    "exTranslation": "The pupil listened quietly to the teacher.",
    "category": "Adverb"
  },
  {
    "term": "屋上",
    "termReading": "おくじょう",
    "termTranslation": "housetop, roof",
    "ex": "屋上から富士山が見えました。",
    "exReading": "おくじょう から ふじさん が みえました",
    "exTranslation": "Mt. Fuji could be seen from the rooftop.",
    "category": "Noun"
  },
  {
    "term": "味わう",
    "termReading": "あじわう",
    "termTranslation": "taste, appreciate",
    "ex": "母の手料理をゆっくり味わいました。",
    "exReading": "はは の てりょうり を ゆっくり あじわいました",
    "exTranslation": "I took my time savoring my mother's homemade cooking.",
    "category": "Verb"
  },
  {
    "term": "地味",
    "termReading": "じみ",
    "termTranslation": "plain, sober",
    "ex": "今日、彼女は地味な服装をしていますね。",
    "exReading": "きょう かのじょ は じみ な ふくそう を して います ね",
    "exTranslation": "She's modestly dressed today.",
    "category": "Adjectival Noun"
  },
  {
    "term": "月末",
    "termReading": "げつまつ",
    "termTranslation": "end of the month",
    "ex": "月末までに申込書を送ってください。",
    "exReading": "げつまつ まで に もうしこみしょ を おくって ください",
    "exTranslation": "Please send the application form by the end of the month.",
    "category": "Noun"
  },
  {
    "term": "末っ子",
    "termReading": "すえっこ",
    "termTranslation": "youngest child",
    "ex": "彼は5人兄弟の末っ子です。",
    "exReading": "かれ は ごにん きょうだい の すえっこ です",
    "exTranslation": "He is the youngest of five siblings.",
    "category": "Noun"
  },
  {
    "term": "末",
    "termReading": "すえ",
    "termTranslation": "(after) the end",
    "ex": "長い話合いの末、やっと同意に至った。",
    "exReading": "ながい はなしあい の すえ やっと どうい に いたった",
    "exTranslation": "After long talks, they finally reached an agreement.",
    "category": "Noun"
  },
  {
    "term": "飲料水",
    "termReading": "いんりょうすい",
    "termTranslation": "drinking water",
    "ex": "被災地では飲料水が不足しているの。",
    "exReading": "ひさいち で は いんりょうすい が ふそく して いる の",
    "exTranslation": "Drinking water is lacking in the disaster area.",
    "category": "Noun"
  },
  {
    "term": "コンサート",
    "termReading": "コンサート",
    "termTranslation": "concert",
    "ex": "友達とジャズのコンサートに行きました。",
    "exReading": "ともだち と じゃず の こんさーと に いきました 。",
    "exTranslation": "I went to a jazz concert with a friend.",
    "category": "Noun"
  },
  {
    "term": "食料",
    "termReading": "しょくりょう",
    "termTranslation": "food supplies",
    "ex": "食料はこの箱に入っています。",
    "exReading": "しょくりょう は この はこ に はいって います",
    "exTranslation": "Food supplies are in this box.",
    "category": "Noun"
  },
  {
    "term": "見解",
    "termReading": "けんかい",
    "termTranslation": "opinion, view",
    "ex": "あなたの見解を聞かせてください。",
    "exReading": "あなた の けんかい を きかせて ください",
    "exTranslation": "Please tell me your opinion.",
    "category": "Noun"
  },
  {
    "term": "有りのまま",
    "termReading": "ありのまま",
    "termTranslation": "as it is, unchanged",
    "ex": "有りのままを話して下さい。",
    "exReading": "ありのまま を はなして ください",
    "exTranslation": "Please tell it as it is.",
    "category": "Adjectival Noun"
  },
  {
    "term": "作家",
    "termReading": "さっか",
    "termTranslation": "writer, novelist",
    "ex": "彼女は有名な作家です。",
    "exReading": "かのじょ は ゆうめい な さっか です",
    "exTranslation": "She is a well-known writer.",
    "category": "Noun"
  },
  {
    "term": "使用",
    "termReading": "しよう",
    "termTranslation": "usage",
    "ex": "この製品を使用する前に、説明書をお読みください。",
    "exReading": "この せいひん を しよう する まえ に せつめいしょ を およみ ください",
    "exTranslation": "Please read the manual before using this product.",
    "category": "Verbal Noun"
  },
  {
    "term": "作用",
    "termReading": "さよう",
    "termTranslation": "action, effect",
    "ex": "この薬は神経に作用します。",
    "exReading": "この くすり は しんけい に さよう します",
    "exTranslation": "This medicine acts on the nerves.",
    "category": "Verbal Noun"
  },
  {
    "term": "使用人",
    "termReading": "しようにん",
    "termTranslation": "servant",
    "ex": "彼は使用人を首にしたよ。",
    "exReading": "かれ は しようにん を くび に した よ",
    "exTranslation": "He fired the servant.",
    "category": "Noun"
  },
  {
    "term": "いけない",
    "termReading": "いけない",
    "termTranslation": "wrong, must not",
    "ex": "勉強を怠けてはいけないよ。",
    "exReading": "べんきょう を なまけて は いけない よ",
    "exTranslation": "You shouldn't neglect your studies.",
    "category": "Adjective"
  },
  {
    "term": "私用",
    "termReading": "しよう",
    "termTranslation": "private business, private use",
    "ex": "私用で出かけなければなりません。",
    "exReading": "しよう で でかけなければ なりません",
    "exTranslation": "I must go out on a personal errand.",
    "category": "Noun"
  },
  {
    "term": "会費",
    "termReading": "かいひ",
    "termTranslation": "fee, membership fee",
    "ex": "会費が少し高いね。",
    "exReading": "かいひ が すこし たかい ね",
    "exTranslation": "The fee is a little high.",
    "category": "Noun"
  },
  {
    "term": "国費",
    "termReading": "こくひ",
    "termTranslation": "national expenditure",
    "ex": "彼は国費で留学しています。",
    "exReading": "かれ は こくひ で りゅうがく して います",
    "exTranslation": "He's studying abroad on a government-funded scholarship.",
    "category": "Noun"
  },
  {
    "term": "食費",
    "termReading": "しょくひ",
    "termTranslation": "food expenses, board",
    "ex": "男の子が3人もいるので食費がかさみます。",
    "exReading": "おとこ の こ が さんにん も いる の で しょくひ が かさみます",
    "exTranslation": "I have three boys so the food costs mount up.",
    "category": "Noun"
  },
  {
    "term": "私費",
    "termReading": "しひ",
    "termTranslation": "private expense",
    "ex": "彼は私費で留学したんだ。",
    "exReading": "かれ は しひ で りゅうがく した ん だ",
    "exTranslation": "He went to study abroad at his own expense.",
    "category": "Noun"
  },
  {
    "term": "消費",
    "termReading": "しょうひ",
    "termTranslation": "consumption, spending",
    "ex": "日本は消費大国といわれています。",
    "exReading": "にっぽん は しょうひ たいこく と いわれて います",
    "exTranslation": "It is said that Japan is a consumption giant.",
    "category": "Verbal Noun"
  },
  {
    "term": "消火",
    "termReading": "しょうか",
    "termTranslation": "fire fighting",
    "ex": "火事は無事消火されました。",
    "exReading": "かじ は ぶじ しょうか されました",
    "exTranslation": "The fire was safely extinguished.",
    "category": "Verbal Noun"
  },
  {
    "term": "売り上げ",
    "termReading": "うりあげ",
    "termTranslation": "sales, proceeds",
    "ex": "この会社の売り上げは昨年の2倍ね。",
    "exReading": "この かいしゃ の うりあげ は さくねん の に ばい ね",
    "exTranslation": "Sales of the company have doubled from last year.",
    "category": "Noun"
  },
  {
    "term": "あらかじめ",
    "termReading": "あらかじめ",
    "termTranslation": "in advance",
    "ex": "あらかじめ必要な物を書き出して下さい。",
    "exReading": "あらかじめ ひつよう な もの を かきだして ください",
    "exTranslation": "Please write down in advance what you need.",
    "category": "Adverb"
  },
  {
    "term": "売り出す",
    "termReading": "うりだす",
    "termTranslation": "put on sale",
    "ex": "新しい車が売り出された。",
    "exReading": "あたらしい くるま が うりだされた",
    "exTranslation": "A new car was on sale.",
    "category": "Verb"
  },
  {
    "term": "書店",
    "termReading": "しょてん",
    "termTranslation": "bookstore",
    "ex": "駅前に新しい書店ができました。",
    "exReading": "えきまえ に あたらしい しょてん が できました",
    "exTranslation": "A new bookstore has opened in front of the station.",
    "category": "Noun"
  },
  {
    "term": "開店",
    "termReading": "かいてん",
    "termTranslation": "opening of a store, setting up",
    "ex": "デパートは10時に開店しますよ。",
    "exReading": "でぱーと は じゅうじ に かいてん します よ",
    "exTranslation": "The department store opens at ten o'clock.",
    "category": "Verbal Noun"
  },
  {
    "term": "小売店",
    "termReading": "こうりてん",
    "termTranslation": "retail store, outlet",
    "ex": "この商品は小売店でも買えます。",
    "exReading": "この しょうひん は こうりてん で も かえます",
    "exTranslation": "This product can be purchased in retail shops.",
    "category": "Noun"
  },
  {
    "term": "商社",
    "termReading": "しょうしゃ",
    "termTranslation": "trading firm",
    "ex": "兄は商社に勤めています。",
    "exReading": "あに は しょうしゃ に つとめて います",
    "exTranslation": "My elder brother works for a trading company.",
    "category": "Noun"
  },
  {
    "term": "商店",
    "termReading": "しょうてん",
    "termTranslation": "shop, store",
    "ex": "この通りには商店が多いね。",
    "exReading": "この とおり に は しょうてん が おおい ね",
    "exTranslation": "There are many shops on this street.",
    "category": "Noun"
  },
  {
    "term": "商売",
    "termReading": "しょうばい",
    "termTranslation": "trade, business",
    "ex": "彼の商売は儲かっているな。",
    "exReading": "かれ の しょうばい は もうかって いる な",
    "exTranslation": "His business is profitable.",
    "category": "Verbal Noun"
  },
  {
    "term": "しばしば",
    "termReading": "しばしば",
    "termTranslation": "often, frequently",
    "ex": "課長はしばしば出張します。",
    "exReading": "かちょう は しばしば しゅっちょう します",
    "exTranslation": "The section chief often goes on business trips.",
    "category": "Adverb"
  },
  {
    "term": "商人",
    "termReading": "しょうにん",
    "termTranslation": "merchant, trader",
    "ex": "商人は数字に強いね。",
    "exReading": "しょうにん は すうじ に つよい ね",
    "exTranslation": "Businessmen are good with figures.",
    "category": "Noun"
  },
  {
    "term": "食品",
    "termReading": "しょくひん",
    "termTranslation": "food, foodstuff",
    "ex": "食品は日曜日にまとめて買います。",
    "exReading": "しょくひん は にちようび に まとめて かいます",
    "exTranslation": "I buy my food in bulk on Sundays.",
    "category": "Noun"
  },
  {
    "term": "品",
    "termReading": "しな",
    "termTranslation": "article, item",
    "ex": "そちらの品は半額になっています。",
    "exReading": "そちら の しな は はんがく に なって います",
    "exTranslation": "Those goods are half-price.",
    "category": "Noun"
  },
  {
    "term": "上品",
    "termReading": "じょうひん",
    "termTranslation": "elegance, refinement",
    "ex": "このドレスはとても上品なデザインですね。",
    "exReading": "この どれす は とても じょうひん な でざいん です ね",
    "exTranslation": "This dress has a very elegant design.",
    "category": "Adjectival Noun"
  },
  {
    "term": "下品",
    "termReading": "げひん",
    "termTranslation": "vulgar, low",
    "ex": "彼女の下品なふるまいには我慢できない。",
    "exReading": "かのじょ の げひん な ふるまい に は がまん できない",
    "exTranslation": "I can't put up with her vulgar behavior.",
    "category": "Adjective"
  },
  {
    "term": "手段",
    "termReading": "しゅだん",
    "termTranslation": "means, way",
    "ex": "彼は目的のためには手段を選ばなかったわね。",
    "exReading": "かれ は もくてき の ため に は しゅだん を えらばなかった わ ね",
    "exTranslation": "He used every trick in the book to achieve his aims.",
    "category": "Noun"
  },
  {
    "term": "一段と",
    "termReading": "いちだんと",
    "termTranslation": "greater, further",
    "ex": "物価は一段と上昇しそうだね。",
    "exReading": "ぶっか は いちだんと じょうしょう しそう だ ね",
    "exTranslation": "It seems prices are going up even further.",
    "category": "Adverb"
  },
  {
    "term": "ショック",
    "termReading": "ショック",
    "termTranslation": "shock, surprise",
    "ex": "彼女はショックで口もきけなかったわ。",
    "exReading": "かのじょ は しょっく で くち も きけなかった わ",
    "exTranslation": "She was too shocked to speak.",
    "category": "Adjectival Noun"
  },
  {
    "term": "値",
    "termReading": "あたい",
    "termTranslation": "value, worth",
    "ex": "xの値を求めなさい。",
    "exReading": "えっくす の あたい を もとめなさい",
    "exTranslation": "Calculate the value of x.",
    "category": "Noun"
  },
  {
    "term": "値する",
    "termReading": "あたいする",
    "termTranslation": "be worth, deserve",
    "ex": "その絵は一見に値しますよ。",
    "exReading": "その え は いっけん に あたい します よ",
    "exTranslation": "That painting is worth a look.",
    "category": "Verb"
  },
  {
    "term": "価値",
    "termReading": "かち",
    "termTranslation": "value, merit",
    "ex": "とても価値のある話を聞いたよ。",
    "exReading": "とても かち の ある はなし を きいた よ",
    "exTranslation": "I heard a very valuable story.",
    "category": "Noun"
  },
  {
    "term": "高価",
    "termReading": "こうか",
    "termTranslation": "high-priced",
    "ex": "彼女は高価な宝石を持っているわ。",
    "exReading": "かのじょ は こうか な ほうせき を もって いる わ",
    "exTranslation": "She has expensive jewelry.",
    "category": "Adjectival Noun"
  },
  {
    "term": "人格",
    "termReading": "じんかく",
    "termTranslation": "character, personality",
    "ex": "私は彼の人格を尊敬しています。",
    "exReading": "わたし は かれ の じんかく を そんけい して います",
    "exTranslation": "I respect his character.",
    "category": "Noun"
  },
  {
    "term": "格好",
    "termReading": "かっこう",
    "termTranslation": "shape, appearance",
    "ex": "格好のいい青年に会ったよ。",
    "exReading": "かっこう の いい せいねん に あった よ",
    "exTranslation": "I met a good-looking young man.",
    "category": "Verbal Noun"
  },
  {
    "term": "青春",
    "termReading": "せいしゅん",
    "termTranslation": "youth",
    "ex": "この曲を聞くと青春の頃を思い出します。",
    "exReading": "この きょく を きく と せいしゅん の ころ を おもいだします",
    "exTranslation": "When I hear this tune I am reminded of my youth.",
    "category": "Noun"
  },
  {
    "term": "アンテナ",
    "termReading": "アンテナ",
    "termTranslation": "antenna",
    "ex": "屋上にアンテナが立っていますね。",
    "exReading": "おくじょう に あんてな が たって います ね",
    "exTranslation": "The antenna stands on the rooftop.",
    "category": "Noun"
  },
  {
    "term": "春分",
    "termReading": "しゅんぶん",
    "termTranslation": "vernal equinox",
    "ex": "春分の日は毎年3月20日頃です。",
    "exReading": "しゅんぶん の ひ は まいとし さんがつ はつかごろ です",
    "exTranslation": "The Vernal Equinox Day is about March 20th every year.",
    "category": "Noun"
  },
  {
    "term": "初夏",
    "termReading": "しょか",
    "termTranslation": "early summer",
    "ex": "初夏の高原は気持ちがいいですね。",
    "exReading": "しょか の こうげん は きもち が いい です ね",
    "exTranslation": "Being up on the high plateaus in early summer feels good.",
    "category": "Noun"
  },
  {
    "term": "秋分",
    "termReading": "しゅうぶん",
    "termTranslation": "autumnal equinox",
    "ex": "秋分の日は毎年9月23日頃です。",
    "exReading": "しゅうぶん の ひ は まいとし くがつ にじゅうさんにちごろ です",
    "exTranslation": "The Autumnal Equinox Day is about September 23rd every year.",
    "category": "Noun"
  },
  {
    "term": "春夏秋冬",
    "termReading": "しゅんかしゅうとう",
    "termTranslation": "four seasons, all year round",
    "ex": "春夏秋冬の移り変わりを見るのが大好きです。",
    "exReading": "しゅんかしゅうとう の うつりかわり を みる の が だいすき です",
    "exTranslation": "I love to see the seasons changing.",
    "category": "Noun"
  },
  {
    "term": "夏季",
    "termReading": "かき",
    "termTranslation": "summer, summer season",
    "ex": "夏季講習に申し込みした？",
    "exReading": "かき こうしゅう に もうしこみ した ？",
    "exTranslation": "Did you register for summer school?",
    "category": "Noun"
  },
  {
    "term": "寒気",
    "termReading": "さむけ",
    "termTranslation": "chill",
    "ex": "何だか寒気がします。",
    "exReading": "なんだか さむけ が します",
    "exTranslation": "I feel chilly somehow.",
    "category": "Noun"
  },
  {
    "term": "暖か",
    "termReading": "あたたか",
    "termTranslation": "warm, warmhearted",
    "ex": "最近は暖かです。",
    "exReading": "さいきん は あたたか です",
    "exTranslation": "The weather has been warm recently.",
    "category": "Adjective"
  },
  {
    "term": "高温",
    "termReading": "こうおん",
    "termTranslation": "high temperature",
    "ex": "金属は高温で溶かします。",
    "exReading": "きんぞく は こうおん で とかします",
    "exTranslation": "We will melt the metal at high temperatures.",
    "category": "Noun"
  },
  {
    "term": "あえて",
    "termReading": "あえて",
    "termTranslation": "boldly",
    "ex": "彼はあえて危険を冒したの。",
    "exReading": "かれ は あえて きけん を おかした",
    "exTranslation": "He dared to face danger.",
    "category": "Adverb"
  },
  {
    "term": "温暖",
    "termReading": "おんだん",
    "termTranslation": "warm, mild",
    "ex": "この地方は温暖で暮らしやすいな。",
    "exReading": "この ちほう は おんだん で くらし やすい な",
    "exTranslation": "The mild weather in these parts makes it easy to live here.",
    "category": "Adjectival Noun"
  },
  {
    "term": "北風",
    "termReading": "きたかぜ",
    "termTranslation": "north wind",
    "ex": "北風が冷たいです。",
    "exReading": "きたかぜ が つめたい です",
    "exTranslation": "The north wind is cold.",
    "category": "Noun"
  },
  {
    "term": "秋風",
    "termReading": "あきかぜ",
    "termTranslation": "autumn breeze",
    "ex": "秋風が気持ちいいね。",
    "exReading": "あきかぜ が きもち いい ね",
    "exTranslation": "The autumn wind feels good.",
    "category": "Noun"
  },
  {
    "term": "情熱",
    "termReading": "じょうねつ",
    "termTranslation": "passion, enthusiasm",
    "ex": "父は情熱を持って仕事に打ち込んでいます。",
    "exReading": "ちち は じょうねつ を もって しごと に うちこんで います",
    "exTranslation": "My father is devoting himself to work with zeal.",
    "category": "Noun"
  },
  {
    "term": "広告",
    "termReading": "こうこく",
    "termTranslation": "public notice, advertisement",
    "ex": "その広告を新聞で見ました。",
    "exReading": "その こうこく を しんぶん で みました",
    "exTranslation": "I saw that advertisement in the newspaper.",
    "category": "Verbal Noun"
  },
  {
    "term": "新た",
    "termReading": "あらた",
    "termTranslation": "new, fresh",
    "ex": "新たな計画が進んでいます。",
    "exReading": "あらた な けいかく が すすんで います",
    "exTranslation": "A new plan is in progress.",
    "category": "Adjective"
  },
  {
    "term": "新聞社",
    "termReading": "しんぶんしゃ",
    "termTranslation": "newspaper office",
    "ex": "このビルは新聞社です。",
    "exReading": "この びる は しんぶんしゃ です",
    "exTranslation": "This building is a newspaper office.",
    "category": "Noun"
  },
  {
    "term": "カバー",
    "termReading": "カバー",
    "termTranslation": "cover, covering",
    "ex": "本にカバーを掛けました。",
    "exReading": "ほん に かばー を かけました",
    "exTranslation": "I put a cover on the book.",
    "category": "Verbal Noun"
  },
  {
    "term": "新人",
    "termReading": "しんじん",
    "termTranslation": "newcomer, freshman",
    "ex": "彼は今日入ったばかりの新人です。",
    "exReading": "かれ は きょう はいった ばかり の しんじん です",
    "exTranslation": "He is a newcomer who just started today.",
    "category": "Noun"
  },
  {
    "term": "最悪",
    "termReading": "さいあく",
    "termTranslation": "the worst",
    "ex": "何とか最悪の事態を避けることができました。",
    "exReading": "なんとか さいあく の じたい を さける こと が できました",
    "exTranslation": "Somehow we managed to avert the worst-case scenario.",
    "category": "Adjectival Noun"
  },
  {
    "term": "悪用",
    "termReading": "あくよう",
    "termTranslation": "abuse, misuse",
    "ex": "彼は地位を悪用しています。",
    "exReading": "かれ は ちい を あくよう して います",
    "exTranslation": "He is abusing his position.",
    "category": "Noun"
  },
  {
    "term": "悪",
    "termReading": "あく",
    "termTranslation": "evil, badness",
    "ex": "彼は悪を憎んでいます。",
    "exReading": "かれ は あく を にくんで います",
    "exTranslation": "He hates evil.",
    "category": "Noun"
  },
  {
    "term": "悪女",
    "termReading": "あくじょ",
    "termTranslation": "wicked woman, villainess",
    "ex": "彼は悪女に騙されたんだ。",
    "exReading": "かれ は あくじょ に だまされた ん だ",
    "exTranslation": "He was betrayed by a wicked woman.",
    "category": "Noun"
  },
  {
    "term": "心理",
    "termReading": "しんり",
    "termTranslation": "mental state, psychology",
    "ex": "顧客心理を理解することは重要です。",
    "exReading": "こきゃく しんり を りかい する こと は じゅうよう です",
    "exTranslation": "It's important to understand customer psychology.",
    "category": "Noun"
  },
  {
    "term": "心",
    "termReading": "こころ",
    "termTranslation": "heart, mind",
    "ex": "彼は素直な心を持っている。",
    "exReading": "かれ は すなお な こころ を もって いる",
    "exTranslation": "He has a good heart.",
    "category": "Noun"
  },
  {
    "term": "ジャーナリスト",
    "termReading": "ジャーナリスト",
    "termTranslation": "journalist",
    "ex": "彼女は有能なジャーナリストだ。",
    "exReading": "かのじょ は ゆうのう な じゃーなりすと だ",
    "exTranslation": "She is an able journalist.",
    "category": "Noun"
  },
  {
    "term": "思い",
    "termReading": "おもい",
    "termTranslation": "thoughts, feelings",
    "ex": "必死の思いで彼に頼んだよ。",
    "exReading": "ひっし の おもい で かれ に たのんだ よ",
    "exTranslation": "I asked him out of desperation.",
    "category": "Noun"
  },
  {
    "term": "思わず",
    "termReading": "おもわず",
    "termTranslation": "unintentionally, unconsciously",
    "ex": "嬉しくて思わず涙が出ました。",
    "exReading": "うれしくて おもわず なみだ が でました",
    "exTranslation": "I was so happy that I suddenly burst into tears.",
    "category": "Adverb"
  },
  {
    "term": "思いがけない",
    "termReading": "おもいがけない",
    "termTranslation": "unexpected, accidental",
    "ex": "彼から思いがけないことを聞いた。",
    "exReading": "かれ から おもいがけない こと を きいた",
    "exTranslation": "I heard something unexpected from him.",
    "category": "Adjective"
  },
  {
    "term": "思いやり",
    "termReading": "おもいやり",
    "termTranslation": "thoughtfulness, kindness",
    "ex": "彼女の思いやりが嬉しかった。",
    "exReading": "かのじょ の おもいやり が うれしかった",
    "exTranslation": "I was glad for her kindness.",
    "category": "Noun"
  },
  {
    "term": "決して",
    "termReading": "けっして",
    "termTranslation": "never, by no means",
    "ex": "このことを決して忘れないでください。",
    "exReading": "この こと を けっして わすれない で ください",
    "exTranslation": "Please never forget this.",
    "category": "Adverb"
  },
  {
    "term": "決心",
    "termReading": "けっしん",
    "termTranslation": "determination, resolve",
    "ex": "今度こそタバコを止める決心をしました。",
    "exReading": "こんど こそ たばこ を やめる けっしん を しました",
    "exTranslation": "I am determined to stop smoking this time.",
    "category": "Verbal Noun"
  },
  {
    "term": "決まり",
    "termReading": "きまり",
    "termTranslation": "settlement, rule",
    "ex": "決まりを守ることは大切です。",
    "exReading": "きまり を まもる こと は たいせつ です",
    "exTranslation": "It is important to keep the rules.",
    "category": "Noun"
  },
  {
    "term": "知り合う",
    "termReading": "しりあう",
    "termTranslation": "get acquainted with",
    "ex": "お二人はどこで知り合ったのですか。",
    "exReading": "おふたり は どこ で しりあった の です か",
    "exTranslation": "Where did you two get to know each other?",
    "category": "Verb"
  },
  {
    "term": "スタイル",
    "termReading": "スタイル",
    "termTranslation": "style",
    "ex": "彼女はモデルのようにスタイルがいいね。",
    "exReading": "かのじょ は もでる の よう に すたいる が いい ね",
    "exTranslation": "With her good figure, she looks like a model.",
    "category": "Noun"
  },
  {
    "term": "知れる",
    "termReading": "しれる",
    "termTranslation": "become known, be found out",
    "ex": "他人に知れるとまずいことになるわね。",
    "exReading": "たにん に しれる と まずい こと に なる わ ね",
    "exTranslation": "This would be terrible if others found out about it.",
    "category": "Verb"
  },
  {
    "term": "知り合い",
    "termReading": "しりあい",
    "termTranslation": "acquaintance",
    "ex": "街で知り合いを見かけたよ。",
    "exReading": "まち で しりあい を みかけた よ",
    "exTranslation": "I saw an acquaintance in town.",
    "category": "Noun"
  },
  {
    "term": "知らせ",
    "termReading": "しらせ",
    "termTranslation": "information, notice",
    "ex": "今日、合格の知らせをもらいました。",
    "exReading": "きょう ごうかく の しらせ を もらいました",
    "exTranslation": "Today I received a notice that I passed.",
    "category": "Noun"
  },
  {
    "term": "知らず知らず",
    "termReading": "しらずしらず",
    "termTranslation": "unawares, without knowing it",
    "ex": "知らず知らずのうちに疲れがたまっていたよ。",
    "exReading": "しらずしらず の うち に つかれ が たまって いた よ",
    "exTranslation": "I got tired little by little without being aware of it.",
    "category": "Adverb"
  },
  {
    "term": "才能",
    "termReading": "さいのう",
    "termTranslation": "talent, ability",
    "ex": "彼は芸術的な才能にあふれているね。",
    "exReading": "かれ は げいじゅつてき な さいのう に あふれて いる ね",
    "exTranslation": "He is full of artistic talent.",
    "category": "Noun"
  },
  {
    "term": "小便",
    "termReading": "しょうべん",
    "termTranslation": "urine, pee",
    "ex": "ちょっと小便しに行って来る。",
    "exReading": "ちょっと しょうべん し に いって くる",
    "exTranslation": "Sorry, I am going for a pee.",
    "category": "Verbal Noun"
  },
  {
    "term": "局",
    "termReading": "きょく",
    "termTranslation": "bureau, public service office",
    "ex": "彼女はラジオ局で働いています。",
    "exReading": "かのじょ は らじおきょく で はたらいて います",
    "exTranslation": "She works for a radio station.",
    "category": "Noun"
  },
  {
    "term": "インタビュー",
    "termReading": "インタビュー",
    "termTranslation": "interview",
    "ex": "彼はインタビューに、はきはきと答えてたよ。",
    "exReading": "かれ は いんたびゅー に はきはき と こたえて た よ",
    "exTranslation": "He eagerly answered questions in the interview.",
    "category": "Verbal Noun"
  },
  {
    "term": "住まい",
    "termReading": "すまい",
    "termTranslation": "dwelling, residence",
    "ex": "私の住まいは東京にあります。",
    "exReading": "わたし の すまい は とうきょう に あります",
    "exTranslation": "My residence is in Tokyo.",
    "category": "Noun"
  },
  {
    "term": "氏",
    "termReading": "し",
    "termTranslation": "Mr.",
    "ex": "会長は田中氏に決定。",
    "exReading": "かいちょう は たなかし に けってい",
    "exTranslation": "It has been decided that Mr. Tanaka will be chairman.",
    "category": "Noun"
  },
  {
    "term": "人名",
    "termReading": "じんめい",
    "termTranslation": "personal name, name",
    "ex": "これは日本の人名ですか。",
    "exReading": "これ は にほん の じんめい です か",
    "exTranslation": "Is this a Japanese name?",
    "category": "Noun"
  },
  {
    "term": "国名",
    "termReading": "こくめい",
    "termTranslation": "country name",
    "ex": "アジアの国名をいくつ知っていますか。",
    "exReading": "あじあ の こくめい を いくつ しって います か",
    "exTranslation": "How many country names in Asia do you know?",
    "category": "Noun"
  },
  {
    "term": "各地",
    "termReading": "かくち",
    "termTranslation": "every place, various parts",
    "ex": "各地で大雨が降っています。",
    "exReading": "かくち で おおあめ が ふって います",
    "exTranslation": "Heavy rain is falling in all regions.",
    "category": "Noun"
  },
  {
    "term": "県",
    "termReading": "けん",
    "termTranslation": "prefecture",
    "ex": "県の代表は2名です。",
    "exReading": "けん の だいひょう は にめい です",
    "exTranslation": "There are two representatives of the prefecture.",
    "category": "Noun"
  },
  {
    "term": "市内",
    "termReading": "しない",
    "termTranslation": "in the city",
    "ex": "明日は市内を観光する予定です。",
    "exReading": "あした は しない を かんこう する よてい です 。",
    "exTranslation": "I am scheduled to go sightseeing in the city tomorrow.",
    "category": "Noun"
  },
  {
    "term": "シーズン",
    "termReading": "シーズン",
    "termTranslation": "season",
    "ex": "その選手は今シーズンも好調だね。",
    "exReading": "その せんしゅ は こん しーずん も こうちょう だ ね 。",
    "exTranslation": "That player is good this season as well.",
    "category": "Noun"
  },
  {
    "term": "市長",
    "termReading": "しちょう",
    "termTranslation": "mayor",
    "ex": "新しい市長が選ばれました。",
    "exReading": "あたらしい しちょう が えらばれました",
    "exTranslation": "A new mayor was chosen.",
    "category": "Noun"
  },
  {
    "term": "市場",
    "termReading": "いちば",
    "termTranslation": "market, marketplace",
    "ex": "市場で新鮮な魚を買ってきました。",
    "exReading": "いちば で しんせん な さかな を かって きました",
    "exTranslation": "I bought some fresh fish at the market.",
    "category": "Noun"
  },
  {
    "term": "市場",
    "termReading": "しじょう",
    "termTranslation": "market, exchange",
    "ex": "デジカメ市場は急速に拡大している。",
    "exReading": "でじかめ しじょう は きゅうそく に かくだい して いる",
    "exTranslation": "The digital camera market has expanded rapidly.",
    "category": "Noun"
  },
  {
    "term": "市外",
    "termReading": "しがい",
    "termTranslation": "city outskirts, suburbs",
    "ex": "祖父は市外の病院に通っているの。",
    "exReading": "そふ は しがい の びょういん に かよって いる の",
    "exTranslation": "My grandfather goes to a hospital outside the city.",
    "category": "Noun"
  },
  {
    "term": "市",
    "termReading": "し",
    "termTranslation": "city",
    "ex": "その市の人口は減り続けているの。",
    "exReading": "その し の じんこう は へりつづけて いる の",
    "exTranslation": "The population of the city keeps decreasing.",
    "category": "Noun"
  },
  {
    "term": "下町",
    "termReading": "したまち",
    "termTranslation": "old part of town",
    "ex": "あの子は下町育ちだ。",
    "exReading": "あの こ は したまち そだち だ",
    "exTranslation": "That kid was raised in the old part of town.",
    "category": "Noun"
  },
  {
    "term": "区分",
    "termReading": "くぶん",
    "termTranslation": "division, section",
    "ex": "この表は年齢区分ごとの人口を表しています。",
    "exReading": "この ひょう は ねんれい くぶん ごと の じんこう を あらわして います",
    "exTranslation": "This table shows the population divided into age groups.",
    "category": "Verbal Noun"
  },
  {
    "term": "区",
    "termReading": "く",
    "termTranslation": "ward",
    "ex": "東京には23の区がある。",
    "exReading": "とうきょう に は 23 の く が ある 。",
    "exTranslation": "There are 23 wards in Tokyo.",
    "category": "Noun"
  },
  {
    "term": "アイデア",
    "termReading": "アイデア",
    "termTranslation": "idea",
    "ex": "彼がいいアイデアを出したね。",
    "exReading": "かれ が いい あいであ を だした ね",
    "exTranslation": "He came up with a good idea.",
    "category": "Noun"
  },
  {
    "term": "様々",
    "termReading": "さまざま",
    "termTranslation": "various, diverse",
    "ex": "その都市には様々な人種が集まっているわ。",
    "exReading": "その とし に は さまざま な じんしゅ が あつまって いる わ",
    "exTranslation": "There are a lot of different ethnic groups in this city.",
    "category": "Adjective"
  },
  {
    "term": "出荷",
    "termReading": "しゅっか",
    "termTranslation": "shipping, dispatch",
    "ex": "ご注文の品は明日出荷致します。",
    "exReading": "ごちゅうもん の しな は あす しゅっか いたします",
    "exTranslation": "We will ship the ordered goods tomorrow.",
    "category": "Verbal Noun"
  },
  {
    "term": "人物",
    "termReading": "じんぶつ",
    "termTranslation": "character, person",
    "ex": "彼は会社の重要な人物です。",
    "exReading": "かれ は かいしゃ の じゅうよう な じんぶつ です",
    "exTranslation": "He is an important person in the company.",
    "category": "Noun"
  },
  {
    "term": "見物人",
    "termReading": "けんぶつにん",
    "termTranslation": "spectator, sightseer",
    "ex": "見物人が大勢集まっているね。",
    "exReading": "けんぶつにん が おおぜい あつまって いる ね",
    "exTranslation": "Many spectators have gathered.",
    "category": "Noun"
  },
  {
    "term": "入れ物",
    "termReading": "いれもの",
    "termTranslation": "receptacle, container",
    "ex": "荷物が多いので大きな入れ物が必要です。",
    "exReading": "にもつ が おおい の で おおき な いれもの が ひつよう です",
    "exTranslation": "There are so many items that we need a big container.",
    "category": "Noun"
  },
  {
    "term": "作物",
    "termReading": "さくもつ",
    "termTranslation": "crop",
    "ex": "米はアジアでは大切な作物だ。",
    "exReading": "こめ は あじあ で は たいせつ な さくもつ だ",
    "exTranslation": "Rice is an important crop in Asia.",
    "category": "Noun"
  },
  {
    "term": "食物",
    "termReading": "しょくもつ",
    "termTranslation": "food",
    "ex": "人間にとって水は食物より大切なの。",
    "exReading": "にんげん に とって みず は しょくもつ より たいせつ なの",
    "exTranslation": "For human beings water is more important than food.",
    "category": "Noun"
  },
  {
    "term": "いかにも",
    "termReading": "いかにも",
    "termTranslation": "indeed, as if",
    "ex": "彼はいかにもスポーツマンらしいですね。",
    "exReading": "かれ は いかにも すぽーつまん らしい です ね",
    "exTranslation": "He indeed looks like a sportsman.",
    "category": "Adverb"
  },
  {
    "term": "書物",
    "termReading": "しょもつ",
    "termTranslation": "book, volume",
    "ex": "彼は書物に囲まれて生活しているの。",
    "exReading": "かれ は しょもつ に かこまれて せいかつ して いる の",
    "exTranslation": "He lives surrounded by books.",
    "category": "Noun"
  },
  {
    "term": "重ねる",
    "termReading": "かさねる",
    "termTranslation": "pile up, repeat",
    "ex": "荷物はここに重ねてください。",
    "exReading": "にもつ は ここ に かさねて ください",
    "exTranslation": "Please pile up the luggage here.",
    "category": "Verb"
  },
  {
    "term": "重なる",
    "termReading": "かさなる",
    "termTranslation": "be piled up, come one after another",
    "ex": "高速道路で事故が重なった。",
    "exReading": "こうそく どうろ で じこ が かさなった",
    "exTranslation": "There was one accident after another on the highway.",
    "category": "Verb"
  },
  {
    "term": "重大",
    "termReading": "じゅうだい",
    "termTranslation": "important, serious",
    "ex": "重大な発表があります。",
    "exReading": "じゅうだい な はっぴょう が あります",
    "exTranslation": "I have an important announcement.",
    "category": "Adjective"
  },
  {
    "term": "重み",
    "termReading": "おもみ",
    "termTranslation": "weight, importance",
    "ex": "雪の重みで枝が折れそうだ。",
    "exReading": "ゆき の おもみ で えだ が おれ そう だ",
    "exTranslation": "The branch looks like it might snap due to the weight of the snow.",
    "category": "Noun"
  },
  {
    "term": "重たい",
    "termReading": "おもたい",
    "termTranslation": "heavy",
    "ex": "この鞄は重たいです。",
    "exReading": "この かばん は おもたい です",
    "exTranslation": "This bag is heavy.",
    "category": "Adjective"
  },
  {
    "term": "気軽",
    "termReading": "きがる",
    "termTranslation": "lighthearted, easy",
    "ex": "いつでも気軽に遊びに来て下さい。",
    "exReading": "いつでも きがる に あそび に きて ください",
    "exTranslation": "Please feel free to stop by any time.",
    "category": "Adjectival Noun"
  },
  {
    "term": "いきなり",
    "termReading": "いきなり",
    "termTranslation": "all of a sudden, without warning",
    "ex": "後ろからいきなり肩をたたかれた。",
    "exReading": "うしろ から いきなり かた を たたかれた",
    "exTranslation": "Someone suddenly tapped on my shoulder from behind.",
    "category": "Adverb"
  },
  {
    "term": "重量",
    "termReading": "じゅうりょう",
    "termTranslation": "weight",
    "ex": "この荷物はかなりの重量ですね。",
    "exReading": "この にもつ は かなり の じゅうりょう です ね",
    "exTranslation": "This luggage is quite heavy.",
    "category": "Noun"
  },
  {
    "term": "少量",
    "termReading": "しょうりょう",
    "termTranslation": "small quantity",
    "ex": "泡立てたクリームに少量のブランデーを加えます。",
    "exReading": "あわだてた くりーむ に しょうりょう の ぶらんでー を くわえます",
    "exTranslation": "A small amount of brandy is added to the whipped cream.",
    "category": "Noun"
  },
  {
    "term": "小量",
    "termReading": "しょうりょう",
    "termTranslation": "small amount",
    "ex": "私はコーヒー豆を小量で買うようにしています。",
    "exReading": "わたし は こーひー まめ を しょうりょう で かうよう に しています 。",
    "exTranslation": "I try to buy coffee beans in small amounts.",
    "category": "Noun"
  },
  {
    "term": "受け入れる",
    "termReading": "うけいれる",
    "termTranslation": "accept, accommodate",
    "ex": "私は彼の意見を受け入れました。",
    "exReading": "わたし は かれ の いけん を うけいれました",
    "exTranslation": "I accepted his opinion.",
    "category": "Verb"
  },
  {
    "term": "受け止める",
    "termReading": "うけとめる",
    "termTranslation": "catch, receive",
    "ex": "ボールが速過ぎて受け止められなかったの。",
    "exReading": "ぼーる が はや すぎて うけとめられ なかった の",
    "exTranslation": "The ball was so fast that I couldn't catch it.",
    "category": "Verb"
  },
  {
    "term": "受かる",
    "termReading": "うかる",
    "termTranslation": "pass",
    "ex": "第一志望の大学に受かりました。",
    "exReading": "だいいち しぼう の だいがく に うかりました",
    "exTranslation": "I passed the entrance exam for my first-choice college.",
    "category": "Verb"
  },
  {
    "term": "受け取り",
    "termReading": "うけとり",
    "termTranslation": "receipt",
    "ex": "受け取りに判子をお願いします。",
    "exReading": "うけとり に はんこ を おねがい します",
    "exTranslation": "Please stamp your seal on the receipt.",
    "category": "Noun"
  },
  {
    "term": "いつまでも",
    "termReading": "いつまでも",
    "termTranslation": "forever",
    "ex": "いつまでもあなたを忘れません。",
    "exReading": "いつまでも あなた を わすれません",
    "exTranslation": "I will never forget you.",
    "category": "Adverb"
  },
  {
    "term": "聞き取り",
    "termReading": "ききとり",
    "termTranslation": "listening comprehension, dictation",
    "ex": "英語の聞き取り試験を受けたよ。",
    "exReading": "えいご の ききとり しけん を うけた よ",
    "exTranslation": "I took an English listening comprehension exam.",
    "category": "Noun"
  },
  {
    "term": "書き取り",
    "termReading": "かきとり",
    "termTranslation": "dictation",
    "ex": "僕たちは毎朝漢字の書き取りをします。",
    "exReading": "ぼくたち は まいあさ かんじ の かきとり を します",
    "exTranslation": "We do kanji dictation every morning.",
    "category": "Noun"
  },
  {
    "term": "受け持つ",
    "termReading": "うけもつ",
    "termTranslation": "have charge of",
    "ex": "1年生を受け持っています。",
    "exReading": "いちねんせい を うけもって います",
    "exTranslation": "I am in charge of the first graders.",
    "category": "Verb"
  },
  {
    "term": "打ち上げる",
    "termReading": "うちあげる",
    "termTranslation": "shoot up",
    "ex": "夏祭りで花火を打ち上げます。",
    "exReading": "なつまつり で はなび を うちあげます",
    "exTranslation": "Fireworks are set off at the summer festival.",
    "category": "Verb"
  },
  {
    "term": "打ち合わせ",
    "termReading": "うちあわせ",
    "termTranslation": "meeting, arrangement",
    "ex": "午後に打ち合わせをしましょう。",
    "exReading": "ごご に うちあわせ を しましょう",
    "exTranslation": "Let's have a meeting in the afternoon.",
    "category": "Verbal Noun"
  },
  {
    "term": "打ち明ける",
    "termReading": "うちあける",
    "termTranslation": "confide in, reveal",
    "ex": "親友に悩みを打ち明けたの。",
    "exReading": "しんゆう に なやみ を うちあけた の",
    "exTranslation": "I confessed my concerns to my best friend.",
    "category": "Verb"
  },
  {
    "term": "打ち合わせる",
    "termReading": "うちあわせる",
    "termTranslation": "arrange, discuss",
    "ex": "来週の予定を打ち合わせましょう。",
    "exReading": "らいしゅう の よてい を うちあわせましょう",
    "exTranslation": "Let's discuss next week's schedule.",
    "category": "Verb"
  },
  {
    "term": "打ち消し",
    "termReading": "うちけし",
    "termTranslation": "negation, denial",
    "ex": "彼はうわさを打ち消したわよ。",
    "exReading": "かれ は うわさ を うちけした わ よ",
    "exTranslation": "He denied the rumor.",
    "category": "Noun"
  },
  {
    "term": "しっかり",
    "termReading": "しっかり",
    "termTranslation": "mature, reliable",
    "ex": "彼は若いのにしっかりしてるね。",
    "exReading": "かれ は わかい の に しっかり してる ね",
    "exTranslation": "He is reliable even though he is young.",
    "category": "Adverb"
  },
  {
    "term": "市役所",
    "termReading": "しやくしょ",
    "termTranslation": "municipal office, city hall",
    "ex": "市役所で書類をもらって来たの。",
    "exReading": "しやくしょ で しょるい を もらって きた の",
    "exTranslation": "I got the documents at the city office.",
    "category": "Noun"
  },
  {
    "term": "区役所",
    "termReading": "くやくしょ",
    "termTranslation": "ward office",
    "ex": "区役所に書類を届けてください。",
    "exReading": "くやくしょ に しょるい を とどけて ください",
    "exTranslation": "Please deliver the document to the ward office.",
    "category": "Noun"
  },
  {
    "term": "重役",
    "termReading": "じゅうやく",
    "termTranslation": "executive",
    "ex": "明日、重役会議が開かれます。",
    "exReading": "あす じゅうやくかいぎ が ひらかれます",
    "exTranslation": "The executive meeting will be held tomorrow.",
    "category": "Noun"
  },
  {
    "term": "生じる",
    "termReading": "しょうじる",
    "termTranslation": "happen, cause",
    "ex": "両者の間に摩擦が生じています。",
    "exReading": "りょうしゃ の あいだ に まさつ が しょうじて います",
    "exTranslation": "There is some friction between the two of them.",
    "category": "Verb"
  },
  {
    "term": "人生",
    "termReading": "じんせい",
    "termTranslation": "human life, life",
    "ex": "彼は自分の人生を振り返ったわ。",
    "exReading": "かれ は じぶん の じんせい を ふりかえった わ",
    "exTranslation": "He looked back on his life.",
    "category": "Noun"
  },
  {
    "term": "生まれ",
    "termReading": "うまれ",
    "termTranslation": "birth, origin",
    "ex": "彼女は京都の生まれです。",
    "exReading": "かのじょ は きょうと の うまれ です",
    "exTranslation": "She was born in Kyoto.",
    "category": "Noun"
  },
  {
    "term": "一生",
    "termReading": "いっしょう",
    "termTranslation": "a lifetime",
    "ex": "一生のお願いがあります。",
    "exReading": "いっしょう の おねがい が あります",
    "exTranslation": "I have a once-in-a-lifetime favor.",
    "category": "Noun"
  },
  {
    "term": "アンケート",
    "termReading": "アンケート",
    "termTranslation": "questionnaire",
    "ex": "彼女はアンケートに答えたよ。",
    "exReading": "かのじょ は あんけーと に こたえた よ",
    "exTranslation": "She answered the questionnaire.",
    "category": "Noun"
  },
  {
    "term": "生み出す",
    "termReading": "うみだす",
    "termTranslation": "generate, produce",
    "ex": "彼は数々の名作を生み出した。",
    "exReading": "かれ は かずかず の めいさく を うみだした",
    "exTranslation": "He produced a number of masterpieces.",
    "category": "Verb"
  },
  {
    "term": "生き方",
    "termReading": "いきかた",
    "termTranslation": "way of life, lifestyle",
    "ex": "自分らしい生き方をしなさい。",
    "exReading": "じぶん らしい いきかた を しなさい",
    "exTranslation": "Live life in your own way.",
    "category": "Noun"
  },
  {
    "term": "生かす",
    "termReading": "いかす",
    "termTranslation": "take advantage of, make use of",
    "ex": "彼女は語学力を仕事に生かしているね。",
    "exReading": "かのじょ は ごがくりょく を しごと に いかして いる ね",
    "exTranslation": "She takes advantage of her language ability in her work.",
    "category": "Verb"
  },
  {
    "term": "生き物",
    "termReading": "いきもの",
    "termTranslation": "living creature",
    "ex": "生き物を大切にしましょう。",
    "exReading": "いきもの を たいせつ に しましょう",
    "exTranslation": "Let's care about living things.",
    "category": "Noun"
  },
  {
    "term": "生け花",
    "termReading": "いけばな",
    "termTranslation": "flower arrangement",
    "ex": "彼女は生け花の先生です。",
    "exReading": "かのじょ は いけばな の せんせい です",
    "exTranslation": "She is a flower arrangement teacher.",
    "category": "Noun"
  },
  {
    "term": "生年月日",
    "termReading": "せいねんがっぴ",
    "termTranslation": "date of birth",
    "ex": "ここに生年月日を記入してください。",
    "exReading": "ここ に せいねんがっぴ を きにゅう して ください",
    "exTranslation": "Please fill in your date of birth here.",
    "category": "Noun"
  },
  {
    "term": "生理",
    "termReading": "せいり",
    "termTranslation": "period, menstruation",
    "ex": "昨日、生理が始まったの。",
    "exReading": "きのう せいり が はじまった の",
    "exTranslation": "My period started yesterday.",
    "category": "Noun"
  },
  {
    "term": "スケジュール",
    "termReading": "スケジュール",
    "termTranslation": "schedule",
    "ex": "スケジュールの調整は君に任せるわ。",
    "exReading": "すけじゅーる の ちょうせい は きみ に まかせる わ",
    "exTranslation": "I'll leave it to you to coordinate my schedule.",
    "category": "Noun"
  },
  {
    "term": "生まれつき",
    "termReading": "うまれつき",
    "termTranslation": "born, by nature",
    "ex": "彼女は生まれつき丈夫です。",
    "exReading": "かのじょ は うまれつき じょうぶ です",
    "exTranslation": "She is healthy by nature.",
    "category": "Adverb"
  },
  {
    "term": "生",
    "termReading": "せい",
    "termTranslation": "life",
    "ex": "私がこの世に生を受けて80年が過ぎたわ。",
    "exReading": "わたし が このよ に せい を うけて はちじゅうねん が すぎた わ",
    "exTranslation": "More than 80 years have passed since I came into this world.",
    "category": "Noun"
  },
  {
    "term": "生物",
    "termReading": "せいぶつ",
    "termTranslation": "living creature, lifeform",
    "ex": "海の底には不思議な生物がたくさんいるよ。",
    "exReading": "うみ の そこ に は ふしぎ な せいぶつ が たくさん いる よ",
    "exTranslation": "There are a lot of strange creatures at the bottom of the sea.",
    "category": "Noun"
  },
  {
    "term": "生き生きと",
    "termReading": "いきいきと",
    "termTranslation": "lively, vividly",
    "ex": "子供たちが生き生きと遊んでいるね。",
    "exReading": "こどもたち が いきいきと あそんで いる ね",
    "exTranslation": "The kids are playing vigorously.",
    "category": "Adverb"
  },
  {
    "term": "性能",
    "termReading": "せいのう",
    "termTranslation": "performance, capacity",
    "ex": "今度のパソコンは性能がすごく良い。",
    "exReading": "こんど の ぱそこん は せいのう が すごく いい",
    "exTranslation": "The performance of this new computer is extremely good.",
    "category": "Noun"
  },
  {
    "term": "性格",
    "termReading": "せいかく",
    "termTranslation": "character, personality",
    "ex": "僕と姉の性格は正反対です。",
    "exReading": "ぼく と あね の せいかく は せいはんたい です",
    "exTranslation": "My personality and my elder sister's are completely opposite.",
    "category": "Noun"
  },
  {
    "term": "性",
    "termReading": "せい",
    "termTranslation": "sex, nature",
    "ex": "この会社では性による差別はありません。",
    "exReading": "この かいしゃ で は せい に よる さべつ は ありません",
    "exTranslation": "There is no gender discrimination in this company.",
    "category": "Noun"
  },
  {
    "term": "国産",
    "termReading": "こくさん",
    "termTranslation": "domestically produced",
    "ex": "このワインは国産です。",
    "exReading": "この わいん は こくさん です",
    "exTranslation": "This wine is a domestic product.",
    "category": "Noun"
  },
  {
    "term": "ガソリン",
    "termReading": "ガソリン",
    "termTranslation": "gasoline",
    "ex": "車にガソリンを入れました。",
    "exReading": "くるま に がそりん を いれました",
    "exTranslation": "I put gasoline in the car.",
    "category": "Noun"
  },
  {
    "term": "産地",
    "termReading": "さんち",
    "termTranslation": "production center",
    "ex": "その地方はお茶の産地です。",
    "exReading": "その ちほう は おちゃ の さんち です",
    "exTranslation": "The region is a production center for tea leaves.",
    "category": "Noun"
  },
  {
    "term": "出産",
    "termReading": "しゅっさん",
    "termTranslation": "birth, delivery",
    "ex": "彼女は女の子を出産したんだ。",
    "exReading": "かのじょ は おんな の こ を しゅっさん した ん だ",
    "exTranslation": "She gave birth to a girl.",
    "category": "Verbal Noun"
  },
  {
    "term": "活用",
    "termReading": "かつよう",
    "termTranslation": "utilization, conjugation",
    "ex": "彼女はインターネットを活用しているの。",
    "exReading": "かのじょ は いんたーねっと を かつよう して いる の",
    "exTranslation": "She makes use of the Internet.",
    "category": "Verbal Noun"
  },
  {
    "term": "活字",
    "termReading": "かつじ",
    "termTranslation": "printed type",
    "ex": "新聞の活字が読みやすくなったね。",
    "exReading": "しんぶん の かつじ が よみ やすく なった ね",
    "exTranslation": "The newspaper typeface became easier to read.",
    "category": "Noun"
  },
  {
    "term": "学会",
    "termReading": "がっかい",
    "termTranslation": "academic association, academic conference",
    "ex": "彼は学会で論文を発表したよ。",
    "exReading": "かれ は がっかい で ろんぶん を はっぴょう した よ",
    "exTranslation": "He presented a paper in an academic conference.",
    "category": "Noun"
  },
  {
    "term": "学年",
    "termReading": "がくねん",
    "termTranslation": "school year, grade",
    "ex": "彼は私より一学年上です。",
    "exReading": "かれ は わたし より ひと がくねん うえ です",
    "exTranslation": "He's one year above me in school.",
    "category": "Noun"
  },
  {
    "term": "工学",
    "termReading": "こうがく",
    "termTranslation": "engineering",
    "ex": "彼は大学で工学を勉強しました。",
    "exReading": "かれ は だいがく で こうがく を べんきょう しました",
    "exTranslation": "He studied engineering in college.",
    "category": "Noun"
  },
  {
    "term": "カット",
    "termReading": "かっと",
    "termTranslation": "cut",
    "ex": "市長がテープをカットした。",
    "exReading": "しちょう が てーぷ を かっと した",
    "exTranslation": "The mayor cut the ribbon.",
    "category": "Verbal Noun"
  },
  {
    "term": "学長",
    "termReading": "がくちょう",
    "termTranslation": "college president",
    "ex": "入学式で学長の挨拶がありました。",
    "exReading": "にゅうがくしき で がくちょう の あいさつ が ありました",
    "exTranslation": "The headmaster gave a greeting speech at the entrance ceremony.",
    "category": "Noun"
  },
  {
    "term": "語学",
    "termReading": "ごがく",
    "termTranslation": "language study",
    "ex": "海外で語学の勉強をします。",
    "exReading": "かいがい で ごがく の べんきょう を します",
    "exTranslation": "I will study a language abroad.",
    "category": "Noun"
  },
  {
    "term": "工学部",
    "termReading": "こうがくぶ",
    "termTranslation": "department of engineering",
    "ex": "彼は工学部の教授です。",
    "exReading": "かれ は こうがくぶ の きょうじゅ です",
    "exTranslation": "He is a professor in the Department of Engineering.",
    "category": "Noun"
  },
  {
    "term": "学費",
    "termReading": "がくひ",
    "termTranslation": "school expenses, tuition",
    "ex": "彼はアルバイトをして学費を稼いだんだ。",
    "exReading": "かれ は あるばいと を して がくひ を かせいだ ん だ 。",
    "exTranslation": "He worked part-time to earn his tuition money.",
    "category": "Noun"
  },
  {
    "term": "学部",
    "termReading": "がくぶ",
    "termTranslation": "faculty, department",
    "ex": "彼は経済学部の学生です。",
    "exReading": "かれ は けいざい がくぶ の がくせい です",
    "exTranslation": "He is a student in the Department of Economics.",
    "category": "Noun"
  },
  {
    "term": "学力",
    "termReading": "がくりょく",
    "termTranslation": "scholastic performance, academic ability",
    "ex": "学力を付けてその大学に進みたい。",
    "exReading": "がくりょく を つけて その だいがく に すすみたい",
    "exTranslation": "I want to improve my academic skills then go to that college.",
    "category": "Noun"
  },
  {
    "term": "休学",
    "termReading": "きゅうがく",
    "termTranslation": "leave of absence, absence",
    "ex": "1年休学することにしました。",
    "exReading": "いちねん きゅうがく する こと に しました",
    "exTranslation": "I decided to take a one-year leave from school.",
    "category": "Verbal Noun"
  },
  {
    "term": "スター",
    "termReading": "スター",
    "termTranslation": "star",
    "ex": "彼はその時スターだったよ。",
    "exReading": "かれ は その とき すたー だった よ 。",
    "exTranslation": "He was a star at the time.",
    "category": "Noun"
  },
  {
    "term": "教員",
    "termReading": "きょういん",
    "termTranslation": "teacher, teaching staff",
    "ex": "彼は高校の教員です。",
    "exReading": "かれ は こうこう の きょういん です",
    "exTranslation": "He's a high school teacher.",
    "category": "Noun"
  },
  {
    "term": "キリスト教",
    "termReading": "きりすときょう",
    "termTranslation": "Christianity",
    "ex": "この先にキリスト教の教会があります。",
    "exReading": "この さき に きりすときょう の きょうかい が あります",
    "exTranslation": "There is a Christian church ahead.",
    "category": "Noun"
  },
  {
    "term": "イスラム教",
    "termReading": "イスラムきょう",
    "termTranslation": "Islam, Muslim religion",
    "ex": "これはイスラム教の寺院です。",
    "exReading": "これ は いすらむきょう の じいん です",
    "exTranslation": "This is a mosque.",
    "category": "Noun"
  },
  {
    "term": "教わる",
    "termReading": "おそわる",
    "termTranslation": "be taught, learn",
    "ex": "私は両親から多くを教わりました。",
    "exReading": "わたし は りょうしん から おおく を おそわりました",
    "exTranslation": "I learned a lot from my parents.",
    "category": "Verb"
  },
  {
    "term": "教え",
    "termReading": "おしえ",
    "termTranslation": "lesson, teachings",
    "ex": "父の教えは「自分に厳しく」です。",
    "exReading": "ちち の おしえ は じぶん に きびしく です",
    "exTranslation": "My father told me to be strict with myself.",
    "category": "Noun"
  },
  {
    "term": "制御",
    "termReading": "せいぎょ",
    "termTranslation": "control, command",
    "ex": "ここのパネルで機械全体を制御できます。",
    "exReading": "ここ の ぱねる で きかい ぜんたい を せいぎょ できます",
    "exTranslation": "The entire machine can be controlled with the panel here.",
    "category": "Verbal Noun"
  },
  {
    "term": "強力",
    "termReading": "きょうりょく",
    "termTranslation": "powerful",
    "ex": "これは強力な接着剤ね。",
    "exReading": "これ は きょうりょく な せっちゃくざい ね",
    "exTranslation": "This is a powerful adhesive.",
    "category": "Adjectival Noun"
  },
  {
    "term": "強制",
    "termReading": "きょうせい",
    "termTranslation": "compulsion, force",
    "ex": "彼らは労働を強制されたんだ。",
    "exReading": "かれら は ろうどう を きょうせい された ん だ",
    "exTranslation": "They were forced to work.",
    "category": "Verbal Noun"
  },
  {
    "term": "カメラマン",
    "termReading": "カメラマン",
    "termTranslation": "photographer, cameraman",
    "ex": "将来はプロのカメラマンになりたいです。",
    "exReading": "しょうらい は ぷろ の かめらまん に なりたい です",
    "exTranslation": "I want to be a professional photographer in the future.",
    "category": "Noun"
  },
  {
    "term": "最強",
    "termReading": "さいきょう",
    "termTranslation": "strongest",
    "ex": "彼は最強チームの一員です。",
    "exReading": "かれ は さいきょう ちーむ の いちいん です",
    "exTranslation": "He is a member of the strongest team.",
    "category": "Noun"
  },
  {
    "term": "心強い",
    "termReading": "こころづよい",
    "termTranslation": "reassuring, heartening",
    "ex": "あなたが一緒にいてくれると心強い。",
    "exReading": "あなた が いっしょ に いて くれる と こころづよい",
    "exTranslation": "I feel reassured when we are together.",
    "category": "Adjective"
  },
  {
    "term": "強引",
    "termReading": "ごういん",
    "termTranslation": "overbearing, coercive",
    "ex": "友人の強引な誘いを断れませんでした。",
    "exReading": "ゆうじん の ごういん な さそい を ことわれません でした",
    "exTranslation": "I was not able to decline my friend's persistent invitation.",
    "category": "Adjective"
  },
  {
    "term": "引用",
    "termReading": "いんよう",
    "termTranslation": "quotation, citation",
    "ex": "論文にその本を引用したの。",
    "exReading": "ろんぶん に その ほん を いんよう した の",
    "exTranslation": "I quoted the book in my thesis.",
    "category": "Verbal Noun"
  },
  {
    "term": "字引",
    "termReading": "じびき",
    "termTranslation": "dictionary",
    "ex": "この漢字を字引で引いてみて。",
    "exReading": "この かんじ を じびき で ひいて みて",
    "exTranslation": "Look up this kanji in the dictionary.",
    "category": "Noun"
  },
  {
    "term": "学習",
    "termReading": "がくしゅう",
    "termTranslation": "study, learning",
    "ex": "今日は野外で学習した。",
    "exReading": "きょう は やがい で がくしゅう した",
    "exTranslation": "We studied outdoors today.",
    "category": "Verbal Noun"
  },
  {
    "term": "試合",
    "termReading": "しあい",
    "termTranslation": "match, game",
    "ex": "試合の結果を早く知りたい。",
    "exReading": "しあい の けっか を はやく しりたい",
    "exTranslation": "I want to know soon the results of the game.",
    "category": "Verbal Noun"
  },
  {
    "term": "アルコール",
    "termReading": "アルコール",
    "termTranslation": "alcohol, alcoholic beverage",
    "ex": "彼はアルコールに強い体質です。",
    "exReading": "かれ は あるこーる に つよい たいしつ です",
    "exTranslation": "He has a disposition to hold his liquor.",
    "category": "Noun"
  },
  {
    "term": "試みる",
    "termReading": "こころみる",
    "termTranslation": "try, attempt",
    "ex": "彼は実験を試みたのよ。",
    "exReading": "かれ は じっけん を こころみた の よ",
    "exTranslation": "He tried the experiment.",
    "category": "Verb"
  },
  {
    "term": "受験",
    "termReading": "じゅけん",
    "termTranslation": "take an examination",
    "ex": "日本語能力試験を受験したんだ。",
    "exReading": "にほんご のうりょく しけん を じゅけん した ん だ",
    "exTranslation": "I took the Japanese proficiency test.",
    "category": "Verbal Noun"
  },
  {
    "term": "性質",
    "termReading": "せいしつ",
    "termTranslation": "nature, property",
    "ex": "この犬は穏やかな性質だよ。",
    "exReading": "この いぬ は おだやか な せいしつ だ よ",
    "exTranslation": "This dog has a calm nature.",
    "category": "Noun"
  },
  {
    "term": "悪質",
    "termReading": "あくしつ",
    "termTranslation": "bad, malignant",
    "ex": "最近は悪質な事件が多いですね。",
    "exReading": "さいきん は あくしつ な じけん が おおい です ね",
    "exTranslation": "There have been a lot of malicious incidents recently.",
    "category": "Adjectival Noun"
  },
  {
    "term": "質",
    "termReading": "しつ",
    "termTranslation": "quality, nature",
    "ex": "量より質の方が大事です。",
    "exReading": "りょう より しつ の ほう が だいじ です",
    "exTranslation": "Quality is more important than quantity.",
    "category": "Noun"
  },
  {
    "term": "学問",
    "termReading": "がくもん",
    "termTranslation": "learning, scholarship",
    "ex": "彼は少年の頃から学問が好きでした。",
    "exReading": "かれ は しょうねん の ころ から がくもん が すき でした",
    "exTranslation": "He liked to learn ever since he was a little boy.",
    "category": "Verbal Noun"
  },
  {
    "term": "有り難い",
    "termReading": "ありがたい",
    "termTranslation": "welcome, grateful",
    "ex": "彼の助けは本当に有り難いな。",
    "exReading": "かれ の たすけ は ほんとう に ありがたい な",
    "exTranslation": "His help is really welcome.",
    "category": "Adjective"
  },
  {
    "term": "せめて",
    "termReading": "せめて",
    "termTranslation": "at least",
    "ex": "せめてこれだけは約束してください。",
    "exReading": "せめて これ だけ は やくそく して ください 。",
    "exTranslation": "Please promise only this at least.",
    "category": "Adverb"
  },
  {
    "term": "重点",
    "termReading": "じゅうてん",
    "termTranslation": "important point, emphasis",
    "ex": "子供の自主性に重点を置いています。",
    "exReading": "こども の じしゅせい に じゅうてん を おいて います",
    "exTranslation": "I put an emphasis on children's autonomy.",
    "category": "Noun"
  },
  {
    "term": "弱点",
    "termReading": "じゃくてん",
    "termTranslation": "weak point",
    "ex": "彼の弱点はスタミナが足りないところです。",
    "exReading": "かれ の じゃくてん は すたみな が たりない ところ です",
    "exTranslation": "His weakness is that he lacks stamina.",
    "category": "Noun"
  },
  {
    "term": "少数",
    "termReading": "しょうすう",
    "termTranslation": "small number, minority",
    "ex": "その計画に反対の人はほんの少数だったよ。",
    "exReading": "その けいかく に はんたい の ひと は ほんの しょうすう だった よ",
    "exTranslation": "Only a very few people opposed the plan.",
    "category": "Noun"
  },
  {
    "term": "小数",
    "termReading": "しょうすう",
    "termTranslation": "decimal",
    "ex": "小数は切り捨てて計算して下さい。",
    "exReading": "しょうすう は きりすてて けいさん して ください",
    "exTranslation": "Please round down the decimal and then calculate.",
    "category": "Noun"
  },
  {
    "term": "回路",
    "termReading": "かいろ",
    "termTranslation": "circuit",
    "ex": "コンピュータの電子回路が故障した。",
    "exReading": "こんぴゅーた の でんし かいろ が こしょう した",
    "exTranslation": "The computer's electric circuits have broken down.",
    "category": "Noun"
  },
  {
    "term": "回数",
    "termReading": "かいすう",
    "termTranslation": "frequency, number of times",
    "ex": "最近はテレビを見る回数が減りました。",
    "exReading": "さいきん は てれび を みる かいすう が へりました",
    "exTranslation": "I watch TV less frequently these days.",
    "category": "Noun"
  },
  {
    "term": "前回",
    "termReading": "ぜんかい",
    "termTranslation": "last time",
    "ex": "前回の続きから始めます。",
    "exReading": "ぜんかい の つづき から はじめます",
    "exTranslation": "We will start where we left off last time.",
    "category": "Noun"
  },
  {
    "term": "ストレス",
    "termReading": "ストレス",
    "termTranslation": "stress",
    "ex": "ストレスがいろいろな病気の元になっているの。",
    "exReading": "すとれす が いろいろ な びょうき の もと に なっている の 。",
    "exTranslation": "Stress is the source of various diseases.",
    "category": "Noun"
  },
  {
    "term": "後回し",
    "termReading": "あとまわし",
    "termTranslation": "deferment, postponement",
    "ex": "おしゃべりは後回しにしましょう。",
    "exReading": "おしゃべり は あとまわし に しましょう",
    "exTranslation": "Let's leave the chit-chat for later.",
    "category": "Noun"
  },
  {
    "term": "個性",
    "termReading": "こせい",
    "termTranslation": "individuality",
    "ex": "彼女たちはそれぞれ個性が強いですね。",
    "exReading": "かのじょたち は それぞれ こせい が つよい です ね",
    "exTranslation": "Each of those women has a strong personality.",
    "category": "Noun"
  },
  {
    "term": "個々",
    "termReading": "ここ",
    "termTranslation": "individually, one at a time",
    "ex": "個々の問題を解決しましょう。",
    "exReading": "ここ の もんだい を かいけつ しましょう",
    "exTranslation": "Let's solve the problems one by one.",
    "category": "Noun"
  },
  {
    "term": "勝手",
    "termReading": "かって",
    "termTranslation": "selfish, self-centered",
    "ex": "勝手なことばかり言わないでくれ。",
    "exReading": "かって な こと ばかり いわないで くれ",
    "exTranslation": "Don't say self-centered things all the time.",
    "category": "Adjective"
  },
  {
    "term": "決勝",
    "termReading": "けっしょう",
    "termTranslation": "final, final game",
    "ex": "僕たちは頑張って決勝まで進んだよ。",
    "exReading": "ぼくたち は がんばって けっしょう まで すすんだ よ",
    "exTranslation": "We tried hard and advanced to the finals.",
    "category": "Noun"
  },
  {
    "term": "勝ち",
    "termReading": "かち",
    "termTranslation": "win, victory",
    "ex": "歌合戦は赤組の勝ちでしたね。",
    "exReading": "うたがっせん は あか ぐみ の かち でした ね",
    "exTranslation": "The red team won the singing contest.",
    "category": "Noun"
  },
  {
    "term": "勝負",
    "termReading": "しょうぶ",
    "termTranslation": "match",
    "ex": "勝負はまだ始まったばかりよ。",
    "exReading": "しょうぶ は まだ はじまった ばかり よ",
    "exTranslation": "The match has just started.",
    "category": "Verbal Noun"
  },
  {
    "term": "担ぐ",
    "termReading": "かつぐ",
    "termTranslation": "shoulder, carry",
    "ex": "彼は大きな荷物を担いで来たの。",
    "exReading": "かれ は おおき な にもつ を かついで きた の",
    "exTranslation": "He came carrying large luggage.",
    "category": "Verb"
  },
  {
    "term": "コード",
    "termReading": "コード",
    "termTranslation": "cord",
    "ex": "コードが短くてコンセントに届きません。",
    "exReading": "こーど が みじかく て こんせんと に とどきません",
    "exTranslation": "The cord is too short to reach the outlet.",
    "category": "Noun"
  },
  {
    "term": "当てる",
    "termReading": "あてる",
    "termTranslation": "guess, hit the jackpot",
    "ex": "彼はくじ引きで一等賞を当てたよ。",
    "exReading": "かれ は くじびき で いっとう しょう を あてた よ",
    "exTranslation": "He won the first prize in the lottery.",
    "category": "Verb"
  },
  {
    "term": "当たり前",
    "termReading": "あたりまえ",
    "termTranslation": "of course, obvious",
    "ex": "あなたの成績が下がったのは当たり前です。",
    "exReading": "あなた の せいせき が さがった の は あたりまえ です",
    "exTranslation": "No wonder your grades dropped.",
    "category": "Adjectival Noun"
  },
  {
    "term": "見当",
    "termReading": "けんとう",
    "termTranslation": "estimate, aim",
    "ex": "この仕事には何日必要か見当もつかないね。",
    "exReading": "この しごと に は なんにち ひつよう か けんとう も つかない ね",
    "exTranslation": "I can't predict how many days are needed for this job.",
    "category": "Noun"
  },
  {
    "term": "当たり",
    "termReading": "あたり",
    "termTranslation": "hit, success",
    "ex": "彼の予想は大当たりでした。",
    "exReading": "かれ の よそう は おおあたり でした",
    "exTranslation": "His prediction was spot on.",
    "category": "Noun"
  },
  {
    "term": "規制",
    "termReading": "きせい",
    "termTranslation": "regulation, control",
    "ex": "牛肉の輸入が厳しく規制されているわね。",
    "exReading": "ぎゅうにく の ゆにゅう が きびしく きせい されて いる わ ね",
    "exTranslation": "Beef imports are severely regulated.",
    "category": "Verbal Noun"
  },
  {
    "term": "経費",
    "termReading": "けいひ",
    "termTranslation": "expense",
    "ex": "これからは経費を節約しましょう。",
    "exReading": "これ から は けいひ を せつやく しましょう",
    "exTranslation": "From now on let's save on expenses.",
    "category": "Noun"
  },
  {
    "term": "済む",
    "termReading": "すむ",
    "termTranslation": "be settled, complete",
    "ex": "父の手術が無事に済みました。",
    "exReading": "ちち の しゅじゅつ が ぶじ に すみました",
    "exTranslation": "My father's operation came off without a hitch.",
    "category": "Verb"
  },
  {
    "term": "コーチ",
    "termReading": "コーチ",
    "termTranslation": "coach",
    "ex": "新しいコーチの指導は厳しかった。",
    "exReading": "あたらしい こーち の しどう は きびしかった",
    "exTranslation": "The new coach's training was harsh.",
    "category": "Verbal Noun"
  },
  {
    "term": "済ませる",
    "termReading": "すませる",
    "termTranslation": "finish off, get done with",
    "ex": "昼ごはんは簡単に済ませましょう。",
    "exReading": "ひるごはん は かんたん に すませましょう",
    "exTranslation": "Let's have a quick lunch.",
    "category": "Verb"
  },
  {
    "term": "済ます",
    "termReading": "すます",
    "termTranslation": "settle, complete",
    "ex": "宿題を済ませてから遊びなさい。",
    "exReading": "しゅくだい を すませて から あそびなさい 。",
    "exTranslation": "Play after you finish doing your homework.",
    "category": "Verb"
  },
  {
    "term": "株式",
    "termReading": "かぶしき",
    "termTranslation": "stock",
    "ex": "彼は株式の売買で多額の利益を得たのさ。",
    "exReading": "かれ は かぶしき の ばいばい で たがく の りえき を えた の さ",
    "exTranslation": "He gained a large profit through buying and selling stocks.",
    "category": "Noun"
  },
  {
    "term": "式",
    "termReading": "しき",
    "termTranslation": "ceremony",
    "ex": "彼らは教会で式を挙げました。",
    "exReading": "かれら は きょうかい で しき を あげました",
    "exTranslation": "They had a wedding ceremony in the church.",
    "category": "Noun"
  },
  {
    "term": "業界",
    "termReading": "ぎょうかい",
    "termTranslation": "industry, business world",
    "ex": "私はIT業界で働いています。",
    "exReading": "わたし は IT ぎょうかい で はたらいて います",
    "exTranslation": "I'm working in the IT industry.",
    "category": "Noun"
  },
  {
    "term": "営業",
    "termReading": "えいぎょう",
    "termTranslation": "business, sales",
    "ex": "彼は営業を担当しています。",
    "exReading": "かれ は えいぎょう を たんとう しています 。",
    "exTranslation": "He is in charge of sales.",
    "category": "Verbal Noun"
  },
  {
    "term": "休業",
    "termReading": "きゅうぎょう",
    "termTranslation": "suspension of business, closure",
    "ex": "明日は臨時に休業します。",
    "exReading": "あした は りんじ に きゅうぎょう します",
    "exTranslation": "We'll be temporarily closed tomorrow.",
    "category": "Verbal Noun"
  },
  {
    "term": "ステージ",
    "termReading": "ステージ",
    "termTranslation": "stage",
    "ex": "彼はステージに立ったよ。",
    "exReading": "かれ は すてーじ に たった よ",
    "exTranslation": "He stood on the stage.",
    "category": "Noun"
  },
  {
    "term": "事業",
    "termReading": "じぎょう",
    "termTranslation": "business, achievement",
    "ex": "彼は事業に失敗したの。",
    "exReading": "かれ は じぎょう に しっぱい した の",
    "exTranslation": "He failed in that business.",
    "category": "Noun"
  },
  {
    "term": "金利",
    "termReading": "きんり",
    "termTranslation": "interest, interest rate",
    "ex": "最近、銀行の金利が少し上がった。",
    "exReading": "さいきん ぎんこう の きんり が すこし あがった",
    "exTranslation": "Recently, the bank's interest rates were raised.",
    "category": "Noun"
  },
  {
    "term": "利く",
    "termReading": "きく",
    "termTranslation": "work, function",
    "ex": "彼女は気が利いている。",
    "exReading": "かのじょ は き が きいて いる",
    "exTranslation": "She's very thoughtful.",
    "category": "Verb"
  },
  {
    "term": "収入",
    "termReading": "しゅうにゅう",
    "termTranslation": "income, earnings",
    "ex": "日本人の平均収入はどの位ですか。",
    "exReading": "にほんじん の へいきん しゅうにゅう は どの くらい です か",
    "exTranslation": "What is the national average income in Japan?",
    "category": "Noun"
  },
  {
    "term": "芸術",
    "termReading": "げいじゅつ",
    "termTranslation": "art, arts and crafts",
    "ex": "ここは芸術を愛する国です。",
    "exReading": "ここ は げいじゅつ を あいする くに です",
    "exTranslation": "This is a country where art is loved.",
    "category": "Noun"
  },
  {
    "term": "製品",
    "termReading": "せいひん",
    "termTranslation": "manufactured goods",
    "ex": "当社の製品は3年間の保障つきです。",
    "exReading": "とうしゃ の せいひん は さんねんかん の ほしょうつき です",
    "exTranslation": "Our company's products come with a three-year guarantee.",
    "category": "Noun"
  },
  {
    "term": "製作",
    "termReading": "せいさく",
    "termTranslation": "manufacture, production",
    "ex": "この映画は香港で製作されました。",
    "exReading": "この えいが は ほんこん で せいさく されました",
    "exTranslation": "This movie was produced in Hong Kong.",
    "category": "Verbal Noun"
  },
  {
    "term": "作製",
    "termReading": "さくせい",
    "termTranslation": "manufacture, production",
    "ex": "合鍵の作製には2日ほどかかります。",
    "exReading": "あいかぎ の さくせい に は ふつか ほど かかります",
    "exTranslation": "It takes about two days to make a duplicate key.",
    "category": "Verbal Noun"
  },
  {
    "term": "アドバイス",
    "termReading": "アドバイス",
    "termTranslation": "advice",
    "ex": "彼のアドバイスはいつも有り難いわね。",
    "exReading": "かれ の あどばいす は いつも ありがたい わ ね",
    "exTranslation": "His advice is always welcome.",
    "category": "Verbal Noun"
  },
  {
    "term": "必ずしも",
    "termReading": "かならずしも",
    "termTranslation": "not always, not necessarily",
    "ex": "親切は必ずしも喜ばれるわけではない。",
    "exReading": "しんせつ は かならずしも よろこばれる わけ で は ない",
    "exTranslation": "Kindness does not please people all the time.",
    "category": "Adverb"
  },
  {
    "term": "求人",
    "termReading": "きゅうじん",
    "termTranslation": "recruiting",
    "ex": "彼は求人広告で仕事を見つけたんだ。",
    "exReading": "かれ は きゅうじん こうこく で しごと を みつけた ん だ",
    "exTranslation": "He found a job through a recruiting ad.",
    "category": "Verbal Noun"
  },
  {
    "term": "額",
    "termReading": "がく",
    "termTranslation": "picture frame",
    "ex": "写真を額に入れて飾ったんだ。",
    "exReading": "しゃしん を がく に いれて かざった ん だ",
    "exTranslation": "I put the photo in a frame and hung it up.",
    "category": "Noun"
  },
  {
    "term": "金額",
    "termReading": "きんがく",
    "termTranslation": "amount of money, sum",
    "ex": "レシートで買い物の金額を確かめたよ。",
    "exReading": "れしーと で かいもの の きんがく を たしかめた よ",
    "exTranslation": "I confirmed the cost of the shopping with the receipt.",
    "category": "Noun"
  },
  {
    "term": "計",
    "termReading": "けい",
    "termTranslation": "total",
    "ex": "3人分の代金は計6000円です。",
    "exReading": "さんにんぶん の だいきん は けい ろくせんえん です",
    "exTranslation": "The cost for three people is 6000 yen in total.",
    "category": "Noun"
  },
  {
    "term": "合計",
    "termReading": "ごうけい",
    "termTranslation": "sum total",
    "ex": "合計金額を計算してください。",
    "exReading": "ごうけい きんがく を けいさん して ください",
    "exTranslation": "Please calculate the total amount of money.",
    "category": "Verbal Noun"
  },
  {
    "term": "家計",
    "termReading": "かけい",
    "termTranslation": "family finances",
    "ex": "彼女は家計を任されているの。",
    "exReading": "かのじょ は かけい を まかされて いる の",
    "exTranslation": "She's in charge of the family budget.",
    "category": "Noun"
  },
  {
    "term": "アルミニウム",
    "termReading": "アルミニウム",
    "termTranslation": "aluminum",
    "ex": "このお鍋はアルミニウム製です。",
    "exReading": "この おなべ は あるみにうむ せい です",
    "exTranslation": "This pan is made of aluminum.",
    "category": "Noun"
  },
  {
    "term": "会計",
    "termReading": "かいけい",
    "termTranslation": "account, finance",
    "ex": "会計を済ませて店を出たんだ。",
    "exReading": "かいけい を すませて みせ を でた ん だ",
    "exTranslation": "I payed the bill and then left the shop.",
    "category": "Noun"
  },
  {
    "term": "寒暖計",
    "termReading": "かんだんけい",
    "termTranslation": "thermometer",
    "ex": "壁に寒暖計が掛かっていました。",
    "exReading": "かべ に かんだんけい が かかって いました",
    "exTranslation": "There was a thermometer hanging on the wall.",
    "category": "Noun"
  },
  {
    "term": "差",
    "termReading": "さ",
    "termTranslation": "difference",
    "ex": "都心と地方では収入に大きな差があるね。",
    "exReading": "としん と ちほう で は しゅうにゅう に おおき な さ が ある ね",
    "exTranslation": "There is a big difference in income between rural and urban areas.",
    "category": "Noun"
  },
  {
    "term": "格差",
    "termReading": "かくさ",
    "termTranslation": "gap, disparity",
    "ex": "貧富の格差が大きくなっているな。",
    "exReading": "ひんぷ の かくさ が おおきく なって いる な",
    "exTranslation": "The gap between rich and poor is growing.",
    "category": "Noun"
  },
  {
    "term": "差し出す",
    "termReading": "さしだす",
    "termTranslation": "submit, stretch out",
    "ex": "彼は握手をしようと手を差し出したの。",
    "exReading": "かれ は あくしゅ を しようと て を さしだした の",
    "exTranslation": "He put out his hand to shake hands.",
    "category": "Verb"
  },
  {
    "term": "時差",
    "termReading": "じさ",
    "termTranslation": "time difference",
    "ex": "日本とフランスの時差は8時間です。",
    "exReading": "にほん と ふらんす の じさ は はちじかん です",
    "exTranslation": "The time difference between Japan and France is eight hours.",
    "category": "Noun"
  },
  {
    "term": "差す",
    "termReading": "さす",
    "termTranslation": "open (an umbrella)",
    "ex": "雨が降ってきたので傘を差しました。",
    "exReading": "あめ が ふって きた の で かさ を さしました",
    "exTranslation": "I opened my umbrella as it started to rain.",
    "category": "Verb"
  },
  {
    "term": "オーケストラ",
    "termReading": "オーケストラ",
    "termTranslation": "orchestra, orchestral music",
    "ex": "彼はオーケストラを指揮しているの。",
    "exReading": "かれ は おーけすとら を しき して いる の",
    "exTranslation": "He is conducting the orchestra.",
    "category": "Noun"
  },
  {
    "term": "差し上げる",
    "termReading": "さしあげる",
    "termTranslation": "respectfully give",
    "ex": "こちらを差し上げます。",
    "exReading": "こちら を さしあげます",
    "exTranslation": "This is for you.",
    "category": "Verb"
  },
  {
    "term": "学割",
    "termReading": "がくわり",
    "termTranslation": "student discount",
    "ex": "学割だとだいぶ安いな。",
    "exReading": "がくわり だ と だいぶ やすい な",
    "exTranslation": "It is quite cheap with a student discount.",
    "category": "Noun"
  },
  {
    "term": "時間割り",
    "termReading": "じかんわり",
    "termTranslation": "time table",
    "ex": "明日の授業は時間割り通りです。",
    "exReading": "あした の じゅぎょう は じかんわり どおり です",
    "exTranslation": "Tomorrow's class is as noted on the time table.",
    "category": "Noun"
  },
  {
    "term": "残業",
    "termReading": "ざんぎょう",
    "termTranslation": "overtime",
    "ex": "昨日は遅くまで残業しました。",
    "exReading": "きのう は おそく まで ざんぎょう しました",
    "exTranslation": "I worked overtime till late yesterday.",
    "category": "Verbal Noun"
  },
  {
    "term": "残暑",
    "termReading": "ざんしょ",
    "termTranslation": "lingering summer heat",
    "ex": "今年も残暑が厳しかった。",
    "exReading": "ことし も ざんしょ が きびしかった",
    "exTranslation": "The late-summer heat was harsh this year, too.",
    "category": "Noun"
  },
  {
    "term": "支店",
    "termReading": "してん",
    "termTranslation": "branch",
    "ex": "彼は支店に転勤したよ。",
    "exReading": "かれ は してん に てんきん した よ",
    "exTranslation": "He was transferred to one of the branches.",
    "category": "Noun"
  },
  {
    "term": "支持",
    "termReading": "しじ",
    "termTranslation": "support, maintenance",
    "ex": "彼は国民の支持を得たのよ。",
    "exReading": "かれ は こくみん の しじ を えた の よ",
    "exTranslation": "He won the people's support.",
    "category": "Verbal Noun"
  },
  {
    "term": "支出",
    "termReading": "ししゅつ",
    "termTranslation": "expenditure, disbursement",
    "ex": "今月のわが家の支出は15万円です。",
    "exReading": "こんげつ の わがや の ししゅつ は じゅうごまんえん です",
    "exTranslation": "Our household expenses are 150,000 yen this month.",
    "category": "Verbal Noun"
  },
  {
    "term": "おじさん",
    "termReading": "おじさん",
    "termTranslation": "Mister, middle-aged man",
    "ex": "今そこで隣のおじさんに会ったよ。",
    "exReading": "いま そこで となり の おじさん に あった よ",
    "exTranslation": "I just met the man next door.",
    "category": "Noun"
  },
  {
    "term": "支配",
    "termReading": "しはい",
    "termTranslation": "control, management",
    "ex": "その権力者による支配は50年以上続いたんです。",
    "exReading": "その けんりょくしゃ に よる しはい は ごじゅうねん いじょう つづいた ん です",
    "exTranslation": "The potentate continued to rule for over 50 years.",
    "category": "Verbal Noun"
  },
  {
    "term": "収支",
    "termReading": "しゅうし",
    "termTranslation": "earnings and expenses",
    "ex": "家計の収支が合わないの。",
    "exReading": "かけい の しゅうし が あわない の",
    "exTranslation": "The household income and expenses do not match.",
    "category": "Noun"
  },
  {
    "term": "支度",
    "termReading": "したく",
    "termTranslation": "arrangement, preparation",
    "ex": "支度ができたら出かけましょう。",
    "exReading": "したく が できたら でかけましょう",
    "exTranslation": "Let's go out when we are ready.",
    "category": "Verbal Noun"
  },
  {
    "term": "支える",
    "termReading": "ささえる",
    "termTranslation": "support, maintain",
    "ex": "父親には一家を支える責任がある。",
    "exReading": "ちちおや に は いっか を ささえる せきにん が ある",
    "exTranslation": "My father has the responsibility to support the family.",
    "category": "Verb"
  },
  {
    "term": "支社",
    "termReading": "ししゃ",
    "termTranslation": "branch office",
    "ex": "来月大阪に支社を開設します。",
    "exReading": "らいげつ おおさか に ししゃ を かいせつ します",
    "exTranslation": "A branch office will be established in Osaka next month.",
    "category": "Noun"
  },
  {
    "term": "支払う",
    "termReading": "しはらう",
    "termTranslation": "pay",
    "ex": "カウンターで料金を支払った。",
    "exReading": "かうんたー で りょうきん を しはらった",
    "exTranslation": "I paid the fee at the counter.",
    "category": "Verb"
  },
  {
    "term": "支払い",
    "termReading": "しはらい",
    "termTranslation": "payment, payout",
    "ex": "お支払いはカードもお使いいただけます。",
    "exReading": "おしはらい は かーど も お つかい いただけます",
    "exTranslation": "You can also pay by credit card.",
    "category": "Verbal Noun"
  },
  {
    "term": "コンクール",
    "termReading": "コンクール",
    "termTranslation": "contest, competition",
    "ex": "来年のコンクールに出場するつもりです。",
    "exReading": "らいねん の こんくーる に しゅつじょう する つもり です",
    "exTranslation": "I am going to participate in next year's contest.",
    "category": "Noun"
  },
  {
    "term": "言い返す",
    "termReading": "いいかえす",
    "termTranslation": "talk back, retort",
    "ex": "彼女は負けずに言い返したの。",
    "exReading": "かのじょ は まけず に いいかえした の",
    "exTranslation": "She talked back just as much.",
    "category": "Verb"
  },
  {
    "term": "返る",
    "termReading": "かえる",
    "termTranslation": "be restored to, return to",
    "ex": "もう一度原点に返って考えましょう。",
    "exReading": "もういちど げんてん に かえって かんがえましょう",
    "exTranslation": "Let's go back to the beginning and think again.",
    "category": "Verb"
  },
  {
    "term": "借り",
    "termReading": "かり",
    "termTranslation": "borrowing, debt",
    "ex": "この借りは必ず返します。",
    "exReading": "この かり は かならず かえします",
    "exTranslation": "I will definitely pay it back.",
    "category": "Noun"
  },
  {
    "term": "借金",
    "termReading": "しゃっきん",
    "termTranslation": "borrowing money, debt",
    "ex": "彼は友達に借金をしたんだ。",
    "exReading": "かれ は ともだち に しゃっきん を した ん だ",
    "exTranslation": "He borrowed money from a friend.",
    "category": "Verbal Noun"
  },
  {
    "term": "貸し出し",
    "termReading": "かしだし",
    "termTranslation": "lending service, checking out",
    "ex": "その本は貸し出ししていません。",
    "exReading": "その ほん は かしだし して いません",
    "exTranslation": "That book can't be checked out.",
    "category": "Verbal Noun"
  },
  {
    "term": "貸し",
    "termReading": "かし",
    "termTranslation": "loan, renting",
    "ex": "彼には貸しがあるの。",
    "exReading": "かれ に は かし が ある の",
    "exTranslation": "He owes me one.",
    "category": "Noun"
  },
  {
    "term": "申請",
    "termReading": "しんせい",
    "termTranslation": "application, petition",
    "ex": "これからパスポートの申請に行きます。",
    "exReading": "これから ぱすぽーと の しんせい に いきます",
    "exTranslation": "I am now going to apply for my passport.",
    "category": "Verbal Noun"
  },
  {
    "term": "アマチュア",
    "termReading": "アマチュア",
    "termTranslation": "amateur",
    "ex": "彼はアマチュア音楽家です。",
    "exReading": "かれ は あまちゅあ おんがくか です",
    "exTranslation": "He is an amateur musician.",
    "category": "Noun"
  },
  {
    "term": "込める",
    "termReading": "こめる",
    "termTranslation": "put in, concentrate on",
    "ex": "彼は感情を込めてその歌を歌ったの。",
    "exReading": "かれ は かんじょう を こめて その うた を うたった の",
    "exTranslation": "He sang the song with feeling.",
    "category": "Verb"
  },
  {
    "term": "打ち込む",
    "termReading": "うちこむ",
    "termTranslation": "devote oneself",
    "ex": "彼は研究に打ち込んでいます。",
    "exReading": "かれ は けんきゅう に うちこんで います",
    "exTranslation": "He is dedicated to his research.",
    "category": "Verb"
  },
  {
    "term": "思い込む",
    "termReading": "おもいこむ",
    "termTranslation": "assume, believe",
    "ex": "彼は騙されたと思い込んでいるようです。",
    "exReading": "かれ は だまされた と おもいこんで いる よう です",
    "exTranslation": "He's apparently convinced he's been cheated.",
    "category": "Verb"
  },
  {
    "term": "初期",
    "termReading": "しょき",
    "termTranslation": "early days, initial",
    "ex": "鼻水は風邪の初期症状のひとつです。",
    "exReading": "はなみず は かぜ の しょき しょうじょう の ひとつ です",
    "exTranslation": "A runny nose is one of the initial symptoms of a cold.",
    "category": "Noun"
  },
  {
    "term": "学期",
    "termReading": "がっき",
    "termTranslation": "school term",
    "ex": "新学期が始まったね。",
    "exReading": "しんがっき が はじまった ね",
    "exTranslation": "A new semester has started.",
    "category": "Noun"
  },
  {
    "term": "後期",
    "termReading": "こうき",
    "termTranslation": "latter period",
    "ex": "後期の授業が始まりました。",
    "exReading": "こうき の じゅぎょう が はじまりました",
    "exTranslation": "The classes for the final term started.",
    "category": "Noun"
  },
  {
    "term": "前期",
    "termReading": "ぜんき",
    "termTranslation": "previous term, last semester",
    "ex": "前期の売上はとても良かったわ。",
    "exReading": "ぜんき の うりあげ は とても よかった わ",
    "exTranslation": "Sales during the first term were very good.",
    "category": "Noun"
  },
  {
    "term": "きつい",
    "termReading": "きつい",
    "termTranslation": "severe, hard, strict",
    "ex": "彼女は性格がきついよね。",
    "exReading": "かのじょ は せいかく が きつい よ ね",
    "exTranslation": "She has a strong personality.",
    "category": "Adjective"
  },
  {
    "term": "期日",
    "termReading": "きじつ",
    "termTranslation": "date, due date",
    "ex": "代金を期日までにお支払いください。",
    "exReading": "だいきん を きじつ まで に おしはらい ください",
    "exTranslation": "Please pay the charge by the due date.",
    "category": "Noun"
  },
  {
    "term": "新学期",
    "termReading": "しんがっき",
    "termTranslation": "new term, new semester",
    "ex": "今日から新学期が始まります。",
    "exReading": "きょう から しんがっき が はじまります",
    "exTranslation": "The new semester starts today.",
    "category": "Noun"
  },
  {
    "term": "限り",
    "termReading": "かぎり",
    "termTranslation": "limit, as far as possible",
    "ex": "限りある資源を大切にしよう。",
    "exReading": "かぎり ある しげん を たいせつ に しよう",
    "exTranslation": "Let's take good care of limited resources.",
    "category": "Noun"
  },
  {
    "term": "制限",
    "termReading": "せいげん",
    "termTranslation": "restriction, limit",
    "ex": "彼女は食事を制限しています。",
    "exReading": "かのじょ は しょくじ を せいげん して います",
    "exTranslation": "She is restricting her diet.",
    "category": "Verbal Noun"
  },
  {
    "term": "限界",
    "termReading": "げんかい",
    "termTranslation": "boundary, limit",
    "ex": "もう我慢の限界です。",
    "exReading": "もう がまん の げんかい です",
    "exTranslation": "I can't take any more.",
    "category": "Noun"
  },
  {
    "term": "期限",
    "termReading": "きげん",
    "termTranslation": "time limit, term",
    "ex": "期限までに申し込みました。",
    "exReading": "きげん まで に もうしこみました",
    "exTranslation": "I signed up before the deadline.",
    "category": "Noun"
  },
  {
    "term": "限度",
    "termReading": "げんど",
    "termTranslation": "limit, bounds",
    "ex": "物事には限度があります。",
    "exReading": "ものごと に は げんど が あります",
    "exTranslation": "Every matter has its limit.",
    "category": "Noun"
  },
  {
    "term": "大急ぎ",
    "termReading": "おおいそぎ",
    "termTranslation": "in a great hurry",
    "ex": "大急ぎでその仕事を仕上げたよ。",
    "exReading": "おおいそぎ で その しごと を しあげた よ",
    "exTranslation": "I finished up the work in a rush.",
    "category": "Adjectival Noun"
  },
  {
    "term": "コーナー",
    "termReading": "コーナー",
    "termTranslation": "corner",
    "ex": "バーゲンコーナーでDVDを買いました。",
    "exReading": "ばーげんこーなー で でぃーぶいでぃー を かいました",
    "exTranslation": "I bought a DVD in the sale corner.",
    "category": "Noun"
  },
  {
    "term": "急用",
    "termReading": "きゅうよう",
    "termTranslation": "urgent business",
    "ex": "彼は急用で帰りました。",
    "exReading": "かれ は きゅうよう で かえりました",
    "exTranslation": "He went home on urgent businness.",
    "category": "Noun"
  },
  {
    "term": "一切",
    "termReading": "いっさい",
    "termTranslation": "not at all, not one bit",
    "ex": "私は一切その問題とは関係がありません。",
    "exReading": "わたし は いっさい その もんだい と は かんけい が ありません",
    "exTranslation": "I have absolutely nothing to do with that issue.",
    "category": "Adverb"
  },
  {
    "term": "区切る",
    "termReading": "くぎる",
    "termTranslation": "punctuate, divide",
    "ex": "フロアはパーティションで区切られています。",
    "exReading": "ふろあ は ぱーてぃしょん で くぎられて います",
    "exTranslation": "The floor has been divided by a partition.",
    "category": "Verb"
  },
  {
    "term": "思い切って",
    "termReading": "おもいきって",
    "termTranslation": "boldly, decisively",
    "ex": "思い切って彼に相談します。",
    "exReading": "おもいきって かれ に そうだん します",
    "exTranslation": "I'll take the plunge and ask him for advice.",
    "category": "Adverb"
  },
  {
    "term": "品切れ",
    "termReading": "しなぎれ",
    "termTranslation": "run out, sell out",
    "ex": "牛乳は品切れだったよ。",
    "exReading": "ぎゅうにゅう は しなぎれ だった よ",
    "exTranslation": "Milk was sold out.",
    "category": "Noun"
  },
  {
    "term": "思い切り",
    "termReading": "おもいきり",
    "termTranslation": "to one's heart's content, with all one's might",
    "ex": "カラオケで思い切り歌ったの。",
    "exReading": "からおけ で おもいきり うたった の",
    "exTranslation": "I sang karaoke to my heart's content.",
    "category": "Adverb"
  },
  {
    "term": "切れ",
    "termReading": "きれ",
    "termTranslation": "sharpness",
    "ex": "この包丁は切れが良いな。",
    "exReading": "この ほうちょう は きれ が いい な",
    "exTranslation": "This kitchen knife cuts well.",
    "category": "Noun"
  },
  {
    "term": "ぎりぎり",
    "termReading": "ぎりぎり",
    "termTranslation": "just in time, barely",
    "ex": "会社の始業時間にぎりぎりで間に合ったよ。",
    "exReading": "かいしゃ の しぎょうじかん に ぎりぎり で まにあった よ",
    "exTranslation": "I just made it in time for the start of work.",
    "category": "Adverb"
  },
  {
    "term": "券",
    "termReading": "けん",
    "termTranslation": "voucher, ticket",
    "ex": "入場券をお持ちですか。",
    "exReading": "にゅうじょうけん を お もち です か",
    "exTranslation": "Do you have an admission ticket?",
    "category": "Noun"
  },
  {
    "term": "回数券",
    "termReading": "かいすうけん",
    "termTranslation": "book of tickets",
    "ex": "バスの回数券を買いました。",
    "exReading": "ばす の かいすうけん を かいました",
    "exTranslation": "I bought a strip of tickets for the bus.",
    "category": "Noun"
  },
  {
    "term": "世代",
    "termReading": "せだい",
    "termTranslation": "generation",
    "ex": "私は彼と同じ世代です。",
    "exReading": "わたし は かれ と おなじ せだい です",
    "exTranslation": "I am of the same generation as him.",
    "category": "Noun"
  },
  {
    "term": "代わり",
    "termReading": "かわり",
    "termTranslation": "substitution",
    "ex": "ごま油の代わりにオリーブ油を使いましょう。",
    "exReading": "ごまあぶら の かわり に おりーぶゆ を つかいましょう",
    "exTranslation": "Let's use olive oil instead of sesame.",
    "category": "Noun"
  },
  {
    "term": "近代",
    "termReading": "きんだい",
    "termTranslation": "modern times",
    "ex": "近代の技術の発展はものすごいです。",
    "exReading": "きんだい の ぎじゅつ の はってん は ものすごい です",
    "exTranslation": "The development of technology in this modern age is truly amazing.",
    "category": "Noun"
  },
  {
    "term": "古代",
    "termReading": "こだい",
    "termTranslation": "ancient times, antiquity",
    "ex": "古代の歴史について勉強しました。",
    "exReading": "こだい の れきし に ついて べんきょう しました",
    "exTranslation": "I studied ancient history.",
    "category": "Noun"
  },
  {
    "term": "代わる代わる",
    "termReading": "かわるがわる",
    "termTranslation": "by turns, alternately",
    "ex": "皆が代わる代わる彼らを祝福したよ。",
    "exReading": "みな が かわるがわる かれら を しゅくふく した よ",
    "exTranslation": "Everyone congratulated them by turns.",
    "category": "Adverb"
  },
  {
    "term": "ウサギ",
    "termReading": "ウサギ",
    "termTranslation": "rabbit, hare",
    "ex": "ウサギとカメの話を知っていますか。",
    "exReading": "うさぎ と かめ の はなし を しって います か",
    "exTranslation": "Do you know the story of the tortoise and the hare?",
    "category": "Noun"
  },
  {
    "term": "指す",
    "termReading": "さす",
    "termTranslation": "point to, aim at",
    "ex": "時計が12時を指してる。",
    "exReading": "とけい が じゅうにじ を さして る",
    "exTranslation": "The clock points to 12 o'clock.",
    "category": "Verb"
  },
  {
    "term": "小指",
    "termReading": "こゆび",
    "termTranslation": "little finger, little toe",
    "ex": "小指を切ってしまいました。",
    "exReading": "こゆび を きって しまいました",
    "exTranslation": "I cut my little finger.",
    "category": "Noun"
  },
  {
    "term": "安定",
    "termReading": "あんてい",
    "termTranslation": "stability, composure",
    "ex": "彼は精神の安定が必要よ。",
    "exReading": "かれ は せいしん の あんてい が ひつよう よ",
    "exTranslation": "He needs mental stability.",
    "category": "Verbal Noun"
  },
  {
    "term": "規定",
    "termReading": "きてい",
    "termTranslation": "regulation, provision",
    "ex": "代金には規定の手数料が含まれます。",
    "exReading": "だいきん に は きてい の てすうりょう が ふくまれます",
    "exTranslation": "Our price includes a set handling fee.",
    "category": "Verbal Noun"
  },
  {
    "term": "定める",
    "termReading": "さだめる",
    "termTranslation": "fix, determine",
    "ex": "税に関する新しい法律が定められたぞ。",
    "exReading": "ぜい に かんする あたらしい ほうりつ が さだめられた ぞ",
    "exTranslation": "A new law concerning taxes was established.",
    "category": "Verb"
  },
  {
    "term": "指定",
    "termReading": "してい",
    "termTranslation": "designation, appointment",
    "ex": "指定された席にお座りください。",
    "exReading": "してい された せき に お すわり ください",
    "exTranslation": "Please sit on the designated seat.",
    "category": "Verbal Noun"
  },
  {
    "term": "悪化",
    "termReading": "あっか",
    "termTranslation": "worsening, aggravation",
    "ex": "手の傷が悪化した。",
    "exReading": "て の きず が あっか した",
    "exTranslation": "The wound in my hand has gotten worse.",
    "category": "Verbal Noun"
  },
  {
    "term": "化学",
    "termReading": "かがく",
    "termTranslation": "chemistry",
    "ex": "彼は化学の教授です。",
    "exReading": "かれ は かがく の きょうじゅ です",
    "exTranslation": "He's a chemistry professor.",
    "category": "Noun"
  },
  {
    "term": "グラウンド",
    "termReading": "グラウンド",
    "termTranslation": "sports field",
    "ex": "野球部はグラウンドで練習しています。",
    "exReading": "やきゅうぶ は ぐらうんど で れんしゅう して います",
    "exTranslation": "The baseball club is practicing at the field.",
    "category": "Noun"
  },
  {
    "term": "消化",
    "termReading": "しょうか",
    "termTranslation": "digestion, consumption",
    "ex": "彼は消化不良を起こしたんだ。",
    "exReading": "かれ は しょうか ふりょう を おこした ん だ",
    "exTranslation": "He had indigestion.",
    "category": "Verbal Noun"
  },
  {
    "term": "更に",
    "termReading": "さらに",
    "termTranslation": "furthermore, still more",
    "ex": "彼は更に質問を続けたの。",
    "exReading": "かれ は さらに しつもん を つづけた の",
    "exTranslation": "He kept raising more questions.",
    "category": "Adverb"
  },
  {
    "term": "今更",
    "termReading": "いまさら",
    "termTranslation": "now, too late",
    "ex": "今更後悔しても、もう遅いよ。",
    "exReading": "いまさら こうかい して も もう おそい よ",
    "exTranslation": "It is too late for regrets.",
    "category": "Adverb"
  },
  {
    "term": "急増",
    "termReading": "きゅうぞう",
    "termTranslation": "sudden increase",
    "ex": "最近ヨガをやる人が急増しています。",
    "exReading": "さいきん よが を やる ひと が きゅうぞう して います",
    "exTranslation": "Recently, there's been a sudden rise in the number of people doing yoga.",
    "category": "Verbal Noun"
  },
  {
    "term": "減少",
    "termReading": "げんしょう",
    "termTranslation": "decrease, reduction",
    "ex": "この国は人口が減少しているわね。",
    "exReading": "この くに は じんこう が げんしょう して いる わ ね",
    "exTranslation": "The population of this country is decreasing.",
    "category": "Verbal Noun"
  },
  {
    "term": "乗車券",
    "termReading": "じょうしゃけん",
    "termTranslation": "railway ticket, bus ticket",
    "ex": "乗車券は無くさないように。",
    "exReading": "じょうしゃけん は なくさない よう に",
    "exTranslation": "Do not lose the ticket.",
    "category": "Noun"
  },
  {
    "term": "乗車",
    "termReading": "じょうしゃ",
    "termTranslation": "boarding, getting on board",
    "ex": "このバスの運賃は乗車するときに払います。",
    "exReading": "この ばす の うんちん は じょうしゃ する とき に はらいます",
    "exTranslation": "On this bus, you pay the fare when you get on.",
    "category": "Verbal Noun"
  },
  {
    "term": "さっぱり",
    "termReading": "さっぱり",
    "termTranslation": "completely, not at all",
    "ex": "彼女の言っていることがさっぱり分からないの。",
    "exReading": "かのじょ の いって いる こと が さっぱり わからない の",
    "exTranslation": "I can't understand what she says at all.",
    "category": "Adverb"
  },
  {
    "term": "乗客",
    "termReading": "じょうきゃく",
    "termTranslation": "passenger",
    "ex": "乗客の一人の具合が悪くなったの。",
    "exReading": "じょうきゃく の いち にん の ぐあい が わるく なった の 。",
    "exTranslation": "The condition of one passenger got worse.",
    "category": "Noun"
  },
  {
    "term": "雨降り",
    "termReading": "あめふり",
    "termTranslation": "rainfall, rainy weather",
    "ex": "雨降りで月が見えなかったね。",
    "exReading": "あめふり で つき が みえなかった ね",
    "exTranslation": "We couldn't see the moon because of the rain.",
    "category": "Noun"
  },
  {
    "term": "税金",
    "termReading": "ぜいきん",
    "termTranslation": "tax",
    "ex": "これは税金の無駄遣いだね。",
    "exReading": "これ は ぜいきん の むだづかい だ ね",
    "exTranslation": "This is a waste of tax money.",
    "category": "Noun"
  },
  {
    "term": "税",
    "termReading": "ぜい",
    "termTranslation": "tax, duty",
    "ex": "これ以上税が上がると生活できないね。",
    "exReading": "これ いじょう ぜい が あがる と せいかつ できない ね",
    "exTranslation": "If tax goes up any further, we will not be able to make a living.",
    "category": "Noun"
  },
  {
    "term": "私立",
    "termReading": "しりつ",
    "termTranslation": "private",
    "ex": "私立の大学は学費が高い。",
    "exReading": "しりつ の だいがく は がくひ が たかい",
    "exTranslation": "Tuition for the private university is expensive.",
    "category": "Noun"
  },
  {
    "term": "市立",
    "termReading": "しりつ",
    "termTranslation": "municipal, city",
    "ex": "娘は市立の学校に通っています。",
    "exReading": "むすめ は しりつ の がっこう に かよって います",
    "exTranslation": "My daughter goes to a municipal school.",
    "category": "Noun"
  },
  {
    "term": "県立",
    "termReading": "けんりつ",
    "termTranslation": "prefectural",
    "ex": "彼は県立の高校に通っているよ。",
    "exReading": "かれ は けんりつ の こうこう に かよって いる よ",
    "exTranslation": "He goes to the prefectural high school.",
    "category": "Noun"
  },
  {
    "term": "シャッター",
    "termReading": "シャッター",
    "termTranslation": "shutter",
    "ex": "丸いボタンを押すとシャッターが閉まります。",
    "exReading": "まるい ぼたん を おすと しゃったー が しまります",
    "exTranslation": "The shutter closes when you push the round button.",
    "category": "Noun"
  },
  {
    "term": "国立",
    "termReading": "こくりつ",
    "termTranslation": "national",
    "ex": "新しい国立劇場が完成しました。",
    "exReading": "あたらしい こくりつ げきじょう が かんせい しました",
    "exTranslation": "The new National Theater was completed.",
    "category": "Noun"
  },
  {
    "term": "座席",
    "termReading": "ざせき",
    "termTranslation": "seat",
    "ex": "飛行機の座席はゆったりしていたよ。",
    "exReading": "ひこうき の ざせき は ゆったり して いた よ",
    "exTranslation": "The airplane seat was spacious.",
    "category": "Noun"
  },
  {
    "term": "客席",
    "termReading": "きゃくせき",
    "termTranslation": "seat, passenger seat",
    "ex": "私たちは客席に座ったんだ。",
    "exReading": "わたしたち は きゃくせき に すわった ん だ",
    "exTranslation": "We sat on the seat.",
    "category": "Noun"
  },
  {
    "term": "欠点",
    "termReading": "けってん",
    "termTranslation": "weak point, defect",
    "ex": "欠点のない人間はいません。",
    "exReading": "けってん の ない にんげん は いません",
    "exTranslation": "No person is without faults.",
    "category": "Noun"
  },
  {
    "term": "欠ける",
    "termReading": "かける",
    "termTranslation": "be chipped, be missing",
    "ex": "お気に入りのカップが欠けてしまいました。",
    "exReading": "おきにいり の かっぷ が かけて しまいました",
    "exTranslation": "My favorite cup has been chipped.",
    "category": "Verb"
  },
  {
    "term": "欠く",
    "termReading": "かく",
    "termTranslation": "lack",
    "ex": "彼の態度は誠意を欠いています。",
    "exReading": "かれ の たいど は せいい を かいて います",
    "exTranslation": "His attitude lacks sincerity.",
    "category": "Verb"
  },
  {
    "term": "次回",
    "termReading": "じかい",
    "termTranslation": "next time",
    "ex": "次回の会議は2週間後に行います。",
    "exReading": "じかい の かいぎ は に しゅうかん ご に おこないます",
    "exTranslation": "The next meeting will be held in two weeks.",
    "category": "Noun"
  },
  {
    "term": "運用",
    "termReading": "うんよう",
    "termTranslation": "operation, management",
    "ex": "会計士に資金運用について相談したよ。",
    "exReading": "かいけいし に しきん うんよう に ついて そうだん した よ",
    "exTranslation": "I consulted an accountant about managing my assets.",
    "category": "Verbal Noun"
  },
  {
    "term": "スピーカー",
    "termReading": "スピーカー",
    "termTranslation": "speaker",
    "ex": "このスピーカーは音がいいですね。",
    "exReading": "この すぴーかー は おと が いい です ね",
    "exTranslation": "The sound of this speaker is good.",
    "category": "Noun"
  },
  {
    "term": "運営",
    "termReading": "うんえい",
    "termTranslation": "operation, management",
    "ex": "その事業は国が運営しています。",
    "exReading": "その じぎょう は くに が うんえい して います",
    "exTranslation": "The project is operated by the government.",
    "category": "Verbal Noun"
  },
  {
    "term": "運賃",
    "termReading": "うんちん",
    "termTranslation": "passenger fare",
    "ex": "鉄道の運賃が値上げされたね。",
    "exReading": "てつどう の うんちん が ねあげ された ね",
    "exTranslation": "Train fares have gone up.",
    "category": "Noun"
  },
  {
    "term": "運",
    "termReading": "うん",
    "termTranslation": "fortune, fate",
    "ex": "彼は運のいい男です。",
    "exReading": "かれ は うん の いい おとこ です",
    "exTranslation": "He is a lucky man.",
    "category": "Noun"
  },
  {
    "term": "運送",
    "termReading": "うんそう",
    "termTranslation": "shipping, transportation",
    "ex": "彼は運送会社に勤めているの。",
    "exReading": "かれ は うんそう がいしゃ に つとめて いる の",
    "exTranslation": "He works for a transportation company.",
    "category": "Verbal Noun"
  },
  {
    "term": "回転",
    "termReading": "かいてん",
    "termTranslation": "revolution, rotation",
    "ex": "彼はボールに回転を掛けたんだ。",
    "exReading": "かれ は ぼーる に かいてん を かけた ん だ",
    "exTranslation": "He put a spin on the ball.",
    "category": "Verbal Noun"
  },
  {
    "term": "転がる",
    "termReading": "ころがる",
    "termTranslation": "roll over, tumble",
    "ex": "猫は砂の上で転がったんだ。",
    "exReading": "ねこ は すな の うえ で ころがった ん だ",
    "exTranslation": "The cat rolled over on the sand.",
    "category": "Verb"
  },
  {
    "term": "転がす",
    "termReading": "ころがす",
    "termTranslation": "roll, roll over",
    "ex": "まず、肉をパン粉の上で転がします。",
    "exReading": "まず にく を ぱんこ の うえ で ころがします",
    "exTranslation": "First of all, roll the meat in bread crumbs.",
    "category": "Verb"
  },
  {
    "term": "セールスマン",
    "termReading": "セールスマン",
    "termTranslation": "sales representative, salesperson",
    "ex": "以前、セールスマンをしていたことがあります。",
    "exReading": "いぜん せーるすまん を して いた こと が あります",
    "exTranslation": "I have worked as a salesman previously.",
    "category": "Noun"
  },
  {
    "term": "移転",
    "termReading": "いてん",
    "termTranslation": "transfer, change of address",
    "ex": "彼の会社は移転したよ。",
    "exReading": "かれ の かいしゃ は いてん した よ",
    "exTranslation": "His company relocated.",
    "category": "Verbal Noun"
  },
  {
    "term": "移動",
    "termReading": "いどう",
    "termTranslation": "movement, shift",
    "ex": "私たちはレンタカーで移動しました。",
    "exReading": "わたしたち は れんたかー で いどう しました",
    "exTranslation": "We traveled around by a rental car.",
    "category": "Verbal Noun"
  },
  {
    "term": "行動",
    "termReading": "こうどう",
    "termTranslation": "action, conduct",
    "ex": "彼の行動は理解できない。",
    "exReading": "かれ の こうどう は りかい できない",
    "exTranslation": "I can't understand his conduct.",
    "category": "Verbal Noun"
  },
  {
    "term": "運動会",
    "termReading": "うんどうかい",
    "termTranslation": "sports festival, athletic meet",
    "ex": "運動会で一位になったよ。",
    "exReading": "うんどうかい で いちい に なった よ",
    "exTranslation": "We won the first place in the athletic meet.",
    "category": "Noun"
  },
  {
    "term": "運動場",
    "termReading": "うんどうじょう",
    "termTranslation": "playground, sports field",
    "ex": "運動場でサッカーをしよう。",
    "exReading": "うんどうじょう で さっかー を しよう",
    "exTranslation": "Let's play soccer in the playing field.",
    "category": "Noun"
  },
  {
    "term": "運動場",
    "termReading": "うんどうじょう",
    "termTranslation": "playground, sports field",
    "ex": "運動場でサッカーをしよう。",
    "exReading": "うんどうじょう で さっかー を しよう",
    "exTranslation": "Let's play soccer in the playing field.",
    "category": "Noun"
  },
  {
    "term": "高速",
    "termReading": "こうそく",
    "termTranslation": "high speed",
    "ex": "高速バスで東京に行ったんだ。",
    "exReading": "こうそくばす で とうきょう に いった ん だ",
    "exTranslation": "I went to Tokyo by the express bus.",
    "category": "Noun"
  },
  {
    "term": "おしゃべり",
    "termReading": "おしゃべり",
    "termTranslation": "chatter, idle talk",
    "ex": "彼女たちはおしゃべりに夢中です。",
    "exReading": "かのじょたち は おしゃべり に むちゅう です",
    "exTranslation": "Those women are absorbed in their conversation.",
    "category": "Noun"
  },
  {
    "term": "急速",
    "termReading": "きゅうそく",
    "termTranslation": "rapid, swift",
    "ex": "あの国の経済は急速に発展しているのね。",
    "exReading": "あの くに の けいざい は きゅうそく に はってん して いる の ね",
    "exTranslation": "That country's going through rapid economic development.",
    "category": "Adjective"
  },
  {
    "term": "高速道路",
    "termReading": "こうそくどうろ",
    "termTranslation": "freeway, highway",
    "ex": "高速道路は混んでたよ。",
    "exReading": "こうそく どうろ は こん でた よ 。",
    "exTranslation": "There was a lot of traffic on the expressway.",
    "category": "Noun"
  },
  {
    "term": "早速",
    "termReading": "さっそく",
    "termTranslation": "immediately",
    "ex": "では早速書類をお送りします。",
    "exReading": "では さっそく しょるい を おおくり します",
    "exTranslation": "I will send the documents immediately.",
    "category": "Adverb"
  },
  {
    "term": "時速",
    "termReading": "じそく",
    "termTranslation": "speed per hour",
    "ex": "新幹線の最高時速は300キロです。",
    "exReading": "しんかんせん の さいこう じそく は さんびゃくきろ です",
    "exTranslation": "The highest speed of the shinkansen bullet train is 300 km/h.",
    "category": "Noun"
  },
  {
    "term": "全速力",
    "termReading": "ぜんそくりょく",
    "termTranslation": "full speed, full steam",
    "ex": "久しぶりに全速力で走りました。",
    "exReading": "ひさしぶり に ぜんそくりょく で はしりました",
    "exTranslation": "I ran at full speed for the first time in a long time.",
    "category": "Noun"
  },
  {
    "term": "遅れ",
    "termReading": "おくれ",
    "termTranslation": "delay, being late",
    "ex": "電車が10分遅れで到着したんだ。",
    "exReading": "でんしゃ が じゅっ ぷん おくれ で とうちゃく した ん だ",
    "exTranslation": "The train arrived ten minutes late.",
    "category": "Noun"
  },
  {
    "term": "遅らす",
    "termReading": "おくらす",
    "termTranslation": "delay, put off",
    "ex": "私たちは出発を1日遅らしたの。",
    "exReading": "わたしたち は しゅっぱつ を いちにち おくらした の",
    "exTranslation": "We delayed our departure one day.",
    "category": "Verb"
  },
  {
    "term": "カラオケ",
    "termReading": "カラオケ",
    "termTranslation": "karaoke",
    "ex": "カラオケで思い切り歌ったの。",
    "exReading": "からおけ で おもいきり うたった の",
    "exTranslation": "I sang karaoke to my heart's content.",
    "category": "Noun"
  },
  {
    "term": "終える",
    "termReading": "おえる",
    "termTranslation": "end, finish",
    "ex": "やっと宿題を終えた。",
    "exReading": "やっと しゅくだい を おえた",
    "exTranslation": "At last I have finished my homework.",
    "category": "Verb"
  },
  {
    "term": "最終",
    "termReading": "さいしゅう",
    "termTranslation": "last, final",
    "ex": "東京行きの最終電車は何時ですか。",
    "exReading": "とうきょうゆき の さいしゅう でんしゃ は なんじ です か",
    "exTranslation": "What time is the last train for Tokyo?",
    "category": "Noun"
  },
  {
    "term": "終点",
    "termReading": "しゅうてん",
    "termTranslation": "last stop, terminus",
    "ex": "電車の終点で降りました。",
    "exReading": "でんしゃ の しゅうてん で おりました",
    "exTranslation": "I got off at the terminal.",
    "category": "Noun"
  },
  {
    "term": "始終",
    "termReading": "しじゅう",
    "termTranslation": "from beginning to end, at all times",
    "ex": "その部屋は始終、人が出入りしているね。",
    "exReading": "その へや は しじゅう ひと が でいり して いる ね",
    "exTranslation": "People keep going in and out of the room.",
    "category": "Adverb"
  },
  {
    "term": "終電",
    "termReading": "しゅうでん",
    "termTranslation": "last train",
    "ex": "終電にやっと間に合った。",
    "exReading": "しゅうでん に やっと まにあった",
    "exTranslation": "I was just in time for the last train.",
    "category": "Noun"
  },
  {
    "term": "現地",
    "termReading": "げんち",
    "termTranslation": "actual site, in the field",
    "ex": "現地の天気は雨です。",
    "exReading": "げんち の てんき は あめ です",
    "exTranslation": "The local weather is rain.",
    "category": "Noun"
  },
  {
    "term": "現代",
    "termReading": "げんだい",
    "termTranslation": "present age, modern times",
    "ex": "現代の科学の進歩には驚くよ。",
    "exReading": "げんだい の かがく の しんぽ に は おどろく よ",
    "exTranslation": "The advancement of contemporary science surprises me.",
    "category": "Noun"
  },
  {
    "term": "現金",
    "termReading": "げんきん",
    "termTranslation": "cash",
    "ex": "代金は現金でお願いします。",
    "exReading": "だいきん は げんきん で おねがい します",
    "exTranslation": "Please pay the price in cash.",
    "category": "Adjectival Noun"
  },
  {
    "term": "こっそり",
    "termReading": "こっそり",
    "termTranslation": "stealthily, secretly",
    "ex": "彼女はこっそりダイエットを始めたの。",
    "exReading": "かのじょ は こっそり だいえっと を はじめた の",
    "exTranslation": "She started dieting in secret.",
    "category": "Adverb"
  },
  {
    "term": "現れる",
    "termReading": "あらわれる",
    "termTranslation": "appear, emerge",
    "ex": "雲の間から太陽が現れました。",
    "exReading": "くも の あいだ から たいよう が あらわれました",
    "exTranslation": "The sun appeared between the clouds.",
    "category": "Verb"
  },
  {
    "term": "現場",
    "termReading": "げんば",
    "termTranslation": "actual spot, job site",
    "ex": "事故現場には入れません。",
    "exReading": "じこ げんば に は はいれません",
    "exTranslation": "You can't enter the accident scene.",
    "category": "Noun"
  },
  {
    "term": "現住所",
    "termReading": "げんじゅうしょ",
    "termTranslation": "present address",
    "ex": "ここには現住所を書いてください。",
    "exReading": "ここ に は げんじゅうしょ を かいて ください",
    "exTranslation": "Please write your current address here.",
    "category": "Noun"
  },
  {
    "term": "現す",
    "termReading": "あらわす",
    "termTranslation": "show, reveal",
    "ex": "彼はようやく姿を現しましたね。",
    "exReading": "かれ は ようやく すがた を あらわしました ね",
    "exTranslation": "He finally showed up.",
    "category": "Verb"
  },
  {
    "term": "現れ",
    "termReading": "あらわれ",
    "termTranslation": "embodiment, manifestation",
    "ex": "それは彼女の期待の現れです。",
    "exReading": "それ は かのじょ の きたい の あらわれ です",
    "exTranslation": "It is a manifestation of her expectations.",
    "category": "Noun"
  },
  {
    "term": "在学",
    "termReading": "ざいがく",
    "termTranslation": "attendance at school",
    "ex": "姉は大学に在学しています。",
    "exReading": "あね は だいがく に ざいがく して います",
    "exTranslation": "My elder sister studies at university.",
    "category": "Verbal Noun"
  },
  {
    "term": "現実",
    "termReading": "げんじつ",
    "termTranslation": "actuality, reality",
    "ex": "現実は予想より厳しかった。",
    "exReading": "げんじつ は よそう より きびしかった",
    "exTranslation": "The reality was harsher than we expected.",
    "category": "Noun"
  },
  {
    "term": "ジョギング",
    "termReading": "ジョギング",
    "termTranslation": "jogging",
    "ex": "私は毎日ジョギングをしています。",
    "exReading": "わたし は まいにち じょぎんぐ を して います",
    "exTranslation": "I jog every day.",
    "category": "Verbal Noun"
  },
  {
    "term": "実は",
    "termReading": "じつは",
    "termTranslation": "actually",
    "ex": "あれは実は私の勘違いでした。",
    "exReading": "あれ は じつは わたし の かんちがい でした",
    "exTranslation": "To tell the truth, that was my misunderstanding.",
    "category": "None"
  },
  {
    "term": "実用",
    "termReading": "じつよう",
    "termTranslation": "practical use, utility",
    "ex": "これはとても実用的なサイトだね。",
    "exReading": "これ は とても じつようてき な さいと だ ね",
    "exTranslation": "This is a very practical site.",
    "category": "Noun"
  },
  {
    "term": "実力",
    "termReading": "じつりょく",
    "termTranslation": "real ability, capability",
    "ex": "二人の実力は互角です。",
    "exReading": "ふたり の じつりょく は ごかく です",
    "exTranslation": "Their abilities are evenly matched.",
    "category": "Noun"
  },
  {
    "term": "実習",
    "termReading": "じっしゅう",
    "termTranslation": "practice, training",
    "ex": "今日は料理の実習があった。",
    "exReading": "きょう は りょうり の じっしゅう が あった",
    "exTranslation": "Today we had cooking practice.",
    "category": "Verbal Noun"
  },
  {
    "term": "実物",
    "termReading": "じつぶつ",
    "termTranslation": "real thing, actual object",
    "ex": "何かの説明をする時は実物を使うとわかりやすいの。",
    "exReading": "なにか の せつめい を する とき は じつぶつ を つかう と わかり やすい の",
    "exTranslation": "When you explain something, it's easier to understand if you use the real thing.",
    "category": "Noun"
  },
  {
    "term": "実に",
    "termReading": "じつに",
    "termTranslation": "truly, surely",
    "ex": "昨日のコンサートは実に素晴らしかったよ。",
    "exReading": "さくじつ の こんさーと は じつに すばらしかった よ",
    "exTranslation": "Yesterday's concert was indeed wonderful.",
    "category": "Adverb"
  },
  {
    "term": "過ごす",
    "termReading": "すごす",
    "termTranslation": "pass, let pass",
    "ex": "夏休みを高原で過ごしました。",
    "exReading": "なつやすみ を こうげん で すごしました",
    "exTranslation": "I spent the summer vacation in the highlands.",
    "category": "Verb"
  },
  {
    "term": "あれこれ",
    "termReading": "あれこれ",
    "termTranslation": "this and that, one thing or another",
    "ex": "服を買うのにあれこれ迷いました。",
    "exReading": "ふく を かう の に あれこれ まよいました",
    "exTranslation": "When I was buying clothes, I couldn't decide between different things.",
    "category": "Noun"
  },
  {
    "term": "経過",
    "termReading": "けいか",
    "termTranslation": "progression, lapse",
    "ex": "手術後の経過は順調です。",
    "exReading": "しゅじゅつ ご の けいか は じゅんちょう です",
    "exTranslation": "Progress after the operation is going well.",
    "category": "Verbal Noun"
  },
  {
    "term": "過ち",
    "termReading": "あやまち",
    "termTranslation": "fault, mistake",
    "ex": "同じ過ちを繰り返さないことだ。",
    "exReading": "おなじ あやまち を くりかえさない こと だ",
    "exTranslation": "Do not repeat the same mistake.",
    "category": "Noun"
  },
  {
    "term": "去る",
    "termReading": "さる",
    "termTranslation": "go away, leave",
    "ex": "去る者は追わず。",
    "exReading": "さる もの は おわず",
    "exTranslation": "Do not chase after one who leaves.",
    "category": "Verb"
  },
  {
    "term": "活発",
    "termReading": "かっぱつ",
    "termTranslation": "lively, active",
    "ex": "活発な意見が交されたの。",
    "exReading": "かっぱつ な いけん が かわされた の",
    "exTranslation": "A lively exchange of opinions took place.",
    "category": "Adjectival Noun"
  },
  {
    "term": "始発",
    "termReading": "しはつ",
    "termTranslation": "first departure",
    "ex": "始発の電車に乗りました。",
    "exReading": "しはつ の でんしゃ に のりました",
    "exTranslation": "I took the first train.",
    "category": "Noun"
  },
  {
    "term": "表す",
    "termReading": "あらわす",
    "termTranslation": "express, represent",
    "ex": "私たちは万歳をして喜びを表しました。",
    "exReading": "わたしたち は ばんざい を して よろこび を あらわしました",
    "exTranslation": "We expressed our joy by throwing our arms in the air.",
    "category": "Verb"
  },
  {
    "term": "言い表わす",
    "termReading": "いいあらわす",
    "termTranslation": "express, say",
    "ex": "この気持ちをうまく言い表わせません。",
    "exReading": "この きもち を うまく いいあらわせません",
    "exTranslation": "I can't express this feeling well.",
    "category": "Verb"
  },
  {
    "term": "絵本",
    "termReading": "えほん",
    "termTranslation": "picture book",
    "ex": "その子は絵本が大好きです。",
    "exReading": "その こ は えほん が だいすき です",
    "exTranslation": "That child loves picture books.",
    "category": "Noun"
  },
  {
    "term": "およそ",
    "termReading": "およそ",
    "termTranslation": "just about",
    "ex": "家から駅までおよそ1キロあります。",
    "exReading": "いえ から えき まで およそ いちきろ あります",
    "exTranslation": "It's about one kilometer from home to the station.",
    "category": "Adverb"
  },
  {
    "term": "雑音",
    "termReading": "ざつおん",
    "termTranslation": "noise, interference",
    "ex": "雑音がひどくて声が聞こえません。",
    "exReading": "ざつおん が ひどくて こえ が きこえません",
    "exTranslation": "The background noise is awful and I can't hear your voice.",
    "category": "Noun"
  },
  {
    "term": "足音",
    "termReading": "あしおと",
    "termTranslation": "sound of footsteps",
    "ex": "廊下から大きな足音が聞こえた。",
    "exReading": "ろうか から おおき な あしおと が きこえた",
    "exTranslation": "Loud footsteps were heard from the hallway.",
    "category": "Noun"
  },
  {
    "term": "音読み",
    "termReading": "おんよみ",
    "termTranslation": "Chinese-derived kanji reading",
    "ex": "この漢字の音読みは何ですか。",
    "exReading": "この かんじ の おんよみ は なん です か",
    "exTranslation": "What's the Chinese-derived reading of this kanji?",
    "category": "Verbal Noun"
  },
  {
    "term": "五十音",
    "termReading": "ごじゅうおん",
    "termTranslation": "Japanese syllabary",
    "ex": "名前は五十音順に並んでいます。",
    "exReading": "なまえ は ごじゅうおんじゅん に ならんで います",
    "exTranslation": "The names are arranged in the order of the Japanese syllabary.",
    "category": "Noun"
  },
  {
    "term": "気楽",
    "termReading": "きらく",
    "termTranslation": "easygoing, comfortable",
    "ex": "将来は気楽な生活がしたいです。",
    "exReading": "しょうらい は きらく な せいかつ が したい です",
    "exTranslation": "I want to have an easy life in the future.",
    "category": "Adjectival Noun"
  },
  {
    "term": "行楽",
    "termReading": "こうらく",
    "termTranslation": "excursion, outing",
    "ex": "秋は行楽にいい季節ですね。",
    "exReading": "あき は こうらく に いい きせつ です ね",
    "exTranslation": "Autumn is a good season for excursions.",
    "category": "Noun"
  },
  {
    "term": "薬指",
    "termReading": "くすりゆび",
    "termTranslation": "third finger, ring finger",
    "ex": "彼女は薬指に指輪をはめています。",
    "exReading": "かのじょ は くすりゆび に ゆびわ を はめて います",
    "exTranslation": "She wears a ring on her ring finger.",
    "category": "Noun"
  },
  {
    "term": "サークル",
    "termReading": "サークル",
    "termTranslation": "circle, club",
    "ex": "どのサークルに入るか迷っちゃった。",
    "exReading": "どの さーくる に はいる か まよっちゃった",
    "exTranslation": "I wasn't sure which club to join.",
    "category": "Noun"
  },
  {
    "term": "食欲",
    "termReading": "しょくよく",
    "termTranslation": "appetite",
    "ex": "今日は食欲がありません。",
    "exReading": "きょう は しょくよく が ありません",
    "exTranslation": "I have no appetite today.",
    "category": "Noun"
  },
  {
    "term": "映る",
    "termReading": "うつる",
    "termTranslation": "be reflected, appear",
    "ex": "水面に月が映っているね。",
    "exReading": "みなも に つき が うつって いる ね",
    "exTranslation": "The moon is reflected in the surface of the water.",
    "category": "Verb"
  },
  {
    "term": "映す",
    "termReading": "うつす",
    "termTranslation": "reflect, project",
    "ex": "彼女は自分の姿を鏡に映したの。",
    "exReading": "かのじょ は じぶん の すがた を かがみ に うつした の",
    "exTranslation": "She looked at herself in the mirror.",
    "category": "Verb"
  },
  {
    "term": "企画",
    "termReading": "きかく",
    "termTranslation": "plan, project",
    "ex": "独身者パーティーを企画した。",
    "exReading": "どくしんしゃ ぱーてぃー を きかく した",
    "exTranslation": "I made plans for a singles' party.",
    "category": "Verbal Noun"
  },
  {
    "term": "画家",
    "termReading": "がか",
    "termTranslation": "artist, painter",
    "ex": "この絵はスペインの画家が描きました。",
    "exReading": "この え は すぺいん の がか が かきました",
    "exTranslation": "A Spanish painter drew this picture.",
    "category": "Noun"
  },
  {
    "term": "区画",
    "termReading": "くかく",
    "termTranslation": "division, section",
    "ex": "あそこの3区画は売り出し中です。",
    "exReading": "あそこ の さんくかく は うりだしちゅう です",
    "exTranslation": "Those three sections are on sale.",
    "category": "Noun"
  },
  {
    "term": "画面",
    "termReading": "がめん",
    "termTranslation": "screen",
    "ex": "テレビの画面が明るすぎる。",
    "exReading": "てれび の がめん が あかる すぎる",
    "exTranslation": "The TV screen is too bright.",
    "category": "Noun"
  },
  {
    "term": "キリスト",
    "termReading": "キリスト",
    "termTranslation": "Christ, Christianity",
    "ex": "キリストの母親の名前はマリアだ。",
    "exReading": "きりすと の ははおや の なまえ は まりあ だ",
    "exTranslation": "Christ's mother's name is Maria.",
    "category": "Noun"
  },
  {
    "term": "地面",
    "termReading": "じめん",
    "termTranslation": "surface, ground",
    "ex": "地面に何か絵が描いてあるぞ。",
    "exReading": "じめん に なにか え が かいて ある ぞ",
    "exTranslation": "There is a picture drawn on the ground.",
    "category": "Noun"
  },
  {
    "term": "水面",
    "termReading": "すいめん",
    "termTranslation": "surface of the water",
    "ex": "湖の水面に小さく波が立っているな。",
    "exReading": "みずうみ の すいめん に ちいさく なみ が たって いる な",
    "exTranslation": "There are a lot of small waves on the surface of the lake.",
    "category": "Noun"
  },
  {
    "term": "一面",
    "termReading": "いちめん",
    "termTranslation": "whole surface",
    "ex": "外は一面の雪景色だったね。",
    "exReading": "そと は いちめん の ゆきげしき だった ね",
    "exTranslation": "It was a snowy landscape outside.",
    "category": "Noun"
  },
  {
    "term": "真実",
    "termReading": "しんじつ",
    "termTranslation": "truth, reality",
    "ex": "真実は一つです。",
    "exReading": "しんじつ は ひとつ です",
    "exTranslation": "There is one truth.",
    "category": "Noun"
  },
  {
    "term": "真理",
    "termReading": "しんり",
    "termTranslation": "truth",
    "ex": "彼は人生の真理を求めて旅に出た。",
    "exReading": "かれ は じんせい の しんり を もとめて たび に でた",
    "exTranslation": "He went on a journey in search of the true meaning of life.",
    "category": "Noun"
  },
  {
    "term": "金色",
    "termReading": "きんいろ",
    "termTranslation": "golden color",
    "ex": "あの寺の屋根は金色ですね。",
    "exReading": "あの てら の やね は きんいろ です ね",
    "exTranslation": "The roof of that temple is golden.",
    "category": "Noun"
  },
  {
    "term": "形式",
    "termReading": "けいしき",
    "termTranslation": "form, model",
    "ex": "書類は形式を守って作ってください。",
    "exReading": "しょるい は けいしき を まもって つくって ください",
    "exTranslation": "Please maintain the format of the documents.",
    "category": "Noun"
  },
  {
    "term": "小型",
    "termReading": "こがた",
    "termTranslation": "small-sized",
    "ex": "小型のスーツケースを買いました。",
    "exReading": "こがた の すーつけーす を かいました",
    "exTranslation": "I bought a small suitcase.",
    "category": "Noun"
  },
  {
    "term": "あっさり",
    "termReading": "あっさり",
    "termTranslation": "plainly, matter-of-factly",
    "ex": "そうあっさり言わないで。",
    "exReading": "そう あっさり いわない で",
    "exTranslation": "Don't say it so matter-of-factly.",
    "category": "Adverb"
  },
  {
    "term": "型",
    "termReading": "かた",
    "termTranslation": "type, mold",
    "ex": "新しい型のカメラを買いました。",
    "exReading": "あたらしい かた の かめら を かいました",
    "exTranslation": "I bought a new-model camera.",
    "category": "Noun"
  },
  {
    "term": "一種",
    "termReading": "いっしゅ",
    "termTranslation": "a kind of, a sort of",
    "ex": "それは果物の一種です。",
    "exReading": "それ は くだもの の いっしゅ です",
    "exTranslation": "It is a kind of fruit.",
    "category": "Noun"
  },
  {
    "term": "各種",
    "termReading": "かくしゅ",
    "termTranslation": "each kind, various",
    "ex": "図書館には各種の雑誌が揃っています。",
    "exReading": "としょかん に は かくしゅ の ざっし が そろって います",
    "exTranslation": "There are various magazines in the library.",
    "category": "Noun"
  },
  {
    "term": "一種",
    "termReading": "いっしゅ",
    "termTranslation": "a kind of, of sorts",
    "ex": "これは一種独特の味がするね。",
    "exReading": "これ は いっしゅ どくとく の あじ が する ね",
    "exTranslation": "This has an unusual sort of taste.",
    "category": "Adverb"
  },
  {
    "term": "人種",
    "termReading": "じんしゅ",
    "termTranslation": "race",
    "ex": "この国には様々な人種がいます。",
    "exReading": "この くに に は さまざま な じんしゅ が います",
    "exTranslation": "There are many ethnic groups in this country.",
    "category": "Noun"
  },
  {
    "term": "種目",
    "termReading": "しゅもく",
    "termTranslation": "item, event",
    "ex": "その選手は2種目でメダルを取ったわ。",
    "exReading": "その せんしゅ は にしゅもく で めだる を とった わ",
    "exTranslation": "The athlete won medals in two events.",
    "category": "Noun"
  },
  {
    "term": "人類",
    "termReading": "じんるい",
    "termTranslation": "mankind, humankind",
    "ex": "人類は2本の足で歩きます。",
    "exReading": "じんるい は にほん の あし で あるきます",
    "exTranslation": "Humans walk on two feet.",
    "category": "Noun"
  },
  {
    "term": "せっせと",
    "termReading": "せっせと",
    "termTranslation": "hard, diligently",
    "ex": "彼は朝から晩までせっせと働いたの。",
    "exReading": "かれ は あさ から ばん まで せっせと はたらいた の",
    "exTranslation": "He worked hard from morning till night.",
    "category": "Adverb"
  },
  {
    "term": "書類",
    "termReading": "しょるい",
    "termTranslation": "documents, papers",
    "ex": "書類を10枚コピーしました。",
    "exReading": "しょるい を じゅうまい こぴー しました",
    "exTranslation": "I made ten copies of the document.",
    "category": "Noun"
  },
  {
    "term": "接近",
    "termReading": "せっきん",
    "termTranslation": "approach, proximity",
    "ex": "台風が接近していますね。",
    "exReading": "たいふう が せっきん して います ね",
    "exTranslation": "A typhoon is approaching.",
    "category": "Verbal Noun"
  },
  {
    "term": "間接",
    "termReading": "かんせつ",
    "termTranslation": "indirect",
    "ex": "それは間接的な原因の一つです。",
    "exReading": "それ は かんせつてき な げんいん の ひとつ です",
    "exTranslation": "It's one of the indirect causes.",
    "category": "Noun"
  },
  {
    "term": "接する",
    "termReading": "せっする",
    "termTranslation": "come in contact, adjoin",
    "ex": "子供が動物に接するのは良いことです。",
    "exReading": "こども が どうぶつ に せっする の は よい こと です",
    "exTranslation": "It is good for children to have contact with animals.",
    "category": "Verb"
  },
  {
    "term": "角度",
    "termReading": "かくど",
    "termTranslation": "angle",
    "ex": "この角度からは画面が見えにくいですね。",
    "exReading": "この かくど からは がめん が みえ にくい です ね",
    "exTranslation": "It's difficult to see the screen from this angle.",
    "category": "Noun"
  },
  {
    "term": "三角形",
    "termReading": "さんかくけい",
    "termTranslation": "triangle",
    "ex": "この三角形の面積を出しなさい。",
    "exReading": "この さんかくけい の めんせき を だしなさい",
    "exTranslation": "Figure out the dimensions of this triangle.",
    "category": "Noun"
  },
  {
    "term": "曲線",
    "termReading": "きょくせん",
    "termTranslation": "curve, curved line",
    "ex": "この道は、ゆるい曲線を描いているよ。",
    "exReading": "この みち は ゆるい きょくせん を えがいて いる よ",
    "exTranslation": "This road curves gently.",
    "category": "Noun"
  },
  {
    "term": "カタログ",
    "termReading": "カタログ",
    "termTranslation": "catalogue",
    "ex": "カタログを見て注文したんだ。",
    "exReading": "かたろぐ を みて ちゅうもん した ん だ",
    "exTranslation": "I looked at the catalog and placed an order.",
    "category": "Noun"
  },
  {
    "term": "作曲",
    "termReading": "さっきょく",
    "termTranslation": "composition",
    "ex": "この曲は誰が作曲したのですか。",
    "exReading": "この きょく は だれ が さっきょく した の です か",
    "exTranslation": "Who composed this tune?",
    "category": "Verbal Noun"
  },
  {
    "term": "共通",
    "termReading": "きょうつう",
    "termTranslation": "commonality",
    "ex": "私たちは共通の趣味を持っています。",
    "exReading": "わたしたち は きょうつう の しゅみ を もって います",
    "exTranslation": "We have a common hobby.",
    "category": "Verbal Noun"
  },
  {
    "term": "共通語",
    "termReading": "きょうつうご",
    "termTranslation": "common language, lingua franca",
    "ex": "インドでは英語は共通語だよ。",
    "exReading": "いんど で は えいご は きょうつうご だ よ",
    "exTranslation": "English is a common language in India.",
    "category": "Noun"
  },
  {
    "term": "共同",
    "termReading": "きょうどう",
    "termTranslation": "joint, commonality",
    "ex": "2社が共同で新製品を開発したんだ。",
    "exReading": "にしゃ が きょうどう で しんせいひん を かいはつ した ん だ",
    "exTranslation": "The two companies jointly developed a new product.",
    "category": "Verbal Noun"
  },
  {
    "term": "合同",
    "termReading": "ごうどう",
    "termTranslation": "combination, joint",
    "ex": "3社合同で新作の発表会を開きました。",
    "exReading": "さんしゃ ごうどう で しんさく の はっぴょうかい を ひらきました",
    "exTranslation": "Three companies had a joint presentation for their new products.",
    "category": "Verbal Noun"
  },
  {
    "term": "一同",
    "termReading": "いちどう",
    "termTranslation": "everybody, all",
    "ex": "一同顔を見合わせました。",
    "exReading": "いちどう かお を みあわせました",
    "exTranslation": "We all looked at each other.",
    "category": "Noun"
  },
  {
    "term": "以前",
    "termReading": "いぜん",
    "termTranslation": "before, ago",
    "ex": "以前彼はこの町に住んでいたんだ。",
    "exReading": "いぜん かれ は この まち に すんで いた ん だ",
    "exTranslation": "He used to live in this town.",
    "category": "Noun"
  },
  {
    "term": "ジャズ",
    "termReading": "ジャズ",
    "termTranslation": "jazz",
    "ex": "昨日の夜、ジャズを聴きに行きました。",
    "exReading": "きのう の よる じゃず を きき に いきました",
    "exTranslation": "I went to listen to jazz last night.",
    "category": "Noun"
  },
  {
    "term": "以下",
    "termReading": "いか",
    "termTranslation": "below",
    "ex": "数学が平均点以下だった。",
    "exReading": "すうがく が へいきんてん いか だった",
    "exTranslation": "The math score was below average.",
    "category": "Noun"
  },
  {
    "term": "以降",
    "termReading": "いこう",
    "termTranslation": "hereafter",
    "ex": "夕方以降にお電話を下さい。",
    "exReading": "ゆうがた いこう に お でんわ を ください",
    "exTranslation": "Please call me in the evening.",
    "category": "Noun"
  },
  {
    "term": "以来",
    "termReading": "いらい",
    "termTranslation": "since",
    "ex": "それ以来彼女に会ってない。",
    "exReading": "それ いらい かのじょ に あってない",
    "exTranslation": "I haven't met her since then.",
    "category": "Noun"
  },
  {
    "term": "以後",
    "termReading": "いご",
    "termTranslation": "from now on, after that",
    "ex": "すみません、以後気を付けます。",
    "exReading": "すみません いご き を つけます",
    "exTranslation": "Sorry, I'll be more careful from now on.",
    "category": "Noun"
  },
  {
    "term": "下宿",
    "termReading": "げしゅく",
    "termTranslation": "lodging, boarding house",
    "ex": "親類の家に4年間下宿しました。",
    "exReading": "しんるい の いえ に よねんかん げしゅく しました",
    "exTranslation": "I boarded at a relative's house for four years.",
    "category": "Verbal Noun"
  },
  {
    "term": "漢和",
    "termReading": "かんわ",
    "termTranslation": "Chinese and Japanese",
    "ex": "漢和辞典で漢字の意味を調べたの。",
    "exReading": "かんわ じてん で かんじ の いみ を しらべた の",
    "exTranslation": "I looked up the meaning of a kanji in a Sino-Japanese dictionary.",
    "category": "Noun"
  },
  {
    "term": "英和",
    "termReading": "えいわ",
    "termTranslation": "English-Japanese",
    "ex": "英和辞書をよく使います。",
    "exReading": "えいわ じしょ を よく つかいます",
    "exTranslation": "I often use an English-Japanese dictionary.",
    "category": "Noun"
  },
  {
    "term": "西洋",
    "termReading": "せいよう",
    "termTranslation": "the West, the Occident",
    "ex": "私は西洋の建築に興味があります。",
    "exReading": "わたし は せいよう の けんちく に きょうみ が あります",
    "exTranslation": "I am interested in Western architecture.",
    "category": "Noun"
  },
  {
    "term": "サイン",
    "termReading": "サイン",
    "termTranslation": "signature, autograph",
    "ex": "ここにサインしてください。",
    "exReading": "ここ に さいん して ください",
    "exTranslation": "Please sign here.",
    "category": "Verbal Noun"
  },
  {
    "term": "海洋",
    "termReading": "かいよう",
    "termTranslation": "ocean, sea",
    "ex": "その昔航海士達は未踏の地を求め海洋に乗り出しました。",
    "exReading": "その むかし こうかいしたち は みとう の ち を もとめ かいよう に のりだしました",
    "exTranslation": "In the past, sailors ventured into the ocean in search of unexplored lands.",
    "category": "Noun"
  },
  {
    "term": "西洋人",
    "termReading": "せいようじん",
    "termTranslation": "Westerner",
    "ex": "西洋人は正座が苦手よ。",
    "exReading": "せいようじん は せいざ が にがて よ",
    "exTranslation": "Westerners are not good at sitting with their legs folded underneath them.",
    "category": "Noun"
  },
  {
    "term": "制服",
    "termReading": "せいふく",
    "termTranslation": "uniform",
    "ex": "私の学校には制服がありません。",
    "exReading": "わたし の がっこう に は せいふく が ありません",
    "exTranslation": "There is no uniform in my school.",
    "category": "Noun"
  },
  {
    "term": "室内",
    "termReading": "しつない",
    "termTranslation": "indoors",
    "ex": "雨の日は子供を室内で遊ばせます。",
    "exReading": "あめ の ひ は こども を しつない で あそばせます",
    "exTranslation": "I have the kids play indoors on rainy days.",
    "category": "Noun"
  },
  {
    "term": "親子",
    "termReading": "おやこ",
    "termTranslation": "parent and child",
    "ex": "池に鴨の親子がいます。",
    "exReading": "いけ に かも の おやこ が います",
    "exTranslation": "A mother duck and her ducklings are in the pond.",
    "category": "Noun"
  },
  {
    "term": "親類",
    "termReading": "しんるい",
    "termTranslation": "relatives, kin",
    "ex": "彼は幼い時に親類に預けられたんだ。",
    "exReading": "かれ は おさない とき に しんるい に あずけられた ん だ",
    "exTranslation": "He was put under his relatives' care when he was small.",
    "category": "Noun"
  },
  {
    "term": "親しむ",
    "termReading": "したしむ",
    "termTranslation": "get close to, get familiar with",
    "ex": "ハイキングは自然に親しむ良い機会ですよ。",
    "exReading": "はいきんぐ は しぜん に したしむ いい きかい です よ",
    "exTranslation": "Hiking is a good chance to get close to nature.",
    "category": "Verb"
  },
  {
    "term": "ごまかす",
    "termReading": "ごまかす",
    "termTranslation": "deceive, evade",
    "ex": "彼は質問の答えをごまかしたね。",
    "exReading": "かれ は しつもん の こたえ を ごまかした ね",
    "exTranslation": "He cheated on the answer to the question.",
    "category": "Verb"
  },
  {
    "term": "親切",
    "termReading": "しんせつ",
    "termTranslation": "kindness",
    "ex": "ご親切は決して忘れません。",
    "exReading": "ごしんせつ は けっして わすれません",
    "exTranslation": "I will never forget your kindness.",
    "category": "Noun"
  },
  {
    "term": "親友",
    "termReading": "しんゆう",
    "termTranslation": "close friend",
    "ex": "彼は私の親友です。",
    "exReading": "かれ は わたし の しんゆう です",
    "exTranslation": "He is a close friend.",
    "category": "Noun"
  },
  {
    "term": "親指",
    "termReading": "おやゆび",
    "termTranslation": "thumb",
    "ex": "親指を怪我しました。",
    "exReading": "おやゆび を けがしました",
    "exTranslation": "I injured my thumb.",
    "category": "Noun"
  },
  {
    "term": "親しみ",
    "termReading": "したしみ",
    "termTranslation": "intimacy, familiarity",
    "ex": "彼には誰もが親しみを感じます。",
    "exReading": "かれ に は だれ も が したしみ を かんじます",
    "exTranslation": "Everyone feels close to him. .",
    "category": "Noun"
  },
  {
    "term": "水族館",
    "termReading": "すいぞくかん",
    "termTranslation": "aquarium",
    "ex": "ここの水族館にはイルカがいます。",
    "exReading": "ここ の すいぞくかん に は いるか が います",
    "exTranslation": "There is a dolphin in the aquarium here.",
    "category": "Noun"
  },
  {
    "term": "歳末",
    "termReading": "さいまつ",
    "termTranslation": "year end",
    "ex": "デパートの歳末大売出しが始まったよ。",
    "exReading": "でぱーと の さいまつ おおうりだし が はじまった よ",
    "exTranslation": "Year-end sales have begun at the department stores.",
    "category": "Noun"
  },
  {
    "term": "姉妹",
    "termReading": "しまい",
    "termTranslation": "sisters",
    "ex": "うちは3人姉妹です。",
    "exReading": "うち は さんにん しまい です",
    "exTranslation": "There are three girls in my family, myself and two sisters.",
    "category": "Noun"
  },
  {
    "term": "インテリ",
    "termReading": "インテリ",
    "termTranslation": "intelligent",
    "ex": "彼女はインテリで、しかも美人ですね。",
    "exReading": "かのじょ は いんてり で しかも びじん です ね",
    "exTranslation": "She is not only intelligent but beautiful too.",
    "category": "Noun"
  },
  {
    "term": "次第に",
    "termReading": "しだいに",
    "termTranslation": "gradually, by degrees",
    "ex": "その事件は次第に忘れられていったのさ。",
    "exReading": "その じけん は しだいに わすれられて いった の さ",
    "exTranslation": "The incident was gradually forgotten.",
    "category": "Adverb"
  },
  {
    "term": "次第",
    "termReading": "しだい",
    "termTranslation": "as soon as",
    "ex": "連絡があり次第出発します。",
    "exReading": "れんらく が あり しだい しゅっぱつ します",
    "exTranslation": "I will depart once I am told.",
    "category": "Noun"
  },
  {
    "term": "息",
    "termReading": "いき",
    "termTranslation": "breath",
    "ex": "大きく息を吸ってください。",
    "exReading": "おおきく いき を すって ください",
    "exTranslation": "Please take a deep breath.",
    "category": "Noun"
  },
  {
    "term": "休息",
    "termReading": "きゅうそく",
    "termTranslation": "rest, break",
    "ex": "休息をとることは大切です。",
    "exReading": "きゅうそく を とる こと は たいせつ です",
    "exTranslation": "It's important to take breaks.",
    "category": "Verbal Noun"
  },
  {
    "term": "消費者",
    "termReading": "しょうひしゃ",
    "termTranslation": "consumer",
    "ex": "この法律は消費者を守るためのものです。",
    "exReading": "この ほうりつ は しょうひしゃ を まもる ため の もの です",
    "exTranslation": "This law is used to defend the consumer.",
    "category": "Noun"
  },
  {
    "term": "学者",
    "termReading": "がくしゃ",
    "termTranslation": "scholar",
    "ex": "彼は作家であり学者です。",
    "exReading": "かれ は さっか で あり がくしゃ です",
    "exTranslation": "He is a writer and a scholar.",
    "category": "Noun"
  },
  {
    "term": "後者",
    "termReading": "こうしゃ",
    "termTranslation": "the latter",
    "ex": "正しい答は後者です。",
    "exReading": "ただしい こたえ は こうしゃ です",
    "exTranslation": "The right answer is the latter.",
    "category": "Noun"
  },
  {
    "term": "作者",
    "termReading": "さくしゃ",
    "termTranslation": "author, writer",
    "ex": "この本の作者は誰ですか。",
    "exReading": "この ほん の さくしゃ は だれ です か",
    "exTranslation": "Who is the author of this book?",
    "category": "Noun"
  },
  {
    "term": "いちいち",
    "termReading": "いちいち",
    "termTranslation": "one by one, in detail",
    "ex": "彼はいちいち私に指図する。",
    "exReading": "かれ は いちいち わたし に さしず する",
    "exTranslation": "He gives me instructions on everything.",
    "category": "Adverb"
  },
  {
    "term": "前者",
    "termReading": "ぜんしゃ",
    "termTranslation": "the former",
    "ex": "私は前者の方が優れていると思います。",
    "exReading": "わたし は ぜんしゃ の ほう が すぐれて いる と おもいます",
    "exTranslation": "I think that the former is excellent.",
    "category": "Noun"
  },
  {
    "term": "結局",
    "termReading": "けっきょく",
    "termTranslation": "after all, finally",
    "ex": "彼は結局何を言いたかったのだろう。",
    "exReading": "かれ は けっきょく なに を いいたかった の だろう",
    "exTranslation": "I wonder what he wanted to say after all.",
    "category": "Adverb"
  },
  {
    "term": "新婚",
    "termReading": "しんこん",
    "termTranslation": "newlywed",
    "ex": "妹夫婦は新婚です。",
    "exReading": "いもうとふうふ は しんこん です",
    "exTranslation": "My younger sister and her husband are the newlyweds.",
    "category": "Noun"
  },
  {
    "term": "婚約",
    "termReading": "こんやく",
    "termTranslation": "engagement, betrothal",
    "ex": "二人は婚約しています。",
    "exReading": "ふたり は こんやく して います",
    "exTranslation": "They are engaged.",
    "category": "Verbal Noun"
  },
  {
    "term": "課長",
    "termReading": "かちょう",
    "termTranslation": "section chief",
    "ex": "彼は課長に昇進しました。",
    "exReading": "かれ は かちょう に しょうしん しました",
    "exTranslation": "He was promoted to section chief.",
    "category": "Noun"
  },
  {
    "term": "効く",
    "termReading": "きく",
    "termTranslation": "be effective",
    "ex": "この薬はあまりよく効かないよ。",
    "exReading": "この くすり は あまり よく きかない よ",
    "exTranslation": "This medicine doesn't work very well.",
    "category": "Verb"
  },
  {
    "term": "効力",
    "termReading": "こうりょく",
    "termTranslation": "effect, validity",
    "ex": "その薬は効力が強いよ。",
    "exReading": "その くすり は こうりょく が つよい よ",
    "exTranslation": "The effect of the medicine is strong.",
    "category": "Noun"
  },
  {
    "term": "ストライキ",
    "termReading": "ストライキ",
    "termTranslation": "strike",
    "ex": "従業員は明日からストライキです。",
    "exReading": "じゅうぎょういん は あす から すとらいき です",
    "exTranslation": "Employees will go on strike from tomorrow.",
    "category": "Noun"
  },
  {
    "term": "効き目",
    "termReading": "ききめ",
    "termTranslation": "effect, efficacy",
    "ex": "この薬の効き目は素晴らしいの。",
    "exReading": "この くすり の ききめ は すばらしい の",
    "exTranslation": "This medicine is so effective.",
    "category": "Noun"
  },
  {
    "term": "自動",
    "termReading": "じどう",
    "termTranslation": "automatic operation",
    "ex": "このドアは自動よ。",
    "exReading": "この どあ は じどう よ",
    "exTranslation": "This door is automatic.",
    "category": "Noun"
  },
  {
    "term": "自国",
    "termReading": "じこく",
    "termTranslation": "one's own country",
    "ex": "自国の文化を大切にしましょう。",
    "exReading": "じこく の ぶんか を たいせつ に しましょう",
    "exTranslation": "Let's take good care of our own country's culture.",
    "category": "Noun"
  },
  {
    "term": "各自",
    "termReading": "かくじ",
    "termTranslation": "each one, every individual",
    "ex": "ごみは各自で持ち帰ってください。",
    "exReading": "ごみ は かくじ で もちかえって ください",
    "exTranslation": "Please take your own garbage home.",
    "category": "Noun"
  },
  {
    "term": "自然に",
    "termReading": "しぜんに",
    "termTranslation": "naturally, instinctively",
    "ex": "硬くならないで、自然に話して下さい。",
    "exReading": "かたく ならない で しぜんに はなして ください",
    "exTranslation": "Please speak naturally without being formal.",
    "category": "Adverb"
  },
  {
    "term": "経由",
    "termReading": "けいゆ",
    "termTranslation": "via, through",
    "ex": "メールを経由して広がるウィルスもあります。",
    "exReading": "めーる を けいゆ して ひろがる うぃるす も あります",
    "exTranslation": "There are even viruses that spread via e-mail.",
    "category": "Verbal Noun"
  },
  {
    "term": "自信",
    "termReading": "じしん",
    "termTranslation": "self-confidence",
    "ex": "あの人の顔には自信があふれているね。",
    "exReading": "あの ひと の かお に は じしん が あふれて いる ね",
    "exTranslation": "That person's face is brimming with confidence.",
    "category": "Noun"
  },
  {
    "term": "あんまり",
    "termReading": "あんまり",
    "termTranslation": "(not) that much, (not) so much",
    "ex": "これはあんまり好きじゃないな。",
    "exReading": "これ は あんまり すき じゃ ない な",
    "exTranslation": "I don't like this so much.",
    "category": "Adverb"
  },
  {
    "term": "信用",
    "termReading": "しんよう",
    "termTranslation": "trust, credit",
    "ex": "彼の言うことは信用できない。",
    "exReading": "かれ の いう こと は しんよう できない",
    "exTranslation": "I can't trust what he says.",
    "category": "Verbal Noun"
  },
  {
    "term": "信頼",
    "termReading": "しんらい",
    "termTranslation": "reliance, confidence",
    "ex": "私は部下を信頼しています。",
    "exReading": "わたし は ぶか を しんらい して います",
    "exTranslation": "I trust my subordinates.",
    "category": "Verbal Noun"
  },
  {
    "term": "依頼",
    "termReading": "いらい",
    "termTranslation": "request, commission",
    "ex": "彼に協力を依頼しました。",
    "exReading": "かれ に きょうりょく を いらい しました",
    "exTranslation": "I requested his cooperation.",
    "category": "Verbal Noun"
  },
  {
    "term": "人民",
    "termReading": "じんみん",
    "termTranslation": "the people, populace",
    "ex": "彼は人民のための政治を行った。",
    "exReading": "かれ は じんみん の ため の せいじ を おこなった",
    "exTranslation": "He engaged in politics for the people's benefit.",
    "category": "Noun"
  },
  {
    "term": "国民",
    "termReading": "こくみん",
    "termTranslation": "the people, nation",
    "ex": "国民の安全が最も大切です。",
    "exReading": "こくみん の あんぜん が もっとも たいせつ です",
    "exTranslation": "Citizen safety is most important.",
    "category": "Noun"
  },
  {
    "term": "主要",
    "termReading": "しゅよう",
    "termTranslation": "main, principal",
    "ex": "IT産業はインドの主要産業よね。",
    "exReading": "あいてぃー さんぎょう は いんど の しゅよう さんぎょう よ ね",
    "exTranslation": "IT is a major industry in India.",
    "category": "Adjectival Noun"
  },
  {
    "term": "主に",
    "termReading": "おもに",
    "termTranslation": "mainly, mostly",
    "ex": "この商品は主に女性に人気がありまして。",
    "exReading": "この しょうひん は おもに じょせい に にんき が ありまし て",
    "exTranslation": "This product is mainly popular among women.",
    "category": "Adverb"
  },
  {
    "term": "主役",
    "termReading": "しゅやく",
    "termTranslation": "leading part, starring role",
    "ex": "彼は学校の劇で主役に選ばれたよ。",
    "exReading": "かれ は がっこう の げき で しゅやく に えらばれた よ",
    "exTranslation": "He was chosen for a leading part in the school play.",
    "category": "Noun"
  },
  {
    "term": "うるさい",
    "termReading": "うるさい",
    "termTranslation": "noisy, bothersome",
    "ex": "あまりうるさく言わないで下さい。",
    "exReading": "あまり うるさく いわない で ください",
    "exTranslation": "Don't nag me so much.",
    "category": "Adjective"
  },
  {
    "term": "主体",
    "termReading": "しゅたい",
    "termTranslation": "agent, main constituent",
    "ex": "政治は国民主体であるべきです。",
    "exReading": "せいじ は こくみん しゅたい で ある べき です",
    "exTranslation": "The government should be the agent of the people.",
    "category": "Noun"
  },
  {
    "term": "主題",
    "termReading": "しゅだい",
    "termTranslation": "subject, theme",
    "ex": "この小説の主題は反戦です。",
    "exReading": "この しょうせつ の しゅだい は はんせん です",
    "exTranslation": "The main theme of this novel is opposition to war.",
    "category": "Noun"
  },
  {
    "term": "自主",
    "termReading": "じしゅ",
    "termTranslation": "independence, autonomy",
    "ex": "その選手は自主トレーニングを始めたの。",
    "exReading": "その せんしゅ は じしゅとれーにんぐ を はじめた の",
    "exTranslation": "Those players started training independently.",
    "category": "Noun"
  },
  {
    "term": "主食",
    "termReading": "しゅしょく",
    "termTranslation": "staple food",
    "ex": "アジアの主食は米です。",
    "exReading": "あじあ の しゅしょく は こめ です",
    "exTranslation": "The staple food of Asia is rice.",
    "category": "Noun"
  },
  {
    "term": "主",
    "termReading": "おも",
    "termTranslation": "main, chief",
    "ex": "彼女の主な仕事は接客だよ。",
    "exReading": "かのじょ の おも な しごと は せっきゃく だ よ",
    "exTranslation": "Her main job is customer service.",
    "category": "Adjective"
  },
  {
    "term": "主義",
    "termReading": "しゅぎ",
    "termTranslation": "principle, -ism",
    "ex": "私は車は持たない主義です。",
    "exReading": "わたし は くるま は もたない しゅぎ です",
    "exTranslation": "I do not have a car on principle.",
    "category": "Noun"
  },
  {
    "term": "共産主義",
    "termReading": "きょうさんしゅぎ",
    "termTranslation": "Communism",
    "ex": "その国は共産主義の国だよ。",
    "exReading": "その くに は きょうさんしゅぎ の くに だ よ",
    "exTranslation": "That country is a Communist nation.",
    "category": "Noun"
  },
  {
    "term": "カーブ",
    "termReading": "カーブ",
    "termTranslation": "curve, bend",
    "ex": "このカーブは気をつけて。",
    "exReading": "この かーぶ は き を つけて",
    "exTranslation": "Be careful on this curve.",
    "category": "Verbal Noun"
  },
  {
    "term": "義理",
    "termReading": "ぎり",
    "termTranslation": "sense of duty, justice",
    "ex": "彼は義理を大切にしています。",
    "exReading": "かれ は ぎり を たいせつ に して います",
    "exTranslation": "He values duty.",
    "category": "Noun"
  },
  {
    "term": "議会",
    "termReading": "ぎかい",
    "termTranslation": "assembly, council",
    "ex": "議会で区の来年の予定が話し合われたんだ。",
    "exReading": "ぎかい で く の らいねん の よてい が はなしあわれた ん だ 。",
    "exTranslation": "The council discussed the district's plan for the upcoming year.",
    "category": "Noun"
  },
  {
    "term": "協議",
    "termReading": "きょうぎ",
    "termTranslation": "conference, deliberation",
    "ex": "委員会はその問題について協議しましたわ。",
    "exReading": "いいんかい は その もんだい に ついて きょうぎ しました わ",
    "exTranslation": "The committee discussed the issue.",
    "category": "Verbal Noun"
  },
  {
    "term": "議長",
    "termReading": "ぎちょう",
    "termTranslation": "chairman, president",
    "ex": "議長が開会の挨拶をしました。",
    "exReading": "ぎちょう が かいかい の あいさつ を しました",
    "exTranslation": "The chairman made the opening greetings for the meeting.",
    "category": "Noun"
  },
  {
    "term": "議題",
    "termReading": "ぎだい",
    "termTranslation": "topic for discussion, agenda",
    "ex": "今日の議題は面白そうです。",
    "exReading": "きょう の ぎだい は おもしろ そう です",
    "exTranslation": "Today's agenda looks interesting.",
    "category": "Noun"
  },
  {
    "term": "議論",
    "termReading": "ぎろん",
    "termTranslation": "argument, discussion",
    "ex": "父は議論好きです。",
    "exReading": "ちち は ぎろんずき です",
    "exTranslation": "My father likes engaging in debates.",
    "category": "Verbal Noun"
  },
  {
    "term": "結論",
    "termReading": "けつろん",
    "termTranslation": "conclusion",
    "ex": "今日の話し合いでは結論が出なかった。",
    "exReading": "きょう の はなしあい で は けつろん が でなかった",
    "exTranslation": "We did not reach a conclusion in our discussion today.",
    "category": "Verbal Noun"
  },
  {
    "term": "ガイド",
    "termReading": "ガイド",
    "termTranslation": "guide",
    "ex": "ガイドつきの旅行を申し込んだの。",
    "exReading": "がいど つき の りょこう を もうしこんだ の",
    "exTranslation": "I signed up for a conducted tour.",
    "category": "Verbal Noun"
  },
  {
    "term": "言論",
    "termReading": "げんろん",
    "termTranslation": "speech, discussion",
    "ex": "言論の自由は国民の権利です。",
    "exReading": "げんろん の じゆう は こくみん の けんり です",
    "exTranslation": "Freedom of speech is the right of the people.",
    "category": "Noun"
  },
  {
    "term": "世論",
    "termReading": "せろん",
    "termTranslation": "public opinion",
    "ex": "世論はあなたの味方です。",
    "exReading": "せろん は あなた の みかた です",
    "exTranslation": "Public opinion is your ally.",
    "category": "Noun"
  },
  {
    "term": "違反",
    "termReading": "いはん",
    "termTranslation": "violation, breach",
    "ex": "彼は校則に違反しました。",
    "exReading": "かれ は こうそく に いはん しました",
    "exTranslation": "He violated school regulations.",
    "category": "Verbal Noun"
  },
  {
    "term": "応じる",
    "termReading": "おうじる",
    "termTranslation": "answer, comply with",
    "ex": "私は彼の要望に応じました。",
    "exReading": "わたし は かれ の ようぼう に おうじました",
    "exTranslation": "I responded to his demand.",
    "category": "Verb"
  },
  {
    "term": "応用",
    "termReading": "おうよう",
    "termTranslation": "application",
    "ex": "このレシピはいろいろ応用できます。",
    "exReading": "この れしぴ は いろいろ おうよう できます",
    "exTranslation": "This recipe can be used in many ways.",
    "category": "Verbal Noun"
  },
  {
    "term": "一応",
    "termReading": "いちおう",
    "termTranslation": "tentatively",
    "ex": "彼にも一応知らせておいたほうがいいだろう。",
    "exReading": "かれ に も いちおう しらせて おいた ほう が いいだろう",
    "exTranslation": "It'd be better to just let him know.",
    "category": "Adverb"
  },
  {
    "term": "回答",
    "termReading": "かいとう",
    "termTranslation": "reply, answer",
    "ex": "アンケートに回答しました。",
    "exReading": "あんけーと に かいとう しました",
    "exTranslation": "I answered the questionnaire.",
    "category": "Verbal Noun"
  },
  {
    "term": "かえる",
    "termReading": "かえる",
    "termTranslation": "frog",
    "ex": "田んぼの中でかえるが合唱している。",
    "exReading": "たんぼ の なか で かえる が がっしょう している 。",
    "exTranslation": "Frogs in the rice field are croaking in chorus.",
    "category": "Noun"
  },
  {
    "term": "解答",
    "termReading": "かいとう",
    "termTranslation": "solution, answer",
    "ex": "インターネットで試験の解答を確認したんだ。",
    "exReading": "いんたーねっと で しけん の かいとう を かくにん した ん だ",
    "exTranslation": "I checked the answers to the exam on the Internet.",
    "category": "Verbal Noun"
  },
  {
    "term": "区別",
    "termReading": "くべつ",
    "termTranslation": "distinction, classification",
    "ex": "あの二人はそっくりで区別できない。",
    "exReading": "あの ふたり は そっくり で くべつ できない",
    "exTranslation": "Those two look identical and I can't tell them apart.",
    "category": "Verbal Noun"
  },
  {
    "term": "差別",
    "termReading": "さべつ",
    "termTranslation": "discrimination",
    "ex": "彼は差別をなくす運動をしています。",
    "exReading": "かれ は さべつ を なくす うんどう を して います",
    "exTranslation": "He is working to end discrimination.",
    "category": "Verbal Noun"
  },
  {
    "term": "性別",
    "termReading": "せいべつ",
    "termTranslation": "sex, gender",
    "ex": "出席者を性別で分けてください。",
    "exReading": "しゅっせきしゃ を せいべつ で わけて ください",
    "exTranslation": "Please divide attendees by gender.",
    "category": "Noun"
  },
  {
    "term": "一般に",
    "termReading": "いっぱんに",
    "termTranslation": "generally, in general",
    "ex": "一般に老人は早起きです。",
    "exReading": "いっぱんに ろうじん は はやおき です",
    "exTranslation": "In general, elderly people get up early.",
    "category": "Adverb"
  },
  {
    "term": "経済的",
    "termReading": "けいざいてき",
    "termTranslation": "economical, economic",
    "ex": "経済的な効果は計り知れません。",
    "exReading": "けいざいてき な こうか は はかり しれません",
    "exTranslation": "The economic effects are immeasurable.",
    "category": "Adjective"
  },
  {
    "term": "自主的",
    "termReading": "じしゅてき",
    "termTranslation": "autonomous, voluntary",
    "ex": "学生たちは自主的に勉強会を開いたわよ。",
    "exReading": "がくせいたち は じしゅてき に べんきょうかい を ひらいた わ よ",
    "exTranslation": "Students held an independent study meeting.",
    "category": "Adjective"
  },
  {
    "term": "合理的",
    "termReading": "ごうりてき",
    "termTranslation": "rational, logical",
    "ex": "彼女は合理的な考え方をする人です。",
    "exReading": "かのじょ は ごうりてき な かんがえかた を する ひと です",
    "exTranslation": "She is a person who thinks logically.",
    "category": "Adjective"
  },
  {
    "term": "ずれる",
    "termReading": "ずれる",
    "termTranslation": "off track, out of line",
    "ex": "ポスターの位置が少しずれているよ。",
    "exReading": "ぽすたー の いち が すこし ずれて いる よ",
    "exTranslation": "The poster is slightly out of position.",
    "category": "Verb"
  },
  {
    "term": "形式的",
    "termReading": "けいしきてき",
    "termTranslation": "formal",
    "ex": "彼は形式的な質問をしただけだった。",
    "exReading": "かれ は けいしきてき な しつもん を した だけ だった",
    "exTranslation": "He only asked formal questions.",
    "category": "Adjective"
  },
  {
    "term": "実用的",
    "termReading": "じつようてき",
    "termTranslation": "practical",
    "ex": "彼の発明品はみな実用的だよ。",
    "exReading": "かれ の はつめいひん は みな じつようてき だ よ",
    "exTranslation": "All his inventions are practical.",
    "category": "Adjective"
  },
  {
    "term": "女性的",
    "termReading": "じょせいてき",
    "termTranslation": "feminine, womanly",
    "ex": "彼は言葉遣いが少し女性的だね。",
    "exReading": "かれ は ことばづかい が すこし じょせいてき だ ね",
    "exTranslation": "His way of talking is a little feminine.",
    "category": "Adjective"
  },
  {
    "term": "水平",
    "termReading": "すいへい",
    "termTranslation": "horizontal, level",
    "ex": "この棚は水平になっていませんね。",
    "exReading": "この たな は すいへい に なって いません ね",
    "exTranslation": "This shelf is not horizontal.",
    "category": "Adjectival Noun"
  },
  {
    "term": "水平線",
    "termReading": "すいへいせん",
    "termTranslation": "horizon",
    "ex": "水平線に太陽が沈んでいった。",
    "exReading": "すいへいせん に たいよう が しずんで いった",
    "exTranslation": "The sun was setting on the horizon.",
    "category": "Noun"
  },
  {
    "term": "高等",
    "termReading": "こうとう",
    "termTranslation": "higher, high-grade",
    "ex": "人間は高等な生物と言われている。",
    "exReading": "にんげん は こうとう な せいぶつ と いわれて いる",
    "exTranslation": "It is said that humans are advanced creatures.",
    "category": "Adjectival Noun"
  },
  {
    "term": "上等",
    "termReading": "じょうとう",
    "termTranslation": "first-class, premium",
    "ex": "上等なワインを飲んだの。",
    "exReading": "じょうとう な わいん を のんだ の",
    "exTranslation": "We drank good wine.",
    "category": "Adjectival Noun"
  },
  {
    "term": "オーバー",
    "termReading": "オーバー",
    "termTranslation": "exaggerate",
    "ex": "彼の話はいつもオーバーです。",
    "exReading": "かれ の はなし は いつも おーばー です",
    "exTranslation": "His story is always exaggerated.",
    "category": "Verbal Noun"
  },
  {
    "term": "急病",
    "termReading": "きゅうびょう",
    "termTranslation": "sudden illness",
    "ex": "知り合いが急病で倒れた。",
    "exReading": "しりあい が きゅうびょう で たおれた",
    "exTranslation": "An acquaintance of mine suddenly fell ill.",
    "category": "Noun"
  },
  {
    "term": "医学",
    "termReading": "いがく",
    "termTranslation": "medical science, medicine",
    "ex": "彼は医学を学んでいます。",
    "exReading": "かれ は いがく を まなんで います",
    "exTranslation": "He studies medicine.",
    "category": "Noun"
  },
  {
    "term": "科学者",
    "termReading": "かがくしゃ",
    "termTranslation": "scientist",
    "ex": "彼はとても有名な科学者だ。",
    "exReading": "かれ は とても ゆうめい な かがくしゃ だ",
    "exTranslation": "He is a very famous scientist.",
    "category": "Noun"
  },
  {
    "term": "科目",
    "termReading": "かもく",
    "termTranslation": "subject",
    "ex": "今日は3科目のテストを受けたよ。",
    "exReading": "きょう は さんかもく の てすと を うけた よ",
    "exTranslation": "I took exams for three subjects today.",
    "category": "Noun"
  },
  {
    "term": "学科",
    "termReading": "がっか",
    "termTranslation": "subject, department",
    "ex": "彼の得意な学科は数学です。",
    "exReading": "かれ の とくい な がっか は すうがく です",
    "exTranslation": "Math is his strong subject.",
    "category": "Noun"
  },
  {
    "term": "外科",
    "termReading": "げか",
    "termTranslation": "surgery",
    "ex": "友人が骨折して外科に入院しました。",
    "exReading": "ゆうじん が こっせつ して げか に にゅういん しました",
    "exTranslation": "My friend broke a bone and was admitted to hospital for surgery.",
    "category": "Noun"
  },
  {
    "term": "歯科",
    "termReading": "しか",
    "termTranslation": "dentistry",
    "ex": "彼は歯科医師です。",
    "exReading": "かれ は しか いし です",
    "exTranslation": "He is a dentist.",
    "category": "Noun"
  },
  {
    "term": "エアコン",
    "termReading": "エアコン",
    "termTranslation": "air-conditioning, air conditioner",
    "ex": "部屋にエアコンを取り付けたんだ。",
    "exReading": "へや に えあこん を とりつけた ん だ",
    "exTranslation": "The air conditioner was installed in the room.",
    "category": "Noun"
  },
  {
    "term": "自然科学",
    "termReading": "しぜんかがく",
    "termTranslation": "natural science",
    "ex": "姉は大学で自然科学を学んでいます。",
    "exReading": "あね は だいがく で しぜんかがく を まなんで います",
    "exTranslation": "My elder sister studies natural science at university.",
    "category": "Noun"
  },
  {
    "term": "人文科学",
    "termReading": "じんぶんかがく",
    "termTranslation": "humanities",
    "ex": "大学で人文科学を専攻しました。",
    "exReading": "だいがく で じんぶんかがく を せんこう しました",
    "exTranslation": "I majored in humanities when I was in college.",
    "category": "Noun"
  },
  {
    "term": "社会科学",
    "termReading": "しゃかいかがく",
    "termTranslation": "social sciences",
    "ex": "社会科学の分野に関心があります。",
    "exReading": "しゃかいかがく の ぶんや に かんしん が あります",
    "exTranslation": "I'm interested in the field of social sciences.",
    "category": "Noun"
  },
  {
    "term": "死",
    "termReading": "し",
    "termTranslation": "death",
    "ex": "死を恐れるのは自然なことです。",
    "exReading": "し を おそれる の は しぜん な こと です",
    "exTranslation": "It is natural to be afraid of death.",
    "category": "Noun"
  },
  {
    "term": "死者",
    "termReading": "ししゃ",
    "termTranslation": "dead person, the deceased",
    "ex": "その事故で30人の死者が出たの。",
    "exReading": "その じこ で さんじゅうにん の ししゃ が でた の",
    "exTranslation": "Thirty people died due to the accident.",
    "category": "Noun"
  },
  {
    "term": "死体",
    "termReading": "したい",
    "termTranslation": "corpse, body",
    "ex": "公園で死体が見つかったの。",
    "exReading": "こうえん で したい が みつかった の",
    "exTranslation": "A corpse was found in the park.",
    "category": "Noun"
  },
  {
    "term": "生死",
    "termReading": "せいし",
    "termTranslation": "life and death",
    "ex": "これは人の生死にかかわる問題です。",
    "exReading": "これ は ひと の せいし に かかわる もんだい で す",
    "exTranslation": "This is a matter of life and death.",
    "category": "Noun"
  },
  {
    "term": "死亡",
    "termReading": "しぼう",
    "termTranslation": "death",
    "ex": "その事故では2人死亡したの。",
    "exReading": "その じこ で は ふたり しぼう した の",
    "exTranslation": "Two people died in that accident.",
    "category": "Verbal Noun"
  },
  {
    "term": "しょっちゅう",
    "termReading": "しょっちゅう",
    "termTranslation": "frequently, repeatedly",
    "ex": "彼はしょっちゅう遅刻しているな。",
    "exReading": "かれ は しょっちゅう ちこく して いる な",
    "exTranslation": "He is frequently late.",
    "category": "Adverb"
  },
  {
    "term": "痛み",
    "termReading": "いたみ",
    "termTranslation": "pain, ache",
    "ex": "背中に痛みがあります。",
    "exReading": "せなか に いたみ が あります",
    "exTranslation": "I have a pain in my back.",
    "category": "Noun"
  },
  {
    "term": "痛む",
    "termReading": "いたむ",
    "termTranslation": "feel pain, hurt",
    "ex": "虫歯がずきずき痛みます。",
    "exReading": "むしば が ずきずき いたみます",
    "exTranslation": "I have a throbbing toothache.",
    "category": "Verb"
  },
  {
    "term": "禁止",
    "termReading": "きんし",
    "termTranslation": "prohibition, forbiddance",
    "ex": "交差点付近は駐車禁止です。",
    "exReading": "こうさてん ふきん は ちゅうしゃ きんし です",
    "exTranslation": "It is forbidden to park close to the intersection.",
    "category": "Verbal Noun"
  },
  {
    "term": "禁じる",
    "termReading": "きんじる",
    "termTranslation": "prohibit, forbid",
    "ex": "市は昨年から歩きタバコを禁じているの。",
    "exReading": "し は さくねん から あるきたばこ を きんじて いる の",
    "exTranslation": "Since last year, the city has banned smoking cigarettes while walking.",
    "category": "Verb"
  },
  {
    "term": "煙",
    "termReading": "けむり",
    "termTranslation": "smoke, fumes",
    "ex": "煙突から煙がまっすぐ上っていたの。",
    "exReading": "えんとつ から けむり が まっすぐ あがって いた の",
    "exTranslation": "Smoke was rising straight up from the chimney.",
    "category": "Noun"
  },
  {
    "term": "酒屋",
    "termReading": "さかや",
    "termTranslation": "liquor store",
    "ex": "そこの酒屋さんでビールを買って来て。",
    "exReading": "そこ の さかやさん で びーる を かって きて",
    "exTranslation": "Go and buy some beer at that liquor store.",
    "category": "Noun"
  },
  {
    "term": "禁酒",
    "termReading": "きんしゅ",
    "termTranslation": "abstention from alcohol",
    "ex": "彼は禁酒しています。",
    "exReading": "かれ は きんしゅ して います",
    "exTranslation": "He is on the wagon.",
    "category": "Verbal Noun"
  },
  {
    "term": "スタンド",
    "termReading": "スタンド",
    "termTranslation": "table lamp, desk lamp",
    "ex": "姉はスタンドの下で本を読んでいたんだ。",
    "exReading": "あね は すたんど の した で ほん を よんで いた ん だ",
    "exTranslation": "My elder sister was reading a book by the desk lamp.",
    "category": "Noun"
  },
  {
    "term": "険しい",
    "termReading": "けわしい",
    "termTranslation": "steep, craggy",
    "ex": "父の表情が険しくなったの。",
    "exReading": "ちち の ひょうじょう が けわしく なった の",
    "exTranslation": "My father's expression became grim.",
    "category": "Adjective"
  },
  {
    "term": "証券",
    "termReading": "しょうけん",
    "termTranslation": "bill, bond",
    "ex": "証券を売って資金にしようと思うの。",
    "exReading": "しょうけん を うって しきん に しよう と おもう の",
    "exTranslation": "I'm thinking of selling some securities and converting them to funds.",
    "category": "Noun"
  },
  {
    "term": "証明",
    "termReading": "しょうめい",
    "termTranslation": "proof, evidence",
    "ex": "容疑者のアリバイが証明されました。",
    "exReading": "ようぎしゃ の ありばい が しょうめい されました",
    "exTranslation": "The suspect's alibi was proven.",
    "category": "Verbal Noun"
  },
  {
    "term": "生存",
    "termReading": "せいぞん",
    "termTranslation": "existence, survival",
    "ex": "乗客は全員生存しています。",
    "exReading": "じょうきゃく は ぜんいん せいぞん して います",
    "exTranslation": "All passengers survived.",
    "category": "Verbal Noun"
  },
  {
    "term": "ご存じ",
    "termReading": "ごぞんじ",
    "termTranslation": "know",
    "ex": "彼の名前をご存じですか。",
    "exReading": "かれ の なまえ を ごぞんじ です か",
    "exTranslation": "Do you know his name?",
    "category": "Noun"
  },
  {
    "term": "合意",
    "termReading": "ごうい",
    "termTranslation": "mutual agreement",
    "ex": "両社が合併に合意しました。",
    "exReading": "りょうしゃ が がっぺい に ごうい しました",
    "exTranslation": "The two companies agreed to the amalgamation.",
    "category": "Verbal Noun"
  },
  {
    "term": "意向",
    "termReading": "いこう",
    "termTranslation": "intention, inclination",
    "ex": "彼の意向を聞いてみましょう。",
    "exReading": "かれ の いこう を きいて みましょう",
    "exTranslation": "Let's ask what he intends to do.",
    "category": "Noun"
  },
  {
    "term": "がっかり",
    "termReading": "がっかり",
    "termTranslation": "feel disappointed",
    "ex": "成績が落ちてがっかりした。",
    "exReading": "せいせき が おちて がっかり した",
    "exTranslation": "I was disappointed bacause my grades fell.",
    "category": "Adverb"
  },
  {
    "term": "意欲",
    "termReading": "いよく",
    "termTranslation": "volition, motivation",
    "ex": "彼は仕事に意欲を燃やしています。",
    "exReading": "かれ は しごと に いよく を もやして います",
    "exTranslation": "He is very motivated to work.",
    "category": "Noun"
  },
  {
    "term": "決意",
    "termReading": "けつい",
    "termTranslation": "resolution, determination",
    "ex": "彼の決意は堅いな。",
    "exReading": "かれ の けつい は かたい な",
    "exTranslation": "His decision is firm.",
    "category": "Verbal Noun"
  },
  {
    "term": "意図",
    "termReading": "いと",
    "termTranslation": "intention, aim",
    "ex": "あなたの意図はよく分かりました。",
    "exReading": "あなた の いと は よく わかりました",
    "exTranslation": "I understood your intention well.",
    "category": "Verbal Noun"
  },
  {
    "term": "意外",
    "termReading": "いがい",
    "termTranslation": "unexpected, unforeseen",
    "ex": "意外にも彼は独身です。",
    "exReading": "いがい に も かれ は どくしん です",
    "exTranslation": "Surprisingly, he is single.",
    "category": "Adjectival Noun"
  },
  {
    "term": "意義",
    "termReading": "いぎ",
    "termTranslation": "meaning, significance",
    "ex": "この事業には大きな意義があります。",
    "exReading": "この じぎょう に は おおき な いぎ が あります",
    "exTranslation": "This project has enormous significance.",
    "category": "Noun"
  },
  {
    "term": "好意",
    "termReading": "こうい",
    "termTranslation": "favor, affection",
    "ex": "彼は彼女に好意をもっています。",
    "exReading": "かれ は かのじょ に こうい を もって います",
    "exTranslation": "He has affections for her.",
    "category": "Noun"
  },
  {
    "term": "意地悪",
    "termReading": "いじわる",
    "termTranslation": "nastiness, ill-natured person",
    "ex": "彼は時々意地悪な質問をする。",
    "exReading": "かれ は ときどき いじわる な しつもん を する 。",
    "exTranslation": "He sometimes raises nasty questions.",
    "category": "Verbal Noun"
  },
  {
    "term": "確保",
    "termReading": "かくほ",
    "termTranslation": "securement",
    "ex": "優れた人材の確保は重要です。",
    "exReading": "すぐれた じんざい の かくほ は じゅうよう です",
    "exTranslation": "Securing excellent talent is important.",
    "category": "Verbal Noun"
  },
  {
    "term": "スライド",
    "termReading": "スライド",
    "termTranslation": "slide",
    "ex": "このふたはスライドします。",
    "exReading": "この ふた は すらいど します",
    "exTranslation": "This cover slides aside.",
    "category": "Verbal Noun"
  },
  {
    "term": "確立",
    "termReading": "かくりつ",
    "termTranslation": "establishment",
    "ex": "北欧では社会保障が確立されています。",
    "exReading": "ほくおう で は しゃかい ほしょう が かくりつ されて います",
    "exTranslation": "Social security has been established in Northern Europe.",
    "category": "Verbal Noun"
  },
  {
    "term": "確定",
    "termReading": "かくてい",
    "termTranslation": "decision, confirmation",
    "ex": "試合の代表メンバーが確定したの。",
    "exReading": "しあい の だいひょう めんばー が かくてい した の",
    "exTranslation": "The game's team members have been decided.",
    "category": "Verbal Noun"
  },
  {
    "term": "確実",
    "termReading": "かくじつ",
    "termTranslation": "certain, secure",
    "ex": "彼女が将来、大統領になることは確実です。",
    "exReading": "かのじょ が しょうらい だいとうりょう に なる こと は かくじつ です",
    "exTranslation": "It is certain that she will become the president in the future.",
    "category": "Adjective"
  },
  {
    "term": "確信",
    "termReading": "かくしん",
    "termTranslation": "firm belief, conviction",
    "ex": "私は彼の成功を確信しています。",
    "exReading": "わたし は かれ の せいこう を かくしん して います",
    "exTranslation": "I am convinced of his success.",
    "category": "Verbal Noun"
  },
  {
    "term": "効率",
    "termReading": "こうりつ",
    "termTranslation": "efficiency",
    "ex": "作業の効率を上げるにはどうしたらいいですか。",
    "exReading": "さぎょう の こうりつ を あげる に は どう したら いい です か",
    "exTranslation": "How should we increase efficiency at work?",
    "category": "Noun"
  },
  {
    "term": "確率",
    "termReading": "かくりつ",
    "termTranslation": "probability, rate",
    "ex": "天気予報は当たる確率が高くなりましたね。",
    "exReading": "てんき よほう は あたる かくりつ が たかく なりました ね",
    "exTranslation": "The chance that the weather forecast is right has increased.",
    "category": "Noun"
  },
  {
    "term": "軽率",
    "termReading": "けいそつ",
    "termTranslation": "rash, light-headed",
    "ex": "軽率な発言はしないよう気をつけなさい。",
    "exReading": "けいそつ な はつげん は しない よう き を つけなさい",
    "exTranslation": "Take care not to make flippant remarks.",
    "category": "Adjective"
  },
  {
    "term": "おっしゃる",
    "termReading": "おっしゃる",
    "termTranslation": "say",
    "ex": "あなたのおっしゃる通りです。",
    "exReading": "あなた の おっしゃる とおり です",
    "exTranslation": "It is as you say.",
    "category": "Verb"
  },
  {
    "term": "機会",
    "termReading": "きかい",
    "termTranslation": "opportunity, occasion",
    "ex": "家族で話し合う機会を持ちました。",
    "exReading": "かぞく で はなしあう きかい を もちました",
    "exTranslation": "I set up an opportunity to have a discussion with the family.",
    "category": "Noun"
  },
  {
    "term": "危機",
    "termReading": "きき",
    "termTranslation": "crisis, emergency",
    "ex": "地球環境の危機が叫ばれているのよ。",
    "exReading": "ちきゅう かんきょう の きき が さけばれて いる の よ",
    "exTranslation": "There's an outcry over a world-wide environmental crisis.",
    "category": "Noun"
  },
  {
    "term": "機長",
    "termReading": "きちょう",
    "termTranslation": "airplane captain",
    "ex": "機長の放送があったの。",
    "exReading": "きちょう の ほうそう が あった の",
    "exTranslation": "There was an announcement from the captain.",
    "category": "Noun"
  },
  {
    "term": "時機",
    "termReading": "じき",
    "termTranslation": "opportunity, chance",
    "ex": "あせらずに時機を待つべきです。",
    "exReading": "あせらず に じき を まつ べき です",
    "exTranslation": "You should wait patiently for the right opportunity.",
    "category": "Noun"
  },
  {
    "term": "楽器",
    "termReading": "がっき",
    "termTranslation": "musical instrument",
    "ex": "私が好きな楽器はギターです。",
    "exReading": "わたし が すき な がっき は ぎたー です",
    "exTranslation": "My favorite instrument is the guitar.",
    "category": "Noun"
  },
  {
    "term": "食器",
    "termReading": "しょっき",
    "termTranslation": "tableware, dinner set",
    "ex": "食器を全部新しくしました。",
    "exReading": "しょっき を ぜんぶ あたらしく しました",
    "exTranslation": "I replaced all the tableware.",
    "category": "Noun"
  },
  {
    "term": "器用",
    "termReading": "きよう",
    "termTranslation": "skillful, clever",
    "ex": "彼はかなり器用な人です。",
    "exReading": "かれ は かなり きよう な ひと です",
    "exTranslation": "He is very good with his hands.",
    "category": "Adjectival Noun"
  },
  {
    "term": "クラシック",
    "termReading": "クラシック",
    "termTranslation": "classical music, the classics",
    "ex": "彼女はクラシック音楽が好きです。",
    "exReading": "かのじょ は くらしっく おんがく が すき です",
    "exTranslation": "She likes classical music.",
    "category": "Adjectival Noun"
  },
  {
    "term": "受話器",
    "termReading": "じゅわき",
    "termTranslation": "receiver",
    "ex": "受話器を取ってもらえますか。",
    "exReading": "じゅわき を とって もらえます か",
    "exTranslation": "Could you pick up the receiver?",
    "category": "Noun"
  },
  {
    "term": "器",
    "termReading": "うつわ",
    "termTranslation": "vessel, dish",
    "ex": "この器は上等ね。",
    "exReading": "この うつわ は じょうとう ね",
    "exTranslation": "This dish is really top quality.",
    "category": "Noun"
  },
  {
    "term": "消火器",
    "termReading": "しょうかき",
    "termTranslation": "fire extinguisher",
    "ex": "消火器を交換しました。",
    "exReading": "しょうかき を こうかん しました",
    "exTranslation": "The fire extinguisher was replaced.",
    "category": "Noun"
  },
  {
    "term": "器械",
    "termReading": "きかい",
    "termTranslation": "instrument, apparatus",
    "ex": "体育館で器械を使って運動したの。",
    "exReading": "たいいくかん で きかい を つかって うんどう した の",
    "exTranslation": "I did exercise with machines in the gym.",
    "category": "Noun"
  },
  {
    "term": "取材",
    "termReading": "しゅざい",
    "termTranslation": "collection of data, gathering of news",
    "ex": "テレビも取材に来たほど有名なレストランです。",
    "exReading": "てれび も しゅざい に きた ほど ゆうめい な れすとらん です",
    "exTranslation": "The restaurant is so famous that the TV news ran a story on it.",
    "category": "Verbal Noun"
  },
  {
    "term": "材木",
    "termReading": "ざいもく",
    "termTranslation": "timber, wood",
    "ex": "船から材木が降ろされていますね。",
    "exReading": "ふね から ざいもく が おろされて います ね",
    "exTranslation": "Timber is being unloaded from the ship.",
    "category": "Noun"
  },
  {
    "term": "具合",
    "termReading": "ぐあい",
    "termTranslation": "condition, health",
    "ex": "今日は体の具合が悪いです。",
    "exReading": "きょう は からだ の ぐあい が わるい です",
    "exTranslation": "I feel unwell today.",
    "category": "Noun"
  },
  {
    "term": "さっと",
    "termReading": "さっと",
    "termTranslation": "quickly, suddenly",
    "ex": "こぼれた牛乳をさっと拭き取ったの。",
    "exReading": "こぼれた ぎゅうにゅう を さっと ふきとった の",
    "exTranslation": "I quickly wiped up the spilt milk.",
    "category": "Adverb"
  },
  {
    "term": "器具",
    "termReading": "きぐ",
    "termTranslation": "utensil, implement",
    "ex": "これはスポーツ施設用の器具です。",
    "exReading": "これ は すぽーつ しせつよう の きぐ です",
    "exTranslation": "This is equipment for the sports facility.",
    "category": "Noun"
  },
  {
    "term": "家具",
    "termReading": "かぐ",
    "termTranslation": "furniture",
    "ex": "部屋の家具を動かしました。",
    "exReading": "へや の かぐ を うごかしました",
    "exTranslation": "I moved the furniture in the room.",
    "category": "Noun"
  },
  {
    "term": "雨具",
    "termReading": "あまぐ",
    "termTranslation": "rain gear, rainwear",
    "ex": "雨具の用意を忘れないように。",
    "exReading": "あまぐ の ようい を わすれない よう に",
    "exTranslation": "Don't forget to prepare your rainwear.",
    "category": "Noun"
  },
  {
    "term": "基地",
    "termReading": "きち",
    "termTranslation": "base, military base",
    "ex": "ここは昔、軍事基地でした。",
    "exReading": "ここ は むかし ぐんじ きち でした",
    "exTranslation": "This used to be a military base.",
    "category": "Noun"
  },
  {
    "term": "水準",
    "termReading": "すいじゅん",
    "termTranslation": "level, standard",
    "ex": "今年の応募作品は水準が高かったね。",
    "exReading": "ことし の おうぼ さくひん は すいじゅん が たかかった ね",
    "exTranslation": "The standard of entries this year was high.",
    "category": "Noun"
  },
  {
    "term": "基準",
    "termReading": "きじゅん",
    "termTranslation": "standard, criterion",
    "ex": "この建物は建築の基準に達していないよ。",
    "exReading": "この たてもの は けんちく の きじゅん に たっしていない よ 。",
    "exTranslation": "This building doesn't meet the construction standards.",
    "category": "Noun"
  },
  {
    "term": "準急",
    "termReading": "じゅんきゅう",
    "termTranslation": "local express",
    "ex": "新宿駅まで準急で行ったのよ。",
    "exReading": "しんじゅくえき まで じゅんきゅう で いった の よ",
    "exTranslation": "I went to Tokyo Station by the local express.",
    "category": "Noun"
  },
  {
    "term": "設立",
    "termReading": "せつりつ",
    "termTranslation": "establishment, foundation",
    "ex": "当社は10年前に設立されたのよ。",
    "exReading": "とうしゃ は じゅうねんまえ に せつりつ された の よ",
    "exTranslation": "Our company was established ten years ago.",
    "category": "Verbal Noun"
  },
  {
    "term": "キャンセル",
    "termReading": "キャンセル",
    "termTranslation": "cancellation",
    "ex": "予約をキャンセルしたよ。",
    "exReading": "よやく を きゃんせる した よ",
    "exTranslation": "I canceled the reservation.",
    "category": "Verbal Noun"
  },
  {
    "term": "設備",
    "termReading": "せつび",
    "termTranslation": "equipment, facilities",
    "ex": "この研究所には最新の設備が揃っています。",
    "exReading": "この けんきゅうじょ に は さいしん の せつび が そろって います",
    "exTranslation": "This laboratory has the latest equipment.",
    "category": "Verbal Noun"
  },
  {
    "term": "説",
    "termReading": "せつ",
    "termTranslation": "theory, opinion",
    "ex": "私は彼の説が正しいと思う。",
    "exReading": "わたし は かれ の せつ が ただしい と おもう",
    "exTranslation": "I think that his theory is correct.",
    "category": "Noun"
  },
  {
    "term": "解説",
    "termReading": "かいせつ",
    "termTranslation": "explanation, commentary",
    "ex": "新聞の解説欄を読みました。",
    "exReading": "しんぶん の かいせつ らん を よみました",
    "exTranslation": "I read the comment column in the newspaper.",
    "category": "Verbal Noun"
  },
  {
    "term": "社説",
    "termReading": "しゃせつ",
    "termTranslation": "editorial",
    "ex": "この新聞の社説は面白いね。",
    "exReading": "この しんぶん の しゃせつ は おもしろい ね",
    "exTranslation": "The editorial in this newspaper is interesting.",
    "category": "Noun"
  },
  {
    "term": "学説",
    "termReading": "がくせつ",
    "termTranslation": "theory",
    "ex": "それは最新の学説ね。",
    "exReading": "それ は さいしん の がくせつ ね",
    "exTranslation": "It is the latest theory.",
    "category": "Noun"
  },
  {
    "term": "公式",
    "termReading": "こうしき",
    "termTranslation": "formal, official",
    "ex": "今後の方針が公式に発表されました。",
    "exReading": "こんご の ほうしん が こうしき に はっぴょう されました",
    "exTranslation": "The future policy was officially announced.",
    "category": "Adjectival Noun"
  },
  {
    "term": "公共",
    "termReading": "こうきょう",
    "termTranslation": "public society, community",
    "ex": "公共の乗り物は誰でも利用できます。",
    "exReading": "こうきょう の のりもの は だれ で も りよう できます",
    "exTranslation": "Public transport is available to everyone.",
    "category": "Noun"
  },
  {
    "term": "シーツ",
    "termReading": "シーツ",
    "termTranslation": "sheet",
    "ex": "メイドがシーツを交換してくれたね。",
    "exReading": "めいど が しーつ を こうかん して くれた ね",
    "exTranslation": "The maid changed the sheets.",
    "category": "Noun"
  },
  {
    "term": "公平",
    "termReading": "こうへい",
    "termTranslation": "impartiality, fairness",
    "ex": "賞金をもらったら公平に分けましょう。",
    "exReading": "しょうきん を もらったら こうへい に わけましょう",
    "exTranslation": "If we win the prize, let's share it equally.",
    "category": "Adjectival Noun"
  },
  {
    "term": "公立",
    "termReading": "こうりつ",
    "termTranslation": "public",
    "ex": "駅前に公立の図書館があるよ。",
    "exReading": "えきまえ に こうりつ の としょかん が ある よ",
    "exTranslation": "There is a public library opposite the station.",
    "category": "Noun"
  },
  {
    "term": "学園",
    "termReading": "がくえん",
    "termTranslation": "educational institution, school",
    "ex": "彼女は郊外の学園に通っているね。",
    "exReading": "かのじょ は こうがい の がくえん に かよって いる ね",
    "exTranslation": "She goes to a school in the suburbs.",
    "category": "Noun"
  },
  {
    "term": "祭日",
    "termReading": "さいじつ",
    "termTranslation": "national holiday, festival day",
    "ex": "うちの会社は祭日は休みです。",
    "exReading": "うち の かいしゃ は さいじつ は やすみ です",
    "exTranslation": "At our company, we get national holidays off.",
    "category": "Noun"
  },
  {
    "term": "国際的",
    "termReading": "こくさいてき",
    "termTranslation": "international",
    "ex": "彼女は国際的に有名な歌手です。",
    "exReading": "かのじょ は こくさいてき に ゆうめい な かしゅ です",
    "exTranslation": "She is an internationally famous singer.",
    "category": "Adjective"
  },
  {
    "term": "国際化",
    "termReading": "こくさいか",
    "termTranslation": "internationalization",
    "ex": "この大学も国際化してきたな。",
    "exReading": "この だいがく も こくさいか して きた な",
    "exTranslation": "This university has internationalized.",
    "category": "Verbal Noun"
  },
  {
    "term": "交際",
    "termReading": "こうさい",
    "termTranslation": "association, friendship",
    "ex": "彼はモデルと交際していたんだ。",
    "exReading": "かれ は もでる と こうさい して いた ん だ",
    "exTranslation": "He was going out with a model.",
    "category": "Verbal Noun"
  },
  {
    "term": "かかる",
    "termReading": "かかる",
    "termTranslation": "catch, get",
    "ex": "彼女はインフルエンザにかかっていますね。",
    "exReading": "かのじょ は いんふるえんざ に かかって います ね",
    "exTranslation": "She has come down with the flu.",
    "category": "Verb"
  },
  {
    "term": "際",
    "termReading": "さい",
    "termTranslation": "occasion, time",
    "ex": "この際はっきり言っておきます。",
    "exReading": "この さい はっきり いって おきます",
    "exTranslation": "It's a good opportunity to speak frankly.",
    "category": "Noun"
  },
  {
    "term": "航空",
    "termReading": "こうくう",
    "termTranslation": "aviation",
    "ex": "会議で航空の安全について話し合ったの。",
    "exReading": "かいぎ で こうくう の あんぜん に ついて はなしあった の",
    "exTranslation": "Airline safety was discussed at the conference.",
    "category": "Noun"
  },
  {
    "term": "航空機",
    "termReading": "こうくうき",
    "termTranslation": "aircraft, airplane",
    "ex": "去年は航空機の事故が多かった。",
    "exReading": "きょねん は こうくうき の じこ が おおかった",
    "exTranslation": "There were a lot of aircraft accidents last year.",
    "category": "Noun"
  },
  {
    "term": "航空券",
    "termReading": "こうくうけん",
    "termTranslation": "airline ticket",
    "ex": "電話で航空券を予約しました。",
    "exReading": "でんわ で こうくうけん を よやく しました",
    "exTranslation": "I booked an airline ticket by phone.",
    "category": "Noun"
  },
  {
    "term": "完全",
    "termReading": "かんぜん",
    "termTranslation": "perfect, complete",
    "ex": "この古い寺院は今でも完全な形を保っているんだ。",
    "exReading": "この ふるい じいん は いま で も かんぜん な かたち を たもって いる ん だ",
    "exTranslation": "This old Buddhist temple still maintains its complete form.",
    "category": "Adjectival Noun"
  },
  {
    "term": "成長",
    "termReading": "せいちょう",
    "termTranslation": "growth",
    "ex": "庭の木、大きく成長したわね。",
    "exReading": "にわ の き、 おおきく せいちょう した わ ね",
    "exTranslation": "The tree in the garden has grown a lot.",
    "category": "Verbal Noun"
  },
  {
    "term": "成立",
    "termReading": "せいりつ",
    "termTranslation": "formation",
    "ex": "あの会社との契約が成立しました。",
    "exReading": "あの かいしゃ と の けいやく が せいりつ しました",
    "exTranslation": "We concluded a contract with that company.",
    "category": "Verbal Noun"
  },
  {
    "term": "形成",
    "termReading": "けいせい",
    "termTranslation": "formation",
    "ex": "今は骨が形成される大切な時期です。",
    "exReading": "いま は ほね が けいせい される たいせつ な じき です",
    "exTranslation": "This is an important period in life when bones are being formed.",
    "category": "Verbal Noun"
  },
  {
    "term": "コック",
    "termReading": "コック",
    "termTranslation": "cook, chef",
    "ex": "彼は腕のいいコックです。",
    "exReading": "かれ は うで の いい こっく です",
    "exTranslation": "He is a good cook.",
    "category": "Noun"
  },
  {
    "term": "結成",
    "termReading": "けっせい",
    "termTranslation": "organization, formation",
    "ex": "新しい代表チームが結成されました。",
    "exReading": "あたらしい だいひょう ちーむ が けっせい されました",
    "exTranslation": "The new representative team was formed.",
    "category": "Verbal Noun"
  },
  {
    "term": "成果",
    "termReading": "せいか",
    "termTranslation": "result, fruit",
    "ex": "厳しい練習が良い成果に結び付きました。",
    "exReading": "きびしい れんしゅう が よい せいか に むすびつきました",
    "exTranslation": "Intense training leads to good results.",
    "category": "Noun"
  },
  {
    "term": "賛成",
    "termReading": "さんせい",
    "termTranslation": "approval, agreement",
    "ex": "私は彼の意見に賛成だ。",
    "exReading": "わたし は かれ の いけん に さんせい だ",
    "exTranslation": "I agree with his opinion.",
    "category": "Verbal Noun"
  },
  {
    "term": "合成",
    "termReading": "ごうせい",
    "termTranslation": "composition, synthesis",
    "ex": "このソフトで画像を合成できます。",
    "exReading": "この そふと で がぞう を ごうせい できます",
    "exTranslation": "Images can be synthesized with this software.",
    "category": "Verbal Noun"
  },
  {
    "term": "成人",
    "termReading": "せいじん",
    "termTranslation": "adult, grownup",
    "ex": "娘が今年成人します。",
    "exReading": "むすめ が ことし せいじん します",
    "exTranslation": "My daughter comes of age this year.",
    "category": "Verbal Noun"
  },
  {
    "term": "成年",
    "termReading": "せいねん",
    "termTranslation": "majority, adult",
    "ex": "成年になると独立した戸籍を作れます。",
    "exReading": "せいねん に なる と どくりつ した こせき を つくれます",
    "exTranslation": "An individual family register can be made when you become an adult.",
    "category": "Noun"
  },
  {
    "term": "失う",
    "termReading": "うしなう",
    "termTranslation": "lose, miss",
    "ex": "彼は地震で親を失いました。",
    "exReading": "かれ は じしん で おや を うしないました",
    "exTranslation": "He lost his parents due to the earthquake.",
    "category": "Verb"
  },
  {
    "term": "えび",
    "termReading": "えび",
    "termTranslation": "lobster, shrimp",
    "ex": "私はえびのてんぷらが好きです。",
    "exReading": "わたし は えび の てんぷら が すき です",
    "exTranslation": "I like shrimp tempura.",
    "category": "Noun"
  },
  {
    "term": "失業",
    "termReading": "しつぎょう",
    "termTranslation": "unemployment, loss of job",
    "ex": "友達のお父さんが突然失業したの。",
    "exReading": "ともだち の おとうさん が とつぜん しつぎょう した の",
    "exTranslation": "My friend's father suddenly lost his job.",
    "category": "Verbal Noun"
  },
  {
    "term": "失敗",
    "termReading": "しっぱい",
    "termTranslation": "failure, mistake",
    "ex": "一度の失敗であきらめてはいけないよ。",
    "exReading": "いちど の しっぱい で あきらめては いけない よ",
    "exTranslation": "You must not give up after one setback.",
    "category": "Verbal Noun"
  },
  {
    "term": "勝敗",
    "termReading": "しょうはい",
    "termTranslation": "victory or defeat",
    "ex": "このセットで勝敗が決まります。",
    "exReading": "この せっと で しょうはい が きまります",
    "exTranslation": "Victory or defeat will be decided in this set.",
    "category": "Noun"
  },
  {
    "term": "原則",
    "termReading": "げんそく",
    "termTranslation": "principle, general rule",
    "ex": "原則としてキャンセルできません。",
    "exReading": "げんそく と して きゃんせる できません",
    "exTranslation": "As a general rule, cancellations can't be accepted.",
    "category": "Noun"
  },
  {
    "term": "原料",
    "termReading": "げんりょう",
    "termTranslation": "raw material",
    "ex": "石油はいろいろな製品の原料になります。",
    "exReading": "せきゆ は いろいろ な せいひん の げんりょう に なります",
    "exTranslation": "Petroleum is the raw material for various products.",
    "category": "Noun"
  },
  {
    "term": "原理",
    "termReading": "げんり",
    "termTranslation": "principle, theory",
    "ex": "見学者に機械の動く原理を説明しました。",
    "exReading": "けんがくしゃ に きかい の うごく げんり を せつめい しました",
    "exTranslation": "I explained the mechanism of the machine to the visitor.",
    "category": "Noun"
  },
  {
    "term": "原子力",
    "termReading": "げんしりょく",
    "termTranslation": "atomic energy, nuclear power",
    "ex": "原子力の安全な利用について考えましょう。",
    "exReading": "げんしりょく の あんぜん な りよう に ついて かんがえましょう",
    "exTranslation": "Let's think about the safe use of nuclear power.",
    "category": "Noun"
  },
  {
    "term": "グリーン",
    "termReading": "グリーン",
    "termTranslation": "green",
    "ex": "そのグリーンのスカーフ、素敵ですね。",
    "exReading": "その ぐりーん の すかーふ すてき です ね",
    "exTranslation": "That green scarf is lovely, isn't it?",
    "category": "Noun"
  },
  {
    "term": "高原",
    "termReading": "こうげん",
    "termTranslation": "plateau, highland",
    "ex": "高原には気持ちのいい風が吹いていたよ。",
    "exReading": "こうげん に は きもち の いい かぜ が ふいていた よ 。",
    "exTranslation": "A pleasant breeze blew across the plateau.",
    "category": "Noun"
  },
  {
    "term": "資料",
    "termReading": "しりょう",
    "termTranslation": "materials, data",
    "ex": "図書館で資料を借りて来ました。",
    "exReading": "としょかん で しりょう を かりて きました",
    "exTranslation": "I borrowed some materials from the library.",
    "category": "Noun"
  },
  {
    "term": "資産",
    "termReading": "しさん",
    "termTranslation": "property, assets",
    "ex": "大臣の資産が公表されたね。",
    "exReading": "だいじん の しさん が こうひょう された ね",
    "exTranslation": "The minister's assets were made public.",
    "category": "Noun"
  },
  {
    "term": "資格",
    "termReading": "しかく",
    "termTranslation": "qualifications, competence",
    "ex": "日本語を教える資格を取りたいです。",
    "exReading": "にほんご を おしえる しかく を とりたい です",
    "exTranslation": "I want to get a license to teach Japanese.",
    "category": "Noun"
  },
  {
    "term": "資本主義",
    "termReading": "しほんしゅぎ",
    "termTranslation": "capitalism",
    "ex": "その国は次第に資本主義になったわね。",
    "exReading": "その くに は しだいに しほんしゅぎ に なった わ ね",
    "exTranslation": "The country gradually became capitalist.",
    "category": "Noun"
  },
  {
    "term": "資源",
    "termReading": "しげん",
    "termTranslation": "resources",
    "ex": "地球の資源を守りましょう。",
    "exReading": "ちきゅう の しげん を まもりましょう",
    "exTranslation": "Let's protect the earth's resources.",
    "category": "Noun"
  },
  {
    "term": "願書",
    "termReading": "がんしょ",
    "termTranslation": "application form",
    "ex": "今日大学に願書を送りました。",
    "exReading": "きょう だいがく に がんしょ を おくりました",
    "exTranslation": "Today I sent an application form to the college.",
    "category": "Noun"
  },
  {
    "term": "正式",
    "termReading": "せいしき",
    "termTranslation": "formal, regular",
    "ex": "正式な招待状を受け取りました。",
    "exReading": "せいしき な しょうたいじょう を うけとりました",
    "exTranslation": "I received a formal invitation.",
    "category": "Adjectival Noun"
  },
  {
    "term": "ジーパン",
    "termReading": "ジーパン",
    "termTranslation": "jeans",
    "ex": "彼はいつもジーパンをはいているね。",
    "exReading": "かれ は いつも じーぱん を はいて いる ね",
    "exTranslation": "He always wears jeans.",
    "category": "Noun"
  },
  {
    "term": "正面",
    "termReading": "しょうめん",
    "termTranslation": "front, face",
    "ex": "その家の正面には大きなバルコニーがあるの。",
    "exReading": "その いえ の しょうめん に は おおき な ばるこにー が ある の",
    "exTranslation": "There is a large balcony at the front of the house.",
    "category": "Noun"
  },
  {
    "term": "正午",
    "termReading": "しょうご",
    "termTranslation": "noon, noontime",
    "ex": "昼休みは正午からです。",
    "exReading": "ひるやすみ は しょうご から です",
    "exTranslation": "The lunch break is from noon.",
    "category": "Noun"
  },
  {
    "term": "正義",
    "termReading": "せいぎ",
    "termTranslation": "justice, righteousness",
    "ex": "この世に正義はないのだろうか。",
    "exReading": "この よ に せいぎ は ない の だろう か",
    "exTranslation": "Is there no justice in this world?",
    "category": "Noun"
  },
  {
    "term": "正門",
    "termReading": "せいもん",
    "termTranslation": "main gate, main entrance",
    "ex": "受験生は正門から入って下さい。",
    "exReading": "じゅけんせい は せいもん から はいって ください",
    "exTranslation": "Test-takers must enter from the front gate.",
    "category": "Noun"
  },
  {
    "term": "正解",
    "termReading": "せいかい",
    "termTranslation": "right answer, correct solution",
    "ex": "10問中9問正解しました。",
    "exReading": "じゅうもんちゅう きゅうもん せいかい しました",
    "exTranslation": "Nine out of ten questions were answered correctly.",
    "category": "Verbal Noun"
  },
  {
    "term": "正方形",
    "termReading": "せいほうけい",
    "termTranslation": "square",
    "ex": "正方形の紙を用意しましょう。",
    "exReading": "せいほうけい の かみ を ようい しましょう",
    "exTranslation": "Prepare a square of paper.",
    "category": "Noun"
  },
  {
    "term": "正",
    "termReading": "せい",
    "termTranslation": "right, positiveness",
    "ex": "書類は正と副の2通あります。",
    "exReading": "しょるい は せい と ふく の につう あります",
    "exTranslation": "The document consists of the original and a copy.",
    "category": "Noun"
  },
  {
    "term": "ジャーナリズム",
    "termReading": "ジャーナリズム",
    "termTranslation": "journalism",
    "ex": "彼はジャーナリズムを専攻している。",
    "exReading": "かれ は じゃーなりずむ を せんこう して いる",
    "exTranslation": "He majors in journalism.",
    "category": "Noun"
  },
  {
    "term": "正座",
    "termReading": "せいざ",
    "termTranslation": "sitting upright with legs folded under one",
    "ex": "彼はきちんと正座して待っていたね。",
    "exReading": "かれ は きちんと せいざ して まって いた ね",
    "exTranslation": "He waited while sitting properly on the floor, Japanese style.",
    "category": "Verbal Noun"
  },
  {
    "term": "正当",
    "termReading": "せいとう",
    "termTranslation": "just, legal",
    "ex": "これは正当な要求です。",
    "exReading": "これ は せいとう な ようきゅう です",
    "exTranslation": "This is a fair claim.",
    "category": "Adjectival Noun"
  },
  {
    "term": "異性",
    "termReading": "いせい",
    "termTranslation": "opposite sex",
    "ex": "息子はもう異性を意識している。",
    "exReading": "むすこ は もう いせい を いしき して いる",
    "exTranslation": "My son is already aware of the opposite sex.",
    "category": "Noun"
  },
  {
    "term": "異常",
    "termReading": "いじょう",
    "termTranslation": "extraordinary, exceptional",
    "ex": "今年の夏は異常な暑さですね。",
    "exReading": "ことし の なつ は いじょう な あつさ です ね",
    "exTranslation": "This summer is exceptionally hot.",
    "category": "Adjectival Noun"
  },
  {
    "term": "正常",
    "termReading": "せいじょう",
    "termTranslation": "normality, normalcy",
    "ex": "患者の呼吸は正常です。",
    "exReading": "かんじゃ の こきゅう は せいじょう です",
    "exTranslation": "The patient's breathing is normal.",
    "category": "Adjectival Noun"
  },
  {
    "term": "意識",
    "termReading": "いしき",
    "termTranslation": "consciousness, awareness",
    "ex": "彼は意識を失いました。",
    "exReading": "かれ は いしき を うしないました",
    "exTranslation": "He lost consciousness.",
    "category": "Verbal Noun"
  },
  {
    "term": "常識",
    "termReading": "じょうしき",
    "termTranslation": "common sense, common knowledge",
    "ex": "そんなの常識だよ。",
    "exReading": "そんな の じょうしき だ よ",
    "exTranslation": "That is common sense.",
    "category": "Noun"
  },
  {
    "term": "スピーチ",
    "termReading": "スピーチ",
    "termTranslation": "speech",
    "ex": "彼のスピーチは素晴らしかった。",
    "exReading": "かれ の すぴーち は すばらしかった 。",
    "exTranslation": "His speech was excellent.",
    "category": "Noun"
  },
  {
    "term": "調べ",
    "termReading": "しらべ",
    "termTranslation": "investigation, inquiry",
    "ex": "警察の調べで女性の身元がわかりました。",
    "exReading": "けいさつ の しらべ で じょせい の みもと が わかりました",
    "exTranslation": "An investigation by the police uncovered the woman's identity.",
    "category": "Noun"
  },
  {
    "term": "好調",
    "termReading": "こうちょう",
    "termTranslation": "favorable condition",
    "ex": "今月はエアコンの売り上げが好調です。",
    "exReading": "こんげつ は えあこん の うりあげ が こうちょう です",
    "exTranslation": "Sales of air conditioners are good this month.",
    "category": "Adjectival Noun"
  },
  {
    "term": "下調べ",
    "termReading": "したしらべ",
    "termTranslation": "preliminary investigation, preparation",
    "ex": "まず第一に、しっかり下調べをしなさい。",
    "exReading": "まず だいいち に しっかり したしらべ を しなさい",
    "exTranslation": "First of all, be sure to make a careful preliminary investigation.",
    "category": "Verbal Noun"
  },
  {
    "term": "整備",
    "termReading": "せいび",
    "termTranslation": "maintenance, servicing",
    "ex": "車は整備に出しています。",
    "exReading": "くるま は せいび に だして います",
    "exTranslation": "I've put my car in for maintenance.",
    "category": "Verbal Noun"
  },
  {
    "term": "整理",
    "termReading": "せいり",
    "termTranslation": "disposition, arrangement",
    "ex": "古い服を整理しました。",
    "exReading": "ふるい ふく を せいり しました",
    "exTranslation": "I sorted out the old clothes.",
    "category": "Verbal Noun"
  },
  {
    "term": "節約",
    "termReading": "せつやく",
    "termTranslation": "economizing, saving",
    "ex": "電気や水を節約しましょう。",
    "exReading": "でんき や みず を せつやく しましょう",
    "exTranslation": "Let's save electricity and water.",
    "category": "Verbal Noun"
  },
  {
    "term": "検査",
    "termReading": "けんさ",
    "termTranslation": "inspection, examination",
    "ex": "私は今日、目の検査を受けます。",
    "exReading": "わたし は きょう め の けんさ を うけます",
    "exTranslation": "I will have an eye examination today.",
    "category": "Verbal Noun"
  },
  {
    "term": "ああ",
    "termReading": "ああ",
    "termTranslation": "that kind of",
    "ex": "ああうるさい人は苦手です。",
    "exReading": "ああ うるさい ひと は にがて です",
    "exTranslation": "I don't like loud people like that.",
    "category": "Adverb"
  },
  {
    "term": "案",
    "termReading": "あん",
    "termTranslation": "plan, proposal",
    "ex": "もっと案を出し合いましょう。",
    "exReading": "もっと あん を だしあいましょう",
    "exTranslation": "Let's share more ideas.",
    "category": "Noun"
  },
  {
    "term": "案外",
    "termReading": "あんがい",
    "termTranslation": "unexpectedly",
    "ex": "彼は案外いい人かも知れない。",
    "exReading": "かれ は あんがい いい ひと かも しれない",
    "exTranslation": "He may unexpectedly turn out to be a good person.",
    "category": "Adverb"
  },
  {
    "term": "案の定",
    "termReading": "あんのじょう",
    "termTranslation": "as feared, sure enough",
    "ex": "案の定、彼は遅刻したな。",
    "exReading": "あんのじょう かれ は ちこく した な",
    "exTranslation": "Just as I thought, he was late.",
    "category": "Adverb"
  },
  {
    "term": "国連",
    "termReading": "こくれん",
    "termTranslation": "United Nations",
    "ex": "国連の本部はニューヨークにあります。",
    "exReading": "こくれん の ほんぶ は にゅーよーく に あります",
    "exTranslation": "The headquarters of the United Nations is in New York.",
    "category": "Noun"
  },
  {
    "term": "接続",
    "termReading": "せつぞく",
    "termTranslation": "connection, joining",
    "ex": "コンピューターをネットワークに接続しました。",
    "exReading": "こんぴゅーたー を ねっとわーく に せつぞく しました",
    "exTranslation": "The computer was connected with the network.",
    "category": "Verbal Noun"
  },
  {
    "term": "外相",
    "termReading": "がいしょう",
    "termTranslation": "Minister of Foreign Affairs",
    "ex": "外相は来週訪米の予定です。",
    "exReading": "がいしょう は らいしゅう ほうべい の よてい です",
    "exTranslation": "The Foreign Minister is scheduled to visit the United States next week.",
    "category": "Noun"
  },
  {
    "term": "真相",
    "termReading": "しんそう",
    "termTranslation": "the truth",
    "ex": "最近、事件の真相が明らかになったよ。",
    "exReading": "さいきん じけん の しんそう が あきらか に なった よ",
    "exTranslation": "Recently the truth of the case has been revealed.",
    "category": "Noun"
  },
  {
    "term": "相変わらず",
    "termReading": "あいかわらず",
    "termTranslation": "as usual, as before",
    "ex": "彼は相変わらず忙しいですね。",
    "exReading": "かれ は あいかわらず いそがしい です ね",
    "exTranslation": "He is busy as usual.",
    "category": "Adverb"
  },
  {
    "term": "アクセント",
    "termReading": "アクセント",
    "termTranslation": "accent, stress",
    "ex": "アメリカ英語とイギリス英語ではアクセントが違うことがあるね。",
    "exReading": "あめりか えいご と いぎりす えいご で は あくせんと が ちがう こと が ある ね",
    "exTranslation": "There can be a difference in stress between American and British English.",
    "category": "Noun"
  },
  {
    "term": "雑談",
    "termReading": "ざつだん",
    "termTranslation": "chat, idle talk",
    "ex": "その先生はいつも授業の前に雑談をするの。",
    "exReading": "その せんせい は いつも じゅぎょう の まえ に ざつだん を する の",
    "exTranslation": "The teacher always chats before the class.",
    "category": "Verbal Noun"
  },
  {
    "term": "記事",
    "termReading": "きじ",
    "termTranslation": "article, story",
    "ex": "環境問題に関する記事を読んだの。",
    "exReading": "かんきょう もんだい に かんする きじ を よんだ の",
    "exTranslation": "I read an article on environmental issues.",
    "category": "Noun"
  },
  {
    "term": "記号",
    "termReading": "きごう",
    "termTranslation": "symbol, mark",
    "ex": "地図にはいろいろな記号が使われているのね。",
    "exReading": "ちず に は いろいろ な きごう が つかわれて いる の ね",
    "exTranslation": "A variety of symbols are used on maps.",
    "category": "Noun"
  },
  {
    "term": "記入",
    "termReading": "きにゅう",
    "termTranslation": "entry, record",
    "ex": "こちらにお名前をご記入ください。",
    "exReading": "こちら に おなまえ を ご きにゅう ください",
    "exTranslation": "Please fill in your name here.",
    "category": "Verbal Noun"
  },
  {
    "term": "暗記",
    "termReading": "あんき",
    "termTranslation": "memorization",
    "ex": "試験の前に英文を暗記したんだ。",
    "exReading": "しけん の まえ に えいぶん を あんき した ん だ",
    "exTranslation": "I memorized the English text before the examination.",
    "category": "Verbal Noun"
  },
  {
    "term": "記憶",
    "termReading": "きおく",
    "termTranslation": "memory, recollection",
    "ex": "当時のことはしっかり記憶しているよ。",
    "exReading": "とうじ の こと は しっかり きおく して いる よ",
    "exTranslation": "I clearly remember what happened at that time.",
    "category": "Verbal Noun"
  },
  {
    "term": "関心",
    "termReading": "かんしん",
    "termTranslation": "concern, interest",
    "ex": "彼は政治に関心が強いね。",
    "exReading": "かれ は せいじ に かんしん が つよい ね",
    "exTranslation": "He has a strong interest in politics.",
    "category": "Noun"
  },
  {
    "term": "コマーシャル",
    "termReading": "コマーシャル",
    "termTranslation": "commercial, advertising",
    "ex": "彼女はテレビのコマーシャルに出ているわ。",
    "exReading": "かのじょ は てれび の こまーしゃる に でて いる わ",
    "exTranslation": "She is appearing in a TV commercial.",
    "category": "Noun"
  },
  {
    "term": "税関",
    "termReading": "ぜいかん",
    "termTranslation": "custom house, customs",
    "ex": "毛皮を税関で没収されたんだ。",
    "exReading": "けがわ を ぜいかん で ぼっしゅう された ん だ",
    "exTranslation": "The fur was seized at customs.",
    "category": "Noun"
  },
  {
    "term": "関節",
    "termReading": "かんせつ",
    "termTranslation": "joint",
    "ex": "手首の関節をひねっちゃった。",
    "exReading": "てくび の かんせつ を ひねっちゃった",
    "exTranslation": "I have twisted my wrist joint.",
    "category": "Noun"
  },
  {
    "term": "関わる",
    "termReading": "かかわる",
    "termTranslation": "involve, concern",
    "ex": "医師は人の命に関わる大切な職業だよ。",
    "exReading": "いし は ひと の いのち に かかわる たいせつ な しょくぎょう だ よ",
    "exTranslation": "A doctor's job is very important, as people's lives depend on it.",
    "category": "Verb"
  },
  {
    "term": "機関",
    "termReading": "きかん",
    "termTranslation": "engine, agency",
    "ex": "台風で交通機関がストップしている。",
    "exReading": "たいふう で こうつう きかん が すとっぷ して いる",
    "exTranslation": "Transportation has stopped because of the typhoon.",
    "category": "Noun"
  },
  {
    "term": "係",
    "termReading": "かかり",
    "termTranslation": "person in charge",
    "ex": "彼女は会場整理の係だったの。",
    "exReading": "かのじょ は かいじょう せいり の かかり だった の",
    "exTranslation": "She was in charge of the hall arrangement.",
    "category": "Noun"
  },
  {
    "term": "現状",
    "termReading": "げんじょう",
    "termTranslation": "present condition",
    "ex": "問題を解決できないのが現状です。",
    "exReading": "もんだい を かいけつ できない の が げんじょう です",
    "exTranslation": "The current situation is that we can't solve the problem.",
    "category": "Noun"
  },
  {
    "term": "事態",
    "termReading": "じたい",
    "termTranslation": "situation, state of affairs",
    "ex": "事態は深刻です。",
    "exReading": "じたい は しんこく です",
    "exTranslation": "The situation is serious.",
    "category": "Noun"
  },
  {
    "term": "しつこい",
    "termReading": "しつこい",
    "termTranslation": "persistent",
    "ex": "しつこい迷惑メールに困っている。",
    "exReading": "しつこい めいわく めーる に こまって いる",
    "exTranslation": "I am having trouble with persistent junk mail.",
    "category": "Adjective"
  },
  {
    "term": "実態",
    "termReading": "じったい",
    "termTranslation": "the actual situation, the realities",
    "ex": "その会社の経営の実態を調査中だ。",
    "exReading": "その かいしゃ の けいえい の じったい を ちょうさちゅう だ",
    "exTranslation": "The state of that company's management is being investigated.",
    "category": "Noun"
  },
  {
    "term": "行政",
    "termReading": "ぎょうせい",
    "termTranslation": "administration",
    "ex": "年金問題は行政の最大の課題のひとつよ。",
    "exReading": "ねんきん もんだい は ぎょうせい の さいだい の かだい の ひとつ よ",
    "exTranslation": "The pension problem is one of the current administration's biggest issues.",
    "category": "Noun"
  },
  {
    "term": "政治家",
    "termReading": "せいじか",
    "termTranslation": "politician",
    "ex": "大きくなったら政治家になりたいです。",
    "exReading": "おおきく なったら せいじか に なりたい です",
    "exTranslation": "I want to become a politician when I grow up.",
    "category": "Noun"
  },
  {
    "term": "治まる",
    "termReading": "おさまる",
    "termTranslation": "settle down, be cured",
    "ex": "咳が少し治まりました。",
    "exReading": "せき が すこし おさまりました",
    "exTranslation": "My cough is a little better.",
    "category": "Verb"
  },
  {
    "term": "政党",
    "termReading": "せいとう",
    "termTranslation": "political party",
    "ex": "選挙では3つの政党が争っています。",
    "exReading": "せんきょ で は みっつ の せいとう が あらそって います",
    "exTranslation": "Three political parties are competing in the election.",
    "category": "Noun"
  },
  {
    "term": "策",
    "termReading": "さく",
    "termTranslation": "scheme, policy",
    "ex": "その問題に対する策を皆で考えたの。",
    "exReading": "その もんだい に たいする さく を みんな で かんがえた の",
    "exTranslation": "We all thought about measures to address the problem.",
    "category": "Noun"
  },
  {
    "term": "挙げる",
    "termReading": "あげる",
    "termTranslation": "cite, give (an example)",
    "ex": "例を幾つか挙げてみましょう。",
    "exReading": "れい を いくつ か あげて みましょう",
    "exTranslation": "Let's give some examples.",
    "category": "Verb"
  },
  {
    "term": "気候",
    "termReading": "きこう",
    "termTranslation": "climate",
    "ex": "日本の気候は温暖です。",
    "exReading": "にほん の きこう は おんだん です",
    "exTranslation": "The climate in Japan is temperate.",
    "category": "Noun"
  },
  {
    "term": "しゃがむ",
    "termReading": "しゃがむ",
    "termTranslation": "squat down",
    "ex": "お年寄りが道端にしゃがんでいますね。",
    "exReading": "おとしより が みちばた に しゃがんで います ね",
    "exTranslation": "An old person is squatting down on the side of the road.",
    "category": "Verb"
  },
  {
    "term": "補う",
    "termReading": "おぎなう",
    "termTranslation": "supplement, replenish",
    "ex": "夏は水分を十分に補いましょう。",
    "exReading": "なつ は すいぶん を じゅうぶん に おぎないましょう",
    "exTranslation": "Make sure you take enough liquids in the summer.",
    "category": "Verb"
  },
  {
    "term": "足首",
    "termReading": "あしくび",
    "termTranslation": "ankle",
    "ex": "彼は足首を痛めています。",
    "exReading": "かれ は あしくび を いためて います",
    "exTranslation": "He has hurt his ankle.",
    "category": "Noun"
  },
  {
    "term": "首脳",
    "termReading": "しゅのう",
    "termTranslation": "head, leader",
    "ex": "東京で五カ国の首脳会談が開かれています。",
    "exReading": "とうきょう で ごかこく の しゅのうかいだん が ひらかれて います",
    "exTranslation": "A five-country summit is being held in Tokyo.",
    "category": "Noun"
  },
  {
    "term": "頭",
    "termReading": "かしら",
    "termTranslation": "head, chief",
    "ex": "彼は一家のお頭だったの。",
    "exReading": "かれ は いっか の おかしら だった の",
    "exTranslation": "He was the head of the family.",
    "category": "Noun"
  },
  {
    "term": "頭痛",
    "termReading": "ずつう",
    "termTranslation": "headache",
    "ex": "今日は頭痛がします。",
    "exReading": "きょう は ずつう が します",
    "exTranslation": "I have a headache today.",
    "category": "Noun"
  },
  {
    "term": "顔色",
    "termReading": "かおいろ",
    "termTranslation": "complexion, countenance",
    "ex": "彼は顔色がよくありませんね。",
    "exReading": "かれ は かおいろ が よく ありません ね 。",
    "exTranslation": "His face looks pale.",
    "category": "Noun"
  },
  {
    "term": "朝顔",
    "termReading": "あさがお",
    "termTranslation": "morning glory",
    "ex": "紫の朝顔が咲きました。",
    "exReading": "むらさき の あさがお が さきました",
    "exTranslation": "A purple morning-glory bloomed.",
    "category": "Noun"
  },
  {
    "term": "コンテスト",
    "termReading": "コンテスト",
    "termTranslation": "contest",
    "ex": "このコンテストに優勝すると車がもらえるんだ。",
    "exReading": "この こんてすと に ゆうしょう する と くるま が もらえる ん だ",
    "exTranslation": "If you win this contest you can get a car.",
    "category": "Noun"
  },
  {
    "term": "改正",
    "termReading": "かいせい",
    "termTranslation": "revision, amendment",
    "ex": "近く交通法が改正されます。",
    "exReading": "ちかく こうつう ほう が かいせい されます",
    "exTranslation": "Traffic regulations will soon be revised.",
    "category": "Verbal Noun"
  },
  {
    "term": "改良",
    "termReading": "かいりょう",
    "termTranslation": "improvement, reform",
    "ex": "日本では絶えず米の品種を改良しているの。",
    "exReading": "にほん で は たえず こめ の ひんしゅ を かいりょう して いる の",
    "exTranslation": "Varieties of rice are always being improved in Japan.",
    "category": "Verbal Noun"
  },
  {
    "term": "改める",
    "termReading": "あらためる",
    "termTranslation": "reform, change",
    "ex": "彼は悪い習慣を改めようとしているわね。",
    "exReading": "かれ は わるい しゅうかん を あらためよう と して いる わ ね",
    "exTranslation": "He's trying to change his bad habits.",
    "category": "Verb"
  },
  {
    "term": "改造",
    "termReading": "かいぞう",
    "termTranslation": "remodeling, reorganization",
    "ex": "首相は内閣の改造を行いました。",
    "exReading": "しゅしょう は ないかく の かいぞう を おこないました",
    "exTranslation": "The Prime Minister reshuffled the Cabinet.",
    "category": "Verbal Noun"
  },
  {
    "term": "改めて",
    "termReading": "あらためて",
    "termTranslation": "once again",
    "ex": "改めてあなたのご意見を聞かせて下さい。",
    "exReading": "あらためて あなた の ごいけん を きかせ て ください",
    "exTranslation": "Please let me hear your opinion again.",
    "category": "Adverb"
  },
  {
    "term": "改まる",
    "termReading": "あらたまる",
    "termTranslation": "be renewed, change",
    "ex": "年号が改まりました。",
    "exReading": "ねんごう が あらたまりました",
    "exTranslation": "The name of the era has changed.",
    "category": "Verb"
  },
  {
    "term": "革命",
    "termReading": "かくめい",
    "termTranslation": "revolution",
    "ex": "それは歴史上の大きな革命です。",
    "exReading": "それ は れきしじょう の おおき な かくめい です",
    "exTranslation": "That is a major revolution in history.",
    "category": "Noun"
  },
  {
    "term": "キャベツ",
    "termReading": "キャベツ",
    "termTranslation": "cabbage",
    "ex": "キャベツの千切りを添えたよ。",
    "exReading": "きゃべつ の せんぎり を そえた よ",
    "exTranslation": "I garnished it with shredded cabbage.",
    "category": "Noun"
  },
  {
    "term": "生命",
    "termReading": "せいめい",
    "termTranslation": "life",
    "ex": "生命は海から始まったと言われている。",
    "exReading": "せいめい は うみ から はじまった と いわれて いる",
    "exTranslation": "It is said that life started in the sea.",
    "category": "Noun"
  },
  {
    "term": "命",
    "termReading": "いのち",
    "termTranslation": "life",
    "ex": "命より大切なものは無いよ。",
    "exReading": "いのち より たいせつ な もの は ない よ",
    "exTranslation": "There is nothing more important than life.",
    "category": "Noun"
  },
  {
    "term": "運命",
    "termReading": "うんめい",
    "termTranslation": "fate, fortune",
    "ex": "運命には逆らえないよ。",
    "exReading": "うんめい に は さからえない よ",
    "exTranslation": "You can't go against fate.",
    "category": "Noun"
  },
  {
    "term": "組合",
    "termReading": "くみあい",
    "termTranslation": "union, guild",
    "ex": "今日は組合の集まりがあるわ。",
    "exReading": "きょう は くみあい の あつまり が ある わ",
    "exTranslation": "There is a union meeting today.",
    "category": "Noun"
  },
  {
    "term": "仕組み",
    "termReading": "しくみ",
    "termTranslation": "construction, arrangement",
    "ex": "この機械の仕組みは複雑だ。",
    "exReading": "この きかい の しくみ は ふくざつ だ",
    "exTranslation": "The mechanism of this machine is complex.",
    "category": "Noun"
  },
  {
    "term": "組",
    "termReading": "くみ",
    "termTranslation": "school class",
    "ex": "彼は1年2組の生徒です。",
    "exReading": "かれ は いちねん にくみ の せいと です",
    "exTranslation": "He's a student in first grade, second class.",
    "category": "Noun"
  },
  {
    "term": "組む",
    "termReading": "くむ",
    "termTranslation": "pair up, partner with",
    "ex": "このプロジェクトで私は彼と組んでいるんだ。",
    "exReading": "この ぷろじぇくと で わたし は かれ と くんで いる ん だ",
    "exTranslation": "I am pairing up with him for this project.",
    "category": "Verb"
  },
  {
    "term": "組み合わせる",
    "termReading": "くみあわせる",
    "termTranslation": "assort, join together",
    "ex": "いろいろな花を組み合わせ花束を作りました。",
    "exReading": "いろいろ な はな を くみあわせ はなたば を つくりました",
    "exTranslation": "I put many kinds of flowers together to make a bouquet.",
    "category": "Verb"
  },
  {
    "term": "アイドル",
    "termReading": "アイドル",
    "termTranslation": "idol, pop singer",
    "ex": "彼女は若者のアイドルです。",
    "exReading": "かのじょ は わかもの の あいどる です",
    "exTranslation": "She is a youth icon.",
    "category": "Noun"
  },
  {
    "term": "組み込む",
    "termReading": "くみこむ",
    "termTranslation": "incorporate, integrate",
    "ex": "キャンペーンに新しいイベントを組み込みました。",
    "exReading": "きゃんぺーん に あたらしい いべんと を くみこみました",
    "exTranslation": "We introduced a new event into the campaign.",
    "category": "Verb"
  },
  {
    "term": "組み合わせ",
    "termReading": "くみあわせ",
    "termTranslation": "combination, assortment",
    "ex": "色の組み合わせで印象が変わりますよ。",
    "exReading": "いろ の くみあわせ で いんしょう が かわります よ",
    "exTranslation": "The image changes according to the combination of colors.",
    "category": "Noun"
  },
  {
    "term": "織物",
    "termReading": "おりもの",
    "termTranslation": "cloth, textile",
    "ex": "その町は織物業で有名です。",
    "exReading": "その まち は おりものぎょう で ゆうめい です",
    "exTranslation": "The town is famous for the textile industry.",
    "category": "Noun"
  },
  {
    "term": "進出",
    "termReading": "しんしゅつ",
    "termTranslation": "advancement",
    "ex": "大手チェーン店が進出している。",
    "exReading": "おおて ちぇーんてん が しんしゅつ して いる",
    "exTranslation": "That major store chain is expanding.",
    "category": "Verbal Noun"
  },
  {
    "term": "進行",
    "termReading": "しんこう",
    "termTranslation": "advancement",
    "ex": "学会は予定通りに進行しています。",
    "exReading": "がっかい は よてい どおり に しんこう して います",
    "exTranslation": "The academic conference is proceeding as scheduled.",
    "category": "Verbal Noun"
  },
  {
    "term": "進歩",
    "termReading": "しんぽ",
    "termTranslation": "progress, advancement",
    "ex": "科学技術は目覚しく進歩しているの。",
    "exReading": "かがく ぎじゅつ は めざましく しんぽ して いる の",
    "exTranslation": "Science and technology have advanced remarkably.",
    "category": "Verbal Noun"
  },
  {
    "term": "前進",
    "termReading": "ぜんしん",
    "termTranslation": "advancement",
    "ex": "一列に並んで前進して下さい。",
    "exReading": "いちれつ に ならんで ぜんしん して ください",
    "exTranslation": "Please form a line and proceed forwards.",
    "category": "Verbal Noun"
  },
  {
    "term": "からかう",
    "termReading": "からかう",
    "termTranslation": "make fun of, tease",
    "ex": "彼は時々妹をからかいます。",
    "exReading": "かれ は ときどき いもうと を からかいます",
    "exTranslation": "He sometimes makes fun of his younger sister.",
    "category": "Verb"
  },
  {
    "term": "進路",
    "termReading": "しんろ",
    "termTranslation": "course, route",
    "ex": "卒業後の進路を迷っています。",
    "exReading": "そつぎょう ご の しんろ を まよって います",
    "exTranslation": "I'm having trouble deciding what path I'll take after graduation.",
    "category": "Noun"
  },
  {
    "term": "行進",
    "termReading": "こうしん",
    "termTranslation": "march, parade",
    "ex": "開会式で選手たちが行進しました。",
    "exReading": "かいかいしき で せんしゅたち が こうしん しました",
    "exTranslation": "Players paraded at the opening ceremony.",
    "category": "Verbal Noun"
  },
  {
    "term": "推進",
    "termReading": "すいしん",
    "termTranslation": "promotion",
    "ex": "その会社はリサイクルを推進しているね。",
    "exReading": "その かいしゃ は りさいくる を すいしん して いる ね",
    "exTranslation": "The company is promoting recycling.",
    "category": "Verbal Noun"
  },
  {
    "term": "主任",
    "termReading": "しゅにん",
    "termTranslation": "person in charge, head",
    "ex": "彼女は会計主任です。",
    "exReading": "かのじょ は かいけい しゅにん です",
    "exTranslation": "She is an account manager.",
    "category": "Noun"
  },
  {
    "term": "辞任",
    "termReading": "じにん",
    "termTranslation": "resignation",
    "ex": "社長の辞任が決まりました。",
    "exReading": "しゃちょう の じにん が きまりました",
    "exTranslation": "The president's resignation has been agreed upon.",
    "category": "Verbal Noun"
  },
  {
    "term": "お世辞",
    "termReading": "おせじ",
    "termTranslation": "compliment, flattery",
    "ex": "彼はお世辞を言うのが上手い。",
    "exReading": "かれ は おせじ を いう の が うまい",
    "exTranslation": "He is good at flattery.",
    "category": "Noun"
  },
  {
    "term": "委員会",
    "termReading": "いいんかい",
    "termTranslation": "committee",
    "ex": "明日、委員会が開かれます。",
    "exReading": "あした いいんかい が ひらかれます",
    "exTranslation": "The committee meeting will be held tomorrow.",
    "category": "Noun"
  },
  {
    "term": "すっきり",
    "termReading": "すっきり",
    "termTranslation": "refreshed",
    "ex": "よく眠ったら気分がすっきりした。",
    "exReading": "よく ねむったら きぶん が すっきり した",
    "exTranslation": "I felt much better after a good sleep.",
    "category": "Adverb"
  },
  {
    "term": "委員",
    "termReading": "いいん",
    "termTranslation": "committee member, delegate",
    "ex": "彼は委員に選ばれました。",
    "exReading": "かれ は いいん に えらばれました",
    "exTranslation": "He was elected to the committee.",
    "category": "Noun"
  },
  {
    "term": "出勤",
    "termReading": "しゅっきん",
    "termTranslation": "to go to work, attendance at work",
    "ex": "毎朝7時5分に出勤します。",
    "exReading": "まいあさ しち じ ご ふん に しゅっきん します",
    "exTranslation": "I go to work at 7:05 every morning.",
    "category": "Verbal Noun"
  },
  {
    "term": "勤勉",
    "termReading": "きんべん",
    "termTranslation": "diligence, hard work",
    "ex": "彼は勤勉な人です。",
    "exReading": "かれ は きんべん な ひと です",
    "exTranslation": "He is a diligent person.",
    "category": "Adjectival Noun"
  },
  {
    "term": "勤務",
    "termReading": "きんむ",
    "termTranslation": "duty, service",
    "ex": "私の一日の勤務時間は8時間です。",
    "exReading": "わたし の いちにち の きんむじかん は はちじかん です",
    "exTranslation": "I work 8 hours a day.",
    "category": "Verbal Noun"
  },
  {
    "term": "義務",
    "termReading": "ぎむ",
    "termTranslation": "duty, obligation",
    "ex": "労働は国民の義務です。",
    "exReading": "ろうどう は こくみん の ぎむ です",
    "exTranslation": "It's a citizen's duty to work.",
    "category": "Noun"
  },
  {
    "term": "事務",
    "termReading": "じむ",
    "termTranslation": "office duty, clerical work",
    "ex": "事務の経験が3年あります。",
    "exReading": "じむ の けいけん が さんねん あります",
    "exTranslation": "I have three years experience in clerical work.",
    "category": "Noun"
  },
  {
    "term": "公務員",
    "termReading": "こうむいん",
    "termTranslation": "public officer, government worker",
    "ex": "私の父は公務員です。",
    "exReading": "わたし の ちち は こうむいん です",
    "exTranslation": "My father is a civil servant.",
    "category": "Noun"
  },
  {
    "term": "あいにく",
    "termReading": "あいにく",
    "termTranslation": "unfortunately",
    "ex": "途中であいにく雨が降り出したの。",
    "exReading": "とちゅう で あいにく あめ が ふりだした の",
    "exTranslation": "Unfortunately, it began to rain on the way.",
    "category": "Adverb"
  },
  {
    "term": "事務員",
    "termReading": "じむいん",
    "termTranslation": "clerk, clerical staff",
    "ex": "新しい事務員が入りました。",
    "exReading": "あたらしい じむいん が はいりました",
    "exTranslation": "A new clerk joined the office.",
    "category": "Noun"
  },
  {
    "term": "従う",
    "termReading": "したがう",
    "termTranslation": "follow, obey",
    "ex": "上司の指示に従った。",
    "exReading": "じょうし の しじ に したがった",
    "exTranslation": "I followed my boss' instructions.",
    "category": "Verb"
  },
  {
    "term": "従業員",
    "termReading": "じゅうぎょういん",
    "termTranslation": "employee, worker",
    "ex": "会社は従業員の数を増やす予定だ。",
    "exReading": "かいしゃ は じゅうぎょういん の かず を ふやす よてい だ",
    "exTranslation": "The company plans to increase the number of employees.",
    "category": "Noun"
  },
  {
    "term": "雇用",
    "termReading": "こよう",
    "termTranslation": "employment",
    "ex": "彼はその会社と雇用契約を結んだの。",
    "exReading": "かれ は その かいしゃ と こよう けいやく を むすんだ の",
    "exTranslation": "He signed a contract of employment with the company.",
    "category": "Verbal Noun"
  },
  {
    "term": "実績",
    "termReading": "じっせき",
    "termTranslation": "achievement, performance",
    "ex": "彼は営業で実績を上げたんだ。",
    "exReading": "かれ は えいぎょう で じっせき を あげた ん だ",
    "exTranslation": "He achieved good results working in sales.",
    "category": "Noun"
  },
  {
    "term": "業績",
    "termReading": "ぎょうせき",
    "termTranslation": "achievement, performance",
    "ex": "彼の今月の業績は素晴らしいです。",
    "exReading": "かれ の こんげつ の ぎょうせき は すばらしい です",
    "exTranslation": "His performance this month was amazing.",
    "category": "Noun"
  },
  {
    "term": "応募",
    "termReading": "おうぼ",
    "termTranslation": "application",
    "ex": "求人に多数の応募があった。",
    "exReading": "きゅうじん に たすう の おうぼ が あった",
    "exTranslation": "There were a lot of applications for the job opening.",
    "category": "Verbal Noun"
  },
  {
    "term": "集中",
    "termReading": "しゅうちゅう",
    "termTranslation": "concentration, convergence",
    "ex": "勉強に集中しなさい。",
    "exReading": "べんきょう に しゅうちゅう しなさい",
    "exTranslation": "Concentrate on studying.",
    "category": "Verbal Noun"
  },
  {
    "term": "おばさん",
    "termReading": "おばさん",
    "termTranslation": "middle-aged woman",
    "ex": "おばさん、こんにちは。",
    "exReading": "おばさん、こんにちは。",
    "exTranslation": "Good afternoon (addressed to a familiar middle-aged woman).",
    "category": "Noun"
  },
  {
    "term": "集合",
    "termReading": "しゅうごう",
    "termTranslation": "gathering, assembly",
    "ex": "7時5分に駅で集合しましょう。",
    "exReading": "しち じ ご ふん に えき で しゅうごう しましょう",
    "exTranslation": "Let's meet at the station at 7:05",
    "category": "Verbal Noun"
  },
  {
    "term": "集まり",
    "termReading": "あつまり",
    "termTranslation": "gathering, assembly",
    "ex": "雨で集まりが悪いですね。",
    "exReading": "あめ で あつまり が わるい です ね",
    "exTranslation": "Not many people are showing up because of the rain.",
    "category": "Noun"
  },
  {
    "term": "収集",
    "termReading": "しゅうしゅう",
    "termTranslation": "collection, gathering",
    "ex": "彼の趣味は切手収集です。",
    "exReading": "かれ の しゅみ は きって しゅうしゅう です",
    "exTranslation": "His hobby is collecting stamps.",
    "category": "Verbal Noun"
  },
  {
    "term": "採算",
    "termReading": "さいさん",
    "termTranslation": "profit",
    "ex": "コストがこんなに高くては採算が取れません。",
    "exReading": "こすと が こんなに たかくて は さいさん が とれません",
    "exTranslation": "When the costs are so high, profit can't be made.",
    "category": "Noun"
  },
  {
    "term": "採点",
    "termReading": "さいてん",
    "termTranslation": "marking, grading",
    "ex": "先生は試験の採点が終わったようね。",
    "exReading": "せんせい は しけん の さいてん が おわった よう ね",
    "exTranslation": "It seems that the teacher has finished marking the examination.",
    "category": "Verbal Noun"
  },
  {
    "term": "供給",
    "termReading": "きょうきゅう",
    "termTranslation": "supply, provision",
    "ex": "彼の会社は電力を供給しています。",
    "exReading": "かれ の かいしゃ は でんりょく を きょうきゅう して います",
    "exTranslation": "His company supplies electricity.",
    "category": "Verbal Noun"
  },
  {
    "term": "月給",
    "termReading": "げっきゅう",
    "termTranslation": "monthly salary",
    "ex": "月給は毎月25日に支給されます。",
    "exReading": "げっきゅう は まいつき にじゅうごにち に しきゅう されます",
    "exTranslation": "The salary is paid on the 25th of every month.",
    "category": "Noun"
  },
  {
    "term": "ジャンプ",
    "termReading": "ジャンプ",
    "termTranslation": "jump",
    "ex": "猿が高い木にジャンプした。",
    "exReading": "さる が たかい き に じゃんぷ した",
    "exTranslation": "The monkey jumped into the tall tree.",
    "category": "Verbal Noun"
  },
  {
    "term": "時給",
    "termReading": "じきゅう",
    "termTranslation": "hourly wage",
    "ex": "この仕事は時給1000円です。",
    "exReading": "この しごと は じきゅう せんえん です",
    "exTranslation": "This work pays 1000 yen per hour.",
    "category": "Noun"
  },
  {
    "term": "需要",
    "termReading": "じゅよう",
    "termTranslation": "demand",
    "ex": "需要が多過ぎて生産が追い付きません。",
    "exReading": "じゅよう が おおすぎ て せいさん が おいつきません",
    "exTranslation": "There's a lot of demand and production can't keep up.",
    "category": "Noun"
  },
  {
    "term": "就任",
    "termReading": "しゅうにん",
    "termTranslation": "inauguration, appointment",
    "ex": "彼は新首相に就任しましたね。",
    "exReading": "かれ は しんしゅしょう に しゅうにん しました ね",
    "exTranslation": "He assumed his post as the new Prime Minister.",
    "category": "Verbal Noun"
  },
  {
    "term": "職員",
    "termReading": "しょくいん",
    "termTranslation": "staff, employee",
    "ex": "ここは職員専用の出入り口です。",
    "exReading": "ここ は しょくいん せんよう の でいりぐち です",
    "exTranslation": "This is an entrance for staff only.",
    "category": "Noun"
  },
  {
    "term": "職場",
    "termReading": "しょくば",
    "termTranslation": "place of work, office",
    "ex": "自宅から職場まで1時間かかります。",
    "exReading": "じたく から しょくば まで いちじかん かかります",
    "exTranslation": "It takes one hour from home to the office.",
    "category": "Noun"
  },
  {
    "term": "職業",
    "termReading": "しょくぎょう",
    "termTranslation": "occupation, vocation",
    "ex": "あなたの職業を教えてください。",
    "exReading": "あなた の しょくぎょう を おしえて ください",
    "exTranslation": "Please tell me your occupation.",
    "category": "Noun"
  },
  {
    "term": "条約",
    "termReading": "じょうやく",
    "termTranslation": "treaty",
    "ex": "2国間で条約が結ばれました。",
    "exReading": "にこくかん で じょうやく が むすばれました",
    "exTranslation": "A treaty was concluded between the two countries.",
    "category": "Noun"
  },
  {
    "term": "あちらこちら",
    "termReading": "あちらこちら",
    "termTranslation": "all over, here and there",
    "ex": "あちらこちらで紅葉がきれいですね。",
    "exReading": "あちらこちら で こうよう が きれい です ね",
    "exTranslation": "Here and there the autumn colors are beautiful.",
    "category": "Noun"
  },
  {
    "term": "参考",
    "termReading": "さんこう",
    "termTranslation": "reference, consultation",
    "ex": "この本を参考にして下さい。",
    "exReading": "この ほん を さんこう に して ください",
    "exTranslation": "Please refer to this book.",
    "category": "Noun"
  },
  {
    "term": "参議院",
    "termReading": "さんぎいん",
    "termTranslation": "House of Councilors, Upper House",
    "ex": "彼は参議院議員です。",
    "exReading": "かれ は さんぎいん ぎいん です",
    "exTranslation": "He is a member of the House of Councilors.",
    "category": "Noun"
  },
  {
    "term": "お参り",
    "termReading": "おまいり",
    "termTranslation": "visiting a shrine or temple",
    "ex": "家族でお寺にお参りに行きました。",
    "exReading": "かぞく で おてら に おまいり に いきました",
    "exTranslation": "We payed a visit to the temple with the family.",
    "category": "Verbal Noun"
  },
  {
    "term": "参考書",
    "termReading": "さんこうしょ",
    "termTranslation": "reference book",
    "ex": "この参考書はとても役に立つよ。",
    "exReading": "この さんこうしょ は とても やく に たつ よ",
    "exTranslation": "This reference book is very useful.",
    "category": "Noun"
  },
  {
    "term": "加わる",
    "termReading": "くわわる",
    "termTranslation": "join in",
    "ex": "私たちのチームに彼が加わった。",
    "exReading": "わたしたち の ちーむ に かれ が くわわった",
    "exTranslation": "He joined our team.",
    "category": "Verb"
  },
  {
    "term": "いい加減",
    "termReading": "いいかげん",
    "termTranslation": "irresponsible, half-baked",
    "ex": "いい加減なことを言ってはいけません。",
    "exReading": "いいかげん な こと を いって は いけません",
    "exTranslation": "You shouldn't say thoughtless things.",
    "category": "Adjectival Noun"
  },
  {
    "term": "追う",
    "termReading": "おう",
    "termTranslation": "chase, pursue",
    "ex": "警官は怪しい男の後を追ったよ。",
    "exReading": "けいかん は あやしい おとこ の あと を おった よ",
    "exTranslation": "The police officer went after the suspicious man.",
    "category": "Verb"
  },
  {
    "term": "追い出す",
    "termReading": "おいだす",
    "termTranslation": "turn out, kick out",
    "ex": "彼は家から追い出された。",
    "exReading": "かれ は いえ から おいだされた",
    "exTranslation": "He was kicked out from his home.",
    "category": "Verb"
  },
  {
    "term": "いつのまにか",
    "termReading": "いつのまにか",
    "termTranslation": "before it is noticed",
    "ex": "いつのまにか夜が明けていたね。",
    "exReading": "いつのまにか よ が あけて いた ね",
    "exTranslation": "The dawn had broken before we realized it.",
    "category": "Adverb"
  },
  {
    "term": "感じ",
    "termReading": "かんじ",
    "termTranslation": "feeling, impression",
    "ex": "あの子は感じの良い子です。",
    "exReading": "あの こ は かんじ の いい こ です",
    "exTranslation": "That child is very likable.",
    "category": "Noun"
  },
  {
    "term": "感情",
    "termReading": "かんじょう",
    "termTranslation": "feeling, emotion",
    "ex": "感情とは複雑なものです。",
    "exReading": "かんじょう と は ふくざつ な もの です",
    "exTranslation": "Emotions are complex things.",
    "category": "Noun"
  },
  {
    "term": "感覚",
    "termReading": "かんかく",
    "termTranslation": "sense, sensation",
    "ex": "冷えて指の感覚がない。",
    "exReading": "ひえて ゆび の かんかく が ない",
    "exTranslation": "I am so cold I can't feel my fingers.",
    "category": "Noun"
  },
  {
    "term": "感動",
    "termReading": "かんどう",
    "termTranslation": "emotion, impression",
    "ex": "感動する映画でした。",
    "exReading": "かんどう する えいが でした",
    "exTranslation": "It was a moving film.",
    "category": "Verbal Noun"
  },
  {
    "term": "実感",
    "termReading": "じっかん",
    "termTranslation": "true sense, realization",
    "ex": "子供が歩き始めたとき、子供の成長を実感した。",
    "exReading": "こども が あるきはじめた とき こども の せいちょう を じっかん した",
    "exTranslation": "I got a real sense of my kid's growth when he started walking.",
    "category": "Verbal Noun"
  },
  {
    "term": "感心",
    "termReading": "かんしん",
    "termTranslation": "admiration",
    "ex": "彼の我慢強さには感心しました。",
    "exReading": "かれ の がまんづよさ に は かんしん しました",
    "exTranslation": "I admired his patience.",
    "category": "Verbal Noun"
  },
  {
    "term": "思想",
    "termReading": "しそう",
    "termTranslation": "thought, conception",
    "ex": "人には思想の自由がある。",
    "exReading": "ひと に は しそう の じゆう が ある",
    "exTranslation": "People have freedom of thought.",
    "category": "Noun"
  },
  {
    "term": "おごる",
    "termReading": "おごる",
    "termTranslation": "treat",
    "ex": "彼に食事をおごってもらった。",
    "exReading": "かれ に しょくじ を おごって もらった",
    "exTranslation": "He bought me a meal.",
    "category": "Verb"
  },
  {
    "term": "感想",
    "termReading": "かんそう",
    "termTranslation": "thought, impression",
    "ex": "ご感想をお聞かせ下さい。",
    "exReading": "ごかんそう を おきかせ ください",
    "exTranslation": "Please tell me your ideas.",
    "category": "Noun"
  },
  {
    "term": "空想",
    "termReading": "くうそう",
    "termTranslation": "fantasy, daydreams",
    "ex": "彼は空想にふけっているの。",
    "exReading": "かれ は くうそう に ふけって いる の",
    "exTranslation": "He's indulging himself in daydreams.",
    "category": "Verbal Noun"
  },
  {
    "term": "現像",
    "termReading": "げんぞう",
    "termTranslation": "developing a film",
    "ex": "このフィルムを現像してください。",
    "exReading": "この ふぃるむ を げんぞう して ください",
    "exTranslation": "Please develop this film.",
    "category": "Verbal Noun"
  },
  {
    "term": "現象",
    "termReading": "げんしょう",
    "termTranslation": "phenomenon",
    "ex": "村では最近、不思議な現象が起きています。",
    "exReading": "むら で は さいきん ふしぎ な げんしょう が おきて います",
    "exTranslation": "A mysterious phenomenon occurred in the village recently.",
    "category": "Noun"
  },
  {
    "term": "気象",
    "termReading": "きしょう",
    "termTranslation": "weather condition",
    "ex": "テレビで明日の気象情報を確認したよ。",
    "exReading": "てれび で あす の きしょう じょうほう を かくにん した よ",
    "exTranslation": "I checked tomorrow's weather forecast on the TV.",
    "category": "Noun"
  },
  {
    "term": "障子",
    "termReading": "しょうじ",
    "termTranslation": "paper sliding-door",
    "ex": "猫が障子を破った。",
    "exReading": "ねこ が しょうじ を やぶった",
    "exTranslation": "The cat tore the paper sliding door.",
    "category": "Noun"
  },
  {
    "term": "修正",
    "termReading": "しゅうせい",
    "termTranslation": "correction, revision",
    "ex": "検討の結果、案を修正したよ。",
    "exReading": "けんとう の けっか あん を しゅうせい した よ",
    "exTranslation": "We adjusted the plan as a result of our deliberations.",
    "category": "Verbal Noun"
  },
  {
    "term": "おやつ",
    "termReading": "おやつ",
    "termTranslation": "snack, refreshment",
    "ex": "今日のおやつはプリンだった。",
    "exReading": "きょう の おやつ は ぷりん だった",
    "exTranslation": "Today's snack was pudding.",
    "category": "Noun"
  },
  {
    "term": "傷",
    "termReading": "きず",
    "termTranslation": "wound, scar",
    "ex": "足の傷が痛みます。",
    "exReading": "あし の きず が いたみます",
    "exTranslation": "The wound in my foot hurts.",
    "category": "Noun"
  },
  {
    "term": "傷める",
    "termReading": "いためる",
    "termTranslation": "damage, spoil",
    "ex": "彼は柔道で腰を傷めたんだ。",
    "exReading": "かれ は じゅうどう で こし を いためた ん だ",
    "exTranslation": "He hurt his lower back doing judo.",
    "category": "Verb"
  },
  {
    "term": "交換",
    "termReading": "こうかん",
    "termTranslation": "exchange, bartering",
    "ex": "試合の相手とユニフォームを交換したよ。",
    "exReading": "しあい の あいて と ゆにふぉーむ を こうかん した よ",
    "exTranslation": "We exchanged uniforms with the opposing team at the match.",
    "category": "Verbal Noun"
  },
  {
    "term": "言い換える",
    "termReading": "いいかえる",
    "termTranslation": "express in different words, paraphrase",
    "ex": "彼は易しい言葉に言い換えたんだ。",
    "exReading": "かれ は やさしい ことば に いいかえた ん だ",
    "exTranslation": "He paraphrased it in easy language.",
    "category": "Verb"
  },
  {
    "term": "着替え",
    "termReading": "きがえ",
    "termTranslation": "change of clothes",
    "ex": "着替えを旅行カバンに詰めたよ。",
    "exReading": "きがえ を りょこう かばん に つめた よ",
    "exTranslation": "I packed spare clothes in a travel bag.",
    "category": "Verbal Noun"
  },
  {
    "term": "交替",
    "termReading": "こうたい",
    "termTranslation": "alternation, shift",
    "ex": "家まで交替で荷物を持ちました。",
    "exReading": "いえ まで こうたい で にもつ を もちました",
    "exTranslation": "We took turns carrying the luggage to the house.",
    "category": "Verbal Noun"
  },
  {
    "term": "火災",
    "termReading": "かさい",
    "termTranslation": "fire, conflagration",
    "ex": "火災の原因は放火だそうです。",
    "exReading": "かさい の げんいん は ほうか だ そう です",
    "exTranslation": "I hear that the cause of the fire was arson.",
    "category": "Noun"
  },
  {
    "term": "災難",
    "termReading": "さいなん",
    "termTranslation": "calamity, misfortune",
    "ex": "旅先で思いがけない災難にあいました。",
    "exReading": "たびさき で おもいがけない さいなん に あいました",
    "exTranslation": "We encountered an unexpected misfortune on the trip.",
    "category": "Noun"
  },
  {
    "term": "ごろごろ",
    "termReading": "ごろごろ",
    "termTranslation": "rumble",
    "ex": "雷がごろごろ鳴っている。",
    "exReading": "かみなり が ごろごろ なって いる",
    "exTranslation": "The thunder is rumbling.",
    "category": "Adverb"
  },
  {
    "term": "障害",
    "termReading": "しょうがい",
    "termTranslation": "obstacle, disorder",
    "ex": "まずは障害を取り除いてから計画を進めよう。",
    "exReading": "まず は しょうがい を とりのぞいて から けいかく を すすめよう",
    "exTranslation": "Let's get rid of the problem and then proceed with the plan.",
    "category": "Noun"
  },
  {
    "term": "災害",
    "termReading": "さいがい",
    "termTranslation": "calamity, disaster",
    "ex": "地震は自然災害のひとつです。",
    "exReading": "じしん は しぜん さいがい の ひとつ です",
    "exTranslation": "Earthquakes are one type of natural disaster.",
    "category": "Noun"
  },
  {
    "term": "公害",
    "termReading": "こうがい",
    "termTranslation": "environmental pollution",
    "ex": "私たちは公害を減らすよう努力しています。",
    "exReading": "わたしたち は こうがい を へらす よう どりょく して います",
    "exTranslation": "We are making an effort to decrease pollution.",
    "category": "Noun"
  },
  {
    "term": "水害",
    "termReading": "すいがい",
    "termTranslation": "flood damage",
    "ex": "水害でたくさんの人が家を失ったの。",
    "exReading": "すいがい で たくさん の ひと が いえ を うしなった の",
    "exTranslation": "A great number of people lost their homes due to flood damage.",
    "category": "Noun"
  },
  {
    "term": "害",
    "termReading": "がい",
    "termTranslation": "harm, damage",
    "ex": "お酒の飲み過ぎは健康に害があります。",
    "exReading": "お さけ の のみすぎ は けんこう に がい が あります 。",
    "exTranslation": "Too much drinking harms your health.",
    "category": "Verbal Noun"
  },
  {
    "term": "被せる",
    "termReading": "かぶせる",
    "termTranslation": "cover",
    "ex": "本にカバーを被せたの。",
    "exReading": "ほん に かばー を かぶせた の 。",
    "exTranslation": "I put a cover on the book.",
    "category": "Verb"
  },
  {
    "term": "救う",
    "termReading": "すくう",
    "termTranslation": "save, relieve",
    "ex": "彼女は通りがかりの人に救われたよ。",
    "exReading": "かのじょ は とおりがかり の ひと に すくわれた よ",
    "exTranslation": "She was saved by a passing stranger.",
    "category": "Verb"
  },
  {
    "term": "シューズ",
    "termReading": "シューズ",
    "termTranslation": "shoes, sports shoes",
    "ex": "ジョギング用のシューズは安くないな。",
    "exReading": "じょぎんぐ よう の しゅーず は やすくない な",
    "exTranslation": "Jogging shoes are not cheap.",
    "category": "Noun"
  },
  {
    "term": "救い",
    "termReading": "すくい",
    "termTranslation": "rescue, relief",
    "ex": "娘の存在が私の救いでした。",
    "exReading": "むすめ の そんざい が わたし の すくい でした",
    "exTranslation": "My daughter's existence saved me.",
    "category": "Noun"
  },
  {
    "term": "助手",
    "termReading": "じょしゅ",
    "termTranslation": "assistant, helper",
    "ex": "資料は助手に預けておいてください。",
    "exReading": "しりょう は じょしゅ に あずけて おいて ください",
    "exTranslation": "Please hand the materials to my assistant.",
    "category": "Noun"
  },
  {
    "term": "救助",
    "termReading": "きゅうじょ",
    "termTranslation": "rescue, relief",
    "ex": "プールで男の子が救助されました。",
    "exReading": "ぷーる で おとこ の こ が きゅうじょ されました",
    "exTranslation": "A boy was rescued in the swimming pool.",
    "category": "Verbal Noun"
  },
  {
    "term": "支援",
    "termReading": "しえん",
    "termTranslation": "support, backing",
    "ex": "彼の支援がなかったらどうなっていたか。",
    "exReading": "かれ の しえん が なかったら どう なって いた か",
    "exTranslation": "What would have happened without his support?",
    "category": "Verbal Noun"
  },
  {
    "term": "援助",
    "termReading": "えんじょ",
    "termTranslation": "aid, assistance",
    "ex": "その国には物資の援助が必要です。",
    "exReading": "その くに に は ぶっし の えんじょ が ひつよう です",
    "exTranslation": "That country needs substantial aid.",
    "category": "Verbal Noun"
  },
  {
    "term": "応援",
    "termReading": "おうえん",
    "termTranslation": "support, cheering",
    "ex": "大勢が応援に駆けつけてくれたよ。",
    "exReading": "おおぜい が おうえん に かけつけて くれた よ",
    "exTranslation": "Many people came to cheer us on.",
    "category": "Verbal Noun"
  },
  {
    "term": "小遣い",
    "termReading": "こづかい",
    "termTranslation": "pocket money, spending money",
    "ex": "おじいちゃんにお小遣いをもらったよ。",
    "exReading": "おじいちゃん に おこづかい を もらった よ",
    "exTranslation": "I got pocket money from grandpa.",
    "category": "Noun"
  },
  {
    "term": "キス",
    "termReading": "キス",
    "termTranslation": "kiss",
    "ex": "初めてのキスは海岸でだったの。",
    "exReading": "はじめて の きす は かいがん で だった の",
    "exTranslation": "My first kiss was by the coast.",
    "category": "Verbal Noun"
  },
  {
    "term": "警告",
    "termReading": "けいこく",
    "termTranslation": "warning, admonition",
    "ex": "車に駐車違反の警告を貼られました。",
    "exReading": "くるま に ちゅうしゃ いはん の けいこく を はられました",
    "exTranslation": "A parking ticket was stuck on the car.",
    "category": "Verbal Noun"
  },
  {
    "term": "警官",
    "termReading": "けいかん",
    "termTranslation": "police officer, policeman",
    "ex": "道で警官に呼び止められた。",
    "exReading": "みち で けいかん に よびとめられた",
    "exTranslation": "I was stopped by a policeman on the road.",
    "category": "Noun"
  },
  {
    "term": "管",
    "termReading": "かん",
    "termTranslation": "pipe, tube",
    "ex": "ガス管が爆発しました。",
    "exReading": "がすかん が ばくはつ しました",
    "exTranslation": "The gas pipe exploded.",
    "category": "Noun"
  },
  {
    "term": "犯す",
    "termReading": "おかす",
    "termTranslation": "offend against, rape",
    "ex": "彼は大きな過ちを犯している。",
    "exReading": "かれ は おおき な あやまち を おかして いる",
    "exTranslation": "He is making a big mistake.",
    "category": "Verb"
  },
  {
    "term": "強盗",
    "termReading": "ごうとう",
    "termTranslation": "robbery",
    "ex": "強盗がカメラに写っていました。",
    "exReading": "ごうとう が かめら に うつって いました",
    "exTranslation": "The burglar was caught on camera.",
    "category": "Noun"
  },
  {
    "term": "自殺",
    "termReading": "じさつ",
    "termTranslation": "suicide",
    "ex": "犯人は警察に捕まる前に自殺しました。",
    "exReading": "はんにん は けいさつ に つかまる まえ に じさつ しました",
    "exTranslation": "The criminal committed suicide before he was caught by the police.",
    "category": "Verbal Noun"
  },
  {
    "term": "殺人",
    "termReading": "さつじん",
    "termTranslation": "murder, homicide",
    "ex": "その殺人事件は白昼に起こったんだ。",
    "exReading": "その さつじん じけん は はくちゅう に おこった ん だ",
    "exTranslation": "The murder happened during daylight.",
    "category": "Noun"
  },
  {
    "term": "くるくる",
    "termReading": "くるくる",
    "termTranslation": "round and round, spin",
    "ex": "猫は私の足元をくるくると回ったの。",
    "exReading": "ねこ は わたし の あしもと を くるくる と まわった の",
    "exTranslation": "The cat rubbed itself around my legs.",
    "category": "Adverb"
  },
  {
    "term": "奪う",
    "termReading": "うばう",
    "termTranslation": "rob, take by force",
    "ex": "その男は彼女のバッグを奪ったぞ。",
    "exReading": "その おとこ は かのじょ の ばっぐ を うばった ぞ",
    "exTranslation": "That man stole her bag.",
    "category": "Verb"
  },
  {
    "term": "戦後",
    "termReading": "せんご",
    "termTranslation": "postwar period",
    "ex": "戦後の日本は混乱していました。",
    "exReading": "せんご の にほん は こんらん して いました",
    "exTranslation": "Japan was in a state of confusion after World War II.",
    "category": "Noun"
  },
  {
    "term": "作戦",
    "termReading": "さくせん",
    "termTranslation": "tactic, strategy",
    "ex": "彼らは次の試合のために作戦を立てたのよ。",
    "exReading": "かれら は つぎ の しあい の ため に さくせん を たてた の よ",
    "exTranslation": "They determined the strategy for the next game.",
    "category": "Noun"
  },
  {
    "term": "戦場",
    "termReading": "せんじょう",
    "termTranslation": "battlefield, front",
    "ex": "祖父は戦場に行ったことがあるそうです。",
    "exReading": "そふ は せんじょう に いった こと が ある そう です",
    "exTranslation": "I've heard that my grandfather has been to the battlefield.",
    "category": "Noun"
  },
  {
    "term": "戦前",
    "termReading": "せんぜん",
    "termTranslation": "prewar period",
    "ex": "戦前の生活は今と全く違いました。",
    "exReading": "せんぜん の せいかつ は いま と まったく ちがいました",
    "exTranslation": "Life before the World War II is quite different from that of now.",
    "category": "Noun"
  },
  {
    "term": "戦死",
    "termReading": "せんし",
    "termTranslation": "death in war",
    "ex": "祖父は戦死しました。",
    "exReading": "そふ は せんし しました",
    "exTranslation": "My grandfather died in the war.",
    "category": "Verbal Noun"
  },
  {
    "term": "争い",
    "termReading": "あらそい",
    "termTranslation": "conflict, battle",
    "ex": "その地域では争いが絶えません。",
    "exReading": "その ちいき で は あらそい が たえません",
    "exTranslation": "There are ceaseless conflicts in that region.",
    "category": "Noun"
  },
  {
    "term": "争う",
    "termReading": "あらそう",
    "termTranslation": "compete, fight",
    "ex": "その2国は資源をめぐって争っています。",
    "exReading": "その にこく は しげん を めぐって あらそって います",
    "exTranslation": "The two countries are competing for resources.",
    "category": "Verb"
  },
  {
    "term": "サボる",
    "termReading": "サボる",
    "termTranslation": "blow off, play truant",
    "ex": "また仕事をサボっていますね。",
    "exReading": "また しごと を さぼって います ね",
    "exTranslation": "He's skipping work again, isn't he?",
    "category": "Verb"
  },
  {
    "term": "競技",
    "termReading": "きょうぎ",
    "termTranslation": "match, sporting event",
    "ex": "彼は個人競技のスポーツが好きです。",
    "exReading": "かれ は こじん きょうぎ の すぽーつ が すき です",
    "exTranslation": "He likes individual sports.",
    "category": "Verbal Noun"
  },
  {
    "term": "混雑",
    "termReading": "こんざつ",
    "termTranslation": "confusion, disorder",
    "ex": "今日はデパートが混雑していたよ。",
    "exReading": "きょう は でぱーと が こんざつ して いた よ",
    "exTranslation": "The department store was crowded today.",
    "category": "Verbal Noun"
  },
  {
    "term": "混乱",
    "termReading": "こんらん",
    "termTranslation": "disorder, confusion",
    "ex": "地震の後、町は大混乱だったよ。",
    "exReading": "じしん の あと まち は だいこんらん だった よ",
    "exTranslation": "After the earthquake, the town was in pandemonium.",
    "category": "Verbal Noun"
  },
  {
    "term": "捜す",
    "termReading": "さがす",
    "termTranslation": "look for, search for",
    "ex": "警察がその男を捜しているの。",
    "exReading": "けいさつ が その おとこ を さがして いる の",
    "exTranslation": "The police are searching for the man.",
    "category": "Verb"
  },
  {
    "term": "索引",
    "termReading": "さくいん",
    "termTranslation": "index",
    "ex": "索引はたいてい本の後ろについていますよ。",
    "exReading": "さくいん は たいてい ほん の うしろ に ついて います よ",
    "exTranslation": "The index is usually found in the end of the book.",
    "category": "Noun"
  },
  {
    "term": "落ち着く",
    "termReading": "おちつく",
    "termTranslation": "calm down, settle in",
    "ex": "私の話を落ち着いて聞いてください。",
    "exReading": "わたし の はなし を おちついて きいて ください",
    "exTranslation": "Calm down and listen to me.",
    "category": "Verb"
  },
  {
    "term": "落ち着き",
    "termReading": "おちつき",
    "termTranslation": "presence of mind, composure",
    "ex": "弟は落ち着きが足りません。",
    "exReading": "おとうと は おちつき が たりません",
    "exTranslation": "My little brother is too restless.",
    "category": "Noun"
  },
  {
    "term": "ショッピング",
    "termReading": "ショッピング",
    "termTranslation": "shopping",
    "ex": "彼女のいちばんの楽しみはショッピングです。",
    "exReading": "かのじょ の いちばん の たのしみ は しょっぴんぐ です",
    "exTranslation": "Her greatest pleasure is shopping.",
    "category": "Verbal Noun"
  },
  {
    "term": "落とし物",
    "termReading": "おとしもの",
    "termTranslation": "lost property",
    "ex": "財布の落とし物を拾いました。",
    "exReading": "さいふ の おとしもの を ひろいました",
    "exTranslation": "I picked up an abandoned wallet.",
    "category": "Noun"
  },
  {
    "term": "交流",
    "termReading": "こうりゅう",
    "termTranslation": "interaction, contact",
    "ex": "もっと他の町との交流を深めましょう。",
    "exReading": "もっと ほか の まち と の こうりゅう を ふかめましょう",
    "exTranslation": "Let's strengthen our ties with other towns.",
    "category": "Verbal Noun"
  },
  {
    "term": "一流",
    "termReading": "いちりゅう",
    "termTranslation": "top-ranked, world-class",
    "ex": "彼は一流の選手です。",
    "exReading": "かれ は いちりゅう の せんしゅ です",
    "exTranslation": "He is a top-ranked player.",
    "category": "Noun"
  },
  {
    "term": "上流",
    "termReading": "じょうりゅう",
    "termTranslation": "upstream",
    "ex": "上流には滝があります。",
    "exReading": "じょうりゅう に は たき が あります",
    "exTranslation": "There is a waterfall upstream.",
    "category": "Noun"
  },
  {
    "term": "下流",
    "termReading": "かりゅう",
    "termTranslation": "downstream",
    "ex": "下流に小さな滝があるよ。",
    "exReading": "かりゅう に ちいさ な たき が ある よ",
    "exTranslation": "There is a small waterfall downstream.",
    "category": "Noun"
  },
  {
    "term": "海流",
    "termReading": "かいりゅう",
    "termTranslation": "ocean current",
    "ex": "ここで2つの海流が出合っている。",
    "exReading": "ここ で ふたつ の かいりゅう が であって いる",
    "exTranslation": "Two sea currents converge here.",
    "category": "Noun"
  },
  {
    "term": "三流",
    "termReading": "さんりゅう",
    "termTranslation": "third-rate",
    "ex": "彼は三流大学を出たが、今は社長だよ。",
    "exReading": "かれ は さんりゅう だいがく を でた が いま は しゃちょう だ よ",
    "exTranslation": "Even though he went to a third-rate college he is now the company president.",
    "category": "Noun"
  },
  {
    "term": "スマート",
    "termReading": "スマート",
    "termTranslation": "sophisticated, skillful",
    "ex": "彼はいつもスマートに行動するね。",
    "exReading": "かれ は いつも すまーと に こうどう する ね",
    "exTranslation": "He always acts sophisticatedly.",
    "category": "Adjective"
  },
  {
    "term": "洪水",
    "termReading": "こうずい",
    "termTranslation": "flood, inundation",
    "ex": "洪水でたくさんの家が流されたの。",
    "exReading": "こうずい で たくさん の いえ が ながされた の",
    "exTranslation": "A lot of houses were washed away in the flood.",
    "category": "Noun"
  },
  {
    "term": "崩れる",
    "termReading": "くずれる",
    "termTranslation": "crumble, collapse",
    "ex": "大雨で崖が崩れたね。",
    "exReading": "おおあめ で がけ が くずれた ね",
    "exTranslation": "The cliff collapsed because of the downpour.",
    "category": "Verb"
  },
  {
    "term": "崩す",
    "termReading": "くずす",
    "termTranslation": "destroy, break",
    "ex": "彼女は体調を崩しています。",
    "exReading": "かのじょ は たいちょう を くずして います",
    "exTranslation": "She is under the weather.",
    "category": "Verb"
  },
  {
    "term": "水洗",
    "termReading": "すいせん",
    "termTranslation": "flushing, washing",
    "ex": "今はほとんどのトイレが水洗ですよ。",
    "exReading": "いま は ほとんど の といれ が すいせん です よ",
    "exTranslation": "Most toilets nowadays flush.",
    "category": "Verbal Noun"
  },
  {
    "term": "洗い物",
    "termReading": "あらいもの",
    "termTranslation": "dishes to be washed",
    "ex": "母は台所で洗い物をしています。",
    "exReading": "はは は だいどころ で あらいもの を して います",
    "exTranslation": "Mother is doing the dishes in the kitchen.",
    "category": "Noun"
  },
  {
    "term": "石油",
    "termReading": "せきゆ",
    "termTranslation": "petroleum, oil",
    "ex": "日本は石油のほとんどを輸入しています。",
    "exReading": "にっぽん は せきゆ の ほとんど を ゆにゅう して います",
    "exTranslation": "Japan imports most of its oil.",
    "category": "Noun"
  },
  {
    "term": "油絵",
    "termReading": "あぶらえ",
    "termTranslation": "oil painting",
    "ex": "趣味で油絵を描いています。",
    "exReading": "しゅみ で あぶらえ を かいています",
    "exTranslation": "I paint oil paintings for fun.",
    "category": "Noun"
  },
  {
    "term": "浮かぶ",
    "termReading": "うかぶ",
    "termTranslation": "float",
    "ex": "沖にボートが浮かんでいます。",
    "exReading": "おき に ぼーと が うかんで います",
    "exTranslation": "The boat is floating off the coast.",
    "category": "Verb"
  },
  {
    "term": "ずらり",
    "termReading": "ずらり",
    "termTranslation": "line up neatly",
    "ex": "玄関に靴がずらりと並んでいたな。",
    "exReading": "げんかん に くつ が ずらり と ならんで いた な",
    "exTranslation": "Shoes were lined up in the doorway.",
    "category": "Adverb"
  },
  {
    "term": "浮かべる",
    "termReading": "うかべる",
    "termTranslation": "set afloat",
    "ex": "お風呂に花を浮かべて入ったの。",
    "exReading": "おふろ に はな を うかべて はいった の",
    "exTranslation": "I floated some flowers in the bath and then got in.",
    "category": "Verb"
  },
  {
    "term": "浮く",
    "termReading": "うく",
    "termTranslation": "float",
    "ex": "氷は水に浮きます。",
    "exReading": "こおり は みず に うきます",
    "exTranslation": "Ice floats on water.",
    "category": "Verb"
  },
  {
    "term": "沈める",
    "termReading": "しずめる",
    "termTranslation": "sink, submerge",
    "ex": "彼女はソファーに体を沈めたんだ。",
    "exReading": "かのじょ は そふぁー に からだ を しずめた ん だ",
    "exTranslation": "She sank down into the sofa.",
    "category": "Verb"
  },
  {
    "term": "将来",
    "termReading": "しょうらい",
    "termTranslation": "future, in the future",
    "ex": "将来はパイロットになりたいです。",
    "exReading": "しょうらい は ぱいろっと に なりたい です 。",
    "exTranslation": "I want to become a pilot in the future.",
    "category": "Noun"
  },
  {
    "term": "永遠",
    "termReading": "えいえん",
    "termTranslation": "eternity",
    "ex": "平和は人類の永遠のテーマです。",
    "exReading": "へいわ は じんるい の えいえん の てーま です",
    "exTranslation": "Peace is an eternal issue for human beings.",
    "category": "Adjectival Noun"
  },
  {
    "term": "永久",
    "termReading": "えいきゅう",
    "termTranslation": "permanence, eternity",
    "ex": "彼は永久に帰らぬ人となったのよ。",
    "exReading": "かれ は えいきゅう に かえらぬ ひと と なった の よ",
    "exTranslation": "He passed away.",
    "category": "Adjectival Noun"
  },
  {
    "term": "河口",
    "termReading": "かこう",
    "termTranslation": "river mouth, estuary",
    "ex": "この川の河口は太平洋に注いでいます。",
    "exReading": "この かわ の かこう は たいへいよう に そそいで います",
    "exTranslation": "The mouth of this river flows into the Pacific Ocean.",
    "category": "Noun"
  },
  {
    "term": "いらっしゃる",
    "termReading": "いらっしゃる",
    "termTranslation": "come, go",
    "ex": "お客様がいらっしゃいました。",
    "exReading": "おきゃくさま が いらっしゃいました",
    "exTranslation": "Your guest has arrived.",
    "category": "Verb"
  },
  {
    "term": "冷ます",
    "termReading": "さます",
    "termTranslation": "cool, let cool",
    "ex": "お茶がとても熱かったので冷ましてから飲んだの。",
    "exReading": "おちゃ が とても あつかった の で さまして から のんだ の",
    "exTranslation": "As the tea was very hot, I let it cool and then drank it.",
    "category": "Verb"
  },
  {
    "term": "車庫",
    "termReading": "しゃこ",
    "termTranslation": "car shed, garage",
    "ex": "車を車庫に入れておきました。",
    "exReading": "くるま を しゃこ に いれて おきました",
    "exTranslation": "I put the car in the garage.",
    "category": "Noun"
  },
  {
    "term": "金庫",
    "termReading": "きんこ",
    "termTranslation": "strong box, safe",
    "ex": "ホテルの金庫に貴重品を入れたよ。",
    "exReading": "ほてる の きんこ に きちょうひん を いれた よ",
    "exTranslation": "I put my valuables in the hotel safe.",
    "category": "Noun"
  },
  {
    "term": "心臓",
    "termReading": "しんぞう",
    "termTranslation": "heart",
    "ex": "祖母は心臓が悪いんだ。",
    "exReading": "そぼ は しんぞう が わるい ん だ",
    "exTranslation": "My grandmother has a heart condition.",
    "category": "Adjectival Noun"
  },
  {
    "term": "軽快",
    "termReading": "けいかい",
    "termTranslation": "light, cheerful",
    "ex": "彼らは軽快なステップで踊り出したの。",
    "exReading": "かれら は けいかい な すてっぷ で おどりだした の",
    "exTranslation": "They began to dance with jaunty steps.",
    "category": "Adjective"
  },
  {
    "term": "快い",
    "termReading": "こころよい",
    "termTranslation": "pleasant, agreeable",
    "ex": "彼女は快い眠りについています。",
    "exReading": "かのじょ は こころよい ねむり に ついて います",
    "exTranslation": "She is sleeping soundly.",
    "category": "Adjective"
  },
  {
    "term": "快晴",
    "termReading": "かいせい",
    "termTranslation": "fair and clear weather",
    "ex": "今日は快晴ですね。",
    "exReading": "きょう は かいせい です ね",
    "exTranslation": "Today's weather is fine.",
    "category": "Noun"
  },
  {
    "term": "ウナギ",
    "termReading": "ウナギ",
    "termTranslation": "eel",
    "ex": "ウナギの蒲焼きは美味しいね。",
    "exReading": "うなぎ の かばやき は おいしい ね",
    "exTranslation": "Broiled eel is delicious.",
    "category": "Noun"
  },
  {
    "term": "最適",
    "termReading": "さいてき",
    "termTranslation": "optimum",
    "ex": "ここは子育てに最適な環境です。",
    "exReading": "ここ は こそだて に さいてき な かんきょう です",
    "exTranslation": "This is the best environment for bringing up children.",
    "category": "Adjectival Noun"
  },
  {
    "term": "指摘",
    "termReading": "してき",
    "termTranslation": "identification, pointing out",
    "ex": "ご指摘いただきありがとうございます。",
    "exReading": "ごしてき いただき ありがとう ございます",
    "exTranslation": "Thank you very much for pointing it out.",
    "category": "Verbal Noun"
  },
  {
    "term": "湿度",
    "termReading": "しつど",
    "termTranslation": "humidity",
    "ex": "日本の夏は湿度が高いです。",
    "exReading": "にほん の なつ は しつど が たかい です",
    "exTranslation": "Humidity is high during summers in Japan.",
    "category": "Noun"
  },
  {
    "term": "湿っぽい",
    "termReading": "しめっぽい",
    "termTranslation": "damp, gloomy",
    "ex": "雨の日が続いて家の中が湿っぽいな。",
    "exReading": "あめ の ひ が つづいて いえ の なか が しめっぽい な",
    "exTranslation": "When it rains for days, the whole house becomes damp.",
    "category": "Adjective"
  },
  {
    "term": "湿気",
    "termReading": "しっけ",
    "termTranslation": "moisture, dampness",
    "ex": "この部屋は暗くて湿気が多いね。",
    "exReading": "この へや は くらくて しっけ が おおい ね",
    "exTranslation": "This room is dark and damp.",
    "category": "Noun"
  },
  {
    "term": "湿る",
    "termReading": "しめる",
    "termTranslation": "get damp, get wet",
    "ex": "洗濯物がまだ湿っています。",
    "exReading": "せんたくもの が まだ しめって います",
    "exTranslation": "The laundry is still damp.",
    "category": "Verb"
  },
  {
    "term": "汚染",
    "termReading": "おせん",
    "termTranslation": "pollution, contamination",
    "ex": "その川の水は汚染されています。",
    "exReading": "その かわ の みず は おせん されて います",
    "exTranslation": "That river's water is polluted.",
    "category": "Verbal Noun"
  },
  {
    "term": "景気",
    "termReading": "けいき",
    "termTranslation": "economic climate",
    "ex": "景気が回復してきたね。",
    "exReading": "けいき が かいふく して きた ね",
    "exTranslation": "Business is recovering.",
    "category": "Noun"
  },
  {
    "term": "ガード",
    "termReading": "ガード",
    "termTranslation": "guard rail",
    "ex": "歩道に新しくガードが付きましたね。",
    "exReading": "ほどう に あたらしく がーど が つきました ね",
    "exTranslation": "They put a new guardrail on the pavement.",
    "category": "Noun"
  },
  {
    "term": "影",
    "termReading": "かげ",
    "termTranslation": "shadow, shade",
    "ex": "窓に男性の影が映っています。",
    "exReading": "まど に だんせい の かげ が うつって います",
    "exTranslation": "There's a man's shadow reflected in the window.",
    "category": "Noun"
  },
  {
    "term": "境界",
    "termReading": "きょうかい",
    "termTranslation": "boundary, border",
    "ex": "ここは隣の市との境界です。",
    "exReading": "ここ は となり の し と の きょうかい です",
    "exTranslation": "Here is the border with the neighboring city.",
    "category": "Noun"
  },
  {
    "term": "環境",
    "termReading": "かんきょう",
    "termTranslation": "environment, surroundings",
    "ex": "引っ越して環境が変わりました。",
    "exReading": "ひっこして かんきょう が かわりました",
    "exTranslation": "My environment changed since I moved to a new location.",
    "category": "Noun"
  },
  {
    "term": "国境",
    "termReading": "こっきょう",
    "termTranslation": "national border",
    "ex": "あの山のすぐ近くが国境です。",
    "exReading": "あの やま の すぐ ちかく が こっきょう です",
    "exTranslation": "The border is right near those mountains.",
    "category": "Noun"
  },
  {
    "term": "境",
    "termReading": "さかい",
    "termTranslation": "boundary, border",
    "ex": "2つの市の境に川が流れているの。",
    "exReading": "ふたつ の し の さかい に かわ が ながれて いる の",
    "exTranslation": "A river flows along the boundary of the two cities.",
    "category": "Noun"
  },
  {
    "term": "観察",
    "termReading": "かんさつ",
    "termTranslation": "observation, supervision",
    "ex": "花の成長を観察して日記を書きなさい。",
    "exReading": "はな の せいちょう を かんさつ して にっき を かきなさい",
    "exTranslation": "Observe the growth of the flower and record it in your diary.",
    "category": "Verbal Noun"
  },
  {
    "term": "外観",
    "termReading": "がいかん",
    "termTranslation": "external appearance",
    "ex": "そのモダンな外観の建物が大使館です。",
    "exReading": "その もだん な がいかん の たてもの が たいしかん です",
    "exTranslation": "That building with the modern exterior is the embassy.",
    "category": "Noun"
  },
  {
    "term": "かぼちゃ",
    "termReading": "かぼちゃ",
    "termTranslation": "pumpkin",
    "ex": "かぼちゃのスープは美味しいです。",
    "exReading": "かぼちゃの すーぷ は おいしい です",
    "exTranslation": "Pumpkin soup is delicious.",
    "category": "Noun"
  },
  {
    "term": "客観的",
    "termReading": "きゃっかんてき",
    "termTranslation": "objective",
    "ex": "彼は自分の状況を客観的に見てみたのね。",
    "exReading": "かれ は じぶん の じょうきょう を きゃっかんてき に みて みた の ね",
    "exTranslation": "He tried to look at his situation objectively.",
    "category": "Adjective"
  },
  {
    "term": "主観",
    "termReading": "しゅかん",
    "termTranslation": "subjectivity, subject",
    "ex": "主観だけで物事を見てはいけない。",
    "exReading": "しゅかん だけ で ものごと を みて は いけない",
    "exTranslation": "You shouldn't look at things merely subjectively.",
    "category": "Noun"
  },
  {
    "term": "主観的",
    "termReading": "しゅかんてき",
    "termTranslation": "subjective",
    "ex": "それは主観的な意見だ。",
    "exReading": "それ は しゅかんてき な いけん だ",
    "exTranslation": "That is a subjective opinion.",
    "category": "Adjective"
  },
  {
    "term": "観客",
    "termReading": "かんきゃく",
    "termTranslation": "audience, spectator",
    "ex": "観客は興奮していたよ。",
    "exReading": "かんきゃく は こうふん して いた よ",
    "exTranslation": "The spectators became excited.",
    "category": "Noun"
  },
  {
    "term": "観光",
    "termReading": "かんこう",
    "termTranslation": "sightseeing",
    "ex": "京都で3日間観光しました。",
    "exReading": "きょうと で みっかかん かんこう しました",
    "exTranslation": "I went sightseeing for three days in Kyoto.",
    "category": "Noun"
  },
  {
    "term": "観測",
    "termReading": "かんそく",
    "termTranslation": "observation",
    "ex": "先生が星の観測に連れて行ってくれました。",
    "exReading": "せんせい が ほし の かんそく に つれて いって くれました",
    "exTranslation": "The teacher took us to observe the stars.",
    "category": "Verbal Noun"
  },
  {
    "term": "推測",
    "termReading": "すいそく",
    "termTranslation": "conjecture, guess",
    "ex": "それは彼の推測にすぎない。",
    "exReading": "それ は かれ の すいそく に すぎない",
    "exTranslation": "It is no more than his guess.",
    "category": "Verbal Noun"
  },
  {
    "term": "クイズ",
    "termReading": "クイズ",
    "termTranslation": "quiz, quiz show",
    "ex": "クイズ番組を見るのが好きです。",
    "exReading": "くいず ばんぐみ を みる の が すき です",
    "exTranslation": "I like to watch quiz shows.",
    "category": "Noun"
  },
  {
    "term": "宇宙",
    "termReading": "うちゅう",
    "termTranslation": "universe, space",
    "ex": "宇宙の謎は限りなく大きいの。",
    "exReading": "うちゅう の なぞ は かぎり なく おおきい の",
    "exTranslation": "The mystery of space is infinitely deep.",
    "category": "Noun"
  },
  {
    "term": "衛星",
    "termReading": "えいせい",
    "termTranslation": "satellite, moon",
    "ex": "衛星が打ち上げられましたね。",
    "exReading": "えいせい が うちあげられました ね",
    "exTranslation": "The satellite was launched.",
    "category": "Noun"
  },
  {
    "term": "衛生",
    "termReading": "えいせい",
    "termTranslation": "hygiene, sanitation",
    "ex": "衛生には十分気を付けましょう。",
    "exReading": "えいせい に は じゅうぶん き を つけましょう",
    "exTranslation": "Let's take proper care of hygiene.",
    "category": "Noun"
  },
  {
    "term": "衛生的",
    "termReading": "えいせいてき",
    "termTranslation": "sanitary, hygienic",
    "ex": "このレストランは衛生的で安心です。",
    "exReading": "この れすとらん は えいせいてき で あんしん です",
    "exTranslation": "This restaurant is clean and safe.",
    "category": "Adjective"
  },
  {
    "term": "球",
    "termReading": "きゅう",
    "termTranslation": "globe, ball",
    "ex": "彼は球技が大好きです。",
    "exReading": "かれ は きゅうぎ が だいすき です",
    "exTranslation": "He loves ball games.",
    "category": "Noun"
  },
  {
    "term": "震度",
    "termReading": "しんど",
    "termTranslation": "seismic intensity",
    "ex": "今朝の地震は震度3でしたよ。",
    "exReading": "けさ の じしん は しんど さん でした よ",
    "exTranslation": "The earthquake this morning had a seismic intensity of three.",
    "category": "Noun"
  },
  {
    "term": "振動",
    "termReading": "しんどう",
    "termTranslation": "vibration",
    "ex": "車の振動で棚の荷物が落ちた。",
    "exReading": "くるま の しんどう で たな の にもつ が おちた 。",
    "exTranslation": "Vibration from a passing car caused things on the shelf to fall down.",
    "category": "Verbal Noun"
  },
  {
    "term": "クリーム",
    "termReading": "クリーム",
    "termTranslation": "cream",
    "ex": "私は洗い物をしたあと、手にクリームをぬります。",
    "exReading": "わたし は あらいもの を した あと て に くりーむ を ぬります",
    "exTranslation": "I put on hand cream after doing the dishes.",
    "category": "Noun"
  },
  {
    "term": "神経",
    "termReading": "しんけい",
    "termTranslation": "sensitivity, nerves",
    "ex": "彼は神経が細かいですね。",
    "exReading": "かれ は しんけい が こまかい です ね",
    "exTranslation": "He is very sensitive.",
    "category": "Noun"
  },
  {
    "term": "神様",
    "termReading": "かみさま",
    "termTranslation": "god, the divine",
    "ex": "神様にお願いしました。",
    "exReading": "かみさま に おねがい しました",
    "exTranslation": "I asked a favor to the gods.",
    "category": "Noun"
  },
  {
    "term": "神道",
    "termReading": "しんとう",
    "termTranslation": "Shinto",
    "ex": "彼女の家は神道です。",
    "exReading": "かのじょ の いえ は しんとう です",
    "exTranslation": "Her family religion is Shinto.",
    "category": "Noun"
  },
  {
    "term": "精神",
    "termReading": "せいしん",
    "termTranslation": "mind, spirit",
    "ex": "彼女は今、精神が不安定だ。",
    "exReading": "かのじょ は いま せいしん が ふあんてい だ",
    "exTranslation": "Now she is mentally unstable.",
    "category": "Noun"
  },
  {
    "term": "精算",
    "termReading": "せいさん",
    "termTranslation": "settlement of accounts, adjustment",
    "ex": "降りる駅で料金を精算して下さい。",
    "exReading": "おりる えき で りょうきん を せいさん して ください",
    "exTranslation": "Please adjust the fare at the station where you get off.",
    "category": "Verbal Noun"
  },
  {
    "term": "厳重",
    "termReading": "げんじゅう",
    "termTranslation": "strict, secure",
    "ex": "上司から厳重に注意されました。",
    "exReading": "じょうし から げんじゅう に ちゅうい されました",
    "exTranslation": "I was told off severely by my boss.",
    "category": "Adjective"
  },
  {
    "term": "貴重",
    "termReading": "きちょう",
    "termTranslation": "precious, valuable",
    "ex": "彼は貴重な体験をしたわね。",
    "exReading": "かれ は きちょう な たいけん を した わ ね",
    "exTranslation": "He had a valuable experience.",
    "category": "Adjectival Noun"
  },
  {
    "term": "貴重品",
    "termReading": "きちょうひん",
    "termTranslation": "article of value, valuables",
    "ex": "貴重品は自分で持っていてください。",
    "exReading": "きちょうひん は じぶん で もって いて ください",
    "exTranslation": "Please take your valuables with you.",
    "category": "Noun"
  },
  {
    "term": "ぐるぐる",
    "termReading": "ぐるぐる",
    "termTranslation": "round and round",
    "ex": "犬が自分のしっぽを追いかけてぐるぐる回っている。",
    "exReading": "いぬ が じぶん の しっぽ を おいかけて ぐるぐる まわって いる",
    "exTranslation": "The dog ran round and round chasing its tail.",
    "category": "Adverb"
  },
  {
    "term": "跡",
    "termReading": "あと",
    "termTranslation": "trace, track",
    "ex": "ここにタイヤの跡があるわ。",
    "exReading": "ここ に たいや の あと が ある わ",
    "exTranslation": "There is a tire imprint here.",
    "category": "Noun"
  },
  {
    "term": "足跡",
    "termReading": "あしあと",
    "termTranslation": "footprint, footmark",
    "ex": "雪の上にうさぎの足跡があった。",
    "exReading": "ゆき の うえ に うさぎ の あしあと が あった",
    "exTranslation": "There were rabbits' footprints in the snow.",
    "category": "Noun"
  },
  {
    "term": "好奇心",
    "termReading": "こうきしん",
    "termTranslation": "curiosity",
    "ex": "子供は好奇心でいっぱいだね。",
    "exReading": "こども は こうきしん で いっぱい だ ね",
    "exTranslation": "Children are full of curiosity.",
    "category": "Noun"
  },
  {
    "term": "奇跡",
    "termReading": "きせき",
    "termTranslation": "miracle, wonder",
    "ex": "彼のマジックはまるで奇跡です。",
    "exReading": "かれ の まじっく は まるで きせき です",
    "exTranslation": "His magic is like a miracle.",
    "category": "Noun"
  },
  {
    "term": "奇数",
    "termReading": "きすう",
    "termTranslation": "odd number",
    "ex": "3は奇数です。",
    "exReading": "さん は きすう です",
    "exTranslation": "Three is an odd number.",
    "category": "Noun"
  },
  {
    "term": "経歴",
    "termReading": "けいれき",
    "termTranslation": "personal history, resume",
    "ex": "あなたの経歴をメールで送ってください。",
    "exReading": "あなた の けいれき を めーる で おくって ください",
    "exTranslation": "Please send your resume by e-mail.",
    "category": "Noun"
  },
  {
    "term": "学歴",
    "termReading": "がくれき",
    "termTranslation": "educational history",
    "ex": "その職は大卒の学歴が必要だ。",
    "exReading": "その しょく は だいそつ の がくれき が ひつよう だ",
    "exTranslation": "That job requires a college diploma.",
    "category": "Noun"
  },
  {
    "term": "さぞ",
    "termReading": "さぞ",
    "termTranslation": "no doubt, must",
    "ex": "それはさぞがっかりしたことでしょう。",
    "exReading": "それはさぞがっかりしたことでしょう。",
    "exTranslation": "That must have been disappointing.",
    "category": "Adverb"
  },
  {
    "term": "王",
    "termReading": "おう",
    "termTranslation": "king",
    "ex": "王の墓を見学したよ。",
    "exReading": "おう の はか を けんがく した よ",
    "exTranslation": "I visited the royal grave.",
    "category": "Noun"
  },
  {
    "term": "王様",
    "termReading": "おうさま",
    "termTranslation": "king",
    "ex": "その国の王様はとても賢い。",
    "exReading": "その くに の おうさま は とても かしこい",
    "exTranslation": "That country's king is very wise.",
    "category": "Noun"
  },
  {
    "term": "建築",
    "termReading": "けんちく",
    "termTranslation": "construction, architecture",
    "ex": "彼らは家を建築中です。",
    "exReading": "かれら は いえ を けんちくちゅう です",
    "exTranslation": "They are in the middle of building a house.",
    "category": "Verbal Noun"
  },
  {
    "term": "新築",
    "termReading": "しんちく",
    "termTranslation": "new building",
    "ex": "彼は去年、家を新築しました。",
    "exReading": "かれ は きょねん いえ を しんちく しました",
    "exTranslation": "He built a new house last year.",
    "category": "Verbal Noun"
  },
  {
    "term": "構想",
    "termReading": "こうそう",
    "termTranslation": "conception, design",
    "ex": "彼は新しい小説の構想を練っているの。",
    "exReading": "かれ は あたらしい しょうせつ の こうそう を ねって いる の",
    "exTranslation": "He is developing a plot for his new novel.",
    "category": "Verbal Noun"
  },
  {
    "term": "構える",
    "termReading": "かまえる",
    "termTranslation": "set up (shop)",
    "ex": "彼はあの通りに店を構えているの。",
    "exReading": "かれ は あの とおり に みせ を かまえて いる の",
    "exTranslation": "He's set up his shop on that street.",
    "category": "Verb"
  },
  {
    "term": "結構",
    "termReading": "けっこう",
    "termTranslation": "all right, no thank you",
    "ex": "お腹がいっぱいなのでお代わりは結構です。",
    "exReading": "おなか が いっぱい な の で おかわり は けっこう です",
    "exTranslation": "I'm full so I won't have a second helping, thanks.",
    "category": "Adjective"
  },
  {
    "term": "サングラス",
    "termReading": "サングラス",
    "termTranslation": "sunglasses",
    "ex": "彼はサングラスが似合うね。",
    "exReading": "かれ は さんぐらす が にあう ね",
    "exTranslation": "Sunglasses suit him.",
    "category": "Noun"
  },
  {
    "term": "結構",
    "termReading": "けっこう",
    "termTranslation": "quite",
    "ex": "彼女は結構めがねが似合うね。",
    "exReading": "かのじょ は けっこう めがね が にあう ね",
    "exTranslation": "Glasses suit her well.",
    "category": "Adverb"
  },
  {
    "term": "構う",
    "termReading": "かまう",
    "termTranslation": "mind, care for",
    "ex": "子供に構い過ぎてはいけない。",
    "exReading": "こども に かまいすぎて は いけない",
    "exTranslation": "You shouldn't bother yourself too much with your kids.",
    "category": "Verb"
  },
  {
    "term": "位",
    "termReading": "くらい",
    "termTranslation": "rank, dignity",
    "ex": "位が上がれば責任も増えるものだ。",
    "exReading": "くらい が あがれ ば せきにん も ふえる もの だ",
    "exTranslation": "As you rise up the ranks, responsibility increases too.",
    "category": "Noun"
  },
  {
    "term": "設置",
    "termReading": "せっち",
    "termTranslation": "establishment",
    "ex": "お店に防犯カメラが設置されたわね。",
    "exReading": "お みせ に ぼうはん かめら が せっち された わ ね",
    "exTranslation": "A security camera was installed in the shop.",
    "category": "Verbal Noun"
  },
  {
    "term": "距離",
    "termReading": "きょり",
    "termTranslation": "distance, interval",
    "ex": "彼らは長い距離を歩き続けたんだ。",
    "exReading": "かれら は ながい きょり を あるきつづけた ん だ",
    "exTranslation": "They kept walking a long distance.",
    "category": "Noun"
  },
  {
    "term": "一周",
    "termReading": "いっしゅう",
    "termTranslation": "circle, around",
    "ex": "私たちは庭園を一周しました。",
    "exReading": "わたしたち は ていえん を いっしゅう しました",
    "exTranslation": "We went around the garden.",
    "category": "Verbal Noun"
  },
  {
    "term": "辺り",
    "termReading": "あたり",
    "termTranslation": "vicinity, around",
    "ex": "辺りを見回したの。",
    "exReading": "あたり を みまわした の",
    "exTranslation": "We looked around the area.",
    "category": "Noun"
  },
  {
    "term": "周囲",
    "termReading": "しゅうい",
    "termTranslation": "circumference, surroundings",
    "ex": "大声で話すと周囲の人に迷惑ですよ。",
    "exReading": "おおごえ で はなす と しゅうい の ひと に めいわく です よ",
    "exTranslation": "If you speak loudly you will disturb people around you.",
    "category": "Noun"
  },
  {
    "term": "きっちり",
    "termReading": "きっちり",
    "termTranslation": "exactly",
    "ex": "彼女はきっちり3時に来たわ。",
    "exReading": "かのじょ は きっちり さんじ に きた わ",
    "exTranslation": "She came at three o'clock precisely.",
    "category": "Adverb"
  },
  {
    "term": "囲む",
    "termReading": "かこむ",
    "termTranslation": "enclose, encircle",
    "ex": "久しぶりに家族全員で食卓を囲みました。",
    "exReading": "ひさしぶり に かぞく ぜんいん で しょくたく を かこみました",
    "exTranslation": "We had the entire family around the dinner table for the first time in a long time.",
    "category": "Verb"
  },
  {
    "term": "横断",
    "termReading": "おうだん",
    "termTranslation": "crossing, traversing",
    "ex": "道路を横断するときは注意して。",
    "exReading": "どうろ を おうだん する とき は ちゅうい して",
    "exTranslation": "Be careful when crossing the road.",
    "category": "Verbal Noun"
  },
  {
    "term": "継続",
    "termReading": "けいぞく",
    "termTranslation": "continuation, succession",
    "ex": "ものごとは根気よく継続することが大切です。",
    "exReading": "もの ごと は こんき よく けいぞく する こと が たいせつ です",
    "exTranslation": "When doing anything, it's important to keep at it persistently.",
    "category": "Verbal Noun"
  },
  {
    "term": "欧米",
    "termReading": "おうべい",
    "termTranslation": "Europe and America",
    "ex": "その会社は欧米に進出しているよね。",
    "exReading": "その かいしゃ は おうべい に しんしゅつ して いる よ ね",
    "exTranslation": "That company has moved into Europe and the United States.",
    "category": "Noun"
  },
  {
    "term": "州",
    "termReading": "しゅう",
    "termTranslation": "state",
    "ex": "来月、隣の州に引っ越します。",
    "exReading": "らいげつ となり の しゅう に ひっこします",
    "exTranslation": "I will move to the neighboring state next month.",
    "category": "Noun"
  },
  {
    "term": "陸",
    "termReading": "おか",
    "termTranslation": "land, shore",
    "ex": "私たちは舟を降りて、陸に上がったんだ。",
    "exReading": "わたしたち は ふね を おりて おか に あがった ん だ",
    "exTranslation": "We got out of the boat and stepped onto shore.",
    "category": "Noun"
  },
  {
    "term": "極めて",
    "termReading": "きわめて",
    "termTranslation": "extremely, very",
    "ex": "これは極めて重要な問題です。",
    "exReading": "これ は きわめて じゅうよう な もんだい です",
    "exTranslation": "This is a vitally important question.",
    "category": "Adverb"
  },
  {
    "term": "きゅうり",
    "termReading": "きゅうり",
    "termTranslation": "cucumber",
    "ex": "きゅうりに味噌をつけて食べたの。",
    "exReading": "きゅうり に みそ を つけて たべた の",
    "exTranslation": "I ate cucumber with miso on it.",
    "category": "Noun"
  },
  {
    "term": "消極的",
    "termReading": "しょうきょくてき",
    "termTranslation": "negative, passive",
    "ex": "消極的な人は成功しないよ。",
    "exReading": "しょうきょくてき な ひと は せいこう しない よ",
    "exTranslation": "A passive person doesn't succeed.",
    "category": "Adjective"
  },
  {
    "term": "極端",
    "termReading": "きょくたん",
    "termTranslation": "extreme",
    "ex": "それは極端な意見だね。",
    "exReading": "それ は きょくたん な いけん だ ね",
    "exTranslation": "That is an extreme opinion.",
    "category": "Adjectival Noun"
  },
  {
    "term": "最先端",
    "termReading": "さいせんたん",
    "termTranslation": "cutting edge, forefront",
    "ex": "そのカメラには最先端の技術が使われています。",
    "exReading": "その かめら に は さいせんたん の ぎじゅつ が つかわれて います",
    "exTranslation": "State-of-the-art technology is used in that camera.",
    "category": "Noun"
  },
  {
    "term": "一緒",
    "termReading": "いっしょ",
    "termTranslation": "together",
    "ex": "一緒に食事しようか。",
    "exReading": "いっしょ に しょくじ しよう か",
    "exTranslation": "Do you want to eat together?",
    "category": "Verbal Noun"
  },
  {
    "term": "外貨",
    "termReading": "がいか",
    "termTranslation": "foreign currency",
    "ex": "外貨を両替しました。",
    "exReading": "がいか を りょうがえ しました",
    "exTranslation": "I exchanged some foreign currency.",
    "category": "Noun"
  },
  {
    "term": "貨物",
    "termReading": "かもつ",
    "termTranslation": "freight, cargo",
    "ex": "このトランクは貨物で送ろう。",
    "exReading": "この とらんく は かもつ で おくろう",
    "exTranslation": "Let's send this trunk by freight.",
    "category": "Noun"
  },
  {
    "term": "車輪",
    "termReading": "しゃりん",
    "termTranslation": "wheel",
    "ex": "このトラックの車輪は頑丈そうですね。",
    "exReading": "この とらっく の しゃりん は がんじょう そう です ね",
    "exTranslation": "The wheels of this truck look strong.",
    "category": "Noun"
  },
  {
    "term": "すっと",
    "termReading": "すっと",
    "termTranslation": "clear",
    "ex": "言いたいことを言ったら胸がすっとした。",
    "exReading": "いいたい こと を いったら むね が すっと した",
    "exTranslation": "I felt relieved after I said what I wanted to say.",
    "category": "Adverb"
  },
  {
    "term": "回復",
    "termReading": "かいふく",
    "termTranslation": "recovery, rehabilitation",
    "ex": "体がすっかり回復した。",
    "exReading": "からだ が すっかり かいふく した",
    "exTranslation": "I've completely recovered.",
    "category": "Verbal Noun"
  },
  {
    "term": "渋い",
    "termReading": "しぶい",
    "termTranslation": "bitter, astringent",
    "ex": "この柿は渋いね。",
    "exReading": "この かき は しぶい ね",
    "exTranslation": "This persimmon is bitter.",
    "category": "Adjective"
  },
  {
    "term": "渋滞",
    "termReading": "じゅうたい",
    "termTranslation": "delay, traffic jam",
    "ex": "この国道はよく渋滞します。",
    "exReading": "この こくどう は よく じゅうたい します",
    "exTranslation": "This national highway is often congested.",
    "category": "Verbal Noun"
  },
  {
    "term": "一帯",
    "termReading": "いったい",
    "termTranslation": "area, vicinity",
    "ex": "この辺一帯はリンゴ畑です。",
    "exReading": "この へん いったい は りんごばたけ です",
    "exTranslation": "The whole area here is apple orchards.",
    "category": "Noun"
  },
  {
    "term": "帯",
    "termReading": "おび",
    "termTranslation": "belt, sash",
    "ex": "この帯は長過ぎます。",
    "exReading": "この おび は なが すぎます",
    "exTranslation": "This sash is too long.",
    "category": "Noun"
  },
  {
    "term": "温帯",
    "termReading": "おんたい",
    "termTranslation": "temperate zone",
    "ex": "日本は温帯にあります。",
    "exReading": "にっぽん は おんたい に あります",
    "exTranslation": "Japan is located in the temperate zone.",
    "category": "Noun"
  },
  {
    "term": "守備",
    "termReading": "しゅび",
    "termTranslation": "defense, fielding",
    "ex": "そのチームは守備が甘いね。",
    "exReading": "その ちーむ は しゅび が あまい ね",
    "exTranslation": "The team's defense is soft.",
    "category": "Verbal Noun"
  },
  {
    "term": "帰宅",
    "termReading": "きたく",
    "termTranslation": "return home",
    "ex": "夜の11時に帰宅しました。",
    "exReading": "よる の じゅういちじ に きたく しました",
    "exTranslation": "I came home at 11 o'clock in the evening.",
    "category": "Verbal Noun"
  },
  {
    "term": "すらすら",
    "termReading": "すらすら",
    "termTranslation": "unhesitantly, confidently",
    "ex": "その小学生は難しい本をすらすら読んだの。",
    "exReading": "その しょうがくせい は むずかしい ほん を すらすら よんだ の",
    "exTranslation": "That elementary school student read a difficult book easily.",
    "category": "Adverb"
  },
  {
    "term": "住宅地",
    "termReading": "じゅうたくち",
    "termTranslation": "residential district",
    "ex": "その住宅地は便利な場所にあるね。",
    "exReading": "その じゅうたくち は べんり な ばしょ に ある ね",
    "exTranslation": "That residential area is conveniently located.",
    "category": "Noun"
  },
  {
    "term": "宛先",
    "termReading": "あてさき",
    "termTranslation": "recipient, addressee",
    "ex": "宛先不明で手紙が戻ってきたの。",
    "exReading": "あてさき ふめい で てがみ が もどって きた の",
    "exTranslation": "The letter was returned as the addressee was unknown.",
    "category": "Noun"
  },
  {
    "term": "宛名",
    "termReading": "あてな",
    "termTranslation": "name of recipient, addressee",
    "ex": "手紙に宛名を書き込んだよ。",
    "exReading": "てがみ に あてな を かきこんだ よ",
    "exTranslation": "I filled in the name of the addressee on the letter.",
    "category": "Noun"
  },
  {
    "term": "後戻り",
    "termReading": "あともどり",
    "termTranslation": "turning back, retreat",
    "ex": "彼女は途中で後戻りしました。",
    "exReading": "かのじょ は とちゅう で あともどり しました",
    "exTranslation": "She turned back half-way through.",
    "category": "Verbal Noun"
  },
  {
    "term": "起こす",
    "termReading": "おこす",
    "termTranslation": "bring about, cause",
    "ex": "彼は暴力事件を起こしたんだよ。",
    "exReading": "かれ は ぼうりょく じけん を おこした ん だ よ",
    "exTranslation": "He caused a violent incident.",
    "category": "Verb"
  },
  {
    "term": "起源",
    "termReading": "きげん",
    "termTranslation": "origin, beginning",
    "ex": "言葉の起源に大変興味があります。",
    "exReading": "ことば の きげん に たいへん きょうみ が あります",
    "exTranslation": "I am very interested in the origins of words.",
    "category": "Noun"
  },
  {
    "term": "起き上がる",
    "termReading": "おきあがる",
    "termTranslation": "get up, rise",
    "ex": "弟はようやくベッドから起き上がった。",
    "exReading": "おとうと は ようやく べっど から おきあがった。",
    "exTranslation": "Finally my younger brother got out of bed.",
    "category": "Verb"
  },
  {
    "term": "うろうろ",
    "termReading": "うろうろ",
    "termTranslation": "wander",
    "ex": "その男はロビーをしばらくうろうろしていたよ。",
    "exReading": "その おとこ は ろびー を しばらく うろうろ して いた よ",
    "exTranslation": "That man was wandering about the lobby for a while.",
    "category": "Adverb"
  },
  {
    "term": "寝室",
    "termReading": "しんしつ",
    "termTranslation": "bedroom",
    "ex": "寝室の壁紙を張り替えました。",
    "exReading": "しんしつ の かべがみ を はりかえました",
    "exTranslation": "I repapered the bedroom walls.",
    "category": "Noun"
  },
  {
    "term": "静まる",
    "termReading": "しずまる",
    "termTranslation": "become quiet, calm down",
    "ex": "台風が去って風が静まったね。",
    "exReading": "たいふう が さって かぜ が しずまった ね",
    "exTranslation": "Because the typhoon has passed, the wind has dropped.",
    "category": "Verb"
  },
  {
    "term": "休暇",
    "termReading": "きゅうか",
    "termTranslation": "holiday, vacation",
    "ex": "今度の休暇にフィリピンに行きます。",
    "exReading": "こんど の きゅうか に ふぃりぴん に いきます",
    "exTranslation": "I am going to the Philippines for my next vacation.",
    "category": "Noun"
  },
  {
    "term": "片手",
    "termReading": "かたて",
    "termTranslation": "one hand",
    "ex": "片手運転は危ないよ。",
    "exReading": "かたて うんてん は あぶないよ",
    "exTranslation": "Single-handed driving is dangerous.",
    "category": "Noun"
  },
  {
    "term": "片方",
    "termReading": "かたほう",
    "termTranslation": "one side",
    "ex": "片方の目がかゆいです。",
    "exReading": "かたほう の め が かゆい です",
    "exTranslation": "One of my eyes is itchy.",
    "category": "Noun"
  },
  {
    "term": "片側",
    "termReading": "かたがわ",
    "termTranslation": "one side",
    "ex": "この道は片側通行です。",
    "exReading": "この みち は かたがわ つうこう です",
    "exTranslation": "This road is a one-way street.",
    "category": "Noun"
  },
  {
    "term": "裏切る",
    "termReading": "うらぎる",
    "termTranslation": "betray",
    "ex": "彼は仲間を裏切ったの。",
    "exReading": "かれ は なかま を うらぎった の",
    "exTranslation": "He betrayed his friends.",
    "category": "Verb"
  },
  {
    "term": "おまけ",
    "termReading": "おまけ",
    "termTranslation": "free gift",
    "ex": "八百屋さんでたくさんおまけしてもらったよ。",
    "exReading": "やおや さん で たくさん おまけ して もらった よ",
    "exTranslation": "I got lots of freebies at the vegetable store.",
    "category": "Verbal Noun"
  },
  {
    "term": "裏口",
    "termReading": "うらぐち",
    "termTranslation": "back door, kitchen door",
    "ex": "裏口へお回りください。",
    "exReading": "うらぐち へ おまわり ください",
    "exTranslation": "Please go around to the back door.",
    "category": "Noun"
  },
  {
    "term": "裏返し",
    "termReading": "うらがえし",
    "termTranslation": "turn over, turn inside out",
    "ex": "シャツを裏返しに着ているよ。",
    "exReading": "しゃつ を うらがえし に きて いる よ",
    "exTranslation": "Your shirt is inside out.",
    "category": "Verbal Noun"
  },
  {
    "term": "裏門",
    "termReading": "うらもん",
    "termTranslation": "back gate",
    "ex": "奴は裏門から出てきたぜ。",
    "exReading": "やつ は うらもん から でて きた ぜ",
    "exTranslation": "He came out from the back gate.",
    "category": "Noun"
  },
  {
    "term": "裏表",
    "termReading": "うらおもて",
    "termTranslation": "inside-out, front and back",
    "ex": "シャツを裏表に着ていますよ。",
    "exReading": "しゃつ を うらおもて に きて います よ",
    "exTranslation": "You are wearing your shirt inside-out.",
    "category": "Noun"
  },
  {
    "term": "項目",
    "termReading": "こうもく",
    "termTranslation": "item, clause",
    "ex": "論文の項目を分かりやすく整理したわ。",
    "exReading": "ろんぶん の こうもく を わかりやすく せいり した わ",
    "exTranslation": "I arranged the items of the thesis in an approachable manner.",
    "category": "Noun"
  },
  {
    "term": "印象",
    "termReading": "いんしょう",
    "termTranslation": "impression",
    "ex": "彼から良い印象を受けたわ。",
    "exReading": "かれ から いい いんしょう を うけた わ",
    "exTranslation": "I got a good impression from him.",
    "category": "Noun"
  },
  {
    "term": "印",
    "termReading": "しるし",
    "termTranslation": "mark, symbol",
    "ex": "間違いに印を付けておきました。",
    "exReading": "まちがい に しるし を つけて おきました",
    "exTranslation": "I marked the mistakes.",
    "category": "Noun"
  },
  {
    "term": "クッキー",
    "termReading": "クッキー",
    "termTranslation": "cookie, biscuit",
    "ex": "クッキーと紅茶をいただきました。",
    "exReading": "くっきー と こうちゃ を いただきました",
    "exTranslation": "I had cookies and tea.",
    "category": "Noun"
  },
  {
    "term": "印刷",
    "termReading": "いんさつ",
    "termTranslation": "printing",
    "ex": "年賀状を印刷したの。",
    "exReading": "ねんがじょう を いんさつ した の",
    "exTranslation": "I printed New Year's cards.",
    "category": "Verbal Noun"
  },
  {
    "term": "週刊",
    "termReading": "しゅうかん",
    "termTranslation": "published weekly",
    "ex": "この週刊誌を毎週買っているの。",
    "exReading": "この しゅうかんし を まいしゅう かって いる の",
    "exTranslation": "I buy this weekly magazine every week.",
    "category": "Noun"
  },
  {
    "term": "月刊",
    "termReading": "げっかん",
    "termTranslation": "published monthly",
    "ex": "この雑誌は月刊ですか。",
    "exReading": "この ざっし は げっかん です か",
    "exTranslation": "Is this magazine monthly?",
    "category": "Noun"
  },
  {
    "term": "出版",
    "termReading": "しゅっぱん",
    "termTranslation": "publishing, publication",
    "ex": "この本は15年前に出版された。",
    "exReading": "この ほん は じゅうごねんまえ に しゅっぱん された",
    "exTranslation": "This book was published 15 years ago.",
    "category": "Verbal Noun"
  },
  {
    "term": "出版社",
    "termReading": "しゅっぱんしゃ",
    "termTranslation": "publishing company",
    "ex": "彼女は出版社で働いています。",
    "exReading": "かのじょ は しゅっぱんしゃ で はたらいて います",
    "exTranslation": "She works for a publisher.",
    "category": "Noun"
  },
  {
    "term": "詳細",
    "termReading": "しょうさい",
    "termTranslation": "details, particulars",
    "ex": "詳細はお気軽にお問い合わせ下さい。",
    "exReading": "しょうさい は おきがる に おといあわせ ください",
    "exTranslation": "Please do not hesitate to inquire for further details.",
    "category": "Adjectival Noun"
  },
  {
    "term": "心細い",
    "termReading": "こころぼそい",
    "termTranslation": "helpless, lonely",
    "ex": "夜道の一人歩きは心細いね。",
    "exReading": "よみち の ひとりあるき は こころぼそい ね",
    "exTranslation": "Walking alone at night makes one feel uneasy.",
    "category": "Adjective"
  },
  {
    "term": "掲示",
    "termReading": "けいじ",
    "termTranslation": "notice, bulletin",
    "ex": "大会のスローガンを掲示したよ。",
    "exReading": "たいかい の すろーがん を けいじ した よ",
    "exTranslation": "I posted the rally's slogan.",
    "category": "Verbal Noun"
  },
  {
    "term": "コンセント",
    "termReading": "コンセント",
    "termTranslation": "outlet, wall socket",
    "ex": "プラグをコンセントに挿し込んだよ。",
    "exReading": "ぷらぐ を こんせんと に さしこんだ よ",
    "exTranslation": "I put the plug into the outlet.",
    "category": "Noun"
  },
  {
    "term": "積極的",
    "termReading": "せっきょくてき",
    "termTranslation": "positive, active",
    "ex": "姉は何に対しても積極的です。",
    "exReading": "あね は なに に たいして も せっきょくてき です",
    "exTranslation": "My elder sister is positive about everything.",
    "category": "Adjective"
  },
  {
    "term": "言い訳",
    "termReading": "いいわけ",
    "termTranslation": "apology, excuse",
    "ex": "あなたは言い訳が多すぎます。",
    "exReading": "あなた は いいわけ が おおすぎます",
    "exTranslation": "You give too many excuses.",
    "category": "Verbal Noun"
  },
  {
    "term": "誤り",
    "termReading": "あやまり",
    "termTranslation": "error, mistake",
    "ex": "解答に誤りが3つ有りますよ。",
    "exReading": "かいとう に あやまり が みっつ あります よ",
    "exTranslation": "There are three errors in the answer.",
    "category": "Noun"
  },
  {
    "term": "誤る",
    "termReading": "あやまる",
    "termTranslation": "make a mistake",
    "ex": "彼は機械の操作を誤った。",
    "exReading": "かれ は きかい の そうさ を あやまった",
    "exTranslation": "He made a mistake operating the machine.",
    "category": "Verb"
  },
  {
    "term": "誤解",
    "termReading": "ごかい",
    "termTranslation": "misunderstanding",
    "ex": "私の気持ちを誤解しているんじゃない！",
    "exReading": "わたし の きもち を ごかい して いる ん じゃ ない",
    "exTranslation": "You have misunderstood my feelings, haven't you?",
    "category": "Verbal Noun"
  },
  {
    "term": "気付く",
    "termReading": "きづく",
    "termTranslation": "notice, become aware of",
    "ex": "彼はやっと問題点に気付きました。",
    "exReading": "かれ は やっと もんだいてん に きづきました",
    "exTranslation": "He finally noticed the problem.",
    "category": "Verb"
  },
  {
    "term": "くっ付く",
    "termReading": "くっつく",
    "termTranslation": "stick, cling",
    "ex": "靴の底にガムがくっ付いてしまった。",
    "exReading": "くつ の そこ に がむ が くっついて しまった",
    "exTranslation": "Chewing gum got stuck to the sole of my shoe.",
    "category": "Verb"
  },
  {
    "term": "ずるい",
    "termReading": "ずるい",
    "termTranslation": "cunning, sneaky",
    "ex": "あなたのやり方はずるい。",
    "exReading": "あなた の やりかた は ずるい",
    "exTranslation": "Your method is cunning.",
    "category": "Adjective"
  },
  {
    "term": "追い付く",
    "termReading": "おいつく",
    "termTranslation": "overtake, catch up with",
    "ex": "駅でようやく彼に追い付きました。",
    "exReading": "えき で ようやく かれ に おいつきました",
    "exTranslation": "I finally caught up with him at the station.",
    "category": "Verb"
  },
  {
    "term": "顔付き",
    "termReading": "かおつき",
    "termTranslation": "face, expression",
    "ex": "彼は恐い顔付きで話したの。",
    "exReading": "かれ は こわい かおつき で はなした の",
    "exTranslation": "He spoke with a scary look on his face.",
    "category": "Noun"
  },
  {
    "term": "思い付く",
    "termReading": "おもいつく",
    "termTranslation": "think of, hit upon",
    "ex": "彼は名案を思い付いたの。",
    "exReading": "かれ は めいあん を おもいついた の",
    "exTranslation": "He hit on a good idea.",
    "category": "Verb"
  },
  {
    "term": "後片付け",
    "termReading": "あとかたづけ",
    "termTranslation": "cleaning up",
    "ex": "食事の後片付けを手伝ったの。",
    "exReading": "しょくじ の あとかたづけ を てつだった の",
    "exTranslation": "I helped to clean up after the meal.",
    "category": "Verbal Noun"
  },
  {
    "term": "傷付く",
    "termReading": "きずつく",
    "termTranslation": "get injured, be hurt",
    "ex": "私は彼女の言葉に傷付きました。",
    "exReading": "わたし は かのじょ の ことば に きずつきました",
    "exTranslation": "I was hurt by her words.",
    "category": "Verb"
  },
  {
    "term": "傷付ける",
    "termReading": "きずつける",
    "termTranslation": "wound, hurt",
    "ex": "あなたを傷付けるつもりはありませんでした。",
    "exReading": "あなた を きずつける つもり は ありませんでした",
    "exTranslation": "I didn't intend to hurt you.",
    "category": "Verb"
  },
  {
    "term": "くっ付ける",
    "termReading": "くっつける",
    "termTranslation": "join, paste",
    "ex": "彼は壁に耳をくっ付けて隣の話を聞いていたの。",
    "exReading": "かれ は かべ に みみ を くっつけて となり の はなし を きいて いた の",
    "exTranslation": "He pressed his ear to the wall and listened to what the people next door were saying.",
    "category": "Verb"
  },
  {
    "term": "せっかち",
    "termReading": "せっかち",
    "termTranslation": "impatient",
    "ex": "彼はせっかちで困ります。",
    "exReading": "かれ は せっかち で こまります",
    "exTranslation": "His impatience troubles me.",
    "category": "Adjectival Noun"
  },
  {
    "term": "言付ける",
    "termReading": "ことづける",
    "termTranslation": "leave a message",
    "ex": "彼女への伝言を言付けたの。",
    "exReading": "かのじょ へ の でんごん を ことづけた の",
    "exTranslation": "I left her a message.",
    "category": "Verb"
  },
  {
    "term": "金属",
    "termReading": "きんぞく",
    "termTranslation": "metal",
    "ex": "アルミニウムは金属の一種です。",
    "exReading": "あるみにうむ は きんぞく の いっしゅ です",
    "exTranslation": "Aluminum is a kind of metal.",
    "category": "Noun"
  },
  {
    "term": "所属",
    "termReading": "しょぞく",
    "termTranslation": "belonging, attachment",
    "ex": "学校では音楽部に所属していました。",
    "exReading": "がっこう で は おんがくぶ に しょぞく して いました",
    "exTranslation": "I belonged to the music club at school.",
    "category": "Verbal Noun"
  },
  {
    "term": "大蔵省",
    "termReading": "おおくらしょう",
    "termTranslation": "Ministry of Finance",
    "ex": "彼は大蔵省に勤務しているんだよ。",
    "exReading": "かれ は おおくらしょう に きんむ して いる ん だ よ",
    "exTranslation": "He works for the Ministry of Finance.",
    "category": "Noun"
  },
  {
    "term": "外務省",
    "termReading": "がいむしょう",
    "termTranslation": "Ministry of Foreign Affairs",
    "ex": "ビザについて外務省に問い合わせた。",
    "exReading": "びざ に ついて がいむしょう に といあわせた",
    "exTranslation": "I contacted the Ministry of Foreign Affairs to enquire about the visa.",
    "category": "Noun"
  },
  {
    "term": "帰省",
    "termReading": "きせい",
    "termTranslation": "homecoming",
    "ex": "来週、帰省します。",
    "exReading": "らいしゅう きせい します",
    "exTranslation": "I will go home next week.",
    "category": "Verbal Noun"
  },
  {
    "term": "省略",
    "termReading": "しょうりゃく",
    "termTranslation": "omission, abbreviation",
    "ex": "地図内の小さい建物は省略してあります。",
    "exReading": "ちずない の ちいさい たてもの は しょうりゃく して あります",
    "exTranslation": "Small buildings have been omitted from the map.",
    "category": "Verbal Noun"
  },
  {
    "term": "概念",
    "termReading": "がいねん",
    "termTranslation": "general idea, concept",
    "ex": "インターネットは情報の概念を変えたよね。",
    "exReading": "いんたーねっと は じょうほう の がいねん を かえた よ ね",
    "exTranslation": "The Internet changed the concept of information.",
    "category": "Noun"
  },
  {
    "term": "うちわ",
    "termReading": "うちわ",
    "termTranslation": "round paper fan",
    "ex": "父はうちわで扇いでいるの。",
    "exReading": "ちち は うちわ で あおいで いる の",
    "exTranslation": "My father is fanning himself with a paper fan.",
    "category": "Noun"
  },
  {
    "term": "記念",
    "termReading": "きねん",
    "termTranslation": "commemoration, memory",
    "ex": "卒業の記念にみんなで旅行したよ。",
    "exReading": "そつぎょう の きねん に みんな で りょこう した よ",
    "exTranslation": "We all went on a trip to celebrate our graduation.",
    "category": "Verbal Noun"
  },
  {
    "term": "信念",
    "termReading": "しんねん",
    "termTranslation": "belief, conviction",
    "ex": "自分の信念に従いたいと思います。",
    "exReading": "じぶん の しんねん に したがいたい と おもいます",
    "exTranslation": "I think I want to follow my own faith.",
    "category": "Noun"
  },
  {
    "term": "順調",
    "termReading": "じゅんちょう",
    "termTranslation": "favorable condition, smooth progress",
    "ex": "進み具合は全て順調です。",
    "exReading": "すすみ ぐあい は すべて じゅんちょう です",
    "exTranslation": "It is all progressing well.",
    "category": "Adjectival Noun"
  },
  {
    "term": "順",
    "termReading": "じゅん",
    "termTranslation": "order, sequence",
    "ex": "あいうえお順に並べて下さい。",
    "exReading": "あいうえお じゅん に ならべて ください",
    "exTranslation": "Please line up in order of the Japanese syllabary.",
    "category": "Noun"
  },
  {
    "term": "順番",
    "termReading": "じゅんばん",
    "termTranslation": "order, turn",
    "ex": "これを順番通りに並べ替えて下さい。",
    "exReading": "これ を じゅんばん どおり に ならべ かえて ください",
    "exTranslation": "Please rearrange these in order.",
    "category": "Noun"
  },
  {
    "term": "順位",
    "termReading": "じゅんい",
    "termTranslation": "order, rank",
    "ex": "今年は去年よりも順位が上がりました。",
    "exReading": "ことし は きょねん より も じゅんい が あがりました",
    "exTranslation": "Our rank went up this year more than it did last year.",
    "category": "Noun"
  },
  {
    "term": "順々に",
    "termReading": "じゅんじゅんに",
    "termTranslation": "by turns, in order",
    "ex": "子供たちは順々にお菓子を受け取ったよ。",
    "exReading": "こどもたち は じゅんじゅんに おかし を うけとった よ",
    "exTranslation": "Children received snacks one at a time.",
    "category": "Adverb"
  },
  {
    "term": "うっかり",
    "termReading": "うっかり",
    "termTranslation": "carelessly, accidentally",
    "ex": "大事なメールをうっかり消してしまった。",
    "exReading": "だいじ な めーる を うっかり けして しまった",
    "exTranslation": "I accidentally deleted an important email.",
    "category": "Adverb"
  },
  {
    "term": "順序",
    "termReading": "じゅんじょ",
    "termTranslation": "order",
    "ex": "正しい順序で操作してください。",
    "exReading": "ただしい じゅんじょ で そうさ して ください",
    "exTranslation": "Please carry out the operations in the correct order.",
    "category": "Noun"
  },
  {
    "term": "逆",
    "termReading": "ぎゃく",
    "termTranslation": "reverse, inverse",
    "ex": "駅は逆方向です。",
    "exReading": "えき は ぎゃくほうこう です",
    "exTranslation": "The station is in the opposite direction.",
    "category": "Adjectival Noun"
  },
  {
    "term": "逆らう",
    "termReading": "さからう",
    "termTranslation": "disobey, go against",
    "ex": "親に逆らうのは良くない事です。",
    "exReading": "おや に さからう の は よくない こと です",
    "exTranslation": "It is not a good thing to disobey your parents.",
    "category": "Verb"
  },
  {
    "term": "逆さ",
    "termReading": "さかさ",
    "termTranslation": "reverse, inversion",
    "ex": "絵を逆さにしてみてごらん。",
    "exReading": "え を さかさ に して みて ごらん",
    "exTranslation": "Try looking at the picture upside down.",
    "category": "Adjectival Noun"
  },
  {
    "term": "逆さま",
    "termReading": "さかさま",
    "termTranslation": "upside-down, reverse",
    "ex": "それじゃあ上と下が逆さまだよ。",
    "exReading": "それじゃあ うえ と した が さかさま だ よ",
    "exTranslation": "The way you have it is upside down.",
    "category": "Adjectival Noun"
  },
  {
    "term": "行列",
    "termReading": "ぎょうれつ",
    "termTranslation": "queue, line",
    "ex": "店の前に長い行列ができていますよ。",
    "exReading": "みせ の まえ に ながい ぎょうれつ が できて います よ",
    "exTranslation": "There is a long line in front of the shop.",
    "category": "Verbal Noun"
  },
  {
    "term": "整列",
    "termReading": "せいれつ",
    "termTranslation": "row, line",
    "ex": "体育館に行って整列しなさい。",
    "exReading": "たいいくかん に いって せいれつ しなさい",
    "exTranslation": "Go to the gymnasium and line up.",
    "category": "Verbal Noun"
  },
  {
    "term": "くだらない",
    "termReading": "くだらない",
    "termTranslation": "worthless, trifling",
    "ex": "くだらないおしゃべりはやめなさい。",
    "exReading": "くだらないおしゃべりはやめなさい。",
    "exTranslation": "Stop chattering.",
    "category": "Adjective"
  },
  {
    "term": "実例",
    "termReading": "じつれい",
    "termTranslation": "example, instance",
    "ex": "実例を使って説明してください。",
    "exReading": "じつれい を つかって せつめい して ください",
    "exTranslation": "Please explain by using an actual example.",
    "category": "Noun"
  },
  {
    "term": "余り",
    "termReading": "あまり",
    "termTranslation": "remainder, leftover",
    "ex": "余りは次回使いましょう。",
    "exReading": "あまり は じかい つかいましょう",
    "exTranslation": "Let's use the left-over portion next time.",
    "category": "Adverb"
  },
  {
    "term": "削減",
    "termReading": "さくげん",
    "termTranslation": "curtailment, reduction",
    "ex": "福祉予算は削減されないことに決まったのよ。",
    "exReading": "ふくしよさん は さくげん されない こと に きまった の よ",
    "exTranslation": "They decided not to reduce the social welfare budget.",
    "category": "Verbal Noun"
  },
  {
    "term": "削除",
    "termReading": "さくじょ",
    "termTranslation": "deletion, elimination",
    "ex": "要らないファイルは削除して下さい。",
    "exReading": "いらない ふぁいる は さくじょ して ください",
    "exTranslation": "Please delete unneeded files.",
    "category": "Verbal Noun"
  },
  {
    "term": "既に",
    "termReading": "すでに",
    "termTranslation": "already, previously",
    "ex": "そのことは既にみんな知っています。",
    "exReading": "その こと は すでに みんな しって います",
    "exTranslation": "Everyone already knows that.",
    "category": "Adverb"
  },
  {
    "term": "既製",
    "termReading": "きせい",
    "termTranslation": "ready-made, ready-to-wear",
    "ex": "彼の体型じゃ既製のサイズに合わないよ。",
    "exReading": "かれ の たいけい じゃ きせい の さいず に あわない よ",
    "exTranslation": "His figure does not suit ready-to-wear clothing sizes.",
    "category": "Noun"
  },
  {
    "term": "深刻",
    "termReading": "しんこく",
    "termTranslation": "serious, grave",
    "ex": "これは深刻な問題です。",
    "exReading": "これ は しんこく な もんだい で す",
    "exTranslation": "This is a serious problem.",
    "category": "Adjective"
  },
  {
    "term": "刻む",
    "termReading": "きざむ",
    "termTranslation": "cut fine",
    "ex": "玉ねぎを細かく刻んでください。",
    "exReading": "たまねぎ を こまかく きざんで ください",
    "exTranslation": "Please chop the onion finely.",
    "category": "Verb"
  },
  {
    "term": "ずうずうしい",
    "termReading": "ずうずうしい",
    "termTranslation": "cheeky, impudent",
    "ex": "何てずうずうしい人なんだろう。",
    "exReading": "なんて ずうずうしい ひと なん だろう",
    "exTranslation": "What a cheeky fellow!",
    "category": "Adjective"
  },
  {
    "term": "時刻",
    "termReading": "じこく",
    "termTranslation": "time, hour",
    "ex": "ただ今の時刻は6時35分です。",
    "exReading": "ただいま の じこく は ろくじ さんじゅうごふん です",
    "exTranslation": "The time just now is 6:35.",
    "category": "Noun"
  },
  {
    "term": "締め切り",
    "termReading": "しめきり",
    "termTranslation": "deadline, time limit",
    "ex": "申し込みの締め切りはいつですか。",
    "exReading": "もうしこみ の しめきり は いつ です か",
    "exTranslation": "When is the deadline for the application?",
    "category": "Noun"
  },
  {
    "term": "締め切る",
    "termReading": "しめきる",
    "termTranslation": "close, fix a deadline",
    "ex": "応募受付は締め切りました。",
    "exReading": "おうぼ うけつけ は しめきりました",
    "exTranslation": "The deadline for receipt of applications is closed.",
    "category": "Verb"
  },
  {
    "term": "締まる",
    "termReading": "しまる",
    "termTranslation": "tighten, be compact",
    "ex": "レバーを右に回すと締まります。",
    "exReading": "ればー を みぎ に まわす と しまります",
    "exTranslation": "It will shut when the lever is turned to the right.",
    "category": "Verb"
  },
  {
    "term": "栄える",
    "termReading": "さかえる",
    "termTranslation": "flourish, thrive",
    "ex": "ここはかつてゴールドラッシュで栄えた町だよ。",
    "exReading": "ここ は かつて ごーるど らっしゅ で さかえた まち だ よ",
    "exTranslation": "This town once prospered during the Gold Rush.",
    "category": "Verb"
  },
  {
    "term": "栄養",
    "termReading": "えいよう",
    "termTranslation": "nutrition, nourishment",
    "ex": "豆腐は栄養のある食べ物です。",
    "exReading": "とうふ は えいよう の ある たべもの です",
    "exTranslation": "Bean curd is a nutritious food.",
    "category": "Noun"
  },
  {
    "term": "教養",
    "termReading": "きょうよう",
    "termTranslation": "culture, education",
    "ex": "彼女はとても教養のある人ですね。",
    "exReading": "かのじょ は とても きょうよう の ある ひと です ね",
    "exTranslation": "She is a well-educated person.",
    "category": "Noun"
  },
  {
    "term": "すやすや",
    "termReading": "すやすや",
    "termTranslation": "peaceful and content",
    "ex": "子供がすやすや眠っているね。",
    "exReading": "こども が すやすや ねむって いる ね",
    "exTranslation": "The child is sleeping soundly.",
    "category": "Adverb"
  },
  {
    "term": "休養",
    "termReading": "きゅうよう",
    "termTranslation": "rest, recreation",
    "ex": "彼女は今、休養中です。",
    "exReading": "かのじょ は いま きゅうようちゅう です",
    "exTranslation": "She is taking a rest now.",
    "category": "Verbal Noun"
  },
  {
    "term": "苦しむ",
    "termReading": "くるしむ",
    "termTranslation": "suffer, be troubled",
    "ex": "彼女はアレルギーに苦しんでいます。",
    "exReading": "かのじょ は あれるぎー に くるしんで います",
    "exTranslation": "She suffers from an allergy.",
    "category": "Verb"
  },
  {
    "term": "苦情",
    "termReading": "くじょう",
    "termTranslation": "complaint, grievance",
    "ex": "店に苦情の電話をかけました。",
    "exReading": "みせ に くじょう の でんわ を かけました",
    "exTranslation": "I phoned the shop to complain.",
    "category": "Noun"
  },
  {
    "term": "苦心",
    "termReading": "くしん",
    "termTranslation": "pains, efforts",
    "ex": "苦心して絵を描き上げました。",
    "exReading": "くしん して え を かきあげました",
    "exTranslation": "I took pains over finishing the picture.",
    "category": "Verbal Noun"
  },
  {
    "term": "苦しみ",
    "termReading": "くるしみ",
    "termTranslation": "distress, suffering",
    "ex": "誰も彼の苦しみを理解していなかったな。",
    "exReading": "だれ も かれ の くるしみ を りかい して いなかった な",
    "exTranslation": "No one recognized his suffering.",
    "category": "Noun"
  },
  {
    "term": "苦痛",
    "termReading": "くつう",
    "termTranslation": "pain, agony",
    "ex": "私は人前で話すことが苦痛です。",
    "exReading": "わたし は ひとまえ で はなす こと が くつう です",
    "exTranslation": "It is painful for me to speak in front of other people.",
    "category": "Noun"
  },
  {
    "term": "苦しめる",
    "termReading": "くるしめる",
    "termTranslation": "torment, trouble",
    "ex": "強い日差しが選手たちを苦しめたの。",
    "exReading": "つよい ひざし が せんしゅたち を くるしめた の",
    "exTranslation": "The strong sunlight troubled the athletes.",
    "category": "Verb"
  },
  {
    "term": "セルフサービス",
    "termReading": "セルフサービス",
    "termTranslation": "self-service",
    "ex": "この食堂はセルフサービスです。",
    "exReading": "この しょくどう は せるふさーびす です",
    "exTranslation": "This dining hall is self-service.",
    "category": "Noun"
  },
  {
    "term": "苦労",
    "termReading": "くろう",
    "termTranslation": "difficulty, hardship",
    "ex": "母は苦労して私たちを育てたの。",
    "exReading": "はは は くろう して わたしたち を そだてた の",
    "exTranslation": "My mother had a hard time bringing us up.",
    "category": "Verbal Noun"
  },
  {
    "term": "勤労",
    "termReading": "きんろう",
    "termTranslation": "labor, work",
    "ex": "父は長い勤労生活を送ったんです。",
    "exReading": "ちち は ながい きんろう せいかつ を おくった ん です",
    "exTranslation": "My father had a long working career.",
    "category": "Verbal Noun"
  },
  {
    "term": "過労",
    "termReading": "かろう",
    "termTranslation": "overwork",
    "ex": "彼は過労のために倒れたの。",
    "exReading": "かれ は かろう の ため に たおれた の",
    "exTranslation": "He collapsed from overworking.",
    "category": "Noun"
  },
  {
    "term": "困難",
    "termReading": "こんなん",
    "termTranslation": "difficulty, hardship",
    "ex": "困難にあってもあきらめてはいけないよ。",
    "exReading": "こんなん に あって も あきらめて は いけない よ",
    "exTranslation": "You must not give up even if you encounter difficulties.",
    "category": "Verbal Noun"
  },
  {
    "term": "甘える",
    "termReading": "あまえる",
    "termTranslation": "depend on, take advantage of",
    "ex": "彼女の親切に甘えました。",
    "exReading": "かのじょ の しんせつ に あまえました",
    "exTranslation": "I depended on her kindness.",
    "category": "Verb"
  },
  {
    "term": "甘やかす",
    "termReading": "あまやかす",
    "termTranslation": "be indulgent to, spoil",
    "ex": "子供を甘やかしてはいけないの。",
    "exReading": "こども を あまやかしては いけない の",
    "exTranslation": "You must not spoil children.",
    "category": "Verb"
  },
  {
    "term": "辛い",
    "termReading": "からい",
    "termTranslation": "strict, severe",
    "ex": "部長は僕の仕事に辛い評価を出したんだ。",
    "exReading": "ぶちょう は ぼく の しごと に からい ひょうか を だした ん だ",
    "exTranslation": "My manager was very critical in his evaluation of my work.",
    "category": "Adjective"
  },
  {
    "term": "アルファベット",
    "termReading": "アルファベット",
    "termTranslation": "alphabet",
    "ex": "この子はアルファベットを全部言えます。",
    "exReading": "この こ は あるふぁべっと を ぜんぶ いえます",
    "exTranslation": "This kid can say the entire alphabet.",
    "category": "Noun"
  },
  {
    "term": "幸い",
    "termReading": "さいわい",
    "termTranslation": "happiness, good fortune",
    "ex": "幸い、電車に嵐の影響はなかった。",
    "exReading": "さいわい でんしゃ に あらし の えいきょう は なかった",
    "exTranslation": "Fortunately, the train was not affected by the storm.",
    "category": "Adjectival Noun"
  },
  {
    "term": "幸運",
    "termReading": "こううん",
    "termTranslation": "good fortune, good luck",
    "ex": "幸運にもチケットを手に入れました。",
    "exReading": "こううん に も ちけっと を て に いれました",
    "exTranslation": "Fortunately, I got a ticket.",
    "category": "Adjectival Noun"
  },
  {
    "term": "幸福",
    "termReading": "こうふく",
    "termTranslation": "happiness, good fortune",
    "ex": "彼女は幸福な日々を過ごしているわ。",
    "exReading": "かのじょ は こうふく な ひび を すごして いる わ",
    "exTranslation": "She's leading a happy life.",
    "category": "Adjectival Noun"
  },
  {
    "term": "砂",
    "termReading": "すな",
    "termTranslation": "sand, grit",
    "ex": "靴に砂が入ってしまった。",
    "exReading": "くつ に すな が はいって しまった",
    "exTranslation": "Sand has got into my shoes.",
    "category": "Noun"
  },
  {
    "term": "砂漠",
    "termReading": "さばく",
    "termTranslation": "desert",
    "ex": "砂漠ではほとんど雨が降らないのよ。",
    "exReading": "さばく で は ほとんど あめ が ふらない の よ",
    "exTranslation": "It hardly rains in the desert.",
    "category": "Noun"
  },
  {
    "term": "漁業",
    "termReading": "ぎょぎょう",
    "termTranslation": "fishing industry",
    "ex": "彼らは漁業を営んでいるんだ。",
    "exReading": "かれら は ぎょぎょう を いとなん でいる ん だ 。",
    "exTranslation": "They are engaged in a fishing business.",
    "category": "Noun"
  },
  {
    "term": "薄暗い",
    "termReading": "うすぐらい",
    "termTranslation": "gloomy, dim",
    "ex": "外はもう薄暗くなりましたよ。",
    "exReading": "そと は もう うすぐらく なりました よ",
    "exTranslation": "It is already getting dark outside.",
    "category": "Adjective"
  },
  {
    "term": "薄める",
    "termReading": "うすめる",
    "termTranslation": "thin, dilute",
    "ex": "スープを少し薄めましょうか。",
    "exReading": "すーぷ を すこし うすめましょう か",
    "exTranslation": "Shall I water down the soup a little?",
    "category": "Verb"
  },
  {
    "term": "いびき",
    "termReading": "いびき",
    "termTranslation": "snoring",
    "ex": "父のいびきは大きいんだ。",
    "exReading": "ちち の いびき は おおきい ん だ",
    "exTranslation": "My father snores loudly.",
    "category": "Noun"
  },
  {
    "term": "薄着",
    "termReading": "うすぎ",
    "termTranslation": "light clothing",
    "ex": "子供たちは冬でも薄着で通します。",
    "exReading": "こどもたち は ふゆ で も うすぎ で とおします",
    "exTranslation": "The kids are dressed lightly even through the winter.",
    "category": "Verbal Noun"
  },
  {
    "term": "厚着",
    "termReading": "あつぎ",
    "termTranslation": "heavy clothing",
    "ex": "普段から厚着しないようにしています。",
    "exReading": "ふだん から あつぎ しない よう に して います",
    "exTranslation": "I usually try not to wear heavy clothes.",
    "category": "Verbal Noun"
  },
  {
    "term": "圧力",
    "termReading": "あつりょく",
    "termTranslation": "pressure",
    "ex": "相手会社から強い圧力が掛かった。",
    "exReading": "あいてがいしゃ から つよい あつりょく が かかった",
    "exTranslation": "The other company put on a lot of pressure.",
    "category": "Noun"
  },
  {
    "term": "気圧",
    "termReading": "きあつ",
    "termTranslation": "atmospheric pressure",
    "ex": "高い山は気圧が低いね。",
    "exReading": "たかい やま は きあつ が ひくい ね",
    "exTranslation": "In high mountains the atmospheric pressure is low.",
    "category": "Noun"
  },
  {
    "term": "高気圧",
    "termReading": "こうきあつ",
    "termTranslation": "high pressure",
    "ex": "この暑さは高気圧のせいです。",
    "exReading": "この あつさ は こうきあつ の せい です",
    "exTranslation": "This heat is due to high atmospheric pressure.",
    "category": "Noun"
  },
  {
    "term": "縮小",
    "termReading": "しゅくしょう",
    "termTranslation": "reduction, curtailment",
    "ex": "これを縮小して印刷してください。",
    "exReading": "これ を しゅくしょう して いんさつ して ください",
    "exTranslation": "Please reduce and print this.",
    "category": "Verbal Noun"
  },
  {
    "term": "越す",
    "termReading": "こす",
    "termTranslation": "go over, surmount",
    "ex": "暑さは峠を越したね。",
    "exReading": "あつさ は とうげ を こした ね",
    "exTranslation": "The worst of the heat has passed.",
    "category": "Verb"
  },
  {
    "term": "いやらしい",
    "termReading": "いやらしい",
    "termTranslation": "disgusting, nasty",
    "ex": "彼はいやらしい男ね。",
    "exReading": "かれ は いやらしい おとこ ね",
    "exTranslation": "He's a nasty man.",
    "category": "Adjective"
  },
  {
    "term": "追い抜く",
    "termReading": "おいぬく",
    "termTranslation": "pass, overtake",
    "ex": "リレーで彼は2人追い抜いたね。",
    "exReading": "りれー で かれ は ふたり おいぬいた ね",
    "exTranslation": "He overtook two people in the relay.",
    "category": "Verb"
  },
  {
    "term": "札",
    "termReading": "さつ",
    "termTranslation": "bill, paper money",
    "ex": "彼はカバンから札の束を取り出したんだ。",
    "exReading": "かれ は かばん から さつ の たば を とりだした ん だ 。",
    "exTranslation": "He took a bundle of cash out of his bag.",
    "category": "Noun"
  },
  {
    "term": "改札",
    "termReading": "かいさつ",
    "termTranslation": "ticket gate",
    "ex": "改札で3時に会おう。",
    "exReading": "かいさつ で さんじ に あおう",
    "exTranslation": "Let's meet at the ticket gate at 3 o'clock.",
    "category": "Verbal Noun"
  },
  {
    "term": "感謝",
    "termReading": "かんしゃ",
    "termTranslation": "gratitude, thanks",
    "ex": "家族に感謝しています。",
    "exReading": "かぞく に かんしゃ して います",
    "exTranslation": "I am grateful to my family.",
    "category": "Verbal Noun"
  },
  {
    "term": "月謝",
    "termReading": "げっしゃ",
    "termTranslation": "monthly fee",
    "ex": "先生に月謝を渡しましたか。",
    "exReading": "せんせい に げっしゃ を わたしました か",
    "exTranslation": "Did you give the monthly fee to the teacher?",
    "category": "Noun"
  },
  {
    "term": "射す",
    "termReading": "さす",
    "termTranslation": "shine on",
    "ex": "今日は久しぶりに日が射してるね。",
    "exReading": "きょう は ひさしぶり に ひ が さして る ね",
    "exTranslation": "The sun is shining today for the first time in a long time.",
    "category": "Verb"
  },
  {
    "term": "過程",
    "termReading": "かてい",
    "termTranslation": "process, course",
    "ex": "プログラムの過程に問題があるの。",
    "exReading": "ぷろぐらむ の かてい に もんだい が ある の",
    "exTranslation": "There is a problem in the programming process.",
    "category": "Noun"
  },
  {
    "term": "イントネーション",
    "termReading": "イントネーション",
    "termTranslation": "intonation",
    "ex": "イントネーションの違いに気を付けて。",
    "exReading": "いんとねーしょん の ちがい に き を つけて",
    "exTranslation": "Be careful about the difference in intonation.",
    "category": "Noun"
  },
  {
    "term": "課程",
    "termReading": "かてい",
    "termTranslation": "course, curriculum",
    "ex": "一年生の課程を修了したの。",
    "exReading": "いちねんせい の かてい を しゅうりょう した の",
    "exTranslation": "He completed the first-year curriculum.",
    "category": "Noun"
  },
  {
    "term": "行程",
    "termReading": "こうてい",
    "termTranslation": "distance, journey",
    "ex": "全部で6時間の行程です。",
    "exReading": "ぜんぶ で ろくじかん の こうてい です",
    "exTranslation": "It is a six-hour journey in all.",
    "category": "Noun"
  },
  {
    "term": "先程",
    "termReading": "さきほど",
    "termTranslation": "some time ago, a little while ago",
    "ex": "その件でしたら、先程上司から許可を得ております。",
    "exReading": "その けん でしたら さきほど じょうし から きょか を えて おります",
    "exTranslation": "If you're referring to that matter, I obtained permission from my boss a little while ago.",
    "category": "Noun"
  },
  {
    "term": "優れる",
    "termReading": "すぐれる",
    "termTranslation": "be superior to, be fine",
    "ex": "彼は非常に優れた選手です。",
    "exReading": "かれ は ひじょうに すぐれた せんしゅ です",
    "exTranslation": "He is an excellent athlete.",
    "category": "Verb"
  },
  {
    "term": "女優",
    "termReading": "じょゆう",
    "termTranslation": "actress",
    "ex": "彼女はずっと女優になるのが夢でした。",
    "exReading": "かのじょ は ずっと じょゆう に なる の が ゆめ でした",
    "exTranslation": "It has always been her dream to become an actress.",
    "category": "Noun"
  },
  {
    "term": "透き通る",
    "termReading": "すきとおる",
    "termTranslation": "be transparent",
    "ex": "湖の水は透き通っていたよ。",
    "exReading": "みずうみ の みず は すきとおって いた よ",
    "exTranslation": "The water of the lake was transparent.",
    "category": "Verb"
  },
  {
    "term": "指導",
    "termReading": "しどう",
    "termTranslation": "guide, lead",
    "ex": "彼は生徒の指導が上手ね。",
    "exReading": "かれ は せいと の しどう が じょうず ね",
    "exTranslation": "He's good at instructing the students.",
    "category": "Verbal Noun"
  },
  {
    "term": "希望",
    "termReading": "きぼう",
    "termTranslation": "hope, wish",
    "ex": "彼は本社で働くことを希望しています。",
    "exReading": "かれ は ほんしゃ で はたらく こと を きぼう して います",
    "exTranslation": "He hopes to work at the the head office.",
    "category": "Verbal Noun"
  },
  {
    "term": "がたがた",
    "termReading": "がたがた",
    "termTranslation": "rattle",
    "ex": "この椅子はがたがたしているね。",
    "exReading": "この いす は がたがた して いる ね",
    "exTranslation": "This chair is unstable.",
    "category": "Adjective"
  },
  {
    "term": "失望",
    "termReading": "しつぼう",
    "termTranslation": "disappointment, loss of hope",
    "ex": "彼女は結婚生活に失望していたの。",
    "exReading": "かのじょ は けっこん せいかつ に しつぼう して いた の",
    "exTranslation": "She found married life disappointing.",
    "category": "Verbal Noun"
  },
  {
    "term": "意志",
    "termReading": "いし",
    "termTranslation": "will, volition",
    "ex": "彼は意志の強い人です。",
    "exReading": "かれ は いし の つよい ひと です",
    "exTranslation": "He is a strong-willed man.",
    "category": "Noun"
  },
  {
    "term": "志す",
    "termReading": "こころざす",
    "termTranslation": "have an ambition for, aspire",
    "ex": "私は医者を志しています。",
    "exReading": "わたし は いしゃ を こころざして います",
    "exTranslation": "I am determined to become a doctor.",
    "category": "Verb"
  },
  {
    "term": "怒り",
    "termReading": "いかり",
    "termTranslation": "anger, rage",
    "ex": "彼ったら怒り爆発だったよ。",
    "exReading": "かれ ったら いかり ばくはつ だった よ",
    "exTranslation": "He blew his stack.",
    "category": "Noun"
  },
  {
    "term": "自身",
    "termReading": "じしん",
    "termTranslation": "self",
    "ex": "自分自身を疑ってはいけません。",
    "exReading": "じぶん じしん を うたがって は いけません",
    "exTranslation": "Don't doubt yourself.",
    "category": "Noun"
  },
  {
    "term": "出身",
    "termReading": "しゅっしん",
    "termTranslation": "come from, origin",
    "ex": "家内は九州出身です。",
    "exReading": "かない は きゅうしゅう しゅっしん です",
    "exTranslation": "My wife is from Kyushu.",
    "category": "Noun"
  },
  {
    "term": "全身",
    "termReading": "ぜんしん",
    "termTranslation": "the whole body",
    "ex": "運動した翌日は全身の筋肉が痛い。",
    "exReading": "うんどう した よくじつ は ぜんしん の きんにく が いたい",
    "exTranslation": "I have muscular pain all over my body the day after I exercise.",
    "category": "Noun"
  },
  {
    "term": "カンニング",
    "termReading": "カンニング",
    "termTranslation": "cheating",
    "ex": "カンニングをした人は0点です。",
    "exReading": "かんにんぐ を した ひと は れいてん です",
    "exTranslation": "Those who cheat on the exam get no points.",
    "category": "Verbal Noun"
  },
  {
    "term": "身長",
    "termReading": "しんちょう",
    "termTranslation": "stature, height",
    "ex": "身長はどれくらいありますか。",
    "exReading": "しんちょう は どれ くらい あります か",
    "exTranslation": "How tall are you?",
    "category": "Noun"
  },
  {
    "term": "心身",
    "termReading": "しんしん",
    "termTranslation": "mind and body",
    "ex": "私は心身共に疲れていました。",
    "exReading": "わたし は しんしん ともに つかれて いました",
    "exTranslation": "I was mentally and physically tired.",
    "category": "Noun"
  },
  {
    "term": "受け身",
    "termReading": "うけみ",
    "termTranslation": "passiveness, passive voice",
    "ex": "彼はいつも受け身の姿勢で、自分からは何もしないんだ。",
    "exReading": "かれ は いつも うけみ の しせい で じぶん からは なに も しない ん だ",
    "exTranslation": "He is always passive and never initiates anything himself.",
    "category": "Noun"
  },
  {
    "term": "証拠",
    "termReading": "しょうこ",
    "termTranslation": "proof, evidence",
    "ex": "証拠を見つけるまで逮捕はできません。",
    "exReading": "しょうこ を みつける まで たいほ は できません",
    "exTranslation": "It is not possible to make an arrest until evidence is found.",
    "category": "Noun"
  },
  {
    "term": "照明",
    "termReading": "しょうめい",
    "termTranslation": "illumination, lighting",
    "ex": "もっと照明を明るくしてください。",
    "exReading": "もっと しょうめい を あかるく して ください",
    "exTranslation": "Please turn up the lighting more.",
    "category": "Verbal Noun"
  },
  {
    "term": "工夫",
    "termReading": "くふう",
    "termTranslation": "contrivance, scheme",
    "ex": "いろいろ工夫して仕事をやりとげたさ。",
    "exReading": "いろいろ くふう して しごと を やりとげた さ",
    "exTranslation": "I made various adjustments and carried out the work.",
    "category": "Verbal Noun"
  },
  {
    "term": "主婦",
    "termReading": "しゅふ",
    "termTranslation": "housewife",
    "ex": "主婦の主な仕事は家事よ。",
    "exReading": "しゅふ の おも な しごと は かじ よ",
    "exTranslation": "A housewife's main task is housework.",
    "category": "Noun"
  },
  {
    "term": "あぐら",
    "termReading": "あぐら",
    "termTranslation": "sit cross legged",
    "ex": "彼はあぐらをかいてしゃべっていますね。",
    "exReading": "かれ は あぐら を かいて しゃべって います ね",
    "exTranslation": "He is talking while sitting cross-legged.",
    "category": "Noun"
  },
  {
    "term": "産婦人科",
    "termReading": "さんふじんか",
    "termTranslation": "obstetrics and gynecology",
    "ex": "最近、産婦人科の不足が問題になっているね。",
    "exReading": "さいきん さんふじんか の ふそく が もんだい に なって いる ね",
    "exTranslation": "Recently, shortages of obstetrics and gynecology specialists have become a problem.",
    "category": "Noun"
  },
  {
    "term": "奥様",
    "termReading": "おくさま",
    "termTranslation": "married woman, someone's wife",
    "ex": "社長の奥様はきれいな方です。",
    "exReading": "しゃちょう の おくさま は きれい な かた です",
    "exTranslation": "The president's wife is a beautiful woman.",
    "category": "Noun"
  },
  {
    "term": "お互い",
    "termReading": "おたがい",
    "termTranslation": "each other",
    "ex": "お互いを信頼することが大事です。",
    "exReading": "おたがい を しんらい する こと が だいじ です",
    "exTranslation": "It is important to trust each other.",
    "category": "Noun"
  },
  {
    "term": "交互",
    "termReading": "こうご",
    "termTranslation": "by turns, alternatively",
    "ex": "男女交互に並んでください。",
    "exReading": "だんじょ こうご に ならんで ください",
    "exTranslation": "Please make a line of boy girl boy girl.",
    "category": "Noun"
  },
  {
    "term": "高齢",
    "termReading": "こうれい",
    "termTranslation": "advanced age, elderly",
    "ex": "彼は高齢を理由に社長を辞めたよ。",
    "exReading": "かれ は こうれい を りゆう に しゃちょう を やめた よ",
    "exTranslation": "He gave up the company presidency due to age.",
    "category": "Noun"
  },
  {
    "term": "愛情",
    "termReading": "あいじょう",
    "termTranslation": "love, affection",
    "ex": "子供はたくさんの愛情が必要です。",
    "exReading": "こども は たくさん の あいじょう が ひつよう です",
    "exTranslation": "Children need plenty of love.",
    "category": "Noun"
  },
  {
    "term": "可愛らしい",
    "termReading": "かわいらしい",
    "termTranslation": "lovely, charming",
    "ex": "彼女は娘に可愛らしい服を作りましたね。",
    "exReading": "かのじょ は むすめ に かわいらしい ふく を つくりました ね",
    "exTranslation": "She made her daughter some lovely clothes.",
    "category": "Adjective"
  },
  {
    "term": "可愛がる",
    "termReading": "かわいがる",
    "termTranslation": "love, cherish",
    "ex": "彼女は猫を可愛がっています。",
    "exReading": "かのじょ は ねこ を かわいがって います",
    "exTranslation": "She cherishes her cat.",
    "category": "Verb"
  },
  {
    "term": "いやいや",
    "termReading": "いやいや",
    "termTranslation": "reluctantly",
    "ex": "その子はいやいや学校へ行ったの。",
    "exReading": "その こ は いやいや がっこう へ いった の",
    "exTranslation": "The child went to school reluctantly.",
    "category": "Adverb"
  },
  {
    "term": "恋",
    "termReading": "こい",
    "termTranslation": "love, romance",
    "ex": "彼女は恋をしてきれいになったね。",
    "exReading": "かのじょ は こい を して きれい に なった ね",
    "exTranslation": "She fell in love and became beautiful.",
    "category": "Noun"
  },
  {
    "term": "失恋",
    "termReading": "しつれん",
    "termTranslation": "broken heart",
    "ex": "彼は最近、失恋したらしいの。",
    "exReading": "かれ は さいきん しつれん した らしい の",
    "exTranslation": "I hear he had a broken heart recently.",
    "category": "Verbal Noun"
  },
  {
    "term": "恋する",
    "termReading": "こいする",
    "termTranslation": "love, fall in love with",
    "ex": "恋する気持ちを歌にしました。",
    "exReading": "こいする きもち を うた に しました",
    "exTranslation": "I expressed my feelings of love in my song.",
    "category": "Verb"
  },
  {
    "term": "延長",
    "termReading": "えんちょう",
    "termTranslation": "extension, prolongation",
    "ex": "国会の会期が延長されたわね。",
    "exReading": "こっかい の かいき が えんちょう された わ ね",
    "exTranslation": "The session of the Diet has been extended.",
    "category": "Verbal Noun"
  },
  {
    "term": "延期",
    "termReading": "えんき",
    "termTranslation": "postponement, deferment",
    "ex": "運動会は雨で延期されました。",
    "exReading": "うんどうかい は あめ で えんき されました",
    "exTranslation": "The athletic meet was postponed due to rain.",
    "category": "Verbal Noun"
  },
  {
    "term": "祝う",
    "termReading": "いわう",
    "termTranslation": "celebrate, congratulate",
    "ex": "家族で彼の合格を祝ったよ。",
    "exReading": "かぞく で かれ の ごうかく を いわった よ",
    "exTranslation": "His whole family celebrated him having passed the exam.",
    "category": "Verb"
  },
  {
    "term": "祝い",
    "termReading": "いわい",
    "termTranslation": "celebration, congratulation",
    "ex": "結婚のお祝いに食器を頂きました。",
    "exReading": "けっこん の おいわい に しょっき を いただきました",
    "exTranslation": "I received tableware as a wedding gift.",
    "category": "Noun"
  },
  {
    "term": "ウエスト",
    "termReading": "ウエスト",
    "termTranslation": "the waist",
    "ex": "最近ウエストが太くなったな。",
    "exReading": "さいきん うえすと が ふとく なった な",
    "exTranslation": "My waist has gotten bigger lately.",
    "category": "Noun"
  },
  {
    "term": "純粋",
    "termReading": "じゅんすい",
    "termTranslation": "pure, unmixed",
    "ex": "子供の純粋な心を傷付けてはいけません。",
    "exReading": "こども の じゅんすい な こころ を きずつけて は いけません",
    "exTranslation": "You shouldn't hurt children's pure hearts.",
    "category": "Adjectival Noun"
  },
  {
    "term": "慎重",
    "termReading": "しんちょう",
    "termTranslation": "prudence, discretion",
    "ex": "もう一度慎重に見直しましょう。",
    "exReading": "もういちど しんちょう に みなおしましょう",
    "exTranslation": "Let's carefully review it again.",
    "category": "Adjectival Noun"
  },
  {
    "term": "笑顔",
    "termReading": "えがお",
    "termTranslation": "smiling face, smile",
    "ex": "赤ちゃんの笑顔が可愛いい。",
    "exReading": "あかちゃん の えがお が かわいい",
    "exTranslation": "The baby's smile is cute.",
    "category": "Noun"
  },
  {
    "term": "大喜び",
    "termReading": "おおよろこび",
    "termTranslation": "great joy, delight",
    "ex": "弟は新しい自転車に大喜びです。",
    "exReading": "おとうと は あたらしい じてんしゃ に おおよろこび です",
    "exTranslation": "My little brother is overjoyed with his new bike.",
    "category": "Verbal Noun"
  },
  {
    "term": "看護",
    "termReading": "かんご",
    "termTranslation": "nursing",
    "ex": "この病院は24時間看護です。",
    "exReading": "この びょういん は にじゅうよじかん かんご です",
    "exTranslation": "This hospital has around-the-clock care.",
    "category": "Verbal Noun"
  },
  {
    "term": "看病",
    "termReading": "かんびょう",
    "termTranslation": "nursing, nursing care",
    "ex": "彼女は一晩中彼を看病したの。",
    "exReading": "かのじょ は ひとばんじゅう かれ を かんびょう した の",
    "exTranslation": "She nursed him all night long.",
    "category": "Verbal Noun"
  },
  {
    "term": "肯定",
    "termReading": "こうてい",
    "termTranslation": "affirmation",
    "ex": "僕は彼のしたことは肯定できないな。",
    "exReading": "ぼく は かれ の した こと は こうてい できない な",
    "exTranslation": "I can't agree with what he did.",
    "category": "Verbal Noun"
  },
  {
    "term": "オルガン",
    "termReading": "オルガン",
    "termTranslation": "organ, pipe organ",
    "ex": "僕たちはオルガンに合わせて賛美歌を歌ったんだ。",
    "exReading": "ぼくたち は おるがん に あわせて さんびか を うたった んだ",
    "exTranslation": "We sang hymns with an organ accompaniment.",
    "category": "Noun"
  },
  {
    "term": "記述",
    "termReading": "きじゅつ",
    "termTranslation": "description",
    "ex": "日本の古い料理法についての記述を読んだんだ。",
    "exReading": "にほん の ふるい りょうりほう に ついて の きじゅつ を よんだ ん だ",
    "exTranslation": "I read a description of ancient Japanese cooking methods.",
    "category": "Verbal Noun"
  },
  {
    "term": "裁判",
    "termReading": "さいばん",
    "termTranslation": "trial, judgment",
    "ex": "裁判の様子はテレビで中継されたわよ。",
    "exReading": "さいばん の ようす は てれび で ちゅうけい された わ よ",
    "exTranslation": "Scenes of the trial were broadcast on television.",
    "category": "Verbal Noun"
  },
  {
    "term": "裁判所",
    "termReading": "さいばんしょ",
    "termTranslation": "courthouse",
    "ex": "彼らは裁判所の前で知らせを待っています。",
    "exReading": "かれら は さいばんしょ の まえ で しらせ を まって います",
    "exTranslation": "They are waiting for information in front of the courthouse.",
    "category": "Noun"
  },
  {
    "term": "訴え",
    "termReading": "うったえ",
    "termTranslation": "lawsuit, appeal",
    "ex": "彼女の訴えは認められたわ。",
    "exReading": "かのじょ の うったえ は みとめられた わ",
    "exTranslation": "Her complaint was admitted.",
    "category": "Noun"
  },
  {
    "term": "区域",
    "termReading": "くいき",
    "termTranslation": "zone",
    "ex": "ここは危険区域よ。",
    "exReading": "ここ は きけん くいき よ",
    "exTranslation": "This is a danger zone.",
    "category": "Noun"
  },
  {
    "term": "疑い",
    "termReading": "うたがい",
    "termTranslation": "doubt, suspicion",
    "ex": "彼は盗みの疑いをかけられたの。",
    "exReading": "かれ は ぬすみ の うたがい を かけられた の",
    "exTranslation": "He was suspected of the theft.",
    "category": "Noun"
  },
  {
    "term": "疑問",
    "termReading": "ぎもん",
    "termTranslation": "question, doubt",
    "ex": "彼の言葉が本当かは疑問です。",
    "exReading": "かれ の ことば が ほんとう か は ぎもん です",
    "exTranslation": "I doubt whether what he says is true.",
    "category": "Noun"
  },
  {
    "term": "からから",
    "termReading": "からから",
    "termTranslation": "roar, laugh loudly",
    "ex": "彼はからからと笑いました。",
    "exReading": "かれ は からから と わらいました",
    "exTranslation": "He laughed loudly.",
    "category": "Adverb"
  },
  {
    "term": "疑う",
    "termReading": "うたがう",
    "termTranslation": "doubt, be suspicious",
    "ex": "なぜあなたは私を疑うのですか。",
    "exReading": "なぜ あなた は わたし を うたがう の です か",
    "exTranslation": "Why do you doubt me?",
    "category": "Verb"
  },
  {
    "term": "疑わしい",
    "termReading": "うたがわしい",
    "termTranslation": "doubtful, questionable",
    "ex": "疑わしい場所は全部調べよう。",
    "exReading": "うたがわしい ばしょ は ぜんぶ しらべよう",
    "exTranslation": "Let's search in all the suspicious places.",
    "category": "Adjective"
  },
  {
    "term": "著しい",
    "termReading": "いちじるしい",
    "termTranslation": "remarkable, conspicuous",
    "ex": "彼の成長は著しいです。",
    "exReading": "かれ の せいちょう は いちじるしい です",
    "exTranslation": "His progress is remarkable.",
    "category": "Adjective"
  },
  {
    "term": "著す",
    "termReading": "あらわす",
    "termTranslation": "write, compose",
    "ex": "この本では自然の大切さがよく著されているよ。",
    "exReading": "この ほん で は しぜん の たいせつさ が よく あらわされて いる よ",
    "exTranslation": "The importance of nature is well written in this book.",
    "category": "Verb"
  },
  {
    "term": "権利",
    "termReading": "けんり",
    "termTranslation": "right, privilege",
    "ex": "私たちには知る権利があります。",
    "exReading": "わたしたち に は しる けんり が あります",
    "exTranslation": "We have the right to know.",
    "category": "Noun"
  },
  {
    "term": "権力",
    "termReading": "けんりょく",
    "termTranslation": "power, authority",
    "ex": "彼はこの国で大きな権力を持っているわ。",
    "exReading": "かれ は この くに で おおき な けんりょく を もって いる わ",
    "exTranslation": "He has a lot of power in this country.",
    "category": "Noun"
  },
  {
    "term": "人権",
    "termReading": "じんけん",
    "termTranslation": "human rights",
    "ex": "全ての人に人権がある。",
    "exReading": "すべて の ひと に じんけん が ある",
    "exTranslation": "All people have human rights.",
    "category": "Noun"
  },
  {
    "term": "棄権",
    "termReading": "きけん",
    "termTranslation": "dropping out, renouncing rights",
    "ex": "彼は試合の途中で棄権したぞ。",
    "exReading": "かれ は しあい の とちゅう で きけん した ぞ",
    "exTranslation": "He sat out during the game.",
    "category": "Verbal Noun"
  },
  {
    "term": "きっちん",
    "termReading": "きっちん",
    "termTranslation": "kitchen",
    "ex": "彼女はキッチンで夕食を作っています。",
    "exReading": "かのじょ は きっちん で ゆうしょく を つくって います",
    "exTranslation": "She is preparing dinner in the kitchen.",
    "category": "Noun"
  },
  {
    "term": "侵す",
    "termReading": "おかす",
    "termTranslation": "invade, violate",
    "ex": "他人の権利を侵してはなりません。",
    "exReading": "たにん の けんり を おかしては なりません",
    "exTranslation": "You shouldn't infringe on other people's rights.",
    "category": "Verb"
  },
  {
    "term": "行為",
    "termReading": "こうい",
    "termTranslation": "act, conduct",
    "ex": "彼の行為はみんなの誤解を招いたわ。",
    "exReading": "かれ の こうい は みんな の ごかい を まねいた わ",
    "exTranslation": "His actions ended up misleading everyone.",
    "category": "Noun"
  },
  {
    "term": "賞",
    "termReading": "しょう",
    "termTranslation": "prize, award",
    "ex": "このデザインは数々の賞を受けています。",
    "exReading": "この でざいん は かずかず の しょう を うけて います",
    "exTranslation": "This design has received a lot of prizes.",
    "category": "Noun"
  },
  {
    "term": "賞品",
    "termReading": "しょうひん",
    "termTranslation": "prize, trophy",
    "ex": "パーティーのビンゴの賞品は何がいいだろう。",
    "exReading": "ぱーてぃー の びんご の しょうひん は なに が いいだろう",
    "exTranslation": "What might be a good prize for bingo at the party?",
    "category": "Noun"
  },
  {
    "term": "観賞",
    "termReading": "かんしょう",
    "termTranslation": "ornamental",
    "ex": "この鉢植えは観賞用です。",
    "exReading": "この はちうえ は かんしょうよう です",
    "exTranslation": "This potted plant is for ornamental purposes.",
    "category": "Verbal Noun"
  },
  {
    "term": "財政",
    "termReading": "ざいせい",
    "termTranslation": "public finance, financial affairs",
    "ex": "国の財政はとても苦しい状態だな。",
    "exReading": "くに の ざいせい は とても くるしい じょうたい だ な",
    "exTranslation": "The country's finances are in a very tight condition.",
    "category": "Noun"
  },
  {
    "term": "財産",
    "termReading": "ざいさん",
    "termTranslation": "fortune, asset",
    "ex": "彼は株で財産の半分を失ったの。",
    "exReading": "かれ は かぶ で ざいさん の はんぶん を うしなった の",
    "exTranslation": "He lost half his assets in the stock market.",
    "category": "Noun"
  },
  {
    "term": "くしゃくしゃ",
    "termReading": "くしゃくしゃ",
    "termTranslation": "messy, scrunch",
    "ex": "紙をくしゃくしゃに丸めました。",
    "exReading": "かみ を くしゃくしゃ に まるめました",
    "exTranslation": "I rolled the paper into a ball.",
    "category": "Adverb"
  },
  {
    "term": "金融",
    "termReading": "きんゆう",
    "termTranslation": "finance",
    "ex": "彼は金融関係の会社で働いています。",
    "exReading": "かれ は きんゆう かんけい の かいしゃ で はたらいて います",
    "exTranslation": "He works at a financial company.",
    "category": "Noun"
  },
  {
    "term": "間隔",
    "termReading": "かんかく",
    "termTranslation": "interval, space",
    "ex": "電車は5分間隔で来ますよ。",
    "exReading": "でんしゃ は ごふん かんかく で きます よ",
    "exTranslation": "The train comes every five minutes.",
    "category": "Noun"
  },
  {
    "term": "終了",
    "termReading": "しゅうりょう",
    "termTranslation": "end, expiry",
    "ex": "コンサートは夜7時5分に終了しました。",
    "exReading": "こんさーと は よる しち じ ご ふん に しゅうりょう しました",
    "exTranslation": "The concert ended at 7:05 pm.",
    "category": "Verbal Noun"
  },
  {
    "term": "完了",
    "termReading": "かんりょう",
    "termTranslation": "completion",
    "ex": "仕事は全て完了しました。",
    "exReading": "しごと は すべて かんりょう しました",
    "exTranslation": "I completed all the work.",
    "category": "Verbal Noun"
  },
  {
    "term": "修了",
    "termReading": "しゅうりょう",
    "termTranslation": "completion",
    "ex": "先月、そのコースを修了しました。",
    "exReading": "せんげつ その こーす を しゅうりょう しました",
    "exTranslation": "I finished the course last month.",
    "category": "Verbal Noun"
  },
  {
    "term": "承認",
    "termReading": "しょうにん",
    "termTranslation": "approval, recognition",
    "ex": "これは政府の承認を受けた資格です。",
    "exReading": "これ は せいふ の しょうにん を うけた しかく です",
    "exTranslation": "This is a qualification approved by the government",
    "category": "Verbal Noun"
  },
  {
    "term": "承知",
    "termReading": "しょうち",
    "termTranslation": "consent, knowledge",
    "ex": "そのことは承知しております。",
    "exReading": "その こと は しょうち して おります",
    "exTranslation": "I know that.",
    "category": "Verbal Noun"
  },
  {
    "term": "けち",
    "termReading": "けち",
    "termTranslation": "stingy, miser",
    "ex": "彼は金持ちだけど、けちね。",
    "exReading": "かれ は かねもち だ けど けち ね",
    "exTranslation": "He is rich but stingy.",
    "category": "Adjectival Noun"
  },
  {
    "term": "納める",
    "termReading": "おさめる",
    "termTranslation": "pay a tax or fee",
    "ex": "自動車税を納めたよ。",
    "exReading": "じどうしゃぜい を おさめた よ",
    "exTranslation": "I paid my car tax.",
    "category": "Verb"
  },
  {
    "term": "説得",
    "termReading": "せっとく",
    "termTranslation": "persuasion",
    "ex": "なんとか親を説得してみるよ。",
    "exReading": "なんとか おや を せっとく して みる よ",
    "exTranslation": "I will try my best to persuade my parents.",
    "category": "Verbal Noun"
  },
  {
    "term": "所得",
    "termReading": "しょとく",
    "termTranslation": "income, earnings",
    "ex": "ここに去年の所得をご記入ください。",
    "exReading": "ここ に きょねん の しょとく を ご きにゅう ください",
    "exTranslation": "Please fill in last year's income here.",
    "category": "Noun"
  },
  {
    "term": "得る",
    "termReading": "える",
    "termTranslation": "obtain, gain",
    "ex": "彼は大金を得ましたよ。",
    "exReading": "かれ は たいきん を えました よ",
    "exTranslation": "He obtained a lot of money.",
    "category": "Verb"
  },
  {
    "term": "乾燥",
    "termReading": "かんそう",
    "termTranslation": "drying up, desiccation",
    "ex": "冬は空気が乾燥しますね。",
    "exReading": "ふゆ は くうき が かんそう します ね",
    "exTranslation": "The air in winter is dry.",
    "category": "Verbal Noun"
  },
  {
    "term": "幹部",
    "termReading": "かんぶ",
    "termTranslation": "executive",
    "ex": "あの会社の幹部は皆とても優秀だね。",
    "exReading": "あの かいしゃ の かんぶ は みんな とても ゆうしゅう だ ね",
    "exTranslation": "That company's executives are all exceptional people.",
    "category": "Noun"
  },
  {
    "term": "酸素",
    "termReading": "さんそ",
    "termTranslation": "oxygen",
    "ex": "私たちは酸素無しでは生きていけない。",
    "exReading": "わたしたち は さんそ なし で は いきて いけない",
    "exTranslation": "We can't live without oxygen.",
    "category": "Noun"
  },
  {
    "term": "素直",
    "termReading": "すなお",
    "termTranslation": "honest, straightforward",
    "ex": "彼女はとても素直で可愛いですね。",
    "exReading": "かのじょ は とても すなお で かわいい です ね",
    "exTranslation": "She is very honest and cute.",
    "category": "Adjective"
  },
  {
    "term": "ざらざら",
    "termReading": "ざらざら",
    "termTranslation": "rough, gritty",
    "ex": "砂ぼこりで机がざらざらしているね。",
    "exReading": "すなぼこり で つくえ が ざらざら して いる ね",
    "exTranslation": "The top of the desk is rough because of the sandy dust.",
    "category": "Noun"
  },
  {
    "term": "水素",
    "termReading": "すいそ",
    "termTranslation": "hydrogen",
    "ex": "水は水素と酸素でできています。",
    "exReading": "みず は すいそ と さんそ で できて います",
    "exTranslation": "Water is made of hydrogen and oxygen.",
    "category": "Noun"
  },
  {
    "term": "素早い",
    "termReading": "すばやい",
    "termTranslation": "nimble, quick",
    "ex": "彼は素早くあたりを見回したの。",
    "exReading": "かれ は すばやく あたり を みまわした の",
    "exTranslation": "He quickly looked around.",
    "category": "Adjective"
  },
  {
    "term": "石炭",
    "termReading": "せきたん",
    "termTranslation": "coal",
    "ex": "小屋に石炭の山があります。",
    "exReading": "こや に せきたん の やま が あります",
    "exTranslation": "There is a mountain of coal in the hut.",
    "category": "Noun"
  },
  {
    "term": "岩",
    "termReading": "いわ",
    "termTranslation": "rock, crag",
    "ex": "あの岩まで泳ごう。",
    "exReading": "あの いわ まで およごう",
    "exTranslation": "Let's swim up to that rock.",
    "category": "Noun"
  },
  {
    "term": "岸",
    "termReading": "きし",
    "termTranslation": "shore, bank",
    "ex": "船がやっと岸に着いたよ。",
    "exReading": "ふね が やっと きし に ついた よ",
    "exTranslation": "The ship finally reached the shore.",
    "category": "Noun"
  },
  {
    "term": "校庭",
    "termReading": "こうてい",
    "termTranslation": "schoolyard, campus",
    "ex": "陸上部は校庭で練習しています。",
    "exReading": "りくじょうぶ は こうてい で れんしゅう して います",
    "exTranslation": "A track club is practicing in the schoolyard.",
    "category": "Noun"
  },
  {
    "term": "解散",
    "termReading": "かいさん",
    "termTranslation": "breakup, dissolution",
    "ex": "来年、衆議院が解散されるだろう。",
    "exReading": "らいねん しゅうぎいん が かいさん される だろう",
    "exTranslation": "The Lower House will probably be dissolved next year.",
    "category": "Verbal Noun"
  },
  {
    "term": "スモッグ",
    "termReading": "スモッグ",
    "termTranslation": "smog",
    "ex": "都会の空はスモッグで灰色ね。",
    "exReading": "とかい の そら は すもっぐ で はいいろ ね",
    "exTranslation": "The city sky is gray because of smog.",
    "category": "Noun"
  },
  {
    "term": "植物",
    "termReading": "しょくぶつ",
    "termTranslation": "plant, vegetation",
    "ex": "休日は植物の世話をして過ごします。",
    "exReading": "きゅうじつ は しょくぶつ の せわ を して すごします",
    "exTranslation": "I spend my days off looking after my plants.",
    "category": "Noun"
  },
  {
    "term": "植民地",
    "termReading": "しょくみんち",
    "termTranslation": "colony, settlement",
    "ex": "この国はイギリスの植民地でした。",
    "exReading": "この くに は いぎりす の しょくみんち でした",
    "exTranslation": "This country was a colony of Britain.",
    "category": "Noun"
  },
  {
    "term": "植木",
    "termReading": "うえき",
    "termTranslation": "garden plant, shrub",
    "ex": "植木に水をやりました。",
    "exReading": "うえき に みず を やりました",
    "exTranslation": "I watered the plant.",
    "category": "Noun"
  },
  {
    "term": "植物園",
    "termReading": "しょくぶつえん",
    "termTranslation": "botanical garden",
    "ex": "植物園には珍しい花がたくさんありますね。",
    "exReading": "しょくぶつえん に は めずらしい はな が たくさん あります ね",
    "exTranslation": "There are a lot of rare flowers in the botanical garden.",
    "category": "Noun"
  },
  {
    "term": "根拠",
    "termReading": "こんきょ",
    "termTranslation": "grounds, basis",
    "ex": "何を根拠にそんな事を言うのですか。",
    "exReading": "なに を こんきょ に そんな こと を いう の です か",
    "exTranslation": "On what grounds do you say such a thing?",
    "category": "Noun"
  },
  {
    "term": "根本",
    "termReading": "こんぽん",
    "termTranslation": "basis, root",
    "ex": "問題の根本を見直しましょう。",
    "exReading": "もんだい の こんぽん を みなおしましょう",
    "exTranslation": "Let's look again at the root of the problem.",
    "category": "Noun"
  },
  {
    "term": "板",
    "termReading": "いた",
    "termTranslation": "board, plank",
    "ex": "父は長い板を買って来たんだ。",
    "exReading": "ちち は ながい いた を かって きた ん だ",
    "exTranslation": "My father bought a long plank.",
    "category": "Noun"
  },
  {
    "term": "すり",
    "termReading": "すり",
    "termTranslation": "pickpocket",
    "ex": "すりに財布をとられた。",
    "exReading": "すり に さいふ を とられた",
    "exTranslation": "My wallet was taken by a pickpocket.",
    "category": "Noun"
  },
  {
    "term": "看板",
    "termReading": "かんばん",
    "termTranslation": "signboard, sign",
    "ex": "店の看板を塗り替えました。",
    "exReading": "みせ の かんばん を ぬりかえました",
    "exTranslation": "I repainted the shop's sign.",
    "category": "Noun"
  },
  {
    "term": "草花",
    "termReading": "くさばな",
    "termTranslation": "plants and flowers",
    "ex": "草花を大切にしましょう。",
    "exReading": "くさばな を たいせつ に しましょう",
    "exTranslation": "Let's take good care of plants and flowers.",
    "category": "Noun"
  },
  {
    "term": "草木",
    "termReading": "くさき",
    "termTranslation": "plants and trees",
    "ex": "この庭は草木が枯れているね。",
    "exReading": "この にわ は くさき が かれて いる ね",
    "exTranslation": "The plants dry out in this garden.",
    "category": "Noun"
  },
  {
    "term": "言葉遣い",
    "termReading": "ことばづかい",
    "termTranslation": "wording, language",
    "ex": "先生にそんな言葉遣いをしてはいけません。",
    "exReading": "せんせい に そんな ことばづかい を して は いけません",
    "exTranslation": "Do not speak to the teacher like that.",
    "category": "Noun"
  },
  {
    "term": "落ち葉",
    "termReading": "おちば",
    "termTranslation": "fallen leaves",
    "ex": "落ち葉の季節になりましたね。",
    "exReading": "おちば の きせつ に なりました ね",
    "exTranslation": "The season of falling leaves has arrived.",
    "category": "Noun"
  },
  {
    "term": "木の葉",
    "termReading": "このは",
    "termTranslation": "tree leaves",
    "ex": "秋には木の葉が赤くなります。",
    "exReading": "あき に は このは が あかく なります",
    "exTranslation": "Leaves go red in autumn.",
    "category": "Noun"
  },
  {
    "term": "書き言葉",
    "termReading": "かきことば",
    "termTranslation": "written language",
    "ex": "書き言葉と話し言葉はだいぶ違うことがあります。",
    "exReading": "かきことば と はなしことば は だいぶ ちがう こと が あります",
    "exTranslation": "Written language and spoken language are sometimes quite different.",
    "category": "Noun"
  },
  {
    "term": "吸収",
    "termReading": "きゅうしゅう",
    "termTranslation": "absorption, merger",
    "ex": "彼は知識の吸収が早いですね。",
    "exReading": "かれ は ちしき の きゅうしゅう が はやい です ね",
    "exTranslation": "He absorbs knowledge quickly.",
    "category": "Verbal Noun"
  },
  {
    "term": "おにぎり",
    "termReading": "おにぎり",
    "termTranslation": "rice ball",
    "ex": "昼食におにぎりを食べました。",
    "exReading": "ちゅうしょく に おにぎり を たべました",
    "exTranslation": "I ate rice balls for lunch.",
    "category": "Noun"
  },
  {
    "term": "呼吸",
    "termReading": "こきゅう",
    "termTranslation": "breathing, respiration",
    "ex": "ゆっくり呼吸してください。",
    "exReading": "ゆっくり こきゅう して ください",
    "exTranslation": "Please breathe slowly.",
    "category": "Verbal Noun"
  },
  {
    "term": "吸い込む",
    "termReading": "すいこむ",
    "termTranslation": "inhale, suck in",
    "ex": "ほこりを吸い込んじゃった。",
    "exReading": "ほこり を すいこんじゃった",
    "exTranslation": "I have inhaled some dust.",
    "category": "Verb"
  },
  {
    "term": "及ぶ",
    "termReading": "およぶ",
    "termTranslation": "reach to, come up to",
    "ex": "あなたにまで迷惑が及んでごめんなさい。",
    "exReading": "あなた に まで めいわく が およんで ごめんなさい",
    "exTranslation": "I'm sorry that even you've been inconvenienced.",
    "category": "Verb"
  },
  {
    "term": "扱う",
    "termReading": "あつかう",
    "termTranslation": "handle, deal with",
    "ex": "この荷物は丁寧に扱って下さい。",
    "exReading": "この にもつ は ていねい に あつかって ください",
    "exTranslation": "Please handle this luggage with care.",
    "category": "Verb"
  },
  {
    "term": "高級",
    "termReading": "こうきゅう",
    "termTranslation": "high rank, high class",
    "ex": "私たちは高級ホテルに泊まったの。",
    "exReading": "わたしたち は こうきゅう ほてる に とまった の",
    "exTranslation": "We stayed at a high class hotel.",
    "category": "Adjectival Noun"
  },
  {
    "term": "級",
    "termReading": "きゅう",
    "termTranslation": "grade, class",
    "ex": "彼は書道3級です。",
    "exReading": "かれ は しょどう さんきゅう です",
    "exTranslation": "He has a class three certificate in Japanese calligraphy.",
    "category": "Noun"
  },
  {
    "term": "上級",
    "termReading": "じょうきゅう",
    "termTranslation": "higher grade, advanced class",
    "ex": "彼は上級のコースに上がったよ。",
    "exReading": "かれ は じょうきゅう の こーす に あがった よ",
    "exTranslation": "He went up to the advanced course.",
    "category": "Noun"
  },
  {
    "term": "かみそり",
    "termReading": "かみそり",
    "termTranslation": "razor",
    "ex": "かみそりの刃で指を切りました。",
    "exReading": "かみそり の は で ゆび を きりました",
    "exTranslation": "I cut my finger with a razor blade.",
    "category": "Noun"
  },
  {
    "term": "初級",
    "termReading": "しょきゅう",
    "termTranslation": "beginner class, elementary level",
    "ex": "これは初級の教科書です。",
    "exReading": "これ は しょきゅう の きょうかしょ です 。",
    "exTranslation": "This is a beginner's textbook.",
    "category": "Noun"
  },
  {
    "term": "血管",
    "termReading": "けっかん",
    "termTranslation": "blood vessel",
    "ex": "年をとると血管が硬くなります。",
    "exReading": "とし を とる と けっかん が かたく なります",
    "exTranslation": "The blood vessels harden with age.",
    "category": "Noun"
  },
  {
    "term": "出血",
    "termReading": "しゅっけつ",
    "termTranslation": "bleeding, hemorrhage",
    "ex": "出血がひどいので、医者に行ったほうがいい。",
    "exReading": "しゅっけつ が ひどい の で いしゃ に いった ほう が いい",
    "exTranslation": "You're bleeding badly so you should go see a doctor.",
    "category": "Verbal Noun"
  },
  {
    "term": "液体",
    "termReading": "えきたい",
    "termTranslation": "liquid, fluid",
    "ex": "洗濯に液体の洗剤を使っています。",
    "exReading": "せんたく に えきたい の せんざい を つかって います",
    "exTranslation": "I use liquid detergent for washing clothes.",
    "category": "Noun"
  },
  {
    "term": "血液",
    "termReading": "けつえき",
    "termTranslation": "blood",
    "ex": "心臓は全身に血液を送り出している。",
    "exReading": "しんぞう は ぜんしん に けつえき を おくりだして いる",
    "exTranslation": "The heart sends blood to the whole body.",
    "category": "Noun"
  },
  {
    "term": "液",
    "termReading": "えき",
    "termTranslation": "liquid, fluid",
    "ex": "容器から液がこぼれていますよ。",
    "exReading": "ようき から えき が こぼれて います よ",
    "exTranslation": "The liquid is spilling from the container.",
    "category": "Noun"
  },
  {
    "term": "背中",
    "termReading": "せなか",
    "termTranslation": "back",
    "ex": "背中がかゆいです。",
    "exReading": "せなか が かゆい です",
    "exTranslation": "My back itches.",
    "category": "Noun"
  },
  {
    "term": "がやがや",
    "termReading": "がやがや",
    "termTranslation": "chatter loudly",
    "ex": "生徒たちががやがやと騒いでいますね。",
    "exReading": "せいとたち が がやがや と さわいで います ね",
    "exTranslation": "Students are making a lot of noise.",
    "category": "Adverb"
  },
  {
    "term": "背広",
    "termReading": "せびろ",
    "termTranslation": "business suit",
    "ex": "背広をクリーニングに出しました。",
    "exReading": "せびろ を くりーにんぐ に だしました",
    "exTranslation": "I took my suit to the dry cleaner.",
    "category": "Noun"
  },
  {
    "term": "骨折",
    "termReading": "こっせつ",
    "termTranslation": "fracture, broken bone",
    "ex": "彼女はスキーで足を骨折したの。",
    "exReading": "かのじょ は すきー で あし を こっせつ した の",
    "exTranslation": "She fractured her foot while skiing.",
    "category": "Verbal Noun"
  },
  {
    "term": "健全",
    "termReading": "けんぜん",
    "termTranslation": "healthy, sound",
    "ex": "子供の健全な心を育てましょう。",
    "exReading": "こども の けんぜん な こころ を そだてましょう",
    "exTranslation": "Let's cultivate a wholesome mindset in children.",
    "category": "Adjective"
  },
  {
    "term": "健康",
    "termReading": "けんこう",
    "termTranslation": "health",
    "ex": "健康が一番大切だ。",
    "exReading": "けんこう が いちばん たいせつ だ 。",
    "exTranslation": "The most important thing is your health.",
    "category": "Noun"
  },
  {
    "term": "診断",
    "termReading": "しんだん",
    "termTranslation": "diagnosis",
    "ex": "医師は異常なしと診断したんだ。",
    "exReading": "いし は いじょう なし と しんだん した ん だ",
    "exTranslation": "According to the doctor's diagnosis, there's no abnormality.",
    "category": "Verbal Noun"
  },
  {
    "term": "診察",
    "termReading": "しんさつ",
    "termTranslation": "medical examination",
    "ex": "今日、病院で診察してもらったの。",
    "exReading": "きょう びょういん で しんさつ して もらった の",
    "exTranslation": "I had an examination at the hospital today.",
    "category": "Verbal Noun"
  },
  {
    "term": "医療",
    "termReading": "いりょう",
    "termTranslation": "medical service, healthcare",
    "ex": "医療関係の仕事をしています。",
    "exReading": "いりょう かんけい の しごと を して います",
    "exTranslation": "I work in healthcare.",
    "category": "Noun"
  },
  {
    "term": "きょろきょろ",
    "termReading": "きょろきょろ",
    "termTranslation": "look around",
    "ex": "会場で子供がきょろきょろしているわよ。",
    "exReading": "かいじょう で こども が きょろきょろ して いる わ よ",
    "exTranslation": "The child is staring about the hall.",
    "category": "Adverb"
  },
  {
    "term": "気の毒",
    "termReading": "きのどく",
    "termTranslation": "pitiable, regrettable",
    "ex": "彼らは気の毒な生活をしている。",
    "exReading": "かれら は きのどく な せいかつ を して いる",
    "exTranslation": "They live pitiful lives.",
    "category": "Adjectival Noun"
  },
  {
    "term": "消毒",
    "termReading": "しょうどく",
    "termTranslation": "disinfection, sterilization",
    "ex": "足の傷を消毒したわ。",
    "exReading": "あし の きず を しょうどく した わ",
    "exTranslation": "I disinfected the wound on the foot.",
    "category": "Verbal Noun"
  },
  {
    "term": "症状",
    "termReading": "しょうじょう",
    "termTranslation": "symptom",
    "ex": "医者に症状を説明したよ。",
    "exReading": "いしゃ に しょうじょう を せつめい した よ",
    "exTranslation": "I explained the symptoms to the doctor.",
    "category": "Noun"
  },
  {
    "term": "清書",
    "termReading": "せいしょ",
    "termTranslation": "clean copy",
    "ex": "この手紙を清書してください。",
    "exReading": "この てがみ を せいしょ して ください",
    "exTranslation": "Please make a clean copy of this letter.",
    "category": "Verbal Noun"
  },
  {
    "term": "清潔",
    "termReading": "せいけつ",
    "termTranslation": "cleanliness, neatness",
    "ex": "レストランは清潔が第一です。",
    "exReading": "れすとらん は せいけつ が だいいち です",
    "exTranslation": "In restaurants cleanliness is the most important thing.",
    "category": "Adjectival Noun"
  },
  {
    "term": "監督",
    "termReading": "かんとく",
    "termTranslation": "supervisor, film director",
    "ex": "その監督はアカデミー賞を受賞したよな。",
    "exReading": "その かんとく は あかでみーしょう を じゅしょう した よ な",
    "exTranslation": "The director won an Academy Award.",
    "category": "Verbal Noun"
  },
  {
    "term": "撮影",
    "termReading": "さつえい",
    "termTranslation": "photographing, shoot",
    "ex": "撮影は3ヶ月かけて行われました。",
    "exReading": "さつえい は さんかげつ かけて おこなわれました",
    "exTranslation": "Shooting took place over three months.",
    "category": "Verbal Noun"
  },
  {
    "term": "描く",
    "termReading": "えがく",
    "termTranslation": "depict, describe",
    "ex": "彼は人物を描くのがうまいな。",
    "exReading": "かれ は じんぶつ を えがく の が うまい な",
    "exTranslation": "He is good at drawing people.",
    "category": "Verb"
  },
  {
    "term": "くしゃみ",
    "termReading": "くしゃみ",
    "termTranslation": "sneeze",
    "ex": "風邪でくしゃみが止まりません。",
    "exReading": "かぜ で くしゃみ が とまりません",
    "exTranslation": "I can't stop sneezing because of my cold.",
    "category": "Noun"
  },
  {
    "term": "活躍",
    "termReading": "かつやく",
    "termTranslation": "activity, action",
    "ex": "彼の活躍で優勝したよ。",
    "exReading": "かれ の かつやく で ゆうしょう した よ",
    "exTranslation": "His performance led us to win the tournament.",
    "category": "Verbal Noun"
  },
  {
    "term": "再開",
    "termReading": "さいかい",
    "termTranslation": "reopening, restart",
    "ex": "試合はすぐに再開されたの。",
    "exReading": "しあい は すぐ に さいかい された の",
    "exTranslation": "The game was restarted at once.",
    "category": "Verbal Noun"
  },
  {
    "term": "再生",
    "termReading": "さいせい",
    "termTranslation": "playback",
    "ex": "留守番電話のメッセージを再生したの。",
    "exReading": "るすばんでんわ の めっせーじ を さいせい した の",
    "exTranslation": "I played back the messages on the answering machine.",
    "category": "Verbal Noun"
  },
  {
    "term": "再会",
    "termReading": "さいかい",
    "termTranslation": "reunion",
    "ex": "彼らは互いに再会を喜んだの。",
    "exReading": "かれら は たがいに さいかい を よろこんだ の",
    "exTranslation": "They were both very pleased to meet each other again.",
    "category": "Verbal Noun"
  },
  {
    "term": "編む",
    "termReading": "あむ",
    "termTranslation": "knit",
    "ex": "妹はマフラーを編みました。",
    "exReading": "いもうと は まふらー を あみました",
    "exTranslation": "My younger sister knitted a scarf.",
    "category": "Verb"
  },
  {
    "term": "解放",
    "termReading": "かいほう",
    "termTranslation": "release, setting free",
    "ex": "人質が解放されてよかった。",
    "exReading": "ひとじち が かいほう されて よかった",
    "exTranslation": "It is good that the hostage has been freed.",
    "category": "Verbal Noun"
  },
  {
    "term": "開放",
    "termReading": "かいほう",
    "termTranslation": "opening",
    "ex": "その国は市場の開放を求められているの。",
    "exReading": "その くに は しじょう の かいほう を もとめられて いる の",
    "exTranslation": "That country has been requested to open its markets.",
    "category": "Verbal Noun"
  },
  {
    "term": "ぐずぐず",
    "termReading": "ぐずぐず",
    "termTranslation": "drag one's feet, waste time",
    "ex": "ぐずぐずしていると電車に乗り遅れますよ。",
    "exReading": "ぐずぐず して いる と でんしゃ に のりおくれます よ",
    "exTranslation": "You'll miss the train if you dilly-dally.",
    "category": "Adverb"
  },
  {
    "term": "重視",
    "termReading": "じゅうし",
    "termTranslation": "importance, value",
    "ex": "あの企業では学歴が重視されるよ。",
    "exReading": "あの きぎょう で は がくれき が じゅうし される よ",
    "exTranslation": "An educational background is valued in that company.",
    "category": "Verbal Noun"
  },
  {
    "term": "視点",
    "termReading": "してん",
    "termTranslation": "viewpoint",
    "ex": "視点を変えて見てみましょう。",
    "exReading": "してん を かえて みて みましょう",
    "exTranslation": "Let's try to change our point of view.",
    "category": "Noun"
  },
  {
    "term": "近視",
    "termReading": "きんし",
    "termTranslation": "nearsightedness, shortsightedness",
    "ex": "彼は軽い近視です。",
    "exReading": "かれ は かるい きんし です",
    "exTranslation": "He is slightly near-sighted.",
    "category": "Noun"
  },
  {
    "term": "衣類",
    "termReading": "いるい",
    "termTranslation": "clothes, garments",
    "ex": "衣類の整理をしたよ。",
    "exReading": "いるい の せいり を した よ",
    "exTranslation": "I organized the clothes.",
    "category": "Noun"
  },
  {
    "term": "衣服",
    "termReading": "いふく",
    "termTranslation": "clothes, dress",
    "ex": "彼の会社は衣服を扱っているよ。",
    "exReading": "かれ の かいしゃ は いふく を あつかって いる よ",
    "exTranslation": "His company deals with clothing.",
    "category": "Noun"
  },
  {
    "term": "衣料",
    "termReading": "いりょう",
    "termTranslation": "clothing, garments",
    "ex": "その会社は衣料を扱っているんだ。",
    "exReading": "その かいしゃ は いりょう を あつかって いる ん だ",
    "exTranslation": "The company deals with clothing.",
    "category": "Noun"
  },
  {
    "term": "衣食住",
    "termReading": "いしょくじゅう",
    "termTranslation": "clothing and food and housing",
    "ex": "土地によって衣食住は変化する。",
    "exReading": "とち によって いしょくじゅう は へんか する",
    "exTranslation": "Clothing, food and housing vary from region to region.",
    "category": "Noun"
  },
  {
    "term": "くすぐったい",
    "termReading": "くすぐったい",
    "termTranslation": "ticklish",
    "ex": "犬に顔をなめられてくすぐったいよ。",
    "exReading": "いぬ に かお を なめられて くすぐったい よ 。",
    "exTranslation": "It tickles to have my face licked by a dog.",
    "category": "Adjective"
  },
  {
    "term": "仮に",
    "termReading": "かりに",
    "termTranslation": "supposing that, even if",
    "ex": "仮にそれが事実だとしてももう遅いよ。",
    "exReading": "かりに それ が じじつ だ と して も もう おそい よ",
    "exTranslation": "Even if it is true, it's already too late.",
    "category": "Adverb"
  },
  {
    "term": "仮定",
    "termReading": "かてい",
    "termTranslation": "assumption, supposition",
    "ex": "仮定の話だけでは結論は出ません。",
    "exReading": "かてい の はなし だけ で は けつろん は でません",
    "exTranslation": "A conclusion can't come from assumptions alone.",
    "category": "Verbal Noun"
  },
  {
    "term": "仮",
    "termReading": "かり",
    "termTranslation": "provisional, temporary",
    "ex": "仮の申し込みをしました。",
    "exReading": "かり の もうしこみ を しました",
    "exTranslation": "I submitted a temporary application.",
    "category": "Noun"
  },
  {
    "term": "仮名遣い",
    "termReading": "かなづかい",
    "termTranslation": "kana orthography, use of kana",
    "ex": "祖父は古い仮名遣いで書くことがあるの。",
    "exReading": "そふ は ふるい かなづかい で かく こと が ある の",
    "exTranslation": "My grandfather sometimes writes in the old kana orthography.",
    "category": "Noun"
  },
  {
    "term": "演説",
    "termReading": "えんぜつ",
    "termTranslation": "speech, address",
    "ex": "彼は地方で演説したのよ。",
    "exReading": "かれ は ちほう で えんぜつ した の よ",
    "exTranslation": "He made a speech in the provinces.",
    "category": "Verbal Noun"
  },
  {
    "term": "公演",
    "termReading": "こうえん",
    "termTranslation": "public performance",
    "ex": "彼らの海外公演が発表されたよ。",
    "exReading": "かれら の かいがいこうえん が はっぴょう された よ",
    "exTranslation": "Their overseas performances were announced.",
    "category": "Verbal Noun"
  },
  {
    "term": "演じる",
    "termReading": "えんじる",
    "termTranslation": "perform, play",
    "ex": "彼はよく刑事の役を演じるね。",
    "exReading": "かれ は よく けいじ の やく を えんじる ね",
    "exTranslation": "He often performs the role of a detective.",
    "category": "Verb"
  },
  {
    "term": "出演",
    "termReading": "しゅつえん",
    "termTranslation": "appearance on stage, performance",
    "ex": "彼女はその映画に出演しているよ。",
    "exReading": "かのじょ は その えいが に しゅつえん して いる よ",
    "exTranslation": "She is performing in the movie.",
    "category": "Verbal Noun"
  },
  {
    "term": "くたびれる",
    "termReading": "くたびれる",
    "termTranslation": "get tired, be worn out",
    "ex": "たくさん歩いてくたびれました。",
    "exReading": "たくさん あるいて くたびれました",
    "exTranslation": "I was worn out from walking so much.",
    "category": "Verb"
  },
  {
    "term": "劇",
    "termReading": "げき",
    "termTranslation": "drama, play",
    "ex": "小学校で子供たちの劇を見ました。",
    "exReading": "しょうがっこう で こどもたち の げき を みました",
    "exTranslation": "I saw a children's play at an elementary school.",
    "category": "Noun"
  },
  {
    "term": "劇場",
    "termReading": "げきじょう",
    "termTranslation": "theater",
    "ex": "午後8時に劇場の前で会いましょう。",
    "exReading": "ごご はちじ に げきじょう の まえ で あいましょう",
    "exTranslation": "Let's meet in front of the theater at 8:00 pm.",
    "category": "Noun"
  },
  {
    "term": "演劇",
    "termReading": "えんげき",
    "termTranslation": "drama, play",
    "ex": "彼女は演劇を学んでいます。",
    "exReading": "かのじょ は えんげき を まなんで います",
    "exTranslation": "She studies acting.",
    "category": "Noun"
  },
  {
    "term": "喜劇",
    "termReading": "きげき",
    "termTranslation": "comedy",
    "ex": "昨夜はテレビで喜劇を見たよ。",
    "exReading": "さくや は てれび で きげき を みた よ",
    "exTranslation": "I watched a comedy show on TV last night.",
    "category": "Noun"
  },
  {
    "term": "悲しみ",
    "termReading": "かなしみ",
    "termTranslation": "grief, sadness",
    "ex": "突然の悲しみが一家を襲いました。",
    "exReading": "とつぜん の かなしみ が いっか を おそいました",
    "exTranslation": "Sudden grief fell upon the whole family.",
    "category": "Noun"
  },
  {
    "term": "集団",
    "termReading": "しゅうだん",
    "termTranslation": "group, mass",
    "ex": "テロ集団が警察に捕まりました。",
    "exReading": "てろ しゅうだん が けいさつ に つかまりました",
    "exTranslation": "The terrorist group was caught by the police.",
    "category": "Noun"
  },
  {
    "term": "固定",
    "termReading": "こてい",
    "termTranslation": "fixing, stability",
    "ex": "棒をテープで固定しなさい。",
    "exReading": "ぼう を てーぷ で こてい しなさい",
    "exTranslation": "Fix the stick in place with tape.",
    "category": "Verbal Noun"
  },
  {
    "term": "いらいら",
    "termReading": "いらいら",
    "termTranslation": "be irritated",
    "ex": "彼女は朝からいらいらしている。",
    "exReading": "かのじょ は あさ から いらいら して いる",
    "exTranslation": "She's been feeling irritated since this morning.",
    "category": "Adjective"
  },
  {
    "term": "固める",
    "termReading": "かためる",
    "termTranslation": "harden, strengthen",
    "ex": "私はもう決心を固めたの。",
    "exReading": "わたし は もう けっしん を かためた の",
    "exTranslation": "I already made up my mind.",
    "category": "Verb"
  },
  {
    "term": "固まる",
    "termReading": "かたまる",
    "termTranslation": "harden, solidify",
    "ex": "もうプリンは固まったかな。",
    "exReading": "もう ぷりん は かたまった か な",
    "exTranslation": "Has the pudding set already?",
    "category": "Verb"
  },
  {
    "term": "固体",
    "termReading": "こたい",
    "termTranslation": "solid, solid matter",
    "ex": "氷は固体です。",
    "exReading": "こおり は こたい です",
    "exTranslation": "Ice is a solid.",
    "category": "Noun"
  },
  {
    "term": "固有",
    "termReading": "こゆう",
    "termTranslation": "peculiar to, characteristic",
    "ex": "これは日本固有の鳥です。",
    "exReading": "これ は にほん こゆう の とり です",
    "exTranslation": "This bird is native to Japan.",
    "category": "Adjectival Noun"
  },
  {
    "term": "主催",
    "termReading": "しゅさい",
    "termTranslation": "sponsorship, promotion",
    "ex": "その芸術祭は市が主催しています。",
    "exReading": "その げいじゅつさい は し が しゅさい して います",
    "exTranslation": "The art festival is sponsored by the city.",
    "category": "Verbal Noun"
  },
  {
    "term": "促す",
    "termReading": "うながす",
    "termTranslation": "hasten, urge",
    "ex": "彼に集中するよう注意を促しといたよ。",
    "exReading": "かれ に しゅうちゅう する よう ちゅうい を うながし とい た よ",
    "exTranslation": "I urged him to concentrate.",
    "category": "Verb"
  },
  {
    "term": "催促",
    "termReading": "さいそく",
    "termTranslation": "demand, urging",
    "ex": "彼女に本を返すように催促したの。",
    "exReading": "かのじょ に ほん を かえす よう に さいそく した の",
    "exTranslation": "I urged her to return the book.",
    "category": "Verbal Noun"
  },
  {
    "term": "エチケット",
    "termReading": "エチケット",
    "termTranslation": "etiquette",
    "ex": "エチケットを守ることは大切です。",
    "exReading": "えちけっと を まもる こと は たいせつ です",
    "exTranslation": "It is important to keep the rules of etiquette.",
    "category": "Noun"
  },
  {
    "term": "古典",
    "termReading": "こてん",
    "termTranslation": "classics",
    "ex": "私は古典を読むのが好きです。",
    "exReading": "わたし は こてん を よむ の が すき です",
    "exTranslation": "I like to read classics.",
    "category": "Noun"
  },
  {
    "term": "辞典",
    "termReading": "じてん",
    "termTranslation": "dictionary",
    "ex": "知らない言葉を辞典で調べました。",
    "exReading": "しらない ことば を じてん で しらべました",
    "exTranslation": "I looked up the word I didn't know in the dictionary.",
    "category": "Noun"
  },
  {
    "term": "殊に",
    "termReading": "ことに",
    "termTranslation": "especially, exceptionally",
    "ex": "ロックは殊に若者に人気だ。",
    "exReading": "ろっく は ことに わかもの に にんき だ",
    "exTranslation": "Rock music is especially popular among young people.",
    "category": "Adverb"
  },
  {
    "term": "象徴",
    "termReading": "しょうちょう",
    "termTranslation": "symbol, symbolize",
    "ex": "天皇は日本国の象徴です。",
    "exReading": "てんのう は にほんこく の しょうちょう です",
    "exTranslation": "The emperor is a symbol of Japan.",
    "category": "Verbal Noun"
  },
  {
    "term": "微か",
    "termReading": "かすか",
    "termTranslation": "faint, dim",
    "ex": "階下から微かな音が聞こえた。",
    "exReading": "かいか から かすか な おと が きこえた",
    "exTranslation": "I heard a faint sound from downstairs.",
    "category": "Adjective"
  },
  {
    "term": "ご免",
    "termReading": "ごめん",
    "termTranslation": "sorry",
    "ex": "遅れてご免。",
    "exReading": "おくれて ごめん",
    "exTranslation": "I am sorry I'm late.",
    "category": "Noun"
  },
  {
    "term": "許可",
    "termReading": "きょか",
    "termTranslation": "permission, approval",
    "ex": "先生に許可をもらって早退しました。",
    "exReading": "せんせい に きょか を もらって そうたい しました",
    "exTranslation": "I got permission from the teacher and left early.",
    "category": "Verbal Noun"
  },
  {
    "term": "訓練",
    "termReading": "くんれん",
    "termTranslation": "training, drill",
    "ex": "学校で避難訓練がありました。",
    "exReading": "がっこう で ひなん くんれん が ありました",
    "exTranslation": "There was a fire drill at the school.",
    "category": "Verbal Noun"
  },
  {
    "term": "かっと",
    "termReading": "かっと",
    "termTranslation": "lose one's temper, fly into a rage",
    "ex": "かっとなって余計なことを言ってしまったな。",
    "exReading": "かっと なって よけい な こと を いって しまった な",
    "exTranslation": "I lost my temper and said something I shouldn't have.",
    "category": "Adverb"
  },
  {
    "term": "訓読み",
    "termReading": "くんよみ",
    "termTranslation": "Japanese pronunciation",
    "ex": "漢字には音読みと訓読みがあります。",
    "exReading": "かんじ に は おんよみ と くんよみ が あります",
    "exTranslation": "Every kanji has both Chinese-derived and Japanese pronunciations.",
    "category": "Verbal Noun"
  },
  {
    "term": "訓",
    "termReading": "くん",
    "termTranslation": "Japanese reading of kanji",
    "ex": "漢字の読み方には、音と訓の2通りがあるの。",
    "exReading": "かんじ の よみかた に は おん と くん の ふたとおり が ある の",
    "exTranslation": "There are two ways to pronounce kanji: the Chinese-derived reading and the Japanese reading.",
    "category": "Noun"
  },
  {
    "term": "助詞",
    "termReading": "じょし",
    "termTranslation": "particle, postposition",
    "ex": "助詞を変えると文の意味が変わります。",
    "exReading": "じょし を かえる と ぶん の いみ が かわります",
    "exTranslation": "The sentence meaning changes when the particle is changed.",
    "category": "Noun"
  },
  {
    "term": "形容詞",
    "termReading": "けいようし",
    "termTranslation": "adjective",
    "ex": "「大きい」は形容詞です。",
    "exReading": "おおきい は けいようし です",
    "exTranslation": "Large is an adjective.",
    "category": "Noun"
  },
  {
    "term": "上司",
    "termReading": "じょうし",
    "termTranslation": "boss",
    "ex": "上司に相談してみます。",
    "exReading": "じょうし に そうだん して みます",
    "exTranslation": "I will consult my boss.",
    "category": "Noun"
  },
  {
    "term": "司会",
    "termReading": "しかい",
    "termTranslation": "master of ceremonies, host",
    "ex": "彼は司会が上手ですね。",
    "exReading": "かれ は しかい が じょうず です ね",
    "exTranslation": "He is a good moderator.",
    "category": "Verbal Noun"
  },
  {
    "term": "購入",
    "termReading": "こうにゅう",
    "termTranslation": "purchase",
    "ex": "入学式の後、教科書を購入してください。",
    "exReading": "にゅうがくしき の あと きょうかしょ を こうにゅう して ください",
    "exTranslation": "Please buy the textbooks after the entrance ceremony.",
    "category": "Verbal Noun"
  },
  {
    "term": "がらがら",
    "termReading": "がらがら",
    "termTranslation": "gargle",
    "ex": "誰かがガラガラとうがいをしているね。",
    "exReading": "だれか が がらがら と うがい を して いる ね",
    "exTranslation": "Someone is gargling loudly.",
    "category": "Adverb"
  },
  {
    "term": "講演",
    "termReading": "こうえん",
    "termTranslation": "lecture",
    "ex": "彼の講演は評判がいい。",
    "exReading": "かれ の こうえん は ひょうばん が いい",
    "exTranslation": "His lectures have a good reputation.",
    "category": "Verbal Noun"
  },
  {
    "term": "講義",
    "termReading": "こうぎ",
    "termTranslation": "lecture",
    "ex": "彼の講義はとても分かりやすいですね。",
    "exReading": "かれ の こうぎ は とても わかり やすい です ね",
    "exTranslation": "His lectures are very easy to understand.",
    "category": "Verbal Noun"
  },
  {
    "term": "休講",
    "termReading": "きゅうこう",
    "termTranslation": "cancellation of lecture",
    "ex": "今日のフランス語の講座は休講です。",
    "exReading": "きょう の ふらんすご の こうざ は きゅうこう です",
    "exTranslation": "Today's French class is canceled.",
    "category": "Verbal Noun"
  },
  {
    "term": "医師",
    "termReading": "いし",
    "termTranslation": "doctor, physician",
    "ex": "医師に入院を勧められたの。",
    "exReading": "いし に にゅういん を すすめられた の",
    "exTranslation": "The doctor suggested I admit myself to a hospital.",
    "category": "Noun"
  },
  {
    "term": "講師",
    "termReading": "こうし",
    "termTranslation": "speaker, lecturer",
    "ex": "専門家を講師に招いた。",
    "exReading": "せんもんか を こうし に まねいた",
    "exTranslation": "We invited a specialist to lecture the class.",
    "category": "Noun"
  },
  {
    "term": "技師",
    "termReading": "ぎし",
    "termTranslation": "engineer, technician",
    "ex": "彼はレントゲン技師です。",
    "exReading": "かれ は れんとげん ぎし です",
    "exTranslation": "He is a radiographer.",
    "category": "Noun"
  },
  {
    "term": "師走",
    "termReading": "しわす",
    "termTranslation": "December",
    "ex": "師走に入ると忙しくなります。",
    "exReading": "しわす に はいる と いそがしく なります",
    "exTranslation": "I get very busy once December comes.",
    "category": "Noun"
  },
  {
    "term": "かんかん",
    "termReading": "かんかん",
    "termTranslation": "furious",
    "ex": "彼女はかんかんに怒っています。",
    "exReading": "かのじょ は かんかん に おこって います",
    "exTranslation": "She is inflamed with rage.",
    "category": "Adverb"
  },
  {
    "term": "お手伝い",
    "termReading": "おてつだい",
    "termTranslation": "helper, assistant",
    "ex": "何かお手伝いしましょうか。",
    "exReading": "なに か おてつだい しましょう か",
    "exTranslation": "Is there anything I can do to help?",
    "category": "Verbal Noun"
  },
  {
    "term": "小鳥",
    "termReading": "ことり",
    "termTranslation": "small bird",
    "ex": "誕生日に小鳥を買ってもらいました。",
    "exReading": "たんじょうび に ことり を かって もらいました",
    "exTranslation": "I was bought a small bird for my birthday.",
    "category": "Noun"
  },
  {
    "term": "大声",
    "termReading": "おおごえ",
    "termTranslation": "loud voice",
    "ex": "私たちは大声で歌を歌ったの。",
    "exReading": "わたしたち は おおごえ で うた を うたった の",
    "exTranslation": "We sang loudly.",
    "category": "Noun"
  },
  {
    "term": "歌声",
    "termReading": "うたごえ",
    "termTranslation": "singing voice",
    "ex": "校舎から歌声が聞こえて来たよ。",
    "exReading": "こうしゃ から うたごえ が きこえて きた よ",
    "exTranslation": "You could hear singing coming from the school building.",
    "category": "Noun"
  },
  {
    "term": "騒ぎ",
    "termReading": "さわぎ",
    "termTranslation": "clamor, commotion",
    "ex": "一体何の騒ぎですか。",
    "exReading": "いったい なん の さわぎ です か",
    "exTranslation": "What on earth is the commotion?",
    "category": "Noun"
  },
  {
    "term": "騒がしい",
    "termReading": "さわがしい",
    "termTranslation": "noisy",
    "ex": "何だか表が騒がしい。",
    "exReading": "なんだか おもて が さわがしい",
    "exTranslation": "It is noisy outside, for some reason.",
    "category": "Adjective"
  },
  {
    "term": "飼う",
    "termReading": "かう",
    "termTranslation": "raise, keep",
    "ex": "ペットを飼ったことはありますか。",
    "exReading": "ぺっと を かった こと は あります か",
    "exTranslation": "Have you ever had a pet?",
    "category": "Verb"
  },
  {
    "term": "これまで",
    "termReading": "これまで",
    "termTranslation": "until now, until here",
    "ex": "これまでの私とは違うんです。",
    "exReading": "これまで の わたし と は ちがうん です",
    "exTranslation": "I am not the same as before.",
    "category": "Noun"
  },
  {
    "term": "刺さる",
    "termReading": "ささる",
    "termTranslation": "stick, be stuck",
    "ex": "靴の底に釘が刺さってしまったんだ。",
    "exReading": "くつ の そこ に くぎ が ささって しまった ん だ",
    "exTranslation": "A nail has stuck in the bottom of my shoe.",
    "category": "Verb"
  },
  {
    "term": "急激",
    "termReading": "きゅうげき",
    "termTranslation": "sudden, abrupt",
    "ex": "これから高齢化が急激に進みます。",
    "exReading": "これから こうれいか が きゅうげき に すすみます",
    "exTranslation": "The aging of the population will rapidly advance in the years ahead.",
    "category": "Adjectival Noun"
  },
  {
    "term": "刺激",
    "termReading": "しげき",
    "termTranslation": "stimulus, stimulation",
    "ex": "そのクイズ番組は脳を刺激するね。",
    "exReading": "その くいずばんぐみ は のう を しげき する ね",
    "exTranslation": "The quiz show stimulates the brain.",
    "category": "Verbal Noun"
  },
  {
    "term": "感激",
    "termReading": "かんげき",
    "termTranslation": "deep emotion, moving",
    "ex": "感激して泣いてしまいました。",
    "exReading": "かんげき して ないて しまいました",
    "exTranslation": "I was moved to tears.",
    "category": "Verbal Noun"
  },
  {
    "term": "興味",
    "termReading": "きょうみ",
    "termTranslation": "interest",
    "ex": "私は歴史に興味があります。",
    "exReading": "わたし は れきし に きょうみ が あります",
    "exTranslation": "I'm interested in history.",
    "category": "Noun"
  },
  {
    "term": "興奮",
    "termReading": "こうふん",
    "termTranslation": "excitement, agitation",
    "ex": "彼女の優勝に感激し興奮しました。",
    "exReading": "かのじょ の ゆうしょう に かんげき し こうふん しました",
    "exTranslation": "I was excited and moved by her victory.",
    "category": "Verbal Noun"
  },
  {
    "term": "驚き",
    "termReading": "おどろき",
    "termTranslation": "surprise, amazement",
    "ex": "彼女は驚きを隠せませんでしたよ。",
    "exReading": "かのじょ は おどろき を かくせませんでした よ",
    "exTranslation": "She was not able to conceal her surprise.",
    "category": "Noun"
  },
  {
    "term": "驚かす",
    "termReading": "おどろかす",
    "termTranslation": "surprise, amaze",
    "ex": "あなたを驚かす話があります。",
    "exReading": "あなた を おどろかす はなし が あります",
    "exTranslation": "I have a story that will surprise you.",
    "category": "Verb"
  },
  {
    "term": "じめじめ",
    "termReading": "じめじめ",
    "termTranslation": "feel damp, clammy",
    "ex": "梅雨時はじめじめする。",
    "exReading": "つゆどき は じめじめ する",
    "exTranslation": "It is damp in the rainy season.",
    "category": "Adverb"
  },
  {
    "term": "至る",
    "termReading": "いたる",
    "termTranslation": "come to, reach",
    "ex": "ようやく結論に至ったようね",
    "exReading": "ようやく けつろん に いたった よう ね",
    "exTranslation": "It seemed that we finally reached a conclusion.",
    "category": "Verb"
  },
  {
    "term": "至る所",
    "termReading": "いたるところ",
    "termTranslation": "everywhere, all over",
    "ex": "コンビニは至る所にあります。",
    "exReading": "こんびに は いたるところ に あります",
    "exTranslation": "Convenience stores are everywhere.",
    "category": "Noun"
  },
  {
    "term": "至急",
    "termReading": "しきゅう",
    "termTranslation": "urgent, pressing",
    "ex": "会社から「至急」との連絡があったんだ。",
    "exReading": "かいしゃ から しきゅう と の れんらく が あった ん だ",
    "exTranslation": "The company contacted me on an \"urgent\" matter.",
    "category": "Noun"
  },
  {
    "term": "一致",
    "termReading": "いっち",
    "termTranslation": "accord, agreement",
    "ex": "皆の意見が一致しました。",
    "exReading": "みんな の いけん が いっち しました",
    "exTranslation": "We all agreed in opinion.",
    "category": "Verbal Noun"
  },
  {
    "term": "致す",
    "termReading": "いたす",
    "termTranslation": "humbly do",
    "ex": "私からご連絡致します。",
    "exReading": "わたし から ごれんらく いたします",
    "exTranslation": "I'll get in touch with you.",
    "category": "Verb"
  },
  {
    "term": "傾く",
    "termReading": "かたむく",
    "termTranslation": "decline, go down",
    "ex": "お日様が西に傾きましたね。",
    "exReading": "おひさま が にし に かたむきました ね",
    "exTranslation": "The sun went down in the west.",
    "category": "Verb"
  },
  {
    "term": "傾ける",
    "termReading": "かたむける",
    "termTranslation": "lean, cock (one's head)",
    "ex": "彼女は首を少し傾けて笑うの。",
    "exReading": "かのじょ は くび を すこし かたむけて わらう の",
    "exTranslation": "She cocks her head slightly to the side when she smiles.",
    "category": "Verb"
  },
  {
    "term": "じろじろ",
    "termReading": "じろじろ",
    "termTranslation": "stare",
    "ex": "人をじろじろ見るものではありません。",
    "exReading": "ひと を じろじろ みる もの で は ありません",
    "exTranslation": "Do not stare at people.",
    "category": "Adverb"
  },
  {
    "term": "坂",
    "termReading": "さか",
    "termTranslation": "slope, incline",
    "ex": "この坂を上るのはすごくきついね。",
    "exReading": "この さか を のぼる の は すごく きつい ね",
    "exTranslation": "Going up this slope is very hard.",
    "category": "Noun"
  },
  {
    "term": "狭まる",
    "termReading": "せばまる",
    "termTranslation": "become narrow, contract",
    "ex": "ここから道の幅が狭まっています。",
    "exReading": "ここ から みち の はば が せばまって います",
    "exTranslation": "The road narrows from here.",
    "category": "Verb"
  },
  {
    "term": "狭める",
    "termReading": "せばめる",
    "termTranslation": "narrow, contract",
    "ex": "もっと範囲を狭めて探しましょう",
    "exReading": "もっと はんい を せばめて さがしましょう",
    "exTranslation": "Let's narrow the range and search.",
    "category": "Verb"
  },
  {
    "term": "徐行",
    "termReading": "じょこう",
    "termTranslation": "slow speed",
    "ex": "この先は徐行して下さい。",
    "exReading": "この さき は じょこう して ください",
    "exTranslation": "Please go slowly ahead.",
    "category": "Verbal Noun"
  },
  {
    "term": "硬さ",
    "termReading": "かたさ",
    "termTranslation": "hardness",
    "ex": "理科の時間に石の硬さを比べたよ。",
    "exReading": "りか の じかん に いし の かたさ を くらべた よ",
    "exTranslation": "In science class we compared the hardness of stones.",
    "category": "Noun"
  },
  {
    "term": "柔軟",
    "termReading": "じゅうなん",
    "termTranslation": "pliable, flexible",
    "ex": "彼は柔軟に対応をした。",
    "exReading": "かれ は じゅうなん に たいおう を した",
    "exTranslation": "He gave a flexible response.",
    "category": "Adjective"
  },
  {
    "term": "緊急",
    "termReading": "きんきゅう",
    "termTranslation": "urgent, pressing",
    "ex": "緊急事態が発生した。",
    "exReading": "きんきゅう じたい が はっせい した",
    "exTranslation": "An emergency situation arose.",
    "category": "Adjectival Noun"
  },
  {
    "term": "あり",
    "termReading": "あり",
    "termTranslation": "ant",
    "ex": "ありの群れが砂糖にたかっている。",
    "exReading": "あり の むれ が さとう に たかっている 。",
    "exTranslation": "The army of ants is swarming around the sugar.",
    "category": "Noun"
  },
  {
    "term": "緊張",
    "termReading": "きんちょう",
    "termTranslation": "tension, strain",
    "ex": "彼は緊張していたな。",
    "exReading": "かれ は きんちょう して いた な",
    "exTranslation": "He felt nervous.",
    "category": "Verbal Noun"
  },
  {
    "term": "拡張",
    "termReading": "かくちょう",
    "termTranslation": "expansion, extension",
    "ex": "その会社は店舗を拡張していますね。",
    "exReading": "その かいしゃ は てんぽ を かくちょう して います ね",
    "exTranslation": "The company is expanding the store.",
    "category": "Verbal Noun"
  },
  {
    "term": "出張",
    "termReading": "しゅっちょう",
    "termTranslation": "business trip",
    "ex": "部長は京都に出張中です。",
    "exReading": "ぶちょう は きょうと に しゅっちょうちゅう です",
    "exTranslation": "The manager is on a business trip to Kyoto.",
    "category": "Verbal Noun"
  },
  {
    "term": "衝突",
    "termReading": "しょうとつ",
    "termTranslation": "collision, conflict",
    "ex": "そこの角で車同士が衝突したのよ。",
    "exReading": "そこ の かど で くるま どうし が しょうとつ した の よ",
    "exTranslation": "The cars collided into each other at the corner there.",
    "category": "Verbal Noun"
  },
  {
    "term": "煙突",
    "termReading": "えんとつ",
    "termTranslation": "chimney, smokestack",
    "ex": "工場の煙突から煙が上がっている。",
    "exReading": "こうじょう の えんとつ から けむり が あがって いる",
    "exTranslation": "Smoke is rising from the factory's smokestack.",
    "category": "Noun"
  },
  {
    "term": "避ける",
    "termReading": "さける",
    "termTranslation": "avoid, evade",
    "ex": "彼女は昨日から私のことを避けているようなんだ。",
    "exReading": "かのじょ は きのう から わたし の こと を さけて いる よう なん だ",
    "exTranslation": "She seems to have been avoiding me since yesterday.",
    "category": "Verb"
  },
  {
    "term": "軍事",
    "termReading": "ぐんじ",
    "termTranslation": "military affairs",
    "ex": "彼は軍事に関わる仕事を続けてきたんだ。",
    "exReading": "かれ は ぐんじ に かかわる しごと を つづけて きた ん だ",
    "exTranslation": "He kept working in a job related to military affairs.",
    "category": "Noun"
  },
  {
    "term": "軍",
    "termReading": "ぐん",
    "termTranslation": "army, troops",
    "ex": "怪我人は軍の病院に運ばれたよ。",
    "exReading": "けがにん は ぐん の びょういん に はこばれた よ",
    "exTranslation": "The injured were taken to the army hospital.",
    "category": "Noun"
  },
  {
    "term": "あいづち",
    "termReading": "あいづち",
    "termTranslation": "responsive sounds, chiming in",
    "ex": "彼の話に私はあいづちを打ったの。",
    "exReading": "かれ の はなし に わたし は あいづち を うった の",
    "exTranslation": "I chimed in as he spoke.",
    "category": "Noun"
  },
  {
    "term": "自衛隊",
    "termReading": "じえいたい",
    "termTranslation": "Self Defense Forces",
    "ex": "自衛隊がイラクに派遣されたわ。",
    "exReading": "じえいたい が いらく に はけん された わ",
    "exTranslation": "The Self Defense Forces were sent to Iraq.",
    "category": "Noun"
  },
  {
    "term": "軍隊",
    "termReading": "ぐんたい",
    "termTranslation": "army, troops",
    "ex": "彼は軍隊に入ったよ。",
    "exReading": "かれ は ぐんたい に はいった よ",
    "exTranslation": "He entered the military.",
    "category": "Noun"
  },
  {
    "term": "核",
    "termReading": "かく",
    "termTranslation": "core, atomic nucleus",
    "ex": "核戦争は絶対に防ぐべきよ。",
    "exReading": "かくせんそう は ぜったい に ふせぐ べき よ",
    "exTranslation": "Nuclear war should be avoided at all costs.",
    "category": "Noun"
  },
  {
    "term": "結核",
    "termReading": "けっかく",
    "termTranslation": "tuberculosis",
    "ex": "昔は結核でたくさんの人が亡くなった。",
    "exReading": "むかし は けっかく で たくさん の ひと が なくなった",
    "exTranslation": "In the past, a lot of people died of tuberculosis.",
    "category": "Noun"
  },
  {
    "term": "専攻",
    "termReading": "せんこう",
    "termTranslation": "major, specialization",
    "ex": "大学では物理を専攻していました。",
    "exReading": "だいがく で は ぶつり を せんこう して いました",
    "exTranslation": "I majored in physics at university.",
    "category": "Verbal Noun"
  },
  {
    "term": "攻める",
    "termReading": "せめる",
    "termTranslation": "attack, take the offensive",
    "ex": "彼は積極的に攻めたが勝てなかったな。",
    "exReading": "かれ は せっきょくてき に せめた が かてなかった な",
    "exTranslation": "He didn't win although he put up a good attack.",
    "category": "Verb"
  },
  {
    "term": "撃つ",
    "termReading": "うつ",
    "termTranslation": "fire, shoot",
    "ex": "彼は拳銃で撃たれたわ。",
    "exReading": "かれ は けんじゅう で うたれた わ",
    "exTranslation": "He was shot with a handgun.",
    "category": "Verb"
  },
  {
    "term": "かちかち",
    "termReading": "かちかち",
    "termTranslation": "hard, solid",
    "ex": "池がかちかちに凍っています。",
    "exReading": "いけ が かちかち に こおって います",
    "exTranslation": "The pond is frozen solid.",
    "category": "Adjective"
  },
  {
    "term": "暴れる",
    "termReading": "あばれる",
    "termTranslation": "act violently, rage about",
    "ex": "彼は悪酔いして暴れたんだ。",
    "exReading": "かれ は わるよい して あばれた ん だ",
    "exTranslation": "He was so drunk that he became violent.",
    "category": "Verb"
  },
  {
    "term": "襲う",
    "termReading": "おそう",
    "termTranslation": "raid, attack",
    "ex": "山で登山客が熊に襲われたよ。",
    "exReading": "やま で とざん きゃく が くま に おそわれた よ",
    "exTranslation": "Some climbers were attacked by a bear in the mountains.",
    "category": "Verb"
  },
  {
    "term": "絶対",
    "termReading": "ぜったい",
    "termTranslation": "absolutely",
    "ex": "そんなことは絶対できないよ。",
    "exReading": "そんな こと は ぜったい できない よ",
    "exTranslation": "I can never ever do such a thing.",
    "category": "Adverb"
  },
  {
    "term": "気絶",
    "termReading": "きぜつ",
    "termTranslation": "fainting",
    "ex": "彼女は驚いて気絶してしまったの。",
    "exReading": "かのじょ は おどろいて きぜつ して しまった の",
    "exTranslation": "She fainted in surprise.",
    "category": "Verbal Noun"
  },
  {
    "term": "消防",
    "termReading": "しょうぼう",
    "termTranslation": "fire fighting, fire prevention",
    "ex": "消防士は勇敢でなければならない。",
    "exReading": "しょうぼうし は ゆうかん で なけれ ば ならない",
    "exTranslation": "Fire fighters must be brave.",
    "category": "Noun"
  },
  {
    "term": "嫌う",
    "termReading": "きらう",
    "termTranslation": "dislike",
    "ex": "父はラッシュアワーを嫌っています。",
    "exReading": "ちち は らっしゅあわー を きらって います",
    "exTranslation": "My father dislikes rush hour.",
    "category": "Verb"
  },
  {
    "term": "嫌がる",
    "termReading": "いやがる",
    "termTranslation": "dislike, hate",
    "ex": "彼はタバコの煙を嫌がるの。",
    "exReading": "かれ は たばこ の けむり を いやがる の",
    "exTranslation": "He dislikes cigarette smoke.",
    "category": "Verb"
  },
  {
    "term": "くたくた",
    "termReading": "くたくた",
    "termTranslation": "exhausted",
    "ex": "今日はたくさん歩いてくたくたです。",
    "exReading": "きょう は たくさん あるいて くたくた です",
    "exTranslation": "I am exhausted because I have walked so much today.",
    "category": "Adjective"
  },
  {
    "term": "機嫌",
    "termReading": "きげん",
    "termTranslation": "mood, health",
    "ex": "彼女は大変機嫌がいいね。",
    "exReading": "かのじょ は たいへん きげん が いい ね",
    "exTranslation": "She is in a very good mood.",
    "category": "Noun"
  },
  {
    "term": "好き嫌い",
    "termReading": "すききらい",
    "termTranslation": "likes and dislikes, taste",
    "ex": "食べ物の好き嫌いは特にありません。",
    "exReading": "たべもの の すききらい は とくに ありません",
    "exTranslation": "I have no particular likes or dislikes when it comes to food.",
    "category": "Noun"
  },
  {
    "term": "抗議",
    "termReading": "こうぎ",
    "termTranslation": "protest, remonstrance",
    "ex": "彼の発言に対してたくさんの抗議があったよ。",
    "exReading": "かれ の はつげん に たいして たくさん の こうぎ が あった よ",
    "exTranslation": "His remark was met with numerous complaints.",
    "category": "Verbal Noun"
  },
  {
    "term": "素敵",
    "termReading": "すてき",
    "termTranslation": "lovely, nice",
    "ex": "素敵なプレゼントをありがとう。",
    "exReading": "すてき な ぷれぜんと を ありがとう",
    "exTranslation": "Thank you for the wonderful gift.",
    "category": "Adjective"
  },
  {
    "term": "脅かす",
    "termReading": "おどかす",
    "termTranslation": "threaten, intimidate",
    "ex": "脅かさないでよ。",
    "exReading": "おどかさない で よ",
    "exTranslation": "Don't threaten me.",
    "category": "Verb"
  },
  {
    "term": "権威",
    "termReading": "けんい",
    "termTranslation": "authority, power",
    "ex": "博士はその道の権威です。",
    "exReading": "はかせ は そのみち の けんい です",
    "exTranslation": "The doctor is an authority in that field.",
    "category": "Noun"
  },
  {
    "term": "威張る",
    "termReading": "いばる",
    "termTranslation": "put on airs, be arrogant",
    "ex": "彼は威張ってなんかいません。",
    "exReading": "かれ は いばって なんか いません",
    "exTranslation": "He's not putting on airs.",
    "category": "Verb"
  },
  {
    "term": "情勢",
    "termReading": "じょうせい",
    "termTranslation": "state of things, situation",
    "ex": "私は世界情勢を知るために毎日ニュースを見るわ。",
    "exReading": "わたし は せかい じょうせい を しる ため に まいにち にゅーす を みる わ",
    "exTranslation": "I watch the news everyday to know about world affairs.",
    "category": "Noun"
  },
  {
    "term": "こしょう",
    "termReading": "こしょう",
    "termTranslation": "pepper",
    "ex": "こしょうを入れ過ぎてスープが辛い。",
    "exReading": "こしょう を いれすぎて すーぷ が からい",
    "exTranslation": "I put in too much pepper and now the soup is spicy.",
    "category": "Noun"
  },
  {
    "term": "勢力",
    "termReading": "せいりょく",
    "termTranslation": "influence, power",
    "ex": "この頃は、ローマが勢力を伸ばしていました。",
    "exReading": "この ころ は ろーま が せいりょく を のばして いました",
    "exTranslation": "At that time Rome was expanding its power.",
    "category": "Noun"
  },
  {
    "term": "勢い",
    "termReading": "いきおい",
    "termTranslation": "vigor, momentum",
    "ex": "そのチームには勢いがありますね。",
    "exReading": "その ちーむ に は いきおい が あります ね",
    "exTranslation": "That team has momentum.",
    "category": "Noun"
  },
  {
    "term": "恐れ",
    "termReading": "おそれ",
    "termTranslation": "fear, risk",
    "ex": "叔父には心臓病の恐れがあります。",
    "exReading": "おじ に は しんぞうびょう の おそれ が あります",
    "exTranslation": "There's a possibility my uncle has heart disease.",
    "category": "Noun"
  },
  {
    "term": "恐れる",
    "termReading": "おそれる",
    "termTranslation": "fear, apprehend",
    "ex": "彼は受験の失敗を恐れています。",
    "exReading": "かれ は じゅけん の しっぱい を おそれて います",
    "exTranslation": "He is worrying about failing the examination.",
    "category": "Verb"
  },
  {
    "term": "恐らく",
    "termReading": "おそらく",
    "termTranslation": "probably",
    "ex": "明日は恐らく晴れるでしょう。",
    "exReading": "あした は おそらく はれる でしょう",
    "exTranslation": "The weather will probably clear up tomorrow.",
    "category": "Adverb"
  },
  {
    "term": "怖がる",
    "termReading": "こわがる",
    "termTranslation": "be afraid of, fear",
    "ex": "彼女はクモを怖がります。",
    "exReading": "かのじょ は くも を こわがります",
    "exTranslation": "She is afraid of spiders.",
    "category": "Verb"
  },
  {
    "term": "巨大",
    "termReading": "きょだい",
    "termTranslation": "huge, gigantic",
    "ex": "あの巨大な建物は博物館です。",
    "exReading": "あの きょだい な たてもの は はくぶつかん です",
    "exTranslation": "That huge building is a museum.",
    "category": "Adjectival Noun"
  },
  {
    "term": "すべすべ",
    "termReading": "すべすべ",
    "termTranslation": "smooth",
    "ex": "彼女の肌はすべすべしているね。",
    "exReading": "かのじょ の はだ は すべすべ して いる ね",
    "exTranslation": "Her skin is smooth.",
    "category": "Adverb"
  },
  {
    "term": "拒否",
    "termReading": "きょひ",
    "termTranslation": "denial, rejection",
    "ex": "彼女は出席を拒否した。",
    "exReading": "かのじょ は しゅっせき を きょひ した",
    "exTranslation": "She refused to attend.",
    "category": "Verbal Noun"
  },
  {
    "term": "系列",
    "termReading": "けいれつ",
    "termTranslation": "affiliation",
    "ex": "高校卒業後、系列の大学に進みました。",
    "exReading": "こうこう そつぎょう ご けいれつ の だいがく に すすみました",
    "exTranslation": "After graduating from high school, he entered an affiliated university.",
    "category": "Noun"
  },
  {
    "term": "系統",
    "termReading": "けいとう",
    "termTranslation": "system, lineage",
    "ex": "電気系統を図面で確かめました。",
    "exReading": "でんき けいとう を ずめん で たしかめました",
    "exTranslation": "I checked the electrical system on the diagram.",
    "category": "Noun"
  },
  {
    "term": "子孫",
    "termReading": "しそん",
    "termTranslation": "descendant, offspring",
    "ex": "彼は織田信長の子孫だよ。",
    "exReading": "かれ は おだのぶなが の しそん だ よ",
    "exTranslation": "He is a descendant of Oda Nobunaga.",
    "category": "Noun"
  },
  {
    "term": "絹",
    "termReading": "きぬ",
    "termTranslation": "silk, silk fabrics",
    "ex": "このシャツは絹でできています。",
    "exReading": "この しゃつ は きぬ で できて います",
    "exTranslation": "This shirt is made of silk.",
    "category": "Noun"
  },
  {
    "term": "維持",
    "termReading": "いじ",
    "termTranslation": "maintenance, upkeep",
    "ex": "彼女は何とか健康を維持しているわね。",
    "exReading": "かのじょ は なんとか けんこう を いじ して いる わ ね",
    "exTranslation": "Somehow, she manages to keep healthy.",
    "category": "Verbal Noun"
  },
  {
    "term": "紳士",
    "termReading": "しんし",
    "termTranslation": "gentleman",
    "ex": "身なりのいい紳士が話しかけてきたの。",
    "exReading": "みなり の いい しんし が はなしかけて きた の",
    "exTranslation": "A well-dressed gentleman spoke to me.",
    "category": "Noun"
  },
  {
    "term": "せっかく",
    "termReading": "せっかく",
    "termTranslation": "with much trouble, specially",
    "ex": "せっかく来たんだからゆっくりして行きなさい。",
    "exReading": "せっかく きたん だから ゆっくり して いきなさい",
    "exTranslation": "Since you've come all the way, why don't you stay for a while?",
    "category": "Adverb"
  },
  {
    "term": "偉大",
    "termReading": "いだい",
    "termTranslation": "great, mighty",
    "ex": "彼は偉大な事業をなしとげました。",
    "exReading": "かれ は いだい な じぎょう を なしとげ ました",
    "exTranslation": "He accomplished a great project.",
    "category": "Adjective"
  },
  {
    "term": "刀",
    "termReading": "かたな",
    "termTranslation": "sword, blade",
    "ex": "日本映画で刀を見た。",
    "exReading": "にほん えいが で かたな を みた",
    "exTranslation": "I saw a sword in a Japanese movie.",
    "category": "Noun"
  },
  {
    "term": "孤独",
    "termReading": "こどく",
    "termTranslation": "solitary, lonely",
    "ex": "彼は孤独な人生を送っていたんだ。",
    "exReading": "かれ は こどく な じんせい を おくって いた ん だ",
    "exTranslation": "He lived a solitary life.",
    "category": "Adjectival Noun"
  },
  {
    "term": "事柄",
    "termReading": "ことがら",
    "termTranslation": "matter, affair",
    "ex": "これまで話し合った事柄をまとめてください。",
    "exReading": "これ まで はなしあった ことがら を まとめて ください",
    "exTranslation": "Please summarize the things we have been talking about so far.",
    "category": "Noun"
  },
  {
    "term": "柄",
    "termReading": "がら",
    "termTranslation": "pattern, design",
    "ex": "彼は犬の柄のTシャツを着ているよ。",
    "exReading": "かれ は いぬ の がら の Tしゃつ を きて いる よ",
    "exTranslation": "He's wearing a T-shirt with a dog print on it.",
    "category": "Noun"
  },
  {
    "term": "柄",
    "termReading": "え",
    "termTranslation": "handle, grip",
    "ex": "この傘は柄が丈夫だな。",
    "exReading": "この かさ は え が じょうぶ だ な",
    "exTranslation": "The handle of this umbrella is strong.",
    "category": "Noun"
  },
  {
    "term": "枝",
    "termReading": "えだ",
    "termTranslation": "branch, twig",
    "ex": "強風で木の枝が折れた。",
    "exReading": "きょうふう で き の えだ が おれた",
    "exTranslation": "The tree branch broke in the strong wind.",
    "category": "Noun"
  },
  {
    "term": "あきれる",
    "termReading": "あきれる",
    "termTranslation": "be stunned in disappointment or disbelief",
    "ex": "彼の頑固さにはあきれました。",
    "exReading": "かれ の がんこさ に は あきれました",
    "exTranslation": "I was amazed at his stubbornness.",
    "category": "Verb"
  },
  {
    "term": "枯れる",
    "termReading": "かれる",
    "termTranslation": "wither, die",
    "ex": "花瓶の花が枯れました。",
    "exReading": "かびん の はな が かれました",
    "exTranslation": "The flowers in the vase died.",
    "category": "Verb"
  },
  {
    "term": "木枯らし",
    "termReading": "こがらし",
    "termTranslation": "cold wintry wind",
    "ex": "外は木枯らしが吹いているよ。",
    "exReading": "そと は こがらし が ふいて いる よ",
    "exTranslation": "A cold wintry wind is blowing outside.",
    "category": "Noun"
  },
  {
    "term": "詩",
    "termReading": "し",
    "termTranslation": "poetry",
    "ex": "彼女の詩は世界中で有名になったんだ。",
    "exReading": "かのじょ の し は せかいじゅう で ゆうめい に なった ん だ",
    "exTranslation": "Her poetry became famous all over the world.",
    "category": "Noun"
  },
  {
    "term": "詩人",
    "termReading": "しじん",
    "termTranslation": "poet",
    "ex": "彼は優れた詩人です。",
    "exReading": "かれ は すぐれた しじん です",
    "exTranslation": "He is a fine poet.",
    "category": "Noun"
  },
  {
    "term": "誠実",
    "termReading": "せいじつ",
    "termTranslation": "sincerity, honesty",
    "ex": "彼はとても誠実な人です。",
    "exReading": "かれ は とても せいじつ な ひと です",
    "exTranslation": "He is a very honest person.",
    "category": "Adjectival Noun"
  },
  {
    "term": "盛ん",
    "termReading": "さかん",
    "termTranslation": "prosperous, active",
    "ex": "その都市は商業が盛んよ。",
    "exReading": "その とし は しょうぎょう が さかん よ",
    "exTranslation": "Commerce is thriving in that city.",
    "category": "Adjective"
  },
  {
    "term": "謙遜",
    "termReading": "けんそん",
    "termTranslation": "humility, modesty",
    "ex": "そんなに謙遜しなくてもいい。",
    "exReading": "そんな に けんそん しなくて も いい",
    "exTranslation": "You do not need to be so modest.",
    "category": "Verbal Noun"
  },
  {
    "term": "兼ねる",
    "termReading": "かねる",
    "termTranslation": "serve two functions, combine",
    "ex": "彼女は秘書と事務の担当を兼ねています。",
    "exReading": "かのじょ は ひしょ と じむ の たんとう を かねて います",
    "exTranslation": "She's in charge of both secreterial and clerical work.",
    "category": "Verb"
  },
  {
    "term": "あだな",
    "termReading": "あだな",
    "termTranslation": "nickname",
    "ex": "彼のあだなは「りき」です。",
    "exReading": "かれ の あだな は 「 りき 」 です 。",
    "exTranslation": "His nickname is \"Ricky.\"",
    "category": "Noun"
  },
  {
    "term": "鋭い",
    "termReading": "するどい",
    "termTranslation": "pointed, acute",
    "ex": "鋭い刃物で指を怪我した。",
    "exReading": "するどい はもの で ゆび を けが した",
    "exTranslation": "I injured my finger on the sharp knife.",
    "category": "Adjective"
  },
  {
    "term": "鎖",
    "termReading": "くさり",
    "termTranslation": "chain, tether",
    "ex": "犬を鎖でつなぎました。",
    "exReading": "いぬ を くさり で つなぎました",
    "exTranslation": "I tied up the dog with a chain.",
    "category": "Noun"
  },
  {
    "term": "鑑賞",
    "termReading": "かんしょう",
    "termTranslation": "viewing, enjoyment (of art)",
    "ex": "今夜はオペラ鑑賞に行きます。",
    "exReading": "こんや は おぺら かんしょう に いきます",
    "exTranslation": "I'm going to see an opera tonight.",
    "category": "Verbal Noun"
  },
  {
    "term": "印鑑",
    "termReading": "いんかん",
    "termTranslation": "personal seal",
    "ex": "ここに受け取りの印鑑をお願いします。",
    "exReading": "ここ に うけとり の いんかん を おねがい します",
    "exTranslation": "Please put your personal seal here for the receipt.",
    "category": "Noun"
  },
  {
    "term": "鐘",
    "termReading": "かね",
    "termTranslation": "bell, gong",
    "ex": "この鐘の音はとてもきれいですね。",
    "exReading": "この かね の ね は とても きれい です ね",
    "exTranslation": "The sound of this bell is very beautiful.",
    "category": "Noun"
  },
  {
    "term": "寿命",
    "termReading": "じゅみょう",
    "termTranslation": "life span",
    "ex": "亀の寿命は長いんだ。",
    "exReading": "かめ の じゅみょう は ながい ん だ",
    "exTranslation": "Turtles have long life spans.",
    "category": "Noun"
  },
  {
    "term": "海水浴",
    "termReading": "かいすいよく",
    "termTranslation": "sea bathing",
    "ex": "夏休みには海水浴に行きます。",
    "exReading": "なつやすみ に は かいすいよく に いきます",
    "exTranslation": "I go to swim in the sea during the summer.",
    "category": "Verbal Noun"
  },
  {
    "term": "あふれる",
    "termReading": "あふれる",
    "termTranslation": "over flow, flood",
    "ex": "バスタブからお湯があふれました。",
    "exReading": "ばすたぶ から おゆ が あふれました",
    "exTranslation": "The hot water overflowed from the bathtub.",
    "category": "Verb"
  },
  {
    "term": "沿岸",
    "termReading": "えんがん",
    "termTranslation": "coast, shore",
    "ex": "今日は沿岸の波が荒いでしょう。",
    "exReading": "きょう は えんがん の なみ が あらい でしょう",
    "exTranslation": "The waves on the coast will be rough today.",
    "category": "Noun"
  },
  {
    "term": "砂浜",
    "termReading": "すなはま",
    "termTranslation": "sandy beach, sands",
    "ex": "朝早く砂浜を散歩しました。",
    "exReading": "あさ はやく すなはま を さんぽ しました",
    "exTranslation": "I strolled on the beach early in the morning.",
    "category": "Noun"
  },
  {
    "term": "沖",
    "termReading": "おき",
    "termTranslation": "offshore",
    "ex": "沖に小島が見えます。",
    "exReading": "おき に こじま が みえます",
    "exTranslation": "A small island can be seen off the shore.",
    "category": "Noun"
  },
  {
    "term": "泉",
    "termReading": "いずみ",
    "termTranslation": "spring, fountain",
    "ex": "森の中にきれいな泉があるの。",
    "exReading": "もり の なか に きれい な いずみ が ある の",
    "exTranslation": "There is a beautiful spring in the forest.",
    "category": "Noun"
  },
  {
    "term": "温泉",
    "termReading": "おんせん",
    "termTranslation": "hot spring",
    "ex": "ここの温泉はよく効くそうです。",
    "exReading": "ここ の おんせん は よく きく そう です",
    "exTranslation": "I hear this hot spring is very effective.",
    "category": "Noun"
  },
  {
    "term": "澄む",
    "termReading": "すむ",
    "termTranslation": "clear, become clear",
    "ex": "山の空気は澄んでいますね。",
    "exReading": "やま の くうき は すんで います ね",
    "exTranslation": "The mountain air is clear.",
    "category": "Verb"
  },
  {
    "term": "叫び",
    "termReading": "さけび",
    "termTranslation": "shout, cry",
    "ex": "彼女の心の叫びに誰も気付かなかったよ。",
    "exReading": "かのじょ の こころ の さけび に だれ も きづかなかった よ",
    "exTranslation": "No one noticed her cry from the heart.",
    "category": "Noun"
  },
  {
    "term": "いか",
    "termReading": "いか",
    "termTranslation": "squid",
    "ex": "私はイカの刺し身が大好きです。",
    "exReading": "わたし は いか の さしみ が だいすき です",
    "exTranslation": "I love squid sashimi.",
    "category": "Noun"
  },
  {
    "term": "叫ぶ",
    "termReading": "さけぶ",
    "termTranslation": "shout, cry out",
    "ex": "彼女は助けを求めて大声で叫んだの。",
    "exReading": "かのじょ は たすけ を もとめて おおごえ で さけんだ の",
    "exTranslation": "She cried out for help.",
    "category": "Verb"
  },
  {
    "term": "喫煙",
    "termReading": "きつえん",
    "termTranslation": "smoking",
    "ex": "ここでは喫煙できません。",
    "exReading": "ここ で は きつえん できません 。",
    "exTranslation": "Smoking is not permitted here.",
    "category": "Verbal Noun"
  },
  {
    "term": "懸ける",
    "termReading": "かける",
    "termTranslation": "stake",
    "ex": "彼は仕事に命を懸けているの。",
    "exReading": "かれ は しごと に いのち を かけている の 。",
    "exTranslation": "He risks his life in his job.",
    "category": "Verb"
  },
  {
    "term": "恩",
    "termReading": "おん",
    "termTranslation": "debt of gratitude, kindness",
    "ex": "このご恩は一生忘れません。",
    "exReading": "この ごおん は いっしょう わすれません",
    "exTranslation": "I will never forget this kindness.",
    "category": "Noun"
  },
  {
    "term": "市街",
    "termReading": "しがい",
    "termTranslation": "the streets, downtown",
    "ex": "夕方の市街は車が渋滞するよ。",
    "exReading": "ゆうがた の しがい は くるま が じゅうたい する よ",
    "exTranslation": "The town gets congested with cars in the late afternoon.",
    "category": "Noun"
  },
  {
    "term": "粉",
    "termReading": "こな",
    "termTranslation": "powder, flour",
    "ex": "その白い粉は赤ちゃんのミルクです。",
    "exReading": "その しろい こな は あかちゃん の みるく です",
    "exTranslation": "The white powder is baby's milk.",
    "category": "Noun"
  },
  {
    "term": "小麦",
    "termReading": "こむぎ",
    "termTranslation": "wheat",
    "ex": "小麦は色々な食べ物に使われている。",
    "exReading": "こむぎ は いろいろ な たべもの に つかわれて いる",
    "exTranslation": "Wheat is used in many foods.",
    "category": "Noun"
  },
  {
    "term": "小麦粉",
    "termReading": "こむぎこ",
    "termTranslation": "flour",
    "ex": "うどんは小麦粉から作られます。",
    "exReading": "うどん は こむぎこ から つくられます",
    "exTranslation": "Udon noodles are made from flour.",
    "category": "Noun"
  },
  {
    "term": "いつのまに",
    "termReading": "いつのまに",
    "termTranslation": "when",
    "ex": "彼はいつのまにいなくなったの。",
    "exReading": "かれ は いつのまに いなく なった の",
    "exTranslation": "When did he leave?",
    "category": "Adverb"
  },
  {
    "term": "大麦",
    "termReading": "おおむぎ",
    "termTranslation": "barley",
    "ex": "大麦はビールの原料になります。",
    "exReading": "おおむぎ は びーる の げんりょう に なります",
    "exTranslation": "Barley is one of the ingredients for beer.",
    "category": "Noun"
  },
  {
    "term": "炊事",
    "termReading": "すいじ",
    "termTranslation": "cooking",
    "ex": "うちでは夫も炊事をします。",
    "exReading": "うち で は おっと も すいじ を します",
    "exTranslation": "In my house, my husband also cooks.",
    "category": "Verbal Noun"
  },
  {
    "term": "暦",
    "termReading": "こよみ",
    "termTranslation": "calendar, almanac",
    "ex": "暦の上では今日から冬ですね。",
    "exReading": "こよみ の うえ で は きょう から ふゆ です ね",
    "exTranslation": "Winter starts today according to the almanac.",
    "category": "Noun"
  },
  {
    "term": "皮",
    "termReading": "かわ",
    "termTranslation": "peel, fur",
    "ex": "りんごの皮をむきましたよ。",
    "exReading": "りんご の かわ を むきました よ",
    "exTranslation": "I peeled an apple.",
    "category": "Noun"
  },
  {
    "term": "毛皮",
    "termReading": "けがわ",
    "termTranslation": "fur",
    "ex": "彼女は毛皮のコートを着ていたの。",
    "exReading": "かのじょ は けがわ の こーと を きて いた の",
    "exTranslation": "She wore a fur coat.",
    "category": "Noun"
  },
  {
    "term": "毛糸",
    "termReading": "けいと",
    "termTranslation": "woolen yarn, wool",
    "ex": "彼女は毛糸のセーターを編みました。",
    "exReading": "かのじょ は けいと の せーたー を あみました",
    "exTranslation": "She knitted a woolen sweater.",
    "category": "Noun"
  },
  {
    "term": "尾",
    "termReading": "お",
    "termTranslation": "tail",
    "ex": "尾の長い鳥が飛んでいますね。",
    "exReading": "お の ながい とり が とんで います ね",
    "exTranslation": "A bird with a long tail is flying.",
    "category": "Noun"
  },
  {
    "term": "インフレーション",
    "termReading": "インフレーション",
    "termTranslation": "inflation",
    "ex": "市場にはインフレーションの影響が出ているね。",
    "exReading": "しじょう に は いんふれーしょん の えいきょう が でて いる ね",
    "exTranslation": "The market showed the impact of inflation.",
    "category": "Noun"
  },
  {
    "term": "白髪",
    "termReading": "しらが",
    "termTranslation": "white hair, gray hair",
    "ex": "祖父は白髪が少ないほうです。",
    "exReading": "そふ は しらが が すくない ほう です",
    "exTranslation": "My grandfather has relatively little gray hair.",
    "category": "Noun"
  },
  {
    "term": "耳鼻科",
    "termReading": "じびか",
    "termTranslation": "otorhinology, ENT",
    "ex": "今、耳鼻科にかかっています。",
    "exReading": "いま じびか に かかって います",
    "exTranslation": "I go to an ENT specialist now.",
    "category": "Noun"
  },
  {
    "term": "唇",
    "termReading": "くちびる",
    "termTranslation": "lip, lips",
    "ex": "寒くて唇が青くなってしまった。",
    "exReading": "さむく て くちびる が あおく なって しまった",
    "exTranslation": "My lips are blue with cold.",
    "category": "Noun"
  },
  {
    "term": "解釈",
    "termReading": "かいしゃく",
    "termTranslation": "interpretation, explanation",
    "ex": "この詩を解釈してみましょう。",
    "exReading": "この し を かいしゃく して みましょう",
    "exTranslation": "Let's try to interpret this poem.",
    "category": "Verbal Noun"
  },
  {
    "term": "居間",
    "termReading": "いま",
    "termTranslation": "living room",
    "ex": "父は居間でテレビを見ている。",
    "exReading": "ちち は いま で てれび を みて いる",
    "exTranslation": "My father is watching TV in the living room.",
    "category": "Noun"
  },
  {
    "term": "居る",
    "termReading": "おる",
    "termTranslation": "be present, stay (humble)",
    "ex": "母は今、うちに居りません。",
    "exReading": "はは は いま うち に おりません",
    "exTranslation": "My mother isn't at home now.",
    "category": "Verb"
  },
  {
    "term": "一層",
    "termReading": "いっそう",
    "termTranslation": "more, even more",
    "ex": "雨が一層激しくなったね。",
    "exReading": "あめ が いっそう はげしく なった ね",
    "exTranslation": "The rain got even heavier.",
    "category": "Adverb"
  },
  {
    "term": "ウィークエンド",
    "termReading": "ウィークエンド",
    "termTranslation": "weekend",
    "ex": "今度のウィークエンドは映画を見ます。",
    "exReading": "こんど の うぃーくえんど は えいが を みます",
    "exTranslation": "I am going to watch a movie this weekend.",
    "category": "Noun"
  },
  {
    "term": "高層",
    "termReading": "こうそう",
    "termTranslation": "high altitude, high-rise",
    "ex": "このあたりは高層ビルが増えましたね。",
    "exReading": "この あたり は こうそうびる が ふえました ね",
    "exTranslation": "The number of high-rise buildings has increased around here.",
    "category": "Noun"
  },
  {
    "term": "覆う",
    "termReading": "おおう",
    "termTranslation": "cover, hide",
    "ex": "雲が空を覆っているね。",
    "exReading": "くも が そら を おおって いる ね",
    "exTranslation": "The sky is overcast.",
    "category": "Verb"
  },
  {
    "term": "戸籍",
    "termReading": "こせき",
    "termTranslation": "family register",
    "ex": "結婚すると新しい戸籍が作られます。",
    "exReading": "けっこん する と あたらしい こせき が つくられます",
    "exTranslation": "A new family register is made when a couple marry.",
    "category": "Noun"
  },
  {
    "term": "ガラス戸",
    "termReading": "がらすど",
    "termTranslation": "glass door",
    "ex": "お店のガラス戸が割られたんだ。",
    "exReading": "おみせ の がらすど が わられた ん だ",
    "exTranslation": "The shop's glass door was broken.",
    "category": "Noun"
  },
  {
    "term": "新鮮",
    "termReading": "しんせん",
    "termTranslation": "fresh",
    "ex": "この店では新鮮な野菜が買えますよ。",
    "exReading": "この みせ で は しんせん な やさい が かえます よ",
    "exTranslation": "You can buy fresh vegetables at this shop.",
    "category": "Adjective"
  },
  {
    "term": "鮮やか",
    "termReading": "あざやか",
    "termTranslation": "vivid",
    "ex": "山は鮮やかな緑でした。",
    "exReading": "やま は あざやか な みどり でした",
    "exTranslation": "The mountain was a vivid green.",
    "category": "Adjective"
  },
  {
    "term": "群集",
    "termReading": "ぐんしゅう",
    "termTranslation": "crowd",
    "ex": "数百人の群集が集まっているの。",
    "exReading": "すうひゃくにん の ぐんしゅう が あつまって いる の",
    "exTranslation": "A crowd of hundreds has assembled.",
    "category": "Verbal Noun"
  },
  {
    "term": "郡",
    "termReading": "ぐん",
    "termTranslation": "county",
    "ex": "この郡は来年、市になりますよ。",
    "exReading": "この ぐん は らいねん し に なります よ",
    "exTranslation": "This county will turn into a city next year.",
    "category": "Noun"
  },
  {
    "term": "うがい",
    "termReading": "うがい",
    "termTranslation": "gargling",
    "ex": "冬はまめにうがいをします。",
    "exReading": "ふゆ は まめ に うがい を します",
    "exTranslation": "I often gargle in winter.",
    "category": "Verbal Noun"
  },
  {
    "term": "君",
    "termReading": "きみ",
    "termTranslation": "you",
    "ex": "この本を君にあげます。",
    "exReading": "この ほん を きみ に あげます",
    "exTranslation": "I'm going to give you this book.",
    "category": "Noun"
  },
  {
    "term": "脂",
    "termReading": "あぶら",
    "termTranslation": "fat, grease",
    "ex": "この肉は脂が多い。",
    "exReading": "この にく は あぶら が おおい",
    "exTranslation": "This meat is fatty.",
    "category": "Noun"
  },
  {
    "term": "脂肪",
    "termReading": "しぼう",
    "termTranslation": "fat, grease",
    "ex": "お腹の脂肪を取りたい。",
    "exReading": "おなか の しぼう を とりたい",
    "exTranslation": "I want to get rid of the fat on my belly.",
    "category": "Noun"
  },
  {
    "term": "胃腸",
    "termReading": "いちょう",
    "termTranslation": "stomach and intestines",
    "ex": "彼は胃腸が強くありません。",
    "exReading": "かれ は いちょう が つよく ありません",
    "exTranslation": "His digestive system is not strong.",
    "category": "Noun"
  },
  {
    "term": "肝心",
    "termReading": "かんじん",
    "termTranslation": "vital, essential",
    "ex": "何事も最初が肝心だ。",
    "exReading": "なにごと も さいしょ が かんじん だ",
    "exTranslation": "In all things, it's the beginning that counts.",
    "category": "Adjectival Noun"
  },
  {
    "term": "山脈",
    "termReading": "さんみゃく",
    "termTranslation": "mountain range",
    "ex": "列車の窓から雄大な山脈が見えたんだよ。",
    "exReading": "れっしゃ の まど から ゆうだい な さんみゃく が みえた ん だ よ",
    "exTranslation": "I was able to see a magnificent mountain range from the window of the train.",
    "category": "Noun"
  },
  {
    "term": "暮らす",
    "termReading": "くらす",
    "termTranslation": "live, earn one's livelihood",
    "ex": "将来は海の近くで暮らしたいな。",
    "exReading": "しょうらい は うみ の ちかく で くらしたい な",
    "exTranslation": "I want to live near the sea in the future.",
    "category": "Verb"
  },
  {
    "term": "うきうき",
    "termReading": "うきうき",
    "termTranslation": "feel excited",
    "ex": "もうすぐ夏休みなのでうきうきしています。",
    "exReading": "もうすぐ なつやすみ な の で うきうき して います",
    "exTranslation": "I'm excited because the summer vacation is coming soon.",
    "category": "Adverb"
  },
  {
    "term": "暮らし",
    "termReading": "くらし",
    "termTranslation": "living, livelihood",
    "ex": "彼女は毎日の暮らしを楽しんでいますね。",
    "exReading": "かのじょ は まいにち の くらし を たのしんで います ね",
    "exTranslation": "She's enjoying her daily life.",
    "category": "Noun"
  },
  {
    "term": "暮れ",
    "termReading": "くれ",
    "termTranslation": "dusk, the end (of a season or a year)",
    "ex": "暮れは用事が多くて忙しいです。",
    "exReading": "くれ は ようじ が おおくて いそがしい です",
    "exTranslation": "I'm busy with a lot of things to do at the end of the year.",
    "category": "Noun"
  },
  {
    "term": "暮れる",
    "termReading": "くれる",
    "termTranslation": "grow dark",
    "ex": "日が暮れる前に帰りましょう。",
    "exReading": "ひ が くれる まえ に かえりましょう",
    "exTranslation": "Let's go home before it gets dark.",
    "category": "Verb"
  },
  {
    "term": "お歳暮",
    "termReading": "おせいぼ",
    "termTranslation": "year-end gift",
    "ex": "デパートでお歳暮を送りました。",
    "exReading": "でぱーと で おせいぼ を おくりました",
    "exTranslation": "I sent year-end gifts from the department store.",
    "category": "Noun"
  },
  {
    "term": "芝生",
    "termReading": "しばふ",
    "termTranslation": "lawn, turf",
    "ex": "芝生がよく手入れされていますね。",
    "exReading": "しばふ が よく ていれ されて います ね",
    "exTranslation": "The lawn is well-maintained.",
    "category": "Noun"
  },
  {
    "term": "茎",
    "termReading": "くき",
    "termTranslation": "stem",
    "ex": "チューリップは茎が長いです。",
    "exReading": "ちゅーりっぷ は くき が ながい です",
    "exTranslation": "The tulip's stalk is long.",
    "category": "Noun"
  },
  {
    "term": "推薦",
    "termReading": "すいせん",
    "termTranslation": "recommendation, nomination",
    "ex": "彼は会長に推薦されたよ。",
    "exReading": "かれ は かいちょう に すいせん された よ",
    "exTranslation": "He was recommended to the chairman.",
    "category": "Verbal Noun"
  },
  {
    "term": "うつむく",
    "termReading": "うつむく",
    "termTranslation": "face downward",
    "ex": "彼はうつむいて何かを考えているね。",
    "exReading": "かれ は うつむいて なにか を かんがえて いる ね",
    "exTranslation": "He is thinking of something with his head down.",
    "category": "Verb"
  },
  {
    "term": "荒れる",
    "termReading": "あれる",
    "termTranslation": "be wild, run waste",
    "ex": "台風で山も海も荒れています。",
    "exReading": "たいふう で やま も うみ も あれて います",
    "exTranslation": "Because of the typhoon, the weather is rough both on the mountains and in the ocean.",
    "category": "Verb"
  },
  {
    "term": "荒い",
    "termReading": "あらい",
    "termTranslation": "wild, reckless",
    "ex": "彼は金遣いが荒いな。",
    "exReading": "かれ は かねづかい が あらい な",
    "exTranslation": "He spends money recklessly.",
    "category": "Adjective"
  },
  {
    "term": "荒い",
    "termReading": "あらい",
    "termTranslation": "harsh, rough",
    "ex": "うちの犬は気が荒い。",
    "exReading": "うち の いぬ は き が あらい",
    "exTranslation": "Our dog is bad-tempered.",
    "category": "Adjective"
  },
  {
    "term": "荒す",
    "termReading": "あらす",
    "termTranslation": "damage, ransack",
    "ex": "彼は強盗に家の中を荒されたの。",
    "exReading": "かれ は ごうとう に いえ の なか を あらされた の",
    "exTranslation": "His house was ransacked by a burglar.",
    "category": "Verb"
  },
  {
    "term": "慌ただしい",
    "termReading": "あわただしい",
    "termTranslation": "busy",
    "ex": "今日は慌ただしい一日でした。",
    "exReading": "きょう は あわただしい いちにち でした",
    "exTranslation": "It was a busy day today.",
    "category": "Adjective"
  },
  {
    "term": "慌てる",
    "termReading": "あわてる",
    "termTranslation": "be flurried, hurry",
    "ex": "そんなに慌ててどこに行くの。",
    "exReading": "そんなに あわてて どこ に いく の",
    "exTranslation": "Where are you going in such a hurry?",
    "category": "Verb"
  },
  {
    "term": "儀式",
    "termReading": "ぎしき",
    "termTranslation": "ceremony, ritual",
    "ex": "儀式には作法があります。",
    "exReading": "ぎしき に は さほう が あります",
    "exTranslation": "Ceremonies have their rules of etiquette.",
    "category": "Noun"
  },
  {
    "term": "うなずく",
    "termReading": "うなずく",
    "termTranslation": "nod",
    "ex": "彼女はうなずいて同意を示したの。",
    "exReading": "かのじょ は うなずいて どうい を しめした の",
    "exTranslation": "She showed agreement with a nod.",
    "category": "Verb"
  },
  {
    "term": "行儀",
    "termReading": "ぎょうぎ",
    "termTranslation": "manners, behavior",
    "ex": "この子は本当に行儀の良い子です。",
    "exReading": "この こ は ほんとう に ぎょうぎ の いい こ です",
    "exTranslation": "This child is very well behaved.",
    "category": "Noun"
  },
  {
    "term": "犠牲",
    "termReading": "ぎせい",
    "termTranslation": "sacrifice",
    "ex": "多くの人が災害の犠牲になったの。",
    "exReading": "おおく の ひと が さいがい の ぎせい に なった の",
    "exTranslation": "A lot of people lost their lives in the disaster.",
    "category": "Noun"
  },
  {
    "term": "赤ん坊",
    "termReading": "あかんぼう",
    "termTranslation": "baby",
    "ex": "その頃彼はまだ赤ん坊だったよね。",
    "exReading": "その ころ かれ は まだ あかんぼう だった よ ね",
    "exTranslation": "He was still a baby in those days.",
    "category": "Noun"
  },
  {
    "term": "お坊さん",
    "termReading": "おぼうさん",
    "termTranslation": "monk, priest",
    "ex": "お寺からお坊さんが出て来ましたよ。",
    "exReading": "おてら から おぼうさん が でて きました よ",
    "exTranslation": "The priest came out of the temple.",
    "category": "Noun"
  },
  {
    "term": "国旗",
    "termReading": "こっき",
    "termTranslation": "national flag",
    "ex": "日本の国旗は描くのが簡単です。",
    "exReading": "にほん の こっき は かく の が かんたん です",
    "exTranslation": "It is easy to draw the national flag of Japan.",
    "category": "Noun"
  },
  {
    "term": "筋肉",
    "termReading": "きんにく",
    "termTranslation": "muscle, sinews",
    "ex": "彼は最近筋肉を鍛えているよ。",
    "exReading": "かれ は さいきん きんにく を きたえて いる よ",
    "exTranslation": "He has been building up his muscles recently.",
    "category": "Noun"
  },
  {
    "term": "筋道",
    "termReading": "すじみち",
    "termTranslation": "method, logic",
    "ex": "筋道を立てて考えなさい。",
    "exReading": "すじみち を たてて かんがえなさい",
    "exTranslation": "Think logically.",
    "category": "Noun"
  },
  {
    "term": "筋",
    "termReading": "すじ",
    "termTranslation": "muscle",
    "ex": "転んで筋を痛めてしまいました。",
    "exReading": "ころんで すじ を いためて しまいました",
    "exTranslation": "I fell down and damaged my tendon.",
    "category": "Noun"
  },
  {
    "term": "おだてる",
    "termReading": "おだてる",
    "termTranslation": "flatter",
    "ex": "彼は人をおだてるのが上手です。",
    "exReading": "かれ は ひと を おだてる の が じょうず です",
    "exTranslation": "He is good at flattering people.",
    "category": "Verb"
  },
  {
    "term": "裂ける",
    "termReading": "さける",
    "termTranslation": "split, tear",
    "ex": "釘に引っかけて袖が裂けてしまったな。",
    "exReading": "くぎ に ひっかけて そで が さけて しまった な",
    "exTranslation": "I caught it on the nail and tore the sleeve.",
    "category": "Verb"
  },
  {
    "term": "座布団",
    "termReading": "ざぶとん",
    "termTranslation": "cushion",
    "ex": "この座布団は座り心地がいいね。",
    "exReading": "この ざぶとん は すわり ごこち が いい ね",
    "exTranslation": "This cushion is comfortable to sit on.",
    "category": "Noun"
  },
  {
    "term": "自己",
    "termReading": "じこ",
    "termTranslation": "oneself, self",
    "ex": "これからは自己の判断で行動してください。",
    "exReading": "これ から は じこ の はんだん で こうどう して ください",
    "exTranslation": "Please act on your own judgment from now on.",
    "category": "Noun"
  },
  {
    "term": "抱える",
    "termReading": "かかえる",
    "termTranslation": "hold in one's arms",
    "ex": "彼は大きな荷物を抱えているわ。",
    "exReading": "かれ は おおき な にもつ を かかえて いる わ",
    "exTranslation": "He is carrying some large luggage.",
    "category": "Verb"
  },
  {
    "term": "抱く",
    "termReading": "いだく",
    "termTranslation": "embrace, hug",
    "ex": "少年よ大志を抱け。",
    "exReading": "しょうねん よ たいし を いだけ",
    "exTranslation": "Boys, be ambitious.",
    "category": "Verb"
  },
  {
    "term": "句",
    "termReading": "く",
    "termTranslation": "phrase, clause",
    "ex": "この句はどんな意味でしょうか。",
    "exReading": "この く は どんな いみ でしょう か",
    "exTranslation": "What does this phrase mean?",
    "category": "Noun"
  },
  {
    "term": "慣用句",
    "termReading": "かんようく",
    "termTranslation": "idiom, common phrase",
    "ex": "「手が空く」は慣用句です。",
    "exReading": "て が すく は かんようく です",
    "exTranslation": "I have my hands free is an idiom.",
    "category": "Noun"
  },
  {
    "term": "かかと",
    "termReading": "かかと",
    "termTranslation": "heel",
    "ex": "靴のかかとで彼の足を踏んでしまったの。",
    "exReading": "くつ の かかと で かれ の あし を ふん でしまった の 。",
    "exTranslation": "I ended up stepping on his foot with the heel of my shoe.",
    "category": "Noun"
  },
  {
    "term": "下旬",
    "termReading": "げじゅん",
    "termTranslation": "end of the month",
    "ex": "この仕事は来月の下旬には終わります。",
    "exReading": "この しごと は らいげつ の げじゅん に は おわります",
    "exTranslation": "This job will end at the end of next month.",
    "category": "Noun"
  },
  {
    "term": "上旬",
    "termReading": "じょうじゅん",
    "termTranslation": "beginning of the month",
    "ex": "7月上旬に夏祭りがありますよ。",
    "exReading": "しちがつ じょうじゅん に なつまつり が あります よ",
    "exTranslation": "There is a summer festival at the beginning of July.",
    "category": "Noun"
  },
  {
    "term": "飽きる",
    "termReading": "あきる",
    "termTranslation": "be fed up",
    "ex": "その子はおもちゃに飽きたようだね。",
    "exReading": "その こ は おもちゃ に あきた よう だ ね",
    "exTranslation": "The child seems to have gotten tired of the toy.",
    "category": "Verb"
  },
  {
    "term": "飾り",
    "termReading": "かざり",
    "termTranslation": "ornament, decoration",
    "ex": "この部屋は飾りが多すぎるな。",
    "exReading": "この へや は かざり が おおすぎる な",
    "exTranslation": "There are too many decorations in this room.",
    "category": "Noun"
  },
  {
    "term": "餌",
    "termReading": "えさ",
    "termTranslation": "bait, feed",
    "ex": "毎日金魚に餌をやります。",
    "exReading": "まいにち きんぎょ に えさ を やります",
    "exTranslation": "I feed the goldfish everyday.",
    "category": "Noun"
  },
  {
    "term": "旧",
    "termReading": "きゅう",
    "termTranslation": "old, former",
    "ex": "旧ソビエトは今はロシアと呼ばれている。",
    "exReading": "きゅうそびえと は いま は ろしあ と よばれて いる",
    "exTranslation": "The former Soviet Union is now called Russia.",
    "category": "Noun"
  },
  {
    "term": "育児",
    "termReading": "いくじ",
    "termTranslation": "childcare, child-raising",
    "ex": "彼女は育児で忙しいよ。",
    "exReading": "かのじょ は いくじ で いそがしい よ",
    "exTranslation": "She is busy looking after her children.",
    "category": "Noun"
  },
  {
    "term": "がさがさ",
    "termReading": "がさがさ",
    "termTranslation": "rustle",
    "ex": "玄関でがさがさと音がしましたよ。",
    "exReading": "げんかん で がさがさ と おと が しました よ",
    "exTranslation": "I heard a rustling sound at the entrance.",
    "category": "Adverb"
  },
  {
    "term": "小児科",
    "termReading": "しょうにか",
    "termTranslation": "pediatrics",
    "ex": "子供を小児科に連れて行くところです。",
    "exReading": "こども を しょうにか に つれて いく ところ です",
    "exTranslation": "I'm about to take my kid to the pediatrician.",
    "category": "Noun"
  },
  {
    "term": "姓名",
    "termReading": "せいめい",
    "termTranslation": "full name",
    "ex": "あなたの姓名を教えてください。",
    "exReading": "あなた の せいめい を おしえて ください",
    "exTranslation": "Please tell me your full name.",
    "category": "Noun"
  },
  {
    "term": "姓",
    "termReading": "せい",
    "termTranslation": "surname, family name",
    "ex": "結婚して姓が変わりました。",
    "exReading": "けっこん して せい が かわりました",
    "exTranslation": "I got married and changed my family name.",
    "category": "Noun"
  },
  {
    "term": "幼い",
    "termReading": "おさない",
    "termTranslation": "young, childish",
    "ex": "彼女には幼い息子がいます。",
    "exReading": "かのじょ に は おさない むすこ が います",
    "exTranslation": "She has a little son.",
    "category": "Adjective"
  },
  {
    "term": "眼科",
    "termReading": "がんか",
    "termTranslation": "ophthalmology",
    "ex": "眼科で視力検査をしました。",
    "exReading": "がんか で しりょく けんさ を しました",
    "exTranslation": "I took an eyesight test in the eye clinic.",
    "category": "Noun"
  },
  {
    "term": "近眼",
    "termReading": "きんがん",
    "termTranslation": "nearsightedness, shortsightedness",
    "ex": "彼女は近眼です。",
    "exReading": "かのじょ は きんがん です",
    "exTranslation": "She is nearsighted.",
    "category": "Noun"
  },
  {
    "term": "居眠り",
    "termReading": "いねむり",
    "termTranslation": "doze, nap",
    "ex": "彼はソファーで居眠りをしているよ。",
    "exReading": "かれ は そふぁー で いねむり を して いる よ",
    "exTranslation": "He is dozing on the sofa.",
    "category": "Verbal Noun"
  },
  {
    "term": "一瞬",
    "termReading": "いっしゅん",
    "termTranslation": "instant, moment",
    "ex": "一瞬自分の耳を疑ったよ。",
    "exReading": "いっしゅん じぶん の みみ を うたがった よ",
    "exTranslation": "For a moment I couldn't believe what I heard.",
    "category": "Noun"
  },
  {
    "term": "かじる",
    "termReading": "かじる",
    "termTranslation": "nibble, bite",
    "ex": "弟は美味しそうにりんごをかじっていたよ。",
    "exReading": "おとうと は おいし そう に りんご を かじって いた よ",
    "exTranslation": "My little brother was enjoying nibbling an apple.",
    "category": "Verb"
  },
  {
    "term": "瞬間",
    "termReading": "しゅんかん",
    "termTranslation": "instant, moment",
    "ex": "その瞬間、猫が跳び出しました。",
    "exReading": "その しゅんかん ねこ が とびだしました",
    "exTranslation": "At that instant, the cat ran out.",
    "category": "Noun"
  },
  {
    "term": "睡眠",
    "termReading": "すいみん",
    "termTranslation": "sleep, slumber",
    "ex": "1日7時間は睡眠を取るようにしています。",
    "exReading": "いちにち しちじかん は すいみん を とる よう に して います",
    "exTranslation": "I try to sleep for seven hours a day.",
    "category": "Verbal Noun"
  },
  {
    "term": "垂直",
    "termReading": "すいちょく",
    "termTranslation": "vertical, perpendicular",
    "ex": "彼らは垂直のがけを登り始めたの。",
    "exReading": "かれら は すいちょく の がけ を のぼりはじめた の",
    "exTranslation": "They began to climb a vertical cliff.",
    "category": "Adjectival Noun"
  },
  {
    "term": "心掛ける",
    "termReading": "こころがける",
    "termTranslation": "bear in mind, try",
    "ex": "安全運転を心掛けてください。",
    "exReading": "あんぜん うんてん を こころがけて ください",
    "exTranslation": "Please remember to drive safely.",
    "category": "Verb"
  },
  {
    "term": "腰掛ける",
    "termReading": "こしかける",
    "termTranslation": "sit down",
    "ex": "私はベンチに腰掛けたの。",
    "exReading": "わたし は べんち に こしかけた の",
    "exTranslation": "I had a seat on the bench.",
    "category": "Verb"
  },
  {
    "term": "追い掛ける",
    "termReading": "おいかける",
    "termTranslation": "chase, run after",
    "ex": "パトカーがバイクを追いかけてるぞ。",
    "exReading": "ぱとかー が ばいく を おいかけて る ぞ",
    "exTranslation": "The police car is chasing the motorbike.",
    "category": "Verb"
  },
  {
    "term": "腰掛け",
    "termReading": "こしかけ",
    "termTranslation": "stool, seat",
    "ex": "腰掛けはありませんか。",
    "exReading": "こしかけ は ありません か",
    "exTranslation": "Isn't there a seat?",
    "category": "Noun"
  },
  {
    "term": "がぶがぶ",
    "termReading": "がぶがぶ",
    "termTranslation": "(the sound of) guzzling",
    "ex": "彼は水をがぶがぶ飲んだんだ。",
    "exReading": "かれ は みず を がぶがぶ のんだ ん だ",
    "exTranslation": "He gulped down the water.",
    "category": "Adverb"
  },
  {
    "term": "お目に掛かる",
    "termReading": "おめにかかる",
    "termTranslation": "meet, have the honor of seeing",
    "ex": "お目に掛かれて嬉しいです。",
    "exReading": "おめにかかれて うれしい です",
    "exTranslation": "I am very pleased to meet you.",
    "category": "Verb"
  },
  {
    "term": "拝む",
    "termReading": "おがむ",
    "termTranslation": "bow in veneration, worship",
    "ex": "仏像に手を合わせて拝みました。",
    "exReading": "ぶつぞう に て を あわせて おがみました",
    "exTranslation": "We joined our hands together and prayed at the statue of Buddha.",
    "category": "Verb"
  },
  {
    "term": "括弧",
    "termReading": "かっこ",
    "termTranslation": "parentheses, brackets",
    "ex": "括弧の部分は省略できます。",
    "exReading": "かっこ の ぶぶん は しょうりゃく できます",
    "exTranslation": "The part in parentheses can be omitted.",
    "category": "Verbal Noun"
  },
  {
    "term": "指揮",
    "termReading": "しき",
    "termTranslation": "command, initiative",
    "ex": "彼がそのプロジェクトの指揮を取ったの。",
    "exReading": "かれ が その ぷろじぇくと の しき を とった の",
    "exTranslation": "He took the initiative on that project.",
    "category": "Verbal Noun"
  },
  {
    "term": "輝く",
    "termReading": "かがやく",
    "termTranslation": "shine brilliantly, glitter",
    "ex": "彼女の瞳は喜びで輝いていますね。",
    "exReading": "かのじょ の ひとみ は よろこび で かがやいて います ね",
    "exTranslation": "Her eyes are shining with delight.",
    "category": "Verb"
  },
  {
    "term": "抑える",
    "termReading": "おさえる",
    "termTranslation": "suppress, bring under control",
    "ex": "彼は怒りを抑えていたの。",
    "exReading": "かれ は いかり を おさえて いた の",
    "exTranslation": "He was restraining his anger.",
    "category": "Verb"
  },
  {
    "term": "信仰",
    "termReading": "しんこう",
    "termTranslation": "faith, religious belief",
    "ex": "信仰は心の支えです。",
    "exReading": "しんこう は こころ の ささえ です",
    "exTranslation": "Faith uplifts the spirit.",
    "category": "Verbal Noun"
  },
  {
    "term": "からす",
    "termReading": "からす",
    "termTranslation": "crow, raven",
    "ex": "木の上でからすが鳴いています。",
    "exReading": "き の うえ で からす が ないて います",
    "exTranslation": "The crow is cawing on the tree.",
    "category": "Noun"
  },
  {
    "term": "偶然",
    "termReading": "ぐうぜん",
    "termTranslation": "chance, accident",
    "ex": "街で偶然友人に会いました。",
    "exReading": "まち で ぐうぜん ゆうじん に あいました",
    "exTranslation": "I met a friend by chance downtown.",
    "category": "Adjectival Noun"
  },
  {
    "term": "偶数",
    "termReading": "ぐうすう",
    "termTranslation": "even number",
    "ex": "偶数は2で割り切れます。",
    "exReading": "ぐうすう は に で わりきれます",
    "exTranslation": "Even numbers can be divided by 2.",
    "category": "Noun"
  },
  {
    "term": "隅",
    "termReading": "すみ",
    "termTranslation": "nook, corner",
    "ex": "部屋の隅にいすが積んであったの。",
    "exReading": "へや の すみ に いす が つんで あった の",
    "exTranslation": "Chairs were piled up in the corner of the room.",
    "category": "Noun"
  },
  {
    "term": "城",
    "termReading": "しろ",
    "termTranslation": "castle, fort",
    "ex": "今回の旅行ではヨーロッパの城を見て回ります。",
    "exReading": "こんかい の りょこう で は よーろっぱ の しろ を みて まわります",
    "exTranslation": "I will look around some European castles on this trip.",
    "category": "Noun"
  },
  {
    "term": "栽培",
    "termReading": "さいばい",
    "termTranslation": "cultivation, raising",
    "ex": "この地方ではみかんの栽培が盛んです。",
    "exReading": "この ちほう で は みかん の さいばい が さかん です",
    "exTranslation": "Mandarin orange cultivation is well developed in this region.",
    "category": "Verbal Noun"
  },
  {
    "term": "警戒",
    "termReading": "けいかい",
    "termTranslation": "vigilance, precaution",
    "ex": "地震のあとは津波に警戒してください。",
    "exReading": "じしん の あと は つなみ に けいかい して ください",
    "exTranslation": "Beware of tsunamis after an earthquake.",
    "category": "Verbal Noun"
  },
  {
    "term": "幾ら",
    "termReading": "いくら",
    "termTranslation": "no matter how much",
    "ex": "幾ら呼んでも彼は返事をしなかったわ。",
    "exReading": "いくら よんで も かれ は へんじ を しなかった わ",
    "exTranslation": "No matter how many times I called, he didn't respond.",
    "category": "Adverb"
  },
  {
    "term": "伺う",
    "termReading": "うかがう",
    "termTranslation": "politely ask",
    "ex": "お話しを伺いたいのですが。",
    "exReading": "おはなし を うかがいたい の です が",
    "exTranslation": "I want to hear your story.",
    "category": "Verb"
  },
  {
    "term": "がん",
    "termReading": "がん",
    "termTranslation": "cancer",
    "ex": "祖父はがんで亡くなりました。",
    "exReading": "そふ は がん で なくなりました",
    "exTranslation": "My grandfather died of cancer.",
    "category": "Noun"
  },
  {
    "term": "後悔",
    "termReading": "こうかい",
    "termTranslation": "regret",
    "ex": "後悔しても、しょうがない。",
    "exReading": "こうかい して も しょうがない",
    "exTranslation": "There is no point in regretting the past.",
    "category": "Verbal Noun"
  },
  {
    "term": "悔しい",
    "termReading": "くやしい",
    "termTranslation": "vexing, frustrating",
    "ex": "試合に負けてとても悔しい。",
    "exReading": "しあい に まけて とても くやしい",
    "exTranslation": "I'm so frustrated that we lost the game.",
    "category": "Adjective"
  },
  {
    "term": "俺",
    "termReading": "おれ",
    "termTranslation": "I, me",
    "ex": "俺の頼みを聞いてくれ。",
    "exReading": "おれ の たのみ を きいて くれ",
    "exTranslation": "Do me a favor.",
    "category": "Noun"
  },
  {
    "term": "相撲",
    "termReading": "すもう",
    "termTranslation": "sumo wrestling",
    "ex": "お相撲さんは、みんな力持ちだ。",
    "exReading": "おすもうさん は みんな ちからもち だ",
    "exTranslation": "All sumo wrestlers are strong.",
    "category": "Noun"
  },
  {
    "term": "偏る",
    "termReading": "かたよる",
    "termTranslation": "be one-sided, deviate",
    "ex": "栄養が偏らないように食事に気を付けています。",
    "exReading": "えいよう が かたよらない よう に しょくじ に き を つけて います",
    "exTranslation": "I take care to maintain a balanced diet.",
    "category": "Verb"
  },
  {
    "term": "一遍に",
    "termReading": "いっぺんに",
    "termTranslation": "all at once",
    "ex": "春は一遍に花が咲く。",
    "exReading": "はる は いっぺんに はな が さく",
    "exTranslation": "Flowers bloom all together in spring.",
    "category": "Adverb"
  },
  {
    "term": "宗教",
    "termReading": "しゅうきょう",
    "termTranslation": "religion",
    "ex": "宗教を持たない人もたくさんいるわ。",
    "exReading": "しゅうきょう を もたない ひと も たくさん いる わ",
    "exTranslation": "There are also many people who have no religion.",
    "category": "Noun"
  },
  {
    "term": "ぎっしり",
    "termReading": "ぎっしり",
    "termTranslation": "tightly",
    "ex": "この箱にはぎっしり物が詰まっています。",
    "exReading": "この はこ に は ぎっしり もの が つまって います",
    "exTranslation": "This box is tightly packed with stuff.",
    "category": "Adverb"
  },
  {
    "term": "審議",
    "termReading": "しんぎ",
    "termTranslation": "deliberation, consideration",
    "ex": "その問題は審議中です。",
    "exReading": "その もんだい は しんぎちゅう です",
    "exTranslation": "The issue is under deliberation.",
    "category": "Verbal Noun"
  },
  {
    "term": "憲法",
    "termReading": "けんぽう",
    "termTranslation": "constitution, constitutional law",
    "ex": "憲法を改正することは難しいわね。",
    "exReading": "けんぽう を かいせい する こと は むずかしい わ ね",
    "exTranslation": "It is difficult to revise the constitution.",
    "category": "Noun"
  },
  {
    "term": "衰える",
    "termReading": "おとろえる",
    "termTranslation": "weaken, decline",
    "ex": "年と共に体力が衰えています。",
    "exReading": "とし と とも に たいりょく が おとろえて います",
    "exTranslation": "The body has become weak with age.",
    "category": "Verb"
  },
  {
    "term": "惜しむ",
    "termReading": "おしむ",
    "termTranslation": "grudge, regret",
    "ex": "私たちはみな彼の死を惜しんだの。",
    "exReading": "わたしたち は みな かれ の し を おしんだ の",
    "exTranslation": "We all felt sorry about his death.",
    "category": "Verb"
  },
  {
    "term": "惜しい",
    "termReading": "おしい",
    "termTranslation": "almost, close",
    "ex": "惜しい、もう少しで優勝だった。",
    "exReading": "おしい もうすこし で ゆうしょう だった",
    "exTranslation": "How close! We almost won.",
    "category": "Adjective"
  },
  {
    "term": "恨み",
    "termReading": "うらみ",
    "termTranslation": "grudge, hatred",
    "ex": "彼女は長年の恨みを晴らした。",
    "exReading": "かのじょ は ながねん の うらみ を はらした",
    "exTranslation": "She settled an old score.",
    "category": "Noun"
  },
  {
    "term": "恨む",
    "termReading": "うらむ",
    "termTranslation": "hold a grudge, feel resentment",
    "ex": "彼を恨んではいけません。",
    "exReading": "かれ を うらんで は いけません",
    "exTranslation": "You should not bear a grudge against him.",
    "category": "Verb"
  },
  {
    "term": "ぎゅうぎゅう",
    "termReading": "ぎゅうぎゅう",
    "termTranslation": "packed, crammed",
    "ex": "電車がぎゅうぎゅうに込んでいますね。",
    "exReading": "でんしゃ が ぎゅうぎゅう に こんで います ね",
    "exTranslation": "The train is jam-packed.",
    "category": "Adverb"
  },
  {
    "term": "覚悟",
    "termReading": "かくご",
    "termTranslation": "readiness, resolution",
    "ex": "覚悟はできています。",
    "exReading": "かくご は できて います",
    "exTranslation": "I am prepared.",
    "category": "Noun"
  },
  {
    "term": "怪しい",
    "termReading": "あやしい",
    "termTranslation": "suspicious, dubious",
    "ex": "その男の行動は怪しかったわ。",
    "exReading": "その おとこ の こうどう は あやしかった わ",
    "exTranslation": "That man's behavior was suspicious.",
    "category": "Adjective"
  },
  {
    "term": "怪しむ",
    "termReading": "あやしむ",
    "termTranslation": "suspect",
    "ex": "警察はそのグループを怪しんでいます。",
    "exReading": "けいさつ は その ぐるーぷ を あやしんで います",
    "exTranslation": "The police are suspicious of the group.",
    "category": "Verb"
  },
  {
    "term": "自慢",
    "termReading": "じまん",
    "termTranslation": "showing off",
    "ex": "彼女はよく自分の成績を自慢するよね。",
    "exReading": "かのじょ は よく じぶん の せいせき を じまん する よ ね",
    "exTranslation": "She often boasts about her results.",
    "category": "Verbal Noun"
  },
  {
    "term": "食卓",
    "termReading": "しょくたく",
    "termTranslation": "dining table",
    "ex": "食卓に花を飾りましょう。",
    "exReading": "しょくたく に はな を かざりましょう",
    "exTranslation": "Let's decorate the dining table with flowers.",
    "category": "Noun"
  },
  {
    "term": "歓迎",
    "termReading": "かんげい",
    "termTranslation": "welcome",
    "ex": "温かい歓迎を受けました。",
    "exReading": "あたたかい かんげい を うけました",
    "exTranslation": "I received a warm welcome.",
    "category": "Verbal Noun"
  },
  {
    "term": "勧める",
    "termReading": "すすめる",
    "termTranslation": "suggest, recommend",
    "ex": "勧められて欲しくもないものを買っちゃったよ。",
    "exReading": "すすめられて ほしく も ない もの を かっちゃった よ",
    "exTranslation": "On someone's advice, I ended up buying something I didn't want.",
    "category": "Verb"
  },
  {
    "term": "ぎょうざ",
    "termReading": "ぎょうざ",
    "termTranslation": "Chinese dumpling",
    "ex": "ここのぎょうざは美味しいよ。",
    "exReading": "ここ の ぎょうざ は おいしい よ",
    "exTranslation": "The dumplings here are tasty.",
    "category": "Noun"
  },
  {
    "term": "焦点",
    "termReading": "しょうてん",
    "termTranslation": "focus, focal point",
    "ex": "焦点を絞って話しましょう。",
    "exReading": "しょうてん を しぼって はなしましょう",
    "exTranslation": "Let's focus on the matter.",
    "category": "Noun"
  },
  {
    "term": "焦る",
    "termReading": "あせる",
    "termTranslation": "feel pressured, feel hurried",
    "ex": "何をそんなに焦っているのですか。",
    "exReading": "なに を そんなに あせって いる の です か",
    "exTranslation": "What are you in such a hurry for?",
    "category": "Verb"
  },
  {
    "term": "焦げる",
    "termReading": "こげる",
    "termTranslation": "scorch, burn",
    "ex": "シチューが焦げたよー。",
    "exReading": "しちゅー が こげた よー",
    "exTranslation": "The stew burnt.",
    "category": "Verb"
  },
  {
    "term": "英雄",
    "termReading": "えいゆう",
    "termTranslation": "hero",
    "ex": "彼は国の英雄ですね。",
    "exReading": "かれ は くに の えいゆう です ね",
    "exTranslation": "He is a national hero.",
    "category": "Noun"
  },
  {
    "term": "雄",
    "termReading": "おす",
    "termTranslation": "male",
    "ex": "うちの猫は雄です。",
    "exReading": "うち の ねこ は おす です",
    "exTranslation": "Our cat is a male.",
    "category": "Noun"
  },
  {
    "term": "獲得",
    "termReading": "かくとく",
    "termTranslation": "acquisition",
    "ex": "その選手の獲得賞金は2億円だって。",
    "exReading": "その せんしゅ の かくとく しょうきん は におくえん だって",
    "exTranslation": "I hear the acquisition bonus for that player is 200 million yen.",
    "category": "Verbal Noun"
  },
  {
    "term": "収穫",
    "termReading": "しゅうかく",
    "termTranslation": "harvest, harvesting",
    "ex": "お米の収穫が始まったね。",
    "exReading": "おこめ の しゅうかく が はじまった ね",
    "exTranslation": "The rice harvest has started.",
    "category": "Verbal Noun"
  },
  {
    "term": "稲",
    "termReading": "いね",
    "termTranslation": "rice plant",
    "ex": "日本は稲の品種が多いよ。",
    "exReading": "にっぽん は いね の ひんしゅ が おおい よ",
    "exTranslation": "Japan has lots of varieties of rice.",
    "category": "Noun"
  },
  {
    "term": "きれ",
    "termReading": "きれ",
    "termTranslation": "a cut of cloth",
    "ex": "余ったきれで人形を作りましょう。",
    "exReading": "あまった きれ で にんぎょう を つくりましょう",
    "exTranslation": "Let's make a doll from the excess cloth.",
    "category": "Noun"
  },
  {
    "term": "稼ぐ",
    "termReading": "かせぐ",
    "termTranslation": "earn, make money",
    "ex": "彼はお金をだいぶ稼いだらしいよ。",
    "exReading": "かれ は おかね を だいぶ かせいだ らしい よ",
    "exTranslation": "I hear he earned a lot of money.",
    "category": "Verb"
  },
  {
    "term": "原稿用紙",
    "termReading": "げんこうようし",
    "termTranslation": "manuscript paper, writing pad",
    "ex": "作文を書くのに原稿用紙を使ったの。",
    "exReading": "さくぶん を かく の に げんこうようし を つかった の",
    "exTranslation": "I used writing paper to write my composition on.",
    "category": "Noun"
  },
  {
    "term": "穏やか",
    "termReading": "おだやか",
    "termTranslation": "calm, mild",
    "ex": "彼らは穏やかな暮らしをしているの。",
    "exReading": "かれら は おだやか な くらし を して い の",
    "exTranslation": "They live a quiet life.",
    "category": "Adjective"
  },
  {
    "term": "隠す",
    "termReading": "かくす",
    "termTranslation": "hide, conceal",
    "ex": "僕に隠していることは無いですか。",
    "exReading": "ぼく に かくして いる こと は ない です か",
    "exTranslation": "You aren't hiding anything from me, are you?",
    "category": "Verb"
  },
  {
    "term": "お陰",
    "termReading": "おかげ",
    "termTranslation": "help, thanks to",
    "ex": "先生のお陰で大学に合格できました。",
    "exReading": "せんせい の おかげ で だいがく に ごうかく できました",
    "exTranslation": "Thanks to the teacher, I was able to pass the college entrance exam.",
    "category": "Noun"
  },
  {
    "term": "陰",
    "termReading": "かげ",
    "termTranslation": "shade, behind the scenes",
    "ex": "陰で少し休憩しましょう。",
    "exReading": "かげ で すこし きゅうけい しましょう",
    "exTranslation": "Let's take a break in the shade.",
    "category": "Noun"
  },
  {
    "term": "塊",
    "termReading": "かたまり",
    "termTranslation": "lump, ingot",
    "ex": "道に土の塊ができてたよ。",
    "exReading": "みち に つち の かたまり が できてた よ",
    "exTranslation": "A clump of earth had formed on the road.",
    "category": "Noun"
  },
  {
    "term": "くすぐる",
    "termReading": "くすぐる",
    "termTranslation": "tickle",
    "ex": "弟に足の裏をくすぐられた。",
    "exReading": "おとうと に あし の うら を くすぐられた",
    "exTranslation": "My younger brother tickled the sole of my foot.",
    "category": "Verb"
  },
  {
    "term": "卑しい",
    "termReading": "いやしい",
    "termTranslation": "mean, greedy",
    "ex": "彼は金に卑しいね。",
    "exReading": "かれ は かね に いやしい ね",
    "exTranslation": "He's greedy for money.",
    "category": "Adjective"
  },
  {
    "term": "砕く",
    "termReading": "くだく",
    "termTranslation": "crush, smash",
    "ex": "氷を細かく砕いてください。",
    "exReading": "こおり を こまかく くだいて ください",
    "exTranslation": "Please crush the ice into small pieces.",
    "category": "Verb"
  },
  {
    "term": "砕ける",
    "termReading": "くだける",
    "termTranslation": "be crushed, break down",
    "ex": "石が粉々に砕けましたね。",
    "exReading": "いし が こなごな に くだけました ね",
    "exTranslation": "The stone was crushed to pieces.",
    "category": "Verb"
  },
  {
    "term": "基礎",
    "termReading": "きそ",
    "termTranslation": "basis, foundation",
    "ex": "今ドイツ語の基礎を学んでいます。",
    "exReading": "いま どいつご の きそ を まなんで います",
    "exTranslation": "We are learning the basics of German at the moment.",
    "category": "Noun"
  },
  {
    "term": "貝",
    "termReading": "かい",
    "termTranslation": "shellfish, shell",
    "ex": "浜辺できれいな貝を拾いました。",
    "exReading": "はまべ で きれい な かい を ひろいました",
    "exTranslation": "I found a beautiful shell on the beach.",
    "category": "Noun"
  },
  {
    "term": "賢い",
    "termReading": "かしこい",
    "termTranslation": "wise, smart",
    "ex": "彼は冷静で賢い男だね。",
    "exReading": "かれ は れいせい で かしこい おとこ だ ね",
    "exTranslation": "He is a calm, wise man.",
    "category": "Adjective"
  },
  {
    "term": "頻りに",
    "termReading": "しきりに",
    "termTranslation": "frequently, repeatedly",
    "ex": "彼は頻りに時計を気にしていた。",
    "exReading": "かれ は しきりに とけい を き に して いた",
    "exTranslation": "He keeps looking at the clock.",
    "category": "Adverb"
  },
  {
    "term": "げらげら",
    "termReading": "げらげら",
    "termTranslation": "out loud",
    "ex": "彼女はいつまでもげらげら笑っていたな。",
    "exReading": "かのじょ は いつ まで も げらげら わらって いた な",
    "exTranslation": "She kept on laughing aloud.",
    "category": "Adverb"
  },
  {
    "term": "頑固",
    "termReading": "がんこ",
    "termTranslation": "stubborn, obstinate",
    "ex": "なんて頑固な子だ。",
    "exReading": "なんて がんこ な こ だ",
    "exTranslation": "What an obstinate child she is!",
    "category": "Adjectival Noun"
  },
  {
    "term": "頂く",
    "termReading": "いただく",
    "termTranslation": "receive, eat",
    "ex": "お客様にお菓子を頂いた。",
    "exReading": "おきゃくさま に おかし を いただいた",
    "exTranslation": "A customer gave me some snacks.",
    "category": "Verb"
  },
  {
    "term": "一斉に",
    "termReading": "いっせいに",
    "termTranslation": "all together, all at once",
    "ex": "彼らは一斉に拍手したね。",
    "exReading": "かれら は いっせいに はくしゅ した ね",
    "exTranslation": "They applauded all together.",
    "category": "Adverb"
  },
  {
    "term": "洗剤",
    "termReading": "せんざい",
    "termTranslation": "detergent, cleanser",
    "ex": "床に洗剤をこぼしてしまいました。",
    "exReading": "ゆか に せんざい を こぼして しまいました",
    "exTranslation": "I spilt detergent on the floor.",
    "category": "Noun"
  },
  {
    "term": "真剣",
    "termReading": "しんけん",
    "termTranslation": "serious, sincere",
    "ex": "彼は真剣に話を聞いていました。",
    "exReading": "かれ は しんけん に はなし を きいて いました",
    "exTranslation": "He listened attentively to the story.",
    "category": "Adjective"
  },
  {
    "term": "刑務所",
    "termReading": "けいむしょ",
    "termTranslation": "prison",
    "ex": "彼は二度と刑務所から出ることができないの。",
    "exReading": "かれ は にどと けいむしょ から でる こと が できない の",
    "exTranslation": "He can never be released from prison.",
    "category": "Noun"
  },
  {
    "term": "刑事",
    "termReading": "けいじ",
    "termTranslation": "detective",
    "ex": "刑事が現場を調べているわ。",
    "exReading": "けいじ が げんば を しらべて いる わ",
    "exTranslation": "The detective is investigating the crime scene.",
    "category": "Noun"
  },
  {
    "term": "強烈",
    "termReading": "きょうれつ",
    "termTranslation": "intense, severe",
    "ex": "彼女は強烈な個性の持ち主ですよ。",
    "exReading": "かのじょ は きょうれつ な こせい の もちぬし です よ",
    "exTranslation": "She has a very strong personality.",
    "category": "Adjective"
  },
  {
    "term": "ゴールデンウィーク",
    "termReading": "ゴールデンウィーク",
    "termTranslation": "golden week",
    "ex": "ゴールデンウィークに海外旅行をします。",
    "exReading": "ごーるでんうぃーく に かいがい りょこう を します",
    "exTranslation": "I will travel abroad during Golden Week.",
    "category": "Noun"
  },
  {
    "term": "獣",
    "termReading": "けもの",
    "termTranslation": "beast, brute",
    "ex": "彼は獣のような目をしていたな。",
    "exReading": "かれ は けもの の よう な め を して いた な",
    "exTranslation": "He had eyes like a beast.",
    "category": "Noun"
  },
  {
    "term": "駆ける",
    "termReading": "かける",
    "termTranslation": "gallop, run quickly",
    "ex": "子供たちは広場に駆けて行ったわよ。",
    "exReading": "こどもたち は ひろば に かけて いった わ よ",
    "exTranslation": "The children rushed into the square.",
    "category": "Verb"
  },
  {
    "term": "駆け足",
    "termReading": "かけあし",
    "termTranslation": "gallop, run",
    "ex": "駅まで駆け足で行ったよ。",
    "exReading": "えき まで かけあし で いった よ",
    "exTranslation": "I ran to the station.",
    "category": "Verbal Noun"
  },
  {
    "term": "丘",
    "termReading": "おか",
    "termTranslation": "hill, slope",
    "ex": "丘の上にホテルが建ちましたね。",
    "exReading": "おか の うえ に ほてる が たちました ね",
    "exTranslation": "A hotel was built on top of the hill.",
    "category": "Noun"
  },
  {
    "term": "官庁",
    "termReading": "かんちょう",
    "termTranslation": "government office",
    "ex": "その古い建物は官庁です。",
    "exReading": "その ふるい たてもの は かんちょう です",
    "exTranslation": "That old building is the government office.",
    "category": "Noun"
  },
  {
    "term": "県庁",
    "termReading": "けんちょう",
    "termTranslation": "prefectural office",
    "ex": "あの白い建物が県庁です。",
    "exReading": "あの しろい たてもの が けんちょう です",
    "exTranslation": "That white building is the prefectural government office.",
    "category": "Noun"
  },
  {
    "term": "擦る",
    "termReading": "こする",
    "termTranslation": "rub, strike",
    "ex": "冷えた手を擦って温めた。",
    "exReading": "ひえた て を こすって あたためた",
    "exTranslation": "I warmed my cold hands by rubbing them.",
    "category": "Verb"
  },
  {
    "term": "こたつ",
    "termReading": "こたつ",
    "termTranslation": "kotatsu, heated table with a coverlet",
    "ex": "寒いのでこたつを出しました。",
    "exReading": "さむい の で こたつ を だしました",
    "exTranslation": "We got out the kotatsu because it was cold.",
    "category": "Noun"
  },
  {
    "term": "汗",
    "termReading": "あせ",
    "termTranslation": "sweat",
    "ex": "彼は額に汗をかいていたの。",
    "exReading": "かれ は ひたい に あせ を かいていた の",
    "exTranslation": "He was sweating from his forehead.",
    "category": "Noun"
  },
  {
    "term": "軸",
    "termReading": "じく",
    "termTranslation": "axis",
    "ex": "この線を軸にして図形を回転してください。",
    "exReading": "この せん を じく に して ずけい を かいてん して ください",
    "exTranslation": "Please take this line as the axis and rotate the figure around it.",
    "category": "Noun"
  },
  {
    "term": "後輩",
    "termReading": "こうはい",
    "termTranslation": "one's junior",
    "ex": "彼は大学の後輩です。",
    "exReading": "かれ は だいがく の こうはい です",
    "exTranslation": "He's my junior in college.",
    "category": "Noun"
  },
  {
    "term": "香り",
    "termReading": "かおり",
    "termTranslation": "perfume, fragrance",
    "ex": "この花はいい香りがしますね。",
    "exReading": "この はな は いい かおり が します ね",
    "exTranslation": "This flower smells good.",
    "category": "Noun"
  },
  {
    "term": "香水",
    "termReading": "こうすい",
    "termTranslation": "perfume",
    "ex": "香水のいい香りがした。",
    "exReading": "こうすい の いい かおり が した 。",
    "exTranslation": "There was a nice scent of perfume.",
    "category": "Noun"
  },
  {
    "term": "合唱",
    "termReading": "がっしょう",
    "termTranslation": "chorus",
    "ex": "私たちは校歌を合唱したの。",
    "exReading": "わたしたち は こうか を がっしょう した の",
    "exTranslation": "We sang the school song in chorus.",
    "category": "Verbal Noun"
  },
  {
    "term": "結晶",
    "termReading": "けっしょう",
    "termTranslation": "crystallization",
    "ex": "雪の結晶にはいろいろな形があります。",
    "exReading": "ゆき の けっしょう に は いろいろ な かたち が あります",
    "exTranslation": "Snow crystals come in a variety of shapes.",
    "category": "Verbal Noun"
  },
  {
    "term": "ことわざ",
    "termReading": "ことわざ",
    "termTranslation": "proverb, common saying",
    "ex": "日本のことわざをいくつくらい知っていますか。",
    "exReading": "にほん の ことわざ を いくつ くらい しって います か",
    "exTranslation": "About how many Japanese proverbs do you know?",
    "category": "Noun"
  },
  {
    "term": "敬う",
    "termReading": "うやまう",
    "termTranslation": "respect, honor",
    "ex": "両親を敬うことは大切です。",
    "exReading": "りょうしん を うやまう こと は たいせつ です",
    "exTranslation": "It is important to respect your parents.",
    "category": "Verb"
  },
  {
    "term": "座敷",
    "termReading": "ざしき",
    "termTranslation": "drawing room, Japanese-style room",
    "ex": "明日はお座敷での宴会になります。",
    "exReading": "あした は おざしき で の えんかい に なります",
    "exTranslation": "Tomorrow's banquet will be in a tatami-floored room.",
    "category": "Noun"
  },
  {
    "term": "敷金",
    "termReading": "しききん",
    "termTranslation": "deposit, key money",
    "ex": "マンションの敷金を払いました。",
    "exReading": "まんしょん の しききん を はらいました",
    "exTranslation": "I paid the deposit on the apartment.",
    "category": "Noun"
  },
  {
    "term": "劣る",
    "termReading": "おとる",
    "termTranslation": "be inferior to, be worse than",
    "ex": "私は体力では誰にも劣りません。",
    "exReading": "わたし は たいりょく で は だれ に も おとりません",
    "exTranslation": "My physical strength is second to none.",
    "category": "Verb"
  },
  {
    "term": "勘定",
    "termReading": "かんじょう",
    "termTranslation": "check, tab",
    "ex": "お勘定をして下さい。",
    "exReading": "おかんじょう を して ください",
    "exTranslation": "Can I have the check, please?",
    "category": "Verbal Noun"
  },
  {
    "term": "勘",
    "termReading": "かん",
    "termTranslation": "sense, intuition",
    "ex": "女の勘を甘く見てはいけません。",
    "exReading": "おんな の かん を あまく みて は いけません",
    "exTranslation": "Don't underestimate a woman's intuition.",
    "category": "Noun"
  },
  {
    "term": "勘違い",
    "termReading": "かんちがい",
    "termTranslation": "misunderstanding",
    "ex": "待ち合わせは2時だと勘違いしていました。",
    "exReading": "まちあわせ は にじ だ と かんちがい して いました",
    "exTranslation": "I mistakenly thought the meeting was at two o'clock.",
    "category": "Verbal Noun"
  },
  {
    "term": "霧",
    "termReading": "きり",
    "termTranslation": "fog, mist",
    "ex": "霧の深い夜のことでした。",
    "exReading": "きり の ふかい よる の こと でした",
    "exTranslation": "It was a foggy night.",
    "category": "Noun"
  },
  {
    "term": "これから",
    "termReading": "これから",
    "termTranslation": "from now on, after this",
    "ex": "これからはもっと気を付けます。",
    "exReading": "これから は もっと き を つけます",
    "exTranslation": "I will be more careful from now on.",
    "category": "Noun"
  },
  {
    "term": "霜",
    "termReading": "しも",
    "termTranslation": "frost",
    "ex": "今朝は庭の草に霜が降りていたの。",
    "exReading": "けさ は にわ の くさ に しも が おりて いた の",
    "exTranslation": "There was frost on the grass in the garden this morning.",
    "category": "Noun"
  },
  {
    "term": "公衆",
    "termReading": "こうしゅう",
    "termTranslation": "the public",
    "ex": "彼は公衆電話を探したの。",
    "exReading": "かれ は こうしゅう でんわ を さがした の",
    "exTranslation": "He looked for a public phone.",
    "category": "Noun"
  },
  {
    "term": "衆議院",
    "termReading": "しゅうぎいん",
    "termTranslation": "House of Representatives, Lower House",
    "ex": "予算案が衆議院を通過したな。",
    "exReading": "よさんあん が しゅうぎいん を つうか した な",
    "exTranslation": "The budget proposal passed the House of Representatives.",
    "category": "Noun"
  },
  {
    "term": "観衆",
    "termReading": "かんしゅう",
    "termTranslation": "audience",
    "ex": "スタジアムは観衆で満員だったよ。",
    "exReading": "すたじあむ は かんしゅう で まんいん だった よ",
    "exTranslation": "The stadium was full of spectators.",
    "category": "Noun"
  },
  {
    "term": "群衆",
    "termReading": "ぐんしゅう",
    "termTranslation": "the masses, multitude",
    "ex": "彼はマイクで群衆に話しかけたんだ。",
    "exReading": "かれ は まいく で ぐんしゅう に はなしかけた ん だ",
    "exTranslation": "He spoke to the masses through a microphone.",
    "category": "Noun"
  },
  {
    "term": "暑中見舞い",
    "termReading": "しょちゅうみまい",
    "termTranslation": "summer greeting",
    "ex": "先生に暑中見舞いを出しました。",
    "exReading": "せんせい に しょちゅうみまい を だしました",
    "exTranslation": "I sent a summer greeting card to my teacher.",
    "category": "Noun"
  },
  {
    "term": "お仕舞い",
    "termReading": "おしまい",
    "termTranslation": "finished, over",
    "ex": "話はこれでお仕舞いです。",
    "exReading": "はなし は これ で おしまい です",
    "exTranslation": "This is the end of the talk.",
    "category": "Noun"
  },
  {
    "term": "さす",
    "termReading": "さす",
    "termTranslation": "pour",
    "ex": "自転車に油をさしたの。",
    "exReading": "じてんしゃ に あぶら を さした の 。",
    "exTranslation": "I applied oil to the bicycle.",
    "category": "Verb"
  },
  {
    "term": "金銭",
    "termReading": "きんせん",
    "termTranslation": "money, cash",
    "ex": "金銭のトラブルには関わりたくありません。",
    "exReading": "きんせん の とらぶる に は かかわりたく ありません",
    "exTranslation": "I don't want to get involved with money problems.",
    "category": "Noun"
  },
  {
    "term": "児童",
    "termReading": "じどう",
    "termTranslation": "child, juvenile",
    "ex": "ここは児童の通学路です。",
    "exReading": "ここ は じどう の つうがくろ です",
    "exTranslation": "This is a school route for children.",
    "category": "Noun"
  },
  {
    "term": "埋める",
    "termReading": "うめる",
    "termTranslation": "bury, fill up",
    "ex": "庭に穴を掘ってそれを埋めました。",
    "exReading": "にわ に あな を ほって それ を うめました",
    "exTranslation": "I dug a hole in the backyard and buried it in there.",
    "category": "Verb"
  },
  {
    "term": "埋める",
    "termReading": "うずめる",
    "termTranslation": "bury, fill in",
    "ex": "パレードと観衆が道を埋めていたよ。",
    "exReading": "ぱれーど と かんしゅう が みち を うずめて いた よ",
    "exTranslation": "The parade and the spectators filled the road.",
    "category": "Verb"
  },
  {
    "term": "墨",
    "termReading": "すみ",
    "termTranslation": "black ink",
    "ex": "服に墨がついちゃった。",
    "exReading": "ふく に すみ が ついちゃった",
    "exTranslation": "The black ink has stained the clothes.",
    "category": "Noun"
  },
  {
    "term": "講堂",
    "termReading": "こうどう",
    "termTranslation": "lecture hall, auditorium",
    "ex": "全員、講堂に集まってください。",
    "exReading": "ぜんいん こうどう に あつまって ください",
    "exTranslation": "Everyone, please come to the auditorium.",
    "category": "Noun"
  },
  {
    "term": "奨学金",
    "termReading": "しょうがくきん",
    "termTranslation": "scholarship, fellowship",
    "ex": "彼女は奨学金で大学に行きました。",
    "exReading": "かのじょ は しょうがくきん で だいがく に いきました",
    "exTranslation": "She went to university on a scholarship.",
    "category": "Noun"
  },
  {
    "term": "ＪＲ",
    "termReading": "じぇいあーる",
    "termTranslation": "JR, Japan Railways",
    "ex": "私は通勤にJRを使うんだ。",
    "exReading": "わたし は つうきん に じぇいあーる を つかう ん だ",
    "exTranslation": "I commute by JR.",
    "category": "Noun"
  },
  {
    "term": "狂う",
    "termReading": "くるう",
    "termTranslation": "become insane, get out of order",
    "ex": "この時計はすぐ狂うの。",
    "exReading": "この とけい は すぐ くるう の",
    "exTranslation": "This clock often gets out of whack.",
    "category": "Verb"
  },
  {
    "term": "猿",
    "termReading": "さる",
    "termTranslation": "monkey, ape",
    "ex": "山で猿の親子を見ました。",
    "exReading": "やま で さる の おやこ を みました",
    "exTranslation": "I saw a female monkey and her child in the mountains.",
    "category": "Noun"
  },
  {
    "term": "地獄",
    "termReading": "じごく",
    "termTranslation": "hell, inferno",
    "ex": "地震の後、街は地獄のようだったよ。",
    "exReading": "じしん の あと まち は じごく の よう だった よ",
    "exTranslation": "After the earthquake, the town looked like hell.",
    "category": "Noun"
  },
  {
    "term": "章",
    "termReading": "しょう",
    "termTranslation": "chapter",
    "ex": "この本の第6章が特に好きです。",
    "exReading": "この ほん の だいろくしょう が とくに すき です",
    "exTranslation": "I especially like Chapter 6 of this book.",
    "category": "Noun"
  },
  {
    "term": "頑丈",
    "termReading": "がんじょう",
    "termTranslation": "solid, strong",
    "ex": "錠を頑丈なものに替えました。",
    "exReading": "じょう を がんじょう な もの に かえました",
    "exTranslation": "The lock was substituted for a stronger one.",
    "category": "Adjectival Noun"
  },
  {
    "term": "親戚",
    "termReading": "しんせき",
    "termTranslation": "a relative",
    "ex": "祖父の家に親戚が集まったんだ。",
    "exReading": "そふ の いえ に しんせき が あつまった ん だ",
    "exTranslation": "Relatives gathered at my grandfather's house.",
    "category": "Noun"
  },
  {
    "term": "祈る",
    "termReading": "いのる",
    "termTranslation": "pray, wish for",
    "ex": "皆が人質の無事を祈っているわよ。",
    "exReading": "みんな が ひとじち の ぶじ を いのって いる わ よ",
    "exTranslation": "Everyone is praying for the hostage's safety.",
    "category": "Verb"
  },
  {
    "term": "しびれる",
    "termReading": "しびれる",
    "termTranslation": "become numb, get pins and needles",
    "ex": "足がしびれた。",
    "exReading": "あし が しびれた",
    "exTranslation": "My foot went to sleep.",
    "category": "Verb"
  },
  {
    "term": "祈り",
    "termReading": "いのり",
    "termTranslation": "prayer",
    "ex": "その日、国民は平和への祈りを捧げるの。",
    "exReading": "その ひ こくみん は へいわ へ の いのり を ささげる　の",
    "exTranslation": "The people pray for peace on that day.",
    "category": "Noun"
  },
  {
    "term": "襟",
    "termReading": "えり",
    "termTranslation": "collar, lapel",
    "ex": "襟の大きいコートを買いました。",
    "exReading": "えり の おおきい こーと を かいました",
    "exTranslation": "I bought a coat with a large collar.",
    "category": "Noun"
  },
  {
    "term": "先祖",
    "termReading": "せんぞ",
    "termTranslation": "forefather, ancestor",
    "ex": "神道では先祖を大切にします。",
    "exReading": "しんとう で は せんぞ を たいせつ に します",
    "exTranslation": "In Shintoism we revere our ancestors.",
    "category": "Noun"
  },
  {
    "term": "粗筋",
    "termReading": "あらすじ",
    "termTranslation": "outline, plot",
    "ex": "その映画はどんな粗筋ですか。",
    "exReading": "その えいが は どんな あらすじ です か",
    "exTranslation": "What's the storyline of the movie?",
    "category": "Noun"
  },
  {
    "term": "酢",
    "termReading": "す",
    "termTranslation": "vinegar",
    "ex": "お酢を入れすぎて酸っぱい。",
    "exReading": "お す を いれすぎて すっぱい",
    "exTranslation": "It's sour because I put in too much vinegar.",
    "category": "Noun"
  },
  {
    "term": "郊外",
    "termReading": "こうがい",
    "termTranslation": "suburbs, outskirts",
    "ex": "私は郊外に家を買いました。",
    "exReading": "わたし は こうがい に いえ を かいました",
    "exTranslation": "I bought a house in the suburbs.",
    "category": "Noun"
  },
  {
    "term": "近郊",
    "termReading": "きんこう",
    "termTranslation": "suburbs, outskirts",
    "ex": "彼らは東京近郊に住んでいる。",
    "exReading": "かれら は とうきょう きんこう に すんで いる",
    "exTranslation": "They live on the outskirts of Tokyo.",
    "category": "Noun"
  },
  {
    "term": "邪魔",
    "termReading": "じゃま",
    "termTranslation": "hindrance, obstruction",
    "ex": "邪魔です、どいてください。",
    "exReading": "じゃま です どいて ください",
    "exTranslation": "You're in the way, so please move.",
    "category": "Verbal Noun"
  },
  {
    "term": "じゃぶじゃぶ",
    "termReading": "じゃぶじゃぶ",
    "termTranslation": "vigorously",
    "ex": "彼は顔をじゃぶじゃぶ洗ったの。",
    "exReading": "かれ は かお を じゃぶじゃぶ あらった の",
    "exTranslation": "He washed his face vigorously.",
    "category": "Adverb"
  },
  {
    "term": "お年玉",
    "termReading": "おとしだま",
    "termTranslation": "New Year's gift",
    "ex": "甥と姪にお年玉をあげたの。",
    "exReading": "おい と めい に おとしだま を あげた の",
    "exTranslation": "I gave New Year's pocket money to my niece and nephew.",
    "category": "Noun"
  },
  {
    "term": "改善",
    "termReading": "かいぜん",
    "termTranslation": "improvement, amelioration",
    "ex": "彼は食生活を改善しました。",
    "exReading": "かれ は しょくせいかつ を かいぜん しました",
    "exTranslation": "He has improved his eating habits.",
    "category": "Verbal Noun"
  },
  {
    "term": "親善",
    "termReading": "しんぜん",
    "termTranslation": "goodwill, friendly relations",
    "ex": "これからも両国の親善を深めましょう。",
    "exReading": "これから も りょうこく の しんぜん を ふかめましょう",
    "exTranslation": "Let's continue promoting friendship between our two countries from now on.",
    "category": "Noun"
  },
  {
    "term": "網",
    "termReading": "あみ",
    "termTranslation": "net, netting",
    "ex": "少年は網でその蝶を捕まえた。",
    "exReading": "しょうねん は あみ で その ちょう を つかまえた",
    "exTranslation": "The boy captured the butterfly with the net.",
    "category": "Noun"
  },
  {
    "term": "縛る",
    "termReading": "しばる",
    "termTranslation": "bind, restrict",
    "ex": "古新聞をひもで縛ったよ。",
    "exReading": "ふるしんぶん を ひも で しばった よ 。",
    "exTranslation": "I tied up the old newspapers with string.",
    "category": "Verb"
  },
  {
    "term": "絞る",
    "termReading": "しぼる",
    "termTranslation": "wring out, squeeze",
    "ex": "布をもっと固く絞りなさい。",
    "exReading": "ぬの を もっと かたく しぼりなさい",
    "exTranslation": "Squeeze the cloth harder.",
    "category": "Verb"
  },
  {
    "term": "紺",
    "termReading": "こん",
    "termTranslation": "dark blue, navy blue",
    "ex": "日本の制服は紺が多いね。",
    "exReading": "にほん の せいふく は こん が おおい ね",
    "exTranslation": "Many school uniforms in Japan are navy blue.",
    "category": "Noun"
  },
  {
    "term": "じゃんじゃん",
    "termReading": "じゃんじゃん",
    "termTranslation": "many times, repeatedly",
    "ex": "電話がじゃんじゃん掛かってきたな。",
    "exReading": "でんわ が じゃんじゃん かかって きた な",
    "exTranslation": "The telephone rang loudly.",
    "category": "Adverb"
  },
  {
    "term": "紅葉",
    "termReading": "こうよう",
    "termTranslation": "autumn leaves, crimson foliage",
    "ex": "この山は紅葉がとても美しい。",
    "exReading": "この やま は こうよう が とても うつくしい",
    "exTranslation": "The autumn leaves on this mountain are very beautiful.",
    "category": "Verbal Noun"
  },
  {
    "term": "口紅",
    "termReading": "くちべに",
    "termTranslation": "lipstick, rouge",
    "ex": "赤い口紅を買いました。",
    "exReading": "あかい くちべに を かいました",
    "exTranslation": "I bought some red lipstick.",
    "category": "Noun"
  },
  {
    "term": "梅",
    "termReading": "うめ",
    "termTranslation": "plum blossom",
    "ex": "梅の花が咲きました。",
    "exReading": "うめ の はな が さきました",
    "exTranslation": "Plum blossoms bloomed.",
    "category": "Noun"
  },
  {
    "term": "梅干",
    "termReading": "うめぼし",
    "termTranslation": "pickled plum",
    "ex": "うちでは、朝食には必ず梅干が出ます。",
    "exReading": "うち で は ちょうしょく に は かならず うめぼし が でます",
    "exTranslation": "In my family, pickled plums are always served for breakfast.",
    "category": "Noun"
  },
  {
    "term": "巣",
    "termReading": "す",
    "termTranslation": "nest, den",
    "ex": "アリは土の中に巣を作ります。",
    "exReading": "あり は つち の なか に す を つくります",
    "exTranslation": "Ants build nests underground.",
    "category": "Noun"
  },
  {
    "term": "囲碁",
    "termReading": "いご",
    "termTranslation": "game of \"go\"",
    "ex": "彼の趣味は囲碁です。",
    "exReading": "かれ の しゅみ は いご です",
    "exTranslation": "His hobby is playing Go.",
    "category": "Noun"
  },
  {
    "term": "嘘つき",
    "termReading": "うそつき",
    "termTranslation": "liar",
    "ex": "嘘つきは泥棒の始まりよ。",
    "exReading": "うそつき は どろぼう の はじまり よ 。",
    "exTranslation": "Thieves begin with lying.",
    "category": "Noun"
  },
  {
    "term": "しわ",
    "termReading": "しわ",
    "termTranslation": "wrinkle",
    "ex": "スカートにしわがよっているよ。",
    "exReading": "すかーと に しわ が よって いる よ",
    "exTranslation": "Your skirt is rumpled.",
    "category": "Noun"
  },
  {
    "term": "考慮",
    "termReading": "こうりょ",
    "termTranslation": "consideration, deliberation",
    "ex": "あなたの事情を考慮して予定をたてました。",
    "exReading": "あなた の じじょう を こうりょ して よてい を たてました",
    "exTranslation": "We made the plan while taking your circumstances into consideration.",
    "category": "Verbal Noun"
  },
  {
    "term": "癖",
    "termReading": "くせ",
    "termTranslation": "habit, mannerism",
    "ex": "爪をかむ癖は直した方がいい。",
    "exReading": "つめ を かむ くせ は なおした ほう が いい",
    "exTranslation": "You should stop the habit of biting your nails.",
    "category": "Noun"
  },
  {
    "term": "下痢",
    "termReading": "げり",
    "termTranslation": "diarrhea",
    "ex": "古いお寿司を食べて下痢をしてしまったんだ。",
    "exReading": "ふるい おすし を たべて げり を して しまった ん だ",
    "exTranslation": "I got diarrhea from eating old sushi.",
    "category": "Verbal Noun"
  },
  {
    "term": "崖",
    "termReading": "がけ",
    "termTranslation": "cliff",
    "ex": "大雨で崖が崩れたんだ。",
    "exReading": "おおあめ で がけ が くずれた ん だ",
    "exTranslation": "The cliff collapsed because of the downpour.",
    "category": "Noun"
  },
  {
    "term": "嵐",
    "termReading": "あらし",
    "termTranslation": "storm",
    "ex": "嵐で庭の木が折れたよ。",
    "exReading": "あらし で にわ の き が おれた よ 。",
    "exTranslation": "The tree in the garden snapped in the storm.",
    "category": "Noun"
  },
  {
    "term": "海峡",
    "termReading": "かいきょう",
    "termTranslation": "straits, channel",
    "ex": "その海峡に橋が掛けられました。",
    "exReading": "その かいきょう に はし が かけられました",
    "exTranslation": "A bridge was built across that strait.",
    "category": "Noun"
  },
  {
    "term": "噂",
    "termReading": "うわさ",
    "termTranslation": "gossip, rumor",
    "ex": "その噂は本当ですか。",
    "exReading": "その うわさ は ほんとう です か",
    "exTranslation": "Is the rumor true?",
    "category": "Verbal Noun"
  },
  {
    "term": "田舎",
    "termReading": "いなか",
    "termTranslation": "country, countryside",
    "ex": "私は毎年夏に田舎に帰ります。",
    "exReading": "わたし は まいとし なつ に いなか に かえります",
    "exTranslation": "Every year I go back to my hometown in the summer.",
    "category": "Noun"
  },
  {
    "term": "ずるがしこい",
    "termReading": "ずるがしこい",
    "termTranslation": "sly, cunning",
    "ex": "あいつはずるがしこい顔をしているね。",
    "exReading": "あいつ は ずるがしこい かお を して いる ね",
    "exTranslation": "That person has a sly look on his face.",
    "category": "Adjective"
  },
  {
    "term": "校舎",
    "termReading": "こうしゃ",
    "termTranslation": "school building",
    "ex": "古い校舎の修理が必要です。",
    "exReading": "ふるい こうしゃ の しゅうり が ひつよう です",
    "exTranslation": "It is necessary to repair the old school building.",
    "category": "Noun"
  },
  {
    "term": "お嬢さん",
    "termReading": "おじょうさん",
    "termTranslation": "young lady, someone's daughter",
    "ex": "彼はお嬢さんと一緒でした。",
    "exReading": "かれ は おじょうさん と いっしょ でした",
    "exTranslation": "He was with his daughter.",
    "category": "Noun"
  },
  {
    "term": "娯楽",
    "termReading": "ごらく",
    "termTranslation": "amusement, pastime",
    "ex": "テレビは彼のいちばんの娯楽です。",
    "exReading": "てれび は かれ の いちばん の ごらく です",
    "exTranslation": "Watching TV is his favorite way of passing time.",
    "category": "Noun"
  },
  {
    "term": "生涯",
    "termReading": "しょうがい",
    "termTranslation": "for life, lifetime",
    "ex": "彼は80年の生涯を閉じました。",
    "exReading": "かれ は はちじゅうねん の しょうがい を とじました",
    "exTranslation": "His life of 80 years came to a close.",
    "category": "Noun"
  },
  {
    "term": "汽車",
    "termReading": "きしゃ",
    "termTranslation": "train",
    "ex": "汽車で街まで行った。",
    "exReading": "きしゃ で まち まで いった",
    "exTranslation": "I went to town by train.",
    "category": "Noun"
  },
  {
    "term": "賭ける",
    "termReading": "かける",
    "termTranslation": "bet, stake",
    "ex": "彼は新しい仕事に人生を賭けている。",
    "exReading": "かれ は あたらしい しごと に じんせい を かけて いる",
    "exTranslation": "He stakes his fortune on this new job.",
    "category": "Verb"
  },
  {
    "term": "蛍光灯",
    "termReading": "けいこうとう",
    "termTranslation": "fluorescent lamp",
    "ex": "古い蛍光灯を取り替えてください。",
    "exReading": "ふるい けいこうとう を とりかえて ください",
    "exTranslation": "Please change the old fluorescent light.",
    "category": "Noun"
  },
  {
    "term": "せいぜい",
    "termReading": "せいぜい",
    "termTranslation": "at most",
    "ex": "どんなに頑張っても、せいぜい3位くらいにしかなれないだろうな。",
    "exReading": "どんな に がんばって も せいぜい さんい くらい に しか なれない だろう な",
    "exTranslation": "No matter how hard I try, I can probably only get third place at best.",
    "category": "Adverb"
  },
  {
    "term": "車掌",
    "termReading": "しゃしょう",
    "termTranslation": "train conductor",
    "ex": "車掌さんが車内を回って来たよ。",
    "exReading": "しゃしょう さん が しゃない を まわって きた よ",
    "exTranslation": "The conductor went around the carriage.",
    "category": "Noun"
  },
  {
    "term": "芋",
    "termReading": "いも",
    "termTranslation": "potato, yam",
    "ex": "お芋の料理はお好きですか。",
    "exReading": "お いも の りょうり は お すき です か",
    "exTranslation": "Do you like potato dishes?",
    "category": "Noun"
  },
  {
    "term": "菊",
    "termReading": "きく",
    "termTranslation": "chrysanthemum",
    "ex": "菊の花を買ってきました。",
    "exReading": "きく の はな を かって きました",
    "exTranslation": "I bought some chrysanthemums.",
    "category": "Noun"
  },
  {
    "term": "軽蔑",
    "termReading": "けいべつ",
    "termTranslation": "contempt, disdain",
    "ex": "彼女は彼を軽蔑していたの。",
    "exReading": "かのじょ は かれ を けいべつ して いた の",
    "exTranslation": "She despised him.",
    "category": "Verbal Noun"
  },
  {
    "term": "揚げる",
    "termReading": "あげる",
    "termTranslation": "deep-fry",
    "ex": "彼女は夕食に天ぷらを揚げました。",
    "exReading": "かのじょ は ゆうしょく に てんぷら を あげました",
    "exTranslation": "She fried some tempura for dinner.",
    "category": "Verb"
  },
  {
    "term": "諦める",
    "termReading": "あきらめる",
    "termTranslation": "abandon, give up",
    "ex": "彼は留学の夢を諦めていないよ。",
    "exReading": "かれ は りゅうがく の ゆめ を あきらめて いない よ",
    "exTranslation": "He hasn't given up his dream of studying abroad.",
    "category": "Verb"
  },
  {
    "term": "演奏",
    "termReading": "えんそう",
    "termTranslation": "musical performance",
    "ex": "彼女のピアノの演奏は素晴らしいね。",
    "exReading": "かのじょ の ぴあの の えんそう は すばらしい ね",
    "exTranslation": "Her piano performance is wonderful.",
    "category": "Verbal Noun"
  },
  {
    "term": "ぜいたく",
    "termReading": "ぜいたく",
    "termTranslation": "luxury, extravagance",
    "ex": "彼女は一生ぜいたくに暮らしたんだ。",
    "exReading": "かのじょ は いっしょう ぜいたく に くらした ん だ",
    "exTranslation": "She lived out her entire life in luxury.",
    "category": "Verbal Noun"
  },
  {
    "term": "窮屈",
    "termReading": "きゅうくつ",
    "termTranslation": "tight, cramped",
    "ex": "この服は窮屈になったな。",
    "exReading": "この ふく は きゅうくつ に なった な",
    "exTranslation": "These clothes have become tight.",
    "category": "Adjective"
  },
  {
    "term": "貨幣",
    "termReading": "かへい",
    "termTranslation": "money, currency",
    "ex": "博物館で昔の貨幣を見ました。",
    "exReading": "はくぶつかん で むかし の かへい を みました",
    "exTranslation": "I saw some old money in the museum.",
    "category": "Noun"
  },
  {
    "term": "真珠",
    "termReading": "しんじゅ",
    "termTranslation": "pearl",
    "ex": "母に真珠のネックレスをもらいました。",
    "exReading": "はは に しんじゅ の ねっくれす を もらいました",
    "exTranslation": "I was given a pearl necklace by my mother.",
    "category": "Noun"
  },
  {
    "term": "故郷",
    "termReading": "こきょう",
    "termTranslation": "hometown, birthplace",
    "ex": "彼女は久しぶりに故郷に帰りました。",
    "exReading": "かのじょ は ひさしぶり に こきょう に かえりました",
    "exTranslation": "She returned to her hometown after a long time away.",
    "category": "Noun"
  },
  {
    "term": "花瓶",
    "termReading": "かびん",
    "termTranslation": "flower vase",
    "ex": "クリスタルガラスの花瓶を買いました。",
    "exReading": "くりすたるがらす の かびん を かいました",
    "exTranslation": "I bought a crystal glass vase.",
    "category": "Noun"
  },
  {
    "term": "一旦",
    "termReading": "いったん",
    "termTranslation": "once, for a while",
    "ex": "疲れたでしょう、一旦休みましょう。",
    "exReading": "つかれた でしょう いったん やすみましょう",
    "exTranslation": "You must be tired; let's take a rest for a while.",
    "category": "Adverb"
  },
  {
    "term": "乞食",
    "termReading": "こじき",
    "termTranslation": "beggar",
    "ex": "乞食が公園のベンチで寝ている。",
    "exReading": "こじき が こうえん の べんち で ねて いる",
    "exTranslation": "A beggar is sleeping on the park bench.",
    "category": "Noun"
  },
  {
    "term": "亀",
    "termReading": "かめ",
    "termTranslation": "turtle, tortoise",
    "ex": "この池には亀がいますね。",
    "exReading": "この いけ に は かめ が います ね",
    "exTranslation": "There is a turtle in this pond.",
    "category": "Noun"
  },
  {
    "term": "がくんと",
    "termReading": "がくんと",
    "termTranslation": "sharply",
    "ex": "今学期は成績ががくんと落ちてしまった。",
    "exReading": "こんがっき は せいせき が がくんと おちて しまった",
    "exTranslation": "My grades fell sharply this semester.",
    "category": "Adverb"
  },
  {
    "term": "羨ましい",
    "termReading": "うらやましい",
    "termTranslation": "envious, envy",
    "ex": "彼の才能は羨ましい程だね。",
    "exReading": "かれ の さいのう は うらやましい ほど だ ね",
    "exTranslation": "I almost envy his talent.",
    "category": "Adjective"
  },
  {
    "term": "漕ぐ",
    "termReading": "こぐ",
    "termTranslation": "row",
    "ex": "彼らは交代でボートを漕いだんだ。",
    "exReading": "かれら は こうたい で ぼーと を こいだ ん だ",
    "exTranslation": "They took turns rowing the boat.",
    "category": "Verb"
  },
  {
    "term": "稽古",
    "termReading": "けいこ",
    "termTranslation": "practice, learning",
    "ex": "相撲の稽古はとても厳しいよ。",
    "exReading": "すもう の けいこ は とても きびしい よ",
    "exTranslation": "Training for Sumo wrestling is very tough.",
    "category": "Verbal Noun"
  },
  {
    "term": "歌舞伎",
    "termReading": "かぶき",
    "termTranslation": "kabuki",
    "ex": "歌舞伎の芝居を見に行きました。",
    "exReading": "かぶき の しばい を み に いきました 。",
    "exTranslation": "I went to see a kabuki play.",
    "category": "Noun"
  },
  {
    "term": "囁く",
    "termReading": "ささやく",
    "termTranslation": "whisper",
    "ex": "「この会議は退屈だ」と同僚が私に囁いたの。",
    "exReading": "「 この かいぎ は たいくつ だ 」 と どうりょう が わたし に ささやいた の 。",
    "exTranslation": "My colleague whispered to me, \"this meeting is boring.\"",
    "category": "Verb"
  },
  {
    "term": "咳",
    "termReading": "せき",
    "termTranslation": "cough",
    "ex": "咳が止まらないので病院に行ってきたの。",
    "exReading": "せき が とまらない ので びょういん に いってきた の 。",
    "exTranslation": "I went to the hospital as my cough wouldn't stop.",
    "category": "Noun"
  },
  {
    "term": "噛み付く",
    "termReading": "かみつく",
    "termTranslation": "bite at",
    "ex": "犬が手に噛み付きました。",
    "exReading": "いぬ が て に かみつきました。",
    "exTranslation": "The dog bit my hand.",
    "category": "Verb"
  },
  {
    "term": "きらりと",
    "termReading": "きらりと",
    "termTranslation": "sparkle",
    "ex": "ダイヤモンドがきらりと光った。",
    "exReading": "だいやもんど が きらりと ひかった",
    "exTranslation": "The diamond gleamed.",
    "category": "Adverb"
  },
  {
    "term": "屑",
    "termReading": "くず",
    "termTranslation": "waste, scraps",
    "ex": "彼の背広に糸屑がついているわ。",
    "exReading": "かれ の せびろ に いとくず が ついて いる わ",
    "exTranslation": "He has fluff on his suit.",
    "category": "Noun"
  },
  {
    "term": "紙屑",
    "termReading": "かみくず",
    "termTranslation": "wastepaper",
    "ex": "紙屑は屑入れに入れなさい。",
    "exReading": "かみくず は くず いれ に いれなさい 。",
    "exTranslation": "Put wastepaper in the garbage bin.",
    "category": "Noun"
  },
  {
    "term": "掻く",
    "termReading": "かく",
    "termTranslation": "scratch",
    "ex": "背中をお母さんに掻いてもらったの。",
    "exReading": "せなか を おかあさん に かいて もらった の",
    "exTranslation": "I had my mother scratch my back.",
    "category": "Verb"
  },
  {
    "term": "掻き回す",
    "termReading": "かきまわす",
    "termTranslation": "stir up, agitate",
    "ex": "母は鍋のシチューを掻き回しているよ。",
    "exReading": "はは は なべ の しちゅー を かきまわしている よ 。",
    "exTranslation": "Mother is mixing the stew in the pan.",
    "category": "Verb"
  },
  {
    "term": "憧れ",
    "termReading": "あこがれ",
    "termTranslation": "yearning, longing",
    "ex": "海外に住むのは私の憧れです。",
    "exReading": "かいがい に すむ の は わたし の あこがれ です",
    "exTranslation": "I long to live abroad.",
    "category": "Noun"
  },
  {
    "term": "憧れる",
    "termReading": "あこがれる",
    "termTranslation": "yearn after, adore",
    "ex": "彼はパイロットの職に憧れているんだ。",
    "exReading": "かれ は ぱいろっと の しょく に あこがれて いる ん だ",
    "exTranslation": "His ambition is to become a pilot.",
    "category": "Verb"
  },
  {
    "term": "溺れる",
    "termReading": "おぼれる",
    "termTranslation": "drown, be drowned",
    "ex": "彼は溺れている子を助けたんだ。",
    "exReading": "かれ は おぼれて いる こ を たすけた ん だ",
    "exTranslation": "He saved the drowning child.",
    "category": "Verb"
  },
  {
    "term": "ごくんと",
    "termReading": "ごくんと",
    "termTranslation": "in one gulp",
    "ex": "薬をごくんと飲み込んだんだ。",
    "exReading": "くすり を ごくんと のみこんだ ん だ",
    "exTranslation": "I swallowed the medicine in one gulp.",
    "category": "Adverb"
  },
  {
    "term": "御無沙汰",
    "termReading": "ごぶさた",
    "termTranslation": "be out of touch",
    "ex": "長いこと御無沙汰いたしました。",
    "exReading": "ながい こと ごぶさた いたしました",
    "exTranslation": "I have been out of touch with you for a long time.",
    "category": "Verbal Noun"
  },
  {
    "term": "汲む",
    "termReading": "くむ",
    "termTranslation": "collect, scoop up",
    "ex": "小さなバケツで水を汲んだの。",
    "exReading": "ちいさ な ばけつ で みず を くんだ の",
    "exTranslation": "I scooped up some water with a small bucket.",
    "category": "Verb"
  },
  {
    "term": "苛め",
    "termReading": "いじめ",
    "termTranslation": "bullying",
    "ex": "学校での苛めはなかなか減らない。",
    "exReading": "がっこう で の いじめ は なかなか へらない 。",
    "exTranslation": "Bullying at school is hard to decrease.",
    "category": "Noun"
  },
  {
    "term": "苛める",
    "termReading": "いじめる",
    "termTranslation": "ill-treat, abuse",
    "ex": "動物を苛めてはいけません。",
    "exReading": "どうぶつ を いじめて は いけません",
    "exTranslation": "Don't abuse animals.",
    "category": "Verb"
  },
  {
    "term": "曖昧",
    "termReading": "あいまい",
    "termTranslation": "ambiguous, vague",
    "ex": "彼女は曖昧な返事をしたね。",
    "exReading": "かのじょ は あいまい な へんじ を した ね",
    "exTranslation": "She gave a vague answer.",
    "category": "Adjective"
  },
  {
    "term": "大晦日",
    "termReading": "おおみそか",
    "termTranslation": "new year's eve",
    "ex": "日本では、大晦日にそばを食べます。",
    "exReading": "にっぽん で は おおみそか に そば を たべます",
    "exTranslation": "In Japan we eat buckwheat noodles on New Year's Eve.",
    "category": "Noun"
  },
  {
    "term": "臆病",
    "termReading": "おくびょう",
    "termTranslation": "cowardice, timidity",
    "ex": "弟は小さい頃は臆病だったんだ。",
    "exReading": "おとうと は ちいさい ころ は おくびょう だった ん だ",
    "exTranslation": "My younger brother was cowardly when he was little.",
    "category": "Adjectival Noun"
  },
  {
    "term": "車椅子",
    "termReading": "くるまいす",
    "termTranslation": "wheelchair",
    "ex": "彼女は車椅子に乗っています。",
    "exReading": "かのじょ は くるまいす に のっています 。",
    "exTranslation": "She uses a wheelchair.",
    "category": "Noun"
  },
  {
    "term": "柿",
    "termReading": "かき",
    "termTranslation": "persimmon, persimmon tree",
    "ex": "庭に柿の実がなりました。",
    "exReading": "にわ に かき の み が なりました",
    "exTranslation": "The persimmon in the garden has born fruit.",
    "category": "Noun"
  },
  {
    "term": "お詫び",
    "termReading": "おわび",
    "termTranslation": "apologize",
    "ex": "誤解があったことをお詫びします。",
    "exReading": "ごかい が あった こと を おわび します",
    "exTranslation": "I apologize for the misunderstanding.",
    "category": "Verbal Noun"
  },
  {
    "term": "釘",
    "termReading": "くぎ",
    "termTranslation": "nail, spike",
    "ex": "釘を踏んで怪我をしました。",
    "exReading": "くぎ を ふんで けが を しました",
    "exTranslation": "I injured myself stepping on a nail.",
    "category": "Noun"
  },
  {
    "term": "錆びる",
    "termReading": "さびる",
    "termTranslation": "rust, get rusty",
    "ex": "包丁が錆びてしまった。",
    "exReading": "ほうちょう が さびて しまった",
    "exTranslation": "The kitchen knife has rusted.",
    "category": "Verb"
  },
  {
    "term": "爽やか",
    "termReading": "さわやか",
    "termTranslation": "refreshing, clear",
    "ex": "レモンの爽やかな香りがした。",
    "exReading": "れもん の さわやか な かおり が した",
    "exTranslation": "There was the fresh smell of lemon.",
    "category": "Adjective"
  },
  {
    "term": "雀",
    "termReading": "すずめ",
    "termTranslation": "sparrow",
    "ex": "朝は雀の声で目が覚めます。",
    "exReading": "あさ は すずめ の こえ で め が さめます",
    "exTranslation": "I wake up to the sound of sparrows in the morning.",
    "category": "Noun"
  },
  {
    "term": "甥",
    "termReading": "おい",
    "termTranslation": "nephew",
    "ex": "私の甥は3才です。",
    "exReading": "わたし の おい は さんさい です",
    "exTranslation": "My nephew is 3 years old.",
    "category": "Noun"
  },
  {
    "term": "嗅ぐ",
    "termReading": "かぐ",
    "termTranslation": "smell, sniff",
    "ex": "犬がお皿の匂いをクンクン嗅いでいるね。",
    "exReading": "いぬ が おさら の におい を くんくん かいで いる ね",
    "exTranslation": "The dog sniffed at the smell on the plate.",
    "category": "Verb"
  },
  {
    "term": "炒める",
    "termReading": "いためる",
    "termTranslation": "stir-fry",
    "ex": "次に、野菜を炒めてください。",
    "exReading": "つぎ に やさい を いためて ください",
    "exTranslation": "Next, stir-fry the vegetables.",
    "category": "Verb"
  },
  {
    "term": "一まず",
    "termReading": "ひとまず",
    "termTranslation": "first of all",
    "ex": "一まず休憩しましょう。",
    "exReading": "ひとまず きゅうけい しましょう",
    "exTranslation": "Let's take a rest first.",
    "category": "Adverb"
  },
  {
    "term": "万一",
    "termReading": "まんいち",
    "termTranslation": "if by any chance",
    "ex": "万一のために保険に入ったの。",
    "exReading": "まんいち の ため に ほけん に はいった の",
    "exTranslation": "I bought insurance for emergencies.",
    "category": "Adverb"
  },
  {
    "term": "日ごろ",
    "termReading": "ひごろ",
    "termTranslation": "routinely, daily",
    "ex": "彼女には日ごろからお世話になっています。",
    "exReading": "かのじょ に は ひごろ から おせわ に なって います",
    "exTranslation": "I'm indebted to her since she helps me on a daily basis.",
    "category": "Adverb"
  },
  {
    "term": "日ソ",
    "termReading": "にっそ",
    "termTranslation": "Japanese-Soviet",
    "ex": "当時、日ソ会談が開かれた。",
    "exReading": "とうじ にっそ かいだん が ひらかれた",
    "exTranslation": "At the time, talks were held between Japan and the Soviet Union.",
    "category": "Noun"
  },
  {
    "term": "日",
    "termReading": "にち",
    "termTranslation": "Japan",
    "ex": "日仏の共同研究が始まったな。",
    "exReading": "にちふつ の きょうどう けんきゅう が はじまった な",
    "exTranslation": "Joint research has begun between Japan and France.",
    "category": "Noun"
  },
  {
    "term": "データ",
    "termReading": "データ",
    "termTranslation": "data",
    "ex": "去年のデータを見せてください。",
    "exReading": "きょねん の でーた を みせて ください 。",
    "exTranslation": "Please show me last year's data.",
    "category": "Noun"
  },
  {
    "term": "日時",
    "termReading": "にちじ",
    "termTranslation": "date, time",
    "ex": "試写会の日時を教えてください。",
    "exReading": "ししゃかい の にちじ を おしえて ください",
    "exTranslation": "Please tell me the date of the sneak preview.",
    "category": "Noun"
  },
  {
    "term": "日日",
    "termReading": "ひにち",
    "termTranslation": "date, number of days",
    "ex": "ミーティングの日日を間違えました。",
    "exReading": "みーてぃんぐ の ひにち を まちがえました",
    "exTranslation": "I mistook the day of the meeting.",
    "category": "Noun"
  },
  {
    "term": "日々",
    "termReading": "ひび",
    "termTranslation": "daily, every day",
    "ex": "日々の努力が大切です。",
    "exReading": "ひび の どりょく が たいせつ です",
    "exTranslation": "It's important to make efforts every day.",
    "category": "Noun"
  },
  {
    "term": "三日月",
    "termReading": "みかづき",
    "termTranslation": "new moon, crescent moon",
    "ex": "空に三日月が見えました。",
    "exReading": "そら に みかづき が みえました",
    "exTranslation": "I was able to see a crescent moon in the sky.",
    "category": "Noun"
  },
  {
    "term": "月日",
    "termReading": "つきひ",
    "termTranslation": "time, days",
    "ex": "月日が経つのは早いものです。",
    "exReading": "つきひ が たつ の は はやい もの です",
    "exTranslation": "Time passes by quickly.",
    "category": "Noun"
  },
  {
    "term": "ただ",
    "termReading": "ただ",
    "termTranslation": "simply, merely",
    "ex": "私はただ彼女と話したかっただけです。",
    "exReading": "わたし は ただ かのじょ と はなしたかった だけ です",
    "exTranslation": "I just wanted to speak with her.",
    "category": "Adverb"
  },
  {
    "term": "土",
    "termReading": "つち",
    "termTranslation": "soil, ground",
    "ex": "土を掘って木を植えました。",
    "exReading": "つち を ほって き を うえました",
    "exTranslation": "I dug up the soil and planted a tree.",
    "category": "Noun"
  },
  {
    "term": "年月",
    "termReading": "としつき",
    "termTranslation": "time, years",
    "ex": "あれから長い年月が経ちました。",
    "exReading": "あれ から ながい としつき が たちました",
    "exTranslation": "A long time has passed since then.",
    "category": "Noun"
  },
  {
    "term": "年月日",
    "termReading": "ねんがっぴ",
    "termTranslation": "date",
    "ex": "申請の年月日を西暦で書いてください。",
    "exReading": "しんせい の ねんがっぴ を せいれき で かいて ください",
    "exTranslation": "Please write down the application date in the Western calendar.",
    "category": "Noun"
  },
  {
    "term": "年月",
    "termReading": "ねんげつ",
    "termTranslation": "years, time",
    "ex": "そのお寺は長い年月をかけて建てられた。",
    "exReading": "その おてら は ながい ねんげつ を かけて たてられた",
    "exTranslation": "The temple was built over the course of many years.",
    "category": "Noun"
  },
  {
    "term": "年々",
    "termReading": "ねんねん",
    "termTranslation": "year by year, annually",
    "ex": "東京の人口は年々増えています。",
    "exReading": "とうきょう の じんこう は ねんねん ふえて います",
    "exTranslation": "The population of Tokyo increases year by year.",
    "category": "Adverb"
  },
  {
    "term": "もっとも",
    "termReading": "もっとも",
    "termTranslation": "quite right",
    "ex": "彼の意見はもっともです。",
    "exReading": "かれ の いけん は もっとも です",
    "exTranslation": "His opinion is quite right.",
    "category": "Adjective"
  },
  {
    "term": "何か",
    "termReading": "なにか",
    "termTranslation": "something, some",
    "ex": "何か質問はありますか。",
    "exReading": "なにか しつもん は あります か",
    "exTranslation": "Are there any questions?",
    "category": "Adverb"
  },
  {
    "term": "何とか",
    "termReading": "なんとか",
    "termTranslation": "somehow or other, one way or another",
    "ex": "何とかお願いします。",
    "exReading": "なんとか おねがい します",
    "exTranslation": "I beg you to take care of it.",
    "category": "Adverb"
  },
  {
    "term": "何より",
    "termReading": "なにより",
    "termTranslation": "above all, before everything else",
    "ex": "ご無事で何よりです。",
    "exReading": "ごぶじ で なにより です",
    "exTranslation": "I'm glad to know you're safe.",
    "category": "Noun"
  },
  {
    "term": "何と",
    "termReading": "なんと",
    "termTranslation": "what, how",
    "ex": "何と彼らは結婚したそうです。",
    "exReading": "なんと かれら は けっこん した そう です",
    "exTranslation": "I heard that they got married.",
    "category": "Adverb"
  },
  {
    "term": "何となく",
    "termReading": "なんとなく",
    "termTranslation": "in some way, somehow",
    "ex": "パーティーに行くのは何となく気が進まないな。",
    "exReading": "ぱーてぃー に いく の は なんとなく き が すすまない な",
    "exTranslation": "Somehow I don't feel like going to the party.",
    "category": "Adverb"
  },
  {
    "term": "何だか",
    "termReading": "なんだか",
    "termTranslation": "somehow, what's what",
    "ex": "茶柱が立って何だか少し幸せな気分です。",
    "exReading": "ちゃばしら が たって なんだか すこし しあわせ な きぶん です",
    "exTranslation": "I saw tea leaves floating upright, so now I feel happy somehow.",
    "category": "Adverb"
  },
  {
    "term": "メーカー",
    "termReading": "メーカー",
    "termTranslation": "maker, manufacturer",
    "ex": "彼は靴のメーカーで働いています。",
    "exReading": "かれ は くつ の めーかー で はたらいて います",
    "exTranslation": "He works for a shoe manufacturer.",
    "category": "Noun"
  },
  {
    "term": "何で",
    "termReading": "なんで",
    "termTranslation": "why, what for",
    "ex": "彼女は何で来ないんだろう。",
    "exReading": "かのじょ は なんで こないん だろう",
    "exTranslation": "I wonder why she hasn't come.",
    "category": "Adverb"
  },
  {
    "term": "何十",
    "termReading": "なんじゅう",
    "termTranslation": "several tens",
    "ex": "その店に何十人も行列していたよ。",
    "exReading": "その みせ に なんじゅうにん も ぎょうれつ して いた よ",
    "exTranslation": "Tens of people formed a line to the shop.",
    "category": "Noun"
  },
  {
    "term": "何て",
    "termReading": "なんて",
    "termTranslation": "what, how",
    "ex": "何てきれいな人なんだ。",
    "exReading": "なんて きれい な ひと なんだ",
    "exTranslation": "What a beautiful person she is!",
    "category": "Adverb"
  },
  {
    "term": "何しろ",
    "termReading": "なにしろ",
    "termTranslation": "anyhow, as you know",
    "ex": "彼は何しろよくしゃべります。",
    "exReading": "かれ は なにしろ よく しゃべります",
    "exTranslation": "Anyway, he talks a lot.",
    "category": "Adverb"
  },
  {
    "term": "何千",
    "termReading": "なんぜん",
    "termTranslation": "several thousand, thousands",
    "ex": "新聞社に何千ものメールが寄せられたよ。",
    "exReading": "しんぶんしゃ に なんぜん も の めーる が よせられた よ",
    "exTranslation": "The newspaper company received thousands of e-mails.",
    "category": "Noun"
  },
  {
    "term": "レベル",
    "termReading": "レベル",
    "termTranslation": "level",
    "ex": "自分のレベルに合った授業を選んでください。",
    "exReading": "じぶん の れべる に あった じゅぎょう を えらんで ください",
    "exTranslation": "Please choose a class suitable to your level.",
    "category": "Noun"
  },
  {
    "term": "何百",
    "termReading": "なんびゃく",
    "termTranslation": "several hundred, hundreds",
    "ex": "海で何百もの美しい魚を見ました。",
    "exReading": "うみ で なんびゃく も の うつくしい さかな を みました",
    "exTranslation": "I saw hundreds of beautiful fish in the sea.",
    "category": "Noun"
  },
  {
    "term": "やって来る",
    "termReading": "やってくる",
    "termTranslation": "come, turn up",
    "ex": "フランスからサーカスがやって来ます。",
    "exReading": "ふらんす から さーかす が やってきます",
    "exTranslation": "A circus is coming from France.",
    "category": "Verb"
  },
  {
    "term": "来日",
    "termReading": "らいにち",
    "termTranslation": "coming to Japan",
    "ex": "有名なバンドが来日していますね。",
    "exReading": "ゆうめい な ばんど が らいにち して います ね",
    "exTranslation": "A famous band has come to Japan.",
    "category": "Verbal Noun"
  },
  {
    "term": "日帰り",
    "termReading": "ひがえり",
    "termTranslation": "a day trip",
    "ex": "私たちは日帰りで京都に行きました。",
    "exReading": "わたしたち は ひがえり で きょうと に いきました",
    "exTranslation": "We went to Kyoto on a day trip.",
    "category": "Verbal Noun"
  },
  {
    "term": "大して",
    "termReading": "たいして",
    "termTranslation": "very, greatly",
    "ex": "彼は大して嬉しそうには見えなかったよね。",
    "exReading": "かれ は たいして うれし そう に は みえなかった よ ね",
    "exTranslation": "He didn't look particularly happy.",
    "category": "Adverb"
  },
  {
    "term": "むしろ",
    "termReading": "むしろ",
    "termTranslation": "rather",
    "ex": "彼は建築家というよりむしろ芸術家ね。",
    "exReading": "かれ は けんちくか と いう より むしろ げいじゅつか ね",
    "exTranslation": "He's an artist rather than an architect.",
    "category": "Adverb"
  },
  {
    "term": "大金",
    "termReading": "たいきん",
    "termTranslation": "large sum of money",
    "ex": "このかばんには大金が入っています。",
    "exReading": "この かばん に は たいきん が はいって います",
    "exTranslation": "This bag contains a lot of money.",
    "category": "Noun"
  },
  {
    "term": "大",
    "termReading": "だい",
    "termTranslation": "bigness, large size",
    "ex": "チーズケーキの大を一つ下さい。",
    "exReading": "ちーずけーき の だい を ひとつ ください",
    "exTranslation": "Please give me a slice of the large size cheesecake.",
    "category": "Noun"
  },
  {
    "term": "日中",
    "termReading": "にっちゅう",
    "termTranslation": "during the day",
    "ex": "日中はずっと海で泳いでいました。",
    "exReading": "にっちゅう は ずっと うみ で およいで いました",
    "exTranslation": "I swam in the sea all day long.",
    "category": "Noun"
  },
  {
    "term": "日中",
    "termReading": "にっちゅう",
    "termTranslation": "Sino-Japanese",
    "ex": "日中貿易は急激に伸びているわね。",
    "exReading": "にっちゅう ぼうえき は きゅうげき に のびて いる わ ね",
    "exTranslation": "Trade has expanded rapidly between Japan and China.",
    "category": "Noun"
  },
  {
    "term": "中年",
    "termReading": "ちゅうねん",
    "termTranslation": "middle age",
    "ex": "これは、中年の男性によく見られる症状です。",
    "exReading": "これ は ちゅうねん の だんせい に よく みられる しょうじょう です",
    "exTranslation": "These symptoms are often seen in middle-aged men.",
    "category": "Noun"
  },
  {
    "term": "中",
    "termReading": "ちゅう",
    "termTranslation": "middle, medium size",
    "ex": "うちの子の身長はクラスで中くらいです。",
    "exReading": "うち の こ の しんちょう は くらす で ちゅう くらい です",
    "exTranslation": "My child is of average height in class.",
    "category": "Noun"
  },
  {
    "term": "ますます",
    "termReading": "ますます",
    "termTranslation": "increasingly",
    "ex": "状況はますます悪くなったね。",
    "exReading": "じょうきょう は ますます わるく なった ね",
    "exTranslation": "The situation has gotten worse and worse.",
    "category": "Adverb"
  },
  {
    "term": "年中",
    "termReading": "ねんじゅう",
    "termTranslation": "all year",
    "ex": "叔母は年中旅行しています。",
    "exReading": "おば は ねんじゅう りょこう して います",
    "exTranslation": "My aunt travels all year round.",
    "category": "Adverb"
  },
  {
    "term": "大小",
    "termReading": "だいしょう",
    "termTranslation": "size",
    "ex": "応募作品の大小は問いません。",
    "exReading": "おうぼ さくひん の だいしょう は といません",
    "exTranslation": "Submitted works of all sizes accepted.",
    "category": "Noun"
  },
  {
    "term": "多少",
    "termReading": "たしょう",
    "termTranslation": "a little, somewhat",
    "ex": "このソフトには多少問題がある。",
    "exReading": "この そふと に は たしょう もんだい が ある",
    "exTranslation": "There are some problems with this software.",
    "category": "Adverb"
  },
  {
    "term": "ぶら下がる",
    "termReading": "ぶらさがる",
    "termTranslation": "hang down, dangle",
    "ex": "猿が木にぶら下がってるな。",
    "exReading": "さる が き に ぶらさがって る な",
    "exTranslation": "The monkey is hanging in the tree.",
    "category": "Verb"
  },
  {
    "term": "ぶら下げる",
    "termReading": "ぶらさげる",
    "termTranslation": "hang, suspend",
    "ex": "彼は首にカメラをぶら下げているね。",
    "exReading": "かれ は くび に かめら を ぶらさげて いる ね",
    "exTranslation": "He has a camera around his neck.",
    "category": "Verb"
  },
  {
    "term": "モデル",
    "termReading": "モデル",
    "termTranslation": "model",
    "ex": "彼女はこの小説の主人公のモデルです。",
    "exReading": "かのじょ は この しょうせつ の しゅじんこう の もでる です",
    "exTranslation": "She's the model for the heroine of this novel.",
    "category": "Noun"
  },
  {
    "term": "下",
    "termReading": "もと",
    "termTranslation": "under the supervision",
    "ex": "私はその教授の下で論文を書いたの。",
    "exReading": "わたし は その きょうじゅ の もと で ろんぶん を かいた の",
    "exTranslation": "I wrote my thesis under that professor's supervision.",
    "category": "Noun"
  },
  {
    "term": "やり方",
    "termReading": "やりかた",
    "termTranslation": "way of doing",
    "ex": "仕事のやり方を教えてください。",
    "exReading": "しごと の やりかた を おしえて ください",
    "exTranslation": "Please teach me how to do the job.",
    "category": "Noun"
  },
  {
    "term": "方々",
    "termReading": "ほうぼう",
    "termTranslation": "everyone, all people",
    "ex": "彼の連絡先を方々に問い合わせたんだ。",
    "exReading": "かれ の れんらくさき を ほうぼう に といあわせた ん だ",
    "exTranslation": "I inquired everywhere for his contact information.",
    "category": "Noun"
  },
  {
    "term": "行方",
    "termReading": "ゆくえ",
    "termTranslation": "one's whereabouts",
    "ex": "彼の行方が分かりません。",
    "exReading": "かれ の ゆくえ が わかりません",
    "exTranslation": "I don't know his whereabouts.",
    "category": "Noun"
  },
  {
    "term": "一人一人",
    "termReading": "ひとりひとり",
    "termTranslation": "each and every person",
    "ex": "この学校では一人一人の生徒を大切にしているの。",
    "exReading": "この がっこう で は ひとりひとり の せいと を たいせつ に して いる の",
    "exTranslation": "Each individual student is valued in this school.",
    "category": "Noun"
  },
  {
    "term": "もたらす",
    "termReading": "もたらす",
    "termTranslation": "bring about, lead to",
    "ex": "彼はこの国に平和をもたらしたのよ。",
    "exReading": "かれ は この くに に へいわ を もたらした の よ",
    "exTranslation": "He brought peace to this country.",
    "category": "Verb"
  },
  {
    "term": "日の入り",
    "termReading": "ひのいり",
    "termTranslation": "sunset",
    "ex": "今日の日の入りは午後6時でした。",
    "exReading": "きょう の ひのいり は ごご 6 じ でした",
    "exTranslation": "Today, the sun set at 6 p.m.",
    "category": "Noun"
  },
  {
    "term": "出入り",
    "termReading": "でいり",
    "termTranslation": "coming and going",
    "ex": "彼の家は人の出入りが多いね。",
    "exReading": "かれ の いえ は ひと の でいり が おおい ね",
    "exTranslation": "There are a lot of people going in and out his house.",
    "category": "Verbal Noun"
  },
  {
    "term": "出来上がる",
    "termReading": "できあがる",
    "termTranslation": "be completed, be finished",
    "ex": "この家は来年出来上がります。",
    "exReading": "この いえ は らいねん できあがります",
    "exTranslation": "This house will be completed next year.",
    "category": "Verb"
  },
  {
    "term": "人出",
    "termReading": "ひとで",
    "termTranslation": "turnout, crowd",
    "ex": "今日も遊園地は大変な人出だったよ。",
    "exReading": "きょう も ゆうえんち は たいへん な ひとで だった よ",
    "exTranslation": "The amusement park was very crowded too today.",
    "category": "Noun"
  },
  {
    "term": "日の出",
    "termReading": "ひので",
    "termTranslation": "sunrise",
    "ex": "日の出がとてもきれいですね。",
    "exReading": "ひので が とても きれい です ね",
    "exTranslation": "The sunrise is very beautiful.",
    "category": "Noun"
  },
  {
    "term": "それぞれ",
    "termReading": "それぞれ",
    "termTranslation": "each, respective",
    "ex": "人はそれぞれ考え方が違います。",
    "exReading": "ひと は それぞれ かんがえかた が ちがいます",
    "exTranslation": "People all have different ideas.",
    "category": "Adverb"
  },
  {
    "term": "出来上がり",
    "termReading": "できあがり",
    "termTranslation": "completion, finish",
    "ex": "私は作品の出来上がりに満足しています。",
    "exReading": "わたし は さくひん の できあがり に まんぞく して います",
    "exTranslation": "I'm satisfied with the completed work.",
    "category": "Noun"
  },
  {
    "term": "出来るだけ",
    "termReading": "できるだけ",
    "termTranslation": "as much as possible",
    "ex": "出来るだけ早く来てください。",
    "exReading": "できるだけ はやく きて ください",
    "exTranslation": "Please come as soon as possible.",
    "category": "Adverb"
  },
  {
    "term": "外す",
    "termReading": "はずす",
    "termTranslation": "remove",
    "ex": "彼はメガネを外しました。",
    "exReading": "かれ は めがね を はずしました",
    "exTranslation": "He removed his glasses.",
    "category": "Verb"
  },
  {
    "term": "外れる",
    "termReading": "はずれる",
    "termTranslation": "come off",
    "ex": "びんのふたが外れません。",
    "exReading": "びん の ふた が はずれません",
    "exTranslation": "The jar's lid won't come off.",
    "category": "Verb"
  },
  {
    "term": "外れ",
    "termReading": "はずれ",
    "termTranslation": "blank",
    "ex": "このくじは外れです。",
    "exReading": "この くじ は はずれ です",
    "exTranslation": "This is a losing ticket.",
    "category": "Noun"
  },
  {
    "term": "その内",
    "termReading": "そのうち",
    "termTranslation": "before long, sooner or later",
    "ex": "彼女もその内、親の気持ちが分かるだろう。",
    "exReading": "かのじょ も そのうち おや の きもち が わかる だろう",
    "exTranslation": "Sooner or later, she'll come to understand her parents' feelings.",
    "category": "Adverb"
  },
  {
    "term": "トップ",
    "termReading": "トップ",
    "termTranslation": "top",
    "ex": "彼はトップの成績で合格しました。",
    "exReading": "かれ は とっぷ の せいせき で ごうかく しました",
    "exTranslation": "He passed with the best score.",
    "category": "Noun"
  },
  {
    "term": "本来",
    "termReading": "ほんらい",
    "termTranslation": "originally, essentially",
    "ex": "彼女はプレッシャーから解放されて本来の自分に戻ったな。",
    "exReading": "かのじょ は ぷれっしゃー から かいほう されて ほんらい の じぶん に もどった な",
    "exTranslation": "She was relieved of the pressure and became herself again.",
    "category": "Adverb"
  },
  {
    "term": "本人",
    "termReading": "ほんにん",
    "termTranslation": "the person himself, the said person",
    "ex": "それは本人に聞いてください。",
    "exReading": "それ は ほんにん に きいて ください",
    "exTranslation": "Please ask the person in question.",
    "category": "Noun"
  },
  {
    "term": "本年",
    "termReading": "ほんねん",
    "termTranslation": "this year",
    "ex": "会社の本年の目標が発表されたよ。",
    "exReading": "かいしゃ の ほんねん の もくひょう が はっぴょう された よ",
    "exTranslation": "The company's goal this year was announced.",
    "category": "Noun"
  },
  {
    "term": "本日",
    "termReading": "ほんじつ",
    "termTranslation": "today",
    "ex": "本日のランチはハンバーグでございます。",
    "exReading": "ほんじつ の らんち は はんばーぐ で ございます",
    "exTranslation": "The lunch of the day is hamburger steak.",
    "category": "Noun"
  },
  {
    "term": "休める",
    "termReading": "やすめる",
    "termTranslation": "give a rest, set at ease",
    "ex": "疲れた体を休めてください。",
    "exReading": "つかれた からだ を やすめて ください",
    "exTranslation": "Please rest your weary bones.",
    "category": "Verb"
  },
  {
    "term": "ブーム",
    "termReading": "ブーム",
    "termTranslation": "boom",
    "ex": "最近、日本は健康ブームです。",
    "exReading": "さいきん にほん は けんこう ぶーむ です",
    "exTranslation": "There has been a health fad in Japan recently.",
    "category": "Noun"
  },
  {
    "term": "一休み",
    "termReading": "ひとやすみ",
    "termTranslation": "rest",
    "ex": "ここで一休みしましょう。",
    "exReading": "ここ で ひとやすみ しましょう",
    "exTranslation": "Let's take a break here.",
    "category": "Verbal Noun"
  },
  {
    "term": "大体",
    "termReading": "だいたい",
    "termTranslation": "outline, generally",
    "ex": "大体、初めから無理な計画だったのだ。",
    "exReading": "だいたい はじめ から むり な けいかく だった の だ",
    "exTranslation": "To be fair, it was a far-fetched plan from the beginning.",
    "category": "Adverb"
  },
  {
    "term": "入力",
    "termReading": "にゅうりょく",
    "termTranslation": "input, power input",
    "ex": "彼女の仕事はデータの入力です。",
    "exReading": "かのじょ の しごと は でーた の にゅうりょく です",
    "exTranslation": "Her job is inputting data.",
    "category": "Verbal Noun"
  },
  {
    "term": "体力",
    "termReading": "たいりょく",
    "termTranslation": "physical strength",
    "ex": "若者は体力がありますね。",
    "exReading": "わかもの は たいりょく が あります ね",
    "exTranslation": "Young people have a lot of stamina.",
    "category": "Noun"
  },
  {
    "term": "目上",
    "termReading": "めうえ",
    "termTranslation": "one's superiors, one's elders",
    "ex": "彼は目上の人にとても気を使います。",
    "exReading": "かれ は めうえ の ひと に とても き を つかいます",
    "exTranslation": "He's very respectful of his seniors.",
    "category": "Noun"
  },
  {
    "term": "ようやく",
    "termReading": "ようやく",
    "termTranslation": "at last, at length",
    "ex": "ようやくゴールが見えてきました。",
    "exReading": "ようやく ごーる が みえて きました",
    "exTranslation": "At last, we could see the finish line.",
    "category": "Adverb"
  },
  {
    "term": "目方",
    "termReading": "めかた",
    "termTranslation": "weight",
    "ex": "この荷の目方は70キロってとこかね。",
    "exReading": "この に の めかた は 70 きろ って とこ か ね",
    "exTranslation": "I guess the weight of this load is about 70 kilograms.",
    "category": "Noun"
  },
  {
    "term": "目下",
    "termReading": "めした",
    "termTranslation": "one's inferiors",
    "ex": "彼は目下の者にも優しいんだ。",
    "exReading": "かれ は めした の もの に も やさしい ん だ",
    "exTranslation": "He's kind to his juniors too.",
    "category": "Noun"
  },
  {
    "term": "目下",
    "termReading": "もっか",
    "termTranslation": "now, at present",
    "ex": "息子は目下勉強中です。",
    "exReading": "むすこ は もっか べんきょうちゅう です",
    "exTranslation": "My son is in the middle of studying.",
    "category": "Noun"
  },
  {
    "term": "出入り口",
    "termReading": "でいりぐち",
    "termTranslation": "doorway, gateway",
    "ex": "出入り口に車を停めないでください。",
    "exReading": "でいりぐち に くるま を とめない で ください",
    "exTranslation": "Please don't stop the car by the entrance.",
    "category": "Noun"
  },
  {
    "term": "一口",
    "termReading": "ひとくち",
    "termTranslation": "mouthful, bite",
    "ex": "彼はおまんじゅうを一口で食べたの。",
    "exReading": "かれ は おまんじゅう を ひとくち で たべた の",
    "exTranslation": "He ate the sweet bun in one mouthful.",
    "category": "Noun"
  },
  {
    "term": "人手",
    "termReading": "ひとで",
    "termTranslation": "hand, manpower",
    "ex": "人手が足りなくて忙しい。",
    "exReading": "ひとで が たりなくて いそがしい",
    "exTranslation": "We're busy because we lack manpower.",
    "category": "Noun"
  },
  {
    "term": "チーム",
    "termReading": "チーム",
    "termTranslation": "team",
    "ex": "彼はチームの一員です。",
    "exReading": "かれ は ちーむ の いちいん です",
    "exTranslation": "He's a member of the team.",
    "category": "Noun"
  },
  {
    "term": "手入れ",
    "termReading": "ていれ",
    "termTranslation": "care, repairs",
    "ex": "母は庭の手入れをしています。",
    "exReading": "はは は にわ の ていれ を して います",
    "exTranslation": "My mother's tending the garden.",
    "category": "Verbal Noun"
  },
  {
    "term": "手本",
    "termReading": "てほん",
    "termTranslation": "model, example",
    "ex": "手本を見ながら習字をしました。",
    "exReading": "てほん を みながら しゅうじ を しました",
    "exTranslation": "I practiced calligraphy by looking at examples.",
    "category": "Noun"
  },
  {
    "term": "手足",
    "termReading": "てあし",
    "termTranslation": "hands and feet, limbs",
    "ex": "あの人は手足が長い。",
    "exReading": "あの ひと は てあし が ながい",
    "exTranslation": "That person has long arms and legs.",
    "category": "Noun"
  },
  {
    "term": "田",
    "termReading": "た",
    "termTranslation": "rice field, rice paddy",
    "ex": "今は田に水を入れる季節です。",
    "exReading": "いま は た に みず を いれる きせつ です",
    "exTranslation": "It's the season now when rice fields are filled with water.",
    "category": "Noun"
  },
  {
    "term": "花火",
    "termReading": "はなび",
    "termTranslation": "fireworks, firecrackers",
    "ex": "昨日、花火を見に行ったよ。",
    "exReading": "きのう はなび を み に いった よ",
    "exTranslation": "I went to see fireworks yesterday.",
    "category": "Noun"
  },
  {
    "term": "タイプ",
    "termReading": "タイプ",
    "termTranslation": "type",
    "ex": "同じタイプで色の違うものはありますか。",
    "exReading": "おなじ たいぷ で いろ の ちがう もの は あります か",
    "exTranslation": "Do you have the same type in a different color?",
    "category": "Noun"
  },
  {
    "term": "男女",
    "termReading": "だんじょ",
    "termTranslation": "men and women, both sexes",
    "ex": "そのスポーツは男女一緒にします。",
    "exReading": "その すぽーつ は だんじょ いっしょ に します",
    "exTranslation": "Men and women play that sport together.",
    "category": "Noun"
  },
  {
    "term": "男子",
    "termReading": "だんし",
    "termTranslation": "boy, young man",
    "ex": "男子はこっちに並んで。",
    "exReading": "だんし は こっち に ならんで",
    "exTranslation": "Boys line up here.",
    "category": "Noun"
  },
  {
    "term": "私",
    "termReading": "わたくし",
    "termTranslation": "I, myself",
    "ex": "私は北海道の出身です。",
    "exReading": "わたくし は ほっかいどう の しゅっしん です",
    "exTranslation": "I'm from Hokkaido.",
    "category": "Noun"
  },
  {
    "term": "友人",
    "termReading": "ゆうじん",
    "termTranslation": "friend",
    "ex": "彼は高校時代からの友人です。",
    "exReading": "かれ は こうこう じだい から の ゆうじん です",
    "exTranslation": "He's my old friend from high school.",
    "category": "Noun"
  },
  {
    "term": "友",
    "termReading": "とも",
    "termTranslation": "friend, companion",
    "ex": "持つべきものは友よね。",
    "exReading": "もつべき もの は とも よ ね",
    "exTranslation": "The important thing to keep is friends.",
    "category": "Noun"
  },
  {
    "term": "ビジネス",
    "termReading": "ビジネス",
    "termTranslation": "business",
    "ex": "彼はビジネスクラスに乗ったの。",
    "exReading": "かれ は びじねすくらす に のった の",
    "exTranslation": "He traveled business class.",
    "category": "Noun"
  },
  {
    "term": "元",
    "termReading": "もと",
    "termTranslation": "origin, source",
    "ex": "あの人は卓球の元選手です。",
    "exReading": "あの ひと は たっきゅう の もとせんしゅ です",
    "exTranslation": "That's a former table tennis player.",
    "category": "Noun"
  },
  {
    "term": "手元",
    "termReading": "てもと",
    "termTranslation": "at hand",
    "ex": "説明書は手元にありますか。",
    "exReading": "せつめいしょ は てもと に あります か",
    "exTranslation": "Do you have the manual at hand?",
    "category": "Noun"
  },
  {
    "term": "元々",
    "termReading": "もともと",
    "termTranslation": "originally, by nature",
    "ex": "彼女は元々フランスに行くつもりだったの。",
    "exReading": "かのじょ は もともと ふらんす に いく つもり だった の",
    "exTranslation": "She was planning to go to France originally.",
    "category": "Adverb"
  },
  {
    "term": "天",
    "termReading": "てん",
    "termTranslation": "sky, Heaven",
    "ex": "天から恵みの雨が降ったね。",
    "exReading": "てん から めぐみ の あめ が ふった ね",
    "exTranslation": "A welcome rain fell from the skies.",
    "category": "Noun"
  },
  {
    "term": "本気",
    "termReading": "ほんき",
    "termTranslation": "seriousness, earnestness",
    "ex": "いや、僕は本気なんだ。",
    "exReading": "いや ぼく は ほんき な ん だ",
    "exTranslation": "No, I'm serious.",
    "category": "Adjectival Noun"
  },
  {
    "term": "明日",
    "termReading": "みょうにち",
    "termTranslation": "tomorrow",
    "ex": "明日、会議を開きます。",
    "exReading": "みょうにち かいぎ を ひらきます",
    "exTranslation": "The conference will be held tomorrow.",
    "category": "Noun"
  },
  {
    "term": "ネットワーク",
    "termReading": "ネットワーク",
    "termTranslation": "network",
    "ex": "最近、社内ネットワークの調子が悪い。",
    "exReading": "さいきん しゃない ねっとわーく の ちょうし が わるい",
    "exTranslation": "The office's intranet doesn't work well recently.",
    "category": "Noun"
  },
  {
    "term": "東西",
    "termReading": "とうざい",
    "termTranslation": "east and west",
    "ex": "東西に大きな道路が通っています。",
    "exReading": "とうざい に おおき な どうろ が とおって います",
    "exTranslation": "There's a major road running from east to west.",
    "category": "Noun"
  },
  {
    "term": "南西",
    "termReading": "なんせい",
    "termTranslation": "southwest",
    "ex": "ここから南西に進むと村があります。",
    "exReading": "ここ から なんせい に すすむ と むら が あります",
    "exTranslation": "There's a village if you continue southwest from here.",
    "category": "Noun"
  },
  {
    "term": "南東",
    "termReading": "なんとう",
    "termTranslation": "southeast",
    "ex": "南東の方角に月が見えるよ。",
    "exReading": "なんとう の ほうがく に つき が みえる よ",
    "exTranslation": "You can see the moon in the southeast.",
    "category": "Noun"
  },
  {
    "term": "南北",
    "termReading": "なんぼく",
    "termTranslation": "north and south",
    "ex": "南北に山が広がっています。",
    "exReading": "なんぼく に やま が ひろがって います",
    "exTranslation": "The mountains stretch out to the north and south.",
    "category": "Noun"
  },
  {
    "term": "北西",
    "termReading": "ほくせい",
    "termTranslation": "northwest",
    "ex": "台風は北西に進んでいます。",
    "exReading": "たいふう は ほくせい に すすんで います",
    "exTranslation": "The typhoon is advancing toward the northwest.",
    "category": "Noun"
  },
  {
    "term": "やや",
    "termReading": "やや",
    "termTranslation": "little, somewhat",
    "ex": "この服、私にはやや小さいみたい。",
    "exReading": "この ふく わたし に は やや ちいさい みたい",
    "exTranslation": "It looks like these clothes are a little small on me.",
    "category": "Adverb"
  },
  {
    "term": "北東",
    "termReading": "ほくとう",
    "termTranslation": "northeast",
    "ex": "町の北東に山があります。",
    "exReading": "まち の ほくとう に やま が あります",
    "exTranslation": "There's a mountain to the northeast of the town.",
    "category": "Noun"
  },
  {
    "term": "向ける",
    "termReading": "むける",
    "termTranslation": "turn toward, point at",
    "ex": "彼は上司に怒りの目を向けたんだよ。",
    "exReading": "かれ は じょうし に いかり の め を むけた ん だ よ",
    "exTranslation": "He looked angrily at his boss.",
    "category": "Verb"
  },
  {
    "term": "向き",
    "termReading": "むき",
    "termTranslation": "direction, turn",
    "ex": "花瓶の向きを変えたの。",
    "exReading": "かびん の むき を かえた の",
    "exTranslation": "I changed the way the vase was facing.",
    "category": "Noun"
  },
  {
    "term": "向かい",
    "termReading": "むかい",
    "termTranslation": "opposite side",
    "ex": "向かいの席が空いていますよ。",
    "exReading": "むかい の せき が あいて います よ",
    "exTranslation": "The seat opposite is vacant.",
    "category": "Noun"
  },
  {
    "term": "入門",
    "termReading": "にゅうもん",
    "termTranslation": "initiation",
    "ex": "私は相撲部屋に入門しました。",
    "exReading": "わたし は すもう べや に にゅうもん しました",
    "exTranslation": "I entered a sumo stable.",
    "category": "Noun"
  },
  {
    "term": "デモ",
    "termReading": "デモ",
    "termTranslation": "demonstration",
    "ex": "彼女はデモに参加したよ。",
    "exReading": "かのじょ は でも に さんか した よ",
    "exTranslation": "She participated in the demonstration.",
    "category": "Noun"
  },
  {
    "term": "門",
    "termReading": "もん",
    "termTranslation": "gate, entrance",
    "ex": "8時に学校の門が開きます。",
    "exReading": "8 じ に がっこう の もん が ひらきます",
    "exTranslation": "The gate of the school opens at eight o'clock.",
    "category": "Noun"
  },
  {
    "term": "開ける",
    "termReading": "ひらける",
    "termTranslation": "widen, develop",
    "ex": "霧が晴れて視界が開けたね。",
    "exReading": "きり が はれて しかい が ひらけた ね",
    "exTranslation": "The fog has cleared up and visibility has increased.",
    "category": "Verb"
  },
  {
    "term": "中間",
    "termReading": "ちゅうかん",
    "termTranslation": "middle, between",
    "ex": "そのふたつの町の中間には川が流れているの。",
    "exReading": "その ふたつ の まち の ちゅうかん に は かわ が ながれて いる の",
    "exTranslation": "A river flows between the two towns.",
    "category": "Noun"
  },
  {
    "term": "手間",
    "termReading": "てま",
    "termTranslation": "trouble, time",
    "ex": "これはとても手間のかかる料理です。",
    "exReading": "これ は とても てま の かかる りょうり です",
    "exTranslation": "This is a very time-consuming dish.",
    "category": "Noun"
  },
  {
    "term": "間",
    "termReading": "ま",
    "termTranslation": "interval, duration of time, chamber",
    "ex": "彼は少し間を置いてから返事した。",
    "exReading": "かれ は すこし ま を おいて から へんじ した",
    "exTranslation": "He responded after a short pause.",
    "category": "Noun"
  },
  {
    "term": "ワープロ",
    "termReading": "ワープロ",
    "termTranslation": "word processor",
    "ex": "ワープロで報告書を打ったよ。",
    "exReading": "わーぷろ で ほうこくしょ を うった よ",
    "exTranslation": "The report was typed with a word processor.",
    "category": "Noun"
  },
  {
    "term": "高まる",
    "termReading": "たかまる",
    "termTranslation": "rise, increase",
    "ex": "その計画に対する反対の声が高まっているわ。",
    "exReading": "その けいかく に たいする はんたい の こえ が たかまって いる わ",
    "exTranslation": "Opposition to the plan is increasing.",
    "category": "Verb"
  },
  {
    "term": "高める",
    "termReading": "たかめる",
    "termTranslation": "raise, enhance",
    "ex": "自分を高めるのは大切なことです。",
    "exReading": "じぶん を たかめる の は たいせつ な こと です",
    "exTranslation": "It's important to improve oneself.",
    "category": "Verb"
  },
  {
    "term": "安っぽい",
    "termReading": "やすっぽい",
    "termTranslation": "cheap, tacky",
    "ex": "そのシャツは安っぽいね。",
    "exReading": "その しゃつ は やすっぽい ね",
    "exTranslation": "That shirt looks cheap.",
    "category": "Adjective"
  },
  {
    "term": "低下",
    "termReading": "ていか",
    "termTranslation": "falling, lowering",
    "ex": "最近、教育レベルが低下しています。",
    "exReading": "さいきん きょういく れべる が ていか して います",
    "exTranslation": "Recently, education levels are decreasing.",
    "category": "Verbal Noun"
  },
  {
    "term": "低",
    "termReading": "てい",
    "termTranslation": "low",
    "ex": "彼は低賃金で働いているんだ。",
    "exReading": "かれ は ていちんぎん で はたらいて いる ん だ",
    "exTranslation": "He's working for a low wage.",
    "category": "Noun"
  },
  {
    "term": "最も",
    "termReading": "もっとも",
    "termTranslation": "the most",
    "ex": "彼は世界で最も早い男です。",
    "exReading": "かれ は せかい で もっとも はやい おとこ です",
    "exTranslation": "He's the fastest man in the world.",
    "category": "Adverb"
  },
  {
    "term": "メンバー",
    "termReading": "メンバー",
    "termTranslation": "member",
    "ex": "グループのメンバーは10人です。",
    "exReading": "ぐるーぷ の めんばー は 10 にん です 。",
    "exTranslation": "There are 10 members in the group.",
    "category": "Noun"
  },
  {
    "term": "月初め",
    "termReading": "つきはじめ",
    "termTranslation": "at the beginning of the month",
    "ex": "いつも月初めに彼と会います。",
    "exReading": "いつも つきはじめ に かれ と あいます",
    "exTranslation": "I always meet him at the beginning of the month.",
    "category": "Noun"
  },
  {
    "term": "前年",
    "termReading": "ぜんねん",
    "termTranslation": "the year before",
    "ex": "前年に比べて利益が上がりました。",
    "exReading": "ぜんねん に くらべて りえき が あがりました",
    "exTranslation": "Profits went up compared with the previous year.",
    "category": "Noun"
  },
  {
    "term": "前方",
    "termReading": "ぜんぽう",
    "termTranslation": "front",
    "ex": "前方に山が見えますね。",
    "exReading": "ぜんぽう に やま が みえます ね",
    "exTranslation": "We can see the mountain ahead.",
    "category": "Noun"
  },
  {
    "term": "手前",
    "termReading": "てまえ",
    "termTranslation": "in front",
    "ex": "駅の手前に郵便局があります。",
    "exReading": "えき の てまえ に ゆうびんきょく が あります",
    "exTranslation": "There's a post office by the station.",
    "category": "Noun"
  },
  {
    "term": "前向き",
    "termReading": "まえむき",
    "termTranslation": "facing forward, positively",
    "ex": "前向きに検討します。",
    "exReading": "まえむき に けんとう します",
    "exTranslation": "We'll give positive consideration to the matter.",
    "category": "Adjectival Noun"
  },
  {
    "term": "ハードウェア",
    "termReading": "ハードウェア",
    "termTranslation": "hardware",
    "ex": "この問題はハードウェアの故障が原因です。",
    "exReading": "この もんだい は はーどうぇあ の こしょう が げんいん です",
    "exTranslation": "This problem is caused by a hardware malfunction.",
    "category": "Noun"
  },
  {
    "term": "人前",
    "termReading": "ひとまえ",
    "termTranslation": "in front of other people",
    "ex": "彼女は人前に出ると緊張します。",
    "exReading": "かのじょ は ひとまえ に でる と きんちょう します",
    "exTranslation": "She feels nervous in front of other people.",
    "category": "Noun"
  },
  {
    "term": "前もって",
    "termReading": "まえもって",
    "termTranslation": "in advance",
    "ex": "休む時は前もって連絡ください。",
    "exReading": "やすむ とき は まえもって れんらく ください",
    "exTranslation": "If you're going to take time off, please give me advance notice.",
    "category": "Adverb"
  },
  {
    "term": "出前",
    "termReading": "でまえ",
    "termTranslation": "home delivery of food",
    "ex": "寿司の出前を頼んだよ。",
    "exReading": "すし の でまえ を たのんだ よ",
    "exTranslation": "We ordered some sushi by delivery.",
    "category": "Noun"
  },
  {
    "term": "前半",
    "termReading": "ぜんはん",
    "termTranslation": "first half",
    "ex": "相手チームのリードで前半が終わったよ。",
    "exReading": "あいて ちーむ の りーど で ぜんはん が おわった よ",
    "exTranslation": "The first half ended with the opposing team in the lead.",
    "category": "Noun"
  },
  {
    "term": "半ば",
    "termReading": "なかば",
    "termTranslation": "middle, halfway",
    "ex": "彼は30代の半ばです。",
    "exReading": "かれ は 30 だい の なかば です",
    "exTranslation": "He's in his mid-thirties.",
    "category": "Noun"
  },
  {
    "term": "マスコミ",
    "termReading": "マスコミ",
    "termTranslation": "mass communication",
    "ex": "彼はよくマスコミにも登場するね。",
    "exReading": "かれ は よく ますこみ に も とうじょう する ね",
    "exTranslation": "He often appears in the media.",
    "category": "Noun"
  },
  {
    "term": "半年",
    "termReading": "はんとし",
    "termTranslation": "half a year",
    "ex": "日本に来て半年になります。",
    "exReading": "にっぽん に きて はんとし に なります",
    "exTranslation": "It has been half a year since I came to Japan.",
    "category": "Noun"
  },
  {
    "term": "明朝",
    "termReading": "みょうちょう",
    "termTranslation": "tomorrow morning",
    "ex": "明朝10時からまた会議です。",
    "exReading": "みょうちょう 10 じ から また かいぎ です",
    "exTranslation": "There's another meeting from ten o'clock tomorrow morning.",
    "category": "Noun"
  },
  {
    "term": "晩年",
    "termReading": "ばんねん",
    "termTranslation": "late in life",
    "ex": "彼は晩年を故郷で過ごしたんだ。",
    "exReading": "かれ は ばんねん を こきょう で すごした ん だ",
    "exTranslation": "He spent his later years in his hometown.",
    "category": "Noun"
  },
  {
    "term": "夜間",
    "termReading": "やかん",
    "termTranslation": "night, nighttime",
    "ex": "夜間は裏口から入ってください。",
    "exReading": "やかん は うらぐち から はいって ください",
    "exTranslation": "Please enter from the back door at night.",
    "category": "Noun"
  },
  {
    "term": "夜空",
    "termReading": "よぞら",
    "termTranslation": "night sky",
    "ex": "ふたりで夜空を見上げたの。",
    "exReading": "ふたり で よぞら を みあげた の",
    "exTranslation": "The two of us looked up at the night sky.",
    "category": "Noun"
  },
  {
    "term": "夜明け",
    "termReading": "よあけ",
    "termTranslation": "dawn, daybreak",
    "ex": "夜明けと共に目が覚めたんだ。",
    "exReading": "よあけ と とも に め が さめた ん だ",
    "exTranslation": "I awoke at daybreak.",
    "category": "Noun"
  },
  {
    "term": "やがて",
    "termReading": "やがて",
    "termTranslation": "soon, before long",
    "ex": "やがて雪も止むでしょう。",
    "exReading": "やがて ゆき も やむ でしょう",
    "exTranslation": "The snow will stop before long.",
    "category": "Adverb"
  },
  {
    "term": "月夜",
    "termReading": "つきよ",
    "termTranslation": "moonlit night",
    "ex": "散歩にいい月夜ですね。",
    "exReading": "さんぽ に いい つきよ です ね",
    "exTranslation": "It's a good moonlit night for a stroll.",
    "category": "Noun"
  },
  {
    "term": "夜",
    "termReading": "よ",
    "termTranslation": "night, evening",
    "ex": "あと1時間で夜が明けますね。",
    "exReading": "あと 1 じかん で よ が あけます ね",
    "exTranslation": "It will be dawn in another hour.",
    "category": "Noun"
  },
  {
    "term": "七夕",
    "termReading": "たなばた",
    "termTranslation": "Tanabata Festival, Star Festival",
    "ex": "日本では7月7日は七夕の日です。",
    "exReading": "にほん で は 7 がつ 7 か は たなばた の ひ です",
    "exTranslation": "July 7th is the day of the Tanabata Festival in Japan.",
    "category": "Noun"
  },
  {
    "term": "夕日",
    "termReading": "ゆうひ",
    "termTranslation": "setting sun",
    "ex": "夕日が西の空に沈んだね。",
    "exReading": "ゆうひ が にし の そら に しずんだ ね",
    "exTranslation": "The evening sun sunk in the western sky.",
    "category": "Noun"
  },
  {
    "term": "飲み水",
    "termReading": "のみみず",
    "termTranslation": "drinking water",
    "ex": "昔は川の水が飲み水でした。",
    "exReading": "むかし は かわ の みず が のみみず でした",
    "exTranslation": "A long time ago, rivers were used for drinking water.",
    "category": "Noun"
  },
  {
    "term": "わずか",
    "termReading": "わずか",
    "termTranslation": "few, little",
    "ex": "彼はわずかな貯金で暮らしている。",
    "exReading": "かれ は わずか な ちょきん で くらして いる",
    "exTranslation": "He lives on his meager savings.",
    "category": "Adjectival Noun"
  },
  {
    "term": "飯",
    "termReading": "めし",
    "termTranslation": "cooked rice, meal",
    "ex": "今朝は飯を食べたかい。",
    "exReading": "けさ は めし を たべた かい",
    "exTranslation": "Did you have a meal this morning?",
    "category": "Noun"
  },
  {
    "term": "見上げる",
    "termReading": "みあげる",
    "termTranslation": "look up",
    "ex": "私は空を見上げたの。",
    "exReading": "わたし は そら を みあげた の",
    "exTranslation": "I looked up at the sky.",
    "category": "Verb"
  },
  {
    "term": "見下ろす",
    "termReading": "みおろす",
    "termTranslation": "look down, overlook",
    "ex": "山の頂上からふもとを見下ろしたの。",
    "exReading": "やま の ちょうじょう から ふもと を みおろした の",
    "exTranslation": "I looked down on the foothills from the top of the mountain.",
    "category": "Verb"
  },
  {
    "term": "見出し",
    "termReading": "みだし",
    "termTranslation": "headline, heading",
    "ex": "新聞の見出しが彼の目を引いたんだ。",
    "exReading": "しんぶん の みだし が かれ の め を ひいた ん だ",
    "exTranslation": "The newspaper article drew his attention.",
    "category": "Noun"
  },
  {
    "term": "見本",
    "termReading": "みほん",
    "termTranslation": "sample, model",
    "ex": "見本はこちらにございます。",
    "exReading": "みほん は こちら に ございます",
    "exTranslation": "There's a sample here.",
    "category": "Noun"
  },
  {
    "term": "パターン",
    "termReading": "パターン",
    "termTranslation": "pattern",
    "ex": "彼女の行動パターンは興味深いな。",
    "exReading": "かのじょ の こうどう ぱたーん は きょうみぶかい な",
    "exTranslation": "Her behavior pattern is interesting.",
    "category": "Noun"
  },
  {
    "term": "見つめる",
    "termReading": "みつめる",
    "termTranslation": "stare",
    "ex": "彼はその絵をずっと見つめていたの。",
    "exReading": "かれ は その え を ずっと みつめていた の 。",
    "exTranslation": "He was gazing at that painting for a long time.",
    "category": "Verb"
  },
  {
    "term": "見晴らし",
    "termReading": "みはらし",
    "termTranslation": "view, outlook",
    "ex": "このベランダは見晴らしがいい。",
    "exReading": "この べらんだ は みはらし が いい",
    "exTranslation": "The view from this veranda is good.",
    "category": "Noun"
  },
  {
    "term": "月見",
    "termReading": "つきみ",
    "termTranslation": "moon viewing",
    "ex": "9月には月見を楽しみます。",
    "exReading": "9 がつ に は つきみ を たのしみます",
    "exTranslation": "We enjoy moon viewing in September.",
    "category": "Noun"
  },
  {
    "term": "よそ見",
    "termReading": "よそみ",
    "termTranslation": "look around",
    "ex": "運転中はよそ見をしてはいけません。",
    "exReading": "うんてんちゅう は よそみ を して は いけません",
    "exTranslation": "Keep your eyes on the road when you're driving.",
    "category": "Verbal Noun"
  },
  {
    "term": "見かけ",
    "termReading": "みかけ",
    "termTranslation": "looks, appearance",
    "ex": "彼は見かけによらず優しいね。",
    "exReading": "かれ は みかけ に よらず やさしい ね",
    "exTranslation": "He's kind despite his appearance.",
    "category": "Noun"
  },
  {
    "term": "方言",
    "termReading": "ほうげん",
    "termTranslation": "dialect",
    "ex": "彼は方言で話します。",
    "exReading": "かれ は ほうげん で はなします",
    "exTranslation": "He speaks in a dialect.",
    "category": "Noun"
  },
  {
    "term": "ついに",
    "termReading": "ついに",
    "termTranslation": "at last, finally",
    "ex": "彼はついに弁護士の資格を取ったよ。",
    "exReading": "かれ は ついに べんごし の しかく を とった よ",
    "exTranslation": "He finally passed the bar examination.",
    "category": "Adverb"
  },
  {
    "term": "一言",
    "termReading": "ひとこと",
    "termTranslation": "single word",
    "ex": "社長に一言お願いしたの。",
    "exReading": "しゃちょう に ひとこと おねがい した の",
    "exTranslation": "I asked the president to deliver a brief speech.",
    "category": "Noun"
  },
  {
    "term": "文明",
    "termReading": "ぶんめい",
    "termTranslation": "civilization, culture",
    "ex": "多くの文明は川の近くで始まった。",
    "exReading": "おおく の ぶんめい は かわ の ちかく で はじまった",
    "exTranslation": "A lot of civilizations started by a river.",
    "category": "Noun"
  },
  {
    "term": "本文",
    "termReading": "ほんぶん",
    "termTranslation": "text, this text",
    "ex": "本文をよく読んで答えてください。",
    "exReading": "ほんぶん を よく よんで こたえて ください",
    "exTranslation": "Please read the text carefully and answer.",
    "category": "Noun"
  },
  {
    "term": "文",
    "termReading": "ぶん",
    "termTranslation": "writings, sentence",
    "ex": "この文は意味が分かりません。",
    "exReading": "この ぶん は いみ が わかりません",
    "exTranslation": "I don't understand the meaning of this sentence.",
    "category": "Noun"
  },
  {
    "term": "読書",
    "termReading": "どくしょ",
    "termTranslation": "reading a book",
    "ex": "私の趣味は読書です。",
    "exReading": "わたし の しゅみ は どくしょ です",
    "exTranslation": "My hobby is reading.",
    "category": "Verbal Noun"
  },
  {
    "term": "まるで",
    "termReading": "まるで",
    "termTranslation": "just like, completely",
    "ex": "彼はまるで子供のようにはしゃいだよ。",
    "exReading": "かれ は まるで こども の よう に はしゃいだ よ",
    "exTranslation": "He froliced just like a kid.",
    "category": "Adverb"
  },
  {
    "term": "読み書き",
    "termReading": "よみかき",
    "termTranslation": "reading and writing",
    "ex": "息子は学校で読み書きを勉強しています。",
    "exReading": "むすこ は がっこう で よみかき を べんきょう して います",
    "exTranslation": "My son studies reading and writing at school.",
    "category": "Noun"
  },
  {
    "term": "目覚ましい",
    "termReading": "めざましい",
    "termTranslation": "striking, marvelous",
    "ex": "あの国は目覚ましい発展を遂げたの。",
    "exReading": "あの くに は めざましい はってん を とげた の",
    "exTranslation": "That country accomplished remarkable development.",
    "category": "Adjective"
  },
  {
    "term": "見覚え",
    "termReading": "みおぼえ",
    "termTranslation": "recognition, remembrance",
    "ex": "この人に見覚えがありますか。",
    "exReading": "この ひと に みおぼえ が あります か",
    "exTranslation": "Do you remember seeing this person?",
    "category": "Noun"
  },
  {
    "term": "目覚まし",
    "termReading": "めざまし",
    "termTranslation": "waking up",
    "ex": "目覚ましにコーヒーを飲んだの。",
    "exReading": "めざまし に こーひー を のんだ の",
    "exTranslation": "I drank coffee to wake up.",
    "category": "Noun"
  },
  {
    "term": "閉会",
    "termReading": "へいかい",
    "termTranslation": "closing, adjournment",
    "ex": "会長が閉会の挨拶をしました。",
    "exReading": "かいちょう が へいかい の あいさつ を しました",
    "exTranslation": "The chairman gave the closing greetings.",
    "category": "Verbal Noun"
  },
  {
    "term": "どんどん",
    "termReading": "どんどん",
    "termTranslation": "steadily",
    "ex": "彼は山道をどんどん進んで行ったの。",
    "exReading": "かれ は やまみち を どんどん すすんで いった の",
    "exTranslation": "He made quick progress along the mountain path.",
    "category": "Adverb"
  },
  {
    "term": "出会う",
    "termReading": "であう",
    "termTranslation": "meet",
    "ex": "アメリカで彼女と出会いました。",
    "exReading": "あめりか で かのじょ と であいました。",
    "exTranslation": "I met her in the United States.",
    "category": "Verb"
  },
  {
    "term": "話し合い",
    "termReading": "はなしあい",
    "termTranslation": "talk, discussion",
    "ex": "プロジェクトメンバーと話し合いをしたの。",
    "exReading": "ぷろじぇくと めんばー と はなしあい を した の",
    "exTranslation": "I had a discussion with the project members.",
    "category": "Noun"
  },
  {
    "term": "見合い",
    "termReading": "みあい",
    "termTranslation": "meeting with a view to marriage",
    "ex": "うちの両親はお見合い結婚でした。",
    "exReading": "うち の りょうしん は おみあい けっこん でした",
    "exTranslation": "My parents had an arranged marriage.",
    "category": "Verbal Noun"
  },
  {
    "term": "間に合わせる",
    "termReading": "まにあわせる",
    "termTranslation": "make do",
    "ex": "昼食はクッキーで間に合わせたの。",
    "exReading": "ちゅうしょく は くっきー で まにあわせた の",
    "exTranslation": "I made do with cookies for lunch.",
    "category": "Verb"
  },
  {
    "term": "本社",
    "termReading": "ほんしゃ",
    "termTranslation": "head office",
    "ex": "今日は本社で会議があります。",
    "exReading": "きょう は ほんしゃ で かいぎ が あります",
    "exTranslation": "There's a conference at headquarters today.",
    "category": "Noun"
  },
  {
    "term": "それほど",
    "termReading": "それほど",
    "termTranslation": "so much, that much",
    "ex": "彼がそれほど悩んでいたとは知らなかった。",
    "exReading": "かれ が それほど なやんで いた と は しらなかった",
    "exTranslation": "I didn't know he was so worried.",
    "category": "Adverb"
  },
  {
    "term": "入社",
    "termReading": "にゅうしゃ",
    "termTranslation": "entering a company",
    "ex": "彼は昨年入社したの。",
    "exReading": "かれ は さくねん にゅうしゃ した の",
    "exTranslation": "He joined the company last year.",
    "category": "Verbal Noun"
  },
  {
    "term": "満足",
    "termReading": "まんぞく",
    "termTranslation": "satisfaction, contentment",
    "ex": "彼は結果に満足したようです。",
    "exReading": "かれ は けっか に まんぞく した よう です",
    "exTranslation": "He seems to be satisfied with the result.",
    "category": "Verbal Noun"
  },
  {
    "term": "満たす",
    "termReading": "みたす",
    "termTranslation": "fill, satisfy",
    "ex": "彼は応募の条件を満たしていない。",
    "exReading": "かれ は おうぼ の じょうけん を みたして いない",
    "exTranslation": "He doesn't meet the application requirements.",
    "category": "Verb"
  },
  {
    "term": "満ちる",
    "termReading": "みちる",
    "termTranslation": "be filled, expire",
    "ex": "月が満ちてきましたね。",
    "exReading": "つき が みちて きました ね",
    "exTranslation": "The moon is waxing.",
    "category": "Verb"
  },
  {
    "term": "満員",
    "termReading": "まんいん",
    "termTranslation": "full to capacity",
    "ex": "このバスは満員です。",
    "exReading": "この ばす は まんいん です",
    "exTranslation": "This bus is full.",
    "category": "Noun"
  },
  {
    "term": "満月",
    "termReading": "まんげつ",
    "termTranslation": "full moon",
    "ex": "今日は満月ですね。",
    "exReading": "きょう は まんげつ です ね",
    "exTranslation": "It's a full moon today.",
    "category": "Noun"
  },
  {
    "term": "もはや",
    "termReading": "もはや",
    "termTranslation": "already, no longer",
    "ex": "もはや彼の助けは必要じゃないの。",
    "exReading": "もはや かれ の たすけ は ひつよう じゃ ない の",
    "exTranslation": "His help is no longer needed.",
    "category": "Adverb"
  },
  {
    "term": "出来事",
    "termReading": "できごと",
    "termTranslation": "occurrence, incident",
    "ex": "面白い出来事がありました。",
    "exReading": "おもしろい できごと が ありました",
    "exTranslation": "Something interesting happened.",
    "category": "Noun"
  },
  {
    "term": "見事",
    "termReading": "みごと",
    "termTranslation": "splendid, admirable",
    "ex": "彼は見事なジャンプを見せたね。",
    "exReading": "かれ は みごと な じゃんぷ を みせた ね",
    "exTranslation": "He showed us a splendid jump.",
    "category": "Adjectival Noun"
  },
  {
    "term": "大工",
    "termReading": "だいく",
    "termTranslation": "carpenter",
    "ex": "私の父は大工です。",
    "exReading": "わたし の ちち は だいく です",
    "exTranslation": "My father's a carpenter.",
    "category": "Noun"
  },
  {
    "term": "場",
    "termReading": "ば",
    "termTranslation": "opportunity, occasion",
    "ex": "この場でお礼を言わせてください。",
    "exReading": "この ば で おれい を いわせて ください",
    "exTranslation": "Please let me take this opportunity to offer my gratitude.",
    "category": "Noun"
  },
  {
    "term": "入場",
    "termReading": "にゅうじょう",
    "termTranslation": "entrance, admission",
    "ex": "選手の入場です。",
    "exReading": "せんしゅ の にゅうじょう です",
    "exTranslation": "The athletes will march in.",
    "category": "Verbal Noun"
  },
  {
    "term": "デザイン",
    "termReading": "デザイン",
    "termTranslation": "design",
    "ex": "この服のデザインは素敵ですね。",
    "exReading": "この ふく の でざいん は すてき です ね 。",
    "exTranslation": "The design of this garment is wonderful.",
    "category": "Verbal Noun"
  },
  {
    "term": "電力",
    "termReading": "でんりょく",
    "termTranslation": "electric power, electricity",
    "ex": "このエアコンはあまり電力を使いません。",
    "exReading": "この えあこん は あまり でんりょく を つかいません",
    "exTranslation": "This air conditioner doesn't use much electricity.",
    "category": "Noun"
  },
  {
    "term": "電子",
    "termReading": "でんし",
    "termTranslation": "electron, electronic",
    "ex": "電子辞書はとても便利です。",
    "exReading": "でんし じしょ は とても べんり です",
    "exTranslation": "Electronic dictionaries are very useful.",
    "category": "Noun"
  },
  {
    "term": "交じる",
    "termReading": "まじる",
    "termTranslation": "be mingled, be mixed",
    "ex": "彼女は外国人に交じってダンスをしたの。",
    "exReading": "かのじょ は がいこくじん に まじって だんす を した の",
    "exTranslation": "She mingled with foreigners as she danced.",
    "category": "Verb"
  },
  {
    "term": "交わる",
    "termReading": "まじわる",
    "termTranslation": "associate with, socialize",
    "ex": "人と交わって、多くのことを学びました。",
    "exReading": "ひと と まじわって おおく の こと を まなびました",
    "exTranslation": "I learned a lot of things by socializing with people.",
    "category": "Verb"
  },
  {
    "term": "交ぜる",
    "termReading": "まぜる",
    "termTranslation": "include",
    "ex": "私も交ぜてください。",
    "exReading": "わたし も まぜて ください",
    "exTranslation": "Please include me.",
    "category": "Verb"
  },
  {
    "term": "ファン",
    "termReading": "ファン",
    "termTranslation": "fan, lover",
    "ex": "彼は大のサッカーファンです。",
    "exReading": "かれ は だい の さっかーふぁん です",
    "exTranslation": "He's a huge soccer fan.",
    "category": "Noun"
  },
  {
    "term": "交ざる",
    "termReading": "まざる",
    "termTranslation": "mix, socialize with",
    "ex": "大人も子供も交ざって遊んだの。",
    "exReading": "おとな も こども も まざって あそんだ の",
    "exTranslation": "Adults and children played together.",
    "category": "Verb"
  },
  {
    "term": "交わる",
    "termReading": "まじわる",
    "termTranslation": "cross",
    "ex": "二つの国道はここで交わります。",
    "exReading": "ふたつ の こくどう は ここ で まじわります",
    "exTranslation": "Two national roads intersect here.",
    "category": "Verb"
  },
  {
    "term": "見通し",
    "termReading": "みとおし",
    "termTranslation": "perspective, prospect",
    "ex": "仕事の見通しがたたない。",
    "exReading": "しごと の みとおし が たたない",
    "exTranslation": "There's no end in sight to my work.",
    "category": "Noun"
  },
  {
    "term": "通じる",
    "termReading": "つうじる",
    "termTranslation": "pass, communicate with",
    "ex": "その国では英語は通じますか。",
    "exReading": "その くに で は えいご は つうじます か",
    "exTranslation": "Do they speak English in that country?",
    "category": "Verb"
  },
  {
    "term": "通す",
    "termReading": "とおす",
    "termTranslation": "let pass, run through",
    "ex": "針に糸を通してください。",
    "exReading": "はり に いと を とおして ください",
    "exTranslation": "Please thread the needle.",
    "category": "Verb"
  },
  {
    "term": "通行",
    "termReading": "つうこう",
    "termTranslation": "passage, traffic",
    "ex": "この道は通行できません。",
    "exReading": "この みち は つうこう できません",
    "exTranslation": "This is an impassable road.",
    "category": "Noun"
  },
  {
    "term": "なさる",
    "termReading": "なさる",
    "termTranslation": "do",
    "ex": "お申し込みなさるのでしたら、こちらにお並びください。",
    "exReading": "おもうしこみ なさる の でしたら こちら に お ならび ください",
    "exTranslation": "Please line up here if you're applying.",
    "category": "Verb"
  },
  {
    "term": "人通り",
    "termReading": "ひとどおり",
    "termTranslation": "traffic",
    "ex": "ここは人通りが激しいね。",
    "exReading": "ここ は ひとどおり が はげしい ね",
    "exTranslation": "A lot of people pass through here.",
    "category": "Noun"
  },
  {
    "term": "一通り",
    "termReading": "ひととおり",
    "termTranslation": "briefly, once over",
    "ex": "説明書を一通り読んだの。",
    "exReading": "せつめいしょ を ひととおり よんだ の",
    "exTranslation": "I glanced over the manual.",
    "category": "Adverb"
  },
  {
    "term": "通路",
    "termReading": "つうろ",
    "termTranslation": "passage, alley",
    "ex": "通路の右側にトイレがありますよ。",
    "exReading": "つうろ の みぎがわ に といれ が あります よ",
    "exTranslation": "There's a restroom on the right side of the corridor.",
    "category": "Noun"
  },
  {
    "term": "地上",
    "termReading": "ちじょう",
    "termTranslation": "above ground",
    "ex": "この電車は地上を走ります。",
    "exReading": "この でんしゃ は ちじょう を はしります",
    "exTranslation": "This train runs on the surface.",
    "category": "Noun"
  },
  {
    "term": "地",
    "termReading": "ち",
    "termTranslation": "ground, place",
    "ex": "彼はその地で残りの生涯を過ごしたんだ。",
    "exReading": "かれ は その ち で のこり の しょうがい を すごした ん だ",
    "exTranslation": "He spent the rest of his life in that place.",
    "category": "Noun"
  },
  {
    "term": "まとまる",
    "termReading": "まとまる",
    "termTranslation": "be collected",
    "ex": "みんなの意見がまとまりました。",
    "exReading": "みんな の いけん が まとまりました",
    "exTranslation": "Everyone's opinions were brought together.",
    "category": "Verb"
  },
  {
    "term": "地下道",
    "termReading": "ちかどう",
    "termTranslation": "underpass, underground passage",
    "ex": "地下道を通って行きましょう。",
    "exReading": "ちかどう を とおって いきましょう",
    "exTranslation": "Let's go through the underpass.",
    "category": "Noun"
  },
  {
    "term": "地下",
    "termReading": "ちか",
    "termTranslation": "underground",
    "ex": "スタジオは地下にあります。",
    "exReading": "すたじお は ちか に あります",
    "exTranslation": "The studio is in the basement.",
    "category": "Noun"
  },
  {
    "term": "地方",
    "termReading": "ちほう",
    "termTranslation": "district, region",
    "ex": "この地方は漁業が盛んです。",
    "exReading": "この ちほう は ぎょぎょう が さかん です",
    "exTranslation": "The fishing industry is flourishing in this region.",
    "category": "Noun"
  },
  {
    "term": "図る",
    "termReading": "はかる",
    "termTranslation": "strive for, attempt",
    "ex": "これからは経営の合理化を図りたいと思います。",
    "exReading": "これから は けいえい の ごうりか を はかりたい と おもいます",
    "exTranslation": "From now on I want to attempt to streamline management.",
    "category": "Verb"
  },
  {
    "term": "図書",
    "termReading": "としょ",
    "termTranslation": "books",
    "ex": "これは児童図書です。",
    "exReading": "これ は じどうとしょ です",
    "exTranslation": "This is a children's book.",
    "category": "Noun"
  },
  {
    "term": "ルール",
    "termReading": "ルール",
    "termTranslation": "rule",
    "ex": "このゲームのルールは簡単です。",
    "exReading": "この げーむ の るーる は かんたん です",
    "exTranslation": "The rules of this game are easy.",
    "category": "Noun"
  },
  {
    "term": "他方",
    "termReading": "たほう",
    "termTranslation": "other side",
    "ex": "他方の視点からも見てみましょう。",
    "exReading": "たほう の してん から も みて みましょう",
    "exTranslation": "Let's think from the other point of view.",
    "category": "Noun"
  },
  {
    "term": "他人",
    "termReading": "たにん",
    "termTranslation": "another person, stranger",
    "ex": "私は他人に住所を教えたくない。",
    "exReading": "わたし は たにん に じゅうしょ を おしえたく ない",
    "exTranslation": "I don't want to tell other people my address.",
    "category": "Noun"
  },
  {
    "term": "中止",
    "termReading": "ちゅうし",
    "termTranslation": "suspension, stopping",
    "ex": "雨で運動会が中止になったの。",
    "exReading": "あめ で うんどうかい が ちゅうし に なった の",
    "exTranslation": "The athletic meet has been canceled due to the rain.",
    "category": "Verbal Noun"
  },
  {
    "term": "通行止め",
    "termReading": "つうこうどめ",
    "termTranslation": "road closed",
    "ex": "あの道路は通行止めだそうです。",
    "exReading": "あの どうろ は つうこうどめ だ そう です",
    "exTranslation": "I hear that that road is closed to traffic.",
    "category": "Noun"
  },
  {
    "term": "見渡す",
    "termReading": "みわたす",
    "termTranslation": "look out over",
    "ex": "丘の上から草原を見渡したの。",
    "exReading": "おか の うえ から そうげん を みわたした の",
    "exTranslation": "I looked over the meadow from the top of the hill.",
    "category": "Verb"
  },
  {
    "term": "二度と",
    "termReading": "にどと",
    "termTranslation": "never again",
    "ex": "もうここには二度と来ません。",
    "exReading": "もう ここ に は にどと きません",
    "exTranslation": "I'll never come here again.",
    "category": "Adverb"
  },
  {
    "term": "ヘリコプター",
    "termReading": "ヘリコプター",
    "termTranslation": "helicopter",
    "ex": "事故現場の上空をヘリコプターが飛んでいます。",
    "exReading": "じこ げんば の じょうくう を へりこぷたー が とんで います",
    "exTranslation": "There's a helicopter flying over the accident site.",
    "category": "Noun"
  },
  {
    "term": "度々",
    "termReading": "たびたび",
    "termTranslation": "often",
    "ex": "彼から度々メールが来ます。",
    "exReading": "かれ から たびたび めーる が きます",
    "exTranslation": "I often get email from him.",
    "category": "Adverb"
  },
  {
    "term": "この度",
    "termReading": "このたび",
    "termTranslation": "on this occasion",
    "ex": "この度はご結婚おめでとうございます。",
    "exReading": "このたび は ごけっこん おめでとう ございます",
    "exTranslation": "Congratulations on your marriage.",
    "category": "Noun"
  },
  {
    "term": "毎度",
    "termReading": "まいど",
    "termTranslation": "every time, always",
    "ex": "毎度ありがとうございます。",
    "exReading": "まいど ありがとう ございます",
    "exTranslation": "Thank you for your patronage.",
    "category": "Noun"
  },
  {
    "term": "間近",
    "termReading": "まぢか",
    "termTranslation": "nearby, close at hand",
    "ex": "有名人を間近で見たよ。",
    "exReading": "ゆうめいじん を まぢか で みた よ",
    "exTranslation": "I saw a celebrity up close.",
    "category": "Adjectival Noun"
  },
  {
    "term": "近道",
    "termReading": "ちかみち",
    "termTranslation": "shortcut",
    "ex": "こっちが近道です。",
    "exReading": "こっち が ちかみち です",
    "exTranslation": "This is a shortcut.",
    "category": "Noun"
  },
  {
    "term": "ともかく",
    "termReading": "ともかく",
    "termTranslation": "at least, at any rate",
    "ex": "ともかく一度考え直しましょう。",
    "exReading": "ともかく いちど かんがえなおしましょう",
    "exTranslation": "At any rate, let's rethink this one more time.",
    "category": "Adverb"
  },
  {
    "term": "長年",
    "termReading": "ながねん",
    "termTranslation": "many years, long time",
    "ex": "彼は長年の友人です。",
    "exReading": "かれ は ながねん の ゆうじん です",
    "exTranslation": "He's an old friend of mine.",
    "category": "Noun"
  },
  {
    "term": "年長",
    "termReading": "ねんちょう",
    "termTranslation": "seniority",
    "ex": "彼がこのグループで一番年長です。",
    "exReading": "かれ が この ぐるーぷ で いちばん ねんちょう です",
    "exTranslation": "He's the most senior in this group.",
    "category": "Adjectival Noun"
  },
  {
    "term": "長らく",
    "termReading": "ながらく",
    "termTranslation": "for a long time",
    "ex": "長らくお待たせしました。",
    "exReading": "ながらく お またせ しました 。",
    "exTranslation": "I'm sorry to keep you waiting for such a long time.",
    "category": "Adverb"
  },
  {
    "term": "長話",
    "termReading": "ながばなし",
    "termTranslation": "long conversation, talk for a long time",
    "ex": "母が電話で長話をしているんだ。",
    "exReading": "はは が でんわ で ながばなし を して いる ん だ",
    "exTranslation": "My mother is having a long talk on the telephone.",
    "category": "Verbal Noun"
  },
  {
    "term": "短大",
    "termReading": "たんだい",
    "termTranslation": "junior college",
    "ex": "妹は短大を卒業しました。",
    "exReading": "いもうと は たんだい を そつぎょう しました",
    "exTranslation": "My little sister graduated from junior college.",
    "category": "Noun"
  },
  {
    "term": "つい",
    "termReading": "つい",
    "termTranslation": "inadvertently, accidentally",
    "ex": "会議中、ついあくびをしてしまいました。",
    "exReading": "かいぎちゅう つい あくび を して しまいました",
    "exTranslation": "I inadvertently yawned in the meeting.",
    "category": "Adverb"
  },
  {
    "term": "短気",
    "termReading": "たんき",
    "termTranslation": "short temper",
    "ex": "彼は短気な人です。",
    "exReading": "かれ は たんき な ひと です",
    "exTranslation": "He's a short-tempered person.",
    "category": "Adjectival Noun"
  },
  {
    "term": "広げる",
    "termReading": "ひろげる",
    "termTranslation": "spread, unfold",
    "ex": "電車の中では新聞を広げないで。",
    "exReading": "でんしゃ の なか で は しんぶん を ひろげない で",
    "exTranslation": "Don't spread your newspaper wide open in the train.",
    "category": "Verb"
  },
  {
    "term": "広場",
    "termReading": "ひろば",
    "termTranslation": "square, open space",
    "ex": "広場に子供が沢山集まっていたよ。",
    "exReading": "ひろば に こども が たくさん あつまって いた よ",
    "exTranslation": "A lot of children had gathered in the plaza.",
    "category": "Noun"
  },
  {
    "term": "広まる",
    "termReading": "ひろまる",
    "termTranslation": "spread, be in general circulation",
    "ex": "その噂はすぐに広まったよ。",
    "exReading": "その うわさ は すぐ に ひろまった よ",
    "exTranslation": "The rumor spread quickly.",
    "category": "Verb"
  },
  {
    "term": "広める",
    "termReading": "ひろめる",
    "termTranslation": "spread, extend",
    "ex": "誰が噂を広めたんだろう。",
    "exReading": "だれ が うわさ を ひろめた ん だろう",
    "exTranslation": "Who spread the rumor?",
    "category": "Verb"
  },
  {
    "term": "トンネル",
    "termReading": "トンネル",
    "termTranslation": "tunnel",
    "ex": "トンネルを抜けると海が見えたよ。",
    "exReading": "とんねる を ぬける と うみ が みえた よ",
    "exTranslation": "When we came out of the tunnel, we could see the sea.",
    "category": "Noun"
  },
  {
    "term": "全力",
    "termReading": "ぜんりょく",
    "termTranslation": "all one's might, full capacity",
    "ex": "全力で走れ。",
    "exReading": "ぜんりょく で はしれ",
    "exTranslation": "Run with all your might!",
    "category": "Noun"
  },
  {
    "term": "部門",
    "termReading": "ぶもん",
    "termTranslation": "division, department",
    "ex": "あの歌手は3部門で賞を取ったの。",
    "exReading": "あの かしゅ は 3 ぶもん で しょう を とった の",
    "exTranslation": "That singer took the prize in three categories.",
    "category": "Noun"
  },
  {
    "term": "本部",
    "termReading": "ほんぶ",
    "termTranslation": "head office, headquarters",
    "ex": "その事件の直後、捜査本部が設置された。",
    "exReading": "その じけん の ちょくご そうさ ほんぶ が せっち された",
    "exTranslation": "Immediately after the incident, investigation headquarters were set up.",
    "category": "Noun"
  },
  {
    "term": "部長",
    "termReading": "ぶちょう",
    "termTranslation": "section chief, department head",
    "ex": "部長に仕事の相談をしたんだ。",
    "exReading": "ぶちょう に しごと の そうだん を した ん だ",
    "exTranslation": "I consulted the manager about my job.",
    "category": "Noun"
  },
  {
    "term": "内部",
    "termReading": "ないぶ",
    "termTranslation": "interior, inner parts",
    "ex": "これは機械の内部の問題です。",
    "exReading": "これ は きかい の ないぶ の もんだい です",
    "exTranslation": "This is a problem with the internal parts of the machine.",
    "category": "Noun"
  },
  {
    "term": "部下",
    "termReading": "ぶか",
    "termTranslation": "subordinate",
    "ex": "彼は優秀な部下を持っているわね。",
    "exReading": "かれ は ゆうしゅう な ぶか を もって いる わ ね",
    "exTranslation": "He has outstanding people working for him.",
    "category": "Noun"
  },
  {
    "term": "バランス",
    "termReading": "バランス",
    "termTranslation": "balance",
    "ex": "栄養バランスの良い食事をしよう。",
    "exReading": "えいよう ばらんす の いい しょくじ を しよう",
    "exTranslation": "We should eat well-balanced meals.",
    "category": "Noun"
  },
  {
    "term": "大部分",
    "termReading": "だいぶぶん",
    "termTranslation": "most, greater part",
    "ex": "絵の大部分が水に濡れてしまったな。",
    "exReading": "え の だいぶぶん が みず に ぬれて しまった な",
    "exTranslation": "A large portion of picture got wet.",
    "category": "Noun"
  },
  {
    "term": "大国",
    "termReading": "たいこく",
    "termTranslation": "world power, large country",
    "ex": "その国は経済大国よ。",
    "exReading": "その くに は けいざい たいこく よ",
    "exTranslation": "That country's an economic powerhouse.",
    "category": "Noun"
  },
  {
    "term": "入国",
    "termReading": "にゅうこく",
    "termTranslation": "entry into a country, immigration",
    "ex": "私は留学生として日本に入国しました。",
    "exReading": "わたし は りゅうがくせい として にっぽん に にゅうこく しました",
    "exTranslation": "I entered Japan as an international student.",
    "category": "Verbal Noun"
  },
  {
    "term": "本国",
    "termReading": "ほんごく",
    "termTranslation": "one's native country",
    "ex": "彼女は本国に帰りました。",
    "exReading": "かのじょ は ほんごく に かえりました",
    "exTranslation": "She returned to her native land.",
    "category": "Noun"
  },
  {
    "term": "天国",
    "termReading": "てんごく",
    "termTranslation": "paradise",
    "ex": "死んだら天国に行きたいです。",
    "exReading": "しんだら てんごく に いきたい です",
    "exTranslation": "I want to go to heaven when I die.",
    "category": "Noun"
  },
  {
    "term": "どうしても",
    "termReading": "どうしても",
    "termTranslation": "by any means, really",
    "ex": "どうしてもその訳を知りたい。",
    "exReading": "どうしても その わけ を しりたい",
    "exTranslation": "I really want to know about the circumstances.",
    "category": "Adverb"
  },
  {
    "term": "世の中",
    "termReading": "よのなか",
    "termTranslation": "the world, society",
    "ex": "世の中にはいろいろな人がいます。",
    "exReading": "よのなか に は いろいろ な ひと が います",
    "exTranslation": "There are many different people in the world.",
    "category": "Noun"
  },
  {
    "term": "中世",
    "termReading": "ちゅうせい",
    "termTranslation": "Middle Ages",
    "ex": "彼女は中世の音楽が好きです。",
    "exReading": "かのじょ は ちゅうせい の おんがく が すき です",
    "exTranslation": "She likes medieval music.",
    "category": "Noun"
  },
  {
    "term": "世",
    "termReading": "よ",
    "termTranslation": "world, public,",
    "ex": "やっと私の作品が世に出たの。",
    "exReading": "やっと わたし の さくひん が よ に でた の",
    "exTranslation": "My work was finally released to the world.",
    "category": "Noun"
  },
  {
    "term": "明白",
    "termReading": "めいはく",
    "termTranslation": "clear, plain",
    "ex": "彼が犯人なのは明白です。",
    "exReading": "かれ が はんにん な の は めいはく です",
    "exTranslation": "It's clear that he's the perpetrator.",
    "category": "Adjectival Noun"
  },
  {
    "term": "鉄道",
    "termReading": "てつどう",
    "termTranslation": "railway",
    "ex": "日本は鉄道がとても発達しています。",
    "exReading": "にっぽん は てつどう が とても はったつ して います",
    "exTranslation": "The railway system in Japan is well developed.",
    "category": "Noun"
  },
  {
    "term": "どうか",
    "termReading": "どうか",
    "termTranslation": "please",
    "ex": "どうかお許しください。",
    "exReading": "どうか おゆるし ください",
    "exTranslation": "Please forgive me.",
    "category": "Adverb"
  },
  {
    "term": "鉄",
    "termReading": "てつ",
    "termTranslation": "iron",
    "ex": "この鍋は鉄でできています。",
    "exReading": "この なべ は てつ で できて います",
    "exTranslation": "This pot is made of iron.",
    "category": "Noun"
  },
  {
    "term": "肉体",
    "termReading": "にくたい",
    "termTranslation": "body, flesh",
    "ex": "肉体はいつか滅びます。",
    "exReading": "にくたい は いつか ほろびます",
    "exTranslation": "Our bodies must perish at some time or another.",
    "category": "Noun"
  },
  {
    "term": "白菜",
    "termReading": "はくさい",
    "termTranslation": "nappa cabbage",
    "ex": "彼女は白菜の漬物が好きです。",
    "exReading": "かのじょ は はくさい の つけもの が すき です",
    "exTranslation": "She likes pickled nappa cabbage.",
    "category": "Noun"
  },
  {
    "term": "日米",
    "termReading": "にちべい",
    "termTranslation": "Japan and U.S.",
    "ex": "テレビで日米野球をやっていますよ。",
    "exReading": "てれび で にちべい やきゅう を やって います よ",
    "exTranslation": "A Japan vs. U.S. baseball game is being shown on television.",
    "category": "Noun"
  },
  {
    "term": "味方",
    "termReading": "みかた",
    "termTranslation": "friend, ally",
    "ex": "母はいつも私の味方です。",
    "exReading": "はは は いつも わたし の みかた です",
    "exTranslation": "My mother always looks out for me.",
    "category": "Noun"
  },
  {
    "term": "年末",
    "termReading": "ねんまつ",
    "termTranslation": "end of year",
    "ex": "年末のセールはいつも込んでいるね。",
    "exReading": "ねんまつ の せーる は いつも こんで いる ね",
    "exTranslation": "The year-end sale is always crowded.",
    "category": "Noun"
  },
  {
    "term": "プロ",
    "termReading": "プロ",
    "termTranslation": "pro, professional",
    "ex": "彼はスケートのプロです。",
    "exReading": "かれ は すけーと の ぷろ です",
    "exTranslation": "He's a professional skater.",
    "category": "Noun"
  },
  {
    "term": "末",
    "termReading": "まつ",
    "termTranslation": "last part, end",
    "ex": "今月末にカナダに行きます。",
    "exReading": "こんげつ まつ に かなだ に いきます",
    "exTranslation": "I will go to Canada at the end of this month.",
    "category": "Noun"
  },
  {
    "term": "料金",
    "termReading": "りょうきん",
    "termTranslation": "charge, fee",
    "ex": "まだ料金は払っていないけど。",
    "exReading": "まだ りょうきん は はらって いない けど",
    "exTranslation": "But I haven't paid the charge yet.",
    "category": "Noun"
  },
  {
    "term": "地理",
    "termReading": "ちり",
    "termTranslation": "geography",
    "ex": "彼は地理に詳しいの。",
    "exReading": "かれ は ちり に くわしい の",
    "exTranslation": "He's knowledgeable in geography.",
    "category": "Noun"
  },
  {
    "term": "理解",
    "termReading": "りかい",
    "termTranslation": "understanding, comprehension",
    "ex": "理解してくれてありがとう。",
    "exReading": "りかい して くれて ありがとう",
    "exTranslation": "Thank you for understanding.",
    "category": "Verbal Noun"
  },
  {
    "term": "解く",
    "termReading": "とく",
    "termTranslation": "solve",
    "ex": "この問題を解けますか。",
    "exReading": "この もんだい を とけます か",
    "exTranslation": "Can you solve this question?",
    "category": "Verb"
  },
  {
    "term": "チャンス",
    "termReading": "チャンス",
    "termTranslation": "chance",
    "ex": "これは素晴らしいチャンスだ。",
    "exReading": "これ は すばらしい ちゃんす だ",
    "exTranslation": "This is a wonderful chance.",
    "category": "Noun"
  },
  {
    "term": "分解",
    "termReading": "ぶんかい",
    "termTranslation": "taking apart",
    "ex": "機械を分解してみたの。",
    "exReading": "きかい を ぶんかい して みた の",
    "exTranslation": "I tried to take the machine apart.",
    "category": "Verbal Noun"
  },
  {
    "term": "解ける",
    "termReading": "とける",
    "termTranslation": "solve, resolve",
    "ex": "やっと難しい問題が解けました。",
    "exReading": "やっと むずかしい もんだい が とけました",
    "exTranslation": "We finally solved a difficult problem.",
    "category": "Verb"
  },
  {
    "term": "有力",
    "termReading": "ゆうりょく",
    "termTranslation": "powerful, influential",
    "ex": "あの都市はオリンピックの有力な候補地です。",
    "exReading": "あの とし は おりんぴっく の ゆうりょく な こうほち です",
    "exTranslation": "That city is a strong candidate to host the Olympics.",
    "category": "Adjectival Noun"
  },
  {
    "term": "有する",
    "termReading": "ゆうする",
    "termTranslation": "have, possess",
    "ex": "資格を有する人のみ応募できます。",
    "exReading": "しかく を ゆうする ひと のみ おうぼ できます",
    "exTranslation": "Only qualified individuals may apply.",
    "category": "Verb"
  },
  {
    "term": "有料",
    "termReading": "ゆうりょう",
    "termTranslation": "fee-charging, pay",
    "ex": "このトイレは有料です。",
    "exReading": "この といれ は ゆうりょう です",
    "exTranslation": "This is a pay toilet.",
    "category": "Noun"
  },
  {
    "term": "どうも",
    "termReading": "どうも",
    "termTranslation": "for some reason, somehow",
    "ex": "今日はどうも体の調子が悪い。",
    "exReading": "きょう は どうも からだ の ちょうし が わるい",
    "exTranslation": "I don't feel very well today for some reason.",
    "category": "Adverb"
  },
  {
    "term": "無理",
    "termReading": "むり",
    "termTranslation": "impossibility",
    "ex": "無理はしないでください。",
    "exReading": "むり は しない で ください",
    "exTranslation": "Don't overdo it.",
    "category": "Noun"
  },
  {
    "term": "無料",
    "termReading": "むりょう",
    "termTranslation": "no charge, free",
    "ex": "お飲み物は無料でございます。",
    "exReading": "お のみもの は むりょう で ございます",
    "exTranslation": "Beverages are free of charge.",
    "category": "Noun"
  },
  {
    "term": "無事",
    "termReading": "ぶじ",
    "termTranslation": "safely, peacefully",
    "ex": "無事、家に着きました。",
    "exReading": "ぶじ いえ に つきました",
    "exTranslation": "I arrived home safely.",
    "category": "Adverb"
  },
  {
    "term": "無口",
    "termReading": "むくち",
    "termTranslation": "not speaking much, reticence",
    "ex": "彼女は無口な人ですね。",
    "exReading": "かのじょ は むくち な ひと です ね",
    "exTranslation": "She's a silent person.",
    "category": "Adjectival Noun"
  },
  {
    "term": "間も無く",
    "termReading": "まもなく",
    "termTranslation": "soon, in a short time",
    "ex": "あの飛行機は間も無く着陸しますね。",
    "exReading": "あの ひこうき は まもなく ちゃくりく します ね",
    "exTranslation": "That airplane will land soon.",
    "category": "Adverb"
  },
  {
    "term": "無言",
    "termReading": "むごん",
    "termTranslation": "silence, muteness",
    "ex": "彼は一日中無言だったな。",
    "exReading": "かれ は いちにちじゅう むごん だった な",
    "exTranslation": "He was silent all day long.",
    "category": "Noun"
  },
  {
    "term": "ハード",
    "termReading": "ハード",
    "termTranslation": "hard",
    "ex": "最近、仕事がかなりハードです。",
    "exReading": "さいきん しごと が かなり はーど です",
    "exTranslation": "Recently, my work is pretty hard.",
    "category": "Adjective"
  },
  {
    "term": "無茶",
    "termReading": "むちゃ",
    "termTranslation": "reckless, excessive",
    "ex": "無茶をしないでくださいね。",
    "exReading": "むちゃ を しない で ください ね",
    "exTranslation": "Don't be unreasonable.",
    "category": "Adjectival Noun"
  },
  {
    "term": "無理やり",
    "termReading": "むりやり",
    "termTranslation": "by force, against one's will",
    "ex": "荷物を無理やりかばんに詰めたよ。",
    "exReading": "にもつ を むりやり かばん に つめた よ",
    "exTranslation": "I forced my belongings into the bag.",
    "category": "Adverb"
  },
  {
    "term": "作り上げる",
    "termReading": "つくりあげる",
    "termTranslation": "make up, build up",
    "ex": "それは彼女が作り上げた話です。",
    "exReading": "それ は かのじょ が つくりあげた はなし です",
    "exTranslation": "It's a story that she made up.",
    "category": "Verb"
  },
  {
    "term": "作り話",
    "termReading": "つくりばなし",
    "termTranslation": "fable, fiction",
    "ex": "その子が言っていることは作り話です。",
    "exReading": "その こ が いって いる こと は つくりばなし です",
    "exTranslation": "What that kid said is a made-up story.",
    "category": "Noun"
  },
  {
    "term": "大使",
    "termReading": "たいし",
    "termTranslation": "ambassador",
    "ex": "彼は昔、ドイツの大使でした。",
    "exReading": "かれ は むかし どいつ の たいし でした",
    "exTranslation": "He was ambassador to Germany a long time ago.",
    "category": "Noun"
  },
  {
    "term": "マイナス",
    "termReading": "マイナス",
    "termTranslation": "minus, below zero",
    "ex": "外の温度はマイナス3度です。",
    "exReading": "そと の おんど は まいなす 3 ど です",
    "exTranslation": "The temperature outside is minus three degrees.",
    "category": "Verbal Noun"
  },
  {
    "term": "使い道",
    "termReading": "つかいみち",
    "termTranslation": "use, application",
    "ex": "ボーナスの使い道を考えているところです。",
    "exReading": "ぼーなす の つかいみち を かんがえて いる ところ です",
    "exTranslation": "I'm just thinking about how to use my bonus.",
    "category": "Noun"
  },
  {
    "term": "使い",
    "termReading": "つかい",
    "termTranslation": "errand",
    "ex": "主人の使いで市役所に行くところです。",
    "exReading": "しゅじん の つかい で しやくしょ に いく ところ です",
    "exTranslation": "I'm about to go to city hall on an errand for my husband.",
    "category": "Noun"
  },
  {
    "term": "用",
    "termReading": "よう",
    "termTranslation": "things to do",
    "ex": "私に何か用ですか。",
    "exReading": "わたし に なにか よう です か",
    "exTranslation": "Do you need me for something?",
    "category": "Noun"
  },
  {
    "term": "無用",
    "termReading": "むよう",
    "termTranslation": "useless, unnecessary",
    "ex": "心配は無用です。",
    "exReading": "しんぱい は むよう です",
    "exTranslation": "There's no need to worry.",
    "category": "Adjectival Noun"
  },
  {
    "term": "費用",
    "termReading": "ひよう",
    "termTranslation": "expenses",
    "ex": "イタリア旅行の費用は30万円です。",
    "exReading": "いたりあ りょこう の ひよう は 30 まんえん です",
    "exTranslation": "The cost of the trip to Italy is 300,000 yen.",
    "category": "Noun"
  },
  {
    "term": "とにかく",
    "termReading": "とにかく",
    "termTranslation": "in any case, anyway",
    "ex": "とにかく現場へ行ってみましょう。",
    "exReading": "とにかく げんば へ いって みましょう",
    "exTranslation": "Anyway, let's go to the site.",
    "category": "Adverb"
  },
  {
    "term": "費やす",
    "termReading": "ついやす",
    "termTranslation": "expend, waste",
    "ex": "私は語学の勉強にかなりの時間を費やしています。",
    "exReading": "わたし は ごがく の べんきょう に かなり の じかん を ついやして います",
    "exTranslation": "I have spent considerable time on language study.",
    "category": "Verb"
  },
  {
    "term": "安売り",
    "termReading": "やすうり",
    "termTranslation": "sale",
    "ex": "あの店で野菜の安売りをしていましたよ。",
    "exReading": "あの みせ で やさい の やすうり を して いました よ",
    "exTranslation": "Vegetables were on special at that store.",
    "category": "Verbal Noun"
  },
  {
    "term": "売買",
    "termReading": "ばいばい",
    "termTranslation": "buying and selling, trade",
    "ex": "彼は不動産の売買をしています。",
    "exReading": "かれ は ふどうさん の ばいばい を して います",
    "exTranslation": "He buys and sells real estate.",
    "category": "Verbal Noun"
  },
  {
    "term": "閉店",
    "termReading": "へいてん",
    "termTranslation": "shop closing",
    "ex": "この店は8時に閉店します。",
    "exReading": "この みせ は 8 じ に へいてん します",
    "exTranslation": "This shop closes at eight o'clock.",
    "category": "Verbal Noun"
  },
  {
    "term": "本店",
    "termReading": "ほんてん",
    "termTranslation": "head office",
    "ex": "ここはチェーン店の本店です。",
    "exReading": "ここ は ちぇーんてん の ほんてん です",
    "exTranslation": "This is the headquarters of the chain.",
    "category": "Noun"
  },
  {
    "term": "パイプ",
    "termReading": "パイプ",
    "termTranslation": "pipe",
    "ex": "このパイプは詰まっていますよ。",
    "exReading": "この ぱいぷ は つまって います よ",
    "exTranslation": "This pipe is blocked.",
    "category": "Noun"
  },
  {
    "term": "部品",
    "termReading": "ぶひん",
    "termTranslation": "parts, accessories",
    "ex": "車の部品を取り替えたんだ。",
    "exReading": "くるま の ぶひん を とりかえた ん だ",
    "exTranslation": "I changed some parts of the car.",
    "category": "Noun"
  },
  {
    "term": "日用品",
    "termReading": "にちようひん",
    "termTranslation": "groceries, daily necessities",
    "ex": "今日は日用品の買い物をした。",
    "exReading": "きょう は にちようひん の かいもの を した",
    "exTranslation": "I did grocery shopping today.",
    "category": "Noun"
  },
  {
    "term": "段",
    "termReading": "だん",
    "termTranslation": "step, grade",
    "ex": "この階段は18段ありますね。",
    "exReading": "この かいだん は 18 だん あります ね",
    "exTranslation": "There are 18 steps in this staircase.",
    "category": "Noun"
  },
  {
    "term": "値上がり",
    "termReading": "ねあがり",
    "termTranslation": "price rise",
    "ex": "野菜が値上がりしていますね。",
    "exReading": "やさい が ねあがり して います ね",
    "exTranslation": "The price of vegetables is going up.",
    "category": "Verbal Noun"
  },
  {
    "term": "値上げ",
    "termReading": "ねあげ",
    "termTranslation": "price hike",
    "ex": "バス代が値上げされました。",
    "exReading": "ばすだい が ねあげ されました",
    "exTranslation": "Bus fares were raised.",
    "category": "Verbal Noun"
  },
  {
    "term": "値下がり",
    "termReading": "ねさがり",
    "termTranslation": "fall in price",
    "ex": "ガソリンが値下がりしました。",
    "exReading": "がそりん が ねさがり しました",
    "exTranslation": "The price of gasoline has gone down.",
    "category": "Verbal Noun"
  },
  {
    "term": "たちまち",
    "termReading": "たちまち",
    "termTranslation": "in a moment, instantly",
    "ex": "空がたちまち曇ってきたね。",
    "exReading": "そら が たちまち くもって きた ね",
    "exTranslation": "The sky suddenly clouded over.",
    "category": "Adverb"
  },
  {
    "term": "値下げ",
    "termReading": "ねさげ",
    "termTranslation": "price reduction",
    "ex": "電話料金が値下げされた。",
    "exReading": "でんわ りょうきん が ねさげ された",
    "exTranslation": "My phone charge was reduced.",
    "category": "Verbal Noun"
  },
  {
    "term": "地価",
    "termReading": "ちか",
    "termTranslation": "price of land",
    "ex": "東京の地価は上がり続けているんだ。",
    "exReading": "とうきょう の ちか は あがりつづけて いる ん だ",
    "exTranslation": "The price of land in Tokyo keeps going up.",
    "category": "Noun"
  },
  {
    "term": "体格",
    "termReading": "たいかく",
    "termTranslation": "physique, build",
    "ex": "彼はとても体格がいいわ。",
    "exReading": "かれ は とても たいかく が いい わ",
    "exTranslation": "He has a very nice body.",
    "category": "Noun"
  },
  {
    "term": "冬季",
    "termReading": "とうき",
    "termTranslation": "winter season",
    "ex": "次の冬季オリンピックは2年後です。",
    "exReading": "つぎ の とうき おりんぴっく は 2 ねんご です",
    "exTranslation": "The next Winter Olympics will be in two years.",
    "category": "Noun"
  },
  {
    "term": "熱する",
    "termReading": "ねっする",
    "termTranslation": "heat, become hot",
    "ex": "鉄は熱すると曲がるんだ。",
    "exReading": "てつ は ねっする と まがる ん だ",
    "exTranslation": "Iron bends if you heat it.",
    "category": "Verb"
  },
  {
    "term": "プラス",
    "termReading": "プラス",
    "termTranslation": "plus, advantage",
    "ex": "この経験はあなたにとってプラスになるでしょう。",
    "exReading": "この けいけん は あなた に とって ぷらす に なる でしょう",
    "exTranslation": "This experience will be a good thing for you.",
    "category": "Verbal Noun"
  },
  {
    "term": "低温",
    "termReading": "ていおん",
    "termTranslation": "low temperature",
    "ex": "この製品は低温で保存してください。",
    "exReading": "この せいひん は ていおん で ほぞん して ください",
    "exTranslation": "Please store this product at a low temperature.",
    "category": "Noun"
  },
  {
    "term": "体温",
    "termReading": "たいおん",
    "termTranslation": "body temperature",
    "ex": "今朝の体温は36度でした。",
    "exReading": "けさ の たいおん は 36 ど でした",
    "exTranslation": "My temperature this morning was 36 degrees.",
    "category": "Noun"
  },
  {
    "term": "友情",
    "termReading": "ゆうじょう",
    "termTranslation": "friendship",
    "ex": "彼らは強い友情で結ばれているな。",
    "exReading": "かれら は つよい ゆうじょう で むすばれて いる な",
    "exTranslation": "They're tied by a strong friendship.",
    "category": "Noun"
  },
  {
    "term": "人情",
    "termReading": "にんじょう",
    "termTranslation": "human feelings, humanity",
    "ex": "この町の人たちには人情があるね。",
    "exReading": "この まち の ひとたち に は にんじょう が ある ね",
    "exTranslation": "The people in this town have humanity.",
    "category": "Noun"
  },
  {
    "term": "情けない",
    "termReading": "なさけない",
    "termTranslation": "pitiful, shameful",
    "ex": "こんなことも知らないとは情けない。",
    "exReading": "こんな こと も しらない と は なさけない",
    "exTranslation": "It's pretty sad you don't even know something like that.",
    "category": "Adjective"
  },
  {
    "term": "リード",
    "termReading": "リード",
    "termTranslation": "the lead, take a lead",
    "ex": "彼は彼女をリードしながら踊ったね。",
    "exReading": "かれ は かのじょ を りーど しながら おどった ね",
    "exTranslation": "He led her in the dance.",
    "category": "Verbal Noun"
  },
  {
    "term": "報道",
    "termReading": "ほうどう",
    "termTranslation": "news, report",
    "ex": "夜中もテレビで台風の報道をしていた。",
    "exReading": "よなか も てれび で たいふう の ほうどう を して いた",
    "exTranslation": "The typhoon was also reported on television during the night.",
    "category": "Verbal Noun"
  },
  {
    "term": "電報",
    "termReading": "でんぽう",
    "termTranslation": "telegram",
    "ex": "実家の母から電報が来ました。",
    "exReading": "じっか の はは から でんぽう が きました",
    "exTranslation": "A telegram came from my mother at home.",
    "category": "Noun"
  },
  {
    "term": "中古",
    "termReading": "ちゅうこ",
    "termTranslation": "secondhand goods",
    "ex": "その車は中古で買ったんだ。",
    "exReading": "その くるま は ちゅうこ で かった ん だ",
    "exTranslation": "I bought that car second hand.",
    "category": "Noun"
  },
  {
    "term": "古本",
    "termReading": "ふるほん",
    "termTranslation": "secondhand book",
    "ex": "おととい古本を3冊買いました。",
    "exReading": "おととい ふるほん を 3 さつ かいました",
    "exTranslation": "I bought three secondhand books the day before yesterday.",
    "category": "Noun"
  },
  {
    "term": "昔",
    "termReading": "むかし",
    "termTranslation": "old times, the old days",
    "ex": "彼は昔は貧乏だった。",
    "exReading": "かれ は むかし は びんぼう だった",
    "exTranslation": "He used to be poor.",
    "category": "Noun"
  },
  {
    "term": "悪口",
    "termReading": "わるくち",
    "termTranslation": "slander, abuse",
    "ex": "彼は決して人の悪口を言わないの。",
    "exReading": "かれ は けっして ひと の わるくち を いわない の",
    "exTranslation": "He never speaks ill of people.",
    "category": "Noun"
  },
  {
    "term": "たとえ",
    "termReading": "たとえ",
    "termTranslation": "even if, granting that",
    "ex": "たとえ、嵐になっても絶対に行く。",
    "exReading": "たとえ あらし に なって も ぜったい に いく",
    "exTranslation": "I'll absolutely go even if the storm comes.",
    "category": "Adverb"
  },
  {
    "term": "熱心",
    "termReading": "ねっしん",
    "termTranslation": "enthusiasm, zeal",
    "ex": "彼女は4年間熱心に勉強したわ。",
    "exReading": "かのじょ は 4 ねんかん ねっしん に べんきょう した わ",
    "exTranslation": "She studied hard for four years.",
    "category": "Adjectival Noun"
  },
  {
    "term": "良心",
    "termReading": "りょうしん",
    "termTranslation": "conscience",
    "ex": "私は良心に従って行動します。",
    "exReading": "わたし は りょうしん に したがって こうどう します",
    "exTranslation": "I behave according to my conscience.",
    "category": "Noun"
  },
  {
    "term": "内心",
    "termReading": "ないしん",
    "termTranslation": "back of one's mind, one's real intention",
    "ex": "彼女は内心どきどきしていましたよ。",
    "exReading": "かのじょ は ないしん どきどき して いました よ",
    "exTranslation": "Inside, her heart was pounding.",
    "category": "Noun"
  },
  {
    "term": "用心",
    "termReading": "ようじん",
    "termTranslation": "care, caution",
    "ex": "夜道は用心して歩きましょう。",
    "exReading": "よみち は ようじん して あるきましょう",
    "exTranslation": "Be careful when walking at night.",
    "category": "Verbal Noun"
  },
  {
    "term": "忘年会",
    "termReading": "ぼうねんかい",
    "termTranslation": "year-end party",
    "ex": "明日は会社の忘年会があります。",
    "exReading": "あした は かいしゃ の ぼうねんかい が あります",
    "exTranslation": "The company will have a year-end party tomorrow.",
    "category": "Noun"
  },
  {
    "term": "なるべく",
    "termReading": "なるべく",
    "termTranslation": "as...as possible, if possible",
    "ex": "なるべく早く仕事を終わらせてください。",
    "exReading": "なるべく はやく しごと を おわらせて ください",
    "exTranslation": "Please complete your work as early as possible.",
    "category": "Adverb"
  },
  {
    "term": "度忘れ",
    "termReading": "どわすれ",
    "termTranslation": "memory lapse, mental block",
    "ex": "彼の名前を度忘れしたぞ。",
    "exReading": "かれ の なまえ を どわすれ した ぞ",
    "exTranslation": "His name has completely slipped from my memory.",
    "category": "Verbal Noun"
  },
  {
    "term": "知事",
    "termReading": "ちじ",
    "termTranslation": "governor",
    "ex": "彼は有能な知事ね。",
    "exReading": "かれ は ゆうのう な ちじ ね",
    "exTranslation": "He's a capable governor.",
    "category": "Noun"
  },
  {
    "term": "未知",
    "termReading": "みち",
    "termTranslation": "unknown",
    "ex": "ここからは未知の領域です。",
    "exReading": "ここ から は みち の りょういき です",
    "exTranslation": "It's an unknown area from here on.",
    "category": "Noun"
  },
  {
    "term": "通知",
    "termReading": "つうち",
    "termTranslation": "notification",
    "ex": "明日、詳細を通知します。",
    "exReading": "あす、 しょうさい を つうち します",
    "exTranslation": "I'll notify you of the details tomorrow.",
    "category": "Verbal Noun"
  },
  {
    "term": "無知",
    "termReading": "むち",
    "termTranslation": "ignorance",
    "ex": "彼女は政治について無知でした。",
    "exReading": "かのじょ は せいじ に ついて むち でした",
    "exTranslation": "She was ignorant about politics.",
    "category": "Adjectival Noun"
  },
  {
    "term": "テーマ",
    "termReading": "テーマ",
    "termTranslation": "the theme, the subject",
    "ex": "講演のテーマは何ですか。",
    "exReading": "こうえん の てーま は なん です か",
    "exTranslation": "What's the subject of the lecture?",
    "category": "Noun"
  },
  {
    "term": "知人",
    "termReading": "ちじん",
    "termTranslation": "acquaintance, friend",
    "ex": "彼は昔からの知人です。",
    "exReading": "かれ は むかし から の ちじん です",
    "exTranslation": "He's an old acquaintance.",
    "category": "Noun"
  },
  {
    "term": "天才",
    "termReading": "てんさい",
    "termTranslation": "genius",
    "ex": "彼は笑いの天才だね。",
    "exReading": "かれ は わらい の てんさい だ ね",
    "exTranslation": "He's a comic genius.",
    "category": "Noun"
  },
  {
    "term": "本能",
    "termReading": "ほんのう",
    "termTranslation": "instinct",
    "ex": "動物は本能のまま動くね。",
    "exReading": "どうぶつ は ほんのう の まま うごく ね",
    "exTranslation": "Animals act purely on instinct.",
    "category": "Noun"
  },
  {
    "term": "有能",
    "termReading": "ゆうのう",
    "termTranslation": "able, competent",
    "ex": "彼女はとても有能な部下です。",
    "exReading": "かのじょ は とても ゆうのう な ぶか です",
    "exTranslation": "She's a very capable subordinate.",
    "category": "Adjectival Noun"
  },
  {
    "term": "知能",
    "termReading": "ちのう",
    "termTranslation": "intelligence, mental capacity",
    "ex": "あの子の知能はとても高いそうです。",
    "exReading": "あの こ の ちのう は とても たかい そう です",
    "exTranslation": "Apparently, that child is highly intelligent.",
    "category": "Noun"
  },
  {
    "term": "無能",
    "termReading": "むのう",
    "termTranslation": "inefficiency, incompetence",
    "ex": "彼は無能だ。",
    "exReading": "かれ は むのう だ",
    "exTranslation": "He's incapable.",
    "category": "Adjectival Noun"
  },
  {
    "term": "ムード",
    "termReading": "ムード",
    "termTranslation": "mood",
    "ex": "部屋を暗くしてムードを出してみたよ。",
    "exReading": "へや を くらく して むーど を だして みた よ",
    "exTranslation": "I darkened the room to set the mood.",
    "category": "Noun"
  },
  {
    "term": "能",
    "termReading": "のう",
    "termTranslation": "noh play",
    "ex": "先日、初めて能を見に行きました。",
    "exReading": "せんじつ はじめて のう を み に いきました",
    "exTranslation": "The other day, I went to see Noh for the first time.",
    "category": "Noun"
  },
  {
    "term": "能",
    "termReading": "のう",
    "termTranslation": "ability, capability",
    "ex": "彼は勉強するしか能のない人間だね。",
    "exReading": "かれ は べんきょう する しか のう の ない にんげん だ ね",
    "exTranslation": "He's the kind of person who only knows how to study.",
    "category": "Noun"
  },
  {
    "term": "不安",
    "termReading": "ふあん",
    "termTranslation": "uneasiness, anxiety",
    "ex": "明日、病院に検査に行くので少し不安です。",
    "exReading": "あす びょういん に けんさ に いく の で すこし ふあん です",
    "exTranslation": "I'm a bit worried since I have to go to the hospital for tests tomorrow.",
    "category": "Adjectival Noun"
  },
  {
    "term": "不満",
    "termReading": "ふまん",
    "termTranslation": "dissatisfaction, discontent",
    "ex": "私は彼のやり方には不満です。",
    "exReading": "わたし は かれ の やりかた に は ふまん です",
    "exTranslation": "I'm dissatisfied with his way of doing things.",
    "category": "Adjectival Noun"
  },
  {
    "term": "不足",
    "termReading": "ふそく",
    "termTranslation": "insufficiency, lack",
    "ex": "今年はひどい水不足よ。",
    "exReading": "ことし は ひどい みずぶそく よ",
    "exTranslation": "There's a severe drought this year.",
    "category": "Verbal Noun"
  },
  {
    "term": "どく",
    "termReading": "どく",
    "termTranslation": "move out of the way",
    "ex": "そこをどいてください。",
    "exReading": "そこをどいてください。",
    "exTranslation": "Please move out the way, there.",
    "category": "Verb"
  },
  {
    "term": "不可能",
    "termReading": "ふかのう",
    "termTranslation": "impossible",
    "ex": "レポートを1日で仕上げるのは不可能です。",
    "exReading": "れぽーと を 1 にち で しあげる の は ふかのう です",
    "exTranslation": "It's impossible to finish up the report in a day.",
    "category": "Adjectival Noun"
  },
  {
    "term": "不十分",
    "termReading": "ふじゅうぶん",
    "termTranslation": "insufficiency, lack",
    "ex": "その程度の努力では不十分です。",
    "exReading": "その ていど の どりょく で は ふじゅうぶん です",
    "exTranslation": "That amount of effort is insufficient.",
    "category": "Adjectival Noun"
  },
  {
    "term": "不明",
    "termReading": "ふめい",
    "termTranslation": "unknown",
    "ex": "その病気は原因不明と言われているんだよ。",
    "exReading": "その びょうき は げんいん ふめい と いわれている ん だ よ",
    "exTranslation": "The cause of this illness is said to be unknown.",
    "category": "Adjectival Noun"
  },
  {
    "term": "不良",
    "termReading": "ふりょう",
    "termTranslation": "bad, defective",
    "ex": "不良品を返品したよ。",
    "exReading": "ふりょうひん を へんぴん した よ",
    "exTranslation": "I returned the defective product.",
    "category": "Adjectival Noun"
  },
  {
    "term": "不合格",
    "termReading": "ふごうかく",
    "termTranslation": "failure, disqualification",
    "ex": "残念ながら試験は不合格でした。",
    "exReading": "ざんねん ながら しけん は ふごうかく でした",
    "exTranslation": "To our regret, he failed the examination.",
    "category": "Noun"
  },
  {
    "term": "マーケット",
    "termReading": "マーケット",
    "termTranslation": "market",
    "ex": "彼はイタリアのマーケットを開拓しました。",
    "exReading": "かれ は いたりあ の まーけっと を かいたく しました",
    "exTranslation": "He has broken into the Italian market.",
    "category": "Noun"
  },
  {
    "term": "不通",
    "termReading": "ふつう",
    "termTranslation": "interruption of service",
    "ex": "今朝、停電で電車が不通になったよ。",
    "exReading": "けさ ていでん で でんしゃ が ふつう に なった よ",
    "exTranslation": "The train stopped because of a power failure this morning.",
    "category": "Noun"
  },
  {
    "term": "便り",
    "termReading": "たより",
    "termTranslation": "news, correspondence",
    "ex": "月に一度母から便りが来ます。",
    "exReading": "つき に いちど はは から たより が きます",
    "exTranslation": "I get a letter from my mother once a month.",
    "category": "Noun"
  },
  {
    "term": "大便",
    "termReading": "だいべん",
    "termTranslation": "stool, feces",
    "ex": "病院で大便の検査をした。",
    "exReading": "びょういん で だいべん の けんさ を した",
    "exTranslation": "I had a stool examination at the hospital.",
    "category": "Verbal Noun"
  },
  {
    "term": "番",
    "termReading": "ばん",
    "termTranslation": "one's turn, watch",
    "ex": "今日は私が皿を洗う番ですね。",
    "exReading": "きょう は わたし が さら を あらう ばん です ね",
    "exTranslation": "It's my turn to do dishes today.",
    "category": "Noun"
  },
  {
    "term": "長所",
    "termReading": "ちょうしょ",
    "termTranslation": "strong point, merit",
    "ex": "君の長所は明るいところだね。",
    "exReading": "きみ の ちょうしょ は あかるい ところ だ ね",
    "exTranslation": "Your strong point is your cheerfulness.",
    "category": "Noun"
  },
  {
    "term": "ファッション",
    "termReading": "ファッション",
    "termTranslation": "fashion",
    "ex": "彼女はファッションの専門家だ。",
    "exReading": "かのじょ は ふぁっしょん の せんもんか だ",
    "exTranslation": "She's a fashion expert.",
    "category": "Noun"
  },
  {
    "term": "短所",
    "termReading": "たんしょ",
    "termTranslation": "shortcoming, defect",
    "ex": "すぐにあきらめてしまうのが彼の短所です。",
    "exReading": "すぐ に あきらめて しまう の が かれ の たんしょ です",
    "exTranslation": "His weak point is that he gives up too easily.",
    "category": "Noun"
  },
  {
    "term": "所々",
    "termReading": "ところどころ",
    "termTranslation": "here and there, at places",
    "ex": "この本はページが所々破れているね。",
    "exReading": "この ほん は ぺーじ が ところどころ やぶれて いる ね",
    "exTranslation": "The pages are torn here and there in this book.",
    "category": "Noun"
  },
  {
    "term": "名",
    "termReading": "な",
    "termTranslation": "name, reputation",
    "ex": "彼は名の通った会社に就職しました。",
    "exReading": "かれ は な の とおった かいしゃ に しゅうしょく しました",
    "exTranslation": "He found employment in a well-known company.",
    "category": "Noun"
  },
  {
    "term": "名人",
    "termReading": "めいじん",
    "termTranslation": "master, master hand",
    "ex": "彼は釣りの名人です。",
    "exReading": "かれ は つり の めいじん です",
    "exTranslation": "He's an expert angler.",
    "category": "Noun"
  },
  {
    "term": "地名",
    "termReading": "ちめい",
    "termTranslation": "place name",
    "ex": "その地名は聞いたことがないなあ。",
    "exReading": "その ちめい は きいた こと が ない なあ",
    "exTranslation": "I have never heard of that place.",
    "category": "Noun"
  },
  {
    "term": "名所",
    "termReading": "めいしょ",
    "termTranslation": "noted place, sights",
    "ex": "ここは桜の名所です。",
    "exReading": "ここ は さくら の めいしょ です",
    "exTranslation": "This place is well-known for cherry blossoms.",
    "category": "Noun"
  },
  {
    "term": "ブレーキ",
    "termReading": "ブレーキ",
    "termTranslation": "brake",
    "ex": "赤信号でブレーキを踏んだの。",
    "exReading": "あかしんごう で ぶれーき を ふんだ の",
    "exTranslation": "I stepped on the brake at the red light.",
    "category": "Noun"
  },
  {
    "term": "町外れ",
    "termReading": "まちはずれ",
    "termTranslation": "outskirts of a town",
    "ex": "彼女は町外れの工場で働いているよ。",
    "exReading": "かのじょ は まちはずれ の こうじょう で はたらいて いる よ",
    "exTranslation": "She's working in a factory on the outskirts of town.",
    "category": "Noun"
  },
  {
    "term": "町中",
    "termReading": "まちなか",
    "termTranslation": "the downtown area, the central area of a town",
    "ex": "町中で彼女に出会ったよ。",
    "exReading": "まちなか で かのじょ に であった よ",
    "exTranslation": "I met her downtown.",
    "category": "Noun"
  },
  {
    "term": "村",
    "termReading": "むら",
    "termTranslation": "village, hamlet",
    "ex": "私は隣の村から来ました。",
    "exReading": "わたし は となり の むら から きました",
    "exTranslation": "I come from the next village over.",
    "category": "Noun"
  },
  {
    "term": "都内",
    "termReading": "とない",
    "termTranslation": "in the metropolitan area of Tokyo",
    "ex": "彼の職場は都内にあります。",
    "exReading": "かれ の しょくば は とない に あります",
    "exTranslation": "His office is in the Tokyo metropolitan area.",
    "category": "Noun"
  },
  {
    "term": "都心",
    "termReading": "としん",
    "termTranslation": "heart of a city",
    "ex": "彼は都心に住んでいます。",
    "exReading": "かれ は としん に すんで います",
    "exTranslation": "He lives downtown.",
    "category": "Noun"
  },
  {
    "term": "ミス",
    "termReading": "ミス",
    "termTranslation": "mistake",
    "ex": "ミスは誰にでもあります。",
    "exReading": "みす は だれ に で も あります",
    "exTranslation": "Everyone makes mistakes.",
    "category": "Verbal Noun"
  },
  {
    "term": "都",
    "termReading": "と",
    "termTranslation": "metropolis of Tokyo",
    "ex": "彼女は都の職員です。",
    "exReading": "かのじょ は と の しょくいん です",
    "exTranslation": "She's an official of the metropolitan government.",
    "category": "Noun"
  },
  {
    "term": "都会",
    "termReading": "とかい",
    "termTranslation": "city, town",
    "ex": "彼は都会での生活を楽しんでいるの。",
    "exReading": "かれ は とかい で の せいかつ を たのしんで いる の",
    "exTranslation": "He's enjoying city life.",
    "category": "Noun"
  },
  {
    "term": "都",
    "termReading": "みやこ",
    "termTranslation": "capital, city",
    "ex": "ミラノはファッションの都です。",
    "exReading": "みらの は ふぁっしょん の みやこ です",
    "exTranslation": "Milan is the capital of fashion.",
    "category": "Noun"
  },
  {
    "term": "様子",
    "termReading": "ようす",
    "termTranslation": "appearance, aspect",
    "ex": "彼女の様子を見てきます。",
    "exReading": "かのじょ の ようす を みて きます",
    "exTranslation": "I'm going to see how she's doing.",
    "category": "Noun"
  },
  {
    "term": "物価",
    "termReading": "ぶっか",
    "termTranslation": "prices",
    "ex": "この国の物価はここ10年でだいぶ上がったね。",
    "exReading": "この くに の ぶっか は ここ 10 ねん で だいぶ あがった ね",
    "exTranslation": "Prices in this country have gone up considerably these last ten years.",
    "category": "Noun"
  },
  {
    "term": "ヨット",
    "termReading": "ヨット",
    "termTranslation": "sailboat, yacht",
    "ex": "ヨットでクルージングを楽しみましたわ。",
    "exReading": "よっと で くるーじんぐ を たのしみました わ",
    "exTranslation": "I enjoyed cruising on the yacht.",
    "category": "Noun"
  },
  {
    "term": "物体",
    "termReading": "ぶったい",
    "termTranslation": "object, physical body",
    "ex": "謎の物体が空を飛んでいます。",
    "exReading": "なぞ の ぶったい が そら を とんで います",
    "exTranslation": "A mysterious object is flying in the sky.",
    "category": "Noun"
  },
  {
    "term": "本物",
    "termReading": "ほんもの",
    "termTranslation": "real thing, genuine article",
    "ex": "これは本物のダイヤモンドです。",
    "exReading": "これ は ほんもの の だいやもんど です",
    "exTranslation": "This is a real diamond.",
    "category": "Noun"
  },
  {
    "term": "物理",
    "termReading": "ぶつり",
    "termTranslation": "physics, natural law",
    "ex": "彼は物理を専攻している。",
    "exReading": "かれ は ぶつり を せんこう して いる",
    "exTranslation": "He majors in physics.",
    "category": "Noun"
  },
  {
    "term": "物事",
    "termReading": "ものごと",
    "termTranslation": "things, everything",
    "ex": "彼はいつも物事を深く考えるの。",
    "exReading": "かれ は いつも ものごと を ふかく かんがえる の",
    "exTranslation": "He always thinks deeply about things.",
    "category": "Noun"
  },
  {
    "term": "名物",
    "termReading": "めいぶつ",
    "termTranslation": "specialty, noted product",
    "ex": "この町の名物はぶどうです。",
    "exReading": "この まち の めいぶつ は ぶどう です",
    "exTranslation": "This town is well-known for its grapes.",
    "category": "Noun"
  },
  {
    "term": "物知り",
    "termReading": "ものしり",
    "termTranslation": "knowledgeable person, know-it-all",
    "ex": "あの老人はとても物知りだね。",
    "exReading": "あの ろうじん は とても ものしり だ ね",
    "exTranslation": "That old person is very knowledgeable.",
    "category": "Noun"
  },
  {
    "term": "なかなか",
    "termReading": "なかなか",
    "termTranslation": "rather, pretty",
    "ex": "彼女は絵がなかなか上手です。",
    "exReading": "かのじょ は え が なかなか じょうず です",
    "exTranslation": "She's pretty good at painting.",
    "category": "Adverb"
  },
  {
    "term": "物覚え",
    "termReading": "ものおぼえ",
    "termTranslation": "memory",
    "ex": "彼女は物覚えがいい。",
    "exReading": "かのじょ は ものおぼえ が いい",
    "exTranslation": "She learns things quickly.",
    "category": "Noun"
  },
  {
    "term": "体重",
    "termReading": "たいじゅう",
    "termTranslation": "body weight",
    "ex": "私は毎朝体重を測っています。",
    "exReading": "わたし は まいあさ たいじゅう を はかって います",
    "exTranslation": "I weigh myself every morning.",
    "category": "Noun"
  },
  {
    "term": "手軽",
    "termReading": "てがる",
    "termTranslation": "easy, light",
    "ex": "手軽に作れる料理を教えてください。",
    "exReading": "てがる に つくれる りょうり を おしえて ください",
    "exTranslation": "Please teach me some easy-to-do dishes.",
    "category": "Adjective"
  },
  {
    "term": "多量",
    "termReading": "たりょう",
    "termTranslation": "large quantity, great deal",
    "ex": "その事故で多量のガス漏れがあったね。",
    "exReading": "その じこ で たりょう の がすもれ が あった ね",
    "exTranslation": "A large amount of gas leaked because of the accident.",
    "category": "Noun"
  },
  {
    "term": "量る",
    "termReading": "はかる",
    "termTranslation": "measure, weigh",
    "ex": "はかりで小麦粉の分量を量ったよ。",
    "exReading": "はかり で こむぎこ の ぶんりょう を はかった よ",
    "exTranslation": "I measured how much flour there was with a kitchen scale.",
    "category": "Verb"
  },
  {
    "term": "ロック",
    "termReading": "ロック",
    "termTranslation": "rock (music)",
    "ex": "俺はロックを聞くのが好きだ。",
    "exReading": "おれ は ろっく を きく の が すき だ",
    "exTranslation": "I like to listen to rock.",
    "category": "Noun"
  },
  {
    "term": "量",
    "termReading": "りょう",
    "termTranslation": "quantity, volume",
    "ex": "最近、食事の量を減らしています。",
    "exReading": "さいきん しょくじ の りょう を へらして います",
    "exTranslation": "Lately, I'm cutting down how much food I eat.",
    "category": "Noun"
  },
  {
    "term": "大量",
    "termReading": "たいりょう",
    "termTranslation": "large quantity, great volume",
    "ex": "昨日大量のゴミが出たの。",
    "exReading": "きのう たいりょう の ごみ が でた の",
    "exTranslation": "A large amount of garbage was put out yesterday.",
    "category": "Noun"
  },
  {
    "term": "年配",
    "termReading": "ねんぱい",
    "termTranslation": "age, elderly age",
    "ex": "年配の人に席を譲りました。",
    "exReading": "ねんぱい の ひと に せき を ゆずりました",
    "exTranslation": "I gave up my seat to an elderly person.",
    "category": "Noun"
  },
  {
    "term": "送金",
    "termReading": "そうきん",
    "termTranslation": "money transfer, remittance",
    "ex": "取引先への送金を済ませました。",
    "exReading": "とりひきさき へ の そうきん を すませました",
    "exTranslation": "I completed the money transfer to our client.",
    "category": "Verbal Noun"
  },
  {
    "term": "郵送",
    "termReading": "ゆうそう",
    "termTranslation": "mailing",
    "ex": "書類を郵送してください。",
    "exReading": "しょるい を ゆうそう して ください",
    "exTranslation": "Please mail the documents.",
    "category": "Verbal Noun"
  },
  {
    "term": "わざわざ",
    "termReading": "わざわざ",
    "termTranslation": "go to a lot of trouble",
    "ex": "わざわざ来てくれてありがとう。",
    "exReading": "わざわざ きて くれて ありがとう",
    "exTranslation": "Thank you for coming all the way here.",
    "category": "Adverb"
  },
  {
    "term": "取り上げる",
    "termReading": "とりあげる",
    "termTranslation": "take up, confiscate",
    "ex": "危ないので子供からライターを取り上げました。",
    "exReading": "あぶない の で こども から らいたー を とりあげました",
    "exTranslation": "The lighter was taken from the child because it was dangerous.",
    "category": "Verb"
  },
  {
    "term": "取り入れる",
    "termReading": "とりいれる",
    "termTranslation": "take in, adopt",
    "ex": "彼は新しいアイデアをすぐ取り入れます。",
    "exReading": "かれ は あたらしい あいであ を すぐ とりいれます",
    "exTranslation": "He's quick to adopt new ideas.",
    "category": "Verb"
  },
  {
    "term": "取り決め",
    "termReading": "とりきめ",
    "termTranslation": "decision, agreement",
    "ex": "これは会社間の取り決めです。",
    "exReading": "これ は かいしゃかん の とりきめ です",
    "exTranslation": "This is a decision between companies.",
    "category": "Noun"
  },
  {
    "term": "取れる",
    "termReading": "とれる",
    "termTranslation": "be harvested, be cultivated",
    "ex": "このナスは畑で取れたばかりです。",
    "exReading": "この なす は はたけ で とれた ばかり です",
    "exTranslation": "This eggplant has just been picked from the field.",
    "category": "Verb"
  },
  {
    "term": "取り消す",
    "termReading": "とりけす",
    "termTranslation": "cancel, nullify",
    "ex": "ホテルの予約を取り消したよ。",
    "exReading": "ほてる の よやく を とりけした よ",
    "exTranslation": "I canceled our hotel reservation.",
    "category": "Verb"
  },
  {
    "term": "取消し",
    "termReading": "とりけし",
    "termTranslation": "cancellation, annulment",
    "ex": "彼は免許取消しの処分を受けたよ。",
    "exReading": "かれ は めんきょ とりけし の しょぶん を うけた よ",
    "exTranslation": "He had his driver's license revoked.",
    "category": "Verbal Noun"
  },
  {
    "term": "パイロット",
    "termReading": "パイロット",
    "termTranslation": "pilot",
    "ex": "彼はパイロットです。",
    "exReading": "かれ は ぱいろっと です",
    "exTranslation": "He's a pilot.",
    "category": "Noun"
  },
  {
    "term": "取り出す",
    "termReading": "とりだす",
    "termTranslation": "take out, pull out",
    "ex": "彼はポケットから財布を取り出したの。",
    "exReading": "かれ は ぽけっと から さいふ を とりだした の",
    "exTranslation": "He took his wallet out of his pocket.",
    "category": "Verb"
  },
  {
    "term": "届け",
    "termReading": "とどけ",
    "termTranslation": "report, delivery",
    "ex": "郵便局に引っ越しの届けを出したよ。",
    "exReading": "ゆうびんきょく に ひっこし の とどけ を だした よ",
    "exTranslation": "I submitted the change of address notification to the post office.",
    "category": "Noun"
  },
  {
    "term": "待ち合わせ",
    "termReading": "まちあわせ",
    "termTranslation": "waiting, rendez-vous",
    "ex": "明日の待ち合わせは11時です。",
    "exReading": "あす の まちあわせ は 11 じ です",
    "exTranslation": "Tomorrow we'll meet at 11 o'clock.",
    "category": "Verbal Noun"
  },
  {
    "term": "待ち遠しい",
    "termReading": "まちどおしい",
    "termTranslation": "just can't wait for, long for",
    "ex": "入学式が待ち遠しいです。",
    "exReading": "にゅうがくしき が まちどおしい です",
    "exTranslation": "I can't wait for the entrance ceremony.",
    "category": "Adjective"
  },
  {
    "term": "待ち合わせる",
    "termReading": "まちあわせる",
    "termTranslation": "wait for, meet by appointment",
    "ex": "彼と新宿で待ち合わせました。",
    "exReading": "かれ と しんじゅく で まちあわせました",
    "exTranslation": "I met him in Shinjuku.",
    "category": "Verb"
  },
  {
    "term": "どうやら",
    "termReading": "どうやら",
    "termTranslation": "seems, looks like",
    "ex": "明日はどうやら雨らしいよ。",
    "exReading": "あした は どうやら あめ らしい よ",
    "exTranslation": "It looks like it's going to rain tomorrow.",
    "category": "Adverb"
  },
  {
    "term": "持ち物",
    "termReading": "もちもの",
    "termTranslation": "one's property, one's baggage",
    "ex": "持ち物には名前を書いてください。",
    "exReading": "もちもの に は なまえ を かいて ください",
    "exTranslation": "Please write your name on your belongings.",
    "category": "Noun"
  },
  {
    "term": "長持ち",
    "termReading": "ながもち",
    "termTranslation": "endurance, lasting long",
    "ex": "このコートは長持ちしている。",
    "exReading": "この こーと は ながもち して いる",
    "exTranslation": "I've had this coat for a long time.",
    "category": "Verbal Noun"
  },
  {
    "term": "値打ち",
    "termReading": "ねうち",
    "termTranslation": "value, worth",
    "ex": "この絵はとても値打ちがあります。",
    "exReading": "この え は とても ねうち が あります",
    "exTranslation": "This painting is very valuable.",
    "category": "Noun"
  },
  {
    "term": "投書",
    "termReading": "とうしょ",
    "termTranslation": "contribution, letter",
    "ex": "その事件について新聞に投書したんだ。",
    "exReading": "その じけん に ついて しんぶん に とうしょ した ん だ",
    "exTranslation": "I wrote in to the newspaper regarding that incident.",
    "category": "Verbal Noun"
  },
  {
    "term": "役所",
    "termReading": "やくしょ",
    "termTranslation": "public office",
    "ex": "役所で住民票をもらってきた。",
    "exReading": "やくしょ で じゅうみんひょう を もらって きた",
    "exTranslation": "I went to get a resident's card at the public office.",
    "category": "Noun"
  },
  {
    "term": "ふと",
    "termReading": "ふと",
    "termTranslation": "suddenly, unexpectedly",
    "ex": "ふと昔の事を思い出したんだ。",
    "exReading": "ふと むかし の こと を おもいだした ん だ",
    "exTranslation": "I suddenly remembered the past.",
    "category": "Adverb"
  },
  {
    "term": "役人",
    "termReading": "やくにん",
    "termTranslation": "government official",
    "ex": "叔父は役人として30年働きました。",
    "exReading": "おじ は やくにん と して 30 ねん はたらきました",
    "exTranslation": "My uncle worked as a government official for 30 years.",
    "category": "Noun"
  },
  {
    "term": "役目",
    "termReading": "やくめ",
    "termTranslation": "duty, role",
    "ex": "私は無事に役目を終えたよ。",
    "exReading": "わたし は ぶじ に やくめ を おえた よ",
    "exTranslation": "I completed the duties without incident.",
    "category": "Noun"
  },
  {
    "term": "役",
    "termReading": "やく",
    "termTranslation": "role, part",
    "ex": "彼女は弁護士の役を演じているんだ。",
    "exReading": "かのじょ は べんごし の やく を えんじて いる ん だ",
    "exTranslation": "She's playing the part of a lawyer.",
    "category": "Noun"
  },
  {
    "term": "生える",
    "termReading": "はえる",
    "termTranslation": "grow, spring up",
    "ex": "息子に新しい歯が生えました。",
    "exReading": "むすこ に あたらしい は が はえました",
    "exTranslation": "My son has a new tooth coming in.",
    "category": "Verb"
  },
  {
    "term": "長生き",
    "termReading": "ながいき",
    "termTranslation": "long life, longevity",
    "ex": "この村の人たちは長生きです。",
    "exReading": "この むら の ひとたち は ながいき です",
    "exTranslation": "People in this village live a long life.",
    "category": "Verbal Noun"
  },
  {
    "term": "ベテラン",
    "termReading": "ベテラン",
    "termTranslation": "veteran",
    "ex": "彼はベテランの運転手です。",
    "exReading": "かれ は べてらん の うんてんしゅ です",
    "exTranslation": "He's an experienced driver.",
    "category": "Noun"
  },
  {
    "term": "生やす",
    "termReading": "はやす",
    "termTranslation": "grow, cultivate",
    "ex": "彼はヒゲを生やしています。",
    "exReading": "かれ は ひげ を はやして います",
    "exTranslation": "He's growing a beard.",
    "category": "Verb"
  },
  {
    "term": "生",
    "termReading": "なま",
    "termTranslation": "raw, fresh",
    "ex": "彼は生の魚が食べられません。",
    "exReading": "かれ は なま の さかな が たべられません",
    "exTranslation": "He can't eat raw fish.",
    "category": "Adjectival Noun"
  },
  {
    "term": "無生物",
    "termReading": "むせいぶつ",
    "termTranslation": "inanimate object",
    "ex": "岩は無生物に分類される。",
    "exReading": "いわ は むせいぶつ に ぶんるい される",
    "exTranslation": "Rocks are classified as inanimate matter.",
    "category": "Noun"
  },
  {
    "term": "男性",
    "termReading": "だんせい",
    "termTranslation": "male, man",
    "ex": "彼は素敵な男性です。",
    "exReading": "かれ は すてき な だんせい です",
    "exTranslation": "He's a wonderful man.",
    "category": "Noun"
  },
  {
    "term": "理性",
    "termReading": "りせい",
    "termTranslation": "reason, reasoning power",
    "ex": "人間は理性を持つ動物です。",
    "exReading": "にんげん は りせい を もつ どうぶつ です",
    "exTranslation": "Human beings are rational animals.",
    "category": "Noun"
  },
  {
    "term": "ボーナス",
    "termReading": "ボーナス",
    "termTranslation": "bonus",
    "ex": "今年はボーナスがたくさん出ました。",
    "exReading": "ことし は ぼーなす が たくさん でました 。",
    "exTranslation": "A lot of bonuses were paid this year.",
    "category": "Noun"
  },
  {
    "term": "土産",
    "termReading": "みやげ",
    "termTranslation": "souvenir",
    "ex": "土産に日本酒をもらった。",
    "exReading": "みやげ に にほんしゅ を もらった",
    "exTranslation": "I was given sake as a souvenir.",
    "category": "Noun"
  },
  {
    "term": "徒歩",
    "termReading": "とほ",
    "termTranslation": "walking, going on foot",
    "ex": "家から駅まで徒歩3分です。",
    "exReading": "いえ から えき まで とほ 3 ぷん です",
    "exTranslation": "It's 3 minutes on foot from home to the station.",
    "category": "Noun"
  },
  {
    "term": "学ぶ",
    "termReading": "まなぶ",
    "termTranslation": "learn, study",
    "ex": "私は哲学を学んでいます。",
    "exReading": "わたし は てつがく を まなんで います",
    "exTranslation": "I study philosophy.",
    "category": "Verb"
  },
  {
    "term": "文学",
    "termReading": "ぶんがく",
    "termTranslation": "literature",
    "ex": "彼女は文学に興味を持っているのよ。",
    "exReading": "かのじょ は ぶんがく に きょうみ を もっている の よ 。",
    "exTranslation": "She's interested in literature.",
    "category": "Noun"
  },
  {
    "term": "体育",
    "termReading": "たいいく",
    "termTranslation": "physical training, physical education",
    "ex": "今日は体育の授業があります。",
    "exReading": "きょう は たいいく の じゅぎょう が あります",
    "exTranslation": "There's a PE lesson today.",
    "category": "Noun"
  },
  {
    "term": "そっくり",
    "termReading": "そっくり",
    "termTranslation": "identical, look alike",
    "ex": "あの親子はそっくりだね。",
    "exReading": "あの おやこ は そっくり だ ね",
    "exTranslation": "That parent and child look alike.",
    "category": "Adjective"
  },
  {
    "term": "体制",
    "termReading": "たいせい",
    "termTranslation": "system, structure",
    "ex": "政治の体制を変える必要があります。",
    "exReading": "せいじ の たいせい を かえる ひつよう が あります",
    "exTranslation": "It's necessary to change the political system.",
    "category": "Noun"
  },
  {
    "term": "強まる",
    "termReading": "つよまる",
    "termTranslation": "become strong, gain strength",
    "ex": "雨はだんだん強まります。",
    "exReading": "あめ は だんだん つよまります",
    "exTranslation": "The rain has been gradually getting stronger.",
    "category": "Verb"
  },
  {
    "term": "強める",
    "termReading": "つよめる",
    "termTranslation": "strengthen, emphasize",
    "ex": "火を強めてください。",
    "exReading": "ひ を つよめて ください",
    "exTranslation": "Please turn the heat up.",
    "category": "Verb"
  },
  {
    "term": "力強い",
    "termReading": "ちからづよい",
    "termTranslation": "powerful, vigorous",
    "ex": "彼は力強い演技をするね。",
    "exReading": "かれ は ちからづよい えんぎ を する ね",
    "exTranslation": "He's a powerful actor.",
    "category": "Adjective"
  },
  {
    "term": "強気",
    "termReading": "つよき",
    "termTranslation": "tough, bullish",
    "ex": "彼女は強気な女性ですね。",
    "exReading": "かのじょ は つよき な じょせい です ね",
    "exTranslation": "She's a tough woman.",
    "category": "Adjectival Noun"
  },
  {
    "term": "レンズ",
    "termReading": "レンズ",
    "termTranslation": "lens",
    "ex": "眼鏡のレンズを替えました。",
    "exReading": "めがね の れんず を かえました",
    "exTranslation": "I had the lenses in my glasses changed.",
    "category": "Noun"
  },
  {
    "term": "弱まる",
    "termReading": "よわまる",
    "termTranslation": "weaken, abate",
    "ex": "夜になって風が弱まったね。",
    "exReading": "よる に なって かぜ が よわまった ね",
    "exTranslation": "The wind dropped at night.",
    "category": "Verb"
  },
  {
    "term": "弱める",
    "termReading": "よわめる",
    "termTranslation": "weaken, lower",
    "ex": "火を弱めてください。",
    "exReading": "ひ を よわめて ください",
    "exTranslation": "Please turn down the heat.",
    "category": "Verb"
  },
  {
    "term": "弱る",
    "termReading": "よわる",
    "termTranslation": "weaken, be debilitated",
    "ex": "彼は病気で弱っているんだ。",
    "exReading": "かれ は びょうき で よわって いる ん だ",
    "exTranslation": "He has been getting weaker due to his illness.",
    "category": "Verb"
  },
  {
    "term": "弱み",
    "termReading": "よわみ",
    "termTranslation": "weakness, shortcoming",
    "ex": "彼は私の弱みを握っているんだ。",
    "exReading": "かれ は わたし の よわみ を にぎって いる ん だ",
    "exTranslation": "He's got dirt on me.",
    "category": "Noun"
  },
  {
    "term": "弱気",
    "termReading": "よわき",
    "termTranslation": "timid, weak-spirited",
    "ex": "彼は少し弱気になっています。",
    "exReading": "かれ は すこし よわき に なって います",
    "exTranslation": "He's getting a little timid.",
    "category": "Adjectival Noun"
  },
  {
    "term": "引き受ける",
    "termReading": "ひきうける",
    "termTranslation": "undertake",
    "ex": "新しい仕事を引き受けたよ。",
    "exReading": "あたらしい しごと を ひきうけた よ",
    "exTranslation": "I accepted the new job.",
    "category": "Verb"
  },
  {
    "term": "ペース",
    "termReading": "ペース",
    "termTranslation": "pace",
    "ex": "彼はとても早いペースで走っているね。",
    "exReading": "かれ は とても はやい ぺーす で はしって いる ね",
    "exTranslation": "He's running at a very fast pace.",
    "category": "Noun"
  },
  {
    "term": "引き上げる",
    "termReading": "ひきあげる",
    "termTranslation": "pull up, salvage",
    "ex": "沈んだ船を引き上げたんだ。",
    "exReading": "しずんだ ふね を ひきあげた ん だ",
    "exTranslation": "The sunken ship was salvaged.",
    "category": "Verb"
  },
  {
    "term": "引き出す",
    "termReading": "ひきだす",
    "termTranslation": "draw out, lure out",
    "ex": "先生が私の能力を引き出してくれました。",
    "exReading": "せんせい が わたし の のうりょく を ひきだしてくれました",
    "exTranslation": "The teacher was able to draw out my abilities.",
    "category": "Verb"
  },
  {
    "term": "引き取る",
    "termReading": "ひきとる",
    "termTranslation": "take over, take custody",
    "ex": "彼女は息子を引き取ったの。",
    "exReading": "かのじょ は むすこ を ひきとった の",
    "exTranslation": "She took custody of her son.",
    "category": "Verb"
  },
  {
    "term": "値引き",
    "termReading": "ねびき",
    "termTranslation": "discount",
    "ex": "あと1000円値引きしましょう。",
    "exReading": "あと 1000 えん ねびき しましょう",
    "exTranslation": "I'll further discount the price by 1,000 yen.",
    "category": "Verbal Noun"
  },
  {
    "term": "長引く",
    "termReading": "ながびく",
    "termTranslation": "be prolonged, drag on",
    "ex": "会議が長引いているようです。",
    "exReading": "かいぎ が ながびいて いる よう です",
    "exTranslation": "The conference seems to be dragging on.",
    "category": "Verb"
  },
  {
    "term": "マラソン",
    "termReading": "マラソン",
    "termTranslation": "marathon",
    "ex": "彼はマラソンに出場したよ。",
    "exReading": "かれ は まらそん に しゅつじょう した よ",
    "exTranslation": "He participated in the marathon.",
    "category": "Noun"
  },
  {
    "term": "引きずる",
    "termReading": "ひきずる",
    "termTranslation": "drag along, trail",
    "ex": "彼はまだ失恋を引きずっています。",
    "exReading": "かれ は まだ しつれん を ひきずって います",
    "exTranslation": "He's still dragging along the emotional baggage of a romantic breakup.",
    "category": "Verb"
  },
  {
    "term": "引き分け",
    "termReading": "ひきわけ",
    "termTranslation": "draw, drawn game",
    "ex": "この勝負は引き分けです。",
    "exReading": "この しょうぶ は ひきわけ です",
    "exTranslation": "This match is a tie.",
    "category": "Noun"
  },
  {
    "term": "引き止める",
    "termReading": "ひきとめる",
    "termTranslation": "detain, keep",
    "ex": "帰ろうとする友達を引き止めたんだ。",
    "exReading": "かえろう と する ともだち を ひきとめた ん だ",
    "exTranslation": "I stalled my friend who was about to go home.",
    "category": "Verb"
  },
  {
    "term": "見習う",
    "termReading": "みならう",
    "termTranslation": "learn, follow someone's example",
    "ex": "彼を見習ってもっと勉強します。",
    "exReading": "かれ を みならって もっと べんきょう します",
    "exTranslation": "I'll follow his example and study harder.",
    "category": "Verb"
  },
  {
    "term": "慣れ",
    "termReading": "なれ",
    "termTranslation": "getting used to, habituation",
    "ex": "仕事には慣れも必要です。",
    "exReading": "しごと に は なれ も ひつよう です",
    "exTranslation": "Getting used to the work is also necessary in any job.",
    "category": "Noun"
  },
  {
    "term": "よほど",
    "termReading": "よほど",
    "termTranslation": "very, by far",
    "ex": "今日の遠足はよほど楽しかったらしい。",
    "exReading": "きょう の えんそく は よほど たのしかった らしい",
    "exTranslation": "Today's excursion seems to have been really good fun.",
    "category": "Adverb"
  },
  {
    "term": "慣らす",
    "termReading": "ならす",
    "termTranslation": "accustom",
    "ex": "水の温度に体を慣らしてから、潜ったほうがいいぞ。",
    "exReading": "みず の おんど に からだ を ならして から もぐった ほう が いい ぞ",
    "exTranslation": "You should dive only after getting your body used to the water temperature.",
    "category": "Verb"
  },
  {
    "term": "入試",
    "termReading": "にゅうし",
    "termTranslation": "entrance examination",
    "ex": "明日は高校の入試です。",
    "exReading": "あした は こうこう の にゅうし です",
    "exTranslation": "The high school entrance exam is tomorrow.",
    "category": "Noun"
  },
  {
    "term": "試す",
    "termReading": "ためす",
    "termTranslation": "try, test",
    "ex": "彼はそのソフトウェアを試したの。",
    "exReading": "かれ は その そふとうぇあ を ためした の",
    "exTranslation": "He tested the software.",
    "category": "Verb"
  },
  {
    "term": "試し",
    "termReading": "ためし",
    "termTranslation": "trial, test",
    "ex": "試しにこの曲を弾いてみてください。",
    "exReading": "ためし に この きょく を ひいて みて ください",
    "exTranslation": "Try playing this tune, by way of experiment.",
    "category": "Noun"
  },
  {
    "term": "体験",
    "termReading": "たいけん",
    "termTranslation": "experience",
    "ex": "今日、珍しい体験をしました。",
    "exReading": "きょう めずらしい たいけん を しました",
    "exTranslation": "Today, I had an unusual experience.",
    "category": "Verbal Noun"
  },
  {
    "term": "物質",
    "termReading": "ぶっしつ",
    "termTranslation": "matter, substance",
    "ex": "この製品は有害な化学物質を含んでいるわよ。",
    "exReading": "この せいひん は ゆうがい な かがくぶっしつ を ふくんで いる わ よ",
    "exTranslation": "This product contains a harmful chemical.",
    "category": "Noun"
  },
  {
    "term": "ラグビー",
    "termReading": "ラグビー",
    "termTranslation": "rugby",
    "ex": "父は若いころラグビーの選手でした。",
    "exReading": "ちち は わかい ころ らぐびー の せんしゅ でした",
    "exTranslation": "When he was young, my father was a rugby player.",
    "category": "Noun"
  },
  {
    "term": "品質",
    "termReading": "ひんしつ",
    "termTranslation": "quality",
    "ex": "このメーカーの製品は高品質だわね。",
    "exReading": "この めーかー の せいひん は こうひんしつ だ わ ね",
    "exTranslation": "The products from this manufacturer are high-quality.",
    "category": "Noun"
  },
  {
    "term": "本質",
    "termReading": "ほんしつ",
    "termTranslation": "essence, reality",
    "ex": "彼は仕事の本質をよく理解しているわね。",
    "exReading": "かれ は しごと の ほんしつ を よく りかい して いる わ ね",
    "exTranslation": "He understands the essence of the work well.",
    "category": "Noun"
  },
  {
    "term": "問う",
    "termReading": "とう",
    "termTranslation": "ask, question",
    "ex": "応募者の年齢は問いません。",
    "exReading": "おうぼしゃ の ねんれい は といません",
    "exTranslation": "Applicants of all ages are accepted.",
    "category": "Verb"
  },
  {
    "term": "問い合わせる",
    "termReading": "といあわせる",
    "termTranslation": "inquire, contact",
    "ex": "保険会社に問い合わせます。",
    "exReading": "ほけん がいしゃ に といあわせます",
    "exTranslation": "I'll inquire with my insurance company.",
    "category": "Verb"
  },
  {
    "term": "問い",
    "termReading": "とい",
    "termTranslation": "question, inquiry",
    "ex": "この問いに答えられますか。",
    "exReading": "この とい に こたえられます か",
    "exTranslation": "Can you answer this question?",
    "category": "Noun"
  },
  {
    "term": "ラッシュ",
    "termReading": "ラッシュ",
    "termTranslation": "rush, rush hour",
    "ex": "私は毎朝ラッシュの電車に乗っています。",
    "exReading": "わたし は まいあさ らっしゅ の でんしゃ に のって います",
    "exTranslation": "I take the train during rush hour every morning.",
    "category": "Noun"
  },
  {
    "term": "問屋",
    "termReading": "とんや",
    "termTranslation": "wholesale store, wholesaler",
    "ex": "この街には家具の問屋がたくさんあります。",
    "exReading": "この まち に は かぐ の とんや が たくさん あります",
    "exTranslation": "There are a lot of wholesale furniture stores in this town.",
    "category": "Noun"
  },
  {
    "term": "話題",
    "termReading": "わだい",
    "termTranslation": "topic of conversation",
    "ex": "ここが話題のレストランです。",
    "exReading": "ここ が わだい の れすとらん です",
    "exTranslation": "This is the restaurant that we were talking about.",
    "category": "Noun"
  },
  {
    "term": "題名",
    "termReading": "だいめい",
    "termTranslation": "title",
    "ex": "この本の題名は「坊ちゃん」です。",
    "exReading": "この ほん の だいめい は ぼっちゃん です",
    "exTranslation": "The title of this book is \"Botchan\".",
    "category": "Noun"
  },
  {
    "term": "題",
    "termReading": "だい",
    "termTranslation": "title, topic",
    "ex": "その曲の題を思い出せません。",
    "exReading": "その きょく の だい を おもいだせません",
    "exTranslation": "I can't remember the title of the tune.",
    "category": "Noun"
  },
  {
    "term": "単語",
    "termReading": "たんご",
    "termTranslation": "word",
    "ex": "日本語の単語をいくつ知っていますか。",
    "exReading": "にほんご の たんご を いくつ しって います か",
    "exTranslation": "How many Japanese words do you know?",
    "category": "Noun"
  },
  {
    "term": "ロープ",
    "termReading": "ロープ",
    "termTranslation": "rope",
    "ex": "ロープを使って崖を下りたんだ。",
    "exReading": "ろーぷ を つかって がけ を おりた ん だ",
    "exTranslation": "I descended the cliff using a rope.",
    "category": "Noun"
  },
  {
    "term": "単に",
    "termReading": "たんに",
    "termTranslation": "merely, simply",
    "ex": "心配しないで、単に眠いだけです。",
    "exReading": "しんぱい しない で たんに ねむい だけ です",
    "exTranslation": "Don't worry, I'm simply sleepy.",
    "category": "Adverb"
  },
  {
    "term": "地点",
    "termReading": "ちてん",
    "termTranslation": "spot, point",
    "ex": "もうすぐ目標の地点に到達します。",
    "exReading": "もうすぐ もくひょう の ちてん に とうたつ します",
    "exTranslation": "We'll soon be arriving at our destination.",
    "category": "Noun"
  },
  {
    "term": "満点",
    "termReading": "まんてん",
    "termTranslation": "full marks, perfect score",
    "ex": "国語のテストは満点でした。",
    "exReading": "こくご の てすと は まんてん でした",
    "exTranslation": "I got full marks on a Japanese test.",
    "category": "Noun"
  },
  {
    "term": "点",
    "termReading": "てん",
    "termTranslation": "point, issue",
    "ex": "その点は心配ありません。",
    "exReading": "その てん は しんぱい ありません",
    "exTranslation": "There's nothing to worry about regarding that.",
    "category": "Noun"
  },
  {
    "term": "多数",
    "termReading": "たすう",
    "termTranslation": "large number, multitude",
    "ex": "その仕事に多数の応募があったよ。",
    "exReading": "その しごと に たすう の おうぼ が あった よ",
    "exTranslation": "There were a lot of applications for the job.",
    "category": "Noun"
  },
  {
    "term": "ダム",
    "termReading": "ダム",
    "termTranslation": "dam",
    "ex": "今、ダムの水が少ないね。",
    "exReading": "いま だむ の みず が すくない ね",
    "exTranslation": "Now the dam doesn't have a lot of water.",
    "category": "Noun"
  },
  {
    "term": "複数",
    "termReading": "ふくすう",
    "termTranslation": "plural",
    "ex": "複数のレポートをひとつにまとめています。",
    "exReading": "ふくすう の れぽーと を ひとつ に まとめて います",
    "exTranslation": "I have put several reports together in one.",
    "category": "Noun"
  },
  {
    "term": "無数",
    "termReading": "むすう",
    "termTranslation": "countless, innumerable",
    "ex": "宇宙には無数の星があります。",
    "exReading": "うちゅう に は むすう の ほし が あります",
    "exTranslation": "There are an infinite number of stars in space.",
    "category": "Adjectival Noun"
  },
  {
    "term": "日数",
    "termReading": "にっすう",
    "termTranslation": "number of days",
    "ex": "今月は出勤日数が多いです。",
    "exReading": "こんげつ は しゅっきん にっすう が おおい です",
    "exTranslation": "I'm working many days this month.",
    "category": "Noun"
  },
  {
    "term": "点数",
    "termReading": "てんすう",
    "termTranslation": "points, score",
    "ex": "今回はテストの点数が悪かった。",
    "exReading": "こんかい は てすと の てんすう が わるかった",
    "exTranslation": "My test score this time was bad.",
    "category": "Noun"
  },
  {
    "term": "単数",
    "termReading": "たんすう",
    "termTranslation": "singular",
    "ex": "penは単数でpensは複数です。",
    "exReading": "ぺんは たんすう で ぺんず は ふくすう です",
    "exTranslation": "Pen is the singular and \"pens\" is the plural.",
    "category": "Noun"
  },
  {
    "term": "手数",
    "termReading": "てすう",
    "termTranslation": "trouble, bother",
    "ex": "お手数ですがよろしくお願いします。",
    "exReading": "おてすう です が よろしく おねがい します",
    "exTranslation": "Sorry to trouble you, but please take care of it.",
    "category": "Noun"
  },
  {
    "term": "ダウン",
    "termReading": "ダウン",
    "termTranslation": "down, malfunction",
    "ex": "サーバーがダウンしています。",
    "exReading": "さーばー が だうん して います",
    "exTranslation": "The server has crashed.",
    "category": "Verbal Noun"
  },
  {
    "term": "人数",
    "termReading": "にんずう",
    "termTranslation": "number of people",
    "ex": "参加者の人数を教えてください。",
    "exReading": "さんかしゃ の にんずう を おしえて ください",
    "exTranslation": "Please tell me the number of participants.",
    "category": "Noun"
  },
  {
    "term": "回り",
    "termReading": "まわり",
    "termTranslation": "vicinity, whereabouts",
    "ex": "先生の周りに集まってください。",
    "exReading": "せんせい の まわり に あつまって ください 。",
    "exTranslation": "Gather around me now.",
    "category": "Noun"
  },
  {
    "term": "回り道",
    "termReading": "まわりみち",
    "termTranslation": "roundabout way, detour",
    "ex": "今日は回り道して帰ろう。",
    "exReading": "きょう は まわりみち して かえろう",
    "exTranslation": "Today, let's make a detour on the way home.",
    "category": "Noun"
  },
  {
    "term": "枚数",
    "termReading": "まいすう",
    "termTranslation": "number of flat things",
    "ex": "コピーの枚数を数えてください。",
    "exReading": "こぴー の まいすう を かぞえて ください",
    "exTranslation": "Please count the number of copies.",
    "category": "Noun"
  },
  {
    "term": "負け",
    "termReading": "まけ",
    "termTranslation": "defeat, loss",
    "ex": "この勝負はあなたの負けです。",
    "exReading": "この しょうぶ は あなた の まけ です",
    "exTranslation": "This match is your defeat.",
    "category": "Noun"
  },
  {
    "term": "トレーニング",
    "termReading": "トレーニング",
    "termTranslation": "training, workout",
    "ex": "彼は毎日トレーニングをするの。",
    "exReading": "かれ は まいにち とれーにんぐ を する の",
    "exTranslation": "He works out every day.",
    "category": "Verbal Noun"
  },
  {
    "term": "負担",
    "termReading": "ふたん",
    "termTranslation": "burden, charge",
    "ex": "交通費は個人負担です。",
    "exReading": "こうつうひ は こじん ふたん です",
    "exTranslation": "Transportation costs are covered by the individual.",
    "category": "Verbal Noun"
  },
  {
    "term": "分担",
    "termReading": "ぶんたん",
    "termTranslation": "assigning tasks, sharing burden",
    "ex": "私たち夫婦は家事を分担しています。",
    "exReading": "わたしたち ふうふ は かじ を ぶんたん して います",
    "exTranslation": "We share the housework in my marriage.",
    "category": "Verbal Noun"
  },
  {
    "term": "当初",
    "termReading": "とうしょ",
    "termTranslation": "original, initial",
    "ex": "当初の計画ではもっと早く終わるはずでした。",
    "exReading": "とうしょ の けいかく で は もっと はやく おわる はず でした",
    "exTranslation": "According to the initial plan, it should have finished sooner.",
    "category": "Noun"
  },
  {
    "term": "当局",
    "termReading": "とうきょく",
    "termTranslation": "the authorities concerned",
    "ex": "その事件については当局が調査しています。",
    "exReading": "その じけん に ついて は とうきょく が ちょうさ して います",
    "exTranslation": "The authorities concerned are investigating the incident.",
    "category": "Noun"
  },
  {
    "term": "当日",
    "termReading": "とうじつ",
    "termTranslation": "that day",
    "ex": "入場券は当日でも買えますよ。",
    "exReading": "にゅうじょうけん は とうじつ で も かえます よ",
    "exTranslation": "You can buy admission tickets on the same day.",
    "category": "Noun"
  },
  {
    "term": "そっと",
    "termReading": "そっと",
    "termTranslation": "softly, gently",
    "ex": "母親は娘の髪をそっとなでたの。",
    "exReading": "ははおや は むすめ の かみ を そっと なでた の",
    "exTranslation": "The mother quietly patted her daughter's hair.",
    "category": "Adverb"
  },
  {
    "term": "手当て",
    "termReading": "てあて",
    "termTranslation": "allowance, medical care",
    "ex": "彼女は急いで怪我の手当てをしたよ。",
    "exReading": "かのじょ は いそいで けが の てあて を した よ",
    "exTranslation": "She quickly administered first aid treatment.",
    "category": "Verbal Noun"
  },
  {
    "term": "当分",
    "termReading": "とうぶん",
    "termTranslation": "for some time, for the present",
    "ex": "彼女は当分学校を休むそうです。",
    "exReading": "かのじょ は とうぶん がっこう を やすむ そう です",
    "exTranslation": "It seems she'll be absent from the school for some time.",
    "category": "Adverb"
  },
  {
    "term": "当人",
    "termReading": "とうにん",
    "termTranslation": "the person in question",
    "ex": "当人は意外に平気なようね。",
    "exReading": "とうにん は いがい に へいき なよう ね 。",
    "exTranslation": "The person concerned seems to be unexpectedly cool.",
    "category": "Noun"
  },
  {
    "term": "当番",
    "termReading": "とうばん",
    "termTranslation": "being on duty, person on duty",
    "ex": "今日は私が掃除の当番です。",
    "exReading": "きょう は わたし が そうじ の とうばん です",
    "exTranslation": "I'm in charge of the cleaning today.",
    "category": "Noun"
  },
  {
    "term": "日当たり",
    "termReading": "ひあたり",
    "termTranslation": "exposure to the sun",
    "ex": "この部屋は日当たりがいい。",
    "exReading": "この へや は ひあたり が いい",
    "exTranslation": "This room gets a lot of sunshine.",
    "category": "Noun"
  },
  {
    "term": "天然",
    "termReading": "てんねん",
    "termTranslation": "nature",
    "ex": "ここは天然の温泉です。",
    "exReading": "ここ は てんねん の おんせん です",
    "exTranslation": "This is a natural hot spring.",
    "category": "Noun"
  },
  {
    "term": "マーク",
    "termReading": "マーク",
    "termTranslation": "logo",
    "ex": "このブランドのマークは可愛いですね。",
    "exReading": "この ぶらんど の まーく は かわいい です ね",
    "exTranslation": "This brand logo is cute.",
    "category": "Verbal Noun"
  },
  {
    "term": "文法",
    "termReading": "ぶんぽう",
    "termTranslation": "grammar",
    "ex": "今日は英語の文法を勉強します。",
    "exReading": "きょう は えいご の ぶんぽう を べんきょう します",
    "exTranslation": "I'll study English grammar today.",
    "category": "Noun"
  },
  {
    "term": "法学部",
    "termReading": "ほうがくぶ",
    "termTranslation": "law department",
    "ex": "彼女は法学部の学生です。",
    "exReading": "かのじょ は ほうがくぶ の がくせい です",
    "exTranslation": "She's a student in the Law Department.",
    "category": "Noun"
  },
  {
    "term": "法",
    "termReading": "ほう",
    "termTranslation": "law, rule",
    "ex": "国民は法に従わなければならないよ。",
    "exReading": "こくみん は ほう に したがわなければ ならない よ",
    "exTranslation": "Citizens must observe the law.",
    "category": "Noun"
  },
  {
    "term": "法則",
    "termReading": "ほうそく",
    "termTranslation": "law, rule",
    "ex": "勝利するには法則があるそうだ。",
    "exReading": "しょうり する に は ほうそく が ある そう だ",
    "exTranslation": "I heard that there are rules to follow in order to win.",
    "category": "Noun"
  },
  {
    "term": "不規則",
    "termReading": "ふきそく",
    "termTranslation": "irregular",
    "ex": "最近、不規則な生活をしている。",
    "exReading": "さいきん ふきそく な せいかつ を して いる",
    "exTranslation": "My life has been quite disorganized lately.",
    "category": "Adjectival Noun"
  },
  {
    "term": "たった",
    "termReading": "たった",
    "termTranslation": "only, merely",
    "ex": "財布の中にたった1000円しかないよ。",
    "exReading": "さいふ の なか に たった せんえん しか ない よ",
    "exTranslation": "I only have 1,000 yen in my purse.",
    "category": "Adverb"
  },
  {
    "term": "経る",
    "termReading": "へる",
    "termTranslation": "pass, pass through",
    "ex": "彼は新聞記者を経て作家になりました。",
    "exReading": "かれ は しんぶん きしゃ を へて さっか に なりました",
    "exTranslation": "He became a writer after being a journalist.",
    "category": "Verb"
  },
  {
    "term": "有利",
    "termReading": "ゆうり",
    "termTranslation": "advantageous, profitable",
    "ex": "資格があると就職に有利です。",
    "exReading": "しかく が ある と しゅうしょく に ゆうり です",
    "exTranslation": "It's advantageous to have qualifications when looking for work.",
    "category": "Adjectival Noun"
  },
  {
    "term": "不利",
    "termReading": "ふり",
    "termTranslation": "disadvantage",
    "ex": "彼は今、不利な立場にいます。",
    "exReading": "かれ は いま ふり な たちば に います",
    "exTranslation": "He's at a disadvantage now.",
    "category": "Adjectival Noun"
  },
  {
    "term": "利口",
    "termReading": "りこう",
    "termTranslation": "clever, bright",
    "ex": "あの犬はとても利口ですね。",
    "exReading": "あの いぬ は とても りこう です ね",
    "exTranslation": "That dog is very clever.",
    "category": "Adjectival Noun"
  },
  {
    "term": "利子",
    "termReading": "りし",
    "termTranslation": "interest",
    "ex": "借金に利子をつけて返したの。",
    "exReading": "しゃっきん に りし を つけて かえした の",
    "exTranslation": "He returned the debt with interest.",
    "category": "Noun"
  },
  {
    "term": "たまたま",
    "termReading": "たまたま",
    "termTranslation": "by chance, accidentally",
    "ex": "道でたまたま友達に会った。",
    "exReading": "みち で たまたま ともだち に あった",
    "exTranslation": "I met a friend in the street by chance.",
    "category": "Adverb"
  },
  {
    "term": "左利き",
    "termReading": "ひだりきき",
    "termTranslation": "left-handedness, left-hander",
    "ex": "私の息子は左利きです。",
    "exReading": "わたし の むすこ は ひだりきき です",
    "exTranslation": "My son is left-handed.",
    "category": "Noun"
  },
  {
    "term": "利益",
    "termReading": "りえき",
    "termTranslation": "profit, benefit",
    "ex": "先月の利益は200万円でした。",
    "exReading": "せんげつ の りえき は 200 まんえん でした",
    "exTranslation": "Last month's profit was two million yen.",
    "category": "Noun"
  },
  {
    "term": "有益",
    "termReading": "ゆうえき",
    "termTranslation": "beneficial, profitable",
    "ex": "昨日の話し合いは有益でした。",
    "exReading": "きのう の はなしあい は ゆうえき でした",
    "exTranslation": "Yesterday's discussion was beneficial.",
    "category": "Adjectival Noun"
  },
  {
    "term": "買収",
    "termReading": "ばいしゅう",
    "termTranslation": "buying up, bribing",
    "ex": "彼は買収されたらしいわ。",
    "exReading": "かれ は ばいしゅう された らしい わ",
    "exTranslation": "He was bribed, apparently.",
    "category": "Verbal Noun"
  },
  {
    "term": "年収",
    "termReading": "ねんしゅう",
    "termTranslation": "annual income",
    "ex": "税金の額は年収によって変わります。",
    "exReading": "ぜいきん の がく は ねんしゅう に よって かわります",
    "exTranslation": "The amount of tax depends on the annual income.",
    "category": "Noun"
  },
  {
    "term": "木造",
    "termReading": "もくぞう",
    "termTranslation": "wooden",
    "ex": "隣に木造の家が建ったね。",
    "exReading": "となり に もくぞう の いえ が たった ね",
    "exTranslation": "A wooden house was built next door.",
    "category": "Noun"
  },
  {
    "term": "テンポ",
    "termReading": "テンポ",
    "termTranslation": "tempo, pace",
    "ex": "この曲はテンポが速いですね。",
    "exReading": "この きょく は てんぽ が はやい です ね",
    "exTranslation": "The tempo of this tune is fast.",
    "category": "Noun"
  },
  {
    "term": "必然",
    "termReading": "ひつぜん",
    "termTranslation": "inevitable, necessary",
    "ex": "私と彼が出会ったのは必然だったの。",
    "exReading": "わたし と かれ が であった の は ひつぜん だった の",
    "exTranslation": "It was inevitable that he and I should meet.",
    "category": "Noun"
  },
  {
    "term": "要する",
    "termReading": "ようする",
    "termTranslation": "require, need",
    "ex": "このビルは完成までに2年を要した。",
    "exReading": "この びる は かんせい まで に 2 ねん を ようした",
    "exTranslation": "The building needed two years to be completed.",
    "category": "Verb"
  },
  {
    "term": "不要",
    "termReading": "ふよう",
    "termTranslation": "unnecessary, waste",
    "ex": "不要になったパソコンを処分したんだ。",
    "exReading": "ふよう に なった ぱそこん を しょぶん した ん だ",
    "exTranslation": "I disposed of the unneeded personal computer.",
    "category": "Adjectival Noun"
  },
  {
    "term": "要するに",
    "termReading": "ようするに",
    "termTranslation": "in a word, in short",
    "ex": "要するに時機を待つべきだ。",
    "exReading": "ようするに じき を まつべき だ",
    "exTranslation": "In short, we should wait for the right opportunity.",
    "category": "Adverb"
  },
  {
    "term": "不必要",
    "termReading": "ふひつよう",
    "termTranslation": "unnecessary, superfluous",
    "ex": "不必要なファイルは削除してください。",
    "exReading": "ふひつよう な ふぁいる は さくじょ して ください",
    "exTranslation": "Please delete any unwanted files.",
    "category": "Adjectival Noun"
  },
  {
    "term": "プラン",
    "termReading": "プラン",
    "termTranslation": "plan",
    "ex": "彼女と旅行のプランを考えました。",
    "exReading": "かのじょ と りょこう の ぷらん を かんがえました",
    "exTranslation": "I thought of a travel plan with her.",
    "category": "Noun"
  },
  {
    "term": "要点",
    "termReading": "ようてん",
    "termTranslation": "main point, gist",
    "ex": "話の要点だけ教えてください。",
    "exReading": "はなし の ようてん だけ おしえて ください",
    "exTranslation": "Please tell me just the main points of the talk.",
    "category": "Noun"
  },
  {
    "term": "求める",
    "termReading": "もとめる",
    "termTranslation": "seek, wish for",
    "ex": "子供は親の愛を求めます。",
    "exReading": "こども は おや の あい を もとめます",
    "exTranslation": "Children need their parents' love.",
    "category": "Verb"
  },
  {
    "term": "要請",
    "termReading": "ようせい",
    "termTranslation": "request, demand",
    "ex": "その国の政府は各国に支援を要請したのよ。",
    "exReading": "その くに の せいふ は かっこく に しえん を ようせい した の よ",
    "exTranslation": "The government of that country requested support from all nations.",
    "category": "Verbal Noun"
  },
  {
    "term": "額",
    "termReading": "ひたい",
    "termTranslation": "forehead, brow",
    "ex": "額に汗をかいたよ。",
    "exReading": "ひたい に あせ を かいた よ",
    "exTranslation": "His forehead was covered in sweat.",
    "category": "Noun"
  },
  {
    "term": "計る",
    "termReading": "はかる",
    "termTranslation": "compute, measure",
    "ex": "100メートル走のタイムを計ったんだ。",
    "exReading": "100 めーとるそう の たいむ を はかった ん だ",
    "exTranslation": "I timed the 100m race.",
    "category": "Verb"
  },
  {
    "term": "ほっと",
    "termReading": "ほっと",
    "termTranslation": "feel relieved",
    "ex": "家に着いてほっとしたよ。",
    "exReading": "いえ に ついて ほっと した よ",
    "exTranslation": "I felt relieved to be home.",
    "category": "Adverb"
  },
  {
    "term": "体温計",
    "termReading": "たいおんけい",
    "termTranslation": "thermometer",
    "ex": "体温計が壊れてしまった。",
    "exReading": "たいおんけい が こわれて しまった",
    "exTranslation": "The clinical thermometer has broken.",
    "category": "Noun"
  },
  {
    "term": "日差し",
    "termReading": "ひざし",
    "termTranslation": "rays of the sun, sunlight",
    "ex": "今日は日差しが強いですね。",
    "exReading": "きょう は ひざし が つよい です ね",
    "exTranslation": "The sun is shining strongly today.",
    "category": "Noun"
  },
  {
    "term": "物差し",
    "termReading": "ものさし",
    "termTranslation": "ruler",
    "ex": "30センチの物差しをください。",
    "exReading": "30 せんち の ものさし を ください",
    "exTranslation": "Please give me a 30 centimeter ruler.",
    "category": "Noun"
  },
  {
    "term": "役割",
    "termReading": "やくわり",
    "termTranslation": "role, part",
    "ex": "みんなで役割を分担しましょう。",
    "exReading": "みんな で やくわり を ぶんたん しましょう",
    "exTranslation": "Let's give everyone a role.",
    "category": "Noun"
  },
  {
    "term": "割合",
    "termReading": "わりあい",
    "termTranslation": "rate, proportion",
    "ex": "二つの薬品を1対3の割合で混ぜたんだ。",
    "exReading": "ふたつ の やくひん を 1 たい 3 の わりあい で まぜた ん だ",
    "exTranslation": "I mixed the two chemicals at a ratio of 1 to 3.",
    "category": "Adverb"
  },
  {
    "term": "なるほど",
    "termReading": "なるほど",
    "termTranslation": "I see, really",
    "ex": "なるほど、よく分かりました。",
    "exReading": "なるほど よく わかりました",
    "exTranslation": "I see, I understand quite well.",
    "category": "Adverb"
  },
  {
    "term": "割り当て",
    "termReading": "わりあて",
    "termTranslation": "assignment, allotment",
    "ex": "チケットの割り当ては1人10枚です。",
    "exReading": "ちけっと の わりあて は ひとり じゅうまい です",
    "exTranslation": "Ten tickets are allocated per person.",
    "category": "Noun"
  },
  {
    "term": "割り当てる",
    "termReading": "わりあてる",
    "termTranslation": "assign, allot",
    "ex": "全員に作業が割り当てられました。",
    "exReading": "ぜんいん に さぎょう が わりあてられました",
    "exTranslation": "Work was assigned to all members.",
    "category": "Verb"
  },
  {
    "term": "割り引く",
    "termReading": "わりびく",
    "termTranslation": "give a discount, make a reduction",
    "ex": "定価から2000円割り引きますよ。",
    "exReading": "ていか から 2 せんえん わりびきます よ",
    "exTranslation": "I'll deduct 2000 yen from the recommended retail price.",
    "category": "Verb"
  },
  {
    "term": "割に",
    "termReading": "わりに",
    "termTranslation": "comparatively, rather",
    "ex": "この映画は割に面白いわ。",
    "exReading": "この えいが は わりに おもしろい わ",
    "exTranslation": "This movie is rather interesting.",
    "category": "Adverb"
  },
  {
    "term": "割り",
    "termReading": "わり",
    "termTranslation": "rate",
    "ex": "3日に1度の割りで彼からメールが来るの。",
    "exReading": "みっか に 1 ど の わり で かれ から めーる が くる の",
    "exTranslation": "I get e-mails from him at a rate of once every three days.",
    "category": "Noun"
  },
  {
    "term": "割り引き",
    "termReading": "わりびき",
    "termTranslation": "discount, reduction",
    "ex": "今日は全品10%割り引きです。",
    "exReading": "きょう は ぜんぴん 10% わりびき です",
    "exTranslation": "Today all goods are 10% off.",
    "category": "Verbal Noun"
  },
  {
    "term": "バイト",
    "termReading": "バイト",
    "termTranslation": "part-time job",
    "ex": "今日は6時からバイトです。",
    "exReading": "きょう は 6 じ から ばいと です",
    "exTranslation": "My part-time job starts at 6 today.",
    "category": "Verbal Noun"
  },
  {
    "term": "割合に",
    "termReading": "わりあいに",
    "termTranslation": "relatively, rather",
    "ex": "今回のテストは割合に簡単でした。",
    "exReading": "こんかい の てすと は わりあいに かんたん でした",
    "exTranslation": "This test was relatively easy.",
    "category": "Adverb"
  },
  {
    "term": "残り",
    "termReading": "のこり",
    "termTranslation": "remainder, leftover",
    "ex": "仕事の残りは家でします。",
    "exReading": "しごと の のこり は いえ で します",
    "exTranslation": "I'll do the rest of the work at home.",
    "category": "Noun"
  },
  {
    "term": "残らず",
    "termReading": "のこらず",
    "termTranslation": "entirely, without exception",
    "ex": "ゴミを残らず拾ったよ。",
    "exReading": "ごみ を のこらず ひろった よ",
    "exTranslation": "I picked up all the garbage.",
    "category": "Adverb"
  },
  {
    "term": "払い",
    "termReading": "はらい",
    "termTranslation": "payment, account",
    "ex": "飲み屋の払いがたまっているんだ。",
    "exReading": "のみや の はらい が たまって いる ん だ",
    "exTranslation": "I'm running up a bar bill.",
    "category": "Noun"
  },
  {
    "term": "引き返す",
    "termReading": "ひきかえす",
    "termTranslation": "turn back, retrace one's steps",
    "ex": "雨が強かったので引き返したよ。",
    "exReading": "あめ が つよかった の で ひきかえした よ",
    "exTranslation": "I turned back because it was raining heavily.",
    "category": "Verb"
  },
  {
    "term": "ぶつける",
    "termReading": "ぶつける",
    "termTranslation": "bump",
    "ex": "車を壁にぶつけてしまいました。",
    "exReading": "くるま を かべ に ぶつけて しまいました",
    "exTranslation": "The car hit the wall.",
    "category": "Verb"
  },
  {
    "term": "取り返す",
    "termReading": "とりかえす",
    "termTranslation": "take back, regain",
    "ex": "彼はチャンピオンのタイトルを取り返したね。",
    "exReading": "かれ は ちゃんぴおん の たいとる を とりかえした ね",
    "exTranslation": "He took back the championship title.",
    "category": "Verb"
  },
  {
    "term": "申し出る",
    "termReading": "もうしでる",
    "termTranslation": "offer, propose",
    "ex": "彼はプロジェクトへの参加を申し出たよ。",
    "exReading": "かれ は ぷろじぇくと へ の さんか を もうしでた よ",
    "exTranslation": "He offered to join the project.",
    "category": "Verb"
  },
  {
    "term": "申し上げる",
    "termReading": "もうしあげる",
    "termTranslation": "speak humbly, do",
    "ex": "結果を申し上げます。",
    "exReading": "けっか を もうしあげます",
    "exTranslation": "I'll announce the results.",
    "category": "Verb"
  },
  {
    "term": "申す",
    "termReading": "もうす",
    "termTranslation": "speak humbly, state humbly",
    "ex": "私は鈴木と申します。",
    "exReading": "わたくし は すずき と もうします",
    "exTranslation": "My name is Suzuki.",
    "category": "Verb"
  },
  {
    "term": "持ち込む",
    "termReading": "もちこむ",
    "termTranslation": "bring in, carry on",
    "ex": "機内に荷物を持ち込んだの。",
    "exReading": "きない に にもつ を もちこんだ の",
    "exTranslation": "We carried our luggage onto the plane.",
    "category": "Verb"
  },
  {
    "term": "タイヤ",
    "termReading": "タイヤ",
    "termTranslation": "tire",
    "ex": "車のタイヤを換えた。",
    "exReading": "くるま の たいや を かえた",
    "exTranslation": "I changed the tires on the car.",
    "category": "Noun"
  },
  {
    "term": "見込み",
    "termReading": "みこみ",
    "termTranslation": "hope, prospect",
    "ex": "3月に大学を卒業の見込みです。",
    "exReading": "3 がつ に だいがく を そつぎょう の みこみ です",
    "exTranslation": "I expect to graduate from university in March.",
    "category": "Noun"
  },
  {
    "term": "申し込み",
    "termReading": "もうしこみ",
    "termTranslation": "application, proposal",
    "ex": "今日、スポーツジムの申し込みをしました。",
    "exReading": "きょう すぽーつじむ の もうしこみ を しました",
    "exTranslation": "I signed up for the sports gym today.",
    "category": "Noun"
  },
  {
    "term": "払い込む",
    "termReading": "はらいこむ",
    "termTranslation": "pay in, pay up",
    "ex": "授業料を学校に払い込みました。",
    "exReading": "じゅぎょうりょう を がっこう に はらいこみました",
    "exTranslation": "I paid tuition to the school.",
    "category": "Verb"
  },
  {
    "term": "飲み込む",
    "termReading": "のみこむ",
    "termTranslation": "swallow, grasp",
    "ex": "彼は薬を一気に飲み込んだ。",
    "exReading": "かれ は くすり を いっきに のみこんだ",
    "exTranslation": "He swallowed all of the medicine in one go.",
    "category": "Verb"
  },
  {
    "term": "割り込む",
    "termReading": "わりこむ",
    "termTranslation": "cut in",
    "ex": "車が前に割り込んできた。",
    "exReading": "くるま が まえ に わりこんで きた",
    "exTranslation": "A car cut in front of me.",
    "category": "Verb"
  },
  {
    "term": "引っ込む",
    "termReading": "ひっこむ",
    "termTranslation": "draw back, pull in",
    "ex": "ダイエットをしてお腹が引っ込みました。",
    "exReading": "だいえっと を して おなか が ひっこみました",
    "exTranslation": "I dieted and my stomach flattened out.",
    "category": "Verb"
  },
  {
    "term": "マイク",
    "termReading": "マイク",
    "termTranslation": "mic, microphone",
    "ex": "彼女はマイクを持って話し始めたの。",
    "exReading": "かのじょ は まいく を もって はなしはじめた の",
    "exTranslation": "She took the microphone and began to speak.",
    "category": "Noun"
  },
  {
    "term": "人込み",
    "termReading": "ひとごみ",
    "termTranslation": "crowd",
    "ex": "私は人込みが好きではありません。",
    "exReading": "わたし は ひとごみ が すき で は ありません",
    "exTranslation": "I don't like crowds.",
    "category": "Noun"
  },
  {
    "term": "長期",
    "termReading": "ちょうき",
    "termTranslation": "long period",
    "ex": "今回は長期の滞在です。",
    "exReading": "こんかい は ちょうき の たいざい です",
    "exTranslation": "This time I'll stay for a long time.",
    "category": "Noun"
  },
  {
    "term": "短期",
    "termReading": "たんき",
    "termTranslation": "short-term",
    "ex": "明日から2週間、短期のアルバイトをします。",
    "exReading": "あした から 2 しゅうかん たんき の あるばいと を します",
    "exTranslation": "For two weeks starting tomorrow, I'm doing a short stint as a part-time worker.",
    "category": "Noun"
  },
  {
    "term": "短期大学",
    "termReading": "たんきだいがく",
    "termTranslation": "junior college",
    "ex": "彼女は短期大学で日本文学を勉強しました。",
    "exReading": "かのじょ は たんきだいがく で にほん ぶんがく を べんきょう しました",
    "exTranslation": "She studied Japanese literature in junior college.",
    "category": "Noun"
  },
  {
    "term": "有限",
    "termReading": "ゆうげん",
    "termTranslation": "limited, finite",
    "ex": "宇宙は有限だと思いますか。",
    "exReading": "うちゅう は ゆうげん だ と おもいます か",
    "exTranslation": "Do you think that space is finite?",
    "category": "Adjectival Noun"
  },
  {
    "term": "ゆとり",
    "termReading": "ゆとり",
    "termTranslation": "room, leeway",
    "ex": "最近、生活にゆとりがでてきました。",
    "exReading": "さいきん せいかつ に ゆとり が でて きました",
    "exTranslation": "I have more room to breathe lately.",
    "category": "Noun"
  },
  {
    "term": "無限",
    "termReading": "むげん",
    "termTranslation": "infinite, endless",
    "ex": "資源は無限ではありません。",
    "exReading": "しげん は むげん で は ありません",
    "exTranslation": "Resources are not infinite.",
    "category": "Adjectival Noun"
  },
  {
    "term": "無制限",
    "termReading": "むせいげん",
    "termTranslation": "unlimited, free",
    "ex": "このサイトでは音楽を無制限でダウンロードできる。",
    "exReading": "この さいと で は おんがく を むせいげん で だうんろーど できる",
    "exTranslation": "You get unlimited music downloads on this site.",
    "category": "Adjectival Noun"
  },
  {
    "term": "値切る",
    "termReading": "ねぎる",
    "termTranslation": "bargain, haggle",
    "ex": "彼は値切るのが上手です。",
    "exReading": "かれ は ねぎる の が じょうず です",
    "exTranslation": "He's good at haggling.",
    "category": "Verb"
  },
  {
    "term": "前売り券",
    "termReading": "まえうりけん",
    "termTranslation": "advance ticket",
    "ex": "コンサートの前売り券を手に入れたんだ。",
    "exReading": "こんさーと の まえうりけん を て に いれた ん だ",
    "exTranslation": "I got an advance ticket for the concert.",
    "category": "Noun"
  },
  {
    "term": "年代",
    "termReading": "ねんだい",
    "termTranslation": "age, date",
    "ex": "私と彼は同じ年代です。",
    "exReading": "わたし と かれ は おなじ ねんだい です",
    "exTranslation": "He and I are the same generation.",
    "category": "Noun"
  },
  {
    "term": "ぼんやり",
    "termReading": "ぼんやり",
    "termTranslation": "vaguely, absent-mindedly",
    "ex": "彼は遠くをぼんやり見ていたの。",
    "exReading": "かれ は とおく を ぼんやり みて いた の",
    "exTranslation": "He stared vacantly into the distance.",
    "category": "Adjective"
  },
  {
    "term": "代金",
    "termReading": "だいきん",
    "termTranslation": "charge, fee",
    "ex": "ここで代金をお支払いください。",
    "exReading": "ここ で だいきん を お しはらい ください",
    "exTranslation": "Please pay the bill here.",
    "category": "Noun"
  },
  {
    "term": "目指す",
    "termReading": "めざす",
    "termTranslation": "aim for, have an eye on",
    "ex": "私は料理人を目指しています。",
    "exReading": "わたし は りょうりにん を めざして います",
    "exTranslation": "I intend becoming a cook.",
    "category": "Verb"
  },
  {
    "term": "指差す",
    "termReading": "ゆびさす",
    "termTranslation": "point to",
    "ex": "みんなが彼の指差す方を見たんだ。",
    "exReading": "みんな が かれ の ゆびさす ほう を みたんだ",
    "exTranslation": "Everyone looked in the direction he pointed.",
    "category": "Verb"
  },
  {
    "term": "人差し指",
    "termReading": "ひとさしゆび",
    "termTranslation": "index finger",
    "ex": "彼女は人差し指を怪我したの。",
    "exReading": "かのじょ は ひとさしゆび を けが した の 。",
    "exTranslation": "She injured her forefinger.",
    "category": "Noun"
  },
  {
    "term": "中指",
    "termReading": "なかゆび",
    "termTranslation": "middle finger",
    "ex": "中指をドアに挟んでしまった。",
    "exReading": "なかゆび を どあ に はさんで しまった",
    "exTranslation": "I caught my middle finger in the door.",
    "category": "Noun"
  },
  {
    "term": "定年",
    "termReading": "ていねん",
    "termTranslation": "mandatory retirement age",
    "ex": "彼は来年定年を迎える。",
    "exReading": "かれ は らいねん ていねん を むかえる",
    "exTranslation": "He will retire next year.",
    "category": "Noun"
  },
  {
    "term": "たまらない",
    "termReading": "たまらない",
    "termTranslation": "can't stand, can't put up with",
    "ex": "頭が痛くてたまらない。",
    "exReading": "あたま が いたくて たまらない",
    "exTranslation": "I have an unbearable headache.",
    "category": "Adjective"
  },
  {
    "term": "不安定",
    "termReading": "ふあんてい",
    "termTranslation": "instability",
    "ex": "最近、体調が少し不安定です。",
    "exReading": "さいきん たいちょう が すこし ふあんてい です",
    "exTranslation": "Recently, my physical condition is a little unstable.",
    "category": "Adjectival Noun"
  },
  {
    "term": "定員",
    "termReading": "ていいん",
    "termTranslation": "fixed staff number, capacity",
    "ex": "降りてください、定員オーバーです。",
    "exReading": "おりて ください ていいん おーばー です",
    "exTranslation": "Please get off, we are over capacity.",
    "category": "Noun"
  },
  {
    "term": "定期",
    "termReading": "ていき",
    "termTranslation": "fixed term, regular",
    "ex": "定期演奏会は年に4回あります。",
    "exReading": "ていきえんそうかい は ねん に 4 かい あります",
    "exTranslation": "There are regular concerts four times a year.",
    "category": "Noun"
  },
  {
    "term": "定価",
    "termReading": "ていか",
    "termTranslation": "fixed price",
    "ex": "この本の定価は525円です。",
    "exReading": "この ほん の ていか は 525 えん です",
    "exTranslation": "The regular price of this book is 525 yen.",
    "category": "Noun"
  },
  {
    "term": "未定",
    "termReading": "みてい",
    "termTranslation": "undecided, pending",
    "ex": "この件の担当者は未定です。",
    "exReading": "この けん の たんとうしゃ は みてい です",
    "exTranslation": "It's not yet decided who the person in charge of this matter will be.",
    "category": "Noun"
  },
  {
    "term": "モーター",
    "termReading": "モーター",
    "termTranslation": "motor, engine",
    "ex": "車のモーターを修理したぜ！",
    "exReading": "くるま の もーたー を しゅうり した ぜ",
    "exTranslation": "I repaired the car's motor.",
    "category": "Noun"
  },
  {
    "term": "定食",
    "termReading": "ていしょく",
    "termTranslation": "day's special, set menu",
    "ex": "昼の定食は3種類あります。",
    "exReading": "ひる の ていしょく は 3 しゅるい あります",
    "exTranslation": "There are three kinds of set lunches.",
    "category": "Noun"
  },
  {
    "term": "定休日",
    "termReading": "ていきゅうび",
    "termTranslation": "regular holiday",
    "ex": "この店は水曜が定休日です。",
    "exReading": "この みせ は すいよう が ていきゅうび です",
    "exTranslation": "This shop is closed on Wednesdays.",
    "category": "Noun"
  },
  {
    "term": "予算",
    "termReading": "よさん",
    "termTranslation": "budget",
    "ex": "車の費用、予算オーバーだ。",
    "exReading": "くるま の ひよう、 よさん おーばー だ",
    "exTranslation": "The car expenses are over budget.",
    "category": "Noun"
  },
  {
    "term": "予報",
    "termReading": "よほう",
    "termTranslation": "forecast, prediction",
    "ex": "予報では明日は雨ですね。",
    "exReading": "よほう で は あした は あめ です ね",
    "exTranslation": "It will rain tomorrow according to the forecast.",
    "category": "Verbal Noun"
  },
  {
    "term": "予言",
    "termReading": "よげん",
    "termTranslation": "prediction, forecast",
    "ex": "彼の予言は当たったことがないね。",
    "exReading": "かれ の よげん は あたった こと が ない ね",
    "exTranslation": "His predictions have never been correct.",
    "category": "Verbal Noun"
  },
  {
    "term": "まして",
    "termReading": "まして",
    "termTranslation": "much more",
    "ex": "他人でも悲しいのだから、まして本人はどれほどでしょう。",
    "exReading": "たにん で も かなしい の だ から まして ほんにん は どれほど でしょう",
    "exTranslation": "If other people feel saddened, imagine how much sadder the person in question must feel.",
    "category": "Adverb"
  },
  {
    "term": "束",
    "termReading": "たば",
    "termTranslation": "bundle, counter for bundles",
    "ex": "これは一束で300円です。",
    "exReading": "これ は ひとたば で 300 えん です",
    "exTranslation": "These are 300 yen a bunch.",
    "category": "Noun"
  },
  {
    "term": "花束",
    "termReading": "はなたば",
    "termTranslation": "bunch of flowers",
    "ex": "卒業式に花束をもらいました。",
    "exReading": "そつぎょうしき に はなたば を もらいました",
    "exTranslation": "I got a bouquet of flowers at my graduation ceremony.",
    "category": "Noun"
  },
  {
    "term": "変更",
    "termReading": "へんこう",
    "termTranslation": "alteration, change",
    "ex": "予定が変更になりました。",
    "exReading": "よてい が へんこう に なりました",
    "exTranslation": "The schedule has been changed.",
    "category": "Verbal Noun"
  },
  {
    "term": "夜更かし",
    "termReading": "よふかし",
    "termTranslation": "sitting up late at night",
    "ex": "最近の子供たちは夜更かしです。",
    "exReading": "さいきん の こども たち は よふかし です",
    "exTranslation": "These days children stay up till late.",
    "category": "Verbal Noun"
  },
  {
    "term": "増す",
    "termReading": "ます",
    "termTranslation": "increase, augment",
    "ex": "大雨で川の水かさが増しているな。",
    "exReading": "おおあめ で かわ の みずかさ が まして いる な",
    "exTranslation": "The river swells because of the downpour.",
    "category": "Verb"
  },
  {
    "term": "ピストル",
    "termReading": "ピストル",
    "termTranslation": "pistol",
    "ex": "犯人はピストルを持っているわ。",
    "exReading": "はんにん は ぴすとる を もって いる わ",
    "exTranslation": "The criminal has a pistol.",
    "category": "Noun"
  },
  {
    "term": "増大",
    "termReading": "ぞうだい",
    "termTranslation": "increase, enlargement",
    "ex": "生産コスト増大のため、値上げします。",
    "exReading": "せいさん こすと ぞうだい の ため ねあげ します",
    "exTranslation": "Prices are rising because production costs are increasing.",
    "category": "Verbal Noun"
  },
  {
    "term": "増減",
    "termReading": "ぞうげん",
    "termTranslation": "increase and decrease, rise and fall",
    "ex": "この数年、体重は増減していません。",
    "exReading": "この すうねん たいじゅう は ぞうげん して いません",
    "exTranslation": "My weight has not increased or decreased for several years.",
    "category": "Verbal Noun"
  },
  {
    "term": "乗り出す",
    "termReading": "のりだす",
    "termTranslation": "lean forward, embark on",
    "ex": "船が長い航海に乗り出したの。",
    "exReading": "ふね が ながい こうかい に のりだした の",
    "exTranslation": "The ship set out on a long sea voyage.",
    "category": "Verb"
  },
  {
    "term": "乗り込む",
    "termReading": "のりこむ",
    "termTranslation": "board, march into",
    "ex": "あの駅で学生がたくさん乗り込んだね。",
    "exReading": "あの えき で がくせい が たくさん のりこんだ ね",
    "exTranslation": "A lot of students got on board at that station.",
    "category": "Verb"
  },
  {
    "term": "乗り降り",
    "termReading": "のりおり",
    "termTranslation": "getting on and off",
    "ex": "この駅でたくさんの人が乗り降りしますね。",
    "exReading": "この えき で たくさん の ひと が のりおり します ね",
    "exTranslation": "A huge number of people get on and off at this station.",
    "category": "Verbal Noun"
  },
  {
    "term": "着ける",
    "termReading": "つける",
    "termTranslation": "pull up, park (a vehicle)",
    "ex": "玄関に車を着けます。",
    "exReading": "げんかん に くるま を つけます",
    "exTranslation": "I'll bring the car to the door.",
    "category": "Verb"
  },
  {
    "term": "たっぷり",
    "termReading": "たっぷり",
    "termTranslation": "fully, amply",
    "ex": "たっぷりとマッサージしてもらいました。",
    "exReading": "たっぷり と まっさーじ して もらいました",
    "exTranslation": "I got a full massage.",
    "category": "Adverb"
  },
  {
    "term": "水着",
    "termReading": "みずぎ",
    "termTranslation": "bathing suit",
    "ex": "水着に着替えました。",
    "exReading": "みずぎ に きがえました",
    "exTranslation": "I changed into a swimsuit.",
    "category": "Noun"
  },
  {
    "term": "役立つ",
    "termReading": "やくだつ",
    "termTranslation": "be of use, serve a purpose",
    "ex": "学校で勉強したことが役立った。",
    "exReading": "がっこう で べんきょう した こと が やくだった",
    "exTranslation": "What I studied at school was useful.",
    "category": "Verb"
  },
  {
    "term": "立ち上がる",
    "termReading": "たちあがる",
    "termTranslation": "stand up, take action",
    "ex": "彼は急に立ち上がったの。",
    "exReading": "かれ は きゅう に たちあがった の",
    "exTranslation": "He suddenly stood up.",
    "category": "Verb"
  },
  {
    "term": "中立",
    "termReading": "ちゅうりつ",
    "termTranslation": "neutrality",
    "ex": "私は中立の立場を取っています。",
    "exReading": "わたし は ちゅうりつ の たちば を とって います",
    "exTranslation": "I'm taking a neutral standpoint.",
    "category": "Verbal Noun"
  },
  {
    "term": "都立",
    "termReading": "とりつ",
    "termTranslation": "metropolitan, Tokyo metropolitan",
    "ex": "ここは都立の病院です。",
    "exReading": "ここ は とりつ の びょういん です",
    "exTranslation": "This is a metropolitan hospital.",
    "category": "Noun"
  },
  {
    "term": "つくづく",
    "termReading": "つくづく",
    "termTranslation": "truly, deeply",
    "ex": "来てよかったとつくづく思います。",
    "exReading": "きて よかったと つくづく おもいます",
    "exTranslation": "I really feel that it was good to come.",
    "category": "Adverb"
  },
  {
    "term": "立て込む",
    "termReading": "たてこむ",
    "termTranslation": "full of, crowded with",
    "ex": "この辺は住宅が立て込んで います。",
    "exReading": "このへん は じゅうたく が たてこんで います",
    "exTranslation": "This area is crowded with houses.",
    "category": "Verb"
  },
  {
    "term": "夕立",
    "termReading": "ゆうだち",
    "termTranslation": "sudden evening shower",
    "ex": "帰宅中、夕立にあったの。",
    "exReading": "きたくちゅう ゆうだち に あった の",
    "exTranslation": "I was caught in a shower while coming home.",
    "category": "Noun"
  },
  {
    "term": "立ち止まる",
    "termReading": "たちどまる",
    "termTranslation": "stop, halt",
    "ex": "人々は立ち止まって上を見上げたの。",
    "exReading": "ひとびと は たちどまって うえ を みあげた の",
    "exTranslation": "People stopped in their tracks and looked up.",
    "category": "Verb"
  },
  {
    "term": "着席",
    "termReading": "ちゃくせき",
    "termTranslation": "taking a seat",
    "ex": "みなさん、着席してください。",
    "exReading": "みなさん ちゃくせき して ください",
    "exTranslation": "Everybody, please be seated.",
    "category": "Verbal Noun"
  },
  {
    "term": "次々に",
    "termReading": "つぎつぎに",
    "termTranslation": "one after another, in succession",
    "ex": "走者が次々にゴールしました。",
    "exReading": "そうしゃ が つぎつぎに ごーる しました",
    "exTranslation": "Runners reached the goal one after another.",
    "category": "Adverb"
  },
  {
    "term": "どうせ",
    "termReading": "どうせ",
    "termTranslation": "anyway",
    "ex": "どうせ間に合わないならゆっくり行こう。",
    "exReading": "どうせ まにあわない なら ゆっくり いこう",
    "exTranslation": "Let's go slowly as we won't be on time anyway.",
    "category": "Adverb"
  },
  {
    "term": "次ぐ",
    "termReading": "つぐ",
    "termTranslation": "rank next to, come next",
    "ex": "彼はトップに次ぐ好成績でした。",
    "exReading": "かれ は とっぷ に つぐ こうせいせき でした",
    "exTranslation": "His excellent results were next to the top.",
    "category": "Verb"
  },
  {
    "term": "取り次ぐ",
    "termReading": "とりつぐ",
    "termTranslation": "relay a message, transfer a call",
    "ex": "電話があったら取り次いでください。",
    "exReading": "でんわ が あったら とりついで ください",
    "exTranslation": "Please transfer the phone to me if it rings.",
    "category": "Verb"
  },
  {
    "term": "目次",
    "termReading": "もくじ",
    "termTranslation": "table of contents",
    "ex": "読みたい章を目次で探しました。",
    "exReading": "よみたい しょう を もくじ で さがしました",
    "exTranslation": "I looked for the chapter that I wanted to read in the table of contents.",
    "category": "Noun"
  },
  {
    "term": "不運",
    "termReading": "ふうん",
    "termTranslation": "misfortune, bad luck",
    "ex": "彼に不運な出来事が起こったの。",
    "exReading": "かれ に ふうん な できごと が おこった の",
    "exTranslation": "He suffered an unfortunate incident.",
    "category": "Adjectival Noun"
  },
  {
    "term": "動向",
    "termReading": "どうこう",
    "termTranslation": "trend, tendency",
    "ex": "今、経済の動向は読みにくいわ。",
    "exReading": "いま けいざい の どうこう は よみ にくい わ",
    "exTranslation": "Economic trends are difficult to read at the moment.",
    "category": "Noun"
  },
  {
    "term": "動作",
    "termReading": "どうさ",
    "termTranslation": "movements, actions",
    "ex": "彼は動作が機敏です。",
    "exReading": "かれ は どうさ が きびん です",
    "exTranslation": "His movements are quick.",
    "category": "Noun"
  },
  {
    "term": "リズム",
    "termReading": "リズム",
    "termTranslation": "rhythm",
    "ex": "最近彼は生活のリズムが乱れています。",
    "exReading": "さいきん かれ は せいかつ の りずむ が みだれて います",
    "exTranslation": "His daily routine has been disrupted lately.",
    "category": "Noun"
  },
  {
    "term": "動力",
    "termReading": "どうりょく",
    "termTranslation": "power",
    "ex": "この車の動力は電気です。",
    "exReading": "この くるま の どうりょく は でんき です",
    "exTranslation": "This car is powered by electricity.",
    "category": "Noun"
  },
  {
    "term": "働き",
    "termReading": "はたらき",
    "termTranslation": "work, function",
    "ex": "部下が素晴らしい働きをしたな。",
    "exReading": "ぶか が すばらしい はたらき を した な",
    "exTranslation": "My subordinates did some fantastic work.",
    "category": "Noun"
  },
  {
    "term": "早朝",
    "termReading": "そうちょう",
    "termTranslation": "early morning",
    "ex": "私は早朝のジョギングを日課にしています。",
    "exReading": "わたし は そうちょう の じょぎんぐ を にっか に して います",
    "exTranslation": "It's my routine to go jogging every morning.",
    "category": "Noun"
  },
  {
    "term": "早める",
    "termReading": "はやめる",
    "termTranslation": "move forward (in time), expedite",
    "ex": "集合時間を30分早めました。",
    "exReading": "しゅうごうじかん を 30 ぷん はやめました",
    "exTranslation": "I pushed forward the meeting time by 30 minutes.",
    "category": "Verb"
  },
  {
    "term": "早まる",
    "termReading": "はやまる",
    "termTranslation": "make a hasty decision, jump the gun",
    "ex": "早まらないでよく考えましょう。",
    "exReading": "はやまらない で よく かんがえましょう",
    "exTranslation": "Let's think carefully without acting rashly.",
    "category": "Verb"
  },
  {
    "term": "ハンドル",
    "termReading": "ハンドル",
    "termTranslation": "steering wheel",
    "ex": "この車は左ハンドルです。",
    "exReading": "この くるま は ひだり はんどる です",
    "exTranslation": "This car has the steering wheel on the left.",
    "category": "Noun"
  },
  {
    "term": "速度",
    "termReading": "そくど",
    "termTranslation": "speed, velocity",
    "ex": "新幹線の速度はどれくらいですか。",
    "exReading": "しんかんせん の そくど は どれ くらい です か",
    "exTranslation": "How fast is the speed of the Shinkansen?",
    "category": "Noun"
  },
  {
    "term": "速達便",
    "termReading": "そくたつびん",
    "termTranslation": "special delivery, express mail",
    "ex": "速達便なら明日の午前中に届きます。",
    "exReading": "そくたつびん なら あした の ごぜんちゅう に とどきます",
    "exTranslation": "It will arrive tomorrow morning if you send it by express mail.",
    "category": "Noun"
  },
  {
    "term": "乗り遅れる",
    "termReading": "のりおくれる",
    "termTranslation": "miss",
    "ex": "寝坊して新幹線に乗り遅れたよ。",
    "exReading": "ねぼう して しんかんせん に のりおくれた よ",
    "exTranslation": "I overslept and missed the Shinkansen.",
    "category": "Verb"
  },
  {
    "term": "始め",
    "termReading": "はじめ",
    "termTranslation": "beginning, origin",
    "ex": "私たちの旅は始めはよかったんだ。",
    "exReading": "わたしたち の たび は はじめ は よかった ん だ",
    "exTranslation": "The start of our trip was good.",
    "category": "Noun"
  },
  {
    "term": "始まり",
    "termReading": "はじまり",
    "termTranslation": "beginning",
    "ex": "いよいよ劇の始まりですね。",
    "exReading": "いよいよ げき の はじまり です ね",
    "exTranslation": "The play is about to start.",
    "category": "Noun"
  },
  {
    "term": "プリント",
    "termReading": "プリント",
    "termTranslation": "print",
    "ex": "デジカメで撮った写真をプリントしたんだ。",
    "exReading": "でじかめ で とった しゃしん を ぷりんと した ん だ",
    "exTranslation": "I printed the photograph that I took with my digital camera.",
    "category": "Verbal Noun"
  },
  {
    "term": "年始",
    "termReading": "ねんし",
    "termTranslation": "beginning of the year",
    "ex": "部下の方が年始の挨拶に見えましたよ。",
    "exReading": "ぶか の かた が ねんし の あいさつ に みえました よ",
    "exTranslation": "Your men came to give greetings for the new year.",
    "category": "Noun"
  },
  {
    "term": "不在",
    "termReading": "ふざい",
    "termTranslation": "absence",
    "ex": "妻の不在中、夫は毎日外食したんだ。",
    "exReading": "つま の ふざいちゅう おっと は まいにち がいしょく した ん だ",
    "exTranslation": "The husband dined out every day during his wife's absence.",
    "category": "Noun"
  },
  {
    "term": "実る",
    "termReading": "みのる",
    "termTranslation": "bear fruit, ripen",
    "ex": "やっと努力が実りました。",
    "exReading": "やっと どりょく が みのりました",
    "exTranslation": "My effort has finally paid off.",
    "category": "Verb"
  },
  {
    "term": "実",
    "termReading": "み",
    "termTranslation": "fruit, nut",
    "ex": "庭の木が赤い実をつけた。",
    "exReading": "にわ の き が あかい み を つけた",
    "exTranslation": "The tree in the garden bore some red fruit.",
    "category": "Noun"
  },
  {
    "term": "通り過ぎる",
    "termReading": "とおりすぎる",
    "termTranslation": "go past, pass",
    "ex": "うっかり目的地を通り過ぎた。",
    "exReading": "うっかり もくてきち を とおりすぎた",
    "exTranslation": "I inadvertently missed my destination.",
    "category": "Verb"
  },
  {
    "term": "発売",
    "termReading": "はつばい",
    "termTranslation": "sale, selling",
    "ex": "新しい車が発売された。",
    "exReading": "あたらしい くるま が はつばい された",
    "exTranslation": "A new car was put on the market.",
    "category": "Verbal Noun"
  },
  {
    "term": "まぶしい",
    "termReading": "まぶしい",
    "termTranslation": "dazzling, glaring",
    "ex": "夏の日差しがまぶしかったよ。",
    "exReading": "なつ の ひざし が まぶしかった よ",
    "exTranslation": "The summer sunlight was dazzling.",
    "category": "Adjective"
  },
  {
    "term": "発つ",
    "termReading": "たつ",
    "termTranslation": "start, leave",
    "ex": "彼は明日メキシコへ発ちます。",
    "exReading": "かれ は あした めきしこ へ たちます",
    "exTranslation": "He will leave for Mexico tomorrow.",
    "category": "Verb"
  },
  {
    "term": "発行",
    "termReading": "はっこう",
    "termTranslation": "publication, issue",
    "ex": "その雑誌は年に4回発行されているんだ。",
    "exReading": "その ざっし は ねん に 4 かい はっこう されて いる ん だ",
    "exTranslation": "The magazine is issued four times a year.",
    "category": "Verbal Noun"
  },
  {
    "term": "発達",
    "termReading": "はったつ",
    "termTranslation": "development, growth",
    "ex": "通信技術の発達は目覚ましいな。",
    "exReading": "つうしん ぎじゅつ の はったつ は めざましい な",
    "exTranslation": "The progress of communication technology is remarkable.",
    "category": "Verbal Noun"
  },
  {
    "term": "発明",
    "termReading": "はつめい",
    "termTranslation": "invention",
    "ex": "彼は偉大な発明王です。",
    "exReading": "かれ は いだい な はつめいおう です",
    "exTranslation": "He's a great master of invention.",
    "category": "Verbal Noun"
  },
  {
    "term": "発電",
    "termReading": "はつでん",
    "termTranslation": "generation of electricity",
    "ex": "ここでは太陽エネルギーを使って発電しています。",
    "exReading": "ここ で は たいよう えねるぎー を つかって はつでん して います",
    "exTranslation": "We generate electricity with solar energy here.",
    "category": "Verbal Noun"
  },
  {
    "term": "よそ",
    "termReading": "よそ",
    "termTranslation": "somewhere else",
    "ex": "夕食はよそでごちそうになりました。",
    "exReading": "ゆうしょく は よそ で ごちそう に なりました",
    "exTranslation": "I was treated to dinner out.",
    "category": "Noun"
  },
  {
    "term": "発電所",
    "termReading": "はつでんしょ",
    "termTranslation": "power plant, generating station",
    "ex": "すぐそこに発電所があります。",
    "exReading": "すぐ そこ に はつでんしょ が あります",
    "exTranslation": "There's a power plant near here.",
    "category": "Noun"
  },
  {
    "term": "発熱",
    "termReading": "はつねつ",
    "termTranslation": "generation of heat, get a fever",
    "ex": "娘が突然、発熱したんだ。",
    "exReading": "むすめ が とつぜん はつねつ した ん だ",
    "exTranslation": "My daughter suddenly developed a fever.",
    "category": "Verbal Noun"
  },
  {
    "term": "表情",
    "termReading": "ひょうじょう",
    "termTranslation": "expression, look",
    "ex": "彼はとても表情が豊かですね。",
    "exReading": "かれ は とても ひょうじょう が ゆたか です ね",
    "exTranslation": "He's very expressive.",
    "category": "Noun"
  },
  {
    "term": "用紙",
    "termReading": "ようし",
    "termTranslation": "blank form, stationery",
    "ex": "この用紙に名前を書いてください。",
    "exReading": "この ようし に なまえ を かいて ください",
    "exTranslation": "Please write your name on this form.",
    "category": "Noun"
  },
  {
    "term": "表紙",
    "termReading": "ひょうし",
    "termTranslation": "cover, binding",
    "ex": "その人気アイドルが今月の表紙だよ。",
    "exReading": "その にんき あいどる が こんげつ の ひょうし だ よ",
    "exTranslation": "The popular star is on the cover this month.",
    "category": "Noun"
  },
  {
    "term": "つぐ",
    "termReading": "つぐ",
    "termTranslation": "pour, pour into",
    "ex": "お酒をおつぎしましょう。",
    "exReading": "おさけ を お つぎ しましょう",
    "exTranslation": "Let me pour you a drink.",
    "category": "Verb"
  },
  {
    "term": "白紙",
    "termReading": "はくし",
    "termTranslation": "blank sheet, clean slate",
    "ex": "テストを白紙で出したの。",
    "exReading": "てすと を はくし で だした の",
    "exTranslation": "I handed in my test answer sheet completely blank.",
    "category": "Noun"
  },
  {
    "term": "音",
    "termReading": "ね",
    "termTranslation": "sound, note",
    "ex": "秋は虫の音が心地良いです。",
    "exReading": "あき は むし の ね が ここちいい です",
    "exTranslation": "The chirping of insects in fall feels comfortable.",
    "category": "Noun"
  },
  {
    "term": "発音",
    "termReading": "はつおん",
    "termTranslation": "pronunciation",
    "ex": "この単語を発音してください。",
    "exReading": "この たんご を はつおん して ください",
    "exTranslation": "Please pronounce this word.",
    "category": "Verbal Noun"
  },
  {
    "term": "物音",
    "termReading": "ものおと",
    "termTranslation": "noise, sound",
    "ex": "物音がしたので見に行った。",
    "exReading": "ものおと が した の で み に いった",
    "exTranslation": "I heard a noise so I went to look.",
    "category": "Noun"
  },
  {
    "term": "楽しみ",
    "termReading": "たのしみ",
    "termTranslation": "pleasure, something looked forward to",
    "ex": "旅行は父の老後の楽しみです。",
    "exReading": "りょこう は ちち の ろうご の たのしみ です",
    "exTranslation": "My father looks forward to traveling in his old age.",
    "category": "Noun"
  },
  {
    "term": "ディスコ",
    "termReading": "ディスコ",
    "termTranslation": "disco",
    "ex": "昔はよくディスコに行ったな。",
    "exReading": "むかし は よく でぃすこ に いった な",
    "exTranslation": "I often went to the disco in the past.",
    "category": "Noun"
  },
  {
    "term": "楽",
    "termReading": "らく",
    "termTranslation": "comfort, relief",
    "ex": "彼には楽な仕事が与えられたよ。",
    "exReading": "かれ に は らく な しごと が あたえられた よ",
    "exTranslation": "He was given an easy job.",
    "category": "Adjectival Noun"
  },
  {
    "term": "薬品",
    "termReading": "やくひん",
    "termTranslation": "medicine, drug",
    "ex": "彼女は薬品を戸棚から出したんだ。",
    "exReading": "かのじょ は やくひん を とだな から だした ん だ",
    "exTranslation": "She took the medicine out of the cupboard.",
    "category": "Noun"
  },
  {
    "term": "薬局",
    "termReading": "やっきょく",
    "termTranslation": "drugstore, pharmacy",
    "ex": "薬局で目薬を買いました。",
    "exReading": "やっきょく で めぐすり を かいました",
    "exTranslation": "I bought some eye drops at the drugstore.",
    "category": "Noun"
  },
  {
    "term": "目薬",
    "termReading": "めぐすり",
    "termTranslation": "eye drops",
    "ex": "目が疲れたので目薬をさしたよ。",
    "exReading": "め が つかれた の で めぐすり を さした よ",
    "exTranslation": "Because my eyes were tired, I put in some eye drops.",
    "category": "Noun"
  },
  {
    "term": "欲",
    "termReading": "よく",
    "termTranslation": "desire, greed",
    "ex": "あまり欲を出しちゃだめだよ。",
    "exReading": "あまり よく を だし ちゃ だめ だ よ",
    "exTranslation": "Don't be too greedy.",
    "category": "Noun"
  },
  {
    "term": "欲求",
    "termReading": "よっきゅう",
    "termTranslation": "want, desire",
    "ex": "時には自分の欲求を抑えることも必要です。",
    "exReading": "ときには じぶん の よっきゅう を おさえる こと も ひつよう です",
    "exTranslation": "Sometimes I need to control my desires.",
    "category": "Verbal Noun"
  },
  {
    "term": "ぴったり",
    "termReading": "ぴったり",
    "termTranslation": "exactly right, perfect",
    "ex": "この服はあなたにぴったりですね。",
    "exReading": "この ふく は あなた に ぴったり です ね",
    "exTranslation": "These clothes suit you perfectly.",
    "category": "Adjectival Noun"
  },
  {
    "term": "表面",
    "termReading": "ひょうめん",
    "termTranslation": "surface, appearance",
    "ex": "月の表面にはクレーターがたくさんあるね。",
    "exReading": "つき の ひょうめん に は くれーたー が たくさん ある ね",
    "exTranslation": "There are a lot of craters on the surface of the moon.",
    "category": "Noun"
  },
  {
    "term": "場面",
    "termReading": "ばめん",
    "termTranslation": "scene, situation",
    "ex": "ここがいちばん面白い場面です。",
    "exReading": "ここ が いちばん おもしろい ばめん です",
    "exTranslation": "This is the most interesting place.",
    "category": "Noun"
  },
  {
    "term": "面する",
    "termReading": "めんする",
    "termTranslation": "face",
    "ex": "私の家は川に面しています。",
    "exReading": "わたし の いえ は かわ に めんして います",
    "exTranslation": "My house faces the river.",
    "category": "Verb"
  },
  {
    "term": "方面",
    "termReading": "ほうめん",
    "termTranslation": "direction",
    "ex": "沖縄方面にお出かけの方は台風にご注意ください。",
    "exReading": "おきなわ ほうめん に おでかけ の かた は たいふう に ごちゅうい ください",
    "exTranslation": "People going in the direction of Okinawa should be careful of typhoons.",
    "category": "Noun"
  },
  {
    "term": "半面",
    "termReading": "はんめん",
    "termTranslation": "profile, one side",
    "ex": "テニスコートの半面を使って試合をしたの。",
    "exReading": "てにすこーと の はんめん を つかって しあい を した の",
    "exTranslation": "We played tennis using one side of the court.",
    "category": "Noun"
  },
  {
    "term": "まさか",
    "termReading": "まさか",
    "termTranslation": "never in a million years",
    "ex": "まさか車が当たるとは思わなかった。",
    "exReading": "まさか くるま が あたる と は おもわなかった",
    "exTranslation": "I never imagined I would win the car!",
    "category": "Adverb"
  },
  {
    "term": "面",
    "termReading": "めん",
    "termTranslation": "surface, aspect",
    "ex": "彼の意外な面を見た。",
    "exReading": "かれ の いがい な めん を みた",
    "exTranslation": "I saw an unexpected side of him.",
    "category": "Noun"
  },
  {
    "term": "真っ先",
    "termReading": "まっさき",
    "termTranslation": "first thing, forefront",
    "ex": "合格を真っ先に両親に伝えたの。",
    "exReading": "ごうかく を まっさき に りょうしん に つたえた の",
    "exTranslation": "I told my parents first of all that I had passed the exam.",
    "category": "Noun"
  },
  {
    "term": "真夏",
    "termReading": "まなつ",
    "termTranslation": "midsummer",
    "ex": "真夏のビールはおいしい。",
    "exReading": "まなつ の びーる は おいしい",
    "exTranslation": "Beer is delicious in midsummer.",
    "category": "Noun"
  },
  {
    "term": "真夜中",
    "termReading": "まよなか",
    "termTranslation": "midnight, middle of the night",
    "ex": "彼は真夜中に帰ってきたの。",
    "exReading": "かれ は まよなか に かえって きた の",
    "exTranslation": "He came back at midnight.",
    "category": "Noun"
  },
  {
    "term": "真っ最中",
    "termReading": "まっさいちゅう",
    "termTranslation": "midst",
    "ex": "今は試験の真っ最中です。",
    "exReading": "いま は しけん の まっさいちゅう です",
    "exTranslation": "I'm right in the middle of examinations.",
    "category": "Noun"
  },
  {
    "term": "どっと",
    "termReading": "どっと",
    "termTranslation": "burst (out laughing)",
    "ex": "観衆がどっと笑ったんだ。",
    "exReading": "かんしゅう が どっと わらった ん だ",
    "exTranslation": "The audience burst out laughing.",
    "category": "Adverb"
  },
  {
    "term": "真心",
    "termReading": "まごころ",
    "termTranslation": "sincerity, true heart",
    "ex": "彼女は真心をこめて彼にケーキを作ったよ。",
    "exReading": "かのじょ は まごころ を こめて かれ に けーき を つくった よ",
    "exTranslation": "She put her heart into baking him a cake.",
    "category": "Noun"
  },
  {
    "term": "無色",
    "termReading": "むしょく",
    "termTranslation": "colorless, neutral",
    "ex": "アルコールは無色です。",
    "exReading": "あるこーる は むしょく です",
    "exTranslation": "Alcohol is colorless.",
    "category": "Noun"
  },
  {
    "term": "ばら色",
    "termReading": "ばらいろ",
    "termTranslation": "rose color",
    "ex": "彼女の人生はばら色だったの。",
    "exReading": "かのじょ の じんせい は ばらいろ だった の 。",
    "exTranslation": "Her life was rosy.",
    "category": "Noun"
  },
  {
    "term": "水色",
    "termReading": "みずいろ",
    "termTranslation": "sky blue, turquoise",
    "ex": "箱に水色のリボンがかかっていたの。",
    "exReading": "はこ に みずいろ の りぼん が かかって いた の",
    "exTranslation": "A light blue ribbon was tied around the box.",
    "category": "Noun"
  },
  {
    "term": "長方形",
    "termReading": "ちょうほうけい",
    "termTranslation": "rectangle",
    "ex": "私の部屋は長方形です。",
    "exReading": "わたし の へや は ちょうほうけい です",
    "exTranslation": "My room is rectangular.",
    "category": "Noun"
  },
  {
    "term": "地形",
    "termReading": "ちけい",
    "termTranslation": "geographical features, topography",
    "ex": "ここはなだらかな地形です。",
    "exReading": "ここ は なだらか な ちけい です",
    "exTranslation": "The landscape here features gentle slopes.",
    "category": "Noun"
  },
  {
    "term": "もうける",
    "termReading": "もうける",
    "termTranslation": "make a profit",
    "ex": "先月は株で100万円もうけたの。",
    "exReading": "せんげつ は かぶ で 100 まん えん もうけた の",
    "exTranslation": "I gained a million yen on the stock market last month.",
    "category": "Verb"
  },
  {
    "term": "種",
    "termReading": "たね",
    "termTranslation": "seed",
    "ex": "プランターにトマトの種をまきました。",
    "exReading": "ぷらんたーに とまと の たね を まきました 。",
    "exTranslation": "I planted tomato seeds in the planter.",
    "category": "Noun"
  },
  {
    "term": "分類",
    "termReading": "ぶんるい",
    "termTranslation": "classification",
    "ex": "これらの本を分類してください。",
    "exReading": "これら の ほん を ぶんるい して ください",
    "exTranslation": "Please classify these books.",
    "category": "Verbal Noun"
  },
  {
    "term": "直前",
    "termReading": "ちょくぜん",
    "termTranslation": "just before, immediately prior",
    "ex": "彼は旅行の直前に熱が出たの。",
    "exReading": "かれ は りょこう の ちょくぜん に ねつ が でた の",
    "exTranslation": "He got a fever just before going on holiday.",
    "category": "Noun"
  },
  {
    "term": "直ちに",
    "termReading": "ただちに",
    "termTranslation": "at once, immediately",
    "ex": "直ちに会社に戻ってください。",
    "exReading": "ただちに かいしゃ に もどって ください",
    "exTranslation": "Please return to the office at once.",
    "category": "Adverb"
  },
  {
    "term": "見直す",
    "termReading": "みなおす",
    "termTranslation": "reconsider, change one's opinion for the better",
    "ex": "彼のことを見直しました。",
    "exReading": "かれ の こと を みなおしました",
    "exTranslation": "I changed my opinion of him.",
    "category": "Verb"
  },
  {
    "term": "ゆったり",
    "termReading": "ゆったり",
    "termTranslation": "comfortable",
    "ex": "彼女はゆったりした服を着ていますね。",
    "exReading": "かのじょ は ゆったり した ふく を きて います ね",
    "exTranslation": "She's wearing baggy clothes.",
    "category": "Adverb"
  },
  {
    "term": "直後",
    "termReading": "ちょくご",
    "termTranslation": "immediately after",
    "ex": "食べた直後に寝ないほうがいいよ。",
    "exReading": "たべた ちょくご に ねない ほう が いい よ",
    "exTranslation": "You should not sleep immediately after having eaten.",
    "category": "Noun"
  },
  {
    "term": "立ち直る",
    "termReading": "たちなおる",
    "termTranslation": "recover, regain one's footing",
    "ex": "彼女は失敗から立ち直ったよ。",
    "exReading": "かのじょ は しっぱい から たちなおった よ",
    "exTranslation": "She recovered from the failure.",
    "category": "Verb"
  },
  {
    "term": "やり直し",
    "termReading": "やりなおし",
    "termTranslation": "doing over, redoing",
    "ex": "仕事がやり直しになった。",
    "exReading": "しごと が やりなおし に なった",
    "exTranslation": "My work had to be re-done.",
    "category": "Noun"
  },
  {
    "term": "やり直す",
    "termReading": "やりなおす",
    "termTranslation": "do over again",
    "ex": "急いでやり直します。",
    "exReading": "いそい で やりなおします。",
    "exTranslation": "I'll re-do it immediately.",
    "category": "Verb"
  },
  {
    "term": "直通",
    "termReading": "ちょくつう",
    "termTranslation": "direct link",
    "ex": "これは私の直通の電話番号です。",
    "exReading": "これ は わたし の ちょくつう の でんわ ばんごう です 。",
    "exTranslation": "This is my direct telephone number.",
    "category": "Verbal Noun"
  },
  {
    "term": "ちっとも",
    "termReading": "ちっとも",
    "termTranslation": "nothing at all",
    "ex": "彼女が結婚したなんて、ちっとも知らなかった。",
    "exReading": "かのじょ が けっこん した なんて ちっとも しらなかった",
    "exTranslation": "I had no idea that she'd gotten married.",
    "category": "Adverb"
  },
  {
    "term": "面接",
    "termReading": "めんせつ",
    "termTranslation": "interview",
    "ex": "新しい仕事の面接に行ってきました。",
    "exReading": "あたらしい しごと の めんせつ に いって きました",
    "exTranslation": "I went to an interview for a new job.",
    "category": "Verbal Noun"
  },
  {
    "term": "無線",
    "termReading": "むせん",
    "termTranslation": "radio, wireless",
    "ex": "運転手が無線で話していたの。",
    "exReading": "うんてんしゅ が むせん で はなして いた の",
    "exTranslation": "The driver was speaking on the radio.",
    "category": "Noun"
  },
  {
    "term": "線路",
    "termReading": "せんろ",
    "termTranslation": "line, track",
    "ex": "子猫が線路に迷い込んだぞ。",
    "exReading": "こねこ が せんろ に まよいこんだ ぞ",
    "exTranslation": "The kitten strayed onto the railway track.",
    "category": "Noun"
  },
  {
    "term": "直線",
    "termReading": "ちょくせん",
    "termTranslation": "straight line",
    "ex": "直線を描いてください。",
    "exReading": "ちょくせん を かいて ください",
    "exTranslation": "Please draw a straight line.",
    "category": "Noun"
  },
  {
    "term": "電線",
    "termReading": "でんせん",
    "termTranslation": "electric wire",
    "ex": "風で電線が揺れていますね。",
    "exReading": "かぜ で でんせん が ゆれて います ね",
    "exTranslation": "The electric wires are shaking in the wind.",
    "category": "Noun"
  },
  {
    "term": "脱線",
    "termReading": "だっせん",
    "termTranslation": "derailment",
    "ex": "地震で電車が脱線したよ。",
    "exReading": "じしん で でんしゃ が だっせん した よ",
    "exTranslation": "The train was derailed due to the earthquake.",
    "category": "Verbal Noun"
  },
  {
    "term": "ドレス",
    "termReading": "ドレス",
    "termTranslation": "dress",
    "ex": "昨日素敵なドレスを買いました。",
    "exReading": "きのう すてき な どれす を かいました",
    "exTranslation": "I bought a wonderful dress yesterday.",
    "category": "Noun"
  },
  {
    "term": "白線",
    "termReading": "はくせん",
    "termTranslation": "white line, warning line",
    "ex": "白線の内側に下がってお待ちください。",
    "exReading": "はくせん の うちがわ に さがって おまち ください",
    "exTranslation": "Please stand behind the white lines as you wait.",
    "category": "Noun"
  },
  {
    "term": "方角",
    "termReading": "ほうがく",
    "termTranslation": "direction",
    "ex": "私と彼は帰る方角が同じです。",
    "exReading": "わたし と かれ は かえる ほうがく が おなじ です",
    "exTranslation": "He and I go home in the same direction.",
    "category": "Noun"
  },
  {
    "term": "直角",
    "termReading": "ちょっかく",
    "termTranslation": "right angle",
    "ex": "ここで直角に曲がってください。",
    "exReading": "ここ で ちょっかく に まがって ください",
    "exTranslation": "Please make a 90 degree turn here.",
    "category": "Noun"
  },
  {
    "term": "町角",
    "termReading": "まちかど",
    "termTranslation": "street corner",
    "ex": "町角にカフェがありました。",
    "exReading": "まちかど に かふぇ が ありました",
    "exTranslation": "There was a cafe on the street corner.",
    "category": "Noun"
  },
  {
    "term": "真四角",
    "termReading": "ましかく",
    "termTranslation": "square",
    "ex": "彼の部屋には真四角な窓があったんだ。",
    "exReading": "かれ の へや に は ましかく な まど が あった ん だ",
    "exTranslation": "There was a square window in his room.",
    "category": "Adjectival Noun"
  },
  {
    "term": "パンク",
    "termReading": "パンク",
    "termTranslation": "puncture",
    "ex": "自転車のタイヤがパンクしました。",
    "exReading": "じてんしゃ の たいや が ぱんく しました",
    "exTranslation": "My bike got a flat tire.",
    "category": "Verbal Noun"
  },
  {
    "term": "共に",
    "termReading": "ともに",
    "termTranslation": "together, both",
    "ex": "最後まで共にがんばりましょう。",
    "exReading": "さいご まで ともに がんばりましょう",
    "exTranslation": "Let's try our best right to the end.",
    "category": "Adverb"
  },
  {
    "term": "同時",
    "termReading": "どうじ",
    "termTranslation": "simultaneous",
    "ex": "二人の走者は同時にゴールしたよ。",
    "exReading": "ふたり の そうしゃ は どうじ に ごーる した よ",
    "exTranslation": "The two runners reached the goal at the same time.",
    "category": "Noun"
  },
  {
    "term": "同一",
    "termReading": "どういつ",
    "termTranslation": "sameness, identity",
    "ex": "この人とその人は、同一人物ですか。",
    "exReading": "この ひと と その ひと は どういつ じんぶつ です か",
    "exTranslation": "Are this person and that person the same?",
    "category": "Adjectival Noun"
  },
  {
    "term": "同情",
    "termReading": "どうじょう",
    "termTranslation": "sympathy, compassion",
    "ex": "友人は私に同情してくれたよ。",
    "exReading": "ゆうじん は わたし に どうじょう して くれた よ",
    "exTranslation": "My friend sympathized with me.",
    "category": "Verbal Noun"
  },
  {
    "term": "同性",
    "termReading": "どうせい",
    "termTranslation": "of the same sex",
    "ex": "同性の友達より異性の友達のほうが多いよ。",
    "exReading": "どうせい の ともだち より いせい の ともだち の ほう が おおい よ",
    "exTranslation": "I have more friends of the opposite sex than I do of the same sex.",
    "category": "Noun"
  },
  {
    "term": "のんびり",
    "termReading": "のんびり",
    "termTranslation": "take it easy",
    "ex": "休みの日は家でのんびり過ごします。",
    "exReading": "やすみ の ひ は うち で のんびり すごします",
    "exTranslation": "I spend my days off relaxing at home.",
    "category": "Adverb"
  },
  {
    "term": "違いない",
    "termReading": "ちがいない",
    "termTranslation": "no mistaking it, for certain",
    "ex": "彼は成功するに違いないわ。",
    "exReading": "かれ は せいこう する に ちがいない わ",
    "exTranslation": "He will certainly succeed.",
    "category": "Adjective"
  },
  {
    "term": "人違い",
    "termReading": "ひとちがい",
    "termTranslation": "mistaken identity",
    "ex": "すみません、人違いでした。",
    "exReading": "すみません ひとちがい でした",
    "exTranslation": "I'm sorry I mistook you for someone else.",
    "category": "Verbal Noun"
  },
  {
    "term": "見違える",
    "termReading": "みちがえる",
    "termTranslation": "mistake, fail to recognize",
    "ex": "掃除をしたら部屋が見違えるようになったの。",
    "exReading": "そうじ を したら へや が みちがえる よう に なった の",
    "exTranslation": "I began to see the room differently as I cleaned it.",
    "category": "Verb"
  },
  {
    "term": "違い",
    "termReading": "ちがい",
    "termTranslation": "difference, mistake",
    "ex": "この二つには大きな違いがある。",
    "exReading": "この ふたつ に は おおき な ちがい が ある",
    "exTranslation": "There's a big difference between these two.",
    "category": "Noun"
  },
  {
    "term": "似る",
    "termReading": "にる",
    "termTranslation": "resemble, be alike",
    "ex": "女の子は話し方まで母親に似るわね。",
    "exReading": "おんな の こ は はなしかた まで ははおや に にる わ ね",
    "exTranslation": "The girl even talks like her mother.",
    "category": "Verb"
  },
  {
    "term": "はめる",
    "termReading": "はめる",
    "termTranslation": "put on, wear",
    "ex": "彼女は指輪をたくさんはめていますね。",
    "exReading": "かのじょ は ゆびわ を たくさん はめて います ね",
    "exTranslation": "She wears a lot of rings.",
    "category": "Verb"
  },
  {
    "term": "似合う",
    "termReading": "にあう",
    "termTranslation": "suit, match well",
    "ex": "彼女は着物がよく似合います。",
    "exReading": "かのじょ は きもの が よく にあいます",
    "exTranslation": "Kimonos suit her well.",
    "category": "Verb"
  },
  {
    "term": "似せる",
    "termReading": "にせる",
    "termTranslation": "imitate, forge",
    "ex": "彼の字に似せて書きました。",
    "exReading": "かれ の じ に にせて かきました",
    "exTranslation": "I wrote it by imitating his handwriting.",
    "category": "Verb"
  },
  {
    "term": "旅",
    "termReading": "たび",
    "termTranslation": "journey, travel",
    "ex": "姉はよく旅をします。",
    "exReading": "あね は よく たび を します",
    "exTranslation": "My big sister often travels.",
    "category": "Verbal Noun"
  },
  {
    "term": "旅客",
    "termReading": "りょかく",
    "termTranslation": "traveler, passenger",
    "ex": "その便は外国人の旅客が多かったよ。",
    "exReading": "その びん は がいこくじん の りょかく が おおかった よ",
    "exTranslation": "There were a lot of foreign travelers on that flight.",
    "category": "Noun"
  },
  {
    "term": "旅費",
    "termReading": "りょひ",
    "termTranslation": "traveling expenses",
    "ex": "父が旅費を出してくれました。",
    "exReading": "ちち が りょひ を だして くれました",
    "exTranslation": "My father paid for my travel expenses.",
    "category": "Noun"
  },
  {
    "term": "和らげる",
    "termReading": "やわらげる",
    "termTranslation": "soften, relieve",
    "ex": "ユーモアは場の雰囲気を和らげるわね。",
    "exReading": "ゆーもあ は ば の ふんいき を やわらげる わ ね",
    "exTranslation": "Humor can soften the mood.",
    "category": "Verb"
  },
  {
    "term": "ふた",
    "termReading": "ふた",
    "termTranslation": "lid, cover",
    "ex": "箱にふたをしたよ。",
    "exReading": "はこ に ふた を した よ",
    "exTranslation": "I shut the lid on the box.",
    "category": "Noun"
  },
  {
    "term": "和語",
    "termReading": "わご",
    "termTranslation": "native Japanese word",
    "ex": "日本で生まれた言葉を和語といいます。",
    "exReading": "にっぽん で うまれた ことば を わご と いいます",
    "exTranslation": "The words created in Japan are called indigenous Japanese words.",
    "category": "Noun"
  },
  {
    "term": "和風",
    "termReading": "わふう",
    "termTranslation": "Japanese style",
    "ex": "夕食に和風パスタを作ったよ。",
    "exReading": "ゆうしょく に わふう ぱすた を つくった よ",
    "exTranslation": "I made Japanese-style pasta for supper.",
    "category": "Noun"
  },
  {
    "term": "和食",
    "termReading": "わしょく",
    "termTranslation": "Japanese-style food",
    "ex": "私は和食が好きです。",
    "exReading": "わたし は わしょく が すき です",
    "exTranslation": "I like Japanese food.",
    "category": "Noun"
  },
  {
    "term": "和やか",
    "termReading": "なごやか",
    "termTranslation": "peaceful, mild",
    "ex": "彼らは和やかに食事をした。",
    "exReading": "かれら は なごやか に しょくじ を した",
    "exTranslation": "They ate peacefully.",
    "category": "Adjective"
  },
  {
    "term": "和らぐ",
    "termReading": "やわらぐ",
    "termTranslation": "soften, calm down",
    "ex": "この曲を聞くと気持ちが和らぎます。",
    "exReading": "この きょく を きく と きもち が やわらぎます",
    "exTranslation": "When I hear this tune, I feel at ease.",
    "category": "Verb"
  },
  {
    "term": "レポート",
    "termReading": "レポート",
    "termTranslation": "report, paper",
    "ex": "教授にレポートを提出しました。",
    "exReading": "きょうじゅ に れぽーと を ていしゅつ しました",
    "exTranslation": "I submitted the paper to the professor.",
    "category": "Verbal Noun"
  },
  {
    "term": "和式",
    "termReading": "わしき",
    "termTranslation": "Japanese style",
    "ex": "あの家のトイレは和式です。",
    "exReading": "あの うち の といれ は わしき です",
    "exTranslation": "The toilet in that house is Japanese style.",
    "category": "Noun"
  },
  {
    "term": "和英",
    "termReading": "わえい",
    "termTranslation": "Japanese-English",
    "ex": "私は和英辞書をよく使います。",
    "exReading": "わたし は わえい じしょ を よく つかいます",
    "exTranslation": "I often use a Japanese-English dictionary.",
    "category": "Noun"
  },
  {
    "term": "東洋",
    "termReading": "とうよう",
    "termTranslation": "Orient",
    "ex": "彼は東洋文化を研究しているよ。",
    "exReading": "かれ は とうようぶんか を けんきゅう して いる よ",
    "exTranslation": "He researches Asian culture.",
    "category": "Noun"
  },
  {
    "term": "洋風",
    "termReading": "ようふう",
    "termTranslation": "Western style",
    "ex": "私は洋風の家に住んでいます。",
    "exReading": "わたし は ようふう の いえ に すんで います",
    "exTranslation": "I live in a Western-style house.",
    "category": "Noun"
  },
  {
    "term": "洋画",
    "termReading": "ようが",
    "termTranslation": "foreign film",
    "ex": "私は週に3本洋画を見ます。",
    "exReading": "わたし は しゅう に 3 ぼん ようが を みます",
    "exTranslation": "I watch three foreign films a week.",
    "category": "Noun"
  },
  {
    "term": "マネー",
    "termReading": "マネー",
    "termTranslation": "money, finance",
    "ex": "インターネットでマネー情報をチェックしたよ。",
    "exReading": "いんたーねっと で まねー じょうほう を ちぇっく した よ",
    "exTranslation": "I checked some financial information on the Internet.",
    "category": "Noun"
  },
  {
    "term": "洋式",
    "termReading": "ようしき",
    "termTranslation": "Western style",
    "ex": "彼の家のトイレは洋式です。",
    "exReading": "かれ の いえ の といれ は ようしき です",
    "exTranslation": "His house has a Western-style toilet.",
    "category": "Noun"
  },
  {
    "term": "洋食",
    "termReading": "ようしょく",
    "termTranslation": "Western cooking, European dish",
    "ex": "昨日の晩御飯は洋食でした。",
    "exReading": "きのう の ばんごはん は ようしょく でした",
    "exTranslation": "Last night's dinner was Western food.",
    "category": "Noun"
  },
  {
    "term": "洋間",
    "termReading": "ようま",
    "termTranslation": "Western-style room",
    "ex": "彼の家には洋間があります。",
    "exReading": "かれ の いえ に は ようま が あります",
    "exTranslation": "There's a Western-style room in his house.",
    "category": "Noun"
  },
  {
    "term": "和服",
    "termReading": "わふく",
    "termTranslation": "Japanese clothes, kimono",
    "ex": "彼女は和服がよく似合う。",
    "exReading": "かのじょ は わふく が よく にあう",
    "exTranslation": "Traditional Japanese clothes suit her well.",
    "category": "Noun"
  },
  {
    "term": "待合室",
    "termReading": "まちあいしつ",
    "termTranslation": "waiting room",
    "ex": "待合室はとても込んでいたよ。",
    "exReading": "まちあいしつ は とても こんで いた よ",
    "exTranslation": "The waiting room was very crowded.",
    "category": "Noun"
  },
  {
    "term": "和室",
    "termReading": "わしつ",
    "termTranslation": "Japanese style room",
    "ex": "この和室の天井は低いね。",
    "exReading": "この わしつ の てんじょう は ひくい ね",
    "exTranslation": "The ceiling in this Japanese-style room is low.",
    "category": "Noun"
  },
  {
    "term": "もろい",
    "termReading": "もろい",
    "termTranslation": "weak, brittle",
    "ex": "この岩は意外ともろい。",
    "exReading": "この いわ は いがい と もろい",
    "exTranslation": "This rock is unexpectedly fragile.",
    "category": "Adjective"
  },
  {
    "term": "洋室",
    "termReading": "ようしつ",
    "termTranslation": "Western-style room",
    "ex": "このテーブルは洋室に合わない。",
    "exReading": "この てーぶる は ようしつ に あわない",
    "exTranslation": "This table is not suited to a Western-style room.",
    "category": "Noun"
  },
  {
    "term": "窓口",
    "termReading": "まどぐち",
    "termTranslation": "window",
    "ex": "お振込みは3番の窓口です。",
    "exReading": "おふりこみ は 3 ばん の まどぐち です",
    "exTranslation": "Bank transfers are at window number 3.",
    "category": "Noun"
  },
  {
    "term": "父母",
    "termReading": "ふぼ",
    "termTranslation": "father and mother, parents",
    "ex": "学校から父母に連絡があったんだ。",
    "exReading": "がっこう から ふぼ に れんらく が あった ん だ",
    "exTranslation": "My parents were contacted by the school.",
    "category": "Noun"
  },
  {
    "term": "母親",
    "termReading": "ははおや",
    "termTranslation": "mother",
    "ex": "彼女は2才の子の母親です。",
    "exReading": "かのじょ は 2 さい の こ の ははおや です",
    "exTranslation": "She's the mother of a two-year-old kid.",
    "category": "Noun"
  },
  {
    "term": "父親",
    "termReading": "ちちおや",
    "termTranslation": "father",
    "ex": "彼の父親は先生です。",
    "exReading": "かれ の ちちおや は せんせい です 。",
    "exTranslation": "His father is a teacher.",
    "category": "Noun"
  },
  {
    "term": "ユーモア",
    "termReading": "ユーモア",
    "termTranslation": "humor",
    "ex": "彼女はユーモアのセンスがあります。",
    "exReading": "かのじょ は ゆーもあ の せんす が あります 。",
    "exTranslation": "She has a sense of humor.",
    "category": "Noun"
  },
  {
    "term": "父兄",
    "termReading": "ふけい",
    "termTranslation": "parents, legal guardians",
    "ex": "父兄の方々はこちらへどうぞ。",
    "exReading": "ふけい の かたがた は こちら へ どうぞ",
    "exTranslation": "This way please, parents.",
    "category": "Noun"
  },
  {
    "term": "第一",
    "termReading": "だいいち",
    "termTranslation": "number one, the best",
    "ex": "私には仕事が第一です。",
    "exReading": "わたし に は しごと が だいいち です",
    "exTranslation": "My first priority is my job.",
    "category": "Noun"
  },
  {
    "term": "利息",
    "termReading": "りそく",
    "termTranslation": "interest",
    "ex": "預金には利息がつきます。",
    "exReading": "よきん に は りそく が つきます",
    "exTranslation": "Savings gain interest.",
    "category": "Noun"
  },
  {
    "term": "若々しい",
    "termReading": "わかわかしい",
    "termTranslation": "youthful, young-looking",
    "ex": "彼女はとても若々しい人です。",
    "exReading": "かのじょ は とても わかわかしい ひと です",
    "exTranslation": "She's a very youthful person.",
    "category": "Adjective"
  },
  {
    "term": "読者",
    "termReading": "どくしゃ",
    "termTranslation": "reader, subscriber",
    "ex": "新聞の読者の投書欄は好きです。",
    "exReading": "しんぶん の どくしゃ の とうしょらん は すき です",
    "exTranslation": "I like the readers' letters column in the newspaper.",
    "category": "Noun"
  },
  {
    "term": "バック",
    "termReading": "バック",
    "termTranslation": "back",
    "ex": "車のギアをバックに入れたよ。",
    "exReading": "くるま の ぎあ を ばっく に いれた よ",
    "exTranslation": "I put the car into reverse gear.",
    "category": "Verbal Noun"
  },
  {
    "term": "歩行者",
    "termReading": "ほこうしゃ",
    "termTranslation": "pedestrian",
    "ex": "歩行者は道の右側を歩いてください。",
    "exReading": "ほこうしゃ は みち の みぎがわ を あるいて ください",
    "exTranslation": "Pedestrians must walk on the right side of the road.",
    "category": "Noun"
  },
  {
    "term": "文学者",
    "termReading": "ぶんがくしゃ",
    "termTranslation": "literary person, literati",
    "ex": "彼は有名な文学者です。",
    "exReading": "かれ は ゆうめい な ぶんがくしゃ です",
    "exTranslation": "He's a famous literati.",
    "category": "Noun"
  },
  {
    "term": "歩行者天国",
    "termReading": "ほこうしゃてんごく",
    "termTranslation": "vehicle-free promenade",
    "ex": "日曜日はこの通りが歩行者天国になります。",
    "exReading": "にちようび は この とおり が ほこうしゃてんごく に なります",
    "exTranslation": "This street is turned into a vehicle-free promenade on Sundays.",
    "category": "Noun"
  },
  {
    "term": "者",
    "termReading": "もの",
    "termTranslation": "person, fellow",
    "ex": "私は先ほど電話した者です。",
    "exReading": "わたし は さきほど でんわ した もの です",
    "exTranslation": "I'm the person who called a while earlier.",
    "category": "Noun"
  },
  {
    "term": "結ぶ",
    "termReading": "むすぶ",
    "termTranslation": "tie, join",
    "ex": "彼女は髪にリボンを結んだね。",
    "exReading": "かのじょ は かみ に りぼん を むすんだ ね",
    "exTranslation": "She tied a ribbon in her hair.",
    "category": "Verb"
  },
  {
    "term": "結び",
    "termReading": "むすび",
    "termTranslation": "end, close",
    "ex": "彼は会の結びとしてスピーチをしたよ。",
    "exReading": "かれ は かい の むすび と して すぴーち を した よ",
    "exTranslation": "He made a speech to close the meeting.",
    "category": "Noun"
  },
  {
    "term": "そびえる",
    "termReading": "そびえる",
    "termTranslation": "tower",
    "ex": "いくつもの高層ビルがそびえていたんだ。",
    "exReading": "いくつ も の こうそう びる が そびえていた ん だ 。",
    "exTranslation": "A number of tall buildings towered high.",
    "category": "Verb"
  },
  {
    "term": "未婚",
    "termReading": "みこん",
    "termTranslation": "unmarried",
    "ex": "彼はまだ未婚です。",
    "exReading": "かれ は まだ みこん です",
    "exTranslation": "He's still single.",
    "category": "Noun"
  },
  {
    "term": "果たす",
    "termReading": "はたす",
    "termTranslation": "accomplish, realize",
    "ex": "彼はしっかりと責任を果たしました。",
    "exReading": "かれ は しっかり と せきにん を はたしました",
    "exTranslation": "He fulfilled his responsibilities diligently.",
    "category": "Verb"
  },
  {
    "term": "果たして",
    "termReading": "はたして",
    "termTranslation": "as was expected, really",
    "ex": "果たして彼は現れるだろうか。",
    "exReading": "はたして かれ は あらわれるだろう か",
    "exTranslation": "Will he really show up in the end?",
    "category": "Adverb"
  },
  {
    "term": "日課",
    "termReading": "にっか",
    "termTranslation": "daily routine",
    "ex": "犬の散歩は私の日課です。",
    "exReading": "いぬ の さんぽ は わたし の にっか です",
    "exTranslation": "Walking the dog is my daily routine.",
    "category": "Noun"
  },
  {
    "term": "有効",
    "termReading": "ゆうこう",
    "termTranslation": "effective, valid",
    "ex": "私の免許は来年まで有効です。",
    "exReading": "わたし の めんきょ は らいねん まで ゆうこう です",
    "exTranslation": "My license is effective until next year.",
    "category": "Adjectival Noun"
  },
  {
    "term": "タレント",
    "termReading": "タレント",
    "termTranslation": "celebrity, talent",
    "ex": "彼はタレントなのでよくテレビに出ています。",
    "exReading": "かれ は たれんと な の で よく てれび に でて います",
    "exTranslation": "Since he's a celebrity, he often appears on TV.",
    "category": "Noun"
  },
  {
    "term": "無効",
    "termReading": "むこう",
    "termTranslation": "invalidity, ineffectiveness",
    "ex": "このクーポンはもう無効です。",
    "exReading": "この くーぽん は もう むこう です",
    "exTranslation": "This coupon is already invalid.",
    "category": "Adjectival Noun"
  },
  {
    "term": "自ら",
    "termReading": "みずから",
    "termTranslation": "oneself",
    "ex": "社長自らがその会社と交渉したよ。",
    "exReading": "しゃちょう みずから が その かいしゃ と こうしょう した よ",
    "exTranslation": "The president himself negotiated with the company.",
    "category": "Adverb"
  },
  {
    "term": "不自然",
    "termReading": "ふしぜん",
    "termTranslation": "unnatural, artificial",
    "ex": "彼の態度はどこか不自然だったな。",
    "exReading": "かれ の たいど は どこか ふしぜん だった な",
    "exTranslation": "His behavior was somewhat unnatural.",
    "category": "Adjectival Noun"
  },
  {
    "term": "不自由",
    "termReading": "ふじゆう",
    "termTranslation": "inconvenience, discomfort",
    "ex": "彼は不自由な暮らしに慣れたようだね。",
    "exReading": "かれ は ふじゆう な くらし に なれた よう だ ね",
    "exTranslation": "It seems that he's gotten used to his restricted lifestyle.",
    "category": "Verbal Noun"
  },
  {
    "term": "通信",
    "termReading": "つうしん",
    "termTranslation": "correspondence, communication",
    "ex": "通信教育はとても便利です。",
    "exReading": "つうしん きょういく は とても べんり です",
    "exTranslation": "Distance learning is very convenient.",
    "category": "Verbal Noun"
  },
  {
    "term": "たまに",
    "termReading": "たまに",
    "termTranslation": "occasionally, by chance",
    "ex": "彼はたまに料理をします。",
    "exReading": "かれ は たまに りょうり を します",
    "exTranslation": "He cooks occasionally.",
    "category": "Adverb"
  },
  {
    "term": "頼る",
    "termReading": "たよる",
    "termTranslation": "rely on, depend on",
    "ex": "人に頼らないで、自分でやってごらん。",
    "exReading": "ひと に たよらない で じぶん で やって ごらん",
    "exTranslation": "Do it yourself without relying on other people.",
    "category": "Verb"
  },
  {
    "term": "頼み",
    "termReading": "たのみ",
    "termTranslation": "request, favor",
    "ex": "あなたに頼みがあるんですけど。",
    "exReading": "あなた に たのみ が ある ん です けど",
    "exTranslation": "By the way, I have a favor to ask you.",
    "category": "Noun"
  },
  {
    "term": "頼もしい",
    "termReading": "たのもしい",
    "termTranslation": "reliable, dependable",
    "ex": "彼は頼もしい人です。",
    "exReading": "かれ は たのもしい ひと です",
    "exTranslation": "He's a dependable person.",
    "category": "Adjective"
  },
  {
    "term": "民間",
    "termReading": "みんかん",
    "termTranslation": "citizens, civilians",
    "ex": "その土地は民間企業に売却されたんだ。",
    "exReading": "その とち は みんかん きぎょう に ばいきゃく された ん だ",
    "exTranslation": "The land was sold off to a private company.",
    "category": "Noun"
  },
  {
    "term": "民族",
    "termReading": "みんぞく",
    "termTranslation": "race, people",
    "ex": "私は民族の歴史に興味があります。",
    "exReading": "わたし は みんぞく の れきし に きょうみ が あります",
    "exTranslation": "I'm interested in the history of ethnic groups.",
    "category": "Noun"
  },
  {
    "term": "ペンキ",
    "termReading": "ペンキ",
    "termTranslation": "paint",
    "ex": "床にペンキをこぼしてしまいました。",
    "exReading": "ゆか に ぺんき を こぼして しまいました",
    "exTranslation": "I spilt paint on the floor.",
    "category": "Noun"
  },
  {
    "term": "持ち主",
    "termReading": "もちぬし",
    "termTranslation": "owner, proprietor",
    "ex": "この自転車の持ち主は誰ですか。",
    "exReading": "この じてんしゃ の もちぬし は だれ です か",
    "exTranslation": "Who is the owner of this bicycle?",
    "category": "Noun"
  },
  {
    "term": "民主",
    "termReading": "みんしゅ",
    "termTranslation": "democracy",
    "ex": "民主主義について勉強しました。",
    "exReading": "みんしゅしゅぎ に ついて べんきょう しました",
    "exTranslation": "We studied democracy.",
    "category": "Noun"
  },
  {
    "term": "家主",
    "termReading": "やぬし",
    "termTranslation": "landlord",
    "ex": "家主は1階に住んでいます。",
    "exReading": "やぬし は 1 かい に すんで います",
    "exTranslation": "The landlord lives on the first floor.",
    "category": "Noun"
  },
  {
    "term": "定義",
    "termReading": "ていぎ",
    "termTranslation": "definition",
    "ex": "美しさを定義してください。",
    "exReading": "うつくしさ を ていぎ して ください",
    "exTranslation": "Please define beauty.",
    "category": "Verbal Noun"
  },
  {
    "term": "不思議",
    "termReading": "ふしぎ",
    "termTranslation": "mystery, wonder",
    "ex": "それはとても不思議な話だね。",
    "exReading": "それ は とても ふしぎ な はなし だ ね",
    "exTranslation": "It's a very mysterious story.",
    "category": "Adjectival Noun"
  },
  {
    "term": "理論",
    "termReading": "りろん",
    "termTranslation": "theory",
    "ex": "彼は大学で音楽の理論を勉強したんだよ。",
    "exReading": "かれ は だいがく で おんがく の りろん を べんきょう した ん だ よ",
    "exTranslation": "He studied music theory in college.",
    "category": "Noun"
  },
  {
    "term": "ベンチ",
    "termReading": "ベンチ",
    "termTranslation": "bench",
    "ex": "公園のベンチで本を読んでいます。",
    "exReading": "こうえん の べんち で ほん を よんで います",
    "exTranslation": "I'm reading a book on the park bench.",
    "category": "Noun"
  },
  {
    "term": "論理",
    "termReading": "ろんり",
    "termTranslation": "logic",
    "ex": "数学は論理に基づく学問です。",
    "exReading": "すうがく は ろんり に もとづく がくもん です",
    "exTranslation": "Mathematics is a field of study based on logic.",
    "category": "Noun"
  },
  {
    "term": "論文",
    "termReading": "ろんぶん",
    "termTranslation": "thesis, paper",
    "ex": "論文を仕上げないと卒業できませんよ。",
    "exReading": "ろんぶん を しあげない と そつぎょう できません よ",
    "exTranslation": "You can't graduate if you don't finish your thesis.",
    "category": "Noun"
  },
  {
    "term": "論じる",
    "termReading": "ろんじる",
    "termTranslation": "argue, discuss",
    "ex": "仲間と地球の未来について論じたの。",
    "exReading": "なかま と ちきゅう の みらい に ついて ろんじた の",
    "exTranslation": "I discussed the future of the earth with my friends.",
    "category": "Verb"
  },
  {
    "term": "無論",
    "termReading": "むろん",
    "termTranslation": "of course, no doubt",
    "ex": "ぼくは無論きみに賛成だ。",
    "exReading": "ぼく は むろん きみ に さんせい だ",
    "exTranslation": "Of course, I agree with you.",
    "category": "Adverb"
  },
  {
    "term": "世論",
    "termReading": "よろん",
    "termTranslation": "public opinion",
    "ex": "世論は新しい法律に反対です。",
    "exReading": "よろん は あたらしい ほうりつ に はんたい です",
    "exTranslation": "Public opinion is against the new law.",
    "category": "Noun"
  },
  {
    "term": "めいめい",
    "termReading": "めいめい",
    "termTranslation": "each, individual",
    "ex": "交通費はめいめい支払ってください。",
    "exReading": "こうつうひ は めいめい しはらって ください",
    "exTranslation": "Please pay the fare individually.",
    "category": "Adverb"
  },
  {
    "term": "論",
    "termReading": "ろん",
    "termTranslation": "argument, opinion",
    "ex": "彼の人生論は独特だな。",
    "exReading": "かれ の じんせいろん は どくとく だ な",
    "exTranslation": "His philosophy of life is unique.",
    "category": "Noun"
  },
  {
    "term": "反発",
    "termReading": "はんぱつ",
    "termTranslation": "reaction, backlash",
    "ex": "彼は先生に反発していたよ。",
    "exReading": "かれ は せんせい に はんぱつ して いた よ",
    "exTranslation": "He was rebelling against the teacher.",
    "category": "Verbal Noun"
  },
  {
    "term": "反映",
    "termReading": "はんえい",
    "termTranslation": "reflection",
    "ex": "新型車に研究の成果が反映されています。",
    "exReading": "しんがたしゃ に けんきゅう の せいか が はんえい されて います",
    "exTranslation": "The results of our research are reflected in the new model.",
    "category": "Verbal Noun"
  },
  {
    "term": "反論",
    "termReading": "はんろん",
    "termTranslation": "objection, counterargument",
    "ex": "反論がある人はどうぞ。",
    "exReading": "はんろん が ある ひと は どうぞ",
    "exTranslation": "Please speak up if you have an objection.",
    "category": "Verbal Noun"
  },
  {
    "term": "反する",
    "termReading": "はんする",
    "termTranslation": "oppose, act contrary to",
    "ex": "彼の行いはモラルに反しています。",
    "exReading": "かれ の おこない は もらる に はんして います",
    "exTranslation": "What he is doing goes against morals.",
    "category": "Verb"
  },
  {
    "term": "どうにか",
    "termReading": "どうにか",
    "termTranslation": "somehow",
    "ex": "どうにか締め切りに間に合った。",
    "exReading": "どうにか しめきり に まにあった",
    "exTranslation": "I managed somehow to meet the deadline.",
    "category": "Adverb"
  },
  {
    "term": "反則",
    "termReading": "はんそく",
    "termTranslation": "foul, rule violation",
    "ex": "その選手は反則で負けました。",
    "exReading": "その せんしゅ は はんそく で まけました",
    "exTranslation": "The athlete lost because of a foul.",
    "category": "Verbal Noun"
  },
  {
    "term": "対話",
    "termReading": "たいわ",
    "termTranslation": "dialogue, conversation",
    "ex": "親子の対話は大切だよ。",
    "exReading": "おやこ の たいわ は たいせつ だ よ",
    "exTranslation": "Conversation between parents and children is important.",
    "category": "Verbal Noun"
  },
  {
    "term": "対",
    "termReading": "つい",
    "termTranslation": "pair",
    "ex": "このズボンは上着と対になっています。",
    "exReading": "この ずぼん は うわぎ と つい に なって います",
    "exTranslation": "This pair of trousers and this jacket go together.",
    "category": "Noun"
  },
  {
    "term": "対応",
    "termReading": "たいおう",
    "termTranslation": "correspondence, equivalence",
    "ex": "彼はいつも素早い対応をするね。",
    "exReading": "かれ は いつも すばやい たいおう を する ね",
    "exTranslation": "He always responds quickly.",
    "category": "Verbal Noun"
  },
  {
    "term": "反応",
    "termReading": "はんのう",
    "termTranslation": "reaction, response",
    "ex": "彼女は良い反応を示したわ。",
    "exReading": "かのじょ は よい はんのう を しめした わ",
    "exTranslation": "She reacted well.",
    "category": "Verbal Noun"
  },
  {
    "term": "問答",
    "termReading": "もんどう",
    "termTranslation": "questions and answers, argument",
    "ex": "あなたと問答している暇はないの。",
    "exReading": "あなた と もんどう して いる ひま は ない の",
    "exTranslation": "I have no time to argue with you.",
    "category": "Verbal Noun"
  },
  {
    "term": "ちゃんと",
    "termReading": "ちゃんと",
    "termTranslation": "regularly, properly",
    "ex": "朝食はちゃんと食べましたか。",
    "exReading": "ちょうしょく は ちゃんと たべました か",
    "exTranslation": "Did you eat a good breakfast?",
    "category": "Adverb"
  },
  {
    "term": "特定",
    "termReading": "とくてい",
    "termTranslation": "specific, particular",
    "ex": "警察は犯人を特定したらしいよ。",
    "exReading": "けいさつ は はんにん を とくてい した らしい よ",
    "exTranslation": "The police seem to have determined the criminal.",
    "category": "Noun"
  },
  {
    "term": "特色",
    "termReading": "とくしょく",
    "termTranslation": "characteristic",
    "ex": "その学校の教育は特色がありますね。",
    "exReading": "その がっこう の きょういく は とくしょく が あります ね",
    "exTranslation": "The education system at that school is unique.",
    "category": "Noun"
  },
  {
    "term": "特有",
    "termReading": "とくゆう",
    "termTranslation": "characteristic, special",
    "ex": "これは子供に特有の病気です。",
    "exReading": "これ は こども に とくゆう の びょうき です",
    "exTranslation": "This illness is particular to children.",
    "category": "Adjective"
  },
  {
    "term": "別に",
    "termReading": "べつに",
    "termTranslation": "especially, particularly",
    "ex": "私は別に気になりません。",
    "exReading": "わたし は べつに き に なりません",
    "exTranslation": "I'm not especially anxious.",
    "category": "Adverb"
  },
  {
    "term": "別れ",
    "termReading": "わかれ",
    "termTranslation": "separation, parting",
    "ex": "別れはいつでも悲しいものです。",
    "exReading": "わかれ は いつでも かなしい もの です",
    "exTranslation": "Parting is always sad.",
    "category": "Noun"
  },
  {
    "term": "のどか",
    "termReading": "のどか",
    "termTranslation": "tranquil, peaceful",
    "ex": "私の田舎はのどかな所です。",
    "exReading": "わたし の いなか は のどか な ところ です",
    "exTranslation": "My home town is a peaceful place.",
    "category": "Adjective"
  },
  {
    "term": "送別",
    "termReading": "そうべつ",
    "termTranslation": "farewell, send-off",
    "ex": "送別の辞は誰に頼みましょうか。",
    "exReading": "そうべつ の じ は だれ に たのみましょう か",
    "exTranslation": "Whom shall we ask to give a farewell speech?",
    "category": "Verbal Noun"
  },
  {
    "term": "送別会",
    "termReading": "そうべつかい",
    "termTranslation": "farewell party",
    "ex": "来週、課長の送別会を開きます。",
    "exReading": "らいしゅう かちょう の そうべつかい を ひらきます",
    "exTranslation": "The section chief's farewell party will be held next week.",
    "category": "Noun"
  },
  {
    "term": "専門家",
    "termReading": "せんもんか",
    "termTranslation": "specialist, expert",
    "ex": "教授はフランス文学の専門家。",
    "exReading": "きょうじゅ は ふらんす ぶんがく の せんもんか",
    "exTranslation": "The professor is a specialist of French literature.",
    "category": "Noun"
  },
  {
    "term": "専門",
    "termReading": "せんもん",
    "termTranslation": "specialty, profession",
    "ex": "法律は私の専門です。",
    "exReading": "ほうりつ は わたし の せんもん です",
    "exTranslation": "My specialty is law.",
    "category": "Noun"
  },
  {
    "term": "専用",
    "termReading": "せんよう",
    "termTranslation": "exclusive use, private use",
    "ex": "これは女性専用の車両です。",
    "exReading": "これ は じょせい せんよう の しゃりょう です",
    "exTranslation": "This is a women-only carriage.",
    "category": "Noun"
  },
  {
    "term": "メロディー",
    "termReading": "メロディー",
    "termTranslation": "melody",
    "ex": "このメロディーは聞いたことがある。",
    "exReading": "この めろでぃー は きいた こと が ある",
    "exTranslation": "I've heard this melody before.",
    "category": "Noun"
  },
  {
    "term": "全般",
    "termReading": "ぜんぱん",
    "termTranslation": "whole, general",
    "ex": "彼は植物全般に詳しいね。",
    "exReading": "かれ は しょくぶつ ぜんぱん に くわしい ね",
    "exTranslation": "He knows a lot about plants in general.",
    "category": "Noun"
  },
  {
    "term": "本格的",
    "termReading": "ほんかくてき",
    "termTranslation": "full-scale, authentic",
    "ex": "彼は絵を本格的に勉強しているんだ。",
    "exReading": "かれ は え を ほんかくてき に べんきょう して いる ん だ",
    "exTranslation": "He studies painting seriously.",
    "category": "Adjective"
  },
  {
    "term": "全面的",
    "termReading": "ぜんめんてき",
    "termTranslation": "all-out, overall",
    "ex": "彼が全面的に協力してくれるそうです。",
    "exReading": "かれ が ぜんめんてき に きょうりょく して くれる そう です",
    "exTranslation": "I hear that he'll cooperate completely.",
    "category": "Adjective"
  },
  {
    "term": "論理的",
    "termReading": "ろんりてき",
    "termTranslation": "logical",
    "ex": "彼は論理的な人です。",
    "exReading": "かれ は ろんりてき な ひと です",
    "exTranslation": "He's a logical person.",
    "category": "Adjective"
  },
  {
    "term": "知的",
    "termReading": "ちてき",
    "termTranslation": "intellectual, mental",
    "ex": "彼はすごく知的な人です。",
    "exReading": "かれ は すごく ちてき な ひと です",
    "exTranslation": "He's an extremely intellectual person.",
    "category": "Adjective"
  },
  {
    "term": "目的地",
    "termReading": "もくてきち",
    "termTranslation": "destination, goal",
    "ex": "やっと目的地に着いたよ。",
    "exReading": "やっと もくてきち に ついた よ",
    "exTranslation": "I finally arrived at the destination.",
    "category": "Noun"
  },
  {
    "term": "リットル",
    "termReading": "リットル",
    "termTranslation": "liter, litre",
    "ex": "今日は水を2リットル以上飲んだわ。",
    "exReading": "きょう は みず を 2 りっとる いじょう のんだ わ",
    "exTranslation": "I drank more than two liters of water today.",
    "category": "Noun"
  },
  {
    "term": "文化的",
    "termReading": "ぶんかてき",
    "termTranslation": "cultural",
    "ex": "この国は文化的な事業に力を入れています。",
    "exReading": "この くに は ぶんかてき な じぎょう に ちから を いれて います",
    "exTranslation": "This country is putting efforts into cultural projects.",
    "category": "Adjective"
  },
  {
    "term": "男性的",
    "termReading": "だんせいてき",
    "termTranslation": "masculine, manly",
    "ex": "彼はとても男性的な人です。",
    "exReading": "かれ は とても だんせいてき な ひと です",
    "exTranslation": "He's a very manly person.",
    "category": "Adjective"
  },
  {
    "term": "普段",
    "termReading": "ふだん",
    "termTranslation": "usual, ordinary",
    "ex": "私は普段はTシャツとジーンズを着ています。",
    "exReading": "わたし は ふだん は てぃーしゃつ と じーんず を きて います",
    "exTranslation": "I usually wear a T-shirt and jeans.",
    "category": "Adverb"
  },
  {
    "term": "並み",
    "termReading": "なみ",
    "termTranslation": "ordinary, average",
    "ex": "彼は並みの人間ではありません。",
    "exReading": "かれ は なみ の にんげん で は ありません",
    "exTranslation": "He's not an average man.",
    "category": "Noun"
  },
  {
    "term": "並木",
    "termReading": "なみき",
    "termTranslation": "row of trees, roadside trees",
    "ex": "駅前の並木が台風で倒れたらしい。",
    "exReading": "えきまえ の なみき が たいふう で たおれた らしい",
    "exTranslation": "I hear the trees around the station fell over due to the typhoon.",
    "category": "Noun"
  },
  {
    "term": "わざと",
    "termReading": "わざと",
    "termTranslation": "on purpose",
    "ex": "彼はわざと負けたように見えたな。",
    "exReading": "かれ は わざと まけた よう に みえた な",
    "exTranslation": "It looked like he lost on purpose.",
    "category": "Adverb"
  },
  {
    "term": "平面",
    "termReading": "へいめん",
    "termTranslation": "level surface, plain",
    "ex": "このメガネをかけると平面が立体に見えます。",
    "exReading": "この めがね を かける と へいめん が りったい に みえます",
    "exTranslation": "When you wear these glasses, two-dimensional things look three-dimensional.",
    "category": "Noun"
  },
  {
    "term": "平気",
    "termReading": "へいき",
    "termTranslation": "nonchalant, calm",
    "ex": "彼女は平気な顔をしていた。",
    "exReading": "かのじょ は へいき な かお を して いた",
    "exTranslation": "She looked as if nothing had happened.",
    "category": "Adjective"
  },
  {
    "term": "平ら",
    "termReading": "たいら",
    "termTranslation": "flat, level",
    "ex": "その建物の屋根は平らだね。",
    "exReading": "その たてもの の やね は たいら だ　ね",
    "exTranslation": "The roof on that building is flat.",
    "category": "Adjective"
  },
  {
    "term": "平行",
    "termReading": "へいこう",
    "termTranslation": "parallel, side by side",
    "ex": "平行に線を引いてください。",
    "exReading": "へいこう に せん を ひいて ください",
    "exTranslation": "Please draw the lines parallel.",
    "category": "Verbal Noun"
  },
  {
    "term": "不平",
    "termReading": "ふへい",
    "termTranslation": "discontent, dissatisfaction",
    "ex": "私はいつも同僚の不平を聞いているんだ。",
    "exReading": "わたし は いつも どうりょう の ふへい を きいて いる ん だ",
    "exTranslation": "I'm always hearing my colleague's complaints.",
    "category": "Adjectival Noun"
  },
  {
    "term": "とっくに",
    "termReading": "とっくに",
    "termTranslation": "long ago",
    "ex": "彼ならとっくに帰りましたよ。",
    "exReading": "かれ なら とっくに かえりました よ",
    "exTranslation": "He's already gone home.",
    "category": "Adverb"
  },
  {
    "term": "地平線",
    "termReading": "ちへいせん",
    "termTranslation": "horizon",
    "ex": "地平線に夕日が沈むところだったの。",
    "exReading": "ちへいせん に ゆうひ が しずむ ところ だった の",
    "exTranslation": "The sun was setting on the horizon.",
    "category": "Noun"
  },
  {
    "term": "平野",
    "termReading": "へいや",
    "termTranslation": "plain, open field",
    "ex": "広い平野が一面雪で真っ白でした。",
    "exReading": "ひろい へいや が いちめん ゆき で まっしろ でした",
    "exTranslation": "The vast plains were pure-white with the snow.",
    "category": "Noun"
  },
  {
    "term": "平たい",
    "termReading": "ひらたい",
    "termTranslation": "flat, level",
    "ex": "平たいお皿を一枚取って。",
    "exReading": "ひらたい おさら を いちまい とって",
    "exTranslation": "Can you hand me a plate?",
    "category": "Adjective"
  },
  {
    "term": "平方",
    "termReading": "へいほう",
    "termTranslation": "square",
    "ex": "この土地の面積は約100平方メートルです。",
    "exReading": "この とち の めんせき は やく 100 へいほうめーとる です",
    "exTranslation": "The area of this land is about 100 square meters.",
    "category": "Noun"
  },
  {
    "term": "平日",
    "termReading": "へいじつ",
    "termTranslation": "weekday",
    "ex": "彼は平日がお休みです。",
    "exReading": "かれ は へいじつ が おやすみ です",
    "exTranslation": "He has weekdays off.",
    "category": "Noun"
  },
  {
    "term": "ベスト",
    "termReading": "ベスト",
    "termTranslation": "best",
    "ex": "私はベストを尽くしました。",
    "exReading": "わたし は べすと を つくしました",
    "exTranslation": "I did my best.",
    "category": "Noun"
  },
  {
    "term": "平均",
    "termReading": "へいきん",
    "termTranslation": "average, equilibrium",
    "ex": "平均で一日に8時間ぐらい働いています。",
    "exReading": "へいきん で いちにち に 8 じかん ぐらい はたらいて います",
    "exTranslation": "I work for about eight hours a day on average.",
    "category": "Verbal Noun"
  },
  {
    "term": "等しい",
    "termReading": "ひとしい",
    "termTranslation": "equal, same",
    "ex": "私は株の知識がないに等しいです。",
    "exReading": "わたし は かぶ の ちしき が ない に ひとしい です",
    "exTranslation": "I have almost no knowledge of stocks.",
    "category": "Adjective"
  },
  {
    "term": "平等",
    "termReading": "びょうどう",
    "termTranslation": "equality, impartiality",
    "ex": "あの先生は生徒をみな平等に扱います。",
    "exReading": "あの せんせい は せいと を みな びょうどう に あつかいます",
    "exTranslation": "That teacher treats all students equally.",
    "category": "Adjectival Noun"
  },
  {
    "term": "同等",
    "termReading": "どうとう",
    "termTranslation": "equality",
    "ex": "彼には大学生と同等の学力があります。",
    "exReading": "かれ に は だいがくせい と どうとう の がくりょく が あります",
    "exTranslation": "He has the same academic ability as university students.",
    "category": "Noun"
  },
  {
    "term": "対等",
    "termReading": "たいとう",
    "termTranslation": "equality, parity",
    "ex": "その子供は大人と対等に話していたよ。",
    "exReading": "その こども は おとな と たいとう に はなして いた よ",
    "exTranslation": "That kid was speaking with an adult as an equal.",
    "category": "Adjectival Noun"
  },
  {
    "term": "不平等",
    "termReading": "ふびょうどう",
    "termTranslation": "unequal, unfair",
    "ex": "職場での男女不平等はよく見られるね。",
    "exReading": "しょくば で の だんじょ ふびょうどう は よく みられる ね",
    "exTranslation": "You can often find gender inequality in the workplace.",
    "category": "Adjectival Noun"
  },
  {
    "term": "ワンピース",
    "termReading": "ワンピース",
    "termTranslation": "dress",
    "ex": "彼女は白いワンピースを着ていたよ。",
    "exReading": "かのじょ は しろい わんぴーす を きて いた よ",
    "exTranslation": "She was wearing a white dress.",
    "category": "Noun"
  },
  {
    "term": "病室",
    "termReading": "びょうしつ",
    "termTranslation": "patient room, ward",
    "ex": "その病室はとても広くてきれいだった。",
    "exReading": "その びょうしつ は とても ひろくて きれい だった",
    "exTranslation": "The hospital room was very large and clean.",
    "category": "Noun"
  },
  {
    "term": "病人",
    "termReading": "びょうにん",
    "termTranslation": "sick person, patient",
    "ex": "病人が出たので電車が少し止まったんだ。",
    "exReading": "びょうにん が でた の で でんしゃ が すこし とまった ん だ",
    "exTranslation": "The train stopped for a while as someone was ill.",
    "category": "Noun"
  },
  {
    "term": "内科",
    "termReading": "ないか",
    "termTranslation": "internal medicine",
    "ex": "内科で胃の調子を診てもらったよ。",
    "exReading": "ないか で い の ちょうし を みて もらった よ",
    "exTranslation": "I had my stomach examined in the internal medicine department.",
    "category": "Noun"
  },
  {
    "term": "必死",
    "termReading": "ひっし",
    "termTranslation": "desperation",
    "ex": "学生たちは授業についていくのに必死です。",
    "exReading": "がくせいたち は じゅぎょう に ついて いく の に ひっし です",
    "exTranslation": "Students are desperately trying to follow the class.",
    "category": "Adjectival Noun"
  },
  {
    "term": "病死",
    "termReading": "びょうし",
    "termTranslation": "death from sickness",
    "ex": "その作家は35歳という若さで病死した。",
    "exReading": "その さっか は 35 さい と いう わかさ で びょうし した",
    "exTranslation": "The writer died of an illness at the young age of 35.",
    "category": "Verbal Noun"
  },
  {
    "term": "ダイヤモンド",
    "termReading": "ダイヤモンド",
    "termTranslation": "diamond",
    "ex": "ダイヤモンドはとても高価ですね。",
    "exReading": "だいやもんど は とても こうか です ね",
    "exTranslation": "Diamonds are very expensive.",
    "category": "Noun"
  },
  {
    "term": "必死に",
    "termReading": "ひっしに",
    "termTranslation": "desperately",
    "ex": "必死に単語を暗記したよ。",
    "exReading": "ひっしに たんご を あんき した よ",
    "exTranslation": "I struggled to memorize the words.",
    "category": "Adverb"
  },
  {
    "term": "亡くす",
    "termReading": "なくす",
    "termTranslation": "lose (in death)",
    "ex": "昨年、友人を亡くしました。",
    "exReading": "さくねん ゆうじん を なくしました",
    "exTranslation": "I lost my friend last year.",
    "category": "Verb"
  },
  {
    "term": "多忙",
    "termReading": "たぼう",
    "termTranslation": "very busy",
    "ex": "彼女は多忙な人です。",
    "exReading": "かのじょ は たぼう な ひと です",
    "exTranslation": "She's a busy person.",
    "category": "Adjectival Noun"
  },
  {
    "term": "疲れ",
    "termReading": "つかれ",
    "termTranslation": "fatigue, exhaustion",
    "ex": "最近疲れがたまっています。",
    "exReading": "さいきん つかれ が たまって います",
    "exTranslation": "My fatigue is building up these days.",
    "category": "Noun"
  },
  {
    "term": "立ち入り禁止",
    "termReading": "たちいりきんし",
    "termTranslation": "no trespassing",
    "ex": "ここは立ち入り禁止です。",
    "exReading": "ここ は たちいりきんし です",
    "exTranslation": "This is a restricted area.",
    "category": "Noun"
  },
  {
    "term": "ナンバー",
    "termReading": "ナンバー",
    "termTranslation": "number",
    "ex": "その車のナンバーを覚えていますか。",
    "exReading": "その くるま の なんばー を おぼえて います か",
    "exTranslation": "Do you remember the car's license number?",
    "category": "Noun"
  },
  {
    "term": "日本酒",
    "termReading": "にほんしゅ",
    "termTranslation": "sake, rice wine",
    "ex": "珍しい日本酒が手に入りました。",
    "exReading": "めずらしい にほんしゅ が て に はいりました",
    "exTranslation": "I obtained some unusual sake.",
    "category": "Noun"
  },
  {
    "term": "酔う",
    "termReading": "よう",
    "termTranslation": "get drunk",
    "ex": "みんなかなり酔っていたの。",
    "exReading": "みんな かなり よって いた の",
    "exTranslation": "Everyone got considerably drunk.",
    "category": "Verb"
  },
  {
    "term": "保つ",
    "termReading": "たもつ",
    "termTranslation": "preserve, sustain",
    "ex": "彼女は若さを保とうと必死だ。",
    "exReading": "かのじょ は わかさ を たもとう と ひっし だ",
    "exTranslation": "She's desperately trying to retain her youth.",
    "category": "Verb"
  },
  {
    "term": "保険",
    "termReading": "ほけん",
    "termTranslation": "insurance",
    "ex": "あなたは保険に加入していますか。",
    "exReading": "あなた は ほけん に かにゅう して います か",
    "exTranslation": "Have you taken out insurance?",
    "category": "Noun"
  },
  {
    "term": "保証",
    "termReading": "ほしょう",
    "termTranslation": "guarantee, warranty",
    "ex": "この製品の保証期間は5年間となっております。",
    "exReading": "この せいひん の ほしょう きかん は 5 ねん かん と なって おります",
    "exTranslation": "The guarantee period of this product is five years.",
    "category": "Verbal Noun"
  },
  {
    "term": "保証人",
    "termReading": "ほしょうにん",
    "termTranslation": "guarantor",
    "ex": "彼が私の保証人になってくれました。",
    "exReading": "かれ が わたし の ほしょうにん に なって くれました",
    "exTranslation": "He became my guarantor.",
    "category": "Noun"
  },
  {
    "term": "とうとう",
    "termReading": "とうとう",
    "termTranslation": "at last, after all",
    "ex": "とうとう引っ越しの日がきました。",
    "exReading": "とうとう ひっこし の ひ が きました",
    "exTranslation": "At last, moving day has come.",
    "category": "Adverb"
  },
  {
    "term": "保存",
    "termReading": "ほぞん",
    "termTranslation": "preservation, conservation",
    "ex": "データを30分おきに保存してください。",
    "exReading": "でーた を 30 ぷん おき に ほぞん して ください",
    "exTranslation": "Please save data every 30 minutes.",
    "category": "Verbal Noun"
  },
  {
    "term": "存じる",
    "termReading": "ぞんじる",
    "termTranslation": "think, know",
    "ex": "郵便局はどこかご存じですか。",
    "exReading": "ゆうびんきょく は どこ か ごぞんじ です か",
    "exTranslation": "Do you know where the post office is?",
    "category": "Verb"
  },
  {
    "term": "注ぐ",
    "termReading": "そそぐ",
    "termTranslation": "pour into, concentrate",
    "ex": "みんなのグラスにジュースを注いだよ。",
    "exReading": "みんな の ぐらす に じゅーす を そそいだ よ",
    "exTranslation": "I poured juice into everyone's glass.",
    "category": "Verb"
  },
  {
    "term": "注",
    "termReading": "ちゅう",
    "termTranslation": "annotation, explanatory note",
    "ex": "詳しくは注を読んでください。",
    "exReading": "くわしくは ちゅう を よんで ください",
    "exTranslation": "For details, please read the notes.",
    "category": "Noun"
  },
  {
    "term": "同意",
    "termReading": "どうい",
    "termTranslation": "consent, approval",
    "ex": "彼の意見には同意できません。",
    "exReading": "かれ の いけん に は どうい できません",
    "exTranslation": "I can't agree with his opinion.",
    "category": "Verbal Noun"
  },
  {
    "term": "とんでもない",
    "termReading": "とんでもない",
    "termTranslation": "unthinkable, outrageous",
    "ex": "仕事中に帰るなんてとんでもない。",
    "exReading": "しごとちゅう に かえる なんて とんでもない",
    "exTranslation": "It's unthinkable to go home in the middle of work.",
    "category": "Adjective"
  },
  {
    "term": "無意味",
    "termReading": "むいみ",
    "termTranslation": "meaningless, absurd",
    "ex": "そんなことをしても無意味よ。",
    "exReading": "そんな こと を して も むいみ よ",
    "exTranslation": "It's meaningless to do such a thing.",
    "category": "Adjectival Noun"
  },
  {
    "term": "不注意",
    "termReading": "ふちゅうい",
    "termTranslation": "carelessness, inattention",
    "ex": "その事故は運転手の不注意が原因で起きたの。",
    "exReading": "その じこ は うんてんしゅ の ふちゅうい が げんいん で おきた の",
    "exTranslation": "The accident happened due to the driver's carelessness.",
    "category": "Adjectival Noun"
  },
  {
    "term": "生意気",
    "termReading": "なまいき",
    "termTranslation": "cheeky, conceited",
    "ex": "あの子は生意気だと思います。",
    "exReading": "あの こ は なまいき だ と おもいます",
    "exTranslation": "That kid is quite cheeky, I think.",
    "category": "Adjectival Noun"
  },
  {
    "term": "確かめる",
    "termReading": "たしかめる",
    "termTranslation": "make sure of, confirm",
    "ex": "母はお釣りを確かめたの。",
    "exReading": "はは は おつり を たしかめた の",
    "exTranslation": "My mother checked the change.",
    "category": "Verb"
  },
  {
    "term": "明確",
    "termReading": "めいかく",
    "termTranslation": "clear, precise",
    "ex": "彼女には明確な目標があるね。",
    "exReading": "かのじょ に は めいかく な もくひょう が ある ね",
    "exTranslation": "She has a clear aim.",
    "category": "Adjectival Noun"
  },
  {
    "term": "にこやか",
    "termReading": "にこやか",
    "termTranslation": "smiling, beaming",
    "ex": "彼らはにこやかに挨拶を交わしたけどね。",
    "exReading": "かれら は にこやか に あいさつ を かわした けど ね",
    "exTranslation": "They exchanged greetings with a smile at first.",
    "category": "Adjective"
  },
  {
    "term": "不確か",
    "termReading": "ふたしか",
    "termTranslation": "unreliable, uncertain",
    "ex": "人の記憶は不確かよ。",
    "exReading": "ひと の きおく は ふたしか よ",
    "exTranslation": "Human memories are unreliable.",
    "category": "Adjectival Noun"
  },
  {
    "term": "認可",
    "termReading": "にんか",
    "termTranslation": "approval, authorization",
    "ex": "この薬はまだ認可されていません。",
    "exReading": "この くすり は まだ にんか されて いません",
    "exTranslation": "This drug has yet to be approved.",
    "category": "Verbal Noun"
  },
  {
    "term": "認める",
    "termReading": "みとめる",
    "termTranslation": "recognize, acknowledge",
    "ex": "父が彼女との結婚を認めてくれました。",
    "exReading": "ちち が かのじょ と の けっこん を みとめて くれました",
    "exTranslation": "My father gave me permission to marry her.",
    "category": "Verb"
  },
  {
    "term": "率",
    "termReading": "りつ",
    "termTranslation": "ratio, proportion",
    "ex": "その手術の成功率は90%だそうです。",
    "exReading": "その しゅじゅつ の せいこうりつ は 90% だ そう です",
    "exTranslation": "Apparently, the success rate of the operation is 90 %.",
    "category": "Noun"
  },
  {
    "term": "率直",
    "termReading": "そっちょく",
    "termTranslation": "frank, openhearted",
    "ex": "率直なご意見ありがとうございました。",
    "exReading": "そっちょく な ごいけん ありがとう ございました",
    "exTranslation": "Thank you for your straightforward opinion.",
    "category": "Adjective"
  },
  {
    "term": "能率",
    "termReading": "のうりつ",
    "termTranslation": "efficiency",
    "ex": "この方法だと能率がいいですね。",
    "exReading": "この ほうほう だ と のうりつ が いい です ね",
    "exTranslation": "Using this method is more efficient.",
    "category": "Noun"
  },
  {
    "term": "ロッカー",
    "termReading": "ロッカー",
    "termTranslation": "locker",
    "ex": "荷物を駅のロッカーに入れた。",
    "exReading": "にもつ を えき の ろっかー に いれた",
    "exTranslation": "I put my luggage in a locker at the station.",
    "category": "Noun"
  },
  {
    "term": "旅客機",
    "termReading": "りょかくき",
    "termTranslation": "passenger plane",
    "ex": "旅客機が墜落したよ。",
    "exReading": "りょかくき が ついらく した よ",
    "exTranslation": "The passenger jet crashed.",
    "category": "Noun"
  },
  {
    "term": "不器用",
    "termReading": "ぶきよう",
    "termTranslation": "clumsy, unskillful",
    "ex": "妹は不器用で、料理も苦手なの。",
    "exReading": "いもうと は ぶきよう で りょうり も にがて な の",
    "exTranslation": "My little sister is clumsy and not good at cooking.",
    "category": "Adjectival Noun"
  },
  {
    "term": "道具",
    "termReading": "どうぐ",
    "termTranslation": "tool",
    "ex": "道具は全部揃っていますか。",
    "exReading": "どうぐ は ぜんぶ そろって います か",
    "exTranslation": "Do we have all the tools ready?",
    "category": "Noun"
  },
  {
    "term": "予備",
    "termReading": "よび",
    "termTranslation": "reserve, spare",
    "ex": "旅行には予備の靴を持って行きます。",
    "exReading": "りょこう に は よび の くつ を もって いきます",
    "exTranslation": "I'll take some spare shoes on holiday.",
    "category": "Noun"
  },
  {
    "term": "説く",
    "termReading": "とく",
    "termTranslation": "explain, preach",
    "ex": "彼は非暴力を説きました。",
    "exReading": "かれ は ひぼうりょく を ときました",
    "exTranslation": "He preached nonviolence.",
    "category": "Verb"
  },
  {
    "term": "プライド",
    "termReading": "プライド",
    "termTranslation": "pride",
    "ex": "彼はプライドが高い人です。",
    "exReading": "かれ は ぷらいど が たかい ひと です",
    "exTranslation": "He's a proud person.",
    "category": "Noun"
  },
  {
    "term": "不公平",
    "termReading": "ふこうへい",
    "termTranslation": "unfair, unjust",
    "ex": "彼のやり方は不公平です。",
    "exReading": "かれ の やりかた は ふこうへい です",
    "exTranslation": "His way of doing things is unfair.",
    "category": "Adjectival Noun"
  },
  {
    "term": "遊園地",
    "termReading": "ゆうえんち",
    "termTranslation": "amusement park",
    "ex": "この遊園地のチケットは3000円です。",
    "exReading": "この ゆうえんち の ちけっと は さんぜんえん です",
    "exTranslation": "The tickets for this amusement park are 3000 yen.",
    "category": "Noun"
  },
  {
    "term": "保育園",
    "termReading": "ほいくえん",
    "termTranslation": "day-care center, nursery school",
    "ex": "娘を保育園に迎えに行きます。",
    "exReading": "むすめ を ほいくえん に むかえ に いきます",
    "exTranslation": "I'm going to pick up my daughter at day-care.",
    "category": "Noun"
  },
  {
    "term": "飛び出す",
    "termReading": "とびだす",
    "termTranslation": "fly out, dash out",
    "ex": "彼は道路に飛び出したの。",
    "exReading": "かれ は どうろ に とびだした の",
    "exTranslation": "He ran out into the road.",
    "category": "Verb"
  },
  {
    "term": "飛ばす",
    "termReading": "とばす",
    "termTranslation": "fly, shoot",
    "ex": "子供が紙飛行機を飛ばしています。",
    "exReading": "こども が かみひこうき を とばして います",
    "exTranslation": "The child is flying a paper airplane.",
    "category": "Verb"
  },
  {
    "term": "ママ",
    "termReading": "ママ",
    "termTranslation": "mommy, mummy",
    "ex": "ママに聞いてみよう。",
    "exReading": "まま に きいて みよう",
    "exTranslation": "Let's ask Mom.",
    "category": "Noun"
  },
  {
    "term": "飛び上がる",
    "termReading": "とびあがる",
    "termTranslation": "fly up, jump up",
    "ex": "大きな音にびっくりして飛び上がりました。",
    "exReading": "おおき な おと に びっくり して とびあがりました",
    "exTranslation": "I jumped in surprise at the loud noise.",
    "category": "Verb"
  },
  {
    "term": "飛び下りる",
    "termReading": "とびおりる",
    "termTranslation": "jump off, jump down",
    "ex": "猫が屋根から飛び下りました。",
    "exReading": "ねこ が やね から とびおりました",
    "exTranslation": "A cat jumped down from the roof.",
    "category": "Verb"
  },
  {
    "term": "飛び込む",
    "termReading": "とびこむ",
    "termTranslation": "dive into",
    "ex": "カエルが池に飛び込んだね。",
    "exReading": "かえる が いけ に とびこんだ ね",
    "exTranslation": "The frog jumped into the pond.",
    "category": "Verb"
  },
  {
    "term": "飛行",
    "termReading": "ひこう",
    "termTranslation": "flight, aviation",
    "ex": "このフライトの飛行時間は約3時間です。",
    "exReading": "この ふらいと の ひこう じかん は やく 3 じかん です",
    "exTranslation": "The flight time is about three hours.",
    "category": "Verbal Noun"
  },
  {
    "term": "船長",
    "termReading": "せんちょう",
    "termTranslation": "captain",
    "ex": "私がこの船の船長です。",
    "exReading": "わたし が この ふね の せんちょう です",
    "exTranslation": "I'm captain of this ship.",
    "category": "Noun"
  },
  {
    "term": "よける",
    "termReading": "よける",
    "termTranslation": "move out the way",
    "ex": "椅子の荷物をよけて座ったけど。",
    "exReading": "いす の にもつ を よけて すわった けど",
    "exTranslation": "I moved the bag on the chair and sat down anyway.",
    "category": "Verb"
  },
  {
    "term": "風船",
    "termReading": "ふうせん",
    "termTranslation": "balloon",
    "ex": "子供が風船を膨らませているね。",
    "exReading": "こども が ふうせん を ふくらませて いる ね",
    "exTranslation": "The child is blowing up a balloon.",
    "category": "Noun"
  },
  {
    "term": "半島",
    "termReading": "はんとう",
    "termTranslation": "peninsula",
    "ex": "台風がその半島を通過したの。",
    "exReading": "たいふう が その はんとう を つうか した の",
    "exTranslation": "The typhoon passed over the peninsula.",
    "category": "Noun"
  },
  {
    "term": "島",
    "termReading": "とう",
    "termTranslation": "island",
    "ex": "私たちはハワイのマウイ島に旅行したの。",
    "exReading": "わたしたち は はわい の まういとう に りょこう した の",
    "exTranslation": "We traveled to Maui island in Hawaii.",
    "category": "Noun"
  },
  {
    "term": "不完全",
    "termReading": "ふかんぜん",
    "termTranslation": "imperfection, incompleteness",
    "ex": "このデータはまだ不完全ね。",
    "exReading": "この でーた は まだ ふかんぜん ね",
    "exTranslation": "This data is still unfinished.",
    "category": "Adjectival Noun"
  },
  {
    "term": "達成",
    "termReading": "たっせい",
    "termTranslation": "attain, achieve",
    "ex": "彼は今月の売上目標を達成した。",
    "exReading": "かれ は こんげつ の うりあげ もくひょう を たっせい した",
    "exTranslation": "He achieved the sales target this month.",
    "category": "Verbal Noun"
  },
  {
    "term": "成り立つ",
    "termReading": "なりたつ",
    "termTranslation": "consist of, be built on",
    "ex": "この島は観光で成り立っています。",
    "exReading": "この しま は かんこう で なりたって います",
    "exTranslation": "This island is built on sightseeing.",
    "category": "Verb"
  },
  {
    "term": "バッジ",
    "termReading": "バッジ",
    "termTranslation": "badge",
    "ex": "彼は弁護士バッジを付けていたよ。",
    "exReading": "かれ は べんごし ばっじ を つけて いた よ",
    "exTranslation": "He was wearing a lawyer's badge.",
    "category": "Noun"
  },
  {
    "term": "未成年",
    "termReading": "みせいねん",
    "termTranslation": "minor, underage",
    "ex": "未成年はお酒を飲めません。",
    "exReading": "みせいねん は おさけ を のめません",
    "exTranslation": "Minors can't drink alcohol.",
    "category": "Noun"
  },
  {
    "term": "敗れる",
    "termReading": "やぶれる",
    "termTranslation": "be defeated, be beaten",
    "ex": "私のチームは1回戦で敗れたよ。",
    "exReading": "わたし の ちーむ は 1 かいせん で やぶれた よ",
    "exTranslation": "My team lost in the first round.",
    "category": "Verb"
  },
  {
    "term": "野原",
    "termReading": "のはら",
    "termTranslation": "field, plain",
    "ex": "私たちは野原で花をつんだの。",
    "exReading": "わたしたち は のはら で はな を つんだ の",
    "exTranslation": "We picked flowers in the field.",
    "category": "Noun"
  },
  {
    "term": "原",
    "termReading": "はら",
    "termTranslation": "plain, field",
    "ex": "クローバーの原でピクニックをしたよ。",
    "exReading": "くろーばー の はら で ぴくにっく を した よ",
    "exTranslation": "We had a picnic in a clover field.",
    "category": "Noun"
  },
  {
    "term": "要因",
    "termReading": "よういん",
    "termTranslation": "primary factor, main cause",
    "ex": "私たちはがんの要因を研究しています。",
    "exReading": "わたしたち は がん の よういん を けんきゅう して います",
    "exTranslation": "We're researching the contributing factors of cancer.",
    "category": "Noun"
  },
  {
    "term": "ピン",
    "termReading": "ピン",
    "termTranslation": "hairpin, pin",
    "ex": "彼女はいつも髪をピンで留めているね。",
    "exReading": "かのじょ は いつも かみ を ぴん で とめて いる ね",
    "exTranslation": "She always holds her hair with pins.",
    "category": "Noun"
  },
  {
    "term": "因る",
    "termReading": "よる",
    "termTranslation": "be caused by, be due to",
    "ex": "彼の病気は過労に因るものです。",
    "exReading": "かれ の びょうき は かろう に よる もの です",
    "exTranslation": "His sickness is caused by overwork.",
    "category": "Verb"
  },
  {
    "term": "物資",
    "termReading": "ぶっし",
    "termTranslation": "commodities, goods",
    "ex": "被災地に物資を送ったよ。",
    "exReading": "ひさいち に ぶっし を おくった よ",
    "exTranslation": "We sent supplies to the disaster area.",
    "category": "Noun"
  },
  {
    "term": "願い",
    "termReading": "ねがい",
    "termTranslation": "wish, request",
    "ex": "世界の平和が私たちの願いです。",
    "exReading": "せかい の へいわ が わたし たち の ねがい です",
    "exTranslation": "Our wish is for world peace.",
    "category": "Noun"
  },
  {
    "term": "不正",
    "termReading": "ふせい",
    "termTranslation": "fraud, impropriety",
    "ex": "試験で不正が見つかったよ。",
    "exReading": "しけん で ふせい が みつかった よ",
    "exTranslation": "Cheating was discovered in the examination.",
    "category": "Adjectival Noun"
  },
  {
    "term": "大正",
    "termReading": "たいしょう",
    "termTranslation": "Taisho era",
    "ex": "祖母は大正生まれです。",
    "exReading": "そぼ は たいしょう うまれ です",
    "exTranslation": "My grandmother was born in the Taisho era.",
    "category": "Noun"
  },
  {
    "term": "まあ",
    "termReading": "まあ",
    "termTranslation": "well",
    "ex": "まあこれでいいだろう。",
    "exReading": "まあこれでいいだろう。",
    "exTranslation": "Well, this will do I suppose.",
    "category": "Interjection"
  },
  {
    "term": "正に",
    "termReading": "まさに",
    "termTranslation": "just, surely",
    "ex": "彼は正に英雄ね。",
    "exReading": "かれ は まさに えいゆう ね",
    "exTranslation": "He's a real hero.",
    "category": "Adverb"
  },
  {
    "term": "常に",
    "termReading": "つねに",
    "termTranslation": "always, at all times",
    "ex": "彼は常に姿勢がいい。",
    "exReading": "かれ は つねに しせい が いい",
    "exTranslation": "His posture is always good.",
    "category": "Adverb"
  },
  {
    "term": "日常",
    "termReading": "にちじょう",
    "termTranslation": "daily, everyday",
    "ex": "音楽は私の日常の一部です。",
    "exReading": "おんがく は わたし の にちじょう の いちぶ です",
    "exTranslation": "Music is a part of my daily life.",
    "category": "Noun"
  },
  {
    "term": "知識",
    "termReading": "ちしき",
    "termTranslation": "knowledge",
    "ex": "私は旅行から多くの知識を得た。",
    "exReading": "わたし は りょこう から おおく の ちしき を えた",
    "exTranslation": "I gained a lot of knowledge from traveling.",
    "category": "Noun"
  },
  {
    "term": "認識",
    "termReading": "にんしき",
    "termTranslation": "recognition, acknowledgment",
    "ex": "その件は終わったと認識しています。",
    "exReading": "その けん は おわった と にんしき して います",
    "exTranslation": "My understanding is that the matter is over.",
    "category": "Verbal Noun"
  },
  {
    "term": "無意識",
    "termReading": "むいしき",
    "termTranslation": "unconsciously, without thinking",
    "ex": "私は無意識に彼を傷つけてしまった。",
    "exReading": "わたし は むいしき に かれ を きずつけて しまった",
    "exTranslation": "Unintentionally, I ended up hurting him.",
    "category": "Adverb"
  },
  {
    "term": "むなしい",
    "termReading": "むなしい",
    "termTranslation": "empty, vain",
    "ex": "愛がなければ人生はむなしいよ。",
    "exReading": "あい が なければ じんせい は むなしい よ",
    "exTranslation": "Life is empty without love.",
    "category": "Adjective"
  },
  {
    "term": "非難",
    "termReading": "ひなん",
    "termTranslation": "criticism, blame",
    "ex": "国民は総理大臣を非難しているね。",
    "exReading": "こくみん は そうり だいじん を ひなん して いる ね",
    "exTranslation": "The people are criticizing the Prime Minister.",
    "category": "Verbal Noun"
  },
  {
    "term": "非常",
    "termReading": "ひじょう",
    "termTranslation": "emergency, calamity",
    "ex": "非常事態です。",
    "exReading": "ひじょう じたい です",
    "exTranslation": "We're in a state of emergency!",
    "category": "Adjectival Noun"
  },
  {
    "term": "非常識",
    "termReading": "ひじょうしき",
    "termTranslation": "lack of common sense",
    "ex": "彼は非常識な時間に電話してきたの。",
    "exReading": "かれ は ひじょうしき な じかん に でんわ して きた の",
    "exTranslation": "He called me at an unreasonable hour.",
    "category": "Adjectival Noun"
  },
  {
    "term": "非常口",
    "termReading": "ひじょうぐち",
    "termTranslation": "emergency exit",
    "ex": "ビルの非常口を確認したの。",
    "exReading": "びる の ひじょうぐち を かくにん した の",
    "exTranslation": "I checked the building's fire exit.",
    "category": "Noun"
  },
  {
    "term": "調子",
    "termReading": "ちょうし",
    "termTranslation": "tone, condition",
    "ex": "体の調子がとても良いです。",
    "exReading": "からだ の ちょうし が とても いい です",
    "exTranslation": "I'm in good physical condition.",
    "category": "Noun"
  },
  {
    "term": "ついで",
    "termReading": "ついで",
    "termTranslation": "on the way",
    "ex": "ついでだから彼も呼ぼうよ。",
    "exReading": "ついで だから かれ も よぼうよ",
    "exTranslation": "Let's use this chance to invite him too.",
    "category": "Noun"
  },
  {
    "term": "調和",
    "termReading": "ちょうわ",
    "termTranslation": "harmony, agreement",
    "ex": "彼女は自然と調和した暮らし方をしているの。",
    "exReading": "かのじょ は しぜん と ちょうわ した くらし かた を して いる の",
    "exTranslation": "She lives her life in harmony with nature.",
    "category": "Verbal Noun"
  },
  {
    "term": "体調",
    "termReading": "たいちょう",
    "termTranslation": "health condition",
    "ex": "今日は体調が悪いです。",
    "exReading": "きょう は たいちょう が わるい です",
    "exTranslation": "I feel sick today.",
    "category": "Noun"
  },
  {
    "term": "単調",
    "termReading": "たんちょう",
    "termTranslation": "monotonous, dull",
    "ex": "この曲は単調でつまらないな。",
    "exReading": "この きょく は たんちょう で つまらない な",
    "exTranslation": "This tune is monotonous and boring.",
    "category": "Adjectival Noun"
  },
  {
    "term": "調味料",
    "termReading": "ちょうみりょう",
    "termTranslation": "seasoning, flavoring",
    "ex": "塩、コショウはよく使われる調味料です。",
    "exReading": "しお こしょう は よく つかわれる ちょうみりょう です",
    "exTranslation": "Salt and pepper are commonly used seasonings.",
    "category": "Noun"
  },
  {
    "term": "調整",
    "termReading": "ちょうせい",
    "termTranslation": "regulation, adjustment",
    "ex": "今、スケジュールの調整をしています。",
    "exReading": "いま すけじゅーる の ちょうせい を して います",
    "exTranslation": "I'm adjusting the schedule now.",
    "category": "Verbal Noun"
  },
  {
    "term": "バケツ",
    "termReading": "バケツ",
    "termTranslation": "bucket",
    "ex": "バケツに水を汲んできてください。",
    "exReading": "ばけつ に みず を くんで きて ください",
    "exTranslation": "Please fetch water in the bucket.",
    "category": "Noun"
  },
  {
    "term": "整える",
    "termReading": "ととのえる",
    "termTranslation": "arrange, adjust",
    "ex": "彼はスピーチの前に服装を整えた。",
    "exReading": "かれ は すぴーち の まえ に ふくそう を ととのえた",
    "exTranslation": "He straightened up his clothes before the speech.",
    "category": "Verb"
  },
  {
    "term": "整う",
    "termReading": "ととのう",
    "termTranslation": "be in order, be adjusted",
    "ex": "パーティーの準備が整いました。",
    "exReading": "ぱーてぃー の じゅんび が ととのいました",
    "exTranslation": "The preparations for the party are all in order.",
    "category": "Verb"
  },
  {
    "term": "調節",
    "termReading": "ちょうせつ",
    "termTranslation": "adjustment, regulation",
    "ex": "ここで部屋の温度が調節できます。",
    "exReading": "ここ で へや の おんど が ちょうせつ できます",
    "exTranslation": "You can adjust the room temperature here.",
    "category": "Verbal Noun"
  },
  {
    "term": "提出",
    "termReading": "ていしゅつ",
    "termTranslation": "submittal",
    "ex": "課題は7月5日までに提出してください。",
    "exReading": "かだい は 7 がつ 5 か まで に ていしゅつ して ください",
    "exTranslation": "Please submit your assignment by July 5.",
    "category": "Verbal Noun"
  },
  {
    "term": "前提",
    "termReading": "ぜんてい",
    "termTranslation": "assumption, premise",
    "ex": "交渉が成立するという前提で話を進めます。",
    "exReading": "こうしょう が せいりつ する と いう ぜんてい で はなし を すすめます",
    "exTranslation": "We're proceeding with talks under the assumption we'll complete the deal.",
    "category": "Noun"
  },
  {
    "term": "答案",
    "termReading": "とうあん",
    "termTranslation": "examination paper, answer",
    "ex": "今から答案を集めます。",
    "exReading": "いま から とうあん を あつめます",
    "exTranslation": "I'll collect the answer sheets now.",
    "category": "Noun"
  },
  {
    "term": "はっと",
    "termReading": "はっと",
    "termTranslation": "taken aback",
    "ex": "彼女の美しさにはっとしたよ。",
    "exReading": "かのじょ の うつくしさ に はっと した よ",
    "exTranslation": "I was amazed by her beauty.",
    "category": "Adverb"
  },
  {
    "term": "投票",
    "termReading": "とうひょう",
    "termTranslation": "vote, ballot",
    "ex": "私は朝早く投票を済ませました。",
    "exReading": "わたし は あさ はやく とうひょう を すませました",
    "exTranslation": "I finished voting early in the morning.",
    "category": "Verbal Noun"
  },
  {
    "term": "目標",
    "termReading": "もくひょう",
    "termTranslation": "target, goal",
    "ex": "私は父を目標にしています。",
    "exReading": "わたし は ちち を もくひょう に して います",
    "exTranslation": "I take my father as a role model.",
    "category": "Noun"
  },
  {
    "term": "標準",
    "termReading": "ひょうじゅん",
    "termTranslation": "standard, norm",
    "ex": "ニュースでは標準語が使われるの。",
    "exReading": "にゅーす で は ひょうじゅんご が つかわれる の",
    "exTranslation": "Standard language is used in news broadcasts.",
    "category": "Noun"
  },
  {
    "term": "連れる",
    "termReading": "つれる",
    "termTranslation": "take along, bring along",
    "ex": "親が君を一度連れて来いって言うんだ。",
    "exReading": "おや が きみ を いちど つれて こい って いう ん だ",
    "exTranslation": "My parents said I should take you to their place one time.",
    "category": "Verb"
  },
  {
    "term": "連日",
    "termReading": "れんじつ",
    "termTranslation": "day after day, everyday",
    "ex": "展覧会は連日賑わいました。",
    "exReading": "てんらんかい は れんじつ にぎわいました",
    "exTranslation": "The exhibition was crowded every day.",
    "category": "Noun"
  },
  {
    "term": "まずい",
    "termReading": "まずい",
    "termTranslation": "bad, no good",
    "ex": "彼女の運転がまずくて、はらはらした。",
    "exReading": "かのじょ の うんてん が まずくて はらはら した",
    "exTranslation": "I felt uneasy since her driving was so bad.",
    "category": "Adjective"
  },
  {
    "term": "連休",
    "termReading": "れんきゅう",
    "termTranslation": "consecutive holidays",
    "ex": "今度の連休は実家に帰ります。",
    "exReading": "こんど の れんきゅう は じっか に かえります",
    "exTranslation": "I'll go back to my parents' house for the next consecutive holidays.",
    "category": "Noun"
  },
  {
    "term": "連れ",
    "termReading": "つれ",
    "termTranslation": "companion",
    "ex": "彼女は私の連れです。",
    "exReading": "かのじょ は わたし の つれ です",
    "exTranslation": "She's with me.",
    "category": "Noun"
  },
  {
    "term": "連絡",
    "termReading": "れんらく",
    "termTranslation": "connection, communication",
    "ex": "仕事が終わったら連絡します。",
    "exReading": "しごと が おわったら れんらく します",
    "exTranslation": "I'll contact you when I finish the job.",
    "category": "Verbal Noun"
  },
  {
    "term": "手続き",
    "termReading": "てつづき",
    "termTranslation": "procedure, formalities",
    "ex": "入国手続きが終わりました。",
    "exReading": "にゅうこく てつづき が おわりました",
    "exTranslation": "The immigration procedures are finished.",
    "category": "Noun"
  },
  {
    "term": "連続",
    "termReading": "れんぞく",
    "termTranslation": "continuation, succession",
    "ex": "彼女の人生は苦労の連続でした。",
    "exReading": "かのじょ の じんせい は くろう の れんぞく でした",
    "exTranslation": "Her life was a series of hardships.",
    "category": "Verbal Noun"
  },
  {
    "term": "もしも",
    "termReading": "もしも",
    "termTranslation": "if, if ever",
    "ex": "もしも彼女と結婚できたらどんなに嬉しいだろう。",
    "exReading": "もしも かのじょ と けっこん できたら どんなに うれしい だろう",
    "exTranslation": "If I could ever marry her, how happy I would be!",
    "category": "Adverb"
  },
  {
    "term": "続々",
    "termReading": "ぞくぞく",
    "termTranslation": "in rapid succession, one after another",
    "ex": "お客さんが続々とやって来ましたよ。",
    "exReading": "おきゃくさん が ぞくぞく と やってきました よ",
    "exTranslation": "The guests came one after another.",
    "category": "Adverb"
  },
  {
    "term": "続き",
    "termReading": "つづき",
    "termTranslation": "continuation, sequel",
    "ex": "話の続きは電話でしましょう。",
    "exReading": "はなし の つづき は でんわ で しましょう",
    "exTranslation": "Let's continue this conversation on the phone.",
    "category": "Noun"
  },
  {
    "term": "長続き",
    "termReading": "ながつづき",
    "termTranslation": "lasting for a long time",
    "ex": "彼は仕事が長続きしません。",
    "exReading": "かれ は しごと が ながつづき しません",
    "exTranslation": "He can't keep a job for long.",
    "category": "Verbal Noun"
  },
  {
    "term": "相場",
    "termReading": "そうば",
    "termTranslation": "market price, speculation",
    "ex": "この辺りの家賃の相場はいくらですか。",
    "exReading": "この あたり の やちん の そうば は いくら です か",
    "exTranslation": "What is the going rate for rent around here?",
    "category": "Noun"
  },
  {
    "term": "相当",
    "termReading": "そうとう",
    "termTranslation": "correspondence, suitability",
    "ex": "1ポンドは454グラムに相当します。",
    "exReading": "いちぽんど は 454 ぐらむ に そうとう します",
    "exTranslation": "One pound is equivalent to 454 grams.",
    "category": "Verbal Noun"
  },
  {
    "term": "やかましい",
    "termReading": "やかましい",
    "termTranslation": "noisy, clamorous",
    "ex": "スピーカーの音がやかましいな。",
    "exReading": "すぴーかー の おと が やかましい な",
    "exTranslation": "The sound coming from the speaker's too loud.",
    "category": "Adjective"
  },
  {
    "term": "相応しい",
    "termReading": "ふさわしい",
    "termTranslation": "suitable, appropriate",
    "ex": "その場に相応しい服装で来てください。",
    "exReading": "その ば に ふさわしい ふくそう で きて ください",
    "exTranslation": "Please come in suitable clothes for the occasion.",
    "category": "Adjective"
  },
  {
    "term": "対談",
    "termReading": "たいだん",
    "termTranslation": "interview",
    "ex": "雑誌にその女優の対談が載っていたよ。",
    "exReading": "ざっし に その じょゆう の たいだん が のって いた よ",
    "exTranslation": "There was an interview with that actress in a magazine.",
    "category": "Verbal Noun"
  },
  {
    "term": "録画",
    "termReading": "ろくが",
    "termTranslation": "videotaping",
    "ex": "好きな番組を録画したの。",
    "exReading": "すき な ばんぐみ を ろくが した の",
    "exTranslation": "I recorded my favorite program.",
    "category": "Verbal Noun"
  },
  {
    "term": "録音テープ",
    "termReading": "ろくおんテープ",
    "termTranslation": "tape",
    "ex": "インタビューはこの録音テープに入っています。",
    "exReading": "いんたびゅー は この ろくおんてーぷ に はいって います",
    "exTranslation": "The interview is recorded on this tape.",
    "category": "Noun"
  },
  {
    "term": "登場",
    "termReading": "とうじょう",
    "termTranslation": "appearance on stage, appearance",
    "ex": "彼女の登場で会場は盛り上がったね。",
    "exReading": "かのじょ の とうじょう で かいじょう は もりあがった ね",
    "exTranslation": "The audience got excited as she appeared.",
    "category": "Verbal Noun"
  },
  {
    "term": "登録",
    "termReading": "とうろく",
    "termTranslation": "registration",
    "ex": "心理学の授業に登録しましたか。",
    "exReading": "しんりがく の じゅぎょう に とうろく しました か",
    "exTranslation": "Have you registered for the psychology class?",
    "category": "Verbal Noun"
  },
  {
    "term": "バット",
    "termReading": "バット",
    "termTranslation": "bat",
    "ex": "このバットは金属で出来ています。",
    "exReading": "この ばっと は きんぞく で できて います",
    "exTranslation": "This bat is made of metal.",
    "category": "Noun"
  },
  {
    "term": "登山",
    "termReading": "とざん",
    "termTranslation": "climbing, mountaineer",
    "ex": "夏休みには家族で登山をします。",
    "exReading": "なつやすみ に は かぞく で とざん を します",
    "exTranslation": "I'm going mountain climbing with my family during summer vacation.",
    "category": "Verbal Noun"
  },
  {
    "term": "登校",
    "termReading": "とうこう",
    "termTranslation": "attending school",
    "ex": "生徒たちは朝8時ごろ登校します。",
    "exReading": "せいとたち は あさ 8 じごろ とうこう します",
    "exTranslation": "Pupils arrive at school around 8 AM.",
    "category": "Verbal Noun"
  },
  {
    "term": "山登り",
    "termReading": "やまのぼり",
    "termTranslation": "mountain climbing",
    "ex": "明日は友達と山登りに行きます。",
    "exReading": "あした は ともだち と やまのぼり に いきます",
    "exTranslation": "I'm going mountain climbing with my friend tomorrow.",
    "category": "Verbal Noun"
  },
  {
    "term": "無関心",
    "termReading": "むかんしん",
    "termTranslation": "apathy, indifference",
    "ex": "彼女は政治に無関心です。",
    "exReading": "かのじょ は せいじ に むかんしん です",
    "exTranslation": "She's indifferent to politics.",
    "category": "Adjectival Noun"
  },
  {
    "term": "無関係",
    "termReading": "むかんけい",
    "termTranslation": "irrelevant, unrelated",
    "ex": "彼はこの事件と無関係です。",
    "exReading": "かれ は この じけん と むかんけい です",
    "exTranslation": "He has nothing to do with this incident.",
    "category": "Adjectival Noun"
  },
  {
    "term": "マナー",
    "termReading": "マナー",
    "termTranslation": "manners",
    "ex": "食事のマナーを守りましょう。",
    "exReading": "しょくじ の まなー を まもりましょう",
    "exTranslation": "We should observe table manners.",
    "category": "Noun"
  },
  {
    "term": "不況",
    "termReading": "ふきょう",
    "termTranslation": "depression, slump",
    "ex": "不況の影響で仕事が少ないね。",
    "exReading": "ふきょう の えいきょう で しごと が すくない ね",
    "exTranslation": "There's little work due to the recession.",
    "category": "Noun"
  },
  {
    "term": "態度",
    "termReading": "たいど",
    "termTranslation": "attitude, manner",
    "ex": "あの男の態度にみんなあきれてたよ。",
    "exReading": "あの おとこ の たいど に みんな あきれて た よ",
    "exTranslation": "Everyone was tired of the man's attitude.",
    "category": "Noun"
  },
  {
    "term": "明治",
    "termReading": "めいじ",
    "termTranslation": "Meiji era",
    "ex": "祖父は明治の生まれです。",
    "exReading": "そふ は めいじ の うまれ です",
    "exTranslation": "My grandfather was born in the Meiji era.",
    "category": "Noun"
  },
  {
    "term": "府",
    "termReading": "ふ",
    "termTranslation": "urban prefecture",
    "ex": "彼は大阪府に住んでいます。",
    "exReading": "かれ は おおさかふ に すんで います",
    "exTranslation": "He lives in Osaka Prefecture.",
    "category": "Noun"
  },
  {
    "term": "府立",
    "termReading": "ふりつ",
    "termTranslation": "prefectural",
    "ex": "彼女は府立大学に通っています。",
    "exReading": "かのじょ は ふりつ だいがく に かよって います",
    "exTranslation": "She goes to the prefectural university.",
    "category": "Noun"
  },
  {
    "term": "ばらばら",
    "termReading": "ばらばら",
    "termTranslation": "separately",
    "ex": "生徒たちはばらばらに帰宅したね。",
    "exReading": "せいとたち は ばらばら に きたく した ね",
    "exTranslation": "Students went home separately.",
    "category": "Adverb"
  },
  {
    "term": "野党",
    "termReading": "やとう",
    "termTranslation": "opposition party",
    "ex": "野党が与党を厳しく非難していましたね。",
    "exReading": "やとう が よとう を きびしく ひなん して いました ね",
    "exTranslation": "The opposition party was severely criticizing the ruling party.",
    "category": "Noun"
  },
  {
    "term": "当選",
    "termReading": "とうせん",
    "termTranslation": "election to office",
    "ex": "彼は選挙に当選しました。",
    "exReading": "かれ は せんきょ に とうせん しました",
    "exTranslation": "He won the election.",
    "category": "Verbal Noun"
  },
  {
    "term": "入選",
    "termReading": "にゅうせん",
    "termTranslation": "acceptance, selection",
    "ex": "彼の絵がコンクールに入選した。",
    "exReading": "かれ の え が こんくーる に にゅうせん した",
    "exTranslation": "His painting won a prize at the contest.",
    "category": "Verbal Noun"
  },
  {
    "term": "選択",
    "termReading": "せんたく",
    "termTranslation": "selection, choice",
    "ex": "この5種類から選択できます。",
    "exReading": "この 5 しゅるい から せんたく できます",
    "exTranslation": "You can select one from these five types.",
    "category": "Verbal Noun"
  },
  {
    "term": "天候",
    "termReading": "てんこう",
    "termTranslation": "weather, climate",
    "ex": "ここは天候の変化が激しいですね。",
    "exReading": "ここ は てんこう の へんか が はげしい です ね",
    "exTranslation": "The climate here is very changeable.",
    "category": "Noun"
  },
  {
    "term": "手首",
    "termReading": "てくび",
    "termTranslation": "wrist",
    "ex": "手首の関節をひねっちゃった。",
    "exReading": "てくび の かんせつ を ひねっちゃった",
    "exTranslation": "I have twisted my wrist joint.",
    "category": "Noun"
  },
  {
    "term": "プラットホーム",
    "termReading": "プラットホーム",
    "termTranslation": "platform",
    "ex": "プラットホームで電車が来るのを待ったの。",
    "exReading": "ぷらっとほーむ で でんしゃ が くる の を まった の",
    "exTranslation": "I waited on the platform for the train to arrive.",
    "category": "Noun"
  },
  {
    "term": "脳",
    "termReading": "のう",
    "termTranslation": "brain",
    "ex": "そのクイズ番組は脳を刺激するね。",
    "exReading": "その くいずばんぐみ は のう を しげき する ね",
    "exTranslation": "The quiz show stimulates the brain.",
    "category": "Noun"
  },
  {
    "term": "悩む",
    "termReading": "なやむ",
    "termTranslation": "be troubled, suffer",
    "ex": "彼は受験のことで悩んでいます。",
    "exReading": "かれ は じゅけん の こと で なやんで います",
    "exTranslation": "He's worried about entrance examinations.",
    "category": "Verb"
  },
  {
    "term": "悩み",
    "termReading": "なやみ",
    "termTranslation": "troubles, worries",
    "ex": "彼は大きな悩みを抱えていました。",
    "exReading": "かれ は おおき な なやみ を かかえて いました",
    "exTranslation": "He had a major concern.",
    "category": "Noun"
  },
  {
    "term": "先頭",
    "termReading": "せんとう",
    "termTranslation": "forefront, head",
    "ex": "先頭の人はプラカードを持ってください。",
    "exReading": "せんとう の ひと は ぷらかーど を もって ください",
    "exTranslation": "People at the front, please hold up a placard.",
    "category": "Noun"
  },
  {
    "term": "命じる",
    "termReading": "めいじる",
    "termTranslation": "order, give orders",
    "ex": "急に出張を命じられました。",
    "exReading": "きゅう に しゅっちょう を めいじられました",
    "exTranslation": "I was suddenly sent on a business trip.",
    "category": "Verb"
  },
  {
    "term": "まぐろ",
    "termReading": "まぐろ",
    "termTranslation": "tuna",
    "ex": "まぐろの刺身を食べました。",
    "exReading": "まぐろ の さしみ を たべました",
    "exTranslation": "I ate tuna sashimi.",
    "category": "Noun"
  },
  {
    "term": "領土",
    "termReading": "りょうど",
    "termTranslation": "territory, domain",
    "ex": "ここから先は隣の国の領土です。",
    "exReading": "ここ から さき は となり の くに の りょうど です",
    "exTranslation": "From here onwards is the territory of the neighboring country.",
    "category": "Noun"
  },
  {
    "term": "領事館",
    "termReading": "りょうじかん",
    "termTranslation": "consulate",
    "ex": "彼はアメリカ領事館に出かけたよ。",
    "exReading": "かれ は あめりか りょうじかん に でかけた よ",
    "exTranslation": "He went out to the American consulate.",
    "category": "Noun"
  },
  {
    "term": "領収書",
    "termReading": "りょうしゅうしょ",
    "termTranslation": "receipt, voucher",
    "ex": "領収書をください。",
    "exReading": "りょうしゅうしょ を ください",
    "exTranslation": "Please give me a receipt.",
    "category": "Noun"
  },
  {
    "term": "取り組む",
    "termReading": "とりくむ",
    "termTranslation": "grapple, pursue",
    "ex": "彼女はスペイン語に取り組んでいます。",
    "exReading": "かのじょ は すぺいんご に とりくんで います",
    "exTranslation": "She's working on her Spanish.",
    "category": "Verb"
  },
  {
    "term": "任せる",
    "termReading": "まかせる",
    "termTranslation": "entrust to, leave it to",
    "ex": "この仕事は君に任せる。",
    "exReading": "この しごと は きみ に まかせる",
    "exTranslation": "I leave this work up to you.",
    "category": "Verb"
  },
  {
    "term": "ろくに",
    "termReading": "ろくに",
    "termTranslation": "hardly, barely",
    "ex": "ゆうべはろくに寝ていない。",
    "exReading": "ゆうべ は ろくに ねて いない",
    "exTranslation": "I barely slept last night.",
    "category": "Adverb"
  },
  {
    "term": "担任",
    "termReading": "たんにん",
    "termTranslation": "supervisor",
    "ex": "私は3年生のクラスを担任しています。",
    "exReading": "わたし は 3 ねんせい の くらす を たんにん して います",
    "exTranslation": "I'm in charge of the third-grade class.",
    "category": "Verbal Noun"
  },
  {
    "term": "無責任",
    "termReading": "むせきにん",
    "termTranslation": "irresponsibility",
    "ex": "無責任な行動は許されません。",
    "exReading": "むせきにん な こうどう は ゆるされません",
    "exTranslation": "Irresponsible behavior will not be tolerated.",
    "category": "Adjectival Noun"
  },
  {
    "term": "転勤",
    "termReading": "てんきん",
    "termTranslation": "transfer",
    "ex": "彼は大阪に転勤しました。",
    "exReading": "かれ は おおさか に てんきん しました",
    "exTranslation": "He was transferred to Osaka.",
    "category": "Verbal Noun"
  },
  {
    "term": "勤め先",
    "termReading": "つとめさき",
    "termTranslation": "employment",
    "ex": "私の勤め先にご連絡ください。",
    "exReading": "わたし の つとめさき に ごれんらく ください",
    "exTranslation": "Please contact my office.",
    "category": "Noun"
  },
  {
    "term": "勤め",
    "termReading": "つとめ",
    "termTranslation": "service, duties",
    "ex": "来月で勤めをやめます。",
    "exReading": "らいげつ で つとめ を やめます",
    "exTranslation": "I'll quit my job next month.",
    "category": "Noun"
  },
  {
    "term": "務める",
    "termReading": "つとめる",
    "termTranslation": "perform a duty, play a role",
    "ex": "私が司会を務めます。",
    "exReading": "わたし が しかい を つとめます",
    "exTranslation": "I'll serve as the host.",
    "category": "Verb"
  },
  {
    "term": "つまむ",
    "termReading": "つまむ",
    "termTranslation": "munch on",
    "ex": "お菓子でもつまんでください。",
    "exReading": "おかし で も つまんで ください",
    "exTranslation": "Please help yourself to some snacks.",
    "category": "Verb"
  },
  {
    "term": "任務",
    "termReading": "にんむ",
    "termTranslation": "duty, mission",
    "ex": "彼は最後まで自分の任務を果たした。",
    "exReading": "かれ は さいご まで じぶん の にんむ を はたした",
    "exTranslation": "He fulfilled his duties right to the end.",
    "category": "Noun"
  },
  {
    "term": "務め",
    "termReading": "つとめ",
    "termTranslation": "duty, task",
    "ex": "人々を守るのが私の務めです。",
    "exReading": "ひとびと を まもる の が わたし の つとめ です",
    "exTranslation": "It's my duty to defend people.",
    "category": "Noun"
  },
  {
    "term": "雇う",
    "termReading": "やとう",
    "termTranslation": "employ, hire",
    "ex": "新しい社員を雇いました。",
    "exReading": "あたらしい しゃいん を やといました",
    "exTranslation": "We employed a new staff member.",
    "category": "Verb"
  },
  {
    "term": "募集",
    "termReading": "ぼしゅう",
    "termTranslation": "recruitment, collection",
    "ex": "私の会社で社員を募集しています。",
    "exReading": "わたし の かいしゃ で しゃいん を ぼしゅう して います",
    "exTranslation": "My company is recruiting new staff.",
    "category": "Verbal Noun"
  },
  {
    "term": "採る",
    "termReading": "とる",
    "termTranslation": "gather, pick",
    "ex": "この山ではきのこが採れますよ",
    "exReading": "この やま で は きのこ が とれます よ",
    "exTranslation": "You can collect mushrooms on this mountain.",
    "category": "Verb"
  },
  {
    "term": "ボーイ",
    "termReading": "ボーイ",
    "termTranslation": "waiter",
    "ex": "ボーイに荷物を運んでもらった。",
    "exReading": "ぼーい に にもつ を はこんで もらった",
    "exTranslation": "I had the porter carry the luggage.",
    "category": "Noun"
  },
  {
    "term": "就く",
    "termReading": "つく",
    "termTranslation": "set about a task, enter upon",
    "ex": "今年から新しい仕事に就きます。",
    "exReading": "ことし から あたらしい しごと に つきます",
    "exTranslation": "I'll start a new job this year.",
    "category": "Verb"
  },
  {
    "term": "退職",
    "termReading": "たいしょく",
    "termTranslation": "retirement, resignation",
    "ex": "私は今月一杯で退職します。",
    "exReading": "わたし は こんげつ いっぱい で たいしょく します",
    "exTranslation": "I retire at the end of this month.",
    "category": "Verbal Noun"
  },
  {
    "term": "退学",
    "termReading": "たいがく",
    "termTranslation": "withdrawal from school",
    "ex": "弟は高校を退学しました。",
    "exReading": "おとうと は こうこう を たいがく しました",
    "exTranslation": "My little brother withdrew from high school.",
    "category": "Verbal Noun"
  },
  {
    "term": "早退",
    "termReading": "そうたい",
    "termTranslation": "early departure",
    "ex": "具合が悪かったので仕事を早退しました。",
    "exReading": "ぐあい が わるかった の で しごと を そうたい しました",
    "exTranslation": "I left work earlier than usual because I was unwell.",
    "category": "Verbal Noun"
  },
  {
    "term": "理屈",
    "termReading": "りくつ",
    "termTranslation": "reason, logic",
    "ex": "彼には理屈が通じない。",
    "exReading": "かれ に は りくつ が つうじない",
    "exTranslation": "He doesn't understand logics.",
    "category": "Noun"
  },
  {
    "term": "ホット",
    "termReading": "ホット",
    "termTranslation": "hot",
    "ex": "コーヒーをホットでください。",
    "exReading": "こーひー を ほっと で ください",
    "exTranslation": "Please bring me a cup of hot coffee.",
    "category": "Noun"
  },
  {
    "term": "退屈",
    "termReading": "たいくつ",
    "termTranslation": "boring, tedious",
    "ex": "校長の退屈な話が続いたんだよ。",
    "exReading": "こうちょう の たいくつ な はなし が つづいた ん だ よ",
    "exTranslation": "The principal's boring talk carried on.",
    "category": "Verbal Noun"
  },
  {
    "term": "用件",
    "termReading": "ようけん",
    "termTranslation": "matter, things to be done",
    "ex": "用件をメモしておきました。",
    "exReading": "ようけん を めも して おきました",
    "exTranslation": "I made a note of the matter.",
    "category": "Noun"
  },
  {
    "term": "参る",
    "termReading": "まいる",
    "termTranslation": "go, come",
    "ex": "さあ、参りましょうか。",
    "exReading": "さあ まいりましょう か",
    "exTranslation": "Well, shall we go?",
    "category": "Verb"
  },
  {
    "term": "惨め",
    "termReading": "みじめ",
    "termTranslation": "miserable, pitiable",
    "ex": "彼は惨めな気持ちになったの。",
    "exReading": "かれ は みじめ な きもち に なった の",
    "exTranslation": "He began to feel miserable.",
    "category": "Adjective"
  },
  {
    "term": "追加",
    "termReading": "ついか",
    "termTranslation": "addition",
    "ex": "オーダーを追加しました。",
    "exReading": "おーだー を ついか しました",
    "exTranslation": "I made an additional order.",
    "category": "Verbal Noun"
  },
  {
    "term": "まし",
    "termReading": "まし",
    "termTranslation": "more, better",
    "ex": "これでもないよりましだ。",
    "exReading": "これでもないよりましだ。",
    "exTranslation": "Even this is better than nothing.",
    "category": "Adjective"
  },
  {
    "term": "比率",
    "termReading": "ひりつ",
    "termTranslation": "ratio, percentage",
    "ex": "IT業界は女性の比率が低い。",
    "exReading": "あいてぃーぎょうかい は じょせい の ひりつ が ひくい",
    "exTranslation": "The ratio of women in the IT industry is low.",
    "category": "Noun"
  },
  {
    "term": "対比",
    "termReading": "たいひ",
    "termTranslation": "contrast, comparison",
    "ex": "この絵は赤と黒の対比が美しいですね。",
    "exReading": "この え は あか と くろ の たいひ が うつくしい です ね",
    "exTranslation": "The contrast of the red and black in this picture is beautiful.",
    "category": "Verbal Noun"
  },
  {
    "term": "見比べる",
    "termReading": "みくらべる",
    "termTranslation": "look from one to another, compare",
    "ex": "彼女は二人の顔を見比べたの。",
    "exReading": "かのじょ は ふたり の かお を みくらべた の",
    "exTranslation": "She looked alternately at the two of them, reading their faces.",
    "category": "Verb"
  },
  {
    "term": "比較的",
    "termReading": "ひかくてき",
    "termTranslation": "comparatively, relatively",
    "ex": "今年は比較的景気がいい。",
    "exReading": "ことし は ひかくてき けいき が いい",
    "exTranslation": "Business is comparatively good this year.",
    "category": "Adverb"
  },
  {
    "term": "比較",
    "termReading": "ひかく",
    "termTranslation": "comparison",
    "ex": "去年の売り上げと比較しましょう。",
    "exReading": "きょねん の うりあげ と ひかく しましょう",
    "exTranslation": "Let's compare it with last year's sales.",
    "category": "Verbal Noun"
  },
  {
    "term": "判決",
    "termReading": "はんけつ",
    "termTranslation": "judicial decision, judgment",
    "ex": "判決が下された。",
    "exReading": "はんけつ が くだされた",
    "exTranslation": "Judgment was passed.",
    "category": "Noun"
  },
  {
    "term": "ぱっと",
    "termReading": "ぱっと",
    "termTranslation": "suddenly, quickly",
    "ex": "彼女はぱっと目を開いた。",
    "exReading": "かのじょ は ぱっと め を ひらいた",
    "exTranslation": "She suddenly opened her eyes.",
    "category": "Adverb"
  },
  {
    "term": "判子",
    "termReading": "はんこ",
    "termTranslation": "seal, stamp",
    "ex": "ここに判子を押してください。",
    "exReading": "ここ に はんこ を おして ください",
    "exTranslation": "Please put your personal seal here.",
    "category": "Noun"
  },
  {
    "term": "評判",
    "termReading": "ひょうばん",
    "termTranslation": "fame, reputation",
    "ex": "評判の良いレストランに行きました。",
    "exReading": "ひょうばん の いい れすとらん に いきました",
    "exTranslation": "We went to a restaurant with a good reputation.",
    "category": "Noun"
  },
  {
    "term": "批評",
    "termReading": "ひひょう",
    "termTranslation": "criticism, comment",
    "ex": "その映画はよい批評を得ているんだ。",
    "exReading": "その えいが は よい ひひょう を えて いる ん だ",
    "exTranslation": "The movie received critical acclaim.",
    "category": "Verbal Noun"
  },
  {
    "term": "反感",
    "termReading": "はんかん",
    "termTranslation": "ill feeling, antipathy",
    "ex": "俺たちは彼の言動に反感を持ったね。",
    "exReading": "おれたち は かれ の げんどう に はんかん を もった ね",
    "exTranslation": "We had negative feelings towards his conduct.",
    "category": "Noun"
  },
  {
    "term": "予想",
    "termReading": "よそう",
    "termTranslation": "prospect, expectation",
    "ex": "私の予想が当たった。",
    "exReading": "わたし の よそう が あたった",
    "exTranslation": "My forecast proved right.",
    "category": "Verbal Noun"
  },
  {
    "term": "つなぐ",
    "termReading": "つなぐ",
    "termTranslation": "connect, join",
    "ex": "インターネットは世界の人々をつなぎますね。",
    "exReading": "いんたーねっと は せかい の ひとびと を つなぎます ね",
    "exTranslation": "The Internet links people around the world together.",
    "category": "Verb"
  },
  {
    "term": "理想",
    "termReading": "りそう",
    "termTranslation": "ideal",
    "ex": "彼は高い理想を持っているの。",
    "exReading": "かれ は たかい りそう を もって いる の",
    "exTranslation": "He has high ideals.",
    "category": "Noun"
  },
  {
    "term": "想像",
    "termReading": "そうぞう",
    "termTranslation": "imagination",
    "ex": "そんなことは想像できないよ。",
    "exReading": "そんな こと は そうぞう できない よ",
    "exTranslation": "I can't imagine such a thing.",
    "category": "Verbal Noun"
  },
  {
    "term": "象",
    "termReading": "ぞう",
    "termTranslation": "elephant",
    "ex": "象に乗ってみたいです。",
    "exReading": "ぞう に のって みたい です",
    "exTranslation": "I want to ride on the elephant.",
    "category": "Noun"
  },
  {
    "term": "抽象的",
    "termReading": "ちゅうしょうてき",
    "termTranslation": "abstract",
    "ex": "彼は抽象的な絵が好きだね。",
    "exReading": "かれ は ちゅうしょうてき な え が すき だ ね",
    "exTranslation": "He likes abstract pictures.",
    "category": "Adjective"
  },
  {
    "term": "保障",
    "termReading": "ほしょう",
    "termTranslation": "guarantee, security",
    "ex": "私があなたの安全を保障します。",
    "exReading": "わたし が あなた の あんぜん を ほしょう します",
    "exTranslation": "I guarantee your safety.",
    "category": "Verbal Noun"
  },
  {
    "term": "ひょっとしたら",
    "termReading": "ひょっとしたら",
    "termTranslation": "perhaps",
    "ex": "ひょっとしたら彼はそのことを知らないのかもしれない。",
    "exReading": "ひょっとしたら かれ は その こと を しらない の かも しれない",
    "exTranslation": "Perhaps he might not know that.",
    "category": "Adverb"
  },
  {
    "term": "負傷",
    "termReading": "ふしょう",
    "termTranslation": "hurt, injury",
    "ex": "その事故で多くの人が負傷したの。",
    "exReading": "その じこ で おおく の ひと が ふしょう した の",
    "exTranslation": "A lot of people were injured in the accident.",
    "category": "Verbal Noun"
  },
  {
    "term": "変換",
    "termReading": "へんかん",
    "termTranslation": "change, conversion",
    "ex": "ひらがなをカタカナに変換しました。",
    "exReading": "ひらがな を かたかな に へんかん しました",
    "exTranslation": "I converted hiragana into katakana.",
    "category": "Verbal Noun"
  },
  {
    "term": "取り替える",
    "termReading": "とりかえる",
    "termTranslation": "change, replace",
    "ex": "シーツを取り替えました。",
    "exReading": "しーつ を とりかえました",
    "exTranslation": "I changed the sheets.",
    "category": "Verb"
  },
  {
    "term": "立て替える",
    "termReading": "たてかえる",
    "termTranslation": "pay for another, pay in advance",
    "ex": "お金がないなら私が立て替えておきます。",
    "exReading": "おかね が ない なら わたし が たてかえて おきます",
    "exTranslation": "I'll pay for you for the time being if you have no money.",
    "category": "Verb"
  },
  {
    "term": "天災",
    "termReading": "てんさい",
    "termTranslation": "natural disaster",
    "ex": "天災を防ぐことはできません。",
    "exReading": "てんさい を ふせぐ こと は できません",
    "exTranslation": "Natural disasters can't be prevented.",
    "category": "Noun"
  },
  {
    "term": "有害",
    "termReading": "ゆうがい",
    "termTranslation": "harmful, hazardous",
    "ex": "この材料は有害だよ。",
    "exReading": "この ざいりょう は ゆうがい だ よ",
    "exTranslation": "This material is hazardous.",
    "category": "Adjectival Noun"
  },
  {
    "term": "ひとりでに",
    "termReading": "ひとりでに",
    "termTranslation": "automatically, by oneself",
    "ex": "ドアがひとりでに閉まったな。",
    "exReading": "どあ が ひとりでに しまった な",
    "exTranslation": "The door closed by itself.",
    "category": "Adverb"
  },
  {
    "term": "無害",
    "termReading": "むがい",
    "termTranslation": "harmless",
    "ex": "この農薬は人には無害です。",
    "exReading": "この のうやく は ひと に は むがい です",
    "exTranslation": "This agricultural chemical is harmless to humans.",
    "category": "Adjectival Noun"
  },
  {
    "term": "被害",
    "termReading": "ひがい",
    "termTranslation": "damage, harm",
    "ex": "彼の家は台風の被害にあったの。",
    "exReading": "かれ の いえ は たいふう の ひがい に あった の",
    "exTranslation": "His house was damaged in the typhoon.",
    "category": "Noun"
  },
  {
    "term": "破る",
    "termReading": "やぶる",
    "termTranslation": "defeat",
    "ex": "彼は強敵を見事に破ったね。",
    "exReading": "かれ は きょうてき を みごと に やぶった ね",
    "exTranslation": "He heroically defeated the powerful enemy.",
    "category": "Verb"
  },
  {
    "term": "破壊",
    "termReading": "はかい",
    "termTranslation": "breaking, destruction",
    "ex": "自然の破壊が進んでいるのよ。",
    "exReading": "しぜん の はかい が すすんで いる の よ",
    "exTranslation": "The destruction of nature is advancing.",
    "category": "Verbal Noun"
  },
  {
    "term": "助かる",
    "termReading": "たすかる",
    "termTranslation": "be helped, be rescued",
    "ex": "彼はシートベルトをしていたので助かったんだ。",
    "exReading": "かれ は しーとべると を して いた の で たすかった ん だ",
    "exTranslation": "He survived because he was wearing a seat belt.",
    "category": "Verb"
  },
  {
    "term": "ひねる",
    "termReading": "ひねる",
    "termTranslation": "twist, turn",
    "ex": "彼は水道の蛇口をひねったの。",
    "exReading": "かれ は すいどう の じゃぐち を ひねった の",
    "exTranslation": "He turned the tap.",
    "category": "Verb"
  },
  {
    "term": "派手",
    "termReading": "はで",
    "termTranslation": "showy, flashy",
    "ex": "雪道で派手に転んでしまったの。",
    "exReading": "ゆきみち で はで に ころんで しまった の",
    "exTranslation": "I slipped spectacularly on the snowy road.",
    "category": "Adjective"
  },
  {
    "term": "派出所",
    "termReading": "はしゅつじょ",
    "termTranslation": "branch office, police box",
    "ex": "派出所にだれもいないな。",
    "exReading": "はしゅつじょ に だれ も いない な",
    "exTranslation": "There's no one in the police box.",
    "category": "Noun"
  },
  {
    "term": "派遣",
    "termReading": "はけん",
    "termTranslation": "dispatch, sending",
    "ex": "彼はイギリスに派遣されました。",
    "exReading": "かれ は いぎりす に はけん されました",
    "exTranslation": "He was sent to England.",
    "category": "Verbal Noun"
  },
  {
    "term": "犯人",
    "termReading": "はんにん",
    "termTranslation": "criminal, offender",
    "ex": "あいつが犯人です。",
    "exReading": "あいつ が はんにん です",
    "exTranslation": "That guy's the criminal.",
    "category": "Noun"
  },
  {
    "term": "犯罪",
    "termReading": "はんざい",
    "termTranslation": "offense, crime",
    "ex": "最近犯罪が減っています。",
    "exReading": "さいきん はんざい が へって います",
    "exTranslation": "Crime has been decreasing lately.",
    "category": "Noun"
  },
  {
    "term": "ひび",
    "termReading": "ひび",
    "termTranslation": "crack",
    "ex": "窓ガラスにひびが入ったよ。",
    "exReading": "まどがらす に ひび が はいった よ",
    "exTranslation": "There's a crack in the window pane.",
    "category": "Noun"
  },
  {
    "term": "罪",
    "termReading": "つみ",
    "termTranslation": "crime, sin",
    "ex": "彼の罪は重いな。",
    "exReading": "かれ の つみ は おもい な",
    "exTranslation": "His crime is serious.",
    "category": "Noun"
  },
  {
    "term": "盗難",
    "termReading": "とうなん",
    "termTranslation": "robbery, burglary",
    "ex": "昨夜、自転車の盗難にあったよ。",
    "exReading": "さくや じてんしゃ の とうなん に あった よ",
    "exTranslation": "My bicycle was stolen last night.",
    "category": "Noun"
  },
  {
    "term": "捕まる",
    "termReading": "つかまる",
    "termTranslation": "be caught, be arrested",
    "ex": "彼女はついに捕まりました。",
    "exReading": "かのじょ は ついに つかまりました",
    "exTranslation": "She was finally caught.",
    "category": "Verb"
  },
  {
    "term": "捕まえる",
    "termReading": "つかまえる",
    "termTranslation": "arrest, capture",
    "ex": "少年は網でその蝶を捕まえた。",
    "exReading": "しょうねん は あみ で その ちょう を つかまえた",
    "exTranslation": "The boy captured the butterfly with the net.",
    "category": "Verb"
  },
  {
    "term": "逃げ出す",
    "termReading": "にげだす",
    "termTranslation": "make a break for, run off",
    "ex": "トラがおりから逃げ出しました。",
    "exReading": "とら が おり から にげだしました",
    "exTranslation": "The tiger escaped from the cage.",
    "category": "Verb"
  },
  {
    "term": "逃がす",
    "termReading": "にがす",
    "termTranslation": "let go",
    "ex": "釣った魚を逃がしました。",
    "exReading": "つった さかな を にがしました",
    "exTranslation": "I let go of the fish I had caught.",
    "category": "Verb"
  },
  {
    "term": "やっつける",
    "termReading": "やっつける",
    "termTranslation": "beat, let someone have it",
    "ex": "主人公が悪者をやっつけた。",
    "exReading": "しゅじんこう が わるもの を やっつけた",
    "exTranslation": "The hero finished off the villain.",
    "category": "Verb"
  },
  {
    "term": "逃げ道",
    "termReading": "にげみち",
    "termTranslation": "escape route",
    "ex": "失敗した時の逃げ道を考えたの。",
    "exReading": "しっぱい した とき の にげみち を かんがえた の",
    "exTranslation": "I considered my escape route if I failed.",
    "category": "Noun"
  },
  {
    "term": "戦う",
    "termReading": "たたかう",
    "termTranslation": "wage war, fight",
    "ex": "彼は最後まで戦ったよ。",
    "exReading": "かれ は さいご まで たたかった よ",
    "exTranslation": "He fought to the very end.",
    "category": "Verb"
  },
  {
    "term": "挑戦",
    "termReading": "ちょうせん",
    "termTranslation": "challenge",
    "ex": "彼は新しいことに挑戦している。",
    "exReading": "かれ は あたらしい こと に ちょうせん して いる",
    "exTranslation": "He's trying out new challenges.",
    "category": "Verbal Noun"
  },
  {
    "term": "敗戦",
    "termReading": "はいせん",
    "termTranslation": "lost battle, defeat",
    "ex": "敗戦の原因は何だろう。",
    "exReading": "はいせん の げんいん は なん だろう",
    "exTranslation": "I wonder what caused the defeat.",
    "category": "Verbal Noun"
  },
  {
    "term": "戦い",
    "termReading": "たたかい",
    "termTranslation": "war, match",
    "ex": "長い戦いが終わった。",
    "exReading": "ながい たたかい が おわった",
    "exTranslation": "The long battle came to an end.",
    "category": "Noun"
  },
  {
    "term": "レントゲン",
    "termReading": "レントゲン",
    "termTranslation": "X-rays",
    "ex": "病院でレントゲンを撮ったよ。",
    "exReading": "びょういん で れんとげん を とった よ",
    "exTranslation": "I had X-rays taken at the hospital.",
    "category": "Noun"
  },
  {
    "term": "大戦",
    "termReading": "たいせん",
    "termTranslation": "world war",
    "ex": "大戦で多くの人が亡くなりました。",
    "exReading": "たいせん で おおく の ひと が なくなりました",
    "exTranslation": "A lot of people died in the world wars.",
    "category": "Noun"
  },
  {
    "term": "混ぜる",
    "termReading": "まぜる",
    "termTranslation": "blend, mix by stirring",
    "ex": "カレーにヨーグルトを入れて、よく混ぜてください。",
    "exReading": "かれー に よーぐると を いれて よく まぜて ください",
    "exTranslation": "Please put the yogurt in the curry, and mix it well.",
    "category": "Verb"
  },
  {
    "term": "混ざる",
    "termReading": "まざる",
    "termTranslation": "be mixed, be stirred",
    "ex": "水と油は混ざりません。",
    "exReading": "みず と あぶら は まざりません",
    "exTranslation": "Water and oil don't mix.",
    "category": "Verb"
  },
  {
    "term": "混じる",
    "termReading": "まじる",
    "termTranslation": "be blended, get mixed",
    "ex": "電話の声に雑音が混じっていたの。",
    "exReading": "でんわ の こえ に ざつおん が まじって いた の",
    "exTranslation": "The voice on the phone was mixed with background noise.",
    "category": "Verb"
  },
  {
    "term": "乱れる",
    "termReading": "みだれる",
    "termTranslation": "be disordered, be disturbed",
    "ex": "風で髪が乱れたね。",
    "exReading": "かぜ で かみ が みだれた ね",
    "exTranslation": "The wind has messed up your hair.",
    "category": "Verb"
  },
  {
    "term": "パンツ",
    "termReading": "パンツ",
    "termTranslation": "pants, trousers",
    "ex": "彼女はスカートよりパンツが似合うね。",
    "exReading": "かのじょ は すかーと より ぱんつ が にあう ね",
    "exTranslation": "She looks better in pants than in a skirt.",
    "category": "Noun"
  },
  {
    "term": "乱す",
    "termReading": "みだす",
    "termTranslation": "put into disorder, disrupt",
    "ex": "彼は風紀を乱している。",
    "exReading": "かれ は ふうき を みだして いる",
    "exTranslation": "He's corrupting public morals.",
    "category": "Verb"
  },
  {
    "term": "統合",
    "termReading": "とうごう",
    "termTranslation": "integration, combination",
    "ex": "三つの町が統合されて新しい市が誕生したのよ。",
    "exReading": "みっつ の まち が とうごう されて あたらしい し が たんじょう した の よ",
    "exTranslation": "Three towns were merged and a new city was created.",
    "category": "Verbal Noun"
  },
  {
    "term": "統一",
    "termReading": "とういつ",
    "termTranslation": "unity, coordination",
    "ex": "文字の大きさは統一してください。",
    "exReading": "もじ の おおきさ は とういつ して ください",
    "exTranslation": "Please standardize the size of the characters.",
    "category": "Verbal Noun"
  },
  {
    "term": "統計",
    "termReading": "とうけい",
    "termTranslation": "statistics",
    "ex": "これは昨年の売り上げの統計です。",
    "exReading": "これ は さくねん の うりあげ の とうけい です",
    "exTranslation": "These are the statistics for last year's sales.",
    "category": "Verbal Noun"
  },
  {
    "term": "総会",
    "termReading": "そうかい",
    "termTranslation": "general meeting",
    "ex": "来月の総会は東京で行われます。",
    "exReading": "らいげつ の そうかい は とうきょう で おこなわれます",
    "exTranslation": "The general meeting next month is to be held in Tokyo.",
    "category": "Noun"
  },
  {
    "term": "むける",
    "termReading": "むける",
    "termTranslation": "come off, peel",
    "ex": "日焼けで皮がむけた。",
    "exReading": "ひやけ で かわ が むけた",
    "exTranslation": "My skin peeled off due to sunburn.",
    "category": "Verb"
  },
  {
    "term": "総合",
    "termReading": "そうごう",
    "termTranslation": "synthesis, integration",
    "ex": "全員の意見を総合してみましょう。",
    "exReading": "ぜんいん の いけん を そうごう して みましょう",
    "exTranslation": "Let's put all the opinions together.",
    "category": "Verbal Noun"
  },
  {
    "term": "総理",
    "termReading": "そうり",
    "termTranslation": "president, prime minister",
    "ex": "総理はヨーロッパを訪問中です。",
    "exReading": "そうり は よーろっぱ を ほうもんちゅう です",
    "exTranslation": "The prime minister is on a visit to Europe.",
    "category": "Noun"
  },
  {
    "term": "総数",
    "termReading": "そうすう",
    "termTranslation": "total",
    "ex": "参加者の総数は705人でした。",
    "exReading": "さんかしゃ の そうすう は 705 にん でした",
    "exTranslation": "The total number of attendees was 705.",
    "category": "Noun"
  },
  {
    "term": "捜査",
    "termReading": "そうさ",
    "termTranslation": "criminal investigation, search",
    "ex": "その殺人事件の捜査は2年間続きました。",
    "exReading": "その さつじん じけん の そうさ は 2 ねんかん つづきました",
    "exTranslation": "The investigation of the murder case continued for two years.",
    "category": "Verbal Noun"
  },
  {
    "term": "湖",
    "termReading": "みずうみ",
    "termTranslation": "lake",
    "ex": "湖でスケートをした。",
    "exReading": "みずうみ で すけーと を した",
    "exTranslation": "We went skating on the lake.",
    "category": "Noun"
  },
  {
    "term": "深める",
    "termReading": "ふかめる",
    "termTranslation": "deepen, intensify",
    "ex": "彼女は異文化に対する理解を深めたね。",
    "exReading": "かのじょ は いぶんか に たいする りかい を ふかめた ね",
    "exTranslation": "She deepend her understanding of different cultures.",
    "category": "Verb"
  },
  {
    "term": "ぶつぶつ",
    "termReading": "ぶつぶつ",
    "termTranslation": "grumble",
    "ex": "彼はぶつぶつと独り言を言ったんだ。",
    "exReading": "かれ は ぶつぶつ と ひとりごと を いった ん だ",
    "exTranslation": "He grumbled to himself.",
    "category": "Adverb"
  },
  {
    "term": "深まる",
    "termReading": "ふかまる",
    "termTranslation": "deepen, become deeper",
    "ex": "二人の愛はますます深まっているね。",
    "exReading": "ふたり の あい は ますます ふかまっている ね",
    "exTranslation": "Their love has become deeper and deeper.",
    "category": "Verb"
  },
  {
    "term": "注意深い",
    "termReading": "ちゅういぶかい",
    "termTranslation": "careful, discreet",
    "ex": "彼は注意深い人です。",
    "exReading": "かれ は ちゅういぶかい ひと です",
    "exTranslation": "He's a cautious person.",
    "category": "Adjective"
  },
  {
    "term": "段落",
    "termReading": "だんらく",
    "termTranslation": "end of paragraph",
    "ex": "次の段落を読んでください。",
    "exReading": "つぎ の だんらく を よんで ください",
    "exTranslation": "Please read the following paragraph.",
    "category": "Noun"
  },
  {
    "term": "見落とす",
    "termReading": "みおとす",
    "termTranslation": "miss, fail to notice",
    "ex": "間違いを見落としたの。",
    "exReading": "まちがい を みおとした の",
    "exTranslation": "I failed to notice a mistake.",
    "category": "Verb"
  },
  {
    "term": "落第",
    "termReading": "らくだい",
    "termTranslation": "failing an exam",
    "ex": "このテストに失敗したら落第です。",
    "exReading": "この てすと に しっぱい したら らくだい です",
    "exTranslation": "You're going to flunk if you fail this test.",
    "category": "Verbal Noun"
  },
  {
    "term": "ぶるぶる",
    "termReading": "ぶるぶる",
    "termTranslation": "shiver",
    "ex": "彼はぶるぶる震えていたよ。",
    "exReading": "かれ は ぶるぶる ふるえて いた よ",
    "exTranslation": "He was shivering.",
    "category": "Adverb"
  },
  {
    "term": "波",
    "termReading": "なみ",
    "termTranslation": "wave, fluctuations",
    "ex": "今日の海は波が穏やかです。",
    "exReading": "きょう の うみ は なみ が おだやか です",
    "exTranslation": "Waves are calm on the sea today.",
    "category": "Noun"
  },
  {
    "term": "流れ",
    "termReading": "ながれ",
    "termTranslation": "flow, stream",
    "ex": "川の上流は流れが速いよ。",
    "exReading": "かわ の じょうりゅう は ながれ が はやい よ",
    "exTranslation": "The river flows fast upstream.",
    "category": "Noun"
  },
  {
    "term": "流通",
    "termReading": "りゅうつう",
    "termTranslation": "circulation, distribution",
    "ex": "今日は流通の仕組みを勉強しましょう。",
    "exReading": "きょう は りゅうつう の しくみ を べんきょう しましょう",
    "exTranslation": "Let's study the distribution mechanism today.",
    "category": "Verbal Noun"
  },
  {
    "term": "流す",
    "termReading": "ながす",
    "termTranslation": "let flow, set adrift",
    "ex": "彼女は涙を流したんだ。",
    "exReading": "かのじょ は なみだ を ながした ん だ",
    "exTranslation": "She shed tears.",
    "category": "Verb"
  },
  {
    "term": "中流",
    "termReading": "ちゅうりゅう",
    "termTranslation": "mid-stream, middle reaches",
    "ex": "川の中流あたりにその村はあるよ。",
    "exReading": "かわ の ちゅうりゅう あたり に その むら は ある よ",
    "exTranslation": "That village is near the middle reaches of the river.",
    "category": "Noun"
  },
  {
    "term": "ボリューム",
    "termReading": "ボリューム",
    "termTranslation": "volume",
    "ex": "ラジオのボリュームを上げてください。",
    "exReading": "らじお の ぼりゅーむ を あげて ください",
    "exTranslation": "Please turn up the volume on the radio.",
    "category": "Noun"
  },
  {
    "term": "二流",
    "termReading": "にりゅう",
    "termTranslation": "second-rate",
    "ex": "彼はまだまだ二流の芸人だね。",
    "exReading": "かれ は まだまだ にりゅう の げいにん だ ね",
    "exTranslation": "He's still a second-class artist.",
    "category": "Noun"
  },
  {
    "term": "流行",
    "termReading": "はやり",
    "termTranslation": "fashion, trend",
    "ex": "この服は今の流行です。",
    "exReading": "この ふく は いま の はやり です",
    "exTranslation": "These clothes are the latest fashion.",
    "category": "Noun"
  },
  {
    "term": "流行",
    "termReading": "りゅうこう",
    "termTranslation": "fashion, trend",
    "ex": "このスタイルは今年の流行です。",
    "exReading": "この すたいる は ことし の りゅうこう です",
    "exTranslation": "This style is in fashion this year.",
    "category": "Verbal Noun"
  },
  {
    "term": "洗面",
    "termReading": "せんめん",
    "termTranslation": "washing one's face",
    "ex": "洗面用具を忘れた。",
    "exReading": "せんめん ようぐ を わすれた",
    "exTranslation": "I forgot my toiletries.",
    "category": "Noun"
  },
  {
    "term": "手洗い",
    "termReading": "てあらい",
    "termTranslation": "toilet, washstand",
    "ex": "風邪をひかないように手洗いとうがいをしましょう。",
    "exReading": "かぜ を ひかない よう に てあらい と うがい を しましょう",
    "exTranslation": "Make sure you wash your hands and gargle to avoid catching a cold.",
    "category": "Noun"
  },
  {
    "term": "洗面器",
    "termReading": "せんめんき",
    "termTranslation": "washbowl, sink",
    "ex": "洗面器でハンカチを洗ったの。",
    "exReading": "せんめんき で はんかち を あらった の",
    "exTranslation": "I washed my handkerchief in a washbowl.",
    "category": "Noun"
  },
  {
    "term": "ちり",
    "termReading": "ちり",
    "termTranslation": "dust",
    "ex": "ちりも積もれば山となる。",
    "exReading": "ちり も つもれば やま と なる 。",
    "exTranslation": "A penny saved is a penny earned.",
    "category": "Noun"
  },
  {
    "term": "沈没",
    "termReading": "ちんぼつ",
    "termTranslation": "sinking",
    "ex": "船は沈没しました。",
    "exReading": "ふね は ちんぼつ しました",
    "exTranslation": "The ship sank.",
    "category": "Verbal Noun"
  },
  {
    "term": "冷え込む",
    "termReading": "ひえこむ",
    "termTranslation": "get colder",
    "ex": "明日から急に冷え込むそうです。",
    "exReading": "あす から きゅう に ひえこむ そう です",
    "exTranslation": "I hear it will suddenly get cold from tomorrow.",
    "category": "Verb"
  },
  {
    "term": "冷やかす",
    "termReading": "ひやかす",
    "termTranslation": "tease, jeer",
    "ex": "友達はそのカップルを冷やかしたんだ。",
    "exReading": "ともだち は その かっぷる を ひやかした ん だ",
    "exTranslation": "My friend teased the couple.",
    "category": "Verb"
  },
  {
    "term": "冷凍",
    "termReading": "れいとう",
    "termTranslation": "freezing, cold storage",
    "ex": "残ったカレーを冷凍しました。",
    "exReading": "のこった かれー を れいとう しました",
    "exTranslation": "I froze the left-over curry.",
    "category": "Verbal Noun"
  },
  {
    "term": "内臓",
    "termReading": "ないぞう",
    "termTranslation": "internal organs",
    "ex": "来週、内臓を検査します。",
    "exReading": "らいしゅう ないぞう を けんさ します",
    "exTranslation": "Next week I'll have an internal medical examination.",
    "category": "Noun"
  },
  {
    "term": "はげる",
    "termReading": "はげる",
    "termTranslation": "come off, peel off",
    "ex": "壁のペンキがはげてきたな。",
    "exReading": "かべ の ぺんき が はげて きた な",
    "exTranslation": "The paint on the wall is peeling off.",
    "category": "Verb"
  },
  {
    "term": "適用",
    "termReading": "てきよう",
    "termTranslation": "application",
    "ex": "この場合、保険が適用されますか。",
    "exReading": "この ばあい ほけん が てきよう されます か",
    "exTranslation": "In this case, does insurance apply?",
    "category": "Verbal Noun"
  },
  {
    "term": "適切",
    "termReading": "てきせつ",
    "termTranslation": "appropriate, adequate",
    "ex": "彼は適切な言葉で説明してくれました。",
    "exReading": "かれ は てきせつ な ことば で せつめい して くれました",
    "exTranslation": "He explained it to me in appropriate terms.",
    "category": "Adjective"
  },
  {
    "term": "適応",
    "termReading": "てきおう",
    "termTranslation": "adaptation, adjustment",
    "ex": "彼はどんな環境にも適応できます。",
    "exReading": "かれ は どんな かんきょう に も てきおう できます",
    "exTranslation": "He can adjust to any environment.",
    "category": "Verbal Noun"
  },
  {
    "term": "適する",
    "termReading": "てきする",
    "termTranslation": "suit, be suitable",
    "ex": "彼はこの仕事に適しています。",
    "exReading": "かれ は この しごと に てきしています",
    "exTranslation": "He's suitable for this work.",
    "category": "Verb"
  },
  {
    "term": "適当",
    "termReading": "てきとう",
    "termTranslation": "suitable, appropriate",
    "ex": "その質問の適当な答えが見つかりません。",
    "exReading": "その しつもん の てきとう な こたえ が みつかりません",
    "exTranslation": "I could not find an appropriate answer to the question.",
    "category": "Adjectival Noun"
  },
  {
    "term": "パパ",
    "termReading": "パパ",
    "termTranslation": "dad, father",
    "ex": "私のパパは36歳です。",
    "exReading": "わたし の ぱぱ は 36 さい です",
    "exTranslation": "My dad is 36 years old.",
    "category": "Noun"
  },
  {
    "term": "適度",
    "termReading": "てきど",
    "termTranslation": "moderate, proper",
    "ex": "健康のため、適度な運動が必要です。",
    "exReading": "けんこう の ため てきど な うんどう が ひつよう です",
    "exTranslation": "Moderate exercise is necessary for your health.",
    "category": "Adjectival Noun"
  },
  {
    "term": "染める",
    "termReading": "そめる",
    "termTranslation": "dye, color",
    "ex": "髪を赤に染めてみた。",
    "exReading": "かみ を あか に そめて みた",
    "exTranslation": "I tried dyeing my hair red.",
    "category": "Verb"
  },
  {
    "term": "廃止",
    "termReading": "はいし",
    "termTranslation": "abolishment, abandonment",
    "ex": "その制度は廃止されました。",
    "exReading": "その せいど は はいし されました",
    "exTranslation": "The system was abolished.",
    "category": "Verbal Noun"
  },
  {
    "term": "風景",
    "termReading": "ふうけい",
    "termTranslation": "scenery, landscape",
    "ex": "私は山の風景が好きです。",
    "exReading": "わたし は やま の ふうけい が すき です",
    "exTranslation": "I like mountain scenery.",
    "category": "Noun"
  },
  {
    "term": "不景気",
    "termReading": "ふけいき",
    "termTranslation": "business depression, slump",
    "ex": "今、あの国は不景気らしいよ。",
    "exReading": "いま あの くに は ふけいき らしい よ",
    "exTranslation": "Apparently, that country is in recession now.",
    "category": "Adjectival Noun"
  },
  {
    "term": "響く",
    "termReading": "ひびく",
    "termTranslation": "reverberate, vibrate",
    "ex": "彼の声はよく響きます。",
    "exReading": "かれ の こえ は よく ひびきます",
    "exTranslation": "His voice carries well.",
    "category": "Verb"
  },
  {
    "term": "つかむ",
    "termReading": "つかむ",
    "termTranslation": "grip, grab",
    "ex": "彼女が僕の手をつかみました。",
    "exReading": "かのじょ が ぼく の て を つかみました",
    "exTranslation": "She gripped my hand.",
    "category": "Verb"
  },
  {
    "term": "日光",
    "termReading": "にっこう",
    "termTranslation": "sunshine, sunlight",
    "ex": "この部屋は日光がよく当たるね。",
    "exReading": "この へや は にっこう が よく あたる ね",
    "exTranslation": "This room gets quite a lot of sunshine.",
    "category": "Noun"
  },
  {
    "term": "光",
    "termReading": "ひかり",
    "termTranslation": "light, flash",
    "ex": "一筋の光が窓から差し込んだの。",
    "exReading": "ひとすじ の ひかり が まど から さしこんだ の",
    "exTranslation": "A beam of light shone through the window.",
    "category": "Noun"
  },
  {
    "term": "測定",
    "termReading": "そくてい",
    "termTranslation": "measure, gauge",
    "ex": "これから身長と体重を測定します。",
    "exReading": "これから しんちょう と たいじゅう を そくてい します",
    "exTranslation": "Now your height and weight will be measured.",
    "category": "Verbal Noun"
  },
  {
    "term": "測る",
    "termReading": "はかる",
    "termTranslation": "measure, gauge",
    "ex": "このドアの高さを測ってください。",
    "exReading": "この どあ の たかさ を はかって ください",
    "exTranslation": "Please measure the height of this door.",
    "category": "Verb"
  },
  {
    "term": "陽気",
    "termReading": "ようき",
    "termTranslation": "cheerful, bright",
    "ex": "彼女はとても陽気です。",
    "exReading": "かのじょ は とても ようき です",
    "exTranslation": "She's very cheerful.",
    "category": "Adjectival Noun"
  },
  {
    "term": "もったいない",
    "termReading": "もったいない",
    "termTranslation": "wasteful, a shame",
    "ex": "食べ物を残してはもったいないわよ。",
    "exReading": "たべもの を のこして は もったいない わ よ",
    "exTranslation": "It's wasteful to leave food.",
    "category": "Adjective"
  },
  {
    "term": "プロ野球",
    "termReading": "プロやきゅう",
    "termTranslation": "professional baseball",
    "ex": "最近のプロ野球は面白くなってきたね。",
    "exReading": "さいきん の ぷろやきゅう は おもしろく なって きた ね",
    "exTranslation": "Recently professional baseball is getting interesting.",
    "category": "Noun"
  },
  {
    "term": "電球",
    "termReading": "でんきゅう",
    "termTranslation": "light bulb",
    "ex": "電球が切れたので交換しましょう。",
    "exReading": "でんきゅう が きれた の で こうかん しましょう",
    "exTranslation": "The bulb has blown so shall I change it?",
    "category": "Noun"
  },
  {
    "term": "振り返る",
    "termReading": "ふりかえる",
    "termTranslation": "turn one's head, turn round",
    "ex": "学生時代を懐かしく振り返ったんだ。",
    "exReading": "がくせい じだい を なつかしく ふりかえった ん だ",
    "exTranslation": "I looked back fondly on my school days.",
    "category": "Verb"
  },
  {
    "term": "振り向く",
    "termReading": "ふりむく",
    "termTranslation": "turn around, look back",
    "ex": "彼女は振り向いて俺に微笑んだんだ。",
    "exReading": "かのじょ は ふりむいて おれ に ほほえんだ ん だ",
    "exTranslation": "She turned around and smiled at me.",
    "category": "Verb"
  },
  {
    "term": "振り",
    "termReading": "ふり",
    "termTranslation": "swing, shake",
    "ex": "彼はバットの振りが大きすぎる。",
    "exReading": "かれ は ばっと の ふり が おおき すぎる",
    "exTranslation": "He swings the bat too wide.",
    "category": "Noun"
  },
  {
    "term": "ふざける",
    "termReading": "ふざける",
    "termTranslation": "mess around, fool around",
    "ex": "ふざけるのは止めて。",
    "exReading": "ふざける の は やめて",
    "exTranslation": "Stop messing around.",
    "category": "Verb"
  },
  {
    "term": "秘書",
    "termReading": "ひしょ",
    "termTranslation": "secretary",
    "ex": "私の秘書はとても優秀です。",
    "exReading": "わたし の ひしょ は とても ゆうしゅう です",
    "exTranslation": "My secretary is quite exceptional.",
    "category": "Noun"
  },
  {
    "term": "密か",
    "termReading": "ひそか",
    "termTranslation": "secret, private",
    "ex": "彼女の誕生日パーティーを密かに計画しています。",
    "exReading": "かのじょ の たんじょうび ぱーてぃー を ひそか に けいかく して います",
    "exTranslation": "We're secretly planning a birthday party for her.",
    "category": "Adjective"
  },
  {
    "term": "貴い",
    "termReading": "とうとい",
    "termTranslation": "precious, valuable",
    "ex": "その事故で貴い命が失われました。",
    "exReading": "その じこ で とうとい いのち が うしなわれました",
    "exTranslation": "A precious life was lost in the accident.",
    "category": "Adjective"
  },
  {
    "term": "追跡",
    "termReading": "ついせき",
    "termTranslation": "pursuit, chase",
    "ex": "パトカーが車を追跡しているわ。",
    "exReading": "ぱとかー が くるま を ついせき して いる わ",
    "exTranslation": "The police vehicle is chasing a car.",
    "category": "Verbal Noun"
  },
  {
    "term": "寄せる",
    "termReading": "よせる",
    "termTranslation": "draw near, draw up, gather",
    "ex": "車を塀に寄せたよ。",
    "exReading": "くるま を へい に よせた よ",
    "exTranslation": "I pulled the car over by the wall.",
    "category": "Verb"
  },
  {
    "term": "だるい",
    "termReading": "だるい",
    "termTranslation": "be dull, feel dull, weary",
    "ex": "昨日は風邪で少しだるかったんだ。",
    "exReading": "きのう は かぜ で すこし だるかった ん だ",
    "exTranslation": "Yesterday I felt run-down because I had a cold.",
    "category": "Adjective"
  },
  {
    "term": "寄る",
    "termReading": "よる",
    "termTranslation": "stop by, stop off",
    "ex": "帰りに叔母の家に寄ります。",
    "exReading": "かえり に おば の いえ に よります",
    "exTranslation": "I dropped in at my aunt's house on the way home.",
    "category": "Verb"
  },
  {
    "term": "近寄る",
    "termReading": "ちかよる",
    "termTranslation": "go near, approach",
    "ex": "彼に近寄らないで。",
    "exReading": "かれ に ちかよらない で",
    "exTranslation": "Don't go near him!",
    "category": "Verb"
  },
  {
    "term": "寄り道",
    "termReading": "よりみち",
    "termTranslation": "dropping by, going out of the way",
    "ex": "今日は寄り道してから帰ります。",
    "exReading": "きょう は よりみち して から かえります",
    "exTranslation": "I'll take a side trip on the way home today.",
    "category": "Verbal Noun"
  },
  {
    "term": "寄り集まる",
    "termReading": "よりあつまる",
    "termTranslation": "gather, assemble",
    "ex": "ニューヨークには芸術家が寄り集まっているの。",
    "exReading": "にゅーよーく に は げいじゅつか が よりあつまって いる の",
    "exTranslation": "New York is a place where artists meet and come together.",
    "category": "Verb"
  },
  {
    "term": "歴史的",
    "termReading": "れきしてき",
    "termTranslation": "historical",
    "ex": "今日は歴史的な日です。",
    "exReading": "きょう は れきしてき な ひ です",
    "exTranslation": "It's a historical day today.",
    "category": "Adjective"
  },
  {
    "term": "宝石",
    "termReading": "ほうせき",
    "termTranslation": "gem, jewel",
    "ex": "私が一番好きな宝石はダイヤモンドなの。",
    "exReading": "わたし が いちばん すき な ほうせき は だいやもんど なの",
    "exTranslation": "My favorite jewel is diamonds.",
    "category": "Noun"
  },
  {
    "term": "ちぎる",
    "termReading": "ちぎる",
    "termTranslation": "tear, tear off",
    "ex": "紙を細かくちぎってください。",
    "exReading": "かみ を こまかく ちぎって ください",
    "exTranslation": "Please shred the paper.",
    "category": "Verb"
  },
  {
    "term": "建て前",
    "termReading": "たてまえ",
    "termTranslation": "a front, polite face",
    "ex": "本音と建て前は違うことが多いよ。",
    "exReading": "ほんね と たてまえ は ちがう こと が おおい よ",
    "exTranslation": "What people really think and what they say are often different.",
    "category": "Noun"
  },
  {
    "term": "単位",
    "termReading": "たんい",
    "termTranslation": "unit, credit",
    "ex": "メートルは長さの単位です。",
    "exReading": "めーとる は ながさ の たんい です",
    "exTranslation": "The meter is a unit of length.",
    "category": "Noun"
  },
  {
    "term": "地位",
    "termReading": "ちい",
    "termTranslation": "position, status",
    "ex": "彼女は会社で高い地位に就いているよ。",
    "exReading": "かのじょ は かいしゃ で たかい ちい に ついて いる よ",
    "exTranslation": "She holds a high position in the company.",
    "category": "Noun"
  },
  {
    "term": "分離",
    "termReading": "ぶんり",
    "termTranslation": "separation, split",
    "ex": "自民党から新しい党が分離したね。",
    "exReading": "じみんとう から あたらしい とう が ぶんり した ね",
    "exTranslation": "A new party separated from the Liberal-Democratic Party.",
    "category": "Verbal Noun"
  },
  {
    "term": "離れる",
    "termReading": "はなれる",
    "termTranslation": "separate, leave",
    "ex": "実家を4年間離れていました。",
    "exReading": "じっか を 4 ねんかん はなれて いました",
    "exTranslation": "I was away from my parents' house for 4 years.",
    "category": "Verb"
  },
  {
    "term": "ねぎ",
    "termReading": "ねぎ",
    "termTranslation": "Welsh onion, green onion",
    "ex": "みそ汁にねぎを入れました。",
    "exReading": "みそしる に ねぎ を いれました",
    "exTranslation": "I put green onions in the miso soup.",
    "category": "Noun"
  },
  {
    "term": "離す",
    "termReading": "はなす",
    "termTranslation": "separate, detach",
    "ex": "子供の手を離さないでください。",
    "exReading": "こども の て を はなさないで ください",
    "exTranslation": "Don't let go of your child's hand.",
    "category": "Verb"
  },
  {
    "term": "停電",
    "termReading": "ていでん",
    "termTranslation": "power cut, power failure",
    "ex": "台風で停電したよ。",
    "exReading": "たいふう で ていでん した よ",
    "exTranslation": "There was a blackout because of the typhoon.",
    "category": "Verbal Noun"
  },
  {
    "term": "停止",
    "termReading": "ていし",
    "termTranslation": "stop, suspension",
    "ex": "突然、機械が停止してしまったの。",
    "exReading": "とつぜん きかい が ていし して しまった の",
    "exTranslation": "The machine stopped suddenly.",
    "category": "Verbal Noun"
  },
  {
    "term": "範囲",
    "termReading": "はんい",
    "termTranslation": "range, scope",
    "ex": "知っている範囲で教えてください。",
    "exReading": "しって いる はんい で おしえて ください",
    "exTranslation": "Please tell us as much as you know.",
    "category": "Noun"
  },
  {
    "term": "隣り合う",
    "termReading": "となりあう",
    "termTranslation": "adjoin, be next door to each other",
    "ex": "この町は山と海が隣り合っています。",
    "exReading": "この まち は やま と うみ が となりあって います",
    "exTranslation": "This town lies between the sea and the mountain.",
    "category": "Verb"
  },
  {
    "term": "びしょびしょ",
    "termReading": "びしょびしょ",
    "termTranslation": "soaked",
    "ex": "服が雨でびしょびしょになったよ。",
    "exReading": "ふく が あめ で びしょびしょ に なった よ",
    "exTranslation": "My clothes got soaked in the rain.",
    "category": "Adjectival Noun"
  },
  {
    "term": "横切る",
    "termReading": "よこぎる",
    "termTranslation": "cross, go across",
    "ex": "目の前を猫が横切ったんだ。",
    "exReading": "め の まえ を ねこ が よこぎった ん だ",
    "exTranslation": "The cat crossed in front of me.",
    "category": "Verb"
  },
  {
    "term": "横顔",
    "termReading": "よこがお",
    "termTranslation": "profile, face seen from side",
    "ex": "彼女の横顔は素敵だ。",
    "exReading": "かのじょ の よこがお は すてき だ",
    "exTranslation": "Her profile is lovely.",
    "category": "Noun"
  },
  {
    "term": "中断",
    "termReading": "ちゅうだん",
    "termTranslation": "interruption, discontinuance",
    "ex": "停電のため仕事を中断しました。",
    "exReading": "ていでん の ため しごと を ちゅうだん しました",
    "exTranslation": "We broke off our work due to the power failure.",
    "category": "Verbal Noun"
  },
  {
    "term": "断水",
    "termReading": "だんすい",
    "termTranslation": "water stoppage",
    "ex": "地震のために1週間、断水したの。",
    "exReading": "じしん の ため に 1 しゅうかん だんすい した の",
    "exTranslation": "The water supply was cut off for a week due to the earthquake.",
    "category": "Verbal Noun"
  },
  {
    "term": "油断",
    "termReading": "ゆだん",
    "termTranslation": "inattention, carelessness",
    "ex": "少しの油断が大きな事故につながります。",
    "exReading": "すこし の ゆだん が おおき な じこ に つながります",
    "exTranslation": "A little carelessness can cause a big accident.",
    "category": "Verbal Noun"
  },
  {
    "term": "断定",
    "termReading": "だんてい",
    "termTranslation": "decision, conclusion",
    "ex": "まだ原因は断定できません。",
    "exReading": "まだ げんいん は だんてい できません",
    "exTranslation": "The cause has yet to be determined.",
    "category": "Verbal Noun"
  },
  {
    "term": "バーゲン",
    "termReading": "バーゲン",
    "termTranslation": "bargain sale",
    "ex": "昨日バーゲンでスーツを買いました。",
    "exReading": "きのう ばーげん で すーつ を かいました",
    "exTranslation": "I bought a suit in the sale yesterday.",
    "category": "Noun"
  },
  {
    "term": "継ぐ",
    "termReading": "つぐ",
    "termTranslation": "succeed, inherit",
    "ex": "彼は父親の店を継いだの。",
    "exReading": "かれ は ちちおや の みせ を ついだ の",
    "exTranslation": "He took over his father's shop.",
    "category": "Verb"
  },
  {
    "term": "中継",
    "termReading": "ちゅうけい",
    "termTranslation": "relay, broadcasting",
    "ex": "京都から中継で放送しています。",
    "exReading": "きょうと から ちゅうけい で ほうそう して います",
    "exTranslation": "We're broadcasting from Kyoto.",
    "category": "Verbal Noun"
  },
  {
    "term": "幅広い",
    "termReading": "はばひろい",
    "termTranslation": "wide, broad",
    "ex": "彼は幅広い知識を持っています。",
    "exReading": "かれ は はばひろい ちしき を もって います",
    "exTranslation": "His knowledge is profound.",
    "category": "Adjective"
  },
  {
    "term": "幅",
    "termReading": "はば",
    "termTranslation": "width, breadth",
    "ex": "この道は幅が狭いので気をつけて運転してください。",
    "exReading": "この みち は はば が せまい の で き を つけて うんてん して ください",
    "exTranslation": "This road is narrow so please drive carefully.",
    "category": "Noun"
  },
  {
    "term": "大陸",
    "termReading": "たいりく",
    "termTranslation": "continent",
    "ex": "ユーラシアは世界で最も大きい大陸です。",
    "exReading": "ゆーらしあ は せかい で もっとも おおきい たいりく です",
    "exTranslation": "Eurasia is the largest continent in the world.",
    "category": "Noun"
  },
  {
    "term": "メーター",
    "termReading": "メーター",
    "termTranslation": "meter, taximeter",
    "ex": "ガス会社がメーターを調べにきたぞ。",
    "exReading": "がす がいしゃ が めーたー を しらべ に きた ぞ",
    "exTranslation": "The gas company came to examine the meter.",
    "category": "Noun"
  },
  {
    "term": "着陸",
    "termReading": "ちゃくりく",
    "termTranslation": "landing",
    "ex": "飛行機が無事着陸したわね。",
    "exReading": "ひこうき が ぶじ ちゃくりく した わ ね",
    "exTranslation": "The airplane landed safely.",
    "category": "Verbal Noun"
  },
  {
    "term": "離陸",
    "termReading": "りりく",
    "termTranslation": "takeoff",
    "ex": "まもなく飛行機が離陸します。",
    "exReading": "まもなく ひこうき が りりく します",
    "exTranslation": "The airplane will take off soon.",
    "category": "Verbal Noun"
  },
  {
    "term": "内陸",
    "termReading": "ないりく",
    "termTranslation": "inland, interior",
    "ex": "彼は中国の内陸を旅行しました。",
    "exReading": "かれ は ちゅうごく の ないりく を りょこう しました",
    "exTranslation": "He traveled through inland China.",
    "category": "Noun"
  },
  {
    "term": "陸上",
    "termReading": "りくじょう",
    "termTranslation": "land, shore",
    "ex": "あの動物は陸上で生活しています。",
    "exReading": "あの どうぶつ は りくじょう で せいかつ して います",
    "exTranslation": "That animal lives on land.",
    "category": "Noun"
  },
  {
    "term": "陸",
    "termReading": "りく",
    "termTranslation": "land, shore",
    "ex": "ウミガメが陸に上がってきたんだ。",
    "exReading": "うみがめ が りく に あがって きた ん だ",
    "exTranslation": "The sea turtles have come up to dry land.",
    "category": "Noun"
  },
  {
    "term": "もしかすると",
    "termReading": "もしかすると",
    "termTranslation": "possibly, perhaps",
    "ex": "もしかするとあなたに一度お会いしてますか。",
    "exReading": "もしかすると あなた に いちど おあい してます か",
    "exTranslation": "Is it possible I've met you once before?",
    "category": "Adverb"
  },
  {
    "term": "南極",
    "termReading": "なんきょく",
    "termTranslation": "South Pole",
    "ex": "南極でペンギンを見ました。",
    "exReading": "なんきょく で ぺんぎん を みました",
    "exTranslation": "I saw some penguins at the South Pole.",
    "category": "Noun"
  },
  {
    "term": "北極",
    "termReading": "ほっきょく",
    "termTranslation": "North Pole",
    "ex": "北極にペンギンはいません。",
    "exReading": "ほっきょく に ぺんぎん は いません",
    "exTranslation": "There are no penguins at the North Pole.",
    "category": "Noun"
  },
  {
    "term": "先端",
    "termReading": "せんたん",
    "termTranslation": "edge, vanguard",
    "ex": "棒の先端を持って下さい。",
    "exReading": "ぼう の せんたん を もって ください",
    "exTranslation": "Please hold the end of the stick.",
    "category": "Noun"
  },
  {
    "term": "端",
    "termReading": "はし",
    "termTranslation": "end, edge",
    "ex": "辞書は本棚の端にあります。",
    "exReading": "じしょ は ほんだな の はし に あります",
    "exTranslation": "The dictionary is at the end of the bookshelf.",
    "category": "Noun"
  },
  {
    "term": "訪問",
    "termReading": "ほうもん",
    "termTranslation": "visit",
    "ex": "来週、妻の実家を訪問します。",
    "exReading": "らいしゅう つま の じっか を ほうもん します",
    "exTranslation": "Next week we'll visit my wife's family home.",
    "category": "Verbal Noun"
  },
  {
    "term": "女房",
    "termReading": "にょうぼう",
    "termTranslation": "wife",
    "ex": "女房は実家に帰っています。",
    "exReading": "にょうぼう は じっか に かえって います",
    "exTranslation": "My wife is back at her parents' home.",
    "category": "Noun"
  },
  {
    "term": "ちぎれる",
    "termReading": "ちぎれる",
    "termTranslation": "be torn off, come off",
    "ex": "荷物が重くて手がちぎれそうだ。",
    "exReading": "にもつ が おもくて て が ちぎれ そう だ",
    "exTranslation": "My luggage is so heavy, it feels as if my arm could tear off.",
    "category": "Verb"
  },
  {
    "term": "内緒",
    "termReading": "ないしょ",
    "termTranslation": "secrecy, privacy",
    "ex": "この話は課長には内緒ですよ。",
    "exReading": "この はなし は かちょう に は ないしょ です よ",
    "exTranslation": "You have to keep this secret from the section chief.",
    "category": "Noun"
  },
  {
    "term": "百貨店",
    "termReading": "ひゃっかてん",
    "termTranslation": "department store",
    "ex": "友達と百貨店で買い物をしました。",
    "exReading": "ともだち と ひゃっかてん で かいもの を しました",
    "exTranslation": "I went shopping at the department store with my friend.",
    "category": "Noun"
  },
  {
    "term": "輸送",
    "termReading": "ゆそう",
    "termTranslation": "transport, ship",
    "ex": "この商品はトラックで輸送します。",
    "exReading": "この しょうひん は とらっく で ゆそう します",
    "exTranslation": "These goods are transported by truck.",
    "category": "Verbal Noun"
  },
  {
    "term": "復活",
    "termReading": "ふっかつ",
    "termTranslation": "revival, rebirth",
    "ex": "その選手は怪我を乗り越えて復活したわね。",
    "exReading": "その せんしゅ は けが を のりこえて ふっかつ した わ ね",
    "exTranslation": "The player recovered from the injury.",
    "category": "Verbal Noun"
  },
  {
    "term": "操作",
    "termReading": "そうさ",
    "termTranslation": "operation, manipulation",
    "ex": "この携帯電話は操作が簡単です。",
    "exReading": "この けいたい でんわ は そうさ が かんたん です",
    "exTranslation": "This cellular phone is easy to operate.",
    "category": "Verbal Noun"
  },
  {
    "term": "にっこり",
    "termReading": "にっこり",
    "termTranslation": "smiling, grinning",
    "ex": "彼女はにっこりほほえんだ。",
    "exReading": "かのじょ は にっこり ほほえんだ",
    "exTranslation": "She smiled broadly.",
    "category": "Adverb"
  },
  {
    "term": "体操",
    "termReading": "たいそう",
    "termTranslation": "gymnastics, physical exercise",
    "ex": "兄は体操の選手です。",
    "exReading": "あに は たいそう の せんしゅ です",
    "exTranslation": "My big brother's a gymnast.",
    "category": "Noun"
  },
  {
    "term": "縦",
    "termReading": "たて",
    "termTranslation": "lengthways, vertical",
    "ex": "縦2列に並んでください。",
    "exReading": "たて 2 れつ に ならんで ください",
    "exTranslation": "Please line up in two rows lengthways.",
    "category": "Noun"
  },
  {
    "term": "操縦",
    "termReading": "そうじゅう",
    "termTranslation": "steering, piloting",
    "ex": "このボートは操縦が簡単です。",
    "exReading": "この ぼーと は そうじゅう が かんたん です",
    "exTranslation": "This boat is easy to operate.",
    "category": "Verbal Noun"
  },
  {
    "term": "縦書き",
    "termReading": "たてがき",
    "termTranslation": "vertical writing",
    "ex": "作文は縦書きで書いてください。",
    "exReading": "さくぶん は たてがき で かいてください",
    "exTranslation": "Please write the composition in vertical columns.",
    "category": "Noun"
  },
  {
    "term": "滞在",
    "termReading": "たいざい",
    "termTranslation": "stay",
    "ex": "日本では北海道に滞在しました。",
    "exReading": "にっぽん で は ほっかいどう に たいざい しました",
    "exTranslation": "I stayed in Hokkaido when I was in Japan.",
    "category": "Verbal Noun"
  },
  {
    "term": "ぱいなっぷる",
    "termReading": "ぱいなっぷる",
    "termTranslation": "pineapple",
    "ex": "このパイナップルを切ってください。",
    "exReading": "この ぱいなっぷる を きって ください",
    "exTranslation": "Please cut up this pineapple.",
    "category": "Noun"
  },
  {
    "term": "地帯",
    "termReading": "ちたい",
    "termTranslation": "zone, area",
    "ex": "この都市は工業地帯です。",
    "exReading": "この とし は こうぎょう ちたい です",
    "exTranslation": "This city is an industrial zone.",
    "category": "Noun"
  },
  {
    "term": "熱帯",
    "termReading": "ねったい",
    "termTranslation": "tropics",
    "ex": "店に熱帯の果物が並んでいますよ。",
    "exReading": "みせ に ねったい の くだもの が ならんで います よ",
    "exTranslation": "There are some tropical fruits on display in the shop.",
    "category": "Noun"
  },
  {
    "term": "保守",
    "termReading": "ほしゅ",
    "termTranslation": "conservation, maintenance",
    "ex": "あの村は伝統を保守している。",
    "exReading": "あの むら は でんとう を ほしゅ して いる",
    "exTranslation": "That village keeps up traditions.",
    "category": "Verbal Noun"
  },
  {
    "term": "見守る",
    "termReading": "みまもる",
    "termTranslation": "watch, keep watch over",
    "ex": "皆で暖かく見守りましょう。",
    "exReading": "みんな で あたたかく みまもりましょう",
    "exTranslation": "I hope all of us will kindly watch over them.",
    "category": "Verb"
  },
  {
    "term": "留守番",
    "termReading": "るすばん",
    "termTranslation": "caretaking, caretaker",
    "ex": "私は留守番の者です。",
    "exReading": "わたし は るすばん の もの です",
    "exTranslation": "I'm looking after the house.",
    "category": "Noun"
  },
  {
    "term": "ぱんだ",
    "termReading": "ぱんだ",
    "termTranslation": "panda",
    "ex": "パンダは笹を食べます。",
    "exReading": "ぱんだ は ささ を たべます",
    "exTranslation": "Pandas eat bamboo grass.",
    "category": "Noun"
  },
  {
    "term": "戻す",
    "termReading": "もどす",
    "termTranslation": "return, give back",
    "ex": "話を戻しましょう。",
    "exReading": "はなし を もどしましょう",
    "exTranslation": "Let's go back to what we were talking about.",
    "category": "Verb"
  },
  {
    "term": "取り戻す",
    "termReading": "とりもどす",
    "termTranslation": "take back, recover",
    "ex": "緑を取り戻す必要があります。",
    "exReading": "みどり を とりもどす ひつよう が あります",
    "exTranslation": "We need to recover our green spaces.",
    "category": "Verb"
  },
  {
    "term": "払い戻す",
    "termReading": "はらいもどす",
    "termTranslation": "pay back, reimburse",
    "ex": "飛行機の運賃が払い戻されたの。",
    "exReading": "ひこうき の うんちん が はらいもどされた の",
    "exTranslation": "My airfare was reimbursed.",
    "category": "Verb"
  },
  {
    "term": "寝かす",
    "termReading": "ねかす",
    "termTranslation": "put to sleep",
    "ex": "いつも9時に子供を寝かします。",
    "exReading": "いつも 9 じ に こども を ねかします",
    "exTranslation": "I always put our kid to bed at nine o'clock.",
    "category": "Verb"
  },
  {
    "term": "寝過ごす",
    "termReading": "ねすごす",
    "termTranslation": "oversleep",
    "ex": "うっかり寝過ごしてしまったんだ。",
    "exReading": "うっかり ねすごして しまった ん だ",
    "exTranslation": "I accidentally overslept.",
    "category": "Verb"
  },
  {
    "term": "早寝",
    "termReading": "はやね",
    "termTranslation": "going to bed early",
    "ex": "早寝は健康のためによいことです。",
    "exReading": "はやね は けんこう の ため に よい こと です",
    "exTranslation": "Going to bed early is good for your health.",
    "category": "Verbal Noun"
  },
  {
    "term": "びっしょり",
    "termReading": "びっしょり",
    "termTranslation": "soaked",
    "ex": "運動して汗びっしょりです。",
    "exReading": "うんどう して あせ びっしょり です",
    "exTranslation": "I exercised and now I'm all sweaty.",
    "category": "Adverb"
  },
  {
    "term": "寝かせる",
    "termReading": "ねかせる",
    "termTranslation": "put to bed, lay down",
    "ex": "赤ちゃんをベッドに寝かせた。",
    "exReading": "あかちゃん を べっど に ねかせた",
    "exTranslation": "I laid the baby down on the bed.",
    "category": "Verb"
  },
  {
    "term": "寝転ぶ",
    "termReading": "ねころぶ",
    "termTranslation": "lie down",
    "ex": "土手に寝転んで空をながめました。",
    "exReading": "どて に ねころんで そら を ながめました",
    "exTranslation": "I lay down on the bank and looked at the sky.",
    "category": "Verb"
  },
  {
    "term": "冷静",
    "termReading": "れいせい",
    "termTranslation": "cool, calm",
    "ex": "冷静に話し合いましょう。",
    "exReading": "れいせい に はなしあいましょう",
    "exTranslation": "Let's talk calmly.",
    "category": "Adjectival Noun"
  },
  {
    "term": "両者",
    "termReading": "りょうしゃ",
    "termTranslation": "both",
    "ex": "両者の意見を聞きましょう。",
    "exReading": "りょうしゃ の いけん を ききましょう",
    "exTranslation": "Let's hear both parties' opinions.",
    "category": "Noun"
  },
  {
    "term": "両立",
    "termReading": "りょうりつ",
    "termTranslation": "managing two things, having it both ways",
    "ex": "勉強と仕事の両立は難しいよ。",
    "exReading": "べんきょう と しごと の りょうりつ は むずかしい よ",
    "exTranslation": "It's difficult to manage both study and work.",
    "category": "Verbal Noun"
  },
  {
    "term": "ペンチ",
    "termReading": "ペンチ",
    "termTranslation": "pliers",
    "ex": "ペンチで針金を切ります。",
    "exReading": "ぺんち で はりがね を きります",
    "exTranslation": "I cut the wire with pincers.",
    "category": "Noun"
  },
  {
    "term": "両手",
    "termReading": "りょうて",
    "termTranslation": "both hands",
    "ex": "彼は両手を握り締めたの。",
    "exReading": "かれ は りょうて を にぎりしめた の",
    "exTranslation": "He clenched his fists.",
    "category": "Noun"
  },
  {
    "term": "両面",
    "termReading": "りょうめん",
    "termTranslation": "both sides",
    "ex": "紙の両面に字が書かれていたよ。",
    "exReading": "かみ の りょうめん に じ が かかれて いた よ",
    "exTranslation": "There was writing on both sides of the paper.",
    "category": "Noun"
  },
  {
    "term": "側面",
    "termReading": "そくめん",
    "termTranslation": "side, flank",
    "ex": "これが建物の側面の写真です。",
    "exReading": "これ が たてもの の そくめん の しゃしん です",
    "exTranslation": "This is a side-view photograph of the building.",
    "category": "Noun"
  },
  {
    "term": "両側",
    "termReading": "りょうがわ",
    "termTranslation": "both sides",
    "ex": "道の両側にたくさんお店がありますよ。",
    "exReading": "みち の りょうがわ に たくさん おみせ が あります よ",
    "exTranslation": "There are a lot of shops on both sides of the road.",
    "category": "Noun"
  },
  {
    "term": "要項",
    "termReading": "ようこう",
    "termTranslation": "important points",
    "ex": "募集要項をよくお読みください。",
    "exReading": "ぼしゅう ようこう を よく お よみ ください",
    "exTranslation": "Please read the application guidelines carefully.",
    "category": "Noun"
  },
  {
    "term": "モダン",
    "termReading": "モダン",
    "termTranslation": "modern",
    "ex": "あの建物はモダンだね。",
    "exReading": "あの たてもの は もだん だ ね",
    "exTranslation": "That building is modern.",
    "category": "Adjectival Noun"
  },
  {
    "term": "目印",
    "termReading": "めじるし",
    "termTranslation": "landmark, sign",
    "ex": "私の家は赤い屋根が目印です。",
    "exReading": "わたし の いえ は あかい やね が めじるし です",
    "exTranslation": "My house has a red roof that's easy to spot.",
    "category": "Noun"
  },
  {
    "term": "細長い",
    "termReading": "ほそながい",
    "termTranslation": "long and thin, slender",
    "ex": "その細長い棒を取ってください。",
    "exReading": "その ほそながい ぼう を とって ください",
    "exTranslation": "Please take that long, thin stick.",
    "category": "Adjective"
  },
  {
    "term": "載る",
    "termReading": "のる",
    "termTranslation": "appear in print",
    "ex": "先生の論文が雑誌に載りましたよ。",
    "exReading": "せんせい の ろんぶん が ざっし に のりました よ",
    "exTranslation": "Our professor's paper was published in the journal.",
    "category": "Verb"
  },
  {
    "term": "積む",
    "termReading": "つむ",
    "termTranslation": "pile up, accumulate",
    "ex": "車に荷物を積んでください。",
    "exReading": "くるま に にもつ を つんで ください",
    "exTranslation": "Please load the luggage into the car.",
    "category": "Verb"
  },
  {
    "term": "面積",
    "termReading": "めんせき",
    "termTranslation": "area, square measure",
    "ex": "この土地の面積はどれ位ですか。",
    "exReading": "この とち の めんせき は どれ くらい です か",
    "exTranslation": "What is the area of this land?",
    "category": "Noun"
  },
  {
    "term": "翻訳",
    "termReading": "ほんやく",
    "termTranslation": "translation",
    "ex": "彼の翻訳は分かりやすいですよ。",
    "exReading": "かれ の ほんやく は わかりやすい です よ",
    "exTranslation": "His translation is easy to understand.",
    "category": "Verbal Noun"
  },
  {
    "term": "リクエスト",
    "termReading": "リクエスト",
    "termTranslation": "request",
    "ex": "ラジオ番組にリクエストを送ったの。",
    "exReading": "らじお ばんぐみ に りくえすと を おくった の",
    "exTranslation": "I sent a request to the radio show.",
    "category": "Verbal Noun"
  },
  {
    "term": "通訳",
    "termReading": "つうやく",
    "termTranslation": "interpreting",
    "ex": "私は通訳です。",
    "exReading": "わたし は つうやく です",
    "exTranslation": "I'm an interpreter.",
    "category": "Verbal Noun"
  },
  {
    "term": "申し訳ない",
    "termReading": "もうしわけない",
    "termTranslation": "sorry, inexcusable",
    "ex": "彼には申し訳ないことをした。",
    "exReading": "かれ に は もうしわけない こと を した",
    "exTranslation": "I did something inexcusable to him.",
    "category": "Adjective"
  },
  {
    "term": "訳",
    "termReading": "わけ",
    "termTranslation": "reason, cause",
    "ex": "そんな訳で、私は仕事を辞めました。",
    "exReading": "そんな わけ で わたし は しごと を やめました",
    "exTranslation": "I quit my job for that reason.",
    "category": "Noun"
  },
  {
    "term": "申し訳",
    "termReading": "もうしわけ",
    "termTranslation": "excuse, apology",
    "ex": "申し訳ございません。",
    "exReading": "もうしわけ ございません",
    "exTranslation": "I'm very sorry.",
    "category": "Noun"
  },
  {
    "term": "訂正",
    "termReading": "ていせい",
    "termTranslation": "correction, amendment",
    "ex": "間違いを訂正しました。",
    "exReading": "まちがい を ていせい しました",
    "exTranslation": "I corrected the mistakes.",
    "category": "Verbal Noun"
  },
  {
    "term": "ビーチ",
    "termReading": "ビーチ",
    "termTranslation": "beach",
    "ex": "ビーチで友達とサーフィンしたよ。",
    "exReading": "びーち で ともだち と さーふぃん した よ",
    "exTranslation": "I surfed with my friends on the beach.",
    "category": "Noun"
  },
  {
    "term": "討論",
    "termReading": "とうろん",
    "termTranslation": "debate, discussion",
    "ex": "その問題についてクラスで討論しました。",
    "exReading": "その もんだい に ついて くらす で とうろん しました",
    "exTranslation": "The problem was discussed in class.",
    "category": "Verbal Noun"
  },
  {
    "term": "添える",
    "termReading": "そえる",
    "termTranslation": "add to, attach to",
    "ex": "贈り物に手書きのカードを添えました。",
    "exReading": "おくりもの に てがき の かーど を そえました",
    "exTranslation": "I attached a hand-written card to the gift.",
    "category": "Verb"
  },
  {
    "term": "添う",
    "termReading": "そう",
    "termTranslation": "accompany, go along with",
    "ex": "あなたの期待に添えなくてすみません。",
    "exReading": "あなた の きたい に そえなくて すみません",
    "exTranslation": "I'm sorry I couldn't fulfill your expectation.",
    "category": "Verb"
  },
  {
    "term": "付近",
    "termReading": "ふきん",
    "termTranslation": "neighborhood, environs",
    "ex": "事件現場付近で怪しい人物を見た。",
    "exReading": "じけん げんば ふきん で あやしい じんぶつ を みた",
    "exTranslation": "A suspicious person was seen in the vicinity of the crime scene.",
    "category": "Noun"
  },
  {
    "term": "付き合い",
    "termReading": "つきあい",
    "termTranslation": "friendship, association",
    "ex": "今日は付き合いで帰りが遅くなります。",
    "exReading": "きょう は つきあい で かえり が おそく なります",
    "exTranslation": "I'm coming home late today so I can socialize with my colleagues.",
    "category": "Noun"
  },
  {
    "term": "ビタミン",
    "termReading": "ビタミン",
    "termTranslation": "vitamin",
    "ex": "ビタミンを毎日とりましょう。",
    "exReading": "びたみん を まいにち とりましょう",
    "exTranslation": "We should take vitamins every day.",
    "category": "Noun"
  },
  {
    "term": "付け加える",
    "termReading": "つけくわえる",
    "termTranslation": "add",
    "ex": "自分の考えを付け加えました。",
    "exReading": "じぶん の かんがえ を つけくわえました",
    "exTranslation": "I added my own idea.",
    "category": "Verb"
  },
  {
    "term": "付き合う",
    "termReading": "つきあう",
    "termTranslation": "socialize with, see someone",
    "ex": "彼女と4年ほど付き合っています。",
    "exReading": "かのじょ と 4 ねん ほど つきあって います",
    "exTranslation": "I've been seeing her for 4 years now.",
    "category": "Verb"
  },
  {
    "term": "名付ける",
    "termReading": "なづける",
    "termTranslation": "name, give a name to",
    "ex": "子猫にトラと名付けました。",
    "exReading": "こねこ に とら と なづけました",
    "exTranslation": "I named the kitten \"Tora\".",
    "category": "Verb"
  },
  {
    "term": "付録",
    "termReading": "ふろく",
    "termTranslation": "appendix, supplement",
    "ex": "今月の付録はアニメのDVDです。",
    "exReading": "こんげつ の ふろく は あにめ の でぃーぶぃーでぃー です",
    "exTranslation": "The free gift this month is an anime DVD.",
    "category": "Noun"
  },
  {
    "term": "近付ける",
    "termReading": "ちかづける",
    "termTranslation": "bring close",
    "ex": "私は顔を窓に近付けたんだ。",
    "exReading": "わたし は かお を まど に ちかづけた ん だ",
    "exTranslation": "I brought my face close to the window.",
    "category": "Verb"
  },
  {
    "term": "付け足す",
    "termReading": "つけたす",
    "termTranslation": "add on, append",
    "ex": "何か付け足すことはありますか。",
    "exReading": "なにか つけたす こと は あります か",
    "exTranslation": "Is there anything to add?",
    "category": "Verb"
  },
  {
    "term": "フライパン",
    "termReading": "フライパン",
    "termTranslation": "frying pan",
    "ex": "フライパンで目玉焼きを作ったよ。",
    "exReading": "ふらいぱん で めだまやき を つくった よ",
    "exTranslation": "I made eggs sunny-side up in the frying pan.",
    "category": "Noun"
  },
  {
    "term": "日付け",
    "termReading": "ひづけ",
    "termTranslation": "date",
    "ex": "今日の日付けは6月19日です。",
    "exReading": "きょう の ひづけ は 6 がつ 19 にち です",
    "exTranslation": "Today's date is June 19th.",
    "category": "Noun"
  },
  {
    "term": "付属",
    "termReading": "ふぞく",
    "termTranslation": "attachment, belonging",
    "ex": "このソフトには色々なツールが付属しています。",
    "exReading": "この そふと に は いろいろ な つーる が ふぞく して います",
    "exTranslation": "Various tools are included with this software.",
    "category": "Verbal Noun"
  },
  {
    "term": "属する",
    "termReading": "ぞくする",
    "termTranslation": "belong to, be one of",
    "ex": "私は市民オーケストラに属しています。",
    "exReading": "わたし は しみん おーけすとら に ぞくして います",
    "exTranslation": "I belong to the civic orchestra.",
    "category": "Verb"
  },
  {
    "term": "反省",
    "termReading": "はんせい",
    "termTranslation": "reflection, repentance",
    "ex": "彼は十分反省しています。",
    "exReading": "かれ は じゅうぶん はんせい して います",
    "exTranslation": "He has repented enough.",
    "category": "Verbal Noun"
  },
  {
    "term": "省く",
    "termReading": "はぶく",
    "termTranslation": "omit, dispense with",
    "ex": "時間がないので詳細は省きます。",
    "exReading": "じかん が ない の で しょうさい は はぶきます",
    "exTranslation": "I'll omit the details as there is no time.",
    "category": "Verb"
  },
  {
    "term": "まあまあ",
    "termReading": "まあまあ",
    "termTranslation": "now now",
    "ex": "まあまあ、落ち着いてください。",
    "exReading": "まあまあ おちついて ください",
    "exTranslation": "Now, now, please settle down.",
    "category": "Adverb"
  },
  {
    "term": "略す",
    "termReading": "りゃくす",
    "termTranslation": "abbreviate, omit",
    "ex": "国際連合を略して国連といいます。",
    "exReading": "こくさいれんごう を りゃく して こくれん と いいます",
    "exTranslation": "The UN is short for the United Nations.",
    "category": "Verb"
  },
  {
    "term": "略",
    "termReading": "りゃく",
    "termTranslation": "abbreviation",
    "ex": "「IT」は何の略か知っていますか。",
    "exReading": "あいてぃー は なん の りゃく か しって います か",
    "exTranslation": "Do you know what IT stands for?",
    "category": "Noun"
  },
  {
    "term": "大概",
    "termReading": "たいがい",
    "termTranslation": "generally, probably",
    "ex": "大概、風邪は寝ていれば治ります。",
    "exReading": "たいがい かぜ は ねて いれば なおります",
    "exTranslation": "Generally, colds get better after you get some sleep.",
    "category": "Adverb"
  },
  {
    "term": "含める",
    "termReading": "ふくめる",
    "termTranslation": "include",
    "ex": "私を含めて10人が参加しました。",
    "exReading": "わたし を ふくめて 10 にん が さんか しました",
    "exTranslation": "Ten people participated, myself included.",
    "category": "Verb"
  },
  {
    "term": "含む",
    "termReading": "ふくむ",
    "termTranslation": "contain, include",
    "ex": "その食品は有害物質を含んでいるぞ。",
    "exReading": "その しょくひん は ゆうがい ぶっしつ を ふくんで いる ぞ",
    "exTranslation": "The food contains a poisonous substance.",
    "category": "Verb"
  },
  {
    "term": "たこ",
    "termReading": "たこ",
    "termTranslation": "octopus",
    "ex": "タコには足が8本ある。",
    "exReading": "たこ に は あし が 8 ぽん ある",
    "exTranslation": "An octopus has eight legs.",
    "category": "Noun"
  },
  {
    "term": "道順",
    "termReading": "みちじゅん",
    "termTranslation": "route, itinerary",
    "ex": "家から学校までの道順を教えてください。",
    "exReading": "いえ から がっこう まで の みちじゅん を おしえて ください",
    "exTranslation": "Please tell me the route from your house to the school.",
    "category": "Noun"
  },
  {
    "term": "列車",
    "termReading": "れっしゃ",
    "termTranslation": "train",
    "ex": "列車がホームに入ってきた。",
    "exReading": "れっしゃ が ほーむ に はいって きた",
    "exTranslation": "The train has arrived at the platform.",
    "category": "Noun"
  },
  {
    "term": "列島",
    "termReading": "れっとう",
    "termTranslation": "archipelago",
    "ex": "日本は列島です。",
    "exReading": "にっぽん は れっとう です",
    "exTranslation": "Japan is an archipelago.",
    "category": "Noun"
  },
  {
    "term": "列",
    "termReading": "れつ",
    "termTranslation": "row, line",
    "ex": "店の前に長い列ができていたね。",
    "exReading": "みせ の まえ に ながい れつ が できて いた ね",
    "exTranslation": "A long line formed in front of the shop.",
    "category": "Noun"
  },
  {
    "term": "例外",
    "termReading": "れいがい",
    "termTranslation": "exception",
    "ex": "例外は認めません。",
    "exReading": "れいがい は みとめません",
    "exTranslation": "Exceptions are not allowed.",
    "category": "Noun"
  },
  {
    "term": "デザート",
    "termReading": "デザート",
    "termTranslation": "dessert",
    "ex": "デザートにケーキを食べました。",
    "exReading": "でざーと に けーき を たべました",
    "exTranslation": "I had the cake for dessert.",
    "category": "Noun"
  },
  {
    "term": "比例",
    "termReading": "ひれい",
    "termTranslation": "proportion, ratio",
    "ex": "努力と結果が比例していないの。",
    "exReading": "どりょく と けっか が ひれい して いない の",
    "exTranslation": "The effort and its result are not proportional.",
    "category": "Verbal Noun"
  },
  {
    "term": "用例",
    "termReading": "ようれい",
    "termTranslation": "example, illustration",
    "ex": "用例を使って説明してください。",
    "exReading": "ようれい を つかって せつめい して ください",
    "exTranslation": "Please explain by using examples.",
    "category": "Noun"
  },
  {
    "term": "例える",
    "termReading": "たとえる",
    "termTranslation": "compare, liken",
    "ex": "彼は彼女をバラに例えたんだ。",
    "exReading": "かれ は かのじょ を ばら に たとえた ん だ",
    "exTranslation": "He compared her to a rose.",
    "category": "Verb"
  },
  {
    "term": "例題",
    "termReading": "れいだい",
    "termTranslation": "example, exercise",
    "ex": "みんなで例題を解いてみましょう。",
    "exReading": "みんな で れいだい を といて みましょう",
    "exTranslation": "Let's solve the example problem together.",
    "category": "Noun"
  },
  {
    "term": "例年",
    "termReading": "れいねん",
    "termTranslation": "normal year, average year",
    "ex": "例年8月は雨が少ないね。",
    "exReading": "れいねん 8 がつ は あめ が すくない ね",
    "exTranslation": "There's little rain in a typical August.",
    "category": "Noun"
  },
  {
    "term": "例え",
    "termReading": "たとえ",
    "termTranslation": "metaphor, simile",
    "ex": "例えを上げると話が分かり易くなる。",
    "exReading": "たとえ を あげる と はなし が わかり やすく なる",
    "exTranslation": "It will be easier to understand if you give an example.",
    "category": "Noun"
  },
  {
    "term": "とっさに",
    "termReading": "とっさに",
    "termTranslation": "at once",
    "ex": "とっさに彼の名前が出てこなかった。",
    "exReading": "とっさ に かれ の なまえ が でてこなかった 。",
    "exTranslation": "I couldn't remember his name momentarily.",
    "category": "Adverb"
  },
  {
    "term": "余地",
    "termReading": "よち",
    "termTranslation": "room, space",
    "ex": "彼に言い訳の余地はありません。",
    "exReading": "かれ に いいわけ の よち は ありません",
    "exTranslation": "He doesn't have room for excuses.",
    "category": "Noun"
  },
  {
    "term": "余計",
    "termReading": "よけい",
    "termTranslation": "excess, needless",
    "ex": "余計なことを言ってはだめよ。",
    "exReading": "よけい な こと を いって は だめ よ",
    "exTranslation": "Don't say things you don't need to say.",
    "category": "Adjectival Noun"
  },
  {
    "term": "余分",
    "termReading": "よぶん",
    "termTranslation": "excess, extra",
    "ex": "食事は余分に用意してあります。",
    "exReading": "しょくじ は よぶん に ようい して あります",
    "exTranslation": "I've prepared some extra portions for the meal.",
    "category": "Adjectival Noun"
  },
  {
    "term": "途中",
    "termReading": "とちゅう",
    "termTranslation": "on the way, half way",
    "ex": "途中で30分ほど休みましょう。",
    "exReading": "とちゅう で 30 ぷん ほど やすみましょう",
    "exTranslation": "Let's take a rest on the way for about 30 minutes.",
    "category": "Noun"
  },
  {
    "term": "用途",
    "termReading": "ようと",
    "termTranslation": "use, service",
    "ex": "この道具の用途を説明します。",
    "exReading": "この どうぐ の ようと を せつめい します",
    "exTranslation": "I'll explain how to use this tool.",
    "category": "Noun"
  },
  {
    "term": "ぬるぬる",
    "termReading": "ぬるぬる",
    "termTranslation": "slimy",
    "ex": "うなぎはぬるぬるしていますね。",
    "exReading": "うなぎはぬるぬるしていますね。",
    "exTranslation": "Eels are slimy.",
    "category": "Adverb"
  },
  {
    "term": "中途",
    "termReading": "ちゅうと",
    "termTranslation": "halfway",
    "ex": "私は中途採用で入社しました。",
    "exReading": "わたし は ちゅうと さいよう で にゅうしゃ しました",
    "exTranslation": "I entered the company as a mid-career worker.",
    "category": "Noun"
  },
  {
    "term": "取り締まり",
    "termReading": "とりしまり",
    "termTranslation": "regulation, crackdown",
    "ex": "違法駐車の取り締まりが厳しくなったの。",
    "exReading": "いほう ちゅうしゃ の とりしまり が きびしく なった の",
    "exTranslation": "The regulation of illegal parking has become stricter.",
    "category": "Noun"
  },
  {
    "term": "取り締まる",
    "termReading": "とりしまる",
    "termTranslation": "control, crack down",
    "ex": "警察が飲酒運転を取り締まっていますよ。",
    "exReading": "けいさつ が いんしゅ うんてん を とりしまって います よ",
    "exTranslation": "The police are cracking down on drunk driving.",
    "category": "Verb"
  },
  {
    "term": "緩める",
    "termReading": "ゆるめる",
    "termTranslation": "loosen, unloose",
    "ex": "食べ過ぎたのでベルトを緩めたの。",
    "exReading": "たべすぎた の で べると を ゆるめた の",
    "exTranslation": "I loosened my belt because I ate too much.",
    "category": "Verb"
  },
  {
    "term": "緩やか",
    "termReading": "ゆるやか",
    "termTranslation": "slack, loose",
    "ex": "ここから先は緩やかな坂になっています。",
    "exReading": "ここ から さき は ゆるやか な さか に なって います",
    "exTranslation": "There's a gentle slope ahead.",
    "category": "Adjective"
  },
  {
    "term": "のろい",
    "termReading": "のろい",
    "termTranslation": "slow, sluggish",
    "ex": "この電車は本当にのろいですね。",
    "exReading": "この でんしゃ は ほんとうに のろい です ね",
    "exTranslation": "This train is really slow.",
    "category": "Adjective"
  },
  {
    "term": "緩む",
    "termReading": "ゆるむ",
    "termTranslation": "slacken, be loose",
    "ex": "彼は気が緩んでいます。",
    "exReading": "かれ は き が ゆるんで います",
    "exTranslation": "He's off-guard.",
    "category": "Verb"
  },
  {
    "term": "養う",
    "termReading": "やしなう",
    "termTranslation": "foster, support",
    "ex": "私は3人の子供を養っています。",
    "exReading": "わたし は 3 にん の こども を やしなって います",
    "exTranslation": "I'm feeding three children.",
    "category": "Verb"
  },
  {
    "term": "豊か",
    "termReading": "ゆたか",
    "termTranslation": "abundant, ample",
    "ex": "彼は心が豊かな人です。",
    "exReading": "かれ は こころ が ゆたか な ひと です",
    "exTranslation": "He has a rich spirit.",
    "category": "Adjective"
  },
  {
    "term": "富む",
    "termReading": "とむ",
    "termTranslation": "be rich, abound in",
    "ex": "彼の人生は変化に富んでいるな。",
    "exReading": "かれ の じんせい は へんか に とんで いる な",
    "exTranslation": "He has a wealth of life-changing experiences.",
    "category": "Verb"
  },
  {
    "term": "豊富",
    "termReading": "ほうふ",
    "termTranslation": "abundant, plentiful",
    "ex": "この果物はビタミンが豊富です。",
    "exReading": "この くだもの は びたみん が ほうふ です",
    "exTranslation": "This fruit is rich in vitamins.",
    "category": "Adjectival Noun"
  },
  {
    "term": "目茶苦茶",
    "termReading": "めちゃくちゃ",
    "termTranslation": "in a mess, terrible",
    "ex": "彼の運転は目茶苦茶です。",
    "exReading": "かれ の うんてん は めちゃくちゃ です",
    "exTranslation": "His driving is awful.",
    "category": "Adjectival Noun"
  },
  {
    "term": "ぴょんぴょん",
    "termReading": "ぴょんぴょん",
    "termTranslation": "jump, hop",
    "ex": "カエルがぴょんぴょんはねているね。",
    "exReading": "かえる が ぴょんぴょん はねて いる ね",
    "exTranslation": "Frogs are jumping around the pond.",
    "category": "Adverb"
  },
  {
    "term": "労働者",
    "termReading": "ろうどうしゃ",
    "termTranslation": "laborer, worker",
    "ex": "労働者がストをしたそうだよ。",
    "exReading": "ろうどうしゃ が すと を した そう だ よ",
    "exTranslation": "I hear that the workers went on strike.",
    "category": "Noun"
  },
  {
    "term": "労働",
    "termReading": "ろうどう",
    "termTranslation": "labor, toil",
    "ex": "彼らは1日7時間労働している。",
    "exReading": "かれら は 1 にち 7 じかん ろうどう して いる",
    "exTranslation": "They've been working for seven hours a day.",
    "category": "Verbal Noun"
  },
  {
    "term": "疲労",
    "termReading": "ひろう",
    "termTranslation": "fatigue",
    "ex": "部下が疲労で倒れたの。",
    "exReading": "ぶか が ひろう で たおれた の",
    "exTranslation": "My subordinate collapsed from fatigue.",
    "category": "Verbal Noun"
  },
  {
    "term": "労力",
    "termReading": "ろうりょく",
    "termTranslation": "labor, work",
    "ex": "仕事には時間と労力が必要です。",
    "exReading": "しごと に は じかん と ろうりょく が ひつよう です",
    "exTranslation": "Working requires time and labor.",
    "category": "Noun"
  },
  {
    "term": "貧しい",
    "termReading": "まずしい",
    "termTranslation": "poor, needy",
    "ex": "彼は貧しい家庭で育ったんだ。",
    "exReading": "かれ は まずしい かてい で そだった ん だ",
    "exTranslation": "He grew up in a poor family.",
    "category": "Adjective"
  },
  {
    "term": "ブラシ",
    "termReading": "ブラシ",
    "termTranslation": "brush",
    "ex": "犬の毛をブラシでとかしてやったの。",
    "exReading": "いぬ の け を ぶらし で とかして やった の",
    "exTranslation": "I brushed the dog's hair with a brush.",
    "category": "Noun"
  },
  {
    "term": "乏しい",
    "termReading": "とぼしい",
    "termTranslation": "scarce, short of",
    "ex": "彼女はまだ仕事の経験が乏しい。",
    "exReading": "かのじょ は まだ しごと の けいけん が とぼしい。",
    "exTranslation": "She still lacks work experience.",
    "category": "Adjective"
  },
  {
    "term": "貧乏人",
    "termReading": "びんぼうにん",
    "termTranslation": "poor person, the poor",
    "ex": "貧乏人が大金持ちになることもあるさ。",
    "exReading": "びんぼうにん が おおがねもち に なる こと も ある さ",
    "exTranslation": "Occasionally, poor people can become millionaires.",
    "category": "Noun"
  },
  {
    "term": "辛い",
    "termReading": "つらい",
    "termTranslation": "difficult, harsh",
    "ex": "この仕事は辛いです。",
    "exReading": "この しごと は つらい です",
    "exTranslation": "This work is harsh.",
    "category": "Adjective"
  },
  {
    "term": "不幸せ",
    "termReading": "ふしあわせ",
    "termTranslation": "unhappiness, misfortune",
    "ex": "彼ほど不幸せな人はいないな。",
    "exReading": "かれ ほど ふしあわせ な ひと は いない な",
    "exTranslation": "There's no one less happy than he.",
    "category": "Adjectival Noun"
  },
  {
    "term": "福祉",
    "termReading": "ふくし",
    "termTranslation": "welfare",
    "ex": "姉は福祉の勉強をしています。",
    "exReading": "あね は ふくし の べんきょう を して います",
    "exTranslation": "My big sister studies welfare.",
    "category": "Noun"
  },
  {
    "term": "マヨネーズ",
    "termReading": "マヨネーズ",
    "termTranslation": "mayonnaise",
    "ex": "サラダにマヨネーズをかけたの。",
    "exReading": "さらだ に まよねーず を かけた の",
    "exTranslation": "I put mayonnaise on the salad.",
    "category": "Noun"
  },
  {
    "term": "模様",
    "termReading": "もよう",
    "termTranslation": "pattern, design",
    "ex": "彼女は水玉模様のスカートをはいているね。",
    "exReading": "かのじょ は みずたま もよう の すかーと を はいて いる ね",
    "exTranslation": "She's wearing a skirt with polka dots.",
    "category": "Noun"
  },
  {
    "term": "模範",
    "termReading": "もはん",
    "termTranslation": "model, role model",
    "ex": "彼は全校生徒の模範です。",
    "exReading": "かれ は ぜんこう せいと の もはん です",
    "exTranslation": "He's a role model for all the students in our school.",
    "category": "Noun"
  },
  {
    "term": "農民",
    "termReading": "のうみん",
    "termTranslation": "peasants, farmers",
    "ex": "中世の農民は貧しかった。",
    "exReading": "ちゅうせい の のうみん は まずしかった",
    "exTranslation": "The farmers in the Middle Ages were poor.",
    "category": "Noun"
  },
  {
    "term": "農家",
    "termReading": "のうか",
    "termTranslation": "farmhouse",
    "ex": "彼は農家に生まれました。",
    "exReading": "かれ は のうか に うまれました",
    "exTranslation": "He was born into a farming family.",
    "category": "Noun"
  },
  {
    "term": "農村",
    "termReading": "のうそん",
    "termTranslation": "farm village, agricultural community",
    "ex": "私は農村で育ちました。",
    "exReading": "わたし は のうそん で そだちました",
    "exTranslation": "I grew up in a farming village.",
    "category": "Noun"
  },
  {
    "term": "低気圧",
    "termReading": "ていきあつ",
    "termTranslation": "low pressure, atmospheric depression",
    "ex": "低気圧が近づいています。",
    "exReading": "ていきあつ が ちかづいて います",
    "exTranslation": "A low-pressure front is approaching.",
    "category": "Noun"
  },
  {
    "term": "だらしない",
    "termReading": "だらしない",
    "termTranslation": "slovenly, untidy",
    "ex": "だらしない格好をしないでください。",
    "exReading": "だらしない かっこう を しない で ください",
    "exTranslation": "Please don't dress so sloppily.",
    "category": "Adjective"
  },
  {
    "term": "短縮",
    "termReading": "たんしゅく",
    "termTranslation": "shortening, contraction",
    "ex": "今日は授業を1時間に短縮します。",
    "exReading": "きょう は じゅぎょう を 1 じかん に たんしゅく します",
    "exTranslation": "The class will be reduced to one hour today.",
    "category": "Verbal Noun"
  },
  {
    "term": "縮める",
    "termReading": "ちぢめる",
    "termTranslation": "contract, shorten",
    "ex": "彼はタイムを1秒縮めたの。",
    "exReading": "かれ は たいむ を 1 びょう ちぢめた の",
    "exTranslation": "He reduced the time by one second.",
    "category": "Verb"
  },
  {
    "term": "縮む",
    "termReading": "ちぢむ",
    "termTranslation": "shrink, contract",
    "ex": "この服の縮み具合はひどい。",
    "exReading": "この ふく の ちぢみ ぐあい は ひどい",
    "exTranslation": "These clothes have shrunk terribly.",
    "category": "Verb"
  },
  {
    "term": "縮み",
    "termReading": "ちぢみ",
    "termTranslation": "shrinkage",
    "ex": "この服の縮み具合はひどい。",
    "exReading": "この ふく の ちぢみ ぐあい は ひどい",
    "exTranslation": "These clothes have shrunk terribly.",
    "category": "Noun"
  },
  {
    "term": "縮まる",
    "termReading": "ちぢまる",
    "termTranslation": "be shortened, be contracted",
    "ex": "兄との身長の差が縮まった。",
    "exReading": "あに と の しんちょう の さ が ちぢまった",
    "exTranslation": "The difference in height between me and my big brother has decreased.",
    "category": "Verb"
  },
  {
    "term": "とうもろこし",
    "termReading": "とうもろこし",
    "termTranslation": "corn, maize",
    "ex": "このとうもろこしは甘くておいしい。",
    "exReading": "この とうもろこし は あまく て おいしい",
    "exTranslation": "This corn is sweet and delicious.",
    "category": "Noun"
  },
  {
    "term": "伸ばす",
    "termReading": "のばす",
    "termTranslation": "stretch, straighten",
    "ex": "ストレッチで筋肉を伸ばしましょう。",
    "exReading": "すとれっち で きんにく を のばしましょう",
    "exTranslation": "Let's stretch our muscles with exercises.",
    "category": "Verb"
  },
  {
    "term": "引き伸ばす",
    "termReading": "ひきのばす",
    "termTranslation": "stretch out, enlarge",
    "ex": "この写真を引き伸ばしてください。",
    "exReading": "この しゃしん を ひきのばして ください",
    "exTranslation": "Please enlarge this photograph.",
    "category": "Verb"
  },
  {
    "term": "追伸",
    "termReading": "ついしん",
    "termTranslation": "P.S.",
    "ex": "追伸、お兄さんはお元気ですか。",
    "exReading": "ついしん おにいさん は おげんき です か",
    "exTranslation": "P.S. How is your older brother?",
    "category": "Noun"
  },
  {
    "term": "倍",
    "termReading": "ばい",
    "termTranslation": "double",
    "ex": "以前の収入は今の倍はあった。",
    "exReading": "いぜん の しゅうにゅう は いま の ばい は あった",
    "exTranslation": "My previous income was twice what it is now.",
    "category": "Noun"
  },
  {
    "term": "超過",
    "termReading": "ちょうか",
    "termTranslation": "excess",
    "ex": "この荷物は重量超過です。",
    "exReading": "この にもつ は じゅうりょう ちょうか です",
    "exTranslation": "This luggage is overweight.",
    "category": "Verbal Noun"
  },
  {
    "term": "ひらひら",
    "termReading": "ひらひら",
    "termTranslation": "lightly, floating",
    "ex": "木の葉がひらひらと落ちたの。",
    "exReading": "このは が ひらひら と おちた の",
    "exTranslation": "A leaf fluttered down.",
    "category": "Adverb"
  },
  {
    "term": "乗り越える",
    "termReading": "のりこえる",
    "termTranslation": "overcome, get over",
    "ex": "彼は悲しみを乗り越えて強く生きた。",
    "exReading": "かれ は かなしみ を のりこえて つよく いきた",
    "exTranslation": "He overcame his sadness and carried on determinedly.",
    "category": "Verb"
  },
  {
    "term": "乗り越し",
    "termReading": "のりこし",
    "termTranslation": "riding past one's stop",
    "ex": "乗り越しを機械で精算したの。",
    "exReading": "のりこし を きかい で せいさん した の",
    "exTranslation": "I paid the excess fare at the machine.",
    "category": "Verbal Noun"
  },
  {
    "term": "乗り越す",
    "termReading": "のりこす",
    "termTranslation": "ride past",
    "ex": "居眠りして降りる駅を乗り越した。",
    "exReading": "いねむり して おりる えき を のりこした",
    "exTranslation": "I dozed off and ending up going past my station.",
    "category": "Verb"
  },
  {
    "term": "抜ける",
    "termReading": "ぬける",
    "termTranslation": "come out, leave",
    "ex": "彼はグループから抜けました。",
    "exReading": "かれ は ぐるーぷ から ぬけました",
    "exTranslation": "He left the group.",
    "category": "Verb"
  },
  {
    "term": "抜く",
    "termReading": "ぬく",
    "termTranslation": "pull out",
    "ex": "ワインのコルクを抜きました。",
    "exReading": "わいん の こるく を ぬきました",
    "exTranslation": "I uncorked the wine.",
    "category": "Verb"
  },
  {
    "term": "ぽかぽか",
    "termReading": "ぽかぽか",
    "termTranslation": "pleasantly warm",
    "ex": "今日はぽかぽか暖かい日です。",
    "exReading": "きょう は ぽかぽか あたたかい ひ です",
    "exTranslation": "It's a lovely warm day today.",
    "category": "Adverb"
  },
  {
    "term": "昇る",
    "termReading": "のぼる",
    "termTranslation": "rise,",
    "ex": "太陽は東から昇ります。",
    "exReading": "たいよう は ひがし から のぼります",
    "exTranslation": "The sun rises in the east.",
    "category": "Verb"
  },
  {
    "term": "膨らむ",
    "termReading": "ふくらむ",
    "termTranslation": "expand, swell",
    "ex": "桜のつぼみが膨らんだのね。",
    "exReading": "さくら の つぼみ が ふくらんだ の ね",
    "exTranslation": "The cherry blossom buds swelled.",
    "category": "Verb"
  },
  {
    "term": "膨れる",
    "termReading": "ふくれる",
    "termTranslation": "expand, fill up",
    "ex": "お腹が膨れたら眠くなった。",
    "exReading": "おなか が ふくれたら ねむく なった",
    "exTranslation": "When my stomach was full, I became sleepy.",
    "category": "Verb"
  },
  {
    "term": "札",
    "termReading": "ふだ",
    "termTranslation": "sign, billboard",
    "ex": "店の外にまだ営業中の札がでているよ。",
    "exReading": "みせ の そと に まだ えいぎょう ちゅう の ふだ が でている よ",
    "exTranslation": "The \"open for business\" sign is still posted outside the store.",
    "category": "Noun"
  },
  {
    "term": "名札",
    "termReading": "なふだ",
    "termTranslation": "name badge, tag",
    "ex": "生徒たちは校内では名札をつけます。",
    "exReading": "せいとたち は こうない で は なふだ を つけます",
    "exTranslation": "Students wear name tags in school.",
    "category": "Noun"
  },
  {
    "term": "礼",
    "termReading": "れい",
    "termTranslation": "bow, courtesy",
    "ex": "先生にお礼の手紙を書きました。",
    "exReading": "せんせい に おれい の てがみ を かきました",
    "exTranslation": "I wrote a thank-you letter to my teacher.",
    "category": "Noun"
  },
  {
    "term": "よる",
    "termReading": "よる",
    "termTranslation": "be caused by",
    "ex": "人は見かけによらないな。",
    "exReading": "ひと は みかけ に よらない な",
    "exTranslation": "You can't judge people by their appearance.",
    "category": "Verb"
  },
  {
    "term": "反射",
    "termReading": "はんしゃ",
    "termTranslation": "reflection",
    "ex": "車のライトが反射していますね。",
    "exReading": "くるま の らいと が はんしゃ して います ね",
    "exTranslation": "Car lights are being reflected.",
    "category": "Verbal Noun"
  },
  {
    "term": "日程",
    "termReading": "にってい",
    "termTranslation": "program, schedule",
    "ex": "試験の日程が発表されました。",
    "exReading": "しけん の にってい が はっぴょう されました",
    "exTranslation": "The examination schedule was announced.",
    "category": "Noun"
  },
  {
    "term": "程",
    "termReading": "ほど",
    "termTranslation": "extent, limit",
    "ex": "冗談にも程がある。",
    "exReading": "じょうだん に も ほど が ある",
    "exTranslation": "There are limits on jokes.",
    "category": "Noun"
  },
  {
    "term": "優勝",
    "termReading": "ゆうしょう",
    "termTranslation": "victory, championship",
    "ex": "私たちのチームが優勝しました。",
    "exReading": "わたしたち の ちーむ が ゆうしょう しました",
    "exTranslation": "Our team won the championship.",
    "category": "Verbal Noun"
  },
  {
    "term": "優秀",
    "termReading": "ゆうしゅう",
    "termTranslation": "excellent, exceptional",
    "ex": "彼はとても優秀な生徒です。",
    "exReading": "かれ は とても ゆうしゅう な せいと です",
    "exTranslation": "He's a very exceptional student.",
    "category": "Adjectival Noun"
  },
  {
    "term": "だぶだぶ",
    "termReading": "だぶだぶ",
    "termTranslation": "baggy, too big",
    "ex": "この服は大き過ぎてだぶだぶです。",
    "exReading": "この ふく は おおき すぎて だぶだぶ です",
    "exTranslation": "These clothes are too big and baggy.",
    "category": "Adjective"
  },
  {
    "term": "透明",
    "termReading": "とうめい",
    "termTranslation": "transparent",
    "ex": "ゴミは透明な袋に入れて出してください。",
    "exReading": "ごみ は とうめい な ふくろ に いれて だして ください",
    "exTranslation": "Please put the garbage out in a clear plastic bag.",
    "category": "Adjectival Noun"
  },
  {
    "term": "半導体",
    "termReading": "はんどうたい",
    "termTranslation": "semiconductor",
    "ex": "半導体は様々な製品に使われているよ。",
    "exReading": "はんどうたい は さまざま な せいひん に つかわれて いる よ",
    "exTranslation": "Semiconductors are used in many products.",
    "category": "Noun"
  },
  {
    "term": "導く",
    "termReading": "みちびく",
    "termTranslation": "guide, lead",
    "ex": "先生は私たちを導いてくれます。",
    "exReading": "せんせい は わたしたち を みちびいて くれます",
    "exTranslation": "The teacher guides us.",
    "category": "Verb"
  },
  {
    "term": "望む",
    "termReading": "のぞむ",
    "termTranslation": "desire, hope",
    "ex": "彼は私との結婚を望んでいます。",
    "exReading": "かれ は わたし と の けっこん を のぞんで います",
    "exTranslation": "He wants to marry me.",
    "category": "Verb"
  },
  {
    "term": "望ましい",
    "termReading": "のぞましい",
    "termTranslation": "desirable, welcome",
    "ex": "夜は10時までに寝るのが望ましいの。",
    "exReading": "よる は 10 じ まで に ねる の が のぞましい の",
    "exTranslation": "It's recommended to go to sleep by ten o'clock at night.",
    "category": "Adjective"
  },
  {
    "term": "つるつる",
    "termReading": "つるつる",
    "termTranslation": "smooth, slippery",
    "ex": "床がつるつるすべります。",
    "exReading": "ゆか が つるつる すべります",
    "exTranslation": "The floor is shiny and slippery.",
    "category": "Adverb"
  },
  {
    "term": "要望",
    "termReading": "ようぼう",
    "termTranslation": "demand, request",
    "ex": "お客様の要望を聞かせてください。",
    "exReading": "おきゃくさま の ようぼう を きかせて ください",
    "exTranslation": "What would you like to request?",
    "category": "Verbal Noun"
  },
  {
    "term": "望み",
    "termReading": "のぞみ",
    "termTranslation": "desire, hope",
    "ex": "私の望みは海外で暮らすことです。",
    "exReading": "わたし の のぞみ は かいがい で くらす こと です",
    "exTranslation": "It's my wish to live abroad.",
    "category": "Noun"
  },
  {
    "term": "有望",
    "termReading": "ゆうぼう",
    "termTranslation": "promising, hopeful",
    "ex": "彼は有望な社員です。",
    "exReading": "かれ は ゆうぼう な しゃいん です",
    "exTranslation": "He's a promising employee.",
    "category": "Adjectival Noun"
  },
  {
    "term": "欲望",
    "termReading": "よくぼう",
    "termTranslation": "desire, craving",
    "ex": "彼は欲望が強い人です。",
    "exReading": "かれ は よくぼう が つよい ひと です",
    "exTranslation": "He's a person with strong desires.",
    "category": "Noun"
  },
  {
    "term": "待ち望む",
    "termReading": "まちのぞむ",
    "termTranslation": "expect, look forward to",
    "ex": "その国の人々は平和を待ち望んでいるの。",
    "exReading": "その くに の ひとびと は へいわ を まちのぞんで いる の",
    "exTranslation": "The citizens of that country are hoping for peace.",
    "category": "Verb"
  },
  {
    "term": "努める",
    "termReading": "つとめる",
    "termTranslation": "endeavor, make efforts, try hard",
    "ex": "良い成績が取れるように努めます。",
    "exReading": "いい せいせき が とれる よう に つとめます",
    "exTranslation": "I make efforts to get good grades.",
    "category": "Verb"
  },
  {
    "term": "トースト",
    "termReading": "トースト",
    "termTranslation": "toast",
    "ex": "私は毎朝トーストを2枚食べます。",
    "exReading": "わたし は まいあさ とーすと を 2 まい たべます",
    "exTranslation": "I eat two slices of toast every morning.",
    "category": "Noun"
  },
  {
    "term": "独立",
    "termReading": "どくりつ",
    "termTranslation": "independence, self-reliance",
    "ex": "先日会社から独立しました。",
    "exReading": "せんじつ かいしゃ から どくりつ しました",
    "exTranslation": "I created my own company the other day.",
    "category": "Verbal Noun"
  },
  {
    "term": "独特",
    "termReading": "どくとく",
    "termTranslation": "peculiarity, uniqueness",
    "ex": "彼の服のセンスは独特よね。",
    "exReading": "かれ の ふく の せんす は どくとく よ ね",
    "exTranslation": "His sense of style is unique.",
    "category": "Adjective"
  },
  {
    "term": "独り言",
    "termReading": "ひとりごと",
    "termTranslation": "soliloquy, monologue",
    "ex": "彼女はいつも独り言を言うんだ。",
    "exReading": "かのじょ は いつも ひとりごと を いう ん だ",
    "exTranslation": "She always talks to herself.",
    "category": "Noun"
  },
  {
    "term": "身",
    "termReading": "み",
    "termTranslation": "body, one's own person",
    "ex": "旅行中は身の安全が第一です。",
    "exReading": "りょこうちゅう は み の あんぜん が だいいち です",
    "exTranslation": "Personal safety is paramount while traveling.",
    "category": "Noun"
  },
  {
    "term": "中身",
    "termReading": "なかみ",
    "termTranslation": "content, interior",
    "ex": "かばんの中身を見せてください。",
    "exReading": "かばん の なかみ を みせて ください",
    "exTranslation": "Please show me the contents of the bag.",
    "category": "Noun"
  },
  {
    "term": "どきっと",
    "termReading": "どきっと",
    "termTranslation": "surprised, shocked",
    "ex": "突然の質問にどきっとしたよ。",
    "exReading": "とつぜん の しつもん に どきっと した よ",
    "exTranslation": "I was taken aback by the sudden question.",
    "category": "Adverb"
  },
  {
    "term": "身近",
    "termReading": "みぢか",
    "termTranslation": "near oneself, close to one",
    "ex": "私にとって動物は身近な存在です。",
    "exReading": "わたし に とって どうぶつ は みぢか な そんざい です",
    "exTranslation": "I feel very close to animals.",
    "category": "Adjectival Noun"
  },
  {
    "term": "身の回り",
    "termReading": "みのまわり",
    "termTranslation": "one's person, one's appearance",
    "ex": "身の回りのお世話は私がします。",
    "exReading": "みのまわり の おせわ は わたし が します 。",
    "exTranslation": "I'll take care of things for you.",
    "category": "Noun"
  },
  {
    "term": "身なり",
    "termReading": "みなり",
    "termTranslation": "dress, one's appearance",
    "ex": "身なりのいい人が入ってきた。",
    "exReading": "みなり の いい ひと が はいって きた",
    "exTranslation": "A well-dressed person came in.",
    "category": "Noun"
  },
  {
    "term": "身振り",
    "termReading": "みぶり",
    "termTranslation": "gesture, gesticulation",
    "ex": "彼は身振りを交えて説明してくれたの。",
    "exReading": "かれ は みぶり を まじえて せつめい して くれた の",
    "exTranslation": "He explained it to me while gesturing.",
    "category": "Noun"
  },
  {
    "term": "独占",
    "termReading": "どくせん",
    "termTranslation": "exclusive possession, monopoly",
    "ex": "この2社が市場を独占しています。",
    "exReading": "この 2 しゃ が しじょう を どくせん して います",
    "exTranslation": "These two companies monopolize the market.",
    "category": "Verbal Noun"
  },
  {
    "term": "のろのろ",
    "termReading": "のろのろ",
    "termTranslation": "slowly, easy",
    "ex": "車は雪道をのろのろと走ったの。",
    "exReading": "くるま は ゆきみち を のろのろ と はしった の",
    "exTranslation": "The car drove very slowly on the snowy road.",
    "category": "Adverb"
  },
  {
    "term": "仲良く",
    "termReading": "なかよく",
    "termTranslation": "harmoniously, amicably",
    "ex": "あの夫婦は仲良く暮らしているよ。",
    "exReading": "あの ふうふ は なかよく くらして いる よ",
    "exTranslation": "That married couple is living happily together.",
    "category": "Adverb"
  },
  {
    "term": "仲良し",
    "termReading": "なかよし",
    "termTranslation": "good friend, chum",
    "ex": "あの3人組は仲良しですね。",
    "exReading": "あの 3 にんぐみ は なかよし です ね",
    "exTranslation": "Those three are good friends.",
    "category": "Noun"
  },
  {
    "term": "仲直り",
    "termReading": "なかなおり",
    "termTranslation": "reconciliation, making up",
    "ex": "けんかした友達と仲直りしました。",
    "exReading": "けんか した ともだち と なかなおり しました",
    "exTranslation": "I made up with the friend I had a fight with.",
    "category": "Verbal Noun"
  },
  {
    "term": "仲",
    "termReading": "なか",
    "termTranslation": "personal relationship",
    "ex": "あなたたちは仲がいいですね。",
    "exReading": "あなたたち は なか が いい です ね",
    "exTranslation": "You get on well together, don't you?",
    "category": "Noun"
  },
  {
    "term": "仲間",
    "termReading": "なかま",
    "termTranslation": "comrade, peer",
    "ex": "彼には仲間がたくさんいる。",
    "exReading": "かれ に は なかま が たくさん いる",
    "exTranslation": "He has a lot of peers.",
    "category": "Noun"
  },
  {
    "term": "仲人",
    "termReading": "なこうど",
    "termTranslation": "go-between, matchmaker",
    "ex": "あの夫婦は私たちの仲人です。",
    "exReading": "あの ふうふ は わたしたち の なこうど です",
    "exTranslation": "That couple is our go-between.",
    "category": "Noun"
  },
  {
    "term": "ぴかぴか",
    "termReading": "ぴかぴか",
    "termTranslation": "new, shiny",
    "ex": "新車はぴかぴかですね。",
    "exReading": "しんしゃ は ぴかぴか です ね",
    "exTranslation": "The new car really shines.",
    "category": "Adverb"
  },
  {
    "term": "照らす",
    "termReading": "てらす",
    "termTranslation": "illuminate, shine on",
    "ex": "月が庭を照らしている。",
    "exReading": "つき が にわ を てらして いる",
    "exTranslation": "The moon is shining on the garden.",
    "category": "Verb"
  },
  {
    "term": "照る",
    "termReading": "てる",
    "termTranslation": "shine, be fine",
    "ex": "日差しが強く照りつけますね。",
    "exReading": "ひざし が つよく てりつけます ね",
    "exTranslation": "The sun is shining strongly.",
    "category": "Verb"
  },
  {
    "term": "夫人",
    "termReading": "ふじん",
    "termTranslation": "wife, married lady",
    "ex": "スミス夫人がいらっしゃいました。",
    "exReading": "すみすふじん が いらっしゃいました",
    "exTranslation": "Mrs. Smith came.",
    "category": "Noun"
  },
  {
    "term": "婦人",
    "termReading": "ふじん",
    "termTranslation": "woman, lady",
    "ex": "婦人服売り場は5階でございます。",
    "exReading": "ふじんふく うりば は 5 かい で ございます",
    "exTranslation": "The ladies' clothing department is on the fifth floor.",
    "category": "Noun"
  },
  {
    "term": "夫妻",
    "termReading": "ふさい",
    "termTranslation": "husband and wife, Mr. and Mrs.",
    "ex": "昨日の夜、社長ご夫妻と食事をしました。",
    "exReading": "きのう の よる しゃちょうごふさい と しょくじ を しました",
    "exTranslation": "I had dinner with the president and his wife last night.",
    "category": "Noun"
  },
  {
    "term": "ぺこぺこ",
    "termReading": "ぺこぺこ",
    "termTranslation": "bow, kowtow",
    "ex": "彼は上司にぺこぺこしている。",
    "exReading": "かれ は じょうし に ぺこぺこ して いる",
    "exTranslation": "He's bowing to his boss.",
    "category": "Adverb"
  },
  {
    "term": "相互",
    "termReading": "そうご",
    "termTranslation": "mutual, reciprocal",
    "ex": "チームの中では相互の信頼が大切です。",
    "exReading": "ちーむ の なか で は そうご の しんらい が たいせつ です",
    "exTranslation": "Mutual trust within the team is important.",
    "category": "Noun"
  },
  {
    "term": "互い",
    "termReading": "たがい",
    "termTranslation": "each other",
    "ex": "互いの話をよく聞きなさい。",
    "exReading": "たがい の はなし を よく ききなさい",
    "exTranslation": "Listen carefully to each other.",
    "category": "Noun"
  },
  {
    "term": "皆",
    "termReading": "みな",
    "termTranslation": "all, everybody",
    "ex": "私の昇進を皆が喜んでくれたの。",
    "exReading": "わたし の しょうしん を みな が よろこんで くれた の",
    "exTranslation": "Everyone was pleased with my promotion.",
    "category": "Noun"
  },
  {
    "term": "我々",
    "termReading": "われわれ",
    "termTranslation": "we",
    "ex": "我々の決意は固いです。",
    "exReading": "われわれ の けつい は かたい です",
    "exTranslation": "Our decision is firm.",
    "category": "Noun"
  },
  {
    "term": "我が国",
    "termReading": "わがくに",
    "termTranslation": "our country, Japan",
    "ex": "彼は我が国を代表する作家です。",
    "exReading": "かれ は わがくに を だいひょう する さっか です",
    "exTranslation": "He's a writer who's representative of our country.",
    "category": "Noun"
  },
  {
    "term": "ぺらぺら",
    "termReading": "ぺらぺら",
    "termTranslation": "floppy, flimsy",
    "ex": "この本は薄くてぺらぺらですね。",
    "exReading": "この ほん は うすくて ぺらぺら です ね",
    "exTranslation": "This book is thin and flimsy.",
    "category": "Adjective"
  },
  {
    "term": "我が家",
    "termReading": "わがや",
    "termTranslation": "one's home",
    "ex": "ぜひ我が家に遊びに来てください。",
    "exReading": "ぜひ わがや に あそび に きて ください 。",
    "exTranslation": "Please come and visit me at home.",
    "category": "Noun"
  },
  {
    "term": "年齢",
    "termReading": "ねんれい",
    "termTranslation": "age, years",
    "ex": "彼女の年齢は27です。",
    "exReading": "かのじょ の ねんれい は 27 です",
    "exTranslation": "She's 27 years old.",
    "category": "Noun"
  },
  {
    "term": "恋愛",
    "termReading": "れんあい",
    "termTranslation": "love",
    "ex": "彼女は恋愛にあこがれる年ごろです。",
    "exReading": "かのじょ は れんあい に あこがれる とし ごろ です",
    "exTranslation": "She's at that age when girls yearn for romance.",
    "category": "Verbal Noun"
  },
  {
    "term": "初恋",
    "termReading": "はつこい",
    "termTranslation": "first love",
    "ex": "私の初恋は小学生の時です。",
    "exReading": "わたし の はつこい は しょうがくせい の とき です",
    "exTranslation": "My first love was when I was at elementary school.",
    "category": "Noun"
  },
  {
    "term": "誕生",
    "termReading": "たんじょう",
    "termTranslation": "birth",
    "ex": "先月、娘が誕生しました。",
    "exReading": "せんげつ むすめ が たんじょう しました",
    "exTranslation": "My daughter was born last month.",
    "category": "Verbal Noun"
  },
  {
    "term": "ほこり",
    "termReading": "ほこり",
    "termTranslation": "dust",
    "ex": "この部屋はほこりだらけですね。",
    "exReading": "この へや は ほこり だらけ です ね 。",
    "exTranslation": "This room is covered in dust.",
    "category": "Noun"
  },
  {
    "term": "延びる",
    "termReading": "のびる",
    "termTranslation": "extend, be postponed",
    "ex": "工事の予定が1ヶ月延びてしまった。",
    "exReading": "こうじ の よてい が 1 かげつ のびて しまった",
    "exTranslation": "The construction schedule has been postponed for one month.",
    "category": "Verb"
  },
  {
    "term": "延ばす",
    "termReading": "のばす",
    "termTranslation": "extend, postpone",
    "ex": "出発を一週間延ばしたの。",
    "exReading": "しゅっぱつ を いっしゅうかん のばした の",
    "exTranslation": "I delayed my departure for a week.",
    "category": "Verb"
  },
  {
    "term": "引き延ばす",
    "termReading": "ひきのばす",
    "termTranslation": "extend, prolong",
    "ex": "司会者は話を引き延ばしたわ。",
    "exReading": "しかいしゃ は はなし を ひきのばした わ",
    "exTranslation": "The emcee prolonged his speech.",
    "category": "Verb"
  },
  {
    "term": "単純",
    "termReading": "たんじゅん",
    "termTranslation": "simple, uncomplicated",
    "ex": "彼は単純な人です。",
    "exReading": "かれ は たんじゅん な ひと です",
    "exTranslation": "He's an uncomplicated person.",
    "category": "Adjectival Noun"
  },
  {
    "term": "夢中",
    "termReading": "むちゅう",
    "termTranslation": "be absorbed",
    "ex": "うちの子はその本に夢中です。",
    "exReading": "うち の こ は その ほん に むちゅう です",
    "exTranslation": "Our child is fascinated by that book.",
    "category": "Adjectival Noun"
  },
  {
    "term": "泣き顔",
    "termReading": "なきがお",
    "termTranslation": "tear-stained face",
    "ex": "彼女は泣き顔になったの。",
    "exReading": "かのじょ は なきがお に なった の",
    "exTranslation": "She looked like she was about to cry.",
    "category": "Noun"
  },
  {
    "term": "ポット",
    "termReading": "ポット",
    "termTranslation": "pot, thermos",
    "ex": "お湯はポットに入っています。",
    "exReading": "おゆ は ぽっと に はいって います",
    "exTranslation": "There's some hot water in the thermos pot.",
    "category": "Noun"
  },
  {
    "term": "笑い",
    "termReading": "わらい",
    "termTranslation": "laughter",
    "ex": "あの家は笑いが絶えないね。",
    "exReading": "あの いえ は わらい が たえない ね",
    "exTranslation": "Laughter never stops in that house.",
    "category": "Noun"
  },
  {
    "term": "喜び",
    "termReading": "よろこび",
    "termTranslation": "joy, happiness",
    "ex": "人々は喜びに沸いた。",
    "exReading": "ひとびと は よろこび に わいた",
    "exTranslation": "The people were buzzing with happiness.",
    "category": "Noun"
  },
  {
    "term": "喜ばす",
    "termReading": "よろこばす",
    "termTranslation": "please, gladden",
    "ex": "私は人を喜ばすのが大好きです。",
    "exReading": "わたし は ひと を よろこばす の が だいすき です",
    "exTranslation": "I love to make other people happy.",
    "category": "Verb"
  },
  {
    "term": "恥",
    "termReading": "はじ",
    "termTranslation": "shame, disgrace",
    "ex": "間違えることは恥ではありません。",
    "exReading": "まちがえる こと は はじ で は ありません",
    "exTranslation": "There's no shame in making a mistake.",
    "category": "Noun"
  },
  {
    "term": "弁論",
    "termReading": "べんろん",
    "termTranslation": "discussion, oral proceedings",
    "ex": "弁論大会で優勝したことがあります。",
    "exReading": "べんろん たいかい で ゆうしょう した こと が あります",
    "exTranslation": "I've won a speech contest before.",
    "category": "Verbal Noun"
  },
  {
    "term": "ミュージック",
    "termReading": "ミュージック",
    "termTranslation": "music",
    "ex": "彼はソウルミュージックをよく聞くの。",
    "exReading": "かれ は そうる みゅーじっく を よく きく の",
    "exTranslation": "He often listens to soul music.",
    "category": "Noun"
  },
  {
    "term": "保護",
    "termReading": "ほご",
    "termTranslation": "protection, safeguard",
    "ex": "みんなで環境を保護しましょう。",
    "exReading": "みんな で かんきょう を ほご しましょう",
    "exTranslation": "Let's all protect the environment.",
    "category": "Verbal Noun"
  },
  {
    "term": "弁護",
    "termReading": "べんご",
    "termTranslation": "defense",
    "ex": "友人が私を弁護してくれました。",
    "exReading": "ゆうじん が わたし を べんご して くれました",
    "exTranslation": "My friend defended me.",
    "category": "Verbal Noun"
  },
  {
    "term": "保護者",
    "termReading": "ほごしゃ",
    "termTranslation": "guardian, protector",
    "ex": "私はこの子の保護者です。",
    "exReading": "わたし は この こ の ほごしゃ です",
    "exTranslation": "I'm this child's guardian.",
    "category": "Noun"
  },
  {
    "term": "同士",
    "termReading": "どうし",
    "termTranslation": "fellow, to each other",
    "ex": "彼と私はいとこ同士です。",
    "exReading": "かれ と わたし は いとこ どうし です",
    "exTranslation": "He and I are cousins.",
    "category": "Noun"
  },
  {
    "term": "弁護士",
    "termReading": "べんごし",
    "termTranslation": "lawyer, attorney",
    "ex": "父は弁護士です。",
    "exReading": "ちち は べんごし です",
    "exTranslation": "My father's a lawyer.",
    "category": "Noun"
  },
  {
    "term": "ロマンチック",
    "termReading": "ロマンチック",
    "termTranslation": "romantic",
    "ex": "彼女はロマンチックな人です。",
    "exReading": "かのじょ は ろまんちっく な ひと です",
    "exTranslation": "She's a romantic person.",
    "category": "Adjective"
  },
  {
    "term": "否定",
    "termReading": "ひてい",
    "termTranslation": "denial, negation",
    "ex": "彼、友達の意見を否定した。",
    "exReading": "かれ ともだち の いけん を ひてい した",
    "exTranslation": "He disagreed with his friend's opinion.",
    "category": "Verbal Noun"
  },
  {
    "term": "総裁",
    "termReading": "そうさい",
    "termTranslation": "president, governor",
    "ex": "あの人は日本銀行の総裁です。",
    "exReading": "あの ひと は にっぽんぎんこう の そうさい です",
    "exTranslation": "That person is the president of the Bank of Japan.",
    "category": "Noun"
  },
  {
    "term": "迷信",
    "termReading": "めいしん",
    "termTranslation": "superstition",
    "ex": "村の人たちは迷信を信じています。",
    "exReading": "むら の ひとたち は めいしん を しんじて います",
    "exTranslation": "The people in the village believe in superstition.",
    "category": "Noun"
  },
  {
    "term": "迷子",
    "termReading": "まいご",
    "termTranslation": "lost child, lost person",
    "ex": "うちの子が迷子になりました。",
    "exReading": "うち の こ が まいご に なりました",
    "exTranslation": "Our child has gotten lost.",
    "category": "Noun"
  },
  {
    "term": "領域",
    "termReading": "りょういき",
    "termTranslation": "territory, domain",
    "ex": "彼女は料理の腕が素人の領域を超えているわ。",
    "exReading": "かのじょ は りょうり の うで が しろうと の りょういき を こえて いる わ",
    "exTranslation": "Her cooking skills go beyond the range of an amateur.",
    "category": "Noun"
  },
  {
    "term": "謎",
    "termReading": "なぞ",
    "termTranslation": "puzzle, mystery",
    "ex": "ピラミッドには謎が多いんだ。",
    "exReading": "ぴらみっど に は なぞ が おおい ん だ",
    "exTranslation": "There are a lot of mysteries about the pyramids.",
    "category": "Noun"
  },
  {
    "term": "わくわく",
    "termReading": "わくわく",
    "termTranslation": "feel excited",
    "ex": "遠足が楽しみでわくわくしています。",
    "exReading": "えんそく が たのしみ で わくわく して います",
    "exTranslation": "I'm looking forward to the trip, so I'm really excited.",
    "category": "Adverb"
  },
  {
    "term": "著者",
    "termReading": "ちょしゃ",
    "termTranslation": "author, writer",
    "ex": "この本の著者はイギリス人です。",
    "exReading": "この ほん の ちょしゃ は いぎりすじん です",
    "exTranslation": "The author of this book is British.",
    "category": "Noun"
  },
  {
    "term": "著書",
    "termReading": "ちょしょ",
    "termTranslation": "literary work, book",
    "ex": "彼の新しい著書が出版されたね。",
    "exReading": "かれ の あたらしい ちょしょ が しゅっぱん された ね",
    "exTranslation": "His new book was published.",
    "category": "Noun"
  },
  {
    "term": "偽物",
    "termReading": "にせもの",
    "termTranslation": "sham, imitation",
    "ex": "彼らが売っていたのは偽物だ。",
    "exReading": "かれら が うって いた の は にせもの だ",
    "exTranslation": "What they were selling was a fake.",
    "category": "Noun"
  },
  {
    "term": "ノーベル賞",
    "termReading": "ノーベルしょう",
    "termTranslation": "Nobel Prize",
    "ex": "日本人がノーベル賞を取ったよ。",
    "exReading": "にほんじん が のーべるしょう を とった よ",
    "exTranslation": "A Japanese person won the Nobel prize.",
    "category": "Noun"
  },
  {
    "term": "文化財",
    "termReading": "ぶんかざい",
    "termTranslation": "cultural assets",
    "ex": "この建物は国の文化財です。",
    "exReading": "この たてもの は くに の ぶんかざい です",
    "exTranslation": "This building is a national cultural asset.",
    "category": "Noun"
  },
  {
    "term": "わさび",
    "termReading": "わさび",
    "termTranslation": "wasabi, Japanese horseradish",
    "ex": "刺身にわさびは欠かせませんね。",
    "exReading": "さしみ に わさび は かかせません ね",
    "exTranslation": "Wasabi is indispensable to sashimi.",
    "category": "Noun"
  },
  {
    "term": "預金",
    "termReading": "よきん",
    "termTranslation": "deposit, bank account",
    "ex": "私はこの銀行に預金しています。",
    "exReading": "わたし は この ぎんこう に よきん して います",
    "exTranslation": "I've deposited money in this bank.",
    "category": "Verbal Noun"
  },
  {
    "term": "納得",
    "termReading": "なっとく",
    "termTranslation": "acceptance, persuasion",
    "ex": "彼の説明で納得できました。",
    "exReading": "かれ の せつめい で なっとく できました",
    "exTranslation": "I was able to accept his explanation.",
    "category": "Verbal Noun"
  },
  {
    "term": "不得意",
    "termReading": "ふとくい",
    "termTranslation": "one's weak point",
    "ex": "私は数学が不得意です。",
    "exReading": "わたし は すうがく が ふとくい です",
    "exTranslation": "I'm not good at math.",
    "category": "Adjectival Noun"
  },
  {
    "term": "得する",
    "termReading": "とくする",
    "termTranslation": "profit, gain",
    "ex": "ネットで得する情報を見つけたよ。",
    "exReading": "ねっと で とく する じょうほう を みつけた よ",
    "exTranslation": "I found good deals on the internet.",
    "category": "Verb"
  },
  {
    "term": "得",
    "termReading": "とく",
    "termTranslation": "gain, advantage",
    "ex": "この車を今買うとお得ですよ。",
    "exReading": "この くるま を いま かう と お とく です よ",
    "exTranslation": "There's a special offer on this car if you buy it now.",
    "category": "Adjectival Noun"
  },
  {
    "term": "チキン",
    "termReading": "チキン",
    "termTranslation": "chicken, fried chicken",
    "ex": "チキンソテーは私の大好物です。",
    "exReading": "ちきんそてー は わたし の だいこうぶつ です",
    "exTranslation": "Chicken saute is my favorite dish.",
    "category": "Noun"
  },
  {
    "term": "損",
    "termReading": "そん",
    "termTranslation": "loss, damage",
    "ex": "パチンコで5000円損しました。",
    "exReading": "ぱちんこ で 5000 えん そん しました",
    "exTranslation": "I lost 5,000 yen at pachinko.",
    "category": "Verbal Noun"
  },
  {
    "term": "損害",
    "termReading": "そんがい",
    "termTranslation": "damage, harm",
    "ex": "町は台風で大きな損害を受けたんだ。",
    "exReading": "まち は たいふう で おおきな そんがい を うけた ん だ",
    "exTranslation": "The town sustained a lot of damages because of the typhoon.",
    "category": "Noun"
  },
  {
    "term": "損する",
    "termReading": "そんする",
    "termTranslation": "lose, suffer loss",
    "ex": "わざわざ行って損した。",
    "exReading": "わざわざ いって そん した",
    "exTranslation": "I wasted my time going to all that trouble.",
    "category": "Verb"
  },
  {
    "term": "燃料",
    "termReading": "ねんりょう",
    "termTranslation": "fuel",
    "ex": "車から燃料がもれていますよ。",
    "exReading": "くるま から ねんりょう が もれて います よ",
    "exTranslation": "Fuel is leaking from the car.",
    "category": "Noun"
  },
  {
    "term": "燃やす",
    "termReading": "もやす",
    "termTranslation": "burn",
    "ex": "古い手紙を燃やしたんだ。",
    "exReading": "ふるい てがみ を もやした ん だ",
    "exTranslation": "I burned some old letters.",
    "category": "Verb"
  },
  {
    "term": "日焼け",
    "termReading": "ひやけ",
    "termTranslation": "suntan",
    "ex": "海で日焼けしたんだ。",
    "exReading": "うみ で ひやけ した ん だ",
    "exTranslation": "I got sunburnt at the seaside.",
    "category": "Verbal Noun"
  },
  {
    "term": "とじる",
    "termReading": "とじる",
    "termTranslation": "bind, file",
    "ex": "書類はこのファイルにとじてください。",
    "exReading": "しょるい は この ふぁいる に とじて ください",
    "exTranslation": "Put the documents in this file.",
    "category": "Verb"
  },
  {
    "term": "焼き肉",
    "termReading": "やきにく",
    "termTranslation": "grilled meat, barbequed meat",
    "ex": "彼は焼き肉が大好きです。",
    "exReading": "かれ は やきにく が だいすき です",
    "exTranslation": "He loves beef barbecue.",
    "category": "Noun"
  },
  {
    "term": "夕焼け",
    "termReading": "ゆうやけ",
    "termTranslation": "sunset glow",
    "ex": "今日は夕焼けがきれいです。",
    "exReading": "きょう は ゆうやけ が きれい です",
    "exTranslation": "The sunset glow is beautiful today.",
    "category": "Noun"
  },
  {
    "term": "焼きそば",
    "termReading": "やきそば",
    "termTranslation": "pan-fried noodles",
    "ex": "お祭りで焼きそばを食べたよ。",
    "exReading": "おまつり で やきそば を たべた よ",
    "exTranslation": "I ate pan-fried noodles at the festival.",
    "category": "Noun"
  },
  {
    "term": "幹",
    "termReading": "みき",
    "termTranslation": "tree trunk",
    "ex": "この木の幹はとても太いよ。",
    "exReading": "この き の みき は とても ふとい よ",
    "exTranslation": "The trunk of this tree is very thick.",
    "category": "Noun"
  },
  {
    "term": "分散",
    "termReading": "ぶんさん",
    "termTranslation": "dispersion",
    "ex": "その会社はいろいろな国に投資を分散しているね。",
    "exReading": "その かいしゃ は いろいろ な くに に とうし を ぶんさん して いる ね",
    "exTranslation": "The company spreads their investment into a variety of countries.",
    "category": "Verbal Noun"
  },
  {
    "term": "にやにや",
    "termReading": "にやにや",
    "termTranslation": "grin to oneself",
    "ex": "なぜか彼はにやにやしています。",
    "exReading": "なぜ か かれ は にやにや して います",
    "exTranslation": "He's grinning for some reason.",
    "category": "Adverb"
  },
  {
    "term": "散らばる",
    "termReading": "ちらばる",
    "termTranslation": "be scattered",
    "ex": "机の上に書類が散らばっている。",
    "exReading": "つくえ の うえ に しょるい が ちらばって いる",
    "exTranslation": "The documents were scattered on the desk.",
    "category": "Verb"
  },
  {
    "term": "散る",
    "termReading": "ちる",
    "termTranslation": "scatter, fall",
    "ex": "風で桜の花が散ってるね。",
    "exReading": "かぜ で さくら の はな が ちってる ね",
    "exTranslation": "Cherry blossoms are scattering in the wind.",
    "category": "Verb"
  },
  {
    "term": "散らかる",
    "termReading": "ちらかる",
    "termTranslation": "lie scattered about, be in disorder",
    "ex": "弟の部屋はいつも散らかっているんだ。",
    "exReading": "おとうと の へや は いつも ちらかって いる ん だ",
    "exTranslation": "My little brother's room is always untidy.",
    "category": "Verb"
  },
  {
    "term": "散らかす",
    "termReading": "ちらかす",
    "termTranslation": "scatter, disarrange",
    "ex": "部屋を散らかさないでください。",
    "exReading": "へや を ちらかさない で ください",
    "exTranslation": "Please don't mess up the room.",
    "category": "Verb"
  },
  {
    "term": "田植え",
    "termReading": "たうえ",
    "termTranslation": "rice planting",
    "ex": "5月は田植えの季節です。",
    "exReading": "5 がつ は たうえ の きせつ です",
    "exTranslation": "May is the rice planting season.",
    "category": "Verbal Noun"
  },
  {
    "term": "ばら",
    "termReading": "ばら",
    "termTranslation": "rose",
    "ex": "お祝いにばらの花束を贈りました。",
    "exReading": "おいわい に ばら の はなたば を おくりました",
    "exTranslation": "I gave her a bouquet of roses to celebrate the occasion.",
    "category": "Noun"
  },
  {
    "term": "根",
    "termReading": "ね",
    "termTranslation": "root, source",
    "ex": "この木の根はとても太いな。",
    "exReading": "この き の ね は とても ふとい な",
    "exTranslation": "The roots of this tree are very thick.",
    "category": "Noun"
  },
  {
    "term": "大根",
    "termReading": "だいこん",
    "termTranslation": "daikon radish, oriental radish",
    "ex": "大根は白くて長い野菜です。",
    "exReading": "だいこん は しろくて ながい やさい です",
    "exTranslation": "The daikon radish is a long, white vegetable.",
    "category": "Noun"
  },
  {
    "term": "まな板",
    "termReading": "まないた",
    "termTranslation": "chopping board",
    "ex": "魚を切った後、まな板を洗ったの。",
    "exReading": "さかな を きった あと、 まないた を あらった の",
    "exTranslation": "I washed the cutting board after cutting fish.",
    "category": "Noun"
  },
  {
    "term": "ほうれん草",
    "termReading": "ほうれんそう",
    "termTranslation": "spinach",
    "ex": "ほうれん草はビタミンが豊富です。",
    "exReading": "ほうれんそう は びたみん が ほうふ です 。",
    "exTranslation": "Spinach is rich in vitamins.",
    "category": "Noun"
  },
  {
    "term": "話し言葉",
    "termReading": "はなしことば",
    "termTranslation": "spoken language",
    "ex": "話し言葉と書き言葉は少し違いますね。",
    "exReading": "はなしことば と かきことば は すこし ちがいます ね",
    "exTranslation": "The spoken and written language are a little different.",
    "category": "Noun"
  },
  {
    "term": "パンティー",
    "termReading": "パンティー",
    "termTranslation": "panties",
    "ex": "白いパンティーを買いました。",
    "exReading": "しろい ぱんてぃー を かいました",
    "exTranslation": "I bought some white panties.",
    "category": "Noun"
  },
  {
    "term": "葉っぱ",
    "termReading": "はっぱ",
    "termTranslation": "leaf",
    "ex": "もみじの葉っぱが赤くなりましたね。",
    "exReading": "もみじ の はっぱ が あかく なりました ね",
    "exTranslation": "The maple leaves turned red.",
    "category": "Noun"
  },
  {
    "term": "呼び出す",
    "termReading": "よびだす",
    "termTranslation": "call, page",
    "ex": "親が学校に呼び出されたんだ。",
    "exReading": "おや が がっこう に よびだされた ん だ",
    "exTranslation": "My parents were called in to the school.",
    "category": "Verb"
  },
  {
    "term": "取り扱う",
    "termReading": "とりあつかう",
    "termTranslation": "handle, deal in",
    "ex": "当店ではお酒を取り扱っておりません。",
    "exReading": "とうてん で は お さけ を とりあつかって おりません",
    "exTranslation": "We don't sell alcohol in this shop.",
    "category": "Verb"
  },
  {
    "term": "取り扱い",
    "termReading": "とりあつかい",
    "termTranslation": "handling, manipulation",
    "ex": "この機械は取り扱いに注意してください。",
    "exReading": "この きかい は とりあつかい に ちゅうい して ください",
    "exTranslation": "Be careful when handling this machine.",
    "category": "Noun"
  },
  {
    "term": "同級生",
    "termReading": "どうきゅうせい",
    "termTranslation": "classmate",
    "ex": "私たちは同級生です。",
    "exReading": "わたしたち は どうきゅうせい です",
    "exTranslation": "We're classmates.",
    "category": "Noun"
  },
  {
    "term": "中級",
    "termReading": "ちゅうきゅう",
    "termTranslation": "middle-class, intermediate",
    "ex": "彼は中級レベルの日本語を習っています。",
    "exReading": "かれ は ちゅうきゅう れべる の にほんご を ならって います",
    "exTranslation": "He's learning intermediate-level Japanese.",
    "category": "Noun"
  },
  {
    "term": "ふわふわ",
    "termReading": "ふわふわ",
    "termTranslation": "soft",
    "ex": "ふわふわの布団に寝たよ。",
    "exReading": "ふわふわ の ふとん に ねた よ",
    "exTranslation": "I slept on a soft futon.",
    "category": "Adjective"
  },
  {
    "term": "腹",
    "termReading": "はら",
    "termTranslation": "belly",
    "ex": "腹が減って動けない。",
    "exReading": "はら が へって うごけない",
    "exTranslation": "I'm too hungry to move.",
    "category": "Noun"
  },
  {
    "term": "腹一杯",
    "termReading": "はらいっぱい",
    "termTranslation": "full stomach",
    "ex": "夕食を腹一杯食べた。",
    "exReading": "ゆうしょく を はらいっぱい たべた",
    "exTranslation": "I ate heartily at supper.",
    "category": "Adverb"
  },
  {
    "term": "肺",
    "termReading": "はい",
    "termTranslation": "lung, lungs",
    "ex": "彼は肺の病気にかかったのよ。",
    "exReading": "かれ は はい の びょうき に かかった の よ",
    "exTranslation": "He contracted lung disease.",
    "category": "Noun"
  },
  {
    "term": "溶ける",
    "termReading": "とける",
    "termTranslation": "be dissolved, be melted",
    "ex": "暑さでアイスクリームが溶けてしまった。",
    "exReading": "あつさ で あいすくりーむ が とけて しまった",
    "exTranslation": "The ice cream melted in the heat.",
    "category": "Verb"
  },
  {
    "term": "溶かす",
    "termReading": "とかす",
    "termTranslation": "dissolve, melt",
    "ex": "春の太陽が雪を溶かしました。",
    "exReading": "はる の たいよう が ゆき を とかしました",
    "exTranslation": "The spring sun melted the snow.",
    "category": "Verb"
  },
  {
    "term": "まずい",
    "termReading": "まずい",
    "termTranslation": "problematic, troublesome",
    "ex": "今日中に返答しなければまずい。",
    "exReading": "きょうじゅう に へんとう しなければ まずい",
    "exTranslation": "If I don't respond by the end of today there will be trouble.",
    "category": "Adjective"
  },
  {
    "term": "容易",
    "termReading": "ようい",
    "termTranslation": "easy, simple",
    "ex": "彼はその問題を容易に解決したわ。",
    "exReading": "かれ は その もんだい を ようい に かいけつ した わ",
    "exTranslation": "He easily solved the problem.",
    "category": "Adjectival Noun"
  },
  {
    "term": "容器",
    "termReading": "ようき",
    "termTranslation": "receptacle, container",
    "ex": "容器のふたはきちんと閉めましょう。",
    "exReading": "ようき の ふた は きちんと しめましょう",
    "exTranslation": "Be sure to properly close the containers' lids.",
    "category": "Noun"
  },
  {
    "term": "滑らか",
    "termReading": "なめらか",
    "termTranslation": "smooth, glassy",
    "ex": "この生地は滑らかな手触りが特長です。",
    "exReading": "この きじ は なめらか な てざわり が とくちょう です",
    "exTranslation": "This cloth's main feature is its smooth texture.",
    "category": "Adjective"
  },
  {
    "term": "分析",
    "termReading": "ぶんせき",
    "termTranslation": "analysis",
    "ex": "今の経済の動きを分析しています。",
    "exReading": "いま の けいざい の うごき を ぶんせき して います",
    "exTranslation": "We're analyzing today's economic movements.",
    "category": "Verbal Noun"
  },
  {
    "term": "保健",
    "termReading": "ほけん",
    "termTranslation": "health, health care",
    "ex": "保健室で少し休んだわ。",
    "exReading": "ほけんしつ で すこし やすんだ わ",
    "exTranslation": "I rested a little in the school nurse's office.",
    "category": "Noun"
  },
  {
    "term": "もむ",
    "termReading": "もむ",
    "termTranslation": "rub, massage",
    "ex": "肩をもんでください。",
    "exReading": "かた を もんで ください",
    "exTranslation": "Please massage my shoulders.",
    "category": "Verb"
  },
  {
    "term": "診る",
    "termReading": "みる",
    "termTranslation": "examine",
    "ex": "今日、医者に診てもらいました。",
    "exReading": "きょう 、 いしゃ に みてもらいました。",
    "exTranslation": "I had the doctor examine me today.",
    "category": "Verb"
  },
  {
    "term": "治療",
    "termReading": "ちりょう",
    "termTranslation": "medical treatment",
    "ex": "今、歯を治療しています。",
    "exReading": "いま は を ちりょう して います",
    "exTranslation": "I'm having my teeth treated now.",
    "category": "Verbal Noun"
  },
  {
    "term": "毒",
    "termReading": "どく",
    "termTranslation": "poison, harm",
    "ex": "飲み過ぎは体に毒ですよ。",
    "exReading": "のみ すぎ は からだ に どく です よ",
    "exTranslation": "Drinking too much can poison your body.",
    "category": "Noun"
  },
  {
    "term": "中毒",
    "termReading": "ちゅうどく",
    "termTranslation": "poisoning",
    "ex": "そのホテルで食中毒が発生したんだ。",
    "exReading": "その ほてる で しょくちゅうどく が はっせい した ん だ",
    "exTranslation": "Food poisoning broke out at the hotel.",
    "category": "Verbal Noun"
  },
  {
    "term": "有毒",
    "termReading": "ゆうどく",
    "termTranslation": "poisonous, noxious",
    "ex": "その工場は有毒ガスを出しているのね。",
    "exReading": "その こうじょう は ゆうどくがす を だして いる の ね",
    "exTranslation": "There are noxious fumes coming out of that factory.",
    "category": "Adjectival Noun"
  },
  {
    "term": "ばい菌",
    "termReading": "ばいきん",
    "termTranslation": "bacterium, germ",
    "ex": "傷口にばい菌が入った。",
    "exReading": "きずぐち に ばいきん が はいった",
    "exTranslation": "Some bacteria got into the wound.",
    "category": "Noun"
  },
  {
    "term": "センター",
    "termReading": "センター",
    "termTranslation": "center",
    "ex": "このセンターで工場全体を管理しています。",
    "exReading": "この せんたー で こうじょう ぜんたい を かんり して います",
    "exTranslation": "The entire factory is run from this center.",
    "category": "Noun"
  },
  {
    "term": "不潔",
    "termReading": "ふけつ",
    "termTranslation": "dirty, filthy",
    "ex": "体を不潔にしているとかゆくなりますよ。",
    "exReading": "からだ を ふけつ に して いる と かゆく なります よ",
    "exTranslation": "If you don't keep your body clean, you're going to start itching.",
    "category": "Adjectival Noun"
  },
  {
    "term": "編集",
    "termReading": "へんしゅう",
    "termTranslation": "editing, compilation",
    "ex": "私は雑誌の編集の仕事をしています。",
    "exReading": "わたし は ざっし の へんしゅう の しごと を して います",
    "exTranslation": "I work as a magazine editor.",
    "category": "Verbal Noun"
  },
  {
    "term": "追放",
    "termReading": "ついほう",
    "termTranslation": "banishment, deportation",
    "ex": "彼は国外に追放されました。",
    "exReading": "かれ は こくがい に ついほう されました",
    "exTranslation": "He was deported.",
    "category": "Verbal Noun"
  },
  {
    "term": "放射能",
    "termReading": "ほうしゃのう",
    "termTranslation": "radioactivity",
    "ex": "この地区は放射能に汚染されたんだ。",
    "exReading": "この ちく は ほうしゃのう に おせん された ん だ",
    "exTranslation": "This area has been contaminated by radioactivity.",
    "category": "Noun"
  },
  {
    "term": "放す",
    "termReading": "はなす",
    "termTranslation": "release, free",
    "ex": "公園で犬を放したの。",
    "exReading": "こうえん で いぬ を はなした の",
    "exTranslation": "I let my dog run freely in the park.",
    "category": "Verb"
  },
  {
    "term": "ぞんざい",
    "termReading": "ぞんざい",
    "termTranslation": "rough, rude",
    "ex": "そんなぞんざいな口のききかたはよくないよ。",
    "exReading": "そんな ぞんざい な くち の ききかた は よくない よ",
    "exTranslation": "That kind of sloppy way of talking is no good.",
    "category": "Adjective"
  },
  {
    "term": "放る",
    "termReading": "ほうる",
    "termTranslation": "fling, hurl",
    "ex": "ボールを空中に放ったの。",
    "exReading": "ぼーる を くうちゅう に ほうった の",
    "exTranslation": "I threw the ball in the air.",
    "category": "Verb"
  },
  {
    "term": "無視",
    "termReading": "むし",
    "termTranslation": "ignoring, disregarding",
    "ex": "彼の意見は無視されたよ。",
    "exReading": "かれ の いけん は むし された よ",
    "exTranslation": "His opinion was disregarded.",
    "category": "Verbal Noun"
  },
  {
    "term": "服装",
    "termReading": "ふくそう",
    "termTranslation": "clothes, garments",
    "ex": "そのパーティーはカジュアルな服装で大丈夫です。",
    "exReading": "その ぱーてぃー は かじゅある な ふくそう で だいじょうぶ です",
    "exTranslation": "It's alright to wear casual clothes to the party.",
    "category": "Noun"
  },
  {
    "term": "振り仮名",
    "termReading": "ふりがな",
    "termTranslation": "reading aid in kana characters",
    "ex": "名前に振り仮名をつけてください。",
    "exReading": "なまえ に ふりがな を つけて ください",
    "exTranslation": "Please indicate the pronunciation of your name with kana.",
    "category": "Noun"
  },
  {
    "term": "銅",
    "termReading": "どう",
    "termTranslation": "copper",
    "ex": "10円玉は銅でできています。",
    "exReading": "10 えんだま は どう で できて います",
    "exTranslation": "The ten yen coin is made of copper.",
    "category": "Noun"
  },
  {
    "term": "たんす",
    "termReading": "たんす",
    "termTranslation": "chest of drawers, wardrobe",
    "ex": "昨日、たんすを買いました。",
    "exReading": "きのう たんす を かいました",
    "exTranslation": "I bought a chest of drawers yesterday.",
    "category": "Noun"
  },
  {
    "term": "悲劇",
    "termReading": "ひげき",
    "termTranslation": "tragedy, tragic drama",
    "ex": "あの悲劇を繰り返してはいけない。",
    "exReading": "あの ひげき を くりかえして は いけない",
    "exTranslation": "You must not repeat that tragedy.",
    "category": "Noun"
  },
  {
    "term": "団体",
    "termReading": "だんたい",
    "termTranslation": "group, corporation",
    "ex": "サッカーは団体競技です。",
    "exReading": "さっかー は だんたい きょうぎ です",
    "exTranslation": "Soccer is a team event.",
    "category": "Noun"
  },
  {
    "term": "団地",
    "termReading": "だんち",
    "termTranslation": "housing estate, housing complex",
    "ex": "私の弟は団地に住んでいます。",
    "exReading": "わたし の おとうと は だんち に すんで います",
    "exTranslation": "My little brother lives in a housing complex.",
    "category": "Noun"
  },
  {
    "term": "美術",
    "termReading": "びじゅつ",
    "termTranslation": "art, fine arts",
    "ex": "弟は美術を専攻しています。",
    "exReading": "おとうと は びじゅつ を せんこう して います",
    "exTranslation": "My little brother majors in fine art.",
    "category": "Noun"
  },
  {
    "term": "博士",
    "termReading": "はくし",
    "termTranslation": "Ph.D.",
    "ex": "彼は数学の博士だそうです。",
    "exReading": "かれ は すうがく の はくし だ そう です",
    "exTranslation": "I hear he has a Ph.D in math.",
    "category": "Noun"
  },
  {
    "term": "博士",
    "termReading": "はかせ",
    "termTranslation": "expert, doctor",
    "ex": "彼は物理学の博士です。",
    "exReading": "かれ は ぶつりがく の はかせ です",
    "exTranslation": "He's a doctor of physics.",
    "category": "Noun"
  },
  {
    "term": "チェンジ",
    "termReading": "チェンジ",
    "termTranslation": "change, exchange",
    "ex": "坂道でギアーをチェンジしたよ。",
    "exReading": "さかみち で ぎあー を ちぇんじ した よ",
    "exTranslation": "I changed gear as the road sloped.",
    "category": "Verbal Noun"
  },
  {
    "term": "展開",
    "termReading": "てんかい",
    "termTranslation": "unfolding, development",
    "ex": "話の展開についていけない。",
    "exReading": "はなし の てんかい に ついていけない",
    "exTranslation": "I can't follow the thread of the conversation.",
    "category": "Verbal Noun"
  },
  {
    "term": "発展",
    "termReading": "はってん",
    "termTranslation": "expansion, prosperity",
    "ex": "あの会社は目覚しく発展しているね。",
    "exReading": "あの かいしゃ は めざましく はってん して いる ね",
    "exTranslation": "That company has expanded remarkably.",
    "category": "Verbal Noun"
  },
  {
    "term": "催す",
    "termReading": "もよおす",
    "termTranslation": "feel, experience",
    "ex": "静かな音楽で眠気を催したよ。",
    "exReading": "しずか な おんがく で ねむけ を もよおした よ",
    "exTranslation": "I felt sleepy due to the quiet music.",
    "category": "Verb"
  },
  {
    "term": "催し",
    "termReading": "もよおし",
    "termTranslation": "event, function",
    "ex": "学校の催しに参加しました。",
    "exReading": "がっこう の もよおし に さんか しました",
    "exTranslation": "I took part in the event at school.",
    "category": "Noun"
  },
  {
    "term": "典型的",
    "termReading": "てんけいてき",
    "termTranslation": "typical, representative",
    "ex": "彼女は典型的なイタリア人です。",
    "exReading": "かのじょ は てんけいてき な いたりあじん です",
    "exTranslation": "She's a typical Italian.",
    "category": "Adjective"
  },
  {
    "term": "つかまる",
    "termReading": "つかまる",
    "termTranslation": "grip, grab",
    "ex": "しっかりとつかまっていてください。",
    "exReading": "しっかりとつかまっていてください。",
    "exTranslation": "Now hold on tight.",
    "category": "Verb"
  },
  {
    "term": "典型",
    "termReading": "てんけい",
    "termTranslation": "type, model",
    "ex": "彼は職人の典型です。",
    "exReading": "かれ は しょくにん の てんけい です",
    "exTranslation": "He's a typical artisan.",
    "category": "Noun"
  },
  {
    "term": "百科事典",
    "termReading": "ひゃっかじてん",
    "termTranslation": "encyclopedia",
    "ex": "めずらしい猫について百科事典で調べたの。",
    "exReading": "めずらしい ねこ に ついて ひゃっかじてん で しらべた の",
    "exTranslation": "I looked up a rare cat in the encyclopedia.",
    "category": "Noun"
  },
  {
    "term": "特殊",
    "termReading": "とくしゅ",
    "termTranslation": "special, unique",
    "ex": "彼は特殊な能力を持っています。",
    "exReading": "かれ は とくしゅ な のうりょく を もって います",
    "exTranslation": "He has a special ability.",
    "category": "Adjective"
  },
  {
    "term": "微妙",
    "termReading": "びみょう",
    "termTranslation": "subtle, delicate",
    "ex": "彼は会社で微妙な立場にあります。",
    "exReading": "かれ は かいしゃ で びみょう な たちば に あります",
    "exTranslation": "He's in a delicate position in the company.",
    "category": "Adjectival Noun"
  },
  {
    "term": "免税",
    "termReading": "めんぜい",
    "termTranslation": "tax exemption",
    "ex": "この商品は免税です。",
    "exReading": "この しょうひん は めんぜい です",
    "exTranslation": "This product is tax-exempt.",
    "category": "Verbal Noun"
  },
  {
    "term": "つながり",
    "termReading": "つながり",
    "termTranslation": "connection, relation",
    "ex": "この文のつながりはおかしいです。",
    "exReading": "この ぶん の つながり は おかしい です",
    "exTranslation": "The flow of this sentence is strange.",
    "category": "Noun"
  },
  {
    "term": "免許",
    "termReading": "めんきょ",
    "termTranslation": "license, permit",
    "ex": "やっと運転免許を手に入れたよ。",
    "exReading": "やっと うんてん めんきょ を て に いれた よ",
    "exTranslation": "I finally got my driver's license.",
    "category": "Noun"
  },
  {
    "term": "動詞",
    "termReading": "どうし",
    "termTranslation": "verb",
    "ex": "「食べる」は動詞です。",
    "exReading": "たべる は どうし です",
    "exTranslation": "Eat is a verb.",
    "category": "Noun"
  },
  {
    "term": "名詞",
    "termReading": "めいし",
    "termTranslation": "noun",
    "ex": "「学校」は名詞です。",
    "exReading": "がっこう は めいし です",
    "exTranslation": "School is a noun.",
    "category": "Noun"
  },
  {
    "term": "副詞",
    "termReading": "ふくし",
    "termTranslation": "adverb",
    "ex": "「ゆっくり歩く」の「ゆっくり」は副詞です。",
    "exReading": "ゆっくり あるく の ゆっくり は ふくし です",
    "exTranslation": "Slowly in \"Walk slowly\" is an adverb.",
    "category": "Noun"
  },
  {
    "term": "代名詞",
    "termReading": "だいめいし",
    "termTranslation": "pronoun",
    "ex": "「彼」は代名詞の一つです。",
    "exReading": "かれ は だいめいし の ひとつ です",
    "exTranslation": "He is a pronoun.",
    "category": "Noun"
  },
  {
    "term": "つながる",
    "termReading": "つながる",
    "termTranslation": "connect, join",
    "ex": "電話がつながりません。",
    "exReading": "でんわ が つながりません",
    "exTranslation": "Phone calls aren't getting through.",
    "category": "Verb"
  },
  {
    "term": "伝統",
    "termReading": "でんとう",
    "termTranslation": "tradition, convention",
    "ex": "私は日本の伝統を大切にします。",
    "exReading": "わたし は にっぽん の でんとう を たいせつ に します",
    "exTranslation": "I value the traditions of Japan.",
    "category": "Noun"
  },
  {
    "term": "伝わる",
    "termReading": "つたわる",
    "termTranslation": "be transmitted, be handed down",
    "ex": "漢字は中国から伝わった。",
    "exReading": "かんじ は ちゅうごく から つたわった",
    "exTranslation": "Chinese characters were transmitted from China.",
    "category": "Verb"
  },
  {
    "term": "伝説",
    "termReading": "でんせつ",
    "termTranslation": "legend, folk tale",
    "ex": "彼は数々の伝説を残しました。",
    "exReading": "かれ は かずかず の でんせつ を のこしました",
    "exTranslation": "He left behind a lot of legends.",
    "category": "Noun"
  },
  {
    "term": "手伝い",
    "termReading": "てつだい",
    "termTranslation": "help, assistant",
    "ex": "会議の準備に手伝いが必要です。",
    "exReading": "かいぎ の じゅんび に てつだい が ひつよう です",
    "exTranslation": "We need help with preparations for the meeting.",
    "category": "Noun"
  },
  {
    "term": "伝言",
    "termReading": "でんごん",
    "termTranslation": "verbal message",
    "ex": "課長から伝言があります。",
    "exReading": "かちょう から でんごん が あります",
    "exTranslation": "There's a message from the section chief.",
    "category": "Verbal Noun"
  },
  {
    "term": "伝染",
    "termReading": "でんせん",
    "termTranslation": "contagion, infection",
    "ex": "その国ではコレラの伝染が蔓延しています。",
    "exReading": "その くに で は これら の でんせん が まんえん して います",
    "exTranslation": "There's a spreading cholera epidemic in that country.",
    "category": "Verbal Noun"
  },
  {
    "term": "つばめ",
    "termReading": "つばめ",
    "termTranslation": "swallow",
    "ex": "つばめが飛んでいますね。",
    "exReading": "つばめ が とんで います ね",
    "exTranslation": "The swallows are flying.",
    "category": "Noun"
  },
  {
    "term": "焼き鳥",
    "termReading": "やきとり",
    "termTranslation": "yakitori, grilled chicken",
    "ex": "焼き鳥はビールに合うな。",
    "exReading": "やきとり は びーる に あう な",
    "exTranslation": "Yakitori goes well with beer.",
    "category": "Noun"
  },
  {
    "term": "鳴らす",
    "termReading": "ならす",
    "termTranslation": "ring, sound",
    "ex": "お坊さんが鐘を鳴らしていますね。",
    "exReading": "おぼうさん が かね を ならして います ね",
    "exTranslation": "The priest is ringing the gong.",
    "category": "Verb"
  },
  {
    "term": "悲鳴",
    "termReading": "ひめい",
    "termTranslation": "shriek, scream",
    "ex": "外から悲鳴が聞こえたな。",
    "exReading": "そと から ひめい が きこえた な",
    "exTranslation": "A scream was heard outside.",
    "category": "Noun"
  },
  {
    "term": "笑い声",
    "termReading": "わらいごえ",
    "termTranslation": "laughter",
    "ex": "部屋の中から笑い声が聞こえた。",
    "exReading": "へや の なか から わらいごえ が きこえた",
    "exTranslation": "I heard laughter coming from the room.",
    "category": "Noun"
  },
  {
    "term": "泣き声",
    "termReading": "なきごえ",
    "termTranslation": "tearful voice, crying",
    "ex": "赤ちゃんの泣き声が聞こえますね。",
    "exReading": "あかちゃん の なきごえ が きこえます ね",
    "exTranslation": "I can hear a baby crying.",
    "category": "Noun"
  },
  {
    "term": "つぶやく",
    "termReading": "つぶやく",
    "termTranslation": "mutter, murmur",
    "ex": "彼は一人で何かつぶやいていたの。",
    "exReading": "かれ は ひとり で なにか つぶやいて いた の",
    "exTranslation": "He was muttering something to himself.",
    "category": "Verb"
  },
  {
    "term": "話し声",
    "termReading": "はなしごえ",
    "termTranslation": "voice, whisper",
    "ex": "隣の部屋から話し声が聞こえます。",
    "exReading": "となり の へや から はなしごえ が きこえます",
    "exTranslation": "I can hear voices coming from the room next door.",
    "category": "Noun"
  },
  {
    "term": "駐車",
    "termReading": "ちゅうしゃ",
    "termTranslation": "parking",
    "ex": "車はここに駐車してください。",
    "exReading": "くるま は ここ に ちゅうしゃ して ください",
    "exTranslation": "Please park the car here.",
    "category": "Verbal Noun"
  },
  {
    "term": "騒音",
    "termReading": "そうおん",
    "termTranslation": "noise",
    "ex": "窓から車の騒音が入って来ます。",
    "exReading": "まど から くるま の そうおん が はいって きます",
    "exTranslation": "The noise of the cars is coming through the window.",
    "category": "Noun"
  },
  {
    "term": "騒動",
    "termReading": "そうどう",
    "termTranslation": "disturbance, confusion",
    "ex": "警察がやって来て騒動を静めたんだ。",
    "exReading": "けいさつ が やってきて そうどう を しずめた ん だ",
    "exTranslation": "The police came and calmed the riot.",
    "category": "Noun"
  },
  {
    "term": "騒々しい",
    "termReading": "そうぞうしい",
    "termTranslation": "noisy, rowdy",
    "ex": "隣の家はいつも騒々しい。",
    "exReading": "となり の いえ は いつも そうぞうしい",
    "exTranslation": "The house next door is always noisy.",
    "category": "Adjective"
  },
  {
    "term": "つぶる",
    "termReading": "つぶる",
    "termTranslation": "close",
    "ex": "目をつぶってください。",
    "exReading": "め を つぶって ください",
    "exTranslation": "Close your eyes.",
    "category": "Verb"
  },
  {
    "term": "名刺",
    "termReading": "めいし",
    "termTranslation": "business card, calling card",
    "ex": "私たちは名刺を交換しました。",
    "exReading": "わたしたち は めいし を こうかん しました",
    "exTranslation": "We exchanged business cards.",
    "category": "Noun"
  },
  {
    "term": "到着",
    "termReading": "とうちゃく",
    "termTranslation": "arrival",
    "ex": "夜9時に東京に到着しました。",
    "exReading": "よる 9 じ に とうきょう に とうちゃく しました",
    "exTranslation": "I arrived at Tokyo at 9 pm.",
    "category": "Verbal Noun"
  },
  {
    "term": "到達",
    "termReading": "とうたつ",
    "termTranslation": "arrival, attainment",
    "ex": "ついに山頂に到達しました。",
    "exReading": "ついに さんちょう に とうたつ しました",
    "exTranslation": "We finally reached the top of the mountain.",
    "category": "Verbal Noun"
  },
  {
    "term": "倒産",
    "termReading": "とうさん",
    "termTranslation": "insolvency",
    "ex": "会社が倒産しました。",
    "exReading": "かいしゃ が とうさん しました",
    "exTranslation": "The company went bankrupt.",
    "category": "Verbal Noun"
  },
  {
    "term": "面倒",
    "termReading": "めんどう",
    "termTranslation": "troublesome, difficult",
    "ex": "会社で面倒なことが起こったの。",
    "exReading": "かいしゃ で めんどう な こと が おこった の",
    "exTranslation": "There was some trouble at the office.",
    "category": "Adjectival Noun"
  },
  {
    "term": "斜め",
    "termReading": "ななめ",
    "termTranslation": "diagonal, slant",
    "ex": "ここに斜めに線を引いてください。",
    "exReading": "ここ に ななめ に せん を ひいて ください",
    "exTranslation": "Please draw a line here diagonally.",
    "category": "Adjectival Noun"
  },
  {
    "term": "つぼみ",
    "termReading": "つぼみ",
    "termTranslation": "bud",
    "ex": "花のつぼみがたくさんついていますね。",
    "exReading": "はな の つぼみ が たくさん ついています ね 。",
    "exTranslation": "There are a lot of flower buds on it.",
    "category": "Noun"
  },
  {
    "term": "柔らかい",
    "termReading": "やわらかい",
    "termTranslation": "soft, gentle",
    "ex": "柔らかい日差しが気持ちいいね。",
    "exReading": "やわらかい ひざし が きもち いい ね",
    "exTranslation": "Soft sunlight feels pleasant.",
    "category": "Adjective"
  },
  {
    "term": "張る",
    "termReading": "はる",
    "termTranslation": "strain, be stretched",
    "ex": "疲れて肩が張っています。",
    "exReading": "つかれて かた が はって います",
    "exTranslation": "My shoulders are stiff with fatigue.",
    "category": "Verb"
  },
  {
    "term": "張り切る",
    "termReading": "はりきる",
    "termTranslation": "go all out, make efforts",
    "ex": "母は張り切ってお弁当を用意したの。",
    "exReading": "はは は はりきって おべんとう を ようい した の",
    "exTranslation": "My mother went all out when she made this lunch box.",
    "category": "Verb"
  },
  {
    "term": "欲張り",
    "termReading": "よくばり",
    "termTranslation": "greed, greedy",
    "ex": "彼女は欲張りです。",
    "exReading": "かのじょ は よくばり です",
    "exTranslation": "She's greedy.",
    "category": "Adjectival Noun"
  },
  {
    "term": "突然",
    "termReading": "とつぜん",
    "termTranslation": "abruptly, suddenly",
    "ex": "彼は突然走り出したのよ。",
    "exReading": "かれ は とつぜん はしりだした の よ",
    "exTranslation": "He suddenly began to run.",
    "category": "Adverb"
  },
  {
    "term": "つまずく",
    "termReading": "つまずく",
    "termTranslation": "stumble, trip",
    "ex": "石につまずきました。",
    "exReading": "いし に つまずきました 。",
    "exTranslation": "I stumbled on the stone.",
    "category": "Verb"
  },
  {
    "term": "突っ込む",
    "termReading": "つっこむ",
    "termTranslation": "thrust in, dash into",
    "ex": "ポケットに財布を突っ込んじゃった。",
    "exReading": "ぽけっと に さいふ を つっこん じゃった",
    "exTranslation": "I thrust my wallet into my pocket.",
    "category": "Verb"
  },
  {
    "term": "突く",
    "termReading": "つく",
    "termTranslation": "thrust, poke",
    "ex": "彼はビリヤードの球を上手に突くね。",
    "exReading": "かれ は びりやーど の たま を じょうず に つく ね",
    "exTranslation": "He shoots pool very well.",
    "category": "Verb"
  },
  {
    "term": "追突",
    "termReading": "ついとつ",
    "termTranslation": "collision",
    "ex": "バスが乗用車に追突したよ。",
    "exReading": "ばす が じょうようしゃ に ついとつ した よ",
    "exTranslation": "The bus bumped the passenger car from behind.",
    "category": "Verbal Noun"
  },
  {
    "term": "触れる",
    "termReading": "ふれる",
    "termTranslation": "touch, come in contact with",
    "ex": "手と手が触れてどきどきしたよ。",
    "exReading": "て と て が ふれて どきどき した よ",
    "exTranslation": "My heart pounded as our hands touched.",
    "category": "Verb"
  },
  {
    "term": "避難",
    "termReading": "ひなん",
    "termTranslation": "refuge, shelter",
    "ex": "危ないので避難してください。",
    "exReading": "あぶない の で ひなん して ください",
    "exTranslation": "It's dangerous here, so please evacuate!",
    "category": "Verbal Noun"
  },
  {
    "term": "ツル",
    "termReading": "ツル",
    "termTranslation": "crane, stork",
    "ex": "湖にツルがいますよ。",
    "exReading": "みずうみ に つる が います よ",
    "exTranslation": "There's a crane on the lake.",
    "category": "Noun"
  },
  {
    "term": "墜落",
    "termReading": "ついらく",
    "termTranslation": "fall, crash",
    "ex": "飛行機の墜落事故があったんだ。",
    "exReading": "ひこうき の ついらく じこ が あった ん だ",
    "exTranslation": "There was an airplane crash.",
    "category": "Verbal Noun"
  },
  {
    "term": "兵士",
    "termReading": "へいし",
    "termTranslation": "soldier",
    "ex": "その爆撃で兵士が3人負傷しました。",
    "exReading": "その ばくげき で へいし が さんにん ふしょう しました",
    "exTranslation": "Three soldiers were injured in the bombing.",
    "category": "Noun"
  },
  {
    "term": "兵器",
    "termReading": "へいき",
    "termTranslation": "arms, weapon",
    "ex": "あの国は強力な兵器を持っている。",
    "exReading": "あの くに は きょうりょく な へいき を もって いる",
    "exTranslation": "That country has strong weapons.",
    "category": "Noun"
  },
  {
    "term": "兵隊",
    "termReading": "へいたい",
    "termTranslation": "soldier, troops",
    "ex": "兵隊が銃をかまえていたよ。",
    "exReading": "へいたい が じゅう を かまえて いた よ",
    "exTranslation": "The soldier was holding the gun at the ready.",
    "category": "Noun"
  },
  {
    "term": "武器",
    "termReading": "ぶき",
    "termTranslation": "weapon, arms",
    "ex": "彼らは武器を取り、立ち上がった。",
    "exReading": "かれら は ぶき を とり たちあがった",
    "exTranslation": "They took arms, and stood up.",
    "category": "Noun"
  },
  {
    "term": "武士",
    "termReading": "ぶし",
    "termTranslation": "samurai, warrior",
    "ex": "彼の家柄は武士でした。",
    "exReading": "かれ の いえがら は ぶし でした",
    "exTranslation": "He's from a samurai family.",
    "category": "Noun"
  },
  {
    "term": "でこぼこ",
    "termReading": "でこぼこ",
    "termTranslation": "unevenness, bumpiness",
    "ex": "この道はでこぼこしていますね。",
    "exReading": "この みち は でこぼこ して います ね",
    "exTranslation": "This road is bumpy.",
    "category": "Verbal Noun"
  },
  {
    "term": "弾",
    "termReading": "たま",
    "termTranslation": "bullet, shot",
    "ex": "彼はピストルに弾を込めたんだ。",
    "exReading": "かれ は ぴすとる に たま を こめた ん だ",
    "exTranslation": "He loaded the pistol.",
    "category": "Noun"
  },
  {
    "term": "弾む",
    "termReading": "はずむ",
    "termTranslation": "spring back, bounce",
    "ex": "このボールはよく弾みますね。",
    "exReading": "この ぼーる は よく はずみます ね",
    "exTranslation": "This ball bounces well.",
    "category": "Verb"
  },
  {
    "term": "日の丸",
    "termReading": "ひのまる",
    "termTranslation": "Rising Sun Flag, Japanese flag",
    "ex": "日本の国旗は日の丸と呼ばれています。",
    "exReading": "にっぽん の こっき は ひのまる と よばれて います",
    "exTranslation": "Japan's national flag is known as the rising-sun flag.",
    "category": "Noun"
  },
  {
    "term": "真ん丸",
    "termReading": "まんまる",
    "termTranslation": "perfect circle",
    "ex": "今日は月が真ん丸です。",
    "exReading": "きょう は つき が まんまる です",
    "exTranslation": "The moon is perfectly round today.",
    "category": "Adjectival Noun"
  },
  {
    "term": "爆発",
    "termReading": "ばくはつ",
    "termTranslation": "explosion, eruption",
    "ex": "ダイナマイトが爆発したんだ。",
    "exReading": "だいなまいと が ばくはつ した ん だ",
    "exTranslation": "The dynamite exploded.",
    "category": "Verbal Noun"
  },
  {
    "term": "てのひら",
    "termReading": "てのひら",
    "termTranslation": "palm",
    "ex": "てのひらに汗をかきました。",
    "exReading": "てのひら に あせ を かきました",
    "exTranslation": "My palms are sweating.",
    "category": "Noun"
  },
  {
    "term": "爆弾",
    "termReading": "ばくだん",
    "termTranslation": "bomb",
    "ex": "その町に爆弾が落とされたの。",
    "exReading": "その まち に ばくだん が おとされた の",
    "exTranslation": "Bombs were dropped on that town.",
    "category": "Noun"
  },
  {
    "term": "暴落",
    "termReading": "ぼうらく",
    "termTranslation": "slump, crash",
    "ex": "昨日株価が暴落しました。",
    "exReading": "きのう かぶか が ぼうらく しました",
    "exTranslation": "Stock prices dropped sharply yesterday.",
    "category": "Verbal Noun"
  },
  {
    "term": "暴力",
    "termReading": "ぼうりょく",
    "termTranslation": "violence, force",
    "ex": "暴力はいけません。",
    "exReading": "ぼうりょく は いけません",
    "exTranslation": "Violence is wrong.",
    "category": "Noun"
  },
  {
    "term": "乱暴",
    "termReading": "らんぼう",
    "termTranslation": "violence",
    "ex": "人に乱暴してはいけません。",
    "exReading": "ひと に らんぼう して は いけません",
    "exTranslation": "You must not use violence on people.",
    "category": "Verbal Noun"
  },
  {
    "term": "絶えず",
    "termReading": "たえず",
    "termTranslation": "constantly, always",
    "ex": "今日は絶えず電話が鳴りました。",
    "exReading": "きょう は たえず でんわ が なりました",
    "exTranslation": "The telephone didn't stop ringing today.",
    "category": "Adverb"
  },
  {
    "term": "とがる",
    "termReading": "とがる",
    "termTranslation": "be sharp",
    "ex": "この鉛筆はとがっていますね。",
    "exReading": "この えんぴつ は とがって います ね",
    "exTranslation": "This pencil is sharp.",
    "category": "Verb"
  },
  {
    "term": "滅びる",
    "termReading": "ほろびる",
    "termTranslation": "go to ruin, fall",
    "ex": "その文明は何千年も前に滅びてしまったの。",
    "exReading": "その ぶんめい は なんぜんねん も まえ に ほろびて しまった の",
    "exTranslation": "That civilization was destroyed thousands of years ago.",
    "category": "Verb"
  },
  {
    "term": "防ぐ",
    "termReading": "ふせぐ",
    "termTranslation": "prevent, defend",
    "ex": "怪我を防ぐためによくストレッチをしてください。",
    "exReading": "けが を ふせぐ ため に よく すとれっち を して ください",
    "exTranslation": "Please stretch well to prevent injuries.",
    "category": "Verb"
  },
  {
    "term": "防衛",
    "termReading": "ぼうえい",
    "termTranslation": "defense, protection",
    "ex": "チャンピオンがタイトルを防衛した。",
    "exReading": "ちゃんぴおん が たいとる を ぼうえい した",
    "exTranslation": "The champion defended the title.",
    "category": "Verbal Noun"
  },
  {
    "term": "防止",
    "termReading": "ぼうし",
    "termTranslation": "prevention, determent",
    "ex": "警察は犯罪の防止に努めています。",
    "exReading": "けいさつ は はんざい の ぼうし に つとめて います",
    "exTranslation": "The police are making efforts to prevent crime.",
    "category": "Verbal Noun"
  },
  {
    "term": "予防",
    "termReading": "よぼう",
    "termTranslation": "prevention, protection",
    "ex": "虫歯は予防できます。",
    "exReading": "むしば は よぼう できます",
    "exTranslation": "Tooth decay can be prevented.",
    "category": "Verbal Noun"
  },
  {
    "term": "とんかつ",
    "termReading": "とんかつ",
    "termTranslation": "pork cutlet",
    "ex": "昼食にとんかつを食べました。",
    "exReading": "ちゅうしょく に とん かつ を たべました 。",
    "exTranslation": "I ate a pork cutlet for lunch.",
    "category": "Noun"
  },
  {
    "term": "防火",
    "termReading": "ぼうか",
    "termTranslation": "fire prevention, fireproof",
    "ex": "学校で防火訓練がありました。",
    "exReading": "がっこう で ぼうかくんれん が ありました",
    "exTranslation": "There was a fire drill at school.",
    "category": "Noun"
  },
  {
    "term": "妨害",
    "termReading": "ぼうがい",
    "termTranslation": "hindrance, obstruction",
    "ex": "彼に営業を妨害されました。",
    "exReading": "かれ に えいぎょう を ぼうがい されました",
    "exTranslation": "My business operations were thwarted by him.",
    "category": "Verbal Noun"
  },
  {
    "term": "不機嫌",
    "termReading": "ふきげん",
    "termTranslation": "bad mood, displeasure",
    "ex": "この頃、あの人は不機嫌ですね。",
    "exReading": "このごろ あの ひと は ふきげん です ね",
    "exTranslation": "He's been in a bad mood lately.",
    "category": "Adjectival Noun"
  },
  {
    "term": "徹夜",
    "termReading": "てつや",
    "termTranslation": "staying up all night",
    "ex": "ゆうべは徹夜しました。",
    "exReading": "ゆうべ は てつや しました",
    "exTranslation": "I stayed up all last night.",
    "category": "Noun"
  },
  {
    "term": "底",
    "termReading": "そこ",
    "termTranslation": "bottom, depths",
    "ex": "コップの底が濡れていますよ。",
    "exReading": "こっぷ の そこ が ぬれて います よ",
    "exTranslation": "The bottom of the glass is wet.",
    "category": "Noun"
  },
  {
    "term": "徹底的",
    "termReading": "てっていてき",
    "termTranslation": "thorough, exhaustive",
    "ex": "部屋の中を徹底的に探しました。",
    "exReading": "へや の なか を てっていてき に さがしました",
    "exTranslation": "I searched inside the room thoroughly.",
    "category": "Adjective"
  },
  {
    "term": "どんなに",
    "termReading": "どんなに",
    "termTranslation": "how much",
    "ex": "彼女はどんなに嬉しかったろう。",
    "exReading": "かのじょ は どんなに うれしかったろう",
    "exTranslation": "How happy she must have been.",
    "category": "Adverb"
  },
  {
    "term": "到底",
    "termReading": "とうてい",
    "termTranslation": "after all, in the long run",
    "ex": "今夜中に東京に着くのは到底無理でしょう。",
    "exReading": "こんやじゅう に とうきょう に つく の は とうてい むり でしょう",
    "exTranslation": "We can't possibly arrive in Tokyo tonight.",
    "category": "Adverb"
  },
  {
    "term": "抵抗",
    "termReading": "ていこう",
    "termTranslation": "resistance, opposition",
    "ex": "犯人は警察に抵抗したの。",
    "exReading": "はんにん は けいさつ に ていこう した の",
    "exTranslation": "The criminal resisted the police.",
    "category": "Verbal Noun"
  },
  {
    "term": "敵",
    "termReading": "てき",
    "termTranslation": "enemy, foe",
    "ex": "あそこに敵がひそんでいる。",
    "exReading": "あそこ に てき が ひそんで いる",
    "exTranslation": "The enemy is lurking there.",
    "category": "Noun"
  },
  {
    "term": "態勢",
    "termReading": "たいせい",
    "termTranslation": "state, preparedness",
    "ex": "作業を始める態勢は整っています。",
    "exReading": "さぎょう を はじめる たいせい は ととのって います",
    "exTranslation": "The preparations for starting work are completed.",
    "category": "Noun"
  },
  {
    "term": "体系",
    "termReading": "たいけい",
    "termTranslation": "system, organization",
    "ex": "勤務の体系を見直すことにしました。",
    "exReading": "きんむ の たいけい を みなおす こと に しました",
    "exTranslation": "We've decided to review the work system.",
    "category": "Noun"
  },
  {
    "term": "なめる",
    "termReading": "なめる",
    "termTranslation": "lick, lap",
    "ex": "犬に顔をなめられました。",
    "exReading": "いぬ に かお を なめられました",
    "exTranslation": "The dog licked my face.",
    "category": "Verb"
  },
  {
    "term": "理系",
    "termReading": "りけい",
    "termTranslation": "science",
    "ex": "彼は理系の学生です。",
    "exReading": "かれ は りけい の がくせい です",
    "exTranslation": "He's a science student.",
    "category": "Noun"
  },
  {
    "term": "文系",
    "termReading": "ぶんけい",
    "termTranslation": "liberal arts, humanities",
    "ex": "彼女は文系です。",
    "exReading": "かのじょ は ぶんけい です",
    "exTranslation": "She's in a humanities program.",
    "category": "Noun"
  },
  {
    "term": "綿",
    "termReading": "めん",
    "termTranslation": "cotton",
    "ex": "私は綿のシャツをよく着ます。",
    "exReading": "わたし は めん の しゃつ を よく きます",
    "exTranslation": "I often wear cotton shirts.",
    "category": "Noun"
  },
  {
    "term": "繁栄",
    "termReading": "はんえい",
    "termTranslation": "prosperity, thriving",
    "ex": "あの国は経済的に繁栄しているわね。",
    "exReading": "あの くに は けいざいてき に はんえい して いる わ ね",
    "exTranslation": "That country is prospering economically.",
    "category": "Verbal Noun"
  },
  {
    "term": "日韓",
    "termReading": "にっかん",
    "termTranslation": "Japan and Korea",
    "ex": "日韓合同のコンサートが開かれたよ。",
    "exReading": "にっかん ごうどう の こんさーと が ひらかれた よ",
    "exTranslation": "There was a joint concert with Japan and South Korea.",
    "category": "Noun"
  },
  {
    "term": "にらむ",
    "termReading": "にらむ",
    "termTranslation": "glare, stare",
    "ex": "彼女は私をにらんだの。",
    "exReading": "かのじょ は わたし を にらんだ の",
    "exTranslation": "She glared at me.",
    "category": "Verb"
  },
  {
    "term": "耐える",
    "termReading": "たえる",
    "termTranslation": "withstand, endure",
    "ex": "このビルは大地震に耐えられるでしょうか。",
    "exReading": "この びる は おおじしん に たえられる でしょう か",
    "exTranslation": "Would this building be able to withstand a large earthquake?",
    "category": "Verb"
  },
  {
    "term": "矢印",
    "termReading": "やじるし",
    "termTranslation": "arrow",
    "ex": "矢印にそって歩いてください。",
    "exReading": "やじるし に そって あるいて ください",
    "exTranslation": "Please follow the arrow.",
    "category": "Noun"
  },
  {
    "term": "爪切り",
    "termReading": "つめきり",
    "termTranslation": "nail clipper",
    "ex": "爪切りを取ってください。",
    "exReading": "つめきり を とって ください",
    "exTranslation": "Please pass me the nail clipper.",
    "category": "Noun"
  },
  {
    "term": "枠",
    "termReading": "わく",
    "termTranslation": "frame, limits",
    "ex": "枠の中に答えを書いてください。",
    "exReading": "わく の なか に こたえ を かいて ください",
    "exTranslation": "Please write the answer in the box.",
    "category": "Noun"
  },
  {
    "term": "棒",
    "termReading": "ぼう",
    "termTranslation": "rod, stick",
    "ex": "この棒は何に使うのですか。",
    "exReading": "この ぼう は なに に つかう の です か",
    "exTranslation": "What do you use this stick for?",
    "category": "Noun"
  },
  {
    "term": "人柄",
    "termReading": "ひとがら",
    "termTranslation": "character, personality",
    "ex": "みんな彼の温かい人柄が大好きだよ。",
    "exReading": "みんな かれ の あたたかい ひとがら が だいすき だ よ",
    "exTranslation": "Everyone loves his warm personality.",
    "category": "Noun"
  },
  {
    "term": "ねずみ",
    "termReading": "ねずみ",
    "termTranslation": "rat, mouse",
    "ex": "台所にねずみが出た。",
    "exReading": "だいどころ に ねずみ が でた",
    "exTranslation": "A mouse came out in the kitchen.",
    "category": "Noun"
  },
  {
    "term": "詰める",
    "termReading": "つめる",
    "termTranslation": "stuff, fill",
    "ex": "かばんに荷物を詰めました。",
    "exReading": "かばん に にもつ を つめました",
    "exTranslation": "I packed my things into the bag.",
    "category": "Verb"
  },
  {
    "term": "詰まる",
    "termReading": "つまる",
    "termTranslation": "be stuffed, be stopped up",
    "ex": "プリンタのインクが詰まってしまいました。",
    "exReading": "ぷりんた の いんく が つまって しまいました",
    "exTranslation": "The printer's ink got blocked up.",
    "category": "Verb"
  },
  {
    "term": "譲る",
    "termReading": "ゆずる",
    "termTranslation": "transfer, concede",
    "ex": "上司が車を譲ってくれたの。",
    "exReading": "じょうし が くるま を ゆずって くれた の",
    "exTranslation": "My boss gave me his car.",
    "category": "Verb"
  },
  {
    "term": "誠に",
    "termReading": "まことに",
    "termTranslation": "truly, very",
    "ex": "誠にありがとうございます。",
    "exReading": "まことに ありがとう ございます",
    "exTranslation": "Thank you very much indeed.",
    "category": "Adverb"
  },
  {
    "term": "盛り上げる",
    "termReading": "もりあげる",
    "termTranslation": "build up excitement",
    "ex": "応援団が試合を盛り上げたんだ。",
    "exReading": "おうえんだん が しあい を もりあげた ん だ",
    "exTranslation": "The cheering squad boosted the excitement of the game.",
    "category": "Verb"
  },
  {
    "term": "のぞく",
    "termReading": "のぞく",
    "termTranslation": "look, peep",
    "ex": "ドアの隙間から中をのぞいたんだ。",
    "exReading": "どあ の すきま から なか を のぞいた ん だ",
    "exTranslation": "I peeped inside through the crack in the door.",
    "category": "Verb"
  },
  {
    "term": "盛り上がる",
    "termReading": "もりあがる",
    "termTranslation": "become lively, get fired up",
    "ex": "昨夜のパーティはとても盛り上がったな。",
    "exReading": "さくや の ぱーてぃ は とても もりあがった な",
    "exTranslation": "Last night's party was very lively.",
    "category": "Verb"
  },
  {
    "term": "盛る",
    "termReading": "もる",
    "termTranslation": "heap up, fill",
    "ex": "ご飯を盛ってください。",
    "exReading": "ごはん を もって ください",
    "exTranslation": "Please fill the bowl with rice.",
    "category": "Verb"
  },
  {
    "term": "針",
    "termReading": "はり",
    "termTranslation": "needle, needle-shaped object",
    "ex": "母は糸を針に通した。",
    "exReading": "はは は いと を はり に とおした",
    "exTranslation": "My mother threaded the needle.",
    "category": "Noun"
  },
  {
    "term": "釣り",
    "termReading": "つり",
    "termTranslation": "angling",
    "ex": "父は釣りが大好きです。",
    "exReading": "ちち は つり が だいすき です",
    "exTranslation": "My father loves fishing.",
    "category": "Noun"
  },
  {
    "term": "釣り合い",
    "termReading": "つりあい",
    "termTranslation": "balance, proportion",
    "ex": "この紙飛行機は左右の釣り合いがとれていないな。",
    "exReading": "この かみひこうき は さゆう の つりあい が とれて いない な",
    "exTranslation": "The right-left balance on this paper airplane isn't right.",
    "category": "Noun"
  },
  {
    "term": "のんき",
    "termReading": "のんき",
    "termTranslation": "carefree, easy-going",
    "ex": "彼はずいぶんのんきなやつだ。",
    "exReading": "かれ は ずいぶん のんき な やつ だ",
    "exTranslation": "He's a fairly easygoing guy.",
    "category": "Adjectival Noun"
  },
  {
    "term": "釣り合う",
    "termReading": "つりあう",
    "termTranslation": "balance, match",
    "ex": "収入と支出が釣り合っていない。",
    "exReading": "しゅうにゅう と ししゅつ が つりあって いない",
    "exTranslation": "The income and expenses don't balance.",
    "category": "Verb"
  },
  {
    "term": "鈍い",
    "termReading": "にぶい",
    "termTranslation": "dull, sluggish",
    "ex": "今日は頭の回転が鈍いです。",
    "exReading": "きょう は あたま の かいてん が にぶい です",
    "exTranslation": "My brain is working slowly today.",
    "category": "Adjective"
  },
  {
    "term": "鈍感",
    "termReading": "どんかん",
    "termTranslation": "thick-headedness",
    "ex": "あの人は少し鈍感だと思います。",
    "exReading": "あの ひと は すこし どんかん だ と おもいます",
    "exTranslation": "That person is a bit thickheaded I think.",
    "category": "Adjectival Noun"
  },
  {
    "term": "谷",
    "termReading": "たに",
    "termTranslation": "valley, ravine",
    "ex": "その村は深い谷にあるの。",
    "exReading": "その むら は ふかい たに に ある の",
    "exTranslation": "The village is in a deep valley.",
    "category": "Noun"
  },
  {
    "term": "余裕",
    "termReading": "よゆう",
    "termTranslation": "room, leeway",
    "ex": "出発まで時間の余裕があります。",
    "exReading": "しゅっぱつ まで じかん の よゆう が あります",
    "exTranslation": "There's plenty of time until departure.",
    "category": "Noun"
  },
  {
    "term": "入浴",
    "termReading": "にゅうよく",
    "termTranslation": "bathing",
    "ex": "私は毎日寝る前に入浴します。",
    "exReading": "わたし は まいにち ねる まえ に にゅうよく します",
    "exTranslation": "I take a bath every day before I go to bed.",
    "category": "Verbal Noun"
  },
  {
    "term": "はえ",
    "termReading": "はえ",
    "termTranslation": "fly",
    "ex": "はえがうるさく飛んでいるね。",
    "exReading": "はえ が うるさく とん でいる ね 。",
    "exTranslation": "An annoying fly is flying about.",
    "category": "Noun"
  },
  {
    "term": "沿う",
    "termReading": "そう",
    "termTranslation": "lie along, follow along",
    "ex": "川に沿って歩いたんだ。",
    "exReading": "かわ に そって あるいた ん だ",
    "exTranslation": "I walked along the river.",
    "category": "Verb"
  },
  {
    "term": "浜",
    "termReading": "はま",
    "termTranslation": "beach, seashore",
    "ex": "今晩、浜で花火大会がありますよ。",
    "exReading": "こんばん はま で はなび たいかい が あります よ",
    "exTranslation": "There's going to be a fireworks show at the beach tonight.",
    "category": "Noun"
  },
  {
    "term": "浜辺",
    "termReading": "はまべ",
    "termTranslation": "beach, seashore",
    "ex": "浜辺できれいな貝がらを拾いました。",
    "exReading": "はまべ で きれい な かいがら を ひろいました",
    "exTranslation": "I picked up a beautiful shell on the beach.",
    "category": "Noun"
  },
  {
    "term": "湾",
    "termReading": "わん",
    "termTranslation": "bay, gulf",
    "ex": "東京湾は重要な役割を果たしている。",
    "exReading": "とうきょうわん は じゅうよう な やくわり を はたして いる",
    "exTranslation": "Tokyo Bay plays an important role.",
    "category": "Noun"
  },
  {
    "term": "潜る",
    "termReading": "もぐる",
    "termTranslation": "dive into",
    "ex": "彼は長い間海に潜っていたの。",
    "exReading": "かれ は ながい あいだ うみ に もぐって いた の",
    "exTranslation": "He was diving in the sea for a long time.",
    "category": "Verb"
  },
  {
    "term": "ばかばかしい",
    "termReading": "ばかばかしい",
    "termTranslation": "stupid, absurd",
    "ex": "ばかばかしい映画だが面白かった。",
    "exReading": "ばかばかしい えいが だ が おもしろかった",
    "exTranslation": "It was an absurd but interesting movie.",
    "category": "Adjective"
  },
  {
    "term": "冷淡",
    "termReading": "れいたん",
    "termTranslation": "cool, indifferent",
    "ex": "彼は彼女に冷淡な態度をとったね。",
    "exReading": "かれ は かのじょ に れいたん な たいど を とった ね",
    "exTranslation": "He took a cold-hearted attitude toward her.",
    "category": "Adjectival Noun"
  },
  {
    "term": "泥",
    "termReading": "どろ",
    "termTranslation": "mud, dirt",
    "ex": "靴が泥だらけになったよ。",
    "exReading": "くつ が どろだらけ に なった よ",
    "exTranslation": "Her shoes were covered with mud.",
    "category": "Noun"
  },
  {
    "term": "濁る",
    "termReading": "にごる",
    "termTranslation": "get cloudy",
    "ex": "その池の水は濁っているね。",
    "exReading": "その いけ の みず は にごって いる ね",
    "exTranslation": "The pond water is cloudy.",
    "category": "Verb"
  },
  {
    "term": "湯飲み",
    "termReading": "ゆのみ",
    "termTranslation": "teacup",
    "ex": "湯飲みにはお茶を入れてお茶碗にはご飯をよそいます。",
    "exReading": "ゆのみ に は おちゃ を いれて お ちゃわん に は ごはん を よそいます",
    "exTranslation": "We pour tea in a teacup and serve rice in a rice bowl.",
    "category": "Noun"
  },
  {
    "term": "湯気",
    "termReading": "ゆげ",
    "termTranslation": "steam, vapor",
    "ex": "やかんから湯気が出ていますよ。",
    "exReading": "やかん から ゆげ が でて います よ",
    "exTranslation": "Steam is coming out the kettle.",
    "category": "Noun"
  },
  {
    "term": "はかり",
    "termReading": "はかり",
    "termTranslation": "scales",
    "ex": "ケーキを作る時ははかりを使います。",
    "exReading": "けーき を つくる とき は はかり を つかいます 。",
    "exTranslation": "I use a kitchen scale when I bake a cake.",
    "category": "Noun"
  },
  {
    "term": "漏れる",
    "termReading": "もれる",
    "termTranslation": "leak, be disclosed",
    "ex": "彼のヘッドフォンから音が漏れているね。",
    "exReading": "かれ の へっどふぉん から おと が もれて いる ね",
    "exTranslation": "Sound is leaking from his headphones.",
    "category": "Verb"
  },
  {
    "term": "漏らす",
    "termReading": "もらす",
    "termTranslation": "give away, let leak",
    "ex": "秘密を漏らしたのは彼です。",
    "exReading": "ひみつ を もらした の は かれ です",
    "exTranslation": "He's the one who leaked the secret.",
    "category": "Verb"
  },
  {
    "term": "漏る",
    "termReading": "もる",
    "termTranslation": "leak, be leaky",
    "ex": "天井から雨が漏るの。",
    "exReading": "てんじょう から あめ が もる の",
    "exTranslation": "Rain is leaking from the ceiling.",
    "category": "Verb"
  },
  {
    "term": "濡らす",
    "termReading": "ぬらす",
    "termTranslation": "wet, moisten",
    "ex": "携帯電話を水で濡らしてしまったんだ。",
    "exReading": "けいたい でんわ を みず で ぬらして しまった ん だ",
    "exTranslation": "I got my cell phone wet.",
    "category": "Verb"
  },
  {
    "term": "溝",
    "termReading": "みぞ",
    "termTranslation": "ditch, groove",
    "ex": "車が溝にはまったの。",
    "exReading": "くるま が みぞ に はまった の",
    "exTranslation": "The car got stuck in the ditch.",
    "category": "Noun"
  },
  {
    "term": "はしご",
    "termReading": "はしご",
    "termTranslation": "ladder",
    "ex": "はしごを使って屋根に上りました。",
    "exReading": "はしご を つかって やね に のぼりました",
    "exTranslation": "I went up to the roof on a ladder.",
    "category": "Noun"
  },
  {
    "term": "吐く",
    "termReading": "はく",
    "termTranslation": "holler, spew",
    "ex": "彼は乱暴な言葉を吐いたぞ。",
    "exReading": "かれ は らんぼう な ことば を はいた ぞ",
    "exTranslation": "He hollered some rough words.",
    "category": "Verb"
  },
  {
    "term": "嘆く",
    "termReading": "なげく",
    "termTranslation": "sigh, grieve",
    "ex": "嘆いていても何も変わりません。",
    "exReading": "なげいていて も なに も かわりません",
    "exTranslation": "Grieving won't change anything.",
    "category": "Verb"
  },
  {
    "term": "虫歯",
    "termReading": "むしば",
    "termTranslation": "decayed tooth, cavity",
    "ex": "虫歯は予防できます。",
    "exReading": "むしば は よぼう できます",
    "exTranslation": "Tooth decay can be prevented.",
    "category": "Noun"
  },
  {
    "term": "虫",
    "termReading": "むし",
    "termTranslation": "insect, worm",
    "ex": "庭で秋の虫が鳴いているね。",
    "exReading": "にわ で あき の むし が ないて いる ね",
    "exTranslation": "The autumn insects are chirping in the garden.",
    "category": "Noun"
  },
  {
    "term": "忠実",
    "termReading": "ちゅうじつ",
    "termTranslation": "faithfulness, devotion",
    "ex": "犬は飼い主に忠実です。",
    "exReading": "いぬ は かいぬし に ちゅうじつ です",
    "exTranslation": "Dogs are faithful to their owner.",
    "category": "Adjectival Noun"
  },
  {
    "term": "忠告",
    "termReading": "ちゅうこく",
    "termTranslation": "advice, admonishment",
    "ex": "先生からの忠告を聞くべきだよ。",
    "exReading": "せんせい から の ちゅうこく を きく べき だ よ",
    "exTranslation": "You should follow the teacher's advice.",
    "category": "Verbal Noun"
  },
  {
    "term": "はだし",
    "termReading": "はだし",
    "termTranslation": "barefoot, bare feet",
    "ex": "砂浜をはだしでかけ回ったんだ。",
    "exReading": "すなはま を はだし で かけまわった ん だ",
    "exTranslation": "He ran around on the beach barefoot.",
    "category": "Noun"
  },
  {
    "term": "恵まれる",
    "termReading": "めぐまれる",
    "termTranslation": "be blessed, be privileged",
    "ex": "当日は天気に恵まれました。",
    "exReading": "とうじつ は てんき に めぐまれました",
    "exTranslation": "We were blessed with good weather that day.",
    "category": "Verb"
  },
  {
    "term": "道徳",
    "termReading": "どうとく",
    "termTranslation": "morality, morals",
    "ex": "子供には道徳をきちんと教えていきたいと思います。",
    "exReading": "こども に は どうとく を きちんと おしえて いきたいと おもいます",
    "exTranslation": "I'd like to properly teach my kids about morality.",
    "category": "Noun"
  },
  {
    "term": "直径",
    "termReading": "ちょっけい",
    "termTranslation": "diameter",
    "ex": "この円は直径6センチです。",
    "exReading": "この えん は ちょっけい 6 せんち です",
    "exTranslation": "This circle has a diameter of 6cm.",
    "category": "Noun"
  },
  {
    "term": "半径",
    "termReading": "はんけい",
    "termTranslation": "radius",
    "ex": "この円の半径は5センチです。",
    "exReading": "この えん の はんけい は 5 せんち です",
    "exTranslation": "The radius of this circle is five centimeters.",
    "category": "Noun"
  },
  {
    "term": "丼",
    "termReading": "どんぶり",
    "termTranslation": "bowl",
    "ex": "彼はご飯を丼で食べたよ。",
    "exReading": "かれ は ごはん を どんぶり で たべた よ",
    "exTranslation": "He ate rice in a big bowl.",
    "category": "Noun"
  },
  {
    "term": "バツ",
    "termReading": "バツ",
    "termTranslation": "cross, x-mark",
    "ex": "バツが三つあるので85点です。",
    "exReading": "ばつ が みっつ ある の で 85 てん です",
    "exTranslation": "Because there are three mistakes you get 85 points.",
    "category": "Noun"
  },
  {
    "term": "豆",
    "termReading": "まめ",
    "termTranslation": "bean, peas",
    "ex": "今、豆を煮ています。",
    "exReading": "いま まめ を にて います",
    "exTranslation": "I'm boiling some beans now.",
    "category": "Noun"
  },
  {
    "term": "粒",
    "termReading": "つぶ",
    "termTranslation": "grain, drop",
    "ex": "その子は大粒の涙を浮かべていたの。",
    "exReading": "その こ は おおつぶ の なみだ を うかべていた の",
    "exTranslation": "The child's eyes were brimming with big tears.",
    "category": "Noun"
  },
  {
    "term": "麦",
    "termReading": "むぎ",
    "termTranslation": "wheat",
    "ex": "この畑では麦を作っています。",
    "exReading": "この はたけ で は むぎ を つくって います",
    "exTranslation": "We're growing wheat in this field.",
    "category": "Noun"
  },
  {
    "term": "田畑",
    "termReading": "たはた",
    "termTranslation": "fields and rice paddies",
    "ex": "この村には田畑がたくさんあります。",
    "exReading": "この むら に は たはた が たくさん あります",
    "exTranslation": "There are a lot of fields in this village.",
    "category": "Noun"
  },
  {
    "term": "灯油",
    "termReading": "とうゆ",
    "termTranslation": "kerosene, lamp oil",
    "ex": "ストーブの灯油がなくなったわよ。",
    "exReading": "すとーぶ の とうゆ が なくなった わ よ",
    "exTranslation": "The kerosene for the stove has run out.",
    "category": "Noun"
  },
  {
    "term": "はねる",
    "termReading": "はねる",
    "termTranslation": "run over, hit",
    "ex": "彼は車にはねられたけど無事だったの。",
    "exReading": "かれ は くるま に はねられた けど ぶじ だった の",
    "exTranslation": "He was hit by a car but he was fine.",
    "category": "Verb"
  },
  {
    "term": "電灯",
    "termReading": "でんとう",
    "termTranslation": "lamp, electric light",
    "ex": "午後5時には電灯がつきます。",
    "exReading": "ごご 5 じ に は でんとう が つきます",
    "exTranslation": "The light will be turned on at 5:00 pm.",
    "category": "Noun"
  },
  {
    "term": "炎",
    "termReading": "ほのお",
    "termTranslation": "flame, blaze",
    "ex": "ろうそくの炎が部屋を照らしたんだ。",
    "exReading": "ろうそく の ほのお が へや を てらした ん だ",
    "exTranslation": "The candle's flame lit the room.",
    "category": "Noun"
  },
  {
    "term": "皮肉",
    "termReading": "ひにく",
    "termTranslation": "cynicism, irony",
    "ex": "彼は皮肉ばかり言います。",
    "exReading": "かれ は ひにく ばかり いいます",
    "exTranslation": "He always says sarcastic things.",
    "category": "Adjectival Noun"
  },
  {
    "term": "皮膚",
    "termReading": "ひふ",
    "termTranslation": "skin",
    "ex": "冬は皮膚が乾燥しますね。",
    "exReading": "ふゆ は ひふ が かんそう します ね",
    "exTranslation": "My skin dries out in winter.",
    "category": "Noun"
  },
  {
    "term": "臭い",
    "termReading": "におい",
    "termTranslation": "smell, odor",
    "ex": "ここはいやな臭いがする。",
    "exReading": "ここ は いや な におい が する 。",
    "exTranslation": "There's an unpleasant smell here.",
    "category": "Noun"
  },
  {
    "term": "生臭い",
    "termReading": "なまぐさい",
    "termTranslation": "smelling of fish",
    "ex": "まな板が生臭いです。",
    "exReading": "まないた が なまぐさい です",
    "exTranslation": "The chopping board smells fishy.",
    "category": "Adjective"
  },
  {
    "term": "はれる",
    "termReading": "はれる",
    "termTranslation": "swell, swell up",
    "ex": "今朝は目がはれています。",
    "exReading": "けさ は め が はれて います",
    "exTranslation": "My eyes are swollen this morning.",
    "category": "Verb"
  },
  {
    "term": "臭う",
    "termReading": "におう",
    "termTranslation": "stink (unpleasant)",
    "ex": "流しが臭うのできれいにしたよ。",
    "exReading": "ながし が におう の で きれい に した よ",
    "exTranslation": "I cleaned the sink because it smelled.",
    "category": "Verb"
  },
  {
    "term": "面倒臭い",
    "termReading": "めんどうくさい",
    "termTranslation": "troublesome, tiresome",
    "ex": "この計算は面倒臭いなあ。",
    "exReading": "この けいさん は めんどうくさい なあ",
    "exTranslation": "This calculation is troublesome.",
    "category": "Adjective"
  },
  {
    "term": "匂う",
    "termReading": "におう",
    "termTranslation": "smell (pleasant)",
    "ex": "バラの花が甘く匂っているね。",
    "exReading": "ばら の はな が あまく におって いる ね",
    "exTranslation": "Rose flowers smell sweet.",
    "category": "Verb"
  },
  {
    "term": "同居",
    "termReading": "どうきょ",
    "termTranslation": "living together",
    "ex": "私はまだ両親と同居しています。",
    "exReading": "わたし は まだ りょうしん と どうきょ して います",
    "exTranslation": "I still live with my parents.",
    "category": "Verbal Noun"
  },
  {
    "term": "掘る",
    "termReading": "ほる",
    "termTranslation": "dig, unearth",
    "ex": "ここに穴を掘りましょう。",
    "exReading": "ここ に あな を ほりましょう",
    "exTranslation": "Let's dig a hole here.",
    "category": "Verb"
  },
  {
    "term": "ひく",
    "termReading": "ひく",
    "termTranslation": "run over",
    "ex": "車にひかれないよう気をつけなさい。",
    "exReading": "くるま に ひかれない よう き を つけなさい 。",
    "exTranslation": "Make sure you don't get run over by a car.",
    "category": "Verb"
  },
  {
    "term": "塀",
    "termReading": "へい",
    "termTranslation": "fence, wall",
    "ex": "猫が塀の上で寝ているぞ。",
    "exReading": "ねこ が へい の うえ で ねて いる ぞ",
    "exTranslation": "The cat is sleeping on top of the wall.",
    "category": "Noun"
  },
  {
    "term": "大層",
    "termReading": "たいそう",
    "termTranslation": "very, highly",
    "ex": "彼は大層喜んでいました。",
    "exReading": "かれ は たいそう よろこんで いました",
    "exTranslation": "He was extremely pleased.",
    "category": "Adverb"
  },
  {
    "term": "履歴",
    "termReading": "りれき",
    "termTranslation": "history, personal history",
    "ex": "最近使ったファイルは、履歴からすぐ開けます。",
    "exReading": "さいきん つかった ふぁいる は、 りれき から すぐ ひらけます",
    "exTranslation": "You can easily open recently used files from the history folder.",
    "category": "Noun"
  },
  {
    "term": "履歴書",
    "termReading": "りれきしょ",
    "termTranslation": "resume, bio",
    "ex": "面接のために履歴書を書きました。",
    "exReading": "めんせつ の ため に りれきしょ を かきました",
    "exTranslation": "I wrote out my resume for the interview.",
    "category": "Noun"
  },
  {
    "term": "履物",
    "termReading": "はきもの",
    "termTranslation": "footwear",
    "ex": "履物は靴箱に入れてください。",
    "exReading": "はきもの は くつばこ に いれて ください",
    "exTranslation": "Please put your footwear in the shoe cupboard",
    "category": "Noun"
  },
  {
    "term": "ヒント",
    "termReading": "ヒント",
    "termTranslation": "hint, clue",
    "ex": "何かヒントをください。",
    "exReading": "なにか ひんと を ください",
    "exTranslation": "Please give me a hint.",
    "category": "Noun"
  },
  {
    "term": "戸棚",
    "termReading": "とだな",
    "termTranslation": "closet, cupboard",
    "ex": "この皿を戸棚にしまってください。",
    "exReading": "この さら を とだな に しまって ください",
    "exTranslation": "Please put away this plate in the cupboard.",
    "category": "Noun"
  },
  {
    "term": "扉",
    "termReading": "とびら",
    "termTranslation": "door",
    "ex": "彼は扉を開けたんだ。",
    "exReading": "かれ は とびら を あけた ん だ",
    "exTranslation": "He opened the door.",
    "category": "Noun"
  },
  {
    "term": "羽根",
    "termReading": "はね",
    "termTranslation": "feather",
    "ex": "このペンは鳥の羽根で作られています。",
    "exReading": "この ぺん は とり の はね で つくられて います",
    "exTranslation": "This pen is made from a bird's feather.",
    "category": "Noun"
  },
  {
    "term": "翌日",
    "termReading": "よくじつ",
    "termTranslation": "the following day",
    "ex": "翌日、彼に会いに行きました。",
    "exReading": "よくじつ かれ に あい に いきました",
    "exTranslation": "I went to see him the next day.",
    "category": "Noun"
  },
  {
    "term": "翌朝",
    "termReading": "よくあさ",
    "termTranslation": "the following morning",
    "ex": "翌朝は快晴だったね。",
    "exReading": "よくあさ は かいせい だった ね",
    "exTranslation": "The weather was fine the next morning.",
    "category": "Noun"
  },
  {
    "term": "翌年",
    "termReading": "よくねん",
    "termTranslation": "the following year",
    "ex": "彼女は大学卒業の翌年に結婚したの。",
    "exReading": "かのじょ は だいがく そつぎょう の よくねん に けっこん した の",
    "exTranslation": "She married the year after graduating from university.",
    "category": "Noun"
  },
  {
    "term": "ファックス",
    "termReading": "ファックス",
    "termTranslation": "facsimile, fax",
    "ex": "詳細はファックスで送ります。",
    "exReading": "しょうさい は ふぁっくす で おくります",
    "exTranslation": "I'll send the details by fax.",
    "category": "Noun"
  },
  {
    "term": "群れ",
    "termReading": "むれ",
    "termTranslation": "crowd, herd",
    "ex": "湖に鳥の群れがいたよ。",
    "exReading": "みずうみ に とり の むれ が いた よ",
    "exTranslation": "There was a flock of birds on the lake.",
    "category": "Noun"
  },
  {
    "term": "殴る",
    "termReading": "なぐる",
    "termTranslation": "hit, strike",
    "ex": "彼は思わず友人を殴ったの。",
    "exReading": "かれ は おもわず ゆうじん を なぐった の",
    "exTranslation": "He hit his friend without thinking.",
    "category": "Verb"
  },
  {
    "term": "要旨",
    "termReading": "ようし",
    "termTranslation": "gist, overview",
    "ex": "会議の要旨は次の通りです。",
    "exReading": "かいぎ の ようし は つぎ の とおり です",
    "exTranslation": "The summary of the conference is as follows.",
    "category": "Noun"
  },
  {
    "term": "肌",
    "termReading": "はだ",
    "termTranslation": "skin, surface",
    "ex": "彼女は肌がとても白いですね。",
    "exReading": "かのじょ は はだ が とても しろい です ね",
    "exTranslation": "Her skin is very white.",
    "category": "Noun"
  },
  {
    "term": "肌着",
    "termReading": "はだぎ",
    "termTranslation": "underwear",
    "ex": "寒いので暖かい肌着を着ました。",
    "exReading": "さむい の で あたたかい はだぎ を きました",
    "exTranslation": "I was cold so I put on some warm underwear.",
    "category": "Noun"
  },
  {
    "term": "ふもと",
    "termReading": "ふもと",
    "termTranslation": "foot of the mountain",
    "ex": "彼は山のふもとに住んでいるよ。",
    "exReading": "かれ は やま の ふもと に すんで いる よ",
    "exTranslation": "He lives at the foot of a mountain.",
    "category": "Noun"
  },
  {
    "term": "肌色",
    "termReading": "はだいろ",
    "termTranslation": "flesh color",
    "ex": "彼女は肌色のシャツを着ています。",
    "exReading": "かのじょ は はだいろ の しゃつ を きて います",
    "exTranslation": "She's wearing a skin-colored shirt.",
    "category": "Noun"
  },
  {
    "term": "腸",
    "termReading": "ちょう",
    "termTranslation": "intestines, entrails",
    "ex": "彼は腸の手術を受けたの。",
    "exReading": "かれ は ちょう の しゅじゅつ を うけた の",
    "exTranslation": "He had an operation on his intestines.",
    "category": "Noun"
  },
  {
    "term": "大胆",
    "termReading": "だいたん",
    "termTranslation": "boldness, daring",
    "ex": "彼女はずいぶん大胆なことを言うね。",
    "exReading": "かのじょ は ずいぶん だいたん な こと を いう ね",
    "exTranslation": "She says fairly bold things.",
    "category": "Adjectival Noun"
  },
  {
    "term": "幕",
    "termReading": "まく",
    "termTranslation": "curtain",
    "ex": "ステージの幕が上がったんだ。",
    "exReading": "すてーじ の まく が あがった ん だ",
    "exTranslation": "The stage curtain rose.",
    "category": "Noun"
  },
  {
    "term": "夕暮れ",
    "termReading": "ゆうぐれ",
    "termTranslation": "evening, twilight",
    "ex": "夕暮れの空がきれいですね。",
    "exReading": "ゆうぐれ の そら が きれい です ね",
    "exTranslation": "The twilight sky is beautiful.",
    "category": "Noun"
  },
  {
    "term": "フルーツ",
    "termReading": "フルーツ",
    "termTranslation": "fruit",
    "ex": "おいしそうなフルーツゼリーだね。",
    "exReading": "おいし そう な ふるーつぜりー だ ね",
    "exTranslation": "That fruit jelly looks delicious, doesn't it?",
    "category": "Noun"
  },
  {
    "term": "墓",
    "termReading": "はか",
    "termTranslation": "grave, gravestone",
    "ex": "祖父の墓は近くにあります。",
    "exReading": "そふ の はか は ちかく に あります",
    "exTranslation": "My grandfather's grave is nearby.",
    "category": "Noun"
  },
  {
    "term": "墓地",
    "termReading": "ぼち",
    "termTranslation": "graveyard, cemetery",
    "ex": "寺のとなりに墓地があります。",
    "exReading": "てら の となり に ぼち が あります",
    "exTranslation": "There's a graveyard next to the temple.",
    "category": "Noun"
  },
  {
    "term": "墓参り",
    "termReading": "はかまいり",
    "termTranslation": "visit to a grave",
    "ex": "明日は家族で墓参りに行きます。",
    "exReading": "あした は かぞく で はかまいり に いきます",
    "exTranslation": "I'm going to visit the grave with my family tomorrow.",
    "category": "Verbal Noun"
  },
  {
    "term": "芽",
    "termReading": "め",
    "termTranslation": "bud, sprout",
    "ex": "チューリップの芽が出ました。",
    "exReading": "ちゅーりっぷ の め が でました",
    "exTranslation": "The tulip buds emerged.",
    "category": "Noun"
  },
  {
    "term": "葬式",
    "termReading": "そうしき",
    "termTranslation": "funeral ceremony",
    "ex": "彼の葬式は明日です。",
    "exReading": "かれ の そうしき は あした です",
    "exTranslation": "His funeral is tomorrow.",
    "category": "Noun"
  },
  {
    "term": "へそ",
    "termReading": "へそ",
    "termTranslation": "the navel, the bellybutton",
    "ex": "カエルにはへそがないんだ。",
    "exReading": "かえる に は へそ が ない ん だ",
    "exTranslation": "Frogs have no belly button.",
    "category": "Noun"
  },
  {
    "term": "礼儀",
    "termReading": "れいぎ",
    "termTranslation": "good manners, courtesy",
    "ex": "彼女は礼儀が身に付いています。",
    "exReading": "かのじょ は れいぎ が み に ついて います",
    "exTranslation": "She's accomplished in etiquette.",
    "category": "Noun"
  },
  {
    "term": "憎む",
    "termReading": "にくむ",
    "termTranslation": "hate, detest",
    "ex": "彼はもう彼女を憎んではいないよ。",
    "exReading": "かれ は もう かのじょ を にくんで は いない よ",
    "exTranslation": "He no longer hates her.",
    "category": "Verb"
  },
  {
    "term": "憎しみ",
    "termReading": "にくしみ",
    "termTranslation": "hatred, enmity",
    "ex": "彼の心は憎しみに満ちていたんだ。",
    "exReading": "かれ の こころ は にくしみ に みちて いた ん だ",
    "exTranslation": "His heart was filled with hatred.",
    "category": "Noun"
  },
  {
    "term": "憎らしい",
    "termReading": "にくらしい",
    "termTranslation": "hateful, horrible",
    "ex": "妹は時々憎らしいことを言うんだ。",
    "exReading": "いもうと は ときどき にくらしい こと を いう ん だ",
    "exTranslation": "My little sister sometimes says hateful things.",
    "category": "Adjective"
  },
  {
    "term": "憎い",
    "termReading": "にくい",
    "termTranslation": "hateful, abominable",
    "ex": "彼が憎いですか。",
    "exReading": "かれ が にくい です か",
    "exTranslation": "Do you hate him?",
    "category": "Adjective"
  },
  {
    "term": "仏",
    "termReading": "ほとけ",
    "termTranslation": "buddha, Buddhist image",
    "ex": "仏の教えを勉強しました。",
    "exReading": "ほとけ の おしえ を べんきょう しました",
    "exTranslation": "I studied the teachings of Buddha.",
    "category": "Noun"
  },
  {
    "term": "べたべた",
    "termReading": "べたべた",
    "termTranslation": "stick together",
    "ex": "カップルがべたべたしているね。",
    "exReading": "かっぷる が べたべた して いる ね",
    "exTranslation": "That couple are inseparable.",
    "category": "Adjective"
  },
  {
    "term": "仏教",
    "termReading": "ぶっきょう",
    "termTranslation": "Buddhism",
    "ex": "お葬式は仏教で行うことが多いです。",
    "exReading": "おそうしき は ぶっきょう で おこなう こと が おおい です",
    "exTranslation": "Funerals are often conducted according to Buddhist rites.",
    "category": "Noun"
  },
  {
    "term": "坊さん",
    "termReading": "ぼうさん",
    "termTranslation": "Buddhist priest",
    "ex": "私の友人はお坊さんをしているの。",
    "exReading": "わたし の ゆうじん は おぼうさん を して いる の",
    "exTranslation": "My friend works as a priest.",
    "category": "Noun"
  },
  {
    "term": "竹",
    "termReading": "たけ",
    "termTranslation": "bamboo",
    "ex": "この笛は竹でできている。",
    "exReading": "この ふえ は たけ で できて いる",
    "exTranslation": "This flute is made of bamboo.",
    "category": "Noun"
  },
  {
    "term": "筆者",
    "termReading": "ひっしゃ",
    "termTranslation": "writer",
    "ex": "筆者の趣旨を考えてください。",
    "exReading": "ひっしゃ の しゅし を かんがえて ください",
    "exTranslation": "Please consider the author's point.",
    "category": "Noun"
  },
  {
    "term": "筆記試験",
    "termReading": "ひっきしけん",
    "termTranslation": "written examination",
    "ex": "その会社の筆記試験は難しかったよ。",
    "exReading": "その かいしゃ の ひっきしけん は むずかしかった よ",
    "exTranslation": "That company's written examination was difficult.",
    "category": "Noun"
  },
  {
    "term": "ほうき",
    "termReading": "ほうき",
    "termTranslation": "broom",
    "ex": "ほうきで庭を掃除しました。",
    "exReading": "ほうき で にわ を そうじ しました 。",
    "exTranslation": "I swept the yard with a broom.",
    "category": "Noun"
  },
  {
    "term": "筆記用具",
    "termReading": "ひっきようぐ",
    "termTranslation": "writing materials, pens and pencils",
    "ex": "今日は筆記用具を忘れました。",
    "exReading": "きょう は ひっきようぐ を わすれました",
    "exTranslation": "I forgot my writing materials today.",
    "category": "Noun"
  },
  {
    "term": "筆",
    "termReading": "ふで",
    "termTranslation": "writing brush, penmanship",
    "ex": "彼は筆で手紙を書いたの。",
    "exReading": "かれ は ふで で てがみ を かいた の",
    "exTranslation": "He wrote the letter with a brush.",
    "category": "Noun"
  },
  {
    "term": "笛",
    "termReading": "ふえ",
    "termTranslation": "flute, whistle",
    "ex": "彼は笛を吹くのが上手いね。",
    "exReading": "かれ は ふえ を ふく の が うまい ね",
    "exTranslation": "He's good at playing the flute.",
    "category": "Noun"
  },
  {
    "term": "名簿",
    "termReading": "めいぼ",
    "termTranslation": "registry, name list",
    "ex": "これが参加者の名簿です。",
    "exReading": "これ が さんかしゃ の めいぼ です",
    "exTranslation": "This is a list of the participants.",
    "category": "Noun"
  },
  {
    "term": "分裂",
    "termReading": "ぶんれつ",
    "termTranslation": "division, split",
    "ex": "会社が二つの派閥に分裂しているんだ。",
    "exReading": "かいしゃ が ふたつ の はばつ に ぶんれつ して いる ん だ",
    "exTranslation": "The company has been divided into two factions.",
    "category": "Verbal Noun"
  },
  {
    "term": "ポスター",
    "termReading": "ポスター",
    "termTranslation": "poster",
    "ex": "電柱にポスターが貼ってあった。",
    "exReading": "でんちゅう に ぽすたー が はって あった",
    "exTranslation": "There was a poster pasted to the telegraph pole.",
    "category": "Noun"
  },
  {
    "term": "布",
    "termReading": "ぬの",
    "termTranslation": "cloth",
    "ex": "この布はカーテンに使えます。",
    "exReading": "この ぬの は かーてん に つかえます",
    "exTranslation": "This cloth can be used for curtains.",
    "category": "Noun"
  },
  {
    "term": "包丁",
    "termReading": "ほうちょう",
    "termTranslation": "kitchen knife, carving knife",
    "ex": "包丁で指を切った。",
    "exReading": "ほうちょう で ゆび を きった",
    "exTranslation": "I cut my finger with a kitchen knife.",
    "category": "Noun"
  },
  {
    "term": "包帯",
    "termReading": "ほうたい",
    "termTranslation": "bandage, dressing",
    "ex": "彼女は手に包帯を巻いていたんだ。",
    "exReading": "かのじょ は て に ほうたい を まいて いた ん だ",
    "exTranslation": "Her hand was wrapped in a bandage.",
    "category": "Verbal Noun"
  },
  {
    "term": "包み",
    "termReading": "つつみ",
    "termTranslation": "package, parcel",
    "ex": "この包みは誰のですか。",
    "exReading": "この つつみ は だれ の です か",
    "exTranslation": "Whose parcel is this?",
    "category": "Noun"
  },
  {
    "term": "包み紙",
    "termReading": "つつみがみ",
    "termTranslation": "wrapping paper",
    "ex": "包み紙はたたんでください。",
    "exReading": "つつみがみ は たたんで ください",
    "exTranslation": "Please fold the wrapping paper.",
    "category": "Noun"
  },
  {
    "term": "抱く",
    "termReading": "だく",
    "termTranslation": "hug, embrace",
    "ex": "祖母がうちの猫を抱いているわよ。",
    "exReading": "そぼ が うち の ねこ を だいて いる わ よ",
    "exTranslation": "Grandma's holding our cat.",
    "category": "Verb"
  },
  {
    "term": "ほどく",
    "termReading": "ほどく",
    "termTranslation": "undo, unfasten",
    "ex": "靴のひもをほどいたの。",
    "exReading": "くつ の ひも を ほどいた の",
    "exTranslation": "I unlaced my shoes.",
    "category": "Verb"
  },
  {
    "term": "文句",
    "termReading": "もんく",
    "termTranslation": "complaint",
    "ex": "彼女はいつも文句ばかり言う。",
    "exReading": "かのじょ は いつも もんく ばかり いう",
    "exTranslation": "She always does nothing but complain.",
    "category": "Noun"
  },
  {
    "term": "中旬",
    "termReading": "ちゅうじゅん",
    "termTranslation": "middle ten days of a month",
    "ex": "来月中旬にフランスへ行きます。",
    "exReading": "らいげつ ちゅうじゅん に ふらんす へ いきます 。",
    "exTranslation": "I'll go to France in the middle of next month.",
    "category": "Noun"
  },
  {
    "term": "平凡",
    "termReading": "へいぼん",
    "termTranslation": "common, ordinary",
    "ex": "彼はごく平凡な人です。",
    "exReading": "かれ は ごく へいぼん な ひと です",
    "exTranslation": "He's a very ordinary person.",
    "category": "Adjectival Noun"
  },
  {
    "term": "通帳",
    "termReading": "つうちょう",
    "termTranslation": "bankbook, passbook",
    "ex": "銀行の通帳を持ってきてください。",
    "exReading": "ぎんこう の つうちょう を もって きて ください",
    "exTranslation": "Please bring your bank book.",
    "category": "Noun"
  },
  {
    "term": "妊娠",
    "termReading": "にんしん",
    "termTranslation": "pregnancy, conception",
    "ex": "彼女が妊娠したそうです。",
    "exReading": "かのじょ が にんしん した そう です",
    "exTranslation": "I hear that she's pregnant.",
    "category": "Verbal Noun"
  },
  {
    "term": "ほほ",
    "termReading": "ほほ",
    "termTranslation": "cheek",
    "ex": "彼女は真っ赤なほほをしているね。",
    "exReading": "かのじょ は まっか な ほほ を している ね 。",
    "exTranslation": "Her cheeks are flushed.",
    "category": "Noun"
  },
  {
    "term": "同姓",
    "termReading": "どうせい",
    "termTranslation": "same surname",
    "ex": "日本では夫婦同姓が一般的よ。",
    "exReading": "にっぽん で は ふうふ どうせい が いっぱんてき よ",
    "exTranslation": "It's common in Japan for a married couple to have the same family name.",
    "category": "Noun"
  },
  {
    "term": "嫁",
    "termReading": "よめ",
    "termTranslation": "bride, daughter-in-law",
    "ex": "姉が嫁に行ったの。",
    "exReading": "あね が よめ に いった の",
    "exTranslation": "My big sister got married.",
    "category": "Noun"
  },
  {
    "term": "花嫁",
    "termReading": "はなよめ",
    "termTranslation": "bride",
    "ex": "花嫁が父親と一緒に入ってきましたね。",
    "exReading": "はなよめ が ちちおや と いっしょ に はいって きました ね",
    "exTranslation": "The bride entered with her father.",
    "category": "Noun"
  },
  {
    "term": "花婿",
    "termReading": "はなむこ",
    "termTranslation": "bridegroom",
    "ex": "花婿と花嫁が並んで登場したんだ。",
    "exReading": "はなむこ と はなよめ が ならんで とうじょう した ん だ",
    "exTranslation": "The bride and bridegroom appeared side by side.",
    "category": "Noun"
  },
  {
    "term": "幼児",
    "termReading": "ようじ",
    "termTranslation": "young child, infant",
    "ex": "幼児は入場無料です。",
    "exReading": "ようじ は にゅうじょう むりょう です",
    "exTranslation": "Small children are admitted free of charge.",
    "category": "Noun"
  },
  {
    "term": "ほほえむ",
    "termReading": "ほほえむ",
    "termTranslation": "smile",
    "ex": "彼女は私にほほえんだよ。",
    "exReading": "かのじょ は わたし に ほほえんだ よ",
    "exTranslation": "She smiled at me.",
    "category": "Verb"
  },
  {
    "term": "眠たい",
    "termReading": "ねむたい",
    "termTranslation": "sleepy",
    "ex": "まだ8時なのに、もう眠たいです。",
    "exReading": "まだ 8 じ な の に もう ねむたい です",
    "exTranslation": "I'm already sleepy even though it is only eight o'clock.",
    "category": "Adjective"
  },
  {
    "term": "眠り",
    "termReading": "ねむり",
    "termTranslation": "sleep, slumber",
    "ex": "彼女はいつもより早く眠りに着いたよ。",
    "exReading": "かのじょ は いつも より はやく ねむり に ついた よ",
    "exTranslation": "She fell asleep quicker than usual.",
    "category": "Noun"
  },
  {
    "term": "眺める",
    "termReading": "ながめる",
    "termTranslation": "look out, gaze",
    "ex": "猫が窓から外を眺めているよ。",
    "exReading": "ねこ が まど から そと を ながめて いる よ",
    "exTranslation": "The cat is at the window staring outside.",
    "category": "Verb"
  },
  {
    "term": "眺め",
    "termReading": "ながめ",
    "termTranslation": "view, prospect",
    "ex": "ここからの眺めは最高です。",
    "exReading": "ここ からの ながめ は さいこう です",
    "exTranslation": "The view from here is fantastic.",
    "category": "Noun"
  },
  {
    "term": "同封",
    "termReading": "どうふう",
    "termTranslation": "enclosure",
    "ex": "手紙に写真が同封されていたよ。",
    "exReading": "てがみ に しゃしん が どうふう されて いた よ",
    "exTranslation": "A photograph was enclosed with the letter.",
    "category": "Verbal Noun"
  },
  {
    "term": "封",
    "termReading": "ふう",
    "termTranslation": "seal, sealing",
    "ex": "手紙に封をしたよ。",
    "exReading": "てがみ に ふう を した よ",
    "exTranslation": "I sealed the letter.",
    "category": "Noun"
  },
  {
    "term": "まく",
    "termReading": "まく",
    "termTranslation": "sow, scatter",
    "ex": "庭に花の種をまきました。",
    "exReading": "にわ に はな の たね を まきました",
    "exTranslation": "I planted some flower seeds in the garden.",
    "category": "Verb"
  },
  {
    "term": "呼び掛ける",
    "termReading": "よびかける",
    "termTranslation": "call to, appeal to",
    "ex": "友人達に協力を呼び掛けたの。",
    "exReading": "ゆうじんたち に きょうりょく を よびかけた の",
    "exTranslation": "I asked some friends for their cooperation.",
    "category": "Verb"
  },
  {
    "term": "引っ掛かる",
    "termReading": "ひっかかる",
    "termTranslation": "be caught, be trapped",
    "ex": "魚の骨がのどに引っ掛かった。",
    "exReading": "さかな の ほね が のど に ひっかかった",
    "exTranslation": "A fish bone got caught in my throat.",
    "category": "Verb"
  },
  {
    "term": "話し掛ける",
    "termReading": "はなしかける",
    "termTranslation": "address someone, speak to someone",
    "ex": "知らない人が話し掛けてきた。",
    "exReading": "しらない ひと が はなしかけて きた",
    "exTranslation": "A stranger came up and spoke to me.",
    "category": "Verb"
  },
  {
    "term": "通り掛かる",
    "termReading": "とおりかかる",
    "termTranslation": "happen to pass by",
    "ex": "その店を通り掛かったらバーゲンをやっていた。",
    "exReading": "その みせ を とおりかかったら ばーげん を やっていた 。",
    "exTranslation": "When I happened to pass the shop they were having a sale.",
    "category": "Verb"
  },
  {
    "term": "引っ掛ける",
    "termReading": "ひっかける",
    "termTranslation": "hook on",
    "ex": "その選手はハードルに足を引っ掛けたんだ。",
    "exReading": "その せんしゅ は はーどる に あし を ひっかけた ん だ",
    "exTranslation": "The athlete caught his foot on the hurdle.",
    "category": "Verb"
  },
  {
    "term": "まとめ",
    "termReading": "まとめ",
    "termTranslation": "summary",
    "ex": "論文のまとめを書いています。",
    "exReading": "ろんぶん の まとめ を かいています 。",
    "exTranslation": "I'm writing a summary of the article.",
    "category": "Noun"
  },
  {
    "term": "寄り掛かる",
    "termReading": "よりかかる",
    "termTranslation": "lean against something",
    "ex": "彼はフェンスに寄り掛かったの。",
    "exReading": "かれ は ふぇんす に よりかかった の 。",
    "exTranslation": "He leant against the fence.",
    "category": "Verb"
  },
  {
    "term": "措置",
    "termReading": "そち",
    "termTranslation": "measure, step",
    "ex": "被災者を救うための特別な措置が取られました。",
    "exReading": "ひさいしゃ を すくう ため の とくべつ な そち が とられました",
    "exTranslation": "Special measures were taken to help the disaster vicitims.",
    "category": "Verbal Noun"
  },
  {
    "term": "拝見",
    "termReading": "はいけん",
    "termTranslation": "humbly look at or see",
    "ex": "あなたの著書を拝見しました。",
    "exReading": "あなた の ちょしょ を はいけん しました",
    "exTranslation": "I read your book.",
    "category": "Verbal Noun"
  },
  {
    "term": "控える",
    "termReading": "ひかえる",
    "termTranslation": "hold back, show moderation",
    "ex": "最近甘いものを控えています。",
    "exReading": "さいきん あまい もの を ひかえて います",
    "exTranslation": "I'm refraining from eating sweets these days.",
    "category": "Verb"
  },
  {
    "term": "控え室",
    "termReading": "ひかえしつ",
    "termTranslation": "waiting room",
    "ex": "ここはお客様用の控え室です。",
    "exReading": "ここ は おきゃくさま よう の ひかえしつ です 。",
    "exTranslation": "This is a waiting room for guests.",
    "category": "Noun"
  },
  {
    "term": "まね",
    "termReading": "まね",
    "termTranslation": "imitation, mimicry",
    "ex": "娘はよく私のまねをします。",
    "exReading": "むすめ は よく わたし の まね を します",
    "exTranslation": "My daughter often copies me.",
    "category": "Verbal Noun"
  },
  {
    "term": "握り締める",
    "termReading": "にぎりしめる",
    "termTranslation": "squeeze, grip",
    "ex": "彼は両手を握り締めたの。",
    "exReading": "かれ は りょうて を にぎりしめた の",
    "exTranslation": "He clenched his fists.",
    "category": "Verb"
  },
  {
    "term": "揃う",
    "termReading": "そろう",
    "termTranslation": "be (all) together",
    "ex": "朝は家族全員が揃って食事します。",
    "exReading": "あさ は かぞく ぜんいん が そろって しょくじ します",
    "exTranslation": "The whole family eats together in the morning.",
    "category": "Verb"
  },
  {
    "term": "揃える",
    "termReading": "そろえる",
    "termTranslation": "align, make uniform",
    "ex": "花は長さを揃えて花瓶にさしましょう。",
    "exReading": "はな は ながさ を そろえて かびん に さしましょう",
    "exTranslation": "Cut the flowers the same length and stand them in the vase.",
    "category": "Verb"
  },
  {
    "term": "出迎え",
    "termReading": "でむかえ",
    "termTranslation": "meeting, receiving",
    "ex": "空港へ彼女を出迎えに行きました。",
    "exReading": "くうこう へ かのじょ を でむかえ に いきました",
    "exTranslation": "I went to the airport to pick her up.",
    "category": "Noun"
  },
  {
    "term": "迎え",
    "termReading": "むかえ",
    "termTranslation": "greeting, meeting",
    "ex": "駅まで迎えに来て下さい。",
    "exReading": "えき まで むかえ に きて ください",
    "exTranslation": "Please come pick me up at the station.",
    "category": "Noun"
  },
  {
    "term": "まねる",
    "termReading": "まねる",
    "termTranslation": "imitate, mimic",
    "ex": "私の動きをまねてください。",
    "exReading": "わたし の うごき を まねて ください",
    "exTranslation": "Please imitate my movements.",
    "category": "Verb"
  },
  {
    "term": "遂げる",
    "termReading": "とげる",
    "termTranslation": "accomplish, achieve",
    "ex": "この会社は急成長を遂げました。",
    "exReading": "この かいしゃ は きゅうせいちょう を とげました",
    "exTranslation": "This company has achieved rapid growth.",
    "category": "Verb"
  },
  {
    "term": "巡る",
    "termReading": "めぐる",
    "termTranslation": "go round, circulate",
    "ex": "明日から温泉を巡る旅に出ます。",
    "exReading": "あした から おんせん を めぐる たび に でます",
    "exTranslation": "I'm going on a tour of hot springs tomorrow.",
    "category": "Verb"
  },
  {
    "term": "待遇",
    "termReading": "たいぐう",
    "termTranslation": "treatment, pay",
    "ex": "社員たちは待遇の改善を要求している。",
    "exReading": "しゃいんたち は たいぐう の かいぜん を ようきゅう して いる",
    "exTranslation": "Employees are demanding improved working conditions.",
    "category": "Noun"
  },
  {
    "term": "俳句",
    "termReading": "はいく",
    "termTranslation": "haiku",
    "ex": "「さくら」という言葉を使って俳句を書いたよ。",
    "exReading": "「さくら」 という ことば を つかって はいく を かいた よ",
    "exTranslation": "I composed a haiku poem using the word \"sakura.\"",
    "category": "Noun"
  },
  {
    "term": "俳優",
    "termReading": "はいゆう",
    "termTranslation": "actor, actress",
    "ex": "彼は俳優です。",
    "exReading": "かれ は はいゆう です",
    "exTranslation": "He's an actor.",
    "category": "Noun"
  },
  {
    "term": "敏感",
    "termReading": "びんかん",
    "termTranslation": "sensitive",
    "ex": "彼女は流行に敏感だね。",
    "exReading": "かのじょ は りゅうこう に びんかん だ ね",
    "exTranslation": "She's sensitive to the latest trends.",
    "category": "Adjectival Noun"
  },
  {
    "term": "まぶた",
    "termReading": "まぶた",
    "termTranslation": "eyelid",
    "ex": "眠くてまぶたが重くなってきた。",
    "exReading": "ねむくて まぶた が おもく なってきた 。",
    "exTranslation": "I'm sleepy and my eyelids have gotten heavy.",
    "category": "Noun"
  },
  {
    "term": "倣う",
    "termReading": "ならう",
    "termTranslation": "copy, follow an example",
    "ex": "この街はロンドンに倣って作られました。",
    "exReading": "この まち は ろんどん に ならって つくられました",
    "exTranslation": "This town was built to look like London.",
    "category": "Verb"
  },
  {
    "term": "寮",
    "termReading": "りょう",
    "termTranslation": "dormitory, lodging house",
    "ex": "彼は会社の寮に住んでいます。",
    "exReading": "かれ は かいしゃ の りょう に すんで います",
    "exTranslation": "He lives in the company dormitory.",
    "category": "Noun"
  },
  {
    "term": "偏見",
    "termReading": "へんけん",
    "termTranslation": "prejudice, biased view",
    "ex": "彼は世の中の偏見と戦ったんだ。",
    "exReading": "かれ は よのなか の へんけん と たたかった ん だ",
    "exTranslation": "He fought against prejudice in the world.",
    "category": "Noun"
  },
  {
    "term": "何遍",
    "termReading": "なんべん",
    "termTranslation": "how many times",
    "ex": "この映画は何遍も見ました。",
    "exReading": "この えいが は なんべん も みました",
    "exTranslation": "I've seen this movie several times.",
    "category": "Noun"
  },
  {
    "term": "宣伝",
    "termReading": "せんでん",
    "termTranslation": "publicity, advertisement",
    "ex": "その商品の宣伝をよく見かけます。",
    "exReading": "その しょうひん の せんでん を よく みかけます",
    "exTranslation": "I often see advertisements for this product.",
    "category": "Verbal Noun"
  },
  {
    "term": "まれ",
    "termReading": "まれ",
    "termTranslation": "rarely",
    "ex": "彼が仕事を休むのはまれです。",
    "exReading": "かれ が しごと を やすむ の は まれ です",
    "exTranslation": "It's rare that he takes a day off from work.",
    "category": "Adjective"
  },
  {
    "term": "展覧会",
    "termReading": "てんらんかい",
    "termTranslation": "exhibition",
    "ex": "昨日、絵の展覧会に行ってきました。",
    "exReading": "きのう え の てんらんかい に いって きました",
    "exTranslation": "I went to an exhibition yesterday.",
    "category": "Noun"
  },
  {
    "term": "総理大臣",
    "termReading": "そうりだいじん",
    "termTranslation": "prime minister",
    "ex": "国民の6割が総理大臣を支持しています。",
    "exReading": "こくみん の 6 わり が そうりだいじん を しじ して います",
    "exTranslation": "60 percent of the people support the Prime Minister.",
    "category": "Noun"
  },
  {
    "term": "大臣",
    "termReading": "だいじん",
    "termTranslation": "minister",
    "ex": "大臣が汚職で逮捕されました。",
    "exReading": "だいじん が おしょく で たいほ されました",
    "exTranslation": "The minister was arrested for corruption.",
    "category": "Noun"
  },
  {
    "term": "臨む",
    "termReading": "のぞむ",
    "termTranslation": "be present at",
    "ex": "選手は最高の状態で大会に臨んだわ。",
    "exReading": "せんしゅ は さいこう の じょうたい で たいかい に のぞんだ わ",
    "exTranslation": "Athletes took part in the meet in top condition.",
    "category": "Verb"
  },
  {
    "term": "臨時",
    "termReading": "りんじ",
    "termTranslation": "temporary, provisional",
    "ex": "彼女は臨時の店員です。",
    "exReading": "かのじょ は りんじ の てんいん です",
    "exTranslation": "She's a temporary clerk.",
    "category": "Noun"
  },
  {
    "term": "ミスプリント",
    "termReading": "ミスプリント",
    "termTranslation": "misprint, error",
    "ex": "資料にミスプリントがありました。",
    "exReading": "しりょう に みすぷりんと が ありました",
    "exTranslation": "There was a misprint in the materials.",
    "category": "Noun"
  },
  {
    "term": "内閣",
    "termReading": "ないかく",
    "termTranslation": "cabinet",
    "ex": "新しい内閣が誕生したね。",
    "exReading": "あたらしい ないかく が たんじょう した ね",
    "exTranslation": "A new Cabinet was formed.",
    "category": "Noun"
  },
  {
    "term": "闘う",
    "termReading": "たたかう",
    "termTranslation": "fight",
    "ex": "彼は病気と闘ったの。",
    "exReading": "かれ は びょうき と たたかった の",
    "exTranslation": "He battled against his illness.",
    "category": "Verb"
  },
  {
    "term": "派閥",
    "termReading": "はばつ",
    "termTranslation": "clique, faction",
    "ex": "あの大学には派閥がたくさんあります。",
    "exReading": "あの だいがく に は はばつ が たくさん あります",
    "exTranslation": "There are a lot of factions at that university.",
    "category": "Noun"
  },
  {
    "term": "懐かしい",
    "termReading": "なつかしい",
    "termTranslation": "longed-for, old",
    "ex": "ここは私にとって懐かしい場所です。",
    "exReading": "ここ は わたし に とって なつかしい ばしょ です",
    "exTranslation": "This is a nostalgic place for me.",
    "category": "Adjective"
  },
  {
    "term": "噴火",
    "termReading": "ふんか",
    "termTranslation": "eruption, volcanic activity",
    "ex": "島で火山が噴火したよ。",
    "exReading": "しま で かざん が ふんか した よ",
    "exTranslation": "The volcano erupted on the island.",
    "category": "Verbal Noun"
  },
  {
    "term": "噴水",
    "termReading": "ふんすい",
    "termTranslation": "jet, fountain",
    "ex": "公園の噴水の前で会いましょう。",
    "exReading": "こうえん の ふんすい の まえ で あいましょう",
    "exTranslation": "Let's meet in front of the fountain in the park.",
    "category": "Noun"
  },
  {
    "term": "むく",
    "termReading": "むく",
    "termTranslation": "peel, pare",
    "ex": "人参の皮をむきました。",
    "exReading": "にんじん の かわ を むきました",
    "exTranslation": "I peeled the carrot.",
    "category": "Verb"
  },
  {
    "term": "不愉快",
    "termReading": "ふゆかい",
    "termTranslation": "unpleasant, disagreeable",
    "ex": "彼の話を聞いて不愉快になった。",
    "exReading": "かれ の はなし を きいて ふゆかい に なった",
    "exTranslation": "I got upset listening to his story.",
    "category": "Adjectival Noun"
  },
  {
    "term": "愉快",
    "termReading": "ゆかい",
    "termTranslation": "pleasant, delightful",
    "ex": "彼らはとても愉快な人たちです。",
    "exReading": "かれら は とても ゆかい な ひとたち です",
    "exTranslation": "They're very pleasant people.",
    "category": "Adjectival Noun"
  },
  {
    "term": "漫画",
    "termReading": "まんが",
    "termTranslation": "manga, comics",
    "ex": "妹は漫画が好きです。",
    "exReading": "いもうと は まんが が すき です",
    "exTranslation": "My little sister likes manga.",
    "category": "Noun"
  },
  {
    "term": "電卓",
    "termReading": "でんたく",
    "termTranslation": "pocket calculator",
    "ex": "この電卓はポケットに入ります。",
    "exReading": "この でんたく は ぽけっと に はいります",
    "exTranslation": "This calculator fits in your pocket.",
    "category": "Noun"
  },
  {
    "term": "雄大",
    "termReading": "ゆうだい",
    "termTranslation": "grand, magnificent",
    "ex": "雄大な景色に感動したの。",
    "exReading": "ゆうだい な けしき に かんどう した の",
    "exTranslation": "I was impressed by the magnificent scenery.",
    "category": "Adjective"
  },
  {
    "term": "めくる",
    "termReading": "めくる",
    "termTranslation": "turn over",
    "ex": "彼はページをめくったの。",
    "exReading": "かれ は ぺーじ を めくった の",
    "exTranslation": "He turned the page.",
    "category": "Verb"
  },
  {
    "term": "雌",
    "termReading": "めす",
    "termTranslation": "female",
    "ex": "この猫は雌です。",
    "exReading": "この ねこ は めす です",
    "exTranslation": "This cat is a female.",
    "category": "Noun"
  },
  {
    "term": "幼稚",
    "termReading": "ようち",
    "termTranslation": "childish, infantile",
    "ex": "その考えは少し幼稚だと思う。",
    "exReading": "その かんがえ は すこし ようち だ と おもう",
    "exTranslation": "I think that idea's a bit childish.",
    "category": "Adjectival Noun"
  },
  {
    "term": "名称",
    "termReading": "めいしょう",
    "termTranslation": "name",
    "ex": "みんなで製品の名称を考えました。",
    "exReading": "みんな で せいひん の めいしょう を かんがえました",
    "exTranslation": "Everyone thought about the name of the product.",
    "category": "Noun"
  },
  {
    "term": "秩序",
    "termReading": "ちつじょ",
    "termTranslation": "order, method",
    "ex": "その国の社会秩序は乱れているわ。",
    "exReading": "その くに の しゃかい ちつじょ は みだれて いる わ",
    "exTranslation": "Social order in that country is in a state of chaos.",
    "category": "Noun"
  },
  {
    "term": "物陰",
    "termReading": "ものかげ",
    "termTranslation": "place behind something",
    "ex": "私たちは物陰に隠れたんだ。",
    "exReading": "わたしたち は ものかげ に かくれた ん だ",
    "exTranslation": "We hid ourselves away.",
    "category": "Noun"
  },
  {
    "term": "めでたい",
    "termReading": "めでたい",
    "termTranslation": "happy, fortunate",
    "ex": "それはめでたいことだ。",
    "exReading": "それはめでたいことだ。",
    "exTranslation": "That's good news.",
    "category": "Adjective"
  },
  {
    "term": "魅力",
    "termReading": "みりょく",
    "termTranslation": "charm, attraction",
    "ex": "彼女の明るさに魅力を感じました。",
    "exReading": "かのじょ の あかるさ に みりょく を かんじました",
    "exTranslation": "I was charmed by her cheerfulness.",
    "category": "Noun"
  },
  {
    "term": "醜い",
    "termReading": "みにくい",
    "termTranslation": "ugly, bad-looking",
    "ex": "醜い争いはやめましょう。",
    "exReading": "みにくい あらそい は やめましょう",
    "exTranslation": "Let's stop an ugly fight.",
    "category": "Adjective"
  },
  {
    "term": "悪賢い",
    "termReading": "わるがしこい",
    "termTranslation": "cunning, sly",
    "ex": "彼は悪賢い男だな。",
    "exReading": "かれ は わるがしこい おとこ だ な",
    "exTranslation": "He's a cunning fellow.",
    "category": "Adjective"
  },
  {
    "term": "頂上",
    "termReading": "ちょうじょう",
    "termTranslation": "summit, climax",
    "ex": "あと少しで山の頂上です。",
    "exReading": "あと すこし で やま の ちょうじょう です",
    "exTranslation": "The top of the mountain is not far from here.",
    "category": "Noun"
  },
  {
    "term": "近頃",
    "termReading": "ちかごろ",
    "termTranslation": "recently, nowadays",
    "ex": "近頃、彼女の様子がおかしい。",
    "exReading": "ちかごろ かのじょ の ようす が おかしい",
    "exTranslation": "She's been behaving strangely these days.",
    "category": "Noun"
  },
  {
    "term": "矛盾",
    "termReading": "むじゅん",
    "termTranslation": "contradiction",
    "ex": "彼の言っていることは矛盾しています。",
    "exReading": "かれ の いって いる こと は むじゅん して います",
    "exTranslation": "What he says is contradictory.",
    "category": "Verbal Noun"
  },
  {
    "term": "もうかる",
    "termReading": "もうかる",
    "termTranslation": "make a profit, be profitable",
    "ex": "彼の商売はもうかっているらしいね。",
    "exReading": "かれ の しょうばい は もうかって いる らしい ね",
    "exTranslation": "His business seems to be doing well.",
    "category": "Verb"
  },
  {
    "term": "倉庫",
    "termReading": "そうこ",
    "termTranslation": "warehouse, storehouse",
    "ex": "この荷物を倉庫に運びましょう。",
    "exReading": "この にもつ を そうこ に はこびましょう",
    "exTranslation": "Let's carry this luggage to the warehouse.",
    "category": "Noun"
  },
  {
    "term": "創立",
    "termReading": "そうりつ",
    "termTranslation": "establishment, organization",
    "ex": "この学校は1962年に創立されました。",
    "exReading": "この がっこう は 1962 ねん に そうりつ されました",
    "exTranslation": "This school was established in 1962.",
    "category": "Verbal Noun"
  },
  {
    "term": "罰金",
    "termReading": "ばっきん",
    "termTranslation": "fine, penalty",
    "ex": "罰金を3万円もとられたよ。",
    "exReading": "ばっきん を 3 まんえん も とられた よ",
    "exTranslation": "I was fined no less than 30,000 yen.",
    "category": "Noun"
  },
  {
    "term": "罰する",
    "termReading": "ばっする",
    "termTranslation": "punish",
    "ex": "彼は違法駐車で罰せられたんだ。",
    "exReading": "かれ は いほう ちゅうしゃ で ばっせられた ん だ",
    "exTranslation": "He was fined for illegal parking.",
    "category": "Verb"
  },
  {
    "term": "罰",
    "termReading": "ばつ",
    "termTranslation": "punishment, penalty",
    "ex": "彼は悪いことをしたので罰を受けたんだ。",
    "exReading": "かれ は わるい こと を した ので ばつ を うけた ん だ",
    "exTranslation": "He did something bad so he was punished.",
    "category": "Noun"
  },
  {
    "term": "もうけ",
    "termReading": "もうけ",
    "termTranslation": "profits, earnings",
    "ex": "今日のもうけは2万円でした。",
    "exReading": "きょう の もうけ は 2 まんえん でした",
    "exTranslation": "Today's profit was 20,000 yen.",
    "category": "Noun"
  },
  {
    "term": "老人",
    "termReading": "ろうじん",
    "termTranslation": "old person, elderly",
    "ex": "子供が老人の手を引いて歩いていたの。",
    "exReading": "こども が ろうじん の て を ひいて あるいて いた の",
    "exTranslation": "The child held the old person's hand as they walked.",
    "category": "Noun"
  },
  {
    "term": "煮る",
    "termReading": "にる",
    "termTranslation": "boil, cook",
    "ex": "今、豆を煮ています。",
    "exReading": "いま まめ を にて います",
    "exTranslation": "I'm boiling some beans now.",
    "category": "Verb"
  },
  {
    "term": "煮える",
    "termReading": "にえる",
    "termTranslation": "boil, be boiled",
    "ex": "もうすぐじゃがいもが煮えます。",
    "exReading": "もうすぐ じゃがいも が にえます",
    "exTranslation": "The potato will be cooked soon.",
    "category": "Verb"
  },
  {
    "term": "蒸し暑い",
    "termReading": "むしあつい",
    "termTranslation": "humid",
    "ex": "今日は蒸し暑いね。",
    "exReading": "きょう は むしあつい ね",
    "exTranslation": "It's humid today.",
    "category": "Adjective"
  },
  {
    "term": "黙る",
    "termReading": "だまる",
    "termTranslation": "become silent, shut one's mouth",
    "ex": "彼はいつも黙って仕事をします。",
    "exReading": "かれ は いつも だまって しごと を します",
    "exTranslation": "He always works in silence.",
    "category": "Verb"
  },
  {
    "term": "もしかしたら",
    "termReading": "もしかしたら",
    "termTranslation": "possibly, perhaps",
    "ex": "もしかしたら来年転勤になるかも知れない。",
    "exReading": "もしかしたら らいねん てんきん に なる かも しれない",
    "exTranslation": "It's possible that I might be transferred next year.",
    "category": "Adverb"
  },
  {
    "term": "無駄",
    "termReading": "むだ",
    "termTranslation": "no good, pointless",
    "ex": "それは時間の無駄です。",
    "exReading": "それ は じかん の むだ です",
    "exTranslation": "It's a waste of time.",
    "category": "Adjectival Noun"
  },
  {
    "term": "無駄遣い",
    "termReading": "むだづかい",
    "termTranslation": "wasting money",
    "ex": "これは税金の無駄遣いだね。",
    "exReading": "これ は ぜいきん の むだづかい だ ね",
    "exTranslation": "This is a waste of tax money.",
    "category": "Verbal Noun"
  },
  {
    "term": "沸騰",
    "termReading": "ふっとう",
    "termTranslation": "boiling",
    "ex": "水が沸騰したよ。",
    "exReading": "みず が ふっとう した よ",
    "exTranslation": "The water boiled.",
    "category": "Verbal Noun"
  },
  {
    "term": "返却",
    "termReading": "へんきゃく",
    "termTranslation": "return",
    "ex": "図書館に本を返却したよ。",
    "exReading": "としょかん に ほん を へんきゃく した よ",
    "exTranslation": "I returned the book to the library.",
    "category": "Verbal Noun"
  },
  {
    "term": "庁",
    "termReading": "ちょう",
    "termTranslation": "public office, agency",
    "ex": "彼は県庁で働いています。",
    "exReading": "かれ は けんちょう で はたらいて います",
    "exTranslation": "He works for the prefectural government.",
    "category": "Noun"
  },
  {
    "term": "もてる",
    "termReading": "もてる",
    "termTranslation": "be popular with the opposite sex",
    "ex": "彼は非常にもてるね。",
    "exReading": "かれ は ひじょう に もてる ね",
    "exTranslation": "He's very popular.",
    "category": "Verb"
  },
  {
    "term": "都庁",
    "termReading": "とちょう",
    "termTranslation": "Tokyo Metropolitan City Hall",
    "ex": "あの高い建物が都庁です。",
    "exReading": "あの たかい たてもの が とちょう です",
    "exTranslation": "That tall building is Tokyo Metropolitan City Hall.",
    "category": "Noun"
  },
  {
    "term": "府庁",
    "termReading": "ふちょう",
    "termTranslation": "urban prefectural office",
    "ex": "彼は府庁で働いています。",
    "exReading": "かれ は ふちょう で はたらいて います",
    "exTranslation": "He works for the prefectural office.",
    "category": "Noun"
  },
  {
    "term": "摩擦",
    "termReading": "まさつ",
    "termTranslation": "friction, rubbing",
    "ex": "その二つの国に摩擦が生じたんだ。",
    "exReading": "その ふたつ の くに に まさつ が しょうじた ん だ",
    "exTranslation": "Friction arose between the two countries.",
    "category": "Verbal Noun"
  },
  {
    "term": "先輩",
    "termReading": "せんぱい",
    "termTranslation": "senior, superior",
    "ex": "日本人は先輩、後輩の関係をとても大切に考えます。",
    "exReading": "にっぽんじん は せんぱい こうはい の かんけい を とても たいせつ に かんがえます",
    "exTranslation": "Japanese people value the relationship between seniors and juniors.",
    "category": "Noun"
  },
  {
    "term": "冒険",
    "termReading": "ぼうけん",
    "termTranslation": "adventure, risk",
    "ex": "昔の子供たちは冒険小説をよく読みました。",
    "exReading": "むかし の こどもたち は ぼうけん しょうせつ を よく よみました",
    "exTranslation": "Children in the past often read adventure novels.",
    "category": "Verbal Noun"
  },
  {
    "term": "牧場",
    "termReading": "ぼくじょう",
    "termTranslation": "stock farm, pasture",
    "ex": "叔父は牧場を持っているんだ。",
    "exReading": "おじ は ぼくじょう を もって いる ん だ",
    "exTranslation": "My uncle has a ranch.",
    "category": "Noun"
  },
  {
    "term": "ゆでる",
    "termReading": "ゆでる",
    "termTranslation": "boil",
    "ex": "今、野菜をゆでています。",
    "exReading": "いま やさい を ゆでて います",
    "exTranslation": "I'm boiling vegetables now.",
    "category": "Verb"
  },
  {
    "term": "取り敢えず",
    "termReading": "とりあえず",
    "termTranslation": "for now, first of all",
    "ex": "取り敢えずお知らせしておきます。",
    "exReading": "とりあえず おしらせ して おきます",
    "exTranslation": "Here's my report, for now.",
    "category": "Adverb"
  },
  {
    "term": "勇気",
    "termReading": "ゆうき",
    "termTranslation": "courage, nerve",
    "ex": "彼は勇気があるね。",
    "exReading": "かれ は ゆうき が ある ね",
    "exTranslation": "He's courageous.",
    "category": "Noun"
  },
  {
    "term": "勇敢",
    "termReading": "ゆうかん",
    "termTranslation": "brave, heroic",
    "ex": "彼女はとても勇敢でした。",
    "exReading": "かのじょ は とても ゆうかん でした",
    "exTranslation": "She was very brave.",
    "category": "Adjectival Noun"
  },
  {
    "term": "励ます",
    "termReading": "はげます",
    "termTranslation": "encourage, urge",
    "ex": "父が息子を励ましたの。",
    "exReading": "ちち が むすこ を はげました の",
    "exTranslation": "The father encouraged his son.",
    "category": "Verb"
  },
  {
    "term": "露",
    "termReading": "つゆ",
    "termTranslation": "dew, dewdrop",
    "ex": "花に露がついていました。",
    "exReading": "はな に つゆ が ついて いました",
    "exTranslation": "There was some dew on the flower.",
    "category": "Noun"
  },
  {
    "term": "よける",
    "termReading": "よける",
    "termTranslation": "avoid, evade",
    "ex": "彼は飛んできたボールをよけたの。",
    "exReading": "かれ は とんで きた ぼーる を よけた の",
    "exTranslation": "He dodged the ball that flew towards him.",
    "category": "Verb"
  },
  {
    "term": "零下",
    "termReading": "れいか",
    "termTranslation": "below zero, sub-zero",
    "ex": "今日は零下の寒さでしたね。",
    "exReading": "きょう は れいか の さむさ でした ね",
    "exTranslation": "The cold was below zero today.",
    "category": "Noun"
  },
  {
    "term": "雰囲気",
    "termReading": "ふんいき",
    "termTranslation": "atmosphere, mood",
    "ex": "とても雰囲気のいいお店ですね。",
    "exReading": "とても ふんいき の いい おみせ です ね",
    "exTranslation": "The shop has a really good atmosphere.",
    "category": "Noun"
  },
  {
    "term": "盆地",
    "termReading": "ぼんち",
    "termTranslation": "valley, basin",
    "ex": "盆地は夏、とても暑いんだ。",
    "exReading": "ぼんち は なつ、とても あつい ん だ",
    "exTranslation": "It's very hot in the valley in summer.",
    "category": "Noun"
  },
  {
    "term": "盆",
    "termReading": "ぼん",
    "termTranslation": "Bon Festival",
    "ex": "お盆は実家に帰りました。",
    "exReading": "お ぼん は じっか に かえりました",
    "exTranslation": "I returned to my parents' home at the Bon festival.",
    "category": "Noun"
  },
  {
    "term": "盆",
    "termReading": "ぼん",
    "termTranslation": "tray",
    "ex": "母が料理をお盆に乗せたの。",
    "exReading": "はは が りょうり を おぼん に のせた の",
    "exTranslation": "My mother put the dishes on a tray.",
    "category": "Noun"
  },
  {
    "term": "よこす",
    "termReading": "よこす",
    "termTranslation": "send, hand over",
    "ex": "父が長い手紙をよこしたよ。",
    "exReading": "ちち が ながい てがみ を よこした よ",
    "exTranslation": "My father sent a long letter.",
    "category": "Verb"
  },
  {
    "term": "大衆",
    "termReading": "たいしゅう",
    "termTranslation": "the masses, the populace",
    "ex": "能は元々大衆の文化でした。",
    "exReading": "のう は もともと たいしゅう の ぶんか でした",
    "exTranslation": "Noh drama was originally the culture of the masses.",
    "category": "Noun"
  },
  {
    "term": "舞台",
    "termReading": "ぶたい",
    "termTranslation": "stage, setting",
    "ex": "その物語は京都が舞台だ。",
    "exReading": "その ものがたり は きょうと が ぶたい だ",
    "exTranslation": "The story is set in Kyoto.",
    "category": "Noun"
  },
  {
    "term": "見舞う",
    "termReading": "みまう",
    "termTranslation": "visit someone",
    "ex": "昨日友人を見舞ったの。",
    "exReading": "きのう ゆうじん を みまった の",
    "exTranslation": "I went to visit a friend in hospital yesterday.",
    "category": "Verb"
  },
  {
    "term": "見舞い",
    "termReading": "みまい",
    "termTranslation": "personal visit",
    "ex": "友達が見舞いに来てくれたよ。",
    "exReading": "ともだち が みまい に きて くれた よ",
    "exTranslation": "My friend came to visit me in hospital.",
    "category": "Noun"
  },
  {
    "term": "盆踊り",
    "termReading": "ぼんおどり",
    "termTranslation": "Bon Festival dance",
    "ex": "みんなで盆踊りに行きました。",
    "exReading": "みんな で ぼんおどり に いきました",
    "exTranslation": "We all went to the Bon festival dance.",
    "category": "Noun"
  },
  {
    "term": "跳ねる",
    "termReading": "はねる",
    "termTranslation": "leap, hop, bound",
    "ex": "ウサギが雪の上を跳ねていったよ。",
    "exReading": "うさぎ が ゆき の うえ を はねて いった よ",
    "exTranslation": "The rabbit hopped through the snow.",
    "category": "Verb"
  },
  {
    "term": "よす",
    "termReading": "よす",
    "termTranslation": "quit, stop",
    "ex": "人をからかうのはよしなさい。",
    "exReading": "ひと を からかう の は よしなさい",
    "exTranslation": "Stop making fun of people.",
    "category": "Verb"
  },
  {
    "term": "銭湯",
    "termReading": "せんとう",
    "termTranslation": "public bath, bathhouse",
    "ex": "銭湯は昔より少なくなったわね。",
    "exReading": "せんとう は むかし より すくなく なった わ ね",
    "exTranslation": "There are fewer public bathhouses today than there used to be.",
    "category": "Noun"
  },
  {
    "term": "古里",
    "termReading": "ふるさと",
    "termTranslation": "hometown, birthplace",
    "ex": "いつも古里を懐かしく思い出すの。",
    "exReading": "いつも ふるさと を なつかしく おもいだす の",
    "exTranslation": "I always remember my hometown fondly.",
    "category": "Noun"
  },
  {
    "term": "狙う",
    "termReading": "ねらう",
    "termTranslation": "aim, be after",
    "ex": "来年は優勝を狙います。",
    "exReading": "らいねん は ゆうしょう を ねらいます",
    "exTranslation": "We will aim for a victory next year.",
    "category": "Verb"
  },
  {
    "term": "彫る",
    "termReading": "ほる",
    "termTranslation": "engrave, carve",
    "ex": "彼は木の像を彫ったの。",
    "exReading": "かれ は き の ぞう を ほった の",
    "exTranslation": "He carved a wooden image.",
    "category": "Verb"
  },
  {
    "term": "中央",
    "termReading": "ちゅうおう",
    "termTranslation": "center",
    "ex": "その公園は町のほぼ中央に位置するんだ。",
    "exReading": "その こうえん は まち の ほぼ ちゅうおう に いち する ん だ",
    "exTranslation": "The park is located almost in the center of town.",
    "category": "Noun"
  },
  {
    "term": "レクリエーション",
    "termReading": "レクリエーション",
    "termTranslation": "recreation, games",
    "ex": "町内会のレクリエーションに参加したの。",
    "exReading": "ちょうないかい の れくりえーしょん に さんか した の",
    "exTranslation": "I took part in the recreational activities organized by the neighborhood association.",
    "category": "Noun"
  },
  {
    "term": "双子",
    "termReading": "ふたご",
    "termTranslation": "twins",
    "ex": "友達に双子が生まれたよ。",
    "exReading": "ともだち に ふたご が うまれた よ",
    "exTranslation": "My friend gave birth to twins.",
    "category": "Noun"
  },
  {
    "term": "裸",
    "termReading": "はだか",
    "termTranslation": "naked body, bareness",
    "ex": "彼らは裸のつきあいをしているよ。",
    "exReading": "かれら は はだか の つきあい を して いる よ",
    "exTranslation": "They have an open relationship with each other.",
    "category": "Noun"
  },
  {
    "term": "祖先",
    "termReading": "そせん",
    "termTranslation": "ancestor, forefather",
    "ex": "犬も猫も祖先は同じ動物らしいよ。",
    "exReading": "いぬ も ねこ も そせん は おなじ どうぶつ らしい よ",
    "exTranslation": "I hear that the ancestor of dogs and cats is the same animal.",
    "category": "Noun"
  },
  {
    "term": "報酬",
    "termReading": "ほうしゅう",
    "termTranslation": "remuneration, reward",
    "ex": "その仕事の報酬として20万円もらいました。",
    "exReading": "その しごと の ほうしゅう と して 20 まんえん もらいました",
    "exTranslation": "I was remunerated 200,000 yen for this work.",
    "category": "Noun"
  },
  {
    "term": "無邪気",
    "termReading": "むじゃき",
    "termTranslation": "innocence, simplicity",
    "ex": "子供たちが無邪気に遊んでいるね。",
    "exReading": "こどもたち が むじゃき に あそんで いる ね",
    "exTranslation": "The kids are playing so innocently.",
    "category": "Adjectival Noun"
  },
  {
    "term": "ろうそく",
    "termReading": "ろうそく",
    "termTranslation": "candle",
    "ex": "バースデーケーキにろうそくを立てました。",
    "exReading": "ばーすでーけーき に ろうそく を たてました",
    "exTranslation": "We put candles on the birthday cake.",
    "category": "Noun"
  },
  {
    "term": "玉ねぎ",
    "termReading": "たまねぎ",
    "termTranslation": "onion",
    "ex": "私は玉ねぎが嫌いです。",
    "exReading": "わたし は たまねぎ が きらい です 。",
    "exTranslation": "I hate onions.",
    "category": "Noun"
  },
  {
    "term": "玉",
    "termReading": "たま",
    "termTranslation": "bead, ball",
    "ex": "彼の顔に玉のような汗が流れていたの。",
    "exReading": "かれ の かお に たま の よう な あせ が ながれて いた の",
    "exTranslation": "Beads of sweat rolled down his face.",
    "category": "Noun"
  },
  {
    "term": "天皇",
    "termReading": "てんのう",
    "termTranslation": "emperor",
    "ex": "日本には天皇がいます。",
    "exReading": "にっぽん に は てんのう が います",
    "exTranslation": "Japan has an emperor.",
    "category": "Noun"
  },
  {
    "term": "風呂場",
    "termReading": "ふろば",
    "termTranslation": "bathroom",
    "ex": "私は風呂場で歯を磨きます。",
    "exReading": "わたし は ふろば で は を みがきます",
    "exTranslation": "I brush my teeth in the bathroom.",
    "category": "Noun"
  },
  {
    "term": "班",
    "termReading": "はん",
    "termTranslation": "squad, group",
    "ex": "班のメンバーは5人です。",
    "exReading": "はん の めんばー は 5 にん です",
    "exTranslation": "There are five members in the group.",
    "category": "Noun"
  },
  {
    "term": "召し上がる",
    "termReading": "めしあがる",
    "termTranslation": "eat, drink",
    "ex": "どうぞ召し上がってください。",
    "exReading": "どうぞ めしあがって ください",
    "exTranslation": "Please begin eating.",
    "category": "Verb"
  },
  {
    "term": "わがまま",
    "termReading": "わがまま",
    "termTranslation": "selfishness, willfulness",
    "ex": "子供のわがままを叱ったの。",
    "exReading": "こども の わがまま を しかった の",
    "exTranslation": "I yelled at my kid for being selfish.",
    "category": "Adjectival Noun"
  },
  {
    "term": "哲学",
    "termReading": "てつがく",
    "termTranslation": "philosophy",
    "ex": "哲学は興味深い学問です。",
    "exReading": "てつがく は きょうみぶかい がくもん です",
    "exTranslation": "Philosophy is an interesting field of study.",
    "category": "Noun"
  },
  {
    "term": "綱",
    "termReading": "つな",
    "termTranslation": "rope, line",
    "ex": "この綱は直径20cmあるそうです。",
    "exReading": "この つな は ちょっけい 20 せんち ある そう です",
    "exTranslation": "I hear the diameter of the rope is 20cm.",
    "category": "Noun"
  },
  {
    "term": "縄",
    "termReading": "なわ",
    "termTranslation": "rope, cord",
    "ex": "枝を縄でしばったよ。",
    "exReading": "えだ を なわ で しばった よ",
    "exTranslation": "I bound the branch with the rope.",
    "category": "Noun"
  },
  {
    "term": "縫う",
    "termReading": "ぬう",
    "termTranslation": "sew, stitch",
    "ex": "彼女は子供のスカートを縫ったんだ。",
    "exReading": "かのじょ は こども の すかーと を ぬった ん だ",
    "exTranslation": "She made a child's skirt.",
    "category": "Verb"
  },
  {
    "term": "虹",
    "termReading": "にじ",
    "termTranslation": "rainbow",
    "ex": "雨が上がって美しい虹が出たね。",
    "exReading": "あめ が あがって うつくしい にじ が でた ね 。",
    "exTranslation": "The rain stopped and a beautiful rainbow came out.",
    "category": "Noun"
  },
  {
    "term": "わく",
    "termReading": "わく",
    "termTranslation": "well up",
    "ex": "友達に励まされて勇気がわいたよ。",
    "exReading": "ともだち に はげまされて ゆうき が わいた よ",
    "exTranslation": "I felt courage well up within me after my friends' encouragement.",
    "category": "Verb"
  },
  {
    "term": "梅雨明け",
    "termReading": "つゆあけ",
    "termTranslation": "end of the rainy season",
    "ex": "梅雨明けは来週だそうです。",
    "exReading": "つゆあけ は らいしゅう だ そう です",
    "exTranslation": "I heard that the rainy season will be over next week.",
    "category": "Verbal Noun"
  },
  {
    "term": "梅雨入り",
    "termReading": "つゆいり",
    "termTranslation": "start of the rainy season",
    "ex": "梅雨入りは6月17日でした。",
    "exReading": "つゆいり は 6 がつ 17 にち でした",
    "exTranslation": "The rainy season started on June 17.",
    "category": "Verbal Noun"
  },
  {
    "term": "欄",
    "termReading": "らん",
    "termTranslation": "column, field",
    "ex": "お名前は上の欄にお書きください。",
    "exReading": "お なまえ は うえ の らん に お かき ください",
    "exTranslation": "Please write your name in the top box.",
    "category": "Noun"
  },
  {
    "term": "栓抜き",
    "termReading": "せんぬき",
    "termTranslation": "bottle opener, corkscrew",
    "ex": "栓抜きはどこですか。",
    "exReading": "せんぬき は どこ です か",
    "exTranslation": "Where's the bottle opener?",
    "category": "Noun"
  },
  {
    "term": "峠",
    "termReading": "とうげ",
    "termTranslation": "mountain pass, ridge",
    "ex": "この峠を越えると村があります。",
    "exReading": "この とうげ を こえる と むら が あります",
    "exTranslation": "There's a village if you go over this mountain pass.",
    "category": "Noun"
  },
  {
    "term": "ばからしい",
    "termReading": "ばからしい",
    "termTranslation": "stupid, useless",
    "ex": "小さなことにくよくよするのはばからしいよ。",
    "exReading": "ちいさ な こと に くよくよ する の は ばからしい よ",
    "exTranslation": "It's stupid to worry about such a small thing.",
    "category": "Adjective"
  },
  {
    "term": "婆さん",
    "termReading": "ばあさん",
    "termTranslation": "grandmother, old lady",
    "ex": "彼女は元気なお婆さんね。",
    "exReading": "かのじょ は げんき な おばあさん ね",
    "exTranslation": "She's a sprightly old lady.",
    "category": "Noun"
  },
  {
    "term": "漬ける",
    "termReading": "つける",
    "termTranslation": "pickle, marinade",
    "ex": "魚を味噌に漬けました。",
    "exReading": "さかな を みそ に つけました",
    "exTranslation": "I marinaded the fish in miso.",
    "category": "Verb"
  },
  {
    "term": "漬け物",
    "termReading": "つけもの",
    "termTranslation": "pickle, pickled vegetable",
    "ex": "祖母は漬け物を自分で作ります。",
    "exReading": "そぼ は つけもの を じぶん で つくります",
    "exTranslation": "My grandmother makes her own pickles.",
    "category": "Noun"
  },
  {
    "term": "潰す",
    "termReading": "つぶす",
    "termTranslation": "crush, smash",
    "ex": "ペットボトルは潰して捨てましょう。",
    "exReading": "ぺっとぼとる は つぶして すてましょう",
    "exTranslation": "You should crush plastic bottles before throwing them away.",
    "category": "Verb"
  },
  {
    "term": "潰れる",
    "termReading": "つぶれる",
    "termTranslation": "crush",
    "ex": "箱の角が潰れていますよ。",
    "exReading": "はこ の かど が つぶれて います よ",
    "exTranslation": "The corner of the box is squashed.",
    "category": "Verb"
  },
  {
    "term": "なぜか",
    "termReading": "なぜか",
    "termTranslation": "for some reason",
    "ex": "今日はなぜか体がだるい。",
    "exReading": "きょう は なぜか からだ が だるい",
    "exTranslation": "I feel heavy today for some reason.",
    "category": "Adverb"
  },
  {
    "term": "年賀",
    "termReading": "ねんが",
    "termTranslation": "New Year's card",
    "ex": "年賀葉書が売り出されたね。",
    "exReading": "ねんがはがき が うりだされた ね",
    "exTranslation": "New Year's greeting cards are now on sale.",
    "category": "Noun"
  },
  {
    "term": "賑わう",
    "termReading": "にぎわう",
    "termTranslation": "bustle, enliven",
    "ex": "ここは正月はたくさんの人で賑わいます。",
    "exReading": "ここ は しょうがつ は たくさん の ひと で にぎわいます",
    "exTranslation": "It's lively here with a lot of people at New Years.",
    "category": "Verb"
  },
  {
    "term": "蛇",
    "termReading": "へび",
    "termTranslation": "snake, serpent",
    "ex": "蛇がカエルを捕まえたの。",
    "exReading": "へび が かえる を つかまえた の",
    "exTranslation": "The snake captured the frog.",
    "category": "Noun"
  },
  {
    "term": "華やか",
    "termReading": "はなやか",
    "termTranslation": "gorgeous, colorful",
    "ex": "彼女は華やかな女性ですね。",
    "exReading": "かのじょ は はなやか な じょせい です ね",
    "exTranslation": "She's a gorgeous woman.",
    "category": "Adjective"
  },
  {
    "term": "挟む",
    "termReading": "はさむ",
    "termTranslation": "trap",
    "ex": "ドアに指を挟んだ。",
    "exReading": "どあ に ゆび を はさんだ",
    "exTranslation": "I trapped my finger in the door.",
    "category": "Verb"
  },
  {
    "term": "塔",
    "termReading": "とう",
    "termTranslation": "tower, pagoda",
    "ex": "あの塔の高さはどれくらいですか。",
    "exReading": "あの とう の たかさ は どれ くらい です か",
    "exTranslation": "How tall is that tower?",
    "category": "Noun"
  },
  {
    "term": "にわかに",
    "termReading": "にわかに",
    "termTranslation": "rapidly",
    "ex": "空がにわかに暗くなったな。",
    "exReading": "そら が にわかに くらく なった な",
    "exTranslation": "The sky darkened rapidly.",
    "category": "Adverb"
  },
  {
    "term": "誓う",
    "termReading": "ちかう",
    "termTranslation": "vow, swear",
    "ex": "二人は一生を共にすることを誓ったの。",
    "exReading": "ふたり は いっしょう を ともに する こと を ちかった の",
    "exTranslation": "The couple swore to be together for a lifetime.",
    "category": "Verb"
  },
  {
    "term": "慰める",
    "termReading": "なぐさめる",
    "termTranslation": "console, comfort",
    "ex": "友人が慰めてくれました。",
    "exReading": "ゆうじん が なぐさめて くれました",
    "exTranslation": "My friend consoled me.",
    "category": "Verb"
  },
  {
    "term": "朗らか",
    "termReading": "ほがらか",
    "termTranslation": "cheerful, bright",
    "ex": "彼はとても朗らかな人です。",
    "exReading": "かれ は とても ほがらか な ひと です",
    "exTranslation": "He's a very cheerful person.",
    "category": "Adjective"
  },
  {
    "term": "蝶蝶",
    "termReading": "ちょうちょう",
    "termTranslation": "butterfly",
    "ex": "見て、きれいな蝶蝶が飛んでいる。",
    "exReading": "みて 、 きれい な ちょうちょう が とん でいる 。",
    "exTranslation": "Look, there's a beautiful butterfly flying.",
    "category": "Noun"
  },
  {
    "term": "鳩",
    "termReading": "はと",
    "termTranslation": "pigeon, dove",
    "ex": "公園の鳩にえさをやったの。",
    "exReading": "こうえん の はと に えさ を やった の",
    "exTranslation": "I fed the pigeons in the park.",
    "category": "Noun"
  },
  {
    "term": "ぴたりと",
    "termReading": "ぴたりと",
    "termTranslation": "suddenly",
    "ex": "風がぴたりと止んだね。",
    "exReading": "かぜ が ぴたりと やんだ ね",
    "exTranslation": "The wind suddenly died down.",
    "category": "Adverb"
  },
  {
    "term": "雛祭",
    "termReading": "ひなまつり",
    "termTranslation": "Doll Festival",
    "ex": "3月3日は雛祭りです。",
    "exReading": "3 がつ 3 か は ひなまつり です",
    "exTranslation": "March 3rd is the Doll Festival.",
    "category": "Noun"
  },
  {
    "term": "物凄い",
    "termReading": "ものすごい",
    "termTranslation": "tremendous",
    "ex": "夕方、物凄い雨が降ったね。",
    "exReading": "ゆうがた、 ものすごい あめ が ふった ね",
    "exTranslation": "There was a tremendous downpour earlier this evening.",
    "category": "Adjective"
  },
  {
    "term": "喉",
    "termReading": "のど",
    "termTranslation": "the throat",
    "ex": "風邪をひいて喉が痛いです。",
    "exReading": "かぜ を ひいて のど が いたい です",
    "exTranslation": "I caught a cold and have a sore throat.",
    "category": "Noun"
  },
  {
    "term": "唾",
    "termReading": "つば",
    "termTranslation": "saliva, spittle",
    "ex": "彼は地面に唾を吐いたよ。",
    "exReading": "かれ は じめん に つば を はいた よ",
    "exTranslation": "He spat on the ground.",
    "category": "Noun"
  },
  {
    "term": "吠える",
    "termReading": "ほえる",
    "termTranslation": "bark, bay",
    "ex": "どこかで犬が吠えていますね。",
    "exReading": "どこか で いぬ が ほえて います ね",
    "exTranslation": "A dog is barking somewhere.",
    "category": "Verb"
  },
  {
    "term": "ぴょんと",
    "termReading": "ぴょんと",
    "termTranslation": "jump",
    "ex": "子供が水たまりをぴょんと飛びこえたね。",
    "exReading": "こども が みずたまり を ぴょんと とびこえた ね",
    "exTranslation": "The child jumped over the puddle.",
    "category": "Adverb"
  },
  {
    "term": "吊るす",
    "termReading": "つるす",
    "termTranslation": "hang",
    "ex": "木にブランコを吊るしました。",
    "exReading": "き に ぶらんこ を つるしました",
    "exTranslation": "I hung a swing up on the tree.",
    "category": "Verb"
  },
  {
    "term": "味噌",
    "termReading": "みそ",
    "termTranslation": "miso, soybean paste",
    "ex": "きゅうりに味噌をつけて食べたんだ。",
    "exReading": "きゅうり に みそ を つけて たべた ん だ",
    "exTranslation": "I spread miso on the cucumber and ate it.",
    "category": "Noun"
  },
  {
    "term": "塞がる",
    "termReading": "ふさがる",
    "termTranslation": "close up",
    "ex": "傷口はもう塞がりました。",
    "exReading": "きずぐち は もう ふさがりました",
    "exTranslation": "The wound has already clotted.",
    "category": "Verb"
  },
  {
    "term": "塞ぐ",
    "termReading": "ふさぐ",
    "termTranslation": "close, shut",
    "ex": "あまりにうるさかったので耳を塞いだわ。",
    "exReading": "あまりに うるさかった の で みみ を ふさいだ わ",
    "exTranslation": "I blocked my ears as it was too noisy.",
    "category": "Verb"
  },
  {
    "term": "撫でる",
    "termReading": "なでる",
    "termTranslation": "stroke, pat",
    "ex": "猫の頭を撫でてあげた。",
    "exReading": "ねこ の あたま を なでて あげた",
    "exTranslation": "I patted the cat's head.",
    "category": "Verb"
  },
  {
    "term": "卑怯",
    "termReading": "ひきょう",
    "termTranslation": "cowardice, meanness",
    "ex": "彼らは卑怯な方法で勝利したの。",
    "exReading": "かれら は ひきょう な ほうほう で しょうり した の",
    "exTranslation": "They won by playing dirty.",
    "category": "Adjectival Noun"
  },
  {
    "term": "ひらりと",
    "termReading": "ひらりと",
    "termTranslation": "lightly, delicately",
    "ex": "桜の花びらがひらりと舞い落ちたな。",
    "exReading": "さくら の はなびら が ひらりと まいおちた な",
    "exTranslation": "The cherry blossom petals softly fluttered down.",
    "category": "Adverb"
  },
  {
    "term": "溜め息",
    "termReading": "ためいき",
    "termTranslation": "sigh",
    "ex": "母は溜め息をついたの。",
    "exReading": "はは は ためいき を ついた の",
    "exTranslation": "My mother sighed.",
    "category": "Noun"
  },
  {
    "term": "溜まる",
    "termReading": "たまる",
    "termTranslation": "accumulate, store up",
    "ex": "彼はストレスが溜まっているの。",
    "exReading": "かれ は すとれす が たまって いる の",
    "exTranslation": "He's under a lot of stress.",
    "category": "Verb"
  },
  {
    "term": "溜める",
    "termReading": "ためる",
    "termTranslation": "store, collect",
    "ex": "お風呂に水を溜めておいてください。",
    "exReading": "おふろ に みず を ためて おいて ください",
    "exTranslation": "Please draw the water for the bath.",
    "category": "Verb"
  },
  {
    "term": "海苔",
    "termReading": "のり",
    "termTranslation": "laver",
    "ex": "旅館の朝食にのりが出ました。",
    "exReading": "りょかん の ちょうしょく に のり が でました 。",
    "exTranslation": "Dried seaweed was served for breakfast at the inn.",
    "category": "Noun"
  },
  {
    "term": "遥か",
    "termReading": "はるか",
    "termTranslation": "far, distant",
    "ex": "遥か向こうに目的地が見えてきた。",
    "exReading": "はるか むこう に もくてきち が みえて きた",
    "exTranslation": "Far in the distance, our destination came into view.",
    "category": "Adjective"
  },
  {
    "term": "もうじき",
    "termReading": "もうじき",
    "termTranslation": "soon",
    "ex": "彼はもうじき父親になります。",
    "exReading": "かれ は もうじき ちちおや に なります",
    "exTranslation": "Soon he'lll become a father.",
    "category": "Adverb"
  },
  {
    "term": "股",
    "termReading": "また",
    "termTranslation": "crotch, groin",
    "ex": "自転車の乗り過ぎで股が痛いです。",
    "exReading": "じてんしゃ の のりすぎ で また が いたい です",
    "exTranslation": "My crotch hurts from riding the bicycle too much.",
    "category": "Noun"
  },
  {
    "term": "焚く",
    "termReading": "たく",
    "termTranslation": "start a fire",
    "ex": "薪を集めて火を焚いたよ。",
    "exReading": "まき を あつめて ひ を たいた よ",
    "exTranslation": "I gathered wood and made a fire.",
    "category": "Verb"
  },
  {
    "term": "明瞭",
    "termReading": "めいりょう",
    "termTranslation": "clear, plain",
    "ex": "彼は話し方が明瞭ね。",
    "exReading": "かれ は はなしかた が めいりょう ね",
    "exTranslation": "The way he talks is very clear.",
    "category": "Adjectival Noun"
  },
  {
    "term": "眉",
    "termReading": "まゆ",
    "termTranslation": "eyebrow",
    "ex": "彼は眉が濃いね。",
    "exReading": "かれ は まゆ が こい ね",
    "exTranslation": "He has thick eyebrows.",
    "category": "Noun"
  },
  {
    "term": "眉毛",
    "termReading": "まゆげ",
    "termTranslation": "eyebrows",
    "ex": "彼女の眉毛は太いな。",
    "exReading": "かのじょ の まゆげ は ふとい な",
    "exTranslation": "She has thick eyebrows.",
    "category": "Noun"
  },
  {
    "term": "やたらに",
    "termReading": "やたらに",
    "termTranslation": "frequently, persistently",
    "ex": "彼はやたらに話しかけてきた。",
    "exReading": "かれ は やたらに はなしかけて きた",
    "exTranslation": "He persistently tried to talk to me.",
    "category": "Adverb"
  },
  {
    "term": "割り箸",
    "termReading": "わりばし",
    "termTranslation": "disposable chopsticks",
    "ex": "彼女は割り箸を使わず、自分の箸を使うの。",
    "exReading": "かのじょ は わりばし を つかわず じぶん の はし を つかう の",
    "exTranslation": "She uses her own chopsticks rather than disposable ones.",
    "category": "Noun"
  },
  {
    "term": "蛋白質",
    "termReading": "たんぱくしつ",
    "termTranslation": "protein",
    "ex": "蛋白質は大切な栄養です。",
    "exReading": "たんぱくしつ は たいせつ な えいよう です",
    "exTranslation": "Protein is an important nutrient.",
    "category": "Noun"
  },
  {
    "term": "蜂",
    "termReading": "はち",
    "termTranslation": "bee",
    "ex": "友達が蜂に刺されたんだ。",
    "exReading": "ともだち が はち に さされた ん だ",
    "exTranslation": "My friend was stung by a bee.",
    "category": "Noun"
  },
  {
    "term": "頂戴",
    "termReading": "ちょうだい",
    "termTranslation": "getting, receiving",
    "ex": "それ、ひとつ頂戴。",
    "exReading": "それ ひとつ ちょうだい",
    "exTranslation": "I'll have one of those.",
    "category": "Verbal Noun"
  },
  {
    "term": "初詣で",
    "termReading": "はつもうで",
    "termTranslation": "first shrine visit of the new year",
    "ex": "近くの神社に初詣でに行きました。",
    "exReading": "ちかく の じんじゃ に はつもうで に いきました",
    "exTranslation": "We paid a new year's visit to the nearby Shinto shrine.",
    "category": "Verbal Noun"
  },
  {
    "term": "詫びる",
    "termReading": "わびる",
    "termTranslation": "apologize",
    "ex": "彼は彼女に心から詫びたんだ。",
    "exReading": "かれ は かのじょ に こころ から わびた ん だ",
    "exTranslation": "He apologized sincerely to her.",
    "category": "Verb"
  },
  {
    "term": "頬",
    "termReading": "ほお",
    "termTranslation": "cheek",
    "ex": "彼女はほおを赤く染めたわ。",
    "exReading": "かのじょ は ほお を あかく そめた わ",
    "exTranslation": "Her cheeks flushed pink.",
    "category": "Noun"
  },
  {
    "term": "餅",
    "termReading": "もち",
    "termTranslation": "rice cake",
    "ex": "正月には餅を食べますよ。",
    "exReading": "しょうがつ に は もち を たべます よ",
    "exTranslation": "We eat rice cakes during the New Year.",
    "category": "Noun"
  },
  {
    "term": "騙す",
    "termReading": "だます",
    "termTranslation": "deceive, cheat",
    "ex": "人を騙してはいけません。",
    "exReading": "ひと を だまして は いけません",
    "exTranslation": "You shouldn't cheat people.",
    "category": "Verb"
  },
  {
    "term": "便箋",
    "termReading": "びんせん",
    "termTranslation": "letter paper, stationery",
    "ex": "友達に手紙を書こうと便箋を買ったんだ。",
    "exReading": "ともだち に てがみ を かこう と びんせん を かった ん だ",
    "exTranslation": "I bought writing paper as I intended to write a letter to my friend.",
    "category": "Noun"
  }
 ];

 export {Vocab};